import { Table } from "@mui/joy";
import BootstrapTooltip1 from "../BootstrapToolTip/BootstrapToolTip1";
import { useTranslation } from "react-i18next";
export default function ROE({ flight }) {
    const { t } = useTranslation();
    return (
        <div className="flight-itenary">
            <div className="flight-itenary-header">
                <div className="flight-itenary-title-container">
                    <div>
                        {t("ROE")}
                    </div>
                </div>
            </div>
            <Table>
                <thead className="mui-table-header">
                    <BootstrapTooltip1 title={t("Supplier ROE")}>
                        <th style={{ textAlign: 'center' }}>{t("Supplier ROE")}</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title={t("Markup ROE")}>
                        <th style={{ textAlign: 'center' }}>{t("Markup ROE")}</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title={t("Client ROE")}>
                        <th style={{ textAlign: 'center' }}>{t("Client ROE")}</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title={t("IBoss ROE")}>
                        <th style={{ textAlign: 'center' }}>{t("IBoss ROE")}</th>
                    </BootstrapTooltip1>
                </thead>
                <tbody>
                    {
                        flight?.roes &&
                        <tr>
                            <td align="center">{flight?.roes?.supplierROE}</td>
                            <td align="center">{flight?.roes?.markupROE}</td>
                            <td align="center">{flight?.roes?.clientROE}</td>
                            <td align="center">{flight?.roes?.iBossROE}</td>
                        </tr>
                    }
                </tbody>
            </Table>
        </div>
    );
}