import React, {Fragment} from "react";
import './LineLoader.css';

const LineLoader = () => {
  return (
    <>
      <Fragment>
        <div
          className="ssc-line mb-3"
          style={{
            width: "100%",
            margin: "0 auto",
            padding: "5px 0",
          }}
        ></div>
        <div
          className="ssc-line mb-3"
          style={{
            width: "100%",
            margin: "0 auto",
            padding: "5px 0",
          }}
        ></div>
        <div
          className="ssc-line mb-3"
          style={{
            width: "100%",
            margin: "0 auto",
            padding: "5px 0",
          }}
        ></div>
      </Fragment>
    </>
  );
};

export default LineLoader;
