import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import AdjustIcon from "@mui/icons-material/Adjust";
import getTotalFare from "../../functions/getTotalFare";
import { useTranslation } from "react-i18next";
export default function BrandedFareHeadTable({
  result,
  selectedItinerary,
  setSelectedItinerary,
}) {
  const { t } = useTranslation();
  return (
    <div className="brandFareDetailsDiv">
      <Table className="fullHeight fareDetailsTable mb-3">
        <TableHead>
          <TableRow>
            <TableCell className="fareDetailsHeadCell">
              {" "}
              <p>{t("Brand Type")}</p>
            </TableCell>
            {result.fareGroups.map((item, index) => (
              <TableCell
                className="fareDetailsHeadCell"
                onClick={() => setSelectedItinerary(index)}
              >
                <p> {item?.priceClass?.toLowerCase()}</p>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell className="fareDetailsHeadCell">{t("Selection")}</TableCell>
            {result.fareGroups.map((item, index) => (
              <TableCell
                className="fareDetailsBodyCell"
                onClick={() => setSelectedItinerary(index)}
              >
                {index === selectedItinerary ? (
                  <p className="fs16 textTransCap ">&#9989;</p>
                ) : (
                  <p className="fs14 textTransCap colorBlack">
                    <AdjustIcon style={{ width: "18px" }} />
                  </p>
                )}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell className="fareDetailsHeadCell">
              {" "}
              <p>{t("Fares")}</p>
            </TableCell>
            {result.fareGroups.map((item, index) => (
              <TableCell className="fareDetailsBodyCell">
                <p>
                  {" "}
                  {getTotalFare(
                    item,
                    result.adtNum,
                    result.chdNum,
                    result.infNum
                  ).total.toFixed(2)}
                </p>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
    </div>
  );
}
