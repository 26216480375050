import BrandedFareHeadTable from "./BrandedFareHeadTable";
import BrandedFareTable from "./BrandedFareTable";
import getTotalFare from "../../functions/getTotalFare";
import CurrencyCodeComponent from "../../providers/CurrencyCode";
import { Row, Col, Nav, Tab } from "react-bootstrap";
import CancelIcon from "@mui/icons-material/Cancel";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DescriptionIcon from "@mui/icons-material/Description";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LuggageIcon from "@mui/icons-material/Luggage";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";


import {
  checkIfUserStaff,
  isClientUserHasPermission,
  getCurrentRole,
} from "../../services/AuthService";
import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import MiniRulesComponent from "../MiniRules/MiniRulesComponent";
import { useTranslation } from "react-i18next";
export default function BrandedFareComponent({
  arrayOf,
  result,
  selectedItinerary,
  setSelectedItinerary,
  filterAndSortBaggage,
  spliceBagtext,
  currencyCode,
  traceId,
  clientId,
  getFareDetails,
  activeTab,
  fareSegGroups,
  getAdultPrice,
  getChildrenPrice,
  getInfantPrice,
  getAdultTaxPrice,
  getChildrenTaxPrice,
  getInfantTaxPrice,
  proceedToBook,
  showNetfare,
  itineraryMarkup,
  adultcount,
  childcount,
  infantcount,
  additionalClientmarkup,
  flag,
  bookingDetails,
  tripType
}) {
  const [toggleShowHideFareDtls, setToggleShowHideFareDtls] = useState(false);
  const [userRole, setUserRole] = useState("");
  const { t } = useTranslation();
  const BootstrapTooltip1 = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.grey,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.grey,
    },
  }));


  useEffect(() => {
    setUserRole(getCurrentRole());

  }, [])

  const priceClass = result?.fareGroups[selectedItinerary]?.priceClass;

  return (
    <div className={"more_fare_detail"} id={`multiFareDetails_` + arrayOf}>
      {/* <> */}
      <Row className={"individual_fares"}>
        <Col sm={2} className="card_class">
          <span>
            {" "}
            {result?.fareGroups[selectedItinerary]?.priceClass !== undefined
              ? t(priceClass)
              : t("Economy")}
          </span>{" "}
        </Col>
        <Col sm={10} className="card_details">
          <Row className="reset_rows">
            {(result?.fareGroups[selectedItinerary]?.baggages &&
              filterAndSortBaggage(
                result?.fareGroups[selectedItinerary]?.baggages
              ) &&
              filterAndSortBaggage(
                result?.fareGroups[selectedItinerary]?.baggages
              )?.checkInBag &&
              filterAndSortBaggage(
                result?.fareGroups[selectedItinerary]?.baggages
              )?.checkInBag !== "Nil" &&
              filterAndSortBaggage(
                result?.fareGroups[selectedItinerary]?.baggages
              )?.checkInBag !== "0PC" &&
              filterAndSortBaggage(
                result?.fareGroups[selectedItinerary]?.baggages
              )?.checkInBag !== "0KG" &&
              filterAndSortBaggage(
                result?.fareGroups[selectedItinerary]?.baggages
              )?.checkInBag !== "0 Kg") ||
              (result?.fareGroups[selectedItinerary]?.baggages &&
                filterAndSortBaggage(
                  result?.fareGroups[selectedItinerary]?.baggages
                ) &&
                filterAndSortBaggage(
                  result?.fareGroups[selectedItinerary]?.baggages
                )?.cabinBag &&
                filterAndSortBaggage(
                  result?.fareGroups[selectedItinerary]?.baggages
                )?.cabinBag !== "Nil" &&
                filterAndSortBaggage(
                  result?.fareGroups[selectedItinerary]?.baggages
                )?.cabinBag !== "0KG" &&
                filterAndSortBaggage(
                  result?.fareGroups[selectedItinerary]?.baggages
                )?.cabinBag !== "0PC" &&
                filterAndSortBaggage(
                  result?.fareGroups[selectedItinerary]?.baggages
                )?.cabinBag !== "0 Kg") ? (
              <Col sm={3} className="fare_breakdown_container">
                <h6>
                  {result?.fareGroups[selectedItinerary]?.refundable
                    ? t("Refundable")
                    : t("Non-Refundable")}{" "}
                </h6>
                <div className="flight-confirmation-details-item">
                  {filterAndSortBaggage(
                    result?.fareGroups[selectedItinerary]?.baggages
                  )?.checkInBag &&
                    filterAndSortBaggage(
                      result?.fareGroups[selectedItinerary]?.baggages
                    )?.checkInBag !== "Nil" &&
                    filterAndSortBaggage(
                      result?.fareGroups[selectedItinerary]?.baggages
                    )?.checkInBag !== "0PC" &&
                    filterAndSortBaggage(
                      result?.fareGroups[selectedItinerary]?.baggages
                    )?.checkInBag !== "0KG" &&
                    filterAndSortBaggage(
                      result?.fareGroups[selectedItinerary]?.baggages
                    )?.checkInBag !== "0 Kg" && (
                      <>
                        <p className="bagText ">
                          {t("Check In")} :{" "}
                          {spliceBagtext(
                            filterAndSortBaggage(
                              result?.fareGroups[selectedItinerary]
                                ?.baggages
                            )?.checkInBag
                              ? filterAndSortBaggage(
                                result?.fareGroups[selectedItinerary]
                                  ?.baggages
                              )?.checkInBag
                              : ""
                          )}
                        </p>
                      </>
                    )}
                  {filterAndSortBaggage(
                    result?.fareGroups[selectedItinerary]?.baggages
                  )?.cabinBag &&
                    filterAndSortBaggage(
                      result?.fareGroups[selectedItinerary]?.baggages
                    )?.cabinBag !== "Nil" &&
                    filterAndSortBaggage(
                      result?.fareGroups[selectedItinerary]?.baggages
                    )?.cabinBag !== "0PC" &&
                    filterAndSortBaggage(
                      result?.fareGroups[selectedItinerary]?.baggages
                    )?.cabinBag !== "0KG" &&
                    filterAndSortBaggage(
                      result?.fareGroups[selectedItinerary]?.baggages
                    )?.cabinBag !== "0 Kg" && (
                      <>
                        <p className="bagText ">
                          {t("Cabin")} :{" "}
                          {spliceBagtext(
                            filterAndSortBaggage(
                              result?.fareGroups[selectedItinerary]
                                ?.baggages
                            )?.cabinBag
                              ? filterAndSortBaggage(
                                result?.fareGroups[selectedItinerary]
                                  ?.baggages
                              )?.cabinBag
                              : ""
                          )}
                        </p>
                      </>
                    )}
                </div>
              </Col>
            ) : (
              <Col sm={3} className="fare_breakdown_container textCenter">
                <h6>
                  {result?.fareGroups[selectedItinerary]?.refundable
                    ? t("Refundable")
                    : t("Non-Refundable")}{" "}
                </h6>
              </Col>
            )}

            <Col sm={9} className="padd_0">
              <Row className="reset_rows">
                <Col className="card_price">
                  <>
                    &nbsp;
                    {result.fareGroups[selectedItinerary]?.netFare > 0 && (
                      <BootstrapTooltip1
                        title={
                          <>
                            <p
                              style={{
                                borderBottom: "2px solid grey",
                              }}
                            >
                              {t("Fare Details")}
                            </p>

                            {((!checkIfUserStaff() && (isClientUserHasPermission("IsYouEarn") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                              showNetfare === true &&
                              result?.fareGroups[selectedItinerary]
                                ?.clientMarkup > 0 && (
                                <div className="d-flex justify-content-between">
                                  <div>{t("You Earn")}</div>
                                  <div>
                                    <CurrencyCodeComponent
                                      currencyCode={currencyCode}
                                    />

                                    {(
                                      parseFloat(
                                        result.fareGroups[selectedItinerary]
                                          ?.clientMarkup
                                      ) +
                                      (result?.fareGroups[selectedItinerary]
                                        ?.commission > 0
                                        ? parseFloat(
                                          result?.fareGroups[
                                            selectedItinerary
                                          ]?.commission
                                        )
                                        : 0) +
                                      (itineraryMarkup
                                        ? itineraryMarkup
                                        : 0) *
                                      adultcount +
                                      (itineraryMarkup
                                        ? itineraryMarkup
                                        : 0) *
                                      childcount
                                    ).toFixed(2)}
                                  </div>
                                </div>
                              )}
                            {((!checkIfUserStaff() && (isClientUserHasPermission("IsShowNetfares") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                              showNetfare === true &&
                              result?.fareGroups[selectedItinerary]
                                ?.clientMarkup > 0 && (
                                <div
                                  className="d-flex justify-content-between"
                                  style={{
                                    fontSize: "10px",
                                    color: "lightgray",
                                  }}
                                >
                                  <div>{t("Agent Mark-up")}</div>

                                  <div>
                                    <CurrencyCodeComponent
                                      currencyCode={currencyCode}
                                    />
                                    {(
                                      result.fareGroups[selectedItinerary]
                                        ?.clientMarkup +
                                      (itineraryMarkup
                                        ? itineraryMarkup * adultcount
                                        : 0) +
                                      (itineraryMarkup
                                        ? itineraryMarkup * childcount
                                        : 0)
                                    ).toFixed(2)}
                                  </div>
                                </div>
                              )}
                            {((!checkIfUserStaff() && (isClientUserHasPermission("IsShowNetfares") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                              showNetfare === true &&
                              result?.fareGroups[selectedItinerary]
                                ?.commission > 0 && (
                                <div
                                  className="d-flex justify-content-between"
                                  style={{
                                    fontSize: "10px",
                                    color: "lightgray",
                                  }}
                                >
                                  <div>{t("Commission")}</div>
                                  <div>
                                    <CurrencyCodeComponent
                                      currencyCode={currencyCode}
                                    />
                                    0.0
                                  </div>
                                </div>
                              )}
                            <div
                              className="d-flex justify-content-between"
                              style={{
                                borderTop: "2px solid grey",
                              }}
                            >
                              <div>{t("Total Amount")}</div>
                              <div>
                                <CurrencyCodeComponent
                                  currencyCode={currencyCode}
                                />
                                {result.fareGroups[selectedItinerary]
                                  ?.grossFare +
                                  (itineraryMarkup
                                    ? itineraryMarkup * adultcount
                                    : 0) +
                                  (itineraryMarkup
                                    ? itineraryMarkup * childcount
                                    : 0)}
                              </div>
                            </div>

                            {((!checkIfUserStaff() && (isClientUserHasPermission("IsShowNetfares") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                              showNetfare === true && (
                                <div
                                  className="d-flex justify-content-between"
                                // style={{ fontSize: "9px" }}
                                >
                                  <div>{t("Net Fare")}</div>
                                  <div>
                                    <CurrencyCodeComponent
                                      currencyCode={currencyCode}
                                    />
                                    {
                                      result.fareGroups[selectedItinerary]
                                        ?.netFare
                                    }
                                  </div>
                                </div>
                              )}
                          </>
                        }
                      >
                        <InfoOutlinedIcon />
                      </BootstrapTooltip1>
                    )}
                    <div className="">
                      <p>
                        <CurrencyCodeComponent
                          currencyCode={currencyCode}
                        />
                        {(
                          getTotalFare(
                            result.fareGroups[selectedItinerary],
                            adultcount,
                            childcount,
                            infantcount
                          ).total +
                          (itineraryMarkup
                            ? itineraryMarkup * adultcount
                            : 0) +
                          (itineraryMarkup
                            ? itineraryMarkup * childcount
                            : 0)
                        ).toFixed(2)}
                      </p>
                      {((!checkIfUserStaff() && (isClientUserHasPermission("IsShowNetfares") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                        showNetfare === true &&
                        result.fareGroups[selectedItinerary]?.netFare >
                        0 && (
                          <div
                            className="d-flex align-items-center"
                            style={{
                              fontSize: "13px",
                              color: "gray",
                            }}
                          >
                            <div>{t("Net Fare")} : </div>
                            <div>
                              <CurrencyCodeComponent
                                currencyCode={currencyCode}
                              />
                              {result.fareGroups[selectedItinerary]?.netFare}
                            </div>
                          </div>
                        )}
                    </div>
                    {
                      // !checkIfUserStaff() &&
                      //   !isUserHasPermission("FlightBook") ? null : (
                      <>
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                          {result.isHold === true &&
                            <>
                              <Button
                                variant="contained"
                                disabled={!checkIfUserStaff() && !isClientUserHasPermission("IsPrice", "FlightbookDetail") && userRole !== "ClientSuperAdmin"}
                                className="srn-btn marginBR5"
                                style={{ margin: '10px' }}
                                onClick={() => {
                                  proceedToBook("Hold");
                                  localStorage.removeItem("reValTimer");
                                }}
                              >
                                {t("Hold")}
                              </Button>

                            </>
                          }
                          <div className="flight-confirmation-details-item-Brandedfare">
                            <Button
                              variant="contained"
                              disabled={!checkIfUserStaff() && !isClientUserHasPermission("IsPrice", "FlightbookDetail") && userRole !== "ClientSuperAdmin"}
                              className="srn-btn marginBR5"
                              onClick={() => {
                                proceedToBook("Book");
                                localStorage.removeItem("reValTimer");
                              }}
                            >
                              {t("Book")}
                            </Button>
                            <span
                              onClick={() =>
                                setToggleShowHideFareDtls(
                                  (toggleShowHideFareDtls) =>
                                    !toggleShowHideFareDtls
                                )
                              }
                              className="fs14 linkcolor"
                            >
                              {toggleShowHideFareDtls ? (
                                <>
                                  {t("Hide More Fares")} &nbsp;{" "}
                                  <KeyboardArrowUpRoundedIcon fontSize="small" />
                                </>
                              ) : (
                                <>
                                  {t("Show More Fares")} &nbsp;{" "}
                                  <KeyboardArrowDownRoundedIcon fontSize="small" />
                                </>
                              )}
                            </span>
                          </div>
                        </div>

                      </>
                      // )
                    }
                  </>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {toggleShowHideFareDtls ? (
        <Row className="" id={`fareDetails_`}>
          <Tab.Container
            defaultActiveKey={activeTab}
            onSelect={(k) =>
              getFareDetails(
                k,
                traceId,
                result?.fareGroups[selectedItinerary].purchaseId,
                clientId,
                selectedItinerary,
                arrayOf
              )
            }
          >
            <Row className="fare_tab_container px-0">
              <Col sm={3} className="flex-column fare_header_container">
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="fareDescription">
                      {" "}
                      <DescriptionIcon fontSize="small" /> {t("Brand Description")}{" "}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fareSummary">
                      {" "}
                      <SummarizeIcon fontSize="small" /> {t("Fare Summary")}{" "}
                    </Nav.Link>
                  </Nav.Item>
                  {result?.vendor !== "G9" && (
                    <Nav.Item>
                      <Nav.Link eventKey="baggages">
                        {" "}
                        <LuggageIcon fontSize="small" /> {t("Baggages")}{" "}
                      </Nav.Link>
                    </Nav.Item>
                  )}

                  <Nav.Item>
                    <Nav.Link eventKey="cancel">
                      {" "}
                      <CancelIcon fontSize="small" /> {t("Cancellation")}{" "}
                    </Nav.Link>
                  </Nav.Item>


                  <Nav.Item>
                    <Nav.Link eventKey="change">
                      {" "}
                      <DateRangeIcon fontSize="small" /> {t("Date Change")}{" "}
                    </Nav.Link>
                  </Nav.Item>

                </Nav>
              </Col>
              <Col sm={9} className="fare_tab_contents_wrapper px-0">
                <Tab.Content className="fullHeight">
                  <Tab.Pane
                    eventKey="fareDescription"
                    className="fare_tabs"
                    transition={true}
                  >
                    <BrandedFareTable
                      result={result}
                      selectedItinerary={selectedItinerary}
                      setSelectedItinerary={setSelectedItinerary}
                      tripType={tripType}
                    />
                    {
                      result?.brandDescriptionWarning && result?.vendor === 'TF' &&
                      <div className="text-danger">{result?.brandDescriptionWarning}</div>
                    }
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="fareSummary"
                    className="fare_tabs"
                    transition={true}
                  >
                    {" "}
                    <BrandedFareHeadTable
                      result={result}
                      selectedItinerary={selectedItinerary}
                      setSelectedItinerary={setSelectedItinerary}
                    />{" "}
                    <div className="fullHeight">
                      <table className="fullHeight fareDetailsTable">
                        <tr>
                          <th className="text-start" rowSpan={2}>
                            {t("Travellers")}
                          </th>
                          <th colSpan={3}>{t("Per Pax")} ({currencyCode})</th>
                          {/* <th colSpan={2}>Sub Total ({currencyCode})</th> */}
                          <th colSpan={2}>{t("Gross Total")} ({currencyCode})</th>
                        </tr>
                        <tr>
                          <th>{t("Base")}</th>
                          <th>{t("Tax")}</th>
                          <th>{t("Total")}</th>
                          {/* <th>Base</th>
                            <th>Tax</th> */}
                          <th>{`(Base + Tax)`}</th>
                        </tr>
                        {adultcount > 0 && (
                          <tr>
                            <td className="text-start">
                              {adultcount} x {t("Adult(s)")}
                            </td>
                            <td>{getAdultPrice().toFixed(2)}</td>
                            <td>{getAdultTaxPrice().toFixed(2)}</td>
                            <td>{(Number(getAdultPrice()) + Number(getAdultTaxPrice())).toFixed(2)}</td>
                            <td>
                              {(Number(adultcount * getAdultPrice()) + Number(adultcount * getAdultTaxPrice())).toFixed(2)}
                            </td>
                          </tr>
                        )}
                        {childcount > 0 && (
                          <tr>
                            <td className="text-start">
                              {childcount} x {t("Children")}
                            </td>
                            <td>{getChildrenPrice().toFixed(2)}</td>
                            <td>{getChildrenTaxPrice().toFixed(2)}</td>
                            <td>
                              {(Number(getChildrenPrice()) + Number(
                                getChildrenTaxPrice()
                              )).toFixed(2)}
                            </td>
                            <td>
                              {(Number(childcount * getChildrenPrice()
                              ) + Number(
                                childcount * getChildrenTaxPrice()
                              )).toFixed(2)}
                            </td>
                          </tr>
                        )}
                        {infantcount > 0 && (
                          <tr>
                            <td className="text-start">
                              {infantcount} x {t("Infant")}
                            </td>
                            <td>{getInfantPrice().toFixed(2)}</td>
                            <td>{getInfantTaxPrice().toFixed(2)}</td>
                            <td>
                              {(Number(getInfantPrice()) + Number(getInfantTaxPrice())).toFixed(
                                2
                              )}
                            </td>
                            <td>
                              {(Number(infantcount * getInfantPrice()) + Number(infantcount * getInfantTaxPrice())).toFixed(
                                2
                              )}
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="baggages"
                    className="fare_tabs"
                    transition={true}
                  >
                    {" "}
                    <BrandedFareHeadTable
                      result={result}
                      selectedItinerary={selectedItinerary}
                      setSelectedItinerary={setSelectedItinerary}
                    />
                    <Row>
                      <div className="fullHeight baggDetails">
                        {fareSegGroups.map((segGroup, segGroupIndex) =>
                          segGroup.segs.map((item, index) =>
                            result?.fareGroups[selectedItinerary]
                              ?.baggages &&
                              result?.fareGroups[selectedItinerary]?.baggages
                                .length > 0 ? (
                              <table className="fullHeight fareDetailsTable mb-3">
                                <tr>
                                  <th className="text-start" colSpan={3}>
                                    {item.origin} - {item.destination}
                                  </th>
                                </tr>
                                <tr>
                                  <th className="text-start">{t("Baggages")}</th>
                                  <th>{t("Check In")}</th>
                                  <th>{t("Cabin")}</th>
                                </tr>
                                {result?.fareGroups[
                                  selectedItinerary
                                ]?.baggages
                                  .filter(
                                    (t) =>
                                      t.cityPair ===
                                      item.origin + item.destination
                                  )
                                  .sort(function (a, b) {
                                    if (a.paxType < b.paxType) {
                                      return -1;
                                    }
                                    if (a.paxType > b.paxType) {
                                      return 1;
                                    }
                                    return 0;
                                  })
                                  .map((listItem, index) => (
                                    <tr>
                                      <td
                                        width={150}
                                        className="text-start"
                                      >
                                        {t(listItem.paxType)}
                                      </td>
                                      <td width={250}>
                                        {listItem.baggageInfo === undefined
                                          ? listItem?.checkInBag
                                            ? listItem?.checkInBag
                                            : "NIL"
                                          : listItem.baggageInfo
                                            ? listItem.baggageInfo
                                            : "NIL"}
                                      </td>
                                      <td width={250}>
                                        {/* {result?.vendor === "1A" ||
                                            result?.vendor === "1S" ||
                                            result?.vendor === "1G"
                                              ? "7KG"
                                              : listItem?.cabinBaggageInfo ===
                                                undefined
                                              ? listItem?.cabinBag
                                                ? listItem?.cabinBag
                                                : "NIL"
                                              : listItem?.cabinBaggageInfo
                                              ? listItem?.cabinBaggageInfo
                                              : "NIL"} */}
                                        {listItem?.cabinBaggageInfo ===
                                          undefined
                                          ? listItem?.cabinBag
                                            ? listItem?.cabinBag
                                            : "NIL"
                                          : listItem?.cabinBaggageInfo
                                            ? listItem?.cabinBaggageInfo
                                            : "NIL"}
                                      </td>
                                    </tr>
                                  ))}
                              </table>
                            ) : (
                              <table className="fullHeight fareDetailsTable mb-3">
                                <tr>
                                  <th className="text-start" colSpan={3}>
                                    {item.origin} - {item.destination}
                                  </th>
                                </tr>
                                <tr>
                                  <th className="text-start">{t("Baggages")}</th>
                                  <th>{t("Check In")}</th>
                                  <th>{t("Cabin")}</th>
                                </tr>
                                {
                                  <tr>
                                    <td width={150} className="text-start">
                                      {t("ADT,CHD,INF")}
                                    </td>
                                    <td width={250}>NIL</td>
                                    <td width={250}>
                                      {/* {result?.vendor === "1A" ||
                                            result?.vendor === "1S" ||
                                            result?.vendor === "1G"
                                            ? "7KG"
                                            : "NIL"} */}
                                    </td>
                                  </tr>
                                }
                              </table>
                            )
                          )
                        )}
                      </div>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="cancel"
                    className="fullHeight fare_tabs"
                    transition={true}
                  >
                    <BrandedFareHeadTable
                      result={result}
                      selectedItinerary={selectedItinerary}
                      setSelectedItinerary={setSelectedItinerary}
                    />

                    {result && (
                      <MiniRulesComponent
                        value={"Cancellation"}
                        miniRules={result.fareGroups[selectedItinerary]?.miniRules}
                        currentTicket={result}
                        currencyCode={currencyCode}
                        selectedItinerary={selectedItinerary}
                        traceId={traceId}
                        clientId={clientId}
                        additionalClientmarkup={additionalClientmarkup}
                        flag={flag}
                        adtCount={result.adtNum}
                        chdCount={result.chdNum}
                        infCount={result.infNum}
                        refundable={result.fareGroups[selectedItinerary]?.refundable}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="change"
                    className="fullHeight fare_tabs"
                    transition={true}
                  >
                    <BrandedFareHeadTable
                      result={result}
                      selectedItinerary={selectedItinerary}
                      setSelectedItinerary={setSelectedItinerary}
                    />
                    {result && (
                      <MiniRulesComponent
                        value={"Date Change"}
                        miniRules={result.fareGroups[selectedItinerary]?.miniRules}
                        currentTicket={result}
                        currencyCode={currencyCode}
                        selectedItinerary={selectedItinerary}
                        traceId={traceId}
                        clientId={clientId}
                        additionalClientmarkup={additionalClientmarkup}
                        flag={flag}
                        refundable={result.fareGroups[selectedItinerary]?.refundable}
                        adtCount={result.adtNum}
                        chdCount={result.chdNum}
                        infCount={result.infNum}
                      />
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Row>
      ) : (
        ""
      )}
      {/* </> */}
    </div>
  );
}
