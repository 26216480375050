import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    Button,
    Drawer,
    IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import FlightBaggageDrawer from "./FlightBaggageDrawer";
import airlineDetails from "../../../../data/AirlineDetails";

const FlightBaggageDetails = ({ segGroups, baggageDetails, setBaggageDetails, passengers, flight, flag }) => {
    const [baggDrawer, setBaggDrawer] = useState(false);
    const [baggAction, setBaggAction] = useState(false);
    const [currentEditIndex, setCurrentEditIndex] = useState(null);
    const distinctMrkAirlines = flight.segGroups.map(segGroup => segGroup.segments.map(segment => segment.mrkAirline));
    const flattenedAirlineCodes = distinctMrkAirlines.flat();
    const filteredAirlineDetails = airlineDetails.filter(airline => flattenedAirlineCodes.includes(airline.Code));

    const deleteBaggageRow = (index) => {
        let temp = structuredClone(baggageDetails);
        temp.splice(index, 1);
        setBaggageDetails((baggageDetails) => temp);
    };

    const handleEditBaggage = (index) => {
        setCurrentEditIndex(index);
        setBaggAction("");
        setTimeout(() => setBaggAction("edit"), 0);
        setBaggDrawer(true);
    };


    return (
        <div>
            <div className={`${flag === "reissue" ? "copy-ressue-itenary-header" : "itenary-container-import"} booking-padding-top-40 mt-4`}>
                <div className="">
                    Baggage
                </div>
                <Button
                    variant="outlined"
                    color="primary"
                    className="addButton"
                    onClick={() => {
                        setBaggDrawer(true);
                        setBaggAction("new");
                    }}
                >
                    Add
                </Button>
            </div>
            <Table style={{ backgroundColor: "white" }} className="mt-4 mb-3 itenary-passenger-table">
                <TableHead className="bagg-thead">
                    <TableRow className={flag === "reissue" ? "" : "itenary-passenger-table-header"}>
                        <TableCell align="center">Pax Type</TableCell>
                        <TableCell align="center">Pax Name</TableCell>
                        <TableCell align="center">Airline</TableCell>
                        <TableCell align="center">Check In Baggage</TableCell>
                        <TableCell align="center">Cabin Baggage</TableCell>
                        <TableCell align="center">City Pair</TableCell>
                        <TableCell align="center">Amount</TableCell>
                        <TableCell align="">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {baggageDetails && baggageDetails.length > 0 ? (
                        baggageDetails.map((baggage, index) => {
                            let airlineName = '';
                            if (typeof baggage.airline === "string") {
                                const matchingAirline = filteredAirlineDetails.find(
                                    (airline) => airline.Code === baggage.airline
                                );
                                airlineName = matchingAirline ? matchingAirline.Name : baggage.airline;
                            } else if (baggage.airline && baggage.airline.Name) {
                                airlineName = baggage.airline.Name;
                            }

                            return (
                                <TableRow key={index}>
                                    <TableCell align="center">{baggage.paxType}</TableCell>
                                    <TableCell align="center">{baggage.paxName}</TableCell>
                                    <TableCell align="center">{airlineName}</TableCell>
                                    <TableCell align="center">{baggage.checkInBag}</TableCell>
                                    <TableCell align="center">{baggage.cabinBag}</TableCell>
                                    <TableCell align="center">{baggage.cityPair}</TableCell>
                                    <TableCell align="center">{baggage.amount}</TableCell>
                                    <TableCell align="center" style={{ width: "50px" }} className="d-flex">
                                        <IconButton
                                            color="inherit"
                                            onClick={() => {
                                                handleEditBaggage(index);
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            color="error"
                                            className="deleteIcon"
                                            onClick={async () => {
                                                await deleteBaggageRow(index);
                                            }}
                                            aria-label="delete"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    ) : (
                        <TableRow>
                            <TableCell align="center" colSpan={8}>No Baggage Data!</TableCell>
                        </TableRow>
                    )}

                </TableBody>
            </Table>
            <Drawer
                anchor="right"
                open={baggDrawer}
                onClose={() => setBaggDrawer(false)}
                BackdropProps={{ invisible: true }}
                variant="persistent"
                className="drawer-width"
                transitionDuration={{ enter: 500, exit: 500 }}
            >
                <div className="drawer-min-width-50">
                    <FlightBaggageDrawer
                        baggAction={baggAction}
                        setBaggDrawer={setBaggDrawer}
                        segGroups={segGroups}
                        baggageDetails={baggageDetails}
                        setBaggageDetails={setBaggageDetails}
                        index={currentEditIndex}
                        passengers={passengers}
                        flight={flight}
                        flag={flag}
                    ></FlightBaggageDrawer>
                </div>
            </Drawer>
        </div>
    );
};

export default FlightBaggageDetails;
