import React from 'react'
import { checkIfUserStaff } from '../../services/AuthService';
import { Table } from "@mui/joy";

const ReissueClientFare = ({ bookingDetails, t, passengersDetails, reissueInputUpdates }) => {
    return (
        <div>
            <div className='flight-itenary-header mt-5' >
                <div className='flight-itenary-title-container'>
                    {t("Client Fare")}
                </div>
                <div className=''>
                    {bookingDetails &&
                        bookingDetails?.clientCurrency ? (
                        <p className="ms-1"
                            style={{
                                fontSize: "20px",
                                marginBottom: "0px",
                            }}>
                            {bookingDetails?.clientCurrency}
                        </p>
                    ) : (
                        <p> </p>
                    )}
                </div>
            </div>

            <div className='reissue-clientfare'>
                <Table className='textCenter  itenary-passenger-table'>
                    <thead className="mui-table-header">
                        <th class="text-center">
                            {t("PaxType")}
                        </th>
                        <th class="text-center">
                            {t("BaseFare")}
                        </th>
                        <th class="text-center">
                            {t("Taxes")}
                        </th>
                        <th class="text-center">
                            {t("DateChangeFee")}
                        </th>
                        <th class="text-center">
                            {t("Baggage/SSR")}
                        </th>
                        <th class="text-center">
                            {t("GFSServiceFee")}
                        </th>
                        <th class="text-center">
                            {t("ClientFare(perPax")}
                        </th>
                        <th class="text-center">
                            {t("ClientTotal")}
                        </th>
                    </thead>
                    <tbody className='itenary-passenger-table-item price-row'>
                        {reissueInputUpdates.map(
                            (reissueInput) => {
                                return (
                                    <tr>
                                        <td align='center'>
                                            {reissueInput.paxType} * {reissueInput.paxCount}
                                        </td>
                                        <td align='center'>
                                            {Number(reissueInput.clientBaseFare)?.toFixed(2) || "0.0"}
                                        </td>
                                        <td align='center'>
                                            {Number(reissueInput.clientTax)?.toFixed(2) || "0.0"}
                                        </td>
                                        <td align='center'>
                                            {Number(reissueInput.clientDateChangeFee)?.toFixed(2) || "0.0"}
                                        </td>
                                        <td align='center'>
                                            {Number(reissueInput.clientBaggage)?.toFixed(2) || "0.0"}
                                        </td>
                                        <td align='center'>
                                            {Number(reissueInput.clientGfsServiceFee)?.toFixed(2) || "0.0"}
                                        </td>
                                        <td align='center'>
                                            {Number(reissueInput.clientTotal)?.toFixed(2) || "0.0"}
                                        </td>
                                        <td align='center'>
                                            {Number(reissueInput.clientTotal * reissueInput.paxCount)?.toFixed(2) || "0.0"}
                                        </td>
                                    </tr>
                                );
                            }
                        )}
                        <tr className=''>
                            <td align='center' className="fw600">Total</td>
                            <td align='center' className="fw600">
                                {reissueInputUpdates.reduce((total, input) => total + parseFloat(input.clientBaseFare || 0), 0).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {reissueInputUpdates.reduce((total, input) => total + parseFloat(input.clientTax || 0), 0).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {reissueInputUpdates.reduce((total, input) => total + parseFloat(input.clientDateChangeFee || 0), 0).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {reissueInputUpdates.reduce((total, input) => total + parseFloat(input.clientBaggage || 0), 0).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {reissueInputUpdates.reduce((total, input) => total + parseFloat(input.clientGfsServiceFee || 0), 0).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {reissueInputUpdates.reduce((total, input) => total + parseFloat(input.clientTotal || 0), 0).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {reissueInputUpdates.reduce((total, input) => total + parseFloat((input.clientTotal * input.paxCount) || 0), 0).toFixed(2)}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default ReissueClientFare