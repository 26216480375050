import { Container, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import "./Administration.css";
import { Link } from "react-router-dom";
import { isUserHasPermission } from "../../services/AuthService";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function Administration() {
  const classes = useStyles();

  return (
    <div className="admin-container">
      <Container>
        <Grid container spacing={3}>
          {(isUserHasPermission("ClientRead") ||
            isUserHasPermission("ClientWrite")) && (
            <Grid item xs={6} sm={3}>
              <Paper className={classes.paper}>
                <Link to="/ManageClients">Manage Clients</Link>
              </Paper>
            </Grid>
          )}
          <Grid item xs={6} sm={3}>
            <Paper className={classes.paper}>
              <Link to="/ManageUser/0">Manage Staff User</Link>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className={classes.paper}>
              <Link to="/Supplier">Manage Pcc</Link>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className={classes.paper}>
              <Link to="/Vendor">Manage Vendor</Link>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className={classes.paper}>
              <Link to="/SupplierCommissionGroup">Manage Supplier Commission</Link>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className={classes.paper}>
              <Link to="/SupplierCommissionBlock/0/name">Manage Supplier CommissionBlock</Link>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className={classes.paper}>
              <Link to="/RoleRights">Manage RoleRights</Link>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className={classes.paper}>
              <Link to="/SearchCategory">Manage Search Category</Link>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className={classes.paper}>
              <Link to="/SearchCategoryConfiguration/0">Manage  Category Configuration</Link>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className={classes.paper}>
              <Link to="/BookingSwitches">Manage Re-validation Configuration</Link>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className={classes.paper}>
              <Link to="/TicketingConfiguration">Manage Ticketing Configuration</Link>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Administration;
