import React from 'react';
import { Carousel } from 'react-bootstrap';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

const CarouselComponent = () => {
    return (
        <Carousel pause="hover" interval={20000000} className='banner-carousel'>
            <Carousel.Item>
                {/* <LazyLoadImage
                wrapperClassName="get2ItalyBannerWrapper"
                    className="get2ItalyBanner"
                    src={"https://images.getfares.com/gfs/assets/slider2.png"}
                    // placeholderSrc={"https://images.getfares.com/gfs/assets/slider2Blur.jpg"}
                    width={'1016px'} height={'386px'}
                    alt="Image Alt"
                    effect="blur"
                    placeholderStyle={{
                        objectFit: "scale-down",
                        width: "1016px",
                        height: "386px",
                      }}
                /> */}
                <img
                    className="get2ItalyBanner"
                    alt="https://www.get2italy.com/"
                    src={"https://images.getfares.com/gfs/assets/slider2.png"}
                />
            </Carousel.Item>
            <Carousel.Item>
                {/* <LazyLoadImage
                    className="get2ItalyBanner"
                    wrapperClassName="get2ItalyBannerWrapper"
                    src={"https://images.getfares.com/gfs/assets/slider3.png"}
                    // placeholderSrc={"https://images.getfares.com/gfs/assets/slider3Blur.jpg"}
                    width={'1016px'} height={'386px'}
                    alt="Image Alt"
                    style={{ objectFit: "scale-down" }}
                    effect="blur"
                    placeholderStyle={{
                        objectFit: "scale-down",
                        width: "1016px",
                        height: "386px",
                      }}
                /> */}
                <img
                    className="get2ItalyBanner"
                    alt="https://www.get2italy.com/"
                    src={"https://images.getfares.com/gfs/assets/slider3.png"}
                />
            </Carousel.Item>
        </Carousel>
    );
};

export default CarouselComponent;
