import { Table } from "@mui/joy";
import BootstrapTooltip1 from "../BootstrapToolTip/BootstrapToolTip1";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CurrencyCodeComponent from "../../providers/CurrencyCode";
import { useTranslation } from "react-i18next";
export default function ClientFare({ flight, fop }) {
    const { t } = useTranslation();
    return (
        <div className='flight-itenary'>
            <div className='flight-itenary-header'>
                <div
                    className='flight-itenary-title-container'
                    style={{ width: "100%" }}>
                    <p style={{ marginBottom: "0px", width: "100%" }}>
                        {t("Client Fare")}
                        <span style={{ float: "right" }}>
                            {t("Currency")} -{" "}
                            {flight?.clientFare && flight?.clientFare[0]?.clientCurrency
                                ? flight?.clientFare[0]?.clientCurrency
                                : "-"}
                        </span>
                    </p>
                </div>
            </div>
            {flight?.clientFare && flight?.clientFare?.length > 0 ? (
                <Table>
                    <thead className='mui-table-header'>
                        <BootstrapTooltip1 title={t("PaxType")}>
                            <th style={{ textAlign: "center" }}>{t("PaxType")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("Base Fare")}>
                            <th style={{ textAlign: "center" }}>{t("Base Fare")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("Tax")}>
                            <th style={{ textAlign: "center" }}>{t("Tax")}</th>
                        </BootstrapTooltip1>
                        {fop === "PassThrough" || fop.includes("PassThrough") ? (
                            <BootstrapTooltip1 title={t("PassThrough Markup")}>
                                <th style={{ textAlign: "center" }}>{t("PassThrough Markup")}</th>
                            </BootstrapTooltip1>
                        ) : (
                            ""
                        )}
                        <BootstrapTooltip1 title={t("Baggage Fees")}>
                            <th style={{ textAlign: "center" }}>{t("Baggage Fees")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("Seat")}>
                            <th style={{ textAlign: "center" }}>{t("Seat")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("Meal")}>
                            <th style={{ textAlign: "center" }}>{t("Meal")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("GST")}>
                            <th style={{ textAlign: "center" }}>{t("GST")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("Client Total (Per Pax)")}>
                            <th style={{ textAlign: "center" }}>{t("Client Total (Per Pax)")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("Client Total")}>
                            <th style={{ textAlign: "center" }}>{t("Client Total")}</th>
                        </BootstrapTooltip1>
                    </thead>
                    <tbody>
                        {flight?.clientFare
                            ?.sort(function (a, b) {
                                if (a.paxType < b.paxType) {
                                    return -1;
                                }
                                if (a.paxType > b.paxType) {
                                    return 1;
                                }
                                return 0;
                            })
                            .map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td align='center'>
                                            {item?.paxType} * {item?.paxCount}
                                        </td>
                                        <td align='center'>{(item?.baseFare).toFixed(2)}</td>
                                        <td align='center'>{(item?.tax).toFixed(2)}</td>
                                        {(fop === "PassThrough" || fop.includes("PassThrough")) &&
                                            <td align='center'>
                                                {item?.passThroughMarkup.toFixed(2)}
                                            </td>
                                        }
                                        <td align='center'>{(item?.baggagefare).toFixed(2)}</td>
                                        <td align='center'>{(item?.seatFare).toFixed(2)}</td>
                                        <td align='center'>{(item?.mealFare).toFixed(2)}</td>
                                        <td align='center'>{(item?.gst).toFixed(2)}</td>
                                        {item?.paxType === "INF" ? (
                                            <>
                                                <td align='center'>
                                                    {(
                                                        item?.clientTotal
                                                    ).toFixed(2)}
                                                </td>
                                                <td align='center'>
                                                    {(
                                                        (item?.clientTotal) *
                                                        item?.paxCount
                                                    ).toFixed(2)}
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td align='center'>
                                                    {(
                                                        item?.clientTotal
                                                    ).toFixed(2)}
                                                </td>
                                                <td align='center'>
                                                    {(
                                                        (item?.clientTotal) *
                                                        item?.paxCount
                                                    ).toFixed(2)}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                );
                            })}
                        <tr>
                            <td align='center' className='fw600'>
                                <b>Total</b>
                            </td>
                            <td align='center' className='fw600'>
                                {flight?.clientFare
                                    ?.reduce((total, item) => total + (item?.baseFare || 0), 0)
                                    .toFixed(2)}
                            </td>
                            <td align='center' className='fw600'>
                                {flight?.clientFare
                                    ?.reduce((total, item) => total + (item?.tax || 0), 0)
                                    .toFixed(2)}
                            </td>
                            {(fop === "PassThrough" || fop.includes("PassThrough")) &&
                                <td align='center'>
                                    {flight?.clientFare
                                        ?.reduce((total, item) => total + (item?.passThroughMarkup || 0), 0)
                                        .toFixed(2)}
                                </td>
                            }
                            <td align='center' className='fw600'>
                                {flight?.clientFare
                                    ?.reduce((total, item) => total + (item?.baggagefare || 0), 0)
                                    .toFixed(2)}
                            </td>
                            <td align='center' className='fw600'>
                                {flight?.clientFare
                                    ?.reduce((total, item) => total + (item?.seatFare || 0), 0)
                                    .toFixed(2)}
                            </td>
                            <td align='center' className='fw600'>
                                {flight?.clientFare
                                    ?.reduce((total, item) => total + (item?.mealFare || 0), 0)
                                    .toFixed(2)}
                            </td>
                            <td align='center' className='fw600'>
                                {flight?.clientFare
                                    ?.reduce((total, item) => total + (item?.gst || 0), 0)
                                    .toFixed(2)}
                            </td>
                            <td align='center' className='fw600'>
                                {flight?.clientFare && Array.isArray(flight.clientFare) ? (
                                    flight.clientFare.reduce(
                                        (total, item) => total + (item?.clientTotal || 0),
                                        0
                                    )
                                ) : 0}
                            </td>
                            <td align='center' className='fw600'>
                                {flight?.clientFare?.reduce(
                                    (total, item) =>
                                        total + (item?.clientTotal * item?.paxCount || 0),
                                    0
                                )}
                                <span className=''>
                                    {" "}
                                    {flight?.netfare > 0 && (
                                        <BootstrapTooltip1
                                            title={
                                                <>
                                                    <p
                                                        style={{
                                                            borderBottom: "2px solid grey",
                                                        }}>
                                                        {t("Fare Details")}
                                                    </p>

                                                    {flight?.clientMarkup > 0 && (
                                                        <div className='d-flex justify-content-between'>
                                                            <div>{t("You Earn")}</div>
                                                            <div className='ms-1'>
                                                                <CurrencyCodeComponent
                                                                    currencyCode={flight.currency}
                                                                />

                                                                {(
                                                                    parseFloat(flight.clientMarkup) +
                                                                    (flight?.commission > 0
                                                                        ? parseFloat(flight?.commission)
                                                                        : 0)
                                                                ).toFixed(2)}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {flight?.clientMarkup > 0 && (
                                                        <div
                                                            className='d-flex justify-content-between'
                                                            style={{
                                                                fontSize: "10px",
                                                                color: "lightgray",
                                                            }}>
                                                            <div>{t("Agent Mark-up")}</div>

                                                            <div className='ms-1'>
                                                                <CurrencyCodeComponent
                                                                    currencyCode={flight.currency}
                                                                />
                                                                {flight?.clientMarkup?.toFixed(2)}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {flight?.commission > 0 && (
                                                        <div
                                                            className='d-flex justify-content-between'
                                                            style={{
                                                                fontSize: "10px",
                                                                color: "lightgray",
                                                            }}>
                                                            <div>{t("Commission")}</div>
                                                            <div className='ms-1'>
                                                                <CurrencyCodeComponent
                                                                    currencyCode={flight.currency}
                                                                />
                                                                0.0
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div
                                                        className='d-flex justify-content-between'
                                                        style={{
                                                            borderTop: "2px solid grey",
                                                        }}>
                                                        <div>{t("Total Amount")}</div>
                                                        <div className='ms-1'>
                                                            <CurrencyCodeComponent
                                                                currencyCode={flight.currency}
                                                            />
                                                            {flight?.grossFare?.toFixed(2)}
                                                        </div>
                                                    </div>

                                                    <div
                                                        className='d-flex justify-content-between'
                                                    // style={{ fontSize: "9px" }}
                                                    >
                                                        <div>{t("Net Fare")}</div>
                                                        <div className='ms-1'>
                                                            <CurrencyCodeComponent
                                                                currencyCode={flight.currency}
                                                            />
                                                            {flight?.netfare?.toFixed(2)}
                                                        </div>
                                                    </div>
                                                </>
                                            }>
                                            <InfoOutlinedIcon />
                                        </BootstrapTooltip1>
                                    )}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <p
                        style={{
                            margin: "0px",
                            padding: "0px 10px",
                            width: "40vw",
                            color: "red",
                        }}>
                        {t("* The fares shown above are for one pax")}{" "}
                    </p>
                </Table>
            ) : (
                ""
            )}
        </div>
    );
}
