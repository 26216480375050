import React, { useEffect, useRef, useState } from "react";
import { checkIfUserStaff } from "../../services/AuthService";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import RiseLoading from "../Loader/RiseLoader";
import Button from "@mui/joy/Button";
import findAirlinebyCode from "../../functions/findAirlineByCode";
import findAirportbyCode from "../../functions/findAirportByCode";
import getAirportByCode from "../../functions/getAirportByCode";
import Divider from "@mui/joy/Divider";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@material-ui/core/Grid";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import moment from "moment";
import { Table } from "@mui/joy";
import { Row } from "react-bootstrap";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import axios from "axios";
import SupplierFare from "../ViewBooking/SupplierFare";
import { loadCardDetails } from "../../functions/loadCardDetails";
import getBrowserSignature from "../../functions/getBrowserSignature";
import UserAlerts from "../UserAlerts/UserAlerts"
import { Editor, EditorState, ContentState, convertFromHTML } from "draft-js";
import 'draft-js/dist/Draft.css';
import { stateToHTML } from 'draft-js-export-html';
import { Box, DialogActions } from "@mui/material";
import { useTranslation } from "react-i18next";
import FlightSegmentDetails from "../ViewBooking/FlightSegmentDetails";
import SearchIcon from "@mui/icons-material/Search";
import ReissueCopyItinerary from "./ReissueCopyItinerary";
import CloseIcon from "@material-ui/icons/Close";
import ReissueSupplierFare from "./ReissueSupplierFare";
import ReissueClientFare from "./ReissueClientFare";
import FlightBaggageDetails from "../ImportBooking/FlightImportBooking/FlightBaggageDetails/FlightBaggageDetails";
import Autocomplete from "@mui/material/Autocomplete";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import endEmailMandatoryClients from "../../data/EndEmailMandatoryClients";
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import TextField from "@mui/material/TextField";
import { getStaffUsers } from "../../functions/getStaffUsers";
import loadFop from "../../functions/loadFop";

const ViewReissueDetails = () => {
    const [showLoader, setShowLoader] = useState(false);
    const [bookingDetails, setbookingDetails] = useState([]);
    const [historyDetail, setHistoryDetail] = useState([]);
    const [refundConfrimDialogOpen, setRefundConfrimDialogOpen] = useState(false);
    const [staffremarks, setstaffRemarks] = useState("");
    const [clientRefundAmount, setClientRefundAmount] = useState([]);
    const [errorMsgTkt, seterrorMsgTkt] = useState("");
    const [updateProcess, setupdateProcess] = useState(false);
    const [selectedcancelstatus, setCancelSelectedstatus] = useState("None");
    const [passengersDetails, setPassengersDetails] = useState({});
    const [totalClientNetRefund, setTotalClientNetRefund] = useState(false);
    const [pccList, setPccList] = useState([]);
    const [fopText, setFopText] = useState("");
    const [isSessionExpired, setIsSessionExpired] = useState(false);
    const [ticketModalopen, setTicketModalOpen] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [isEditorUpdated, setIsEditorUpdated] = useState(false);
    const [onwardSegments, setOnwardSegments] = useState([]);
    const [returnSegments, setReturnSegments] = useState([]);
    const [maxHeight, setMaxHeight] = useState(0);
    const [isShowExistingItinerary, setIsShowExistingItinerary] = useState(false);
    const [checkedRows, setCheckedRows] = useState({});
    const [action, setAction] = useState("");
    const [drawer, setDrawer] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [clientRoe, setClientRoe] = useState(1);
    const [markupRoe, setMarkupRoe] = useState(1);
    const [baggageDetails, setBaggageDetails] = useState([]);
    const [fop, setFOP] = useState("");
    const [fopOptions, setFopOption] = useState([]);
    const [cardNumber, setcardNumber] = useState(null);
    const [expiryDate, setexpiryDate] = useState(null);
    const [caseID, setCaseID] = useState("");
    const [salesStaff, setsalesStaff] = useState("");
    const [userProfiles, setuserProfiles] = useState(null);
    const [clientId, setClientId] = useState("");
    const [endClientName, setEndClientName] = useState("");
    const [fema, setfema] = useState("");
    const [endClientEmail, setEndClientEmail] = useState("");
    const [pgCharge, setPGCharge] = useState("");
    const [mco, setMCO] = useState("");
    const [mcoParted, setMCOParted] = useState("");
    const [mcoRemark, setMCORemark] = useState("");
    const sourceList = ["Email", "Call", "WhatsApp"];
    const [source, setSource] = useState("");
    const femaList = ["Corporate", "Retailer"];
    const [panCard, setpanCard] = useState("");
    const [importPNRRemarks, setimportPNRRemarks] = useState("");
    const [showWarning, setShowWarning] = useState(false);
    const [reissueInputUpdates, setReissueInputUpdates] = useState([]);
    const initialContentStateRef = useRef(null);
    const editorRef = useRef(null);
    const { t } = useTranslation();
    const refId = sessionStorage.getItem('cancelRefId');
    const orderRefId = sessionStorage.getItem('reissueRefId');
    const [updatedTicketNumbers, setUpdatedTicketNumbers] = useState({});
    const [segmentData, setSegmentData] = useState([]);
    const AuthorizedUsers = [
        "hafsa.q@akbaroffshore.com",
        "swapnil@akbaroffshore.com",
        "rupali.j@akbaroffshore.com",
        "shalini@akbaroffshore.com",
        "amit.g@akbaroffshore.com",
        "accounts1@akbaroffshore.com",
        "vijeesh.c@akbaroffshore.com",
        "nitin.d@akbaroffshore.com",
        "jagruti@akbaroffshore.com",
        "accounts.audit@akbaroffshore.com",
        "ashna@akbaroffshore.com"
    ];
    const user = JSON.parse(localStorage.getItem("user"));
    let OrgPccList = [];

    const handleStatusChange = (event, newValue) => {
        setCancelSelectedstatus(newValue);
    };

    const calculateFields = (input, bookingDetails) => {
        const baseFare = parseFloat(input.baseFare || 0);
        const tax = parseFloat(input.tax || 0);
        const dateChangeFee = parseFloat(input.dateChangeFee || 0);
        const vendorFee = parseFloat(input.vendorIssuanceFee || 0);
        const vendorTax = parseFloat(input.vendorTax || 0);
        const baggage = parseFloat(input.baggagefare || 0);
        const markup = parseFloat(input.markup || 0);
        const gfsServiceFee = parseFloat(input.gfsServiceFee || 0);
        const markupRoe = bookingDetails.markupRoe || 1;

        return {
            ...input,
            supplierTotal: baseFare + tax + dateChangeFee + vendorFee + vendorTax + baggage,
            clientBaseFare: (baseFare + vendorFee + vendorTax) * clientRoe + markup * markupRoe,
            clientTax: tax * clientRoe,
            clientDateChangeFee: dateChangeFee * clientRoe,
            clientBaggage: baggage * clientRoe,
            clientGfsServiceFee: gfsServiceFee,
            clientTotal: ((baseFare + tax + dateChangeFee + vendorFee + vendorTax + baggage) * clientRoe) + (markup * markupRoe) + gfsServiceFee,
        };
    };

    const handleInputChange = (index, field, value) => {

        const updatedInputs = reissueInputUpdates.map((input, i) => {
            if (i === index) {
                const updatedInput = { ...input, [field]: value };
                const calculatedFields = calculateFields(updatedInput, bookingDetails);

                return { ...updatedInput, ...calculatedFields };
            }
            return input;
        });

        setReissueInputUpdates(updatedInputs);
    };




    const handleChange = (newEditorState) => {
        const currentContent = newEditorState.getCurrentContent();

        const isContentUpdated =
            currentContent !== initialContentStateRef.current;

        const isContentNotEmpty = currentContent.hasText();

        if (isContentUpdated && isContentNotEmpty) {
            setIsEditorUpdated(true);
        } else if (!isContentNotEmpty) {
            setIsEditorUpdated(false);
        } else {
            setIsEditorUpdated(false);
        }

        setEditorState(newEditorState);
    };

    const handleRemarkChange = (event) => {
        const value = event.target.value;
        const validCharacters = /^[a-zA-Z0-9*/= ]*$/;

        if (validCharacters.test(value)) {
            setimportPNRRemarks(value);
            setShowWarning(false);
        } else {
            // Remove invalid characters and set the cleaned value
            const cleanedValue = value.replace(/[^a-zA-Z0-9*/= ]/g, "");
            setimportPNRRemarks(cleanedValue);
            setShowWarning(true);
        }
    };



    const prepareSegGroups = () => {
        return bookingDetails.segGroups.map(segGroup => {
            const updatedSegments = segGroup.segments.map(segment => {
                const updatedSegment = segmentData
                    .flatMap(data => data.segments)
                    .find(seg => seg.id === segment.id);

                return updatedSegment
                    ? {
                        Id: segment.id,
                        IsReturn: updatedSegment.isReturn || false,
                        Origin: updatedSegment.origin,
                        Destination: updatedSegment.destination,
                        DepartureOn: updatedSegment.departureOn,
                        ArrivalOn: updatedSegment.arrivalOn,
                        Duration: updatedSegment.duration || 0,
                        FlightNum: updatedSegment.flightNum,
                        EqpType: updatedSegment.eqpType || "",
                        MrkAirline: updatedSegment.mrkAirline,
                        DepTerminal: updatedSegment.depTerminal || "",
                        ArrTerminal: updatedSegment.arrTerminal || "",
                        OpAirline: updatedSegment.opAirline,
                        RBD: updatedSegment.rbd,
                        CabinClass: updatedSegment.cabinClass,
                        PNR: updatedSegment.pnr,
                        SupplierStatus: updatedSegment.flightTicketStatus || "None",
                    }
                    : segment;
            });

            return {
                Origin: segGroup.origin,
                Destination: segGroup.destination,
                DepartureOn: segGroup.departureOn,
                Segments: updatedSegments,
            };
        });
    };



    const generatePassengerDetails = () => {
        return bookingDetails.passengerDetails.map((passenger) => {
            const updatedTicketNo = updatedTicketNumbers[passenger.Id];

            return {
                Id: passenger.Id,
                TicketNo: updatedTicketNo || passenger.ticketNumber,
                paxType: passenger.paxType,
            };
        });
    };

    const generateBaggage = () => {
        return baggageDetails.map((item) => ({
            paxType: item.paxType,
            PaxName: item.paxName,
            airline: item.airline.Code,
            checkInBag: `${item.checkInBag}N`,
            CabinBag: `${item.cabinBag}pc`,
            cityPair: item.cityPair,
            amount: item.amount,
        }));
    };


    const generateSupplierFareInput = () => {
        return reissueInputUpdates.map((item) => ({
            PaxType: item.paxType,
            BaseFare: item.baseFare,
            Tax: item.tax,
            DateChangeFee: item.dateChangeFee,
            VendorFee: item.vendorIssuanceFee,
            VendorTax: item.vendorTax,
            BaggageFee: item.baggagefare,
            Markup: item.markup,
            GFSServiceFee: item.gfsServiceFee,
            SupplierFare: (
                item.baseFare +
                item.tax +
                item.dateChangeFee +
                item.vendorIssuanceFee +
                item.vendorTax +
                item.baggagefare +
                item.markup +
                item.gfsServiceFee
            ).toFixed(2),
        }));
    };

    const generateClientFare = () => {
        return reissueInputUpdates.map((item) => ({
            PaxType: item.paxType,
            BaseFare: item.baseFare,
            Taxes: item.tax,
            DateChangeFee: item.dateChangeFee,
            ClientFare: (
                item.baseFare +
                item.tax +
                item.dateChangeFee +
                item.baggagefare
            ).toFixed(2),
            BaggageFare: item.baggagefare,
        }));
    };


    const reissueConfirm = async () => {
        setRefundConfrimDialogOpen(false);
        seterrorMsgTkt("");
        setupdateProcess(true);
        setShowLoader(true);
        const signature = getBrowserSignature();

        const ticketContentState = editorState.getCurrentContent();
        let tickeHtml = stateToHTML(ticketContentState);

        const headers = {
            "Content-Type": "application/json",
        };

        const updatedSegGroups = prepareSegGroups();
        const updatedPassengerDetails = generatePassengerDetails();
        const updatedSupplierFareInput = generateSupplierFareInput();
        const updatedClientFareInput = generateClientFare();
        const updatedBaggageInput = generateBaggage();

        await axios
            .post(
                process.env.REACT_APP_CORE_URL +
                "Flights/Booking/Reissue/v1/ReissueUpdateRequest",
                JSON.stringify({
                    OrderRefGFRId: bookingDetails.orderRefId,
                    TraceId: bookingDetails.traceId,
                    ClientId: bookingDetails.clientId,
                    AdultCount: bookingDetails.AdtCount,
                    ChildCount: bookingDetails.ChdCount,
                    InfantCount: bookingDetails.InfCount,
                    PNR: bookingDetails.pnr,
                    FOP: bookingDetails.supplierFare[0].fop,
                    ClientROE: clientRoe,
                    Status: selectedcancelstatus,
                    CaseID: caseID,
                    SalesStaff: salesStaff,
                    ClientEmail: endClientEmail,
                    ClientName: endClientName,
                    PGCharge: pgCharge,
                    MCO: mco,
                    MCOParted: mcoParted,
                    MCORemark: mcoRemark,
                    IsCorporate: fema === "Corporate" ? true : false,
                    PANCard: panCard,
                    Ticketimage: tickeHtml,
                    Remarks: importPNRRemarks,
                    SegGroups: updatedSegGroups,
                    passengers: updatedPassengerDetails,
                    Baggage: updatedBaggageInput,
                    SupplierFareInput: updatedSupplierFareInput,
                    ClientFare: updatedClientFareInput
                }),
                {
                    headers,
                }
            )
            .then((response) => {
                setupdateProcess(false);
                loadBookingDetails(orderRefId);
            })
            .catch((err) => {
                if (err.response.data.toLowerCase().includes("unauthorized browser")) {
                    setIsSessionExpired(true);
                }
                setupdateProcess(false);
                setShowLoader(false);
                //(err.response.data.detail);
                console.log(err);
            });
    };

    const cancel = () => {
        setRefundConfrimDialogOpen(false);
    };
    const handleReissueSubmit = (event) => {
        setRefundConfrimDialogOpen(true);
    };

    const getDuration = (duration) => {
        return `${String(Math.floor(duration / 60)).padStart(2, "0")} h ${String(
            duration % 60
        ).padStart(2, "0")} m`;
    };

    useEffect(() => {
        const fetchData = async () => {

            loadCreditCards();
            loadBookingDetails(orderRefId);
            if (checkIfUserStaff()) {
                loadPCCDetails();
            }
            const staffUsers = await getStaffUsers();
            setuserProfiles(staffUsers);
        };
        fetchData();
    }, []);

    const loadCreditCards = async () => {
        var fop = await loadFop();
        setFopOption(fop);
    };

    async function loadBookingDetails(orderRefId) {
        setShowLoader(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_CORE_URL}Flights/Booking/Reissue/v1/GetReissueDetails/${orderRefId}`
            );

            const updatedInputs = response.data.supplierFareReissueInput.map((input) =>
                calculateFields(input, response.data)
            );

            setClientRoe(response.data.clientRoe);
            setMarkupRoe(response.data.markupRoe);
            setBaggageDetails(response.data.baggages)
            setReissueInputUpdates(updatedInputs);
            setbookingDetails(response.data);
            setShowLoader(false);
        } catch (err) {
            console.error(err);
            setShowLoader(false);
        } finally {
            setShowLoader(false);
        }
    }

    useEffect(() => {
        if (clientRoe && reissueInputUpdates.length > 0) {
            const updatedInputs = reissueInputUpdates.map((input) =>
                calculateFields(input, { ...bookingDetails, clientRoe, markupRoe })
            );
            setReissueInputUpdates(updatedInputs);
        }
    }, [clientRoe, markupRoe]);

    async function loadPCCDetails() {
        let vendorArray = [];
        await axios
            .get(process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor")
            .then((response) => {
                for (var i = 0; i < response.data.length; i++) {
                    vendorArray.push({
                        id: response.data[i].bookingSwitches.id,
                        name: response.data[i].bookingSwitches.vendorName,
                    });
                }
                axios
                    .get(process.env.REACT_APP_CORE_URL + "api/Configuration/PccConfig")
                    .then((response) => {
                        let tmpArray = [];
                        if (
                            bookingDetails &&
                            bookingDetails.flights &&
                            bookingDetails.flights[0]
                        ) {
                            OrgPccList = response.data.map((item) => {
                                return item.pccConfigs;
                            });
                            if (OrgPccList.length > 0) {
                                let tmp = OrgPccList.find(
                                    (item) => item.pccCode === bookingDetails?.flight[0]?.pcc
                                );
                            }
                        }
                        for (var i = 0; i < response.data.length; i++) {
                            let k = i;
                            if (
                                vendorArray.some(
                                    (item) => item.id === response.data[k].pccConfigs.vendorId
                                )
                            ) {
                                tmpArray.push({
                                    value:
                                        response.data[i].pccConfigs.pccCode +
                                        "," +
                                        response.data[i].pccConfigs.supplierName,
                                    label: response.data[i].pccConfigs.pccCode,
                                    label:
                                        response.data[i].pccConfigs.pccCode +
                                        " (" +
                                        vendorArray.find(
                                            (st) => st.id === response.data[i].pccConfigs.vendorId
                                        ).name +
                                        ")",
                                });
                            } else {
                                tmpArray.push({
                                    value:
                                        response.data[i].pccConfigs.pccCode +
                                        "," +
                                        response.data[i].pccConfigs.supplierName,
                                    label: response.data[i].pccConfigs.pccCode,
                                    label: response.data[i].pccConfigs.pccCode,
                                });
                            }
                        }
                        setPccList(tmpArray);
                    });
            });
    }

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.scrollTop = editorRef.current.scrollHeight;
        }
    }, [editorState]);

    const handleEditSegment = (index, type) => {
        setAction("edit");
        setDrawer(true);
        setSelectedIndex((selectedIndex) => index);
    };

    const handleRemoveSegment = (segmentIndex, isReturnSegment) => {
        const segments = isReturnSegment
            ? [...returnSegments]
            : [...onwardSegments];
        segments.splice(segmentIndex, 1);

        isReturnSegment ? setReturnSegments(segments) : setOnwardSegments(segments);
    };

    useEffect(() => {
        const baggageByPaxType = {
            ADT: 0,
            CHD: 0,
            INF: 0,
        };

        const baggageCount = {
            ADT: 0,
            CHD: 0,
            INF: 0,
        };

        // Aggregate baggage amounts by paxType
        baggageDetails.forEach((baggage) => {
            const amount = parseFloat(baggage.amount) || 0;
            baggageByPaxType[baggage.paxType] += amount;
            baggageCount[baggage.paxType]++;
        });

        const averages = {
            ADT: baggageCount.ADT > 0 ? baggageByPaxType.ADT / baggageCount.ADT : 0,
            CHD: baggageCount.CHD > 0 ? baggageByPaxType.CHD / baggageCount.CHD : 0,
            INF: baggageCount.INF > 0 ? baggageByPaxType.INF / baggageCount.INF : 0,
        };

        // Update reissueInputUpdates based on baggage fare averages
        const updatedInputs = reissueInputUpdates.map((input) => {
            const baggagefare = averages[input.paxType] || 0;
            const updatedInput = { ...input, baggagefare };
            return calculateFields(updatedInput, bookingDetails);
        });

        setReissueInputUpdates(updatedInputs);
    }, [baggageDetails]);






    const handleTicketNumberChange = (passengerId, ticketNumber) => {
        setUpdatedTicketNumbers((prevState) => ({
            ...prevState,
            [passengerId]: ticketNumber,
        }));
    };


    return (
        <div>
            <React.Fragment>
                <Modal
                    open={refundConfrimDialogOpen}
                    onClose={() => {
                        setRefundConfrimDialogOpen(false);
                    }}>
                    <ModalDialog
                        variant='outlined'
                        role='alertdialog'
                        className='textCenter pad1vw'>
                        <ModalClose variant='plain' sx={{ m: 0 }} />
                        <Divider />
                        <DialogContent>
                            <span className='colorBlack'>
                                <h5>{t("Would You Like to Proceed ?")}</h5>
                                <h5>
                                    {t("Please reverify the updated value to ensure the correct refund amount")}
                                </h5>
                            </span>
                        </DialogContent>
                        <div className='double-btn'>
                            <div>
                                <Button
                                    variant='contained'
                                    className='cancelref-btn'
                                    onClick={cancel}>
                                    {t("Cancel")}
                                </Button>
                            </div>
                            <div>
                                <Button
                                    variant='contained'
                                    className='refund-btn'
                                    onClick={reissueConfirm}>
                                    {t("Submit")}
                                </Button>
                            </div>
                        </div>
                    </ModalDialog>
                </Modal>
            </React.Fragment>
            <div>
                {showLoader ? (
                    <RiseLoading isviewBooking={showLoader} />
                ) : (
                    <>
                        <Grid container className='viewBooking'>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={10}>
                                {bookingDetails && (
                                    <div className='itenary-container-header'>
                                        <div
                                            className='row mb-3 mt-3'
                                            style={{
                                                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                                            }}>
                                            <div className='col-6'>{t("Flight Itinerary")}</div>
                                        </div>
                                        <div className='d-flex flight-itenary-details-item-text justify-content-between align-items-center'>
                                            <div className='current-div1' style={{ minWidth: "300px" }}>
                                                {checkIfUserStaff() && (
                                                    <>
                                                        <div className='vb-highlight-txt pt-2'>
                                                            {t("Client Name")}: {bookingDetails.clientname}
                                                        </div>
                                                        <div className='vb-highlight-txt mb-3'>
                                                            {bookingDetails.clientEmail}
                                                        </div>
                                                    </>
                                                )}
                                                <div
                                                    className={`itenary-confirmtaion-tag ${bookingDetails?.flightBookingStatus}`}>
                                                    {bookingDetails?.flightBookingStatus}
                                                </div>
                                                <div className='pt-2 text-bold'>{bookingDetails?.orderRefId}</div>
                                            </div>
                                            <div className='vb-highlight-txt pt-2' style={{ minWidth: "300px" }}>
                                                {checkIfUserStaff() && (
                                                    <div className='' style={{ textAlign: "center" }}>
                                                        <p style={{ paddingTop: "10px" }}>
                                                            <span>{t("Booking Source")}: </span>
                                                            <span>
                                                                {bookingDetails.bookingSource &&
                                                                    bookingDetails.bookingSource}
                                                            </span>
                                                        </p>
                                                    </div>
                                                )}

                                                <div className='itenary-container-staff pt-2'>
                                                    <div className='flight-itenary-details-item '>
                                                        <div className='flight-itenary-details-item-text '>
                                                            {bookingDetails.parentOrderRefId}
                                                        </div>
                                                        <div className='flight-itenary-details-item-subtext pt-2'>
                                                            {bookingDetails.parentOrderRefId != ""
                                                                ? t("Reference No")
                                                                : ""}
                                                        </div>
                                                        {checkIfUserStaff() && (
                                                            <>
                                                                <div className='flight-itenary-details-item-subtext pt-2'>
                                                                    {bookingDetails.traceId}
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {checkIfUserStaff() && (
                                                <div class='text-center' style={{ minWidth: "300px" }}>
                                                    <div className='vb-highlight-txt pt-2'>
                                                        <br /> (
                                                        {bookingDetails.pcc &&
                                                            bookingDetails.pcc}
                                                        {" - "}
                                                        {pccList
                                                            .filter((id) =>
                                                                id.value.startsWith(
                                                                    bookingDetails.pcc + ","
                                                                )
                                                            )
                                                            .map(({ label }) => {
                                                                return label?.match(/\(([^)]+)\)/)?.length > 1
                                                                    ? label?.match(/\(([^)]+)\)/)[1]
                                                                    : label?.match(/\(([^)]+)\)/)?.length === 1
                                                                        ? label?.match(/\(([^)]+)\)/)[0]
                                                                        : "";
                                                            })}
                                                        )
                                                    </div>

                                                    {checkIfUserStaff() && (
                                                        <div className='flight-itenary-details-item'>
                                                            <div className='flight-itenary-details-item-text pt-2'>
                                                                {bookingDetails.pnr}
                                                            </div>
                                                            <div className='flight-itenary-details-item-subtext'>
                                                                {bookingDetails.pnr
                                                                    ? t("PNR/Booking Reference")
                                                                    : ""}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {bookingDetails &&
                                    bookingDetails.segGroups &&
                                    bookingDetails.segGroups.length > 0 ? (
                                    <div>
                                        {
                                            <FlightSegmentDetails
                                                t={t}
                                                flight={bookingDetails}
                                                setMaxHeight={setMaxHeight}
                                                flag="reissue"
                                            />
                                        }
                                    </div>


                                ) : (
                                    <>
                                        <div className="no-results-container">
                                            <div className="no-results-icon">
                                                <SearchIcon fontSize="large"></SearchIcon>
                                            </div>
                                            <div className="no-results-text">{t("No Segment Details Found")}</div>
                                        </div>
                                    </>
                                )
                                }

                                {bookingDetails &&
                                    bookingDetails?.passengerDetails && (
                                        <>
                                            <div className='itenary-container booking-padding-top-40 mt-5'>
                                                <div>{t("Passenger Details")}</div>
                                            </div>
                                            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead className='mui-table-header'>
                                                            <TableCell className="text-center">{t("Passenger(s)")}</TableCell>
                                                            <TableCell className="text-center">{t("Gender")}</TableCell>
                                                            <TableCell className="text-center">{t("Date of Birth")}</TableCell>
                                                            <TableCell className="text-center">{t("Ticket(s)")}</TableCell>
                                                            <TableCell className="text-center">{t("Passport No")}</TableCell>
                                                            <TableCell className="text-center">{t("Seat")}</TableCell>
                                                            <TableCell className="text-center">{t("Status")}</TableCell>
                                                        </TableHead>
                                                        <TableBody>
                                                            {bookingDetails?.passengerDetails
                                                                .sort(function (a, b) {
                                                                    if (a.paxType < b.paxType) {
                                                                        return -1;
                                                                    }
                                                                    if (a.paxType > b.paxType) {
                                                                        return 1;
                                                                    }
                                                                    return 0;
                                                                })
                                                                .map((passenger) => (
                                                                    <TableRow
                                                                        hover
                                                                        role='checkbox'
                                                                        tabIndex={-1}>
                                                                        <TableCell align='center'>
                                                                            {passenger.title}{" "}
                                                                            {passenger.firstName}{" "}
                                                                            {passenger.lastName}
                                                                        </TableCell>
                                                                        <TableCell align='center'>
                                                                            {passenger.genderType}
                                                                        </TableCell>
                                                                        <TableCell align='center'>
                                                                            {moment(passenger.dob).format(
                                                                                "Do MMM YYYY"
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell align='center'>
                                                                            <TextField
                                                                                value={
                                                                                    updatedTicketNumbers[passenger.id] !== undefined
                                                                                        ? updatedTicketNumbers[passenger.id]
                                                                                        : passenger.ticketNumber
                                                                                }
                                                                                onChange={(e) =>
                                                                                    handleTicketNumberChange(passenger.id, e.target.value)
                                                                                }
                                                                                variant="outlined"
                                                                                size="small"
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell align='center'>
                                                                            {passenger.passportNumber == ""
                                                                                ? `NA`
                                                                                : passenger.passportNumber}
                                                                        </TableCell>
                                                                        <TableCell align='center'>
                                                                            {passenger.seatPref === ""
                                                                                ? `NA`
                                                                                : passenger.seatPref}
                                                                        </TableCell>
                                                                        <TableCell align='center'>
                                                                            <span
                                                                                style={{
                                                                                    color: "red",
                                                                                    fontSize: "15px",
                                                                                    fontWeight: "300",
                                                                                }}>
                                                                                {!checkIfUserStaff() &&
                                                                                    passenger.flightTicketStatus ===
                                                                                    "Refund_Initiated"
                                                                                    ? t("Cancelled")
                                                                                    : !checkIfUserStaff() &&
                                                                                        passenger.flightTicketStatus ===
                                                                                        "Void_Refunded"
                                                                                        ? t("Refunded")
                                                                                        : !checkIfUserStaff() &&
                                                                                            passenger.flightTicketStatus ===
                                                                                            "Voided"
                                                                                            ? t("Cancel_Requested")
                                                                                            : passenger.flightTicketStatus}
                                                                            </span>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </>

                                    )}

                                {bookingDetails &&
                                    <>
                                        <ReissueCopyItinerary
                                            setIsShowExistingItinerary={setIsShowExistingItinerary}
                                            checkedRows={checkedRows} setCheckedRows={setCheckedRows}
                                            flight={bookingDetails}
                                            handleEditSegment={handleEditSegment} handleRemoveSegment={handleRemoveSegment}
                                            action={action}
                                            segmentData={segmentData}
                                            setSegmentData={setSegmentData}
                                        />

                                        {checkIfUserStaff() && bookingDetails.supplierFare ? (

                                            <SupplierFare
                                                flight={bookingDetails}
                                                fopText={fopText}
                                                fop={bookingDetails && bookingDetails?.supplierFare && bookingDetails?.supplierFare[0] && bookingDetails?.supplierFare[0]?.fop ? bookingDetails?.supplierFare[0]?.fop : ''}
                                                flag="reissue"
                                            />
                                        ) : ''}

                                        {bookingDetails?.segGroups &&
                                            <FlightBaggageDetails segGroups={bookingDetails && bookingDetails?.segGroups}
                                                baggageDetails={baggageDetails} setBaggageDetails={setBaggageDetails}
                                                passengers={bookingDetails && bookingDetails?.passengerDetails}
                                                flight={bookingDetails} flag="reissue" />}

                                        {checkIfUserStaff() && (
                                            <ReissueSupplierFare bookingDetails={bookingDetails && bookingDetails} t={t}
                                                passengersDetails={passengersDetails} handleInputChange={handleInputChange} AuthorizedUsers={AuthorizedUsers}
                                                reissueInputUpdates={reissueInputUpdates} />
                                        )}

                                        <ReissueClientFare bookingDetails={bookingDetails && bookingDetails} t={t} passengersDetails={passengersDetails}
                                            reissueInputUpdates={reissueInputUpdates} />
                                    </>
                                }

                                <div className="import-booking-mco mco-dtls-bg mb-4">
                                    <ValidatorForm>
                                        <Row className="mx-1">
                                            <div
                                                className="col-md-3 FOP"
                                                style={{
                                                    marginTop: "10px",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                <Autocomplete
                                                    id="FOP-select"
                                                    variant="outlined"
                                                    value={fop != ""
                                                        ? fopOptions.find((v) => v.id === fop)
                                                        : null}
                                                    onChange={(event, newValue) => {
                                                        setFOP(newValue ? newValue.id : "");
                                                    }}
                                                    options={fopOptions}
                                                    getOptionLabel={(option) => option.des}
                                                    renderInput={(params) => (
                                                        <TextValidator
                                                            {...params}
                                                            label="FOP"
                                                            required
                                                            value={fop}
                                                            variant="filled"
                                                            color="secondary"
                                                            size="small"
                                                            validators={["required"]}
                                                            errorMessages={["required"]}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }} />
                                                    )} />
                                            </div>
                                            {fop == "Passenger Card" && (
                                                <div
                                                    className="col-md-3"
                                                    style={{
                                                        marginTop: "10px",
                                                        marginBottom: "15px",
                                                    }}
                                                >
                                                    <TextValidator
                                                        id="outlined-clientROE-input"
                                                        required={fop == "Passenger Card" ? ["required"] : false}
                                                        label="Card number"
                                                        variant="outlined"
                                                        size="small"
                                                        color="secondary"
                                                        value={cardNumber}
                                                        onChange={(event) => setcardNumber(event.target.value)}
                                                        validators={["required"]}
                                                        errorMessages={["required"]}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }} />
                                                </div>
                                            )}
                                            {fop == "Passenger Card" && (
                                                <div
                                                    className="col-md-3"
                                                    style={{
                                                        marginTop: "10px",
                                                        marginBottom: "15px",
                                                    }}
                                                >
                                                    <TextValidator
                                                        id="outlined-clientROE-input"
                                                        required={fop == "Passenger Card" ? ["required"] : false}
                                                        label="Expiry Date"
                                                        variant="outlined"
                                                        size="small"
                                                        color="secondary"
                                                        value={expiryDate}
                                                        onChange={(event) => setexpiryDate(event.target.value)}
                                                        validators={["required"]}
                                                        errorMessages={["required"]}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }} />
                                                </div>
                                            )}
                                            <div
                                                className="col-md-3"
                                                style={{
                                                    marginTop: "10px",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                <TextValidator
                                                    id="outlined-clientROE-input"
                                                    label="Client ROE"
                                                    required
                                                    variant="outlined"
                                                    size="small"
                                                    color="secondary"
                                                    value={clientRoe}
                                                    onChange={(event) => {
                                                        setClientRoe((clientRoe) => event.target.value || 1);
                                                    }}
                                                    validators={["required"]}
                                                    errorMessages={["required"]}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }} />
                                            </div>
                                            <div
                                                className="col-md-3"
                                                style={{
                                                    marginTop: "10px",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                <TextValidator
                                                    id="outlined-clientROE-input"
                                                    label="Case ID"
                                                    variant="outlined"
                                                    size="small"
                                                    required
                                                    color="secondary"
                                                    value={caseID}
                                                    onChange={(event) => setCaseID(event.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    validators={["required", 'matchRegexp:^[^\\s]+(\\s+[^\\s]+)*$']}
                                                    errorMessages={["This field is required", "This field cannot be blank or contain only spaces"]} />
                                            </div>
                                            <div
                                                className="col-md-3 salesStaff"
                                                style={{
                                                    marginTop: "10px",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                <Autocomplete
                                                    id="FOP-select"
                                                    variant="outlined"
                                                    value={salesStaff !== undefined &&
                                                        userProfiles?.find((el) => el.id === salesStaff)}
                                                    onChange={(event, newValue) => {
                                                        setsalesStaff(newValue ? newValue.id : "");
                                                    }}
                                                    options={userProfiles}
                                                    getOptionLabel={(option) => option.title +
                                                        "." +
                                                        option.firstName +
                                                        " " +
                                                        option.lastName}
                                                    renderInput={(params) => (
                                                        <TextValidator
                                                            {...params}
                                                            label="Sales Staff"
                                                            required
                                                            // salesStaff !== undefined ? userProfiles.find((v) => v.id == salesStaff) : ""
                                                            value={salesStaff}
                                                            variant="filled"
                                                            color="secondary"
                                                            size="small"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            validators={["required"]}
                                                            errorMessages={["required"]} />
                                                    )} />
                                            </div>
                                            <div
                                                className="col-md-3"
                                                style={{
                                                    marginTop: "10px",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                <TextValidator
                                                    required={endEmailMandatoryClients.includes(clientId.toString())}
                                                    id="outlined-clientROE-input"
                                                    label="End Client Name"
                                                    variant="outlined"
                                                    size="small"
                                                    color="secondary"
                                                    value={endClientName}
                                                    onChange={(event) => setEndClientName(event.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }} />
                                            </div>
                                            <div
                                                className="col-md-3"
                                                style={{
                                                    marginTop: "10px",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                <TextValidator
                                                    // required={
                                                    //   endEmailMandatoryClients.includes(clientId.toString())
                                                    // }
                                                    required
                                                    id="outlined-clientROE-input"
                                                    label="END Client Email / Akbar Online OD"
                                                    variant="outlined"
                                                    size="small"
                                                    color="secondary"
                                                    value={endClientEmail}
                                                    onChange={(event) => setEndClientEmail(event.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    validators={["required", 'matchRegexp:^[^\\s]+(\\s+[^\\s]+)*$']}
                                                    errorMessages={["This field is required", "This field cannot be blank or contain only spaces"]} />
                                            </div>
                                            <div
                                                className="col-md-3 fema"
                                                style={{
                                                    marginTop: "10px",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                <Autocomplete
                                                    id="FOP-select"
                                                    variant="outlined"
                                                    value={fema && femaList.find((el) => el === fema)}
                                                    onChange={(event, newValue) => {
                                                        setfema(newValue ? newValue : "");
                                                    }}
                                                    options={femaList}
                                                    getOptionLabel={(option) => option}
                                                    renderInput={(params) => (
                                                        <TextValidator
                                                            {...params}
                                                            label="Corporate/Retailer"
                                                            value={fema}
                                                            variant="filled"
                                                            color="secondary"
                                                            size="small"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }} />
                                                    )} />
                                            </div>
                                            <div
                                                className="col-md-3"
                                                style={{
                                                    marginTop: "10px",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                <TextValidator
                                                    required={fema === "Retailer" ? ["required"] : false}
                                                    id="outlined-clientROE-input"
                                                    label="Pan Card"
                                                    variant="outlined"
                                                    size="small"
                                                    color="secondary"
                                                    value={panCard}
                                                    onChange={(event) => setpanCard(event.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    validators={[
                                                        "matchRegexp:^[A-Z]{5}[0-9]{4}[A-Z]{1}$",
                                                    ]}
                                                    errorMessages={[
                                                        "Not in valid format - 1st 5 Letters in CAPS, then Next 4 Letters in Numbers & Last Letter in CAPS",
                                                    ]} />
                                            </div>

                                            {/* MCO fields */}

                                            <div
                                                className="col-md-3"
                                                style={{
                                                    marginTop: "10px",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                <TextValidator
                                                    id="outlined-mco-input"
                                                    label="MCO Number"
                                                    type="number"
                                                    variant="outlined"
                                                    size="small"
                                                    color="secondary"
                                                    value={mco}
                                                    onChange={(event) => setMCO(event.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }} />

                                            </div>
                                            <div
                                                className="col-md-3"
                                                style={{
                                                    marginTop: "10px",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                <TextValidator
                                                    id="outlined-pgcharge-input"
                                                    label="MCO Charge"
                                                    type="number"
                                                    variant="outlined"
                                                    size="small"
                                                    color="secondary"
                                                    value={pgCharge}
                                                    onChange={(event) => setPGCharge(event.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }} />

                                            </div>
                                            <div
                                                className="col-md-3 fema"
                                                style={{
                                                    marginTop: "10px",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                <TextValidator
                                                    id="outlined-mcoParted-input"
                                                    label="MCO Parting"
                                                    type="number"
                                                    variant="outlined"
                                                    size="small"
                                                    color="secondary"
                                                    value={mcoParted}
                                                    onChange={(event) => setMCOParted(event.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }} />

                                            </div>
                                            <div
                                                className="col-md-3"
                                                style={{
                                                    marginTop: "10px",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                <TextValidator
                                                    id="outlined-mcoRemark-input"
                                                    label="MCO Remark"
                                                    type="text"
                                                    variant="outlined"
                                                    size="small"
                                                    color="secondary"
                                                    value={mcoRemark}
                                                    onChange={(event) => setMCORemark(event.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }} />
                                            </div>

                                            {/* MCO fields */}
                                            {/* <div
                                                className="col-md-3"
                                                style={{
                                                    marginTop: "10px",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                <Autocomplete
                                                    id="FOP-select"
                                                    variant="outlined"
                                                    value={source && sourceList.find((el) => el === source)}
                                                    onChange={(event, newValue) => {
                                                        setSource(newValue ? newValue : "");
                                                    }}
                                                    options={sourceList}
                                                    getOptionLabel={(option) => option}
                                                    renderInput={(params) => (
                                                        <TextValidator
                                                            {...params}
                                                            label="Source of Booking"
                                                            required
                                                            value={source}
                                                            variant="filled"
                                                            color="secondary"
                                                            size="small"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            validators={["required"]}
                                                            errorMessages={["required"]}
                                                        />
                                                    )}
                                                />
                                            </div> */}
                                            <div
                                                className="col-md-3"
                                                style={{
                                                    marginTop: "10px",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    className="addButton"
                                                    color="primary"
                                                    startIcon={<ContentPasteGoIcon />}
                                                    onClick={() => {
                                                        setTicketModalOpen(true);
                                                    }}
                                                    style={{ marginRight: "10px" }}
                                                >
                                                    Paste Ticket
                                                </Button>
                                                {(editorState.getCurrentContent().getPlainText()) && <p className="error mt-1">Ticket image pasted successfully.</p>}
                                            </div>
                                        </Row>
                                        <div className="import-booking-remarks">
                                            <Box
                                                component="form"
                                                sx={{
                                                    "& > :not(style)": { m: 1 },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <TextField
                                                    id="outlined-basic"
                                                    label="Remarks"
                                                    multiline
                                                    maxRows={4}
                                                    placeholder="Max 300 Characters"
                                                    sx={{
                                                        whiteSpace: "pre-wrap",
                                                        maxWidth: "98.5%",
                                                        minHeight: "100px !important",
                                                    }}
                                                    variant="outlined"
                                                    value={importPNRRemarks}
                                                    inputProps={{ maxLength: 300 }}
                                                    onChange={handleRemarkChange}
                                                />
                                                {showWarning ? (
                                                    <p style={{ color: "red" }}>Invalid characters removed. Only alphanumeric, *, /, =, and spaces are allowed.</p>
                                                ) :
                                                    <p style={{ color: "red", fontSize: "14px" }}>
                                                        Note: Only the Special Characters *  /  = are allowed.
                                                    </p>
                                                }
                                            </Box>
                                        </div>
                                        {checkIfUserStaff() &&
                                            historyDetail &&
                                            historyDetail.length > 0 ? (
                                            <>
                                                <div className='itenary-container invoice-itenary-container booking-padding-top-64 mt-5'>
                                                    <div>{t("History Details")}</div>
                                                </div>
                                                <div className='itenary-passenger-details'>
                                                    <table className='textCenter  itenary-passenger-table'>
                                                        <tr className='itenary-passenger-table-header'>
                                                            <th
                                                                align='left'
                                                                className='width20  itenary-passenger-table-header-pl-12 header-item-font-weight s-no itenary-passenger-view-table-header-item-initial '>
                                                                {t("Booking Status")}
                                                            </th>
                                                            <th
                                                                align='left'
                                                                className='width15 itenary-passenger-table-header-pl-12 header-item-font-weight'>
                                                                {t("Actioned On")}
                                                            </th>
                                                            <th
                                                                align='left'
                                                                className='width15 itenary-passenger-table-header-pl-12 header-item-font-weight'>
                                                                {t("Actioned By")}
                                                            </th>
                                                            <th
                                                                align='left'
                                                                className='width20  itenary-passenger-table-header-pl-12 header-item-font-weight s-no itenary-passenger-view-table-header-item-final '>
                                                                {t("Remarks")}
                                                            </th>
                                                        </tr>
                                                        {historyDetail.map((item) => (
                                                            <tr className='itenary-passenger-table-item  price-row'>
                                                                <td className='width10  itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12'>
                                                                    {item.status}
                                                                </td>
                                                                <td
                                                                    style={{ textAlign: "center !important" }}
                                                                    className='width15 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12'>
                                                                    {item.actionedOn}
                                                                </td>
                                                                <td className='width20 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12'>
                                                                    {item.actionBy}
                                                                </td>
                                                                <td className='width20 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12'>
                                                                    {item.remarks}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </table>

                                                </div>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {checkIfUserStaff() &&
                                            bookingDetails &&
                                            (bookingDetails?.flightBookingStatus === "ReIssue_Requested" ||
                                                bookingDetails?.flightBookingStatus === "ReIssued" ||
                                                bookingDetails?.flightBookingStatus === "ReIssue_Pending" ||
                                                bookingDetails?.flightBookingStatus === "ReIssue_Rejected") && (
                                                <div className="text-right">
                                                    <div className='master-btn'>
                                                        <div className='status-btn'>
                                                            <td colSpan='4' className='padLR2vw padTB1vw'>
                                                                <Select
                                                                    defaultValue='None'
                                                                    onChange={handleStatusChange}
                                                                    disabled={
                                                                        bookingDetails?.flightBookingStatus === "ReIssued" ||
                                                                        bookingDetails?.flightBookingStatus === "ReIssue_Rejected"
                                                                    }>
                                                                    <Option value='None'>{t("Status")}</Option>
                                                                    <Option value='ReIssue_Requested'>{t("Reissue Requested")}</Option>
                                                                    {AuthorizedUsers.includes(user["email_id"]) ==
                                                                        true && <Option value='ReIssued'>{t("Reissued")}</Option>}

                                                                    {bookingDetails &&
                                                                        (bookingDetails?.flightBookingStatus === "ReIssue_Requested") && (
                                                                            <>
                                                                                <Option value='ReIssue_Rejected'>{t("Reissue Rejected")}</Option>
                                                                                <Option value='ReIssue_Pending'>{t("Reissue Pending")}</Option>
                                                                            </>
                                                                        )}
                                                                </Select>
                                                            </td>
                                                        </div>
                                                        <div className='sub-btn'>
                                                            <Button
                                                                disabled={bookingDetails?.flightBookingStatus === "ReIssued" ||
                                                                    bookingDetails?.flightBookingStatus === "ReIssue_Rejected"}
                                                                variant='contained'
                                                                className='refsubmit-btn'
                                                                onClick={handleReissueSubmit}>
                                                                {t("Submit")}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                    </ValidatorForm>
                                </div>
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    </>
                )
                }
            </div >

            <Dialog
                open={ticketModalopen}
                onClose={() => {
                    setTicketModalOpen(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("Paste Ticket Image")}
                </DialogTitle>
                <DialogContent>
                    <div className="custom-editor" ref={editorRef}>
                        <Editor
                            editorState={editorState}
                            onChange={handleChange}
                        />
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog fullWidth maxWidth style={{ marginTop: "5%" }}
                open={isShowExistingItinerary}
                onClose={() => {
                    setIsShowExistingItinerary(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div className="d-flex justify-content-between">
                        <div>
                            Copy Segment From Existing
                        </div>
                        <div>
                            <CloseIcon
                                className="icon-fill pointer"
                                fontSize="medium"
                                onClick={() => setIsShowExistingItinerary(false)}
                            ></CloseIcon>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {bookingDetails &&
                        <div>
                            <FlightSegmentDetails
                                t={t}
                                flight={bookingDetails}
                                setMaxHeight={setMaxHeight}
                                flag="reissuecopy"
                                checkedRows={checkedRows}
                                setCheckedRows={setCheckedRows}
                            />
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button className="srn-btn" onClick={() => setIsShowExistingItinerary(false)}>Copy</Button>
                </DialogActions>
            </Dialog>
            {isSessionExpired && <UserAlerts isSessionExpired={isSessionExpired} />}
        </div >
    );
};

export default ViewReissueDetails