import React from "react";
import { List, Chip } from "@material-ui/core";
import CheckboxFacet from "./CheckboxFacet/CheckboxFacet";
import "./Facets.css";
import moment from "moment";
import { FilterTiltShift } from "@material-ui/icons";
import { ar } from "date-fns/locale";
import { useTranslation } from "react-i18next";

export default function Facets(props) {
  const { t } = useTranslation();
  function mapFacetName(facetName) {
    const capitalizeFirstLetter = (string) =>
      string[0] ? `${string[0].toUpperCase()}${string.substring(1)}` : "";
    facetName = facetName.trim();
    facetName = capitalizeFirstLetter(facetName);

    facetName = facetName.replace("_", " ");
    return t(facetName);
  }

  function addFilter(name, value) {
    const newFilters = props.filters.concat({ field: name, value: value });
    props.setFilters((filters) => newFilters);
    // props.setPageNav(false);
  }

  function removeFilter(filter) {
    if (filter.displayvalue instanceof Date) {
      const newFilters = props.filters.filter((item) => item.field == "Status");
      props.setDateFrom(undefined);
      props.setDateTo(undefined);
      props.setinputDateFrom(undefined);
      props.setinputDateTo(undefined);
      props.setFilters((filters) => newFilters);
    } else {
      const newFilters = props.filters.filter(
        (item) => item.value !== filter.value
      );
      props.setFilters((filters) => newFilters);
    }
    // props.setPageNav(false);
  }

  var facets;
  try {
    if (props.serviceType === 'Flight') {
      facets = Object.keys(props.facets).map((key) => {
        return (
          <CheckboxFacet
            key={key}
            name={key}
            values={props.facets[key]}
            addFilter={addFilter}
            removeFilter={removeFilter}
            mapFacetName={mapFacetName}
            selectedFacets={props.filters.filter((f) => f.field === key)}
          />
        );
      });
    } else if (props.serviceType === 'Rail') {
      facets = Object.keys(props.railFacets).map((key) => {
        return (
          <CheckboxFacet
            key={key}
            name={key}
            values={props.railFacets[key]}
            addFilter={addFilter}
            removeFilter={removeFilter}
            mapFacetName={mapFacetName}
            selectedFacets={props.filters.filter((f) => f.field === key)}
          />
        );
      });
    }

  } catch (error) {
    console.log(error);
  }

  const filters = props.filters
    ?.filter((x) => x.value !== "" && x.value !== undefined)
    .map((filter, index) => {
      return (
        <li key={index}>
          <Chip
            style={{ width: "100%" }}
            label={`${mapFacetName(filter.field)}: ${filter.displayvalue instanceof Date
              ? moment(filter.displayvalue).format("DD/MM/yyyy")
              : filter.value
              }`}
            onDelete={() => removeFilter(filter)}
            className="chip"
          />
        </li>
      );
    });

  return (
    <div id="facetPanel" className="box">
      <div className="facetbox">
        <div id="clearFilters">
          <ul className="filterlist">{filters}</ul>
        </div>
        {facets}
      </div>
    </div>
  );
}
