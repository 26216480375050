import React, { useState, useCallback } from "react";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import moment from "moment";
import "./CarouselCalenderRoundTrip.css";

const CarouselCalenderRoundTrip = ({ fareCalendar, search, setSearch, fetchCalenderResults }) => {
  const [toggleFlightDetails, setToggleFlightDetails] = useState(false);

  // const parseDate = (dateString) => {
  //   const formats = ["YYYY-MM-DDTHH:mm:ss", "YYYY-MM-DD", moment.ISO_8601];
  //   for (let format of formats) {
  //     const parsedDate = moment(dateString, format, true);
  //     if (parsedDate.isValid()) {
  //       return parsedDate;
  //     }
  //   }
  //   console.error("Invalid date format:", dateString);
  //   return null;
  // };

  const parseDate = (dateString) => {
    if (!dateString) return null; // Handle undefined/null input
    const formats = ["YYYY-MM-DDTHH:mm:ss", "YYYY-MM-DD", moment.ISO_8601];
    for (let format of formats) {
      const parsedDate = moment(dateString, format, true);
      if (parsedDate.isValid()) {
        return parsedDate;
      }
    }
    console.error("Invalid date format:", dateString);
    return null;
  };

  const seen = new Set();
  const uniqueFlights = fareCalendar.filter((flight) => {
    const departureDate = parseDate(flight.departureDateTime);
    const returnDate = parseDate(flight.returnDateTime);
    if (!departureDate || !returnDate) return false;

    const key = `${departureDate.format("YYYY-MM-DD")}-${returnDate.format(
      "YYYY-MM-DD"
    )}`;
    if (seen.has(key)) return false;
    seen.add(key);
    return true;
  });

  const departureMoment = parseDate(
    search?.originDestinations[0]?.departureDateTime
  );
  const returnMoment = parseDate(search?.originDestinations[0]?.returnDateTime);

  // if (!departureMoment || !returnMoment) {
  //   console.error("Invalid search dates");
  //   return <div>Error: Invalid search dates provided.</div>;
  // }

  // const generateDateRange = (baseDate) => {
  //   return Array.from({ length: 7 }, (_, i) => baseDate.clone().add(i - 3, "days"));
  // };

  // const generateDateRange = (baseDate) => {
  //   const today = moment().startOf("day");
  //   return Array.from({ length: 7 }, (_, i) =>
  //     baseDate.clone().add(i - 3, "days")
  //   ).filter((date) => date.isSameOrAfter(today));
  // };

  const generateDateRange = (baseDate) => {
    const today = moment().startOf("day");

    // Fallback if baseDate is null
    const validBaseDate = baseDate || today;

    return Array.from({ length: 7 }, (_, i) =>
      validBaseDate.clone().add(i - 3, "days")
    ).filter((date) => date.isSameOrAfter(today));
  };

  const horizontalDates = generateDateRange(departureMoment);
  const verticalDates = generateDateRange(returnMoment);

  const fareMap = uniqueFlights.reduce((map, entry) => {
    const departureDate = parseDate(entry.departureDateTime);
    const returnDate = parseDate(entry.returnDateTime);

    if (departureDate && returnDate) {
      const key = `${departureDate.format("YYYY-MM-DD")}-${returnDate.format(
        "YYYY-MM-DD"
      )}`;
      map[key] = entry.lowestFare.toFixed(2);
    }
    return map;
  }, {});

  const findFare = (departure, returnDate) => {
    const key = `${departure.format("YYYY-MM-DD")}-${returnDate.format(
      "YYYY-MM-DD"
    )}`;
    const fare = fareMap[key];

    if (fare === undefined || fare === "0.00") {
      return { value: "View", color: "black" , fontSize: "14px" }; // Default for zero or undefined
    }

    const fareValue = parseFloat(fare);

  // Get all unique fare values from fareMap
  const fareValues = Object.values(fareMap)
    .map(fare => parseFloat(fare))
    .filter(fare => !isNaN(fare) && fare > 0); // Exclude zero or undefined fares

  // Check if there is a "single value" (only one unique fare in the entire fareMap)
  const uniqueFares = new Set(fareValues);
  const isSingleValue = uniqueFares.size === 1;

  // If there is only one unique fare (single value case), apply black color
  if (isSingleValue) {
    return { value: `₹${fare}`, color: "black", fontSize: "14px", fontWeight: "bold" }; // Single fare value
  }

  // Find the highest and lowest fares dynamically
  const highestFare = Math.max(...fareValues);
  const lowestFare = Math.min(...fareValues);

  // If the fare matches the highest fare, apply red color
  if (fareValue === highestFare) {
    return { value: `₹${fare}`, color: "red", fontSize: "14px", fontWeight: "bold" }; // Highest fare
  }

  // If the fare matches the lowest fare, apply green color
  if (fareValue === lowestFare) {
    return { value: `₹${fare}`, color: "green", fontSize: "14px", fontWeight: "bold" }; // Lowest fare
  }

  // For other fares, apply black color by default
  return { value: `₹${fare}`, color: "black", fontSize: "14px", fontWeight: "bold" };
};

  // Find highest, lowest non-zero fare values
  const fareValues = Object.values(fareMap)
    .map((fare) => parseFloat(fare))
    .filter((fare) => !isNaN(fare) && fare > 0); // Exclude zero/undefined fares


const averageFare = fareValues.reduce((sum, fare) => sum + fare, 0) / fareValues.length;

const singleValue = fareValues.reduce((closest, fare) => 
  Math.abs(fare - averageFare) < Math.abs(closest - averageFare) ? fare : closest, fareValues[0]);


  const highestFare = Math.max(...fareValues, 0); // Default 0 if no valid fares
  const lowestFare = Math.min(...fareValues, Infinity); // Default Infinity if no valid fares

  const centerRowIndex = Math.floor(verticalDates.length / 2);


 
  const handleClickFindFare = useCallback(
    (horizontalDate, verticalDate) => {
      setSearch((prevState) => {
        const updatedSearch = { ...prevState };

        if (updatedSearch.type !== "Multicity") {
          updatedSearch.originDestinations[0] = {
            ...updatedSearch.originDestinations[0],
            departureDateTime: new Date(horizontalDate),
            returnDateTime: new Date(verticalDate),
          };
        }

        return updatedSearch;
      });
      fetchCalenderResults();
    },
    [search]
  );

  return (
    <div style={{ overflowX: "auto", margin: "5px 0" }}>
      <table
        border="1"
        style={{
          borderCollapse: "collapse",
          width: "100%",
          fontFamily: "Arial, sans-serif",
          textAlign: "center",
        }}
      >
        <thead>
          <tr>
            <th
              rowSpan="2"
              colSpan="2"
              style={{
                backgroundColor: "#eee",
                fontWeight: "bold",
                border: "0.5px solid #b5afaf",
                verticalAlign: "middle",
                minWidth: "200px",
              }}
            >
              Fare Calendar
            </th>
            <th
              colSpan="7"
              style={{
                fontWeight: "bold",
                border: "0.5px solid #b5afaf",
                backgroundColor: "#eee",
                position: "relative",
              }}
            >
              {fareCalendar?.length > 0
                ? `${fareCalendar[0]?.origin || "N/A"} - ${
                    fareCalendar[0]?.destination || "N/A"
                  }`
                : "No origin - destination Available"}

              <span
                onClick={() => setToggleFlightDetails(!toggleFlightDetails)}
                style={{
                  cursor: "pointer",
                  color: "black",
                  position: "absolute",
                  right: "15px",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                {toggleFlightDetails ? (
                  <KeyboardArrowUpRoundedIcon fontSize="medium" />
                ) : (
                  <KeyboardArrowDownRoundedIcon fontSize="medium" />
                )}
              </span>
            </th>
          </tr>
          <tr>
            {horizontalDates.map((date, index) => (
              <th
                key={date.format("YYYY-MM-DD")}
                style={{
                  fontWeight: "bold",
                  border: "0.5px solid #b5afaf",
                  backgroundColor: date.isSame(departureMoment, "day")
                    ? "rgb(248, 196, 113)" // Highlight the baseDate in horizontal dates
                    : "#f1f1f1",
                  minWidth: "100px",
                }}
              >
                {date.format("ddd, MMM D")}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {verticalDates.map((verticalDate, rowIndex) => (
            <tr key={verticalDate.format("YYYY-MM-DD")}>
              {/* Destination-Origin column for expanded rows */}
              {rowIndex === 0 && (
                <td
                  rowSpan={verticalDates.length}
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    border: "0.5px solid #b5afaf",
                    verticalAlign: "middle",
                    backgroundColor: "#eee",
                    minWidth: "200px",
                  }}
                >
                  {fareCalendar.length > 0
                    ? `${fareCalendar[0]?.destination || "N/A"} - ${
                        fareCalendar[0]?.origin || "N/A"
                      }`
                    : "No origin - destination Available"}
                </td>
              )}

              {/* Render the date in the first column of each row */}
              {toggleFlightDetails ? (
                verticalDate.isSame(returnMoment, "day") && (
                  <>
                    <td
                      style={{
                        fontWeight: "bold",
                        backgroundColor: verticalDate.isSame(
                          returnMoment,
                          "day"
                        )
                          ? "rgb(248, 196, 113)"
                          : "#f1f1f1",
                        border: "0.5px solid #b5afaf",
                        minWidth: "100px",
                      }}
                    >
                      {verticalDate.format("ddd, MMM D")}
                    </td>

                    {/* Render the fare data for each horizontal date */}
                    {horizontalDates.map((horizontalDate) => {
                      const { value, color,fontSize,fontWeight } = findFare(
                        horizontalDate,
                        verticalDate
                      ); // Destructure the result
                      return (
                        <td
                          key={horizontalDate.format("YYYY-MM-DD")}
                          style={{
                            border: "0.5px solid #b5afaf",
                            textAlign: "center",
                            color: color, // Apply color to the text
                            fontSize:fontSize,
                            fontWeight: fontWeight,
                          }}
                        >
                          {value} {/* Render the value */}
                        </td>
                      );
                    })}
                  </>
                )
              ) : (
                <>
                  <td
                    style={{
                      fontWeight: "bold",
                      backgroundColor: verticalDate.isSame(returnMoment, "day")
                        ? "rgb(248, 196, 113)"
                        : "#f1f1f1",
                      border: "0.5px solid #b5afaf",
                      minWidth: "100px",
                    }}
                  >
                    {verticalDate.format("ddd, MMM D")}
                  </td>

                  {horizontalDates.map((horizontalDate) => {
                    const { value, color,fontSize,fontWeight } = findFare(
                      horizontalDate,
                      verticalDate
                    ); // Destructure the result
                    return (
                      <td
                        key={horizontalDate.format("YYYY-MM-DD")}
                        style={{
                          border: "0.5px solid #b5afaf",
                          textAlign: "center",
                          color: color, // Apply color to the text
                          fontSize:fontSize,
                          fontWeight: fontWeight,
                        }}
                        onClick={()=>handleClickFindFare(horizontalDate,
                          verticalDate)}
                      >
                        {value} {/* Render the value */}
                      </td>
                    );
                  })}
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CarouselCalenderRoundTrip;
