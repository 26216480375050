import moment from "moment";
import React, { useEffect, useRef, useState } from 'react';
import findAirlinebyCode from "../../functions/findAirlineByCode";
import getDuration from "../../functions/getDuration";
import getAirportByCode from "../../functions/getAirportByCode";
import BootstrapTooltip1 from "../BootstrapToolTip/BootstrapToolTip1";
import { Grid, Table } from "@mui/joy";
import { useTranslation } from "react-i18next";
export default function FlightSegmentDetails({ flight, setMaxHeight, bookingDetails, flag, checkedRows, setCheckedRows }) {
    const { t } = useTranslation();
    const tableBodyRef = useRef(null);
    useEffect(() => {
        if (tableBodyRef.current) {
            const columns = tableBodyRef.current.children;
            let height = 0;

            // Find the tallest column
            for (const column of columns) {
                if (column.clientHeight > height) {
                    height = column.clientHeight;
                }
            }
            // Update the maxHeight in the parent component
            setMaxHeight((maxHeight) => height);
        }
    }, [flight]);

    const handleCheckboxChange = (segGroupIndex, segmentIndex) => {
        setCheckedRows((prevState) => {
            const updatedGroup = { ...(prevState[segGroupIndex] || {}) };
            updatedGroup[segmentIndex] = !updatedGroup[segmentIndex];

            return {
                ...prevState,
                [segGroupIndex]: updatedGroup,
            };
        });
    };


    return (
        <>
            {flight.segGroups && flight.segGroups.length > 0 ? (
                flight.segGroups.map((segGroup, segGroupIndex) => (
                    <div className="row">
                        <div style={{ paddingLeft: "0px", width: flag !== "reissuecopy" ? "80%" : "100%" }}>
                            <div className="flight-itenary">
                                <div className="flight-itenary-header">
                                    <div className="flight-itenary-title-container">
                                        <div>
                                            {t("Itinerary Details")}
                                            {flag && flag === "reissue" && <span className="ms-4 p-1 rounded" style={{ backgroundColor: "#671c41" }}>Existing Itinerary</span>}
                                        </div>

                                    </div>
                                </div>
                                {segGroup.segments && segGroup.segments.length > 0 ? (
                                    <>
                                        <Table aria-label="basic table" id="seggroupTable">
                                            <thead>
                                                <tr >
                                                    <BootstrapTooltip1 title={t("Airline/Flight No/ Coach")}>
                                                        <th className="flightItineraryColumnHeader">{t("Airline/Flight No/ Coach")}</th>
                                                    </BootstrapTooltip1>
                                                    <BootstrapTooltip1 title={t("Departure Airport / Terminal")}>
                                                        <th className="flightItineraryColumnHeader">{t("Departure Airport / Terminal")}</th>
                                                    </BootstrapTooltip1>
                                                    <BootstrapTooltip1 title={t("Departure Date & Time")}>
                                                        <th className="flightItineraryColumnHeader">{t("Departure Date & Time")}</th>
                                                    </BootstrapTooltip1>
                                                    <BootstrapTooltip1 title={t("Arrival Airport / Terminal")}>
                                                        <th className="flightItineraryColumnHeader">{t("Arrival Airport / Terminal")}</th>
                                                    </BootstrapTooltip1>
                                                    <BootstrapTooltip1 title={t("Arrival Date & Time")}>
                                                        <th className="flightItineraryColumnHeader">{t("Arrival Date & Time")}</th>
                                                    </BootstrapTooltip1>
                                                    <BootstrapTooltip1 title={t("Duration")}>
                                                        <th className="flightItineraryColumnHeader" style={{ width: '5vw', maxWidth: '5vw' }}>{t("Duration")}</th>
                                                    </BootstrapTooltip1>
                                                    {/*<BootstrapTooltip1 title={''}>
                                              <th style={{textAlign:'center'}}>FBA</th> 
                                            </BootstrapTooltip1>*/}
                                                    <BootstrapTooltip1 title={t("Airline PNR")}>
                                                        <th className="flightItineraryColumnHeader" style={{ width: '5vw', maxWidth: '5vw' }}>{t("Airline PNR")}</th>
                                                    </BootstrapTooltip1>
                                                    <BootstrapTooltip1 title={t("Status")}>
                                                        <th className="flightItineraryColumnHeader" style={!flag?.includes("reissue") ? { width: '5vw', maxWidth: '5vw' } : undefined}>{t("Status")}</th>
                                                    </BootstrapTooltip1>
                                                    {flag === "reissuecopy" && <BootstrapTooltip1 title={t("Actions")}>
                                                        <th className="flightItineraryColumnHeader">{t("Actions")}</th>
                                                    </BootstrapTooltip1>}
                                                </tr>
                                            </thead>
                                            <tbody ref={tableBodyRef}>
                                                {
                                                    segGroup.segments.map((segment, segmentIndex) => (
                                                        <tr >
                                                            <td className="flightItineraryColumn" key={`${segmentIndex}`}>{findAirlinebyCode(segment.mrkAirline)}  <br />  <b>{segment.flightNum}</b>  /  {segment.cabinClass}</td>
                                                            <td className="flightItineraryColumn">{getAirportByCode(segment.origin)} <br /> <b>{segment.origin}</b>  / {segment.depTerminal}</td>
                                                            <td className="flightItineraryColumn">{moment(segment.departureOn).format("Do MMM  YYYY")}<br />
                                                                {moment(segment.departureOn).format("hh:mm A")}</td>
                                                            <td className="flightItineraryColumn">{getAirportByCode(segment.destination)}<br /> <b>{segment.destination}</b>  / {segment.arrTerminal}</td>
                                                            <td className="flightItineraryColumn">{moment(segment.arrivalOn).format("Do MMM  YYYY")} <br />
                                                                {moment(segment.arrivalOn).format("hh:mm A")}</td>
                                                            <td className="flightItineraryColumn" style={{ width: '5vw', maxWidth: '5vw' }}>{getDuration(segment.duration)}</td>
                                                            {/* <td></td> */}
                                                            <td className="flightItineraryColumn" style={{ width: '5vw', maxWidth: '5vw' }}>{segment.pnr ? segment.pnr : "-"}</td>
                                                            <td className="flightItineraryColumn" style={flag !== "reissue" ? { width: '5vw', maxWidth: '5vw' } : undefined}>{segment.flightTicketStatus ? segment.flightTicketStatus : "-"}</td>
                                                            {flag === "reissuecopy" && (
                                                                <td className="flightItineraryColumn">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={!!checkedRows[segGroupIndex]?.[segmentIndex]}
                                                                        onChange={() => handleCheckboxChange(segGroupIndex, segmentIndex)}
                                                                    />
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </div>
                        {flag !== "reissuecopy" && <div style={{ paddingRight: "0px", width: "20%" }}>
                            <div className="flight-itenary">
                                <div className="flight-itenary-header">
                                    <div className="flight-itenary-title-container">
                                        <div>
                                            {t("PNR")}
                                        </div>
                                    </div>
                                </div>
                                <Table aria-label="basic table" id="tablePNR">
                                    <thead>
                                        <tr style={{ display: 'flex' }}>
                                            <BootstrapTooltip1 title="RBD">
                                                <th align="center" style={{ flex: '1 1 auto' }}>{t("RBD")}</th>
                                            </BootstrapTooltip1>
                                            <BootstrapTooltip1 title="Fare Basis">
                                                <th align="center" style={{ flex: '1 1 auto' }}>{t("Fare Basis")}</th>
                                            </BootstrapTooltip1>
                                            <BootstrapTooltip1 title="GDS PNR">
                                                <th align="center" style={{ flex: '1 1 auto' }}>{t("GDS PNR")}</th>
                                            </BootstrapTooltip1>
                                        </tr>
                                    </thead>
                                    <tbody ref={tableBodyRef}>
                                        {
                                            segGroup?.segments?.map((segment, index) => (
                                                <tr id="seggroupPNRTable" style={{ display: 'flex' }}>
                                                    <BootstrapTooltip1 title={segment?.rbd}><td align="center" style={{ flex: '1 1 auto' }}>{segment?.rbd}</td></BootstrapTooltip1>
                                                    <BootstrapTooltip1 title={flight?.fareBasis ? flight?.fareBasis : '-'}><td align="center" style={{ flex: '1 1 auto' }}>{flight?.fareBasis ? (flight.fareBasis.includes(' ') ? flight.fareBasis.substring(0, 12) + '...' : flight.fareBasis) : '-'}</td></BootstrapTooltip1>
                                                    <BootstrapTooltip1 title={flight?.pnr}><td align="center" style={{ flex: '1 1 auto' }}>{flight?.pnr}</td></BootstrapTooltip1>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>}
                    </div>
                ))
            ) : (
                <div></div>
            )}
        </>
    );
}