import React, { } from "react";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import findAirlinebyCode from "../../../functions/findAirlineByCode";
import getAirportByCode from "../../../functions/getAirportByCode";
import { TextValidator } from "react-material-ui-form-validator";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";
import cabinClass from "../../../data/CabinClass";
import findAirportbyCode from "../../../functions/findAirportByCode";

export default function FlightSegmentDetails(
    {
        segGroups,
        flightIndex, handlecabinClassChange, handlePNRChange }
) {
    const cabinOptions = cabinClass.filter((cabin) => cabin.label !== "None" && cabin.label !== "All");
    return (
        <>          {segGroups !== undefined && segGroups.length > 0 ? (
            segGroups.map((segGroup, segGroupIndex) => (
                <div className="flight-itenary">
                    <div className="flight-itenary-header">
                        <div className="flight-itenary-title-container">
                            <div>
                                {findAirportbyCode(segGroup.origin)} (
                                {segGroup.origin})
                            </div>
                            <div className="flight-itenary-icon">
                                {/* <ArrowRightAltIcon></ArrowRightAltIcon> */}
                                {segGroups[0].origin === segGroups[0].destination ? <SyncAltIcon></SyncAltIcon> : <ArrowRightAltIcon></ArrowRightAltIcon>}
                            </div>
                            <div>
                                {findAirportbyCode(segGroup.destination)} (
                                {segGroup.destination})
                            </div>
                        </div>
                        <div className="flight-itenary-date">
                            {moment(segGroup.departureOn).format(
                                "Do MMMM YYYY"
                            )}
                        </div>
                    </div>
                    {segGroup.segments !== undefined &&
                        segGroup.segments.length > 0 ? (
                        segGroup.segments.map(
                            (segment, segmentIndex) => (
                                <div>
                                    <div className="flight-itenary-details">
                                        <div className="flight-itenary-details-item">
                                            <div className="flight-itenary-details-item-text">
                                                {/* {segment.cabinClass} */}
                                                <Autocomplete
                                                    id="FOP-select"
                                                    className="cabinClass"
                                                    variant="outlined"
                                                    required
                                                    style={{
                                                        minWidth: "200px !important",
                                                        padding: "0px !important",
                                                    }}
                                                    value={
                                                        (segment.cabinClass !== "" || segment.cabinClass !== null || segment.cabinClass !== undefined)
                                                            ? cabinOptions.find(
                                                                (v) =>
                                                                    v.label ===
                                                                    segment.cabinClass
                                                            )
                                                            : segment.cabinClass === undefined ? "" : segment.cabinClass === "" ? "" : segment.cabinClass === "All" ? '' : segment.cabinClass === "None" ? '' : null
                                                    }
                                                    onChange={(event, newValue) => {
                                                        handlecabinClassChange(
                                                            event,
                                                            newValue && newValue?.label ? newValue.label : '',
                                                            flightIndex,
                                                            segGroupIndex,
                                                            segmentIndex
                                                        );
                                                    }}
                                                    options={cabinOptions}
                                                    getOptionLabel={(option) =>
                                                        option.label
                                                    }
                                                    renderInput={(params) => (
                                                        <TextValidator
                                                            {...params}
                                                            value={segment.cabinClass}
                                                            variant="filled"
                                                            color="secondary"
                                                            size="small"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            required
                                                            validators={["required"]}
                                                            errorMessages={["required"]}
                                                        />
                                                    )}
                                                />
                                                {
                                                    segment.cabinClass === '' || segment.cabinClass === undefined || segment.cabinClass === null || segment.cabinClass === "All" || segment.cabinClass === "None" ?

                                                        <p style={{ color: 'red', fontSize: '12px' }}>Cabin Class Is Mandatory</p>
                                                        : ''
                                                }
                                                {/* <TextValidator
                              type="text"
                              variant="outlined"
                              size="small"
                              color="secondary"
                              value={segment.cabinClass}
                              // validators={["required", 'matchRegexp:^[a-zA-Z0-9]+$']}
                              // errorMessages={["required", "Only Alphabets are allowed"]}
                              onChange={(event) =>
                                handlecabinClassChange(event, flightIndex, segGroupIndex, segmentIndex)
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                            /> */}
                                            </div>
                                            <div className="flight-itenary-details-item-subtext">
                                                Class Of Service
                                            </div>
                                        </div>
                                        <div className="flight-itenary-details-item">
                                            <div className="flight-itenary-details-item-text">
                                                {findAirlinebyCode(
                                                    segment.mrkAirline
                                                )}
                                            </div>
                                            <div className="flight-itenary-details-item-subtext">
                                                {segment.mrkAirline}-
                                                {segment.flightNum}
                                            </div>
                                        </div>
                                        <div className="flight-itenary-details-item">
                                            <div className="flight-itenary-details-item-text">
                                                {moment(segment.departureOn).format(
                                                    "HH:mm"
                                                )}
                                            </div>
                                            <div className="flight-itenary-details-item-subtext">
                                                Depature
                                            </div>
                                        </div>
                                        <div className="flight-itenary-details-item">
                                            <div className="flight-itenary-details-item-text">
                                                {segment.duration}m
                                            </div>
                                            <div className="flight-itenary-details-item-subtext">
                                                Duration
                                            </div>
                                        </div>
                                        <div className="flight-itenary-details-item">
                                            <div className="flight-itenary-details-item-text">
                                                {moment(segment.arrivalOn).format(
                                                    "HH:mm"
                                                )}
                                            </div>
                                            <div className="flight-itenary-details-item-subtext">
                                                Arrival
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flight-confirmation-details">
                                        <div className="flight-confirmation-details-item">
                                            <div className="flight-confirmation-details-item-text">
                                                Origin
                                            </div>
                                            <div className="flight-confirmation-details-item-subtext">
                                                {getAirportByCode(segment.origin)} -{" "}
                                                {segment.origin}
                                            </div>
                                        </div>
                                        <div className="flight-confirmation-details-item">
                                            <div className="flight-confirmation-details-item-text">
                                                Destination
                                            </div>
                                            <div className="flight-confirmation-details-item-subtext">
                                                {getAirportByCode(
                                                    segment.destination
                                                )}{" "}
                                                - {segment.destination}
                                            </div>
                                        </div>
                                        <div className="flight-confirmation-details-item">
                                            <div
                                                className="flight-confirmation-details-item-text"
                                                style={{ marginBottom: "10px" }}
                                            >
                                                PNR
                                            </div>
                                            <div
                                                className="flight-confirmation-details-item-subtext"
                                                style={{
                                                    marginLeft: "auto",
                                                    maxWidth: " 60%",
                                                }}
                                            >

                                                {segment.pnr ?

                                                    <TextValidator
                                                        type="text"
                                                        variant="outlined"
                                                        size="small"
                                                        required
                                                        color="secondary"
                                                        value={segment.pnr}
                                                        validators={[
                                                            "required",
                                                            "matchRegexp:^[a-zA-Z0-9]+$",
                                                        ]}
                                                        errorMessages={[
                                                            "required",
                                                            "No Special Characters Allowed",
                                                        ]}
                                                        disabled
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                    :
                                                    <TextValidator
                                                        type="text"
                                                        variant="outlined"
                                                        size="small"
                                                        required
                                                        color="secondary"
                                                        value={segment.pnr}
                                                        validators={[
                                                            "required",
                                                            "matchRegexp:^[a-zA-Z0-9]+$",
                                                        ]}
                                                        errorMessages={[
                                                            "required",
                                                            "No Special Characters Allowed",
                                                        ]}
                                                        onChange={(event) =>
                                                            handlePNRChange(
                                                                event,
                                                                flightIndex,
                                                                segGroupIndex,
                                                                segmentIndex
                                                            )
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                }
                                                {
                                                    segment.pnr === '' || segment.pnr === null || segment.pnr === undefined ?

                                                        <p style={{ color: 'red', fontSize: '12px' }}>Airline PNR Is Mandatory</p>
                                                        : ''
                                                }
                                            </div>
                                        </div>
                                        <div className="flight-confirmation-details-item">
                                            <div className="flight-confirmation-details-item-text">
                                                Segment Status
                                            </div>
                                            <div className="flight-confirmation-details-item-subtext full-opacity">
                                                <div className="itenary-confirmtaion-tag success">
                                                    {segment.Status}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        )
                    ) : (
                        <div></div>
                    )}
                </div>
            ))
        ) : (
            <div></div>
        )}
        </>

    );
}