export default function getLayoverTime(firstSeg, secondSeg) {
  let arrivalTime = firstSeg.arrivalOn;
  let departureTime = secondSeg.departureOn;
  return getHumanizedDiff(
    new Date(departureTime).getTime() - new Date(arrivalTime).getTime()
  );
}

function getHumanizedDiff(diff) {
  const totalMinutes = Math.abs(diff) / 60000; // Total difference in minutes
  const hours = Math.floor(totalMinutes / 60); // Total hours
  const minutes = Math.floor(totalMinutes % 60); // Remaining minutes

  return String(hours).padStart(2, '0') + " h " + String(minutes).padStart(2, '0') + " m";
}
