import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import './PreviewOfflineBooking.css';
import Button from "@material-ui/core/Button";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Dialog, DialogTitle, DialogContent } from '@mui/material';
import dayjs from 'dayjs';
import moment from 'moment';
import cabinClass from '../../data/CabinClass';
import { stateToHTML } from 'draft-js-export-html';
import parse from 'html-react-parser';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import RiseLoading from '../Loader/RiseLoader';

const PreviewOfflineBooking = ({
  supplierName,
  pcc,
  clientId,
  onwardSegments,
  returnSegments,
  passengers,
  fop,
  clientProfiles,
  clientROE,
  caseID,
  salesStaff,
  clientEmail,
  fema,
  cardNumber,
  expiryDate,
  panCard,
  mco,
  pgCharge,
  mcoParted,
  mcoRemark,
  importPNRRemarks,
  fareRows,
  adultBase,
  adultTax,
  adultBaggage,
  childBase,
  childTax,
  childBaggage,
  infantBase,
  infantTax,
  infantBaggage,
  adultVendorIssuanceFee,
  childVendorIssuanceFee,
  infantVendorIssuanceFee,
  adultMarkup,
  childMarkup,
  infantMarkup,
  adultVendorTax,
  childVendorTax,
  infantVendorTax,
  adultVendorTotal,
  childVendorTotal,
  infantVendorTotal,
  adultIata,
  childIata,
  infantIata,
  adultVendorIata,
  childVendorIata,
  infantVendorIata,
  adultPlb,
  childPlb,
  infantPlb,
  adultVendorPlb,
  childVendorPlb,
  infantVendorPlb,
  bookingType,
  selectedTripType,
  userProfiles,
  clientAvailableBalance,
  clientTotalWithClientROE,
  vendorTotal,
  clientCurrency,
  vendorCurrencyCode,
  value,
  endClientName,
  setValidateForm,
  currency,
  fopOptions,
  source,
  CreateImportBooking,
  mode,
  baggages,
  flightTicketNumber,
  pnr,
  editorState,
  isImportBtnDisabled, showLoader, selectedProductType,
  adultTotal, childTotal, infantTotal
}) => {
  const [tickeImage, setTickeImage] = useState('');
  const [ticketModalopen, setTicketModalOpen] = useState(false);

  function getClientNameById(clientID) {
    const profile = clientProfiles.find(profile => profile.id === clientID);
    return profile ? profile.clientName : "";
  }

  const clientName = getClientNameById(clientId);


  const getUserById = (id) => {
    return userProfiles.find(user => user.id === id);
  };
  const user = getUserById(salesStaff);

  const getFopId = (id) => {
    return fopOptions.find(fop => fop.id === id);
  };
  const fopDetail = getFopId(fop);


  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    const ticketContentState = editorState.getCurrentContent();
    let tickeHtml = stateToHTML(ticketContentState);
    setTickeImage(tickeHtml);
  }, [editorState])

  return (
    <div className='tableSide preview-offline-page'>
      {
        showLoader ? <RiseLoading isImport={showLoader} selectedProductType={selectedProductType} />
          :

          <Grid item xs={12} style={{ maxWidth: '90vw', margin: '0px 5vw' }}>
            <div className="offline-flight-itenary">
              <Paper
                elevation={1}
                className="OfflineBookingPaper"
                sx={{ padding: '0px' }}
                style={{ padding: '0px' }}
              >
                <div className="flight-itenary-header d-flex justify-content-between">
                  <div className="flight-itenary-title-container">{(mode === "offlineBooking" ? "Offline Booking" : "Import Booking")} - {value}</div>
                  <div>
                    Client Available Balance:  {clientCurrency ? clientCurrency : " "}{" "} {clientAvailableBalance}
                  </div>
                </div>
              </Paper>
            </div>


            <Paper className="offline_booking border-label" style={{ padding: '30px 0' }} >
              <div>
                <Typography variant="body2">
                  <span className="bold-dark">Booking Type:</span> {bookingType === 'fitBooking' ? 'FIT Booking' : 'Group Booking'}
                </Typography>
              </div>

              <Grid container spacing={2} style={{ padding: '25px 0' }}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="subtitle2" gutterBottom className="bold-dark">Client</Typography>
                  <div>{clientName + '(' + clientId + ')'}</div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="subtitle2" gutterBottom className="bold-dark">Supplier Name</Typography>
                  <div>{supplierName}</div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="subtitle2" gutterBottom className="bold-dark">PCC</Typography>
                  <div>{pcc}</div>
                </Grid>
                {mode === "importBooking" && <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography variant="subtitle2" gutterBottom className="bold-dark">PNR</Typography>
                  <div>{pnr}</div>
                </Grid>
                }
              </Grid>

            </Paper>



            <Paper className="offline-itenary-container-import" elevation={3} style={{ marginTop: '16px', justifyContent: 'center' }}>
              <div style={{ padding: '15px 0' }}>
                <Typography variant="h6" gutterBottom>Itinerary Details</Typography>
                <Typography variant="body2" className="text-center">
                  <span className="bold-dark">Trip Type:</span> {selectedTripType === 'onward' ? 'onward' : 'return'}
                </Typography>

                {[onwardSegments, selectedTripType === 'return' && returnSegments].map((segments, index) =>
                  segments && (
                    <TableContainer component={Paper} key={index}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {['Airline', 'Flight No', 'Orig. Airport', 'Dest. Airport', 'Dept. Date', 'Dept. Time', 'Arr. Date', 'Arr. Time',]
                              .map(header => (
                                <TableCell align="center" key={header}>{header}</TableCell>
                              ))}
                            {mode === "importBooking" && <TableCell align="center">Duration</TableCell>}
                            <TableCell align="center">Cabin Class</TableCell>
                            {mode === "offlineBooking" && (
                              <>
                                <TableCell align="center">Book. Class</TableCell>
                                <TableCell align="center">Air PNR</TableCell>
                              </>
                            )}
                            <TableCell align="center">GDS PNR</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {segments.map((segment, i) => (
                            <TableRow key={i}>
                              <TableCell align="center">{segment.airline || segment.mrkAirline}</TableCell>
                              <TableCell align="center">{segment.flightNo || segment.flightNum}</TableCell>
                              <TableCell align="center">
                                {segment.originAirport || segment.origin}
                                {mode === "offlineBooking" && segment.originTerminal && (
                                  <>
                                    <Typography variant="subtitle2" className="bold-dark">Orig. Term</Typography>
                                    <Typography variant="body2" style={{ fontSize: '0.75rem' }}>{segment.originTerminal}</Typography>
                                  </>
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {segment.destinationAirport || segment.destination}
                                {mode === "offlineBooking" && segment.destinationTerminal && (
                                  <>
                                    <Typography variant="subtitle2" className="bold-dark">Dest. Term</Typography>
                                    <Typography variant="body2" style={{ fontSize: '0.75rem' }}>{segment.destinationTerminal}</Typography>
                                  </>
                                )}
                              </TableCell>
                              <TableCell align="center">{dayjs(segment.depDate?.$d || segment.departureOn).format('YYYY-MM-DD')}</TableCell>
                              <TableCell align="center">{dayjs(segment.depTime?.$d || segment.departureOn).format('HH:mm:ss')}</TableCell>
                              <TableCell align="center">{dayjs(segment.arrDate?.$d || segment.arrivalOn).format('YYYY-MM-DD')}</TableCell>
                              <TableCell align="center">{dayjs(segment.arrTime?.$d || segment.arrivalOn).format('HH:mm:ss')}</TableCell>
                              {mode === "importBooking" && <TableCell align="center">{segment.duration}m</TableCell>}
                              <TableCell align="center">{cabinClass.find((v) => v.value === segment.cabinClass)?.label || segment.cabinClass}</TableCell>
                              {mode === "offlineBooking" && <TableCell align="center">{segment.bookingClass}</TableCell>}
                              <TableCell align="center">{segment.gdsPNR || segment.pnr}</TableCell>
                              {mode === "offlineBooking" && <TableCell align="center">{segment.airlinePNR}</TableCell>}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )
                )}
              </div>
            </Paper>


            <Paper className="offline-passenger-container-import" elevation={3} style={{ marginTop: '16px', padding: '16px', justifyContent: "center" }}>
              <div style={{ padding: '15px 0' }}>
                <div align="center">
                  <Typography variant="h6" gutterBottom>Passenger Details</Typography>
                </div>

                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Passenger(s)</TableCell>
                        <TableCell align="center">Pax</TableCell>
                        <TableCell align="center">Date of Birth</TableCell>
                        <TableCell align="center">Gender</TableCell>
                        <TableCell align="center">Ticket No</TableCell>
                        {mode === "offlineBooking" && <TableCell align="center">Passport No</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {passengers.map((passenger, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{passenger.title ? passenger.title : "" + " " + passenger.firstName + " " + passenger.lastName}</TableCell>
                          <TableCell align="center">{passenger.paxType}</TableCell>
                          <TableCell align="center">{moment(passenger.dob).format("Do MMMM YYYY")}</TableCell>
                          <TableCell align="center">{passenger.genderType}</TableCell>
                          <TableCell align="center">{value === "ReIssue" && mode === "importBooking" ? flightTicketNumber[`0-${index}`] : passenger.ticketNumber}</TableCell>
                          {mode === "offlineBooking" && <TableCell align="center">{passenger.passportNumber}</TableCell>}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Paper>

            <Paper className="offline-baggage-container-import" elevation={3} style={{ marginTop: '16px', padding: '16px' }}>
              <div style={{ padding: '15px 0' }}>
                <div><Typography variant="h6" gutterBottom>Baggage</Typography></div>

                <Table style={{ width: '100%', padding: '25px 0' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Passenger Type</TableCell>
                      <TableCell align="center">Passenger(s)</TableCell>
                      {mode === "importBooking" && <TableCell align="center">Airline</TableCell>}
                      <TableCell align="center">CheckIn Bag</TableCell>
                      <TableCell align="center">Cabin Bag</TableCell>
                      <TableCell align="center">City Pair</TableCell>
                      <TableCell align="center">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mode === "offlineBooking" ?
                      passengers.map((passenger, pIndex) => (
                        passenger.baggage.map((baggage, bIndex) => (
                          <TableRow key={`${pIndex}-${bIndex}`}>
                            <TableCell align="center">{passenger.paxType}</TableCell>
                            <TableCell align="center">{passenger.firstName + " " + passenger.lastName}</TableCell>
                            <TableCell align="center">{baggage.checkInBag}</TableCell>
                            <TableCell align="center">{baggage.cabinBag}</TableCell>
                            <TableCell align="center">{baggage.cityPair}</TableCell>
                            <TableCell align="center">{baggage.amount}</TableCell>
                          </TableRow>
                        ))
                      )) :
                      baggages.map((baggage, Index) => (
                        <TableRow>
                          <TableCell align="center">{baggage.paxType}</TableCell>
                          <TableCell align="center">{baggage.paxName}</TableCell>
                          <TableCell align="center">{baggage.airline.Name}</TableCell>
                          <TableCell align="center">{baggage.checkInBag ? baggage.checkInBag : "NIL"}</TableCell>
                          <TableCell align="center">{baggage.cabinBag ? baggage.cabinBag : "NIL"}</TableCell>
                          <TableCell align="center">{baggage.cityPair ? baggage.cityPair : "NIL"}</TableCell>
                          <TableCell align="center">{baggage.amount}</TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>

              </div>
            </Paper>


            <Paper className="offline-clientfare-container-import" elevation={3} style={{ marginTop: '16px', padding: '16px' }}>
              <div style={{ padding: '15px 0' }}>
                <Typography variant="h6" gutterBottom>
                  Supplier Fare Details ({currency})
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Pax</TableCell>
                        <TableCell>Base Fare</TableCell>
                        <TableCell>Tax</TableCell>
                        <TableCell>Baggage</TableCell>
                        <TableCell>MarkUp</TableCell>
                        <TableCell>Vendor Issuance Fee</TableCell>
                        <TableCell>Vendor TAX</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell>Vendor IATA</TableCell>
                        <TableCell>Vendor PLB</TableCell>
                        <TableCell>Client Parting IATA</TableCell>
                        <TableCell>Client Parting PLB</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fareRows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {row.paxType === "ADT"
                              ? "Adult"
                              : row.paxType === "CHD"
                                ? "Child"
                                : row.paxType === "INF"
                                  ? "Infant"
                                  : "Adult"}
                          </TableCell>
                          <TableCell>
                            {Number(row.paxType === "ADT" ? adultBase : row.paxType === "CHD" ? childBase : row.paxType === "INF" ? infantBase : adultBase).toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {Number(row.paxType === "ADT" ? adultTax : row.paxType === "CHD" ? childTax : row.paxType === "INF" ? infantTax : adultTax).toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {Number(row.paxType === "ADT" ? adultBaggage : row.paxType === "CHD" ? childBaggage : row.paxType === "INF" ? infantBaggage : adultBaggage).toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {Number(row.paxType === "ADT" ? adultMarkup : row.paxType === "CHD" ? childMarkup : row.paxType === "INF" ? infantMarkup : adultMarkup).toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {Number(row.paxType === "ADT" ? adultVendorIssuanceFee : row.paxType === "CHD" ? childVendorIssuanceFee : row.paxType === "INF" ? infantVendorIssuanceFee : adultVendorIssuanceFee).toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {Number(row.paxType === "ADT" ? adultVendorTax : row.paxType === "CHD" ? childVendorTax : row.paxType === "INF" ? infantVendorTax : adultVendorTax).toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {Number(row.paxType === "ADT" ? adultVendorTotal : row.paxType === "CHD" ? childVendorTotal : row.paxType === "INF" ? infantVendorTotal : adultVendorTotal).toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {Number(row.paxType === "ADT" ? adultVendorIata : row.paxType === "CHD" ? childVendorIata : row.paxType === "INF" ? infantVendorIata : adultVendorIata).toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {Number(row.paxType === "ADT" ? adultVendorPlb : row.paxType === "CHD" ? childVendorPlb : row.paxType === "INF" ? infantVendorPlb : adultVendorPlb).toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {Number(row.paxType === "ADT" ? adultIata : row.paxType === "CHD" ? childIata : row.paxType === "INF" ? infantIata : adultIata).toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {Number(row.paxType === "ADT" ? adultPlb : row.paxType === "CHD" ? childPlb : row.paxType === "INF" ? infantPlb : adultPlb).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Paper>

            <Paper className="offline-clientfare-container-import" elevation={3} style={{ marginTop: '16px', padding: '16px' }}>
              <div style={{ padding: '15px 0' }}>
                <Typography variant="h6" gutterBottom>
                  Client Fare Details ({clientCurrency})
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Pax</TableCell>
                        <TableCell>Base Fare</TableCell>
                        <TableCell>Tax</TableCell>
                        <TableCell>Baggage</TableCell>
                        <TableCell>Vendor Issuance Fee</TableCell>
                        <TableCell>Vendor TAX</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell>Vendor IATA</TableCell>
                        <TableCell>Vendor PLB</TableCell>
                        <TableCell>Client Parting IATA</TableCell>
                        <TableCell>Client Parting PLB</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fareRows.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {row.paxType === "ADT"
                              ? "Adult"
                              : row.paxType === "CHD"
                                ? "Child"
                                : row.paxType === "INF"
                                  ? "Infant"
                                  : "Adult"}
                          </TableCell>
                          <TableCell>
                            {row.paxType === "ADT"
                              ? ((adultBase * clientROE) + (adultMarkup * clientROE)).toFixed(2)
                              : row.paxType === "CHD"
                                ? ((childBase * clientROE) + (childMarkup * clientROE)).toFixed(2)
                                : row.paxType === "INF"
                                  ? ((infantBase * clientROE) + (infantMarkup * clientROE)).toFixed(2)
                                  : adultBase}
                            <br />
                            <span style={{ fontSize: "8px" }}>
                              (BaseFare + Markup)
                            </span>
                          </TableCell>
                          <TableCell>
                            {row.paxType === "ADT"
                              ? (adultTax * clientROE).toFixed(2)
                              : row.paxType === "CHD"
                                ? (childTax * clientROE).toFixed(2)
                                : row.paxType === "INF"
                                  ? (infantTax * clientROE).toFixed(2)
                                  : adultTax}
                          </TableCell>
                          <TableCell>
                            {row.paxType === "ADT"
                              ? (adultBaggage * clientROE).toFixed(2)
                              : row.paxType === "CHD"
                                ? (childBaggage * clientROE).toFixed(2)
                                : row.paxType === "INF"
                                  ? (infantBaggage * clientROE).toFixed(2)
                                  : adultBaggage}
                          </TableCell>
                          <TableCell>
                            {row.paxType === "ADT"
                              ? (adultVendorIssuanceFee * clientROE).toFixed(2)
                              : row.paxType === "CHD"
                                ? (childVendorIssuanceFee * clientROE).toFixed(2)
                                : row.paxType === "INF"
                                  ? (infantVendorIssuanceFee * clientROE).toFixed(2)
                                  : adultVendorIssuanceFee}
                          </TableCell>
                          <TableCell>
                            {row.paxType === "ADT"
                              ? (adultVendorTax * clientROE).toFixed(2)
                              : row.paxType === "CHD"
                                ? (childVendorTax * clientROE).toFixed(2)
                                : row.paxType === "INF"
                                  ? (infantVendorTax * clientROE).toFixed(2)
                                  : adultVendorTax}
                          </TableCell>
                          <TableCell>
                            {row.paxType === "ADT"
                              ? (adultTotal * clientROE).toFixed(2)
                              : row.paxType === "CHD"
                                ? (childTotal * clientROE).toFixed(2)
                                : row.paxType === "INF"
                                  ? (infantTotal * clientROE).toFixed(2)
                                  : adultVendorTotal}
                          </TableCell>
                          <TableCell>
                            {row.paxType === "ADT"
                              ? (adultVendorIata * clientROE).toFixed(2)
                              : row.paxType === "CHD"
                                ? (childVendorIata * clientROE).toFixed(2)
                                : row.paxType === "INF"
                                  ? (infantVendorIata * clientROE).toFixed(2)
                                  : adultVendorIata}
                          </TableCell>
                          <TableCell>
                            {row.paxType === "ADT"
                              ? (adultVendorPlb * clientROE).toFixed(2)
                              : row.paxType === "CHD"
                                ? (childVendorPlb * clientROE).toFixed(2)
                                : row.paxType === "INF"
                                  ? (infantVendorPlb * clientROE).toFixed(2)
                                  : adultVendorPlb}
                          </TableCell>
                          <TableCell>
                            {row.paxType === "ADT"
                              ? (adultIata * clientROE).toFixed(2)
                              : row.paxType === "CHD"
                                ? (childIata * clientROE).toFixed(2)
                                : row.paxType === "INF"
                                  ? (infantIata * clientROE).toFixed(2)
                                  : adultIata}
                          </TableCell>
                          <TableCell>
                            {row.paxType === "ADT"
                              ? (adultPlb * clientROE).toFixed(2)
                              : row.paxType === "CHD"
                                ? (childPlb * clientROE).toFixed(2)
                                : row.paxType === "INF"
                                  ? (infantPlb * clientROE).toFixed(2)
                                  : adultPlb}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Paper>





            <Paper className="offline_bookingfooter border-label" elevation={3} style={{ marginTop: '16px', padding: '16px' }}>

              <div style={{ padding: '15px 0' }}>
                <Grid container spacing={2} style={{ padding: '25px 0', justifyContent: 'center' }}>

                  <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                    <Typography variant="subtitle2" className="bold-dark" gutterBottom>FOP</Typography>
                    <Typography variant="body2">{fopDetail.des}</Typography>
                  </Grid>
                  {fop === "Passenger Card" && <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }} >
                    <Typography variant="subtitle2" className="bold-dark" gutterBottom>Card number</Typography>
                    <Typography variant="body2">{cardNumber}</Typography>
                  </Grid>}
                  {fop === "Passenger Card" && <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                    <Typography variant="subtitle2" className="bold-dark" gutterBottom>Expiry Date</Typography>
                    <Typography variant="body2">{expiryDate}</Typography>
                  </Grid>}
                  <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                    <Typography variant="subtitle2" className="bold-dark" gutterBottom>Client ROE</Typography>
                    <Typography variant="body2">{clientROE}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                    <Typography variant="subtitle2" className="bold-dark" gutterBottom>Case ID</Typography>
                    <Typography variant="body2">{caseID}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                    <Typography variant="subtitle2" className="bold-dark" gutterBottom>Sales Staff</Typography>
                    <Typography variant="body2">{(user.title ? user.title + ". " : "") + user.firstName + " " + user.lastName}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                    <Typography variant="subtitle2" className="bold-dark" gutterBottom>End Client Name</Typography>

                    <Typography variant="body2">{endClientName}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                    <Typography variant="subtitle2" className="bold-dark" gutterBottom>End Client Email</Typography>
                    <Typography variant="body2">{clientEmail}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                    <Typography variant="subtitle2" className="bold-dark" gutterBottom>Corporate/Retailer</Typography>
                    <Typography variant="body2">{fema}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                    <Typography variant="subtitle2" className="bold-dark" gutterBottom>Pan Card</Typography>
                    <Typography variant="body2">{panCard}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                    <Typography variant="subtitle2" className="bold-dark" gutterBottom>MCO Number</Typography>
                    <Typography variant="body2">{mco}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                    <Typography variant="subtitle2" className="bold-dark" gutterBottom>MCO Charge</Typography>
                    <Typography variant="body2">{pgCharge}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                    <Typography variant="subtitle2" className="bold-dark" gutterBottom>MCO Parting</Typography>
                    <Typography variant="body2">{mcoParted}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                    <Typography variant="subtitle2" className="bold-dark" gutterBottom>MCO Remark</Typography>
                    <Typography variant="body2">{mcoRemark}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                    <Typography variant="subtitle2" className="bold-dark" gutterBottom>Source of Booking</Typography>
                    <Typography variant="body2">{source}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                    <Button
                      variant="outlined"
                      className="addButton"
                      color="primary"
                      startIcon={<ContentPasteGoIcon />}
                      onClick={() => {
                        setTicketModalOpen(true);
                      }}
                      style={{ marginRight: "10px" }}
                    >
                      View Ticket Image
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                    <Typography variant="subtitle2" className="bold-dark" gutterBottom></Typography>
                    <Typography variant="body2"></Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className='headerleft' style={{ paddingLeft: '50px' }}>
                    <Typography variant="subtitle2" className="bold-dark" gutterBottom></Typography>
                    <Typography variant="body2"></Typography>
                  </Grid>

                  <Grid item xs={12} className="full-width-item">
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                      <Typography variant="body2" style={{ marginRight: '20px', marginTop: '20px' }}>
                        <span className="clientsupplier1 text-bold">Client Payable:</span>
                        <span className='text-bold'> {clientCurrency ? clientCurrency : " "}{" "} {clientTotalWithClientROE}</span>
                      </Typography>
                      <Typography variant="body2" style={{ marginRight: '10px', marginTop: '20px' }}>
                        <span className="clientsupplier2 text-bold">Supplier Payable: </span>
                        <span className='text-bold'>{vendorCurrencyCode} {vendorTotal}</span>
                      </Typography>
                    </div>
                  </Grid>


                  <Grid item xs={12} className="full-width-item">
                    <div className="bordered-item">
                      <Typography variant="subtitle2" className="bold-dark" gutterBottom>Remarks:</Typography>
                      <Typography variant="body2">{importPNRRemarks}</Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className='d-flex justify-content-between mt-2'>
                <div></div>
                <div>
                  <Button className='me-2' variant="contained" onClick={() => { setValidateForm(false); scrollToTop(); }}>Edit</Button>
                  <Button variant="contained" color="primary" disabled={isImportBtnDisabled} autoFocus onClick={() => CreateImportBooking()}>Create Booking</Button>
                </div>
              </div>
            </Paper>
          </Grid>}
      <Dialog
        open={ticketModalopen}
        onClose={() => {
          setTicketModalOpen(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Ticket Image"}
        </DialogTitle>
        <DialogContent>
          <div className="custom-editor">
            {parse(tickeImage)}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PreviewOfflineBooking;
