import React, { useState, useRef, useEffect } from "react";
import {
    Drawer,
    TextField,
    Button,
    IconButton,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { TimePicker } from "@material-ui/pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Grid, Paper } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Autocomplete from "@mui/material/Autocomplete";
import airlineDetails from "../../data/AirlineDetails";
import airportDetails from "../../data/AirportDetails";
import cabinClass from "../../data/CabinClass";
import dayjs from "dayjs";

const ReissueCopyItinerary = ({
    // handleEditSegment,
    // handleRemoveSegment,
    setIsShowExistingItinerary,
    checkedRows,
    setCheckedRows,
    flight,
    action,
    segmentData,
    setSegmentData
}) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [editData, setEditData] = useState(null);


    let cabinDetails = cabinClass;
    const [minArrTime, setMinArrTime] = useState(dayjs());
    const today = dayjs();
    const addSegmentForm = useRef();

    const tablesData =
        flight &&
        flight.segGroups &&
        flight.segGroups
            .map((segGroup, segGroupIndex) => {
                const filteredSegments = segGroup.segments.filter(
                    (_, segmentIndex) => checkedRows && checkedRows[segGroupIndex]?.[segmentIndex]
                );

                return {
                    segGroupIndex,
                    origin: segGroup.origin,
                    destination: segGroup.destination,
                    departureOn: segGroup.departureOn,
                    segments: filteredSegments,
                };
            })
            .filter((table) => table.segments.length > 0);


    const openEditDrawer = (segment, segmentIndex, segGroupIndex) => {
        setEditData(JSON.parse(JSON.stringify({ segment, segmentIndex, segGroupIndex })));
        setIsDrawerOpen(true);
    };

    const handleRemoveSegment = (segmentIndex, groupType) => {
        setSegmentData((prevData) => {
            const updatedData = [...prevData];
            const updatedSegments = updatedData[groupType].segments.filter(
                (_, idx) => idx !== segmentIndex
            );

            updatedData[groupType].segments = updatedSegments;

            if (updatedSegments.length === 0) {
                return updatedData.filter((_, idx) => idx !== groupType);
            }

            return updatedData;
        });

        setCheckedRows((prevCheckedRows) => {
            const updatedCheckedRows = { ...prevCheckedRows };

            if (updatedCheckedRows[groupType]) {
                updatedCheckedRows[groupType][segmentIndex] = false;

                if (Object.values(updatedCheckedRows[groupType]).every((isChecked) => !isChecked)) {
                    delete updatedCheckedRows[groupType];
                }
            }

            return updatedCheckedRows;
        });
    };



    const handleInputChange = (fieldName, value) => {
        setEditData((prev) => ({
            ...prev,
            segment: {
                ...prev.segment,
                [fieldName]: value,
            },
        }));
    };

    useEffect(() => {
        if (checkedRows) {
            setSegmentData(
                flight &&
                flight.segGroups &&
                flight.segGroups
                    .map((segGroup, segGroupIndex) => {
                        const filteredSegments = segGroup.segments.filter(
                            (_, segmentIndex) => checkedRows && checkedRows[segGroupIndex]?.[segmentIndex]
                        );

                        return {
                            id: segGroup.id,
                            origin: segGroup.origin,
                            destination: segGroup.destination,
                            departureOn: segGroup.departureOn,
                            reissueTravelDate: segGroup.reissueTravelDate,
                            reIssueStatus: segGroup.reIssueStatus,
                            segments: filteredSegments,
                        };
                    })
                    .filter((table) => table.segments.length > 0) || []
            );
        }
    }, [checkedRows])

    const airlineFilterOptions = (options, { inputValue }) => {
        if (inputValue.length <= 2) {
            return options.filter(
                (option) => option.Code.toLowerCase() === inputValue.toLowerCase()
            );
        } else {
            return options.filter((option) =>
                option.Name.toLowerCase().includes(inputValue.toLowerCase())
            );
        }
    };

    function setDayJSDate(calDate) {
        let day = dayjs(calDate);
        let date = day.get("date");
        let month = day.get("month");
        let year = day.get("year");
        let temp = day.set("date", date).set("month", month).set("year", year);
        return dayjs(temp);
    }

    const onSubmitClick = () => {
        setSegmentData((prev) => {
            const updatedData = [...prev];
            const group = updatedData[editData.segGroupIndex];
            group.segments[editData.segmentIndex] = editData.segment;
            return updatedData;
        });

        setIsDrawerOpen(false);
    };

    const airportFilterOptions = (options, { inputValue }) => {
        if (inputValue.length <= 3) {
            return options.filter(
                (option) => option.city_code.toLowerCase() === inputValue.toLowerCase()
            );
        } else {
            return options.filter((option) =>
                option.airport_name.toLowerCase().includes(inputValue.toLowerCase())
            );
        }
    };

    function setDayJSDateTime(calDate, time) {
        let date = dayjs(calDate);
        let hour = dayjs(time).get("hour");
        let minute = dayjs(time).get("minute");
        let second = dayjs(time).get("second");
        let temp = date
            .set("hour", hour)
            .set("minute", minute)
            .set("second", second);
        return temp;
    }


    return (
        <div className="mt-5">
            <div className="addSegDiv copy-ressue-itenary-header">
                <div>Itinerary Details - Reissue Itinerary</div>
                <div>
                    <Button
                        variant="outlined"
                        className="addButton"
                        color="primary"
                        onClick={() => setIsShowExistingItinerary(true)}
                        style={{ marginRight: "10px" }}
                    >
                        Copy Segment From Existing
                    </Button>
                </div>
            </div>
            {segmentData && segmentData.length > 0 ? (
                segmentData.map((group, groupIndex) => (
                    <div style={{ marginBottom: "2rem" }} key={groupIndex}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Airline</TableCell>
                                    <TableCell align="center">Flight No</TableCell>
                                    <TableCell align="center">Origin</TableCell>
                                    <TableCell align="center">Terminal</TableCell>
                                    <TableCell align="center">Destination</TableCell>
                                    <TableCell align="center">Terminal</TableCell>
                                    <TableCell align="center">Departure</TableCell>
                                    <TableCell align="center">Arrival</TableCell>
                                    <TableCell align="center">Cabin Class</TableCell>
                                    <TableCell align="center">Booking Class</TableCell>
                                    <TableCell align="center">GDS PNR</TableCell>
                                    <TableCell align="center">Airline PNR</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {group.segments.map((segment, segmentIndex) => (
                                    <TableRow key={segmentIndex}>
                                        <TableCell align="center">{segment.mrkAirline}</TableCell>
                                        <TableCell align="center">{segment.flightNum}</TableCell>
                                        <TableCell align="center">{segment.origin}</TableCell>
                                        <TableCell align="center">{segment.depTerminal}</TableCell>
                                        <TableCell align="center">{segment.destination}</TableCell>
                                        <TableCell align="center">{segment.arrTerminal}</TableCell>
                                        <TableCell align="center">
                                            {new Date(segment.departureOn).toLocaleString()}
                                        </TableCell>
                                        <TableCell align="center">
                                            {new Date(segment.arrivalOn).toLocaleString()}
                                        </TableCell>
                                        <TableCell align="center">{segment.cabinClass}</TableCell>
                                        <TableCell align="center">{segment.rbd}</TableCell>
                                        <TableCell align="center">{segment.pnr}</TableCell>
                                        <TableCell align="center">{segment.airlinePnr}</TableCell>
                                        <TableCell className="d-flex" align="center">
                                            <EditIcon color="primary" style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                    openEditDrawer(segment, segmentIndex, groupIndex)
                                                } />
                                            <DeleteIcon className="text-danger ms-2" style={{ cursor: "pointer" }}
                                                onClick={() => handleRemoveSegment(segmentIndex, groupIndex)} />

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                ))
            ) : (
                <Typography variant="body1" align="center">
                    No data selected.
                </Typography>
            )}


            {/* Drawer for Editing Segment */}
            <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
            >
                {editData && (
                    <div className="edit-copy-segment">
                        <div className="m-2" style={{ fontWeight: "600", fontSize: "16px", opacity: "0.5" }}>Edit Segment Data</div>
                        <Grid container spacing={5} className="mt-2">
                            <Grid item xs={6} md={6}>
                                <Autocomplete
                                    id="PCC-select"
                                    value={
                                        editData.segment.mrkAirline !== ""
                                            ? airlineDetails.find(
                                                (v) => v.Code === editData.segment.mrkAirline
                                            )
                                            : null
                                    }
                                    onChange={(event, newValue) => {
                                        handleInputChange(
                                            "mrkAirline",
                                            newValue ? newValue.Code : ""
                                        );
                                    }}
                                    options={airlineDetails.filter((x) => x.Code !== "All")}
                                    getOptionLabel={(option) =>
                                        option.Name + " (" + option.Code + ")"
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className={
                                                action === "view"
                                                    ? "ticket-config-inputs-disabled"
                                                    : "ticket-config-inputs"
                                            }
                                            required
                                            label="Airline"
                                            value={
                                                editData.segment.mrkAirline !== ""
                                                    ? airlineDetails.find(
                                                        (v) => v.Code === editData.segment.mrkAirline
                                                    )
                                                    : null
                                            }
                                            color="secondary"
                                            size="small"
                                            validators={["required"]}
                                            errorMessages={["required"]}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    className="ticket-config-inputs"
                                    label="Flight No"
                                    size="small"
                                    color="secondary"
                                    value={editData.segment.flightNum}
                                    onChange={(event) => handleInputChange("flightNum", event.target.value)}
                                    required
                                    validators={["required", "matchRegexp:^[^\\s]+(\\s+[^\\s]+)*$"]}
                                    errorMessages={["required", "This field cannot be blank or contain only spaces"]}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Autocomplete
                                    options={airportDetails.filter(
                                        (option) => option.city_code !== "All"
                                    )} // Replace with your airline options
                                    value={
                                        editData.segment.origin !== ""
                                            ? airportDetails.find(
                                                (v) => v.city_code === editData.segment.origin
                                            )
                                            : null
                                    }
                                    getOptionLabel={(option) =>
                                        option.airport_name + " (" + option.city_code + ")"
                                    }
                                    onChange={(event, value) =>
                                        handleInputChange(
                                            "origin",
                                            value ? value.city_code : ""
                                        )
                                    }
                                    required
                                    renderInput={(params) => (
                                        <TextField
                                            label="Origin Airport"
                                            validators={["required"]}
                                            errorMessages={["required"]}
                                            required
                                            value={
                                                editData.segment.origin !== ""
                                                    ? airportDetails.find(
                                                        (v) => v.city_code === editData.segment.origin
                                                    )
                                                    : null
                                            }
                                            {...params}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    // validators={["required"]}
                                    // errorMessages={["required"]}
                                    label="Origin Terminal"
                                    value={editData.segment.depTerminal}
                                    onChange={(e) =>
                                        handleInputChange("depTerminal", e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Autocomplete
                                    options={airportDetails.filter(
                                        (option) => option.city_code !== "All"
                                    )} // Replace with your airline options
                                    value={
                                        editData.segment.destination !== ""
                                            ? airportDetails.find(
                                                (v) =>
                                                    v.city_code === editData.segment.destination
                                            )
                                            : null
                                    }
                                    getOptionLabel={(option) =>
                                        option.airport_name + " (" + option.city_code + ")"
                                    }
                                    onChange={(event, value) =>
                                        handleInputChange(
                                            "destination",
                                            value ? value.city_code : ""
                                        )
                                    }
                                    required
                                    renderInput={(params) => (
                                        <TextField
                                            label="Destination Airport"
                                            validators={["required"]}
                                            required
                                            value={
                                                editData.segment.destination !== ""
                                                    ? airportDetails.find(
                                                        (v) =>
                                                            v.city_code === editData.segment.destination
                                                    )
                                                    : null
                                            }
                                            errorMessages={["required"]}
                                            {...params}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    // validators={["required"]}
                                    // errorMessages={["required"]}
                                    label="Destination Terminal"
                                    value={editData.segment.arrTerminal}
                                    onChange={(e) =>
                                        handleInputChange("arrTerminal", e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        onChange={(newValue) => {
                                            handleInputChange(
                                                "departureOn",
                                                newValue.$d ? newValue.$d : ""
                                            );
                                        }}
                                        format="DD/MM/YYYY"
                                        // timezone="Europe/London"
                                        defaultValue={today}
                                        disablePast
                                        required
                                        value={editData.segment.departureOn ? dayjs(editData.segment.departureOn) : null}
                                        label="Departure Date *"
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TimePicker
                                    disabled={segmentData.depDate === null}
                                    clearable
                                    disablePast
                                    ampm={false}
                                    label="Departure Time"
                                    // timezone="Europe/London"
                                    value={dayjs(editData.segment.departureOn)}
                                    onChange={(newValue) => {
                                        handleInputChange("departureOn", newValue ? newValue : "");
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        disabled={segmentData.depDate === null}
                                        onChange={(newValue) => {
                                            handleInputChange(
                                                "arrivalOn",
                                                newValue.$d ? newValue.$d : ""
                                            );
                                        }}
                                        format="DD/MM/YYYY"
                                        // timezone="Europe/London"
                                        defaultValue={
                                            segmentData.depDate
                                                ? setDayJSDate(segmentData.depDate)
                                                : today
                                        }
                                        minDate={
                                            segmentData.depDate
                                                ? setDayJSDate(segmentData.depDate)
                                                : today
                                        }
                                        required
                                        value={editData.segment.arrivalOn ? dayjs(editData.segment.arrivalOn) : null}
                                        label="Arrival Date*"
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TimePicker
                                    disabled={
                                        segmentData.arrDate === null ||
                                        segmentData.depDate === null ||
                                        segmentData.depTime === null
                                    }
                                    clearable
                                    ampm={false}
                                    // timezone="Europe/London"
                                    label="Arrival Time"
                                    minTime={minArrTime}
                                    defaultValue={minArrTime}
                                    value={dayjs(editData.segment.arrivalOn)}
                                    onChange={(newValue) => {
                                        handleInputChange("arrivalOn", newValue ? newValue : "");
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Autocomplete
                                    options={cabinDetails.filter((x) => x.label !== "All")} // Replace with your airport options
                                    value={
                                        editData.segment.cabinClass !== ""
                                            ? cabinDetails.find(
                                                (v) => v.label === editData.segment.cabinClass
                                            )
                                            : null
                                    }
                                    getOptionLabel={(option) => option?.label}
                                    onChange={(event, value) => {
                                        handleInputChange("cabinClass", value ? value.value : "");
                                    }}
                                    required
                                    renderInput={(params) => (
                                        <TextField
                                            validators={["required"]}
                                            errorMessages={["required"]}
                                            required
                                            value={
                                                editData.segment.cabinClass !== ""
                                                    ? cabinDetails.find(
                                                        (v) => v.label === editData.segment.cabinClass
                                                    )
                                                    : null
                                            }
                                            label="Cabin Class"
                                            {...params}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    validators={[
                                        "required",
                                        "matchRegexp:^[^\\s]+(\\s+[^\\s]+)*$",
                                    ]}
                                    errorMessages={[
                                        "required",
                                        "This field cannot be blank or contain only spaces",
                                    ]}
                                    label="Booking Class"
                                    required
                                    value={editData.segment.rbd}
                                    onChange={(e) =>
                                        handleInputChange("rbd", e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    validators={[
                                        "required",
                                        "matchRegexp:^[^\\s]+(\\s+[^\\s]+)*$",
                                    ]}
                                    errorMessages={[
                                        "required",
                                        "This field cannot be blank or contain only spaces",
                                    ]}
                                    label="GDS PNR"
                                    required
                                    value={editData.segment.pnr}
                                    onChange={(e) =>
                                        handleInputChange("pnr", e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    label="Airline PNR"
                                    value={editData.segment.airlinePNR}
                                    onChange={(e) =>
                                        handleInputChange("airlinePnr", e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={9} className="text">
                                <Button
                                    className="modal_submit_btn ms-2"
                                    variant="filled"
                                    color="primary"
                                    size="large"
                                    style={{
                                        textTransform: "none",
                                        width: "100%",
                                    }}
                                    onClick={onSubmitClick}
                                >
                                    {action == "edit" ? "Update" : "Add"}
                                </Button>
                            </Grid>
                        </Grid>{" "}

                    </div>
                )}
            </Drawer>
        </div>
    );
};

export default ReissueCopyItinerary;
