import React, { useState, useEffect } from 'react';
import './Otp.css';
import { Button } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import { checkIfUserStaff, getCurrentRole, isClientUserHasPermission } from '../../../services/AuthService';
import { useHistory } from "react-router-dom";
import getIP from '../../../providers/getIP';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from "react-i18next";
import RiseLoading from '../../Loader/RiseLoader';

const Otp = ({ setOtpPopUp, userName, otp, setOtp, signature, newBrowser, expired, setExpired, disableOtpInput, setDisableOtpInput, resMsg, setResMsg, notValid, setNotValid
    , resOtpTime, setResOtpTime, retryCount, setRetryCount, setDisplayHeader, resUserData, resToken, resRefreshToken, subUserStatus, updateUserRole, showLoader, setShowLoader
}) => {
    const [showReOtp, setShowReOtp] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);
    // const [showLoader, setShowLoader] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        const expiryTime = new Date(Date.parse(resOtpTime));

        const interval = setInterval(() => {
            const now = new Date();
            const timeDifference = expiryTime.getTime() - now.getTime();

            if (timeDifference <= 0) {
                clearInterval(interval);
                setTimeLeft(0);
                setExpired(true);
                setNotValid(true);
                if (subUserStatus || subUserStatus === "true") {
                    setResMsg(t('otp_expired'));
                    setDisableOtpInput(false);
                    setShowReOtp(true);
                    setRetryCount(false);
                    localStorage.removeItem("otpFail");
                    // window?.dataLayer?.push({
                    //     event: 'otp_login',
                    //     message: t('The OTP in your email has expired. Please request a new one'),
                    //     userId: resUserData.user_id,
                    //     userEmail: userName,
                    // });
                }
                else {
                    setResMsg("OTP has expired.");
                    // window?.dataLayer?.push({
                    //     event: 'otp_login',
                    //     message: t('OTP has expired.'),
                    //     userId: resUserData.user_id,
                    //     userEmail: userName,
                    // });
                }
                setDisableOtpInput(false);
                if (!subUserStatus || subUserStatus === "false") {
                    const timer = setTimeout(() => {
                        setOtpPopUp(false);
                    }, 2000);
                    return () => clearTimeout(timer);
                }

            } else {
                setTimeLeft(timeDifference);
            }
            if (timeDifference <= 180000) {
                setShowReOtp(true);
            }
            else {
                setShowReOtp(false);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [resOtpTime]);

    const formatTime = (time) => {
        const minutes = String(Math.floor((time % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
        const seconds = String(Math.floor((time % (1000 * 60)) / 1000)).padStart(2, '0');
        return `${minutes}:${seconds}`;
    };


    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        if (element.nextSibling && element.value !== "") {
            element.nextSibling.focus();
        }
    };

    const handleKeyUp = (e, index) => {
        if (e.key === 'Backspace' && e.target.previousSibling) {
            e.target.previousSibling.focus();
        }
    };

    const handleSubmit = async () => {
        setShowLoader(true);
        if (subUserStatus || subUserStatus === "true") {
            setShowLoader(false);
            updateUserRole("edit");
        }
        else {
            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
            };

            const ip = await getIP();

            const data = `BrowserSignature=${signature}&UserEmail=${userName}&OTP=${otp.join('')}&IPAddress=${ip}`;

            await axios
                .post(
                    process.env.REACT_APP_CORE_URL + "api/Configuration/EmailVerification/OTPAuthorization", data,
                    { headers }
                )
                .then((response) => {
                    setShowLoader(false);
                    setNotValid(false);
                    if (response.data === "Authorized user") {
                        setDisplayHeader(true);
                        // const userRole = getCurrentRole(); 
                        resUserData && localStorage.setItem("user", JSON.stringify(resUserData));
                        resToken && localStorage.setItem("token", resToken);
                        resRefreshToken && localStorage.setItem("refreshtoken", resRefreshToken);
                        if ((isClientUserHasPermission("IsSearch", "FlightbookDetail") && !checkIfUserStaff()) || resUserData.role === "ClientSuperAdmin" || checkIfUserStaff()) {
                            history.push("/flight");
                            //     window?.dataLayer?.push({
                            //         event: 'otp_login',
                            //         message: 'OTP Validated',
                            //         userId: resUserData.user_id,
                            //         userEmail: userName,
                            //     });
                        }
                        else {
                            history.push("/Unauthorized");
                        }
                    } else if (response.data && response.data.includes("~PasswordExpiry~")) {
                        const splitData = response.data.split("~");
                        const token = splitData[2];
                        history.push("/ResetPassword?email=" + userName + "&token=" + token);
                    }
                })
                .catch((err) => {
                    setShowLoader(false);
                    setOtp(new Array(4).fill(''));
                    setNotValid(true);
                    if (err.response.data.includes("Retry Count")) {
                        setResMsg(t('too_many_attempts'));
                        // window?.dataLayer?.push({
                        //     event: 'otp_login',
                        //     message: 'Too many incorrect OTP attempts. Please try after sometimed',
                        //     userId: resUserData.user_id,
                        //     userEmail: userName,
                        // });
                        localStorage.setItem("otpFail", true);
                        setDisableOtpInput(true);
                        setRetryCount(true);
                    }
                    else {
                        setRetryCount(false);
                        // window?.dataLayer?.push({
                        //     event: 'otp_login',
                        //     message: ` ${err.response.data}`,
                        //     userId: resUserData.user_id,
                        //     userEmail: userName,
                        // });
                        setResMsg(err.response.data);
                        console.log(err);
                    }

                });
        }
    };

    const resendOtp = async () => {
        setShowLoader(true);
        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
        };

        const data = `UserEmail=${userName}&BrowserSignature=${(subUserStatus || subUserStatus === "true") ? '""&OTPType=2' : signature}`;

        await axios
            .post(
                process.env.REACT_APP_CORE_URL + "api/Configuration/EmailVerification/ReSendOTP", data,
                { headers }
            )
            .then((response) => {
                setShowLoader(false);
                if (response.data.includes("OTP Send Succuessfully")) {
                    setResMsg(t('resend_otp_success'));
                    setExpired(false);
                    setNotValid(false);
                    // window?.dataLayer?.push({
                    //     event: 'otp_login',
                    //     message: 'Resend OTP - OTPhas been send your email successfull',
                    //     userId: resUserData.user_id,
                    //     userEmail: userName,
                    // });
                    const dateTimeString = response.data.slice(23);
                    const expiryTime = new Date(dateTimeString);
                    const expiryTimeUTC = new Date(expiryTime.getTime() - expiryTime.getTimezoneOffset() * 60000);
                    setResOtpTime(expiryTimeUTC);
                    if (resUserData) {
                        window.dataLayer.push({
                            event: 'otp_login',
                            message: 'Resend OTP - OTPhas been send your email successfull',
                            userId: resUserData.user_id,
                            userEmail: userName,
                        });
                    }
                }
            })
            .catch((err) => {
                setShowLoader(false);
                console.log(err);
            });
    };

    // useEffect(() => {
    //     if (showReOtp && retryCount) {
    //         setDisableOtpInput(false);
    //         setResMsg("");

    //     }
    // }, [showReOtp, retryCount]);


    return (
        <div className='otp-main-container'>
            <div className='p-3 text-center d-flex justify-content-between'>
                <div></div>

                <h2>{(subUserStatus || subUserStatus === "true") ? `User Activation - ` : ``} {t('otp_title')}</h2>
                <div><CloseIcon style={{ cursor: "pointer" }} onClick={() => setOtpPopUp(false)} /></div>
            </div>
            <div className='p-3 text-center'>
                {(subUserStatus || subUserStatus === "true") ?
                    <p>{t('security_msg')}</p> :
                    <p>{newBrowser ? `${t('new_browser_msg')}` : `${t('old_browser_msg')}`}</p>

                }
            </div>
            {/* {expired && <div className='text-danger text-center' style={{ fontSize: "14px" }}><p>The OTP that was sent to your email has expired. Please request a new OTP to continue with your login.</p></div>} */}
            <ValidatorForm
                onSubmit={handleSubmit}
                onError={(errors) => console.log(errors)}
            >
                <div className="otp-container">
                    {otp.map((data, index) => (
                        <input disabled={disableOtpInput}
                            className="otp-input"
                            type="text"
                            maxLength="1"
                            key={index}
                            value={data}
                            onChange={(e) => handleChange(e.target, index)}
                            onKeyUp={(e) => handleKeyUp(e, index)}
                        />
                    ))}
                </div>
                {!disableOtpInput && showReOtp && !retryCount &&
                    <div className='text-center pb-3 resend-txt'>
                        <span className=
                            'resend-otp-t'
                            // {timeLeft === 0 ? 'resend-otp-t' : ''}
                            onClick={resendOtp}>
                            {/* {timeLeft === 0 ? 'Resend OTP' : `Resend OTP in ${formatTime(timeLeft)}`} */}
                            {t('resend_otp')}
                        </span>
                    </div>
                }
                <div className='text-center pb-3'>
                    <Button className='srn-btn' type="submit" disabled={otp.join('').length !== 4 || disableOtpInput}>
                        {t('submit_otp')}
                    </Button>
                </div>
            </ValidatorForm>

            <div className={(notValid ? 'otp-exp-text' : 'otp-valid-text') + ' text-center pb-3'}>
                {resMsg}
            </div>

            <div className='text-center pb-3'>
                <span style={{ fontSize: "13px" }}>
                    {timeLeft === 0 ? "" : `${t("OTP expires in ")}${formatTime(timeLeft)}`}
                </span>
            </div>
            {/* {showLoader && <RiseLoading />} */}
        </div>
    );
};

export default Otp;
