import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Grid, Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
// import DatePicker from "@mui/lab/DatePicker";
// import TimePicker from "@mui/lab/TimePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import cabinClass from "../../data/CabinClass";
import AddSegmentForm from "./AddSegmentForm";
import Drawer from "@material-ui/core/Drawer";
import moment from "moment";
import findAirlinebyCode from "../../functions/findAirlineByCode";
import OfflineItineraryParser from "../../providers/OfflineItineraryParser/OfflineItineraryParser";

const initialSegment = {
  airline: null,
  flightNo: "",
  originAirport: null,
  originTerminal: "",
  destinationAirport: null,
  destinationTerminal: "",
  depDate: null,
  depTime: null,
  arrDate: null,
  arrTime: null,
  cabinClass: null,
  bookingClass: "",
  gdsPNR: "",
  airlinePNR: "",
};

const SegmentForm = ({
  onwardSegments,
  setOnwardSegments,
  returnSegments,
  setReturnSegments,
  selectedTripType,
  setSelectedTripType,
  selectedType,
  setSelectedType,
  value
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [itineraryDrawer, setItineraryDrawer] = useState(false);
  const [action, setAction] = useState("");
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [itineraryText, setItineraryText] = useState("");
  const [itineraries, setItineraries] = useState([]);
  const [error, setError] = useState("");

  const handleInputChange = (segmentIndex, fieldName, value) => { };
  useEffect(() => {
    console.log("segmentData Segment  Form");
    console.log("onwardSegments", onwardSegments);
    console.log("returnSegments", returnSegments);
    console.log("selectedTripType", selectedTripType);
  }, [onwardSegments, selectedTripType, returnSegments]);
  const handleSnackbarToggle = () => {
    // setShowLoader(true);
    setDrawer(false);
    setOnwardSegments((onwardSegments) => structuredClone(onwardSegments));
    setReturnSegments((returnSegments) => structuredClone(returnSegments));
    setSelectedTripType((selectedTripType) =>
      structuredClone(selectedTripType)
    );
    setSelectedType((selectedType) => structuredClone(selectedType));
    // setShowLoader(false);
  };
  const toggleDrawer = (value) => (event) => {
    console.log("toggleDrawer");
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(value);
  };
  const toggleItineraryDrawer = (value) => (event) => {
    console.log("toggleDrawer");
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setItineraryDrawer(value);
  };
  function handleAddSegment(type) {
    setAction("add");
    setSelectedType((selectedType) =>
      type === "onward" ? "onward" : "return"
    );
    setDrawer(true);
  }
  const handleEditSegment = (index, headText, type) => {
    setAction("edit");
    setDrawer(true);
    setSelectedType((selectedType) =>
      headText === "Onward Segment" ? "onward" : "return"
    );
    let item =
      headText === "Onward Segment"
        ? structuredClone(onwardSegments[index])
        : structuredClone(returnSegments[index]);
    setSelectedIndex((selectedIndex) => index);
    setSelectedItem((selectedItem) => structuredClone(item));
  };

  const handleRemoveSegment = (segmentIndex, isReturnSegment) => {
    const segments = isReturnSegment
      ? [...returnSegments]
      : [...onwardSegments];
    segments.splice(segmentIndex, 1);

    isReturnSegment ? setReturnSegments(segments) : setOnwardSegments(segments);
  };
  function handlePasteSegmentData(type) {
    setSelectedType((selectedType) =>
      type === "onward" ? "onward" : "return"
    );
    setItineraries((itineraries) => '');
    setItineraryText((itineraryText) => '');
    setError((error) => '');
    setItineraryDrawer(true);

  }
  return (
    <>
      {/*  <Paper className="OfflineBookingPaper" elevation={3}> */}
      <div className="offline-itenary-container-import">
        <div>Itinerary Details</div>
      </div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Drawer
          anchor="right"
          open={drawer}
          onClose={toggleDrawer(false)}
          BackdropProps={{ invisible: true }}
          variant="persistent"
          className="segformdrawer-width"
          transitionDuration={{ enter: 500, exit: 500 }}
        >
          {/* <div className="drawer-min-width-50"> */}
          <AddSegmentForm
            selectedItem={selectedItem}
            toggleDrawer={toggleDrawer}
            action={action}
            handleSnackbarToggle={handleSnackbarToggle}
            setDrawer={setDrawer}
            setShowLoader={setShowLoader}
            showLoader={showLoader}
            returnSegments={returnSegments}
            onwardSegments={onwardSegments}
            mode={action}
            type={selectedType}
            handleInputChange={handleInputChange}
            setOnwardSegments={setOnwardSegments}
            setReturnSegments={setReturnSegments}
            selectedTripType={selectedTripType}
            setSelectedTripType={setSelectedTripType}
            index={selectedIndex}
            value={value}
          ></AddSegmentForm>
          {/* </div> */}
        </Drawer>
        <Drawer
          anchor="right"
          open={itineraryDrawer}
          onClose={toggleItineraryDrawer(false)}
          BackdropProps={{ invisible: true }}
          variant="persistent"
          className="segformdrawer-width"
          transitionDuration={{ enter: 500, exit: 500 }}
          sx={{ maxWidth: '80vw' }}
        >
          {/* <div className="drawer-min-width-50"> */}
          <OfflineItineraryParser
            toggleItineraryDrawer={toggleItineraryDrawer}
            setItineraryDrawer={setItineraryDrawer}
            itineraryDrawer={itineraryDrawer}
            type={selectedType}
            setOnwardSegments={setOnwardSegments}
            setReturnSegments={setReturnSegments}
            selectedTripType={selectedTripType}
            itineraryText={itineraryText}
            setItineraries={setItineraries}
            itineraries={itineraries}
            setItineraryText={setItineraryText}
            error={error}
            setError={setError}
          ></OfflineItineraryParser>
          {/* </div> */}
        </Drawer>

        <FormControl>
          {" "}
          <div className="tripTypeDiv">
            <FormLabel
              id="demo-radio-buttons-group-label"
              style={{ margin: "0px 10px" }}
            >
              Trip Type
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="onward"
              row
              name="radio-buttons-group"
              style={{ paddingLeft: "10px" }}
            >
              <FormControlLabel
                value="onward"
                onClick={() => {
                  setSelectedTripType((selectedTripType) => "onward");
                  // setSelectedType((selectedType) => "onward");
                }}
                control={<Radio />}
                label="Onward"
              />
              <FormControlLabel
                value="return"
                onClick={() => {
                  setSelectedTripType((selectedTripType) => "return");
                  // setSelectedType((selectedType) => "return");
                }}
                control={<Radio />}
                label="Return"
              />
            </RadioGroup>
          </div>{" "}
        </FormControl>
      </Grid>
      {/* <Grid item xs={12}> */}
      {selectedTripType === "onward" && (
        <SegmentTable
          segments={onwardSegments}
          selectedTripType={selectedTripType}
          setSelectedTripType={setSelectedTripType}
          handleEditSegment={handleEditSegment}
          headText={"Onward Segment"}
          handleInputChange={(...args) => handleInputChange(...args, false)}
          handleAddSegment={handleAddSegment}
          handlePasteSegmentData={handlePasteSegmentData}
          handleRemoveSegment={(...args) => handleRemoveSegment(...args, false)}
        />
      )}

      {selectedTripType === "return" && (
        <>
          <SegmentTable
            segments={onwardSegments}
            selectedTripType={selectedTripType}
            setSelectedTripType={setSelectedTripType}
            handleEditSegment={handleEditSegment}
            headText={"Onward Segment"}
            handleInputChange={(...args) => handleInputChange(...args, false)}
            handleAddSegment={handleAddSegment}
            handlePasteSegmentData={handlePasteSegmentData}
            handleRemoveSegment={(...args) =>
              handleRemoveSegment(...args, false)
            }
          />
          <SegmentTable
            segments={returnSegments}
            selectedTripType={selectedTripType}
            setSelectedTripType={setSelectedTripType}
            handleEditSegment={handleEditSegment}
            headText={"Return Segment"}
            handleInputChange={(...args) => handleInputChange(...args, true)}
            handleAddSegment={handleAddSegment}
            handlePasteSegmentData={handlePasteSegmentData}
            handleRemoveSegment={(...args) =>
              handleRemoveSegment(...args, true)
            }
          />
        </>
      )}
      {/* </Grid> */}

      {/* </Paper> */}
    </>
  );
};

const SegmentTable = ({
  segments,
  handleInputChange,
  handleEditSegment,
  handleAddSegment,
  handleRemoveSegment,
  handlePasteSegmentData,
  headText,
}) => {
  return (
    <div>
      <div className="addSegDiv">
        <Typography variant="h6">{headText}</Typography>
        <div>
          <Button
            variant="outlined"
            className="addButton"
            color="primary"
            startIcon={<ContentPasteGoIcon />}
            onClick={() => {
              handlePasteSegmentData(
                headText === "Return Segment" ? "return" : "onward"
              );
            }}
            style={{ marginRight: "10px" }}
          >
            Paste Segment
          </Button>
          <Button
            variant="outlined"
            className="addButton"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              handleAddSegment(
                headText === "Return Segment" ? "return" : "onward"
              );
            }}
            disabled={segments.length >= 6}
          >
            Add Segment
          </Button>
        </div>
      </div>
      <Table>
        {/* Table Headers */}
        <TableHead>
          <TableRow>
            <TableCell>Airline</TableCell>
            <TableCell>Flight No</TableCell>
            <TableCell>
              Origin
              {/* Airport */}
            </TableCell>
            <TableCell>Terminal</TableCell>
            <TableCell>
              Destination
              {/* Airport */}
            </TableCell>
            <TableCell>Terminal</TableCell>
            {/* <TableCell>Dep Date</TableCell>
            <TableCell>Dep Time</TableCell>
            <TableCell>Arr Date</TableCell>
            <TableCell>Arr Time</TableCell> */}

            <TableCell>
              Departure
              {/* <br></br>Date & Time */}
            </TableCell>
            <TableCell>
              Arrival
              {/* <br></br>Date & Time */}
            </TableCell>
            <TableCell>Cabin Class</TableCell>
            <TableCell>Booking Class</TableCell>
            <TableCell>GDS PNR</TableCell>
            <TableCell>Airline PNR</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        {/* Table Body */}
        <TableBody>
          {segments &&
            segments.length > 0 &&
            segments.map((segment, index) => (
              <TableRow key={index}>
                <TableCell>{segment.airline}</TableCell>
                {/* <TableCell>{findAirlinebyCode(segment.airline)}</TableCell> */}
                <TableCell>{segment.flightNo}</TableCell>
                <TableCell>{segment.originAirport}</TableCell>
                <TableCell>{segment.originTerminal}</TableCell>
                <TableCell>{segment.destinationAirport}</TableCell>
                <TableCell>{segment.destinationTerminal}</TableCell>
                <TableCell>
                  <span>{moment(segment?.depTime?.$d).format("HH:mm")}</span>
                  <br></br>
                  <span>
                    {moment(segment?.depDate?.$d).format("ddd,Do MMM  YYYY")}{" "}
                  </span>
                </TableCell>
                {/* <TableCell>{moment(segment.depTime).format("HH:mm")}</TableCell> */}
                <TableCell>
                  <span> {moment(segment?.arrTime?.$d).format("HH:mm")}</span>
                  <br></br>
                  <span>
                    {" "}
                    {moment(segment?.arrDate?.$d).format(
                      "ddd,Do MMM  YYYY"
                    )}{" "}
                  </span>
                </TableCell>
                {/* <TableCell>
                  {moment(segment.arrTime).format("HH:mm")}{" "}
                </TableCell> */}
                <TableCell>{(cabinClass.find(
                  (v) => v.value === segment.cabinClass
                )?.label)}</TableCell>
                <TableCell>{segment.bookingClass}</TableCell>
                <TableCell>{segment.gdsPNR}</TableCell>
                <TableCell>{segment.airlinePNR}</TableCell>

                <TableCell>
                  <IconButton
                    color="primary"
                    className="editIcon"
                    onClick={() =>
                      handleEditSegment(
                        index,
                        headText,
                        headText === "Return Segment" ? "return" : "onward"
                      )
                    }
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    className="deleteIcon"
                    onClick={() => handleRemoveSegment(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default SegmentForm;
