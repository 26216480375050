const popularAirports = [
  {
    sr: 12050,
    city: "Mumbai",
    city_code: "BOM",
    airport_name: "Chhatrapati Shivaji",
    country: "India",
  },
  {
    sr: 4444,
    city: "Delhi",
    city_code: "DEL",
    airport_name: "Indira Gandhi Intl",
    country: "India",
  },
  {
    sr: 20243,
    city: "Bangkok",
    city_code: "DMK",
    airport_name: "Don Muang Airport",
    country: "Thailand",
  },
  {
    sr: 1432,
    city: "Bangalore",
    city_code: "BLR",
    airport_name: "KEMPEGOWDA INTL",
    country: "India",
  },
  {
    sr: 14700,
    city: "Pune",
    city_code: "PNQ",
    airport_name: "Lohegaon",
    country: "India",
  },
  {
    sr: 7318,
    city: "Hyderabad",
    city_code: "HYD",
    airport_name: "Rajiv Gandhi International Airport",
    country: "India",
  },
  {
    sr: 8962,
    city: "Kolkata",
    city_code: "CCU",
    airport_name: "Netaji Subhas Chandra",
    country: "India",
  },
  {
    sr: 3346,
    city: "Chennai Madras",
    city_code: "MAA",
    airport_name: "Anna International Airport",
    country: "India",
  },
  {
    sr: 6210,
    city: "Goa",
    city_code: "GOI",
    airport_name: "Dabolim",
    country: "India",
  },
  {
    sr: 4766,
    city: "Dubai",
    city_code: "DXB",
    airport_name: "Dubai",
    country: "United Arab Emirates",
  },
  {
    sr: 16702,
    city: "Singapore",
    city_code: "SIN",
    airport_name: "Changi",
    country: "Singapore",
  },
  {
    sr: 8402,
    city: "Kathmandu",
    city_code: "KTM",
    airport_name: "Tribhuvan",
    country: "Nepal",
  },
  {
    sr: 20372,
    city: "Abu Dhabi",
    city_code: "ZVJ",
    airport_name: "Abu Dhabi Bus Station",
    country: "United Arab Emirates",
  },
  {
    sr: 16466,
    city: "Sharjah",
    city_code: "SHJ",
    airport_name: "Sharjah",
    country: "United Arab Emirates",
  },
  {
    sr: 20224,
    city: "New York  NY All airports",
    city_code: "NYC",
    airport_name: "New York",
    country: "United States",
  },
  {
    sr: 20229,
    city: "London UK All Airports",
    city_code: "LON",
    airport_name: "London",
    country: "United Kingdom",
  },
  {
    sr: 20176,
    city: "Hong Kong",
    city_code: "HKG",
    airport_name: "Hong Kong International",
    country: "China",
  },
  {
    sr: 15770,
    city: "San Francisco",
    city_code: "SFO",
    airport_name: "San Francisco International",
    country: "United States",
  },
  {
    sr: 10024,
    city: "London",
    city_code: "LHR",
    airport_name: "Heathrow",
    country: "United Kingdom",
  },
  {
    sr: 13660,
    city: "Paris",
    city_code: "CDG",
    airport_name: "Charles De Gaulle",
    country: "France",
  },
];

export default popularAirports;
