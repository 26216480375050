import axios from "axios";

export async function getStaffUsers() {
    try {
        const filter = "ClinetId==" + 0;
        const response = await axios.get(
            `${process.env.REACT_APP_IDENTITY_SERVER}ids/User?filters=${filter}`
        );
        return response.data.filter(
            (el) =>
                el.emailConfirmed === true &&
                el.firstName !== undefined &&
                el.lastName !== undefined &&
                el.title !== undefined
        );
    } catch (err) {
        console.error("Error fetching staff users:", err);
        return [];
    }
}
