import React, { useEffect, useState } from "react";
import "./HoldRulesDrawer.css";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
const style = {
    position: 'absolute',
    top: '80%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 500,
    overflow: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    marginBottom: '10px',
    padding: '0px 22px'

};
export default function HoldRulesDrawer({ processHold, open, close, holdCharge, isHoldChargeRefundable, currencyCode, total }) {
    const [termsConditionsCheck, setTermsConditionsCheck] = useState(false);
    const [isImportBtnDisabled, setisImportBtnDisabled] = useState(true);
    const { t } = useTranslation();
    return (
        <Modal
            size="md"
            show={open}
            backdrop="static"
            keyboard={false}
            className="hold-rules-modal"
            centered
        >
            <Box sx={style}>
                <div style={{ marginBottom: '10px' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <div className="hold-close-button-container">
                            <Button
                                variant="outlined"
                                size="small"
                                className="hold-close-button"
                                onClick={close}>
                                {t("Close")}
                            </Button>
                        </div>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div>
                            <div style={{ marginBottom: '10px' }}>
                                {
                                    // holdCharge > 0 &&
                                    (
                                        <div className="holdAmountRow">
                                            <div className="col-6" style={{ padding: '10px', borderRight: '1px dotted #000' }}>
                                                <p>{t("Booking Amount Lock Fee")}:
                                                    <br></br>
                                                    <span style={{ marginRight: '5px' }}>
                                                        {currencyCode}
                                                    </span>
                                                    <span style={{ marginRight: '5px' }}>
                                                        {holdCharge}
                                                    </span>
                                                    {
                                                        isHoldChargeRefundable ?
                                                            <span style={{ color: "green", fontSize: '12px' }}>{t("Refundable")}</span>
                                                            :
                                                            <span style={{ color: "red", fontSize: '12px' }}>{t("Non refundable")}</span>
                                                    }
                                                </p>
                                            </div>
                                            <div className="col-6" style={{ padding: '10px' }}>
                                                <p>{t("Amount to be Paid Later")}:
                                                    <br></br>
                                                    <span style={{ marginRight: '5px' }}>
                                                        {currencyCode}
                                                    </span>
                                                    <span>
                                                        {(total - holdCharge)}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        // 
                                    )
                                }
                                {
                                    isHoldChargeRefundable &&
                                    (<p>
                                        {t("Note: Advance Flight booking Amount will be")} <span className="refundable">{t("Refunded")} </span> {t("to your future credit,")}
                                        {t("if booking gets cancelled before issuance and if the ticket is booked,")}
                                        {t("Hold PNR will only create booking at Airline end,")}
                                        {t("but no tickets will be issued until you confirm ticketing.")}
                                        {t("We are not responsible,if any changes done at Airline end.")}
                                    </p>)
                                }
                                {
                                    !isHoldChargeRefundable &&
                                    (<p>
                                        {t("Note: Advance Flight booking Amount will be")} <span className="nonRefundable"> {t("Non- Refunded")}</span>, {t("if booking gets cancelled before issuance and if the ticket is booked,amount will be adjusted in Final fare.")}
                                        {t("Hold PNR will only create booking at Airline end, but no tickets will be issued until you confirm ticketing.")}
                                        {t("We are not responsible,if any changes done at Airline end.")}
                                    </p>)
                                }
                            </div>
                            <div>
                                <ul>
                                    <li>{t("Given fares and flight timings are as of current availability and are subject to change without prior notice.")}</li>
                                    <li>{t("Hold PNR will only create booking at the Airline end, but no tickets will be issued until you confirm ticketing.")}</li>
                                    <li>{t("Quoted fare is not valid until ticketed/Ticket issued.")}</li>
                                    <li style={{ fontWeight: 'bolder' }}>{t("Ancillary  services needs to be booked at the time of confirmation")}</li>
                                    <li>{t("Fares of Un-ticketed bookings are subject to change if any changes are announced by the airline.")}</li>
                                    <li>{t("Failure to issue a ticket before the given Time limit results in auto cancellation of the reservations.")}</li>
                                    <li>{t("In spite of best efforts, there is a possibility that the airline can cancel your PNR.")}</li>
                                </ul>
                            </div>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Checkbox checked={termsConditionsCheck}
                                        onChange={(event, newValue) => {
                                            setTermsConditionsCheck(newValue);
                                            setisImportBtnDisabled(termsConditionsCheck ? false : true);
                                        }} />
                                }
                                    label={t("I Agree With The Above Mentioned Terms and Conditions")} />
                            </FormGroup>
                        </div>
                    </Typography>
                    <Typography>
                        <Button variant="contained"
                            size="medium"
                            color="primary"
                            className="hold-submit-button"
                            disabled={!termsConditionsCheck}
                            onClick={processHold}>
                            {t("Agree")}
                        </Button>
                    </Typography>
                </div>
            </Box>
        </Modal>
    );
}