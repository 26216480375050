import React, { useState, useRef } from 'react';
import { Button, Fab, Tooltip } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';
import { ExpandMore, ExpandLess } from '@mui/icons-material';


const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const fabRef = useRef(null);
    const languages = [
        { code: 'en', label: 'English' },
        { code: 'it', label: 'Italian' },
        // { code: 'de', label: 'German' },
        // { code: 'jp', label: 'Japanese' },
    ];

    const handleLanguageChange = (code) => {
        localStorage.setItem("language", code);
        i18n.changeLanguage(code);
        setOpen(false);
    };

    return (
        <div>
            <Fab
                ref={fabRef}
                className='languageSwitcher'
                style={{
                    position: 'fixed',
                    zIndex: 1000,
                    borderRadius: '50%',
                    transition: 'all 0.3s ease',
                    backgroundImage: 'linear-gradient(96deg, #db2a5f, #a0155a)',
                }}
                onClick={() => setOpen(!open)}
            >
                <LanguageIcon style={{ color: 'white' }} />
            </Fab>
            <div
                className='languageSwitcherFab'
                style={{
                    position: 'fixed',

                    zIndex: 999,
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 8,
                }}
            >
                {open && languages.map((lang, index) => (
                    <Fab
                        key={lang.code}
                        color="secondary"
                        variant="contained"
                        style={{
                            backgroundImage: 'linear-gradient(96deg, #a0155a, #db2a5f)',
                            color: 'white',
                            transition: 'all 0.3s ease',
                            borderRadius: '50%',
                        }}
                        onClick={() => handleLanguageChange(lang.code)}
                    >
                        <span>{lang.code}</span>
                    </Fab>
                ))}
            </div>
        </div>
    );
};
export default LanguageSwitcher;
