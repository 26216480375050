export default function checkAirlinesFilter(item, search) {
  let airlines = search.filters.airlines;
  let checkedAirlines = airlines.filter((airline) => airline.checked === true);
  let isAvailable = false;
  if (checkedAirlines.length > 0) {
    checkedAirlines.forEach((checkedAirline) => {
      if (checkedAirline.airline === item.airline) {
        isAvailable = true;
      }
    });
    console.log("checkAirlinesFilter", isAvailable);
    return isAvailable;
  } else {
    console.log("checkAirlinesFilter", true);
    return true;
  }
}
