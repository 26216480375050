
export default function checkArrivalDepartFilter(item, search) {

  let isAvailable = [];
  search.filters.arrivalDepart.forEach((arrivalDepart, index) => {
    let journeyIndex = Math.floor(index / 2);
    let segGroup = item.segGroups[journeyIndex];
    let checkedItems = arrivalDepart.departArrival.filter(
      (item) => item.checked === true
    );
    if (checkedItems.length === 0) {
      isAvailable.push(true);
    } else {
      if (arrivalDepart.type === "depart") {
        let isDepart = false;
        let departHour = new Date(segGroup.segs[0].departureOn).getHours();

        checkedItems.forEach((items) => {
          const times = items.time.split("-");
          if (times[0] === "23") {
            const timeArray = [23, 0, 1, 2, 3, 4];
            if (timeArray.includes(departHour)) {
              isDepart = true;
            }
          } else {
            if (departHour >= parseInt(times[0]) && departHour < parseInt(times[1])) {
              isDepart = true;
            }
          }
        });
        isAvailable.push(isDepart);
      } else {
        let isArrival = false;
        let arrivalHour = new Date(segGroup.segs[segGroup.segs.length - 1].arrivalOn).getHours();

        checkedItems.forEach((items) => {
          const times = items.time.split("-");
          if (times[0] === "23") {
            const timeArray = [23, 0, 1, 2, 3, 4];
            if (timeArray.includes(arrivalHour)) {
              isArrival = true;
            }
          } else {
            if (arrivalHour >= parseInt(times[0]) && arrivalHour < parseInt(times[1])) {
              isArrival = true;
            }
          }
        });
        isAvailable.push(isArrival);
      }
    }
  });
  if (isAvailable.length > 0) {
    let isTrue = false;
    isAvailable.forEach((items, index) => {
      if (index === 0) {
        isTrue = items;
      } else {
        isTrue = isTrue && items;
      }
    });
    console.log("checkArrivalDepartFilter", isTrue);
    return isTrue;
  } else {
    console.log("checkArrivalDepartFilter", true);
    return true;
  }
}
