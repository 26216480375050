import React from "react";
import { Grid, Button, } from "@material-ui/core";
import flightIcon from "../../icons/flights.svg";
import railIcon from "../../icons/rail.svg";
import hotelIcon from "../../icons/hotel.svg";
import DMCIcon from "../../icons/dmc.svg";
import CBTIcon from "../../icons/cbt.svg";
import POSIcon from "../../icons/pos.svg";
import ClientIcon from "../../icons/clients.svg";
import LocalIcon from "../../icons/local-fares.svg";
import APIIcon from "../../icons/api-xml.svg";
import PaymentsIcon from "../../icons/payments.svg";
import CurrencyIcon from "../../icons/currencies.svg";
import GFSFeedIcon from "../../icons/gds.svg";
import CarouselComponent from "../../providers/CarouselComponent";
import UAEFlag from "../../icons/uae.svg";
import IndiaFlag from "../../icons/india.svg";
import USAFlag from "../../icons/usa.svg";
import ItalyFlag from "../../icons/italy.svg";
import { useTranslation } from "react-i18next";
function SignInHero() {
  const goToRegister = () => {
    const lng = localStorage.getItem('i18nextLng') ?? 'en';
    window.open(`https://register.getfares.com/index_multi.php?lang=${lng}`, "_blank");
  };  
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div id="Home" style={{ backgroundColor: "white", padding: "0 1vw" }}>
        {/* <Container> */}
        <Grid container>
          <Grid item xs={12} md={6} lg={6} style={{ padding: "2em 2em" }}>
            <h1
              style={{
                fontSize: "2vw",
                color: "#352F2F",
                lineHeight: "1.2",
                margin: "0px", fontFamily: 'Montserrat'
              }}
              className="mb-2"
            >
              <b> Get<span style={{ color: "#D22461" }}>Fares</span></b>
            </h1>
            <p
              style={{
                color: "#000",
                fontSize: "1.5vw",
                lineHeight: "2.6vw",
                fontFamily: 'Montserrat'
              }}
            >
              {t("getfares_goal")}
            </p>
            <Button
              className="registerNowButton"
              variant="contained"
              onClick={goToRegister}
            >
              {t('register_now')}
            </Button>
            <div className="products">
              <div className="products-item">
                <img src={flightIcon} alt={t('flights')} />
                <span style={{ fontFamily: 'Montserrat', fontSize: '1vw' }} className="fw500">{t('flights')}</span>
              </div>
              <div className="products-item">
                <img src={railIcon} alt={t('rail')} />
                <span style={{ fontFamily: 'Montserrat', fontSize: '1vw' }} className="fw500">{t('rail')}</span>
              </div>
              <div className="products-item">
                <img src={hotelIcon} alt={t('hotel')} />
                <span style={{ fontFamily: 'Montserrat', fontSize: '1vw' }} className="fw500">{t('hotel')}</span>
              </div>
              <div className="products-item">
                <img src={DMCIcon} alt={t('dmc')} />
                <span style={{ fontFamily: 'Montserrat', fontSize: '1vw' }} className="fw500">{t('dmc')}</span>
              </div>
              <div className="products-item">
                <img src={CBTIcon} alt={t("cbt")} />
                <span style={{ fontFamily: 'Montserrat', fontSize: '1vw' }} className="fw500">{t("cbt")}</span>
              </div>
            </div>

          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CarouselComponent />
          </Grid>
        </Grid>
        {/* </Container> */}
      </div>

      <div className="travelSolutionsContainer" >
        <div style={{ marginBottom: '3vh' }}>
          <h1
            style={{
              fontSize: "2vw",
              fontWeight: "500px",
              color: '#252525',
              margin: "0px",
              padding: "0px",
              fontFamily: "Montserrat",
              marginBottom: '1rem',
            }}
            className="fw600"
          >
            {t('best_solutions')}
          </h1>
          <p
            style={{
              color: "#858585",
              fontSize: "2vw",
              margin: "10px",
              fontFamily: "Montserrat",
            }}
          >
            {t('24_7_support_pre')} <span style={{ color: "#D82A61" }}> <b>{t('24_7_support')}</b></span>.
          </p>
        </div>
        <Grid container className="mb-5 ">
          <Grid item xs={6} md={3} lg={3}>
            <div className="bestSolutions" style={{ textAlign: "center", padding: "1rem" }}>
              <div className="icon-bk">
                <img src={POSIcon} style={{ color: "D82A61", width: "1.5em" }} alt="POSIcon" />
              </div>
              <h5

              >
                {t('50+')}
              </h5>
              <p

              >
                {t("pos")}
              </p>
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <div className="bestSolutions" style={{ textAlign: "center", padding: "1rem" }}>
              <div className="icon-bk">
                <img src={ClientIcon} alt="Clients" style={{ color: "D82A61", width: "1.5em" }} />
              </div>
              <h5

              >
                {t("5000+")}
              </h5>
              <p

              >
                {t("clients")}
              </p>
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <div className="bestSolutions" style={{ textAlign: "center", padding: "1rem" }}>
              <div className="icon-bk">
                <img src={flightIcon} alt="Flights" style={{ color: "D82A61", width: "1.5em" }} />
              </div>
              <h5

              >
                {t("800+")}
              </h5>
              <p

              >
                {t("airlines")}
              </p>
            </div>
          </Grid>

          <Grid item xs={6} md={3} lg={3}>
            <div className="bestSolutions" style={{ textAlign: "center", padding: "1rem" }}>
              <div className="icon-bk">
                <img alt="LocalFaresIcon" src={LocalIcon}
                  style={{ color: "D82A61", width: "1.5em" }} />
              </div>
              <h5

              >
                {t("local")}
              </h5>
              <p

              >
                {t("fares")}
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid container style={{ backgroundColor: "white", borderRadius: '10em' }} className="py-5 px-xl-3">
          <Grid item xs={6} md={6} lg={3}>
            <div className="bestSolutions" style={{ textAlign: "center", padding: "1rem" }}>
              <div className="icon-bk">
                <img style={{ color: "D82A61", width: "1.5em" }} src={APIIcon} alt="API" />
              </div>
              <h5

              >
                {t("API/XML")}
              </h5>
              <p

              >
                {t("single_integration")}
              </p>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={3}>
            <div className="bestSolutions" style={{ textAlign: "center", padding: "1rem" }}>
              <div className="icon-bk">
                <img style={{ color: "D82A61", width: "1.5em" }} src={PaymentsIcon} alt="PaymentsIcon" />
              </div>
              <h5

              >
                {t("payments")}
              </h5>
              <p

              >
                {t("multiple_payments")}
              </p>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={3}>
            <div className="bestSolutions" style={{ textAlign: "center", padding: "1rem" }}>
              <div className="icon-bk">
                <img style={{ color: "D82A61", width: "1.5em" }} src={CurrencyIcon} alt="CurrencyIcon" />
              </div>
              <h5

              >
                {t("currency")}
              </h5>
              <p

              >
                {t("multiple_currencies")}
              </p>
            </div>
          </Grid>

          <Grid item xs={6} md={6} lg={3}>
            <div className="bestSolutions" style={{ textAlign: "center", padding: "1rem" }}>
              <div className="icon-bk">
                <img style={{ color: "D82A61", width: "1.5em" }} src={GFSFeedIcon} alt="GFSFeedIcon" />
              </div>
              <h5

              >
                {t("gds_feed")}
              </h5>
              <p

              >
                {t("multi")}
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="pressenceContainer">
        <h1 style={{ color: '252525', fontFamily: 'montserrat', fontSize: '1.5rem', fontWeight: 'bold' }}>{t("pressence")}</h1>
        <Grid container spacing={6} style={{ marginTop: "2rem" }}>
          <Grid item xs={12} md={6} lg={3}>
            <div style={{ backgroundColor: "rgb(165 26 91 / 5%)", borderRadius: '100px', display: 'flex' }} className="text-left">
              <div>
                <img style={{ color: "D82A61" }} src={UAEFlag} alt="CurrencyIcon" />
              </div>
              <div style={{ marginLeft: '1em', padding: '0.75em 0.75em 0 0' }}>
                <p className="fs24 fw600 mb-2" style={{ color: '252525', fontFamily: 'montserrat', fontWeight: 'bold' }}>UAE</p>
                <p className="multiline-Ellipsis fs12" style={{ color: '252525', fontFamily: 'montserrat', fontWeight: '500', textAlign: 'left' }}>1610, Parklane Tower, Business Bay
                  Dubai, United Arab Emirates</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <div style={{ backgroundColor: "rgb(165 26 91 / 5%)", borderRadius: '100px', display: 'flex' }} className="text-left">
              <div>
                <img style={{ color: "D82A61" }} src={IndiaFlag} alt="CurrencyIcon" />
              </div>
              <div style={{ marginLeft: '1em', padding: '0.75em 0.75em 0 0' }}>
                <p className="fs24 fw600 mb-2" style={{ color: '252525', fontFamily: 'montserrat', fontWeight: 'bold' }}>India</p>
                <p className="multiline-Ellipsis fs12" style={{ color: '252525', fontFamily: 'montserrat', fontWeight: '500', textAlign: 'left' }}>
                  178, 1st Floor, Kavarana Mansion,Dr Baba Saheb Ambedkar Rd, Dadar East,Mumbai, Maharashtra 400014.</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <div style={{ backgroundColor: "rgb(165 26 91 / 5%)", borderRadius: '100px', display: 'flex' }} className="text-left">
              <div>
                <img style={{ color: "D82A61" }} src={USAFlag} alt="CurrencyIcon" />
              </div>
              <div style={{ marginLeft: '1em', padding: '0.75em 0.75em 0 0' }}>
                <p className="fs24 fw600 mb-2" style={{ color: '252525', fontFamily: 'montserrat', fontWeight: 'bold' }}>USA</p>
                <p className="multiline-Ellipsis fs12" style={{ color: '252525', fontFamily: 'montserrat', fontWeight: '500', textAlign: 'left' }}>
                  1610, Parklane Tower, Business Bay
                  Dubai, United Arab Emirates</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <div style={{ backgroundColor: "rgb(165 26 91 / 5%)", borderRadius: '100px', display: 'flex' }} className="text-left">
              <div>
                <img style={{ color: "D82A61" }} src={ItalyFlag} alt="CurrencyIcon" />
              </div>
              <div style={{ marginLeft: '1em', padding: '0.75em 0.75em 0 0' }}>
                <p className="fs24 fw600 mb-2" style={{ color: '252525', fontFamily: 'montserrat', fontWeight: 'bold' }}>Italy</p>
                <p className="multiline-Ellipsis fs12" style={{ color: '252525', fontFamily: 'montserrat', fontWeight: '500', textAlign: 'left' }}>
                  1610, Parklane Tower, Business Bay
                  Dubai, United Arab Emirates</p>
              </div>
            </div>
          </Grid>
        </Grid>

      </div>
    </React.Fragment>
  );
}

export default SignInHero;
