import axios from 'axios';

const logFrontEndData = async (
    traceId,
    clientId,
    currentpage,
    currentaction,
    errorCode,
    errorDescription,
    ErrorMessage,
    Payload,
) => {
    const payload = {
        traceid: traceId,
        clientUser: clientId?.toString(),
        browserID: navigator.userAgent,
        dateTime: new Date()?.toString(),
        currentpage,
        currentaction,
        errorCode: errorCode?.toString(),
        errorDescription,
        ErrorMessage: ErrorMessage ?? '',
        Payload: Payload ?? '',
    };

    const headers = {
        "Content-Type": "application/json",
    };

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_CORE_URL}api/Configuration/ElasticLog/FrontEndLogs`,
            JSON.stringify(payload),
            { headers }
        );

        if (typeof response.data === "string") {
            console.log(JSON.parse(response.data));
        } else {
            console.log(response.data);
        }
    } catch (error) {
        console.log(error);
    }
};

export default logFrontEndData;