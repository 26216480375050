import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import axios from "axios";
import getBrowserSignature from "../../../functions/getBrowserSignature";
import UserAlerts from "../../UserAlerts/UserAlerts"

function AddClientBalance({ open, setOpen, clientId, getClientBalance, getClients }) {
  const [balance, setbalance] = useState("");
  const [Remarks, setRemarks] = useState("");
  const [isAddDisabled, setisAddDisabled] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const handleClose = () => {
    setbalance("")
    setisAddDisabled(false);
    setOpen(false);
  };

  useEffect(() => {
    (balance > 0 || balance < 0) ? setisAddDisabled(false) : setisAddDisabled(true)

  }, [balance]);
  async function handleSubmit() {
    //Call the add api here
    AddClientBalance();
  }

  async function AddClientBalance() {
    const signature = getBrowserSignature();
    setisAddDisabled(true);
    const headers = {
      "Content-Type": "application/json",
    };

    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/Transactions/AddClientBalance/",
        JSON.stringify({
          clientId: clientId,
          balance: balance,
          Remarks: Remarks,
          BrowserId: signature
        }),
        {
          headers,
        }
      )
      .then((res) => {
        // console.log(res);
        setisAddDisabled(false);
        getClientBalance();
        getClients();
        setbalance("")
        handleClose();
      })
      .catch((err) => {
        if (err.response.data.toLowerCase().includes("unauthorized browser")) {
          setIsSessionExpired(true);
        }

        setisAddDisabled(false);
        setbalance("")
        console.log(err);
        handleClose();

      });
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ValidatorForm
          onError={(errors) => console.log(errors)}
          onSubmit={handleSubmit}
        >
          <DialogTitle id="alert-dialog-title">Add Payment Cycle</DialogTitle>
          <DialogContent>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>Add Client Balance </div>
              <div>
                <TextValidator
                  variant="filled"
                  size="small"
                  color="secondary"
                  style={{ width: "80px" }}
                  value={balance}
                  onChange={(event) => { setbalance(event.target.value) }}
                  validators={["required"]}
                  errorMessages={["This Field is Required."]}
                />
              </div>
            </div>
          </DialogContent>
          <DialogContent>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div> Remarks </div>
              <div>
                <TextValidator
                  variant="filled"
                  size="small"
                  color="secondary"
                  style={{ width: "180px" }}
                  value={Remarks}
                  onChange={(event) => { setRemarks(event.target.value) }}
                  validators={["required"]}
                  errorMessages={["This Field is Required."]}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" disabled={isAddDisabled} color="primary">
              Add
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
      {isSessionExpired && <UserAlerts isSessionExpired={isSessionExpired} />}
    </div>
  );
}

export default AddClientBalance;
