import React, { useState, useEffect, useRef, Fragment } from "react";
import "./DestinationSearchMenu.css";
import InputBase from "@material-ui/core/InputBase";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import airportDetails from "../../data/AirportDetails";
import popularAirports from "../../data/PopularAirports";
import useDebounce from "../../hooks/useDebounce";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import countryCodeMap from "../../data/countryCodeMap";
export default function DestinationSearchMenu({
  search,
  setSearch,
  type,
  closeMenu,
  destination,
  arrIndex
}) {
  const inputRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isErrorShow, setIsErrorShow] = useState(false);


  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const setDestination = (value) => {
    const existingSearch = { ...search };
    if (
      destination &&
      Object.keys(destination).length > 0 &&
      existingSearch.destinations.length > 0
    ) {
      const currentDestinationIndex = existingSearch.destinations.findIndex(
        (item) => item.id === destination.id
      );
      if (type === "from") {
        existingSearch.destinations[currentDestinationIndex].from = value;
      } else {
        existingSearch.destinations[currentDestinationIndex].to = value;
      }
      setSearch(existingSearch);
      closeMenu();
    } else {
      if (type === "from") {
        existingSearch.originDestinations[arrIndex].origin = value;
      } else {
        if (value.sr === existingSearch.originDestinations[arrIndex].origin.sr) {
          setIsErrorShow(true);
          return;
        }
        existingSearch.originDestinations[arrIndex].destination = value;
        if (search.originDestinations.length > arrIndex + 1) {
          existingSearch.originDestinations[arrIndex + 1].origin = value;
          if (value.sr === existingSearch.originDestinations[arrIndex + 1].destination.sr) {
            existingSearch.originDestinations[arrIndex + 1].destination = 'To';
          }
        }
      }
      setSearch(existingSearch);
      closeMenu();
    }
  };

  useEffect(() => {
    inputRef.current.focus();
  });

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true);
      const results = airportDetails
        .filter((item) => item?.city_code !== search?.from?.city_code)
        .filter(
          (item) =>
            item.city
              .toLowerCase()
              .includes(debouncedSearchTerm.toLowerCase()) ||
            item.country
              .toLowerCase()
              .includes(debouncedSearchTerm.toLowerCase()) ||
            item.airport_name
              .toLowerCase()
              .includes(debouncedSearchTerm.toLowerCase()) ||
            item.city_code
              .toLowerCase()
              .includes(debouncedSearchTerm.toLowerCase())
        )
        .sort((a, b) => {

          if (debouncedSearchTerm.length >= 4) {
            if (a.city.toLowerCase().startsWith(debouncedSearchTerm.toLowerCase())) {
              return -1;
            } else if (b.city.toLowerCase().startsWith(debouncedSearchTerm.toLowerCase())) {
              return 1;
            } else {
              return 0;
            }
          } else {
            if (a.city_code.toLowerCase().startsWith(debouncedSearchTerm.toLowerCase())) {
              return -1;
            } else if (b.city_code.toLowerCase().startsWith(debouncedSearchTerm.toLowerCase())) {
              return 1;
            } else {
              return 0;
            }
          }
        });
      setSearchResults(results);
      setIsSearching(false);
    } else {
      setSearchResults(popularAirports.filter((item) => item.city_code !== search.from.city_code));
    }
  }, [debouncedSearchTerm]);

  const handleSnackBarClose = () => {
    setIsErrorShow(false);
  }

  const getCountryCode = (country) => countryCodeMap[country] || "unknown";
  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={isErrorShow}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose}
          severity="error"
          sx={{ backgroundColor: 'rgb(253, 237, 237) !important', marginTop: '50px', }}
        >
          The destination cannot be the same as the origin.
        </Alert>
      </Snackbar>
      <div className="destination-search-menu-container">
        <div className="destination-search-bar">
          <div className="search-icon">
            <SearchRoundedIcon fontSize="small" />
          </div>
          <InputBase
            inputRef={inputRef}
            placeholder={type === "from" ? "Search Origin" : "Search Destination"}
            className="input-base-container"
            inputProps={{ "aria-label": "naked" }}
            value={searchTerm}
            onChange={handleSearchTermChange}
            autoFocus={true}
          />
        </div>
        <div className="destination-list-view">
          {searchResults.length === 0 && searchTerm.length > 0 && (
            <div className="no-results-found">No results found</div>
          )}
          {searchResults.map((item, index) => (
            <div
              className={
                "destination-list-item hover-fill pointer " +
                (index + 1 === airportDetails.length ? "" : " bb-1")
              }
              onClick={() => setDestination(item)}
            >
              <div className="destination-details">
                <div className="airport-place">
                  {item.city}, {item.country}
                </div>
                <div className="airport-name">{item.airport_name}</div>
              </div>
              <div className="destination-code" style={{ display: "flex", alignItems: "center" }}>
                {item.city_code}
                <img
                  src={`https://flagcdn.com/16x12/${(getCountryCode(item.country)).toLowerCase()}.png`}
                  alt={`${item.country} flag`}
                  style={{ width: "20px", height: "20px", margin: "0 10px", opacity: 1 }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
}
