import React, { useEffect, useState } from "react";
import "./SupplierDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  useTheme,
} from "@material-ui/core";
import moment from "moment";
import { TextField } from "@material-ui/core";
import countries from "../../../../data/Country";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TimeZones from "../../../../data/TimeZones";
import supplierList from "../../../../data/Supplier";
import axios from "axios";
import { isUserHasPermission } from "../../../../services/AuthService";
import RiseLoading from "../../../Loader/RiseLoader";
import airlineDetails from "../../../../data/AirlineDetails";

const paxTypeList = ["JCB", "SEA"];
const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});
function getStyles(name, pccCode, theme) {
  return {
    fontWeight:
      pccCode.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
      width: 250,
    },
  },
};
const SelectProps = {
  multiple: true,
};

export default function SupplierDrawer({
  toggleDrawer,
  selectedItem,
  action,
  setAction,
  handleSnackbarToggle,
  vendorList,
  clientSelect,
  productType,
}) {

  console.log(selectedItem);
  const theme = useTheme();
  const classes = useStyles();

  const [pccCode, setPccCode] = useState("");
  const [ipcc, setIpcc] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [pointOfSale, setPointOfSale] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [url, setUrl] = useState("");
  const [timeZone, setTimeZone] = useState("0.0");
  const [ticketType, setTicketType] = useState("");
  const [bookedQueueNumber, setBookedQueueNumber] = useState("");
  const [maxItineraries, setMaxItineraries] = useState("");
  const [amadeusWSAP, setAmadeusWSAP] = useState("");
  const [vendor, setVendor] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [savePQ, setSavePQ] = useState(false);
  const [id, setId] = useState("");
  const [code, setCode] = useState("");
  const [key, setKey] = useState("");
  const [dkNumber, setDKNumber] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [voidAllowed, setVoidAllowed] = useState(false);
  const [voidCondition, setVoidCondition] = useState("");
  const [exAirline, setExAirline] = useState([]);
  const [voidCharges, setVoidCharges] = useState(0);

  const voidAllowedOptions = ['Yes', 'No'];
  const voidConditionOptions = ['BSP', 'ARC'];

  console.log('selectedItem')
  useEffect(() => {
    if ((action === "edit" || action === "view") && selectedItem) {

      setPccCode(selectedItem.pccCode);
      setIpcc(selectedItem.ipcc);
      setUserName(selectedItem.userName);
      setPassword(selectedItem.password);
      setSupplierName(selectedItem.supplierName);
      setPointOfSale(selectedItem.pointOfSale);
      setCountryCode(selectedItem.countryCode);
      setUrl(selectedItem.url);
      setTimeZone(selectedItem.timeZone);
      setTicketType(selectedItem.ticketType);
      setBookedQueueNumber(selectedItem.bookedQueueNumber);
      setMaxItineraries(selectedItem.maxItineraries);
      setAmadeusWSAP(selectedItem.amadeusWSAP);
      setVendor(selectedItem.vendorId);
      setIsActive(selectedItem.isActive);
      setSavePQ(selectedItem.savePQ);
      setId(selectedItem.id);
      setCode(selectedItem.code);
      setKey(selectedItem.key);
      setDKNumber(selectedItem.dkNumber);
      setVoidAllowed(selectedItem.voidAllowed ? "Yes" : "No");
      setVoidCondition(selectedItem.voidCondition);
      if (selectedItem.voidExcludeAirline !== undefined && selectedItem.voidExcludeAirline !== "") {
        let list = [];
        selectedItem.voidExcludeAirline
          .split(",")
          .map((air) =>
            list.push(airlineDetails.find((el) => el.Code === air))
          );
        setExAirline(list);
      } else {
        setExAirline([]);
      }

      setVoidCharges(selectedItem.voidCharges);
      // setClient(selectedItem.clientId);
    } else {
      setPccCode("");
      setIpcc("");
      setUserName("");
      setPassword("");
      setSupplierName("");
      setPointOfSale("");
      setCountryCode("");
      setUrl("");
      setTimeZone("0.0");
      setTicketType("");
      setBookedQueueNumber("");
      setMaxItineraries("");
      setAmadeusWSAP("");
      setVendor("");
      setIsActive(true);
      setSavePQ(false);
      setId("");
      setCode("");
      setKey("");
      setDKNumber("");
      setVoidAllowed(false);
      setVoidCondition("");
      setExAirline([]);
      setVoidCharges(0.0);
      // setClient("");
    }
  }, [action, selectedItem]);

  async function handleUpdate() {
    console.log(productType);
    let submitUrl;
    if (productType === 'Rail') {
      submitUrl = "api/Configuration/RailPcc/";
    } else if (productType === 'Flight') {
      submitUrl = "api/Configuration/PccConfig/";
    }

    setShowLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };
    if (action === "edit" || action === "view") {
      await axios
        .put(
          process.env.REACT_APP_CORE_URL +
          submitUrl +
          selectedItem.id,
          JSON.stringify({
            id: selectedItem.id,
            isActive: String(isActive).toLowerCase() === "true" ? true : false,
            createdBy:
              selectedItem.createdBy === undefined
                ? ""
                : selectedItem.createdBy,
            created:
              selectedItem.created === undefined ? "" : selectedItem.created,
            modifiedBy: "",
            modified:
              selectedItem.created === undefined ? "" : selectedItem.created,
            pccCode: pccCode === undefined ? "" : pccCode,
            ipcc: pccCode === undefined ? "" : pccCode,
            userName: userName === undefined ? "" : userName,
            password: password === undefined ? "" : password,
            supplierName: supplierName === undefined ? "" : supplierName,
            pointOfSale: pointOfSale === undefined ? "" : pointOfSale,
            countryCode: countryCode === undefined ? "" : countryCode,
            url: url === undefined ? "" : url,
            timeZone:
              timeZone === undefined || timeZone !== "" ? "0.00" : timeZone,
            ticketType: ticketType === undefined ? "" : ticketType,
            bookedQueueNumber:
              bookedQueueNumber === undefined || bookedQueueNumber === ""
                ? "0"
                : bookedQueueNumber,
            maxItineraries:
              maxItineraries === undefined ? "200" : maxItineraries,
            amadeusWSAP: amadeusWSAP === undefined ? "" : amadeusWSAP,
            vendorId: vendor === undefined ? "0" : vendor,
            savePQ: savePQ === undefined ? false : savePQ,
            code: code === undefined ? "" : code,
            key: key === undefined ? "" : key,
            DKNumber: dkNumber === undefined ? "" : dkNumber,
            productType: productType,
            voidAllowed: voidAllowed === undefined ? false : voidAllowed === "Yes" ? true : false,
            voidCondition: voidCondition === undefined ? "" : voidCondition,
            voidExcludeAirline: exAirline === undefined ? "" : exAirline.map(item => `${item.Code}`).join(","),
            voidCharges: voidCharges === undefined ? 0.0 : parseFloat(voidCharges),
            // clientId: client === undefined ? 0 : client,
          }),
          {
            headers,
          }
        )
        .then((res) => {
          handleSnackbarToggle();
          setShowLoader(false);
        })
        .catch((err) => {
          console.log(err);
          handleSnackbarToggle();
          setShowLoader(false);
        });
    } else {
      setShowLoader(true);
      await axios
        .post(
          process.env.REACT_APP_CORE_URL + submitUrl,
          JSON.stringify({
            id: 0,
            isActive: String(isActive).toLowerCase() === "true" ? true : false,
            createdBy: "",
            created: moment().format(),
            modifiedBy: "",
            modified: moment().format(),
            pccCode: pccCode === undefined ? "" : pccCode,
            ipcc: pccCode === undefined ? "" : pccCode,
            userName: userName === undefined ? "" : userName,
            password: password === undefined ? "" : password,
            supplierName: supplierName === undefined ? "" : supplierName,
            pointOfSale: pointOfSale === undefined ? "" : pointOfSale,
            countryCode: countryCode === undefined ? "" : countryCode,
            url: url === undefined ? "" : url,
            timeZone:
              timeZone === undefined || timeZone !== "" ? "0.00" : timeZone,
            ticketType: ticketType === undefined ? "" : ticketType,
            bookedQueueNumber:
              bookedQueueNumber === undefined || bookedQueueNumber === ""
                ? "0"
                : bookedQueueNumber,
            maxItineraries:
              maxItineraries === undefined ? "200" : maxItineraries,
            amadeusWSAP: amadeusWSAP === undefined ? "" : amadeusWSAP,
            vendorId: vendor === undefined ? "0" : vendor,
            savePQ: savePQ === undefined ? false : savePQ,
            code: code === undefined ? "" : code,
            key: key === undefined ? "" : key,
            DKNumber: dkNumber === undefined ? "" : dkNumber,
            productType: productType,
            voidAllowed: voidAllowed === undefined ? false : voidAllowed === "Yes" ? true : false,
            voidCondition: voidCondition === undefined ? "" : voidCondition,
            voidExcludeAirline: exAirline === undefined ? "" : exAirline.map(item => `${item.Code}`).join(","),
            voidCharges: voidCharges === undefined ? 0.0 : parseFloat(voidCharges),
            // clientId: client === undefined ? 0 : client,
          }),
          {
            headers,
          }
        )
        .then((res) => {
          console.log(res);
          handleSnackbarToggle();
          setShowLoader(false);
        })
        .catch((err) => {
          console.log(err);
          handleSnackbarToggle();
          setShowLoader(false);
        });
    }
  }

  // useEffect(() => {
  //   console.log("client", client);
  // }, [client]);

  return (
    <div className="ticket-drawer-container">
      <div className="close-button-container">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={toggleDrawer(false)}
        ></CloseIcon>
      </div>
      <div className="supplier-heading-container supplier-title-flex-container">
        {action === "view" && (
          <div className="supplier-heading-icons">
            {isUserHasPermission("PCCWrite") ? (
              <IconButton fontSize="small">
                <EditRoundedIcon
                  fontSize="small"
                  className="icon-blue"
                  onClick={() => setAction("edit")}
                ></EditRoundedIcon>
              </IconButton>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      <ValidatorForm
        onError={(errors) => console.log(errors)}
        onSubmit={handleUpdate}
      >
        <div className="supplier-view-edit-drawer-container">
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="supplier select"
                  value={
                    supplierList.find((v) => v.value === supplierName) || null
                  }
                  onChange={(event, newValue) => {
                    setSupplierName(newValue ? newValue.value : "");
                  }}
                  options={supplierList}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.value + " (" + option.label + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Supplier Name"
                      value={supplierName}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      required
                    />
                  )}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <Autocomplete
                  id="vendor select"
                  value={vendorList.find((v) => v.id === vendor) || null}
                  onChange={(event, newValue) => {
                    setVendor(newValue ? newValue.id : "0");
                  }}
                  options={vendorList}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Vendor"
                      value={vendor}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      required
                    />
                  )}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Supplier Name</div>
                <div className="view-supplier-drawer-description">
                  {supplierName}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Vendor</div>
                <div className="view-supplier-drawer-description">
                  {vendorList &&
                    vendorList
                      .filter((id) => id.id === vendor)
                      .map((row) => <p>{row.name}</p>)}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Pseudo"
                  size="small"
                  color="secondary"
                  value={pccCode}
                  onChange={(event) => setPccCode(event.target.value)}
                  required
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <Autocomplete
                  id="point of sale"
                  value={countries.find((v) => v.code === pointOfSale) || null}
                  onChange={(event, newValue) => {
                    setPointOfSale(newValue ? newValue.code : "");
                  }}
                  options={countries}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.label + " (" + option.code + ")"
                  }
                  renderOption={(option) => (
                    <React.Fragment>
                      {option.label} ({option.code})
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Point Of Sale"
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      required
                    />
                  )}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Pseudo</div>
                <div className="view-supplier-drawer-description ">
                  {pccCode}
                </div>
              </div>
            )}

            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Point Of Sale</div>
                <div className="view-supplier-drawer-description">
                  {pointOfSale}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="User Name"
                  size="small"
                  color="secondary"
                  value={userName}
                  onChange={(event) => setUserName(event.target.value)}
                  required
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Password"
                  size="small"
                  color="secondary"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  required
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">User Name</div>
                <div className="view-supplier-drawer-description ">
                  {userName}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Password</div>
                <div className="view-supplier-drawer-description ">
                  {password}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="country-select"
                  value={countries.find((v) => v.code === countryCode) || null}
                  onChange={(event, newValue) => {
                    setCountryCode(newValue ? newValue.code : "");
                  }}
                  options={countries}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.label + " (" + option.code + ")"
                  }
                  renderOption={(option) => (
                    <React.Fragment>
                      {option.label} ({option.code})
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="CountryCode"
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      required
                    />
                  )}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="URL"
                  size="small"
                  color="secondary"
                  value={url}
                  onChange={(event) => setUrl(event.target.value)}
                  required
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Country Code</div>
                <div className="view-supplier-drawer-description">
                  {countryCode}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">URL</div>
                <div className="view-supplier-drawer-description">{url}</div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="timezone-select"
                  value={
                    TimeZones.find((v) => v.value === timeZone.toString()) ||
                    null
                  }
                  onChange={(event, newValue) => {
                    setTimeZone(newValue ? newValue.value : "");
                  }}
                  options={TimeZones}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.text}
                  renderOption={(option) => (
                    <React.Fragment>{option.text})</React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="TimeZone"
                      value={timeZone}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      required
                    />
                  )}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Ticket Type"
                  size="small"
                  color="secondary"
                  value={ticketType}
                  onChange={(event) => setTicketType(event.target.value)}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">TimeZone</div>
                <div className="view-supplier-drawer-description ">
                  {timeZone}
                </div>
              </div>
            )}
            {action == "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Ticket Type</div>
                <div className="view-supplier-drawer-description">
                  {ticketType}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Queue Number"
                  size="small"
                  color="secondary"
                  value={bookedQueueNumber}
                  onChange={(event) => setBookedQueueNumber(event.target.value)}
                  InputProps={{
                    inputProps: {
                      type: "number",
                      min: 0,
                      max: 100,
                    },
                  }}
                />
              </div>
            )}
            {action !== "view" && productType === 'Flight' && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="MaxItineraries"
                  size="small"
                  color="secondary"
                  value={maxItineraries}
                  onChange={(event) => setMaxItineraries(event.target.value)}
                  InputProps={{
                    inputProps: {
                      type: "number",
                      min: 0,
                      max: 200,
                    },
                  }}
                  required
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Queue Number</div>
                <div className="view-supplier-drawer-description ">
                  {bookedQueueNumber}
                </div>
              </div>
            )}
            {action == "view" && productType === 'Flight' && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">MaxItineraries</div>
                <div className="view-supplier-drawer-description">
                  {maxItineraries}
                </div>
              </div>
            )}
          </div>

          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Code"
                  size="small"
                  color="secondary"
                  value={code}
                  onChange={(event) => setCode(event.target.value)}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Key"
                  size="small"
                  color="secondary"
                  value={key}
                  onChange={(event) => setKey(event.target.value)}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Code</div>
                <div className="view-supplier-drawer-description ">{code}</div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Key</div>
                <div className="view-supplier-drawer-description ">{key}</div>
              </div>
            )}
          </div>

          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && productType === 'Flight' && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <FormControl fullWidth>
                  <Autocomplete
                    options={voidAllowedOptions}
                    getOptionLabel={(option) => option}
                    value={voidAllowed}
                    onChange={(event, newValue) => setVoidAllowed(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Void Allowed"
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </FormControl>
              </div>
            )}

            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <FormControl fullWidth>
                  <Autocomplete
                    options={voidConditionOptions}
                    getOptionLabel={(option) => option}
                    value={voidCondition}
                    onChange={(event, newValue) => setVoidCondition(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Void Condition"
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </FormControl>
              </div>
            )}
            {action === "view" && productType === 'Flight' && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Void Allowed</div>
                <div className="view-supplier-drawer-description ">
                  {console.log('voidAllowed', voidAllowed)}
                  {voidAllowed ? "Yes" : "No"}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Void Condition</div>
                <div className="view-supplier-drawer-description">
                  {voidCondition}
                </div>
              </div>
            )}
          </div>

          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && productType === 'Flight' && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <FormControl fullWidth>
                  <Autocomplete
                    required
                    id="airline select"
                    multiple
                    value={exAirline && exAirline}
                    onChange={(event, value) => {
                      setExAirline(value);
                    }}
                    options={airlineDetails}
                    autoHighlight
                    getOptionLabel={(option) =>
                      option.Code === "All"
                        ? "All"
                        : option.Name + " (" + option.Code + ")"
                    }
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label="Void Exclude Airline"
                        value={exAirline}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                    style={{ maxWidth: "34vw" }}
                  />

                </FormControl>
              </div>
            )}

            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Void Charges"
                  size="small"
                  color="secondary"
                  value={voidCharges}
                  onChange={(event) => setVoidCharges(event.target.value)}
                />
              </div>
            )}
            {action === "view" && productType === 'Flight' && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Void Exclude Airline</div>
                <div className="view-supplier-drawer-description ">
                  {exAirline && exAirline.map((airline) => (
                    <div key={airline.Code}>{airline.Name} ({airline.Code})</div>
                  ))}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Void Charges</div>
                <div className="view-supplier-drawer-description">
                  {voidCharges}
                </div>
              </div>
            )}
          </div>

          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && productType === 'Flight' && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="DK Number"
                  size="small"
                  color="secondary"
                  value={dkNumber}
                  onChange={(event) => setDKNumber(event.target.value)}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Status
                  </InputLabel>
                  <NativeSelect
                    variant="outlined"
                    label="Status"
                    size="small"
                    color="secondary"
                    value={isActive}
                    onChange={(event) => setIsActive(event.target.value)}
                  >
                    <option value={true}>true</option>
                    <option value={false}>false</option>
                  </NativeSelect>
                </FormControl>
              </div>
            )}
            {action === "view" && productType === 'Flight' && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">DK Number</div>
                <div className="view-supplier-drawer-description ">
                  {dkNumber}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Status</div>
                <div className="view-supplier-drawer-description">
                  {isActive.toString()}
                </div>
              </div>
            )}
          </div>

          {/* <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <Autocomplete
                  id="client-select"
                  value={clientSelect.find((v) => v.id === client) || null}
                  onChange={(event, newValue) => {
                    setClient(newValue ? newValue.id : "");
                  }}
                  options={clientSelect}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Client"
                      value={
                        clientSelect.find((v) => v.id === client)?.name || ""
                      }
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                    />
                  )}
                />
              </div>
            )}

            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1"></div>
            )}

            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Client</div>
                <div className="view-supplier-drawer-description">
                  {clientSelect &&
                    clientSelect
                      .filter((id) => id.id === client)
                      .map((row) => <p>{row.name}</p>)}
                </div>
              </div>
            )}
          </div> */}

          {supplierName === "Amadeus" && (
            <div className="supplier-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="AmadeusWSAP"
                    size="small"
                    color="secondary"
                    value={amadeusWSAP}
                    onChange={(event) => setAmadeusWSAP(event.target.value)}
                  />
                </div>
              )}
              {action !== "view" && (
                <div className="supplier-view-edit-drawer-input config-input-flex-1"></div>
              )}
              {action === "view" && (
                <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                  <div className="view-supplier-drawer-title">AmadeusWSAP</div>
                  <div className="view-supplier-drawer-description ">
                    {amadeusWSAP}
                  </div>
                </div>
              )}
              {action === "view" && (
                <div className="view-supplier-drawer config-input-flex-1"></div>
              )}
            </div>
          )}
          {supplierName === "Sabre" && (
            <div className="supplier-view-edit-drawer-input-container">
              {action !== "view" && (
                <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                  <TextValidator
                    variant="outlined"
                    label="Branch"
                    size="small"
                    color="secondary"
                    value={ipcc}
                    onChange={(event) => setIpcc(event.target.value)}
                  />
                </div>
              )}
              {action === "view" && (
                <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                  <div className="view-supplier-drawer-title">Branch</div>
                  <div className="view-supplier-drawer-description ">
                    {ipcc}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {action !== "view" && (
          <div>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              style={{
                textTransform: "none",
                marginLeft: "25px",
                marginRight: "25px",
                marginBottom: "25px",
                width: "500px",
              }}
              type="submit"
            >
              {action === "edit" ? "Update" : "Create"}
            </Button>
            {showLoader && <RiseLoading vendor={showLoader} />}
          </div>
        )}
      </ValidatorForm>
    </div>
  );
}
