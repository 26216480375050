import React, { useState, useEffect } from "react";
import "./RegisterUser.css";
import queryString from "query-string";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useDispatch } from "react-redux";
import { login } from "../../../Redux/AuthSlice";
import { useHistory, useLocation } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import axios from "axios";
import { setAuthHeader } from "../../../utils/axiosHeaders";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import PasswordStrengthBar from "react-password-strength-bar";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { checkIfUserStaff, getCurrentRole, isClientUserHasPermission } from "../../../services/AuthService";
import { useTranslation } from "react-i18next";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MustContainItem = (props) => {
  const { data } = props;
  const label = data[0];
  const meetsReq = data[1];

  const setClass = () => {
    const classArr = ["must-line"];
    if (meetsReq) classArr.push("cross-out");
    return classArr.join(" ");
  };

  return (
    <div className="MustContainItem">
      <div className="must-item">
        <li className="must-text">{label}</li>
        <div className={setClass()}></div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(12),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    // width: "500px", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function RegisterUser({ setUser, setDisplayHeader }) {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [dob, setDob] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [containsUL, setContainsUL] = useState(false); // uppercase letter
  const [containsLL, setContainsLL] = useState(false); // lowercase letter
  const [containsN, setContainsN] = useState(false); // number
  const [containsSC, setContainsSC] = useState(false); // special character
  const [contains8C, setContains8C] = useState(false); // min 8 characters
  const [passwordMatch, setPasswordMatch] = useState(false); // passwords match
  const [isTAC, setTAC] = useState(false);
  const [clientId, setClientId] = useState("");
  const [rolename, setRolename] = useState("");
  const [maxDob, setMaxDob] = useState(null);
  const { t } = useTranslation();
  const [passwordType, setPasswordType] = useState("password");
  const [eye, seteye] = useState(true);
  const [DOBerror, setDOBerror] = useState(true);
  const [allNotValid, setAllNotValid] = useState(true);
  const mustContainData = [
    [t("An uppercase letter (A-Z)"), containsUL],
    [t("A lowercase letter (a-z)"), containsLL],
    [t("A number (0-9)"), containsN],
    [t("A special character (!@#$)"), containsSC],
    [t("At least 8 characters"), contains8C],
    [t("Passwords match"), passwordMatch],
  ];

  const validatePassword = () => {
    if (password.toLowerCase() !== password) setContainsUL(true);
    else setContainsUL(false);
    if (password.toUpperCase() !== password) setContainsLL(true);
    else setContainsLL(false);
    if (/\d/.test(password)) setContainsN(true);
    else setContainsN(false);
    if (/[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(password))
      setContainsSC(true);
    else setContainsSC(false);
    if (password.length >= 8) setContains8C(true);
    else setContains8C(false);
    if (password !== "" && password === confirmPassword) setPasswordMatch(true);
    else setPasswordMatch(false);
    if (
      containsUL &&
      containsLL &&
      containsN &&
      containsSC &&
      contains8C &&
      passwordMatch &&
      isTAC
    ) {
      setAllNotValid(false);
    } else {
      setAllNotValid(true);
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    getMaxDobDate();
    console.log("parsed", parsed);
    setEmail(parsed.email);
    setToken(decodeURIComponent(parsed.token));
    setClientId(parsed.clientId);
    setRolename(parsed.role);
  }, []);
  const getMaxDobDate = () => {
    var date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    setMaxDob(date);
  };
  useEffect(() => {
    validatePassword();
  }, [confirmPassword, isTAC]);
  useEffect(() => {
    if (dob) {
      setTAC(false);
      setDOBerror(false);
    } else {
      setTAC(false);
      setDOBerror(true);
    }
  }, [dob]);

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });
  async function handleSubmit() {
    setDisplayHeader(false);
    setAllNotValid(true);
    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(
        process.env.REACT_APP_IDENTITY_SERVER +
        "ids/ManageUser/AddNewUserAndConfirmEmail/",
        JSON.stringify({
          email: email,
          emailConfirmationToken: token,
          password: password,
          ClientId: clientId,
          role: rolename,
        }),
        {
          headers,
        }
      )
      .then((res) => {
        setAllNotValid(false);
        console.log(res);
        console.log(res.data);
        handleSuccessSnackbarToggle(t("User is registered successfully"));
        getToken(email, password);
      })
      .catch((err) => {
        setAllNotValid(false);
        console.log(err);
        console.log(err.response.data.detail);
        handleErrorSnackbarToggle(err.response.data.detail);
      });
    setAllNotValid(false);
  }

  async function getToken(username, password) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const data =
      "grant_type=password&client_id=" +
      process.env.REACT_APP_CLIENTID +
      "&username=" +
      username +
      "&password=" +
      encodeURIComponent(password) +
      "&scope=" +
      process.env.REACT_APP_SCOPE;
    console.log(data);
    await axios
      .post(process.env.REACT_APP_IDENTITY_SERVER + "connect/token", data, {
        headers,
      })
      .then((response) => {
        dispatch(login());
        localStorage.setItem("token", response.data.access_token);
        setAuthHeader(response.data.access_token);
        getUser();
        const userRole = getCurrentRole();

        if ((isClientUserHasPermission("IsSearch", "FlightbookDetail") && !checkIfUserStaff()) || userRole === "ClientSuperAdmin" || checkIfUserStaff()) {
          history.push("/flight");
        }
        else {
          history.push("/Unauthorized");
        }
      })
      .catch((error) => {
        setAlert({
          open: true,
          message: "oops!! " + error.response.data.error_description,
          severity: "error",
        });
      });
  }

  async function getUser() {
    await axios
      .get(process.env.REACT_APP_IDENTITY_SERVER + "connect/userinfo")
      .then((response) => {
        console.log(response);
        setUser(null);
        setDisplayHeader(true);
        localStorage.setItem("user", JSON.stringify(response.data));
      });
  }
  const handleErrorSnackbarToggle = (errorText) => {
    setAlert({
      open: true,
      message: errorText,
      severity: "error",
    });
  };
  const handleSuccessSnackbarToggle = (errorText) => {
    setAlert({
      open: true,
      message: errorText,
      severity: "success",
    });
  };
  const eyeToggle = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      seteye(false);
    } else {
      setPasswordType("password");
      seteye(true);
    }
  };
  const setDate = (date) => {
    setDob(date);
  };
  return (
    <div className="login-page-container">
      <div className="login-items-container">
        <Container component="main" maxWidth="xs">
          <div className="register-padding-container">
            {clientId > 0 ? (
              <div className="login-text">{t("Register Client User")}</div>
            ) : (
              <div className="login-text">{t("Register Staff User")}</div>
            )}
            {/* <div className="login-text">Register User</div> */}
            <ValidatorForm
              onError={(errors) => console.log(errors)}
              onSubmit={handleSubmit}
            >
              <div className={classes.form}>
                <TextValidator
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  size="small"
                  id="email"
                  color="primary"
                  label={t("Email Address")}
                  value={email}
                  disabled
                />

                <TextValidator
                  variant="outlined"
                  margin="normal"
                  size="small"
                  label={t("Password")}
                  color="primary"
                  fullWidth
                  // type="password"
                  type={passwordType}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  validators={["required"]}
                  errorMessages={[t("This Field is Required.")]}
                  onKeyUp={validatePassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={eyeToggle} onMouseDown={eyeToggle}>
                          {eye ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <PasswordStrengthBar minLength="4" password={password} />
                <TextValidator
                  type="password"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  label={t("Confirm Password")}
                  color="primary"
                  fullWidth
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  validators={["required"]}
                  errorMessages={[t("This Field is Required.")]}
                  onKeyUp={validatePassword}
                />
                {/* <h4>Password Checklist:</h4> */}
                <div className="must-container cfb">
                  {mustContainData.map((data) => (
                    <MustContainItem data={data} />
                  ))}
                </div>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="remember"
                      color="primary"
                      onChange={(event) => setTAC(event.target.checked)}
                    />
                  }
                  label={t("I accept the Terms & Conditions of this product before registering myself")}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={allNotValid}
                >
                  {t("Submit & Login")}
                </Button>
              </div>
            </ValidatorForm>
          </div>
        </Container>
      </div>
      <div className="login-ads-container">
        <div className="login-ad-content">
          <div>{t("Get Best Flights")}</div>
          <div>{t("For Cheapest Fares")}</div>
        </div>
      </div>
      <Snackbar
        open={alert.open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAlert({ ...alert, open: false })}
        autoHideDuration={10000}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default RegisterUser;
