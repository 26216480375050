const airportDetails = [
  {
    sr: 1,
    city: "All Airport",
    city_code: "All",
    airport_name: "All",
    country: "All",
  },
  {
    sr: 2,
    city: " ",
    city_code: "ZMH",
    airport_name: "108 Mile Ranch",
    country: "Canada",
  },
  {
    sr: 4,
    city: "Aachen",
    city_code: "AAH",
    airport_name: "Aachen Merzbruck",
    country: "Germany",
  },
  {
    sr: 6,
    city: "Aachen",
    city_code: "ZIU",
    airport_name: "Railway",
    country: "Germany",
  },
  {
    sr: 8,
    city: "Aalborg",
    city_code: "AAL",
    airport_name: "Aalborg",
    country: "Denmark",
  },
  {
    sr: 10,
    city: "Alesund",
    city_code: "AES",
    airport_name: "Vigra",
    country: "Norway",
  },
  {
    sr: 12,
    city: "Aarhus",
    city_code: "AAR",
    airport_name: "Tirstrup",
    country: "Denmark",
  },
  {
    sr: 14,
    city: "Aarhus Limo",
    city_code: "ZBU",
    airport_name: "Aarhus Limo",
    country: "Denmark",
  },
  {
    sr: 16,
    city: "Aasiaat",
    city_code: "JEG",
    airport_name: "Aasiaat",
    country: "Greenland",
  },
  {
    sr: 18,
    city: "Abadan",
    city_code: "ABD",
    airport_name: "Abadan",
    country: "Iran",
  },
  {
    sr: 20,
    city: "Abaiang",
    city_code: "ABF",
    airport_name: "Abaiang",
    country: "Kiribati",
  },
  {
    sr: 22,
    city: "Abakan",
    city_code: "ABA",
    airport_name: "Abakan",
    country: "Russia",
  },
  {
    sr: 24,
    city: "Abau",
    city_code: "ABW",
    airport_name: "Abau",
    country: "Papua New Guinea",
  },
  {
    sr: 26,
    city: "VANCOUVER",
    city_code: "YXX",
    airport_name: "Abbotsford",
    country: "Canada",
  },
  {
    sr: 28,
    city: "Abbottabad",
    city_code: "AAW",
    airport_name: "Mumbai",
    country: "Pakistan",
  },
  {
    sr: 30,
    city: "Abbse",
    city_code: "EAB",
    airport_name: "Abbse",
    country: "Yemen",
  },
  {
    sr: 34,
    city: "Abecher",
    city_code: "AEH",
    airport_name: "Abecher",
    country: "Chad",
  },
  {
    sr: 36,
    city: "Abemama Atoll",
    city_code: "AEA",
    airport_name: "Abemama Atoll",
    country: "Kiribati",
  },
  {
    sr: 38,
    city: "Abengourou",
    city_code: "OGO",
    airport_name: "Abengourou",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 40,
    city: "Aberdeen",
    city_code: "ABZ",
    airport_name: "Dyce",
    country: "United Kingdom",
  },
  {
    sr: 42,
    city: "Aberdeen",
    city_code: "APG",
    airport_name: "Phillips AAF",
    country: "United States",
  },
  {
    sr: 44,
    city: "Aberdeen",
    city_code: "ABR",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 46,
    city: "Abha",
    city_code: "AHB",
    airport_name: "Abha",
    country: "Saudi Arabia",
  },
  {
    sr: 48,
    city: "Abidjan",
    city_code: "ABJ",
    airport_name: "Felix Houphouet Boigny",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 50,
    city: "Abilene",
    city_code: "DYS",
    airport_name: "Dyess AFB",
    country: "United States",
  },
  {
    sr: 52,
    city: "Abilene",
    city_code: "ABI",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 54,
    city: "Asaba",
    city_code: "ABB",
    airport_name: "Asaba International Airport",
    country: "Nigeria",
  },
  {
    sr: 56,
    city: "Abingdon",
    city_code: "ABG",
    airport_name: "Abingdon",
    country: "Australia",
  },
  {
    sr: 58,
    city: "Abingdon",
    city_code: "VJI",
    airport_name: "Virginia Highlands",
    country: "United States",
  },
  {
    sr: 60,
    city: "Aboisso",
    city_code: "ABO",
    airport_name: "Aboisso",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 62,
    city: "Abou Deia",
    city_code: "AOD",
    airport_name: "Abou Deia",
    country: "Chad",
  },
  {
    sr: 64,
    city: "Punta Abreojos",
    city_code: "AJS",
    airport_name: "Punta Abreojos",
    country: "Mexico",
  },
  {
    sr: 68,
    city: "Abu Dhabi",
    city_code: "AUH",
    airport_name: "Abu Dhabi Intl",
    country: "United Arab Emirates",
  },
  {
    sr: 70,
    city: "Abu Dhabi",
    city_code: "DHF",
    airport_name: "Al Dhafra Military Apt",
    country: "United Arab Emirates",
  },
  {
    sr: 72,
    city: "Abu Dhabi",
    city_code: "AZI",
    airport_name: "Bateen",
    country: "United Arab Emirates",
  },
  {
    sr: 74,
    city: "Abu Musa",
    city_code: "AEU",
    airport_name: "Abu Musa",
    country: "Iran",
  },
  {
    sr: 76,
    city: "Abu Rudeis",
    city_code: "AUE",
    airport_name: "Abu Rudeis",
    country: "Egypt",
  },
  {
    sr: 78,
    city: "Abu Simbel",
    city_code: "ABS",
    airport_name: "Abu Simbel",
    country: "Egypt",
  },
  {
    sr: 80,
    city: "Abuja",
    city_code: "ABV",
    airport_name: "International",
    country: "Nigeria",
  },
  {
    sr: 82,
    city: "Acandi",
    city_code: "ACD",
    airport_name: "Acandi",
    country: "Colombia",
  },
  {
    sr: 84,
    city: "Acaricuara",
    city_code: "ARF",
    airport_name: "Acaricuara",
    country: "Colombia",
  },
  {
    sr: 86,
    city: "Acarigua",
    city_code: "AGV",
    airport_name: "Acarigua",
    country: "Venezuela",
  },
  {
    sr: 88,
    city: "Accra",
    city_code: "ACC",
    airport_name: "Kotoka",
    country: "Ghana",
  },
  {
    sr: 90,
    city: "Achinsk",
    city_code: "ACS",
    airport_name: "Achinsk",
    country: "Russia",
  },
  {
    sr: 92,
    city: "Achutupo",
    city_code: "ACU",
    airport_name: "Achutupo",
    country: "Panama",
  },
  {
    sr: 94,
    city: "Ada",
    city_code: "ADT",
    airport_name: "Ada",
    country: "United States",
  },
  {
    sr: 96,
    city: "Adak Island",
    city_code: "ADK",
    airport_name: "Adak Island NS",
    country: "United States",
  },
  {
    sr: 98,
    city: "Adana",
    city_code: "ADA",
    airport_name: "Adana",
    country: "Turkey",
  },
  {
    sr: 102,
    city: "Adana",
    city_code: "UAB",
    airport_name: "Adana Incirlik Airbase",
    country: "Turkey",
  },
  {
    sr: 104,
    city: "Adado",
    city_code: "AAD",
    airport_name: "Adado",
    country: "Sudan",
  },
  {
    sr: 106,
    city: "Addis Ababa",
    city_code: "ADD",
    airport_name: "Bole",
    country: "Ethiopia",
  },
  {
    sr: 108,
    city: "Adelaide",
    city_code: "ADL",
    airport_name: "Adelaide",
    country: "Australia",
  },
  {
    sr: 110,
    city: "Adelaide",
    city_code: "XHX",
    airport_name: "Adelaide Keswick Rail",
    country: "Australia",
  },
  {
    sr: 112,
    city: "Aden",
    city_code: "ADE",
    airport_name: "International",
    country: "Yemen",
  },
  {
    sr: 114,
    city: "Adiyaman",
    city_code: "ADF",
    airport_name: "Adiyaman",
    country: "Turkey",
  },
  {
    sr: 116,
    city: "Adler Sochi",
    city_code: "AER",
    airport_name: "Adler Sochi",
    country: "Russia",
  },
  {
    sr: 118,
    city: "Adrar",
    city_code: "AZR",
    airport_name: "Adrar",
    country: "Algeria",
  },
  {
    sr: 120,
    city: "Adrian",
    city_code: "ADG",
    airport_name: "Lenawee County",
    country: "United States",
  },
  {
    sr: 122,
    city: "PADANGSIDEMPUAN",
    city_code: "AEG",
    airport_name: "Aek Godang",
    country: "Indonesia",
  },
  {
    sr: 124,
    city: "Afore",
    city_code: "AFR",
    airport_name: "Afore",
    country: "Papua New Guinea",
  },
  {
    sr: 126,
    city: "Afton",
    city_code: "AFO",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 128,
    city: "Afutara",
    city_code: "AFT",
    airport_name: "Afutara Aerodrome",
    country: "Solomon Islands",
  },
  {
    sr: 130,
    city: "Afyon",
    city_code: "AFY",
    airport_name: "Afyon",
    country: "Turkey",
  },
  {
    sr: 132,
    city: "Agades",
    city_code: "AJY",
    airport_name: "Agades",
    country: "Niger",
  },
  {
    sr: 136,
    city: "Agadir",
    city_code: "AGA",
    airport_name: "Agadir Almassira",
    country: "Morocco",
  },
  {
    sr: 138,
    city: "Agartala",
    city_code: "IXA",
    airport_name: "Singerbhil",
    country: "India",
  },
  {
    sr: 140,
    city: "Agatti Island",
    city_code: "AGX",
    airport_name: "Agatti Island",
    country: "India",
  },
  {
    sr: 142,
    city: "Agaun",
    city_code: "AUP",
    airport_name: "Agaun",
    country: "Papua New Guinea",
  },
  {
    sr: 144,
    city: "Agen",
    city_code: "AGF",
    airport_name: "La Garenne",
    country: "France",
  },
  {
    sr: 146,
    city: "Aggeneys",
    city_code: "AGZ",
    airport_name: "Aggeneys",
    country: "South Africa",
  },
  {
    sr: 148,
    city: "Aghajari",
    city_code: "AKW",
    airport_name: "Aghajari",
    country: "Iran",
  },
  {
    sr: 150,
    city: "Agnew",
    city_code: "AGW",
    airport_name: "Agnew",
    country: "Australia",
  },
  {
    sr: 152,
    city: "Agra",
    city_code: "AGR",
    airport_name: "Kheria",
    country: "India",
  },
  {
    sr: 154,
    city: "Agri",
    city_code: "AJI",
    airport_name: "Agri",
    country: "Turkey",
  },
  {
    sr: 156,
    city: "Agrinion",
    city_code: "AGQ",
    airport_name: "Agrinion",
    country: "Greece",
  },
  {
    sr: 158,
    city: "Aguaclara",
    city_code: "ACL",
    airport_name: "Aguaclara",
    country: "Colombia",
  },
  {
    sr: 160,
    city: "Aguadilla",
    city_code: "BQN",
    airport_name: "Borinquen",
    country: "Puerto Rico",
  },
  {
    sr: 162,
    city: "Aguascalientes",
    city_code: "AGU",
    airport_name: "Aguascalients",
    country: "Mexico",
  },
  {
    sr: 164,
    city: "Aguni",
    city_code: "AGJ",
    airport_name: "Aguni",
    country: "Japan",
  },
  {
    sr: 166,
    city: "Ahe",
    city_code: "AHE",
    airport_name: "Ahe Airport",
    country: "French Polynesia",
  },
  {
    sr: 170,
    city: "Ahmed Al Jaber",
    city_code: "XIJ",
    airport_name: "Ahmed Al Jaber",
    country: "Kuwait",
  },
  {
    sr: 172,
    city: "Ahmedabad",
    city_code: "AMD",
    airport_name: "Ahmedabad",
    country: "India",
  },
  {
    sr: 174,
    city: "Ahuas",
    city_code: "AHS",
    airport_name: "Ahuas",
    country: "Honduras",
  },
  {
    sr: 176,
    city: "Ahwaz",
    city_code: "AWZ",
    airport_name: "Ahwaz",
    country: "Iran",
  },
  {
    sr: 178,
    city: "Ai Ais",
    city_code: "AIW",
    airport_name: "Ai Ais",
    country: "Namibia",
  },
  {
    sr: 180,
    city: "Aiambak",
    city_code: "AIH",
    airport_name: "Aiambak",
    country: "Papua New Guinea",
  },
  {
    sr: 182,
    city: "Aiken",
    city_code: "AIK",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 184,
    city: "Ailigandi",
    city_code: "AIL",
    airport_name: "Ailigandi",
    country: "Panama",
  },
  {
    sr: 186,
    city: "ADAMPUR",
    city_code: "AIP",
    airport_name: "ADAMPUR",
    country: "India",
  },
  {
    sr: 188,
    city: "Ailuk Island",
    city_code: "AIM",
    airport_name: "Ailuk Island",
    country: "Marshall Islands",
  },
  {
    sr: 190,
    city: "Ainsworth",
    city_code: "ANW",
    airport_name: "Ainsworth",
    country: "United States",
  },
  {
    sr: 192,
    city: "Aiome",
    city_code: "AIE",
    airport_name: "Aiome",
    country: "Papua New Guinea",
  },
  {
    sr: 194,
    city: "Aioun El Atrouss",
    city_code: "AEO",
    airport_name: "Aioun El Atrouss",
    country: "Mauritania",
  },
  {
    sr: 196,
    city: "Airlie Beach",
    city_code: "WSY",
    airport_name: "Whitsunday Airstrip",
    country: "Australia",
  },
  {
    sr: 198,
    city: "Airok",
    city_code: "AIC",
    airport_name: "Airok",
    country: "Marshall Islands",
  },
  {
    sr: 200,
    city: "Aishalton",
    city_code: "AHL",
    airport_name: "Aishalton",
    country: "Guyana",
  },
  {
    sr: 204,
    city: "Aitape",
    city_code: "ATP",
    airport_name: "Airstrip",
    country: "Papua New Guinea",
  },
  {
    sr: 206,
    city: "Aitape",
    city_code: "TAJ",
    airport_name: "Tadji",
    country: "Papua New Guinea",
  },
  {
    sr: 208,
    city: "Aitutaki",
    city_code: "AIT",
    airport_name: "Aitutaki",
    country: "Cook Islands",
  },
  {
    sr: 210,
    city: "Aix La Chapelle",
    city_code: "XHJ",
    airport_name: "Railway Station",
    country: "Germany",
  },
  {
    sr: 212,
    city: "Aiyura",
    city_code: "AYU",
    airport_name: "Aiyura",
    country: "Papua New Guinea",
  },
  {
    sr: 214,
    city: "Aizawl",
    city_code: "AJL",
    airport_name: "LENGPUI",
    country: "India",
  },
  {
    sr: 216,
    city: "Ajaccio",
    city_code: "AJA",
    airport_name: "NAPOLEON BONAPARTE",
    country: "France",
  },
  {
    sr: 218,
    city: "Akhiok",
    city_code: "AKK",
    airport_name: "Akhiok SPB",
    country: "United States",
  },
  {
    sr: 220,
    city: "Akiachak",
    city_code: "KKI",
    airport_name: "Spb",
    country: "United States",
  },
  {
    sr: 222,
    city: "Akiak",
    city_code: "AKI",
    airport_name: "Akiak",
    country: "United States",
  },
  {
    sr: 224,
    city: "Akieni",
    city_code: "AKE",
    airport_name: "Akieni",
    country: "Gabon",
  },
  {
    sr: 226,
    city: "Akita",
    city_code: "AXT",
    airport_name: "Akita",
    country: "Japan",
  },
  {
    sr: 228,
    city: "Akjoujt",
    city_code: "AJJ",
    airport_name: "Akjoujt",
    country: "Mauritania",
  },
  {
    sr: 230,
    city: "Aklavik",
    city_code: "LAK",
    airport_name: "Aklavik",
    country: "Canada",
  },
  {
    sr: 232,
    city: "Akola",
    city_code: "AKD",
    airport_name: "Akola",
    country: "India",
  },
  {
    sr: 234,
    city: "Akron",
    city_code: "AKO",
    airport_name: "Washington Co",
    country: "United States",
  },
  {
    sr: 238,
    city: "Akron Canton",
    city_code: "CAK",
    airport_name: "Akron Canton Regional",
    country: "United States",
  },
  {
    sr: 240,
    city: "Akron Canton",
    city_code: "AKC",
    airport_name: "Fulton International",
    country: "United States",
  },
  {
    sr: 242,
    city: "Akrotiri",
    city_code: "AKT",
    airport_name: "Akrotiri RAF",
    country: "Cyprus",
  },
  {
    sr: 244,
    city: "Aksu",
    city_code: "AKU",
    airport_name: "Aksu",
    country: "China",
  },
  {
    sr: 246,
    city: "Aktau",
    city_code: "SCO",
    airport_name: "Aktau",
    country: "Kazakhstan",
  },
  {
    sr: 248,
    city: "AKTOBE",
    city_code: "AKX",
    airport_name: "AKTOBE",
    country: "Kazakhstan",
  },
  {
    sr: 250,
    city: "Akulivik",
    city_code: "AKV",
    airport_name: "Akulivik",
    country: "Canada",
  },
  {
    sr: 252,
    city: "Akunnaaq",
    city_code: "QCU",
    airport_name: "Heliport",
    country: "Greenland",
  },
  {
    sr: 254,
    city: "Akure",
    city_code: "AKR",
    airport_name: "Akure",
    country: "Nigeria",
  },
  {
    sr: 256,
    city: "Akureyri",
    city_code: "AEY",
    airport_name: "Akureyri",
    country: "Iceland",
  },
  {
    sr: 258,
    city: "Akutan",
    city_code: "KQA",
    airport_name: "Akutan",
    country: "United States",
  },
  {
    sr: 260,
    city: "Al",
    city_code: "XXR",
    airport_name: "Al Railway",
    country: "Norway",
  },
  {
    sr: 262,
    city: "Al Ain",
    city_code: "AAN",
    airport_name: "Al Ain",
    country: "United Arab Emirates",
  },
  {
    sr: 264,
    city: "El Arish",
    city_code: "AAC",
    airport_name: "El Arish",
    country: "Egypt",
  },
  {
    sr: 266,
    city: "Al Ghaydah",
    city_code: "AAY",
    airport_name: "Al Ghaydah",
    country: "Yemen REPUBLIC",
  },
  {
    sr: 268,
    city: "Al Hoceima",
    city_code: "AHU",
    airport_name: "Charif Al Idrissi",
    country: "Morocco",
  },
  {
    sr: 272,
    city: "Al Kharj",
    city_code: "AKH",
    airport_name: "Prince Sultan Air Base",
    country: "Saudi Arabia",
  },
  {
    sr: 274,
    city: "Al Koude",
    city_code: "WWG",
    airport_name: "Al Koude",
    country: "Oman",
  },
  {
    sr: 276,
    city: "Al Thaurah",
    city_code: "SOR",
    airport_name: "Al Thaurah",
    country: "Syria",
  },
  {
    sr: 278,
    city: "Al Udeid",
    city_code: "XJD",
    airport_name: "Al Udeid",
    country: "Qatar",
  },
  {
    sr: 280,
    city: "SURALLAH",
    city_code: "AAV",
    airport_name: "Allah valley",
    country: "Philippines",
  },
  {
    sr: 282,
    city: "Alahsa",
    city_code: "HOF",
    airport_name: "Alahsa",
    country: "Saudi Arabia",
  },
  {
    sr: 284,
    city: "Alakanuk",
    city_code: "AUK",
    airport_name: "Alakanuk",
    country: "United States",
  },
  {
    sr: 286,
    city: "Alameda",
    city_code: "NGZ",
    airport_name: "NAS",
    country: "United States",
  },
  {
    sr: 288,
    city: "Alamogordo",
    city_code: "HMN",
    airport_name: "Holloman AFB",
    country: "United States",
  },
  {
    sr: 290,
    city: "Alamogordo",
    city_code: "ALM",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 292,
    city: "Alamos",
    city_code: "XAL",
    airport_name: "Alamos",
    country: "Mexico",
  },
  {
    sr: 294,
    city: "Alamosa",
    city_code: "ALS",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 296,
    city: "Albacete",
    city_code: "ABC",
    airport_name: "Los Llanos",
    country: "Spain",
  },
  {
    sr: 298,
    city: "Al Baha",
    city_code: "ABT",
    airport_name: "Al Aqiq",
    country: "Saudi Arabia",
  },
  {
    sr: 300,
    city: "Albany",
    city_code: "NAB",
    airport_name: "Albany NAS",
    country: "United States",
  },
  {
    sr: 302,
    city: "Albany",
    city_code: "ABY",
    airport_name: "Dougherty County",
    country: "United States",
  },
  {
    sr: 306,
    city: "Albany",
    city_code: "ALB",
    airport_name: "Albany International",
    country: "United States",
  },
  {
    sr: 308,
    city: "Albany",
    city_code: "ZLY",
    airport_name: "Albany NY Rail",
    country: "United States",
  },
  {
    sr: 310,
    city: "Albany",
    city_code: "CVO",
    airport_name: "Albany",
    country: "United States",
  },
  {
    sr: 312,
    city: "Albany",
    city_code: "ALH",
    airport_name: "Albany",
    country: "Australia",
  },
  {
    sr: 314,
    city: "Albenga",
    city_code: "ALL",
    airport_name: "Albenga",
    country: "Italy",
  },
  {
    sr: 316,
    city: "Albert Lea",
    city_code: "AEL",
    airport_name: "Albert Lea",
    country: "United States",
  },
  {
    sr: 318,
    city: "Albi",
    city_code: "LBI",
    airport_name: "Le Sequestre",
    country: "France",
  },
  {
    sr: 320,
    city: "Albina",
    city_code: "ABN",
    airport_name: "Albina",
    country: "Suriname",
  },
  {
    sr: 322,
    city: "Albuq",
    city_code: "BUK",
    airport_name: "Albuq",
    country: "Yemen",
  },
  {
    sr: 324,
    city: "Albuquerque",
    city_code: "ABQ",
    airport_name: "Albuquerque International",
    country: "United States",
  },
  {
    sr: 326,
    city: "Albury",
    city_code: "ABX",
    airport_name: "Albury",
    country: "Australia",
  },
  {
    sr: 328,
    city: "Alconbury",
    city_code: "AYH",
    airport_name: "RAF Station",
    country: "United Kingdom",
  },
  {
    sr: 330,
    city: "Aldan",
    city_code: "ADH",
    airport_name: "Aldan",
    country: "Russia",
  },
  {
    sr: 332,
    city: "Alderney",
    city_code: "ACI",
    airport_name: "The Blaye",
    country: "United Kingdom",
  },
  {
    sr: 334,
    city: "Aleg",
    city_code: "LEG",
    airport_name: "Aleg",
    country: "Mauritania",
  },
  {
    sr: 336,
    city: "Alegrete",
    city_code: "ALQ",
    airport_name: "Federal",
    country: "Brazil",
  },
  {
    sr: 340,
    city: "Aleknagik",
    city_code: "WKK",
    airport_name: "Aleknagik",
    country: "United States",
  },
  {
    sr: 342,
    city: "Aleneva",
    city_code: "AED",
    airport_name: "Aleneva",
    country: "United States",
  },
  {
    sr: 344,
    city: "Alenquer",
    city_code: "ALT",
    airport_name: "Alenquer",
    country: "Brazil",
  },
  {
    sr: 346,
    city: "Aleppo",
    city_code: "ALP",
    airport_name: "Nejrab",
    country: "Syria",
  },
  {
    sr: 348,
    city: "Alert",
    city_code: "YLT",
    airport_name: "Alert",
    country: "Canada",
  },
  {
    sr: 350,
    city: "Alert Bay",
    city_code: "YAL",
    airport_name: "Alert Bay",
    country: "Canada",
  },
  {
    sr: 352,
    city: "Alerta",
    city_code: "ALD",
    airport_name: "Alerta",
    country: "Peru",
  },
  {
    sr: 354,
    city: "Alexander Bay",
    city_code: "ALJ",
    airport_name: "Alexander Bay",
    country: "South Africa",
  },
  {
    sr: 356,
    city: "Alexander City",
    city_code: "ALX",
    airport_name: "Thomas C Russell Fld",
    country: "United States",
  },
  {
    sr: 358,
    city: "Alexandra",
    city_code: "ALR",
    airport_name: "Alexandra",
    country: "New Zealand",
  },
  {
    sr: 360,
    city: "Alexandria",
    city_code: "HBE",
    airport_name: "Borg El Arab",
    country: "Egypt",
  },
  {
    sr: 362,
    city: "Alexandria",
    city_code: "ALY",
    airport_name: "El Nohza",
    country: "Egypt",
  },
  {
    sr: 364,
    city: "Alexandria",
    city_code: "AEX",
    airport_name: "Alexandria Internation",
    country: "United States",
  },
  {
    sr: 366,
    city: "Alexandria",
    city_code: "ESF",
    airport_name: "Esler Field",
    country: "United States",
  },
  {
    sr: 368,
    city: "Alexandria",
    city_code: "AXN",
    airport_name: "Alexandria",
    country: "United States",
  },
  {
    sr: 370,
    city: "Alexandria",
    city_code: "AXL",
    airport_name: "Alexandria",
    country: "Australia",
  },
  {
    sr: 374,
    city: "Alexandria Bay",
    city_code: "AXB",
    airport_name: "Alexandria Bay",
    country: "United States",
  },
  {
    sr: 376,
    city: "Alexandroupolis",
    city_code: "AXD",
    airport_name: "Demokritos Airport",
    country: "Greece",
  },
  {
    sr: 378,
    city: "Al Fujairah",
    city_code: "FJR",
    airport_name: "Fujairah Intl Arpt",
    country: "United Arab Emirates",
  },
  {
    sr: 380,
    city: "Algeciras",
    city_code: "AEI",
    airport_name: "Algeciras",
    country: "Spain",
  },
  {
    sr: 382,
    city: "Alghero",
    city_code: "AHO",
    airport_name: "Fertilia",
    country: "Italy",
  },
  {
    sr: 384,
    city: "Algiers",
    city_code: "ALG",
    airport_name: "Houari Boumediene",
    country: "Algeria",
  },
  {
    sr: 386,
    city: "Algona",
    city_code: "AXG",
    airport_name: "Algona",
    country: "United States",
  },
  {
    sr: 388,
    city: "Alicante",
    city_code: "ALC",
    airport_name: "Alicante",
    country: "Spain",
  },
  {
    sr: 390,
    city: "Alice",
    city_code: "ALI",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 392,
    city: "Alice Arm",
    city_code: "ZAA",
    airport_name: "Alice Arm",
    country: "Canada",
  },
  {
    sr: 394,
    city: "Alice Springs",
    city_code: "ASP",
    airport_name: "Alice Springs",
    country: "Australia",
  },
  {
    sr: 396,
    city: "Alice Springs",
    city_code: "XHW",
    airport_name: "Alice Springs Railway",
    country: "Australia",
  },
  {
    sr: 398,
    city: "Aliceville",
    city_code: "AIV",
    airport_name: "George Downer",
    country: "United States",
  },
  {
    sr: 400,
    city: "Alisabieh",
    city_code: "AII",
    airport_name: "Alisabieh",
    country: "Djibouti",
  },
  {
    sr: 402,
    city: "Alitak",
    city_code: "ALZ",
    airport_name: "Alitak SPB",
    country: "United States",
  },
  {
    sr: 404,
    city: "Aljouf",
    city_code: "AJO",
    airport_name: "Aljouf",
    country: "Yemen",
  },
  {
    sr: 408,
    city: "Allahabad",
    city_code: "IXD",
    airport_name: "Bamrauli",
    country: "India",
  },
  {
    sr: 410,
    city: "Allakaket",
    city_code: "AET",
    airport_name: "Allakaket",
    country: "United States",
  },
  {
    sr: 412,
    city: "Alldays",
    city_code: "ADY",
    airport_name: "Alldays",
    country: "South Africa",
  },
  {
    sr: 414,
    city: "Alliance",
    city_code: "AIA",
    airport_name: "Alliance",
    country: "United States",
  },
  {
    sr: 416,
    city: "Alluitsup Paa",
    city_code: "LLU",
    airport_name: "Alluitsup Paa",
    country: "Greenland",
  },
  {
    sr: 418,
    city: "Alma",
    city_code: "AMN",
    airport_name: "Gratiot Community",
    country: "United States",
  },
  {
    sr: 420,
    city: "Alma",
    city_code: "YTF",
    airport_name: "Alma",
    country: "Canada",
  },
  {
    sr: 422,
    city: "Almaty",
    city_code: "ALA",
    airport_name: "Almaty",
    country: "Kazakhstan",
  },
  {
    sr: 424,
    city: "Almenara",
    city_code: "AMJ",
    airport_name: "Almenara",
    country: "Brazil",
  },
  {
    sr: 426,
    city: "Almeria",
    city_code: "LEI",
    airport_name: "Almeria",
    country: "Spain",
  },
  {
    sr: 428,
    city: "Along",
    city_code: "IXV",
    airport_name: "Along",
    country: "India",
  },
  {
    sr: 430,
    city: "Alor Island",
    city_code: "ARD",
    airport_name: "Alor Island",
    country: "Indonesia",
  },
  {
    sr: 432,
    city: "Alor Setar",
    city_code: "AOR",
    airport_name: "Alor Setar",
    country: "Malaysia",
  },
  {
    sr: 434,
    city: "Alotau",
    city_code: "GUR",
    airport_name: "Gurney",
    country: "Papua New Guinea",
  },
  {
    sr: 436,
    city: "Alowe",
    city_code: "AWE",
    airport_name: "Alowe",
    country: "Gabon",
  },
  {
    sr: 438,
    city: "Alpe D Huez",
    city_code: "AHZ",
    airport_name: "Alpe D Huez",
    country: "France",
  },
  {
    sr: 442,
    city: "Alpena",
    city_code: "APN",
    airport_name: "Alpena County Regional",
    country: "United States",
  },
  {
    sr: 444,
    city: "Alpha",
    city_code: "ABH",
    airport_name: "Alpha",
    country: "Australia",
  },
  {
    sr: 446,
    city: "Alpine",
    city_code: "ALE",
    airport_name: "Alpine",
    country: "United States",
  },
  {
    sr: 448,
    city: "Alroy Downs",
    city_code: "AYD",
    airport_name: "Alroy Downs",
    country: "Australia",
  },
  {
    sr: 450,
    city: "Alta",
    city_code: "ALF",
    airport_name: "Alta",
    country: "Norway",
  },
  {
    sr: 452,
    city: "Alta Floresta",
    city_code: "AFL",
    airport_name: "Alta Floresta",
    country: "Brazil",
  },
  {
    sr: 454,
    city: "Alta Lake",
    city_code: "YAE",
    airport_name: "Alta Lake",
    country: "Canada",
  },
  {
    sr: 456,
    city: "Altai",
    city_code: "LTI",
    airport_name: "Altai",
    country: "Mongolia",
  },
  {
    sr: 458,
    city: "Altamira",
    city_code: "ATM",
    airport_name: "Altamira",
    country: "Brazil",
  },
  {
    sr: 460,
    city: "Altay",
    city_code: "AAT",
    airport_name: "Altay",
    country: "China",
  },
  {
    sr: 462,
    city: "Altenburg",
    city_code: "AOC",
    airport_name: "Altenburg Nobitz",
    country: "Germany",
  },
  {
    sr: 464,
    city: "Altenrhein",
    city_code: "ACH",
    airport_name: "Altenrhein",
    country: "Switzerland",
  },
  {
    sr: 466,
    city: "Alto Molocue",
    city_code: "AME",
    airport_name: "Alto Molocue",
    country: "Mozambique",
  },
  {
    sr: 468,
    city: "Alto Palena",
    city_code: "WAP",
    airport_name: "Alto Palena",
    country: "Chile",
  },
  {
    sr: 470,
    city: "Alto Parnaiba",
    city_code: "APY",
    airport_name: "Alto Parnaiba",
    country: "Brazil",
  },
  {
    sr: 472,
    city: "Alto Rio Senguerr",
    city_code: "ARR",
    airport_name: "Alto Rio Senguerr",
    country: "Argentina",
  },
  {
    sr: 476,
    city: "Alton",
    city_code: "ALN",
    airport_name: "Alton",
    country: "United States",
  },
  {
    sr: 478,
    city: "Alton Downs",
    city_code: "AWN",
    airport_name: "Alton Downs",
    country: "Australia",
  },
  {
    sr: 480,
    city: "Altus",
    city_code: "LTS",
    airport_name: "Altus AFB",
    country: "United States",
  },
  {
    sr: 482,
    city: "Altus",
    city_code: "AXS",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 484,
    city: "Alula",
    city_code: "ALU",
    airport_name: "Alula",
    country: "Somalia",
  },
  {
    sr: 486,
    city: "Alvdal",
    city_code: "XJA",
    airport_name: "Railway",
    country: "Norway",
  },
  {
    sr: 488,
    city: "Alvesta",
    city_code: "XXA",
    airport_name: "Alvesta",
    country: "Sweden",
  },
  {
    sr: 490,
    city: "Alyeska",
    city_code: "AQY",
    airport_name: "Alyeska",
    country: "United States",
  },
  {
    sr: 492,
    city: "Am Timan",
    city_code: "AMC",
    airport_name: "Am Timan",
    country: "Guam",
  },
  {
    sr: 494,
    city: "Ama",
    city_code: "AMF",
    airport_name: "Ama",
    country: "Papua New Guinea",
  },
  {
    sr: 496,
    city: "Amahai",
    city_code: "AHI",
    airport_name: "Amahai",
    country: "Indonesia",
  },
  {
    sr: 498,
    city: "Amalfi",
    city_code: "AFI",
    airport_name: "Amalfi",
    country: "Colombia",
  },
  {
    sr: 500,
    city: "Amami O Shima",
    city_code: "ASJ",
    airport_name: "Amami O Shima",
    country: "Japan",
  },
  {
    sr: 502,
    city: "Amanab",
    city_code: "AMU",
    airport_name: "Amanab",
    country: "Papua New Guinea",
  },
  {
    sr: 504,
    city: "Amarillo",
    city_code: "AMA",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 506,
    city: "Amarillo",
    city_code: "TDW",
    airport_name: "Tradewind",
    country: "United States",
  },
  {
    sr: 510,
    city: "Amata",
    city_code: "AMT",
    airport_name: "Amata",
    country: "Australia",
  },
  {
    sr: 512,
    city: "Amazon Bay",
    city_code: "AZB",
    airport_name: "Amazon Bay",
    country: "Papua New Guinea",
  },
  {
    sr: 514,
    city: "Ambanja",
    city_code: "IVA",
    airport_name: "Ambanja",
    country: "Madagascar",
  },
  {
    sr: 516,
    city: "Ambato",
    city_code: "ATF",
    airport_name: "Chachoan",
    country: "Ecuador",
  },
  {
    sr: 518,
    city: "Ambatolahy",
    city_code: "AHY",
    airport_name: "Ambatolahy",
    country: "Madagascar",
  },
  {
    sr: 520,
    city: "Ambatomainty",
    city_code: "AMY",
    airport_name: "Ambatomainty",
    country: "Madagascar",
  },
  {
    sr: 522,
    city: "Ambatondrazaka",
    city_code: "WAM",
    airport_name: "Ambatondrazaka",
    country: "Madagascar",
  },
  {
    sr: 524,
    city: "Ambilobe",
    city_code: "AMB",
    airport_name: "Ambilobe",
    country: "Madagascar",
  },
  {
    sr: 526,
    city: "Ambler",
    city_code: "ABL",
    airport_name: "Ambler",
    country: "United States",
  },
  {
    sr: 528,
    city: "Amboin",
    city_code: "AMG",
    airport_name: "Amboin",
    country: "Papua New Guinea",
  },
  {
    sr: 530,
    city: "Ambon",
    city_code: "AMQ",
    airport_name: "Pattimura",
    country: "Indonesia",
  },
  {
    sr: 532,
    city: "Amboseli",
    city_code: "ASV",
    airport_name: "Amboseli",
    country: "Kenya",
  },
  {
    sr: 534,
    city: "Ambriz",
    city_code: "AZZ",
    airport_name: "Ambriz",
    country: "Angola",
  },
  {
    sr: 536,
    city: "Ambunti",
    city_code: "AUJ",
    airport_name: "Ambunti",
    country: "Papua New Guinea",
  },
  {
    sr: 538,
    city: "Amchitka",
    city_code: "AHT",
    airport_name: "Amchitka",
    country: "United States",
  },
  {
    sr: 540,
    city: "Amderma",
    city_code: "AMV",
    airport_name: "Amderma",
    country: "Russia",
  },
  {
    sr: 544,
    city: "American River",
    city_code: "RCN",
    airport_name: "American River",
    country: "Australia",
  },
  {
    sr: 546,
    city: "Amery",
    city_code: "AHH",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 548,
    city: "Ames",
    city_code: "AMW",
    airport_name: "Ames",
    country: "United States",
  },
  {
    sr: 550,
    city: "Amityville",
    city_code: "AYZ",
    airport_name: "Zahns",
    country: "United States",
  },
  {
    sr: 552,
    city: "Amman",
    city_code: "ADJ",
    airport_name: "Civil   Marka Airport",
    country: "Jordan",
  },
  {
    sr: 554,
    city: "Amman",
    city_code: "AMM",
    airport_name: "Queen Alia Intl",
    country: "Jordan",
  },
  {
    sr: 556,
    city: "Ammaroo",
    city_code: "AMX",
    airport_name: "Ammaroo",
    country: "Australia",
  },
  {
    sr: 558,
    city: "Amook",
    city_code: "AOS",
    airport_name: "Amook",
    country: "United States",
  },
  {
    sr: 560,
    city: "Amos",
    city_code: "YEY",
    airport_name: "Amos",
    country: "Canada",
  },
  {
    sr: 562,
    city: "Ampanihy",
    city_code: "AMP",
    airport_name: "Ampanihy",
    country: "Madagascar",
  },
  {
    sr: 564,
    city: "Ampara",
    city_code: "ADP",
    airport_name: "Ampara",
    country: "Sri Lanka",
  },
  {
    sr: 566,
    city: "Ampara",
    city_code: "AFK",
    airport_name: "Kondavattavan Tank",
    country: "Sri Lanka",
  },
  {
    sr: 568,
    city: "Amritsar",
    city_code: "ATQ",
    airport_name: "Raja Sansi",
    country: "India",
  },
  {
    sr: 570,
    city: "Amsterdam",
    city_code: "AMS",
    airport_name: "Amsterdam Schiphol",
    country: "Netherlands",
  },
  {
    sr: 572,
    city: "An Shun",
    city_code: "AVA",
    airport_name: "An Shun Huang Guo Shu",
    country: "China",
  },
  {
    sr: 574,
    city: "Anaa",
    city_code: "AAA",
    airport_name: "Anaa",
    country: "French Polynesia",
  },
  {
    sr: 578,
    city: "Anaco",
    city_code: "AAO",
    airport_name: "Anaco",
    country: "Venezuela",
  },
  {
    sr: 580,
    city: "Anacortes",
    city_code: "OTS",
    airport_name: "Anacortes",
    country: "United States",
  },
  {
    sr: 582,
    city: "Anacostia",
    city_code: "NDV",
    airport_name: "USN Heliport",
    country: "United States",
  },
  {
    sr: 584,
    city: "Anadyr",
    city_code: "DYR",
    airport_name: "Anadyr",
    country: "Russia",
  },
  {
    sr: 586,
    city: "Anaheim",
    city_code: "ANA",
    airport_name: "Anaheim",
    country: "United States",
  },
  {
    sr: 588,
    city: "Anahim Lake",
    city_code: "YAA",
    airport_name: "Anahim Lake",
    country: "Canada",
  },
  {
    sr: 590,
    city: "Anaktuvuk",
    city_code: "AKP",
    airport_name: "Anaktuvuk",
    country: "United States",
  },
  {
    sr: 592,
    city: "Analalava",
    city_code: "HVA",
    airport_name: "Analalava",
    country: "Madagascar",
  },
  {
    sr: 594,
    city: "Anapa",
    city_code: "AAQ",
    airport_name: "Anapa",
    country: "Russia",
  },
  {
    sr: 596,
    city: "Anapolis",
    city_code: "APS",
    airport_name: "Anapolis",
    country: "Brazil",
  },
  {
    sr: 598,
    city: "Anchorage",
    city_code: "EDF",
    airport_name: "Elmendorf AFB",
    country: "United States",
  },
  {
    sr: 600,
    city: "Anchorage",
    city_code: "ANC",
    airport_name: "Ted Stevens Anchorage Int",
    country: "United States",
  },
  {
    sr: 602,
    city: "Anchorage",
    city_code: "MRI",
    airport_name: "Merrill Field",
    country: "United States",
  },
  {
    sr: 604,
    city: "Ancona",
    city_code: "AOI",
    airport_name: "Falconara",
    country: "Italy",
  },
  {
    sr: 606,
    city: "Ancud",
    city_code: "ZUD",
    airport_name: "Ancud",
    country: "Chile",
  },
  {
    sr: 608,
    city: "Andahuaylas",
    city_code: "ANS",
    airport_name: "Andahuaylas",
    country: "Peru",
  },
  {
    sr: 612,
    city: "Andakombe",
    city_code: "ADC",
    airport_name: "Andakombe",
    country: "Papua New Guinea",
  },
  {
    sr: 614,
    city: "Andamooka",
    city_code: "ADO",
    airport_name: "Andamooka",
    country: "Australia",
  },
  {
    sr: 616,
    city: "Andapa",
    city_code: "ZWA",
    airport_name: "Andapa",
    country: "Madagascar",
  },
  {
    sr: 618,
    city: "Andenes",
    city_code: "ANX",
    airport_name: "Andenes",
    country: "Norway",
  },
  {
    sr: 620,
    city: "Anderson",
    city_code: "AID",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 622,
    city: "Anderson",
    city_code: "AND",
    airport_name: "Anderson",
    country: "United States",
  },
  {
    sr: 624,
    city: "Andes",
    city_code: "ADN",
    airport_name: "Andes",
    country: "Colombia",
  },
  {
    sr: 626,
    city: "Andizhan",
    city_code: "AZN",
    airport_name: "Andizhan",
    country: "Uzbekistan",
  },
  {
    sr: 628,
    city: "Andorra La Vella",
    city_code: "ALV",
    airport_name: "Andorra La Vella H P",
    country: "Andorra",
  },
  {
    sr: 630,
    city: "Andover",
    city_code: "ADV",
    airport_name: "Andover",
    country: "United Kingdom",
  },
  {
    sr: 632,
    city: "Andrews",
    city_code: "ADR",
    airport_name: "Andrews",
    country: "United States",
  },
  {
    sr: 634,
    city: "Andriamena",
    city_code: "WAD",
    airport_name: "Andriamena",
    country: "Madagascar",
  },
  {
    sr: 636,
    city: "Andros Town",
    city_code: "ASD",
    airport_name: "Andros Town",
    country: "Bahamas",
  },
  {
    sr: 638,
    city: "Andulo",
    city_code: "ANL",
    airport_name: "Andulo",
    country: "Angola",
  },
  {
    sr: 640,
    city: "Anegada",
    city_code: "NGD",
    airport_name: "Anegada",
    country: "Virgin Islands  British",
  },
  {
    sr: 642,
    city: "ANATOM ISLAND",
    city_code: "AUY",
    airport_name: "ANATOM ISLAND",
    country: "Vanuatu",
  },
  {
    sr: 646,
    city: "Angel Fire",
    city_code: "AXX",
    airport_name: "Angel Fire",
    country: "United States",
  },
  {
    sr: 648,
    city: "Angelholm Helsingborg",
    city_code: "AGH",
    airport_name: "Angelholm",
    country: "Sweden",
  },
  {
    sr: 650,
    city: "Angelholm Helsingborg",
    city_code: "XYQ",
    airport_name: "Angelholm Railway Svc",
    country: "Sweden",
  },
  {
    sr: 652,
    city: "Angelholm Helsingborg",
    city_code: "JHE",
    airport_name: "Heliport",
    country: "Sweden",
  },
  {
    sr: 654,
    city: "Angelholm Helsingborg",
    city_code: "XYH",
    airport_name: "Helsingborg Railway",
    country: "Sweden",
  },
  {
    sr: 656,
    city: "Angers",
    city_code: "ANE",
    airport_name: "Marce",
    country: "France",
  },
  {
    sr: 658,
    city: "Anggi",
    city_code: "AGD",
    airport_name: "Anggi",
    country: "Indonesia",
  },
  {
    sr: 660,
    city: "Angling Lake",
    city_code: "YAX",
    airport_name: "Angling Lake",
    country: "Canada",
  },
  {
    sr: 662,
    city: "Angoche",
    city_code: "ANO",
    airport_name: "Angoche",
    country: "Mozambique",
  },
  {
    sr: 664,
    city: "Angola",
    city_code: "ANQ",
    airport_name: "Tri State Steuben Cty",
    country: "United States",
  },
  {
    sr: 666,
    city: "Angoon",
    city_code: "AGN",
    airport_name: "Angoon",
    country: "United States",
  },
  {
    sr: 668,
    city: "Angoram",
    city_code: "AGG",
    airport_name: "Angoram",
    country: "Papua New Guinea",
  },
  {
    sr: 670,
    city: "Angouleme",
    city_code: "ANG",
    airport_name: "Brie Champniers",
    country: "France",
  },
  {
    sr: 672,
    city: "Anguganak",
    city_code: "AKG",
    airport_name: "Anguganak",
    country: "Papua New Guinea",
  },
  {
    sr: 674,
    city: "Anguilla",
    city_code: "AXA",
    airport_name: "Wallblake",
    country: "Anguilla",
  },
  {
    sr: 676,
    city: "Anguilla",
    city_code: "RFK",
    airport_name: "Rollang Field",
    country: "United States",
  },
  {
    sr: 680,
    city: "Angus Downs",
    city_code: "ANZ",
    airport_name: "Angus Downs",
    country: "Australia",
  },
  {
    sr: 682,
    city: "Aniak",
    city_code: "ANI",
    airport_name: "Aniak",
    country: "United States",
  },
  {
    sr: 684,
    city: "Anita Bay",
    city_code: "AIB",
    airport_name: "Anita Bay",
    country: "United States",
  },
  {
    sr: 686,
    city: "Aniwa",
    city_code: "AWD",
    airport_name: "Aniwa",
    country: "Vanuatu",
  },
  {
    sr: 688,
    city: "Anjouan",
    city_code: "AJN",
    airport_name: "Ouani",
    country: "Comoros",
  },
  {
    sr: 690,
    city: "Ankang",
    city_code: "AKA",
    airport_name: "Ankang",
    country: "China",
  },
  {
    sr: 692,
    city: "Ankara",
    city_code: "ESB",
    airport_name: "Esenboga",
    country: "Turkey",
  },
  {
    sr: 694,
    city: "Ankara",
    city_code: "ANK",
    airport_name: "Etimesgut",
    country: "Turkey",
  },
  {
    sr: 696,
    city: "Ankavandra",
    city_code: "JVA",
    airport_name: "Ankavandra",
    country: "Madagascar",
  },
  {
    sr: 698,
    city: "Ankazoabo",
    city_code: "WAK",
    airport_name: "Ankazoabo",
    country: "Madagascar",
  },
  {
    sr: 700,
    city: "Ankokoambo",
    city_code: "NKO",
    airport_name: "Ankokoambo",
    country: "Madagascar",
  },
  {
    sr: 702,
    city: "Ankorefo",
    city_code: "WOR",
    airport_name: "Moramba",
    country: "Madagascar",
  },
  {
    sr: 704,
    city: "Ann Arbor",
    city_code: "ARB",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 706,
    city: "Annaba",
    city_code: "AAE",
    airport_name: "Les Salines",
    country: "Algeria",
  },
  {
    sr: 708,
    city: "Annai",
    city_code: "NAI",
    airport_name: "Annai",
    country: "Guyana",
  },
  {
    sr: 710,
    city: "Annanberg",
    city_code: "AOB",
    airport_name: "Annanberg",
    country: "Papua New Guinea",
  },
  {
    sr: 714,
    city: "Annapolis",
    city_code: "ANP",
    airport_name: "Lee",
    country: "United States",
  },
  {
    sr: 716,
    city: "Annecy",
    city_code: "NCY",
    airport_name: "Annecy Meythe",
    country: "France",
  },
  {
    sr: 718,
    city: "Annette Island",
    city_code: "ANN",
    airport_name: "Annette Island",
    country: "United States",
  },
  {
    sr: 720,
    city: "Anniston",
    city_code: "ANB",
    airport_name: "County Arpt",
    country: "United States",
  },
  {
    sr: 722,
    city: "Anniston",
    city_code: "QAW",
    airport_name: "Ft Mcclellan Bus Trml",
    country: "United States",
  },
  {
    sr: 724,
    city: "Anniston",
    city_code: "RLI",
    airport_name: "Reilly AHP",
    country: "United States",
  },
  {
    sr: 726,
    city: "Anqing",
    city_code: "AQG",
    airport_name: "Anqing",
    country: "China",
  },
  {
    sr: 728,
    city: "Anshan",
    city_code: "AOG",
    airport_name: "Anshan",
    country: "China",
  },
  {
    sr: 730,
    city: "Anta",
    city_code: "ATA",
    airport_name: "Anta",
    country: "Peru",
  },
  {
    sr: 732,
    city: "Antalaha",
    city_code: "ANM",
    airport_name: "Antsirabato",
    country: "Madagascar",
  },
  {
    sr: 734,
    city: "Antalya",
    city_code: "AYT",
    airport_name: "Antalya",
    country: "Turkey",
  },
  {
    sr: 736,
    city: "Antananarivo",
    city_code: "TNR",
    airport_name: "Antananarivo",
    country: "Madagascar",
  },
  {
    sr: 738,
    city: "Anthony",
    city_code: "ANY",
    airport_name: "Anthony",
    country: "United States",
  },
  {
    sr: 740,
    city: "Anthony Lagoon",
    city_code: "AYL",
    airport_name: "Anthony Lagoon",
    country: "Australia",
  },
  {
    sr: 742,
    city: "Antigua",
    city_code: "ANU",
    airport_name: "V C Bird Intl",
    country: "Antigua and Barbuda",
  },
  {
    sr: 744,
    city: "Antique",
    city_code: "EUQ",
    airport_name: "Evelio Javier",
    country: "Philippines",
  },
  {
    sr: 748,
    city: "Antlers",
    city_code: "ATE",
    airport_name: "Antlers",
    country: "United States",
  },
  {
    sr: 750,
    city: "Antofagasta",
    city_code: "ANF",
    airport_name: "Cerro Moreno",
    country: "Chile",
  },
  {
    sr: 752,
    city: "Antsalova",
    city_code: "WAQ",
    airport_name: "Antsalova",
    country: "Madagascar",
  },
  {
    sr: 754,
    city: "Antsirabe",
    city_code: "ATJ",
    airport_name: "Antsirabe",
    country: "Madagascar",
  },
  {
    sr: 756,
    city: "Antsiranana",
    city_code: "DIE",
    airport_name: "Antsiranana Arrachart",
    country: "Madagascar",
  },
  {
    sr: 758,
    city: "Antsohihy",
    city_code: "WAI",
    airport_name: "Antsohihy",
    country: "Madagascar",
  },
  {
    sr: 760,
    city: "Antwerp",
    city_code: "ZYZ",
    airport_name: "Berchem Railway Stn",
    country: "Belgium",
  },
  {
    sr: 762,
    city: "Antwerp",
    city_code: "ZAY",
    airport_name: "De Keyserlei Bus Stn",
    country: "Belgium",
  },
  {
    sr: 764,
    city: "Antwerp",
    city_code: "ANR",
    airport_name: "Deurne",
    country: "Belgium",
  },
  {
    sr: 766,
    city: "Anuha Island Resort",
    city_code: "ANH",
    airport_name: "Anuha Island Resort",
    country: "Solomon Islands",
  },
  {
    sr: 768,
    city: "Anuradhapura",
    city_code: "ACJ",
    airport_name: "Anuradhapura",
    country: "Sri Lanka",
  },
  {
    sr: 770,
    city: "Anvik",
    city_code: "ANV",
    airport_name: "Anvik",
    country: "United States",
  },
  {
    sr: 772,
    city: "Anyang",
    city_code: "AYN",
    airport_name: "Anyang",
    country: "China",
  },
  {
    sr: 774,
    city: "Aomori",
    city_code: "AOJ",
    airport_name: "Aomori",
    country: "Japan",
  },
  {
    sr: 776,
    city: "Aosta",
    city_code: "AOT",
    airport_name: "Corrado Gex",
    country: "Italy",
  },
  {
    sr: 778,
    city: "Apalachicola",
    city_code: "AAF",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 782,
    city: "Apalapsili",
    city_code: "AAS",
    airport_name: "Apalapsili",
    country: "Indonesia",
  },
  {
    sr: 784,
    city: "Apartado",
    city_code: "APO",
    airport_name: "Apartado",
    country: "Colombia",
  },
  {
    sr: 786,
    city: "Apataki",
    city_code: "APK",
    airport_name: "Apataki",
    country: "French Polynesia",
  },
  {
    sr: 788,
    city: "Apatzingan",
    city_code: "AZG",
    airport_name: "Apatzingan",
    country: "Mexico",
  },
  {
    sr: 790,
    city: "Apia",
    city_code: "FGI",
    airport_name: "Fagali I",
    country: "Samoa",
  },
  {
    sr: 792,
    city: "Apia",
    city_code: "APW",
    airport_name: "Faleolo",
    country: "Samoa",
  },
  {
    sr: 794,
    city: "Apiay",
    city_code: "API",
    airport_name: "Apiay",
    country: "Colombia",
  },
  {
    sr: 796,
    city: "Apolo",
    city_code: "APB",
    airport_name: "Apolo",
    country: "Bolivia",
  },
  {
    sr: 798,
    city: "Apple Valley",
    city_code: "APV",
    airport_name: "Apple Valley",
    country: "United States",
  },
  {
    sr: 800,
    city: "Appleton",
    city_code: "ATW",
    airport_name: "Outagamie County",
    country: "United States",
  },
  {
    sr: 802,
    city: "April River",
    city_code: "APR",
    airport_name: "April River",
    country: "Papua New Guinea",
  },
  {
    sr: 804,
    city: "Apucarana",
    city_code: "APU",
    airport_name: "Apucarana",
    country: "Brazil",
  },
  {
    sr: 806,
    city: "Aqaba",
    city_code: "AQJ",
    airport_name: "King Hussein Intl",
    country: "Jordan",
  },
  {
    sr: 808,
    city: "Araak",
    city_code: "AJK",
    airport_name: "Araak",
    country: "Iran",
  },
  {
    sr: 810,
    city: "Aracaju",
    city_code: "AJU",
    airport_name: "Aracaju",
    country: "Brazil",
  },
  {
    sr: 812,
    city: "Aracatuba",
    city_code: "ARU",
    airport_name: "Aracatuba",
    country: "Brazil",
  },
  {
    sr: 816,
    city: "Arad",
    city_code: "ARW",
    airport_name: "Arad",
    country: "Romania",
  },
  {
    sr: 818,
    city: "Aragarcas",
    city_code: "ARS",
    airport_name: "Aragarcas",
    country: "Brazil",
  },
  {
    sr: 820,
    city: "Aragip",
    city_code: "ARP",
    airport_name: "Aragip",
    country: "Papua New Guinea",
  },
  {
    sr: 822,
    city: "Araguaina",
    city_code: "AUX",
    airport_name: "Araguaina",
    country: "Brazil",
  },
  {
    sr: 824,
    city: "Aramac",
    city_code: "AXC",
    airport_name: "Aramac",
    country: "Australia",
  },
  {
    sr: 826,
    city: "Arandis",
    city_code: "ADI",
    airport_name: "Arandis",
    country: "Namibia",
  },
  {
    sr: 828,
    city: "Aranuka",
    city_code: "AAK",
    airport_name: "Aranuka",
    country: "Kiribati",
  },
  {
    sr: 830,
    city: "Arapahoe",
    city_code: "AHF",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 832,
    city: "Arapiraca",
    city_code: "APQ",
    airport_name: "Arapiraca",
    country: "Brazil",
  },
  {
    sr: 834,
    city: "Arapongas",
    city_code: "APX",
    airport_name: "Arapongas",
    country: "Brazil",
  },
  {
    sr: 836,
    city: "Arapoti",
    city_code: "AAG",
    airport_name: "Arapoti",
    country: "Brazil",
  },
  {
    sr: 838,
    city: "Arar",
    city_code: "RAE",
    airport_name: "Arar",
    country: "Saudi Arabia",
  },
  {
    sr: 840,
    city: "Araracuara",
    city_code: "ACR",
    airport_name: "Araracuara",
    country: "Colombia",
  },
  {
    sr: 842,
    city: "Araraquara",
    city_code: "AQA",
    airport_name: "Manaus",
    country: "Brazil",
  },
  {
    sr: 844,
    city: "Ararat",
    city_code: "ARY",
    airport_name: "Ararat",
    country: "Australia",
  },
  {
    sr: 846,
    city: "Arauca",
    city_code: "AUC",
    airport_name: "Arauca",
    country: "Colombia",
  },
  {
    sr: 850,
    city: "Arauquita",
    city_code: "ARQ",
    airport_name: "Arauquita",
    country: "Colombia",
  },
  {
    sr: 852,
    city: "Arawa",
    city_code: "RAW",
    airport_name: "Arawa",
    country: "Papua New Guinea",
  },
  {
    sr: 854,
    city: "Araxa",
    city_code: "AAX",
    airport_name: "Araxa",
    country: "Brazil",
  },
  {
    sr: 856,
    city: "Arba Mintch",
    city_code: "AMH",
    airport_name: "Arba Mintch",
    country: "Ethiopia",
  },
  {
    sr: 858,
    city: "Arboletas",
    city_code: "ARO",
    airport_name: "Arboletas",
    country: "Colombia",
  },
  {
    sr: 860,
    city: "Arcata",
    city_code: "ACV",
    airport_name: "Arcata",
    country: "United States",
  },
  {
    sr: 862,
    city: "Arctic Bay",
    city_code: "YAB",
    airport_name: "Arctic Bay",
    country: "Canada",
  },
  {
    sr: 864,
    city: "Arctic Village",
    city_code: "ARC",
    airport_name: "Arctic Village",
    country: "United States",
  },
  {
    sr: 866,
    city: "Ardabil",
    city_code: "ADU",
    airport_name: "Ardabil",
    country: "Iran",
  },
  {
    sr: 868,
    city: "Ardmore",
    city_code: "AMZ",
    airport_name: "Ardmore",
    country: "New Zealand",
  },
  {
    sr: 870,
    city: "Ardmore",
    city_code: "AHD",
    airport_name: "Downtown",
    country: "United States",
  },
  {
    sr: 872,
    city: "Ardmore",
    city_code: "ADM",
    airport_name: "Ardmore Municipal Arpt",
    country: "United States",
  },
  {
    sr: 874,
    city: "Arecibo",
    city_code: "ARE",
    airport_name: "Arecibo",
    country: "Puerto Rico",
  },
  {
    sr: 876,
    city: "Arequipa",
    city_code: "AQP",
    airport_name: "Rodriguez Ballon",
    country: "Peru",
  },
  {
    sr: 878,
    city: "Argentia",
    city_code: "NWP",
    airport_name: "NS",
    country: "Canada",
  },
  {
    sr: 880,
    city: "Argyle",
    city_code: "GYL",
    airport_name: "Argyle",
    country: "Australia",
  },
  {
    sr: 884,
    city: "Argyle Downs",
    city_code: "AGY",
    airport_name: "Argyle Downs",
    country: "Australia",
  },
  {
    sr: 886,
    city: "Aribinda",
    city_code: "XAR",
    airport_name: "Aribinda",
    country: "Burkina Faso",
  },
  {
    sr: 888,
    city: "Arica",
    city_code: "ARI",
    airport_name: "Chacalluta",
    country: "Chile",
  },
  {
    sr: 890,
    city: "Arica",
    city_code: "ACM",
    airport_name: "Arica",
    country: "Colombia",
  },
  {
    sr: 892,
    city: "Aripuana",
    city_code: "AIR",
    airport_name: "Aripuana",
    country: "Brazil",
  },
  {
    sr: 894,
    city: "Ariquemes",
    city_code: "AQM",
    airport_name: "Ariquemes",
    country: "Brazil",
  },
  {
    sr: 896,
    city: "Arkalyk",
    city_code: "AYK",
    airport_name: "Arkalyk",
    country: "Kazakhstan",
  },
  {
    sr: 898,
    city: "Arkhangelsk",
    city_code: "ARH",
    airport_name: "Arkhangelsk",
    country: "Russia",
  },
  {
    sr: 900,
    city: "Arlington Heights",
    city_code: "JLH",
    airport_name: "US Army Heliport",
    country: "United States",
  },
  {
    sr: 902,
    city: "Arlit",
    city_code: "RLT",
    airport_name: "Arlit",
    country: "Niger",
  },
  {
    sr: 904,
    city: "Arly",
    city_code: "ARL",
    airport_name: "Arly",
    country: "Burkina Faso",
  },
  {
    sr: 906,
    city: "Armenia",
    city_code: "AXM",
    airport_name: "El Eden",
    country: "Colombia",
  },
  {
    sr: 908,
    city: "Armidale",
    city_code: "ARM",
    airport_name: "Armidale",
    country: "Australia",
  },
  {
    sr: 910,
    city: "Armstrong",
    city_code: "YYW",
    airport_name: "Armstrong",
    country: "Canada",
  },
  {
    sr: 912,
    city: "Arna",
    city_code: "XHT",
    airport_name: "Railway",
    country: "Norway",
  },
  {
    sr: 914,
    city: "Arnes",
    city_code: "YNR",
    airport_name: "Arnes",
    country: "Canada",
  },
  {
    sr: 918,
    city: "Arno",
    city_code: "AMR",
    airport_name: "Arno",
    country: "Marshall Islands",
  },
  {
    sr: 920,
    city: "Aroa",
    city_code: "AOA",
    airport_name: "Aroa",
    country: "Papua New Guinea",
  },
  {
    sr: 922,
    city: "Arona",
    city_code: "AON",
    airport_name: "Arona",
    country: "Papua New Guinea",
  },
  {
    sr: 924,
    city: "Arona",
    city_code: "RNA",
    airport_name: "Ulawa Airport",
    country: "Solomon Islands",
  },
  {
    sr: 926,
    city: "Arorae Island",
    city_code: "AIS",
    airport_name: "Arorae Island",
    country: "Kiribati",
  },
  {
    sr: 928,
    city: "Arrabury",
    city_code: "AAB",
    airport_name: "Arrabury",
    country: "Australia",
  },
  {
    sr: 930,
    city: "Arraias",
    city_code: "AAI",
    airport_name: "Arraias",
    country: "Brazil",
  },
  {
    sr: 932,
    city: "Arso",
    city_code: "ARJ",
    airport_name: "Arso",
    country: "Indonesia",
  },
  {
    sr: 934,
    city: "Arsuk",
    city_code: "JRK",
    airport_name: "Arsuk",
    country: "Greenland",
  },
  {
    sr: 936,
    city: "Artesia",
    city_code: "ATS",
    airport_name: "Artesia",
    country: "United States",
  },
  {
    sr: 938,
    city: "Artigas",
    city_code: "ATI",
    airport_name: "Artigas",
    country: "Uruguay",
  },
  {
    sr: 940,
    city: "Arua",
    city_code: "RUA",
    airport_name: "Arua",
    country: "Uganda",
  },
  {
    sr: 942,
    city: "Aruba",
    city_code: "AUA",
    airport_name: "Reina Beatrix",
    country: "Aruba",
  },
  {
    sr: 944,
    city: "Aruja",
    city_code: "ZFU",
    airport_name: "Aruja",
    country: "Brazil",
  },
  {
    sr: 946,
    city: "Arusha",
    city_code: "ARK",
    airport_name: "Arusha",
    country: "Tanzania",
  },
  {
    sr: 948,
    city: "Arutua",
    city_code: "AXR",
    airport_name: "Arutua",
    country: "French Polynesia",
  },
  {
    sr: 952,
    city: "Arvaikheer",
    city_code: "AVK",
    airport_name: "Arvaikheer",
    country: "Mongolia",
  },
  {
    sr: 954,
    city: "Arviat",
    city_code: "YEK",
    airport_name: "Arviat",
    country: "Canada",
  },
  {
    sr: 956,
    city: "Arvidsjaur",
    city_code: "AJR",
    airport_name: "Arvidsjaur",
    country: "Sweden",
  },
  {
    sr: 958,
    city: "Asahikawa",
    city_code: "AKJ",
    airport_name: "Asahikawa",
    country: "Japan",
  },
  {
    sr: 960,
    city: "Asaloyeh",
    city_code: "YEH",
    airport_name: "Asaloyeh",
    country: "Iran",
  },
  {
    sr: 962,
    city: "Asapa",
    city_code: "APP",
    airport_name: "Asapa",
    country: "Papua New Guinea",
  },
  {
    sr: 964,
    city: "Asau",
    city_code: "AAU",
    airport_name: "Asau",
    country: "Samoa",
  },
  {
    sr: 966,
    city: "Asbestos Hill",
    city_code: "YAF",
    airport_name: "Asbestos Hill",
    country: "Canada",
  },
  {
    sr: 968,
    city: "Neptune  NJ",
    city_code: "ARX",
    airport_name: "Asbury Park",
    country: "United States",
  },
  {
    sr: 970,
    city: "Ascension",
    city_code: "ASC",
    airport_name: "Ascension",
    country: "Bolivia",
  },
  {
    sr: 972,
    city: "Ascona",
    city_code: "ACO",
    airport_name: "Ascona",
    country: "Switzerland",
  },
  {
    sr: 974,
    city: "Aseki",
    city_code: "AEK",
    airport_name: "Aseki",
    country: "Papua New Guinea",
  },
  {
    sr: 976,
    city: "Asela",
    city_code: "ALK",
    airport_name: "Asela",
    country: "Ethiopia",
  },
  {
    sr: 978,
    city: "Ashburton",
    city_code: "ASG",
    airport_name: "Ashburton",
    country: "New Zealand",
  },
  {
    sr: 980,
    city: "Ashcroft",
    city_code: "YZA",
    airport_name: "Ashcroft",
    country: "Canada",
  },
  {
    sr: 982,
    city: "Ashgabat",
    city_code: "ASB",
    airport_name: "Ashgabat",
    country: "Turkmenistan",
  },
  {
    sr: 986,
    city: "Ashland",
    city_code: "ASX",
    airport_name: "Ashland",
    country: "United States",
  },
  {
    sr: 988,
    city: "Ashley",
    city_code: "ASY",
    airport_name: "Ashley",
    country: "United States",
  },
  {
    sr: 990,
    city: "Asirim",
    city_code: "ASZ",
    airport_name: "Asirim",
    country: "Papua New Guinea",
  },
  {
    sr: 992,
    city: "Asmara",
    city_code: "ASM",
    airport_name: "Asmara International",
    country: "Eritrea",
  },
  {
    sr: 994,
    city: "Asosa",
    city_code: "ASO",
    airport_name: "Asosa",
    country: "Ethiopia",
  },
  {
    sr: 996,
    city: "Aspen",
    city_code: "ASE",
    airport_name: "Aspen",
    country: "United States",
  },
  {
    sr: 998,
    city: "Assab",
    city_code: "ASA",
    airport_name: "Assab",
    country: "Ethiopia",
  },
  {
    sr: 1000,
    city: "Assis",
    city_code: "AIF",
    airport_name: "Assis",
    country: "Brazil",
  },
  {
    sr: 1002,
    city: "Asyut",
    city_code: "ATZ",
    airport_name: "Asyut",
    country: "Egypt",
  },
  {
    sr: 1004,
    city: "NUR-SULTAN",
    city_code: "TSE",
    airport_name: "NUR-SULTAN",
    country: "Kazakhstan",
  },
  {
    sr: 1006,
    city: "Astoria",
    city_code: "AST",
    airport_name: "Astoria",
    country: "United States",
  },
  {
    sr: 1008,
    city: "Astrakhan",
    city_code: "ASF",
    airport_name: "Astrakhan",
    country: "Russia",
  },
  {
    sr: 1010,
    city: "Astraksetra",
    city_code: "AKQ",
    airport_name: "Gunung Batin",
    country: "Indonesia",
  },
  {
    sr: 1012,
    city: "Asturias",
    city_code: "OVD",
    airport_name: "Asturias",
    country: "Spain",
  },
  {
    sr: 1014,
    city: "Astypalaia Island",
    city_code: "JTY",
    airport_name: "Astypalaia",
    country: "Greece",
  },
  {
    sr: 1016,
    city: "Asuncion",
    city_code: "ASU",
    airport_name: "Silvio Pettirossi",
    country: "Paraguay",
  },
  {
    sr: 1020,
    city: "Aswan",
    city_code: "ASW",
    airport_name: "Aswan",
    country: "Egypt",
  },
  {
    sr: 1022,
    city: "Atambua",
    city_code: "ABU",
    airport_name: "Atambua",
    country: "Indonesia",
  },
  {
    sr: 1024,
    city: "Ataq",
    city_code: "AXK",
    airport_name: "Ataq",
    country: "Yemen",
  },
  {
    sr: 1026,
    city: "Atar",
    city_code: "ATR",
    airport_name: "Mouakchott",
    country: "Mauritania",
  },
  {
    sr: 1028,
    city: "Atauro",
    city_code: "AUT",
    airport_name: "Atauro",
    country: "Indonesia",
  },
  {
    sr: 1030,
    city: "Atbara",
    city_code: "ATB",
    airport_name: "Atbara",
    country: "Sudan",
  },
  {
    sr: 1032,
    city: "Atbasar",
    city_code: "ATX",
    airport_name: "Atbasar",
    country: "Kazakhstan",
  },
  {
    sr: 1034,
    city: "Athens",
    city_code: "AHN",
    airport_name: "Athens",
    country: "United States",
  },
  {
    sr: 1036,
    city: "Athens",
    city_code: "ATH",
    airport_name: "Eleftherios Venizelos",
    country: "Greece",
  },
  {
    sr: 1038,
    city: "Athens",
    city_code: "HEW",
    airport_name: "Hellinikon  Closed",
    country: "Greece",
  },
  {
    sr: 1040,
    city: "Athens",
    city_code: "ATO",
    airport_name: "Ohio University",
    country: "United States",
  },
  {
    sr: 1042,
    city: "Athens",
    city_code: "MMI",
    airport_name: "McMinn County",
    country: "United States",
  },
  {
    sr: 1044,
    city: "Ati",
    city_code: "ATV",
    airport_name: "Ati",
    country: "Chad",
  },
  {
    sr: 1046,
    city: "Atikokan",
    city_code: "YIB",
    airport_name: "Atikokan",
    country: "Canada",
  },
  {
    sr: 1048,
    city: "Atiu Island",
    city_code: "AIU",
    airport_name: "Atiu Island",
    country: "Cook Islands",
  },
  {
    sr: 1050,
    city: "Atka",
    city_code: "AKB",
    airport_name: "Atka",
    country: "United States",
  },
  {
    sr: 1054,
    city: "Atkamba",
    city_code: "ABP",
    airport_name: "Atkamba",
    country: "Papua New Guinea",
  },
  {
    sr: 1056,
    city: "Atlanta",
    city_code: "JAO",
    airport_name: "Beaver Ruin",
    country: "United States",
  },
  {
    sr: 1058,
    city: "Atlanta",
    city_code: "PDK",
    airport_name: "De Kalb Peachtree",
    country: "United States",
  },
  {
    sr: 1060,
    city: "Atlanta",
    city_code: "FTY",
    airport_name: "Fulton County",
    country: "United States",
  },
  {
    sr: 1062,
    city: "Atlanta",
    city_code: "JGL",
    airport_name: "Galleria",
    country: "United States",
  },
  {
    sr: 1064,
    city: "Atlanta",
    city_code: "ATL",
    airport_name: "Hartsfield Jackson Atlanta Int",
    country: "United States",
  },
  {
    sr: 1066,
    city: "Atlanta",
    city_code: "JAJ",
    airport_name: "Perimeter Mall",
    country: "United States",
  },
  {
    sr: 1068,
    city: "Atlanta",
    city_code: "JAE",
    airport_name: "Technology Park",
    country: "United States",
  },
  {
    sr: 1070,
    city: "Atlantic",
    city_code: "AIO",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 1072,
    city: "Atlantic City",
    city_code: "ACY",
    airport_name: "Atlantic City Intl",
    country: "United States",
  },
  {
    sr: 1074,
    city: "Atlantic City",
    city_code: "AIY",
    airport_name: "Bader Field",
    country: "United States",
  },
  {
    sr: 1076,
    city: "Atlantic City",
    city_code: "JSL",
    airport_name: "Steel Pier Hlpt",
    country: "United States",
  },
  {
    sr: 1078,
    city: "Atmautluak",
    city_code: "ATT",
    airport_name: "Atmautluak",
    country: "United States",
  },
  {
    sr: 1080,
    city: "Atoifi",
    city_code: "ATD",
    airport_name: "Atoifi",
    country: "Solomon Islands",
  },
  {
    sr: 1082,
    city: "Atqasuk",
    city_code: "ATK",
    airport_name: "Atqasuk",
    country: "United States",
  },
  {
    sr: 1084,
    city: "Atsugi",
    city_code: "NJA",
    airport_name: "NAS",
    country: "Japan",
  },
  {
    sr: 1088,
    city: "Attawapiskat",
    city_code: "YAT",
    airport_name: "Attawapiskat",
    country: "Canada",
  },
  {
    sr: 1090,
    city: "Attock",
    city_code: "ATG",
    airport_name: "Attock",
    country: "Pakistan",
  },
  {
    sr: 1092,
    city: "Attopeu",
    city_code: "AOU",
    airport_name: "Attopeu",
    country: "Laos",
  },
  {
    sr: 1094,
    city: "Attu",
    city_code: "QGQ",
    airport_name: "Heliport",
    country: "Greenland",
  },
  {
    sr: 1096,
    city: "Attu Island",
    city_code: "ATU",
    airport_name: "Casco Cove",
    country: "United States",
  },
  {
    sr: 1098,
    city: "Atuona",
    city_code: "AUQ",
    airport_name: "Atuona",
    country: "French Polynesia",
  },
  {
    sr: 1100,
    city: "Atyrau",
    city_code: "GUW",
    airport_name: "Atyrau",
    country: "Kazakhstan",
  },
  {
    sr: 1102,
    city: "Aua Island",
    city_code: "AUI",
    airport_name: "Aua Island",
    country: "Papua New Guinea",
  },
  {
    sr: 1104,
    city: "Aubagne",
    city_code: "JAH",
    airport_name: "Agora Helipad",
    country: "France",
  },
  {
    sr: 1106,
    city: "Aubenas",
    city_code: "OBS",
    airport_name: "Vals Lanas",
    country: "France",
  },
  {
    sr: 1108,
    city: "Auburn",
    city_code: "AUO",
    airport_name: "Auburn Opelika",
    country: "United States",
  },
  {
    sr: 1110,
    city: "Auburn",
    city_code: "AUN",
    airport_name: "Auburn",
    country: "United States",
  },
  {
    sr: 1112,
    city: "Auckland",
    city_code: "AKL",
    airport_name: "Auckland International",
    country: "New Zealand",
  },
  {
    sr: 1114,
    city: "Auckland",
    city_code: "MHB",
    airport_name: "Mechanics Bay",
    country: "New Zealand",
  },
  {
    sr: 1116,
    city: "Aue",
    city_code: "ZAU",
    airport_name: "Sachsen Railway Stn",
    country: "Germany",
  },
  {
    sr: 1118,
    city: "Augusta",
    city_code: "AGS",
    airport_name: "Bush Field",
    country: "United States",
  },
  {
    sr: 1122,
    city: "Augusta",
    city_code: "DNL",
    airport_name: "Daniel Field",
    country: "United States",
  },
  {
    sr: 1124,
    city: "Augusta",
    city_code: "AUG",
    airport_name: "Augusta",
    country: "United States",
  },
  {
    sr: 1126,
    city: "Augustus Downs",
    city_code: "AUD",
    airport_name: "Augustus Downs",
    country: "Australia",
  },
  {
    sr: 1128,
    city: "Aumo",
    city_code: "AUV",
    airport_name: "Aumo",
    country: "Papua New Guinea",
  },
  {
    sr: 1130,
    city: "Aupaluk",
    city_code: "YPJ",
    airport_name: "Aupaluk",
    country: "Canada",
  },
  {
    sr: 1132,
    city: "Aur Island",
    city_code: "AUL",
    airport_name: "Aur Island",
    country: "Marshall Islands",
  },
  {
    sr: 1134,
    city: "Aurangabad",
    city_code: "IXU",
    airport_name: "Chikkalthana",
    country: "India",
  },
  {
    sr: 1136,
    city: "Aurillac",
    city_code: "AUR",
    airport_name: "Aurillac",
    country: "France",
  },
  {
    sr: 1138,
    city: "Aurora",
    city_code: "AUZ",
    airport_name: "Municipal Airport",
    country: "United States",
  },
  {
    sr: 1140,
    city: "Aurukun Mission",
    city_code: "AUU",
    airport_name: "Aurukun Mission",
    country: "Australia",
  },
  {
    sr: 1142,
    city: "Austin",
    city_code: "AUM",
    airport_name: "Austin",
    country: "United States",
  },
  {
    sr: 1144,
    city: "Austin",
    city_code: "ASQ",
    airport_name: "Austin",
    country: "United States",
  },
  {
    sr: 1146,
    city: "Austin",
    city_code: "MMR",
    airport_name: "Camp Maybry AHP",
    country: "United States",
  },
  {
    sr: 1148,
    city: "Austral Downs",
    city_code: "AWP",
    airport_name: "Austral Downs",
    country: "Australia",
  },
  {
    sr: 1150,
    city: "Auvergne",
    city_code: "AVG",
    airport_name: "Auvergne",
    country: "Australia",
  },
  {
    sr: 1152,
    city: "Auxerre",
    city_code: "AUF",
    airport_name: "Auxerre Branches",
    country: "France",
  },
  {
    sr: 1156,
    city: "Avesta Krylbo",
    city_code: "XYP",
    airport_name: "Avesta Krylbo",
    country: "Sweden",
  },
  {
    sr: 1158,
    city: "Aviano",
    city_code: "AVB",
    airport_name: "Aviano",
    country: "Italy",
  },
  {
    sr: 1160,
    city: "Avignon",
    city_code: "XZN",
    airport_name: "Avignon Railway",
    country: "France",
  },
  {
    sr: 1162,
    city: "Avignon",
    city_code: "AVN",
    airport_name: "Avignon Caum",
    country: "France",
  },
  {
    sr: 1164,
    city: "Avon Park",
    city_code: "AVO",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 1166,
    city: "Avoriaz",
    city_code: "AVF",
    airport_name: "Avoriaz",
    country: "France",
  },
  {
    sr: 1168,
    city: "Avu Avu",
    city_code: "AVU",
    airport_name: "Avu Avu",
    country: "Solomon Islands",
  },
  {
    sr: 1170,
    city: "Awaba",
    city_code: "AWB",
    airport_name: "Awaba",
    country: "Papua New Guinea",
  },
  {
    sr: 1172,
    city: "Awar",
    city_code: "AWR",
    airport_name: "Awar",
    country: "Papua New Guinea",
  },
  {
    sr: 1174,
    city: "Awaradam",
    city_code: "AAJ",
    airport_name: "Cayana Airstrip",
    country: "Suriname",
  },
  {
    sr: 1176,
    city: "Awareh",
    city_code: "AWH",
    airport_name: "Awareh",
    country: "Ethiopia",
  },
  {
    sr: 1178,
    city: "Awassa",
    city_code: "AWA",
    airport_name: "Awassa",
    country: "Ethiopia",
  },
  {
    sr: 1180,
    city: "Axum",
    city_code: "AXU",
    airport_name: "Axum",
    country: "Ethiopia",
  },
  {
    sr: 1182,
    city: "Ayacucho",
    city_code: "AYC",
    airport_name: "Ayacucho",
    country: "Colombia",
  },
  {
    sr: 1184,
    city: "Ayacucho",
    city_code: "AYP",
    airport_name: "Yanamilla",
    country: "Peru",
  },
  {
    sr: 1186,
    city: "Ayapel",
    city_code: "AYA",
    airport_name: "Ayapel",
    country: "Colombia",
  },
  {
    sr: 1190,
    city: "Ayawasi",
    city_code: "AYW",
    airport_name: "Ayawasi",
    country: "Indonesia",
  },
  {
    sr: 1192,
    city: "Ayers Rock",
    city_code: "AYQ",
    airport_name: "Connellan",
    country: "Australia",
  },
  {
    sr: 1194,
    city: "Ayolas",
    city_code: "AYO",
    airport_name: "Ayolas",
    country: "Paraguay",
  },
  {
    sr: 1196,
    city: "Ayr",
    city_code: "AYR",
    airport_name: "Ayr",
    country: "Australia",
  },
  {
    sr: 1198,
    city: "Bijie",
    city_code: "BFJ",
    airport_name: "Bijie",
    country: "China",
  },
  {
    sr: 1200,
    city: "Babelegi",
    city_code: "HBL",
    airport_name: "Babelegi Heliport",
    country: "South Africa",
  },
  {
    sr: 1202,
    city: "IRARUTU",
    city_code: "BXB",
    airport_name: "Babo",
    country: "Indonesia",
  },
  {
    sr: 1204,
    city: "BALLERA",
    city_code: "BBL",
    airport_name: "BALLERA",
    country: "australia",
  },
  {
    sr: 1206,
    city: "Baca Grande",
    city_code: "BCJ",
    airport_name: "Baca Grande",
    country: "United States",
  },
  {
    sr: 1208,
    city: "Bacau",
    city_code: "BCM",
    airport_name: "Bacau",
    country: "Romania",
  },
  {
    sr: 1210,
    city: "Bacolod",
    city_code: "BCD",
    airport_name: "Bacolod",
    country: "Philippines",
  },
  {
    sr: 1212,
    city: "Badajoz",
    city_code: "BJZ",
    airport_name: "Talaveral La Real",
    country: "Spain",
  },
  {
    sr: 1214,
    city: "Bade",
    city_code: "BXD",
    airport_name: "Bade",
    country: "Indonesia",
  },
  {
    sr: 1216,
    city: "Badin",
    city_code: "BDN",
    airport_name: "Talhar",
    country: "Pakistan",
  },
  {
    sr: 1218,
    city: "Badu Island",
    city_code: "BDD",
    airport_name: "Badu Island",
    country: "Australia",
  },
  {
    sr: 1220,
    city: "Bafoussam",
    city_code: "BFX",
    airport_name: "Bafoussam",
    country: "Cameroon",
  },
  {
    sr: 1224,
    city: "Bagan",
    city_code: "BPE",
    airport_name: "Bagan",
    country: "Myanmar",
  },
  {
    sr: 1226,
    city: "Baganga",
    city_code: "BNQ",
    airport_name: "Baganga",
    country: "Philippines",
  },
  {
    sr: 1228,
    city: "Bagani",
    city_code: "BQI",
    airport_name: "Bagani",
    country: "Namibia",
  },
  {
    sr: 1230,
    city: "Bagdad",
    city_code: "BGT",
    airport_name: "Bagdad",
    country: "United States",
  },
  {
    sr: 1232,
    city: "Bagdogra",
    city_code: "IXB",
    airport_name: "Bagdogra",
    country: "India",
  },
  {
    sr: 1234,
    city: "Bage",
    city_code: "BGX",
    airport_name: "Bage",
    country: "Brazil",
  },
  {
    sr: 1236,
    city: "Baghdad",
    city_code: "BGW",
    airport_name: "Al Muthana",
    country: "Iraq",
  },
  {
    sr: 1238,
    city: "Baghdad",
    city_code: "SDA",
    airport_name: "Baghdad International",
    country: "Iraq",
  },
  {
    sr: 1240,
    city: "Baglung",
    city_code: "BGL",
    airport_name: "Baglung",
    country: "Nepal",
  },
  {
    sr: 1242,
    city: "Bagotville",
    city_code: "YBG",
    airport_name: "Bagotville",
    country: "Canada",
  },
  {
    sr: 1244,
    city: "Baguio",
    city_code: "BAG",
    airport_name: "Loakan",
    country: "Philippines",
  },
  {
    sr: 1246,
    city: "Bahar Dar",
    city_code: "BJR",
    airport_name: "Bahar Dar",
    country: "Ethiopia",
  },
  {
    sr: 1248,
    city: "Bahawalnagar",
    city_code: "WGB",
    airport_name: "Bahawalnagar",
    country: "Pakistan",
  },
  {
    sr: 1250,
    city: "Bahawalpur",
    city_code: "BHV",
    airport_name: "Bahawalpur",
    country: "Pakistan",
  },
  {
    sr: 1252,
    city: "Bahia Angeles",
    city_code: "BHL",
    airport_name: "Bahia Angeles",
    country: "Mexico",
  },
  {
    sr: 1254,
    city: "Bahia Blanca",
    city_code: "BHI",
    airport_name: "Comandante",
    country: "Argentina",
  },
  {
    sr: 1258,
    city: "Bahia Cupica",
    city_code: "BHF",
    airport_name: "Bahia Cupica",
    country: "Colombia",
  },
  {
    sr: 1260,
    city: "Bahia De Caraquez",
    city_code: "BHA",
    airport_name: "Bahia De Caraquez",
    country: "Ecuador",
  },
  {
    sr: 1262,
    city: "Bahia Negra",
    city_code: "BFA",
    airport_name: "Bahia Negra",
    country: "Paraguay",
  },
  {
    sr: 1264,
    city: "Bahia Pinas",
    city_code: "BFQ",
    airport_name: "Bahia Pinas",
    country: "Panama",
  },
  {
    sr: 1266,
    city: "Bahia Solano",
    city_code: "BSC",
    airport_name: "Bahia Solano",
    country: "Colombia",
  },
  {
    sr: 1268,
    city: "Manama",
    city_code: "BAH",
    airport_name: "Bahrain International",
    country: "Bahrain",
  },
  {
    sr: 1270,
    city: "Bahregan",
    city_code: "IAQ",
    airport_name: "Bahregan",
    country: "Iran",
  },
  {
    sr: 1272,
    city: "Baia Mare",
    city_code: "BAY",
    airport_name: "Baia Mare",
    country: "Romania",
  },
  {
    sr: 1274,
    city: "Baibara",
    city_code: "BAP",
    airport_name: "Baibara",
    country: "Papua New Guinea",
  },
  {
    sr: 1276,
    city: "Baidoa",
    city_code: "BIB",
    airport_name: "Baidoa",
    country: "Somalia",
  },
  {
    sr: 1278,
    city: "Baie Comeau",
    city_code: "YBC",
    airport_name: "Baie Comeau",
    country: "Canada",
  },
  {
    sr: 1280,
    city: "Baie Johan Beetz",
    city_code: "YBJ",
    airport_name: "Baie Johan Beetz",
    country: "Canada",
  },
  {
    sr: 1282,
    city: "Baimuru",
    city_code: "VMU",
    airport_name: "Baimuru",
    country: "Papua New Guinea",
  },
  {
    sr: 1284,
    city: "Bainbridge",
    city_code: "BGE",
    airport_name: "Decatur County",
    country: "United States",
  },
  {
    sr: 1286,
    city: "Baindoung",
    city_code: "BDZ",
    airport_name: "Baindoung",
    country: "Papua New Guinea",
  },
  {
    sr: 1288,
    city: "Bairnsdale",
    city_code: "BSJ",
    airport_name: "Bairnsdale",
    country: "Australia",
  },
  {
    sr: 1292,
    city: "Baitadi",
    city_code: "BIT",
    airport_name: "Baitadi",
    country: "Nepal",
  },
  {
    sr: 1294,
    city: "Bajawa",
    city_code: "BJW",
    airport_name: "Bajawa",
    country: "Indonesia",
  },
  {
    sr: 1296,
    city: "Bajhang",
    city_code: "BJH",
    airport_name: "Bajhang",
    country: "Nepal",
  },
  {
    sr: 1298,
    city: "Bajone",
    city_code: "BJN",
    airport_name: "Bajone",
    country: "Mozambique",
  },
  {
    sr: 1300,
    city: "Bajura",
    city_code: "BJU",
    airport_name: "Bajura Arpt",
    country: "Nepal",
  },
  {
    sr: 1302,
    city: "Bakalalan",
    city_code: "BKM",
    airport_name: "Bakalalan",
    country: "Malaysia",
  },
  {
    sr: 1304,
    city: "Bakel",
    city_code: "BXE",
    airport_name: "Bakel",
    country: "Senegal",
  },
  {
    sr: 1306,
    city: "Baker",
    city_code: "BKE",
    airport_name: "Baker",
    country: "United States",
  },
  {
    sr: 1308,
    city: "Baker Island",
    city_code: "BAR",
    airport_name: "Baker AAF",
    country: "United States",
  },
  {
    sr: 1310,
    city: "Baker Lake",
    city_code: "YBK",
    airport_name: "Baker Lake",
    country: "Canada",
  },
  {
    sr: 1312,
    city: "Bakersfield",
    city_code: "BFL",
    airport_name: "Meadows Field",
    country: "United States",
  },
  {
    sr: 1314,
    city: "Bakkafjordur",
    city_code: "BJD",
    airport_name: "Bakkafjordur",
    country: "Iceland",
  },
  {
    sr: 1316,
    city: "Bakouma",
    city_code: "BMF",
    airport_name: "Bakouma",
    country: "Central African Republic",
  },
  {
    sr: 1318,
    city: "Baku",
    city_code: "ZXT",
    airport_name: "Heydar Aliyev",
    country: "Azerbaijan",
  },
  {
    sr: 1320,
    city: "Balad",
    city_code: "XQC",
    airport_name: "Balad",
    country: "Iraq",
  },
  {
    sr: 1322,
    city: "Balakovo",
    city_code: "BWO",
    airport_name: "Balakovo",
    country: "Russia",
  },
  {
    sr: 1326,
    city: "Balalae",
    city_code: "BAS",
    airport_name: "Balalae",
    country: "Solomon Islands",
  },
  {
    sr: 1328,
    city: "Balaton",
    city_code: "SOB",
    airport_name: "Saarmelleek Balaton",
    country: "Hungary",
  },
  {
    sr: 1330,
    city: "Balboa",
    city_code: "BLB",
    airport_name: "Balboa",
    country: "Panama",
  },
  {
    sr: 1332,
    city: "Balcanoona",
    city_code: "LCN",
    airport_name: "Balcanoona",
    country: "Australia",
  },
  {
    sr: 1334,
    city: "Baler",
    city_code: "BQA",
    airport_name: "Baler",
    country: "Philippines",
  },
  {
    sr: 1336,
    city: "Balgo Hills",
    city_code: "BQW",
    airport_name: "Balgo Hills",
    country: "Australia",
  },
  {
    sr: 1338,
    city: "Balhash",
    city_code: "BXH",
    airport_name: "Balhash",
    country: "Kazakhstan",
  },
  {
    sr: 1340,
    city: "Bali",
    city_code: "BLC",
    airport_name: "Ngurah Rai",
    country: "Cameroon",
  },
  {
    sr: 1342,
    city: "Bali",
    city_code: "BAJ",
    airport_name: "Bali",
    country: "Papua New Guinea",
  },
  {
    sr: 1344,
    city: "Balikesir",
    city_code: "BZI",
    airport_name: "Balikesir",
    country: "Turkey",
  },
  {
    sr: 1346,
    city: "Balikesir",
    city_code: "MQJ",
    airport_name: "Merkez",
    country: "Turkey",
  },
  {
    sr: 1348,
    city: "Balikpapan",
    city_code: "BPN",
    airport_name: "Sepingan",
    country: "Indonesia",
  },
  {
    sr: 1350,
    city: "Balimo",
    city_code: "OPU",
    airport_name: "Balimo",
    country: "Papua New Guinea",
  },
  {
    sr: 1352,
    city: "Ballina",
    city_code: "BNK",
    airport_name: "Ballina Byron Gateway",
    country: "Australia",
  },
  {
    sr: 1354,
    city: "Bally Kelly",
    city_code: "BOL",
    airport_name: "Bally Kelly",
    country: "United Kingdom",
  },
  {
    sr: 1356,
    city: "Balmaceda",
    city_code: "BBA",
    airport_name: "Teniente Vidal",
    country: "Chile",
  },
  {
    sr: 1360,
    city: "Balranald",
    city_code: "BZD",
    airport_name: "Balranald",
    country: "Australia",
  },
  {
    sr: 1362,
    city: "Balsas",
    city_code: "BSS",
    airport_name: "Balsas",
    country: "Brazil",
  },
  {
    sr: 1364,
    city: "Baltimore",
    city_code: "GBO",
    airport_name: "Baltimore Greenbelt T",
    country: "United States",
  },
  {
    sr: 1366,
    city: "Baltimore",
    city_code: "ZBP",
    airport_name: "Baltimore Rail",
    country: "United States",
  },
  {
    sr: 1368,
    city: "Baltimore",
    city_code: "MTN",
    airport_name: "Glenn L Martin",
    country: "United States",
  },
  {
    sr: 1370,
    city: "Baltrum",
    city_code: "BMR",
    airport_name: "Baltrum",
    country: "Germany",
  },
  {
    sr: 1372,
    city: "Balurghat",
    city_code: "RGH",
    airport_name: "Balurghat",
    country: "India",
  },
  {
    sr: 1374,
    city: "Bam",
    city_code: "BXR",
    airport_name: "Bam",
    country: "Iran",
  },
  {
    sr: 1376,
    city: "Bamaga",
    city_code: "ABM",
    airport_name: "Bamaga",
    country: "Australia",
  },
  {
    sr: 1378,
    city: "Bamako",
    city_code: "BKO",
    airport_name: "Bamako",
    country: "Mali",
  },
  {
    sr: 1380,
    city: "Bambari",
    city_code: "BBY",
    airport_name: "Bambari",
    country: "Central African Republic",
  },
  {
    sr: 1382,
    city: "Bambu",
    city_code: "BCP",
    airport_name: "Bambu",
    country: "Papua New Guinea",
  },
  {
    sr: 1384,
    city: "Bamburi",
    city_code: "BMQ",
    airport_name: "Bamburi",
    country: "Kenya",
  },
  {
    sr: 1386,
    city: "Bamenda",
    city_code: "BPC",
    airport_name: "Bamenda",
    country: "Cameroon",
  },
  {
    sr: 1388,
    city: "Bamerny",
    city_code: "BMN",
    airport_name: "Bamerny",
    country: "Iraq",
  },
  {
    sr: 1390,
    city: "Bamfield",
    city_code: "YBF",
    airport_name: "Bamfield",
    country: "Canada",
  },
  {
    sr: 1394,
    city: "Bamiyan",
    city_code: "BIN",
    airport_name: "Bamiyan",
    country: "Afghanistan",
  },
  {
    sr: 1396,
    city: "Bamu",
    city_code: "BMZ",
    airport_name: "Bamu",
    country: "Papua New Guinea",
  },
  {
    sr: 1398,
    city: "Ban Houei",
    city_code: "OUI",
    airport_name: "Ban Houei",
    country: "Laos",
  },
  {
    sr: 1400,
    city: "Ban Mak Khaen",
    city_code: "BAO",
    airport_name: "Udorn",
    country: "Thailand",
  },
  {
    sr: 1402,
    city: "Banaina",
    city_code: "NAF",
    airport_name: "Banaina",
    country: "Indonesia",
  },
  {
    sr: 1404,
    city: "Banda Aceh",
    city_code: "BTJ",
    airport_name: "Blang Bintang",
    country: "Indonesia",
  },
  {
    sr: 1406,
    city: "Bandanaira",
    city_code: "NDA",
    airport_name: "Bandanaira",
    country: "Indonesia",
  },
  {
    sr: 1408,
    city: "Bandar Abbas",
    city_code: "BND",
    airport_name: "Bandar Abbas",
    country: "Iran",
  },
  {
    sr: 1410,
    city: "Bandar Lampung",
    city_code: "TKG",
    airport_name: "Branti",
    country: "Indonesia",
  },
  {
    sr: 1412,
    city: "Bandar Lengeh",
    city_code: "BDH",
    airport_name: "Bandar Lengeh",
    country: "Iran",
  },
  {
    sr: 1414,
    city: "Bandar Mahshahr",
    city_code: "MRX",
    airport_name: "Mahshahr",
    country: "Iran",
  },
  {
    sr: 1416,
    city: "Bandar Seri Begawan",
    city_code: "BWN",
    airport_name: "Brunei International",
    country: "Brunei Darussalam",
  },
  {
    sr: 1418,
    city: "Bandirma",
    city_code: "BDM",
    airport_name: "Bandirma",
    country: "Turkey",
  },
  {
    sr: 1420,
    city: "Bandon",
    city_code: "BDY",
    airport_name: "State",
    country: "United States",
  },
  {
    sr: 1422,
    city: "Bandundu",
    city_code: "FDU",
    airport_name: "Bandundu",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 1424,
    city: "Bandung",
    city_code: "BDO",
    airport_name: "Husein Sastranegara",
    country: "Indonesia",
  },
  {
    sr: 1428,
    city: "Banff",
    city_code: "YBA",
    airport_name: "Banff",
    country: "Canada",
  },
  {
    sr: 1430,
    city: "Banfora",
    city_code: "BNR",
    airport_name: "Banfora",
    country: "Burkina Faso",
  },
  {
    sr: 1432,
    city: "Bangalore",
    city_code: "BLR",
    airport_name: "KEMPEGOWDA INTL",
    country: "India",
  },
  {
    sr: 1434,
    city: "Bangassou",
    city_code: "BGU",
    airport_name: "Bangassou",
    country: "Central African Republic",
  },
  {
    sr: 1436,
    city: "Bangda",
    city_code: "BPX",
    airport_name: "Bangda",
    country: "China",
  },
  {
    sr: 1438,
    city: "Bangkok",
    city_code: "BKK",
    airport_name: "International",
    country: "Thailand",
  },
  {
    sr: 1440,
    city: "Bangor",
    city_code: "BGR",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 1442,
    city: "Bangui",
    city_code: "BGF",
    airport_name: "Bangui",
    country: "Central African Republic",
  },
  {
    sr: 1444,
    city: "Baniyala",
    city_code: "BYX",
    airport_name: "Baniyala",
    country: "Australia",
  },
  {
    sr: 1446,
    city: "Banja Luka",
    city_code: "BNX",
    airport_name: "Banja Luka",
    country: "Bosnia And Herzegovina",
  },
  {
    sr: 1448,
    city: "Banjarmasin",
    city_code: "BDJ",
    airport_name: "Sjamsudin Noor",
    country: "Indonesia",
  },
  {
    sr: 1450,
    city: "Banjul",
    city_code: "BJL",
    airport_name: "Yundum International",
    country: "Gambia",
  },
  {
    sr: 1452,
    city: "Sydney",
    city_code: "BWU",
    airport_name: "Sydney Bankstown",
    country: "Australia",
  },
  {
    sr: 1454,
    city: "Banmethuot",
    city_code: "BMV",
    airport_name: "Phung Duc",
    country: "Vietnam",
  },
  {
    sr: 1456,
    city: "Banning",
    city_code: "BNG",
    airport_name: "Banning",
    country: "United States",
  },
  {
    sr: 1458,
    city: "Bannu",
    city_code: "BNP",
    airport_name: "Bannu",
    country: "Pakistan",
  },
  {
    sr: 1462,
    city: "Bantry",
    city_code: "BYT",
    airport_name: "Bantry",
    country: "Ireland",
  },
  {
    sr: 1464,
    city: "Banz",
    city_code: "BNZ",
    airport_name: "Banz",
    country: "Papua New Guinea",
  },
  {
    sr: 1466,
    city: "Baoshan",
    city_code: "BSD",
    airport_name: "Baoshan",
    country: "China",
  },
  {
    sr: 1468,
    city: "Baotou",
    city_code: "BAV",
    airport_name: "Baotou",
    country: "China",
  },
  {
    sr: 1470,
    city: "Bapi",
    city_code: "BPD",
    airport_name: "Bapi",
    country: "Papua New Guinea",
  },
  {
    sr: 1472,
    city: "Baqubah",
    city_code: "XQV",
    airport_name: "Baqubah",
    country: "Iraq",
  },
  {
    sr: 1474,
    city: "Bar Harbor",
    city_code: "BHB",
    airport_name: "Bar Harbor",
    country: "United States",
  },
  {
    sr: 1476,
    city: "Baracoa",
    city_code: "BCA",
    airport_name: "Baracoa",
    country: "Cuba",
  },
  {
    sr: 1478,
    city: "Barahona",
    city_code: "BRX",
    airport_name: "Barahona",
    country: "Dominican Republic",
  },
  {
    sr: 1480,
    city: "Barakoma",
    city_code: "VEV",
    airport_name: "Barakoma",
    country: "Solomon Islands",
  },
  {
    sr: 1482,
    city: "Baramita",
    city_code: "BMJ",
    airport_name: "Baramita",
    country: "Guyana",
  },
  {
    sr: 1484,
    city: "Baranof",
    city_code: "BNF",
    airport_name: "Warm Spring Bay SPB",
    country: "United States",
  },
  {
    sr: 1486,
    city: "Barbelos",
    city_code: "BAZ",
    airport_name: "Barbelos",
    country: "Brazil",
  },
  {
    sr: 1488,
    city: "Barbers Point",
    city_code: "NAX",
    airport_name: "Barbers Point",
    country: "United States",
  },
  {
    sr: 1490,
    city: "Barbuda",
    city_code: "BBQ",
    airport_name: "Barbuda",
    country: "Antigua and Barbuda",
  },
  {
    sr: 1492,
    city: "Barcaldine",
    city_code: "BCI",
    airport_name: "Barcaldine",
    country: "Australia",
  },
  {
    sr: 1496,
    city: "Barcelona",
    city_code: "BCN",
    airport_name: "El Prat De Llobregat",
    country: "Spain",
  },
  {
    sr: 1498,
    city: "Barcelona",
    city_code: "BLA",
    airport_name: "Gen J A Anzoategui",
    country: "Venezuela",
  },
  {
    sr: 1500,
    city: "Barcelonnette",
    city_code: "BAE",
    airport_name: "Barcelonnette",
    country: "France",
  },
  {
    sr: 1502,
    city: "Bardera",
    city_code: "BSY",
    airport_name: "Bardera",
    country: "Somalia",
  },
  {
    sr: 1504,
    city: "Bardstown",
    city_code: "BRY",
    airport_name: "Samuels Field",
    country: "United States",
  },
  {
    sr: 1506,
    city: "Bardufoss",
    city_code: "BDU",
    airport_name: "Bardufoss",
    country: "Norway",
  },
  {
    sr: 1508,
    city: "Bareli",
    city_code: "BEK",
    airport_name: "Bareli",
    country: "India",
  },
  {
    sr: 1510,
    city: "Bari",
    city_code: "BRI",
    airport_name: "Palese",
    country: "Italy",
  },
  {
    sr: 1512,
    city: "Barinas",
    city_code: "BNS",
    airport_name: "Barinas",
    country: "Venezuela",
  },
  {
    sr: 1514,
    city: "Bario",
    city_code: "BBN",
    airport_name: "Bario",
    country: "Malaysia",
  },
  {
    sr: 1516,
    city: "Barisal",
    city_code: "BZL",
    airport_name: "Barisal",
    country: "Bangladesh",
  },
  {
    sr: 1518,
    city: "Barkly Downs",
    city_code: "BKP",
    airport_name: "Barkly Downs",
    country: "Australia",
  },
  {
    sr: 1520,
    city: "Barnaul",
    city_code: "BAX",
    airport_name: "Barnaul",
    country: "Russia",
  },
  {
    sr: 1522,
    city: "Barnwell",
    city_code: "BNL",
    airport_name: "Barnwell County",
    country: "United States",
  },
  {
    sr: 1524,
    city: "Barora",
    city_code: "RRI",
    airport_name: "Barora",
    country: "Solomon Islands",
  },
  {
    sr: 1526,
    city: "Barquisimeto",
    city_code: "BRM",
    airport_name: "Barquisimeto",
    country: "Venezuela",
  },
  {
    sr: 1530,
    city: "Barra",
    city_code: "BQQ",
    airport_name: "Barra",
    country: "Brazil",
  },
  {
    sr: 1532,
    city: "Barra",
    city_code: "BRR",
    airport_name: "North Bay",
    country: "United Kingdom",
  },
  {
    sr: 1534,
    city: "Barra Colorado",
    city_code: "BCL",
    airport_name: "Barra Colorado",
    country: "Costa Rica",
  },
  {
    sr: 1536,
    city: "Barra Do Corda",
    city_code: "BDC",
    airport_name: "Barra Do Corda",
    country: "Brazil",
  },
  {
    sr: 1538,
    city: "Barra Do Garcas",
    city_code: "BPG",
    airport_name: "Barra Do Garcas",
    country: "Brazil",
  },
  {
    sr: 1540,
    city: "Barranca De Upia",
    city_code: "BAC",
    airport_name: "Barranca De Upia",
    country: "Colombia",
  },
  {
    sr: 1542,
    city: "Barrancabermeja",
    city_code: "EJA",
    airport_name: "Variguies",
    country: "Colombia",
  },
  {
    sr: 1544,
    city: "Barrancominas",
    city_code: "NBB",
    airport_name: "Barrancominas",
    country: "Colombia",
  },
  {
    sr: 1546,
    city: "Barranquilla",
    city_code: "BAQ",
    airport_name: "E Cortissoz",
    country: "Colombia",
  },
  {
    sr: 1548,
    city: "Barreiras",
    city_code: "BRA",
    airport_name: "Barreiras",
    country: "Brazil",
  },
  {
    sr: 1550,
    city: "Barreirinhas",
    city_code: "BRB",
    airport_name: "Barreirinhas",
    country: "Brazil",
  },
  {
    sr: 1552,
    city: "Barretos",
    city_code: "BAT",
    airport_name: "Barretos",
    country: "Brazil",
  },
  {
    sr: 1554,
    city: "Barrow",
    city_code: "PBA",
    airport_name: "Point Barrow",
    country: "United States",
  },
  {
    sr: 1556,
    city: "Barrow",
    city_code: "BRW",
    airport_name: "Wiley Post W Rogers M",
    country: "United States",
  },
  {
    sr: 1558,
    city: "Barrow Island",
    city_code: "BWB",
    airport_name: "Barrow Island",
    country: "Australia",
  },
  {
    sr: 1560,
    city: "Barrow In Furness",
    city_code: "BWF",
    airport_name: "Walney Island",
    country: "United Kingdom",
  },
  {
    sr: 1564,
    city: "Barter Island",
    city_code: "BTI",
    airport_name: "Barter Island",
    country: "United States",
  },
  {
    sr: 1566,
    city: "Barth",
    city_code: "BBH",
    airport_name: "Barth",
    country: "Germany",
  },
  {
    sr: 1568,
    city: "Bartica",
    city_code: "GFO",
    airport_name: "Bartica",
    country: "Guyana",
  },
  {
    sr: 1570,
    city: "Bartlesville",
    city_code: "BVO",
    airport_name: "Bartlesville",
    country: "United States",
  },
  {
    sr: 1572,
    city: "Bartletts",
    city_code: "BSZ",
    airport_name: "Bartletts",
    country: "United States",
  },
  {
    sr: 1574,
    city: "Bartow",
    city_code: "BOW",
    airport_name: "Bartow",
    country: "United States",
  },
  {
    sr: 1576,
    city: "Baruun Urt",
    city_code: "UUN",
    airport_name: "Baruun Urt",
    country: "Mongolia",
  },
  {
    sr: 1578,
    city: "Basankusu",
    city_code: "BSU",
    airport_name: "Basankusu",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 1580,
    city: "Basco",
    city_code: "BSO",
    airport_name: "Basco",
    country: "Philippines",
  },
  {
    sr: 1582,
    city: "Basel Mulhouse",
    city_code: "ZBA",
    airport_name: "Basil Bad   Railway",
    country: "Switzerland",
  },
  {
    sr: 1584,
    city: "Basongo",
    city_code: "BAN",
    airport_name: "Basongo",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 1586,
    city: "Basra",
    city_code: "BSR",
    airport_name: "International",
    country: "Iraq",
  },
  {
    sr: 1588,
    city: "Basse Terre",
    city_code: "BBR",
    airport_name: "Baillif",
    country: "Guadeloupe",
  },
  {
    sr: 1590,
    city: "Bassein",
    city_code: "BSX",
    airport_name: "Bassein",
    country: "Myanmar",
  },
  {
    sr: 1592,
    city: "Bastia",
    city_code: "BIA",
    airport_name: "Poretta",
    country: "France",
  },
  {
    sr: 1594,
    city: "Bata",
    city_code: "BSG",
    airport_name: "Bata",
    country: "Equatorial Guinea",
  },
  {
    sr: 1598,
    city: "Batam",
    city_code: "BTH",
    airport_name: "Hang Nadim",
    country: "Indonesia",
  },
  {
    sr: 1600,
    city: "Batangafo",
    city_code: "BTG",
    airport_name: "Batangafo",
    country: "Central African Republic",
  },
  {
    sr: 1602,
    city: "Batavia Downs",
    city_code: "BVW",
    airport_name: "Batavia Downs",
    country: "Australia",
  },
  {
    sr: 1604,
    city: "Batesville",
    city_code: "BVX",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 1606,
    city: "Batesville",
    city_code: "HLB",
    airport_name: "Hillenbrand",
    country: "United States",
  },
  {
    sr: 1608,
    city: "Bathurst",
    city_code: "ZBF",
    airport_name: "Bathurst",
    country: "Canada",
  },
  {
    sr: 1610,
    city: "Bathurst",
    city_code: "BHS",
    airport_name: "Raglan",
    country: "Australia",
  },
  {
    sr: 1612,
    city: "Bathurst Island",
    city_code: "BRT",
    airport_name: "Bathurst Island",
    country: "Australia",
  },
  {
    sr: 1614,
    city: "Batman",
    city_code: "BAL",
    airport_name: "Batman",
    country: "Turkey",
  },
  {
    sr: 1616,
    city: "Batna",
    city_code: "BLJ",
    airport_name: "Batna",
    country: "Algeria",
  },
  {
    sr: 1618,
    city: "Batom",
    city_code: "BXM",
    airport_name: "Batom",
    country: "Indonesia",
  },
  {
    sr: 1620,
    city: "Baton Rouge",
    city_code: "EBR",
    airport_name: "Downtown",
    country: "United States",
  },
  {
    sr: 1622,
    city: "Baton Rouge",
    city_code: "BTR",
    airport_name: "Ryan",
    country: "United States",
  },
  {
    sr: 1624,
    city: "Batouri",
    city_code: "OUR",
    airport_name: "Batouri",
    country: "Cameroon",
  },
  {
    sr: 1626,
    city: "Batsfjord",
    city_code: "BJF",
    airport_name: "Batsfjord",
    country: "Norway",
  },
  {
    sr: 1628,
    city: "Battambang",
    city_code: "BBM",
    airport_name: "Battambang",
    country: "Cambodia",
  },
  {
    sr: 1632,
    city: "Batticaloa",
    city_code: "BTC",
    airport_name: "Batticaloa",
    country: "Sri Lanka",
  },
  {
    sr: 1634,
    city: "Battle Creek",
    city_code: "BTL",
    airport_name: "WK Kellogg Regional",
    country: "United States",
  },
  {
    sr: 1636,
    city: "Battle Mountain",
    city_code: "BAM",
    airport_name: "Lander County",
    country: "United States",
  },
  {
    sr: 1638,
    city: "Batu Licin",
    city_code: "BTW",
    airport_name: "Batu Licin",
    country: "Indonesia",
  },
  {
    sr: 1640,
    city: "Batumi",
    city_code: "BUS",
    airport_name: "Batumi",
    country: "Georgia",
  },
  {
    sr: 1642,
    city: "Batuna",
    city_code: "BPF",
    airport_name: "Batuna Aerodrome",
    country: "Solomon Islands",
  },
  {
    sr: 1644,
    city: "Baubau",
    city_code: "BUW",
    airport_name: "Baubau",
    country: "Indonesia",
  },
  {
    sr: 1646,
    city: "Baucau",
    city_code: "BCH",
    airport_name: "English Madeira",
    country: "Indonesia  East Timor",
  },
  {
    sr: 1648,
    city: "Bauchi",
    city_code: "BCU",
    airport_name: "Bauchi",
    country: "Nigeria",
  },
  {
    sr: 1650,
    city: "Baudette",
    city_code: "BDE",
    airport_name: "Baudette",
    country: "United States",
  },
  {
    sr: 1652,
    city: "Baures",
    city_code: "BVL",
    airport_name: "Baures",
    country: "Bolivia",
  },
  {
    sr: 1654,
    city: "Bauru",
    city_code: "BAU",
    airport_name: "Bauru",
    country: "Brazil",
  },
  {
    sr: 1656,
    city: "Bawan",
    city_code: "BWJ",
    airport_name: "Bawan",
    country: "Papua New Guinea",
  },
  {
    sr: 1658,
    city: "Bay City",
    city_code: "BBC",
    airport_name: "Bay City",
    country: "United States",
  },
  {
    sr: 1660,
    city: "Bayamo",
    city_code: "BYM",
    airport_name: "C M de Cespedes",
    country: "Cuba",
  },
  {
    sr: 1662,
    city: "Bayankhongor",
    city_code: "BYN",
    airport_name: "Bayankhongor",
    country: "Mongolia",
  },
  {
    sr: 1666,
    city: "Bayreuth",
    city_code: "BYU",
    airport_name: "Bindlacher Berg",
    country: "Germany",
  },
  {
    sr: 1668,
    city: "Baytown",
    city_code: "HPY",
    airport_name: "Baytown",
    country: "United States",
  },
  {
    sr: 1670,
    city: "Bazaruto Island",
    city_code: "BZB",
    airport_name: "Bazaruto Island",
    country: "Mozambique",
  },
  {
    sr: 1672,
    city: "Beagle Bay",
    city_code: "BEE",
    airport_name: "Beagle Bay",
    country: "Australia",
  },
  {
    sr: 1674,
    city: "Bealanana",
    city_code: "WBE",
    airport_name: "Bealanana",
    country: "Madagascar",
  },
  {
    sr: 1676,
    city: "Bear Creek",
    city_code: "BCC",
    airport_name: "Bear Creek",
    country: "United States",
  },
  {
    sr: 1678,
    city: "Bearskin Lake",
    city_code: "XBE",
    airport_name: "Bearskin Lake",
    country: "Canada",
  },
  {
    sr: 1680,
    city: "Beatrice",
    city_code: "BIE",
    airport_name: "Beatrice",
    country: "United States",
  },
  {
    sr: 1682,
    city: "Beatton River",
    city_code: "YZC",
    airport_name: "Beatton River",
    country: "Canada",
  },
  {
    sr: 1684,
    city: "Beatty",
    city_code: "BTY",
    airport_name: "Beatty",
    country: "United States",
  },
  {
    sr: 1686,
    city: "Beaufort",
    city_code: "BFT",
    airport_name: "County",
    country: "United States",
  },
  {
    sr: 1688,
    city: "Beaumont",
    city_code: "BMT",
    airport_name: "Beaumont Muni",
    country: "United States",
  },
  {
    sr: 1690,
    city: "Beaumont",
    city_code: "BPT",
    airport_name: "Jefferson County",
    country: "United States",
  },
  {
    sr: 1692,
    city: "Beaver",
    city_code: "WBQ",
    airport_name: "Beaver",
    country: "United States",
  },
  {
    sr: 1694,
    city: "Beaver Creek",
    city_code: "YXQ",
    airport_name: "Beaver Creek",
    country: "Canada",
  },
  {
    sr: 1696,
    city: "Beaver Falls",
    city_code: "BFP",
    airport_name: "Beaver Falls",
    country: "United States",
  },
  {
    sr: 1700,
    city: "Beaver Inlet",
    city_code: "BVD",
    airport_name: "Sea Port",
    country: "United States",
  },
  {
    sr: 1702,
    city: "Bechar",
    city_code: "CBH",
    airport_name: "Leger",
    country: "Algeria",
  },
  {
    sr: 1704,
    city: "Beckley",
    city_code: "BKW",
    airport_name: "Beckley",
    country: "United States",
  },
  {
    sr: 1706,
    city: "Bedford",
    city_code: "BFR",
    airport_name: "Virgil I Grissom Muni",
    country: "United States",
  },
  {
    sr: 1708,
    city: "Bedford Downs",
    city_code: "BDW",
    airport_name: "Bedford Downs",
    country: "Australia",
  },
  {
    sr: 1710,
    city: "Bedford Hanscom",
    city_code: "BED",
    airport_name: "Hanscom Field",
    country: "United States",
  },
  {
    sr: 1712,
    city: "Bedourie",
    city_code: "BEU",
    airport_name: "Bedourie",
    country: "Australia",
  },
  {
    sr: 1714,
    city: "Bedwell Harbor",
    city_code: "YBW",
    airport_name: "Bedwell Harbor",
    country: "Canada",
  },
  {
    sr: 1716,
    city: "Beef Island",
    city_code: "EIS",
    airport_name: "Beef Island",
    country: "Virgin Islands  British",
  },
  {
    sr: 1718,
    city: "Beer Sheba",
    city_code: "BEV",
    airport_name: "Beer Sheba",
    country: "Israel",
  },
  {
    sr: 1720,
    city: "Beeville",
    city_code: "NIR",
    airport_name: "Chase Field NAS",
    country: "United States",
  },
  {
    sr: 1722,
    city: "Befandriana",
    city_code: "WBD",
    airport_name: "Befandriana",
    country: "Madagascar",
  },
  {
    sr: 1724,
    city: "Beica",
    city_code: "BEI",
    airport_name: "Beica",
    country: "Ethiopia",
  },
  {
    sr: 1726,
    city: "Beida",
    city_code: "LAQ",
    airport_name: "La Braq",
    country: "Libya",
  },
  {
    sr: 1728,
    city: "Beidah",
    city_code: "BYD",
    airport_name: "Beidah",
    country: "Yemen",
  },
  {
    sr: 1730,
    city: "Beihai",
    city_code: "BHY",
    airport_name: "Beihai",
    country: "China",
  },
  {
    sr: 1734,
    city: "Beihan",
    city_code: "BHN",
    airport_name: "Beihan",
    country: "Yemen",
  },
  {
    sr: 1736,
    city: "Beijing",
    city_code: "PEK",
    airport_name: "Capital",
    country: "China",
  },
  {
    sr: 1738,
    city: "Beijing",
    city_code: "NAY",
    airport_name: "Nanyuan Airport",
    country: "China",
  },
  {
    sr: 1740,
    city: "Beira",
    city_code: "BEW",
    airport_name: "Beira",
    country: "Mozambique",
  },
  {
    sr: 1742,
    city: "Beirut",
    city_code: "BEY",
    airport_name: "Beirut Rafic Hariri International Airport",
    country: "Lebanon",
  },
  {
    sr: 1744,
    city: "Bejaia",
    city_code: "BJA",
    airport_name: "Bejaia",
    country: "Algeria",
  },
  {
    sr: 1746,
    city: "Bekily",
    city_code: "OVA",
    airport_name: "Bekily",
    country: "Madagascar",
  },
  {
    sr: 1748,
    city: "Belaga",
    city_code: "BLG",
    airport_name: "Belaga",
    country: "Malaysia",
  },
  {
    sr: 1750,
    city: "Belburn",
    city_code: "BXF",
    airport_name: "Belburn",
    country: "Australia",
  },
  {
    sr: 1752,
    city: "Belem",
    city_code: "BEL",
    airport_name: "Val De Cans",
    country: "Brazil",
  },
  {
    sr: 1754,
    city: "Belep Island",
    city_code: "BMY",
    airport_name: "Belep Island",
    country: "New Caledonia",
  },
  {
    sr: 1756,
    city: "Belfast",
    city_code: "BHD",
    airport_name: "Belfast City Airport",
    country: "United Kingdom",
  },
  {
    sr: 1758,
    city: "Belfast",
    city_code: "BFS",
    airport_name: "Belfast Aldergrove International",
    country: "United Kingdom",
  },
  {
    sr: 1760,
    city: "Belfort",
    city_code: "BOR",
    airport_name: "Fontaine",
    country: "France",
  },
  {
    sr: 1762,
    city: "Belgaum",
    city_code: "IXG",
    airport_name: "Sambre",
    country: "India",
  },
  {
    sr: 1764,
    city: "Belgorod",
    city_code: "EGO",
    airport_name: "Belgorod",
    country: "Russia",
  },
  {
    sr: 1768,
    city: "Belgrade",
    city_code: "BJY",
    airport_name: "Batajnica",
    country: "Yugoslavia",
  },
  {
    sr: 1770,
    city: "Belgrade",
    city_code: "BEG",
    airport_name: "Nikola Tesla Airport",
    country: "Serbia",
  },
  {
    sr: 1772,
    city: "Belize City",
    city_code: "TZA",
    airport_name: "Municipal",
    country: "Belize",
  },
  {
    sr: 1774,
    city: "Belize City",
    city_code: "BZE",
    airport_name: "Philip S W Goldson Int",
    country: "Belize",
  },
  {
    sr: 1776,
    city: "Bell Island",
    city_code: "KBE",
    airport_name: "Hot Springs SPB",
    country: "United States",
  },
  {
    sr: 1778,
    city: "Bella Bella",
    city_code: "ZEL",
    airport_name: "Bella Bella",
    country: "Canada",
  },
  {
    sr: 1780,
    city: "Bella Coola",
    city_code: "QBC",
    airport_name: "Bella Coola",
    country: "Canada",
  },
  {
    sr: 1782,
    city: "Bella Union",
    city_code: "BUV",
    airport_name: "Bella Union",
    country: "Uruguay",
  },
  {
    sr: 1784,
    city: "Bella Yella",
    city_code: "BYL",
    airport_name: "Bella Yella",
    country: "Liberia",
  },
  {
    sr: 1786,
    city: "Bellaire",
    city_code: "ACB",
    airport_name: "Antrim County",
    country: "United States",
  },
  {
    sr: 1788,
    city: "Bellary",
    city_code: "BEP",
    airport_name: "Bellary",
    country: "India",
  },
  {
    sr: 1790,
    city: "Bellavista",
    city_code: "BLP",
    airport_name: "Bellavista",
    country: "Peru",
  },
  {
    sr: 1792,
    city: "Belle Chasse",
    city_code: "BCS",
    airport_name: "Southern Seaplane",
    country: "United States",
  },
  {
    sr: 1794,
    city: "Bellefonte",
    city_code: "PSB",
    airport_name: "Bellefonte",
    country: "United States",
  },
  {
    sr: 1796,
    city: "Belleville",
    city_code: "BLV",
    airport_name: "Belleville",
    country: "United States",
  },
  {
    sr: 1798,
    city: "Bellingham",
    city_code: "BLI",
    airport_name: "Bellingham",
    country: "United States",
  },
  {
    sr: 1802,
    city: "Bellona",
    city_code: "BNY",
    airport_name: "Bellona",
    country: "Solomon Islands",
  },
  {
    sr: 1804,
    city: "Belluno",
    city_code: "BLX",
    airport_name: "Belluno",
    country: "Italy",
  },
  {
    sr: 1806,
    city: "Belmar",
    city_code: "BLM",
    airport_name: "Monmouth County",
    country: "United States",
  },
  {
    sr: 1808,
    city: "Belmonte",
    city_code: "BVM",
    airport_name: "Belmonte",
    country: "Brazil",
  },
  {
    sr: 1810,
    city: "Belmopan",
    city_code: "BCV",
    airport_name: "Belmopan",
    country: "Belize",
  },
  {
    sr: 1812,
    city: "Belmullet",
    city_code: "BLY",
    airport_name: "Belmullet",
    country: "Ireland",
  },
  {
    sr: 1814,
    city: "Belo",
    city_code: "BMD",
    airport_name: "Belo",
    country: "Madagascar",
  },
  {
    sr: 1816,
    city: "Belo Horizonte",
    city_code: "PLU",
    airport_name: "Pampulha",
    country: "Brazil",
  },
  {
    sr: 1818,
    city: "Beloreck",
    city_code: "BCX",
    airport_name: "Beloreck",
    country: "Russia",
  },
  {
    sr: 1820,
    city: "Beltsy",
    city_code: "BZY",
    airport_name: "Beltsy",
    country: "Moldova",
  },
  {
    sr: 1822,
    city: "Beluga",
    city_code: "BVU",
    airport_name: "Beluga",
    country: "United States",
  },
  {
    sr: 1824,
    city: "Bembridge",
    city_code: "BBP",
    airport_name: "Bembridge",
    country: "United Kingdom",
  },
  {
    sr: 1826,
    city: "Bemichi",
    city_code: "BCG",
    airport_name: "Bemichi",
    country: "Guyana",
  },
  {
    sr: 1828,
    city: "Bemidji",
    city_code: "BJI",
    airport_name: "Bemidji",
    country: "United States",
  },
  {
    sr: 1830,
    city: "Benalla",
    city_code: "BLN",
    airport_name: "Benalla",
    country: "Australia",
  },
  {
    sr: 1832,
    city: "Benbecula",
    city_code: "BEB",
    airport_name: "Benbecula",
    country: "United Kingdom",
  },
  {
    sr: 1836,
    city: "Bendigo",
    city_code: "BXG",
    airport_name: "Bendigo",
    country: "Australia",
  },
  {
    sr: 1838,
    city: "Bengbu",
    city_code: "BFU",
    airport_name: "Bengbu",
    country: "China",
  },
  {
    sr: 1840,
    city: "Benghazi",
    city_code: "BEN",
    airport_name: "Benina Intl",
    country: "Libya",
  },
  {
    sr: 1842,
    city: "Bengkulu",
    city_code: "BKS",
    airport_name: "Padangkemiling",
    country: "Indonesia",
  },
  {
    sr: 1844,
    city: "Benguela",
    city_code: "BUG",
    airport_name: "Gen V Deslandes",
    country: "Angola",
  },
  {
    sr: 1846,
    city: "Benguera Island",
    city_code: "BCW",
    airport_name: "Benguera Island",
    country: "Mozambique",
  },
  {
    sr: 1848,
    city: "Beni",
    city_code: "BNC",
    airport_name: "Beni",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 1850,
    city: "Benin City",
    city_code: "BNI",
    airport_name: "Benin City",
    country: "Nigeria",
  },
  {
    sr: 1852,
    city: "Benjina",
    city_code: "BJK",
    airport_name: "Benjina",
    country: "Indonesia",
  },
  {
    sr: 1854,
    city: "Bennettsville",
    city_code: "BTN",
    airport_name: "Bennettsville",
    country: "United States",
  },
  {
    sr: 1856,
    city: "Bensbach",
    city_code: "BSP",
    airport_name: "Bensbach",
    country: "Papua New Guinea",
  },
  {
    sr: 1858,
    city: "Benson",
    city_code: "BEX",
    airport_name: "RAF Station",
    country: "United Kingdom",
  },
  {
    sr: 1860,
    city: "Benson",
    city_code: "BBB",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 1862,
    city: "Bento Goncalves",
    city_code: "BGV",
    airport_name: "Bento Goncalves",
    country: "Brazil",
  },
  {
    sr: 1864,
    city: "Benton Harbor",
    city_code: "BEH",
    airport_name: "Ross Field",
    country: "United States",
  },
  {
    sr: 1866,
    city: "Bentota River",
    city_code: "BJT",
    airport_name: "Bentota River",
    country: "Sri Lanka",
  },
  {
    sr: 1870,
    city: "Beppu",
    city_code: "BPU",
    airport_name: "Beppu",
    country: "Japan",
  },
  {
    sr: 1872,
    city: "Berau",
    city_code: "BEJ",
    airport_name: "Berau",
    country: "Indonesia",
  },
  {
    sr: 1874,
    city: "Berbera",
    city_code: "BBO",
    airport_name: "Berbera",
    country: "Somalia",
  },
  {
    sr: 1876,
    city: "Berberati",
    city_code: "BBT",
    airport_name: "Berberati",
    country: "Central African Republic",
  },
  {
    sr: 1878,
    city: "Berdyansk",
    city_code: "ERD",
    airport_name: "Berdyansk",
    country: "Ukraine",
  },
  {
    sr: 1880,
    city: "Bereby",
    city_code: "BBV",
    airport_name: "Bereby",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 1882,
    city: "Bereina",
    city_code: "BEA",
    airport_name: "Bereina",
    country: "Papua New Guinea",
  },
  {
    sr: 1884,
    city: "Berens River",
    city_code: "YBV",
    airport_name: "Berens River",
    country: "Canada",
  },
  {
    sr: 1886,
    city: "Bergen",
    city_code: "BGO",
    airport_name: "Bergen Airport  Flesland",
    country: "Norway",
  },
  {
    sr: 1888,
    city: "Bergen Op Zoom",
    city_code: "BZM",
    airport_name: "Woensdrecht",
    country: "Netherlands",
  },
  {
    sr: 1890,
    city: "Bergerac",
    city_code: "EGC",
    airport_name: "Roumanieres",
    country: "France",
  },
  {
    sr: 1892,
    city: "Berkak",
    city_code: "XUB",
    airport_name: "Berkak Railway",
    country: "Norway",
  },
  {
    sr: 1894,
    city: "Berkeley",
    city_code: "JBK",
    airport_name: "Berkeley",
    country: "United States",
  },
  {
    sr: 1896,
    city: "Berlevag",
    city_code: "BVG",
    airport_name: "Berlevag",
    country: "Norway",
  },
  {
    sr: 1898,
    city: "Berlin",
    city_code: "QWC",
    airport_name: "Berlin Zoo",
    country: "Germany",
  },
  {
    sr: 1900,
    city: "Berlin",
    city_code: "GWW",
    airport_name: "RAF Gatow",
    country: "Germany",
  },
  {
    sr: 1904,
    city: "Berlin",
    city_code: "SXF",
    airport_name: "Schoenefeld",
    country: "Germany",
  },
  {
    sr: 1906,
    city: "Berlin",
    city_code: "TXL",
    airport_name: "Tegel",
    country: "Germany",
  },
  {
    sr: 1908,
    city: "Berlin",
    city_code: "THF",
    airport_name: "Tempelhof",
    country: "Germany",
  },
  {
    sr: 1910,
    city: "Berlin",
    city_code: "BML",
    airport_name: "Berlin",
    country: "United States",
  },
  {
    sr: 1912,
    city: "Bermejo",
    city_code: "BJO",
    airport_name: "Bermejo",
    country: "Bolivia",
  },
  {
    sr: 1914,
    city: "Bermuda",
    city_code: "BDA",
    airport_name: "Bermuda International",
    country: "Bermuda",
  },
  {
    sr: 1916,
    city: "Bermuda",
    city_code: "NWU",
    airport_name: "NAS",
    country: "Bermuda",
  },
  {
    sr: 1918,
    city: "Berne",
    city_code: "BRN",
    airport_name: "Belp",
    country: "Switzerland",
  },
  {
    sr: 1920,
    city: "Beroroha",
    city_code: "WBO",
    airport_name: "Beroroha",
    country: "Madagascar",
  },
  {
    sr: 1922,
    city: "Yaounde",
    city_code: "BTA",
    airport_name: "Yaounde",
    country: "Cameroon",
  },
  {
    sr: 1924,
    city: "Beru",
    city_code: "BEZ",
    airport_name: "Beru",
    country: "Kiribati",
  },
  {
    sr: 1926,
    city: "Besakoa",
    city_code: "BSV",
    airport_name: "Besakoa",
    country: "Papua New Guinea",
  },
  {
    sr: 1928,
    city: "Besalampy",
    city_code: "BPY",
    airport_name: "Besalampy",
    country: "Madagascar",
  },
  {
    sr: 1930,
    city: "Bethel",
    city_code: "BET",
    airport_name: "Bethel Airport",
    country: "United States",
  },
  {
    sr: 1932,
    city: "Bethel",
    city_code: "JBT",
    airport_name: "City Landing",
    country: "United States",
  },
  {
    sr: 1934,
    city: "Bethpage",
    city_code: "BPA",
    airport_name: "Grumman",
    country: "United States",
  },
  {
    sr: 1938,
    city: "Betioky",
    city_code: "BKU",
    airport_name: "Betioky",
    country: "Madagascar",
  },
  {
    sr: 1940,
    city: "Betoota",
    city_code: "BTX",
    airport_name: "Betoota",
    country: "Australia",
  },
  {
    sr: 1942,
    city: "Betou",
    city_code: "BTB",
    airport_name: "Betou",
    country: "Congo",
  },
  {
    sr: 1944,
    city: "Bettles",
    city_code: "BTT",
    airport_name: "Bettles",
    country: "United States",
  },
  {
    sr: 1946,
    city: "Beverley Springs",
    city_code: "BVZ",
    airport_name: "Beverley Springs",
    country: "Australia",
  },
  {
    sr: 1948,
    city: "Beverly",
    city_code: "BVY",
    airport_name: "Beverly",
    country: "United States",
  },
  {
    sr: 1950,
    city: "Bewani",
    city_code: "BWP",
    airport_name: "Bewani",
    country: "Papua New Guinea",
  },
  {
    sr: 1952,
    city: "Beziers",
    city_code: "BZR",
    airport_name: "Beziers Vias",
    country: "France",
  },
  {
    sr: 1954,
    city: "Bhadrapur",
    city_code: "BDP",
    airport_name: "Bhadrapur",
    country: "Nepal",
  },
  {
    sr: 1956,
    city: "Bhairawa",
    city_code: "BWA",
    airport_name: "Bhairawa",
    country: "Nepal",
  },
  {
    sr: 1958,
    city: "Bhamo",
    city_code: "BMO",
    airport_name: "Bhamo",
    country: "Myanmar",
  },
  {
    sr: 1960,
    city: "Bharatpur",
    city_code: "BHR",
    airport_name: "Bharatpur",
    country: "Nepal",
  },
  {
    sr: 1962,
    city: "Bhatinda",
    city_code: "BUP",
    airport_name: "Bhatinda",
    country: "India",
  },
  {
    sr: 1964,
    city: "Bhavnagar",
    city_code: "BHU",
    airport_name: "Bhavnagar",
    country: "India",
  },
  {
    sr: 1966,
    city: "Bhojpur",
    city_code: "BHP",
    airport_name: "Bhojpur",
    country: "Nepal",
  },
  {
    sr: 1968,
    city: "Bhopal",
    city_code: "BHO",
    airport_name: "Bhopal",
    country: "India",
  },
  {
    sr: 1972,
    city: "Bhubaneswar",
    city_code: "BBI",
    airport_name: "Bhubaneswar",
    country: "India",
  },
  {
    sr: 1974,
    city: "Bhuj",
    city_code: "BHJ",
    airport_name: "Rudra Mata",
    country: "India",
  },
  {
    sr: 1976,
    city: "Bhurban",
    city_code: "BHC",
    airport_name: "Bhurban Heliport",
    country: "United States",
  },
  {
    sr: 1978,
    city: "Biak",
    city_code: "BIK",
    airport_name: "Mokmer",
    country: "Indonesia",
  },
  {
    sr: 1980,
    city: "Bialla",
    city_code: "BAA",
    airport_name: "Bialla",
    country: "Papua New Guinea",
  },
  {
    sr: 1982,
    city: "Biangabip",
    city_code: "BPK",
    airport_name: "Biangabip",
    country: "Papua New Guinea",
  },
  {
    sr: 1984,
    city: "Biarritz",
    city_code: "BIQ",
    airport_name: "Biarritz Parme",
    country: "France",
  },
  {
    sr: 1986,
    city: "Biaru",
    city_code: "BRP",
    airport_name: "Biaru",
    country: "Papua New Guinea",
  },
  {
    sr: 1988,
    city: "Biawonque",
    city_code: "BAW",
    airport_name: "Biawonque",
    country: "Gabon",
  },
  {
    sr: 1990,
    city: "Bickerton Island",
    city_code: "BCZ",
    airport_name: "Bickerton Island",
    country: "Australia",
  },
  {
    sr: 1992,
    city: "Bielefeld",
    city_code: "BFE",
    airport_name: "Bielefeld",
    country: "Germany",
  },
  {
    sr: 1994,
    city: "Big Bay",
    city_code: "GBA",
    airport_name: "Big Bay",
    country: "Vanuatu",
  },
  {
    sr: 1996,
    city: "Big Bay Marina",
    city_code: "YIG",
    airport_name: "Big Bay Marina",
    country: "Canada",
  },
  {
    sr: 1998,
    city: "Big Bay Yacht Club",
    city_code: "YYA",
    airport_name: "Big Bay Yacht Club",
    country: "Canada",
  },
  {
    sr: 2000,
    city: "Big Bear",
    city_code: "RBF",
    airport_name: "Big Bear City Arpt",
    country: "United States",
  },
  {
    sr: 2002,
    city: "Big Bell",
    city_code: "BBE",
    airport_name: "Big Bell",
    country: "Australia",
  },
  {
    sr: 2006,
    city: "Big Creek",
    city_code: "BIC",
    airport_name: "Big Creek",
    country: "United States",
  },
  {
    sr: 2008,
    city: "Big Creek",
    city_code: "BGK",
    airport_name: "Big Creek",
    country: "Belize",
  },
  {
    sr: 2010,
    city: "Big Delta",
    city_code: "BIG",
    airport_name: "Intermediate Field",
    country: "United States",
  },
  {
    sr: 2012,
    city: "Big Lake",
    city_code: "BGQ",
    airport_name: "Big Lake",
    country: "United States",
  },
  {
    sr: 2014,
    city: "Big Mountain",
    city_code: "BMX",
    airport_name: "Big Mountain",
    country: "United States",
  },
  {
    sr: 2016,
    city: "Big Piney",
    city_code: "BPI",
    airport_name: "Big Piney marbleton",
    country: "United States",
  },
  {
    sr: 2018,
    city: "Big Rapids",
    city_code: "WBR",
    airport_name: "Big Rapids",
    country: "United States",
  },
  {
    sr: 2020,
    city: "Big Spring",
    city_code: "HCA",
    airport_name: "Howard County",
    country: "United States",
  },
  {
    sr: 2022,
    city: "Big Spring",
    city_code: "BGS",
    airport_name: "Webb AFB",
    country: "United States",
  },
  {
    sr: 2024,
    city: "Big Trout",
    city_code: "YTL",
    airport_name: "Big Trout",
    country: "Canada",
  },
  {
    sr: 2026,
    city: "Bikaner",
    city_code: "BKB",
    airport_name: "Bikaner",
    country: "India",
  },
  {
    sr: 2028,
    city: "Bikini Atoll",
    city_code: "BII",
    airport_name: "Enyu Airfield",
    country: "Marshall Islands",
  },
  {
    sr: 2030,
    city: "Bilaspur",
    city_code: "PAB",
    airport_name: "Bilaspur",
    country: "India",
  },
  {
    sr: 2032,
    city: "Bilbao",
    city_code: "BIO",
    airport_name: "Bilbao",
    country: "Spain",
  },
  {
    sr: 2034,
    city: "Bildudalur",
    city_code: "BIU",
    airport_name: "Bildudalur",
    country: "Iceland",
  },
  {
    sr: 2036,
    city: "Biliau",
    city_code: "BIJ",
    airport_name: "Biliau",
    country: "Papua New Guinea",
  },
  {
    sr: 2040,
    city: "Billiluna",
    city_code: "BIW",
    airport_name: "Billiluna",
    country: "Australia",
  },
  {
    sr: 2042,
    city: "Billings",
    city_code: "BIL",
    airport_name: "Billings",
    country: "United States",
  },
  {
    sr: 2044,
    city: "Billund",
    city_code: "BLL",
    airport_name: "Billund",
    country: "Denmark",
  },
  {
    sr: 2046,
    city: "Biloela",
    city_code: "ZBL",
    airport_name: "Biloela",
    country: "Australia",
  },
  {
    sr: 2048,
    city: "Biloxi",
    city_code: "BIX",
    airport_name: "Keesler AFB",
    country: "United States",
  },
  {
    sr: 2050,
    city: "Bima",
    city_code: "BMU",
    airport_name: "Bima",
    country: "Indonesia",
  },
  {
    sr: 2052,
    city: "Bimin",
    city_code: "BIZ",
    airport_name: "Bimin",
    country: "Papua New Guinea",
  },
  {
    sr: 2054,
    city: "Bimini",
    city_code: "BIM",
    airport_name: "International",
    country: "Bahamas",
  },
  {
    sr: 2056,
    city: "Bimini",
    city_code: "NSB",
    airport_name: "North SPB",
    country: "Bahamas",
  },
  {
    sr: 2058,
    city: "Biniguni",
    city_code: "XBN",
    airport_name: "Biniguni",
    country: "Papua New Guinea",
  },
  {
    sr: 2060,
    city: "Bintulu",
    city_code: "BTU",
    airport_name: "Bintulu",
    country: "Malaysia",
  },
  {
    sr: 2062,
    city: "Bintulu",
    city_code: "ZMK",
    airport_name: "Bintulu Port",
    country: "Malaysia",
  },
  {
    sr: 2064,
    city: "Bintuni",
    city_code: "NTI",
    airport_name: "Bintuni",
    country: "Indonesia",
  },
  {
    sr: 2066,
    city: "Birao",
    city_code: "IRO",
    airport_name: "Birao",
    country: "Central African Republic",
  },
  {
    sr: 2068,
    city: "Biratnagar",
    city_code: "BIR",
    airport_name: "Biratnagar",
    country: "Nepal",
  },
  {
    sr: 2070,
    city: "Birch Creek",
    city_code: "KBC",
    airport_name: "Birch Creek",
    country: "United States",
  },
  {
    sr: 2074,
    city: "Bird Island",
    city_code: "BDI",
    airport_name: "Bird Island",
    country: "Seychelles",
  },
  {
    sr: 2076,
    city: "Birdsville",
    city_code: "BVI",
    airport_name: "Birdsville",
    country: "Australia",
  },
  {
    sr: 2078,
    city: "Birjand",
    city_code: "XBJ",
    airport_name: "Birjand",
    country: "Iran",
  },
  {
    sr: 2080,
    city: "Birmingham",
    city_code: "BHM",
    airport_name: "Birmingham",
    country: "United States",
  },
  {
    sr: 2082,
    city: "Birmingham",
    city_code: "ZBC",
    airport_name: "Colmore Row Bus Stn",
    country: "United Kingdom",
  },
  {
    sr: 2084,
    city: "Birmingham",
    city_code: "BHX",
    airport_name: "Birmingham International",
    country: "United Kingdom",
  },
  {
    sr: 2086,
    city: "Birni Nkoni",
    city_code: "BKN",
    airport_name: "Birni Nkoni",
    country: "Niger",
  },
  {
    sr: 2088,
    city: "Bisbee",
    city_code: "BSQ",
    airport_name: "Bisbee Municipal Arpt",
    country: "United States",
  },
  {
    sr: 2090,
    city: "Bisha",
    city_code: "BHH",
    airport_name: "Bisha",
    country: "Saudi Arabia",
  },
  {
    sr: 2092,
    city: "Bishe Kola",
    city_code: "BSM",
    airport_name: "Bishe Kola",
    country: "United States",
  },
  {
    sr: 2094,
    city: "Bishkek",
    city_code: "FRU",
    airport_name: "Bishkek",
    country: "Kyrgyzstan",
  },
  {
    sr: 2096,
    city: "Bisho",
    city_code: "BIY",
    airport_name: "Bisho",
    country: "South Africa",
  },
  {
    sr: 2098,
    city: "Bishop",
    city_code: "BIH",
    airport_name: "Bishop",
    country: "United States",
  },
  {
    sr: 2100,
    city: "Biskra",
    city_code: "BSK",
    airport_name: "Biskra",
    country: "Algeria",
  },
  {
    sr: 2102,
    city: "Bislig",
    city_code: "BPH",
    airport_name: "Bislig",
    country: "Philippines",
  },
  {
    sr: 2104,
    city: "Bismarck",
    city_code: "BIS",
    airport_name: "Bismarck",
    country: "United States",
  },
  {
    sr: 2108,
    city: "Bissau",
    city_code: "OXB",
    airport_name: "Osvaldo Vieira",
    country: "Guinea Bissau",
  },
  {
    sr: 2110,
    city: "Bitam",
    city_code: "BMM",
    airport_name: "Bitam",
    country: "Gabon",
  },
  {
    sr: 2112,
    city: "Bitburg",
    city_code: "BBJ",
    airport_name: "Bitburg Air Base",
    country: "Germany",
  },
  {
    sr: 2114,
    city: "Bizant",
    city_code: "BZP",
    airport_name: "Bizant",
    country: "Australia",
  },
  {
    sr: 2116,
    city: "Bjerka",
    city_code: "ZMZ",
    airport_name: "Railway",
    country: "Norway",
  },
  {
    sr: 2118,
    city: "Black Tickle",
    city_code: "YBI",
    airport_name: "Black Tickle",
    country: "Canada",
  },
  {
    sr: 2120,
    city: "Blackall",
    city_code: "BKQ",
    airport_name: "Blackall",
    country: "Australia",
  },
  {
    sr: 2122,
    city: "Yateley",
    city_code: "BBS",
    airport_name: "Blackbushe",
    country: "United Kingdom",
  },
  {
    sr: 2124,
    city: "Blackpool",
    city_code: "BLK",
    airport_name: "Blackpool",
    country: "United Kingdom",
  },
  {
    sr: 2126,
    city: "Blacksburg",
    city_code: "BCB",
    airport_name: "Virginia Tech",
    country: "United States",
  },
  {
    sr: 2128,
    city: "Blackstone",
    city_code: "BKT",
    airport_name: "Blackstone AAF",
    country: "United States",
  },
  {
    sr: 2130,
    city: "Blackwater",
    city_code: "BLT",
    airport_name: "Blackwater",
    country: "Australia",
  },
  {
    sr: 2132,
    city: "Blackwell",
    city_code: "BWL",
    airport_name: "Blackwell",
    country: "United States",
  },
  {
    sr: 2134,
    city: "Blagoveschensk",
    city_code: "BQS",
    airport_name: "Blagoveschensk",
    country: "Russia",
  },
  {
    sr: 2136,
    city: "Blaine",
    city_code: "BWS",
    airport_name: "Blaine",
    country: "United States",
  },
  {
    sr: 2138,
    city: "Blairsville",
    city_code: "BSI",
    airport_name: "Blairsville",
    country: "United States",
  },
  {
    sr: 2142,
    city: "Blakely Island",
    city_code: "BYW",
    airport_name: "Blakely Island",
    country: "United States",
  },
  {
    sr: 2144,
    city: "Blanc Sablon",
    city_code: "YBX",
    airport_name: "Blanc Sablon",
    country: "Canada",
  },
  {
    sr: 2146,
    city: "Blanding",
    city_code: "BDG",
    airport_name: "Blanding",
    country: "United States",
  },
  {
    sr: 2148,
    city: "Blantyre",
    city_code: "BLZ",
    airport_name: "Chileka",
    country: "Malawi",
  },
  {
    sr: 2150,
    city: "Blenheim",
    city_code: "BHE",
    airport_name: "Blenheim",
    country: "New Zealand",
  },
  {
    sr: 2152,
    city: "Block Island",
    city_code: "BID",
    airport_name: "Block Island",
    country: "United States",
  },
  {
    sr: 2154,
    city: "Blonduos",
    city_code: "BLO",
    airport_name: "Blonduos",
    country: "Iceland",
  },
  {
    sr: 2156,
    city: "Bloodvein",
    city_code: "YDV",
    airport_name: "Bloodvein",
    country: "Canada",
  },
  {
    sr: 2158,
    city: "Bloomfield",
    city_code: "BFC",
    airport_name: "Bloomfield",
    country: "Australia",
  },
  {
    sr: 2160,
    city: "Bloomington",
    city_code: "BMG",
    airport_name: "Bloomington",
    country: "United States",
  },
  {
    sr: 2162,
    city: "Bloomington Normal",
    city_code: "BMI",
    airport_name: "Bloomington Normal",
    country: "United States",
  },
  {
    sr: 2164,
    city: "Blubber Bay",
    city_code: "XBB",
    airport_name: "Blubber Bay",
    country: "Canada",
  },
  {
    sr: 2166,
    city: "Blue Bell",
    city_code: "BBX",
    airport_name: "Wings Field",
    country: "United States",
  },
  {
    sr: 2168,
    city: "Blue Canyon",
    city_code: "BLU",
    airport_name: "Blue Canyon",
    country: "United States",
  },
  {
    sr: 2170,
    city: "Blue Fox Bay",
    city_code: "BFB",
    airport_name: "Blue Fox Bay",
    country: "United States",
  },
  {
    sr: 2172,
    city: "Blue Lagoon",
    city_code: "BXL",
    airport_name: "Blue Lagoon",
    country: "Fiji",
  },
  {
    sr: 2176,
    city: "Bluefields",
    city_code: "BEF",
    airport_name: "Bluefields",
    country: "Nicaragua",
  },
  {
    sr: 2178,
    city: "Blumenau",
    city_code: "BNU",
    airport_name: "Blumenau",
    country: "Brazil",
  },
  {
    sr: 2180,
    city: "Blythe",
    city_code: "BLH",
    airport_name: "Blythe",
    country: "United States",
  },
  {
    sr: 2182,
    city: "Blytheville",
    city_code: "BYH",
    airport_name: "Blytheville AFB",
    country: "United States",
  },
  {
    sr: 2184,
    city: "Blytheville",
    city_code: "HKA",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 2186,
    city: "Bo",
    city_code: "KBS",
    airport_name: "Bo",
    country: "Sierra Leone",
  },
  {
    sr: 2188,
    city: "Boa Vista",
    city_code: "BVC",
    airport_name: "Rabil",
    country: "Cape Verde",
  },
  {
    sr: 2190,
    city: "Boa Vista",
    city_code: "BVB",
    airport_name: "Boa Vista",
    country: "Brazil",
  },
  {
    sr: 2192,
    city: "Boana",
    city_code: "BNV",
    airport_name: "Boana",
    country: "Papua New Guinea",
  },
  {
    sr: 2194,
    city: "Boang",
    city_code: "BOV",
    airport_name: "Boang",
    country: "Papua New Guinea",
  },
  {
    sr: 2196,
    city: "Bobadilla",
    city_code: "OZI",
    airport_name: "Bobadilla",
    country: "Spain",
  },
  {
    sr: 2198,
    city: "Bobo Dioulasso",
    city_code: "BOY",
    airport_name: "Borgo",
    country: "Burkina Faso",
  },
  {
    sr: 2200,
    city: "Bobquinn Lake",
    city_code: "YBO",
    airport_name: "Bobquinn Lake",
    country: "Canada",
  },
  {
    sr: 2202,
    city: "Boca Do Acre",
    city_code: "BCR",
    airport_name: "Boca Do Acre",
    country: "Brazil",
  },
  {
    sr: 2204,
    city: "Boca Raton",
    city_code: "BCT",
    airport_name: "Public",
    country: "United States",
  },
  {
    sr: 2206,
    city: "Bocas Del Toro",
    city_code: "BOC",
    airport_name: "Bocas Del Toro",
    country: "Panama",
  },
  {
    sr: 2210,
    city: "Bodinumu",
    city_code: "BNM",
    airport_name: "Bodinumu",
    country: "Papua New Guinea",
  },
  {
    sr: 2212,
    city: "Bodo",
    city_code: "BOO",
    airport_name: "Bodo",
    country: "Norway",
  },
  {
    sr: 2214,
    city: "Bodrum",
    city_code: "BXN",
    airport_name: "Imsik Airport",
    country: "Turkey",
  },
  {
    sr: 2216,
    city: "Bodrum",
    city_code: "BJV",
    airport_name: "Milas Airport",
    country: "Turkey",
  },
  {
    sr: 2218,
    city: "Boeblingen",
    city_code: "PHM",
    airport_name: "Boeblingen",
    country: "Germany",
  },
  {
    sr: 2220,
    city: "Boende",
    city_code: "BNB",
    airport_name: "Boende",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 2222,
    city: "Bogalusa",
    city_code: "BXA",
    airport_name: "George R Carr",
    country: "United States",
  },
  {
    sr: 2224,
    city: "Bogande",
    city_code: "XBG",
    airport_name: "Bogande",
    country: "Burkina Faso",
  },
  {
    sr: 2226,
    city: "Boghe",
    city_code: "BGH",
    airport_name: "Abbaye",
    country: "Mauritania",
  },
  {
    sr: 2228,
    city: "Bogota",
    city_code: "BOG",
    airport_name: "Eldorado",
    country: "Colombia",
  },
  {
    sr: 2230,
    city: "Boigu Island",
    city_code: "GIC",
    airport_name: "Boigu Island",
    country: "Australia",
  },
  {
    sr: 2232,
    city: "Boise",
    city_code: "BOI",
    airport_name: "Air Term  Gowen Fld",
    country: "United States",
  },
  {
    sr: 2234,
    city: "Bojnord",
    city_code: "BJB",
    airport_name: "Bojnord",
    country: "Iran",
  },
  {
    sr: 2236,
    city: "Boke",
    city_code: "BKJ",
    airport_name: "Boke",
    country: "Guinea",
  },
  {
    sr: 2238,
    city: "Bokondini",
    city_code: "BUI",
    airport_name: "Bokondini",
    country: "Indonesia",
  },
  {
    sr: 2240,
    city: "Bokoro",
    city_code: "BKR",
    airport_name: "Bokoro",
    country: "Chad",
  },
  {
    sr: 2244,
    city: "Boku",
    city_code: "BOQ",
    airport_name: "Boku",
    country: "Papua New Guinea",
  },
  {
    sr: 2246,
    city: "Bol",
    city_code: "BWK",
    airport_name: "Bol",
    country: "Croatia  Hrvatska",
  },
  {
    sr: 2248,
    city: "Bol",
    city_code: "OTC",
    airport_name: "Bol",
    country: "Chad",
  },
  {
    sr: 2250,
    city: "Bolaang",
    city_code: "BJG",
    airport_name: "Bolaang",
    country: "Indonesia",
  },
  {
    sr: 2252,
    city: "Bollon",
    city_code: "BLS",
    airport_name: "Bollon",
    country: "Australia",
  },
  {
    sr: 2254,
    city: "Bologna",
    city_code: "BLQ",
    airport_name: "Guglielmo Marconi",
    country: "Italy",
  },
  {
    sr: 2256,
    city: "Bolovip",
    city_code: "BVP",
    airport_name: "Bolovip",
    country: "Papua New Guinea",
  },
  {
    sr: 2258,
    city: "Bolwarra",
    city_code: "BCK",
    airport_name: "Bolwarra",
    country: "Australia",
  },
  {
    sr: 2260,
    city: "Bolzano",
    city_code: "BZO",
    airport_name: "Bolzano",
    country: "Italy",
  },
  {
    sr: 2262,
    city: "Bom Jesus Da Lapa",
    city_code: "LAZ",
    airport_name: "Bom Jesus Da Lapa",
    country: "Brazil",
  },
  {
    sr: 2264,
    city: "Boma",
    city_code: "BOA",
    airport_name: "Boma",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 2266,
    city: "Bomai",
    city_code: "BMH",
    airport_name: "Bomai",
    country: "Papua New Guinea",
  },
  {
    sr: 2268,
    city: "Bonaire",
    city_code: "BON",
    airport_name: "Flamingo International",
    country: "Netherlands Antilles",
  },
  {
    sr: 2270,
    city: "Bonanza",
    city_code: "BZA",
    airport_name: "San Pedro",
    country: "Nicaragua",
  },
  {
    sr: 2272,
    city: "Bonaventure",
    city_code: "YVB",
    airport_name: "Bonaventure",
    country: "Canada",
  },
  {
    sr: 2274,
    city: "Bondoukou",
    city_code: "BDK",
    airport_name: "Bondoukou",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 2278,
    city: "Bongo",
    city_code: "BGP",
    airport_name: "Bongo",
    country: "Gabon",
  },
  {
    sr: 2280,
    city: "Bongor",
    city_code: "OGR",
    airport_name: "Bongor",
    country: "Chad",
  },
  {
    sr: 2282,
    city: "Bongouanou",
    city_code: "BGG",
    airport_name: "Bongouanou",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 2284,
    city: "Bonito",
    city_code: "BYO",
    airport_name: "Bonito Airport",
    country: "Brazil",
  },
  {
    sr: 2286,
    city: "Bonnyville",
    city_code: "YBY",
    airport_name: "Bonnyville",
    country: "Canada",
  },
  {
    sr: 2288,
    city: "Bontang",
    city_code: "BXT",
    airport_name: "Bontang",
    country: "Indonesia",
  },
  {
    sr: 2290,
    city: "Bonthe",
    city_code: "BTE",
    airport_name: "Bonthe",
    country: "Sierra Leone",
  },
  {
    sr: 2292,
    city: "Boone",
    city_code: "BNW",
    airport_name: "Boone",
    country: "United States",
  },
  {
    sr: 2294,
    city: "Booue",
    city_code: "BGB",
    airport_name: "Booue",
    country: "Gabon",
  },
  {
    sr: 2296,
    city: "Bora Bora",
    city_code: "BOB",
    airport_name: "Motu mute",
    country: "French Polynesia",
  },
  {
    sr: 2298,
    city: "Borama",
    city_code: "BXX",
    airport_name: "Borama",
    country: "Somalia",
  },
  {
    sr: 2300,
    city: "Borba",
    city_code: "RBB",
    airport_name: "Borba",
    country: "Brazil",
  },
  {
    sr: 2302,
    city: "Bordeaux",
    city_code: "BOD",
    airport_name: "Bordeaux Airport",
    country: "France",
  },
  {
    sr: 2304,
    city: "Bordeaux",
    city_code: "ZFQ",
    airport_name: "Gare de Bordeaux",
    country: "France",
  },
  {
    sr: 2306,
    city: "Borden",
    city_code: "YBN",
    airport_name: "Borden",
    country: "Canada",
  },
  {
    sr: 2308,
    city: "Bordj Badji Mokhtar",
    city_code: "BMW",
    airport_name: "Bordj Badji Mokhtar",
    country: "Algeria",
  },
  {
    sr: 2312,
    city: "Borgarfjordur Eystri",
    city_code: "BGJ",
    airport_name: "Borgarfjordur Eystri",
    country: "Iceland",
  },
  {
    sr: 2314,
    city: "Borger",
    city_code: "BGD",
    airport_name: "Borger",
    country: "United States",
  },
  {
    sr: 2316,
    city: "Boridi",
    city_code: "BPB",
    airport_name: "Boridi",
    country: "Papua New Guinea",
  },
  {
    sr: 2318,
    city: "Borkum",
    city_code: "BMK",
    airport_name: "Borkum",
    country: "Germany",
  },
  {
    sr: 2320,
    city: "Borlange Falun",
    city_code: "XYB",
    airport_name: "Borlange Railway Svc",
    country: "Sweden",
  },
  {
    sr: 2322,
    city: "Borlange Falun",
    city_code: "BLE",
    airport_name: "Dala",
    country: "Sweden",
  },
  {
    sr: 2324,
    city: "Bornholm",
    city_code: "RNN",
    airport_name: "Bornholm",
    country: "Denmark",
  },
  {
    sr: 2326,
    city: "Bornite",
    city_code: "RLU",
    airport_name: "Bornite Upper",
    country: "United States",
  },
  {
    sr: 2328,
    city: "Borotou",
    city_code: "BRZ",
    airport_name: "Borotou",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 2330,
    city: "Borrego Springs",
    city_code: "BXS",
    airport_name: "Borrego Springs",
    country: "United States",
  },
  {
    sr: 2332,
    city: "Borroloola",
    city_code: "BOX",
    airport_name: "Borroloola",
    country: "Australia",
  },
  {
    sr: 2334,
    city: "Boset",
    city_code: "BOT",
    airport_name: "Boset",
    country: "Papua New Guinea",
  },
  {
    sr: 2336,
    city: "Bossangoa",
    city_code: "BSN",
    airport_name: "Bossangoa",
    country: "Central African Republic",
  },
  {
    sr: 2338,
    city: "Bossaso",
    city_code: "BSA",
    airport_name: "Bossaso",
    country: "Somalia",
  },
  {
    sr: 2340,
    city: "Bossembele",
    city_code: "BEM",
    airport_name: "Bossembele",
    country: "Central African Republic",
  },
  {
    sr: 2342,
    city: "Bost",
    city_code: "BST",
    airport_name: "Bost",
    country: "Afghanistan",
  },
  {
    sr: 2346,
    city: "Boston",
    city_code: "ZTY",
    airport_name: "Boston BKBAY Rail",
    country: "United States",
  },
  {
    sr: 2348,
    city: "Boston",
    city_code: "JBC",
    airport_name: "Boston City Heliport",
    country: "United States",
  },
  {
    sr: 2350,
    city: "Boston",
    city_code: "ZRU",
    airport_name: "Boston RT128 Rail",
    country: "United States",
  },
  {
    sr: 2352,
    city: "Boston",
    city_code: "ZTO",
    airport_name: "Boston South Rail",
    country: "United States",
  },
  {
    sr: 2354,
    city: "Boston",
    city_code: "BOS",
    airport_name: "Logan International",
    country: "United States",
  },
  {
    sr: 2356,
    city: "Boswell Bay",
    city_code: "BSW",
    airport_name: "Boswell Bay",
    country: "United States",
  },
  {
    sr: 2358,
    city: "Botopasie",
    city_code: "BTO",
    airport_name: "Botopasie",
    country: "Suriname",
  },
  {
    sr: 2360,
    city: "Bouake",
    city_code: "BYK",
    airport_name: "Bouake",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 2362,
    city: "Bouar",
    city_code: "BOP",
    airport_name: "Bouar",
    country: "Central African Republic",
  },
  {
    sr: 2364,
    city: "Bouca",
    city_code: "BCF",
    airport_name: "Bouca",
    country: "Central African Republic",
  },
  {
    sr: 2366,
    city: "Boulder",
    city_code: "WBI",
    airport_name: "Broker Inn",
    country: "United States",
  },
  {
    sr: 2368,
    city: "Boulder",
    city_code: "WHH",
    airport_name: "Hiltons Har H",
    country: "United States",
  },
  {
    sr: 2370,
    city: "Boulder",
    city_code: "WBU",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 2372,
    city: "Boulder City",
    city_code: "BLD",
    airport_name: "Boulder City",
    country: "United States",
  },
  {
    sr: 2374,
    city: "Boulia",
    city_code: "BQL",
    airport_name: "Boulia",
    country: "Australia",
  },
  {
    sr: 2376,
    city: "Boulsa",
    city_code: "XBO",
    airport_name: "Boulsa",
    country: "Burkina Faso",
  },
  {
    sr: 2380,
    city: "Bouna",
    city_code: "BQO",
    airport_name: "Bouna",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 2382,
    city: "Boundary",
    city_code: "BYA",
    airport_name: "Boundary",
    country: "United States",
  },
  {
    sr: 2384,
    city: "Boundiali",
    city_code: "BXI",
    airport_name: "Boundiali",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 2386,
    city: "Boundji",
    city_code: "BOE",
    airport_name: "Boundji",
    country: "Congo",
  },
  {
    sr: 2388,
    city: "Bountiful",
    city_code: "BTF",
    airport_name: "Salt Lake Skypark",
    country: "United States",
  },
  {
    sr: 2390,
    city: "Bourgas",
    city_code: "BOJ",
    airport_name: "Bourgas",
    country: "Bulgaria",
  },
  {
    sr: 2392,
    city: "Bourges",
    city_code: "BOU",
    airport_name: "Bourges",
    country: "France",
  },
  {
    sr: 2394,
    city: "Bourke",
    city_code: "BRK",
    airport_name: "Bourke",
    country: "Australia",
  },
  {
    sr: 2396,
    city: "Bournemouth",
    city_code: "BOH",
    airport_name: "Bournemouth International",
    country: "United Kingdom",
  },
  {
    sr: 2398,
    city: "Boussaada",
    city_code: "BUJ",
    airport_name: "Ain Eddis",
    country: "Algeria",
  },
  {
    sr: 2400,
    city: "Bousso",
    city_code: "OUT",
    airport_name: "Bousso",
    country: "Chad",
  },
  {
    sr: 2402,
    city: "Boutilimit",
    city_code: "OTL",
    airport_name: "Boutilimit",
    country: "Mauritania",
  },
  {
    sr: 2404,
    city: "Bowen",
    city_code: "ZBO",
    airport_name: "Bowen",
    country: "Australia",
  },
  {
    sr: 2406,
    city: "Bowling Green",
    city_code: "BWG",
    airport_name: "Warren County",
    country: "United States",
  },
  {
    sr: 2408,
    city: "Bowling Green",
    city_code: "APH",
    airport_name: "Camp A P Hill",
    country: "United States",
  },
  {
    sr: 2410,
    city: "Bowman",
    city_code: "BWM",
    airport_name: "Bowman",
    country: "United States",
  },
  {
    sr: 2414,
    city: "Boxborough",
    city_code: "BXC",
    airport_name: "Boxborough",
    country: "United States",
  },
  {
    sr: 2416,
    city: "Bozeman",
    city_code: "BZN",
    airport_name: "Gallatin Field",
    country: "United States",
  },
  {
    sr: 2418,
    city: "Bozen",
    city_code: "ZBN",
    airport_name: "Bus Station",
    country: "Italy",
  },
  {
    sr: 2420,
    city: "Bozoum",
    city_code: "BOZ",
    airport_name: "Bozoum",
    country: "Central African Republic",
  },
  {
    sr: 2422,
    city: "Brack",
    city_code: "BCQ",
    airport_name: "Brack",
    country: "Libya",
  },
  {
    sr: 2424,
    city: "Bradford",
    city_code: "BRF",
    airport_name: "Bradford",
    country: "United Kingdom",
  },
  {
    sr: 2426,
    city: "Bradford",
    city_code: "ZFC",
    airport_name: "Bus Station",
    country: "United Kingdom",
  },
  {
    sr: 2428,
    city: "Bradford",
    city_code: "BDF",
    airport_name: "Rinkenberger",
    country: "United States",
  },
  {
    sr: 2430,
    city: "Bradford",
    city_code: "BFD",
    airport_name: "Bradford",
    country: "United States",
  },
  {
    sr: 2432,
    city: "Brady",
    city_code: "BBD",
    airport_name: "Curtis Field",
    country: "United States",
  },
  {
    sr: 2434,
    city: "Braga",
    city_code: "BGZ",
    airport_name: "Braga",
    country: "Portugal",
  },
  {
    sr: 2436,
    city: "Braganca",
    city_code: "BGC",
    airport_name: "Braganca",
    country: "Portugal",
  },
  {
    sr: 2438,
    city: "Braganca Paulista",
    city_code: "BJP",
    airport_name: "Braganca Paulista",
    country: "Brazil",
  },
  {
    sr: 2440,
    city: "Brahman",
    city_code: "BRH",
    airport_name: "Brahman",
    country: "Papua New Guinea",
  },
  {
    sr: 2442,
    city: "Brainerd",
    city_code: "BRD",
    airport_name: "Crow Wing County",
    country: "United States",
  },
  {
    sr: 2444,
    city: "Braintree",
    city_code: "WXF",
    airport_name: "Wether Fld RAF",
    country: "United Kingdom",
  },
  {
    sr: 2448,
    city: "Brampton Island",
    city_code: "BMP",
    airport_name: "Brampton Island",
    country: "Australia",
  },
  {
    sr: 2450,
    city: "Brandon",
    city_code: "LKZ",
    airport_name: "Lakenheath RAF",
    country: "United Kingdom",
  },
  {
    sr: 2452,
    city: "Brandon",
    city_code: "YBR",
    airport_name: "Brandon",
    country: "Canada",
  },
  {
    sr: 2454,
    city: "Branson Point Lookout",
    city_code: "PLK",
    airport_name: "M Graham Clark",
    country: "United States",
  },
  {
    sr: 2456,
    city: "Brasilia",
    city_code: "BSB",
    airport_name: "Presidente Juscelino Kubitschek International Airport",
    country: "Brazil",
  },
  {
    sr: 2458,
    city: "Bratislava",
    city_code: "BTS",
    airport_name: "Ivanka",
    country: "Slovak Republic",
  },
  {
    sr: 2460,
    city: "Bratsk",
    city_code: "BTK",
    airport_name: "Bratsk",
    country: "Russia",
  },
  {
    sr: 2462,
    city: "Braunschweig",
    city_code: "BWE",
    airport_name: "Braunschweig",
    country: "Germany",
  },
  {
    sr: 2464,
    city: "Brava",
    city_code: "BVR",
    airport_name: "Esperadinha",
    country: "Cape Verde",
  },
  {
    sr: 2466,
    city: "Brawley",
    city_code: "BWC",
    airport_name: "Brawley",
    country: "United States",
  },
  {
    sr: 2468,
    city: "Brazoria",
    city_code: "BZT",
    airport_name: "Hinkles Ferry",
    country: "United States",
  },
  {
    sr: 2470,
    city: "Brazzaville",
    city_code: "BZV",
    airport_name: "Maya Maya",
    country: "Congo",
  },
  {
    sr: 2472,
    city: "Breckenridge",
    city_code: "QKB",
    airport_name: "Van Service",
    country: "United States",
  },
  {
    sr: 2474,
    city: "Breckenridge",
    city_code: "BKD",
    airport_name: "Stephens County",
    country: "United States",
  },
  {
    sr: 2476,
    city: "Breda",
    city_code: "GLZ",
    airport_name: "Gilze Rijen",
    country: "Netherlands",
  },
  {
    sr: 2478,
    city: "Bregenz",
    city_code: "XGZ",
    airport_name: "Railway Station",
    country: "Austria",
  },
  {
    sr: 2482,
    city: "Breiddalsvik",
    city_code: "BXV",
    airport_name: "Breiddalsvik",
    country: "Iceland",
  },
  {
    sr: 2484,
    city: "Bremen",
    city_code: "BRE",
    airport_name: "Bremen",
    country: "Germany",
  },
  {
    sr: 2486,
    city: "Bremerhaven",
    city_code: "BRV",
    airport_name: "Bremerhaven",
    country: "Germany",
  },
  {
    sr: 2488,
    city: "Bremerton",
    city_code: "PWT",
    airport_name: "Bremerton",
    country: "United States",
  },
  {
    sr: 2490,
    city: "Brest",
    city_code: "BQT",
    airport_name: "Brest",
    country: "Belarus",
  },
  {
    sr: 2492,
    city: "Brest",
    city_code: "BES",
    airport_name: "Guipavas",
    country: "France",
  },
  {
    sr: 2494,
    city: "Breves",
    city_code: "BVS",
    airport_name: "Breves",
    country: "Brazil",
  },
  {
    sr: 2496,
    city: "Brewarrina",
    city_code: "BWQ",
    airport_name: "Brewarrina",
    country: "Australia",
  },
  {
    sr: 2498,
    city: "Bria",
    city_code: "BIV",
    airport_name: "Bria",
    country: "Central African Republic",
  },
  {
    sr: 2500,
    city: "Briansk",
    city_code: "BZK",
    airport_name: "Briansk",
    country: "Russia",
  },
  {
    sr: 2502,
    city: "Bridgeport",
    city_code: "BDR",
    airport_name: "Igor I Sikorsky Mem",
    country: "United States",
  },
  {
    sr: 2504,
    city: "Bridgetown",
    city_code: "BGI",
    airport_name: "Grantley Adams Intl",
    country: "Barbados",
  },
  {
    sr: 2506,
    city: "Brigham City",
    city_code: "BMC",
    airport_name: "Brigham City",
    country: "United States",
  },
  {
    sr: 2508,
    city: "Bright",
    city_code: "BRJ",
    airport_name: "Bright",
    country: "Australia",
  },
  {
    sr: 2510,
    city: "Brighton",
    city_code: "BSH",
    airport_name: "Brighton",
    country: "United Kingdom",
  },
  {
    sr: 2512,
    city: "Brighton Downs",
    city_code: "BHT",
    airport_name: "Brighton Downs",
    country: "Australia",
  },
  {
    sr: 2516,
    city: "Brindisi",
    city_code: "BDS",
    airport_name: "Papola Casale",
    country: "Italy",
  },
  {
    sr: 2518,
    city: "Brisbane",
    city_code: "BNE",
    airport_name: "Brisbane International",
    country: "Australia",
  },
  {
    sr: 2520,
    city: "Bristol",
    city_code: "BRS",
    airport_name: "Bristol",
    country: "United Kingdom",
  },
  {
    sr: 2522,
    city: "London St Pancras",
    city_code: "QQS",
    airport_name: "London St Pancras",
    country: "United Kingdom",
  },
  {
    sr: 2524,
    city: "Britton",
    city_code: "TTO",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 2526,
    city: "Brive La Gaillarde",
    city_code: "BVE",
    airport_name: "Laroche",
    country: "France",
  },
  {
    sr: 2528,
    city: "Brize Norton",
    city_code: "BZZ",
    airport_name: "Raf Station",
    country: "United Kingdom",
  },
  {
    sr: 2530,
    city: "Brno",
    city_code: "BRQ",
    airport_name: "Turany",
    country: "Czechoslovakia  former",
  },
  {
    sr: 2532,
    city: "Broadus",
    city_code: "BDX",
    airport_name: "Broadus",
    country: "United States",
  },
  {
    sr: 2534,
    city: "Broadview",
    city_code: "YDR",
    airport_name: "Broadview",
    country: "Canada",
  },
  {
    sr: 2536,
    city: "Brochet",
    city_code: "YBT",
    airport_name: "Brochet",
    country: "Canada",
  },
  {
    sr: 2538,
    city: "Brockville",
    city_code: "XBR",
    airport_name: "Brockville",
    country: "Canada",
  },
  {
    sr: 2540,
    city: "Broken Bow",
    city_code: "BBW",
    airport_name: "Broken Bow",
    country: "United States",
  },
  {
    sr: 2542,
    city: "Broken Hill",
    city_code: "BHQ",
    airport_name: "Broken Hill",
    country: "Australia",
  },
  {
    sr: 2544,
    city: "Bromont",
    city_code: "ZBM",
    airport_name: "Bromont",
    country: "Canada",
  },
  {
    sr: 2546,
    city: "Bronnoysund",
    city_code: "BNN",
    airport_name: "Bronnoy",
    country: "Norway",
  },
  {
    sr: 2550,
    city: "Bronson Creek",
    city_code: "YBM",
    airport_name: "Bronson Creek",
    country: "Canada",
  },
  {
    sr: 2552,
    city: "Brookings",
    city_code: "BOK",
    airport_name: "Brookings State",
    country: "United States",
  },
  {
    sr: 2554,
    city: "Brookings",
    city_code: "BKX",
    airport_name: "Brookings",
    country: "United States",
  },
  {
    sr: 2556,
    city: "Brooks Lake",
    city_code: "BKF",
    airport_name: "Brooks Lake",
    country: "United States",
  },
  {
    sr: 2558,
    city: "Brooks Lodge",
    city_code: "RBH",
    airport_name: "Brooks Lodge",
    country: "United States",
  },
  {
    sr: 2560,
    city: "Broome",
    city_code: "BME",
    airport_name: "Broome",
    country: "Australia",
  },
  {
    sr: 2562,
    city: "Broomfield",
    city_code: "BJC",
    airport_name: "Jeffco",
    country: "United States",
  },
  {
    sr: 2564,
    city: "Brownsville",
    city_code: "BRO",
    airport_name: "South Padre Is Intl",
    country: "United States",
  },
  {
    sr: 2566,
    city: "Brownwood",
    city_code: "BWD",
    airport_name: "Brownwood",
    country: "United States",
  },
  {
    sr: 2568,
    city: "Brueggen",
    city_code: "BGN",
    airport_name: "R A F",
    country: "Germany",
  },
  {
    sr: 2570,
    city: "Brumado",
    city_code: "BMS",
    airport_name: "Brumado",
    country: "Brazil",
  },
  {
    sr: 2572,
    city: "Brumunddal",
    city_code: "ZVB",
    airport_name: "Brumunddal Railway",
    country: "Norway",
  },
  {
    sr: 2574,
    city: "Brunette Downs",
    city_code: "BTD",
    airport_name: "Brunette Downs",
    country: "Australia",
  },
  {
    sr: 2576,
    city: "Brunswick",
    city_code: "BQK",
    airport_name: "Glynco Jetport",
    country: "United States",
  },
  {
    sr: 2578,
    city: "Brunswick",
    city_code: "SSI",
    airport_name: "Mckinnon",
    country: "United States",
  },
  {
    sr: 2580,
    city: "Brunswick",
    city_code: "NHZ",
    airport_name: "NAS",
    country: "United States",
  },
  {
    sr: 2584,
    city: "Brus Laguna",
    city_code: "BHG",
    airport_name: "Brus Laguna",
    country: "Honduras",
  },
  {
    sr: 2586,
    city: "Brussels",
    city_code: "CRL",
    airport_name: "Charleroi Brussels South",
    country: "Belgium",
  },
  {
    sr: 2588,
    city: "Brussels",
    city_code: "ZYR",
    airport_name: "Midi Railway Station",
    country: "Belgium",
  },
  {
    sr: 2590,
    city: "Brussels",
    city_code: "BRU",
    airport_name: "National",
    country: "Belgium",
  },
  {
    sr: 2592,
    city: "Bryan",
    city_code: "CFD",
    airport_name: "Coulter Field",
    country: "United States",
  },
  {
    sr: 2594,
    city: "Bryce",
    city_code: "BCE",
    airport_name: "Bryce",
    country: "United States",
  },
  {
    sr: 2596,
    city: "Bua",
    city_code: "BVF",
    airport_name: "Dama",
    country: "Fiji",
  },
  {
    sr: 2598,
    city: "Bubaque",
    city_code: "BQE",
    airport_name: "Bubaque",
    country: "Guinea Bissau",
  },
  {
    sr: 2600,
    city: "Bucaramanga",
    city_code: "BGA",
    airport_name: "Palo Negro",
    country: "Colombia",
  },
  {
    sr: 2602,
    city: "Buchanan",
    city_code: "UCN",
    airport_name: "Buchanan",
    country: "Liberia",
  },
  {
    sr: 2604,
    city: "Buchans",
    city_code: "YZM",
    airport_name: "Buchans",
    country: "Canada",
  },
  {
    sr: 2606,
    city: "Bucharest",
    city_code: "BBU",
    airport_name: "Baneasa",
    country: "Romania",
  },
  {
    sr: 2608,
    city: "Bucharest",
    city_code: "OTP",
    airport_name: "Henri Coanda International",
    country: "Romania",
  },
  {
    sr: 2610,
    city: "Buckeye",
    city_code: "BXK",
    airport_name: "Buckeye",
    country: "United States",
  },
  {
    sr: 2612,
    city: "Buckland",
    city_code: "BKC",
    airport_name: "Buckland",
    country: "United States",
  },
  {
    sr: 2614,
    city: "Budapest",
    city_code: "XXJ",
    airport_name: "Deli Railway",
    country: "Hungary",
  },
  {
    sr: 2618,
    city: "Budapest",
    city_code: "BUD",
    airport_name: "Ferihegy",
    country: "Hungary",
  },
  {
    sr: 2620,
    city: "Budapest",
    city_code: "XXQ",
    airport_name: "Keleti Railway",
    country: "Hungary",
  },
  {
    sr: 2622,
    city: "Buenaventura",
    city_code: "BUN",
    airport_name: "Buenaventura",
    country: "Colombia",
  },
  {
    sr: 2624,
    city: "Buenos Aires",
    city_code: "AEP",
    airport_name: "Arpt Jorge Newbery",
    country: "Argentina",
  },
  {
    sr: 2626,
    city: "Buenos Aires",
    city_code: "EZE",
    airport_name: "Ezeiza Ministro Pistarini",
    country: "Argentina",
  },
  {
    sr: 2628,
    city: "Buenos Aires",
    city_code: "BAI",
    airport_name: "Buenos Aires",
    country: "Costa Rica",
  },
  {
    sr: 2630,
    city: "Buffalo",
    city_code: "ZFZ",
    airport_name: "Buffalo Depew Rr",
    country: "United States",
  },
  {
    sr: 2632,
    city: "Buffalo",
    city_code: "ZXS",
    airport_name: "ExchangeSt Railway Svc",
    country: "United States",
  },
  {
    sr: 2634,
    city: "Buffalo",
    city_code: "BYG",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 2636,
    city: "Buffalo Narrows",
    city_code: "YVT",
    airport_name: "Buffalo Narrows",
    country: "Canada",
  },
  {
    sr: 2638,
    city: "Buffalo Range",
    city_code: "BFO",
    airport_name: "Buffalo Range",
    country: "Zimbabwe",
  },
  {
    sr: 2640,
    city: "Bugulma",
    city_code: "UUA",
    airport_name: "Bugulma",
    country: "Russia",
  },
  {
    sr: 2642,
    city: "Buin",
    city_code: "UBI",
    airport_name: "Buin",
    country: "Papua New Guinea",
  },
  {
    sr: 2644,
    city: "Bujumbura",
    city_code: "BJM",
    airport_name: "International",
    country: "Burundi",
  },
  {
    sr: 2646,
    city: "Buka",
    city_code: "BUA",
    airport_name: "Buka",
    country: "Papua New Guinea",
  },
  {
    sr: 2648,
    city: "Bukavu",
    city_code: "BKY",
    airport_name: "Kamenbe",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 2652,
    city: "Bukhara",
    city_code: "BHK",
    airport_name: "Bukhara",
    country: "Uzbekistan",
  },
  {
    sr: 2654,
    city: "Bukoba",
    city_code: "BKZ",
    airport_name: "Bukoba",
    country: "Tanzania",
  },
  {
    sr: 2656,
    city: "Bulawayo",
    city_code: "BUQ",
    airport_name: "Bulawayo",
    country: "Zimbabwe",
  },
  {
    sr: 2658,
    city: "Bulchi",
    city_code: "BCY",
    airport_name: "Bulchi",
    country: "Ethiopia",
  },
  {
    sr: 2660,
    city: "Bulgan",
    city_code: "UGA",
    airport_name: "Bulgan",
    country: "Mongolia",
  },
  {
    sr: 2662,
    city: "Bulimba",
    city_code: "BIP",
    airport_name: "Bulimba",
    country: "Australia",
  },
  {
    sr: 2664,
    city: "Bull Harbour",
    city_code: "YBH",
    airport_name: "Bull Harbour",
    country: "Canada",
  },
  {
    sr: 2666,
    city: "Bullfrog Basin",
    city_code: "BFG",
    airport_name: "Bullfrog Basin",
    country: "United States",
  },
  {
    sr: 2668,
    city: "Bullhead City",
    city_code: "IFP",
    airport_name: "Laughlin Bullhead Intl",
    country: "United States",
  },
  {
    sr: 2670,
    city: "Bulolo",
    city_code: "BUL",
    airport_name: "Bulolo",
    country: "Papua New Guinea",
  },
  {
    sr: 2672,
    city: "Bumba",
    city_code: "BMB",
    airport_name: "Bumba",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 2674,
    city: "Bumi Hills",
    city_code: "BZH",
    airport_name: "Bumi Hills",
    country: "Zimbabwe",
  },
  {
    sr: 2676,
    city: "Bunbury",
    city_code: "BUY",
    airport_name: "Bunbury",
    country: "Australia",
  },
  {
    sr: 2678,
    city: "Bundaberg",
    city_code: "BDB",
    airport_name: "Bundaberg",
    country: "Australia",
  },
  {
    sr: 2680,
    city: "Bundi",
    city_code: "BNT",
    airport_name: "Bundi",
    country: "Papua New Guinea",
  },
  {
    sr: 2682,
    city: "Bunia",
    city_code: "BUX",
    airport_name: "Bunia",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 2686,
    city: "Buno Bedelle",
    city_code: "XBL",
    airport_name: "Buno Bedelle",
    country: "Ethiopia",
  },
  {
    sr: 2688,
    city: "Bunsil",
    city_code: "BXZ",
    airport_name: "Bunsil",
    country: "Papua New Guinea",
  },
  {
    sr: 2690,
    city: "Bunyu",
    city_code: "BYQ",
    airport_name: "Bunyu",
    country: "Indonesia",
  },
  {
    sr: 2692,
    city: "Buochs",
    city_code: "BXO",
    airport_name: "Buochs",
    country: "Guinea Bissau",
  },
  {
    sr: 2694,
    city: "Buol",
    city_code: "UOL",
    airport_name: "Buol",
    country: "Indonesia",
  },
  {
    sr: 2696,
    city: "Buraimi",
    city_code: "RMB",
    airport_name: "Buraimi",
    country: "Oman",
  },
  {
    sr: 2698,
    city: "Burao",
    city_code: "BUO",
    airport_name: "Burao",
    country: "Somalia",
  },
  {
    sr: 2700,
    city: "Burbank",
    city_code: "BUR",
    airport_name: "Bob Hope",
    country: "United States",
  },
  {
    sr: 2702,
    city: "Bureta",
    city_code: "LEV",
    airport_name: "Levuka Airfield",
    country: "Fiji",
  },
  {
    sr: 2704,
    city: "Burg Feuerstein",
    city_code: "URD",
    airport_name: "Burg Feuerstein",
    country: "Germany",
  },
  {
    sr: 2706,
    city: "Buri Ram",
    city_code: "BFV",
    airport_name: "Buri Ram",
    country: "Thailand",
  },
  {
    sr: 2708,
    city: "Burketown",
    city_code: "BUC",
    airport_name: "Burketown",
    country: "Australia",
  },
  {
    sr: 2710,
    city: "Burlington",
    city_code: "BRL",
    airport_name: "Burlington",
    country: "United States",
  },
  {
    sr: 2712,
    city: "Burlington",
    city_code: "BBF",
    airport_name: "Burlington",
    country: "United States",
  },
  {
    sr: 2714,
    city: "Burlington",
    city_code: "BTV",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 2716,
    city: "Burnie",
    city_code: "BWT",
    airport_name: "Burnie Wynyard",
    country: "Australia",
  },
  {
    sr: 2720,
    city: "Burns",
    city_code: "BNO",
    airport_name: "Burns",
    country: "United States",
  },
  {
    sr: 2722,
    city: "Burns Lake",
    city_code: "YPZ",
    airport_name: "Burns Lake",
    country: "Canada",
  },
  {
    sr: 2724,
    city: "Bursa",
    city_code: "BTZ",
    airport_name: "Bursa Airport",
    country: "Turkey",
  },
  {
    sr: 2726,
    city: "Bursa",
    city_code: "YEI",
    airport_name: "Yenisehir",
    country: "Canada",
  },
  {
    sr: 2728,
    city: "Burtonwood",
    city_code: "BUT",
    airport_name: "Burtonwood",
    country: "United Kingdom",
  },
  {
    sr: 2730,
    city: "Burundai",
    city_code: "BXJ",
    airport_name: "Burundai",
    country: "Kazakhstan",
  },
  {
    sr: 2732,
    city: "Burwash Landings",
    city_code: "YDB",
    airport_name: "Burwash Landings",
    country: "Canada",
  },
  {
    sr: 2734,
    city: "Burwell",
    city_code: "BUB",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 2736,
    city: "Bury St Edmunds",
    city_code: "BEQ",
    airport_name: "Honington",
    country: "United Kingdom",
  },
  {
    sr: 2738,
    city: "Busan",
    city_code: "PUS",
    airport_name: "Gimhae",
    country: "South Korea",
  },
  {
    sr: 2740,
    city: "Bushehr",
    city_code: "BUZ",
    airport_name: "Bushehr",
    country: "Iran",
  },
  {
    sr: 2742,
    city: "Bussellton",
    city_code: "BQB",
    airport_name: "Bussellton Airport",
    country: "Australia",
  },
  {
    sr: 2744,
    city: "Busuanga",
    city_code: "USU",
    airport_name: "Busuanga",
    country: "Philippines",
  },
  {
    sr: 2746,
    city: "Buta",
    city_code: "BZU",
    airport_name: "Buta",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 2748,
    city: "Butare",
    city_code: "BTQ",
    airport_name: "Butare",
    country: "Rwanda",
  },
  {
    sr: 2750,
    city: "Butaritari",
    city_code: "BBG",
    airport_name: "Butaritari",
    country: "Kiribati",
  },
  {
    sr: 2754,
    city: "Butler",
    city_code: "BUM",
    airport_name: "Butler",
    country: "United States",
  },
  {
    sr: 2756,
    city: "Butler",
    city_code: "BTP",
    airport_name: "Graham Field",
    country: "United States",
  },
  {
    sr: 2758,
    city: "Butte",
    city_code: "BTM",
    airport_name: "Butte",
    country: "United States",
  },
  {
    sr: 2760,
    city: "Butterworth",
    city_code: "BWH",
    airport_name: "Butterworth",
    country: "Malaysia",
  },
  {
    sr: 2762,
    city: "Butterworth",
    city_code: "UTE",
    airport_name: "Butterworth",
    country: "South Africa",
  },
  {
    sr: 2764,
    city: "Butuan",
    city_code: "BXU",
    airport_name: "Butuan",
    country: "Philippines",
  },
  {
    sr: 2766,
    city: "Buyo",
    city_code: "BUU",
    airport_name: "Buyo",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 2768,
    city: "Buzios",
    city_code: "BZC",
    airport_name: "Buzios",
    country: "Brazil",
  },
  {
    sr: 2770,
    city: "Bydgoszcz",
    city_code: "BZG",
    airport_name: "Bydgoszcz I J Paderewski Airport",
    country: "Poland",
  },
  {
    sr: 2772,
    city: "Ca Mau",
    city_code: "CAH",
    airport_name: "Ca Mau",
    country: "Vietnam",
  },
  {
    sr: 2774,
    city: "Cabimas",
    city_code: "CBS",
    airport_name: "Oro Negro",
    country: "Venezuela",
  },
  {
    sr: 2776,
    city: "Cabin Creek",
    city_code: "CBZ",
    airport_name: "Cabin Creek",
    country: "United States",
  },
  {
    sr: 2778,
    city: "Cabinda",
    city_code: "CAB",
    airport_name: "Cabinda",
    country: "Angola",
  },
  {
    sr: 2780,
    city: "Cabo Frio",
    city_code: "CFB",
    airport_name: "Cabo Frio",
    country: "Brazil",
  },
  {
    sr: 2782,
    city: "Cabo Rojo",
    city_code: "CBJ",
    airport_name: "Cabo Rojo",
    country: "Dominican Republic",
  },
  {
    sr: 2784,
    city: "Cacador",
    city_code: "CFC",
    airport_name: "Cacador",
    country: "Brazil",
  },
  {
    sr: 2788,
    city: "Caceres",
    city_code: "CCX",
    airport_name: "Caceres",
    country: "Brazil",
  },
  {
    sr: 2790,
    city: "Cachoeira",
    city_code: "CCQ",
    airport_name: "Cachoeira",
    country: "Brazil",
  },
  {
    sr: 2792,
    city: "Cachoeiro Itapemirim",
    city_code: "CDI",
    airport_name: "Cachoeiro Itapemirim",
    country: "Brazil",
  },
  {
    sr: 2794,
    city: "Cacoal",
    city_code: "OAL",
    airport_name: "Cacoal",
    country: "Brazil",
  },
  {
    sr: 2796,
    city: "Cadillac",
    city_code: "CAD",
    airport_name: "Cadillac",
    country: "United States",
  },
  {
    sr: 2798,
    city: "Cadiz",
    city_code: "CDZ",
    airport_name: "Cadiz",
    country: "Spain",
  },
  {
    sr: 2800,
    city: "Caen",
    city_code: "CFR",
    airport_name: "Carpiquet",
    country: "France",
  },
  {
    sr: 2802,
    city: "Cafunfo",
    city_code: "CFF",
    airport_name: "Cafunfo",
    country: "Angola",
  },
  {
    sr: 2804,
    city: "Cagayan De Oro",
    city_code: "CGY",
    airport_name: "Lumbia",
    country: "Philippines",
  },
  {
    sr: 2806,
    city: "Cagayan De Sulu",
    city_code: "CDY",
    airport_name: "Cagayan De Sulu",
    country: "Philippines",
  },
  {
    sr: 2808,
    city: "Cagliari",
    city_code: "CAG",
    airport_name: "Cagliari Elmas   Mario Mameli",
    country: "Italy",
  },
  {
    sr: 2810,
    city: "Caherciveen",
    city_code: "CHE",
    airport_name: "Reenroe",
    country: "Ireland",
  },
  {
    sr: 2812,
    city: "Cahors",
    city_code: "ZAO",
    airport_name: "Laberandie",
    country: "France",
  },
  {
    sr: 2814,
    city: "Caia",
    city_code: "CMZ",
    airport_name: "Caia",
    country: "Mozambique",
  },
  {
    sr: 2816,
    city: "Caicara De Oro",
    city_code: "CXA",
    airport_name: "Caicara De Oro",
    country: "Venezuela",
  },
  {
    sr: 2818,
    city: "Caiguna",
    city_code: "CGV",
    airport_name: "Caiguna",
    country: "Australia",
  },
  {
    sr: 2822,
    city: "Cairns",
    city_code: "CNS",
    airport_name: "Cairns",
    country: "Australia",
  },
  {
    sr: 2824,
    city: "Cairo",
    city_code: "CAI",
    airport_name: "International",
    country: "Egypt",
  },
  {
    sr: 2826,
    city: "Cairo",
    city_code: "CIR",
    airport_name: "Cairo",
    country: "United States",
  },
  {
    sr: 2828,
    city: "Cajamarca",
    city_code: "CJA",
    airport_name: "Cajamarca",
    country: "Peru",
  },
  {
    sr: 2830,
    city: "Calabar",
    city_code: "CBQ",
    airport_name: "Calabar",
    country: "Nigeria",
  },
  {
    sr: 2832,
    city: "Calabozo",
    city_code: "CLZ",
    airport_name: "Calabozo",
    country: "Venezuela",
  },
  {
    sr: 2834,
    city: "Calais",
    city_code: "CQF",
    airport_name: "Calais",
    country: "France",
  },
  {
    sr: 2836,
    city: "Calama",
    city_code: "CJC",
    airport_name: "El Loa",
    country: "Chile",
  },
  {
    sr: 2838,
    city: "Calbayog",
    city_code: "CYP",
    airport_name: "Calbayog",
    country: "Philippines",
  },
  {
    sr: 2840,
    city: "Caldas Novas",
    city_code: "CLV",
    airport_name: "Caldas Novas",
    country: "Brazil",
  },
  {
    sr: 2842,
    city: "Caldwell",
    city_code: "CDW",
    airport_name: "Caldwell Wright",
    country: "United States",
  },
  {
    sr: 2844,
    city: "Caledonia",
    city_code: "CDE",
    airport_name: "Caledonia",
    country: "Panama",
  },
  {
    sr: 2846,
    city: "Caleta Olivia",
    city_code: "CVI",
    airport_name: "Caleta Olivia",
    country: "Argentina",
  },
  {
    sr: 2848,
    city: "Calexico",
    city_code: "CXL",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 2850,
    city: "Calgary",
    city_code: "YYC",
    airport_name: "Calgary International Airport",
    country: "Canada",
  },
  {
    sr: 2852,
    city: "Cali",
    city_code: "CLO",
    airport_name: "Alfonso B Aragon",
    country: "Colombia",
  },
  {
    sr: 2856,
    city: "Calipatria",
    city_code: "CLR",
    airport_name: "Calipatria",
    country: "United States",
  },
  {
    sr: 2858,
    city: "Callaway Gardens",
    city_code: "CWG",
    airport_name: "Callaway Gardens",
    country: "United States",
  },
  {
    sr: 2860,
    city: "Caloundra",
    city_code: "CUD",
    airport_name: "Caloundra",
    country: "Australia",
  },
  {
    sr: 2862,
    city: "Calverton",
    city_code: "CTO",
    airport_name: "Peconic River",
    country: "United States",
  },
  {
    sr: 2864,
    city: "Calvi",
    city_code: "CLY",
    airport_name: "Ste Catherine",
    country: "France",
  },
  {
    sr: 2866,
    city: "Camaguey",
    city_code: "CMW",
    airport_name: "Ign Agramonte Intl",
    country: "Cuba",
  },
  {
    sr: 2868,
    city: "Cambridge",
    city_code: "CBG",
    airport_name: "Cambridge",
    country: "United Kingdom",
  },
  {
    sr: 2870,
    city: "Cambridge",
    city_code: "JHY",
    airport_name: "Hyatt Regency H P",
    country: "United States",
  },
  {
    sr: 2872,
    city: "Cambridge",
    city_code: "CGE",
    airport_name: "Cambridge",
    country: "United States",
  },
  {
    sr: 2874,
    city: "Cambridge Bay",
    city_code: "YCB",
    airport_name: "Cambridge Bay",
    country: "Canada",
  },
  {
    sr: 2876,
    city: "Camden",
    city_code: "CDH",
    airport_name: "Harrell Fld",
    country: "United States",
  },
  {
    sr: 2878,
    city: "Camden",
    city_code: "CDU",
    airport_name: "Camden",
    country: "Australia",
  },
  {
    sr: 2880,
    city: "Camden",
    city_code: "CDN",
    airport_name: "Woodward Fld",
    country: "United States",
  },
  {
    sr: 2882,
    city: "Cameta",
    city_code: "CMT",
    airport_name: "Cameta",
    country: "Brazil",
  },
  {
    sr: 2884,
    city: "Camfield",
    city_code: "CFI",
    airport_name: "Camfield",
    country: "Australia",
  },
  {
    sr: 2886,
    city: "Camiguin",
    city_code: "CGM",
    airport_name: "Mambajao",
    country: "Philippines",
  },
  {
    sr: 2890,
    city: "Camiri",
    city_code: "CAM",
    airport_name: "Camiri",
    country: "Bolivia",
  },
  {
    sr: 2892,
    city: "Camocim",
    city_code: "CMC",
    airport_name: "Camocim",
    country: "Brazil",
  },
  {
    sr: 2894,
    city: "Camooweal",
    city_code: "CML",
    airport_name: "Camooweal",
    country: "Australia",
  },
  {
    sr: 2896,
    city: "Camp Douglas",
    city_code: "VOK",
    airport_name: "Volk Field",
    country: "United States",
  },
  {
    sr: 2898,
    city: "Camp Springs",
    city_code: "ADW",
    airport_name: "Andrews AFB",
    country: "United States",
  },
  {
    sr: 2900,
    city: "Camp Springs",
    city_code: "NSF",
    airport_name: "Andrews NAF",
    country: "United States",
  },
  {
    sr: 2902,
    city: "Campbell River",
    city_code: "YBL",
    airport_name: "Campbell River",
    country: "Canada",
  },
  {
    sr: 2904,
    city: "Campbell River",
    city_code: "YHH",
    airport_name: "Harbor SPB",
    country: "Canada",
  },
  {
    sr: 2906,
    city: "Campbellpore",
    city_code: "CWP",
    airport_name: "Campbellpore",
    country: "Pakistan",
  },
  {
    sr: 2908,
    city: "Campbeltown",
    city_code: "CAL",
    airport_name: "Machrihanish",
    country: "United Kingdom",
  },
  {
    sr: 2910,
    city: "Campeche",
    city_code: "CPE",
    airport_name: "Campeche International",
    country: "Mexico",
  },
  {
    sr: 2912,
    city: "Campina Grande",
    city_code: "CPV",
    airport_name: "Joao Suassuna",
    country: "Brazil",
  },
  {
    sr: 2914,
    city: "Campinas",
    city_code: "CPQ",
    airport_name: "International",
    country: "Brazil",
  },
  {
    sr: 2916,
    city: "Campo",
    city_code: "CZZ",
    airport_name: "Campo",
    country: "United States",
  },
  {
    sr: 2918,
    city: "Campo Grande",
    city_code: "CGR",
    airport_name: "Internacional",
    country: "Brazil",
  },
  {
    sr: 2920,
    city: "Campo Mourao",
    city_code: "CBW",
    airport_name: "Campo Mourao",
    country: "Brazil",
  },
  {
    sr: 2924,
    city: "Campos",
    city_code: "CAW",
    airport_name: "Bartolomeu Lisandro",
    country: "Brazil",
  },
  {
    sr: 2926,
    city: "Can Tho",
    city_code: "VCA",
    airport_name: "Can Tho",
    country: "Vietnam",
  },
  {
    sr: 2928,
    city: "Cana Brava",
    city_code: "NBV",
    airport_name: "Cana Brava",
    country: "Brazil",
  },
  {
    sr: 2930,
    city: "Canaima",
    city_code: "CAJ",
    airport_name: "Canaima",
    country: "Venezuela",
  },
  {
    sr: 2932,
    city: "Canakkale",
    city_code: "CKZ",
    airport_name: "Canakkale",
    country: "Turkey",
  },
  {
    sr: 2934,
    city: "Cananea",
    city_code: "CNA",
    airport_name: "Cananea",
    country: "Mexico",
  },
  {
    sr: 2936,
    city: "Canarana",
    city_code: "CQA",
    airport_name: "Canarana Airport",
    country: "Brazil",
  },
  {
    sr: 2938,
    city: "Canas",
    city_code: "CSC",
    airport_name: "Canas",
    country: "Costa Rica",
  },
  {
    sr: 2940,
    city: "Canavieiras",
    city_code: "CNV",
    airport_name: "Canavieiras",
    country: "Brazil",
  },
  {
    sr: 2942,
    city: "Canberra",
    city_code: "CBR",
    airport_name: "Canberra",
    country: "Australia",
  },
  {
    sr: 2944,
    city: "Cancun",
    city_code: "CUN",
    airport_name: "Cancun",
    country: "Mexico",
  },
  {
    sr: 2946,
    city: "Candala",
    city_code: "CXN",
    airport_name: "Candala",
    country: "Somalia",
  },
  {
    sr: 2948,
    city: "Candilejas",
    city_code: "CJD",
    airport_name: "Candilejas",
    country: "Colombia",
  },
  {
    sr: 2950,
    city: "Candle",
    city_code: "CDL",
    airport_name: "Candle",
    country: "United States",
  },
  {
    sr: 2952,
    city: "Cangamba",
    city_code: "CNZ",
    airport_name: "Cangamba",
    country: "Angola",
  },
  {
    sr: 2954,
    city: "Cannes",
    city_code: "JCA",
    airport_name: "Croisette Heliport",
    country: "France",
  },
  {
    sr: 2958,
    city: "Cannes",
    city_code: "CEQ",
    airport_name: "Mandelieu",
    country: "France",
  },
  {
    sr: 2960,
    city: "Cannes",
    city_code: "QYW",
    airport_name: "Vieux Port",
    country: "France",
  },
  {
    sr: 2962,
    city: "Canobie",
    city_code: "CBY",
    airport_name: "Canobie",
    country: "Australia",
  },
  {
    sr: 2964,
    city: "Canon City",
    city_code: "CNE",
    airport_name: "Canon City",
    country: "United States",
  },
  {
    sr: 2966,
    city: "Canouan Island",
    city_code: "CIW",
    airport_name: "Canouan Island",
    country: "Saint Vincent and the Grenadines",
  },
  {
    sr: 2968,
    city: "Canton",
    city_code: "CTK",
    airport_name: "Canton",
    country: "United States",
  },
  {
    sr: 2970,
    city: "Canton Island",
    city_code: "CIS",
    airport_name: "Canton Island",
    country: "Kiribati",
  },
  {
    sr: 2972,
    city: "Cap Haitien",
    city_code: "CAP",
    airport_name: "Cap Haitien",
    country: "Haiti",
  },
  {
    sr: 2974,
    city: "Cap Skirring",
    city_code: "CSK",
    airport_name: "Cap Skirring",
    country: "Senegal",
  },
  {
    sr: 2976,
    city: "Capanda",
    city_code: "KNP",
    airport_name: "Capanda",
    country: "Angola",
  },
  {
    sr: 2978,
    city: "Cape Barren Island",
    city_code: "CBI",
    airport_name: "Cape Barren Island",
    country: "Australia",
  },
  {
    sr: 2980,
    city: "Cape Dorset",
    city_code: "YTE",
    airport_name: "Cape Dorset",
    country: "Canada",
  },
  {
    sr: 2982,
    city: "Cape Eleuthera",
    city_code: "CEL",
    airport_name: "Cape Eleuthera",
    country: "Bahamas",
  },
  {
    sr: 2984,
    city: "Cape Flattery",
    city_code: "CQP",
    airport_name: "Cape Flattery",
    country: "Australia",
  },
  {
    sr: 2986,
    city: "Cape Girardeau",
    city_code: "CGI",
    airport_name: "Cape Girardeau",
    country: "United States",
  },
  {
    sr: 2988,
    city: "Cape Gloucester",
    city_code: "CGC",
    airport_name: "Cape Gloucester",
    country: "Papua New Guinea",
  },
  {
    sr: 2992,
    city: "Cape Lisburne",
    city_code: "LUR",
    airport_name: "Cape Lisburne",
    country: "United States",
  },
  {
    sr: 2994,
    city: "Cape Newenham",
    city_code: "EHM",
    airport_name: "Cape Newenham",
    country: "United States",
  },
  {
    sr: 2996,
    city: "Cape Orford",
    city_code: "CPI",
    airport_name: "Cape Orford",
    country: "Papua New Guinea",
  },
  {
    sr: 2998,
    city: "Cape Palmas",
    city_code: "CPA",
    airport_name: "A Tubman",
    country: "Liberia",
  },
  {
    sr: 3000,
    city: "Cape Pole",
    city_code: "CZP",
    airport_name: "Cape Pole",
    country: "United States",
  },
  {
    sr: 3002,
    city: "Cape Rodney",
    city_code: "CPN",
    airport_name: "Cape Rodney",
    country: "Papua New Guinea",
  },
  {
    sr: 3004,
    city: "Cape Romanzof",
    city_code: "CZF",
    airport_name: "Cape Romanzof",
    country: "United States",
  },
  {
    sr: 3006,
    city: "Cape Spencer",
    city_code: "CSP",
    airport_name: "Coast Guard Heliport",
    country: "United States",
  },
  {
    sr: 3008,
    city: "Cape St Jacques",
    city_code: "CSJ",
    airport_name: "Cape St Jacques",
    country: "Vietnam",
  },
  {
    sr: 3010,
    city: "Cape St James",
    city_code: "YCJ",
    airport_name: "Cape St James",
    country: "Canada",
  },
  {
    sr: 3012,
    city: "Cape Town",
    city_code: "CPT",
    airport_name: "Cape Town International",
    country: "South Africa",
  },
  {
    sr: 3014,
    city: "Cape Vogel",
    city_code: "CVL",
    airport_name: "Cape Vogel",
    country: "Papua New Guinea",
  },
  {
    sr: 3016,
    city: "Capri",
    city_code: "PRJ",
    airport_name: "Capri",
    country: "Italy",
  },
  {
    sr: 3018,
    city: "Caprivi",
    city_code: "LHU",
    airport_name: "Lianshulu",
    country: "Namibia",
  },
  {
    sr: 3020,
    city: "Capurgana",
    city_code: "CPB",
    airport_name: "Capurgana",
    country: "Colombia",
  },
  {
    sr: 3022,
    city: "Caquetania",
    city_code: "CQT",
    airport_name: "Caquetania",
    country: "Colombia",
  },
  {
    sr: 3026,
    city: "Car Nicobar",
    city_code: "CBD",
    airport_name: "Car Nicobar",
    country: "India",
  },
  {
    sr: 3028,
    city: "Caracas",
    city_code: "CCS",
    airport_name: "Maiquetia",
    country: "Venezuela",
  },
  {
    sr: 3030,
    city: "Carajas",
    city_code: "CKS",
    airport_name: "Carajas",
    country: "Brazil",
  },
  {
    sr: 3032,
    city: "Caransebes",
    city_code: "CSB",
    airport_name: "Caransebes",
    country: "Romania",
  },
  {
    sr: 3034,
    city: "Carauari",
    city_code: "CAF",
    airport_name: "Carauari",
    country: "Brazil",
  },
  {
    sr: 3036,
    city: "Caravelas",
    city_code: "CRQ",
    airport_name: "Caravelas",
    country: "Brazil",
  },
  {
    sr: 3038,
    city: "Carbondale",
    city_code: "MDH",
    airport_name: "Southern Illinois",
    country: "United States",
  },
  {
    sr: 3040,
    city: "Carcassonne",
    city_code: "CCF",
    airport_name: "Salvaza",
    country: "France",
  },
  {
    sr: 3042,
    city: "Cardiff",
    city_code: "CWL",
    airport_name: "Cardiff wales Arpt",
    country: "United Kingdom",
  },
  {
    sr: 3044,
    city: "Caribou",
    city_code: "CAR",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 3046,
    city: "Caribou Island",
    city_code: "YCI",
    airport_name: "Caribou Island",
    country: "Canada",
  },
  {
    sr: 3048,
    city: "Carimagua",
    city_code: "CCO",
    airport_name: "Carimagua",
    country: "Colombia",
  },
  {
    sr: 3050,
    city: "Carlisle",
    city_code: "CAX",
    airport_name: "Carlisle",
    country: "United Kingdom",
  },
  {
    sr: 3052,
    city: "Carlsbad",
    city_code: "CNM",
    airport_name: "Carlsbad",
    country: "United States",
  },
  {
    sr: 3054,
    city: "Carlton Hill",
    city_code: "CRY",
    airport_name: "Carlton Hill",
    country: "Australia",
  },
  {
    sr: 3056,
    city: "Carmelita",
    city_code: "CMM",
    airport_name: "Carmelita",
    country: "Guatemala",
  },
  {
    sr: 3060,
    city: "Carmen De Patagones",
    city_code: "CPG",
    airport_name: "Carmen De Patagones",
    country: "Argentina",
  },
  {
    sr: 3062,
    city: "Carnarvon",
    city_code: "CVQ",
    airport_name: "Carnarvon",
    country: "Australia",
  },
  {
    sr: 3064,
    city: "Carnot",
    city_code: "CRF",
    airport_name: "Carnot",
    country: "Central African Republic",
  },
  {
    sr: 3066,
    city: "Carolina",
    city_code: "CLN",
    airport_name: "Carolina",
    country: "Brazil",
  },
  {
    sr: 3068,
    city: "Carora",
    city_code: "VCR",
    airport_name: "Carora",
    country: "Venezuela",
  },
  {
    sr: 3070,
    city: "Carpentaria Downs",
    city_code: "CFP",
    airport_name: "Carpentaria Downs",
    country: "Australia",
  },
  {
    sr: 3072,
    city: "Carriacou Is",
    city_code: "CRU",
    airport_name: "Carriacou Is",
    country: "Grenada",
  },
  {
    sr: 3074,
    city: "Carrillo",
    city_code: "RIK",
    airport_name: "Carrillo",
    country: "Costa Rica",
  },
  {
    sr: 3076,
    city: "Carrizo Springs",
    city_code: "CZT",
    airport_name: "Carrizo Springs",
    country: "United States",
  },
  {
    sr: 3078,
    city: "Carroll",
    city_code: "CIN",
    airport_name: "Carroll",
    country: "United States",
  },
  {
    sr: 3080,
    city: "Carson City",
    city_code: "CSN",
    airport_name: "Carson City",
    country: "United States",
  },
  {
    sr: 3082,
    city: "Cartagena",
    city_code: "CTG",
    airport_name: "Rafael Nunez",
    country: "Colombia",
  },
  {
    sr: 3084,
    city: "Cartago",
    city_code: "CRC",
    airport_name: "Cartago",
    country: "Colombia",
  },
  {
    sr: 3086,
    city: "Carti",
    city_code: "CTE",
    airport_name: "Carti",
    country: "Panama",
  },
  {
    sr: 3088,
    city: "Cartierville",
    city_code: "YCV",
    airport_name: "Cartierville",
    country: "Canada",
  },
  {
    sr: 3090,
    city: "Cartwright",
    city_code: "YRF",
    airport_name: "Cartwright",
    country: "Canada",
  },
  {
    sr: 3094,
    city: "Caruaru",
    city_code: "CAU",
    airport_name: "Caruaru",
    country: "Brazil",
  },
  {
    sr: 3096,
    city: "Carupano",
    city_code: "CUP",
    airport_name: "Carupano",
    country: "Venezuela",
  },
  {
    sr: 3098,
    city: "Caruru",
    city_code: "CUO",
    airport_name: "Caruru",
    country: "Colombia",
  },
  {
    sr: 3100,
    city: "Carutapera",
    city_code: "CTP",
    airport_name: "Carutapera",
    country: "Brazil",
  },
  {
    sr: 3102,
    city: "Casa Grande",
    city_code: "CGZ",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 3104,
    city: "Casablanca",
    city_code: "CAS",
    airport_name: "Anfa",
    country: "Morocco",
  },
  {
    sr: 3106,
    city: "Casablanca",
    city_code: "CMN",
    airport_name: "Mohamed V",
    country: "Morocco",
  },
  {
    sr: 3108,
    city: "Cascade Locks",
    city_code: "CZK",
    airport_name: "Cascade Locks Stevens",
    country: "United States",
  },
  {
    sr: 3110,
    city: "Cascavel",
    city_code: "CAC",
    airport_name: "Cascavel",
    country: "Brazil",
  },
  {
    sr: 3112,
    city: "Casigua",
    city_code: "CUV",
    airport_name: "Casigua",
    country: "Venezuela",
  },
  {
    sr: 3114,
    city: "Casiguran",
    city_code: "CGG",
    airport_name: "Casiguran",
    country: "Philippines",
  },
  {
    sr: 3116,
    city: "Casino",
    city_code: "CSI",
    airport_name: "Casino",
    country: "Australia",
  },
  {
    sr: 3118,
    city: "Casper",
    city_code: "CPR",
    airport_name: "Casper",
    country: "United States",
  },
  {
    sr: 3120,
    city: "Cassilandia",
    city_code: "CSS",
    airport_name: "Cassilandia",
    country: "Brazil",
  },
  {
    sr: 3122,
    city: "Castaway",
    city_code: "CST",
    airport_name: "Castaway",
    country: "Fiji",
  },
  {
    sr: 3124,
    city: "Castlebar",
    city_code: "CLB",
    airport_name: "Castlebar",
    country: "Ireland",
  },
  {
    sr: 3128,
    city: "Castlegar",
    city_code: "YCG",
    airport_name: "Castlegar",
    country: "Canada",
  },
  {
    sr: 3130,
    city: "Castres",
    city_code: "DCM",
    airport_name: "Mazamet",
    country: "France",
  },
  {
    sr: 3132,
    city: "Castro",
    city_code: "WCA",
    airport_name: "Gamboa",
    country: "Chile",
  },
  {
    sr: 3134,
    city: "Casuarito",
    city_code: "CSR",
    airport_name: "Casuarito",
    country: "Colombia",
  },
  {
    sr: 3136,
    city: "Cat Cays",
    city_code: "CXY",
    airport_name: "Cat Cays",
    country: "Bahamas",
  },
  {
    sr: 3138,
    city: "Cat Island",
    city_code: "CAT",
    airport_name: "Cat Island",
    country: "Bahamas",
  },
  {
    sr: 3140,
    city: "Cat Lake",
    city_code: "YAC",
    airport_name: "Cat Lake",
    country: "Canada",
  },
  {
    sr: 3142,
    city: "Catacamas",
    city_code: "CAA",
    airport_name: "Catacamas",
    country: "Honduras",
  },
  {
    sr: 3144,
    city: "Catalao",
    city_code: "TLZ",
    airport_name: "Catalao",
    country: "Brazil",
  },
  {
    sr: 3146,
    city: "Catalina Island",
    city_code: "CIB",
    airport_name: "Ap In The Sky",
    country: "United States",
  },
  {
    sr: 3148,
    city: "Catalina Island",
    city_code: "SXC",
    airport_name: "Avalo Vor WP",
    country: "United States",
  },
  {
    sr: 3150,
    city: "Catalina Island",
    city_code: "AVX",
    airport_name: "Avalon Bay",
    country: "United States",
  },
  {
    sr: 3152,
    city: "Catalina Island",
    city_code: "TWH",
    airport_name: "Two Harbors",
    country: "United States",
  },
  {
    sr: 3154,
    city: "Catamarca",
    city_code: "CTC",
    airport_name: "Catamarca",
    country: "Argentina",
  },
  {
    sr: 3156,
    city: "Catania",
    city_code: "CTA",
    airport_name: "Fontanarossa",
    country: "Italy",
  },
  {
    sr: 3158,
    city: "Catarman",
    city_code: "CRM",
    airport_name: "National",
    country: "Philippines",
  },
  {
    sr: 3162,
    city: "Caticlan",
    city_code: "MPH",
    airport_name: "Malay",
    country: "Philippines",
  },
  {
    sr: 3164,
    city: "Cattle Creek",
    city_code: "CTR",
    airport_name: "Cattle Creek",
    country: "Australia",
  },
  {
    sr: 3166,
    city: "Catumbela",
    city_code: "CBT",
    airport_name: "Catumbela",
    country: "Angola",
  },
  {
    sr: 3168,
    city: "Cauayan",
    city_code: "CYZ",
    airport_name: "Cauayan",
    country: "Philippines",
  },
  {
    sr: 3170,
    city: "Caucasia",
    city_code: "CAQ",
    airport_name: "Caucasia",
    country: "Colombia",
  },
  {
    sr: 3172,
    city: "Cauquira",
    city_code: "CDD",
    airport_name: "Cauquira",
    country: "Honduras",
  },
  {
    sr: 3174,
    city: "Caviahue",
    city_code: "CVH",
    airport_name: "Caviahue",
    country: "Argentina",
  },
  {
    sr: 3176,
    city: "Caxias Do Sul",
    city_code: "CXJ",
    airport_name: "Campo Dos Bugres",
    country: "Brazil",
  },
  {
    sr: 3178,
    city: "Caye Caulker",
    city_code: "CUK",
    airport_name: "Caye Caulker",
    country: "Belize",
  },
  {
    sr: 3180,
    city: "Caye Chapel",
    city_code: "CYC",
    airport_name: "Caye Chapel",
    country: "Belize",
  },
  {
    sr: 3182,
    city: "Cayenne",
    city_code: "CAY",
    airport_name: "Rochambeau",
    country: "French Guiana",
  },
  {
    sr: 3184,
    city: "Cayman Brac Is",
    city_code: "CYB",
    airport_name: "Gerrard Smith",
    country: "Cayman Islands",
  },
  {
    sr: 3186,
    city: "Cayo Coco",
    city_code: "CCC",
    airport_name: "Cayo Coco",
    country: "Cuba",
  },
  {
    sr: 3188,
    city: "Cayo Largo Del Sur",
    city_code: "CYO",
    airport_name: "Cayo Largo Del Sur",
    country: "Cuba",
  },
  {
    sr: 3190,
    city: "Cazombo",
    city_code: "CAV",
    airport_name: "Cazombo",
    country: "Angola",
  },
  {
    sr: 3192,
    city: "Cebu",
    city_code: "CEB",
    airport_name: "Mactan International",
    country: "Philippines",
  },
  {
    sr: 3196,
    city: "Cedar City",
    city_code: "CDC",
    airport_name: "Cedar City",
    country: "United States",
  },
  {
    sr: 3198,
    city: "Cedar Key",
    city_code: "CDK",
    airport_name: "Lewis",
    country: "United States",
  },
  {
    sr: 3200,
    city: "Cedar Rapids",
    city_code: "CID",
    airport_name: "Cedar Rapids",
    country: "United States",
  },
  {
    sr: 3202,
    city: "Ceduna",
    city_code: "CED",
    airport_name: "Ceduna",
    country: "Australia",
  },
  {
    sr: 3204,
    city: "Center Island",
    city_code: "CWS",
    airport_name: "Center Island",
    country: "United States",
  },
  {
    sr: 3206,
    city: "Centerville",
    city_code: "GHM",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 3208,
    city: "Central",
    city_code: "CEM",
    airport_name: "Central",
    country: "United States",
  },
  {
    sr: 3210,
    city: "Centralia",
    city_code: "ENL",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 3212,
    city: "Centralia",
    city_code: "YCE",
    airport_name: "Centralia",
    country: "Canada",
  },
  {
    sr: 3214,
    city: "Cepu",
    city_code: "CPF",
    airport_name: "Cepu",
    country: "Indonesia",
  },
  {
    sr: 3216,
    city: "Ceres",
    city_code: "CRR",
    airport_name: "Ceres",
    country: "Argentina",
  },
  {
    sr: 3218,
    city: "Cerro Sombrero",
    city_code: "SMB",
    airport_name: "Cerro Sombrero",
    country: "Chile",
  },
  {
    sr: 3220,
    city: "Cessnock",
    city_code: "CES",
    airport_name: "Cessnock",
    country: "Australia",
  },
  {
    sr: 3222,
    city: "Ceuta",
    city_code: "JCU",
    airport_name: "Ceuta Heliport",
    country: "Spain",
  },
  {
    sr: 3224,
    city: "Chachapoyas",
    city_code: "CHH",
    airport_name: "Chachapoyas",
    country: "Peru",
  },
  {
    sr: 3226,
    city: "Chadron",
    city_code: "CDR",
    airport_name: "Chadron",
    country: "United States",
  },
  {
    sr: 3230,
    city: "Chagni",
    city_code: "MKD",
    airport_name: "Chagni",
    country: "Ethiopia",
  },
  {
    sr: 3232,
    city: "Chah Bahar",
    city_code: "ZBR",
    airport_name: "Chah Bahar",
    country: "Iran",
  },
  {
    sr: 3234,
    city: "Chaiten",
    city_code: "WCH",
    airport_name: "Chaiten",
    country: "Chile",
  },
  {
    sr: 3236,
    city: "Chakcharan",
    city_code: "CCN",
    airport_name: "Chakcharan",
    country: "Afghanistan",
  },
  {
    sr: 3238,
    city: "Chalkyitsik",
    city_code: "CIK",
    airport_name: "Chalkyitsik",
    country: "United States",
  },
  {
    sr: 3240,
    city: "Challis",
    city_code: "CHL",
    airport_name: "Challis",
    country: "United States",
  },
  {
    sr: 3242,
    city: "Chalons Sur Marne",
    city_code: "XCR",
    airport_name: "Vatry Airport",
    country: "France",
  },
  {
    sr: 3244,
    city: "Chambery",
    city_code: "CMF",
    airport_name: "Chambery",
    country: "France",
  },
  {
    sr: 3246,
    city: "Champaign",
    city_code: "CMI",
    airport_name: "University Of Illinois Willard",
    country: "United States",
  },
  {
    sr: 3248,
    city: "Chanaral",
    city_code: "CNR",
    airport_name: "Chanaral",
    country: "Chile",
  },
  {
    sr: 3250,
    city: "Chandalar",
    city_code: "WCR",
    airport_name: "Chandalar",
    country: "United States",
  },
  {
    sr: 3252,
    city: "Chandigarh",
    city_code: "IXC",
    airport_name: "Chandigarh",
    country: "India",
  },
  {
    sr: 3254,
    city: "Chandler",
    city_code: "SLJ",
    airport_name: "Stellar Air Park",
    country: "United States",
  },
  {
    sr: 3256,
    city: "Chandler",
    city_code: "CHD",
    airport_name: "Williams AFB",
    country: "United States",
  },
  {
    sr: 3258,
    city: "Changchun",
    city_code: "CGQ",
    airport_name: "Changchun",
    country: "China",
  },
  {
    sr: 3260,
    city: "Changde",
    city_code: "CGD",
    airport_name: "Changde",
    country: "China",
  },
  {
    sr: 3264,
    city: "Changhai",
    city_code: "CNI",
    airport_name: "Changhai",
    country: "China",
  },
  {
    sr: 3266,
    city: "Changsha",
    city_code: "CSX",
    airport_name: "Changsha",
    country: "China",
  },
  {
    sr: 3268,
    city: "Changuinola",
    city_code: "CHX",
    airport_name: "Changuinola",
    country: "Panama",
  },
  {
    sr: 3270,
    city: "Changzhi",
    city_code: "CIH",
    airport_name: "Changzhi",
    country: "China",
  },
  {
    sr: 3272,
    city: "Changzhou",
    city_code: "CZX",
    airport_name: "Changzhou",
    country: "China",
  },
  {
    sr: 3274,
    city: "Chania",
    city_code: "CHQ",
    airport_name: "Souda",
    country: "Greece",
  },
  {
    sr: 3276,
    city: "Chanute",
    city_code: "CNU",
    airport_name: "Martin Johnson",
    country: "United States",
  },
  {
    sr: 3278,
    city: "Chaoyang",
    city_code: "CHG",
    airport_name: "Chaoyang Airport",
    country: "China",
  },
  {
    sr: 3280,
    city: "Chaparral",
    city_code: "CPL",
    airport_name: "Chaparral",
    country: "Colombia",
  },
  {
    sr: 3282,
    city: "Chapeco",
    city_code: "XAP",
    airport_name: "Chapeco",
    country: "Brazil",
  },
  {
    sr: 3284,
    city: "Chapleau",
    city_code: "YLD",
    airport_name: "Chapleau",
    country: "Canada",
  },
  {
    sr: 3286,
    city: "Charata",
    city_code: "CNT",
    airport_name: "Charata",
    country: "Argentina",
  },
  {
    sr: 3288,
    city: "Charles City",
    city_code: "CCY",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 3290,
    city: "Charleston",
    city_code: "CHS",
    airport_name: "AFB Municipal",
    country: "United States",
  },
  {
    sr: 3292,
    city: "Charleston",
    city_code: "CRW",
    airport_name: "Yeager",
    country: "United States",
  },
  {
    sr: 3294,
    city: "Charleville",
    city_code: "CTL",
    airport_name: "Charleville",
    country: "Australia",
  },
  {
    sr: 3298,
    city: "Charlo",
    city_code: "YCL",
    airport_name: "Charlo",
    country: "Canada",
  },
  {
    sr: 3300,
    city: "Charlotte",
    city_code: "CLT",
    airport_name: "Douglas",
    country: "United States",
  },
  {
    sr: 3302,
    city: "Charlotte",
    city_code: "QWG",
    airport_name: "Wilgrove Air Park",
    country: "United States",
  },
  {
    sr: 3304,
    city: "Charlottesville",
    city_code: "CHO",
    airport_name: "Albemarle",
    country: "United States",
  },
  {
    sr: 3306,
    city: "Charlottetown",
    city_code: "YHG",
    airport_name: "Charlottetown",
    country: "Canada",
  },
  {
    sr: 3308,
    city: "Charlottetown",
    city_code: "YYG",
    airport_name: "Charlottetown",
    country: "Canada",
  },
  {
    sr: 3310,
    city: "Charters Towers",
    city_code: "CXT",
    airport_name: "Charters Towers",
    country: "Australia",
  },
  {
    sr: 3312,
    city: "Chateauroux",
    city_code: "CHR",
    airport_name: "Chateauroux",
    country: "France",
  },
  {
    sr: 3314,
    city: "Chatham",
    city_code: "CYM",
    airport_name: "Chatham SPB",
    country: "United States",
  },
  {
    sr: 3316,
    city: "Chatham",
    city_code: "XCM",
    airport_name: "Chatham",
    country: "Canada",
  },
  {
    sr: 3318,
    city: "Chatham Island",
    city_code: "CHT",
    airport_name: "Karewa",
    country: "New Zealand",
  },
  {
    sr: 3320,
    city: "Chattanooga",
    city_code: "CHA",
    airport_name: "Lovell Field",
    country: "United States",
  },
  {
    sr: 3322,
    city: "Chaurjhari",
    city_code: "HRJ",
    airport_name: "Chaurjhari",
    country: "Nepal",
  },
  {
    sr: 3324,
    city: "Chaves",
    city_code: "CHV",
    airport_name: "Chaves",
    country: "Portugal",
  },
  {
    sr: 3326,
    city: "Cheboksary",
    city_code: "CSY",
    airport_name: "Cheboksary",
    country: "Russia",
  },
  {
    sr: 3328,
    city: "Chefornak",
    city_code: "CYF",
    airport_name: "Chefornak SPB",
    country: "United States",
  },
  {
    sr: 3332,
    city: "Chehalis",
    city_code: "CLS",
    airport_name: "Centralia",
    country: "United States",
  },
  {
    sr: 3334,
    city: "Chek Lap Kok",
    city_code: "ZJK",
    airport_name: "Off Line Point",
    country: "China",
  },
  {
    sr: 3336,
    city: "Chelinda",
    city_code: "CEH",
    airport_name: "Chelinda",
    country: "Malawi",
  },
  {
    sr: 3338,
    city: "Chelyabinsk",
    city_code: "CEK",
    airport_name: "Chelyabinsk",
    country: "Russia",
  },
  {
    sr: 3340,
    city: "Chemnitz",
    city_code: "ZTZ",
    airport_name: "Chemnitz Railway Stn",
    country: "Germany",
  },
  {
    sr: 3342,
    city: "Chena Hot Springs",
    city_code: "CEX",
    airport_name: "Chena Hot Springs",
    country: "United States",
  },
  {
    sr: 3344,
    city: "Chengdu",
    city_code: "CTU",
    airport_name: "Chengdu",
    country: "China",
  },
  {
    sr: 3346,
    city: "Chennai Madras",
    city_code: "MAA",
    airport_name: "Anna International Airport",
    country: "India",
  },
  {
    sr: 3348,
    city: "Cheongju",
    city_code: "CJJ",
    airport_name: "Cheongju",
    country: "South Korea",
  },
  {
    sr: 3350,
    city: "Cheraw",
    city_code: "HCW",
    airport_name: "Cheraw",
    country: "United States",
  },
  {
    sr: 3352,
    city: "Cherbourg",
    city_code: "CER",
    airport_name: "Maupertus",
    country: "France",
  },
  {
    sr: 3354,
    city: "Cherepovets",
    city_code: "CEE",
    airport_name: "Cherepovets",
    country: "Russia",
  },
  {
    sr: 3356,
    city: "Cherkassy",
    city_code: "CKC",
    airport_name: "Cherkassy",
    country: "Ukraine",
  },
  {
    sr: 3358,
    city: "Chernigov",
    city_code: "CEJ",
    airport_name: "Chernigov",
    country: "Ukraine",
  },
  {
    sr: 3360,
    city: "Chernofski",
    city_code: "KCN",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 3362,
    city: "Chernovtsy",
    city_code: "CWC",
    airport_name: "Chernovtsy",
    country: "Ukraine",
  },
  {
    sr: 3366,
    city: "Cherokee",
    city_code: "CKK",
    airport_name: "Cherokee",
    country: "United States",
  },
  {
    sr: 3368,
    city: "Cherokee",
    city_code: "CKA",
    airport_name: "Kegelman Af",
    country: "United States",
  },
  {
    sr: 3370,
    city: "Cherrabun",
    city_code: "CBC",
    airport_name: "Cherrabun",
    country: "Australia",
  },
  {
    sr: 3372,
    city: "Cherribah",
    city_code: "CRH",
    airport_name: "Cherribah",
    country: "Australia",
  },
  {
    sr: 3374,
    city: "Cherskiy",
    city_code: "CYX",
    airport_name: "Cherskiy",
    country: "Russia",
  },
  {
    sr: 3376,
    city: "Chesapeake",
    city_code: "HTW",
    airport_name: "Huntington County",
    country: "United States",
  },
  {
    sr: 3378,
    city: "Chester",
    city_code: "CEG",
    airport_name: "Chester",
    country: "United Kingdom",
  },
  {
    sr: 3380,
    city: "Chesterfield Inlet",
    city_code: "YCS",
    airport_name: "Chesterfield Inlet",
    country: "Canada",
  },
  {
    sr: 3382,
    city: "Chetumal",
    city_code: "CTM",
    airport_name: "Chetumal",
    country: "Mexico",
  },
  {
    sr: 3384,
    city: "Chetwynd",
    city_code: "YCQ",
    airport_name: "Chetwynd",
    country: "Canada",
  },
  {
    sr: 3386,
    city: "Chevak",
    city_code: "VAK",
    airport_name: "Chevak",
    country: "United States",
  },
  {
    sr: 3388,
    city: "Chevery",
    city_code: "YHR",
    airport_name: "Chevery",
    country: "Canada",
  },
  {
    sr: 3390,
    city: "Cheyenne",
    city_code: "CYS",
    airport_name: "Cheyenne",
    country: "United States",
  },
  {
    sr: 3392,
    city: "Cheyenne",
    city_code: "FEW",
    airport_name: "Warren AFB",
    country: "United States",
  },
  {
    sr: 3394,
    city: "Chi Mei",
    city_code: "CMJ",
    airport_name: "Chi Mei",
    country: "Taiwan",
  },
  {
    sr: 3396,
    city: "Chiang Mai",
    city_code: "CNX",
    airport_name: "International",
    country: "Thailand",
  },
  {
    sr: 3400,
    city: "Chiang Rai",
    city_code: "CEI",
    airport_name: "Chiang Rai",
    country: "Thailand",
  },
  {
    sr: 3402,
    city: "Chiayi",
    city_code: "CYI",
    airport_name: "Chiayi",
    country: "Taiwan",
  },
  {
    sr: 3404,
    city: "Chibougamau",
    city_code: "YMT",
    airport_name: "Chibougamau",
    country: "Canada",
  },
  {
    sr: 3408,
    city: "Chicago",
    city_code: "NOH",
    airport_name: "Chicago NAS",
    country: "United States",
  },
  {
    sr: 3410,
    city: "Chicago",
    city_code: "RFD",
    airport_name: "Chicago Rockford",
    country: "United States",
  },
  {
    sr: 3412,
    city: "Chicago",
    city_code: "DPA",
    airport_name: "Dupage County",
    country: "United States",
  },
  {
    sr: 3414,
    city: "Chicago",
    city_code: "GYY",
    airport_name: "Gary Regional",
    country: "United States",
  },
  {
    sr: 3416,
    city: "Chicago",
    city_code: "CGX",
    airport_name: "Merrill C Meigs",
    country: "United States",
  },
  {
    sr: 3418,
    city: "Chicago",
    city_code: "MDW",
    airport_name: "Midway",
    country: "United States",
  },
  {
    sr: 3420,
    city: "Chicago",
    city_code: "PWK",
    airport_name: "Pal Waukee",
    country: "United States",
  },
  {
    sr: 3422,
    city: "Chichen Itza",
    city_code: "CZA",
    airport_name: "Chichen Itza",
    country: "Mexico",
  },
  {
    sr: 3424,
    city: "Chickasha",
    city_code: "CHK",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 3426,
    city: "Chicken",
    city_code: "CKX",
    airport_name: "Chicken",
    country: "United States",
  },
  {
    sr: 3428,
    city: "Chiclayo",
    city_code: "CIX",
    airport_name: "Cornel Ruiz",
    country: "Peru",
  },
  {
    sr: 3430,
    city: "Chico",
    city_code: "CIC",
    airport_name: "Chico",
    country: "United States",
  },
  {
    sr: 3434,
    city: "Chifeng",
    city_code: "CIF",
    airport_name: "Chifeng",
    country: "China",
  },
  {
    sr: 3436,
    city: "Chignik",
    city_code: "KCQ",
    airport_name: "Chignik",
    country: "United States",
  },
  {
    sr: 3438,
    city: "Chignik",
    city_code: "KBW",
    airport_name: "Chignik Bay",
    country: "United States",
  },
  {
    sr: 3440,
    city: "Chignik",
    city_code: "KCG",
    airport_name: "Fisheries",
    country: "United States",
  },
  {
    sr: 3442,
    city: "Chignik",
    city_code: "KCL",
    airport_name: "Lagoon",
    country: "United States",
  },
  {
    sr: 3444,
    city: "Chigorodo",
    city_code: "IGO",
    airport_name: "Chigorodo",
    country: "Colombia",
  },
  {
    sr: 3446,
    city: "Chihuahua",
    city_code: "CUU",
    airport_name: "Gen Fierro Villalobos",
    country: "Mexico",
  },
  {
    sr: 3448,
    city: "Chilas",
    city_code: "CHB",
    airport_name: "Chilas",
    country: "Pakistan",
  },
  {
    sr: 3450,
    city: "Childress",
    city_code: "CDS",
    airport_name: "Childress",
    country: "United States",
  },
  {
    sr: 3452,
    city: "Chile Chico",
    city_code: "CCH",
    airport_name: "Chile Chico",
    country: "Chile",
  },
  {
    sr: 3454,
    city: "Chilko Lake",
    city_code: "CJH",
    airport_name: "Chilko Lake",
    country: "Canada",
  },
  {
    sr: 3456,
    city: "Chillagoe",
    city_code: "LLG",
    airport_name: "Chillagoe",
    country: "Australia",
  },
  {
    sr: 3458,
    city: "Chillan",
    city_code: "YAI",
    airport_name: "Chillan",
    country: "Chile",
  },
  {
    sr: 3460,
    city: "Chilliwack",
    city_code: "YCW",
    airport_name: "Chilliwack Municipal Airport",
    country: "Canada",
  },
  {
    sr: 3462,
    city: "Chiloquin",
    city_code: "CHZ",
    airport_name: "State",
    country: "United States",
  },
  {
    sr: 3464,
    city: "Chimbote",
    city_code: "CHM",
    airport_name: "Chimbote",
    country: "Peru",
  },
  {
    sr: 3468,
    city: "Chimoio",
    city_code: "VPY",
    airport_name: "Chimoio",
    country: "Mozambique",
  },
  {
    sr: 3470,
    city: "Chinchilla",
    city_code: "CCL",
    airport_name: "Chinchilla",
    country: "Australia",
  },
  {
    sr: 3472,
    city: "Chincoteague",
    city_code: "WAL",
    airport_name: "Wallops Flight Center",
    country: "United States",
  },
  {
    sr: 3474,
    city: "Chinde",
    city_code: "INE",
    airport_name: "Chinde",
    country: "Mozambique",
  },
  {
    sr: 3476,
    city: "Chingola",
    city_code: "CGJ",
    airport_name: "Chingola",
    country: "Zambia",
  },
  {
    sr: 3478,
    city: "Chinguitti",
    city_code: "CGT",
    airport_name: "Chinguitti",
    country: "Mauritania",
  },
  {
    sr: 3480,
    city: "Chino",
    city_code: "CNO",
    airport_name: "Chino",
    country: "United States",
  },
  {
    sr: 3482,
    city: "Chios",
    city_code: "JKH",
    airport_name: "Chios",
    country: "Greece",
  },
  {
    sr: 3484,
    city: "Chipata",
    city_code: "CIP",
    airport_name: "Chipata",
    country: "Zambia",
  },
  {
    sr: 3486,
    city: "Chipinge",
    city_code: "CHJ",
    airport_name: "Chipinge",
    country: "Zimbabwe",
  },
  {
    sr: 3488,
    city: "Chiquimula",
    city_code: "CIQ",
    airport_name: "Chiquimula",
    country: "Guatemala",
  },
  {
    sr: 3490,
    city: "Chisana",
    city_code: "CZN",
    airport_name: "Chisana Field",
    country: "United States",
  },
  {
    sr: 3492,
    city: "Chisasibi",
    city_code: "YKU",
    airport_name: "Chisasibi",
    country: "Canada",
  },
  {
    sr: 3494,
    city: "Chisholm",
    city_code: "HIB",
    airport_name: "Chisholm",
    country: "United States",
  },
  {
    sr: 3496,
    city: "Chisinau",
    city_code: "RMO",
    airport_name: "Chisinau",
    country: "Moldova",
  },
  {
    sr: 3498,
    city: "Chistochina",
    city_code: "CZO",
    airport_name: "Chistochina",
    country: "United States",
  },
  {
    sr: 3502,
    city: "Chita",
    city_code: "HTA",
    airport_name: "Chita",
    country: "Russia",
  },
  {
    sr: 3504,
    city: "Chitato",
    city_code: "PGI",
    airport_name: "Chitato",
    country: "Angola",
  },
  {
    sr: 3506,
    city: "Chitina",
    city_code: "CXC",
    airport_name: "Chitina Arpt",
    country: "United States",
  },
  {
    sr: 3508,
    city: "Chitral",
    city_code: "CJL",
    airport_name: "Chitral",
    country: "Pakistan",
  },
  {
    sr: 3510,
    city: "Chitre",
    city_code: "CTD",
    airport_name: "Chitre",
    country: "Panama",
  },
  {
    sr: 3512,
    city: "Chittagong",
    city_code: "CGP",
    airport_name: "Patenga",
    country: "Bangladesh",
  },
  {
    sr: 3514,
    city: "Chiusa Klausen",
    city_code: "ZAK",
    airport_name: "Bus Station",
    country: "Italy",
  },
  {
    sr: 3516,
    city: "Chivolo",
    city_code: "IVO",
    airport_name: "Chivolo",
    country: "Colombia",
  },
  {
    sr: 3518,
    city: "Chkalovsky",
    city_code: "CKL",
    airport_name: "Chkalovsky",
    country: "Russia",
  },
  {
    sr: 3520,
    city: "Chlef",
    city_code: "CFK",
    airport_name: "Abou Bakr Belkaid",
    country: "Algeria",
  },
  {
    sr: 3522,
    city: "Choibalsan",
    city_code: "COQ",
    airport_name: "Choibalsan",
    country: "Mongolia",
  },
  {
    sr: 3524,
    city: "Choiseul Bay",
    city_code: "CHY",
    airport_name: "Choiseul Bay",
    country: "Solomon Islands",
  },
  {
    sr: 3526,
    city: "Chokurdah",
    city_code: "CKH",
    airport_name: "Chokurdah",
    country: "Russia",
  },
  {
    sr: 3528,
    city: "Chokwe",
    city_code: "TGS",
    airport_name: "Chokwe",
    country: "Mozambique",
  },
  {
    sr: 3530,
    city: "Cholet",
    city_code: "CET",
    airport_name: "Le Pontreau",
    country: "France",
  },
  {
    sr: 3532,
    city: "Chomley",
    city_code: "CIV",
    airport_name: "Chomley",
    country: "United States",
  },
  {
    sr: 3536,
    city: "Chongqing",
    city_code: "CKG",
    airport_name: "Chongqing Jiangbei International",
    country: "China",
  },
  {
    sr: 3538,
    city: "Chos Malal",
    city_code: "HOS",
    airport_name: "Oscar Reguera",
    country: "Argentina",
  },
  {
    sr: 3540,
    city: "Christchurch",
    city_code: "CHC",
    airport_name: "Christchurch International",
    country: "New Zealand",
  },
  {
    sr: 3542,
    city: "Christmas Creek",
    city_code: "CXQ",
    airport_name: "Christmas Creek",
    country: "Australia",
  },
  {
    sr: 3544,
    city: "Christmas Island",
    city_code: "XCH",
    airport_name: "Christmas Island",
    country: "Christmas Island",
  },
  {
    sr: 3546,
    city: "Christmas Island",
    city_code: "CXI",
    airport_name: "Christmas Island",
    country: "Kiribati",
  },
  {
    sr: 3548,
    city: "Chuathbaluk",
    city_code: "CHU",
    airport_name: "Chuathbaluk",
    country: "United States",
  },
  {
    sr: 3550,
    city: "Chub Cay",
    city_code: "CCZ",
    airport_name: "Chub Cay",
    country: "Bahamas",
  },
  {
    sr: 3552,
    city: "Chulman",
    city_code: "CNN1",
    airport_name: "Chulman",
    country: "Russia",
  },
  {
    sr: 3554,
    city: "Chumphon",
    city_code: "CJM",
    airport_name: "Chumphon Airport",
    country: "Thailand",
  },
  {
    sr: 3556,
    city: "Chungribu",
    city_code: "CVB",
    airport_name: "Chungribu",
    country: "Papua New Guinea",
  },
  {
    sr: 3558,
    city: "Churchill Falls",
    city_code: "ZUM",
    airport_name: "Churchill Falls",
    country: "Canada",
  },
  {
    sr: 3560,
    city: "Chute Des Passes",
    city_code: "YWQ",
    airport_name: "Chute Des Passes",
    country: "Canada",
  },
  {
    sr: 3562,
    city: "Cicia",
    city_code: "ICI",
    airport_name: "Cicia",
    country: "Fiji",
  },
  {
    sr: 3564,
    city: "Ciego De Avila",
    city_code: "AVI",
    airport_name: "Maximo Gomez",
    country: "Cuba",
  },
  {
    sr: 3566,
    city: "Cienfuegos",
    city_code: "CFG",
    airport_name: "Cienfuegos",
    country: "Cuba",
  },
  {
    sr: 3570,
    city: "Cilacap",
    city_code: "CXP",
    airport_name: "Tunggul Wulung",
    country: "Indonesia",
  },
  {
    sr: 3572,
    city: "Cimitarra",
    city_code: "CIM",
    airport_name: "Cimitarra",
    country: "Colombia",
  },
  {
    sr: 3574,
    city: "Cincinnati",
    city_code: "CVG",
    airport_name: "Cinci Nrthrn Kentucky",
    country: "United States",
  },
  {
    sr: 3576,
    city: "Cincinnati",
    city_code: "LUK",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 3578,
    city: "Cinder River",
    city_code: "RCP",
    airport_name: "Cinder River",
    country: "United States",
  },
  {
    sr: 3580,
    city: "Circle",
    city_code: "IRC",
    airport_name: "Circle City",
    country: "United States",
  },
  {
    sr: 3582,
    city: "Circle Hot Springs",
    city_code: "CHP",
    airport_name: "Circle Hot Springs",
    country: "United States",
  },
  {
    sr: 3584,
    city: "Cirebon",
    city_code: "CBN",
    airport_name: "Penggung",
    country: "Indonesia",
  },
  {
    sr: 3586,
    city: "Ciudad Acuna",
    city_code: "ACN",
    airport_name: "International",
    country: "Mexico",
  },
  {
    sr: 3588,
    city: "Ciudad Bolivar",
    city_code: "CBL",
    airport_name: "Ciudad Bolivar",
    country: "Venezuela",
  },
  {
    sr: 3590,
    city: "Ciudad Constitucion",
    city_code: "CUA",
    airport_name: "Ciudad Constitucion",
    country: "Mexico",
  },
  {
    sr: 3592,
    city: "Ciudad Del Carmen",
    city_code: "CME",
    airport_name: "Ciudad Del Carmen",
    country: "Mexico",
  },
  {
    sr: 3594,
    city: "Ciudad del Este",
    city_code: "AGT",
    airport_name: "Alejo Garcia",
    country: "Paraguay",
  },
  {
    sr: 3596,
    city: "Ciudad Guayana",
    city_code: "CGU",
    airport_name: "Ciudad Guayana",
    country: "Venezuela",
  },
  {
    sr: 3598,
    city: "Ciudad Juarez",
    city_code: "CJS",
    airport_name: "Intl Abraham Gonzalez",
    country: "Mexico",
  },
  {
    sr: 3600,
    city: "Ciudad Mante",
    city_code: "MMC",
    airport_name: "Ciudad Mante",
    country: "Mexico",
  },
  {
    sr: 3604,
    city: "Ciudad Obregon",
    city_code: "CEN",
    airport_name: "Ciudad Obregon",
    country: "Mexico",
  },
  {
    sr: 3606,
    city: "Ciudad Real",
    city_code: "CJI",
    airport_name: "Ciudad Real",
    country: "Spain",
  },
  {
    sr: 3608,
    city: "Ciudad Victoria",
    city_code: "CVM",
    airport_name: "Ciudad Victoria",
    country: "Mexico",
  },
  {
    sr: 3610,
    city: "Claremont",
    city_code: "CNH",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 3612,
    city: "Clarinda",
    city_code: "ICL",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 3614,
    city: "Clarks Point",
    city_code: "CLP",
    airport_name: "Clarks Point",
    country: "United States",
  },
  {
    sr: 3616,
    city: "Clarksburg",
    city_code: "CKB",
    airport_name: "Benedum",
    country: "United States",
  },
  {
    sr: 3618,
    city: "Clarksdale",
    city_code: "CKM",
    airport_name: "Fletcher Field",
    country: "United States",
  },
  {
    sr: 3620,
    city: "Clarksville",
    city_code: "CKV",
    airport_name: "Outlaw Field",
    country: "United States",
  },
  {
    sr: 3622,
    city: "Clayton",
    city_code: "CAO",
    airport_name: "Clayton",
    country: "United States",
  },
  {
    sr: 3624,
    city: "Clear Lake",
    city_code: "CKE",
    airport_name: "Clear Lake",
    country: "United States",
  },
  {
    sr: 3626,
    city: "Clearlake",
    city_code: "CLC",
    airport_name: "Metroport",
    country: "United States",
  },
  {
    sr: 3628,
    city: "Clearwater",
    city_code: "CLW",
    airport_name: "Executive",
    country: "United States",
  },
  {
    sr: 3630,
    city: "Clemson",
    city_code: "CEU",
    airport_name: "Oconee County",
    country: "United States",
  },
  {
    sr: 3632,
    city: "Clermont",
    city_code: "CMQ",
    airport_name: "Clermont",
    country: "Australia",
  },
  {
    sr: 3634,
    city: "Clermont Ferrand",
    city_code: "CFE",
    airport_name: "Aulnat",
    country: "France",
  },
  {
    sr: 3638,
    city: "Cleve",
    city_code: "CVC",
    airport_name: "Cleve",
    country: "Australia",
  },
  {
    sr: 3640,
    city: "Cleveland",
    city_code: "BKL",
    airport_name: "Burke Lakefront",
    country: "United States",
  },
  {
    sr: 3642,
    city: "Cleveland",
    city_code: "CGF",
    airport_name: "Cuyahoga County",
    country: "United States",
  },
  {
    sr: 3644,
    city: "Cleveland",
    city_code: "CLE",
    airport_name: "Hopkins International",
    country: "United States",
  },
  {
    sr: 3646,
    city: "Clifton",
    city_code: "CFT",
    airport_name: "Morenci",
    country: "United States",
  },
  {
    sr: 3648,
    city: "Clifton Hills",
    city_code: "CFH",
    airport_name: "Clifton Hills",
    country: "Australia",
  },
  {
    sr: 3650,
    city: "Clinton",
    city_code: "CWI",
    airport_name: "Clinton",
    country: "United States",
  },
  {
    sr: 3652,
    city: "Clinton",
    city_code: "CTZ",
    airport_name: "Sampson County",
    country: "United States",
  },
  {
    sr: 3654,
    city: "Clinton",
    city_code: "CLK",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 3656,
    city: "Clinton",
    city_code: "CSM",
    airport_name: "Sherman",
    country: "United States",
  },
  {
    sr: 3658,
    city: "Clinton Creek",
    city_code: "YLM",
    airport_name: "Clinton Creek",
    country: "Canada",
  },
  {
    sr: 3660,
    city: "Clintonville",
    city_code: "CLI",
    airport_name: "Clintonville",
    country: "United States",
  },
  {
    sr: 3662,
    city: "Cloncurry",
    city_code: "CNJ",
    airport_name: "Cloncurry",
    country: "Australia",
  },
  {
    sr: 3664,
    city: "Clorinda",
    city_code: "CLX",
    airport_name: "Clorinda",
    country: "Argentina",
  },
  {
    sr: 3666,
    city: "Clovis",
    city_code: "CVS",
    airport_name: "Cannon AFB",
    country: "United States",
  },
  {
    sr: 3668,
    city: "Clovis",
    city_code: "CVN",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 3672,
    city: "Club Makokola",
    city_code: "CMK",
    airport_name: "Club Makokola",
    country: "Malawi",
  },
  {
    sr: 3674,
    city: "Cluff Lake",
    city_code: "XCL",
    airport_name: "Cluff Lake",
    country: "Canada",
  },
  {
    sr: 3676,
    city: "Cluj Napoca",
    city_code: "CLJ",
    airport_name: "Someseni International Airport",
    country: "Romania",
  },
  {
    sr: 3678,
    city: "Cluny",
    city_code: "CZY",
    airport_name: "Cluny",
    country: "Australia",
  },
  {
    sr: 3680,
    city: "Clyde River",
    city_code: "YCY",
    airport_name: "Clyde River",
    country: "Canada",
  },
  {
    sr: 3682,
    city: "Coalinga",
    city_code: "CLG",
    airport_name: "Coalinga",
    country: "United States",
  },
  {
    sr: 3684,
    city: "Coari",
    city_code: "CIZ",
    airport_name: "Coari",
    country: "Brazil",
  },
  {
    sr: 3686,
    city: "Coatepeque",
    city_code: "CTF",
    airport_name: "Coatepeque Airport",
    country: "Guatemala",
  },
  {
    sr: 3688,
    city: "Coatesville",
    city_code: "CTH",
    airport_name: "Chestercounty Carlson",
    country: "United States",
  },
  {
    sr: 3690,
    city: "Coban",
    city_code: "CBV",
    airport_name: "Coban",
    country: "Guatemala",
  },
  {
    sr: 3692,
    city: "Cobar",
    city_code: "CAZ",
    airport_name: "Cobar",
    country: "Australia",
  },
  {
    sr: 3694,
    city: "Cobija",
    city_code: "CIJ",
    airport_name: "E Beltram",
    country: "Bolivia",
  },
  {
    sr: 3696,
    city: "Coca",
    city_code: "OCC",
    airport_name: "Coca",
    country: "Ecuador",
  },
  {
    sr: 3698,
    city: "Cochabamba",
    city_code: "CBB",
    airport_name: "J Wilsterman",
    country: "Bolivia",
  },
  {
    sr: 3700,
    city: "Cochrane",
    city_code: "LGR",
    airport_name: "Cochrane",
    country: "Chile",
  },
  {
    sr: 3702,
    city: "Cochrane",
    city_code: "YCN",
    airport_name: "Cochrane",
    country: "Canada",
  },
  {
    sr: 3706,
    city: "Cochstedt",
    city_code: "CSO",
    airport_name: "Cochstedt",
    country: "Germany",
  },
  {
    sr: 3708,
    city: "Cocoa",
    city_code: "COI",
    airport_name: "Merritt Island",
    country: "United States",
  },
  {
    sr: 3710,
    city: "Cocoa",
    city_code: "COF",
    airport_name: "Patrick AFB",
    country: "United States",
  },
  {
    sr: 3712,
    city: "Coconut Island",
    city_code: "CNC",
    airport_name: "Coconut Island",
    country: "Australia",
  },
  {
    sr: 3714,
    city: "Cocos Islands",
    city_code: "CCK",
    airport_name: "Cocos Islands",
    country: "Cocos  Keeling  Islands",
  },
  {
    sr: 3716,
    city: "Codazzi",
    city_code: "DZI",
    airport_name: "Codazzi",
    country: "Colombia",
  },
  {
    sr: 3718,
    city: "Cody Yellowstone",
    city_code: "COD",
    airport_name: "Yellowstone Regional",
    country: "United States",
  },
  {
    sr: 3720,
    city: "Coen",
    city_code: "CUQ",
    airport_name: "Coen",
    country: "Australia",
  },
  {
    sr: 3722,
    city: "Coffee Point",
    city_code: "CFA",
    airport_name: "Coffee Point",
    country: "United States",
  },
  {
    sr: 3724,
    city: "Coffeyville",
    city_code: "CFV",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 3726,
    city: "Coffman Cove",
    city_code: "KCC",
    airport_name: "Coffman Cove SPB",
    country: "United States",
  },
  {
    sr: 3728,
    city: "Coffs Harbour",
    city_code: "CFS",
    airport_name: "Coffs Harbour",
    country: "Australia",
  },
  {
    sr: 3730,
    city: "Cognac",
    city_code: "CNG",
    airport_name: "Parvaud",
    country: "France",
  },
  {
    sr: 3732,
    city: "Coimbatore",
    city_code: "CJB",
    airport_name: "Peelamedu",
    country: "India",
  },
  {
    sr: 3734,
    city: "Coimbra",
    city_code: "CBP",
    airport_name: "Coimbra",
    country: "Portugal",
  },
  {
    sr: 3736,
    city: "Colac",
    city_code: "XCO",
    airport_name: "Colac",
    country: "Australia",
  },
  {
    sr: 3740,
    city: "Colby",
    city_code: "CBK",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 3742,
    city: "Cold Bay",
    city_code: "CDB",
    airport_name: "Cold Bay",
    country: "United States",
  },
  {
    sr: 3744,
    city: "Cold Lake",
    city_code: "YOD",
    airport_name: "Cold Lake",
    country: "Canada",
  },
  {
    sr: 3746,
    city: "Coldfoot",
    city_code: "CXF",
    airport_name: "Coldfoot",
    country: "United States",
  },
  {
    sr: 3748,
    city: "Coleman",
    city_code: "COM",
    airport_name: "Coleman",
    country: "United States",
  },
  {
    sr: 3750,
    city: "Colima",
    city_code: "CLQ",
    airport_name: "Colima",
    country: "Mexico",
  },
  {
    sr: 3752,
    city: "Isle Of Coll",
    city_code: "COL",
    airport_name: "Isle Of Coll",
    country: "United Kingdom",
  },
  {
    sr: 3754,
    city: "Collarenebri",
    city_code: "CRB",
    airport_name: "Collarenebri",
    country: "Australia",
  },
  {
    sr: 3756,
    city: "College Park",
    city_code: "CGS",
    airport_name: "College Park",
    country: "United States",
  },
  {
    sr: 3758,
    city: "College Station",
    city_code: "CLL",
    airport_name: "Easterwood Field",
    country: "United States",
  },
  {
    sr: 3760,
    city: "Collie",
    city_code: "CIE",
    airport_name: "Collie",
    country: "Australia",
  },
  {
    sr: 3762,
    city: "Collins Bay",
    city_code: "YKC",
    airport_name: "Collins Bay",
    country: "Canada",
  },
  {
    sr: 3764,
    city: "Collinsville",
    city_code: "KCE",
    airport_name: "Collinsville",
    country: "Australia",
  },
  {
    sr: 3766,
    city: "Colmar",
    city_code: "CMR",
    airport_name: "Colmar Houssen",
    country: "France",
  },
  {
    sr: 3768,
    city: "Cologne",
    city_code: "CGN",
    airport_name: "Cologne bonn",
    country: "Germany",
  },
  {
    sr: 3770,
    city: "Colombo",
    city_code: "CMB",
    airport_name: "Bandaranayake",
    country: "Sri Lanka",
  },
  {
    sr: 3774,
    city: "Colon",
    city_code: "ONX",
    airport_name: "Colon",
    country: "Panama",
  },
  {
    sr: 3776,
    city: "Colonia",
    city_code: "CYR",
    airport_name: "Laguna De Los Patos",
    country: "Uruguay",
  },
  {
    sr: 3778,
    city: "Colonia Catriel",
    city_code: "CCT",
    airport_name: "Colonia Catriel",
    country: "Argentina",
  },
  {
    sr: 3780,
    city: "Colonia Sarmiento",
    city_code: "OLN",
    airport_name: "Colonia Sarmiento",
    country: "Argentina",
  },
  {
    sr: 3782,
    city: "Isle Of Colonsay",
    city_code: "CSA",
    airport_name: "Isle Of Colonsay",
    country: "United Kingdom",
  },
  {
    sr: 3784,
    city: "Colorado Creek",
    city_code: "KCR",
    airport_name: "Colorado Creek",
    country: "United States",
  },
  {
    sr: 3786,
    city: "Colorado do Oeste",
    city_code: "CSW",
    airport_name: "Colorado do Oeste",
    country: "Brazil",
  },
  {
    sr: 3788,
    city: "Colorado Springs",
    city_code: "FCS",
    airport_name: "Butts AAF",
    country: "United States",
  },
  {
    sr: 3790,
    city: "Colorado Springs",
    city_code: "COS",
    airport_name: "Colorado Springs",
    country: "United States",
  },
  {
    sr: 3792,
    city: "Colorado Springs",
    city_code: "AFF",
    airport_name: "USAF Academy",
    country: "United States",
  },
  {
    sr: 3794,
    city: "Columbia",
    city_code: "COA",
    airport_name: "Columbia",
    country: "United States",
  },
  {
    sr: 3796,
    city: "Columbia",
    city_code: "COU",
    airport_name: "Columbia Regional",
    country: "United States",
  },
  {
    sr: 3798,
    city: "Columbia",
    city_code: "MMT",
    airport_name: "Mc Entire ANG Base",
    country: "United States",
  },
  {
    sr: 3800,
    city: "Columbia",
    city_code: "CAE",
    airport_name: "Metropolitan Airport",
    country: "United States",
  },
  {
    sr: 3802,
    city: "Columbia",
    city_code: "CUB",
    airport_name: "Owens Field",
    country: "United States",
  },
  {
    sr: 3804,
    city: "Columbia",
    city_code: "MRC",
    airport_name: "Maury County",
    country: "United States",
  },
  {
    sr: 3808,
    city: "Columbus",
    city_code: "LSF",
    airport_name: "Lawson AAF",
    country: "United States",
  },
  {
    sr: 3810,
    city: "Columbus",
    city_code: "MKF",
    airport_name: "Mckenna AAF",
    country: "United States",
  },
  {
    sr: 3812,
    city: "Columbus",
    city_code: "CLU",
    airport_name: "Columbus Municipal",
    country: "United States",
  },
  {
    sr: 3814,
    city: "Columbus",
    city_code: "CBM",
    airport_name: "Columbus AFB",
    country: "United States",
  },
  {
    sr: 3816,
    city: "Columbus",
    city_code: "GTR",
    airport_name: "Golden Triangle Reg",
    country: "United States",
  },
  {
    sr: 3818,
    city: "Columbus",
    city_code: "UBS",
    airport_name: "Lowndes County",
    country: "United States",
  },
  {
    sr: 3820,
    city: "Columbus",
    city_code: "OLU",
    airport_name: "Columbus",
    country: "United States",
  },
  {
    sr: 3822,
    city: "Columbus",
    city_code: "CUS",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 3824,
    city: "Columbus",
    city_code: "OSU",
    airport_name: "Ohio State University",
    country: "United States",
  },
  {
    sr: 3826,
    city: "Columbus",
    city_code: "CMH",
    airport_name: "Port Columbus Intl",
    country: "United States",
  },
  {
    sr: 3828,
    city: "Columbus",
    city_code: "LCK",
    airport_name: "Rickenbacker",
    country: "United States",
  },
  {
    sr: 3830,
    city: "Colville Lake",
    city_code: "YCK",
    airport_name: "Colville Lake",
    country: "Canada",
  },
  {
    sr: 3832,
    city: "Comayagua",
    city_code: "XPL",
    airport_name: "Palmerola Air Base",
    country: "Honduras",
  },
  {
    sr: 3834,
    city: "Comilla",
    city_code: "CLA",
    airport_name: "Comilla",
    country: "Bangladesh",
  },
  {
    sr: 3836,
    city: "Comino",
    city_code: "JCO",
    airport_name: "Heliport",
    country: "Malta",
  },
  {
    sr: 3838,
    city: "Comiso",
    city_code: "CIY",
    airport_name: "Comiso",
    country: "Italy",
  },
  {
    sr: 3842,
    city: "Comitan",
    city_code: "CJT",
    airport_name: "Copalar",
    country: "Mexico",
  },
  {
    sr: 3844,
    city: "Comodoro Rivadavia",
    city_code: "CRD",
    airport_name: "Comodoro Rivadavia",
    country: "Argentina",
  },
  {
    sr: 3846,
    city: "Comox",
    city_code: "YQQ",
    airport_name: "Comox",
    country: "Canada",
  },
  {
    sr: 3848,
    city: "Compton",
    city_code: "CPM",
    airport_name: "Compton",
    country: "United States",
  },
  {
    sr: 3850,
    city: "Con Dao",
    city_code: "VCS",
    airport_name: "Coong",
    country: "Vietnam",
  },
  {
    sr: 3852,
    city: "Conakry",
    city_code: "CKY",
    airport_name: "Conakry",
    country: "Guinea",
  },
  {
    sr: 3854,
    city: "Conceicao Do Araguaia",
    city_code: "CDJ",
    airport_name: "Conceicao Do Araguaia",
    country: "Brazil",
  },
  {
    sr: 3856,
    city: "Concepcion",
    city_code: "CEP",
    airport_name: "Concepcion",
    country: "Bolivia",
  },
  {
    sr: 3858,
    city: "Concepcion",
    city_code: "CCP",
    airport_name: "Carriel Sur",
    country: "Chile",
  },
  {
    sr: 3860,
    city: "Concepcion",
    city_code: "CIO",
    airport_name: "MCAL Lopez",
    country: "Paraguay",
  },
  {
    sr: 3862,
    city: "Concord",
    city_code: "CCR",
    airport_name: "Buchanan Fld",
    country: "United States",
  },
  {
    sr: 3864,
    city: "Concord",
    city_code: "CON",
    airport_name: "Concord",
    country: "United States",
  },
  {
    sr: 3866,
    city: "Concordia",
    city_code: "COC",
    airport_name: "Concordia",
    country: "Argentina",
  },
  {
    sr: 3868,
    city: "Concordia",
    city_code: "CNK",
    airport_name: "Blosser Municipal",
    country: "United States",
  },
  {
    sr: 3870,
    city: "Concordia",
    city_code: "CCI",
    airport_name: "Concordia",
    country: "Brazil",
  },
  {
    sr: 3872,
    city: "Condobolin",
    city_code: "CBX",
    airport_name: "Condobolin",
    country: "Australia",
  },
  {
    sr: 3876,
    city: "Condoto",
    city_code: "COG",
    airport_name: "Mandinga",
    country: "Colombia",
  },
  {
    sr: 3878,
    city: "Confreza",
    city_code: "CFO",
    airport_name: "Confreza",
    country: "Brazil",
  },
  {
    sr: 3880,
    city: "Congo Town",
    city_code: "COX",
    airport_name: "Congo Town",
    country: "Bahamas",
  },
  {
    sr: 3882,
    city: "Connersville",
    city_code: "CEV",
    airport_name: "Mettle Field",
    country: "United States",
  },
  {
    sr: 3884,
    city: "Conroe",
    city_code: "CXO",
    airport_name: "Montgomery Co",
    country: "United States",
  },
  {
    sr: 3886,
    city: "Constanta",
    city_code: "CND",
    airport_name: "Kogalniceanu",
    country: "Romania",
  },
  {
    sr: 3888,
    city: "Constantine",
    city_code: "CZL",
    airport_name: "Ain El Bey",
    country: "Algeria",
  },
  {
    sr: 3890,
    city: "Constanza",
    city_code: "COZ",
    airport_name: "Constanza",
    country: "Dominican Republic",
  },
  {
    sr: 3892,
    city: "Contadora",
    city_code: "OTD",
    airport_name: "Contadora",
    country: "Panama",
  },
  {
    sr: 3894,
    city: "Coober Pedy",
    city_code: "CPD",
    airport_name: "Coober Pedy",
    country: "Australia",
  },
  {
    sr: 3896,
    city: "Cooch Behar",
    city_code: "COH",
    airport_name: "Cooch Behar",
    country: "India",
  },
  {
    sr: 3898,
    city: "Cooinda",
    city_code: "CDA",
    airport_name: "Cooinda",
    country: "Australia",
  },
  {
    sr: 3900,
    city: "Cooktown",
    city_code: "CTN",
    airport_name: "Cooktown",
    country: "Australia",
  },
  {
    sr: 3902,
    city: "Coolah",
    city_code: "CLH",
    airport_name: "Coolah",
    country: "Australia",
  },
  {
    sr: 3904,
    city: "Coolawanyah",
    city_code: "COY",
    airport_name: "Coolawanyah",
    country: "Australia",
  },
  {
    sr: 3906,
    city: "Coolibah",
    city_code: "COB",
    airport_name: "Coolibah",
    country: "Australia",
  },
  {
    sr: 3910,
    city: "Cooma",
    city_code: "OOM",
    airport_name: "Cooma",
    country: "Australia",
  },
  {
    sr: 3912,
    city: "Coonabarabran",
    city_code: "COJ",
    airport_name: "Coonabarabran",
    country: "Australia",
  },
  {
    sr: 3914,
    city: "Coonamble",
    city_code: "CNB",
    airport_name: "Coonamble",
    country: "Australia",
  },
  {
    sr: 3916,
    city: "Co op Point",
    city_code: "YCP",
    airport_name: "Co op Point",
    country: "Canada",
  },
  {
    sr: 3918,
    city: "Cooper Lodge",
    city_code: "JLA",
    airport_name: "Quartz Creek",
    country: "United States",
  },
  {
    sr: 3920,
    city: "Cooperstown",
    city_code: "COP",
    airport_name: "Cooperstown",
    country: "United States",
  },
  {
    sr: 3922,
    city: "Coorabie",
    city_code: "CRJ",
    airport_name: "Coorabie",
    country: "Australia",
  },
  {
    sr: 3924,
    city: "Cootamundra",
    city_code: "CMD",
    airport_name: "Cootamundra",
    country: "Australia",
  },
  {
    sr: 3926,
    city: "Copan",
    city_code: "RUY",
    airport_name: "Copan",
    country: "Honduras",
  },
  {
    sr: 3928,
    city: "Copenhagen",
    city_code: "CPH",
    airport_name: "Copenhagen Airport",
    country: "Denmark",
  },
  {
    sr: 3930,
    city: "Copenhagen",
    city_code: "RKE",
    airport_name: "Roskilde Airport",
    country: "Denmark",
  },
  {
    sr: 3932,
    city: "Copiapo",
    city_code: "CPO",
    airport_name: "Chamonate",
    country: "Chile",
  },
  {
    sr: 3934,
    city: "Copper Centre",
    city_code: "CZC",
    airport_name: "Copper Centre",
    country: "United States",
  },
  {
    sr: 3936,
    city: "Copper Mountain",
    city_code: "QCE",
    airport_name: "Van Service",
    country: "United States",
  },
  {
    sr: 3938,
    city: "Coquimbo",
    city_code: "COW",
    airport_name: "Coquimbo",
    country: "Chile",
  },
  {
    sr: 3940,
    city: "Coral Harbour",
    city_code: "YZS",
    airport_name: "Coral Harbour",
    country: "Canada",
  },
  {
    sr: 3944,
    city: "Corazon De Jesus",
    city_code: "CZJ",
    airport_name: "Corazon De Jesus",
    country: "Panama",
  },
  {
    sr: 3946,
    city: "Corcoran",
    city_code: "CRO",
    airport_name: "Corcoran",
    country: "United States",
  },
  {
    sr: 3948,
    city: "Cordillo Downs",
    city_code: "ODL",
    airport_name: "Cordillo Downs",
    country: "Australia",
  },
  {
    sr: 3950,
    city: "Cordoba",
    city_code: "COR",
    airport_name: "Pajas Blancas",
    country: "Argentina",
  },
  {
    sr: 3952,
    city: "Cordoba",
    city_code: "ODB",
    airport_name: "Cordoba",
    country: "Spain",
  },
  {
    sr: 3954,
    city: "Cordova",
    city_code: "CKU",
    airport_name: "City",
    country: "United States",
  },
  {
    sr: 3956,
    city: "Cordova",
    city_code: "CDV",
    airport_name: "Mudhole Smith",
    country: "United States",
  },
  {
    sr: 3958,
    city: "Corinth",
    city_code: "CRX",
    airport_name: "Roscoe Turner",
    country: "United States",
  },
  {
    sr: 3960,
    city: "Cork",
    city_code: "ORK",
    airport_name: "Cork",
    country: "Ireland",
  },
  {
    sr: 3962,
    city: "Corn Island",
    city_code: "RNI",
    airport_name: "Corn Island",
    country: "Nicaragua",
  },
  {
    sr: 3964,
    city: "Cornelio Procopio",
    city_code: "CKO",
    airport_name: "Cornelio Procopio",
    country: "Brazil",
  },
  {
    sr: 3966,
    city: "Corner Bay",
    city_code: "CBA",
    airport_name: "Corner Bay",
    country: "United States",
  },
  {
    sr: 3968,
    city: "Corner Brook",
    city_code: "YNF",
    airport_name: "Deer Lake Stephenville",
    country: "Canada",
  },
  {
    sr: 3970,
    city: "Corning",
    city_code: "ELM",
    airport_name: "Corning",
    country: "United States",
  },
  {
    sr: 3972,
    city: "Cornwall",
    city_code: "YCC",
    airport_name: "Regional",
    country: "Canada",
  },
  {
    sr: 3974,
    city: "Coro",
    city_code: "CZE",
    airport_name: "Coro",
    country: "Venezuela",
  },
  {
    sr: 3978,
    city: "Coromandel",
    city_code: "CMV",
    airport_name: "Coromandel",
    country: "New Zealand",
  },
  {
    sr: 3980,
    city: "Coron",
    city_code: "XCN",
    airport_name: "Naia",
    country: "Philippines",
  },
  {
    sr: 3982,
    city: "Coronation",
    city_code: "YCT",
    airport_name: "Coronation",
    country: "Canada",
  },
  {
    sr: 3984,
    city: "Coronel E Soto Cano AB",
    city_code: "ENQ",
    airport_name: "Coronel E Soto Cano AB",
    country: "Honduras",
  },
  {
    sr: 3986,
    city: "Coronel Suarez",
    city_code: "CSZ",
    airport_name: "Brigadier Hector Ruiz",
    country: "Argentina",
  },
  {
    sr: 3988,
    city: "Corowa",
    city_code: "CWW",
    airport_name: "Corowa",
    country: "Australia",
  },
  {
    sr: 3990,
    city: "Corozal",
    city_code: "CZH",
    airport_name: "Corozal",
    country: "Belize",
  },
  {
    sr: 3992,
    city: "Corozal",
    city_code: "CZU",
    airport_name: "Corozal",
    country: "Colombia",
  },
  {
    sr: 3994,
    city: "Corpus Christi",
    city_code: "NGW",
    airport_name: "Cabaniss Field",
    country: "United States",
  },
  {
    sr: 3996,
    city: "Corpus Christi",
    city_code: "CUX",
    airport_name: "Cuddihy Field",
    country: "United States",
  },
  {
    sr: 3998,
    city: "Corpus Christi",
    city_code: "CRP",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 4000,
    city: "Corpus Christi",
    city_code: "NGP",
    airport_name: "NAS",
    country: "United States",
  },
  {
    sr: 4002,
    city: "Corrientes",
    city_code: "CNQ",
    airport_name: "Camba Punta",
    country: "Argentina",
  },
  {
    sr: 4004,
    city: "Corryong",
    city_code: "CYG",
    airport_name: "Corryong",
    country: "Australia",
  },
  {
    sr: 4006,
    city: "Corsicana",
    city_code: "CRS",
    airport_name: "Corsicana",
    country: "United States",
  },
  {
    sr: 4008,
    city: "Cortes Bay",
    city_code: "YCF",
    airport_name: "Cortes Bay",
    country: "Canada",
  },
  {
    sr: 4012,
    city: "Cortez",
    city_code: "CEZ",
    airport_name: "Montezuma County",
    country: "United States",
  },
  {
    sr: 4014,
    city: "Cortland",
    city_code: "CTX",
    airport_name: "Cortland",
    country: "United States",
  },
  {
    sr: 4016,
    city: "Corumba",
    city_code: "CMG",
    airport_name: "Internacional",
    country: "Brazil",
  },
  {
    sr: 4018,
    city: "Corvo Island",
    city_code: "CVU",
    airport_name: "Corvo Island",
    country: "Portugal",
  },
  {
    sr: 4020,
    city: "Costa Marques",
    city_code: "CQS",
    airport_name: "Costa Marques",
    country: "Brazil",
  },
  {
    sr: 4022,
    city: "Cotabato",
    city_code: "CBO",
    airport_name: "Awang",
    country: "Philippines",
  },
  {
    sr: 4024,
    city: "Coto 47",
    city_code: "OTR",
    airport_name: "Coto 47",
    country: "Costa Rica",
  },
  {
    sr: 4026,
    city: "Cotonou",
    city_code: "COO",
    airport_name: "Cotonou",
    country: "Benin",
  },
  {
    sr: 4028,
    city: "Cottbus",
    city_code: "CBU",
    airport_name: "Cottbus",
    country: "Germany",
  },
  {
    sr: 4030,
    city: "Cottbus",
    city_code: "ZTT",
    airport_name: "Cottbus Railway Stn",
    country: "Germany",
  },
  {
    sr: 4032,
    city: "Cottonwood",
    city_code: "CTW",
    airport_name: "Cottonwood",
    country: "United States",
  },
  {
    sr: 4034,
    city: "Cotulla",
    city_code: "COT",
    airport_name: "Cotulla",
    country: "United States",
  },
  {
    sr: 4036,
    city: "Council",
    city_code: "CIL",
    airport_name: "Melsing Creek",
    country: "United States",
  },
  {
    sr: 4038,
    city: "Council Bluffs",
    city_code: "CBF",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 4040,
    city: "Courchevel",
    city_code: "CVF",
    airport_name: "Courchevel",
    country: "France",
  },
  {
    sr: 4042,
    city: "Courtenay",
    city_code: "YCA",
    airport_name: "Courtenay",
    country: "Canada",
  },
  {
    sr: 4046,
    city: "Covenas",
    city_code: "CVE",
    airport_name: "Covenas",
    country: "Colombia",
  },
  {
    sr: 4048,
    city: "Coventry",
    city_code: "CVT",
    airport_name: "Coventry   West Midlands International Airport",
    country: "United Kingdom",
  },
  {
    sr: 4050,
    city: "Covilha",
    city_code: "COV",
    airport_name: "Covilha",
    country: "Portugal",
  },
  {
    sr: 4052,
    city: "Cowarie",
    city_code: "CWR",
    airport_name: "Cowarie",
    country: "Australia",
  },
  {
    sr: 4054,
    city: "Cowell",
    city_code: "CCW",
    airport_name: "Cowell",
    country: "Australia",
  },
  {
    sr: 4056,
    city: "Cowley",
    city_code: "YYM",
    airport_name: "Cowley",
    country: "Canada",
  },
  {
    sr: 4058,
    city: "Cowra",
    city_code: "CWT",
    airport_name: "Cowra",
    country: "Australia",
  },
  {
    sr: 4060,
    city: "Coyhaique",
    city_code: "GXQ",
    airport_name: "Ten Vidal",
    country: "Chile",
  },
  {
    sr: 4062,
    city: "Coyoles",
    city_code: "CYL",
    airport_name: "Coyoles",
    country: "Honduras",
  },
  {
    sr: 4064,
    city: "Cozumel",
    city_code: "CZM",
    airport_name: "Cozumel",
    country: "Mexico",
  },
  {
    sr: 4066,
    city: "Cradock",
    city_code: "CDO",
    airport_name: "Cradock",
    country: "South Africa",
  },
  {
    sr: 4068,
    city: "Craig",
    city_code: "CGA",
    airport_name: "Craig SPB",
    country: "United States",
  },
  {
    sr: 4070,
    city: "Craig",
    city_code: "CIG",
    airport_name: "Craig Moffat",
    country: "United States",
  },
  {
    sr: 4072,
    city: "Craig Cove",
    city_code: "CCV",
    airport_name: "Craig Cove",
    country: "Vanuatu",
  },
  {
    sr: 4074,
    city: "Craiova",
    city_code: "CRA",
    airport_name: "Craiova",
    country: "Romania",
  },
  {
    sr: 4076,
    city: "Cranbrook",
    city_code: "YXC",
    airport_name: "Cranbrook",
    country: "Canada",
  },
  {
    sr: 4080,
    city: "Crane",
    city_code: "CCG",
    airport_name: "Crane County Arpt",
    country: "United States",
  },
  {
    sr: 4082,
    city: "Crane Island",
    city_code: "CKR",
    airport_name: "Crane Island",
    country: "United States",
  },
  {
    sr: 4084,
    city: "Cravo Norte",
    city_code: "RAV",
    airport_name: "Cravo Norte",
    country: "Colombia",
  },
  {
    sr: 4086,
    city: "Creil",
    city_code: "CSF",
    airport_name: "Creil",
    country: "France",
  },
  {
    sr: 4088,
    city: "Crescent City",
    city_code: "CEC",
    airport_name: "Mc Namara Fld",
    country: "United States",
  },
  {
    sr: 4090,
    city: "Cresswell Downs",
    city_code: "CSD",
    airport_name: "Cresswell Downs",
    country: "Australia",
  },
  {
    sr: 4092,
    city: "Crested Butte",
    city_code: "CSE",
    airport_name: "Crested Butte",
    country: "United States",
  },
  {
    sr: 4094,
    city: "Creston",
    city_code: "CFQ",
    airport_name: "Creston",
    country: "Canada",
  },
  {
    sr: 4096,
    city: "Creston",
    city_code: "CSQ",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 4098,
    city: "Crestview",
    city_code: "CEW",
    airport_name: "Bob Sikes",
    country: "United States",
  },
  {
    sr: 4100,
    city: "Criciuma",
    city_code: "CCM",
    airport_name: "Criciuma",
    country: "Brazil",
  },
  {
    sr: 4102,
    city: "Croker Island",
    city_code: "CKI",
    airport_name: "Croker Island",
    country: "Australia",
  },
  {
    sr: 4104,
    city: "Cromarty",
    city_code: "CRN",
    airport_name: "Cromarty",
    country: "United Kingdom",
  },
  {
    sr: 4106,
    city: "Crooked Creek",
    city_code: "CKD",
    airport_name: "Crooked Creek",
    country: "United States",
  },
  {
    sr: 4108,
    city: "Crooked Island",
    city_code: "CRI",
    airport_name: "Crooked Island",
    country: "Bahamas",
  },
  {
    sr: 4110,
    city: "Crookston",
    city_code: "CKN",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 4114,
    city: "Cross City",
    city_code: "CTY",
    airport_name: "Cross City",
    country: "United States",
  },
  {
    sr: 4116,
    city: "Cross Lake",
    city_code: "YCR",
    airport_name: "Cross Lake",
    country: "Canada",
  },
  {
    sr: 4118,
    city: "Crossett",
    city_code: "CRT",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 4120,
    city: "Crossville",
    city_code: "CSV",
    airport_name: "Memorial",
    country: "United States",
  },
  {
    sr: 4122,
    city: "Crotone",
    city_code: "CRV",
    airport_name: "Crotone",
    country: "Italy",
  },
  {
    sr: 4124,
    city: "Crows Landing",
    city_code: "NRC",
    airport_name: "Aux Field",
    country: "United States",
  },
  {
    sr: 4126,
    city: "Croydon",
    city_code: "CDQ",
    airport_name: "Croydon",
    country: "Australia",
  },
  {
    sr: 4128,
    city: "Cruz Alta",
    city_code: "CZB",
    airport_name: "Carlos Ruhl",
    country: "Brazil",
  },
  {
    sr: 4130,
    city: "Cruzeiro Do Sul",
    city_code: "CZS",
    airport_name: "Campo Internacional",
    country: "Brazil",
  },
  {
    sr: 4132,
    city: "Crystal Lake",
    city_code: "CYE",
    airport_name: "Crystal Lake",
    country: "United States",
  },
  {
    sr: 4134,
    city: "Cuamba",
    city_code: "FXO",
    airport_name: "Cuamba",
    country: "Mozambique",
  },
  {
    sr: 4136,
    city: "Cube Cove",
    city_code: "CUW",
    airport_name: "Cube Cove",
    country: "United States",
  },
  {
    sr: 4138,
    city: "Cucuta",
    city_code: "CUC",
    airport_name: "Camilo Dazo",
    country: "Colombia",
  },
  {
    sr: 4140,
    city: "Cudal",
    city_code: "CUG",
    airport_name: "Cudal",
    country: "Australia",
  },
  {
    sr: 4142,
    city: "Cuddapah",
    city_code: "CDP",
    airport_name: "Cuddapah",
    country: "India",
  },
  {
    sr: 4144,
    city: "Cue",
    city_code: "CUY",
    airport_name: "Cue",
    country: "Australia",
  },
  {
    sr: 4148,
    city: "Cuenca",
    city_code: "CUE",
    airport_name: "Cuenca",
    country: "Ecuador",
  },
  {
    sr: 4150,
    city: "Cuernavaca",
    city_code: "CVJ",
    airport_name: "General Mariano Matamoros Airport",
    country: "Mexico",
  },
  {
    sr: 4152,
    city: "Cuiaba",
    city_code: "CGB",
    airport_name: "M Rondon",
    country: "Brazil",
  },
  {
    sr: 4154,
    city: "Cuito Cuanavale",
    city_code: "CTI",
    airport_name: "Cuito Cuanavale",
    country: "Angola",
  },
  {
    sr: 4156,
    city: "Culebra",
    city_code: "CPX",
    airport_name: "Culebra",
    country: "Puerto Rico",
  },
  {
    sr: 4158,
    city: "Culiacan",
    city_code: "CUL",
    airport_name: "Fedl De Bachigualato",
    country: "Mexico",
  },
  {
    sr: 4160,
    city: "Culion",
    city_code: "CUJ",
    airport_name: "Culion",
    country: "Philippines",
  },
  {
    sr: 4162,
    city: "Culver City",
    city_code: "CVR",
    airport_name: "Hughes",
    country: "United States",
  },
  {
    sr: 4164,
    city: "Cumana",
    city_code: "CUM",
    airport_name: "Cumana",
    country: "Venezuela",
  },
  {
    sr: 4166,
    city: "Cumberland",
    city_code: "CBE",
    airport_name: "Wiley Ford",
    country: "United States",
  },
  {
    sr: 4168,
    city: "Cuneo",
    city_code: "CUF",
    airport_name: "Levaldigi",
    country: "Italy",
  },
  {
    sr: 4170,
    city: "Cunnamulla",
    city_code: "CMA",
    airport_name: "Cunnamulla",
    country: "Australia",
  },
  {
    sr: 4172,
    city: "Curacao",
    city_code: "CUR",
    airport_name: "Hato International Airport",
    country: "Netherlands Antilles",
  },
  {
    sr: 4174,
    city: "Curitiba",
    city_code: "CWB",
    airport_name: "Afonso Pena International Airport",
    country: "Brazil",
  },
  {
    sr: 4176,
    city: "Curitiba",
    city_code: "BFH",
    airport_name: "Bacacheri",
    country: "Brazil",
  },
  {
    sr: 4178,
    city: "Currillo",
    city_code: "CUI",
    airport_name: "Currillo",
    country: "Colombia",
  },
  {
    sr: 4182,
    city: "Cururupu",
    city_code: "CPU",
    airport_name: "Cururupu",
    country: "Brazil",
  },
  {
    sr: 4184,
    city: "Curuzu Cuatia",
    city_code: "UZU",
    airport_name: "Curuzu Cuatia",
    country: "Argentina",
  },
  {
    sr: 4186,
    city: "Cushing",
    city_code: "CUH",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 4188,
    city: "Cut Bank",
    city_code: "CTB",
    airport_name: "Cut Bank",
    country: "United States",
  },
  {
    sr: 4190,
    city: "Cutral",
    city_code: "CUT",
    airport_name: "Cutral",
    country: "Argentina",
  },
  {
    sr: 4192,
    city: "Cuxhaven",
    city_code: "FCN",
    airport_name: "Cuxhaven Nordholz",
    country: "Germany",
  },
  {
    sr: 4194,
    city: "Cuyo",
    city_code: "CYU",
    airport_name: "Cuyo",
    country: "Philippines",
  },
  {
    sr: 4196,
    city: "Cuzco",
    city_code: "CUZ",
    airport_name: "Velazco Astete",
    country: "Peru",
  },
  {
    sr: 4198,
    city: "Czestochowa",
    city_code: "CZW",
    airport_name: "Czestochowa",
    country: "Poland",
  },
  {
    sr: 4200,
    city: "Da Nang",
    city_code: "DAD",
    airport_name: "Da Nang",
    country: "Vietnam",
  },
  {
    sr: 4202,
    city: "Dabaa City",
    city_code: "DBB",
    airport_name: "Alalamain Intl",
    country: "Egypt",
  },
  {
    sr: 4204,
    city: "Dabo",
    city_code: "DAO",
    airport_name: "Dabo",
    country: "Papua New Guinea",
  },
  {
    sr: 4206,
    city: "Dabra",
    city_code: "DRH",
    airport_name: "Dabra",
    country: "Indonesia",
  },
  {
    sr: 4208,
    city: "Dadu",
    city_code: "DDU",
    airport_name: "Dadu",
    country: "Pakistan",
  },
  {
    sr: 4210,
    city: "Daegu",
    city_code: "TAE",
    airport_name: "Daegu",
    country: "South Korea",
  },
  {
    sr: 4212,
    city: "Daet",
    city_code: "DTE",
    airport_name: "Camarines Norte",
    country: "Philippines",
  },
  {
    sr: 4216,
    city: "Daggett",
    city_code: "DAG",
    airport_name: "Barstow Daggett",
    country: "United States",
  },
  {
    sr: 4218,
    city: "Dahl Creek",
    city_code: "DCK",
    airport_name: "Dahl Creek Arpt",
    country: "United States",
  },
  {
    sr: 4220,
    city: "Dahlgren",
    city_code: "DGN",
    airport_name: "NAF",
    country: "United States",
  },
  {
    sr: 4222,
    city: "Dakar",
    city_code: "DKR",
    airport_name: "Yoff",
    country: "Senegal",
  },
  {
    sr: 4224,
    city: "Dakhla",
    city_code: "VIL",
    airport_name: "Dakhla",
    country: "Morocco",
  },
  {
    sr: 4226,
    city: "Dakhla Oasis",
    city_code: "DAK",
    airport_name: "Dakhla",
    country: "Egypt",
  },
  {
    sr: 4228,
    city: "Dalaman",
    city_code: "DLM",
    airport_name: "Dalaman",
    country: "Turkey",
  },
  {
    sr: 4230,
    city: "Dalanzadgad",
    city_code: "DLZ",
    airport_name: "Dalanzadgad",
    country: "Mongolia",
  },
  {
    sr: 4232,
    city: "Dalat",
    city_code: "DLI",
    airport_name: "Lienkhang",
    country: "Vietnam",
  },
  {
    sr: 4234,
    city: "Dalbandin",
    city_code: "DBA",
    airport_name: "Dalbandin",
    country: "Pakistan",
  },
  {
    sr: 4236,
    city: "Dalbertis",
    city_code: "DLB",
    airport_name: "Dalbertis",
    country: "Papua New Guinea",
  },
  {
    sr: 4238,
    city: "Dalby",
    city_code: "DBY",
    airport_name: "Dalby",
    country: "Australia",
  },
  {
    sr: 4240,
    city: "Dalgaranga",
    city_code: "DGD",
    airport_name: "Dalgaranga",
    country: "Australia",
  },
  {
    sr: 4242,
    city: "Dalhart",
    city_code: "DHT",
    airport_name: "Dalhart",
    country: "United States",
  },
  {
    sr: 4244,
    city: "Dali City",
    city_code: "DLU",
    airport_name: "Dali",
    country: "China",
  },
  {
    sr: 4246,
    city: "Dalian",
    city_code: "DLC",
    airport_name: "Dalian",
    country: "China",
  },
  {
    sr: 4250,
    city: "Dallas",
    city_code: "ADS",
    airport_name: "Addison Airport",
    country: "United States",
  },
  {
    sr: 4252,
    city: "Dallas",
    city_code: "DNE",
    airport_name: "Dallas North Airport",
    country: "United States",
  },
  {
    sr: 4254,
    city: "Dallas",
    city_code: "DFW",
    airport_name: "Dallas Ft Worth Intl",
    country: "United States",
  },
  {
    sr: 4256,
    city: "Dallas",
    city_code: "JDB",
    airport_name: "Downtown Heliport",
    country: "United States",
  },
  {
    sr: 4258,
    city: "Dallas",
    city_code: "DAL",
    airport_name: "Love Field",
    country: "United States",
  },
  {
    sr: 4260,
    city: "Dallas",
    city_code: "JMD",
    airport_name: "Market Centre H P",
    country: "United States",
  },
  {
    sr: 4262,
    city: "Dallas",
    city_code: "JNH",
    airport_name: "North Park Inn H P",
    country: "United States",
  },
  {
    sr: 4264,
    city: "Dallas",
    city_code: "RBD",
    airport_name: "Redbird",
    country: "United States",
  },
  {
    sr: 4266,
    city: "Daloa",
    city_code: "DJO",
    airport_name: "Daloa",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 4268,
    city: "Dalton",
    city_code: "DNN",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 4270,
    city: "Daly River",
    city_code: "DVR",
    airport_name: "Daly River",
    country: "Australia",
  },
  {
    sr: 4272,
    city: "Daly Waters",
    city_code: "DYW",
    airport_name: "Daly Waters",
    country: "Australia",
  },
  {
    sr: 4274,
    city: "Daman",
    city_code: "NMB",
    airport_name: "Daman",
    country: "India",
  },
  {
    sr: 4276,
    city: "Damascus",
    city_code: "DAM",
    airport_name: "International",
    country: "Syria",
  },
  {
    sr: 4278,
    city: "Dambula",
    city_code: "DBU",
    airport_name: "Dambulu Oya Tank",
    country: "Sri Lanka",
  },
  {
    sr: 4280,
    city: "Danane",
    city_code: "DNC",
    airport_name: "Danane",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 4284,
    city: "Danbury",
    city_code: "DXR",
    airport_name: "Danbury",
    country: "United States",
  },
  {
    sr: 4286,
    city: "Dandong",
    city_code: "DDG",
    airport_name: "Dandong",
    country: "China",
  },
  {
    sr: 4288,
    city: "Dang",
    city_code: "DNP",
    airport_name: "Dang",
    country: "Nepal",
  },
  {
    sr: 4290,
    city: "Danger Bay",
    city_code: "DGB",
    airport_name: "Danger Bay",
    country: "United States",
  },
  {
    sr: 4292,
    city: "Dangriga",
    city_code: "DGA",
    airport_name: "Dangriga",
    country: "Belize",
  },
  {
    sr: 4294,
    city: "Daniels Harbour",
    city_code: "YDH",
    airport_name: "Daniels Harbour",
    country: "Canada",
  },
  {
    sr: 4296,
    city: "Dansville",
    city_code: "DSV",
    airport_name: "Dansville",
    country: "United States",
  },
  {
    sr: 4298,
    city: "Danville",
    city_code: "DNV",
    airport_name: "Vermilion County",
    country: "United States",
  },
  {
    sr: 4300,
    city: "Danville",
    city_code: "DAN",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 4302,
    city: "Daparizo",
    city_code: "DAE",
    airport_name: "Daparizo",
    country: "India",
  },
  {
    sr: 4304,
    city: "Dar Es Salaam",
    city_code: "DAR",
    airport_name: "International",
    country: "Tanzania",
  },
  {
    sr: 4306,
    city: "Darchula",
    city_code: "DAP",
    airport_name: "Darchula",
    country: "Nepal",
  },
  {
    sr: 4308,
    city: "Dargaville",
    city_code: "DGR",
    airport_name: "Dargaville",
    country: "New Zealand",
  },
  {
    sr: 4310,
    city: "Darjeeling",
    city_code: "DAI",
    airport_name: "Darjeeling",
    country: "India",
  },
  {
    sr: 4312,
    city: "Darnley Island",
    city_code: "NLF",
    airport_name: "Darnley Island",
    country: "Australia",
  },
  {
    sr: 4314,
    city: "Daru",
    city_code: "DAU",
    airport_name: "Daru",
    country: "Papua New Guinea",
  },
  {
    sr: 4318,
    city: "Daru",
    city_code: "DSL",
    airport_name: "Daru",
    country: "Sierra Leone",
  },
  {
    sr: 4320,
    city: "Darwaz",
    city_code: "DAZ",
    airport_name: "Darwaz",
    country: "Afghanistan",
  },
  {
    sr: 4322,
    city: "Darwin",
    city_code: "DRW",
    airport_name: "Darwin",
    country: "Australia",
  },
  {
    sr: 4324,
    city: "Darwin",
    city_code: "XJG",
    airport_name: "Darwin Railway",
    country: "Australia",
  },
  {
    sr: 4326,
    city: "Dashoguz",
    city_code: "TAZ",
    airport_name: "Dashoguz",
    country: "Turkmenistan",
  },
  {
    sr: 4328,
    city: "Datadawai",
    city_code: "DTD",
    airport_name: "Datadawai",
    country: "Indonesia",
  },
  {
    sr: 4330,
    city: "Dathina",
    city_code: "DAH",
    airport_name: "Dathina",
    country: "Yemen",
  },
  {
    sr: 4332,
    city: "Datong",
    city_code: "DAT",
    airport_name: "Datong",
    country: "China",
  },
  {
    sr: 4334,
    city: "Dauan Island",
    city_code: "DAJ",
    airport_name: "Dauan Island",
    country: "Australia",
  },
  {
    sr: 4336,
    city: "Daugavpils",
    city_code: "DGP",
    airport_name: "Daugavpils",
    country: "Latvia",
  },
  {
    sr: 4338,
    city: "Daugo",
    city_code: "DGG",
    airport_name: "Daugo",
    country: "Papua New Guinea",
  },
  {
    sr: 4340,
    city: "Daup",
    city_code: "DAF",
    airport_name: "Daup",
    country: "Papua New Guinea",
  },
  {
    sr: 4342,
    city: "Dauphin",
    city_code: "YDN",
    airport_name: "Dauphin",
    country: "Canada",
  },
  {
    sr: 4344,
    city: "Davao",
    city_code: "DVO",
    airport_name: "Francisco Bangoy International Airport",
    country: "Philippines",
  },
  {
    sr: 4346,
    city: "Davenport",
    city_code: "DVN",
    airport_name: "Davenport",
    country: "United States",
  },
  {
    sr: 4348,
    city: "Davenport Downs",
    city_code: "DVP",
    airport_name: "Davenport Downs",
    country: "Australia",
  },
  {
    sr: 4352,
    city: "David",
    city_code: "DAV",
    airport_name: "Enrique Malek",
    country: "Panama",
  },
  {
    sr: 4354,
    city: "Davis Inlet",
    city_code: "YDI",
    airport_name: "Davis Inlet",
    country: "Canada",
  },
  {
    sr: 4356,
    city: "Dawadmi",
    city_code: "DWD",
    airport_name: "Dawadmi",
    country: "Saudi Arabia",
  },
  {
    sr: 4358,
    city: "Dawe",
    city_code: "TVY",
    airport_name: "Dawe",
    country: "Myanmar",
  },
  {
    sr: 4360,
    city: "Dawson City",
    city_code: "YDA",
    airport_name: "Dawson City",
    country: "Canada",
  },
  {
    sr: 4362,
    city: "Dawson Creek",
    city_code: "YDQ",
    airport_name: "Dawson Creek",
    country: "Canada",
  },
  {
    sr: 4364,
    city: "Daxian",
    city_code: "DAX",
    airport_name: "Daxian",
    country: "China",
  },
  {
    sr: 4366,
    city: "Daydream Is",
    city_code: "DDI",
    airport_name: "Daydream Is",
    country: "Australia",
  },
  {
    sr: 4368,
    city: "Dayong",
    city_code: "DYG",
    airport_name: "Dayong",
    country: "China",
  },
  {
    sr: 4370,
    city: "Dayton",
    city_code: "DAY",
    airport_name: "James Cox Dayton Intl",
    country: "United States",
  },
  {
    sr: 4372,
    city: "Dayton",
    city_code: "MGY",
    airport_name: "Montgomery Co",
    country: "United States",
  },
  {
    sr: 4374,
    city: "Dayton",
    city_code: "FFO",
    airport_name: "Patterson AFB",
    country: "United States",
  },
  {
    sr: 4376,
    city: "Dayton",
    city_code: "DWF",
    airport_name: "Wright AFB",
    country: "United States",
  },
  {
    sr: 4378,
    city: "Daytona Beach",
    city_code: "DAB",
    airport_name: "Regional",
    country: "United States",
  },
  {
    sr: 4380,
    city: "Dazu",
    city_code: "DZU",
    airport_name: "Dazu",
    country: "China",
  },
  {
    sr: 4382,
    city: "De Ridder",
    city_code: "DRI",
    airport_name: "Beauregard Parish",
    country: "United States",
  },
  {
    sr: 4386,
    city: "Dean River",
    city_code: "YRD",
    airport_name: "Dean River",
    country: "Canada",
  },
  {
    sr: 4388,
    city: "Dearborn",
    city_code: "DEO",
    airport_name: "Hyatt Regency H P",
    country: "United States",
  },
  {
    sr: 4390,
    city: "Dease Lake",
    city_code: "YDL",
    airport_name: "Dease Lake",
    country: "Canada",
  },
  {
    sr: 4392,
    city: "Death Valley",
    city_code: "DTH",
    airport_name: "Death Valley",
    country: "United States",
  },
  {
    sr: 4394,
    city: "Deauville",
    city_code: "DOL",
    airport_name: "St Gatien",
    country: "France",
  },
  {
    sr: 4396,
    city: "Debepare",
    city_code: "DBP",
    airport_name: "Debepare",
    country: "Papua New Guinea",
  },
  {
    sr: 4398,
    city: "Debra Marcos",
    city_code: "DBM",
    airport_name: "Debra Marcos",
    country: "Ethiopia",
  },
  {
    sr: 4400,
    city: "Debra Tabor",
    city_code: "DBT",
    airport_name: "Debra Tabor",
    country: "Ethiopia",
  },
  {
    sr: 4402,
    city: "Debrecen",
    city_code: "DEB",
    airport_name: "Debrecen",
    country: "Hungary",
  },
  {
    sr: 4404,
    city: "Decatur",
    city_code: "DCU",
    airport_name: "Pyor",
    country: "United States",
  },
  {
    sr: 4406,
    city: "Decatur",
    city_code: "DEC",
    airport_name: "Decatur Arpt",
    country: "United States",
  },
  {
    sr: 4408,
    city: "Decatur",
    city_code: "DCR",
    airport_name: "Decatur Hi Way",
    country: "United States",
  },
  {
    sr: 4410,
    city: "Decatur Island",
    city_code: "DTR",
    airport_name: "Decatur Island",
    country: "United States",
  },
  {
    sr: 4412,
    city: "Deception",
    city_code: "YGY",
    airport_name: "Deception",
    country: "Canada",
  },
  {
    sr: 4414,
    city: "Decimomannu",
    city_code: "DCI",
    airport_name: "Rafsu Decimomannu",
    country: "Italy",
  },
  {
    sr: 4416,
    city: "Decorah",
    city_code: "DEH",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 4420,
    city: "Dedougou",
    city_code: "DGU",
    airport_name: "Dedougou",
    country: "Burkina Faso",
  },
  {
    sr: 4422,
    city: "Deep Bay",
    city_code: "WDB",
    airport_name: "Deep Bay",
    country: "United States",
  },
  {
    sr: 4424,
    city: "Deer Lake",
    city_code: "YDF",
    airport_name: "Deer Lake",
    country: "Canada",
  },
  {
    sr: 4426,
    city: "Deer Lake",
    city_code: "YVZ",
    airport_name: "Deer Lake",
    country: "Canada",
  },
  {
    sr: 4428,
    city: "Deer Park",
    city_code: "DPK",
    airport_name: "Deer Park",
    country: "United States",
  },
  {
    sr: 4430,
    city: "Deering",
    city_code: "DRG",
    airport_name: "Deering",
    country: "United States",
  },
  {
    sr: 4432,
    city: "Defiance",
    city_code: "DFI",
    airport_name: "Memorial",
    country: "United States",
  },
  {
    sr: 4434,
    city: "Degahbur",
    city_code: "DGC",
    airport_name: "Degahbur",
    country: "Ethiopia",
  },
  {
    sr: 4436,
    city: "Dehra Dun",
    city_code: "DED",
    airport_name: "Dehra Dun",
    country: "India",
  },
  {
    sr: 4438,
    city: "Deirezzor",
    city_code: "DEZ",
    airport_name: "Al Jafrah",
    country: "Syria",
  },
  {
    sr: 4440,
    city: "Del Rio",
    city_code: "DRT",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 4442,
    city: "Del Rio",
    city_code: "DLF",
    airport_name: "Laughlin AFB",
    country: "United States",
  },
  {
    sr: 4444,
    city: "Delhi",
    city_code: "DEL",
    airport_name: "Indira Gandhi Intl",
    country: "India",
  },
  {
    sr: 4446,
    city: "Deline",
    city_code: "YWJ",
    airport_name: "Deline",
    country: "Canada",
  },
  {
    sr: 4448,
    city: "Delissaville",
    city_code: "DLV",
    airport_name: "Delissaville",
    country: "Australia",
  },
  {
    sr: 4450,
    city: "Delta",
    city_code: "DTA",
    airport_name: "Delta",
    country: "United States",
  },
  {
    sr: 4454,
    city: "Delta Downs",
    city_code: "DDN",
    airport_name: "Delta Downs",
    country: "Australia",
  },
  {
    sr: 4456,
    city: "Delta Junction",
    city_code: "DJN",
    airport_name: "Delta Junction",
    country: "United States",
  },
  {
    sr: 4458,
    city: "Dembidollo",
    city_code: "DEM",
    airport_name: "Dembidollo",
    country: "Ethiopia",
  },
  {
    sr: 4460,
    city: "Deming",
    city_code: "DMN",
    airport_name: "Deming",
    country: "United States",
  },
  {
    sr: 4462,
    city: "Den Helder",
    city_code: "DHR",
    airport_name: "De Kooy",
    country: "Netherlands",
  },
  {
    sr: 4464,
    city: "Denham",
    city_code: "DNM",
    airport_name: "Denham",
    country: "Australia",
  },
  {
    sr: 4466,
    city: "Deniliquin",
    city_code: "DNQ",
    airport_name: "Deniliquin",
    country: "Australia",
  },
  {
    sr: 4468,
    city: "Denis Island",
    city_code: "DEI",
    airport_name: "Denis Island",
    country: "Seychelles",
  },
  {
    sr: 4470,
    city: "Denison",
    city_code: "DNS",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 4472,
    city: "Denizli",
    city_code: "DNZ",
    airport_name: "Cardak",
    country: "Turkey",
  },
  {
    sr: 4474,
    city: "Denpasar Bali",
    city_code: "DPS",
    airport_name: "Ngurah Rai",
    country: "Indonesia",
  },
  {
    sr: 4476,
    city: "Denver",
    city_code: "APA",
    airport_name: "Arapahoe Co",
    country: "United States",
  },
  {
    sr: 4478,
    city: "Denver",
    city_code: "BFK",
    airport_name: "Buckley ANGB",
    country: "United States",
  },
  {
    sr: 4480,
    city: "Denver",
    city_code: "DEN",
    airport_name: "Denver International",
    country: "United States",
  },
  {
    sr: 4482,
    city: "Deparizo",
    city_code: "DEP",
    airport_name: "Deparizo",
    country: "India",
  },
  {
    sr: 4484,
    city: "Dera Ghazi Khan",
    city_code: "DEA",
    airport_name: "Dera Ghazi Khan",
    country: "Pakistan",
  },
  {
    sr: 4488,
    city: "Dera Ismail Khan",
    city_code: "DSK",
    airport_name: "Dera Ismail Khan",
    country: "Pakistan",
  },
  {
    sr: 4490,
    city: "Derby",
    city_code: "DRB",
    airport_name: "Derby",
    country: "Australia",
  },
  {
    sr: 4492,
    city: "Derim",
    city_code: "DER",
    airport_name: "Derim",
    country: "Papua New Guinea",
  },
  {
    sr: 4494,
    city: "Derna",
    city_code: "DNF",
    airport_name: "Martuba",
    country: "Libya",
  },
  {
    sr: 4496,
    city: "Des Moines",
    city_code: "DSM",
    airport_name: "Des Moines",
    country: "United States",
  },
  {
    sr: 4498,
    city: "Desolation Sound",
    city_code: "YDS",
    airport_name: "Desolation Sound",
    country: "Canada",
  },
  {
    sr: 4500,
    city: "Desroches",
    city_code: "DES",
    airport_name: "Desroches",
    country: "Seychelles",
  },
  {
    sr: 4502,
    city: "Dessau",
    city_code: "ZSU",
    airport_name: "Dessau Railway Stn",
    country: "Germany",
  },
  {
    sr: 4504,
    city: "Dessie",
    city_code: "DSE",
    airport_name: "Combolcha",
    country: "Ethiopia",
  },
  {
    sr: 4506,
    city: "Destin",
    city_code: "DSI",
    airport_name: "Destin",
    country: "United States",
  },
  {
    sr: 4508,
    city: "Detroit",
    city_code: "DET",
    airport_name: "Detroit City",
    country: "United States",
  },
  {
    sr: 4510,
    city: "Detroit",
    city_code: "DTW",
    airport_name: "Wayne County",
    country: "United States",
  },
  {
    sr: 4512,
    city: "Detroit",
    city_code: "YIP",
    airport_name: "Willow Run",
    country: "United States",
  },
  {
    sr: 4514,
    city: "Detroit Lakes",
    city_code: "DTL",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 4516,
    city: "Deux Alpes",
    city_code: "DXA",
    airport_name: "Deux Alpes",
    country: "France",
  },
  {
    sr: 4518,
    city: "Deva",
    city_code: "DVA",
    airport_name: "Deva",
    country: "Romania",
  },
  {
    sr: 4522,
    city: "Devils Lake",
    city_code: "DVL",
    airport_name: "Devils Lake",
    country: "United States",
  },
  {
    sr: 4524,
    city: "Devonport",
    city_code: "DPO",
    airport_name: "Devonport",
    country: "Australia",
  },
  {
    sr: 4526,
    city: "Dewsbury",
    city_code: "ZEQ",
    airport_name: "Bus Station",
    country: "United Kingdom",
  },
  {
    sr: 4528,
    city: "Dezful",
    city_code: "DEF",
    airport_name: "Dezful",
    country: "Iran",
  },
  {
    sr: 4530,
    city: "Dhahran",
    city_code: "DHA",
    airport_name: "Dhahran",
    country: "Saudi Arabia",
  },
  {
    sr: 4532,
    city: "Dhaka",
    city_code: "DAC",
    airport_name: "Zia International",
    country: "Bangladesh",
  },
  {
    sr: 4534,
    city: "Dhala",
    city_code: "DHL",
    airport_name: "Dhala",
    country: "Yemen",
  },
  {
    sr: 4536,
    city: "Dhamar",
    city_code: "DMR",
    airport_name: "Dhamar",
    country: "Yemen",
  },
  {
    sr: 4538,
    city: "Dhanbad",
    city_code: "DBD",
    airport_name: "Dhanbad",
    country: "India",
  },
  {
    sr: 4540,
    city: "Dhangarhi",
    city_code: "DHI",
    airport_name: "Dhangarhi",
    country: "Nepal",
  },
  {
    sr: 4542,
    city: "Dharamsala",
    city_code: "DHM",
    airport_name: "Gaggal Airport",
    country: "India",
  },
  {
    sr: 4544,
    city: "Diamantina Lakes",
    city_code: "DYM",
    airport_name: "Diamantina Lakes",
    country: "Australia",
  },
  {
    sr: 4546,
    city: "Diamantino",
    city_code: "DMT",
    airport_name: "Diamantino",
    country: "Brazil",
  },
  {
    sr: 4548,
    city: "Dianopolis",
    city_code: "DNO",
    airport_name: "Dianopolis",
    country: "Brazil",
  },
  {
    sr: 4550,
    city: "Diapaga",
    city_code: "DIP",
    airport_name: "Diapaga",
    country: "Burkina Faso",
  },
  {
    sr: 4552,
    city: "Diavik",
    city_code: "DVK",
    airport_name: "Diavik",
    country: "Canada",
  },
  {
    sr: 4556,
    city: "Dibaa",
    city_code: "BYB",
    airport_name: "Dibaa",
    country: "Oman",
  },
  {
    sr: 4558,
    city: "Dibrugarh",
    city_code: "DIB",
    airport_name: "Chabua",
    country: "India",
  },
  {
    sr: 4560,
    city: "Dickinson",
    city_code: "DIK",
    airport_name: "Dickinson",
    country: "United States",
  },
  {
    sr: 4562,
    city: "Dickwella",
    city_code: "DIW",
    airport_name: "Mawella Lagoon",
    country: "Sri Lanka",
  },
  {
    sr: 4564,
    city: "Diebougou",
    city_code: "XDE",
    airport_name: "Diebougou",
    country: "Burkina Faso",
  },
  {
    sr: 4566,
    city: "Dien Bien Phu",
    city_code: "DIN",
    airport_name: "Dien Bien",
    country: "Vietnam",
  },
  {
    sr: 4568,
    city: "Dieppe",
    city_code: "DPE",
    airport_name: "Dieppe",
    country: "France",
  },
  {
    sr: 4570,
    city: "Digby",
    city_code: "YDG",
    airport_name: "Digby",
    country: "Canada",
  },
  {
    sr: 4572,
    city: "Dijon",
    city_code: "DIJ",
    airport_name: "Dijon",
    country: "France",
  },
  {
    sr: 4574,
    city: "Dikson",
    city_code: "DKS",
    airport_name: "Dikson",
    country: "Russia",
  },
  {
    sr: 4576,
    city: "Dilasag",
    city_code: "DSG",
    airport_name: "Dilasag",
    country: "Philippines",
  },
  {
    sr: 4578,
    city: "Dili",
    city_code: "DIC",
    airport_name: "Dili",
    country: "Timor Leste",
  },
  {
    sr: 4580,
    city: "Dili",
    city_code: "DIL",
    airport_name: "Comoro",
    country: "Indonesia",
  },
  {
    sr: 4582,
    city: "Dillingham",
    city_code: "DLG",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 4584,
    city: "Dillon",
    city_code: "DLN",
    airport_name: "Dillon",
    country: "United States",
  },
  {
    sr: 4586,
    city: "Dillon",
    city_code: "DLL",
    airport_name: "Dillon",
    country: "United States",
  },
  {
    sr: 4590,
    city: "Dillons Bay",
    city_code: "DLY",
    airport_name: "Dillons Bay",
    country: "Vanuatu",
  },
  {
    sr: 4592,
    city: "Dimapur",
    city_code: "DMU",
    airport_name: "Dimapur",
    country: "India",
  },
  {
    sr: 4594,
    city: "Dimbokro",
    city_code: "DIM",
    airport_name: "Dimbokro",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 4596,
    city: "Dinangat",
    city_code: "DNU",
    airport_name: "Dinangat",
    country: "Papua New Guinea",
  },
  {
    sr: 4598,
    city: "Dinard",
    city_code: "DNR",
    airport_name: "Pleurtuit",
    country: "France",
  },
  {
    sr: 4600,
    city: "Dinder",
    city_code: "DNX",
    airport_name: "Galegu",
    country: "Sudan",
  },
  {
    sr: 4602,
    city: "Diomede Island",
    city_code: "DIO",
    airport_name: "Diomede Island",
    country: "United States",
  },
  {
    sr: 4604,
    city: "Dios",
    city_code: "DOS",
    airport_name: "Dios",
    country: "Papua New Guinea",
  },
  {
    sr: 4606,
    city: "Dipolog",
    city_code: "DPL",
    airport_name: "Dipolog",
    country: "Philippines",
  },
  {
    sr: 4608,
    city: "Diqing",
    city_code: "DIG",
    airport_name: "Diqing",
    country: "China",
  },
  {
    sr: 4610,
    city: "Dire Dawa",
    city_code: "DIR",
    airport_name: "Aba Tenna D Yilma",
    country: "Ethiopia",
  },
  {
    sr: 4612,
    city: "Dirico",
    city_code: "DRC",
    airport_name: "Dirico",
    country: "Angola",
  },
  {
    sr: 4614,
    city: "Dirranbandi",
    city_code: "DRN",
    airport_name: "Dirranbandi",
    country: "Australia",
  },
  {
    sr: 4616,
    city: "Disneyland Paris",
    city_code: "DLP",
    airport_name: "Disneyland Paris",
    country: "France",
  },
  {
    sr: 4618,
    city: "Diu",
    city_code: "DIU",
    airport_name: "Diu",
    country: "India",
  },
  {
    sr: 4620,
    city: "Divinopolis",
    city_code: "DIQ",
    airport_name: "Divinopolis",
    country: "Brazil",
  },
  {
    sr: 4624,
    city: "Divo",
    city_code: "DIV",
    airport_name: "Divo",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 4626,
    city: "Dixie",
    city_code: "DXD",
    airport_name: "Dixie",
    country: "Australia",
  },
  {
    sr: 4628,
    city: "Diyarbakir",
    city_code: "DIY",
    airport_name: "Diyarbakir",
    country: "Turkey",
  },
  {
    sr: 4630,
    city: "Djambala",
    city_code: "DJM",
    airport_name: "Djambala",
    country: "Congo",
  },
  {
    sr: 4632,
    city: "Djanet",
    city_code: "DJG",
    airport_name: "Inedbirenne",
    country: "Algeria",
  },
  {
    sr: 4634,
    city: "Djerba",
    city_code: "DJE",
    airport_name: "Djerba Zarzis",
    country: "Tunisia",
  },
  {
    sr: 4636,
    city: "Djibo",
    city_code: "XDJ",
    airport_name: "Djibo",
    country: "Burkina Faso",
  },
  {
    sr: 4638,
    city: "Djibouti",
    city_code: "JIB",
    airport_name: "Ambouli",
    country: "Djibouti",
  },
  {
    sr: 4640,
    city: "Djoemoe",
    city_code: "DOE",
    airport_name: "Djoemoe",
    country: "Suriname",
  },
  {
    sr: 4642,
    city: "Djougou",
    city_code: "DJA",
    airport_name: "Djougou",
    country: "Benin",
  },
  {
    sr: 4644,
    city: "Djupivogur",
    city_code: "DJU",
    airport_name: "Djupivogur",
    country: "Iceland",
  },
  {
    sr: 4646,
    city: "Dnepropetrovsk",
    city_code: "DNK",
    airport_name: "Dnepropetrovsk",
    country: "Ukraine",
  },
  {
    sr: 4648,
    city: "Doany",
    city_code: "DOA",
    airport_name: "Doany",
    country: "Madagascar",
  },
  {
    sr: 4650,
    city: "Dobo",
    city_code: "DOB",
    airport_name: "Dobo Airport",
    country: "Indonesia",
  },
  {
    sr: 4652,
    city: "Doc Creek",
    city_code: "YDX",
    airport_name: "Doc Creek",
    country: "Canada",
  },
  {
    sr: 4654,
    city: "Docker River",
    city_code: "DKV",
    airport_name: "Docker River",
    country: "Australia",
  },
  {
    sr: 4658,
    city: "Dodge City",
    city_code: "DDC",
    airport_name: "Dodge City Municipal",
    country: "United States",
  },
  {
    sr: 4660,
    city: "Dodoima",
    city_code: "DDM",
    airport_name: "Dodoima",
    country: "Papua New Guinea",
  },
  {
    sr: 4662,
    city: "Dodoma",
    city_code: "DOD",
    airport_name: "Dodoma",
    country: "Tanzania",
  },
  {
    sr: 4664,
    city: "Doha",
    city_code: "DOH",
    airport_name: "Doha",
    country: "Qatar",
  },
  {
    sr: 4666,
    city: "Doha",
    city_code: "XOZ",
    airport_name: "Doha Free Zone",
    country: "Qatar",
  },
  {
    sr: 4668,
    city: "Doini",
    city_code: "DOI",
    airport_name: "Doini",
    country: "Papua New Guinea",
  },
  {
    sr: 4670,
    city: "Dolbeau",
    city_code: "YDO",
    airport_name: "St Methode",
    country: "Canada",
  },
  {
    sr: 4672,
    city: "Dole",
    city_code: "DLE",
    airport_name: "Tavaux",
    country: "France",
  },
  {
    sr: 4674,
    city: "Dolomi",
    city_code: "DLO",
    airport_name: "Dolomi",
    country: "United States",
  },
  {
    sr: 4676,
    city: "Dolpa",
    city_code: "DOP",
    airport_name: "Dolpa",
    country: "Nepal",
  },
  {
    sr: 4678,
    city: "Dominica",
    city_code: "DCF",
    airport_name: "Cane Field",
    country: "Dominica",
  },
  {
    sr: 4680,
    city: "Dominica",
    city_code: "DOM",
    airport_name: "Melville Hall",
    country: "Dominica",
  },
  {
    sr: 4682,
    city: "Doncaster",
    city_code: "DSA",
    airport_name: "Robin Hood International",
    country: "United Kingdom",
  },
  {
    sr: 4684,
    city: "Donegal",
    city_code: "CFN",
    airport_name: "Donegal",
    country: "Ireland",
  },
  {
    sr: 4686,
    city: "Donetsk",
    city_code: "DOK",
    airport_name: "Donetsk",
    country: "Ukraine",
  },
  {
    sr: 4688,
    city: "Dongara",
    city_code: "DOX",
    airport_name: "Dongara",
    country: "Australia",
  },
  {
    sr: 4692,
    city: "Dongguan",
    city_code: "DGM",
    airport_name: "Dongguan",
    country: "China",
  },
  {
    sr: 4694,
    city: "Dongola",
    city_code: "DOG",
    airport_name: "Dongola",
    country: "Sudan",
  },
  {
    sr: 4696,
    city: "Dongsheng",
    city_code: "DSN",
    airport_name: "Dongsheng",
    country: "China",
  },
  {
    sr: 4698,
    city: "Dongying",
    city_code: "DOY",
    airport_name: "Dongying",
    country: "China",
  },
  {
    sr: 4700,
    city: "Doomadgee",
    city_code: "DMD",
    airport_name: "Doomadgee",
    country: "Australia",
  },
  {
    sr: 4702,
    city: "Doongan",
    city_code: "DNG",
    airport_name: "Doongan",
    country: "Australia",
  },
  {
    sr: 4704,
    city: "Dora Bay",
    city_code: "DOF",
    airport_name: "Dora Bay",
    country: "United States",
  },
  {
    sr: 4706,
    city: "Dorado",
    city_code: "DDP",
    airport_name: "Dorado Beach",
    country: "Puerto Rico",
  },
  {
    sr: 4708,
    city: "Dori",
    city_code: "DOR",
    airport_name: "Dori",
    country: "Burkina Faso",
  },
  {
    sr: 4710,
    city: "Dornoch",
    city_code: "DOC",
    airport_name: "Dornoch",
    country: "United Kingdom",
  },
  {
    sr: 4712,
    city: "Dorobisoro",
    city_code: "DOO",
    airport_name: "Dorobisoro",
    country: "Papua New Guinea",
  },
  {
    sr: 4714,
    city: "Dortmund",
    city_code: "DTM",
    airport_name: "Dortmund",
    country: "Germany",
  },
  {
    sr: 4716,
    city: "Dorunda Station",
    city_code: "DRD",
    airport_name: "Dorunda Station",
    country: "Australia",
  },
  {
    sr: 4718,
    city: "Dos Lagunas",
    city_code: "DON",
    airport_name: "Dos Lagunas",
    country: "Guatemala",
  },
  {
    sr: 4720,
    city: "Dothan",
    city_code: "DHN",
    airport_name: "Dothan Arpt",
    country: "United States",
  },
  {
    sr: 4722,
    city: "Douala",
    city_code: "DLA",
    airport_name: "Douala",
    country: "Cameroon",
  },
  {
    sr: 4726,
    city: "Douglas",
    city_code: "DUG",
    airport_name: "Bisbee Douglas Intl",
    country: "United States",
  },
  {
    sr: 4728,
    city: "Douglas",
    city_code: "DGL",
    airport_name: "Douglas Municipal",
    country: "United States",
  },
  {
    sr: 4730,
    city: "Douglas",
    city_code: "DGW",
    airport_name: "Converse County",
    country: "United States",
  },
  {
    sr: 4732,
    city: "Douglas",
    city_code: "DGF",
    airport_name: "Bisbee douglas International",
    country: "United States",
  },
  {
    sr: 4734,
    city: "Dourados",
    city_code: "DOU",
    airport_name: "Dourados",
    country: "Brazil",
  },
  {
    sr: 4736,
    city: "Dover Cheswold",
    city_code: "DOV",
    airport_name: "Dover AFB",
    country: "United States",
  },
  {
    sr: 4738,
    city: "Downey",
    city_code: "JDY",
    airport_name: "Heliport",
    country: "United States",
  },
  {
    sr: 4740,
    city: "Doylestown",
    city_code: "DYL",
    airport_name: "Doylestown",
    country: "United States",
  },
  {
    sr: 4742,
    city: "Drama",
    city_code: "DRM",
    airport_name: "Drama",
    country: "Greece",
  },
  {
    sr: 4744,
    city: "Drayton Valley",
    city_code: "YDC",
    airport_name: "Industrial Airport",
    country: "Canada",
  },
  {
    sr: 4746,
    city: "Dresden",
    city_code: "DRS",
    airport_name: "Dresden Arpt",
    country: "Germany",
  },
  {
    sr: 4748,
    city: "Drietabbetje",
    city_code: "DRJ",
    airport_name: "Drietabbetje",
    country: "Suriname",
  },
  {
    sr: 4750,
    city: "Drift River",
    city_code: "DRF",
    airport_name: "Drift River",
    country: "United States",
  },
  {
    sr: 4752,
    city: "Drumduff",
    city_code: "DFP",
    airport_name: "Drumduff",
    country: "Australia",
  },
  {
    sr: 4754,
    city: "Drummond",
    city_code: "DRU",
    airport_name: "Drummond",
    country: "United States",
  },
  {
    sr: 4756,
    city: "Drummond Island",
    city_code: "DRE",
    airport_name: "Drummond Island",
    country: "United States",
  },
  {
    sr: 4760,
    city: "Dryden",
    city_code: "YHD",
    airport_name: "Municipal",
    country: "Canada",
  },
  {
    sr: 4762,
    city: "Drysdale River",
    city_code: "DRY",
    airport_name: "Drysdale River",
    country: "Australia",
  },
  {
    sr: 4764,
    city: "Dschang",
    city_code: "DSC",
    airport_name: "Dschang",
    country: "Cameroon",
  },
  {
    sr: 4766,
    city: "Dubai",
    city_code: "DXB",
    airport_name: "Dubai",
    country: "United Arab Emirates",
  },
  {
    sr: 4768,
    city: "Dubbo",
    city_code: "DBO",
    airport_name: "Dubbo",
    country: "Australia",
  },
  {
    sr: 4770,
    city: "Dublin",
    city_code: "DBN",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 4772,
    city: "Dublin",
    city_code: "DUB",
    airport_name: "Dublin",
    country: "Ireland",
  },
  {
    sr: 4774,
    city: "Dublin",
    city_code: "PSK",
    airport_name: "New River Valley",
    country: "United States",
  },
  {
    sr: 4776,
    city: "Dubois",
    city_code: "DBS",
    airport_name: "Dubois",
    country: "United States",
  },
  {
    sr: 4778,
    city: "Dubois",
    city_code: "DUJ",
    airport_name: "Jefferson County",
    country: "United States",
  },
  {
    sr: 4780,
    city: "Dubrovnik",
    city_code: "DBV",
    airport_name: "Dubrovnik",
    country: "Croatia  Hrvatska",
  },
  {
    sr: 4782,
    city: "Dubuque",
    city_code: "DBQ",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 4784,
    city: "Duck",
    city_code: "DUF",
    airport_name: "Pine Island Airport",
    country: "United States",
  },
  {
    sr: 4786,
    city: "Dugong",
    city_code: "DGK",
    airport_name: "Dugong",
    country: "Mozambique",
  },
  {
    sr: 4788,
    city: "Dugway",
    city_code: "DPG",
    airport_name: "Michael AAF",
    country: "United States",
  },
  {
    sr: 4790,
    city: "Dukuduk",
    city_code: "DUK",
    airport_name: "Dukuduk",
    country: "South Africa",
  },
  {
    sr: 4794,
    city: "Dulkaninna",
    city_code: "DLK",
    airport_name: "Dulkaninna",
    country: "Australia",
  },
  {
    sr: 4796,
    city: "Duluth",
    city_code: "DLH",
    airport_name: "Duluth International",
    country: "United States",
  },
  {
    sr: 4798,
    city: "Duluth",
    city_code: "LKI",
    airport_name: "Lakeside USAF",
    country: "United States",
  },
  {
    sr: 4800,
    city: "Dumaguete",
    city_code: "DGT",
    airport_name: "Dumaguete",
    country: "Philippines",
  },
  {
    sr: 4802,
    city: "Dumai",
    city_code: "DUM",
    airport_name: "Pinang Kampai",
    country: "Indonesia",
  },
  {
    sr: 4804,
    city: "Dumpu",
    city_code: "DPU",
    airport_name: "Dumpu",
    country: "Papua New Guinea",
  },
  {
    sr: 4806,
    city: "Dunbar",
    city_code: "DNB",
    airport_name: "Dunbar",
    country: "Australia",
  },
  {
    sr: 4808,
    city: "Duncan",
    city_code: "DUC",
    airport_name: "Halliburton Field",
    country: "United States",
  },
  {
    sr: 4810,
    city: "Duncan Town",
    city_code: "DCT",
    airport_name: "Duncan Town",
    country: "Bahamas",
  },
  {
    sr: 4812,
    city: "Duncan Quam",
    city_code: "DUQ",
    airport_name: "Duncan Quam",
    country: "Canada",
  },
  {
    sr: 4814,
    city: "Dundas",
    city_code: "DUN",
    airport_name: "Dundas",
    country: "Greenland",
  },
  {
    sr: 4816,
    city: "Dundee",
    city_code: "DND",
    airport_name: "Dundee",
    country: "United Kingdom",
  },
  {
    sr: 4818,
    city: "Dundee",
    city_code: "ZDU",
    airport_name: "ScotRail",
    country: "United Kingdom",
  },
  {
    sr: 4820,
    city: "Dundo",
    city_code: "DUE",
    airport_name: "Dundo",
    country: "Angola",
  },
  {
    sr: 4822,
    city: "Dunedin",
    city_code: "DUD",
    airport_name: "Dunedin International",
    country: "New Zealand",
  },
  {
    sr: 4824,
    city: "Dunhuang",
    city_code: "DNH",
    airport_name: "Dunhuang",
    country: "China",
  },
  {
    sr: 4828,
    city: "Dunk Island",
    city_code: "DKI",
    airport_name: "Dunk Island",
    country: "Australia",
  },
  {
    sr: 4830,
    city: "Dunkirk",
    city_code: "DKK",
    airport_name: "Dunkirk",
    country: "United States",
  },
  {
    sr: 4832,
    city: "Durango",
    city_code: "AMK",
    airport_name: "Animas Airpark",
    country: "United States",
  },
  {
    sr: 4834,
    city: "Durango",
    city_code: "DRO",
    airport_name: "La Plata",
    country: "United States",
  },
  {
    sr: 4836,
    city: "Durango",
    city_code: "DGO",
    airport_name: "Guadalupe Victoria",
    country: "Mexico",
  },
  {
    sr: 4838,
    city: "Durant",
    city_code: "DUA",
    airport_name: "Eaker",
    country: "United States",
  },
  {
    sr: 4840,
    city: "Durazno",
    city_code: "DZO",
    airport_name: "Durazno",
    country: "Uruguay",
  },
  {
    sr: 4842,
    city: "Durban",
    city_code: "DUR",
    airport_name: "Durban International",
    country: "South Africa",
  },
  {
    sr: 4844,
    city: "Durban",
    city_code: "VIR",
    airport_name: "Virginia",
    country: "South Africa",
  },
  {
    sr: 4846,
    city: "Durham Downs",
    city_code: "DHD",
    airport_name: "Durham Downs",
    country: "Australia",
  },
  {
    sr: 4848,
    city: "Durham Tees Valley",
    city_code: "MME",
    airport_name: "Durham Tees Valley",
    country: "United Kingdom",
  },
  {
    sr: 4850,
    city: "Durrie",
    city_code: "DRR",
    airport_name: "Durrie",
    country: "Australia",
  },
  {
    sr: 4852,
    city: "Dushanbe",
    city_code: "DYU",
    airport_name: "dyu",
    country: "Tajikistan",
  },
  {
    sr: 4854,
    city: "Dusseldorf",
    city_code: "DUS",
    airport_name: "Dusseldorf International Airport  Rhein Ruhr",
    country: "Germany",
  },
  {
    sr: 4856,
    city: "Dusseldorf",
    city_code: "MGL",
    airport_name: "Moenchen Gl DUS Exp",
    country: "Germany",
  },
  {
    sr: 4858,
    city: "Dusseldorf",
    city_code: "NRN",
    airport_name: "Weeze",
    country: "Germany",
  },
  {
    sr: 4862,
    city: "Dusseldorf",
    city_code: "QDU",
    airport_name: "Station",
    country: "Germany",
  },
  {
    sr: 4864,
    city: "Dutch Harbor",
    city_code: "DUT",
    airport_name: "Emergency Field",
    country: "United States",
  },
  {
    sr: 4866,
    city: "Dwangwa",
    city_code: "DWA",
    airport_name: "Dwangwa",
    country: "Malawi",
  },
  {
    sr: 4868,
    city: "Dysart",
    city_code: "DYA",
    airport_name: "Dysart",
    country: "Australia",
  },
  {
    sr: 4870,
    city: "Dzaoudzi",
    city_code: "DZA",
    airport_name: "Dzaoudzi",
    country: "Comoros",
  },
  {
    sr: 4872,
    city: "Eagle",
    city_code: "EAA",
    airport_name: "Eagle",
    country: "United States",
  },
  {
    sr: 4874,
    city: "Eagle Lake",
    city_code: "ELA",
    airport_name: "Eagle Lake",
    country: "United States",
  },
  {
    sr: 4876,
    city: "Eagle Pass",
    city_code: "EGP",
    airport_name: "Maverick Co",
    country: "United States",
  },
  {
    sr: 4878,
    city: "Eagle River",
    city_code: "EGV",
    airport_name: "Eagle River",
    country: "United States",
  },
  {
    sr: 4880,
    city: "Earlton",
    city_code: "YXR",
    airport_name: "Earlton",
    country: "Canada",
  },
  {
    sr: 4882,
    city: "East Fork",
    city_code: "EFO",
    airport_name: "East Fork",
    country: "United States",
  },
  {
    sr: 4884,
    city: "East Hampton",
    city_code: "HTO",
    airport_name: "East Hampton",
    country: "United States",
  },
  {
    sr: 4886,
    city: "East Hartford",
    city_code: "EHT",
    airport_name: "Rentschler",
    country: "United States",
  },
  {
    sr: 4888,
    city: "East London",
    city_code: "ELS",
    airport_name: "East London",
    country: "South Africa",
  },
  {
    sr: 4890,
    city: "East Main",
    city_code: "ZEM",
    airport_name: "East Main",
    country: "Canada",
  },
  {
    sr: 4892,
    city: "East Stroudsburg",
    city_code: "ESP",
    airport_name: "Birchwood Pocono",
    country: "United States",
  },
  {
    sr: 4896,
    city: "East Tawas",
    city_code: "ECA",
    airport_name: "Emmet County Airport",
    country: "United States",
  },
  {
    sr: 4898,
    city: "Easter Island",
    city_code: "IPC",
    airport_name: "Mataveri Intl",
    country: "Chile",
  },
  {
    sr: 4900,
    city: "Eastland",
    city_code: "ETN",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 4902,
    city: "Easton",
    city_code: "ESN",
    airport_name: "Easton",
    country: "United States",
  },
  {
    sr: 4904,
    city: "Easton",
    city_code: "ESW",
    airport_name: "State",
    country: "United States",
  },
  {
    sr: 4906,
    city: "Eastsound",
    city_code: "ESD",
    airport_name: "Orcas Island",
    country: "United States",
  },
  {
    sr: 4908,
    city: "Eau Claire",
    city_code: "EAU",
    airport_name: "Eau Claire",
    country: "United States",
  },
  {
    sr: 4910,
    city: "Ebadon",
    city_code: "EBN",
    airport_name: "Ebadon",
    country: "Marshall Islands",
  },
  {
    sr: 4912,
    city: "Ebolowa",
    city_code: "EBW",
    airport_name: "Ebolowa",
    country: "Cameroon",
  },
  {
    sr: 4914,
    city: "Ebon",
    city_code: "EBO",
    airport_name: "Ebon Airport",
    country: "Marshall Islands",
  },
  {
    sr: 4916,
    city: "Echuca",
    city_code: "ECH",
    airport_name: "Echuca",
    country: "Australia",
  },
  {
    sr: 4918,
    city: "Eday",
    city_code: "EOI",
    airport_name: "Eday",
    country: "United Kingdom",
  },
  {
    sr: 4920,
    city: "Edenton",
    city_code: "EDE",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 4922,
    city: "Edgewood",
    city_code: "EDG",
    airport_name: "Weide AAF",
    country: "United States",
  },
  {
    sr: 4924,
    city: "Edinburgh",
    city_code: "EDI",
    airport_name: "Turnhouse",
    country: "United Kingdom",
  },
  {
    sr: 4926,
    city: "Edinburgh",
    city_code: "ZXE",
    airport_name: "ScotRail",
    country: "United Kingdom",
  },
  {
    sr: 4930,
    city: "Edmonton",
    city_code: "YEG",
    airport_name: "International",
    country: "Canada",
  },
  {
    sr: 4932,
    city: "Edmonton",
    city_code: "YXD",
    airport_name: "Edmonton City Center",
    country: "Canada",
  },
  {
    sr: 4934,
    city: "Edmonton",
    city_code: "YED",
    airport_name: "Namao Fld",
    country: "Canada",
  },
  {
    sr: 4936,
    city: "Edna Bay",
    city_code: "EDA",
    airport_name: "Edna Bay",
    country: "United States",
  },
  {
    sr: 4938,
    city: "Edremit Korfez",
    city_code: "EDO",
    airport_name: "Edremit Korfez",
    country: "Turkey",
  },
  {
    sr: 4940,
    city: "Edson",
    city_code: "YET",
    airport_name: "Edson",
    country: "Canada",
  },
  {
    sr: 4942,
    city: "Edward River",
    city_code: "EDR",
    airport_name: "Edward River",
    country: "Australia",
  },
  {
    sr: 4944,
    city: "Edwards AFB",
    city_code: "EDW",
    airport_name: "Edwards AFB",
    country: "United States",
  },
  {
    sr: 4946,
    city: "Eek",
    city_code: "EEK",
    airport_name: "Eek",
    country: "United States",
  },
  {
    sr: 4948,
    city: "Efogi",
    city_code: "EFG",
    airport_name: "Efogi",
    country: "Papua New Guinea",
  },
  {
    sr: 4950,
    city: "Egegik",
    city_code: "EGX",
    airport_name: "Egegik",
    country: "United States",
  },
  {
    sr: 4952,
    city: "Egilsstadir",
    city_code: "EGS",
    airport_name: "Egilsstadir",
    country: "Iceland",
  },
  {
    sr: 4954,
    city: "Eia",
    city_code: "EIA",
    airport_name: "Popondetta",
    country: "Papua New Guinea",
  },
  {
    sr: 4956,
    city: "Eight Fathom Bight",
    city_code: "EFB",
    airport_name: "Eight Fathom Bight",
    country: "United States",
  },
  {
    sr: 4958,
    city: "Eil",
    city_code: "HCM",
    airport_name: "Eil",
    country: "Somalia",
  },
  {
    sr: 4960,
    city: "Ein Yahav",
    city_code: "EIY",
    airport_name: "Ein Yahav",
    country: "Israel",
  },
  {
    sr: 4964,
    city: "Einasleigh",
    city_code: "EIH",
    airport_name: "Einasleigh",
    country: "Australia",
  },
  {
    sr: 4966,
    city: "Eindhoven",
    city_code: "EIN",
    airport_name: "Eindhoven",
    country: "Netherlands",
  },
  {
    sr: 4968,
    city: "Eirunepe",
    city_code: "ERN",
    airport_name: "Eirunepe",
    country: "Brazil",
  },
  {
    sr: 4970,
    city: "Eisenach",
    city_code: "EIB",
    airport_name: "Eisenach",
    country: "Germany",
  },
  {
    sr: 4972,
    city: "Ekaterinburg",
    city_code: "SVX",
    airport_name: "Ekaterinburg",
    country: "Russia",
  },
  {
    sr: 4974,
    city: "Ekati",
    city_code: "YOA",
    airport_name: "Ekati",
    country: "Canada",
  },
  {
    sr: 4976,
    city: "Ekereku",
    city_code: "EKE",
    airport_name: "Ekereku",
    country: "Guyana",
  },
  {
    sr: 4978,
    city: "Ekibastuz",
    city_code: "EKB",
    airport_name: "Ekibastuz",
    country: "Kazakhstan",
  },
  {
    sr: 4980,
    city: "Ekuk",
    city_code: "KKU",
    airport_name: "Ekuk",
    country: "United States",
  },
  {
    sr: 4982,
    city: "Ekwok",
    city_code: "KEK",
    airport_name: "Ekwok",
    country: "United States",
  },
  {
    sr: 4984,
    city: "El Bagre",
    city_code: "EBG",
    airport_name: "El Bagre",
    country: "Colombia",
  },
  {
    sr: 4986,
    city: "El Banco",
    city_code: "ELB",
    airport_name: "San Bernado",
    country: "Colombia",
  },
  {
    sr: 4988,
    city: "El Bolson",
    city_code: "EHL",
    airport_name: "El Bolson",
    country: "Argentina",
  },
  {
    sr: 4990,
    city: "El Borma",
    city_code: "EBM",
    airport_name: "El Borma",
    country: "Tunisia",
  },
  {
    sr: 4992,
    city: "El Cajon",
    city_code: "CJN",
    airport_name: "El Cajon",
    country: "United States",
  },
  {
    sr: 4994,
    city: "El Calafate",
    city_code: "FTE",
    airport_name: "El Calafate",
    country: "Argentina",
  },
  {
    sr: 4998,
    city: "El Centro",
    city_code: "NJK",
    airport_name: "NAF",
    country: "United States",
  },
  {
    sr: 5000,
    city: "El Charco",
    city_code: "ECR",
    airport_name: "El Charco",
    country: "Colombia",
  },
  {
    sr: 5002,
    city: "El Dorado",
    city_code: "ELD",
    airport_name: "Goodwin Field",
    country: "United States",
  },
  {
    sr: 5004,
    city: "El Dorado",
    city_code: "EDK",
    airport_name: "El Dorado",
    country: "United States",
  },
  {
    sr: 5006,
    city: "El Dorado",
    city_code: "EOR",
    airport_name: "El Dorado",
    country: "Venezuela",
  },
  {
    sr: 5008,
    city: "El Encanto",
    city_code: "ECO",
    airport_name: "El Encanto",
    country: "Colombia",
  },
  {
    sr: 5010,
    city: "El Fasher",
    city_code: "ELF",
    airport_name: "El Fasher",
    country: "Sudan",
  },
  {
    sr: 5012,
    city: "El Golea",
    city_code: "ELG",
    airport_name: "El Golea",
    country: "Algeria",
  },
  {
    sr: 5014,
    city: "El Gouera",
    city_code: "ZLG",
    airport_name: "El Gouera",
    country: "Mauritania",
  },
  {
    sr: 5016,
    city: "El Maiten",
    city_code: "EMX",
    airport_name: "El Maiten",
    country: "Argentina",
  },
  {
    sr: 5018,
    city: "El Minya",
    city_code: "EMY",
    airport_name: "El Minya",
    country: "Egypt",
  },
  {
    sr: 5020,
    city: "El Monte",
    city_code: "EMT",
    airport_name: "El Monte",
    country: "United States",
  },
  {
    sr: 5022,
    city: "El Naranjo",
    city_code: "ENJ",
    airport_name: "El Naranjo",
    country: "Guatemala",
  },
  {
    sr: 5024,
    city: "El Nido",
    city_code: "ENI",
    airport_name: "El Nido",
    country: "Philippines",
  },
  {
    sr: 5026,
    city: "El Obeid",
    city_code: "EBD",
    airport_name: "El Obeid",
    country: "Sudan",
  },
  {
    sr: 5028,
    city: "El Oued",
    city_code: "ELU",
    airport_name: "Guemar",
    country: "Algeria",
  },
  {
    sr: 5032,
    city: "El Palomar",
    city_code: "EPA",
    airport_name: "El Palomar",
    country: "Argentina",
  },
  {
    sr: 5034,
    city: "El Paso",
    city_code: "BIF",
    airport_name: "Biggs AAF",
    country: "United States",
  },
  {
    sr: 5036,
    city: "El Paso",
    city_code: "ELP",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 5038,
    city: "El Portillo samana",
    city_code: "EPS",
    airport_name: "El Portillo",
    country: "Dominican Republic",
  },
  {
    sr: 5040,
    city: "El Porvenir",
    city_code: "PVE",
    airport_name: "El Porvenir",
    country: "Panama",
  },
  {
    sr: 5042,
    city: "El Real",
    city_code: "ELE",
    airport_name: "El Real",
    country: "Panama",
  },
  {
    sr: 5044,
    city: "El Recreo",
    city_code: "ELJ",
    airport_name: "El Recreo",
    country: "Colombia",
  },
  {
    sr: 5046,
    city: "El Salvador",
    city_code: "ESR",
    airport_name: "El Salvador",
    country: "Chile",
  },
  {
    sr: 5048,
    city: "El Tigre",
    city_code: "ELX",
    airport_name: "El Tigre",
    country: "Venezuela",
  },
  {
    sr: 5050,
    city: "El Vigia",
    city_code: "VIG",
    airport_name: "El Vigia",
    country: "Venezuela",
  },
  {
    sr: 5052,
    city: "El Yopal",
    city_code: "EYP",
    airport_name: "El Yopal",
    country: "Colombia",
  },
  {
    sr: 5054,
    city: "Elat",
    city_code: "ETH",
    airport_name: "Elat",
    country: "Israel",
  },
  {
    sr: 5056,
    city: "Elazig",
    city_code: "EZS",
    airport_name: "Elazig",
    country: "Turkey",
  },
  {
    sr: 5058,
    city: "Elba Island",
    city_code: "EBA",
    airport_name: "Marina Di Campo",
    country: "Italy",
  },
  {
    sr: 5060,
    city: "Elcho Island",
    city_code: "ELC",
    airport_name: "Elcho Island",
    country: "Australia",
  },
  {
    sr: 5062,
    city: "Eldebba",
    city_code: "EDB",
    airport_name: "Eldebba",
    country: "Sudan",
  },
  {
    sr: 5066,
    city: "Eldorado",
    city_code: "ELO",
    airport_name: "Eldorado",
    country: "Argentina",
  },
  {
    sr: 5068,
    city: "Eldoret",
    city_code: "EDL",
    airport_name: "Eldoret",
    country: "Kenya",
  },
  {
    sr: 5070,
    city: "Eldred Rock",
    city_code: "ERO",
    airport_name: "Coast Guard",
    country: "United States",
  },
  {
    sr: 5072,
    city: "Elelim",
    city_code: "ELR",
    airport_name: "Elelim",
    country: "Indonesia",
  },
  {
    sr: 5074,
    city: "Elfin Cove",
    city_code: "ELV",
    airport_name: "Elfin Cove SPB",
    country: "United States",
  },
  {
    sr: 5076,
    city: "Elim",
    city_code: "ELI",
    airport_name: "Elim",
    country: "United States",
  },
  {
    sr: 5078,
    city: "Eliptamin",
    city_code: "EPT",
    airport_name: "Eliptamin",
    country: "Papua New Guinea",
  },
  {
    sr: 5080,
    city: "Elista",
    city_code: "ESL",
    airport_name: "Elista",
    country: "Russia",
  },
  {
    sr: 5082,
    city: "Eliye Springs",
    city_code: "EYS",
    airport_name: "Eliye Springs",
    country: "Kenya",
  },
  {
    sr: 5084,
    city: "Elizabeth City",
    city_code: "ECG",
    airport_name: "Elizabeth City",
    country: "United States",
  },
  {
    sr: 5086,
    city: "Elizabethtown",
    city_code: "EKX",
    airport_name: "Elizabethtown",
    country: "United States",
  },
  {
    sr: 5088,
    city: "Elk City",
    city_code: "ELK",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5090,
    city: "Elkedra",
    city_code: "EKD",
    airport_name: "Elkedra",
    country: "Australia",
  },
  {
    sr: 5092,
    city: "Elkhart",
    city_code: "EKI",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5094,
    city: "Elkins",
    city_code: "EKN",
    airport_name: "Elkins",
    country: "United States",
  },
  {
    sr: 5096,
    city: "Elko",
    city_code: "EKO",
    airport_name: "Elko",
    country: "United States",
  },
  {
    sr: 5100,
    city: "Ellamar",
    city_code: "ELW",
    airport_name: "Ellamar",
    country: "United States",
  },
  {
    sr: 5102,
    city: "Ellensburg",
    city_code: "ELN",
    airport_name: "Bowers Field",
    country: "United States",
  },
  {
    sr: 5104,
    city: "Elliot Lake",
    city_code: "YEL",
    airport_name: "Elliot Lake",
    country: "Canada",
  },
  {
    sr: 5106,
    city: "Ellisras",
    city_code: "ELL",
    airport_name: "Ellisras",
    country: "South Africa",
  },
  {
    sr: 5108,
    city: "Elorza",
    city_code: "EOZ",
    airport_name: "Elorza",
    country: "Venezuela",
  },
  {
    sr: 5110,
    city: "Elverum",
    city_code: "XUC",
    airport_name: "Elverum Railway",
    country: "Norway",
  },
  {
    sr: 5112,
    city: "Ely",
    city_code: "LYU",
    airport_name: "Ely",
    country: "United States",
  },
  {
    sr: 5114,
    city: "Ely",
    city_code: "ELY",
    airport_name: "Yelland",
    country: "United States",
  },
  {
    sr: 5116,
    city: "Emae",
    city_code: "EAE",
    airport_name: "Emae",
    country: "Vanuatu",
  },
  {
    sr: 5118,
    city: "Embessa",
    city_code: "EMS",
    airport_name: "Embessa",
    country: "Papua New Guinea",
  },
  {
    sr: 5120,
    city: "Emden",
    city_code: "EME",
    airport_name: "Emden",
    country: "Germany",
  },
  {
    sr: 5122,
    city: "Emerald",
    city_code: "EMD",
    airport_name: "Emerald",
    country: "Australia",
  },
  {
    sr: 5124,
    city: "Emeryville",
    city_code: "JEM",
    airport_name: "Heliport",
    country: "United States",
  },
  {
    sr: 5126,
    city: "Emirau",
    city_code: "EMI",
    airport_name: "Emirau",
    country: "Papua New Guinea",
  },
  {
    sr: 5128,
    city: "Emmen",
    city_code: "EML",
    airport_name: "Emmen",
    country: "Switzerland",
  },
  {
    sr: 5130,
    city: "Emmonak",
    city_code: "EMK",
    airport_name: "Emmonak",
    country: "United States",
  },
  {
    sr: 5134,
    city: "Emo",
    city_code: "EMO",
    airport_name: "Emo",
    country: "Papua New Guinea",
  },
  {
    sr: 5136,
    city: "Empangeni",
    city_code: "EMG",
    airport_name: "Empangeni",
    country: "South Africa",
  },
  {
    sr: 5138,
    city: "Emporia",
    city_code: "EMP",
    airport_name: "Emporia",
    country: "United States",
  },
  {
    sr: 5140,
    city: "En Nahud",
    city_code: "NUD",
    airport_name: "En Nahud",
    country: "Sudan",
  },
  {
    sr: 5142,
    city: "Enarotali",
    city_code: "EWI",
    airport_name: "Enarotali",
    country: "Indonesia",
  },
  {
    sr: 5144,
    city: "Encarnacion",
    city_code: "ENO",
    airport_name: "Teniente Prim Alarcon",
    country: "Paraguay",
  },
  {
    sr: 5146,
    city: "Ende",
    city_code: "ENE",
    airport_name: "Ende",
    country: "Indonesia",
  },
  {
    sr: 5148,
    city: "Endicott",
    city_code: "BGM",
    airport_name: "Endicott",
    country: "United States",
  },
  {
    sr: 5150,
    city: "Eneabba West",
    city_code: "ENB",
    airport_name: "Eneabba West",
    country: "Australia",
  },
  {
    sr: 5152,
    city: "Enewetak Island",
    city_code: "ENT",
    airport_name: "Enewetak Island",
    country: "Marshall Islands",
  },
  {
    sr: 5154,
    city: "Engati",
    city_code: "EGA",
    airport_name: "Engati",
    country: "Papua New Guinea",
  },
  {
    sr: 5156,
    city: "Enid",
    city_code: "WDG",
    airport_name: "Enid Woodring Mun",
    country: "United States",
  },
  {
    sr: 5158,
    city: "Enid",
    city_code: "END",
    airport_name: "Vance AFB",
    country: "United States",
  },
  {
    sr: 5160,
    city: "Eniseysk",
    city_code: "EIE",
    airport_name: "Eniseysk",
    country: "Russia",
  },
  {
    sr: 5162,
    city: "Enkoping",
    city_code: "XWQ",
    airport_name: "Enkoping",
    country: "Sweden",
  },
  {
    sr: 5164,
    city: "Enniskillen",
    city_code: "ENK",
    airport_name: "Enniskillen",
    country: "United Kingdom",
  },
  {
    sr: 5168,
    city: "Enontekio",
    city_code: "ENF",
    airport_name: "Enontekio",
    country: "Finland",
  },
  {
    sr: 5170,
    city: "Enschede",
    city_code: "ENS",
    airport_name: "Twente",
    country: "Netherlands",
  },
  {
    sr: 5172,
    city: "Ensenada",
    city_code: "ESE",
    airport_name: "Ensenada",
    country: "Mexico",
  },
  {
    sr: 5174,
    city: "Enshi",
    city_code: "ENH",
    airport_name: "Enshi",
    country: "China",
  },
  {
    sr: 5176,
    city: "Entebbe",
    city_code: "EBB",
    airport_name: "Entebbe",
    country: "Uganda",
  },
  {
    sr: 5178,
    city: "Enterprise",
    city_code: "ETS",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5180,
    city: "Enugu",
    city_code: "ENU",
    airport_name: "Enugu",
    country: "Nigeria",
  },
  {
    sr: 5182,
    city: "Epena",
    city_code: "EPN",
    airport_name: "Epena",
    country: "Congo",
  },
  {
    sr: 5184,
    city: "Ephrata",
    city_code: "EPH",
    airport_name: "Ephrata",
    country: "United States",
  },
  {
    sr: 5186,
    city: "Epi",
    city_code: "EPI",
    airport_name: "Epi",
    country: "Vanuatu",
  },
  {
    sr: 5188,
    city: "Epinal",
    city_code: "EPL",
    airport_name: "Mirecourt",
    country: "France",
  },
  {
    sr: 5190,
    city: "Episkopi",
    city_code: "EPK",
    airport_name: "Episkopi",
    country: "Cyprus",
  },
  {
    sr: 5192,
    city: "Erandique",
    city_code: "EDQ",
    airport_name: "Erandique",
    country: "Honduras",
  },
  {
    sr: 5194,
    city: "Erave",
    city_code: "ERE",
    airport_name: "Erave",
    country: "Papua New Guinea",
  },
  {
    sr: 5196,
    city: "Lefkosa",
    city_code: "ECN",
    airport_name: "Ercan",
    country: "Cyprus",
  },
  {
    sr: 5198,
    city: "Erdenet",
    city_code: "ERT",
    airport_name: "Erdenet",
    country: "Mongolia",
  },
  {
    sr: 5202,
    city: "Erechim",
    city_code: "ERM",
    airport_name: "Comandante Kraemer",
    country: "Brazil",
  },
  {
    sr: 5204,
    city: "Erfurt",
    city_code: "ERF",
    airport_name: "Erfurt",
    country: "Germany",
  },
  {
    sr: 5206,
    city: "Erie",
    city_code: "ERI",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 5208,
    city: "Erigavo",
    city_code: "ERA",
    airport_name: "Erigavo",
    country: "Somalia",
  },
  {
    sr: 5210,
    city: "Erldunda",
    city_code: "EDD",
    airport_name: "Erldunda",
    country: "Australia",
  },
  {
    sr: 5212,
    city: "Ernabella",
    city_code: "ERB",
    airport_name: "Ernabella",
    country: "Australia",
  },
  {
    sr: 5214,
    city: "Errachidia",
    city_code: "ERH",
    airport_name: "Errachidia",
    country: "Morocco",
  },
  {
    sr: 5216,
    city: "Errol",
    city_code: "ERR",
    airport_name: "Errol",
    country: "United States",
  },
  {
    sr: 5218,
    city: "Erume",
    city_code: "ERU",
    airport_name: "Erume",
    country: "Papua New Guinea",
  },
  {
    sr: 5220,
    city: "Erzincan",
    city_code: "ERC",
    airport_name: "Erzincan",
    country: "Turkey",
  },
  {
    sr: 5222,
    city: "Erzurum",
    city_code: "ERZ",
    airport_name: "Erzurum",
    country: "Turkey",
  },
  {
    sr: 5224,
    city: "Esbjerg",
    city_code: "EBJ",
    airport_name: "Esbjerg",
    country: "Denmark",
  },
  {
    sr: 5226,
    city: "Escanaba",
    city_code: "ESC",
    airport_name: "Delta County Arpt",
    country: "United States",
  },
  {
    sr: 5228,
    city: "Eskilstuna",
    city_code: "EKT",
    airport_name: "Eskilstuna",
    country: "Sweden",
  },
  {
    sr: 5230,
    city: "Eskisehir",
    city_code: "ESK",
    airport_name: "Eskisehir",
    country: "Turkey",
  },
  {
    sr: 5232,
    city: "Eskisehir",
    city_code: "AOE",
    airport_name: "Anadolu University",
    country: "Turkey",
  },
  {
    sr: 5236,
    city: "Esmeraldas",
    city_code: "ESM",
    airport_name: "Esmeraldas",
    country: "Ecuador",
  },
  {
    sr: 5238,
    city: "Espanola",
    city_code: "ESO",
    airport_name: "Espanola",
    country: "United States",
  },
  {
    sr: 5240,
    city: "Esperance",
    city_code: "EPR",
    airport_name: "Esperance",
    country: "Australia",
  },
  {
    sr: 5242,
    city: "Espinosa",
    city_code: "ESI",
    airport_name: "Espinosa",
    country: "Brazil",
  },
  {
    sr: 5244,
    city: "Espiritu Santo",
    city_code: "SON",
    airport_name: "Pekoa",
    country: "Vanuatu",
  },
  {
    sr: 5246,
    city: "Esquel",
    city_code: "EQS",
    airport_name: "Esquel",
    country: "Argentina",
  },
  {
    sr: 5248,
    city: "Esquimalt",
    city_code: "YPF",
    airport_name: "Esquimalt",
    country: "Canada",
  },
  {
    sr: 5250,
    city: "Essaouira",
    city_code: "ESU",
    airport_name: "Essaouira",
    country: "Morocco",
  },
  {
    sr: 5252,
    city: "Essen",
    city_code: "ESS",
    airport_name: "Essen",
    country: "Germany",
  },
  {
    sr: 5254,
    city: "Estevan",
    city_code: "YEN",
    airport_name: "Estevan",
    country: "Canada",
  },
  {
    sr: 5256,
    city: "Estevan Point",
    city_code: "YEP",
    airport_name: "Estevan Point",
    country: "Canada",
  },
  {
    sr: 5258,
    city: "Estherville",
    city_code: "EST",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5260,
    city: "Etadunna",
    city_code: "ETD",
    airport_name: "Etadunna",
    country: "Australia",
  },
  {
    sr: 5262,
    city: "Eua",
    city_code: "EUA",
    airport_name: "Kaufana",
    country: "Tonga",
  },
  {
    sr: 5264,
    city: "Eucla",
    city_code: "EUC",
    airport_name: "Eucla",
    country: "Australia",
  },
  {
    sr: 5266,
    city: "Eufaula",
    city_code: "EUF",
    airport_name: "Weedon Field",
    country: "United States",
  },
  {
    sr: 5270,
    city: "Eugene",
    city_code: "EUG",
    airport_name: "Eugene",
    country: "United States",
  },
  {
    sr: 5272,
    city: "Eunice",
    city_code: "UCE",
    airport_name: "Eunice",
    country: "United States",
  },
  {
    sr: 5274,
    city: "Eureka",
    city_code: "EKA",
    airport_name: "Murray Field",
    country: "United States",
  },
  {
    sr: 5276,
    city: "Eureka",
    city_code: "YEU",
    airport_name: "Eureka",
    country: "Canada",
  },
  {
    sr: 5278,
    city: "Eureka",
    city_code: "EUE",
    airport_name: "Eureka",
    country: "United States",
  },
  {
    sr: 5280,
    city: "Eva Downs",
    city_code: "EVD",
    airport_name: "Eva Downs",
    country: "Australia",
  },
  {
    sr: 5282,
    city: "Evadale",
    city_code: "EVA",
    airport_name: "Landing Strip",
    country: "United States",
  },
  {
    sr: 5284,
    city: "Evans Head",
    city_code: "EVH",
    airport_name: "Evans Head",
    country: "Australia",
  },
  {
    sr: 5286,
    city: "Evanston",
    city_code: "EVW",
    airport_name: "Evanston",
    country: "United States",
  },
  {
    sr: 5288,
    city: "Evansville",
    city_code: "EVV",
    airport_name: "Dress Regional",
    country: "United States",
  },
  {
    sr: 5290,
    city: "Eveleth",
    city_code: "EVM",
    airport_name: "Eveleth",
    country: "United States",
  },
  {
    sr: 5292,
    city: "Everett",
    city_code: "PAE",
    airport_name: "Snohomish County",
    country: "United States",
  },
  {
    sr: 5294,
    city: "Evreux",
    city_code: "EVX",
    airport_name: "Evreux",
    country: "France",
  },
  {
    sr: 5296,
    city: "Evry",
    city_code: "JEV",
    airport_name: "Heliport",
    country: "France",
  },
  {
    sr: 5298,
    city: "Ewer",
    city_code: "EWE",
    airport_name: "Ewer",
    country: "Indonesia",
  },
  {
    sr: 5300,
    city: "Ewo",
    city_code: "EWO",
    airport_name: "Ewo",
    country: "Congo",
  },
  {
    sr: 5304,
    city: "Excursion Inlet",
    city_code: "EXI",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 5306,
    city: "Exeter",
    city_code: "EXT",
    airport_name: "Exeter",
    country: "United Kingdom",
  },
  {
    sr: 5308,
    city: "Exmouth Gulf",
    city_code: "EXM",
    airport_name: "Exmouth Gulf",
    country: "Australia",
  },
  {
    sr: 5310,
    city: "Faaite",
    city_code: "FAC",
    airport_name: "Faaite",
    country: "French Polynesia",
  },
  {
    sr: 5312,
    city: "Fada Ngourma",
    city_code: "FNG",
    airport_name: "Fada Ngourma",
    country: "Burkina Faso",
  },
  {
    sr: 5314,
    city: "Fagernes",
    city_code: "VDB",
    airport_name: "Valdres",
    country: "Norway",
  },
  {
    sr: 5316,
    city: "Fagurholsmyri",
    city_code: "FAG",
    airport_name: "Fagurholsmyri",
    country: "Iceland",
  },
  {
    sr: 5318,
    city: "Fair Isle",
    city_code: "FIE",
    airport_name: "Fair Isle",
    country: "United Kingdom",
  },
  {
    sr: 5320,
    city: "Fairbanks",
    city_code: "EIL",
    airport_name: "Eielson AFB",
    country: "United States",
  },
  {
    sr: 5322,
    city: "Fairbanks",
    city_code: "FBK",
    airport_name: "Ft Wainwright",
    country: "United States",
  },
  {
    sr: 5324,
    city: "Fairbanks",
    city_code: "FAI",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 5326,
    city: "Fairbanks",
    city_code: "MTX",
    airport_name: "Metro Field",
    country: "United States",
  },
  {
    sr: 5328,
    city: "Fairbanks",
    city_code: "PII",
    airport_name: "Phillips Field",
    country: "United States",
  },
  {
    sr: 5330,
    city: "Fairbury",
    city_code: "FBY",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5332,
    city: "Fairfield",
    city_code: "SUU",
    airport_name: "Travis AFB",
    country: "United States",
  },
  {
    sr: 5334,
    city: "Fairfield",
    city_code: "FFL",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5338,
    city: "Fairford",
    city_code: "FFD",
    airport_name: "RAF Station",
    country: "United Kingdom",
  },
  {
    sr: 5340,
    city: "Fairmont",
    city_code: "FRM",
    airport_name: "Fairmont",
    country: "United States",
  },
  {
    sr: 5342,
    city: "Fairmount Springs",
    city_code: "YCZ",
    airport_name: "Fairmount Springs",
    country: "Canada",
  },
  {
    sr: 5344,
    city: "Fairview",
    city_code: "ZFW",
    airport_name: "Fairview",
    country: "Canada",
  },
  {
    sr: 5346,
    city: "Faisalabad",
    city_code: "LYP",
    airport_name: "Faisalabad",
    country: "Pakistan",
  },
  {
    sr: 5348,
    city: "Faizabad",
    city_code: "FBD",
    airport_name: "Faizabad",
    country: "Afghanistan",
  },
  {
    sr: 5350,
    city: "Fajardo",
    city_code: "FAJ",
    airport_name: "Fajardo",
    country: "Puerto Rico",
  },
  {
    sr: 5352,
    city: "Fak Fak",
    city_code: "FKQ",
    airport_name: "Fak Fak",
    country: "Indonesia",
  },
  {
    sr: 5354,
    city: "Fakahina",
    city_code: "FHZ",
    airport_name: "Fakahina",
    country: "French Polynesia",
  },
  {
    sr: 5356,
    city: "Fakarava",
    city_code: "FAV",
    airport_name: "Fakarava",
    country: "French Polynesia",
  },
  {
    sr: 5358,
    city: "Fakenham",
    city_code: "FKH",
    airport_name: "Sculthorp RAF",
    country: "United Kingdom",
  },
  {
    sr: 5360,
    city: "Falher",
    city_code: "YOE",
    airport_name: "Falher",
    country: "Canada",
  },
  {
    sr: 5362,
    city: "Falkenberg",
    city_code: "XYM",
    airport_name: "Falkenberg Rail",
    country: "Sweden",
  },
  {
    sr: 5364,
    city: "Fallon",
    city_code: "FLX",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5366,
    city: "Fallon",
    city_code: "NFL",
    airport_name: "NAS",
    country: "United States",
  },
  {
    sr: 5368,
    city: "Falls Bay",
    city_code: "FLJ",
    airport_name: "Falls Bay",
    country: "United States",
  },
  {
    sr: 5372,
    city: "Falls Creek",
    city_code: "FLC",
    airport_name: "Falls Creek",
    country: "Australia",
  },
  {
    sr: 5374,
    city: "Falmouth",
    city_code: "FMH",
    airport_name: "Otis AFB",
    country: "United States",
  },
  {
    sr: 5376,
    city: "False Island",
    city_code: "FAK",
    airport_name: "False Island",
    country: "United States",
  },
  {
    sr: 5378,
    city: "False Pass",
    city_code: "KFP",
    airport_name: "False Pass",
    country: "United States",
  },
  {
    sr: 5380,
    city: "Fane",
    city_code: "FNE",
    airport_name: "Fane",
    country: "Papua New Guinea",
  },
  {
    sr: 5382,
    city: "Fangatau",
    city_code: "FGU",
    airport_name: "Fangatau",
    country: "French Polynesia",
  },
  {
    sr: 5384,
    city: "Farafangana",
    city_code: "RVA",
    airport_name: "Farafangana",
    country: "Madagascar",
  },
  {
    sr: 5386,
    city: "Farah",
    city_code: "FAH",
    airport_name: "Farah",
    country: "Afghanistan",
  },
  {
    sr: 5388,
    city: "Faranah",
    city_code: "FAA",
    airport_name: "Faranah",
    country: "Guinea",
  },
  {
    sr: 5390,
    city: "Farewell",
    city_code: "FWL",
    airport_name: "Farewell",
    country: "United States",
  },
  {
    sr: 5392,
    city: "Fargo",
    city_code: "FAR",
    airport_name: "Hector Field",
    country: "United States",
  },
  {
    sr: 5394,
    city: "Faribault",
    city_code: "FBL",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5396,
    city: "Farmingdale",
    city_code: "FRG",
    airport_name: "Republic Field",
    country: "United States",
  },
  {
    sr: 5398,
    city: "Farmington",
    city_code: "FAM",
    airport_name: "Regional",
    country: "United States",
  },
  {
    sr: 5400,
    city: "Farmington",
    city_code: "FMN",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5402,
    city: "Farnborough",
    city_code: "FAB",
    airport_name: "Farnborough",
    country: "United Kingdom",
  },
  {
    sr: 5406,
    city: "Faro",
    city_code: "FAO",
    airport_name: "Faro",
    country: "Portugal",
  },
  {
    sr: 5408,
    city: "Faro",
    city_code: "ZYF",
    airport_name: "Faro TP",
    country: "Portugal",
  },
  {
    sr: 5410,
    city: "Faro",
    city_code: "ZFA",
    airport_name: "Faro",
    country: "Canada",
  },
  {
    sr: 5412,
    city: "Faroe Islands",
    city_code: "FAE",
    airport_name: "Vagar",
    country: "Faroe Islands",
  },
  {
    sr: 5414,
    city: "Farsund",
    city_code: "FAN",
    airport_name: "Lista",
    country: "Norway",
  },
  {
    sr: 5416,
    city: "Fasa",
    city_code: "FAZ",
    airport_name: "Fasa",
    country: "Iran",
  },
  {
    sr: 5418,
    city: "Faskrudsfjordur",
    city_code: "FAS",
    airport_name: "Faskrudsfjordur",
    country: "Iceland",
  },
  {
    sr: 5420,
    city: "Faya",
    city_code: "FYT",
    airport_name: "Faya",
    country: "Chad",
  },
  {
    sr: 5422,
    city: "Fayetteville",
    city_code: "FYV",
    airport_name: "Municipal  Drake Fld",
    country: "United States",
  },
  {
    sr: 5424,
    city: "Fayetteville",
    city_code: "XNA",
    airport_name: "Northwest Arkansas Rgn",
    country: "United States",
  },
  {
    sr: 5426,
    city: "Fayetteville",
    city_code: "FAY",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5428,
    city: "Fayetteville",
    city_code: "POB",
    airport_name: "Pope AFB",
    country: "United States",
  },
  {
    sr: 5430,
    city: "Fayetteville",
    city_code: "FYM",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5432,
    city: "Fderik",
    city_code: "FGD",
    airport_name: "Fderik",
    country: "Mauritania",
  },
  {
    sr: 5434,
    city: "Feijo",
    city_code: "FEJ",
    airport_name: "Feijo",
    country: "Brazil",
  },
  {
    sr: 5436,
    city: "Feira De Santana",
    city_code: "FEC",
    airport_name: "Feira De Santana",
    country: "Brazil",
  },
  {
    sr: 5440,
    city: "Fera Island",
    city_code: "FRE",
    airport_name: "Fera Island",
    country: "Solomon Islands",
  },
  {
    sr: 5442,
    city: "Feramin",
    city_code: "FRQ",
    airport_name: "Feramin",
    country: "Papua New Guinea",
  },
  {
    sr: 5444,
    city: "Fergana",
    city_code: "FEG",
    airport_name: "Fergana",
    country: "Uzbekistan",
  },
  {
    sr: 5446,
    city: "Fergus Falls",
    city_code: "FFM",
    airport_name: "Fergus Falls",
    country: "United States",
  },
  {
    sr: 5448,
    city: "Fergusons Gulf",
    city_code: "FER",
    airport_name: "Fergusons Gulf",
    country: "Kenya",
  },
  {
    sr: 5450,
    city: "Ferkessedougou",
    city_code: "FEK",
    airport_name: "Ferkessedougou",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 5452,
    city: "Fernando De Noronha",
    city_code: "FEN",
    airport_name: "Fernando De Noronha",
    country: "Brazil",
  },
  {
    sr: 5454,
    city: "Fetlar",
    city_code: "FEA",
    airport_name: "Fetlar",
    country: "United Kingdom",
  },
  {
    sr: 5456,
    city: "Fez",
    city_code: "FEZ",
    airport_name: "Sais",
    country: "Morocco",
  },
  {
    sr: 5458,
    city: "Fianarantsoa",
    city_code: "WFI",
    airport_name: "Fianarantsoa",
    country: "Madagascar",
  },
  {
    sr: 5460,
    city: "Ficksburg",
    city_code: "FCB",
    airport_name: "Ficksburg Sentra Oes",
    country: "South Africa",
  },
  {
    sr: 5464,
    city: "Fictitious Point AA2",
    city_code: "ZGZ",
    airport_name: "GermanRail Pass",
    country: "Germany",
  },
  {
    sr: 5470,
    city: "Fictitious Point LH",
    city_code: "QFP",
    airport_name: "Fictitious Point LH",
    country: "Germany",
  },
  {
    sr: 5474,
    city: "Figari",
    city_code: "FSC",
    airport_name: "Sud Corse",
    country: "France",
  },
  {
    sr: 5476,
    city: "Filadelfia",
    city_code: "FLM",
    airport_name: "Filadelfia",
    country: "Paraguay",
  },
  {
    sr: 5478,
    city: "Fillmore",
    city_code: "FIL",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5480,
    city: "Filton",
    city_code: "FZO",
    airport_name: "Filton",
    country: "United Kingdom",
  },
  {
    sr: 5482,
    city: "Fin Creek",
    city_code: "FNK",
    airport_name: "Fin Creek",
    country: "United States",
  },
  {
    sr: 5484,
    city: "Fincha",
    city_code: "FNH",
    airport_name: "Fincha",
    country: "Ethiopia",
  },
  {
    sr: 5486,
    city: "Findlay",
    city_code: "FDY",
    airport_name: "Findlay",
    country: "United States",
  },
  {
    sr: 5488,
    city: "Finke",
    city_code: "FIK",
    airport_name: "Finke",
    country: "Australia",
  },
  {
    sr: 5490,
    city: "Hamburg",
    city_code: "XFW",
    airport_name: "Finkenwerder",
    country: "Germany",
  },
  {
    sr: 5492,
    city: "Finley",
    city_code: "FLY",
    airport_name: "Finley",
    country: "Australia",
  },
  {
    sr: 5494,
    city: "Finschhafen",
    city_code: "FIN",
    airport_name: "Finschhafen",
    country: "Papua New Guinea",
  },
  {
    sr: 5496,
    city: "Fire Cove",
    city_code: "FIC",
    airport_name: "Fire Cove",
    country: "United States",
  },
  {
    sr: 5498,
    city: "Fishers Island",
    city_code: "FID",
    airport_name: "Elizabeth Field",
    country: "United States",
  },
  {
    sr: 5500,
    city: "Fitiuta",
    city_code: "FTI",
    airport_name: "Fitiuta",
    country: "American Samoa",
  },
  {
    sr: 5502,
    city: "Fitzroy Crossing",
    city_code: "FIZ",
    airport_name: "Fitzroy Crossing",
    country: "Australia",
  },
  {
    sr: 5504,
    city: "Fiumicino",
    city_code: "ZRR",
    airport_name: "Harbour",
    country: "Canada",
  },
  {
    sr: 5508,
    city: "Five Finger",
    city_code: "FIV",
    airport_name: "Five Finger",
    country: "United States",
  },
  {
    sr: 5510,
    city: "Five Mile",
    city_code: "FMC",
    airport_name: "Five Mile",
    country: "United States",
  },
  {
    sr: 5512,
    city: "Flamingo",
    city_code: "FMG",
    airport_name: "Flamingo",
    country: "Costa Rica",
  },
  {
    sr: 5514,
    city: "Flat",
    city_code: "FLT",
    airport_name: "Flat",
    country: "United States",
  },
  {
    sr: 5516,
    city: "Flateyri",
    city_code: "FLI",
    airport_name: "Flateyri",
    country: "Iceland",
  },
  {
    sr: 5518,
    city: "Flaxman Island",
    city_code: "FXM",
    airport_name: "Flaxman Island",
    country: "United States",
  },
  {
    sr: 5520,
    city: "Flen",
    city_code: "XYI",
    airport_name: "Flen",
    country: "Sweden",
  },
  {
    sr: 5522,
    city: "Flensburg",
    city_code: "FLF",
    airport_name: "Schaferhaus",
    country: "Germany",
  },
  {
    sr: 5524,
    city: "Flin Flon",
    city_code: "YFO",
    airport_name: "Flin Flon",
    country: "Canada",
  },
  {
    sr: 5526,
    city: "Flinders Island",
    city_code: "FLS",
    airport_name: "Flinders Island",
    country: "Australia",
  },
  {
    sr: 5528,
    city: "Flint",
    city_code: "FNT",
    airport_name: "Bishop",
    country: "United States",
  },
  {
    sr: 5530,
    city: "Flippin",
    city_code: "FLP",
    airport_name: "Flippin",
    country: "United States",
  },
  {
    sr: 5532,
    city: "Flora Valley",
    city_code: "FVL",
    airport_name: "Flora Valey",
    country: "Australia",
  },
  {
    sr: 5534,
    city: "Pisa",
    city_code: "PSA",
    airport_name: "Galileo Galilei",
    country: "Italy",
  },
  {
    sr: 5536,
    city: "Florence",
    city_code: "FLR",
    airport_name: "Florence Peretola",
    country: "Italy",
  },
  {
    sr: 5538,
    city: "Florence",
    city_code: "ZMS",
    airport_name: "S M Novella Rail Svc",
    country: "Italy",
  },
  {
    sr: 5542,
    city: "Florence",
    city_code: "FLO",
    airport_name: "Florence",
    country: "United States",
  },
  {
    sr: 5544,
    city: "Florencia",
    city_code: "FLA",
    airport_name: "Capitolio",
    country: "Colombia",
  },
  {
    sr: 5546,
    city: "Flores",
    city_code: "FRS",
    airport_name: "Santa Elena",
    country: "Guatemala",
  },
  {
    sr: 5548,
    city: "Flores Island",
    city_code: "FLW",
    airport_name: "Santa Cruz",
    country: "Portugal",
  },
  {
    sr: 5550,
    city: "Floriano",
    city_code: "FLB",
    airport_name: "Cangapara",
    country: "Brazil",
  },
  {
    sr: 5552,
    city: "Florianopolis",
    city_code: "FLN",
    airport_name: "Hercilio Luz",
    country: "Brazil",
  },
  {
    sr: 5554,
    city: "Floro",
    city_code: "FRO",
    airport_name: "Flora",
    country: "Norway",
  },
  {
    sr: 5556,
    city: "Flotta",
    city_code: "FLH",
    airport_name: "Flotta",
    country: "United Kingdom",
  },
  {
    sr: 5558,
    city: "Foggia",
    city_code: "FOG",
    airport_name: "Gino Lisa",
    country: "Italy",
  },
  {
    sr: 5560,
    city: "Foley",
    city_code: "NHX",
    airport_name: "Barin Olf Osn",
    country: "United States",
  },
  {
    sr: 5562,
    city: "Fond Du Lac",
    city_code: "ZFD",
    airport_name: "Fond Du Lac",
    country: "Canada",
  },
  {
    sr: 5564,
    city: "Fond Du Lac",
    city_code: "FLD",
    airport_name: "Fond Du Lac",
    country: "United States",
  },
  {
    sr: 5566,
    city: "Fontanges",
    city_code: "YFG",
    airport_name: "Fontanges",
    country: "Canada",
  },
  {
    sr: 5568,
    city: "Forbes",
    city_code: "FRB",
    airport_name: "Forbes",
    country: "Australia",
  },
  {
    sr: 5570,
    city: "Forde",
    city_code: "FDE",
    airport_name: "Bringeland",
    country: "Norway",
  },
  {
    sr: 5572,
    city: "Forest City",
    city_code: "FXY",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5576,
    city: "Forest Park",
    city_code: "FOP",
    airport_name: "Morris AAF",
    country: "United States",
  },
  {
    sr: 5578,
    city: "Forestville",
    city_code: "YFE",
    airport_name: "Forestville",
    country: "Canada",
  },
  {
    sr: 5580,
    city: "Forli",
    city_code: "FRL",
    airport_name: "Luigi Ridolfi",
    country: "Italy",
  },
  {
    sr: 5582,
    city: "Formosa",
    city_code: "FMA",
    airport_name: "El Pucu",
    country: "Argentina",
  },
  {
    sr: 5584,
    city: "Forres",
    city_code: "FSS",
    airport_name: "Kinloss",
    country: "United Kingdom",
  },
  {
    sr: 5586,
    city: "Forrest",
    city_code: "FOS",
    airport_name: "Forrest",
    country: "Australia",
  },
  {
    sr: 5588,
    city: "Forrest City",
    city_code: "FCY",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5590,
    city: "Forrest River",
    city_code: "FVR",
    airport_name: "Forrest River Airport",
    country: "Australia",
  },
  {
    sr: 5592,
    city: "Forster",
    city_code: "FOT",
    airport_name: "Forster",
    country: "Australia",
  },
  {
    sr: 5594,
    city: "Fort Albany",
    city_code: "YFA",
    airport_name: "Fort Albany",
    country: "Canada",
  },
  {
    sr: 5596,
    city: "Fort Belvoir",
    city_code: "DAA",
    airport_name: "Davison AAF",
    country: "United States",
  },
  {
    sr: 5598,
    city: "Fort Bragg",
    city_code: "FOB",
    airport_name: "Fort Bragg",
    country: "United States",
  },
  {
    sr: 5600,
    city: "Fort Bragg",
    city_code: "FBG",
    airport_name: "Simmons AAF",
    country: "United States",
  },
  {
    sr: 5602,
    city: "Fort Bridger",
    city_code: "FBR",
    airport_name: "Fort Bridger",
    country: "United States",
  },
  {
    sr: 5604,
    city: "Fort Chaffee",
    city_code: "CCA",
    airport_name: "Chaffee AFB",
    country: "United States",
  },
  {
    sr: 5606,
    city: "Fort Chipewyan",
    city_code: "YPY",
    airport_name: "Fort Chipewyan",
    country: "Canada",
  },
  {
    sr: 5610,
    city: "Fort Collins Loveland",
    city_code: "QWF",
    airport_name: "Fort Collins Bus Servi",
    country: "United States",
  },
  {
    sr: 5612,
    city: "Fort Collins Loveland",
    city_code: "FNL",
    airport_name: "Municipal Airport",
    country: "United States",
  },
  {
    sr: 5614,
    city: "Fort Dauphin",
    city_code: "FTU",
    airport_name: "Marillac",
    country: "Madagascar",
  },
  {
    sr: 5616,
    city: "Fort De France",
    city_code: "FDF",
    airport_name: "Lamentin",
    country: "Martinique",
  },
  {
    sr: 5618,
    city: "Fort Devens",
    city_code: "AYE",
    airport_name: "AAF Heliport",
    country: "United States",
  },
  {
    sr: 5620,
    city: "Fort Dix",
    city_code: "WRI",
    airport_name: "Mc Guire AFB",
    country: "United States",
  },
  {
    sr: 5622,
    city: "Fort Dodge",
    city_code: "FOD",
    airport_name: "Fort Dodge",
    country: "United States",
  },
  {
    sr: 5624,
    city: "Fort Eustis",
    city_code: "FAF",
    airport_name: "Felker AAF",
    country: "United States",
  },
  {
    sr: 5626,
    city: "Fort Frances",
    city_code: "YAG",
    airport_name: "Municipal",
    country: "Canada",
  },
  {
    sr: 5628,
    city: "Fort Good Hope",
    city_code: "YGH",
    airport_name: "Fort Good Hope",
    country: "Canada",
  },
  {
    sr: 5630,
    city: "Fort Hope",
    city_code: "YFH",
    airport_name: "Fort Hope",
    country: "Canada",
  },
  {
    sr: 5632,
    city: "Fort Indiantown",
    city_code: "MUI",
    airport_name: "Muir AAF",
    country: "United States",
  },
  {
    sr: 5634,
    city: "Fort Irwin",
    city_code: "BYS",
    airport_name: "Bicycle Lake AAF",
    country: "United States",
  },
  {
    sr: 5636,
    city: "Fort Jefferson",
    city_code: "RBN",
    airport_name: "Fort Jefferson",
    country: "United States",
  },
  {
    sr: 5638,
    city: "Fort Knox",
    city_code: "FTK",
    airport_name: "Godman AAF",
    country: "United States",
  },
  {
    sr: 5640,
    city: "Fort Kobbe",
    city_code: "HOW",
    airport_name: "Howard Afb",
    country: "Panama",
  },
  {
    sr: 5644,
    city: "Fort Lauderdale",
    city_code: "FXE",
    airport_name: "Executive",
    country: "United States",
  },
  {
    sr: 5646,
    city: "Fort Lauderdale",
    city_code: "FLL",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 5648,
    city: "Fort Leavenworth",
    city_code: "FLV",
    airport_name: "Sherman AAF",
    country: "United States",
  },
  {
    sr: 5650,
    city: "Fort Leonard Wood",
    city_code: "TBN",
    airport_name: "Forney AAF",
    country: "United States",
  },
  {
    sr: 5652,
    city: "Fort Liard",
    city_code: "YJF",
    airport_name: "Fort Liard",
    country: "Canada",
  },
  {
    sr: 5654,
    city: "Fort Madison",
    city_code: "FMS",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5656,
    city: "Fort Mcmurray",
    city_code: "YMM",
    airport_name: "Fort Mcmurray",
    country: "Canada",
  },
  {
    sr: 5658,
    city: "Fort Mcpherson",
    city_code: "ZFM",
    airport_name: "Fort Mcpherson",
    country: "Canada",
  },
  {
    sr: 5660,
    city: "Fort Meade",
    city_code: "FME",
    airport_name: "Tipton AAF",
    country: "United States",
  },
  {
    sr: 5662,
    city: "Fort Myers",
    city_code: "FMY",
    airport_name: "Page Field",
    country: "United States",
  },
  {
    sr: 5664,
    city: "Fort Myers",
    city_code: "RSW",
    airport_name: "Southwest Florida Reg",
    country: "United States",
  },
  {
    sr: 5666,
    city: "Fort Nelson",
    city_code: "YYE",
    airport_name: "Fort Nelson",
    country: "Canada",
  },
  {
    sr: 5668,
    city: "Fort Pierce",
    city_code: "FPR",
    airport_name: "St Lucie County",
    country: "United States",
  },
  {
    sr: 5670,
    city: "Fort Polk",
    city_code: "POE",
    airport_name: "Polk AAF",
    country: "United States",
  },
  {
    sr: 5672,
    city: "Fort Reliance",
    city_code: "YFL",
    airport_name: "Fort Reliance",
    country: "Canada",
  },
  {
    sr: 5674,
    city: "Fort Resolution",
    city_code: "YFR",
    airport_name: "Fort Resolution",
    country: "Canada",
  },
  {
    sr: 5678,
    city: "Fort Richardson",
    city_code: "FRN",
    airport_name: "Bryant AAF",
    country: "United States",
  },
  {
    sr: 5680,
    city: "Fort Riley",
    city_code: "FRI",
    airport_name: "Marshall AAF",
    country: "United States",
  },
  {
    sr: 5682,
    city: "Fort Scott",
    city_code: "FSK",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5684,
    city: "Fort Severn",
    city_code: "YER",
    airport_name: "Fort Severn",
    country: "Canada",
  },
  {
    sr: 5686,
    city: "Fort Sheridan",
    city_code: "FSN",
    airport_name: "Haley AAF",
    country: "United States",
  },
  {
    sr: 5688,
    city: "Fort Sill",
    city_code: "FSI",
    airport_name: "Henry Post AAF",
    country: "United States",
  },
  {
    sr: 5690,
    city: "Fort Simpson",
    city_code: "YFS",
    airport_name: "Fort Simpson",
    country: "Canada",
  },
  {
    sr: 5692,
    city: "Fort Smith",
    city_code: "FSM",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5694,
    city: "Fort Smith",
    city_code: "YSM",
    airport_name: "Fort Smith",
    country: "Canada",
  },
  {
    sr: 5696,
    city: "Fort St John",
    city_code: "YXJ",
    airport_name: "Fort St John",
    country: "Canada",
  },
  {
    sr: 5698,
    city: "Fort Stockton",
    city_code: "FST",
    airport_name: "Pecos County",
    country: "United States",
  },
  {
    sr: 5700,
    city: "Fort Sumner",
    city_code: "FSU",
    airport_name: "Fort Sumner",
    country: "United States",
  },
  {
    sr: 5702,
    city: "Fort Wayne",
    city_code: "FWA",
    airport_name: "Municipal Baer Field",
    country: "United States",
  },
  {
    sr: 5704,
    city: "Fort Wayne",
    city_code: "SMD",
    airport_name: "Smith Field",
    country: "United States",
  },
  {
    sr: 5706,
    city: "Fort William",
    city_code: "FWM",
    airport_name: "Heliport",
    country: "United Kingdom",
  },
  {
    sr: 5708,
    city: "Fort Worth",
    city_code: "FWH",
    airport_name: "Carswell AFB",
    country: "United States",
  },
  {
    sr: 5712,
    city: "Fort Worth",
    city_code: "AFW",
    airport_name: "Fort Worth Alliance",
    country: "United States",
  },
  {
    sr: 5714,
    city: "Fort Worth",
    city_code: "FTW",
    airport_name: "Meacham Field",
    country: "United States",
  },
  {
    sr: 5716,
    city: "Fort Yukon",
    city_code: "FYU",
    airport_name: "Fort Yukon",
    country: "United States",
  },
  {
    sr: 5718,
    city: "Fortaleza",
    city_code: "FOR",
    airport_name: "Pinto Martins",
    country: "Brazil",
  },
  {
    sr: 5720,
    city: "Fortuna",
    city_code: "FON",
    airport_name: "Fortuna",
    country: "Costa Rica",
  },
  {
    sr: 5722,
    city: "Fortuna Ledge",
    city_code: "FTL",
    airport_name: "Fortuna Ledge",
    country: "United States",
  },
  {
    sr: 5724,
    city: "Foshan",
    city_code: "ZCP",
    airport_name: "Foshan Ferry",
    country: "China",
  },
  {
    sr: 5726,
    city: "Fossil Downs",
    city_code: "FSL",
    airport_name: "Fossil Downs",
    country: "Australia",
  },
  {
    sr: 5728,
    city: "Fougamou",
    city_code: "FOU",
    airport_name: "Fougamou",
    country: "Gabon",
  },
  {
    sr: 5730,
    city: "Foula",
    city_code: "FOA",
    airport_name: "Foula",
    country: "United Kingdom",
  },
  {
    sr: 5732,
    city: "Foumban",
    city_code: "FOM",
    airport_name: "Foumban",
    country: "Cameroon",
  },
  {
    sr: 5734,
    city: "Fox",
    city_code: "FOX",
    airport_name: "Fox",
    country: "United States",
  },
  {
    sr: 5736,
    city: "Fox Glacier",
    city_code: "FGL",
    airport_name: "Fox Glacier",
    country: "New Zealand",
  },
  {
    sr: 5738,
    city: "Fox Harbour  St Lewis",
    city_code: "YFX",
    airport_name: "Fox Harbour  St Lewis",
    country: "Canada",
  },
  {
    sr: 5740,
    city: "Foya",
    city_code: "FOY",
    airport_name: "Foya",
    country: "Liberia",
  },
  {
    sr: 5742,
    city: "Franca",
    city_code: "FRC",
    airport_name: "Franca",
    country: "Brazil",
  },
  {
    sr: 5746,
    city: "Franceville",
    city_code: "MVB",
    airport_name: "Franceville Mvengue",
    country: "Gabon",
  },
  {
    sr: 5748,
    city: "Francisco Beltrao",
    city_code: "FBE",
    airport_name: "Francisco Beltrao",
    country: "Brazil",
  },
  {
    sr: 5750,
    city: "Francistown",
    city_code: "FRW",
    airport_name: "Francistown",
    country: "Botswana",
  },
  {
    sr: 5752,
    city: "Frankfort",
    city_code: "FFT",
    airport_name: "Capital City",
    country: "United States",
  },
  {
    sr: 5754,
    city: "Hahn",
    city_code: "HHN",
    airport_name: "Hahn",
    country: "Germany",
  },
  {
    sr: 5756,
    city: "Frankfurt",
    city_code: "FRA",
    airport_name: "Frankfurt International Airport  Rhein Main",
    country: "Germany",
  },
  {
    sr: 5758,
    city: "Frankfurt",
    city_code: "QGV",
    airport_name: "Neu Isenburg",
    country: "Germany",
  },
  {
    sr: 5760,
    city: "Frankfurt",
    city_code: "FRF",
    airport_name: "Rhein Main AFB",
    country: "Germany",
  },
  {
    sr: 5762,
    city: "Frankfurt Oder",
    city_code: "ZFR",
    airport_name: "Frankfurt Oder Railway",
    country: "Germany",
  },
  {
    sr: 5764,
    city: "Franklin",
    city_code: "FKL",
    airport_name: "Chess Lambertin",
    country: "United States",
  },
  {
    sr: 5766,
    city: "Franklin",
    city_code: "FKN",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5768,
    city: "Franz Josef",
    city_code: "WHO",
    airport_name: "Franz Josef",
    country: "New Zealand",
  },
  {
    sr: 5770,
    city: "Frederick",
    city_code: "FDK",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5772,
    city: "Frederick",
    city_code: "FDR",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5774,
    city: "Fredericton",
    city_code: "YFC",
    airport_name: "Fredericton",
    country: "Canada",
  },
  {
    sr: 5776,
    city: "Fredrikstad",
    city_code: "XKF",
    airport_name: "Fredrikstad Rail Stn",
    country: "Norway",
  },
  {
    sr: 5780,
    city: "Freeport",
    city_code: "FEP",
    airport_name: "Albertus",
    country: "United States",
  },
  {
    sr: 5782,
    city: "Freetown",
    city_code: "HGS",
    airport_name: "Hastings",
    country: "Sierra Leone",
  },
  {
    sr: 5784,
    city: "Freetown",
    city_code: "FNA",
    airport_name: "Lungi International",
    country: "Sierra Leone",
  },
  {
    sr: 5786,
    city: "Freetown",
    city_code: "JMY",
    airport_name: "Mammy Yoko Heliport",
    country: "Sierra Leone",
  },
  {
    sr: 5788,
    city: "Fregate Is",
    city_code: "FRK",
    airport_name: "Fregate Is",
    country: "Seychelles",
  },
  {
    sr: 5790,
    city: "Freida River",
    city_code: "FAQ",
    airport_name: "Freida River",
    country: "Papua New Guinea",
  },
  {
    sr: 5792,
    city: "Frejus",
    city_code: "FRJ",
    airport_name: "Frejus",
    country: "France",
  },
  {
    sr: 5794,
    city: "Fremantle",
    city_code: "JFM",
    airport_name: "Heliport",
    country: "Australia",
  },
  {
    sr: 5796,
    city: "Fremont",
    city_code: "FET",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5798,
    city: "French Lick",
    city_code: "FRH",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5800,
    city: "Frenchville",
    city_code: "WFK",
    airport_name: "Frenchville",
    country: "United States",
  },
  {
    sr: 5802,
    city: "Fresh Water Bay",
    city_code: "FRP",
    airport_name: "Fresh Water Bay",
    country: "United States",
  },
  {
    sr: 5804,
    city: "Fresno",
    city_code: "FAT",
    airport_name: "Airterminal",
    country: "United States",
  },
  {
    sr: 5806,
    city: "Fresno",
    city_code: "FCH",
    airport_name: "Fresno Chandler",
    country: "United States",
  },
  {
    sr: 5808,
    city: "Fria",
    city_code: "FIG",
    airport_name: "Fria",
    country: "Guinea",
  },
  {
    sr: 5810,
    city: "Friday Harbor",
    city_code: "FRD",
    airport_name: "Friday Harbor",
    country: "United States",
  },
  {
    sr: 5814,
    city: "Friday Harbor SPB",
    city_code: "FBS",
    airport_name: "Friday Harbor SPB",
    country: "United States",
  },
  {
    sr: 5816,
    city: "Friedrichshafen",
    city_code: "FDH",
    airport_name: "Friedrichshafen",
    country: "Germany",
  },
  {
    sr: 5818,
    city: "Fritzlar",
    city_code: "FRZ",
    airport_name: "Fritzlar Airbase",
    country: "Germany",
  },
  {
    sr: 5820,
    city: "Fort Myers  Florida",
    city_code: "FRR",
    airport_name: "Fort Meyers",
    country: "United States",
  },
  {
    sr: 5822,
    city: "Frutillar",
    city_code: "FRT",
    airport_name: "Frutillar",
    country: "Chile",
  },
  {
    sr: 5824,
    city: "Fryeburg",
    city_code: "FRY",
    airport_name: "Fryeburg",
    country: "United States",
  },
  {
    sr: 5826,
    city: "Fuengirola",
    city_code: "FGR",
    airport_name: "Fuengirola",
    country: "Spain",
  },
  {
    sr: 5828,
    city: "Fuerstenfeldbruck",
    city_code: "FEL",
    airport_name: "Fuerstenfeldbruck",
    country: "Germany",
  },
  {
    sr: 5830,
    city: "Fuerte Olimpo",
    city_code: "OLK",
    airport_name: "Fuerte Olimpo",
    country: "Paraguay",
  },
  {
    sr: 5832,
    city: "Fukue",
    city_code: "FUJ",
    airport_name: "Fukue",
    country: "Japan",
  },
  {
    sr: 5834,
    city: "Fukui",
    city_code: "FKJ",
    airport_name: "Fukui",
    country: "Japan",
  },
  {
    sr: 5836,
    city: "Fukuoka",
    city_code: "FUK",
    airport_name: "Fukuoka",
    country: "Japan",
  },
  {
    sr: 5838,
    city: "Fukushima",
    city_code: "FKS",
    airport_name: "Fukushima Airport",
    country: "Japan",
  },
  {
    sr: 5840,
    city: "Fulleborn",
    city_code: "FUB",
    airport_name: "Fulleborn",
    country: "Papua New Guinea",
  },
  {
    sr: 5842,
    city: "Fullerton",
    city_code: "FUL",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5844,
    city: "Fuma",
    city_code: "FUM",
    airport_name: "Fuma",
    country: "Papua New Guinea",
  },
  {
    sr: 5848,
    city: "Funafuti Atol",
    city_code: "FUN",
    airport_name: "International",
    country: "Tuvalu",
  },
  {
    sr: 5850,
    city: "Funter Bay",
    city_code: "FNR",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 5852,
    city: "Fuoshan",
    city_code: "FUO",
    airport_name: "Fuoshan",
    country: "China",
  },
  {
    sr: 5854,
    city: "Futaleufu",
    city_code: "FFU",
    airport_name: "Futaleufu",
    country: "Chile",
  },
  {
    sr: 5856,
    city: "Futuna Island",
    city_code: "FTA",
    airport_name: "Futuna Airport",
    country: "Vanuatu",
  },
  {
    sr: 5858,
    city: "Futuna Island",
    city_code: "FUT",
    airport_name: "Futuna Island",
    country: "Wallis and Futuna Islands",
  },
  {
    sr: 5860,
    city: "Fuyang",
    city_code: "FUG",
    airport_name: "Fuyang",
    country: "China",
  },
  {
    sr: 5862,
    city: "Fuyun",
    city_code: "FYN",
    airport_name: "Fuyun",
    country: "China",
  },
  {
    sr: 5864,
    city: "Fuzhou",
    city_code: "FOC",
    airport_name: "Fuzhou",
    country: "China",
  },
  {
    sr: 5866,
    city: "Gabbs",
    city_code: "GAB",
    airport_name: "Gabbs",
    country: "United States",
  },
  {
    sr: 5868,
    city: "Gabes",
    city_code: "GAE",
    airport_name: "Gabes",
    country: "Tunisia",
  },
  {
    sr: 5870,
    city: "Gaborone",
    city_code: "GBE",
    airport_name: "Sir Seretse Khama Int",
    country: "Botswana",
  },
  {
    sr: 5872,
    city: "Gachsaran",
    city_code: "GCH",
    airport_name: "Gachsaran",
    country: "Iran",
  },
  {
    sr: 5874,
    city: "Gadsden",
    city_code: "GAD",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5876,
    city: "Gafsa",
    city_code: "GAF",
    airport_name: "Gafsa",
    country: "Tunisia",
  },
  {
    sr: 5878,
    city: "Gag Island",
    city_code: "GAV",
    airport_name: "Gag Island",
    country: "Indonesia",
  },
  {
    sr: 5882,
    city: "Gage",
    city_code: "GAG",
    airport_name: "Gage",
    country: "United States",
  },
  {
    sr: 5884,
    city: "Gagetown",
    city_code: "YCX",
    airport_name: "Gagetown",
    country: "Canada",
  },
  {
    sr: 5886,
    city: "Gagnoa",
    city_code: "GGN",
    airport_name: "Gagnoa",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 5888,
    city: "Gagnon",
    city_code: "YGA",
    airport_name: "Gagnon",
    country: "Canada",
  },
  {
    sr: 5890,
    city: "Gainesville",
    city_code: "GNV",
    airport_name: "Gainesville Regional",
    country: "United States",
  },
  {
    sr: 5892,
    city: "Gainesville",
    city_code: "GVL",
    airport_name: "Lee Gilmer Memorial",
    country: "United States",
  },
  {
    sr: 5894,
    city: "Gainesville",
    city_code: "GLE",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5896,
    city: "Gaithersburg",
    city_code: "GAI",
    airport_name: "Montgomery County",
    country: "United States",
  },
  {
    sr: 5898,
    city: "Gakona",
    city_code: "GAK",
    airport_name: "Gakona",
    country: "United States",
  },
  {
    sr: 5900,
    city: "Gal Oya",
    city_code: "GOY",
    airport_name: "Amparai",
    country: "Sri Lanka",
  },
  {
    sr: 5902,
    city: "Galapagos Is",
    city_code: "GPS",
    airport_name: "Baltra",
    country: "Ecuador",
  },
  {
    sr: 5904,
    city: "Galbraith Lake",
    city_code: "GBH",
    airport_name: "Galbraith Lake",
    country: "United States",
  },
  {
    sr: 5906,
    city: "Galcaio",
    city_code: "GLK",
    airport_name: "Galcaio",
    country: "Somalia",
  },
  {
    sr: 5908,
    city: "Galela",
    city_code: "GLX",
    airport_name: "Galela",
    country: "Indonesia",
  },
  {
    sr: 5910,
    city: "Galena",
    city_code: "GAL",
    airport_name: "Galena",
    country: "United States",
  },
  {
    sr: 5912,
    city: "Galesburg",
    city_code: "GBG",
    airport_name: "Galesburg",
    country: "United States",
  },
  {
    sr: 5916,
    city: "Galion",
    city_code: "GQQ",
    airport_name: "Galion",
    country: "United States",
  },
  {
    sr: 5918,
    city: "Gallivare",
    city_code: "GEV",
    airport_name: "Gallivare",
    country: "Sweden",
  },
  {
    sr: 5920,
    city: "Gallup",
    city_code: "GUP",
    airport_name: "Senator Clark",
    country: "United States",
  },
  {
    sr: 5922,
    city: "Galveston",
    city_code: "GLS",
    airport_name: "Scholes Field",
    country: "United States",
  },
  {
    sr: 5924,
    city: "Galway",
    city_code: "GWY",
    airport_name: "Carnmore",
    country: "Ireland",
  },
  {
    sr: 5926,
    city: "Gamarra",
    city_code: "GRA",
    airport_name: "Gamarra",
    country: "Colombia",
  },
  {
    sr: 5928,
    city: "Gamba",
    city_code: "GAX",
    airport_name: "Gamba",
    country: "Gabon",
  },
  {
    sr: 5930,
    city: "Gambela",
    city_code: "GMB",
    airport_name: "Gambela",
    country: "Ethiopia",
  },
  {
    sr: 5932,
    city: "Gambell",
    city_code: "GAM",
    airport_name: "Gambell",
    country: "United States",
  },
  {
    sr: 5934,
    city: "Gambier Is",
    city_code: "GMR",
    airport_name: "Gambier Is",
    country: "French Polynesia",
  },
  {
    sr: 5936,
    city: "Gamboma",
    city_code: "GMM",
    airport_name: "Gamboma",
    country: "Congo",
  },
  {
    sr: 5938,
    city: "Gamboola",
    city_code: "GBP",
    airport_name: "Gamboola",
    country: "Australia",
  },
  {
    sr: 5940,
    city: "Gan Island",
    city_code: "GAN",
    airport_name: "Gan Airport",
    country: "Maldives",
  },
  {
    sr: 5942,
    city: "Gandajika",
    city_code: "GDJ",
    airport_name: "Gandajika",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 5944,
    city: "Gander",
    city_code: "YQX",
    airport_name: "Gander International",
    country: "Canada",
  },
  {
    sr: 5946,
    city: "Ganes Creek",
    city_code: "GEK",
    airport_name: "Ganes Creek",
    country: "United States",
  },
  {
    sr: 5950,
    city: "Gangaw",
    city_code: "GAW",
    airport_name: "Gangaw",
    country: "Myanmar",
  },
  {
    sr: 5952,
    city: "Ganges Harbor",
    city_code: "YGG",
    airport_name: "Ganges Harbor",
    country: "Canada",
  },
  {
    sr: 5954,
    city: "Gangneung",
    city_code: "KAG",
    airport_name: "Gangneung",
    country: "South Korea",
  },
  {
    sr: 5956,
    city: "Ganzhou",
    city_code: "KOW",
    airport_name: "Ganzhou",
    country: "China",
  },
  {
    sr: 5958,
    city: "Gao",
    city_code: "GAQ",
    airport_name: "Gao",
    country: "Mali",
  },
  {
    sr: 5960,
    city: "Gaoua",
    city_code: "XGA",
    airport_name: "Gaoua",
    country: "Burkina Faso",
  },
  {
    sr: 5962,
    city: "Gap",
    city_code: "GAT",
    airport_name: "Tallard",
    country: "France",
  },
  {
    sr: 5964,
    city: "Gara Djebilet",
    city_code: "GBB",
    airport_name: "Gara Djebilet",
    country: "Algeria",
  },
  {
    sr: 5966,
    city: "Garachine",
    city_code: "GHE",
    airport_name: "Garachine",
    country: "Panama",
  },
  {
    sr: 5968,
    city: "Garaina",
    city_code: "GAR",
    airport_name: "Garaina",
    country: "Papua New Guinea",
  },
  {
    sr: 5970,
    city: "Garasa",
    city_code: "GRL",
    airport_name: "Garasa",
    country: "Papua New Guinea",
  },
  {
    sr: 5972,
    city: "Garbaharey",
    city_code: "GBM",
    airport_name: "Garbaharey",
    country: "Somalia",
  },
  {
    sr: 5974,
    city: "Garden City",
    city_code: "GCK",
    airport_name: "Municipal Airport",
    country: "United States",
  },
  {
    sr: 5976,
    city: "Garden City",
    city_code: "JHC",
    airport_name: "Island Heliport",
    country: "United States",
  },
  {
    sr: 5978,
    city: "Garden Point",
    city_code: "GPN",
    airport_name: "Garden Point",
    country: "Australia",
  },
  {
    sr: 5980,
    city: "Gardez",
    city_code: "GRG",
    airport_name: "Gardez",
    country: "Afghanistan",
  },
  {
    sr: 5984,
    city: "Gardner",
    city_code: "GDM",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 5986,
    city: "Gardo",
    city_code: "GSR",
    airport_name: "Gardo",
    country: "Somalia",
  },
  {
    sr: 5988,
    city: "Garissa",
    city_code: "GAS",
    airport_name: "Garissa",
    country: "Kenya",
  },
  {
    sr: 5990,
    city: "Garoe",
    city_code: "GGR",
    airport_name: "Garoe",
    country: "Somalia",
  },
  {
    sr: 5992,
    city: "Garoua",
    city_code: "GOU",
    airport_name: "Garoua",
    country: "Cameroon",
  },
  {
    sr: 5994,
    city: "Garuahi",
    city_code: "GRH",
    airport_name: "Garuahi",
    country: "Papua New Guinea",
  },
  {
    sr: 5996,
    city: "Gascoyne Junction",
    city_code: "GSC",
    airport_name: "Gascoyne Junction",
    country: "Australia",
  },
  {
    sr: 5998,
    city: "Gasmata Island",
    city_code: "GMI",
    airport_name: "Gasmata Island",
    country: "Papua New Guinea",
  },
  {
    sr: 6000,
    city: "Buraidah",
    city_code: "ELQ",
    airport_name: "Gassim Regional Airport",
    country: "Saudi Arabia",
  },
  {
    sr: 6002,
    city: "Gasuke",
    city_code: "GBC",
    airport_name: "Gasuke",
    country: "Papua New Guinea",
  },
  {
    sr: 6004,
    city: "Gatlinburg",
    city_code: "GKT",
    airport_name: "Gatlinburg",
    country: "United States",
  },
  {
    sr: 6006,
    city: "Gatokae",
    city_code: "GTA",
    airport_name: "Gatokae Aerodrom",
    country: "Solomon Islands",
  },
  {
    sr: 6008,
    city: "Gaua",
    city_code: "ZGU",
    airport_name: "Gaua",
    country: "Vanuatu",
  },
  {
    sr: 6010,
    city: "Gavle",
    city_code: "GVX",
    airport_name: "Sandviken",
    country: "Sweden",
  },
  {
    sr: 6012,
    city: "Gawahati",
    city_code: "GAU",
    airport_name: "Borjhar",
    country: "India",
  },
  {
    sr: 6014,
    city: "Gaya",
    city_code: "GAY",
    airport_name: "Gaya",
    country: "India",
  },
  {
    sr: 6018,
    city: "Gaylord",
    city_code: "GLR",
    airport_name: "Otsego County",
    country: "United States",
  },
  {
    sr: 6020,
    city: "Gayndah",
    city_code: "GAH",
    airport_name: "Gayndah",
    country: "Australia",
  },
  {
    sr: 6022,
    city: "Gaziantep",
    city_code: "GZT",
    airport_name: "Gaziantep",
    country: "Turkey",
  },
  {
    sr: 6024,
    city: "Gbadolite",
    city_code: "BDT",
    airport_name: "Kisangani",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 6026,
    city: "Gbangbatok",
    city_code: "GBK",
    airport_name: "Gbangbatok",
    country: "Sierra Leone",
  },
  {
    sr: 6028,
    city: "Gdansk",
    city_code: "GDN",
    airport_name: "Rebiechowo",
    country: "Poland",
  },
  {
    sr: 6030,
    city: "Gebe",
    city_code: "GEB",
    airport_name: "Gebe",
    country: "Indonesia",
  },
  {
    sr: 6032,
    city: "Gecitkale",
    city_code: "GEC",
    airport_name: "Gecitkale",
    country: "Cyprus",
  },
  {
    sr: 6034,
    city: "Gedaref",
    city_code: "GSU",
    airport_name: "Gedaref",
    country: "Sudan",
  },
  {
    sr: 6036,
    city: "Geelong",
    city_code: "GEX",
    airport_name: "Geelong",
    country: "Australia",
  },
  {
    sr: 6038,
    city: "Geilenkirchen",
    city_code: "GKE",
    airport_name: "Geilenkirchen",
    country: "Germany",
  },
  {
    sr: 6040,
    city: "Geilo",
    city_code: "DLD",
    airport_name: "Dagali Airport",
    country: "Norway",
  },
  {
    sr: 6042,
    city: "Geita",
    city_code: "GIT",
    airport_name: "Geita",
    country: "Tanzania",
  },
  {
    sr: 6044,
    city: "Geladi",
    city_code: "GLC",
    airport_name: "Geladi",
    country: "Ethiopia",
  },
  {
    sr: 6046,
    city: "Gelendzik",
    city_code: "GDZ",
    airport_name: "Gelendzik",
    country: "Russia",
  },
  {
    sr: 6048,
    city: "Gemena",
    city_code: "GMA",
    airport_name: "Gemena",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 6052,
    city: "Genda Wuha",
    city_code: "ETE",
    airport_name: "Genda Wuha",
    country: "Ethiopia",
  },
  {
    sr: 6054,
    city: "Geneina",
    city_code: "EGN",
    airport_name: "Geneina",
    country: "Sudan",
  },
  {
    sr: 6056,
    city: "General Pico",
    city_code: "GPO",
    airport_name: "General Pico",
    country: "Argentina",
  },
  {
    sr: 6058,
    city: "General Roca",
    city_code: "GNR",
    airport_name: "General Roca",
    country: "Argentina",
  },
  {
    sr: 6060,
    city: "General Santos",
    city_code: "GES",
    airport_name: "Buayan",
    country: "Philippines",
  },
  {
    sr: 6062,
    city: "General Villegas",
    city_code: "VGS",
    airport_name: "General Villegas",
    country: "Argentina",
  },
  {
    sr: 6064,
    city: "Geneva",
    city_code: "GVA",
    airport_name: "Geneve cointrin",
    country: "Switzerland",
  },
  {
    sr: 6066,
    city: "Genoa",
    city_code: "GOA",
    airport_name: "Cristoforo Colombo",
    country: "Italy",
  },
  {
    sr: 6068,
    city: "Genting",
    city_code: "GTB",
    airport_name: "Genting",
    country: "Malaysia",
  },
  {
    sr: 6070,
    city: "Geoje",
    city_code: "JGE",
    airport_name: "Heliport",
    country: "South Korea",
  },
  {
    sr: 6072,
    city: "George",
    city_code: "GRJ",
    airport_name: "George",
    country: "South Africa",
  },
  {
    sr: 6074,
    city: "George Town",
    city_code: "GGT",
    airport_name: "Exuma International",
    country: "Bahamas",
  },
  {
    sr: 6076,
    city: "George Town",
    city_code: "GEE",
    airport_name: "George Town",
    country: "Australia",
  },
  {
    sr: 6078,
    city: "Georgetown",
    city_code: "GED",
    airport_name: "Sussex County",
    country: "United States",
  },
  {
    sr: 6080,
    city: "Georgetown",
    city_code: "GTT",
    airport_name: "Georgetown",
    country: "Australia",
  },
  {
    sr: 6082,
    city: "Georgetown",
    city_code: "GGE",
    airport_name: "Georgetown",
    country: "United States",
  },
  {
    sr: 6086,
    city: "Georgetown  Ascension Island",
    city_code: "ASI",
    airport_name: "Wideawake Fld",
    country: "St  Helena",
  },
  {
    sr: 6088,
    city: "Gera",
    city_code: "ZGA",
    airport_name: "Gera Railway",
    country: "Germany",
  },
  {
    sr: 6090,
    city: "Geraldton",
    city_code: "YGQ",
    airport_name: "Geraldton",
    country: "Canada",
  },
  {
    sr: 6092,
    city: "Geraldton",
    city_code: "GET",
    airport_name: "Geraldton",
    country: "Australia",
  },
  {
    sr: 6094,
    city: "Germansen",
    city_code: "YGS",
    airport_name: "Germansen",
    country: "Canada",
  },
  {
    sr: 6096,
    city: "Gerona",
    city_code: "GRO",
    airport_name: "Costa Brava",
    country: "Spain",
  },
  {
    sr: 6098,
    city: "Gethsemani",
    city_code: "ZGS",
    airport_name: "Gethsemani",
    country: "Canada",
  },
  {
    sr: 6100,
    city: "Gettysburg",
    city_code: "GTY",
    airport_name: "Gettysburg",
    country: "United States",
  },
  {
    sr: 6102,
    city: "Geva",
    city_code: "GEF",
    airport_name: "Geva Airstrip",
    country: "Solomon Islands",
  },
  {
    sr: 6104,
    city: "Gewoia",
    city_code: "GEW",
    airport_name: "Gewoia",
    country: "Papua New Guinea",
  },
  {
    sr: 6106,
    city: "Ghadames",
    city_code: "LTD",
    airport_name: "Ghadames",
    country: "Libya",
  },
  {
    sr: 6108,
    city: "Ghanzi",
    city_code: "GNZ",
    airport_name: "Ghanzi",
    country: "Botswana",
  },
  {
    sr: 6110,
    city: "Ghardaia",
    city_code: "GHA",
    airport_name: "Noumerate",
    country: "Algeria",
  },
  {
    sr: 6112,
    city: "Ghat",
    city_code: "GHT",
    airport_name: "Ghat",
    country: "Libya",
  },
  {
    sr: 6114,
    city: "Ghazni",
    city_code: "GZI",
    airport_name: "Ghazni",
    country: "Afghanistan",
  },
  {
    sr: 6116,
    city: "Ghazvin",
    city_code: "GZW",
    airport_name: "Ghazvin",
    country: "Iran",
  },
  {
    sr: 6120,
    city: "Ghent",
    city_code: "GNE",
    airport_name: "Ghent",
    country: "Belgium",
  },
  {
    sr: 6122,
    city: "Gheshm",
    city_code: "GSM",
    airport_name: "Gheshm",
    country: "Iran",
  },
  {
    sr: 6124,
    city: "Ghimbi",
    city_code: "GHD",
    airport_name: "Ghimbi",
    country: "Ethiopia",
  },
  {
    sr: 6126,
    city: "Ghinnir",
    city_code: "GNN",
    airport_name: "Ghinnir",
    country: "Ethiopia",
  },
  {
    sr: 6128,
    city: "Gibb River",
    city_code: "GBV",
    airport_name: "Gibb River",
    country: "Australia",
  },
  {
    sr: 6130,
    city: "Gibraltar",
    city_code: "GIB",
    airport_name: "North Front",
    country: "Gibraltar",
  },
  {
    sr: 6132,
    city: "Giebelstadt",
    city_code: "GHF",
    airport_name: "Giebelstadt",
    country: "Germany",
  },
  {
    sr: 6134,
    city: "Gilgal",
    city_code: "GGL",
    airport_name: "Gilgal",
    country: "Colombia",
  },
  {
    sr: 6136,
    city: "Gilgit",
    city_code: "GIL",
    airport_name: "Gilgit",
    country: "Pakistan",
  },
  {
    sr: 6138,
    city: "Gillam",
    city_code: "YGX",
    airport_name: "Gillam",
    country: "Canada",
  },
  {
    sr: 6140,
    city: "Gillette",
    city_code: "GCC",
    airport_name: "Campbell County",
    country: "United States",
  },
  {
    sr: 6142,
    city: "Gillies Bay",
    city_code: "YGB",
    airport_name: "Gillies Bay",
    country: "Canada",
  },
  {
    sr: 6144,
    city: "Gimli",
    city_code: "YGM",
    airport_name: "Gimli",
    country: "Canada",
  },
  {
    sr: 6146,
    city: "Girardot",
    city_code: "GIR",
    airport_name: "Girardot",
    country: "Colombia",
  },
  {
    sr: 6148,
    city: "Gisborne",
    city_code: "GIS",
    airport_name: "Gisborne",
    country: "New Zealand",
  },
  {
    sr: 6150,
    city: "Gisenyi",
    city_code: "GYI",
    airport_name: "Gisenyi",
    country: "Rwanda",
  },
  {
    sr: 6154,
    city: "Gitega",
    city_code: "GID",
    airport_name: "Gitega",
    country: "Burundi",
  },
  {
    sr: 6156,
    city: "Giyani",
    city_code: "GIY",
    airport_name: "Giyani",
    country: "South Africa",
  },
  {
    sr: 6158,
    city: "Gizo",
    city_code: "GZO",
    airport_name: "Gizo",
    country: "Solomon Islands",
  },
  {
    sr: 6160,
    city: "Gjoa Haven",
    city_code: "YHK",
    airport_name: "Gjoa Haven",
    country: "Canada",
  },
  {
    sr: 6162,
    city: "Gjogur",
    city_code: "GJR",
    airport_name: "Gjogur",
    country: "Iceland",
  },
  {
    sr: 6164,
    city: "Gjovik",
    city_code: "ZYG",
    airport_name: "Railway",
    country: "Norway",
  },
  {
    sr: 6166,
    city: "Glacier Creek",
    city_code: "KGZ",
    airport_name: "Glacier Creek",
    country: "United States",
  },
  {
    sr: 6168,
    city: "Gladstone",
    city_code: "GLT",
    airport_name: "Gladstone",
    country: "Australia",
  },
  {
    sr: 6170,
    city: "Gladwin",
    city_code: "GDW",
    airport_name: "Gladwin",
    country: "United States",
  },
  {
    sr: 6172,
    city: "Glasgow",
    city_code: "GLA",
    airport_name: "Glasgow International",
    country: "United Kingdom",
  },
  {
    sr: 6174,
    city: "Glasgow",
    city_code: "PIK",
    airport_name: "Prestwick",
    country: "United Kingdom",
  },
  {
    sr: 6176,
    city: "Glasgow",
    city_code: "ZGG",
    airport_name: "ScotRail",
    country: "United Kingdom",
  },
  {
    sr: 6178,
    city: "Glasgow",
    city_code: "GLW",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 6180,
    city: "Glasgow",
    city_code: "GGW",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 6182,
    city: "Glen Innes",
    city_code: "GLI",
    airport_name: "Glen Innes",
    country: "Australia",
  },
  {
    sr: 6184,
    city: "Glendale",
    city_code: "JGX",
    airport_name: "Heliport",
    country: "United States",
  },
  {
    sr: 6188,
    city: "Glendale",
    city_code: "GWV",
    airport_name: "Glendale",
    country: "United States",
  },
  {
    sr: 6190,
    city: "Glendive",
    city_code: "GDV",
    airport_name: "Dawson Community",
    country: "United States",
  },
  {
    sr: 6192,
    city: "Glengyle",
    city_code: "GLG",
    airport_name: "Glengyle",
    country: "Australia",
  },
  {
    sr: 6194,
    city: "Glennallen",
    city_code: "GLQ",
    airport_name: "Glennallen",
    country: "United States",
  },
  {
    sr: 6196,
    city: "Glenormiston",
    city_code: "GLM",
    airport_name: "Glenormiston",
    country: "Australia",
  },
  {
    sr: 6198,
    city: "Glens Falls",
    city_code: "GFL",
    airport_name: "Warren County",
    country: "United States",
  },
  {
    sr: 6200,
    city: "Glenview",
    city_code: "NBU",
    airport_name: "NAS",
    country: "United States",
  },
  {
    sr: 6202,
    city: "Glenwood Springs",
    city_code: "GWS",
    airport_name: "Glenwood Springs",
    country: "United States",
  },
  {
    sr: 6204,
    city: "Gloucester",
    city_code: "GLO",
    airport_name: "Gloucestershire",
    country: "United Kingdom",
  },
  {
    sr: 6206,
    city: "Glynco",
    city_code: "NEA",
    airport_name: "Brunswick Golden Isles",
    country: "United States",
  },
  {
    sr: 6208,
    city: "Gnarowein",
    city_code: "GWN",
    airport_name: "Gnarowein",
    country: "Papua New Guinea",
  },
  {
    sr: 6210,
    city: "Goa",
    city_code: "GOI",
    airport_name: "Dabolim",
    country: "India",
  },
  {
    sr: 6212,
    city: "Goba",
    city_code: "GOB",
    airport_name: "Goba",
    country: "Ethiopia",
  },
  {
    sr: 6214,
    city: "Gobabis",
    city_code: "GOG",
    airport_name: "Gobabis",
    country: "Namibia",
  },
  {
    sr: 6216,
    city: "Gobernador Gregores",
    city_code: "GGS",
    airport_name: "Gobernador Gregores",
    country: "Argentina",
  },
  {
    sr: 6218,
    city: "Gode Iddidole",
    city_code: "GDE",
    airport_name: "Gode Iddidole",
    country: "Ethiopia",
  },
  {
    sr: 6222,
    city: "Gods Narrows",
    city_code: "YGO",
    airport_name: "Gods Narrows",
    country: "Canada",
  },
  {
    sr: 6224,
    city: "Gods River",
    city_code: "ZGI",
    airport_name: "Gods River",
    country: "Canada",
  },
  {
    sr: 6226,
    city: "Goerlitz",
    city_code: "ZGE",
    airport_name: "Goerlitz Railway",
    country: "Germany",
  },
  {
    sr: 6228,
    city: "Goiania",
    city_code: "GYN",
    airport_name: "Santa Genoveva",
    country: "Brazil",
  },
  {
    sr: 6230,
    city: "Gol",
    city_code: "GLL",
    airport_name: "Klanten Arpt",
    country: "Norway",
  },
  {
    sr: 6232,
    city: "Gold Beach",
    city_code: "GOL",
    airport_name: "State",
    country: "United States",
  },
  {
    sr: 6234,
    city: "Gold Coast",
    city_code: "OOL",
    airport_name: "Gold Coast  Coolangatta",
    country: "Australia",
  },
  {
    sr: 6236,
    city: "Golden Horn",
    city_code: "GDH",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 6238,
    city: "Goldsboro",
    city_code: "GSB",
    airport_name: "Seymour Johnson AFB",
    country: "United States",
  },
  {
    sr: 6240,
    city: "Goldsworthy",
    city_code: "GLY",
    airport_name: "Goldsworthy",
    country: "Australia",
  },
  {
    sr: 6242,
    city: "Golfito",
    city_code: "GLF",
    airport_name: "Golfito",
    country: "Costa Rica",
  },
  {
    sr: 6244,
    city: "Golmud",
    city_code: "GOQ",
    airport_name: "Golmud",
    country: "China",
  },
  {
    sr: 6246,
    city: "Golovin",
    city_code: "GLV",
    airport_name: "Golovin",
    country: "United States",
  },
  {
    sr: 6248,
    city: "Goma",
    city_code: "GOM",
    airport_name: "Goma",
    country: "D  R  Congo",
  },
  {
    sr: 6250,
    city: "Gomel",
    city_code: "GME",
    airport_name: "Gomel",
    country: "Belarus",
  },
  {
    sr: 6252,
    city: "Gonalia",
    city_code: "GOE",
    airport_name: "Gonalia",
    country: "Papua New Guinea",
  },
  {
    sr: 6256,
    city: "Gondar",
    city_code: "GDQ",
    airport_name: "Gondar",
    country: "Ethiopia",
  },
  {
    sr: 6258,
    city: "Gooding",
    city_code: "GNG",
    airport_name: "Gooding",
    country: "United States",
  },
  {
    sr: 6260,
    city: "Goodland",
    city_code: "GLD",
    airport_name: "Renner Field",
    country: "United States",
  },
  {
    sr: 6262,
    city: "Goodnews Bay",
    city_code: "GNU",
    airport_name: "Goodnews Bay",
    country: "United States",
  },
  {
    sr: 6264,
    city: "Goodyear",
    city_code: "GYR",
    airport_name: "Litchfield",
    country: "United States",
  },
  {
    sr: 6266,
    city: "Goondiwindi",
    city_code: "GOO",
    airport_name: "Goondiwindi",
    country: "Australia",
  },
  {
    sr: 6268,
    city: "Goose Bay",
    city_code: "YYR",
    airport_name: "Goose Bay",
    country: "Canada",
  },
  {
    sr: 6270,
    city: "Gora",
    city_code: "GOC",
    airport_name: "Gora",
    country: "Papua New Guinea",
  },
  {
    sr: 6272,
    city: "Gorakhpur",
    city_code: "GOP",
    airport_name: "Gorakhpur",
    country: "India",
  },
  {
    sr: 6274,
    city: "Gordil",
    city_code: "GDI",
    airport_name: "Gordil",
    country: "Central African Republic",
  },
  {
    sr: 6276,
    city: "Gordon",
    city_code: "GRN",
    airport_name: "Gordon",
    country: "United States",
  },
  {
    sr: 6278,
    city: "Gordon Downs",
    city_code: "GDD",
    airport_name: "Gordon Downs",
    country: "Australia",
  },
  {
    sr: 6280,
    city: "Gordonsville",
    city_code: "GVE",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 6282,
    city: "Gore",
    city_code: "GOR",
    airport_name: "Gore",
    country: "Ethiopia",
  },
  {
    sr: 6284,
    city: "Gore Bay",
    city_code: "YZE",
    airport_name: "Gore Bay",
    country: "Canada",
  },
  {
    sr: 6286,
    city: "Gorgan",
    city_code: "GBT",
    airport_name: "Gorgan",
    country: "Iran",
  },
  {
    sr: 6290,
    city: "Gorge Harbor",
    city_code: "YGE",
    airport_name: "Gorge Harbor",
    country: "Canada",
  },
  {
    sr: 6292,
    city: "Gorkha",
    city_code: "GKH",
    airport_name: "Gorkha",
    country: "Nepal",
  },
  {
    sr: 6294,
    city: "Gorna Orechovitsa",
    city_code: "GOZ",
    airport_name: "Gorna Orechovitsa",
    country: "Bulgaria",
  },
  {
    sr: 6296,
    city: "Goroka",
    city_code: "GKA",
    airport_name: "Goroka",
    country: "Papua New Guinea",
  },
  {
    sr: 6298,
    city: "Gorom Gorom",
    city_code: "XGG",
    airport_name: "Gorom Gorom",
    country: "Burkina Faso",
  },
  {
    sr: 6300,
    city: "Gorontalo",
    city_code: "GTO",
    airport_name: "Tolotio",
    country: "Indonesia",
  },
  {
    sr: 6302,
    city: "Gosford",
    city_code: "GOS",
    airport_name: "Gosford",
    country: "Australia",
  },
  {
    sr: 6304,
    city: "Goshen",
    city_code: "GSH",
    airport_name: "Goshen",
    country: "United States",
  },
  {
    sr: 6306,
    city: "Gotha",
    city_code: "ZGO",
    airport_name: "Gotha Railway",
    country: "Germany",
  },
  {
    sr: 6308,
    city: "Gothenburg",
    city_code: "XWL",
    airport_name: "Gothenburg Rail",
    country: "Sweden",
  },
  {
    sr: 6310,
    city: "Gothenburg",
    city_code: "GOT",
    airport_name: "Landvetter",
    country: "Sweden",
  },
  {
    sr: 6312,
    city: "Gothenburg",
    city_code: "GSE",
    airport_name: "Saeve",
    country: "Sweden",
  },
  {
    sr: 6314,
    city: "Goulburn",
    city_code: "GUL",
    airport_name: "Goulburn",
    country: "Australia",
  },
  {
    sr: 6316,
    city: "Goulburn Island",
    city_code: "GBL",
    airport_name: "Goulburn Island",
    country: "Australia",
  },
  {
    sr: 6318,
    city: "Goulimime",
    city_code: "GLN",
    airport_name: "Goulimime",
    country: "Morocco",
  },
  {
    sr: 6320,
    city: "Gounda",
    city_code: "GDA",
    airport_name: "Gounda",
    country: "Central African Republic",
  },
  {
    sr: 6324,
    city: "Goundam",
    city_code: "GUD",
    airport_name: "Goundam",
    country: "Mali",
  },
  {
    sr: 6326,
    city: "Gove",
    city_code: "GOV",
    airport_name: "Nhulunbuy",
    country: "Australia",
  },
  {
    sr: 6328,
    city: "Governador Valadares",
    city_code: "GVR",
    airport_name: "Governador Valadares",
    country: "Brazil",
  },
  {
    sr: 6330,
    city: "Governors Harbour",
    city_code: "GHB",
    airport_name: "Governors Harbour",
    country: "Bahamas",
  },
  {
    sr: 6332,
    city: "Goya",
    city_code: "OYA",
    airport_name: "Goya",
    country: "Argentina",
  },
  {
    sr: 6334,
    city: "Gozo",
    city_code: "GZM",
    airport_name: "Gozo Heliport",
    country: "Malta",
  },
  {
    sr: 6336,
    city: "Gracias",
    city_code: "GAC",
    airport_name: "Gracias",
    country: "Honduras",
  },
  {
    sr: 6338,
    city: "Graciosa Island",
    city_code: "GRW",
    airport_name: "Graciosa Island",
    country: "Portugal",
  },
  {
    sr: 6340,
    city: "Grafton",
    city_code: "GFN",
    airport_name: "Grafton",
    country: "Australia",
  },
  {
    sr: 6342,
    city: "Granada",
    city_code: "GRX",
    airport_name: "Granada",
    country: "Spain",
  },
  {
    sr: 6344,
    city: "Grand Bahama",
    city_code: "GBI",
    airport_name: "Aux Ab",
    country: "Bahamas",
  },
  {
    sr: 6346,
    city: "Grand Canyon",
    city_code: "JGC",
    airport_name: "Heliport",
    country: "United States",
  },
  {
    sr: 6348,
    city: "Grand Canyon",
    city_code: "GCN",
    airport_name: "National Park",
    country: "United States",
  },
  {
    sr: 6350,
    city: "Grand Canyon",
    city_code: "NGC",
    airport_name: "North Rim",
    country: "United States",
  },
  {
    sr: 6352,
    city: "Grand Canyon",
    city_code: "FLG",
    airport_name: "Pulliam Field",
    country: "United States",
  },
  {
    sr: 6354,
    city: "Georgetown",
    city_code: "GCM",
    airport_name: "Owen Roberts International",
    country: "Cayman Islands",
  },
  {
    sr: 6358,
    city: "Grand Cess",
    city_code: "GRC",
    airport_name: "Grand Cess",
    country: "Liberia",
  },
  {
    sr: 6360,
    city: "Grand Forks",
    city_code: "ZGF",
    airport_name: "Grand Forks",
    country: "Canada",
  },
  {
    sr: 6362,
    city: "Grand Forks",
    city_code: "GFK",
    airport_name: "Grand Forks",
    country: "United States",
  },
  {
    sr: 6364,
    city: "Grand Island",
    city_code: "GRI",
    airport_name: "Grand Island",
    country: "United States",
  },
  {
    sr: 6366,
    city: "Grand Junction",
    city_code: "GJT",
    airport_name: "Walker Field",
    country: "United States",
  },
  {
    sr: 6368,
    city: "Grand Marais",
    city_code: "GRM",
    airport_name: "Devils Track",
    country: "United States",
  },
  {
    sr: 6370,
    city: "Grand Rapids",
    city_code: "GRR",
    airport_name: "Kent County Intl",
    country: "United States",
  },
  {
    sr: 6372,
    city: "Grand Rapids",
    city_code: "GPZ",
    airport_name: "Grand Rapids",
    country: "United States",
  },
  {
    sr: 6374,
    city: "Grand Turk Is",
    city_code: "GDT",
    airport_name: "Grand Turk Is",
    country: "Turks and Caicos Islands",
  },
  {
    sr: 6376,
    city: "Grande Cache",
    city_code: "YGC",
    airport_name: "Grande Cache",
    country: "Canada",
  },
  {
    sr: 6378,
    city: "Grande Prairie",
    city_code: "YQU",
    airport_name: "Grande Prairie",
    country: "Canada",
  },
  {
    sr: 6380,
    city: "Grandview",
    city_code: "GVW",
    airport_name: "Richards Gebaur",
    country: "United States",
  },
  {
    sr: 6382,
    city: "Granite Mountain",
    city_code: "GMT",
    airport_name: "Granite Mountain",
    country: "United States",
  },
  {
    sr: 6384,
    city: "Granites",
    city_code: "GTS",
    airport_name: "Granites",
    country: "Australia",
  },
  {
    sr: 6386,
    city: "Grants",
    city_code: "GNT",
    airport_name: "Milan",
    country: "United States",
  },
  {
    sr: 6388,
    city: "Grantsburg",
    city_code: "GTG",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 6392,
    city: "Granville",
    city_code: "GFR",
    airport_name: "Granville",
    country: "France",
  },
  {
    sr: 6394,
    city: "Granville Lake",
    city_code: "XGL",
    airport_name: "Granville Lake",
    country: "Canada",
  },
  {
    sr: 6396,
    city: "Gravatai",
    city_code: "GCV",
    airport_name: "Gravatai",
    country: "Brazil",
  },
  {
    sr: 6398,
    city: "Grayling",
    city_code: "KGX",
    airport_name: "Grayling",
    country: "United States",
  },
  {
    sr: 6400,
    city: "Graz",
    city_code: "GRZ",
    airport_name: "Thalerhof",
    country: "Austria",
  },
  {
    sr: 6402,
    city: "Great Barrier Island",
    city_code: "GBZ",
    airport_name: "Great Barrier Island",
    country: "New Zealand",
  },
  {
    sr: 6404,
    city: "Great Barrington",
    city_code: "GBR",
    airport_name: "Great Barrington",
    country: "United States",
  },
  {
    sr: 6406,
    city: "Great Bear Lake",
    city_code: "DAS",
    airport_name: "Great Bear Lake",
    country: "Canada",
  },
  {
    sr: 6408,
    city: "Great Bend",
    city_code: "GBD",
    airport_name: "Great Bend",
    country: "United States",
  },
  {
    sr: 6410,
    city: "Great Falls",
    city_code: "GTF",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 6412,
    city: "Great Falls",
    city_code: "GFA",
    airport_name: "Malmstrom AFB",
    country: "United States",
  },
  {
    sr: 6414,
    city: "Great Harbour",
    city_code: "GHC",
    airport_name: "Great Harbour",
    country: "Bahamas",
  },
  {
    sr: 6416,
    city: "Great Keppel Island",
    city_code: "GKL",
    airport_name: "Great Keppel Island",
    country: "Australia",
  },
  {
    sr: 6418,
    city: "Greeley",
    city_code: "GXY",
    airport_name: "Weld County",
    country: "United States",
  },
  {
    sr: 6420,
    city: "Green Bay",
    city_code: "GRB",
    airport_name: "Austin straubel Field",
    country: "United States",
  },
  {
    sr: 6422,
    city: "Green Island",
    city_code: "GNI",
    airport_name: "Green Island",
    country: "Taiwan",
  },
  {
    sr: 6426,
    city: "Green Islands",
    city_code: "GEI",
    airport_name: "Green Islands",
    country: "Papua New Guinea",
  },
  {
    sr: 6428,
    city: "Green River",
    city_code: "GVI",
    airport_name: "Green River",
    country: "Papua New Guinea",
  },
  {
    sr: 6430,
    city: "Green River",
    city_code: "RVR",
    airport_name: "Green River",
    country: "United States",
  },
  {
    sr: 6432,
    city: "Green Turtle",
    city_code: "GTC",
    airport_name: "Green Turtle",
    country: "Bahamas",
  },
  {
    sr: 6434,
    city: "Greenfield",
    city_code: "GFD",
    airport_name: "Pope Field",
    country: "United States",
  },
  {
    sr: 6436,
    city: "Greenvale",
    city_code: "GVP",
    airport_name: "Greenvale",
    country: "Australia",
  },
  {
    sr: 6438,
    city: "Greenville",
    city_code: "GRE",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 6440,
    city: "Greenville",
    city_code: "GLH",
    airport_name: "Greenville",
    country: "United States",
  },
  {
    sr: 6442,
    city: "Greenville",
    city_code: "PGV",
    airport_name: "Pitt Greenville",
    country: "United States",
  },
  {
    sr: 6444,
    city: "Greenville",
    city_code: "GDC",
    airport_name: "Donaldson Center",
    country: "United States",
  },
  {
    sr: 6446,
    city: "Greenville",
    city_code: "GMU",
    airport_name: "Downtown",
    country: "United States",
  },
  {
    sr: 6448,
    city: "Greenville",
    city_code: "GSP",
    airport_name: "Greenville Spartanbur",
    country: "United States",
  },
  {
    sr: 6450,
    city: "Greenville",
    city_code: "GCY",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 6452,
    city: "Greenville",
    city_code: "GVT",
    airport_name: "Majors Field",
    country: "United States",
  },
  {
    sr: 6454,
    city: "Greenway Sound",
    city_code: "YGN",
    airport_name: "Greenway Sound",
    country: "Canada",
  },
  {
    sr: 6456,
    city: "Greenwood",
    city_code: "GWO",
    airport_name: "Leflore",
    country: "United States",
  },
  {
    sr: 6460,
    city: "Greenwood",
    city_code: "YZX",
    airport_name: "Greenwood",
    country: "Canada",
  },
  {
    sr: 6462,
    city: "Greenwood",
    city_code: "GRD",
    airport_name: "Greenwood",
    country: "United States",
  },
  {
    sr: 6464,
    city: "Gregory Downs",
    city_code: "GGD",
    airport_name: "Gregory Downs",
    country: "Australia",
  },
  {
    sr: 6466,
    city: "Greifswald",
    city_code: "ZGW",
    airport_name: "Greifswald Railway",
    country: "Germany",
  },
  {
    sr: 6468,
    city: "Grenada",
    city_code: "GND",
    airport_name: "Point Saline Intl",
    country: "Grenada",
  },
  {
    sr: 6470,
    city: "Grenfell",
    city_code: "GFE",
    airport_name: "Grenfell",
    country: "Australia",
  },
  {
    sr: 6472,
    city: "Greybull",
    city_code: "GEY",
    airport_name: "South Big Horn County",
    country: "United States",
  },
  {
    sr: 6474,
    city: "Greymouth",
    city_code: "GMN",
    airport_name: "Greymouth",
    country: "New Zealand",
  },
  {
    sr: 6476,
    city: "Griffith",
    city_code: "GFF",
    airport_name: "Griffith",
    country: "Australia",
  },
  {
    sr: 6478,
    city: "Grimsby",
    city_code: "GSY",
    airport_name: "Binbrook",
    country: "United Kingdom",
  },
  {
    sr: 6480,
    city: "Grimsey",
    city_code: "GRY",
    airport_name: "Grimsey",
    country: "Iceland",
  },
  {
    sr: 6482,
    city: "Grise Fiord",
    city_code: "YGZ",
    airport_name: "Grise Fiord",
    country: "Canada",
  },
  {
    sr: 6484,
    city: "Grodna",
    city_code: "GNA",
    airport_name: "Grodna",
    country: "Belarus",
  },
  {
    sr: 6486,
    city: "Groennedal",
    city_code: "JGR",
    airport_name: "Heliport",
    country: "Greenland",
  },
  {
    sr: 6488,
    city: "Groningen",
    city_code: "GRQ",
    airport_name: "Eelde",
    country: "Netherlands",
  },
  {
    sr: 6490,
    city: "Groote Eylandt",
    city_code: "GTE",
    airport_name: "Alyangula",
    country: "Australia",
  },
  {
    sr: 6494,
    city: "Grootfontein",
    city_code: "GFY",
    airport_name: "Grootfontein",
    country: "Namibia",
  },
  {
    sr: 6496,
    city: "Grosseto",
    city_code: "GRS",
    airport_name: "Baccarini",
    country: "Italy",
  },
  {
    sr: 6498,
    city: "Groton",
    city_code: "ZGD",
    airport_name: "New London Rail",
    country: "United States",
  },
  {
    sr: 6500,
    city: "Groznyj",
    city_code: "GRV",
    airport_name: "Groznyj",
    country: "Russia",
  },
  {
    sr: 6502,
    city: "Grundarfjordur",
    city_code: "GUU",
    airport_name: "Grundarfjordur",
    country: "Iceland",
  },
  {
    sr: 6504,
    city: "Guacamaya",
    city_code: "GCA",
    airport_name: "Guacamaya",
    country: "Colombia",
  },
  {
    sr: 6506,
    city: "Guadalajara",
    city_code: "GDL",
    airport_name: "Miguel Hidal",
    country: "Mexico",
  },
  {
    sr: 6508,
    city: "Guadalcanal",
    city_code: "GSI",
    airport_name: "Guadalcanal",
    country: "Solomon Islands",
  },
  {
    sr: 6510,
    city: "Guadalupe",
    city_code: "GDP",
    airport_name: "Guadalupe",
    country: "Brazil",
  },
  {
    sr: 6512,
    city: "Guajara Mirim",
    city_code: "GJM",
    airport_name: "Guajara Mirim",
    country: "Brazil",
  },
  {
    sr: 6514,
    city: "Gualaco",
    city_code: "GUO",
    airport_name: "Gualaco",
    country: "Honduras",
  },
  {
    sr: 6516,
    city: "Gualeguaychu",
    city_code: "GHU",
    airport_name: "Gualeguaychu",
    country: "Argentina",
  },
  {
    sr: 6518,
    city: "Agana",
    city_code: "NGM",
    airport_name: "Agana Nas",
    country: "Guam  usa",
  },
  {
    sr: 6520,
    city: "Guam",
    city_code: "UAM",
    airport_name: "Anderson AFB",
    country: "Guam  USA",
  },
  {
    sr: 6522,
    city: "Guam",
    city_code: "NRV",
    airport_name: "Uscg Shore St",
    country: "Guam",
  },
  {
    sr: 6524,
    city: "Guamal",
    city_code: "GAA",
    airport_name: "Guamal",
    country: "Colombia",
  },
  {
    sr: 6528,
    city: "Guanaja",
    city_code: "GJA",
    airport_name: "Guanaja",
    country: "Honduras",
  },
  {
    sr: 6530,
    city: "Guanambi",
    city_code: "GNM",
    airport_name: "Guanambi",
    country: "Brazil",
  },
  {
    sr: 6532,
    city: "Guanare",
    city_code: "GUQ",
    airport_name: "Guanare",
    country: "Venezuela",
  },
  {
    sr: 6534,
    city: "Guang Yuan",
    city_code: "GYS",
    airport_name: "Guang Yuan",
    country: "China",
  },
  {
    sr: 6536,
    city: "Guanghan",
    city_code: "GHN",
    airport_name: "Guanghan",
    country: "China",
  },
  {
    sr: 6538,
    city: "Guanghua",
    city_code: "LHK",
    airport_name: "Guanghua",
    country: "China",
  },
  {
    sr: 6540,
    city: "Guangzhou",
    city_code: "CAN",
    airport_name: "Baiyun",
    country: "China",
  },
  {
    sr: 6542,
    city: "Guantanamo",
    city_code: "NBW",
    airport_name: "Guantanamo NAS",
    country: "Cuba",
  },
  {
    sr: 6544,
    city: "Guantanamo",
    city_code: "GAO",
    airport_name: "Los Canos",
    country: "Cuba",
  },
  {
    sr: 6546,
    city: "Guapi",
    city_code: "GPI",
    airport_name: "Guapi",
    country: "Colombia",
  },
  {
    sr: 6548,
    city: "Guapiles",
    city_code: "GPL",
    airport_name: "Guapiles",
    country: "Costa Rica",
  },
  {
    sr: 6550,
    city: "Guarapari",
    city_code: "GUZ",
    airport_name: "Guarapari",
    country: "Brazil",
  },
  {
    sr: 6552,
    city: "Guarapuava",
    city_code: "GPB",
    airport_name: "Tancredo Thomaz Faria",
    country: "Brazil",
  },
  {
    sr: 6554,
    city: "Guaratingueta",
    city_code: "GUJ",
    airport_name: "Guaratingueta",
    country: "Brazil",
  },
  {
    sr: 6556,
    city: "Guari",
    city_code: "GUG",
    airport_name: "Guari",
    country: "Papua New Guinea",
  },
  {
    sr: 6558,
    city: "Guasdualito",
    city_code: "GDO",
    airport_name: "Vare Maria",
    country: "Venezuela",
  },
  {
    sr: 6562,
    city: "Guasopa",
    city_code: "GAZ",
    airport_name: "Guasopa",
    country: "Papua New Guinea",
  },
  {
    sr: 6564,
    city: "Guatemala City",
    city_code: "GUA",
    airport_name: "La Aurora",
    country: "Guatemala",
  },
  {
    sr: 6566,
    city: "Guayaquil",
    city_code: "GYE",
    airport_name: "Simon Bolivar",
    country: "Ecuador",
  },
  {
    sr: 6568,
    city: "Guayaramerin",
    city_code: "GYA",
    airport_name: "Guayaramerin",
    country: "Bolivia",
  },
  {
    sr: 6570,
    city: "Guaymas",
    city_code: "GYM",
    airport_name: "Gen Jose M Yanez",
    country: "Mexico",
  },
  {
    sr: 6572,
    city: "Guerima",
    city_code: "GMC",
    airport_name: "Guerima",
    country: "Colombia",
  },
  {
    sr: 6574,
    city: "Guernsey",
    city_code: "GCI",
    airport_name: "Guernsey",
    country: "United Kingdom",
  },
  {
    sr: 6576,
    city: "Guerrero Negro",
    city_code: "GUB",
    airport_name: "Guerrero Negro",
    country: "Mexico",
  },
  {
    sr: 6578,
    city: "Guetersloh",
    city_code: "GUT",
    airport_name: "Guetersloh",
    country: "Germany",
  },
  {
    sr: 6580,
    city: "Guettin",
    city_code: "GTI",
    airport_name: "Guettin",
    country: "Germany",
  },
  {
    sr: 6582,
    city: "Guiglo",
    city_code: "GGO",
    airport_name: "Guiglo",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 6584,
    city: "Toronto",
    city_code: "YTO",
    airport_name: "Metropolitan Area",
    country: "Canada",
  },
  {
    sr: 6586,
    city: "Guilin",
    city_code: "KWL",
    airport_name: "Guilin",
    country: "China",
  },
  {
    sr: 6588,
    city: "Guimaraes",
    city_code: "GMS",
    airport_name: "Guimaraes",
    country: "Brazil",
  },
  {
    sr: 6590,
    city: "Guiria",
    city_code: "GUI",
    airport_name: "Guiria",
    country: "Venezuela",
  },
  {
    sr: 6592,
    city: "Guiyang",
    city_code: "KWE",
    airport_name: "Guiyang",
    country: "China",
  },
  {
    sr: 6596,
    city: "Gujrat",
    city_code: "GRT",
    airport_name: "Gujrat",
    country: "Pakistan",
  },
  {
    sr: 6598,
    city: "Gulf Shores",
    city_code: "GUF",
    airport_name: "Edwards",
    country: "United States",
  },
  {
    sr: 6600,
    city: "Gulfport",
    city_code: "GPT",
    airport_name: "Biloxi Regional",
    country: "United States",
  },
  {
    sr: 6602,
    city: "Gulgubip",
    city_code: "GLP",
    airport_name: "Gulgubip",
    country: "Papua New Guinea",
  },
  {
    sr: 6604,
    city: "Gulkana",
    city_code: "GKN",
    airport_name: "Gulkana",
    country: "United States",
  },
  {
    sr: 6606,
    city: "Gulu",
    city_code: "ULU",
    airport_name: "Gulu",
    country: "Uganda",
  },
  {
    sr: 6608,
    city: "Guna",
    city_code: "GUX",
    airport_name: "Guna",
    country: "India",
  },
  {
    sr: 6610,
    city: "Gunnedah",
    city_code: "GUH",
    airport_name: "Gunnedah",
    country: "Australia",
  },
  {
    sr: 6612,
    city: "Gunnison",
    city_code: "GUC",
    airport_name: "Gunnison",
    country: "United States",
  },
  {
    sr: 6614,
    city: "Gunsan",
    city_code: "KUV",
    airport_name: "Gunsan",
    country: "South Korea",
  },
  {
    sr: 6616,
    city: "Gunsan AB",
    city_code: "KUZ",
    airport_name: "Gunsan Airbase",
    country: "South Korea",
  },
  {
    sr: 6618,
    city: "Gunungsitoli",
    city_code: "GNS",
    airport_name: "Gunungsitoli",
    country: "Indonesia",
  },
  {
    sr: 6620,
    city: "Gurayat",
    city_code: "URY",
    airport_name: "Gurayat",
    country: "Saudi Arabia",
  },
  {
    sr: 6622,
    city: "Guriaso",
    city_code: "GUE",
    airport_name: "Guriaso",
    country: "Papua New Guinea",
  },
  {
    sr: 6624,
    city: "Gurue",
    city_code: "VJQ",
    airport_name: "Gurue",
    country: "Mozambique",
  },
  {
    sr: 6626,
    city: "Gurupi",
    city_code: "GRP",
    airport_name: "Gurupi",
    country: "Brazil",
  },
  {
    sr: 6630,
    city: "Gusap",
    city_code: "GAP",
    airport_name: "Gusap",
    country: "Papua New Guinea",
  },
  {
    sr: 6632,
    city: "Gush Katif",
    city_code: "GHK",
    airport_name: "Gush Katif",
    country: "Israel",
  },
  {
    sr: 6634,
    city: "Gustavus",
    city_code: "BQV",
    airport_name: "Bartlett SPB",
    country: "United States",
  },
  {
    sr: 6636,
    city: "Gustavus",
    city_code: "GST",
    airport_name: "Gustavus Arpt",
    country: "United States",
  },
  {
    sr: 6638,
    city: "Guthrie",
    city_code: "GOK",
    airport_name: "Guthrie",
    country: "United States",
  },
  {
    sr: 6640,
    city: "Guymon",
    city_code: "GUY",
    airport_name: "Guymon",
    country: "United States",
  },
  {
    sr: 6642,
    city: "Gwa",
    city_code: "GWA",
    airport_name: "Gwa",
    country: "Myanmar",
  },
  {
    sr: 6644,
    city: "Gwadar",
    city_code: "GWD",
    airport_name: "Gwadar",
    country: "Pakistan",
  },
  {
    sr: 6646,
    city: "Gwalior",
    city_code: "GWL",
    airport_name: "Gwalior",
    country: "India",
  },
  {
    sr: 6648,
    city: "Gwangju",
    city_code: "KWJ",
    airport_name: "Gwangju",
    country: "South Korea",
  },
  {
    sr: 6650,
    city: "Gweru",
    city_code: "GWE",
    airport_name: "Gweru",
    country: "Zimbabwe",
  },
  {
    sr: 6652,
    city: "Gyandzha",
    city_code: "KVD",
    airport_name: "Gyandzha",
    country: "Azerbaijan",
  },
  {
    sr: 6654,
    city: "Gympie",
    city_code: "GYP",
    airport_name: "Gympie",
    country: "Australia",
  },
  {
    sr: 6656,
    city: "Gyoumri",
    city_code: "LWN",
    airport_name: "Gyoumri",
    country: "Armenia",
  },
  {
    sr: 6658,
    city: "Habi",
    city_code: "HBD",
    airport_name: "Habi",
    country: "Papua New Guinea",
  },
  {
    sr: 6660,
    city: "Hachijo Jima",
    city_code: "HAC",
    airport_name: "Hachijo Jima",
    country: "Japan",
  },
  {
    sr: 6664,
    city: "Hachinohe",
    city_code: "HHE",
    airport_name: "Hachinohe",
    country: "Japan",
  },
  {
    sr: 6666,
    city: "Haelogo",
    city_code: "HEO",
    airport_name: "Haelogo",
    country: "Papua New Guinea",
  },
  {
    sr: 6668,
    city: "Hafr Albatin",
    city_code: "HBT",
    airport_name: "Hafr Albatin",
    country: "Saudi Arabia",
  },
  {
    sr: 6670,
    city: "Hagerstown",
    city_code: "HGR",
    airport_name: "Wash County Regional",
    country: "United States",
  },
  {
    sr: 6672,
    city: "Hagfors",
    city_code: "HFS",
    airport_name: "Hagfors",
    country: "Sweden",
  },
  {
    sr: 6674,
    city: "Haifa",
    city_code: "HFA",
    airport_name: "Haifa",
    country: "Israel",
  },
  {
    sr: 6676,
    city: "Haikou",
    city_code: "HAK",
    airport_name: "Haikou",
    country: "China",
  },
  {
    sr: 6678,
    city: "Hail",
    city_code: "HAS",
    airport_name: "Hail",
    country: "Saudi Arabia",
  },
  {
    sr: 6680,
    city: "Hailar",
    city_code: "HLD",
    airport_name: "Hailar",
    country: "China",
  },
  {
    sr: 6682,
    city: "Haines",
    city_code: "HNS",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 6684,
    city: "Haines Junction",
    city_code: "YHT",
    airport_name: "Haines Junction",
    country: "Canada",
  },
  {
    sr: 6686,
    city: "Haiphong",
    city_code: "HPH",
    airport_name: "Catbi",
    country: "Vietnam",
  },
  {
    sr: 6688,
    city: "Hakai Pass",
    city_code: "YHC",
    airport_name: "Hakai Pass",
    country: "Canada",
  },
  {
    sr: 6690,
    city: "Hakodate",
    city_code: "HKD",
    airport_name: "Hakodate",
    country: "Japan",
  },
  {
    sr: 6692,
    city: "Halali",
    city_code: "HAL",
    airport_name: "Halali",
    country: "Namibia",
  },
  {
    sr: 6694,
    city: "Half Moon",
    city_code: "HAF",
    airport_name: "Half Moon",
    country: "United States",
  },
  {
    sr: 6698,
    city: "Halifax",
    city_code: "YWF",
    airport_name: "Dwtown Waterfront H P",
    country: "Canada",
  },
  {
    sr: 6700,
    city: "Halifax",
    city_code: "YHZ",
    airport_name: "Halifax International",
    country: "Canada",
  },
  {
    sr: 6702,
    city: "Halifax",
    city_code: "YAW",
    airport_name: "Shearwater",
    country: "Canada",
  },
  {
    sr: 6704,
    city: "Hall Beach",
    city_code: "YUX",
    airport_name: "Hall Beach",
    country: "Canada",
  },
  {
    sr: 6706,
    city: "Halle",
    city_code: "ZHZ",
    airport_name: "Halle Railway",
    country: "Germany",
  },
  {
    sr: 6708,
    city: "Halls Creek",
    city_code: "HCQ",
    airport_name: "Halls Creek",
    country: "Australia",
  },
  {
    sr: 6710,
    city: "Halmstad",
    city_code: "HAD",
    airport_name: "Halmstad",
    country: "Sweden",
  },
  {
    sr: 6712,
    city: "Hamadan",
    city_code: "HDM",
    airport_name: "Hamadan",
    country: "Iran",
  },
  {
    sr: 6714,
    city: "Hamar",
    city_code: "HMR",
    airport_name: "Hamar Arpt",
    country: "Norway",
  },
  {
    sr: 6716,
    city: "Hamburg",
    city_code: "HAM",
    airport_name: "Hamburg",
    country: "Germany",
  },
  {
    sr: 6718,
    city: "Lubeck",
    city_code: "LBC",
    airport_name: "Lubeck Blankensee",
    country: "Germany",
  },
  {
    sr: 6720,
    city: "Hami",
    city_code: "HMI",
    airport_name: "Hami",
    country: "China",
  },
  {
    sr: 6722,
    city: "Hamilton",
    city_code: "HAB",
    airport_name: "Marion County",
    country: "United States",
  },
  {
    sr: 6724,
    city: "Hamilton",
    city_code: "HLZ",
    airport_name: "Hamilton",
    country: "New Zealand",
  },
  {
    sr: 6726,
    city: "Hamilton",
    city_code: "HAO",
    airport_name: "Hamilton",
    country: "United States",
  },
  {
    sr: 6728,
    city: "Hamilton",
    city_code: "YHM",
    airport_name: "Hamilton",
    country: "Canada",
  },
  {
    sr: 6732,
    city: "Hamilton",
    city_code: "HLT",
    airport_name: "Hamilton",
    country: "Australia",
  },
  {
    sr: 6734,
    city: "Hamilton Island",
    city_code: "HTI",
    airport_name: "Hamilton Island",
    country: "Australia",
  },
  {
    sr: 6736,
    city: "Hammerfest",
    city_code: "HFT",
    airport_name: "Hammerfest",
    country: "Norway",
  },
  {
    sr: 6738,
    city: "Hammersmith",
    city_code: "WUK",
    airport_name: "Off Line Point",
    country: "United Kingdom",
  },
  {
    sr: 6740,
    city: "Hampton",
    city_code: "HPT",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 6742,
    city: "Hampton",
    city_code: "LFI",
    airport_name: "Langley AFB",
    country: "United States",
  },
  {
    sr: 6744,
    city: "Hampton",
    city_code: "ZWW",
    airport_name: "Newport News Rail",
    country: "United States",
  },
  {
    sr: 6746,
    city: "Hampton",
    city_code: "ZWB",
    airport_name: "Williamsburg Rail",
    country: "United States",
  },
  {
    sr: 6748,
    city: "Hana",
    city_code: "HNM",
    airport_name: "Hana",
    country: "United States",
  },
  {
    sr: 6750,
    city: "Hanamaki",
    city_code: "HNA",
    airport_name: "Hanamaki",
    country: "Japan",
  },
  {
    sr: 6752,
    city: "Hanapepe",
    city_code: "PAK",
    airport_name: "Port Allen",
    country: "United States",
  },
  {
    sr: 6754,
    city: "Hancock",
    city_code: "CMX",
    airport_name: "Houghton County",
    country: "United States",
  },
  {
    sr: 6756,
    city: "Hangzhou",
    city_code: "HGH",
    airport_name: "Hangzhou",
    country: "China",
  },
  {
    sr: 6758,
    city: "Hanimaadhoo",
    city_code: "HAQ",
    airport_name: "Hanimaadhoo",
    country: "Maldives",
  },
  {
    sr: 6760,
    city: "Hanksville",
    city_code: "HVE",
    airport_name: "Intermediate",
    country: "United States",
  },
  {
    sr: 6762,
    city: "Hanna",
    city_code: "HNX",
    airport_name: "Hanna",
    country: "United States",
  },
  {
    sr: 6766,
    city: "Hanoi",
    city_code: "HAN",
    airport_name: "Noibai International",
    country: "Vietnam",
  },
  {
    sr: 6768,
    city: "Hanover",
    city_code: "HAJ",
    airport_name: "Hanover Arpt",
    country: "Germany",
  },
  {
    sr: 6770,
    city: "Hanover",
    city_code: "ZVR",
    airport_name: "Hanover Railway",
    country: "Germany",
  },
  {
    sr: 6772,
    city: "Hanover",
    city_code: "ZVM",
    airport_name: "Messe BF Railway Servi",
    country: "Germany",
  },
  {
    sr: 6774,
    city: "Hanus Bay",
    city_code: "HBC",
    airport_name: "Hanus Bay",
    country: "United States",
  },
  {
    sr: 6776,
    city: "Hanzhong",
    city_code: "HZG",
    airport_name: "Hanzhong",
    country: "China",
  },
  {
    sr: 6778,
    city: "Hao Island",
    city_code: "HOI",
    airport_name: "Hao Island",
    country: "French Polynesia",
  },
  {
    sr: 6780,
    city: "Harare",
    city_code: "HRE",
    airport_name: "Harare",
    country: "Zimbabwe",
  },
  {
    sr: 6782,
    city: "Harbin",
    city_code: "HRB",
    airport_name: "Harbin",
    country: "China",
  },
  {
    sr: 6784,
    city: "Harbour Island",
    city_code: "HBI",
    airport_name: "Harbour Island",
    country: "Bahamas",
  },
  {
    sr: 6786,
    city: "Hargeisa",
    city_code: "HGA",
    airport_name: "Hargeisa",
    country: "Somalia",
  },
  {
    sr: 6788,
    city: "Harlingen",
    city_code: "HRL",
    airport_name: "Valley International",
    country: "United States",
  },
  {
    sr: 6790,
    city: "Harrisburg",
    city_code: "HSB",
    airport_name: "Raleigh",
    country: "United States",
  },
  {
    sr: 6792,
    city: "Harrisburg",
    city_code: "MDT",
    airport_name: "Harrisburg Intl",
    country: "United States",
  },
  {
    sr: 6794,
    city: "Harrisburg",
    city_code: "ZUG",
    airport_name: "Harrisburg Rail",
    country: "United States",
  },
  {
    sr: 6796,
    city: "Harrisburg",
    city_code: "HAR",
    airport_name: "Harrisburg Skyport",
    country: "United States",
  },
  {
    sr: 6800,
    city: "Harrismith",
    city_code: "HRS",
    airport_name: "Harrismith Airport",
    country: "South Africa",
  },
  {
    sr: 6802,
    city: "Harrison",
    city_code: "HRO",
    airport_name: "Boone County",
    country: "United States",
  },
  {
    sr: 6804,
    city: "Harrogate",
    city_code: "HRT",
    airport_name: "Linton On Ouse",
    country: "United Kingdom",
  },
  {
    sr: 6806,
    city: "Harstad Narvik",
    city_code: "EVE",
    airport_name: "Evenes",
    country: "Norway",
  },
  {
    sr: 6808,
    city: "Hartford",
    city_code: "BNH",
    airport_name: "Barnes",
    country: "United States",
  },
  {
    sr: 6810,
    city: "Hartford",
    city_code: "HFD",
    airport_name: "Brainard",
    country: "United States",
  },
  {
    sr: 6812,
    city: "Hartford",
    city_code: "ZRT",
    airport_name: "Hartford CT Rail",
    country: "United States",
  },
  {
    sr: 6814,
    city: "Hartley Bay",
    city_code: "YTB",
    airport_name: "Hartley Bay",
    country: "Canada",
  },
  {
    sr: 6816,
    city: "Hartsville",
    city_code: "HVS",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 6818,
    city: "Haskovo",
    city_code: "HKV",
    airport_name: "Haskovo",
    country: "Bulgaria",
  },
  {
    sr: 6820,
    city: "Hassi Messaoud",
    city_code: "HME",
    airport_name: "Oued Irara Apt",
    country: "Algeria",
  },
  {
    sr: 6822,
    city: "Hassleholm",
    city_code: "XWP",
    airport_name: "Hassleholm",
    country: "Sweden",
  },
  {
    sr: 6824,
    city: "Hastings",
    city_code: "HSI",
    airport_name: "Hastings",
    country: "United States",
  },
  {
    sr: 6826,
    city: "Hasvik",
    city_code: "HAA",
    airport_name: "Hasvik",
    country: "Norway",
  },
  {
    sr: 6828,
    city: "Hat Yai",
    city_code: "HDY",
    airport_name: "Hat Yai",
    country: "Thailand",
  },
  {
    sr: 6830,
    city: "Hatanga",
    city_code: "HTG",
    airport_name: "Hatanga",
    country: "Russia",
  },
  {
    sr: 6834,
    city: "Hatchet Lake",
    city_code: "YDJ",
    airport_name: "Hatchet Lake",
    country: "Canada",
  },
  {
    sr: 6836,
    city: "Hateruma",
    city_code: "HTR",
    airport_name: "Hateruma",
    country: "Japan",
  },
  {
    sr: 6838,
    city: "Hatfield",
    city_code: "HTF",
    airport_name: "Hatfield",
    country: "United Kingdom",
  },
  {
    sr: 6840,
    city: "Hato Corozal",
    city_code: "HTZ",
    airport_name: "Hato Corozal",
    country: "Colombia",
  },
  {
    sr: 6842,
    city: "Hatteras",
    city_code: "HNC",
    airport_name: "Hatteras",
    country: "United States",
  },
  {
    sr: 6844,
    city: "Hattiesburg",
    city_code: "HBG",
    airport_name: "The Hattiesburg Bobby L Chain Mun",
    country: "United States",
  },
  {
    sr: 6846,
    city: "Hatton",
    city_code: "NUF",
    airport_name: "Castlereigh Reservoir",
    country: "Sri Lanka",
  },
  {
    sr: 6848,
    city: "Hatzfeldthaven",
    city_code: "HAZ",
    airport_name: "Hatzfeldthaven",
    country: "Papua New Guinea",
  },
  {
    sr: 6850,
    city: "Haugesund",
    city_code: "HAU",
    airport_name: "Haugesund",
    country: "Norway",
  },
  {
    sr: 6852,
    city: "Havadarya",
    city_code: "HDR",
    airport_name: "Havadarya",
    country: "Iran",
  },
  {
    sr: 6854,
    city: "Havana",
    city_code: "HAV",
    airport_name: "Jose Marti Intl",
    country: "Cuba",
  },
  {
    sr: 6856,
    city: "Havana",
    city_code: "UPB",
    airport_name: "Playa Baracoa",
    country: "Cuba",
  },
  {
    sr: 6858,
    city: "Havasupai",
    city_code: "HAE",
    airport_name: "Havasupai",
    country: "United States",
  },
  {
    sr: 6860,
    city: "Haverfordwest",
    city_code: "HAW",
    airport_name: "Haverfordwest",
    country: "United Kingdom",
  },
  {
    sr: 6862,
    city: "Havre",
    city_code: "HVR",
    airport_name: "City County",
    country: "United States",
  },
  {
    sr: 6864,
    city: "Havre St Pierre",
    city_code: "YGV",
    airport_name: "Havre St Pierre",
    country: "Canada",
  },
  {
    sr: 6868,
    city: "Hawabango",
    city_code: "HWA",
    airport_name: "Hawabango",
    country: "Papua New Guinea",
  },
  {
    sr: 6870,
    city: "Hawk Inlet",
    city_code: "HWI",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 6872,
    city: "Hawker",
    city_code: "HWK",
    airport_name: "Wilpena Pound",
    country: "Australia",
  },
  {
    sr: 6874,
    city: "Hawthorne",
    city_code: "HHR",
    airport_name: "Hawthorne",
    country: "United States",
  },
  {
    sr: 6876,
    city: "Hawthorne",
    city_code: "HTH",
    airport_name: "Hawthorne",
    country: "United States",
  },
  {
    sr: 6878,
    city: "Hay",
    city_code: "HXX",
    airport_name: "Hay",
    country: "Australia",
  },
  {
    sr: 6880,
    city: "Hay River",
    city_code: "YHY",
    airport_name: "Hay River",
    country: "Canada",
  },
  {
    sr: 6882,
    city: "Haycock",
    city_code: "HAY",
    airport_name: "Haycock",
    country: "United States",
  },
  {
    sr: 6884,
    city: "Hayden",
    city_code: "HDN",
    airport_name: "Yampa Valley",
    country: "United States",
  },
  {
    sr: 6886,
    city: "Hayfields",
    city_code: "HYF",
    airport_name: "Hayfields",
    country: "Papua New Guinea",
  },
  {
    sr: 6888,
    city: "Hayman Island",
    city_code: "HIS",
    airport_name: "Hayman Island",
    country: "Australia",
  },
  {
    sr: 6890,
    city: "Hays",
    city_code: "HYS",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 6892,
    city: "Hayward",
    city_code: "HWD",
    airport_name: "Air Terminal",
    country: "United States",
  },
  {
    sr: 6894,
    city: "Hayward",
    city_code: "HYR",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 6896,
    city: "Hazebrouck",
    city_code: "HZB",
    airport_name: "Merville Calonne",
    country: "France",
  },
  {
    sr: 6898,
    city: "Hazleton",
    city_code: "HZL",
    airport_name: "Hazleton",
    country: "United States",
  },
  {
    sr: 6902,
    city: "Hazyview",
    city_code: "HZV",
    airport_name: "Hazyview",
    country: "South Africa",
  },
  {
    sr: 6904,
    city: "Headingly",
    city_code: "HIP",
    airport_name: "Headingly",
    country: "Australia",
  },
  {
    sr: 6906,
    city: "Healy Lake",
    city_code: "HKB",
    airport_name: "Healy Lake",
    country: "United States",
  },
  {
    sr: 6908,
    city: "Hearst",
    city_code: "YHF",
    airport_name: "Hearst",
    country: "Canada",
  },
  {
    sr: 6910,
    city: "Heathlands",
    city_code: "HAT",
    airport_name: "Heathlands",
    country: "Australia",
  },
  {
    sr: 6912,
    city: "Hedemora",
    city_code: "XXU",
    airport_name: "Hedemora",
    country: "Sweden",
  },
  {
    sr: 6914,
    city: "Hefei",
    city_code: "HFE",
    airport_name: "Shanghai",
    country: "China",
  },
  {
    sr: 6916,
    city: "Heho",
    city_code: "HEH",
    airport_name: "Heho",
    country: "Myanmar",
  },
  {
    sr: 6918,
    city: "Heide Buesum",
    city_code: "HEI",
    airport_name: "Heide Buesum",
    country: "Germany",
  },
  {
    sr: 6920,
    city: "Heidelberg",
    city_code: "HDB",
    airport_name: "Heidelberg",
    country: "Germany",
  },
  {
    sr: 6922,
    city: "Heihe",
    city_code: "HEK",
    airport_name: "Heihe",
    country: "China",
  },
  {
    sr: 6924,
    city: "Heimdal",
    city_code: "XUE",
    airport_name: "Heimdal Railway",
    country: "Norway",
  },
  {
    sr: 6926,
    city: "Heiweni",
    city_code: "HNI",
    airport_name: "Heiweni",
    country: "Papua New Guinea",
  },
  {
    sr: 6928,
    city: "Helena",
    city_code: "HEE",
    airport_name: "Thompson Robbins",
    country: "United States",
  },
  {
    sr: 6930,
    city: "Helena",
    city_code: "HLN",
    airport_name: "Helena",
    country: "United States",
  },
  {
    sr: 6932,
    city: "Helenvale",
    city_code: "HLV",
    airport_name: "Helenvale",
    country: "Australia",
  },
  {
    sr: 6936,
    city: "Helgoland",
    city_code: "HGL",
    airport_name: "Helgoland",
    country: "Germany",
  },
  {
    sr: 6938,
    city: "Helsinki",
    city_code: "HEM",
    airport_name: "Helsinki Malmi",
    country: "Finland",
  },
  {
    sr: 6940,
    city: "Vantaa",
    city_code: "HEL",
    airport_name: "Helsinki vantaa",
    country: "Finland",
  },
  {
    sr: 6942,
    city: "Hemavan",
    city_code: "HMV",
    airport_name: "Hemavan",
    country: "Sweden",
  },
  {
    sr: 6944,
    city: "Hemet",
    city_code: "HMT",
    airport_name: "Ryan Field",
    country: "United States",
  },
  {
    sr: 6946,
    city: "Henbury",
    city_code: "HRY",
    airport_name: "Henbury",
    country: "Australia",
  },
  {
    sr: 6948,
    city: "Fletcher",
    city_code: "AVL",
    airport_name: "Asheville Regional Airport",
    country: "United States",
  },
  {
    sr: 6950,
    city: "Hendon",
    city_code: "HEN",
    airport_name: "Hendon",
    country: "United Kingdom",
  },
  {
    sr: 6952,
    city: "Hengchun",
    city_code: "HCN",
    airport_name: "Hengchun",
    country: "Taiwan",
  },
  {
    sr: 6954,
    city: "Hengyang",
    city_code: "HNY",
    airport_name: "Hengyang",
    country: "China",
  },
  {
    sr: 6956,
    city: "Henzada",
    city_code: "HEB",
    airport_name: "Henzada",
    country: "Myanmar",
  },
  {
    sr: 6958,
    city: "Heraklion",
    city_code: "HER",
    airport_name: "N Kazantzakis Apt",
    country: "Greece",
  },
  {
    sr: 6960,
    city: "Herat",
    city_code: "HEA",
    airport_name: "Herat",
    country: "Afghanistan",
  },
  {
    sr: 6962,
    city: "Hercegnovi",
    city_code: "HNO",
    airport_name: "Hercegnovi",
    country: "Czechoslovakia  former",
  },
  {
    sr: 6964,
    city: "Herendeen",
    city_code: "HED",
    airport_name: "Herendeen",
    country: "United States",
  },
  {
    sr: 6966,
    city: "Heringsdorf",
    city_code: "HDF",
    airport_name: "Heringsdorf",
    country: "Germany",
  },
  {
    sr: 6970,
    city: "Herlong",
    city_code: "AHC",
    airport_name: "Amedee AAF",
    country: "United States",
  },
  {
    sr: 6972,
    city: "Hermannsburg",
    city_code: "HMG",
    airport_name: "Hermannsburg",
    country: "Australia",
  },
  {
    sr: 6974,
    city: "Hermiston",
    city_code: "HES",
    airport_name: "State",
    country: "United States",
  },
  {
    sr: 6976,
    city: "Hermosillo",
    city_code: "HMO",
    airport_name: "Gen Pesqueira Garcia",
    country: "Mexico",
  },
  {
    sr: 6978,
    city: "Heron Island",
    city_code: "HRN",
    airport_name: "Heliport",
    country: "Australia",
  },
  {
    sr: 6980,
    city: "Herrera",
    city_code: "HRR",
    airport_name: "Herrera",
    country: "Colombia",
  },
  {
    sr: 6982,
    city: "Herrljunga",
    city_code: "XYC",
    airport_name: "Herrljunga",
    country: "Sweden",
  },
  {
    sr: 6984,
    city: "Hervey Bay",
    city_code: "HVB",
    airport_name: "Hervey Bay",
    country: "Australia",
  },
  {
    sr: 6986,
    city: "Hesa",
    city_code: "IFH",
    airport_name: "Hesa",
    country: "Iran",
  },
  {
    sr: 6988,
    city: "Hickory",
    city_code: "HKY",
    airport_name: "Hickory",
    country: "United States",
  },
  {
    sr: 6990,
    city: "Hidden Falls",
    city_code: "HDA",
    airport_name: "Hidden Falls",
    country: "United States",
  },
  {
    sr: 6992,
    city: "Hienghene",
    city_code: "HNG",
    airport_name: "Hienghene",
    country: "New Caledonia",
  },
  {
    sr: 6994,
    city: "High Level",
    city_code: "YOJ",
    airport_name: "Footner Lake",
    country: "Canada",
  },
  {
    sr: 6996,
    city: "High Point",
    city_code: "GSO",
    airport_name: "Piedmont Triad Intl",
    country: "United States",
  },
  {
    sr: 6998,
    city: "High Prairie",
    city_code: "ZHP",
    airport_name: "High Prairie",
    country: "Canada",
  },
  {
    sr: 7000,
    city: "High Wycombe",
    city_code: "HYC",
    airport_name: "High Wycombe",
    country: "United Kingdom",
  },
  {
    sr: 7004,
    city: "Highbury",
    city_code: "HIG",
    airport_name: "Highbury",
    country: "Australia",
  },
  {
    sr: 7006,
    city: "Higlieg",
    city_code: "HGI",
    airport_name: "Higlieg",
    country: "Sudan",
  },
  {
    sr: 7008,
    city: "Hikueru",
    city_code: "HHZ",
    airport_name: "Hikueru",
    country: "French Polynesia",
  },
  {
    sr: 7010,
    city: "Hill City",
    city_code: "HLC",
    airport_name: "Hill City",
    country: "United States",
  },
  {
    sr: 7012,
    city: "Hillsboro",
    city_code: "HIO",
    airport_name: "Portland",
    country: "United States",
  },
  {
    sr: 7014,
    city: "Hillside",
    city_code: "HLL",
    airport_name: "Hillside",
    country: "Australia",
  },
  {
    sr: 7016,
    city: "Hilo",
    city_code: "ITO",
    airport_name: "Hilo International",
    country: "United States",
  },
  {
    sr: 7018,
    city: "Hilton Head",
    city_code: "HHH",
    airport_name: "Hilton Head",
    country: "United States",
  },
  {
    sr: 7020,
    city: "Hinchinbrook Island",
    city_code: "HNK",
    airport_name: "Hinchinbrook Island",
    country: "Australia",
  },
  {
    sr: 7022,
    city: "Hinesville",
    city_code: "LIY",
    airport_name: "Wright AAF",
    country: "United States",
  },
  {
    sr: 7024,
    city: "Hingurakgoda",
    city_code: "HIM",
    airport_name: "Hingurakgoda",
    country: "Sri Lanka",
  },
  {
    sr: 7026,
    city: "Hiroshima",
    city_code: "HIW",
    airport_name: "Hiroshima West",
    country: "Japan",
  },
  {
    sr: 7028,
    city: "Hiroshima",
    city_code: "HIJ",
    airport_name: "International",
    country: "Japan",
  },
  {
    sr: 7030,
    city: "Hissar",
    city_code: "HSS",
    airport_name: "Hissar",
    country: "India",
  },
  {
    sr: 7032,
    city: "Hiva Oa",
    city_code: "HIX",
    airport_name: "Hiva Oa",
    country: "French Polynesia",
  },
  {
    sr: 7034,
    city: "Hivaro",
    city_code: "HIT",
    airport_name: "Hivaro",
    country: "Papua New Guinea",
  },
  {
    sr: 7038,
    city: "Hjerkinn",
    city_code: "YVH",
    airport_name: "Railway",
    country: "Norway",
  },
  {
    sr: 7040,
    city: "Hluhluwe",
    city_code: "HLW",
    airport_name: "Hluhluwe",
    country: "South Africa",
  },
  {
    sr: 7042,
    city: "Ho Chi Minh City",
    city_code: "SGN",
    airport_name: "Tan Son Nhat",
    country: "Vietnam",
  },
  {
    sr: 7044,
    city: "Hobart",
    city_code: "HBR",
    airport_name: "Hobart",
    country: "United States",
  },
  {
    sr: 7046,
    city: "Hobart",
    city_code: "HBA",
    airport_name: "Hobart",
    country: "Australia",
  },
  {
    sr: 7048,
    city: "Hobart Bay",
    city_code: "HBH",
    airport_name: "Hobart Bay",
    country: "United States",
  },
  {
    sr: 7050,
    city: "Hobbs",
    city_code: "HBB",
    airport_name: "Industrial Airpark",
    country: "United States",
  },
  {
    sr: 7052,
    city: "Hobbs",
    city_code: "HOB",
    airport_name: "Lea County",
    country: "United States",
  },
  {
    sr: 7054,
    city: "Hodeidah",
    city_code: "HOD",
    airport_name: "Hodeidah Arpt",
    country: "Yemen",
  },
  {
    sr: 7056,
    city: "Hoedspruit",
    city_code: "HDS",
    airport_name: "Hoedspruit Airport",
    country: "South Africa",
  },
  {
    sr: 7058,
    city: "Hof",
    city_code: "HOQ",
    airport_name: "Hof",
    country: "Germany",
  },
  {
    sr: 7060,
    city: "Hoffman",
    city_code: "HFF",
    airport_name: "Mackall AAF",
    country: "United States",
  },
  {
    sr: 7062,
    city: "Hogatza",
    city_code: "HGZ",
    airport_name: "Hogatza",
    country: "United States",
  },
  {
    sr: 7064,
    city: "Hohenems",
    city_code: "HOH",
    airport_name: "Hohenems",
    country: "Austria",
  },
  {
    sr: 7066,
    city: "Hohhot",
    city_code: "HET",
    airport_name: "Hohhot",
    country: "China",
  },
  {
    sr: 7068,
    city: "Hokitika",
    city_code: "HKK",
    airport_name: "Hokitika Arpt",
    country: "New Zealand",
  },
  {
    sr: 7072,
    city: "Hola",
    city_code: "HOA",
    airport_name: "Hola",
    country: "Kenya",
  },
  {
    sr: 7074,
    city: "Holdrege",
    city_code: "HDE",
    airport_name: "Brewster Field",
    country: "United States",
  },
  {
    sr: 7076,
    city: "Holguin",
    city_code: "HOG",
    airport_name: "Frank Pais",
    country: "Cuba",
  },
  {
    sr: 7078,
    city: "Holikachu",
    city_code: "HOL",
    airport_name: "Holikachu",
    country: "United States",
  },
  {
    sr: 7080,
    city: "Holland",
    city_code: "HLM",
    airport_name: "Park Township",
    country: "United States",
  },
  {
    sr: 7082,
    city: "Hollis",
    city_code: "HYL",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 7084,
    city: "Hollister",
    city_code: "HLI",
    airport_name: "Hollister",
    country: "United States",
  },
  {
    sr: 7086,
    city: "Hollywood",
    city_code: "HWO",
    airport_name: "North Perry",
    country: "United States",
  },
  {
    sr: 7088,
    city: "Holman",
    city_code: "YHI",
    airport_name: "Holman",
    country: "Canada",
  },
  {
    sr: 7090,
    city: "Holmavik",
    city_code: "HVK",
    airport_name: "Holmavik",
    country: "Iceland",
  },
  {
    sr: 7092,
    city: "Holmestrand",
    city_code: "XUG",
    airport_name: "Holmestrand Railway",
    country: "Norway",
  },
  {
    sr: 7094,
    city: "Holy Cross",
    city_code: "HCR",
    airport_name: "Holy Cross",
    country: "United States",
  },
  {
    sr: 7096,
    city: "Holyhead",
    city_code: "HLY",
    airport_name: "Holyhead",
    country: "United Kingdom",
  },
  {
    sr: 7098,
    city: "Homalin",
    city_code: "HOX",
    airport_name: "Homalin",
    country: "Myanmar",
  },
  {
    sr: 7100,
    city: "Homer",
    city_code: "HOM",
    airport_name: "Homer",
    country: "United States",
  },
  {
    sr: 7102,
    city: "Homeshore",
    city_code: "HMS",
    airport_name: "Homeshore",
    country: "United States",
  },
  {
    sr: 7106,
    city: "Homestead",
    city_code: "HST",
    airport_name: "AFB",
    country: "United States",
  },
  {
    sr: 7108,
    city: "Honefoss",
    city_code: "XHF",
    airport_name: "Honefoss Rail Stn",
    country: "Norway",
  },
  {
    sr: 7110,
    city: "Hong Kong",
    city_code: "HHP",
    airport_name: "H K Heliport",
    country: "China",
  },
  {
    sr: 7112,
    city: "Honiara",
    city_code: "HIR",
    airport_name: "Henderson Intl",
    country: "Solomon Islands",
  },
  {
    sr: 7114,
    city: "Honinabi",
    city_code: "HNN",
    airport_name: "Honinabi",
    country: "Papua New Guinea",
  },
  {
    sr: 7116,
    city: "Honningsvag",
    city_code: "HVG",
    airport_name: "Valan",
    country: "Norway",
  },
  {
    sr: 7118,
    city: "Honolulu",
    city_code: "HIK",
    airport_name: "Hickam AFB",
    country: "United States",
  },
  {
    sr: 7120,
    city: "Honolulu",
    city_code: "HNL",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 7122,
    city: "Hook Island",
    city_code: "HIH",
    airport_name: "Hook Island",
    country: "Australia",
  },
  {
    sr: 7124,
    city: "Hooker Creek",
    city_code: "HOK",
    airport_name: "Hooker Creek",
    country: "Australia",
  },
  {
    sr: 7126,
    city: "Hoolehua",
    city_code: "MKK",
    airport_name: "Molokai",
    country: "United States",
  },
  {
    sr: 7128,
    city: "Hoonah",
    city_code: "HNH",
    airport_name: "Hoonah",
    country: "United States",
  },
  {
    sr: 7130,
    city: "Hooper Bay",
    city_code: "HPB",
    airport_name: "Hooper Bay",
    country: "United States",
  },
  {
    sr: 7132,
    city: "Hope",
    city_code: "YHE",
    airport_name: "Hope",
    country: "Canada",
  },
  {
    sr: 7134,
    city: "Hope Vale",
    city_code: "HPE",
    airport_name: "Hope Vale",
    country: "Australia",
  },
  {
    sr: 7136,
    city: "Hopedale",
    city_code: "YHO",
    airport_name: "Hopedale",
    country: "Canada",
  },
  {
    sr: 7140,
    city: "Hopetoun",
    city_code: "HTU",
    airport_name: "Hopetoun",
    country: "Australia",
  },
  {
    sr: 7142,
    city: "Hopkinsville",
    city_code: "HOP",
    airport_name: "Campbell AAF",
    country: "United States",
  },
  {
    sr: 7144,
    city: "Hoquiam",
    city_code: "HQM",
    airport_name: "Bowerman",
    country: "United States",
  },
  {
    sr: 7146,
    city: "Horizontina",
    city_code: "HRZ",
    airport_name: "Horizontina",
    country: "Brazil",
  },
  {
    sr: 7148,
    city: "Horn Island",
    city_code: "HID",
    airport_name: "Horn Island",
    country: "Australia",
  },
  {
    sr: 7150,
    city: "Hornafjordur",
    city_code: "HFN",
    airport_name: "Hornafjordur",
    country: "Iceland",
  },
  {
    sr: 7152,
    city: "Hornepayne",
    city_code: "YHN",
    airport_name: "Hornepayne",
    country: "Canada",
  },
  {
    sr: 7154,
    city: "Horsham",
    city_code: "HSM",
    airport_name: "Horsham",
    country: "Australia",
  },
  {
    sr: 7156,
    city: "Horta",
    city_code: "HOR",
    airport_name: "Horta",
    country: "Portugal",
  },
  {
    sr: 7158,
    city: "Hoskins",
    city_code: "HKN",
    airport_name: "Hoskins",
    country: "Papua New Guinea",
  },
  {
    sr: 7160,
    city: "Hot Springs",
    city_code: "HOT",
    airport_name: "Memorial Field",
    country: "United States",
  },
  {
    sr: 7162,
    city: "Hot Springs",
    city_code: "HSP",
    airport_name: "Ingalls Field",
    country: "United States",
  },
  {
    sr: 7164,
    city: "Hotan",
    city_code: "HTN",
    airport_name: "Hotan",
    country: "China",
  },
  {
    sr: 7166,
    city: "Houailou",
    city_code: "HLU",
    airport_name: "Houailou",
    country: "New Caledonia",
  },
  {
    sr: 7168,
    city: "Houeisay",
    city_code: "HOE",
    airport_name: "Houeisay",
    country: "Laos",
  },
  {
    sr: 7170,
    city: "Houghton",
    city_code: "HTL",
    airport_name: "Roscommon County",
    country: "United States",
  },
  {
    sr: 7174,
    city: "Houlton",
    city_code: "HUL",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 7176,
    city: "Houma",
    city_code: "HUM",
    airport_name: "Terrebonne",
    country: "United States",
  },
  {
    sr: 7178,
    city: "Houn",
    city_code: "HUQ",
    airport_name: "Houn",
    country: "Libya",
  },
  {
    sr: 7180,
    city: "Houston",
    city_code: "ZHO",
    airport_name: "Bus Station",
    country: "Canada",
  },
  {
    sr: 7182,
    city: "Houston",
    city_code: "AAP",
    airport_name: "Andrau Airpark",
    country: "United States",
  },
  {
    sr: 7184,
    city: "Houston",
    city_code: "JDX",
    airport_name: "Central Bus District",
    country: "United States",
  },
  {
    sr: 7186,
    city: "Houston",
    city_code: "DWH",
    airport_name: "David Wayne Hooks",
    country: "United States",
  },
  {
    sr: 7188,
    city: "Houston",
    city_code: "EFD",
    airport_name: "Ellington Field",
    country: "United States",
  },
  {
    sr: 7190,
    city: "Houston",
    city_code: "IAH",
    airport_name: "George Bush Intercntl",
    country: "United States",
  },
  {
    sr: 7192,
    city: "Houston",
    city_code: "JGP",
    airport_name: "Greenway Plaza H P",
    country: "United States",
  },
  {
    sr: 7194,
    city: "Houston",
    city_code: "HOU",
    airport_name: "Hobby",
    country: "United States",
  },
  {
    sr: 7196,
    city: "Houston",
    city_code: "JMA",
    airport_name: "Marriot Astrodome",
    country: "United States",
  },
  {
    sr: 7198,
    city: "Houston",
    city_code: "JPT",
    airport_name: "Park Ten Heliport",
    country: "United States",
  },
  {
    sr: 7202,
    city: "Houston",
    city_code: "JGQ",
    airport_name: "Transco Twr Galleria",
    country: "United States",
  },
  {
    sr: 7204,
    city: "Houston",
    city_code: "IWS",
    airport_name: "West Houston",
    country: "United States",
  },
  {
    sr: 7208,
    city: "Houston",
    city_code: "JWH",
    airport_name: "Westchase Hilton H P",
    country: "United States",
  },
  {
    sr: 7210,
    city: "Houston",
    city_code: "JWL",
    airport_name: "Woodlawns",
    country: "United States",
  },
  {
    sr: 7212,
    city: "Hoy Island",
    city_code: "HOY",
    airport_name: "Hoy Island",
    country: "United Kingdom",
  },
  {
    sr: 7214,
    city: "Hsinchu",
    city_code: "HSZ",
    airport_name: "Hsinchu",
    country: "Taiwan",
  },
  {
    sr: 7216,
    city: "Hua Hin",
    city_code: "HHQ",
    airport_name: "Hua Hin Airport",
    country: "Thailand",
  },
  {
    sr: 7218,
    city: "Huacaraje",
    city_code: "BVK",
    airport_name: "Huacaraje",
    country: "Bolivia",
  },
  {
    sr: 7220,
    city: "Huahine",
    city_code: "HUH",
    airport_name: "Huahine",
    country: "French Polynesia",
  },
  {
    sr: 7222,
    city: "Hualien",
    city_code: "HUN",
    airport_name: "Hualien",
    country: "Taiwan",
  },
  {
    sr: 7224,
    city: "Huambo",
    city_code: "NOV",
    airport_name: "Huambo",
    country: "Angola",
  },
  {
    sr: 7226,
    city: "Huanghua",
    city_code: "HHA",
    airport_name: "Changsha Huanghua",
    country: "China",
  },
  {
    sr: 7228,
    city: "Huangpu",
    city_code: "ZMY",
    airport_name: "Harbour",
    country: "China",
  },
  {
    sr: 7230,
    city: "Huangyan",
    city_code: "HYN",
    airport_name: "Huangyan",
    country: "China",
  },
  {
    sr: 7232,
    city: "Huanuco",
    city_code: "HUU",
    airport_name: "Huanuco",
    country: "Peru",
  },
  {
    sr: 7234,
    city: "Huatulco",
    city_code: "HUX",
    airport_name: "Huatulco",
    country: "Mexico",
  },
  {
    sr: 7236,
    city: "Hubli",
    city_code: "HBX",
    airport_name: "Hubli",
    country: "India",
  },
  {
    sr: 7238,
    city: "Hudiksvall",
    city_code: "HUV",
    airport_name: "Hudiksvall",
    country: "Sweden",
  },
  {
    sr: 7242,
    city: "Hudson",
    city_code: "HCC",
    airport_name: "Columbia County",
    country: "United States",
  },
  {
    sr: 7244,
    city: "Hudson Bay",
    city_code: "YHB",
    airport_name: "Hudson Bay",
    country: "Canada",
  },
  {
    sr: 7246,
    city: "Hue",
    city_code: "HUI",
    airport_name: "Phu Bai",
    country: "Vietnam",
  },
  {
    sr: 7248,
    city: "Huehuetenango",
    city_code: "HUG",
    airport_name: "Huehuetenango",
    country: "Guatemala",
  },
  {
    sr: 7250,
    city: "Huelva",
    city_code: "HEV",
    airport_name: "Huelva",
    country: "Spain",
  },
  {
    sr: 7252,
    city: "Huesca",
    city_code: "HSK",
    airport_name: "Huesca",
    country: "Spain",
  },
  {
    sr: 7254,
    city: "Hughenden",
    city_code: "HGD",
    airport_name: "Hughenden",
    country: "Australia",
  },
  {
    sr: 7256,
    city: "Hughes",
    city_code: "HUS",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 7258,
    city: "Hugo",
    city_code: "HUJ",
    airport_name: "Hugo",
    country: "United States",
  },
  {
    sr: 7260,
    city: "Huizhou",
    city_code: "HUZ",
    airport_name: "Huizhou",
    country: "China",
  },
  {
    sr: 7262,
    city: "Hukuntsi",
    city_code: "HUK",
    airport_name: "Hukuntsi",
    country: "Botswana",
  },
  {
    sr: 7264,
    city: "Hultsfred",
    city_code: "HLF",
    airport_name: "Hultsfred",
    country: "Sweden",
  },
  {
    sr: 7266,
    city: "Humacao",
    city_code: "HUC",
    airport_name: "Humacao Arpt",
    country: "Puerto Rico",
  },
  {
    sr: 7268,
    city: "Humacao",
    city_code: "PPD",
    airport_name: "Palmas Del Mar",
    country: "Puerto Rico",
  },
  {
    sr: 7270,
    city: "Humberside",
    city_code: "HUY",
    airport_name: "Humberside Arpt",
    country: "United Kingdom",
  },
  {
    sr: 7272,
    city: "Humbert River",
    city_code: "HUB",
    airport_name: "Humbert River",
    country: "Australia",
  },
  {
    sr: 7276,
    city: "Humboldt",
    city_code: "HUD",
    airport_name: "Humboldt",
    country: "United States",
  },
  {
    sr: 7278,
    city: "Humboldt",
    city_code: "HBO",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 7280,
    city: "Humen",
    city_code: "ZTI",
    airport_name: "Humen Port",
    country: "China",
  },
  {
    sr: 7282,
    city: "Humera",
    city_code: "HUE",
    airport_name: "Humera",
    country: "Ethiopia",
  },
  {
    sr: 7284,
    city: "Huntingburg",
    city_code: "HNB",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 7286,
    city: "Huntington",
    city_code: "HTS",
    airport_name: "Tri State Milton",
    country: "United States",
  },
  {
    sr: 7288,
    city: "Huntsville",
    city_code: "HUA",
    airport_name: "Redstone AAF",
    country: "United States",
  },
  {
    sr: 7290,
    city: "Huntsville",
    city_code: "HSV",
    airport_name: "Huntsville",
    country: "United States",
  },
  {
    sr: 7292,
    city: "Hurghada",
    city_code: "HRG",
    airport_name: "Hurghada",
    country: "Egypt",
  },
  {
    sr: 7294,
    city: "Huron",
    city_code: "HON",
    airport_name: "Howes",
    country: "United States",
  },
  {
    sr: 7296,
    city: "Husavik",
    city_code: "HZK",
    airport_name: "Husavik",
    country: "Iceland",
  },
  {
    sr: 7298,
    city: "Huslia",
    city_code: "HSL",
    airport_name: "Huslia",
    country: "United States",
  },
  {
    sr: 7300,
    city: "Husum",
    city_code: "QHU",
    airport_name: "Husum",
    country: "Germany",
  },
  {
    sr: 7302,
    city: "Hutchinson",
    city_code: "HUT",
    airport_name: "Hutchinson",
    country: "United States",
  },
  {
    sr: 7304,
    city: "Hvammstangi",
    city_code: "HVM",
    airport_name: "Hvammstangi",
    country: "Iceland",
  },
  {
    sr: 7306,
    city: "Hwange",
    city_code: "WKI",
    airport_name: "Hwange",
    country: "Zimbabwe",
  },
  {
    sr: 7310,
    city: "Hwange Nat Park",
    city_code: "HWN",
    airport_name: "Hwange Nat Park",
    country: "Zimbabwe",
  },
  {
    sr: 7312,
    city: "Hyannis",
    city_code: "HYA",
    airport_name: "Barnstable",
    country: "United States",
  },
  {
    sr: 7314,
    city: "Hydaburg",
    city_code: "HYG",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 7316,
    city: "Hyder",
    city_code: "WHD",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 7318,
    city: "Hyderabad",
    city_code: "HYD",
    airport_name: "Rajiv Gandhi International Airport",
    country: "India",
  },
  {
    sr: 7320,
    city: "Hyderabad",
    city_code: "HDD",
    airport_name: "Hyderabad",
    country: "Pakistan",
  },
  {
    sr: 7322,
    city: "Hyvinkaa",
    city_code: "HYV",
    airport_name: "Hyvinkaa",
    country: "Finland",
  },
  {
    sr: 7324,
    city: "Iamalele",
    city_code: "IMA",
    airport_name: "Iamalele",
    country: "Papua New Guinea",
  },
  {
    sr: 7326,
    city: "Iasi",
    city_code: "IAS",
    airport_name: "Iasi",
    country: "Romania",
  },
  {
    sr: 7328,
    city: "Iaura",
    city_code: "IAU",
    airport_name: "Iaura",
    country: "Papua New Guinea",
  },
  {
    sr: 7330,
    city: "Ibadan",
    city_code: "IBA",
    airport_name: "Ibadan",
    country: "Nigeria",
  },
  {
    sr: 7332,
    city: "Ibague",
    city_code: "IBE",
    airport_name: "Ibague",
    country: "Colombia",
  },
  {
    sr: 7334,
    city: "Iberia",
    city_code: "IBP",
    airport_name: "Iberia",
    country: "Peru",
  },
  {
    sr: 7336,
    city: "Ibiza",
    city_code: "IBZ",
    airport_name: "Ibiza",
    country: "Spain",
  },
  {
    sr: 7338,
    city: "Ibo",
    city_code: "IBO",
    airport_name: "Ibo",
    country: "Mozambique",
  },
  {
    sr: 7340,
    city: "Iboki",
    city_code: "IBI",
    airport_name: "Iboki",
    country: "Papua New Guinea",
  },
  {
    sr: 7344,
    city: "Icabaru",
    city_code: "ICA",
    airport_name: "Icabaru",
    country: "Venezuela",
  },
  {
    sr: 7346,
    city: "Icy Bay",
    city_code: "ICY",
    airport_name: "Icy Bay",
    country: "United States",
  },
  {
    sr: 7348,
    city: "Ida Grove",
    city_code: "IDG",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 7350,
    city: "Idaho Falls",
    city_code: "IDA",
    airport_name: "Fanning Field",
    country: "United States",
  },
  {
    sr: 7352,
    city: "Idiofa",
    city_code: "IDF",
    airport_name: "Idiofa",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 7354,
    city: "Idre",
    city_code: "IDB",
    airport_name: "Idre",
    country: "Sweden",
  },
  {
    sr: 7356,
    city: "Iejima",
    city_code: "IEJ",
    airport_name: "Iejima",
    country: "Japan",
  },
  {
    sr: 7358,
    city: "Iffley",
    city_code: "IFF",
    airport_name: "Iffley",
    country: "Australia",
  },
  {
    sr: 7360,
    city: "Igarka",
    city_code: "IAA",
    airport_name: "Igarka",
    country: "Russia",
  },
  {
    sr: 7362,
    city: "Iginniarfik",
    city_code: "QFI",
    airport_name: "Heliport",
    country: "Greenland",
  },
  {
    sr: 7364,
    city: "Igiugig",
    city_code: "IGG",
    airport_name: "Igiugig",
    country: "United States",
  },
  {
    sr: 7366,
    city: "Igloolik",
    city_code: "YGT",
    airport_name: "Igloolik",
    country: "Canada",
  },
  {
    sr: 7368,
    city: "Ignace",
    city_code: "ZUC",
    airport_name: "Ignace",
    country: "Canada",
  },
  {
    sr: 7370,
    city: "Iguassu Falls",
    city_code: "IGU",
    airport_name: "Cataratas",
    country: "Brazil",
  },
  {
    sr: 7372,
    city: "Iguazu",
    city_code: "IGR",
    airport_name: "Cataratas",
    country: "Argentina",
  },
  {
    sr: 7374,
    city: "Iguela",
    city_code: "IGE",
    airport_name: "Iguela",
    country: "Gabon",
  },
  {
    sr: 7378,
    city: "Ihosy",
    city_code: "IHO",
    airport_name: "Ihosy",
    country: "Madagascar",
  },
  {
    sr: 7380,
    city: "Ihu",
    city_code: "IHU",
    airport_name: "Ihu",
    country: "Papua New Guinea",
  },
  {
    sr: 7382,
    city: "Ijui",
    city_code: "IJU",
    airport_name: "J Batista Bos Filho",
    country: "Brazil",
  },
  {
    sr: 7384,
    city: "Ikamiut",
    city_code: "QJI",
    airport_name: "Heliport",
    country: "Greenland",
  },
  {
    sr: 7386,
    city: "Ikaria Island",
    city_code: "JIK",
    airport_name: "Ikaria",
    country: "Greece",
  },
  {
    sr: 7388,
    city: "Ikela",
    city_code: "IKL",
    airport_name: "Ikela",
    country: "D  R  Congo  Zaire",
  },
  {
    sr: 7390,
    city: "Ikerasaarsuk",
    city_code: "QRY",
    airport_name: "Heliport",
    country: "Greenland",
  },
  {
    sr: 7392,
    city: "Iki",
    city_code: "IKI",
    airport_name: "Iki",
    country: "Japan",
  },
  {
    sr: 7394,
    city: "Ila Da Chilonzuene",
    city_code: "IDC",
    airport_name: "Ila Da Chilonzuene",
    country: "Mozambique",
  },
  {
    sr: 7396,
    city: "Ilaam",
    city_code: "IIL",
    airport_name: "Ilaam",
    country: "Iran",
  },
  {
    sr: 7398,
    city: "Ilaka",
    city_code: "ILK",
    airport_name: "Ilaka",
    country: "Madagascar",
  },
  {
    sr: 7400,
    city: "Ile Des Pins",
    city_code: "ILP",
    airport_name: "Ile Des Pins",
    country: "New Caledonia",
  },
  {
    sr: 7402,
    city: "Ile Ouen",
    city_code: "IOU",
    airport_name: "Ile Ouen",
    country: "New Caledonia",
  },
  {
    sr: 7404,
    city: "Ilebo",
    city_code: "PFR",
    airport_name: "Ilebo",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 7406,
    city: "Ileg",
    city_code: "ILX",
    airport_name: "Ileg",
    country: "Papua New Guinea",
  },
  {
    sr: 7408,
    city: "Iles De La Madeleine",
    city_code: "YGR",
    airport_name: "Iles De La Madeleine",
    country: "Canada",
  },
  {
    sr: 7412,
    city: "Ilford",
    city_code: "ILF",
    airport_name: "Ilford",
    country: "Canada",
  },
  {
    sr: 7414,
    city: "Ilha Solteira",
    city_code: "ILB",
    airport_name: "Ilha Solteira",
    country: "Brazil",
  },
  {
    sr: 7416,
    city: "Ilheus",
    city_code: "IOS",
    airport_name: "Eduardo Gomes",
    country: "Brazil",
  },
  {
    sr: 7418,
    city: "Iliamna",
    city_code: "ILI",
    airport_name: "Iliamna",
    country: "United States",
  },
  {
    sr: 7420,
    city: "Iligan",
    city_code: "IGN",
    airport_name: "Maria Cristina",
    country: "Philippines",
  },
  {
    sr: 7422,
    city: "Ilimanaq",
    city_code: "XIQ",
    airport_name: "Harbour",
    country: "Greenland",
  },
  {
    sr: 7424,
    city: "Illaga",
    city_code: "ILA",
    airport_name: "Illaga",
    country: "Indonesia",
  },
  {
    sr: 7426,
    city: "Illisheim",
    city_code: "ILH",
    airport_name: "Illis Airbase",
    country: "Germany",
  },
  {
    sr: 7428,
    city: "Illizi",
    city_code: "VVZ",
    airport_name: "Illizi",
    country: "Algeria",
  },
  {
    sr: 7430,
    city: "Ilo",
    city_code: "ILQ",
    airport_name: "Ilo",
    country: "Peru",
  },
  {
    sr: 7432,
    city: "Iloilo",
    city_code: "ILO",
    airport_name: "Mandurriao",
    country: "Philippines",
  },
  {
    sr: 7434,
    city: "Ilorin",
    city_code: "ILR",
    airport_name: "Ilorin",
    country: "Nigeria",
  },
  {
    sr: 7436,
    city: "Ilu",
    city_code: "IUL",
    airport_name: "Ilu",
    country: "Indonesia",
  },
  {
    sr: 7438,
    city: "Ilulissat",
    city_code: "JAV",
    airport_name: "Ilulissat",
    country: "Greenland",
  },
  {
    sr: 7440,
    city: "Imane",
    city_code: "IMN",
    airport_name: "Imane",
    country: "Papua New Guinea",
  },
  {
    sr: 7442,
    city: "Imbaimadai",
    city_code: "IMB",
    airport_name: "Imbaimadai",
    country: "Guyana",
  },
  {
    sr: 7446,
    city: "Immokalee",
    city_code: "IMM",
    airport_name: "Immokalee",
    country: "United States",
  },
  {
    sr: 7448,
    city: "Imonda",
    city_code: "IMD",
    airport_name: "Imonda",
    country: "Papua New Guinea",
  },
  {
    sr: 7450,
    city: "Imperatriz",
    city_code: "IMP",
    airport_name: "Imperatriz",
    country: "Brazil",
  },
  {
    sr: 7452,
    city: "Imperial",
    city_code: "IPL",
    airport_name: "Imperial County",
    country: "United States",
  },
  {
    sr: 7454,
    city: "Imperial",
    city_code: "IML",
    airport_name: "Imperial",
    country: "United States",
  },
  {
    sr: 7456,
    city: "Imperial Beach",
    city_code: "NRS",
    airport_name: "NAS",
    country: "United States",
  },
  {
    sr: 7458,
    city: "Impfondo",
    city_code: "ION",
    airport_name: "Impfondo",
    country: "Congo",
  },
  {
    sr: 7460,
    city: "Imphal",
    city_code: "IMF",
    airport_name: "Municipal",
    country: "India",
  },
  {
    sr: 7462,
    city: "In Amenas",
    city_code: "IAM",
    airport_name: "In Amenas",
    country: "Algeria",
  },
  {
    sr: 7464,
    city: "In Guezzam",
    city_code: "INF",
    airport_name: "In Guezzam",
    country: "Algeria",
  },
  {
    sr: 7466,
    city: "In Salah",
    city_code: "INZ",
    airport_name: "In Salah",
    country: "Algeria",
  },
  {
    sr: 7468,
    city: "Inagua",
    city_code: "IGA",
    airport_name: "Inagua",
    country: "Bahamas",
  },
  {
    sr: 7470,
    city: "Inanwatan",
    city_code: "INX",
    airport_name: "Inanwatan",
    country: "Indonesia",
  },
  {
    sr: 7472,
    city: "Incheon",
    city_code: "JCN",
    airport_name: "Heliport",
    country: "South Korea",
  },
  {
    sr: 7474,
    city: "Indagen",
    city_code: "IDN",
    airport_name: "Indagen",
    country: "Papua New Guinea",
  },
  {
    sr: 7476,
    city: "Indaselassie",
    city_code: "SHC",
    airport_name: "Indaselassie",
    country: "Ethiopia",
  },
  {
    sr: 7480,
    city: "Independence",
    city_code: "INB",
    airport_name: "Independence",
    country: "Belize",
  },
  {
    sr: 7482,
    city: "Independence",
    city_code: "IDP",
    airport_name: "Independence",
    country: "United States",
  },
  {
    sr: 7484,
    city: "Indian Springs",
    city_code: "INS",
    airport_name: "Af Aux",
    country: "United States",
  },
  {
    sr: 7486,
    city: "Indiana",
    city_code: "IDI",
    airport_name: "Indiana",
    country: "United States",
  },
  {
    sr: 7488,
    city: "Indianapolis",
    city_code: "IND",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 7490,
    city: "Indigo Bay Lodge",
    city_code: "IBL",
    airport_name: "Indigo Bay Lodge",
    country: "Mozambique",
  },
  {
    sr: 7492,
    city: "Indore",
    city_code: "IDR",
    airport_name: "Indore",
    country: "India",
  },
  {
    sr: 7494,
    city: "Indulkana",
    city_code: "IDK",
    airport_name: "Indulkana",
    country: "Australia",
  },
  {
    sr: 7496,
    city: "Ine Island",
    city_code: "IMI",
    airport_name: "Ine Island",
    country: "Marshall Islands",
  },
  {
    sr: 7498,
    city: "Ingeniero Jacobacci",
    city_code: "IGB",
    airport_name: "Ingeniero Jacobacci",
    country: "Argentina",
  },
  {
    sr: 7500,
    city: "Ingham",
    city_code: "IGH",
    airport_name: "Ingham",
    country: "Australia",
  },
  {
    sr: 7502,
    city: "Ingolstadt Manching",
    city_code: "IGS",
    airport_name: "Ingolstadt Manching",
    country: "Germany",
  },
  {
    sr: 7504,
    city: "Inhaca",
    city_code: "IHC",
    airport_name: "Inhaca",
    country: "Mozambique",
  },
  {
    sr: 7506,
    city: "Inhambane",
    city_code: "INH",
    airport_name: "Inhambane",
    country: "Mozambique",
  },
  {
    sr: 7508,
    city: "Inhaminga",
    city_code: "IMG",
    airport_name: "Inhaminga",
    country: "Mozambique",
  },
  {
    sr: 7510,
    city: "Inisheer",
    city_code: "INQ",
    airport_name: "Inisheer",
    country: "Ireland",
  },
  {
    sr: 7514,
    city: "Inishmaan",
    city_code: "IIA",
    airport_name: "Inishmaan",
    country: "Ireland",
  },
  {
    sr: 7516,
    city: "Inishmore",
    city_code: "IOR",
    airport_name: "Kilronan",
    country: "Ireland",
  },
  {
    sr: 7518,
    city: "Injune",
    city_code: "INJ",
    airport_name: "Injune",
    country: "Australia",
  },
  {
    sr: 7520,
    city: "Inkerman",
    city_code: "IKP",
    airport_name: "Inkerman",
    country: "Australia",
  },
  {
    sr: 7522,
    city: "Innamincka",
    city_code: "INM",
    airport_name: "Innamincka",
    country: "Australia",
  },
  {
    sr: 7524,
    city: "Innisfail",
    city_code: "IFL",
    airport_name: "Innisfail",
    country: "Australia",
  },
  {
    sr: 7526,
    city: "Innsbruck",
    city_code: "INN",
    airport_name: "Kranebitten",
    country: "Austria",
  },
  {
    sr: 7528,
    city: "Inongo",
    city_code: "INO",
    airport_name: "Inongo",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 7530,
    city: "Inta",
    city_code: "INA",
    airport_name: "Inta",
    country: "Russia",
  },
  {
    sr: 7532,
    city: "International Falls",
    city_code: "INL",
    airport_name: "Falls Intl",
    country: "United States",
  },
  {
    sr: 7534,
    city: "Inukjuak",
    city_code: "YPH",
    airport_name: "Inukjuak",
    country: "Canada",
  },
  {
    sr: 7536,
    city: "Inus",
    city_code: "IUS",
    airport_name: "Inus",
    country: "Papua New Guinea",
  },
  {
    sr: 7538,
    city: "Inuvik",
    city_code: "YEV",
    airport_name: "Inuvik Mike Zubko",
    country: "Canada",
  },
  {
    sr: 7540,
    city: "Invercargill",
    city_code: "IVC",
    airport_name: "Invercargill",
    country: "New Zealand",
  },
  {
    sr: 7542,
    city: "Inverell",
    city_code: "IVR",
    airport_name: "Inverell",
    country: "Australia",
  },
  {
    sr: 7544,
    city: "Inverlake",
    city_code: "TIL",
    airport_name: "Inverlake",
    country: "Canada",
  },
  {
    sr: 7548,
    city: "Inverness",
    city_code: "INV",
    airport_name: "Inverness",
    country: "United Kingdom",
  },
  {
    sr: 7550,
    city: "Inverness",
    city_code: "ZIV",
    airport_name: "ScotRail",
    country: "United Kingdom",
  },
  {
    sr: 7552,
    city: "Inverway",
    city_code: "IVW",
    airport_name: "Inverway",
    country: "Australia",
  },
  {
    sr: 7554,
    city: "Inyati",
    city_code: "INY",
    airport_name: "Inyati",
    country: "South Africa",
  },
  {
    sr: 7556,
    city: "Inyokern",
    city_code: "IYK",
    airport_name: "Kern County",
    country: "United States",
  },
  {
    sr: 7558,
    city: "Ioannina",
    city_code: "IOA",
    airport_name: "Ioannina",
    country: "Greece",
  },
  {
    sr: 7560,
    city: "Iokea",
    city_code: "IOK",
    airport_name: "Iokea",
    country: "Papua New Guinea",
  },
  {
    sr: 7562,
    city: "Ioma",
    city_code: "IOP",
    airport_name: "Ioma",
    country: "Papua New Guinea",
  },
  {
    sr: 7564,
    city: "Iowa City",
    city_code: "IOW",
    airport_name: "Iowa City",
    country: "United States",
  },
  {
    sr: 7566,
    city: "Iowa Falls",
    city_code: "IFA",
    airport_name: "Iowa Falls",
    country: "United States",
  },
  {
    sr: 7568,
    city: "Ipatinga",
    city_code: "IPN",
    airport_name: "Usiminas",
    country: "Brazil",
  },
  {
    sr: 7570,
    city: "Ipiales",
    city_code: "IPI",
    airport_name: "San Luis",
    country: "Colombia",
  },
  {
    sr: 7572,
    city: "Ipiau",
    city_code: "IPU",
    airport_name: "Ipiau",
    country: "Brazil",
  },
  {
    sr: 7574,
    city: "Ipil",
    city_code: "IPE",
    airport_name: "Ipil",
    country: "Philippines",
  },
  {
    sr: 7576,
    city: "Ipiranga",
    city_code: "IPG",
    airport_name: "Ipiranga",
    country: "Brazil",
  },
  {
    sr: 7578,
    city: "Ipoh",
    city_code: "IPH",
    airport_name: "Ipoh",
    country: "Malaysia",
  },
  {
    sr: 7582,
    city: "Ipota",
    city_code: "IPA",
    airport_name: "Ipota",
    country: "Vanuatu",
  },
  {
    sr: 7584,
    city: "Ipswich",
    city_code: "IPW",
    airport_name: "Ipswich  Closed",
    country: "United Kingdom",
  },
  {
    sr: 7586,
    city: "Iqaluit",
    city_code: "YFB",
    airport_name: "Iqaluit",
    country: "Canada",
  },
  {
    sr: 7588,
    city: "Iquique",
    city_code: "IQQ",
    airport_name: "Cavancha",
    country: "Chile",
  },
  {
    sr: 7590,
    city: "Iquitos",
    city_code: "IQT",
    airport_name: "C F Secada",
    country: "Peru",
  },
  {
    sr: 7592,
    city: "Iraan",
    city_code: "IRB",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 7594,
    city: "Iran Shahr",
    city_code: "IHR",
    airport_name: "Iran Shahr",
    country: "Iran",
  },
  {
    sr: 7596,
    city: "Irece",
    city_code: "IRE",
    airport_name: "Irece",
    country: "Brazil",
  },
  {
    sr: 7598,
    city: "Iringa",
    city_code: "IRI",
    airport_name: "Nduli",
    country: "Tanzania",
  },
  {
    sr: 7600,
    city: "Iriona",
    city_code: "IRN",
    airport_name: "Iriona",
    country: "Honduras",
  },
  {
    sr: 7602,
    city: "Irkutsk",
    city_code: "IKT",
    airport_name: "Irkutsk",
    country: "Russia",
  },
  {
    sr: 7604,
    city: "Iron Mountain",
    city_code: "IMT",
    airport_name: "Ford",
    country: "United States",
  },
  {
    sr: 7606,
    city: "Ironwood",
    city_code: "IWD",
    airport_name: "Gogebic County",
    country: "United States",
  },
  {
    sr: 7608,
    city: "Istanbul",
    city_code: "ISL",
    airport_name: "Ataturk",
    country: "Turkey",
  },
  {
    sr: 7610,
    city: "Isafjordur",
    city_code: "IFJ",
    airport_name: "Isafjordur",
    country: "Iceland",
  },
  {
    sr: 7612,
    city: "Ischia",
    city_code: "ISH",
    airport_name: "Ischia",
    country: "Italy",
  },
  {
    sr: 7616,
    city: "Iscuande",
    city_code: "ISD",
    airport_name: "Iscuande",
    country: "Colombia",
  },
  {
    sr: 7618,
    city: "Isfahan",
    city_code: "IFN",
    airport_name: "Isfahan",
    country: "Iran",
  },
  {
    sr: 7620,
    city: "Ishigaki",
    city_code: "ISG",
    airport_name: "Ishigaki",
    country: "Japan",
  },
  {
    sr: 7622,
    city: "Ishurdi",
    city_code: "IRD",
    airport_name: "Ishurdi",
    country: "Bangladesh",
  },
  {
    sr: 7624,
    city: "Isiro",
    city_code: "IRP",
    airport_name: "Matari",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 7626,
    city: "Isisford",
    city_code: "ISI",
    airport_name: "Isisford",
    country: "Australia",
  },
  {
    sr: 7628,
    city: "Isla Mujeres",
    city_code: "ISJ",
    airport_name: "Isla Mujeres",
    country: "Mexico",
  },
  {
    sr: 7630,
    city: "Islamabad",
    city_code: "ISB",
    airport_name: "Islamabad Intl",
    country: "Pakistan",
  },
  {
    sr: 7632,
    city: "Island Lk Garden Hill",
    city_code: "YIV",
    airport_name: "Island Lk Garden Hill",
    country: "Canada",
  },
  {
    sr: 7634,
    city: "Islay",
    city_code: "ILY",
    airport_name: "Glenegedale",
    country: "United Kingdom",
  },
  {
    sr: 7636,
    city: "Isle Of Man",
    city_code: "IOM",
    airport_name: "Ronaldsway",
    country: "United Kingdom",
  },
  {
    sr: 7638,
    city: "Isle Of Skye",
    city_code: "SKL",
    airport_name: "Broadford",
    country: "United Kingdom",
  },
  {
    sr: 7640,
    city: "Isles Of Scilly",
    city_code: "ISC",
    airport_name: "St Marys",
    country: "United Kingdom",
  },
  {
    sr: 7642,
    city: "Isles Of Scilly",
    city_code: "TSO",
    airport_name: "Tresco",
    country: "United Kingdom",
  },
  {
    sr: 7644,
    city: "Islip",
    city_code: "ISP",
    airport_name: "Long Island Macarthur",
    country: "United States",
  },
  {
    sr: 7646,
    city: "Isparta",
    city_code: "ISE",
    airport_name: "Isparta",
    country: "Turkey",
  },
  {
    sr: 7650,
    city: "Istanbul",
    city_code: "IST",
    airport_name: "Istanbul New Airport",
    country: "Turkey",
  },
  {
    sr: 7652,
    city: "Istanbul",
    city_code: "SAW",
    airport_name: "Sabiha Gokcen",
    country: "Turkey",
  },
  {
    sr: 7654,
    city: "Itabuna",
    city_code: "ITN",
    airport_name: "Itabuna",
    country: "Brazil",
  },
  {
    sr: 7656,
    city: "Itacoatiara",
    city_code: "ITA",
    airport_name: "Itacoatiara",
    country: "Brazil",
  },
  {
    sr: 7658,
    city: "Itaituba",
    city_code: "ITB",
    airport_name: "Itaituba",
    country: "Brazil",
  },
  {
    sr: 7660,
    city: "Itambacuri",
    city_code: "ITI",
    airport_name: "Itambacuri",
    country: "Brazil",
  },
  {
    sr: 7662,
    city: "Itaperuna",
    city_code: "ITP",
    airport_name: "Itaperuna",
    country: "Brazil",
  },
  {
    sr: 7664,
    city: "Itaqui",
    city_code: "ITQ",
    airport_name: "Itaqui",
    country: "Brazil",
  },
  {
    sr: 7666,
    city: "Itauba",
    city_code: "AUB",
    airport_name: "Itauba",
    country: "Brazil",
  },
  {
    sr: 7668,
    city: "Ithaca",
    city_code: "ITH",
    airport_name: "Tompkins County",
    country: "United States",
  },
  {
    sr: 7670,
    city: "Itokama",
    city_code: "ITK",
    airport_name: "Itokama",
    country: "Papua New Guinea",
  },
  {
    sr: 7672,
    city: "Ittoqqortoormiit",
    city_code: "OBY",
    airport_name: "Ittoqqortoormiit",
    country: "Greenland",
  },
  {
    sr: 7674,
    city: "Itubera",
    city_code: "ITE",
    airport_name: "Itubera",
    country: "Brazil",
  },
  {
    sr: 7676,
    city: "Itumbiara",
    city_code: "ITR",
    airport_name: "Itumbiara",
    country: "Brazil",
  },
  {
    sr: 7678,
    city: "Ivalo",
    city_code: "IVL",
    airport_name: "Ivalo",
    country: "Finland",
  },
  {
    sr: 7680,
    city: "Ivangrad",
    city_code: "IVG",
    airport_name: "Ivangrad",
    country: "Yugoslavia",
  },
  {
    sr: 7684,
    city: "Ivanof Bay",
    city_code: "KIB",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 7686,
    city: "Ivano Frankovsk",
    city_code: "IFO",
    airport_name: "Ivano Frankovsk",
    country: "Ukraine",
  },
  {
    sr: 7688,
    city: "Ivanova",
    city_code: "IWA",
    airport_name: "Ivanova",
    country: "Russia",
  },
  {
    sr: 7690,
    city: "Ivishak",
    city_code: "IVH",
    airport_name: "Ivishak",
    country: "United States",
  },
  {
    sr: 7692,
    city: "Ivujivik",
    city_code: "YIK",
    airport_name: "Ivujivik",
    country: "Canada",
  },
  {
    sr: 7694,
    city: "Iwami",
    city_code: "IWJ",
    airport_name: "Iwami",
    country: "Japan",
  },
  {
    sr: 7696,
    city: "Iwo Jima Vol",
    city_code: "IWO",
    airport_name: "Iwo Jima Airbase",
    country: "Japan",
  },
  {
    sr: 7698,
    city: "Ixtapa Zihuatanejo",
    city_code: "ZIH",
    airport_name: "Internacional",
    country: "Mexico",
  },
  {
    sr: 7700,
    city: "Ixtepec",
    city_code: "IZT",
    airport_name: "Ixtepec",
    country: "Mexico",
  },
  {
    sr: 7702,
    city: "Izhevsk",
    city_code: "IJK",
    airport_name: "Izhevsk",
    country: "Russia",
  },
  {
    sr: 7704,
    city: "Izmir",
    city_code: "ADB",
    airport_name: "Adnan Menderes Arpt",
    country: "Turkey",
  },
  {
    sr: 7706,
    city: "Izmir",
    city_code: "IGL",
    airport_name: "Cigli Military Arpt",
    country: "Turkey",
  },
  {
    sr: 7708,
    city: "Izumo",
    city_code: "IZO",
    airport_name: "Izumo",
    country: "Japan",
  },
  {
    sr: 7710,
    city: "Jabalpur",
    city_code: "JLR",
    airport_name: "Jabalpur",
    country: "India",
  },
  {
    sr: 7712,
    city: "Jabiru",
    city_code: "JAB",
    airport_name: "Jabiru",
    country: "Australia",
  },
  {
    sr: 7714,
    city: "Jabot",
    city_code: "JAT",
    airport_name: "Jabot",
    country: "Marshall Islands",
  },
  {
    sr: 7718,
    city: "Jacareacanga",
    city_code: "JCR",
    airport_name: "Jacareacanga",
    country: "Brazil",
  },
  {
    sr: 7720,
    city: "Jackpot",
    city_code: "KPT",
    airport_name: "Jackpot",
    country: "United States",
  },
  {
    sr: 7722,
    city: "Jackson",
    city_code: "JXN",
    airport_name: "Reynolds Municipal",
    country: "United States",
  },
  {
    sr: 7724,
    city: "Jackson",
    city_code: "MJQ",
    airport_name: "Jackson",
    country: "United States",
  },
  {
    sr: 7726,
    city: "Jackson",
    city_code: "HKS",
    airport_name: "Hawkins Field",
    country: "United States",
  },
  {
    sr: 7728,
    city: "Jackson",
    city_code: "JAN",
    airport_name: "Jackson Evers",
    country: "United States",
  },
  {
    sr: 7730,
    city: "Jackson",
    city_code: "MKL",
    airport_name: "Mckellar",
    country: "United States",
  },
  {
    sr: 7732,
    city: "Jackson",
    city_code: "JAC",
    airport_name: "Jackson Hole",
    country: "United States",
  },
  {
    sr: 7734,
    city: "Jacksonville",
    city_code: "LRF",
    airport_name: "Little Rock Afb",
    country: "United States",
  },
  {
    sr: 7736,
    city: "Jacksonville",
    city_code: "NZC",
    airport_name: "Cecil Field NAS",
    country: "United States",
  },
  {
    sr: 7738,
    city: "Jacksonville",
    city_code: "CRG",
    airport_name: "Craig Municipal",
    country: "United States",
  },
  {
    sr: 7740,
    city: "Jacksonville",
    city_code: "JAX",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 7742,
    city: "Jacksonville",
    city_code: "NIP",
    airport_name: "Jacksonville NAS",
    country: "United States",
  },
  {
    sr: 7744,
    city: "Jacksonville",
    city_code: "IJX",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 7746,
    city: "Jacksonville",
    city_code: "OAJ",
    airport_name: "Albert J Ellis",
    country: "United States",
  },
  {
    sr: 7748,
    city: "Jacksonville",
    city_code: "JKV",
    airport_name: "Jacksonville",
    country: "United States",
  },
  {
    sr: 7752,
    city: "Jacmel",
    city_code: "JAK",
    airport_name: "Jacmel",
    country: "Haiti",
  },
  {
    sr: 7754,
    city: "Jacobabad",
    city_code: "JAG",
    airport_name: "Jacobabad",
    country: "Pakistan",
  },
  {
    sr: 7756,
    city: "Jacobina",
    city_code: "JCM",
    airport_name: "Jacobina",
    country: "Brazil",
  },
  {
    sr: 7758,
    city: "Jacquinot Bay",
    city_code: "JAQ",
    airport_name: "Jacquinot Bay",
    country: "Papua New Guinea",
  },
  {
    sr: 7760,
    city: "Jaffna",
    city_code: "JAF",
    airport_name: "Kankesanturai",
    country: "Sri Lanka",
  },
  {
    sr: 7762,
    city: "Jaffrey",
    city_code: "AFN",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 7764,
    city: "Jagdalpur",
    city_code: "JGB",
    airport_name: "Jagdalpur",
    country: "India",
  },
  {
    sr: 7766,
    city: "Jahrom",
    city_code: "JAR",
    airport_name: "Jahrom",
    country: "United States",
  },
  {
    sr: 7768,
    city: "Jaipur",
    city_code: "JAI",
    airport_name: "Sanganeer",
    country: "India",
  },
  {
    sr: 7770,
    city: "Jaisalmer",
    city_code: "JSA",
    airport_name: "Jaisalmer",
    country: "India",
  },
  {
    sr: 7772,
    city: "Jakarta",
    city_code: "HLP",
    airport_name: "Halim Perdana Kusuma",
    country: "Indonesia",
  },
  {
    sr: 7774,
    city: "Jakarta",
    city_code: "JKT",
    airport_name: "Soekarno Hatta Intl",
    country: "Indonesia",
  },
  {
    sr: 7776,
    city: "Jalalabad",
    city_code: "JAA",
    airport_name: "Jalalabad",
    country: "Afghanistan",
  },
  {
    sr: 7778,
    city: "Jalapa",
    city_code: "JAL",
    airport_name: "Jalapa",
    country: "Mexico",
  },
  {
    sr: 7780,
    city: "Jales",
    city_code: "JLS",
    airport_name: "Jales",
    country: "Brazil",
  },
  {
    sr: 7782,
    city: "Jaluit Island",
    city_code: "UIT",
    airport_name: "Jaluit Island",
    country: "Marshall Islands",
  },
  {
    sr: 7786,
    city: "Jamba",
    city_code: "JMB",
    airport_name: "Jamba",
    country: "Angola",
  },
  {
    sr: 7788,
    city: "Jambi",
    city_code: "DJB",
    airport_name: "Sultan Taha Syarifudn",
    country: "Indonesia",
  },
  {
    sr: 7790,
    city: "Jambol",
    city_code: "JAM",
    airport_name: "Jambol",
    country: "Bulgaria",
  },
  {
    sr: 7792,
    city: "Jamestown",
    city_code: "JMS",
    airport_name: "Jamestown",
    country: "United States",
  },
  {
    sr: 7794,
    city: "Jamestown",
    city_code: "JHW",
    airport_name: "Jamestown",
    country: "United States",
  },
  {
    sr: 7796,
    city: "Jammu",
    city_code: "IXJ",
    airport_name: "Satwari",
    country: "India",
  },
  {
    sr: 7798,
    city: "Jamnagar",
    city_code: "JGA",
    airport_name: "Govardhanpur",
    country: "India",
  },
  {
    sr: 7800,
    city: "Jamshedpur",
    city_code: "IXW",
    airport_name: "Sonari",
    country: "India",
  },
  {
    sr: 7802,
    city: "Janakpur",
    city_code: "JKR",
    airport_name: "Janakpur",
    country: "Nepal",
  },
  {
    sr: 7804,
    city: "Perth",
    city_code: "JAD",
    airport_name: "Jandakot",
    country: "Australia",
  },
  {
    sr: 7806,
    city: "Janesville",
    city_code: "JVL",
    airport_name: "Rock County",
    country: "United States",
  },
  {
    sr: 7808,
    city: "Januaria",
    city_code: "JNA",
    airport_name: "Januaria",
    country: "Brazil",
  },
  {
    sr: 7810,
    city: "Jaque",
    city_code: "JQE",
    airport_name: "Jaque",
    country: "Panama",
  },
  {
    sr: 7812,
    city: "Jasper",
    city_code: "APT",
    airport_name: "Marion County",
    country: "United States",
  },
  {
    sr: 7814,
    city: "Jasper",
    city_code: "JAS",
    airport_name: "County",
    country: "United States",
  },
  {
    sr: 7816,
    city: "Jasper Hinton",
    city_code: "YJP",
    airport_name: "Jasper Hinton",
    country: "Canada",
  },
  {
    sr: 7820,
    city: "Jatai",
    city_code: "JTI",
    airport_name: "Jatai",
    country: "Brazil",
  },
  {
    sr: 7822,
    city: "Jauja",
    city_code: "JAU",
    airport_name: "Jauja",
    country: "Peru",
  },
  {
    sr: 7824,
    city: "Jayapura",
    city_code: "DJJ",
    airport_name: "Sentani",
    country: "Indonesia",
  },
  {
    sr: 7826,
    city: "Jazan",
    city_code: "GIZ",
    airport_name: "Jazan",
    country: "Saudi Arabia",
  },
  {
    sr: 7828,
    city: "Dammam",
    city_code: "XZF",
    airport_name: "Dammam Port",
    country: "Saudi Arabia",
  },
  {
    sr: 7830,
    city: "Jeddah",
    city_code: "JED",
    airport_name: "King Abdulaziz International",
    country: "Saudi Arabia",
  },
  {
    sr: 7832,
    city: "Jefferson",
    city_code: "EFW",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 7834,
    city: "Jefferson",
    city_code: "JFN",
    airport_name: "Ashtabula",
    country: "United States",
  },
  {
    sr: 7836,
    city: "Jefferson City",
    city_code: "JEF",
    airport_name: "Memorial",
    country: "United States",
  },
  {
    sr: 7838,
    city: "Jeh",
    city_code: "JEJ",
    airport_name: "Jeh",
    country: "Marshall Islands",
  },
  {
    sr: 7840,
    city: "Jeju",
    city_code: "JCJ",
    airport_name: "Chuja Heliport",
    country: "South Korea",
  },
  {
    sr: 7842,
    city: "Jeju",
    city_code: "CJU",
    airport_name: "Jeju Airport",
    country: "South Korea",
  },
  {
    sr: 7844,
    city: "Jeju",
    city_code: "JSP",
    airport_name: "Seogwipo Heliport",
    country: "South Korea",
  },
  {
    sr: 7846,
    city: "Jena",
    city_code: "ZJS",
    airport_name: "Jena Railway",
    country: "Germany",
  },
  {
    sr: 7848,
    city: "Jenpeg",
    city_code: "ZJG",
    airport_name: "Jenpeg",
    country: "Canada",
  },
  {
    sr: 7850,
    city: "Jeonju",
    city_code: "CHN",
    airport_name: "Jeonju",
    country: "South Korea",
  },
  {
    sr: 7854,
    city: "Jequie",
    city_code: "JEQ",
    airport_name: "Jequie",
    country: "Brazil",
  },
  {
    sr: 7856,
    city: "Jeremie",
    city_code: "JEE",
    airport_name: "Jeremie",
    country: "Haiti",
  },
  {
    sr: 7858,
    city: "Jerez De La Frontera",
    city_code: "XRY",
    airport_name: "Jerez De La Frontera",
    country: "Spain",
  },
  {
    sr: 7860,
    city: "Jersey",
    city_code: "JER",
    airport_name: "States",
    country: "United Kingdom",
  },
  {
    sr: 7862,
    city: "Jerusalem",
    city_code: "JRS",
    airport_name: "Jerusalem",
    country: "Israel",
  },
  {
    sr: 7864,
    city: "Jesolo",
    city_code: "JLO",
    airport_name: "Jesolo",
    country: "Italy",
  },
  {
    sr: 7866,
    city: "Jessore",
    city_code: "JSR",
    airport_name: "Jessore",
    country: "Bangladesh",
  },
  {
    sr: 7868,
    city: "Jeypore",
    city_code: "PYB",
    airport_name: "Jeypore",
    country: "India",
  },
  {
    sr: 7870,
    city: "Ji An",
    city_code: "JGS",
    airport_name: "Ji An Jing Gang Shan",
    country: "China",
  },
  {
    sr: 7872,
    city: "Jiamusi",
    city_code: "JMU",
    airport_name: "Jiamusi",
    country: "China",
  },
  {
    sr: 7874,
    city: "Jiang Men",
    city_code: "ZBD",
    airport_name: "Jiang Men Ferry",
    country: "China",
  },
  {
    sr: 7876,
    city: "Jiayuguan",
    city_code: "JGN",
    airport_name: "Jiayuguan",
    country: "China",
  },
  {
    sr: 7878,
    city: "Jijel",
    city_code: "GJL",
    airport_name: "Jijel",
    country: "Algeria",
  },
  {
    sr: 7880,
    city: "Jijiga",
    city_code: "JIJ",
    airport_name: "Jigiga",
    country: "Ethiopia",
  },
  {
    sr: 7882,
    city: "Jilin",
    city_code: "JIL",
    airport_name: "Jilin",
    country: "China",
  },
  {
    sr: 7884,
    city: "Jimma",
    city_code: "JIM",
    airport_name: "Jimma",
    country: "Ethiopia",
  },
  {
    sr: 7888,
    city: "Jinan",
    city_code: "TNA",
    airport_name: "Jinan",
    country: "China",
  },
  {
    sr: 7890,
    city: "Jingdezhen",
    city_code: "JDZ",
    airport_name: "Jingdezhen",
    country: "China",
  },
  {
    sr: 7892,
    city: "Jinghong",
    city_code: "JHG",
    airport_name: "Gasa",
    country: "China",
  },
  {
    sr: 7894,
    city: "Jinhae",
    city_code: "CHF",
    airport_name: "Jinhae",
    country: "South Korea",
  },
  {
    sr: 7896,
    city: "Jining",
    city_code: "JNG",
    airport_name: "Jining",
    country: "China",
  },
  {
    sr: 7898,
    city: "Jinja",
    city_code: "JIN",
    airport_name: "Jinja",
    country: "Uganda",
  },
  {
    sr: 7900,
    city: "Jinjiang",
    city_code: "JJN",
    airport_name: "Jinjiang",
    country: "China",
  },
  {
    sr: 7902,
    city: "Jinju",
    city_code: "HIN",
    airport_name: "Sacheon",
    country: "South Korea",
  },
  {
    sr: 7904,
    city: "Jinka",
    city_code: "BCO",
    airport_name: "Jinka",
    country: "Ethiopia",
  },
  {
    sr: 7906,
    city: "Jinzhou",
    city_code: "JNZ",
    airport_name: "Jinzhou",
    country: "China",
  },
  {
    sr: 7908,
    city: "Ji Parana",
    city_code: "JPR",
    airport_name: "Ji Parana",
    country: "Brazil",
  },
  {
    sr: 7910,
    city: "Jipijapa",
    city_code: "JIP",
    airport_name: "Jipijapa",
    country: "Ecuador",
  },
  {
    sr: 7912,
    city: "Jiri",
    city_code: "JIR",
    airport_name: "Jiri",
    country: "Nepal",
  },
  {
    sr: 7914,
    city: "Jiroft",
    city_code: "JYR",
    airport_name: "Jiroft",
    country: "Iran",
  },
  {
    sr: 7916,
    city: "Jiujiang",
    city_code: "JIU",
    airport_name: "Jiujiang",
    country: "China",
  },
  {
    sr: 7918,
    city: "Jiuquan",
    city_code: "CHW",
    airport_name: "Jiuquan",
    country: "China",
  },
  {
    sr: 7922,
    city: "Jiwani",
    city_code: "JIW",
    airport_name: "Jiwani",
    country: "Pakistan",
  },
  {
    sr: 7924,
    city: "Joacaba",
    city_code: "JCB",
    airport_name: "Joacaba",
    country: "Brazil",
  },
  {
    sr: 7926,
    city: "Joao Pessoa",
    city_code: "JPA",
    airport_name: "Castro Pinto",
    country: "Brazil",
  },
  {
    sr: 7928,
    city: "Jodhpur",
    city_code: "JDH",
    airport_name: "Jodhpur",
    country: "India",
  },
  {
    sr: 7930,
    city: "Joensuu",
    city_code: "JOE",
    airport_name: "Joensuu",
    country: "Finland",
  },
  {
    sr: 7932,
    city: "Johannesburg",
    city_code: "GCJ",
    airport_name: "International",
    country: "South Africa",
  },
  {
    sr: 7934,
    city: "Johannesburg",
    city_code: "HCS",
    airport_name: "Randburg Heliport",
    country: "South Africa",
  },
  {
    sr: 7936,
    city: "Johannesburg",
    city_code: "QRA",
    airport_name: "Randgermiston",
    country: "South Africa",
  },
  {
    sr: 7938,
    city: "John Day",
    city_code: "JDA",
    airport_name: "John Day",
    country: "United States",
  },
  {
    sr: 7940,
    city: "Johnny Mountain",
    city_code: "YJO",
    airport_name: "Johnny Mountain",
    country: "Canada",
  },
  {
    sr: 7942,
    city: "Johnson",
    city_code: "JCY",
    airport_name: "Johnson",
    country: "United States",
  },
  {
    sr: 7944,
    city: "Johnson City",
    city_code: "TRI",
    airport_name: "Tri Cities Regional",
    country: "United States",
  },
  {
    sr: 7946,
    city: "Johnston Island",
    city_code: "JON",
    airport_name: "Johnston Island",
    country: "Us Minor Outlying Islands",
  },
  {
    sr: 7948,
    city: "Johnstown",
    city_code: "JST",
    airport_name: "Cambria County",
    country: "United States",
  },
  {
    sr: 7950,
    city: "Johor Bahru",
    city_code: "JHB",
    airport_name: "Sultan Ismail Intl",
    country: "Malaysia",
  },
  {
    sr: 7952,
    city: "Joinville",
    city_code: "JOI",
    airport_name: "Cubatao",
    country: "Brazil",
  },
  {
    sr: 7956,
    city: "Joliet",
    city_code: "JOT",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 7958,
    city: "Jolo",
    city_code: "JOL",
    airport_name: "Jolo",
    country: "Philippines",
  },
  {
    sr: 7960,
    city: "Jolon",
    city_code: "HGT",
    airport_name: "Hunter AAF",
    country: "United States",
  },
  {
    sr: 7962,
    city: "Jomsom",
    city_code: "JMO",
    airport_name: "Jomsom",
    country: "Nepal",
  },
  {
    sr: 7964,
    city: "Jonesboro",
    city_code: "JBR",
    airport_name: "Jonesboro",
    country: "United States",
  },
  {
    sr: 7966,
    city: "Jonkoping",
    city_code: "JKG",
    airport_name: "Axamo",
    country: "Sweden",
  },
  {
    sr: 7968,
    city: "Joplin",
    city_code: "JLN",
    airport_name: "Joplin",
    country: "United States",
  },
  {
    sr: 7970,
    city: "Jordan",
    city_code: "JDN",
    airport_name: "Jordan",
    country: "United States",
  },
  {
    sr: 7972,
    city: "Jorhat",
    city_code: "JRH",
    airport_name: "Rowriah",
    country: "India",
  },
  {
    sr: 7974,
    city: "Jos",
    city_code: "JOS",
    airport_name: "Jos",
    country: "Nigeria",
  },
  {
    sr: 7976,
    city: "Jose De San Martin",
    city_code: "JSM",
    airport_name: "Jose De San Martin",
    country: "Argentina",
  },
  {
    sr: 7978,
    city: "Josephstaal",
    city_code: "JOP",
    airport_name: "Josephstaal",
    country: "Papua New Guinea",
  },
  {
    sr: 7980,
    city: "Joshkar Ola",
    city_code: "JOK",
    airport_name: "Joshkar Ola",
    country: "Russia",
  },
  {
    sr: 7982,
    city: "Sakaka Al Jouf",
    city_code: "AJF",
    airport_name: "Jouf",
    country: "Saudi Arabia",
  },
  {
    sr: 7984,
    city: "Juan Les Pins",
    city_code: "JLP",
    airport_name: "Juan Les Pins",
    country: "France",
  },
  {
    sr: 7986,
    city: "Juanjui",
    city_code: "JJI",
    airport_name: "Juanjui",
    country: "Peru",
  },
  {
    sr: 7990,
    city: "Juara",
    city_code: "JUA",
    airport_name: "Juara",
    country: "Brazil",
  },
  {
    sr: 7992,
    city: "Juazeiro Do Norte",
    city_code: "JDO",
    airport_name: "Regional Do Cariri",
    country: "Brazil",
  },
  {
    sr: 7994,
    city: "Juba",
    city_code: "JUB",
    airport_name: "Juba",
    country: "Sudan",
  },
  {
    sr: 7996,
    city: "Juina",
    city_code: "JIA",
    airport_name: "Juina",
    country: "Brazil",
  },
  {
    sr: 7998,
    city: "Juist",
    city_code: "JUI",
    airport_name: "Juist",
    country: "Germany",
  },
  {
    sr: 8000,
    city: "Juiz De Fora",
    city_code: "JDF",
    airport_name: "Francisco De Assis",
    country: "Brazil",
  },
  {
    sr: 8002,
    city: "Jujuy",
    city_code: "JUJ",
    airport_name: "El Cadillal",
    country: "Argentina",
  },
  {
    sr: 8004,
    city: "Julia Creek",
    city_code: "JCK",
    airport_name: "Julia Creek",
    country: "Australia",
  },
  {
    sr: 8006,
    city: "Juliaca",
    city_code: "JUL",
    airport_name: "Juliaca",
    country: "Peru",
  },
  {
    sr: 8008,
    city: "Jumla",
    city_code: "JUM",
    airport_name: "Jumla",
    country: "Nepal",
  },
  {
    sr: 8010,
    city: "Junction",
    city_code: "JCT",
    airport_name: "Kimble County",
    country: "United States",
  },
  {
    sr: 8012,
    city: "Jundah",
    city_code: "JUN",
    airport_name: "Jundah",
    country: "Australia",
  },
  {
    sr: 8014,
    city: "Juneau",
    city_code: "JNU",
    airport_name: "Boundary Bay",
    country: "United States",
  },
  {
    sr: 8016,
    city: "Juneau",
    city_code: "UNU",
    airport_name: "Dodge County",
    country: "United States",
  },
  {
    sr: 8018,
    city: "Junin",
    city_code: "JNI",
    airport_name: "Junin",
    country: "Argentina",
  },
  {
    sr: 8020,
    city: "Jurado",
    city_code: "JUO",
    airport_name: "Jurado",
    country: "Colombia",
  },
  {
    sr: 8024,
    city: "Jurien Bay",
    city_code: "JUR",
    airport_name: "Jurien Bay",
    country: "Australia",
  },
  {
    sr: 8026,
    city: "Juruena",
    city_code: "JRN",
    airport_name: "Juruena",
    country: "Brazil",
  },
  {
    sr: 8028,
    city: "Juticalpa",
    city_code: "JUT",
    airport_name: "Juticalpa",
    country: "Honduras",
  },
  {
    sr: 8030,
    city: "Juzhou",
    city_code: "JUZ",
    airport_name: "Juzhou",
    country: "China",
  },
  {
    sr: 8032,
    city: "Jwaneng",
    city_code: "JWA",
    airport_name: "Jwaneng",
    country: "Botswana",
  },
  {
    sr: 8034,
    city: "Jyvaskyla",
    city_code: "JYV",
    airport_name: "Jyvaskyla",
    country: "Finland",
  },
  {
    sr: 8036,
    city: "Kaadedhdhoo",
    city_code: "KDM",
    airport_name: "Kaadedhdhoo",
    country: "Maldives",
  },
  {
    sr: 8038,
    city: "Kabala",
    city_code: "KBA",
    airport_name: "Kabala",
    country: "Sierra Leone",
  },
  {
    sr: 8040,
    city: "Kabalega Falls",
    city_code: "KBG",
    airport_name: "Kabalega Falls",
    country: "Uganda",
  },
  {
    sr: 8042,
    city: "Kabalo",
    city_code: "KBO",
    airport_name: "Kabalo",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 8044,
    city: "Kaben",
    city_code: "KBT",
    airport_name: "Kaben",
    country: "Marshall Islands",
  },
  {
    sr: 8046,
    city: "Kabinda",
    city_code: "KBN",
    airport_name: "Kabinda",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 8048,
    city: "Kabri Dar",
    city_code: "ABK",
    airport_name: "Kabri Dar",
    country: "Ethiopia",
  },
  {
    sr: 8050,
    city: "Kabul",
    city_code: "KBL",
    airport_name: "Khwaja Rawash",
    country: "Afghanistan",
  },
  {
    sr: 8052,
    city: "Kabwum",
    city_code: "KBM",
    airport_name: "Kabwum",
    country: "Papua New Guinea",
  },
  {
    sr: 8054,
    city: "Kadanwari",
    city_code: "KCF",
    airport_name: "Kadanwari",
    country: "Pakistan",
  },
  {
    sr: 8058,
    city: "Kadhdhoo",
    city_code: "KDO",
    airport_name: "Kadhdhoo",
    country: "Maldives",
  },
  {
    sr: 8060,
    city: "Kaduna",
    city_code: "KAD",
    airport_name: "Kaduna",
    country: "Nigeria",
  },
  {
    sr: 8062,
    city: "Kaedi",
    city_code: "KED",
    airport_name: "Kaedi",
    country: "Mauritania",
  },
  {
    sr: 8064,
    city: "Kaele",
    city_code: "KLE",
    airport_name: "Kaele",
    country: "Cameroon",
  },
  {
    sr: 8066,
    city: "Kagau",
    city_code: "KGE",
    airport_name: "Kagau",
    country: "Solomon Islands",
  },
  {
    sr: 8068,
    city: "Kagi",
    city_code: "KGW",
    airport_name: "Kagi",
    country: "Papua New Guinea",
  },
  {
    sr: 8070,
    city: "Kagoshima",
    city_code: "KOJ",
    airport_name: "Kagoshima",
    country: "Japan",
  },
  {
    sr: 8072,
    city: "Kagua",
    city_code: "AGK",
    airport_name: "Kagua",
    country: "Papua New Guinea",
  },
  {
    sr: 8074,
    city: "Kagvik Creek",
    city_code: "KKF",
    airport_name: "Kagvik Creek",
    country: "United States",
  },
  {
    sr: 8076,
    city: "Kahramanmaras",
    city_code: "KCM",
    airport_name: "Kahramanmaras",
    country: "Turkey",
  },
  {
    sr: 8078,
    city: "Kahului",
    city_code: "OGG",
    airport_name: "Kahului",
    country: "United States",
  },
  {
    sr: 8080,
    city: "Kaiapit",
    city_code: "KIA",
    airport_name: "Kaiapit",
    country: "Papua New Guinea",
  },
  {
    sr: 8082,
    city: "Kaieteur",
    city_code: "KAI",
    airport_name: "Kaieteur",
    country: "Guyana",
  },
  {
    sr: 8084,
    city: "Kaikohe",
    city_code: "KKO",
    airport_name: "Kaikohe",
    country: "New Zealand",
  },
  {
    sr: 8086,
    city: "Kaikoura",
    city_code: "KBZ",
    airport_name: "Kaikoura",
    country: "New Zealand",
  },
  {
    sr: 8088,
    city: "Kailashahar",
    city_code: "IXH",
    airport_name: "Kailashahar",
    country: "India",
  },
  {
    sr: 8092,
    city: "Kaimana",
    city_code: "KNG",
    airport_name: "Kaimana",
    country: "Indonesia",
  },
  {
    sr: 8094,
    city: "Kaintiba",
    city_code: "KZF",
    airport_name: "Kaintiba",
    country: "Papua New Guinea",
  },
  {
    sr: 8096,
    city: "Kaiser Lake Ozark",
    city_code: "AIZ",
    airport_name: "Lee C Fine Memorial",
    country: "United States",
  },
  {
    sr: 8098,
    city: "Kaiserslautern",
    city_code: "KLT",
    airport_name: "Kaiserslautern",
    country: "Germany",
  },
  {
    sr: 8100,
    city: "Kaitaia",
    city_code: "KAT",
    airport_name: "Kaitaia",
    country: "New Zealand",
  },
  {
    sr: 8102,
    city: "Kajaani",
    city_code: "KAJ",
    airport_name: "Kajaani",
    country: "Finland",
  },
  {
    sr: 8104,
    city: "Kake",
    city_code: "KAE",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 8106,
    city: "Kakhonak",
    city_code: "KNK",
    airport_name: "Kakhonak",
    country: "United States",
  },
  {
    sr: 8108,
    city: "Kalabo",
    city_code: "KLB",
    airport_name: "Kalabo",
    country: "Zambia",
  },
  {
    sr: 8110,
    city: "Kalakaket",
    city_code: "KKK",
    airport_name: "Kalakaket AFS",
    country: "United States",
  },
  {
    sr: 8112,
    city: "Kalaleh",
    city_code: "KLM",
    airport_name: "Kalaleh",
    country: "Iran",
  },
  {
    sr: 8114,
    city: "Kalamata",
    city_code: "KLX",
    airport_name: "Kalamata",
    country: "Greece",
  },
  {
    sr: 8116,
    city: "Kalamazoo",
    city_code: "AZO",
    airport_name: "Battle Creek Intl",
    country: "United States",
  },
  {
    sr: 8118,
    city: "Kalat",
    city_code: "KBH",
    airport_name: "Kalat",
    country: "Pakistan",
  },
  {
    sr: 8120,
    city: "Kalaupapa",
    city_code: "LUP",
    airport_name: "Kalaupapa",
    country: "United States",
  },
  {
    sr: 8122,
    city: "Kalbarri",
    city_code: "KAX",
    airport_name: "Kalbarri",
    country: "Australia",
  },
  {
    sr: 8126,
    city: "Kalemie",
    city_code: "FMI",
    airport_name: "Kalemie",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 8128,
    city: "Kalemyo",
    city_code: "KMV",
    airport_name: "Kalemyo",
    country: "Myanmar",
  },
  {
    sr: 8130,
    city: "Kalgoorlie",
    city_code: "KGI",
    airport_name: "Kalgoorlie",
    country: "Australia",
  },
  {
    sr: 8132,
    city: "Kalibo",
    city_code: "KLO",
    airport_name: "Kalibo",
    country: "Philippines",
  },
  {
    sr: 8134,
    city: "Kalima",
    city_code: "KLY",
    airport_name: "Kalima",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 8136,
    city: "Kalinin",
    city_code: "KLD",
    airport_name: "Migalovo",
    country: "Russia",
  },
  {
    sr: 8138,
    city: "Kaliningrad",
    city_code: "KGD",
    airport_name: "Kaliningrad",
    country: "Russia",
  },
  {
    sr: 8140,
    city: "Kalispell",
    city_code: "FCA",
    airport_name: "Glacier National Park",
    country: "United States",
  },
  {
    sr: 8142,
    city: "Kalkurung",
    city_code: "KFG",
    airport_name: "Kalkurung",
    country: "Australia",
  },
  {
    sr: 8144,
    city: "Kalmar",
    city_code: "KLR",
    airport_name: "Kalmar",
    country: "Sweden",
  },
  {
    sr: 8146,
    city: "Kalokol",
    city_code: "KLK",
    airport_name: "Kalokol",
    country: "Kenya",
  },
  {
    sr: 8148,
    city: "Kalpowar",
    city_code: "KPP",
    airport_name: "Kalpowar",
    country: "Australia",
  },
  {
    sr: 8150,
    city: "Kalskag",
    city_code: "KLG",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 8152,
    city: "Kaltag",
    city_code: "KAL",
    airport_name: "Kaltag",
    country: "United States",
  },
  {
    sr: 8154,
    city: "Kaluga",
    city_code: "KLF",
    airport_name: "Kaluga",
    country: "Russia",
  },
  {
    sr: 8156,
    city: "Kalumburu",
    city_code: "UBU",
    airport_name: "Kalumburu",
    country: "Australia",
  },
  {
    sr: 8160,
    city: "Kamalpur",
    city_code: "IXQ",
    airport_name: "Kamalpur",
    country: "India",
  },
  {
    sr: 8162,
    city: "Kamaran Downs",
    city_code: "KDS",
    airport_name: "Kamaran Downs",
    country: "Australia",
  },
  {
    sr: 8164,
    city: "Kamaran Is",
    city_code: "KAM",
    airport_name: "Kamaran Is",
    country: "Yemen",
  },
  {
    sr: 8166,
    city: "Kamarang",
    city_code: "KAR",
    airport_name: "Kamarang",
    country: "Guyana",
  },
  {
    sr: 8168,
    city: "Kamarata",
    city_code: "KTV",
    airport_name: "Kamarata",
    country: "Venezuela",
  },
  {
    sr: 8170,
    city: "Kambalda",
    city_code: "KDB",
    airport_name: "Kambalda",
    country: "Australia",
  },
  {
    sr: 8172,
    city: "Kamberatoro",
    city_code: "KDQ",
    airport_name: "Kamberatoro",
    country: "Papua New Guinea",
  },
  {
    sr: 8174,
    city: "Kambuaya",
    city_code: "KBX",
    airport_name: "Kambuaya",
    country: "Indonesia",
  },
  {
    sr: 8176,
    city: "Kamembe",
    city_code: "KME",
    airport_name: "Kamembe",
    country: "Rwanda",
  },
  {
    sr: 8178,
    city: "Kamenets Podolskiy",
    city_code: "KCP",
    airport_name: "Kamenets Podolskiy",
    country: "Ukraine",
  },
  {
    sr: 8180,
    city: "Kameshli",
    city_code: "KAC",
    airport_name: "Kameshli",
    country: "Syria",
  },
  {
    sr: 8182,
    city: "Kamileroi",
    city_code: "KML",
    airport_name: "Kamileroi",
    country: "Australia",
  },
  {
    sr: 8184,
    city: "Kamina",
    city_code: "KMN",
    airport_name: "Kamina",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 8186,
    city: "Kamina",
    city_code: "KMF",
    airport_name: "Kamina",
    country: "Papua New Guinea",
  },
  {
    sr: 8188,
    city: "Kamiraba",
    city_code: "KJU",
    airport_name: "Kamiraba",
    country: "Papua New Guinea",
  },
  {
    sr: 8190,
    city: "Kamloops",
    city_code: "YKA",
    airport_name: "Kamloops",
    country: "Canada",
  },
  {
    sr: 8194,
    city: "Kamphangsaen",
    city_code: "KDT",
    airport_name: "Kamphangsaen",
    country: "Thailand",
  },
  {
    sr: 8196,
    city: "Kampot",
    city_code: "KMT",
    airport_name: "Kampot",
    country: "Cambodia",
  },
  {
    sr: 8198,
    city: "Kamuela",
    city_code: "MUE",
    airport_name: "Kamuela",
    country: "United States",
  },
  {
    sr: 8200,
    city: "Kamulai",
    city_code: "KAQ",
    airport_name: "Kamulai",
    country: "Papua New Guinea",
  },
  {
    sr: 8202,
    city: "Kamur",
    city_code: "KCD",
    airport_name: "Kamur",
    country: "Indonesia",
  },
  {
    sr: 8204,
    city: "Kamusi",
    city_code: "KUY",
    airport_name: "Kamusi Airport",
    country: "United States",
  },
  {
    sr: 8206,
    city: "Kanab",
    city_code: "KNB",
    airport_name: "Kanab",
    country: "United States",
  },
  {
    sr: 8208,
    city: "Kanabea",
    city_code: "KEX",
    airport_name: "Kanabea",
    country: "Papua New Guinea",
  },
  {
    sr: 8210,
    city: "Kanainj",
    city_code: "KNE",
    airport_name: "Kanainj",
    country: "Papua New Guinea",
  },
  {
    sr: 8212,
    city: "Kananga",
    city_code: "KGA",
    airport_name: "Kananga",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 8214,
    city: "Kandahar",
    city_code: "KDH",
    airport_name: "Kandahar",
    country: "Afghanistan",
  },
  {
    sr: 8216,
    city: "Kandavu",
    city_code: "KDV",
    airport_name: "Kandavu",
    country: "Fiji",
  },
  {
    sr: 8218,
    city: "Kandep",
    city_code: "KDP",
    airport_name: "Kandep",
    country: "Papua New Guinea",
  },
  {
    sr: 8220,
    city: "Kandi",
    city_code: "KDC",
    airport_name: "Kandi",
    country: "Benin",
  },
  {
    sr: 8222,
    city: "Kandla",
    city_code: "IXY",
    airport_name: "Kandla",
    country: "India",
  },
  {
    sr: 8224,
    city: "Kandrian",
    city_code: "KDR",
    airport_name: "Kandrian",
    country: "Papua New Guinea",
  },
  {
    sr: 8228,
    city: "Kandy",
    city_code: "KDW",
    airport_name: "Vic Resevour Kandy",
    country: "Sri Lanka",
  },
  {
    sr: 8230,
    city: "Kangaatsiaq",
    city_code: "QPW",
    airport_name: "Heliport",
    country: "Greenland",
  },
  {
    sr: 8232,
    city: "Kangan",
    city_code: "KNR",
    airport_name: "Jam",
    country: "Iran",
  },
  {
    sr: 8234,
    city: "Kangerlussuaq",
    city_code: "SFJ",
    airport_name: "Kangerlussuaq",
    country: "Greenland",
  },
  {
    sr: 8236,
    city: "Kangiqsualujjuaq",
    city_code: "XGR",
    airport_name: "Kangiqsualujjuaq",
    country: "Canada",
  },
  {
    sr: 8238,
    city: "Kangiqsujuaq",
    city_code: "YWB",
    airport_name: "Kangiqsujuaq",
    country: "Canada",
  },
  {
    sr: 8240,
    city: "Kangirsuk",
    city_code: "YKG",
    airport_name: "Kangirsuk",
    country: "Canada",
  },
  {
    sr: 8242,
    city: "Kaniama",
    city_code: "KNM",
    airport_name: "Kaniama",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 8244,
    city: "Kankakee",
    city_code: "IKK",
    airport_name: "Greater Kankakee",
    country: "United States",
  },
  {
    sr: 8246,
    city: "Kankan",
    city_code: "KNN",
    airport_name: "Kankan",
    country: "Guinea",
  },
  {
    sr: 8248,
    city: "Kano",
    city_code: "KAN",
    airport_name: "Aminu Kano Intl Apt",
    country: "Nigeria",
  },
  {
    sr: 8250,
    city: "Kanpur",
    city_code: "KNU",
    airport_name: "Kanpur",
    country: "India",
  },
  {
    sr: 8252,
    city: "Kansas City",
    city_code: "KCK",
    airport_name: "Fairfax Municipal",
    country: "United States",
  },
  {
    sr: 8254,
    city: "Kansas City",
    city_code: "MKC",
    airport_name: "Downtown",
    country: "United States",
  },
  {
    sr: 8256,
    city: "Kansas City",
    city_code: "MCI",
    airport_name: "Kansas City International Airport",
    country: "United States",
  },
  {
    sr: 8258,
    city: "Kansas City",
    city_code: "OJC",
    airport_name: "Johnson Executive",
    country: "United States",
  },
  {
    sr: 8262,
    city: "Kansas City",
    city_code: "JCI",
    airport_name: "Johnson Industrial",
    country: "United States",
  },
  {
    sr: 8264,
    city: "Kantchari",
    city_code: "XKA",
    airport_name: "Kantchari",
    country: "Burkina Faso",
  },
  {
    sr: 8266,
    city: "Kanua",
    city_code: "KTK",
    airport_name: "Kanua",
    country: "Papua New Guinea",
  },
  {
    sr: 8268,
    city: "Kaohsiung",
    city_code: "KHH",
    airport_name: "International",
    country: "Taiwan",
  },
  {
    sr: 8270,
    city: "Kaolack",
    city_code: "KLC",
    airport_name: "Kaolack",
    country: "Senegal",
  },
  {
    sr: 8272,
    city: "Kaoma",
    city_code: "KMZ",
    airport_name: "Kaoma",
    country: "Zambia",
  },
  {
    sr: 8274,
    city: "Kapal",
    city_code: "KPL",
    airport_name: "Kapal",
    country: "Papua New Guinea",
  },
  {
    sr: 8276,
    city: "Lahaina",
    city_code: "JHM",
    airport_name: "Kapalua",
    country: "United States",
  },
  {
    sr: 8278,
    city: "Kapanga",
    city_code: "KAP",
    airport_name: "Kapanga",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 8280,
    city: "Kapit",
    city_code: "KPI",
    airport_name: "Kapit",
    country: "Malaysia",
  },
  {
    sr: 8282,
    city: "Kapuskasing",
    city_code: "YYU",
    airport_name: "Kapuskasing",
    country: "Canada",
  },
  {
    sr: 8284,
    city: "Kar",
    city_code: "KAK",
    airport_name: "Kar",
    country: "Papua New Guinea",
  },
  {
    sr: 8286,
    city: "Kar Kar",
    city_code: "KRX",
    airport_name: "Kar Kar",
    country: "Papua New Guinea",
  },
  {
    sr: 8288,
    city: "Karachi",
    city_code: "KHI",
    airport_name: "Quaid E Azam Intl",
    country: "Pakistan",
  },
  {
    sr: 8290,
    city: "Karaganda",
    city_code: "KGF",
    airport_name: "Karaganda",
    country: "Kazakhstan",
  },
  {
    sr: 8292,
    city: "Karamay",
    city_code: "KRY",
    airport_name: "Karamay",
    country: "China",
  },
  {
    sr: 8296,
    city: "Karanambo",
    city_code: "KRM",
    airport_name: "Karanambo",
    country: "Guyana",
  },
  {
    sr: 8298,
    city: "Karasabai",
    city_code: "KRG",
    airport_name: "Karasabai",
    country: "Guyana",
  },
  {
    sr: 8300,
    city: "Karasburg",
    city_code: "KAS",
    airport_name: "Karasburg",
    country: "Namibia",
  },
  {
    sr: 8302,
    city: "Karato",
    city_code: "KAF",
    airport_name: "Karato",
    country: "Papua New Guinea",
  },
  {
    sr: 8304,
    city: "Karawari",
    city_code: "KRJ",
    airport_name: "Karawari",
    country: "Papua New Guinea",
  },
  {
    sr: 8306,
    city: "Kardjali",
    city_code: "KDG",
    airport_name: "Kardjali",
    country: "Bulgaria",
  },
  {
    sr: 8308,
    city: "Kardla",
    city_code: "KDL",
    airport_name: "Kardla",
    country: "Estonia",
  },
  {
    sr: 8310,
    city: "Kariba",
    city_code: "KAB",
    airport_name: "Kariba",
    country: "Zimbabwe",
  },
  {
    sr: 8312,
    city: "Karimui",
    city_code: "KMR",
    airport_name: "Karimui",
    country: "Papua New Guinea",
  },
  {
    sr: 8314,
    city: "Karimunjawa",
    city_code: "KWB",
    airport_name: "Karimunjawa",
    country: "Indonesia",
  },
  {
    sr: 8316,
    city: "Karlovy Vary",
    city_code: "KLV",
    airport_name: "Karlovy Vary",
    country: "Czechoslovakia  former",
  },
  {
    sr: 8318,
    city: "Karlshamn",
    city_code: "XYO",
    airport_name: "Karlshamn",
    country: "Sweden",
  },
  {
    sr: 8320,
    city: "Karlskoga",
    city_code: "KSK",
    airport_name: "Karlskoga",
    country: "Sweden",
  },
  {
    sr: 8322,
    city: "Karlsruhe Baden Baden",
    city_code: "FKB",
    airport_name: "Soellingen",
    country: "Germany",
  },
  {
    sr: 8324,
    city: "Karlstad",
    city_code: "KSD",
    airport_name: "Karlstad",
    country: "Sweden",
  },
  {
    sr: 8326,
    city: "Karluk",
    city_code: "KYK",
    airport_name: "Karluk",
    country: "United States",
  },
  {
    sr: 8330,
    city: "Karluk Lake",
    city_code: "KKL",
    airport_name: "Karluk Lake SPB",
    country: "United States",
  },
  {
    sr: 8332,
    city: "Karonga",
    city_code: "KGJ",
    airport_name: "Karonga",
    country: "Malawi",
  },
  {
    sr: 8334,
    city: "Karoola",
    city_code: "KXR",
    airport_name: "Karoola",
    country: "Papua New Guinea",
  },
  {
    sr: 8336,
    city: "Karpathos",
    city_code: "AOK",
    airport_name: "Karpathos",
    country: "Greece",
  },
  {
    sr: 8338,
    city: "Karratha",
    city_code: "KTA",
    airport_name: "Karratha",
    country: "Australia",
  },
  {
    sr: 8340,
    city: "Kars",
    city_code: "KSY",
    airport_name: "Kars",
    country: "Turkey",
  },
  {
    sr: 8342,
    city: "Karshi",
    city_code: "KSQ",
    airport_name: "Karshi",
    country: "Uzbekistan",
  },
  {
    sr: 8344,
    city: "Karubaga",
    city_code: "KBF",
    airport_name: "Karubaga",
    country: "Indonesia",
  },
  {
    sr: 8346,
    city: "Karumba",
    city_code: "KRB",
    airport_name: "Karumba",
    country: "Australia",
  },
  {
    sr: 8348,
    city: "Karup",
    city_code: "KRP",
    airport_name: "Karup",
    country: "Denmark",
  },
  {
    sr: 8350,
    city: "Kasaan",
    city_code: "KXA",
    airport_name: "Kasaan SPB",
    country: "United States",
  },
  {
    sr: 8352,
    city: "Kasaba Bay",
    city_code: "ZKB",
    airport_name: "Kasaba Bay",
    country: "Zambia",
  },
  {
    sr: 8354,
    city: "Kasabonika",
    city_code: "XKS",
    airport_name: "Kasabonika",
    country: "Canada",
  },
  {
    sr: 8356,
    city: "Kasama",
    city_code: "KAA",
    airport_name: "Kasama",
    country: "Zambia",
  },
  {
    sr: 8358,
    city: "Kasane",
    city_code: "BBK",
    airport_name: "Kasane",
    country: "Botswana",
  },
  {
    sr: 8360,
    city: "Kasanombe",
    city_code: "KSB",
    airport_name: "Kasanombe",
    country: "Papua New Guinea",
  },
  {
    sr: 8364,
    city: "Kasba Lake",
    city_code: "YDU",
    airport_name: "Kasba Lake",
    country: "Canada",
  },
  {
    sr: 8366,
    city: "Kaschechewan",
    city_code: "ZKE",
    airport_name: "Kaschechewan",
    country: "Canada",
  },
  {
    sr: 8368,
    city: "Kasenga",
    city_code: "KEC",
    airport_name: "Kasenga",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 8370,
    city: "Kasese",
    city_code: "KSE",
    airport_name: "Kasese",
    country: "Uganda",
  },
  {
    sr: 8372,
    city: "Kashi",
    city_code: "KHG",
    airport_name: "Kashi",
    country: "China",
  },
  {
    sr: 8374,
    city: "Kasigluk",
    city_code: "KUK",
    airport_name: "Kasigluk",
    country: "United States",
  },
  {
    sr: 8376,
    city: "Kasikasima",
    city_code: "KCB",
    airport_name: "Tepoe Airstrip",
    country: "Suriname",
  },
  {
    sr: 8378,
    city: "Kasompe",
    city_code: "ZKP",
    airport_name: "Kasompe",
    country: "Zambia",
  },
  {
    sr: 8380,
    city: "Kasongo Lunda",
    city_code: "KGN",
    airport_name: "Kasongo Lunda",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 8382,
    city: "Kasos Island",
    city_code: "KSJ",
    airport_name: "Kasos Island",
    country: "Greece",
  },
  {
    sr: 8384,
    city: "Kassala",
    city_code: "KSL",
    airport_name: "Kassala",
    country: "Sudan",
  },
  {
    sr: 8386,
    city: "Kassel",
    city_code: "KSF",
    airport_name: "Kassel Calden",
    country: "Germany",
  },
  {
    sr: 8388,
    city: "Kastamonu",
    city_code: "KFS",
    airport_name: "Kastamonu",
    country: "Turkey",
  },
  {
    sr: 8390,
    city: "Kastelorizo",
    city_code: "KZS",
    airport_name: "Kastelorizo",
    country: "Greece",
  },
  {
    sr: 8392,
    city: "Kastoria",
    city_code: "KSO",
    airport_name: "Aristoteles Airport",
    country: "Greece",
  },
  {
    sr: 8394,
    city: "Kasungu",
    city_code: "KBQ",
    airport_name: "Kasungu",
    country: "Malawi",
  },
  {
    sr: 8398,
    city: "Katanning",
    city_code: "KNI",
    airport_name: "Katanning",
    country: "Australia",
  },
  {
    sr: 8400,
    city: "Katherine",
    city_code: "KTR",
    airport_name: "Tindal",
    country: "Australia",
  },
  {
    sr: 8402,
    city: "Kathmandu",
    city_code: "KTM",
    airport_name: "Tribhuvan",
    country: "Nepal",
  },
  {
    sr: 8404,
    city: "Katiola",
    city_code: "KTC",
    airport_name: "Katiola",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 8406,
    city: "Katiu",
    city_code: "KXU",
    airport_name: "Katiu",
    country: "French Polynesia",
  },
  {
    sr: 8408,
    city: "Kato",
    city_code: "KTO",
    airport_name: "Kato",
    country: "Guyana",
  },
  {
    sr: 8410,
    city: "Ozarowice",
    city_code: "KTW",
    airport_name: "Pyrzowice",
    country: "Poland",
  },
  {
    sr: 8412,
    city: "Katrineholm",
    city_code: "XXK",
    airport_name: "Katrineholm",
    country: "Sweden",
  },
  {
    sr: 8414,
    city: "Kattiniq",
    city_code: "YAU",
    airport_name: "Kattiniq Donaldson",
    country: "Canada",
  },
  {
    sr: 8416,
    city: "Katugastota",
    city_code: "KDZ",
    airport_name: "Polgolla Reservoir",
    country: "Sri Lanka",
  },
  {
    sr: 8418,
    city: "Katukurunda",
    city_code: "KTY",
    airport_name: "Katukurunda Slaf Base",
    country: "Sri Lanka",
  },
  {
    sr: 8420,
    city: "Kau",
    city_code: "KAZ",
    airport_name: "Kau",
    country: "Indonesia",
  },
  {
    sr: 8422,
    city: "Kauai Island",
    city_code: "LIH",
    airport_name: "Lihue",
    country: "United States",
  },
  {
    sr: 8424,
    city: "Kauai Island",
    city_code: "HPV",
    airport_name: "Princeville",
    country: "United States",
  },
  {
    sr: 8426,
    city: "Kauehi",
    city_code: "KHZ",
    airport_name: "Kauehi",
    country: "French Polynesia",
  },
  {
    sr: 8428,
    city: "Kauhajoki",
    city_code: "KHJ",
    airport_name: "Kauhajoki",
    country: "Finland",
  },
  {
    sr: 8432,
    city: "Kauhava",
    city_code: "KAU",
    airport_name: "Kauhava",
    country: "Finland",
  },
  {
    sr: 8434,
    city: "Kaukura Atoll",
    city_code: "KKR",
    airport_name: "Kaukura Atoll",
    country: "French Polynesia",
  },
  {
    sr: 8436,
    city: "Kaunas",
    city_code: "KUN",
    airport_name: "Kaunas",
    country: "Lithuania",
  },
  {
    sr: 8438,
    city: "Kavala",
    city_code: "KVA",
    airport_name: "Megas Alexandros Apt",
    country: "Greece",
  },
  {
    sr: 8440,
    city: "Kavanayen",
    city_code: "KAV",
    airport_name: "Kavanayen",
    country: "Venezuela",
  },
  {
    sr: 8442,
    city: "Kavieng",
    city_code: "KVG",
    airport_name: "Kavieng",
    country: "Papua New Guinea",
  },
  {
    sr: 8444,
    city: "Kavik",
    city_code: "VIK",
    airport_name: "Airstrip",
    country: "United States",
  },
  {
    sr: 8446,
    city: "Kawadjia",
    city_code: "KWD",
    airport_name: "Kawadjia",
    country: "Central African Republic",
  },
  {
    sr: 8448,
    city: "Kawau Island",
    city_code: "KUI",
    airport_name: "Kawau Island",
    country: "New Zealand",
  },
  {
    sr: 8450,
    city: "Kawito",
    city_code: "KWO",
    airport_name: "Kawito",
    country: "Papua New Guinea",
  },
  {
    sr: 8452,
    city: "Kawthaung",
    city_code: "KAW",
    airport_name: "Kawthaung",
    country: "Myanmar",
  },
  {
    sr: 8454,
    city: "Kaya",
    city_code: "XKY",
    airport_name: "Kaya",
    country: "Burkina Faso",
  },
  {
    sr: 8456,
    city: "Kayenta",
    city_code: "MVM",
    airport_name: "Monument Valley",
    country: "United States",
  },
  {
    sr: 8458,
    city: "Kayes",
    city_code: "KYS",
    airport_name: "Kayes",
    country: "Mali",
  },
  {
    sr: 8460,
    city: "Kayseri",
    city_code: "ASR",
    airport_name: "Erkilet International Airport",
    country: "Turkey",
  },
  {
    sr: 8462,
    city: "Kazan",
    city_code: "KZN",
    airport_name: "Kazan",
    country: "Russia",
  },
  {
    sr: 8466,
    city: "Kearney",
    city_code: "EAR",
    airport_name: "Kearney",
    country: "United States",
  },
  {
    sr: 8468,
    city: "Kebar",
    city_code: "KEQ",
    airport_name: "Kebar",
    country: "Indonesia",
  },
  {
    sr: 8470,
    city: "Kedougou",
    city_code: "KGG",
    airport_name: "Kedougou",
    country: "Senegal",
  },
  {
    sr: 8472,
    city: "Keene",
    city_code: "EEN",
    airport_name: "Dillant Hopkins",
    country: "United States",
  },
  {
    sr: 8474,
    city: "Keetmanshoop",
    city_code: "KMP",
    airport_name: "J G H Van Der Wath",
    country: "Namibia",
  },
  {
    sr: 8476,
    city: "Keewaywin",
    city_code: "KEW",
    airport_name: "Keewaywin",
    country: "Canada",
  },
  {
    sr: 8478,
    city: "Kefallinia",
    city_code: "EFL",
    airport_name: "Argostolion",
    country: "Greece",
  },
  {
    sr: 8480,
    city: "Kegaska",
    city_code: "ZKG",
    airport_name: "Kegaska",
    country: "Canada",
  },
  {
    sr: 8482,
    city: "Keglsugl",
    city_code: "KEG",
    airport_name: "Keglsugl",
    country: "Papua New Guinea",
  },
  {
    sr: 8484,
    city: "Keisah",
    city_code: "KEA",
    airport_name: "Keisah",
    country: "Indonesia",
  },
  {
    sr: 8486,
    city: "Kekaha",
    city_code: "BKH",
    airport_name: "Barking Sands",
    country: "United States",
  },
  {
    sr: 8488,
    city: "Kelafo Callaf",
    city_code: "LFO",
    airport_name: "Kelafo",
    country: "Ethiopia",
  },
  {
    sr: 8490,
    city: "Kelaniya",
    city_code: "KEZ",
    airport_name: "Kelaniya River",
    country: "Sri Lanka",
  },
  {
    sr: 8492,
    city: "Kelanoa",
    city_code: "KNL",
    airport_name: "Kelanoa",
    country: "Papua New Guinea",
  },
  {
    sr: 8494,
    city: "Kelila",
    city_code: "LLN",
    airport_name: "Kelila",
    country: "Indonesia",
  },
  {
    sr: 8496,
    city: "Kelle",
    city_code: "KEE",
    airport_name: "Kelle",
    country: "Congo",
  },
  {
    sr: 8500,
    city: "Kelly Bar",
    city_code: "KEU",
    airport_name: "Kelly Bar",
    country: "United States",
  },
  {
    sr: 8502,
    city: "Kelowna",
    city_code: "YLW",
    airport_name: "Kelowna Airport",
    country: "Canada",
  },
  {
    sr: 8504,
    city: "Kelp Bay",
    city_code: "KLP",
    airport_name: "Kelp Bay",
    country: "United States",
  },
  {
    sr: 8506,
    city: "Kelsey",
    city_code: "KES",
    airport_name: "Kelsey",
    country: "Canada",
  },
  {
    sr: 8508,
    city: "Kelso",
    city_code: "KLS",
    airport_name: "Longview",
    country: "United States",
  },
  {
    sr: 8510,
    city: "Keluang",
    city_code: "KLQ",
    airport_name: "Keluang",
    country: "Indonesia",
  },
  {
    sr: 8512,
    city: "Kemano",
    city_code: "XKO",
    airport_name: "Kemano",
    country: "Canada",
  },
  {
    sr: 8514,
    city: "Kemerer",
    city_code: "EMM",
    airport_name: "Kemerer",
    country: "United States",
  },
  {
    sr: 8516,
    city: "Kemerovo",
    city_code: "KEJ",
    airport_name: "Kemerovo",
    country: "Russia",
  },
  {
    sr: 8518,
    city: "Kemi Tornio",
    city_code: "KEM",
    airport_name: "Kemi Tornio",
    country: "Finland",
  },
  {
    sr: 8520,
    city: "Kempsey",
    city_code: "KPS",
    airport_name: "Kempsey",
    country: "Australia",
  },
  {
    sr: 8522,
    city: "Kenai",
    city_code: "ENA",
    airport_name: "Kenai",
    country: "United States",
  },
  {
    sr: 8524,
    city: "Kendari",
    city_code: "KDI",
    airport_name: "Wolter Monginsidi",
    country: "Indonesia",
  },
  {
    sr: 8526,
    city: "Kenema",
    city_code: "KEN",
    airport_name: "Kenema",
    country: "Sierra Leone",
  },
  {
    sr: 8528,
    city: "Keng Tung",
    city_code: "KET",
    airport_name: "Keng Tung",
    country: "Myanmar",
  },
  {
    sr: 8530,
    city: "Kenieba",
    city_code: "KNZ",
    airport_name: "Kenieba",
    country: "Mali",
  },
  {
    sr: 8534,
    city: "Keningau",
    city_code: "KGU",
    airport_name: "Keningau",
    country: "Malaysia",
  },
  {
    sr: 8536,
    city: "Kenitra",
    city_code: "NNA",
    airport_name: "NAF",
    country: "Morocco",
  },
  {
    sr: 8538,
    city: "Kenmore Air Harbor",
    city_code: "KEH",
    airport_name: "Kenmore Air Harbor",
    country: "United States",
  },
  {
    sr: 8540,
    city: "Kennett",
    city_code: "KNT",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 8542,
    city: "Kennosao Lake",
    city_code: "YKI",
    airport_name: "Kennosao Lake",
    country: "Canada",
  },
  {
    sr: 8544,
    city: "Kenora",
    city_code: "YQK",
    airport_name: "Kenora",
    country: "Canada",
  },
  {
    sr: 8546,
    city: "Kenosha",
    city_code: "ENW",
    airport_name: "Kenosha Regional Airport",
    country: "United States",
  },
  {
    sr: 8548,
    city: "Kentland",
    city_code: "KKT",
    airport_name: "Kentland",
    country: "United States",
  },
  {
    sr: 8550,
    city: "Keokuk",
    city_code: "EOK",
    airport_name: "Keokuk",
    country: "United States",
  },
  {
    sr: 8552,
    city: "Kepi",
    city_code: "KEI",
    airport_name: "Kepi",
    country: "Indonesia",
  },
  {
    sr: 8554,
    city: "Kerama",
    city_code: "KJP",
    airport_name: "Kerama",
    country: "Japan",
  },
  {
    sr: 8556,
    city: "Kerang",
    city_code: "KRA",
    airport_name: "Kerang",
    country: "Australia",
  },
  {
    sr: 8558,
    city: "Kerau",
    city_code: "KRU",
    airport_name: "Kerau",
    country: "Papua New Guinea",
  },
  {
    sr: 8560,
    city: "Kerch",
    city_code: "KHC",
    airport_name: "Kerch",
    country: "Ukraine",
  },
  {
    sr: 8562,
    city: "Kerema",
    city_code: "KMA",
    airport_name: "Kerema",
    country: "Papua New Guinea",
  },
  {
    sr: 8564,
    city: "Kericho",
    city_code: "KEY",
    airport_name: "Kericho",
    country: "Kenya",
  },
  {
    sr: 8568,
    city: "Kerikeri",
    city_code: "KKE",
    airport_name: "Kerikeri",
    country: "New Zealand",
  },
  {
    sr: 8570,
    city: "Kerinci",
    city_code: "KRC",
    airport_name: "Kerinci",
    country: "Indonesia",
  },
  {
    sr: 8572,
    city: "Kerio Valley",
    city_code: "KRV",
    airport_name: "Kerio Valley",
    country: "Kenya",
  },
  {
    sr: 8574,
    city: "Kerkyra",
    city_code: "CFU",
    airport_name: "I  Kapodistrias",
    country: "Greece",
  },
  {
    sr: 8576,
    city: "Kerman",
    city_code: "KER",
    airport_name: "Kerman",
    country: "Iran",
  },
  {
    sr: 8578,
    city: "Kermanshah",
    city_code: "KSH",
    airport_name: "Kermanshah",
    country: "Iran",
  },
  {
    sr: 8580,
    city: "Kerrville",
    city_code: "ERV",
    airport_name: "Kerrville",
    country: "United States",
  },
  {
    sr: 8582,
    city: "Killarney Kerry",
    city_code: "KIR",
    airport_name: "Kerry Airport  Farranfore",
    country: "Ireland",
  },
  {
    sr: 8584,
    city: "Kerteh",
    city_code: "KTE",
    airport_name: "Kerteh",
    country: "Malaysia",
  },
  {
    sr: 8586,
    city: "Keshod",
    city_code: "IXK",
    airport_name: "Keshod",
    country: "India",
  },
  {
    sr: 8588,
    city: "Ketapang",
    city_code: "KTG",
    airport_name: "Ketapang",
    country: "Indonesia",
  },
  {
    sr: 8590,
    city: "Ketchikan",
    city_code: "KTN",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 8592,
    city: "Ketchikan",
    city_code: "WFB",
    airport_name: "Waterfront SPB",
    country: "United States",
  },
  {
    sr: 8594,
    city: "Key Lake",
    city_code: "YKJ",
    airport_name: "Key Lake",
    country: "Canada",
  },
  {
    sr: 8596,
    city: "Key Largo",
    city_code: "KYL",
    airport_name: "Port Largo",
    country: "United States",
  },
  {
    sr: 8598,
    city: "Key West",
    city_code: "EYW",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 8602,
    city: "Key West",
    city_code: "NQX",
    airport_name: "NAS",
    country: "United States",
  },
  {
    sr: 8604,
    city: "Keystone",
    city_code: "QKS",
    airport_name: "Van Service",
    country: "United States",
  },
  {
    sr: 8606,
    city: "Khabarovsk",
    city_code: "KHV",
    airport_name: "Novyy",
    country: "Russia",
  },
  {
    sr: 8608,
    city: "Khajuraho",
    city_code: "HJR",
    airport_name: "Khajuraho",
    country: "India",
  },
  {
    sr: 8610,
    city: "Khamis Mushait",
    city_code: "KMX",
    airport_name: "Khamis Mushait",
    country: "Saudi Arabia",
  },
  {
    sr: 8612,
    city: "Khamti",
    city_code: "KHM",
    airport_name: "Khamti",
    country: "Myanmar",
  },
  {
    sr: 8614,
    city: "Khan Al Baghdadi",
    city_code: "XIH",
    airport_name: "Khan Al Baghdadi",
    country: "Iraq",
  },
  {
    sr: 8616,
    city: "Khaneh",
    city_code: "KHA",
    airport_name: "Khaneh",
    country: "Iran",
  },
  {
    sr: 8618,
    city: "Khanty Mansiysk",
    city_code: "HMA",
    airport_name: "Khanty Mansiysk",
    country: "Russia",
  },
  {
    sr: 8620,
    city: "Kharga",
    city_code: "UVL",
    airport_name: "Kharga",
    country: "Egypt",
  },
  {
    sr: 8622,
    city: "Khark Island",
    city_code: "KHK",
    airport_name: "Khark Island",
    country: "Iran",
  },
  {
    sr: 8624,
    city: "Kharkhorin",
    city_code: "KHR",
    airport_name: "Kharkhorin",
    country: "Mongolia",
  },
  {
    sr: 8626,
    city: "Kharkov",
    city_code: "HRK",
    airport_name: "Kharkov",
    country: "Ukraine",
  },
  {
    sr: 8628,
    city: "Khartoum",
    city_code: "KRT",
    airport_name: "Civil",
    country: "Sudan",
  },
  {
    sr: 8630,
    city: "Khasab",
    city_code: "KHS",
    airport_name: "Khasab",
    country: "Oman",
  },
  {
    sr: 8632,
    city: "Khashm El Girba",
    city_code: "GBU",
    airport_name: "Khashm El Girba",
    country: "Sudan",
  },
  {
    sr: 8636,
    city: "Khatgal",
    city_code: "HTM",
    airport_name: "Khatgal",
    country: "Mongolia",
  },
  {
    sr: 8638,
    city: "Kherson",
    city_code: "KHE",
    airport_name: "Kherson",
    country: "Ukraine",
  },
  {
    sr: 8640,
    city: "Khmelnitskiy",
    city_code: "HMJ",
    airport_name: "Khmelnitskiy",
    country: "Ukraine",
  },
  {
    sr: 8642,
    city: "Khoka Moya",
    city_code: "KHO",
    airport_name: "Khoka Moya",
    country: "South Africa",
  },
  {
    sr: 8644,
    city: "Khon Kaen",
    city_code: "KKC",
    airport_name: "Khon Kaen",
    country: "Thailand",
  },
  {
    sr: 8646,
    city: "Khong",
    city_code: "KOG",
    airport_name: "Khong",
    country: "Laos",
  },
  {
    sr: 8648,
    city: "Khorramabad",
    city_code: "KHD",
    airport_name: "Khorramabad",
    country: "Iran",
  },
  {
    sr: 8650,
    city: "Khost",
    city_code: "KHT",
    airport_name: "Khost",
    country: "Afghanistan",
  },
  {
    sr: 8652,
    city: "Khovd",
    city_code: "HVD",
    airport_name: "Khovd",
    country: "Mongolia",
  },
  {
    sr: 8654,
    city: "Khowai",
    city_code: "IXN",
    airport_name: "Khowai",
    country: "India",
  },
  {
    sr: 8656,
    city: "Khoy",
    city_code: "KHY",
    airport_name: "Khoy",
    country: "Iran",
  },
  {
    sr: 8658,
    city: "Khudzhand",
    city_code: "LBD",
    airport_name: "Khudzhand",
    country: "Tajikistan",
  },
  {
    sr: 8660,
    city: "Khujirt",
    city_code: "HJT",
    airport_name: "Khujirt",
    country: "Mongolia",
  },
  {
    sr: 8662,
    city: "Khulna",
    city_code: "KHL",
    airport_name: "Khulna",
    country: "Bangladesh",
  },
  {
    sr: 8664,
    city: "Khuzdar",
    city_code: "KDD",
    airport_name: "Khuzdar",
    country: "Pakistan",
  },
  {
    sr: 8666,
    city: "Khwahan",
    city_code: "KWH",
    airport_name: "Khwahan",
    country: "Afghanistan",
  },
  {
    sr: 8670,
    city: "Khwai River Lodge",
    city_code: "KHW",
    airport_name: "Khwai River Lodge",
    country: "Botswana",
  },
  {
    sr: 8672,
    city: "Kiana",
    city_code: "IAN",
    airport_name: "Bob Barker Memorial",
    country: "United States",
  },
  {
    sr: 8674,
    city: "Kibuli",
    city_code: "KII",
    airport_name: "Kibuli",
    country: "Papua New Guinea",
  },
  {
    sr: 8676,
    city: "Kiel",
    city_code: "KEL",
    airport_name: "Holtenau",
    country: "Germany",
  },
  {
    sr: 8678,
    city: "Kieta",
    city_code: "KIE",
    airport_name: "Aropa",
    country: "Papua New Guinea",
  },
  {
    sr: 8680,
    city: "Kiev",
    city_code: "KBP",
    airport_name: "Borispol",
    country: "Ukraine",
  },
  {
    sr: 8682,
    city: "Kiev",
    city_code: "QOF",
    airport_name: "Darnitsa Bus Station",
    country: "Ukraine",
  },
  {
    sr: 8684,
    city: "Kiev",
    city_code: "QOH",
    airport_name: "Hotel Rus Bus Station",
    country: "Ukraine",
  },
  {
    sr: 8686,
    city: "Kiev",
    city_code: "IEV",
    airport_name: "Zhulhany",
    country: "Ukraine",
  },
  {
    sr: 8688,
    city: "Kiffa",
    city_code: "KFA",
    airport_name: "Kiffa",
    country: "Mauritania",
  },
  {
    sr: 8690,
    city: "Kigali",
    city_code: "KGL",
    airport_name: "Gregoire Kayibanda",
    country: "Rwanda",
  },
  {
    sr: 8692,
    city: "Kigoma",
    city_code: "TKQ",
    airport_name: "Kigoma",
    country: "Tanzania",
  },
  {
    sr: 8694,
    city: "Kikaiga Shima",
    city_code: "KKX",
    airport_name: "Kikaiga Shima",
    country: "Japan",
  },
  {
    sr: 8696,
    city: "Kikinonda",
    city_code: "KIZ",
    airport_name: "Kikinonda",
    country: "Papua New Guinea",
  },
  {
    sr: 8698,
    city: "Kikori",
    city_code: "KRI",
    airport_name: "Kikori",
    country: "Papua New Guinea",
  },
  {
    sr: 8700,
    city: "Kikwit",
    city_code: "KKW",
    airport_name: "Kikwit",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 8704,
    city: "Kilaguni",
    city_code: "ILU",
    airport_name: "Kilaguni",
    country: "Kenya",
  },
  {
    sr: 8706,
    city: "Kilgore",
    city_code: "GGG",
    airport_name: "Kilgore",
    country: "United States",
  },
  {
    sr: 8708,
    city: "Kili Island",
    city_code: "KIO",
    airport_name: "Kili Island",
    country: "Marshall Islands",
  },
  {
    sr: 8710,
    city: "Kilimanjaro",
    city_code: "JRO",
    airport_name: "Kilimanjaro",
    country: "Tanzania",
  },
  {
    sr: 8712,
    city: "Kilkenny",
    city_code: "KKY",
    airport_name: "Kilkenny",
    country: "Ireland",
  },
  {
    sr: 8714,
    city: "Kill Devil Hills",
    city_code: "FFA",
    airport_name: "First Flight",
    country: "United States",
  },
  {
    sr: 8716,
    city: "Killaloe",
    city_code: "YXI",
    airport_name: "Killaloe",
    country: "Canada",
  },
  {
    sr: 8718,
    city: "Killeen",
    city_code: "HLR",
    airport_name: "Fort Hood AAF",
    country: "United States",
  },
  {
    sr: 8720,
    city: "Killeen",
    city_code: "GRK",
    airport_name: "Gray AAF",
    country: "United States",
  },
  {
    sr: 8722,
    city: "Killeen",
    city_code: "ILE",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 8724,
    city: "Killineq",
    city_code: "XBW",
    airport_name: "Killineq",
    country: "Canada",
  },
  {
    sr: 8726,
    city: "Kilwa",
    city_code: "KIL",
    airport_name: "Kilwa",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 8728,
    city: "Kilwa",
    city_code: "KIY",
    airport_name: "Kilwa",
    country: "Tanzania",
  },
  {
    sr: 8730,
    city: "Kimam",
    city_code: "KMM",
    airport_name: "Kimam",
    country: "Indonesia",
  },
  {
    sr: 8732,
    city: "Kimberley",
    city_code: "YQE",
    airport_name: "Kimberley",
    country: "Canada",
  },
  {
    sr: 8734,
    city: "Kimberley",
    city_code: "KIM",
    airport_name: "Kimberley",
    country: "South Africa",
  },
  {
    sr: 8738,
    city: "Kimberley Downs",
    city_code: "KBD",
    airport_name: "Kimberley Downs",
    country: "Australia",
  },
  {
    sr: 8740,
    city: "Kimmirut Lake Harbour",
    city_code: "YLC",
    airport_name: "Kimmirut",
    country: "Canada",
  },
  {
    sr: 8742,
    city: "Kincardine",
    city_code: "YKD",
    airport_name: "Township Airport",
    country: "Canada",
  },
  {
    sr: 8744,
    city: "Kindamba",
    city_code: "KNJ",
    airport_name: "Kindamba",
    country: "Congo",
  },
  {
    sr: 8746,
    city: "Kindersley",
    city_code: "YKY",
    airport_name: "Kindersley",
    country: "Canada",
  },
  {
    sr: 8748,
    city: "Kindu",
    city_code: "KND",
    airport_name: "Kindu",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 8750,
    city: "King City",
    city_code: "KIC",
    airport_name: "Mesa Del Rey",
    country: "United States",
  },
  {
    sr: 8752,
    city: "King Cove",
    city_code: "KVC",
    airport_name: "King Cove",
    country: "United States",
  },
  {
    sr: 8754,
    city: "King Island",
    city_code: "KNS",
    airport_name: "King Island",
    country: "TASMANIA",
  },
  {
    sr: 8756,
    city: "King Khalid Mil City",
    city_code: "KMC",
    airport_name: "King Khalid Military",
    country: "United States",
  },
  {
    sr: 8758,
    city: "King Of Prussia",
    city_code: "KPD",
    airport_name: "King Of Prussia",
    country: "United States",
  },
  {
    sr: 8760,
    city: "King Salmon",
    city_code: "AKN",
    airport_name: "King Salmon",
    country: "United States",
  },
  {
    sr: 8762,
    city: "Kingaroy",
    city_code: "KGY",
    airport_name: "Kingaroy",
    country: "Australia",
  },
  {
    sr: 8764,
    city: "Kingfisher Lake",
    city_code: "KIF",
    airport_name: "Kingfisher Lake",
    country: "Canada",
  },
  {
    sr: 8766,
    city: "Kingman",
    city_code: "IGM",
    airport_name: "Kingman",
    country: "United States",
  },
  {
    sr: 8768,
    city: "Kings Canyon",
    city_code: "KBJ",
    airport_name: "King Canyon",
    country: "Australia",
  },
  {
    sr: 8772,
    city: "Kings Creek Station",
    city_code: "KCS",
    airport_name: "Kings Creek Station",
    country: "Australia",
  },
  {
    sr: 8774,
    city: "Kings Lynn",
    city_code: "KNF",
    airport_name: "Marham RAF",
    country: "United Kingdom",
  },
  {
    sr: 8776,
    city: "Kingscote",
    city_code: "KGC",
    airport_name: "Kingscote",
    country: "Australia",
  },
  {
    sr: 8778,
    city: "Kingston",
    city_code: "KIN",
    airport_name: "Norman Manley",
    country: "Jamaica",
  },
  {
    sr: 8780,
    city: "Kingston",
    city_code: "KTP",
    airport_name: "Tinson",
    country: "Jamaica",
  },
  {
    sr: 8782,
    city: "Kingston",
    city_code: "YGK",
    airport_name: "Kingston",
    country: "Canada",
  },
  {
    sr: 8784,
    city: "Kingsville",
    city_code: "NQI",
    airport_name: "NAS",
    country: "United States",
  },
  {
    sr: 8786,
    city: "Kinmen",
    city_code: "KNH",
    airport_name: "Shang Yi",
    country: "Taiwan",
  },
  {
    sr: 8788,
    city: "Kinoosao",
    city_code: "KNY",
    airport_name: "Kinoosao",
    country: "Canada",
  },
  {
    sr: 8790,
    city: "Kinston",
    city_code: "ISO",
    airport_name: "Stallings Field",
    country: "United States",
  },
  {
    sr: 8792,
    city: "Kipnuk",
    city_code: "KPN",
    airport_name: "Kipnuk SPB",
    country: "United States",
  },
  {
    sr: 8794,
    city: "Kira",
    city_code: "KIQ",
    airport_name: "Kira",
    country: "Papua New Guinea",
  },
  {
    sr: 8796,
    city: "Kirakira",
    city_code: "IRA",
    airport_name: "Kirakira",
    country: "Solomon Islands",
  },
  {
    sr: 8798,
    city: "Kiri",
    city_code: "KRZ",
    airport_name: "Kiri",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 8800,
    city: "Kirkenes",
    city_code: "KKN",
    airport_name: "Hoeybuktmoen",
    country: "Norway",
  },
  {
    sr: 8802,
    city: "Kirkimbie",
    city_code: "KBB",
    airport_name: "Kirkimbie",
    country: "Australia",
  },
  {
    sr: 8806,
    city: "Kirkland Lake",
    city_code: "YKX",
    airport_name: "Kirkland Lake",
    country: "Canada",
  },
  {
    sr: 8808,
    city: "Kirksville",
    city_code: "IRK",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 8810,
    city: "Kirkuk",
    city_code: "KIK",
    airport_name: "Kirkuk",
    country: "Iraq",
  },
  {
    sr: 8812,
    city: "Kirov",
    city_code: "KVX",
    airport_name: "Kirov",
    country: "Russia",
  },
  {
    sr: 8814,
    city: "Kirovograd",
    city_code: "KGO",
    airport_name: "Kirovograd",
    country: "Ukraine",
  },
  {
    sr: 8816,
    city: "Kirovsk",
    city_code: "KVK",
    airport_name: "Kirovsk",
    country: "Russia",
  },
  {
    sr: 8818,
    city: "Kiruna",
    city_code: "KRN",
    airport_name: "Kiruna",
    country: "Sweden",
  },
  {
    sr: 8820,
    city: "Kirundo",
    city_code: "KRE",
    airport_name: "Kirundo",
    country: "Burundi",
  },
  {
    sr: 8822,
    city: "Kiryat Shmona",
    city_code: "KSW",
    airport_name: "Kiryat Shmona",
    country: "Israel",
  },
  {
    sr: 8824,
    city: "Kisangani",
    city_code: "FKI",
    airport_name: "Kisangani",
    country: "Congo",
  },
  {
    sr: 8826,
    city: "Kisengan",
    city_code: "KSG",
    airport_name: "Kisengan",
    country: "Papua New Guinea",
  },
  {
    sr: 8828,
    city: "Kish Island",
    city_code: "KIH",
    airport_name: "Kish Island",
    country: "Iran",
  },
  {
    sr: 8830,
    city: "Kismayu",
    city_code: "KMU",
    airport_name: "Kismayu",
    country: "Somalia",
  },
  {
    sr: 8832,
    city: "Kissidougou",
    city_code: "KSI",
    airport_name: "Kissidougou",
    country: "Guinea",
  },
  {
    sr: 8834,
    city: "Kissimmee",
    city_code: "ISM",
    airport_name: "Kissimmee Gateway",
    country: "United States",
  },
  {
    sr: 8836,
    city: "Kisumu",
    city_code: "KIS",
    airport_name: "Kisumu",
    country: "Kenya",
  },
  {
    sr: 8840,
    city: "Kita Kyushu",
    city_code: "KKJ",
    airport_name: "Kita Kyushu",
    country: "Japan",
  },
  {
    sr: 8842,
    city: "Kitadaito",
    city_code: "KTD",
    airport_name: "Kitadaito",
    country: "Japan",
  },
  {
    sr: 8844,
    city: "Kitale",
    city_code: "KTL",
    airport_name: "Kitale",
    country: "Kenya",
  },
  {
    sr: 8846,
    city: "Kitava",
    city_code: "KVE",
    airport_name: "Kitava",
    country: "Papua New Guinea",
  },
  {
    sr: 8848,
    city: "Kitchener Waterloo",
    city_code: "YKF",
    airport_name: "Kitchener Waterloo Reg",
    country: "Canada",
  },
  {
    sr: 8850,
    city: "Kitee",
    city_code: "KTQ",
    airport_name: "Kitee",
    country: "Finland",
  },
  {
    sr: 8852,
    city: "Kithira",
    city_code: "KIT",
    airport_name: "Kithira",
    country: "Greece",
  },
  {
    sr: 8854,
    city: "Kitkatla",
    city_code: "YKK",
    airport_name: "Kitkatla",
    country: "Canada",
  },
  {
    sr: 8856,
    city: "Kitoi Bay",
    city_code: "KKB",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 8858,
    city: "Kitsissuarsuit",
    city_code: "QJE",
    airport_name: "Heliport",
    country: "Greenland",
  },
  {
    sr: 8860,
    city: "Kittila",
    city_code: "KTT",
    airport_name: "Kittila",
    country: "Finland",
  },
  {
    sr: 8862,
    city: "Kitwe",
    city_code: "KIW",
    airport_name: "Southdowns",
    country: "Zambia",
  },
  {
    sr: 8864,
    city: "Kitzbuehl",
    city_code: "XJS",
    airport_name: "Kitzbuehl Railway",
    country: "Austria",
  },
  {
    sr: 8866,
    city: "Kitzingen",
    city_code: "KZG",
    airport_name: "Kitzingen",
    country: "Germany",
  },
  {
    sr: 8868,
    city: "Kiunga",
    city_code: "KIU",
    airport_name: "Kiunga",
    country: "Kenya",
  },
  {
    sr: 8870,
    city: "Kiunga",
    city_code: "UNG",
    airport_name: "Kiunga",
    country: "Papua New Guinea",
  },
  {
    sr: 8874,
    city: "Kivalina",
    city_code: "KVL",
    airport_name: "Kivalina",
    country: "United States",
  },
  {
    sr: 8876,
    city: "Kiwai Island",
    city_code: "KWX",
    airport_name: "Kiwai Island",
    country: "Papua New Guinea",
  },
  {
    sr: 8878,
    city: "Kiwayu",
    city_code: "KWY",
    airport_name: "Kiwayu",
    country: "Kenya",
  },
  {
    sr: 8880,
    city: "Kizhuyak",
    city_code: "KZH",
    airport_name: "Kizhuyak",
    country: "United States",
  },
  {
    sr: 8882,
    city: "Klag Bay",
    city_code: "KBK",
    airport_name: "Klag Bay",
    country: "United States",
  },
  {
    sr: 8884,
    city: "Klagenfurt",
    city_code: "KLU",
    airport_name: "Alpe Adria",
    country: "Austria",
  },
  {
    sr: 8886,
    city: "Klaipeda Palanga",
    city_code: "PLQ",
    airport_name: "Palanga International",
    country: "Lithuania",
  },
  {
    sr: 8888,
    city: "Klamath Falls",
    city_code: "LMT",
    airport_name: "Kingsley Field",
    country: "United States",
  },
  {
    sr: 8890,
    city: "Klawock",
    city_code: "KLW",
    airport_name: "Klawock",
    country: "United States",
  },
  {
    sr: 8892,
    city: "Kleinzee",
    city_code: "KLZ",
    airport_name: "Kleinzee",
    country: "South Africa",
  },
  {
    sr: 8894,
    city: "Klemtu",
    city_code: "YKT",
    airport_name: "Klemtu",
    country: "Canada",
  },
  {
    sr: 8896,
    city: "Klerksdorp",
    city_code: "KXE",
    airport_name: "Klerksdorp",
    country: "South Africa",
  },
  {
    sr: 8898,
    city: "Knee Lake",
    city_code: "YKE",
    airport_name: "Knee Lake",
    country: "Canada",
  },
  {
    sr: 8900,
    city: "Knights Inlet",
    city_code: "KNV",
    airport_name: "Knights Inlet",
    country: "Canada",
  },
  {
    sr: 8902,
    city: "Knock",
    city_code: "NOC",
    airport_name: "Ireland West Airport Knock",
    country: "Ireland",
  },
  {
    sr: 8904,
    city: "Medan",
    city_code: "KNO",
    airport_name: "Kualanamu International Airport",
    country: "Indonesia",
  },
  {
    sr: 8908,
    city: "Knoxville",
    city_code: "TYS",
    airport_name: "Mc Ghee Tyson",
    country: "United States",
  },
  {
    sr: 8910,
    city: "Kobe",
    city_code: "UKB",
    airport_name: "Kobe",
    country: "Japan",
  },
  {
    sr: 8912,
    city: "Kobuk",
    city_code: "OBU",
    airport_name: "Kobuk Wien",
    country: "United States",
  },
  {
    sr: 8914,
    city: "Kocaeli",
    city_code: "KCO",
    airport_name: "Cengiz Topel",
    country: "Turkey",
  },
  {
    sr: 8916,
    city: "Kochi",
    city_code: "COK",
    airport_name: "Kochi",
    country: "India",
  },
  {
    sr: 8918,
    city: "Kochi",
    city_code: "KCZ",
    airport_name: "Kochi",
    country: "Japan",
  },
  {
    sr: 8920,
    city: "Kodiak",
    city_code: "ADQ",
    airport_name: "Kodiak Airport",
    country: "United States",
  },
  {
    sr: 8922,
    city: "Kodiak",
    city_code: "WDY",
    airport_name: "Kodiak Fss",
    country: "United States",
  },
  {
    sr: 8924,
    city: "Kodiak",
    city_code: "KDK",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 8926,
    city: "Koethen",
    city_code: "KOQ",
    airport_name: "Koethen",
    country: "Germany",
  },
  {
    sr: 8928,
    city: "Kogalym",
    city_code: "KGP",
    airport_name: "Kogalym International",
    country: "Russia",
  },
  {
    sr: 8930,
    city: "Koggala",
    city_code: "KCT",
    airport_name: "Koggala",
    country: "Sri Lanka",
  },
  {
    sr: 8932,
    city: "Koh Kong",
    city_code: "KKZ",
    airport_name: "Koh Kong",
    country: "Cambodia",
  },
  {
    sr: 8934,
    city: "Koh Samui",
    city_code: "USM",
    airport_name: "Koh Samui",
    country: "Thailand",
  },
  {
    sr: 8936,
    city: "Kohat",
    city_code: "OHT",
    airport_name: "Kohat",
    country: "Pakistan",
  },
  {
    sr: 8938,
    city: "Koinambe",
    city_code: "KMB",
    airport_name: "Koinambe",
    country: "Papua New Guinea",
  },
  {
    sr: 8942,
    city: "Koinghaas",
    city_code: "KIG",
    airport_name: "Koinghaas",
    country: "South Africa",
  },
  {
    sr: 8944,
    city: "Kokkola Pietarsaari",
    city_code: "KOK",
    airport_name: "Kruunupyy",
    country: "Finland",
  },
  {
    sr: 8946,
    city: "Kokoda",
    city_code: "KKD",
    airport_name: "Kokoda",
    country: "Papua New Guinea",
  },
  {
    sr: 8948,
    city: "Kokomo",
    city_code: "OKK",
    airport_name: "Kokomo",
    country: "United States",
  },
  {
    sr: 8950,
    city: "Kokonao",
    city_code: "KOX",
    airport_name: "Kokonao",
    country: "Indonesia",
  },
  {
    sr: 8952,
    city: "Kokoro",
    city_code: "KOR",
    airport_name: "Kokoro",
    country: "Papua New Guinea",
  },
  {
    sr: 8954,
    city: "Kokshetau",
    city_code: "KOV",
    airport_name: "Kokshetau",
    country: "Kazakhstan",
  },
  {
    sr: 8956,
    city: "Kol",
    city_code: "KQL",
    airport_name: "Kol",
    country: "Papua New Guinea",
  },
  {
    sr: 8958,
    city: "Kolda",
    city_code: "KDA",
    airport_name: "Kolda",
    country: "Senegal",
  },
  {
    sr: 8960,
    city: "Kolhapur",
    city_code: "KLH",
    airport_name: "Kolhapur",
    country: "India",
  },
  {
    sr: 8962,
    city: "Kolkata",
    city_code: "CCU",
    airport_name: "Netaji Subhas Chandra",
    country: "India",
  },
  {
    sr: 8964,
    city: "Kolwezi",
    city_code: "KWZ",
    airport_name: "Kolwezi",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 8966,
    city: "Komaio",
    city_code: "KCJ",
    airport_name: "Komaio",
    country: "Papua New Guinea",
  },
  {
    sr: 8968,
    city: "Komako",
    city_code: "HOC",
    airport_name: "Komako",
    country: "Papua New Guinea",
  },
  {
    sr: 8970,
    city: "Komatipoort",
    city_code: "KOF",
    airport_name: "Komatipoort",
    country: "South Africa",
  },
  {
    sr: 8972,
    city: "Komatsu",
    city_code: "KMQ",
    airport_name: "Komatsu",
    country: "Japan",
  },
  {
    sr: 8976,
    city: "Komo Manda",
    city_code: "KOM",
    airport_name: "Komo Manda",
    country: "Papua New Guinea",
  },
  {
    sr: 8978,
    city: "Kompiam",
    city_code: "KPM",
    airport_name: "Kompiam",
    country: "Papua New Guinea",
  },
  {
    sr: 8980,
    city: "Kompong Thom",
    city_code: "KZK",
    airport_name: "Kompong Thom",
    country: "Cambodia",
  },
  {
    sr: 8982,
    city: "Kompong Chhna",
    city_code: "KZC",
    airport_name: "Kompong Chhna",
    country: "Cambodia",
  },
  {
    sr: 8984,
    city: "Komsomolsk Na Amure",
    city_code: "KXK",
    airport_name: "Komsomolsk Na Amure",
    country: "Russia",
  },
  {
    sr: 8986,
    city: "Kon",
    city_code: "KCI",
    airport_name: "Kon",
    country: "Indonesia",
  },
  {
    sr: 8988,
    city: "Kona",
    city_code: "KOA",
    airport_name: "Keahole",
    country: "United States",
  },
  {
    sr: 8990,
    city: "Konawaruk",
    city_code: "KKG",
    airport_name: "Konawaruk",
    country: "Guyana",
  },
  {
    sr: 8992,
    city: "Kondubol",
    city_code: "KPF",
    airport_name: "Kondubol",
    country: "Papua New Guinea",
  },
  {
    sr: 8994,
    city: "Kone",
    city_code: "KNQ",
    airport_name: "Kone",
    country: "New Caledonia",
  },
  {
    sr: 8996,
    city: "Konge",
    city_code: "KGB",
    airport_name: "Konge",
    country: "Papua New Guinea",
  },
  {
    sr: 8998,
    city: "Kongiganak",
    city_code: "KKH",
    airport_name: "Kongiganak",
    country: "United States",
  },
  {
    sr: 9000,
    city: "Kongoboumba",
    city_code: "GKO",
    airport_name: "Kongoboumba",
    country: "Gabon",
  },
  {
    sr: 9002,
    city: "Kongolo",
    city_code: "KOO",
    airport_name: "Kongolo",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 9004,
    city: "Kontum",
    city_code: "KON",
    airport_name: "Kontum",
    country: "Vietnam",
  },
  {
    sr: 9006,
    city: "Konya",
    city_code: "KYA",
    airport_name: "Konya",
    country: "Turkey",
  },
  {
    sr: 9010,
    city: "Koolatah",
    city_code: "KOH",
    airport_name: "Koolatah",
    country: "Australia",
  },
  {
    sr: 9012,
    city: "Koolburra",
    city_code: "KKP",
    airport_name: "Koolburra",
    country: "Australia",
  },
  {
    sr: 9014,
    city: "Koonibba",
    city_code: "KQB",
    airport_name: "Koonibba",
    country: "Australia",
  },
  {
    sr: 9016,
    city: "Kopasker",
    city_code: "OPA",
    airport_name: "Kopasker",
    country: "Iceland",
  },
  {
    sr: 9018,
    city: "Kopiago",
    city_code: "KPA",
    airport_name: "Kopiago",
    country: "Papua New Guinea",
  },
  {
    sr: 9020,
    city: "Koppang",
    city_code: "YVK",
    airport_name: "Railway",
    country: "Norway",
  },
  {
    sr: 9022,
    city: "Korhogo",
    city_code: "HGO",
    airport_name: "Korhogo",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 9024,
    city: "Korla",
    city_code: "KRL",
    airport_name: "Korla",
    country: "China",
  },
  {
    sr: 9026,
    city: "Koro Island",
    city_code: "KXF",
    airport_name: "Koro Island",
    country: "Fiji",
  },
  {
    sr: 9028,
    city: "Koroba",
    city_code: "KDE",
    airport_name: "Koroba",
    country: "Papua New Guinea",
  },
  {
    sr: 9030,
    city: "Korolevu",
    city_code: "KVU",
    airport_name: "Korolevu",
    country: "Fiji",
  },
  {
    sr: 9032,
    city: "Koror",
    city_code: "ROR",
    airport_name: "Airai",
    country: "Palau",
  },
  {
    sr: 9034,
    city: "Kortrijk",
    city_code: "KJK",
    airport_name: "Kortrijk",
    country: "Belgium",
  },
  {
    sr: 9036,
    city: "Kos",
    city_code: "KGS",
    airport_name: "Kos",
    country: "Greece",
  },
  {
    sr: 9038,
    city: "Kosciusko",
    city_code: "OSX",
    airport_name: "Attala County",
    country: "United States",
  },
  {
    sr: 9040,
    city: "Kosice",
    city_code: "KSC",
    airport_name: "Barca",
    country: "Slovak Republic",
  },
  {
    sr: 9044,
    city: "Kosipe",
    city_code: "KSP",
    airport_name: "Kosipe",
    country: "Papua New Guinea",
  },
  {
    sr: 9046,
    city: "Kosrae",
    city_code: "KSA",
    airport_name: "Kosrae",
    country: "Micronesia",
  },
  {
    sr: 9048,
    city: "Kostanay",
    city_code: "KSN",
    airport_name: "Kostanay",
    country: "Kazakhstan",
  },
  {
    sr: 9050,
    city: "Kosti",
    city_code: "KST",
    airport_name: "Kosti",
    country: "Sudan",
  },
  {
    sr: 9052,
    city: "Kostroma",
    city_code: "KMW",
    airport_name: "Kostroma",
    country: "Russia",
  },
  {
    sr: 9054,
    city: "Koszalin",
    city_code: "OSZ",
    airport_name: "Koszalin",
    country: "Poland",
  },
  {
    sr: 9056,
    city: "Kota",
    city_code: "KTU",
    airport_name: "Kota",
    country: "India",
  },
  {
    sr: 9058,
    city: "Kota Bharu",
    city_code: "KBR",
    airport_name: "Pengkalan Chepa",
    country: "Malaysia",
  },
  {
    sr: 9060,
    city: "Kota Kinabalu",
    city_code: "BKI",
    airport_name: "Kota Kinabalu",
    country: "Malaysia",
  },
  {
    sr: 9062,
    city: "Kota Kinabalu",
    city_code: "ZWR",
    airport_name: "Kota Kinabalu Port",
    country: "Malaysia",
  },
  {
    sr: 9064,
    city: "Kota Koli",
    city_code: "KLI",
    airport_name: "Kota Koli",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 9066,
    city: "Kotabangun",
    city_code: "KOD",
    airport_name: "Kotabangun",
    country: "Indonesia",
  },
  {
    sr: 9068,
    city: "Kotabaru",
    city_code: "KBU",
    airport_name: "Kotabaru",
    country: "Indonesia",
  },
  {
    sr: 9070,
    city: "Kotlas",
    city_code: "KSZ",
    airport_name: "Kotlas",
    country: "Russia",
  },
  {
    sr: 9072,
    city: "Kotlik",
    city_code: "KOT",
    airport_name: "Kotlik",
    country: "United States",
  },
  {
    sr: 9074,
    city: "Kotzebue",
    city_code: "OTZ",
    airport_name: "Kotzebue",
    country: "United States",
  },
  {
    sr: 9078,
    city: "Kouba",
    city_code: "KDF",
    airport_name: "Kouba",
    country: "Algeria",
  },
  {
    sr: 9080,
    city: "Koulamoutou",
    city_code: "KOU",
    airport_name: "Koulamoutou",
    country: "Gabon",
  },
  {
    sr: 9082,
    city: "Koumac",
    city_code: "KOC",
    airport_name: "Koumac",
    country: "New Caledonia",
  },
  {
    sr: 9084,
    city: "Koumala",
    city_code: "KOL",
    airport_name: "Koumala",
    country: "Central African Republic",
  },
  {
    sr: 9086,
    city: "Koundara",
    city_code: "SBI",
    airport_name: "Sambailo",
    country: "Guinea",
  },
  {
    sr: 9088,
    city: "Koutaba",
    city_code: "KOB",
    airport_name: "Koutaba",
    country: "Cameroon",
  },
  {
    sr: 9090,
    city: "Koutiala",
    city_code: "KTX",
    airport_name: "Koutiala",
    country: "Mali",
  },
  {
    sr: 9092,
    city: "Kouvola",
    city_code: "QVY",
    airport_name: "Bus Station",
    country: "Finland",
  },
  {
    sr: 9094,
    city: "Kouvola",
    city_code: "UTI",
    airport_name: "Utti",
    country: "Finland",
  },
  {
    sr: 9096,
    city: "Kowanyama",
    city_code: "KWM",
    airport_name: "Kowanyama",
    country: "Australia",
  },
  {
    sr: 9098,
    city: "Koyuk",
    city_code: "KKA",
    airport_name: "Koyuk",
    country: "United States",
  },
  {
    sr: 9100,
    city: "Koyukuk",
    city_code: "KYU",
    airport_name: "Koyukuk",
    country: "United States",
  },
  {
    sr: 9102,
    city: "Kozani",
    city_code: "KZI",
    airport_name: "Philippos Airport",
    country: "Greece",
  },
  {
    sr: 9104,
    city: "Kozhikode",
    city_code: "CCJ",
    airport_name: "Calicut",
    country: "India",
  },
  {
    sr: 9106,
    city: "Krabi",
    city_code: "KBV",
    airport_name: "Krabi",
    country: "Thailand",
  },
  {
    sr: 9108,
    city: "Krakor",
    city_code: "KZD",
    airport_name: "Krakor",
    country: "Cambodia",
  },
  {
    sr: 9112,
    city: "Krakow",
    city_code: "KRK",
    airport_name: "J Paul Ii Balice Intl",
    country: "Poland",
  },
  {
    sr: 9114,
    city: "Kramatorsk",
    city_code: "KRQ",
    airport_name: "Kramatorsk",
    country: "Ukraine",
  },
  {
    sr: 9116,
    city: "Kramfors",
    city_code: "KRF",
    airport_name: "Kramfors",
    country: "Sweden",
  },
  {
    sr: 9118,
    city: "Krasnodar",
    city_code: "KRR",
    airport_name: "Rostov",
    country: "Russia",
  },
  {
    sr: 9120,
    city: "Krasnojarsk",
    city_code: "KJA",
    airport_name: "Krasnojarsk",
    country: "Russia",
  },
  {
    sr: 9122,
    city: "Kratie",
    city_code: "KTI",
    airport_name: "Kratie",
    country: "Cambodia",
  },
  {
    sr: 9124,
    city: "Kremenchug",
    city_code: "KHU",
    airport_name: "Kremenchug",
    country: "Ukraine",
  },
  {
    sr: 9126,
    city: "Kribi",
    city_code: "KBI",
    airport_name: "Kribi",
    country: "Cameroon",
  },
  {
    sr: 9128,
    city: "Kristiansand",
    city_code: "KRS",
    airport_name: "Kjevik",
    country: "Norway",
  },
  {
    sr: 9130,
    city: "Kristiansand",
    city_code: "XKR",
    airport_name: "Kristiansand Rail",
    country: "Norway",
  },
  {
    sr: 9132,
    city: "Kristianstad",
    city_code: "KID",
    airport_name: "Kristianstad",
    country: "Sweden",
  },
  {
    sr: 9134,
    city: "Kristiansund",
    city_code: "KSU",
    airport_name: "Kvernberget",
    country: "Norway",
  },
  {
    sr: 9136,
    city: "Kristinehamn",
    city_code: "XYN",
    airport_name: "Kristinehamn Rail",
    country: "Sweden",
  },
  {
    sr: 9138,
    city: "Krivoy Rog",
    city_code: "KWG",
    airport_name: "Krivoy Rog",
    country: "Ukraine",
  },
  {
    sr: 9140,
    city: "Ksar Es Souk",
    city_code: "SEK",
    airport_name: "Ksar Es Souk",
    country: "Morocco",
  },
  {
    sr: 9142,
    city: "Kuala Lumpur",
    city_code: "KUL",
    airport_name: "Kuala Lumpur International Airport  klia",
    country: "Malaysia",
  },
  {
    sr: 9146,
    city: "Kuala Lumpur",
    city_code: "SZB",
    airport_name: "Sultan Abdul Aziz Shah",
    country: "Malaysia",
  },
  {
    sr: 9148,
    city: "Kuala Terengganu",
    city_code: "TGG",
    airport_name: "Sultan Mahmood",
    country: "Malaysia",
  },
  {
    sr: 9150,
    city: "Kuantan",
    city_code: "KUA",
    airport_name: "Kuantan",
    country: "Malaysia",
  },
  {
    sr: 9152,
    city: "Kuantan",
    city_code: "ZJB",
    airport_name: "Kuantan Port",
    country: "Malaysia",
  },
  {
    sr: 9154,
    city: "Kubin Island",
    city_code: "KUG",
    airport_name: "Kubin Island",
    country: "Australia",
  },
  {
    sr: 9156,
    city: "Kuching",
    city_code: "KCH",
    airport_name: "Kuching",
    country: "Malaysia",
  },
  {
    sr: 9158,
    city: "Kuching",
    city_code: "ZGY",
    airport_name: "Kuching Port",
    country: "Malaysia",
  },
  {
    sr: 9160,
    city: "Kudat",
    city_code: "KUD",
    airport_name: "Kudat",
    country: "Malaysia",
  },
  {
    sr: 9162,
    city: "Kufrah",
    city_code: "AKF",
    airport_name: "Kufrah",
    country: "Libya",
  },
  {
    sr: 9164,
    city: "Kugaaruk",
    city_code: "YBB",
    airport_name: "Kugaaruk",
    country: "Canada",
  },
  {
    sr: 9166,
    city: "Kugluktuk Coppermine",
    city_code: "YCO",
    airport_name: "Kugluktuk",
    country: "Canada",
  },
  {
    sr: 9168,
    city: "Kugururok River",
    city_code: "KUW",
    airport_name: "Kugururok River",
    country: "Papua New Guinea",
  },
  {
    sr: 9170,
    city: "Kuito",
    city_code: "SVP",
    airport_name: "Kuito  Silva Porto",
    country: "Angola",
  },
  {
    sr: 9172,
    city: "Kukundu",
    city_code: "KUE",
    airport_name: "Kukundu",
    country: "Solomon Islands",
  },
  {
    sr: 9174,
    city: "Kulgera",
    city_code: "KGR",
    airport_name: "Kulgera",
    country: "Australia",
  },
  {
    sr: 9176,
    city: "Kulik Lake",
    city_code: "LKK",
    airport_name: "Kulik Lake",
    country: "United States",
  },
  {
    sr: 9180,
    city: "Bhuntar",
    city_code: "KUU",
    airport_name: "Kullu Manali",
    country: "India",
  },
  {
    sr: 9182,
    city: "Kulusuk",
    city_code: "KUS",
    airport_name: "Kulusuk",
    country: "Greenland",
  },
  {
    sr: 9184,
    city: "Kumamoto",
    city_code: "KMJ",
    airport_name: "Kumamoto",
    country: "Japan",
  },
  {
    sr: 9186,
    city: "Kumasi",
    city_code: "KMS",
    airport_name: "Kumasi",
    country: "Ghana",
  },
  {
    sr: 9188,
    city: "Kumejima",
    city_code: "UEO",
    airport_name: "Kumejima",
    country: "Japan",
  },
  {
    sr: 9190,
    city: "Kundiawa",
    city_code: "CMU",
    airport_name: "Chimbu",
    country: "Papua New Guinea",
  },
  {
    sr: 9192,
    city: "Kunduz",
    city_code: "UND",
    airport_name: "Kunduz",
    country: "Afghanistan",
  },
  {
    sr: 9194,
    city: "Kungum",
    city_code: "KGM",
    airport_name: "Kungum",
    country: "Papua New Guinea",
  },
  {
    sr: 9196,
    city: "Kunming",
    city_code: "KMG",
    airport_name: "Kunming",
    country: "China",
  },
  {
    sr: 9198,
    city: "Kununurra",
    city_code: "KNX",
    airport_name: "Kununurra",
    country: "Australia",
  },
  {
    sr: 9200,
    city: "Kuopio",
    city_code: "KUO",
    airport_name: "Kuopio",
    country: "Finland",
  },
  {
    sr: 9202,
    city: "Kuorevesi",
    city_code: "KEV",
    airport_name: "Halli",
    country: "Finland",
  },
  {
    sr: 9204,
    city: "Kupang",
    city_code: "KOE",
    airport_name: "Eltari",
    country: "Indonesia",
  },
  {
    sr: 9206,
    city: "Kuparuk",
    city_code: "UUK",
    airport_name: "Kuparuk",
    country: "United States",
  },
  {
    sr: 9208,
    city: "Kupiano",
    city_code: "KUP",
    airport_name: "Kupiano",
    country: "Papua New Guinea",
  },
  {
    sr: 9210,
    city: "Kuqa",
    city_code: "KCA",
    airport_name: "Kuqa",
    country: "China",
  },
  {
    sr: 9214,
    city: "Kuran O Munjan",
    city_code: "KUR",
    airport_name: "Kuran O Munjan",
    country: "Afghanistan",
  },
  {
    sr: 9216,
    city: "Kuressaare",
    city_code: "URE",
    airport_name: "Kuressaare",
    country: "Estonia",
  },
  {
    sr: 9218,
    city: "Kurgan",
    city_code: "KRO",
    airport_name: "Kurgan",
    country: "Russia",
  },
  {
    sr: 9220,
    city: "Kuri",
    city_code: "KUQ",
    airport_name: "Kuri",
    country: "Papua New Guinea",
  },
  {
    sr: 9222,
    city: "Kuria",
    city_code: "KUC",
    airport_name: "Kuria",
    country: "Kiribati",
  },
  {
    sr: 9224,
    city: "Kursk",
    city_code: "URS",
    airport_name: "Kursk",
    country: "Russia",
  },
  {
    sr: 9226,
    city: "Kuruman",
    city_code: "KMH",
    airport_name: "Kuruman",
    country: "South Africa",
  },
  {
    sr: 9228,
    city: "Kurundi",
    city_code: "KRD",
    airport_name: "Kurundi",
    country: "Australia",
  },
  {
    sr: 9230,
    city: "Kurupung",
    city_code: "KPG",
    airport_name: "Kurupung",
    country: "Guyana",
  },
  {
    sr: 9232,
    city: "Kurwina",
    city_code: "KWV",
    airport_name: "Kurwina",
    country: "Papua New Guinea",
  },
  {
    sr: 9234,
    city: "Kushimoto",
    city_code: "KUJ",
    airport_name: "Kushimoto",
    country: "Japan",
  },
  {
    sr: 9236,
    city: "Kushiro",
    city_code: "KUH",
    airport_name: "Kushiro",
    country: "Japan",
  },
  {
    sr: 9238,
    city: "Kutaisi",
    city_code: "KUT",
    airport_name: "Kopitnari",
    country: "Georgia",
  },
  {
    sr: 9240,
    city: "Kuujjuaq",
    city_code: "YVP",
    airport_name: "Kuujjuaq",
    country: "Canada",
  },
  {
    sr: 9242,
    city: "Kuujjuarapik",
    city_code: "YGW",
    airport_name: "Kuujjuarapik",
    country: "Canada",
  },
  {
    sr: 9244,
    city: "Kuusamo",
    city_code: "KAO",
    airport_name: "Kuusamo",
    country: "Finland",
  },
  {
    sr: 9248,
    city: "Kuwait City",
    city_code: "KWI",
    airport_name: "Kuwait International",
    country: "Kuwait",
  },
  {
    sr: 9250,
    city: "Kuyol",
    city_code: "KUX",
    airport_name: "Kuyol",
    country: "Papua New Guinea",
  },
  {
    sr: 9252,
    city: "Kwai Harbour",
    city_code: "KWR",
    airport_name: "Kwai Harbour",
    country: "Solomon Islands",
  },
  {
    sr: 9254,
    city: "Kwailabesi",
    city_code: "KWS",
    airport_name: "Kwailabesi Aerodrom",
    country: "Solomon Islands",
  },
  {
    sr: 9256,
    city: "Kwajalein",
    city_code: "KWA",
    airport_name: "Kwajalein",
    country: "Marshall Islands",
  },
  {
    sr: 9258,
    city: "Kwajalein Atoll",
    city_code: "EAL",
    airport_name: "Elenak",
    country: "Marshall Islands",
  },
  {
    sr: 9260,
    city: "Kwethluk",
    city_code: "KWT",
    airport_name: "Kwethluk",
    country: "United States",
  },
  {
    sr: 9262,
    city: "Kwigillingok",
    city_code: "KWK",
    airport_name: "Kwigillingok",
    country: "United States",
  },
  {
    sr: 9264,
    city: "Kwun Tong",
    city_code: "KTZ",
    airport_name: "Kwun Tong",
    country: "China",
  },
  {
    sr: 9266,
    city: "Kyaukpyu",
    city_code: "KYP",
    airport_name: "Kyaukpyu",
    country: "Myanmar",
  },
  {
    sr: 9268,
    city: "Kyauktaw",
    city_code: "KYT",
    airport_name: "Kyauktaw",
    country: "Myanmar",
  },
  {
    sr: 9270,
    city: "Kyoto",
    city_code: "UKY",
    airport_name: "Kansai",
    country: "Japan",
  },
  {
    sr: 9272,
    city: "Kyzyl",
    city_code: "KYZ",
    airport_name: "Kyzyl",
    country: "Russia",
  },
  {
    sr: 9274,
    city: "Kzyl Orda",
    city_code: "KZO",
    airport_name: "Kzyl Orda",
    country: "Kazakhstan",
  },
  {
    sr: 9276,
    city: "La Baule",
    city_code: "LBY",
    airport_name: "Montoir",
    country: "France",
  },
  {
    sr: 9278,
    city: "La Ceiba",
    city_code: "LCE",
    airport_name: "Goloson International",
    country: "Honduras",
  },
  {
    sr: 9282,
    city: "La Chorrera",
    city_code: "LCR",
    airport_name: "La Chorrera",
    country: "Colombia",
  },
  {
    sr: 9284,
    city: "La Coloma",
    city_code: "LCL",
    airport_name: "La Coloma",
    country: "Cuba",
  },
  {
    sr: 9286,
    city: "La Coruna",
    city_code: "LCG",
    airport_name: "La Coruna",
    country: "Spain",
  },
  {
    sr: 9288,
    city: "La Crosse",
    city_code: "LSE",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 9290,
    city: "La Cumbre",
    city_code: "LCM",
    airport_name: "La Cumbre",
    country: "Argentina",
  },
  {
    sr: 9292,
    city: "La Desirade",
    city_code: "DSD",
    airport_name: "La Desirade",
    country: "Guadeloupe",
  },
  {
    sr: 9294,
    city: "La Esperanza",
    city_code: "LEZ",
    airport_name: "La Esperanza",
    country: "Honduras",
  },
  {
    sr: 9296,
    city: "La Fria",
    city_code: "LFR",
    airport_name: "La Fria",
    country: "Venezuela",
  },
  {
    sr: 9298,
    city: "La Grande",
    city_code: "LGD",
    airport_name: "La Grande",
    country: "United States",
  },
  {
    sr: 9300,
    city: "La Grande",
    city_code: "YGL",
    airport_name: "La Grande",
    country: "Canada",
  },
  {
    sr: 9302,
    city: "La Grange",
    city_code: "LGC",
    airport_name: "Calloway",
    country: "United States",
  },
  {
    sr: 9304,
    city: "La Guaira",
    city_code: "LAG",
    airport_name: "La Guaira",
    country: "Venezuela",
  },
  {
    sr: 9306,
    city: "La Palma",
    city_code: "PLP",
    airport_name: "La Palma",
    country: "Panama",
  },
  {
    sr: 9308,
    city: "La Palma Del Condado",
    city_code: "NDO",
    airport_name: "La Palma Del Condado",
    country: "Spain",
  },
  {
    sr: 9310,
    city: "La Paz",
    city_code: "LPB",
    airport_name: "El Alto",
    country: "Bolivia",
  },
  {
    sr: 9312,
    city: "La Paz",
    city_code: "LAP",
    airport_name: "Leon",
    country: "Mexico",
  },
  {
    sr: 9316,
    city: "La Pedrera",
    city_code: "LPD",
    airport_name: "La Pedrera",
    country: "Colombia",
  },
  {
    sr: 9318,
    city: "La Plagne",
    city_code: "PLG",
    airport_name: "La Plagne",
    country: "France",
  },
  {
    sr: 9320,
    city: "La Plata",
    city_code: "LPG",
    airport_name: "La Plata",
    country: "Argentina",
  },
  {
    sr: 9322,
    city: "La Primavera",
    city_code: "LPE",
    airport_name: "La Primavera",
    country: "Colombia",
  },
  {
    sr: 9324,
    city: "La Rioja",
    city_code: "IRJ",
    airport_name: "La Rioja",
    country: "Argentina",
  },
  {
    sr: 9326,
    city: "La Roche",
    city_code: "EDM",
    airport_name: "Les Ajoncs",
    country: "France",
  },
  {
    sr: 9328,
    city: "La Rochelle",
    city_code: "LRH",
    airport_name: "Laleu",
    country: "France",
  },
  {
    sr: 9330,
    city: "Punta Cana",
    city_code: "LRM",
    airport_name: "La Romana",
    country: "Dominican Republic",
  },
  {
    sr: 9332,
    city: "La Ronge",
    city_code: "YVC",
    airport_name: "La Ronge",
    country: "Canada",
  },
  {
    sr: 9334,
    city: "La Sarre",
    city_code: "SSQ",
    airport_name: "La Sarre",
    country: "Canada",
  },
  {
    sr: 9336,
    city: "La Serena",
    city_code: "LSC",
    airport_name: "La Florida",
    country: "Chile",
  },
  {
    sr: 9338,
    city: "La Tabatiere",
    city_code: "ZLT",
    airport_name: "La Tabatiere",
    country: "Canada",
  },
  {
    sr: 9340,
    city: "La Tuque",
    city_code: "YLQ",
    airport_name: "La Tuque",
    country: "Canada",
  },
  {
    sr: 9342,
    city: "La Union",
    city_code: "LUI",
    airport_name: "La Union",
    country: "Honduras",
  },
  {
    sr: 9344,
    city: "La Uribe",
    city_code: "LAT",
    airport_name: "La Uribe",
    country: "Colombia",
  },
  {
    sr: 9346,
    city: "La Verne",
    city_code: "POC",
    airport_name: "Brackett Field",
    country: "United States",
  },
  {
    sr: 9350,
    city: "Laarbruch",
    city_code: "LRC",
    airport_name: "R A F",
    country: "Germany",
  },
  {
    sr: 9352,
    city: "Laayoune",
    city_code: "EUN",
    airport_name: "Hassan I",
    country: "Morocco",
  },
  {
    sr: 9354,
    city: "Labasa",
    city_code: "LBS",
    airport_name: "Labasa",
    country: "Fiji",
  },
  {
    sr: 9356,
    city: "Labe",
    city_code: "LEK",
    airport_name: "Labe",
    country: "Guinea",
  },
  {
    sr: 9358,
    city: "Lablab",
    city_code: "LAB",
    airport_name: "Lablab",
    country: "Papua New Guinea",
  },
  {
    sr: 9360,
    city: "Labouchere Bay",
    city_code: "WLB",
    airport_name: "Labouchere Bay",
    country: "United States",
  },
  {
    sr: 9362,
    city: "Labrea",
    city_code: "LBR",
    airport_name: "Labrea",
    country: "Brazil",
  },
  {
    sr: 9364,
    city: "Labuan",
    city_code: "LBU",
    airport_name: "Labuan",
    country: "Malaysia",
  },
  {
    sr: 9366,
    city: "Labuan Bajo",
    city_code: "LBJ",
    airport_name: "Mutiara",
    country: "Indonesia",
  },
  {
    sr: 9368,
    city: "Labuha",
    city_code: "LAH",
    airport_name: "Labuha",
    country: "Indonesia",
  },
  {
    sr: 9370,
    city: "Lac Biche",
    city_code: "YLB",
    airport_name: "Lac Biche",
    country: "Canada",
  },
  {
    sr: 9372,
    city: "Lac Brochet",
    city_code: "XLB",
    airport_name: "Lac Brochet",
    country: "Canada",
  },
  {
    sr: 9374,
    city: "Laconia",
    city_code: "LCI",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 9376,
    city: "Ladouanie",
    city_code: "LDO",
    airport_name: "Ladouanie",
    country: "Suriname",
  },
  {
    sr: 9378,
    city: "Lady Elliot Island",
    city_code: "LYT",
    airport_name: "Lady Elliot Island",
    country: "Australia",
  },
  {
    sr: 9380,
    city: "Ladysmith",
    city_code: "LAY",
    airport_name: "Ladysmith",
    country: "South Africa",
  },
  {
    sr: 9384,
    city: "Lae",
    city_code: "LAE",
    airport_name: "Nadzab",
    country: "Papua New Guinea",
  },
  {
    sr: 9386,
    city: "Lae Island",
    city_code: "LML",
    airport_name: "Lae Island",
    country: "Marshall Islands",
  },
  {
    sr: 9388,
    city: "Laeso Island",
    city_code: "BYR",
    airport_name: "Laeso Airport",
    country: "Denmark",
  },
  {
    sr: 9390,
    city: "Lafayette",
    city_code: "LAF",
    airport_name: "Purdue University",
    country: "United States",
  },
  {
    sr: 9392,
    city: "LaForges",
    city_code: "YLF",
    airport_name: "LaForges",
    country: "Canada",
  },
  {
    sr: 9394,
    city: "Lages",
    city_code: "LAJ",
    airport_name: "Lages",
    country: "Brazil",
  },
  {
    sr: 9396,
    city: "Lago Agrio",
    city_code: "LGQ",
    airport_name: "Lago Agrio",
    country: "Ecuador",
  },
  {
    sr: 9398,
    city: "Lago Argentino",
    city_code: "ING",
    airport_name: "Lago Argentino",
    country: "Argentina",
  },
  {
    sr: 9400,
    city: "Lagos",
    city_code: "LOS",
    airport_name: "Murtala Muhammed",
    country: "Nigeria",
  },
  {
    sr: 9402,
    city: "Lagos de Moreno",
    city_code: "LOM",
    airport_name: "Francisco P V y R",
    country: "Mexico",
  },
  {
    sr: 9404,
    city: "Lagrande 3",
    city_code: "YAR",
    airport_name: "Lagrande 3",
    country: "Canada",
  },
  {
    sr: 9406,
    city: "Lagrande 4",
    city_code: "YAH",
    airport_name: "Lagrande 4",
    country: "Canada",
  },
  {
    sr: 9408,
    city: "Lague",
    city_code: "LCO",
    airport_name: "Lague",
    country: "Congo",
  },
  {
    sr: 9410,
    city: "Lagunillas",
    city_code: "LGY",
    airport_name: "Lagunillas",
    country: "Venezuela",
  },
  {
    sr: 9412,
    city: "Lahad Datu",
    city_code: "LDU",
    airport_name: "Lahad Datu",
    country: "Malaysia",
  },
  {
    sr: 9414,
    city: "Lahore",
    city_code: "LHE",
    airport_name: "Alama Iqbal International",
    country: "Pakistan",
  },
  {
    sr: 9418,
    city: "Lahr",
    city_code: "LHA",
    airport_name: "Lahr",
    country: "Germany",
  },
  {
    sr: 9420,
    city: "Lai",
    city_code: "LTC",
    airport_name: "Lai",
    country: "Chad",
  },
  {
    sr: 9422,
    city: "Laiagam",
    city_code: "LGM",
    airport_name: "Laiagam",
    country: "Papua New Guinea",
  },
  {
    sr: 9424,
    city: "Lake Baringo",
    city_code: "LBN",
    airport_name: "Lake Baringo",
    country: "Kenya",
  },
  {
    sr: 9426,
    city: "Lake Charles",
    city_code: "LCH",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 9428,
    city: "Lake Evella",
    city_code: "LEL",
    airport_name: "Lake Evella",
    country: "Australia",
  },
  {
    sr: 9430,
    city: "Lake Geneva",
    city_code: "XES",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 9432,
    city: "Lake Gregory",
    city_code: "LGE",
    airport_name: "Lake Gregory",
    country: "Australia",
  },
  {
    sr: 9434,
    city: "Lake Havasu City",
    city_code: "HII",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 9436,
    city: "Lake Jackson",
    city_code: "LJN",
    airport_name: "Brazoria County",
    country: "United States",
  },
  {
    sr: 9438,
    city: "Lake Manyara",
    city_code: "LKY",
    airport_name: "Lake Manyara",
    country: "Tanzania",
  },
  {
    sr: 9440,
    city: "Lake Minchumina",
    city_code: "LMA",
    airport_name: "Lake Minchumina",
    country: "United States",
  },
  {
    sr: 9442,
    city: "Lake Murray",
    city_code: "LMY",
    airport_name: "Lake Murray",
    country: "Papua New Guinea",
  },
  {
    sr: 9444,
    city: "Lake Nash",
    city_code: "LNH",
    airport_name: "Lake Nash",
    country: "Australia",
  },
  {
    sr: 9446,
    city: "Lake Placid",
    city_code: "LKP",
    airport_name: "Lake Placid",
    country: "United States",
  },
  {
    sr: 9448,
    city: "Lake Rudolf",
    city_code: "LKU",
    airport_name: "Lake Rudolf",
    country: "Kenya",
  },
  {
    sr: 9452,
    city: "Lakeba",
    city_code: "LKB",
    airport_name: "Lakeba",
    country: "Fiji",
  },
  {
    sr: 9454,
    city: "Lakefield",
    city_code: "LFP",
    airport_name: "Lakefield",
    country: "Australia",
  },
  {
    sr: 9456,
    city: "Lakehurst",
    city_code: "NEL",
    airport_name: "Naec",
    country: "United States",
  },
  {
    sr: 9458,
    city: "Lakeland",
    city_code: "LAL",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 9460,
    city: "Lakeland Downs",
    city_code: "LKD",
    airport_name: "Lakeland Downs",
    country: "Australia",
  },
  {
    sr: 9462,
    city: "Lakeside",
    city_code: "LKS",
    airport_name: "Lakeside",
    country: "United States",
  },
  {
    sr: 9464,
    city: "Lakeview",
    city_code: "LKV",
    airport_name: "Lake County",
    country: "United States",
  },
  {
    sr: 9466,
    city: "Lakota",
    city_code: "LKT",
    airport_name: "Lakota",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 9468,
    city: "Lakselv",
    city_code: "LKL",
    airport_name: "Banak",
    country: "Norway",
  },
  {
    sr: 9470,
    city: "Lalibela",
    city_code: "LLI",
    airport_name: "Lalibela",
    country: "Ethiopia",
  },
  {
    sr: 9472,
    city: "Lalomalava",
    city_code: "LAV",
    airport_name: "Lalomalava",
    country: "Samoa",
  },
  {
    sr: 9474,
    city: "Lamacarena",
    city_code: "LMC",
    airport_name: "Lamacarena",
    country: "Colombia",
  },
  {
    sr: 9476,
    city: "Lamap",
    city_code: "LPM",
    airport_name: "Lamap",
    country: "Vanuatu",
  },
  {
    sr: 9478,
    city: "Lamar",
    city_code: "LAA",
    airport_name: "Lamar Field",
    country: "United States",
  },
  {
    sr: 9480,
    city: "Lamassa",
    city_code: "LMG",
    airport_name: "Lamassa",
    country: "Papua New Guinea",
  },
  {
    sr: 9482,
    city: "Lambarene",
    city_code: "LBQ",
    airport_name: "Lambarene",
    country: "Gabon",
  },
  {
    sr: 9486,
    city: "Lamen Bay",
    city_code: "LNB",
    airport_name: "Lamen Bay",
    country: "Vanuatu",
  },
  {
    sr: 9488,
    city: "Lamerd",
    city_code: "LFM",
    airport_name: "Lamerd",
    country: "Iran",
  },
  {
    sr: 9490,
    city: "Lamezia terme",
    city_code: "SUF",
    airport_name: "S Eufemia",
    country: "Italy",
  },
  {
    sr: 9492,
    city: "Lamidanda",
    city_code: "LDN",
    airport_name: "Lamidanda",
    country: "Nepal",
  },
  {
    sr: 9494,
    city: "Lampang",
    city_code: "LPT",
    airport_name: "Lampang",
    country: "Thailand",
  },
  {
    sr: 9496,
    city: "Lampedusa",
    city_code: "LMP",
    airport_name: "Lampedusa",
    country: "Italy",
  },
  {
    sr: 9498,
    city: "Lamu",
    city_code: "LAU",
    airport_name: "Lamu",
    country: "Kenya",
  },
  {
    sr: 9500,
    city: "Lanai City",
    city_code: "LNY",
    airport_name: "Lanai City",
    country: "United States",
  },
  {
    sr: 9502,
    city: "Lancaster",
    city_code: "RZH",
    airport_name: "Quartz Hill",
    country: "United States",
  },
  {
    sr: 9504,
    city: "Lancaster",
    city_code: "WJF",
    airport_name: "William J Fox",
    country: "United States",
  },
  {
    sr: 9506,
    city: "Lancaster",
    city_code: "LNS",
    airport_name: "Lancaster",
    country: "United States",
  },
  {
    sr: 9508,
    city: "Lancaster",
    city_code: "ZRL",
    airport_name: "Lancaster PA Rail",
    country: "United States",
  },
  {
    sr: 9510,
    city: "Lander",
    city_code: "LND",
    airport_name: "Hunt Field",
    country: "United States",
  },
  {
    sr: 9512,
    city: "Landivisiau",
    city_code: "LDV",
    airport_name: "Landivisiau",
    country: "France",
  },
  {
    sr: 9514,
    city: "Lands End",
    city_code: "LEQ",
    airport_name: "Lands End",
    country: "United Kingdom",
  },
  {
    sr: 9516,
    city: "Landskrona",
    city_code: "JLD",
    airport_name: "Heliport",
    country: "Sweden",
  },
  {
    sr: 9520,
    city: "Langara",
    city_code: "YLA",
    airport_name: "Langara",
    country: "Canada",
  },
  {
    sr: 9522,
    city: "Langeoog",
    city_code: "LGO",
    airport_name: "Langeoog",
    country: "Germany",
  },
  {
    sr: 9524,
    city: "Langgur",
    city_code: "LUV",
    airport_name: "Langgur",
    country: "Indonesia",
  },
  {
    sr: 9526,
    city: "Langimar",
    city_code: "LNM",
    airport_name: "Langimar",
    country: "Papua New Guinea",
  },
  {
    sr: 9528,
    city: "Langkawi",
    city_code: "LGK",
    airport_name: "Langkawi International Airport",
    country: "Malaysia",
  },
  {
    sr: 9530,
    city: "Langley",
    city_code: "YLY",
    airport_name: "Langley Regional",
    country: "Canada",
  },
  {
    sr: 9532,
    city: "Langtang",
    city_code: "LTG",
    airport_name: "Langtang",
    country: "Nepal",
  },
  {
    sr: 9534,
    city: "Lannion",
    city_code: "LAI",
    airport_name: "Servel",
    country: "France",
  },
  {
    sr: 9536,
    city: "Lansdowne",
    city_code: "LDW",
    airport_name: "Lansdowne",
    country: "Australia",
  },
  {
    sr: 9538,
    city: "Lansdowne House",
    city_code: "YLH",
    airport_name: "Lansdowne House",
    country: "Canada",
  },
  {
    sr: 9540,
    city: "Lanseria",
    city_code: "HLA",
    airport_name: "Lanseria",
    country: "South Africa",
  },
  {
    sr: 9542,
    city: "Lansing",
    city_code: "LAN",
    airport_name: "Capital City",
    country: "United States",
  },
  {
    sr: 9544,
    city: "Lanzarote",
    city_code: "ACE",
    airport_name: "Lanzarote",
    country: "Spain",
  },
  {
    sr: 9546,
    city: "Lanzhou",
    city_code: "LHW",
    airport_name: "Lanzhou Airport",
    country: "China",
  },
  {
    sr: 9548,
    city: "Lanzhou",
    city_code: "LZD",
    airport_name: "Lanzhoudong",
    country: "China",
  },
  {
    sr: 9550,
    city: "Lanzhou",
    city_code: "ZGC",
    airport_name: "Zhongchuan",
    country: "China",
  },
  {
    sr: 9554,
    city: "Laoag",
    city_code: "LAO",
    airport_name: "Laoag",
    country: "Philippines",
  },
  {
    sr: 9556,
    city: "Laporte",
    city_code: "LPO",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 9558,
    city: "Lappeenranta",
    city_code: "LPP",
    airport_name: "Lappeenranta",
    country: "Finland",
  },
  {
    sr: 9560,
    city: "Lar",
    city_code: "LRR",
    airport_name: "Lar A P",
    country: "Iran",
  },
  {
    sr: 9562,
    city: "Laramie",
    city_code: "LAR",
    airport_name: "General Brees Field",
    country: "United States",
  },
  {
    sr: 9564,
    city: "Larantuka",
    city_code: "LKA",
    airport_name: "Larantuka",
    country: "Indonesia",
  },
  {
    sr: 9566,
    city: "Laredo",
    city_code: "LRD",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 9568,
    city: "Larisa",
    city_code: "LRA",
    airport_name: "Larisa",
    country: "Greece",
  },
  {
    sr: 9570,
    city: "Larnaca",
    city_code: "LCA",
    airport_name: "Larnaca",
    country: "Cyprus",
  },
  {
    sr: 9572,
    city: "Larsen Bay",
    city_code: "KLN",
    airport_name: "Larsen SPB",
    country: "United States",
  },
  {
    sr: 9574,
    city: "Las Canas",
    city_code: "LCS",
    airport_name: "Las Canas",
    country: "Costa Rica",
  },
  {
    sr: 9576,
    city: "Las Cruces",
    city_code: "LRU",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 9578,
    city: "Las Gaviotas",
    city_code: "LGT",
    airport_name: "Las Gaviotas",
    country: "Colombia",
  },
  {
    sr: 9580,
    city: "Las Heras",
    city_code: "LHS",
    airport_name: "Las Heras",
    country: "Argentina",
  },
  {
    sr: 9582,
    city: "Las Khoreh",
    city_code: "LKR",
    airport_name: "Las Khoreh",
    country: "Somalia",
  },
  {
    sr: 9584,
    city: "Las Limas",
    city_code: "LLH",
    airport_name: "Las Limas",
    country: "Honduras",
  },
  {
    sr: 9588,
    city: "Las Lomitas",
    city_code: "LLS",
    airport_name: "Las Lomitas",
    country: "Argentina",
  },
  {
    sr: 9590,
    city: "Las Palmas",
    city_code: "LPA",
    airport_name: "Arpt De Gran Canaria",
    country: "Spain",
  },
  {
    sr: 9592,
    city: "Las Piedras",
    city_code: "LSP",
    airport_name: "Josefa Camejo",
    country: "Venezuela",
  },
  {
    sr: 9594,
    city: "Las Tunas",
    city_code: "VTU",
    airport_name: "Las Tunas",
    country: "Cuba",
  },
  {
    sr: 9596,
    city: "Las Vegas",
    city_code: "LVS",
    airport_name: "Las Vegas",
    country: "United States",
  },
  {
    sr: 9598,
    city: "Las Vegas",
    city_code: "HSH",
    airport_name: "Henderson Sky Harbor",
    country: "United States",
  },
  {
    sr: 9600,
    city: "Las Vegas",
    city_code: "LAS",
    airport_name: "Mccarran Intl",
    country: "United States",
  },
  {
    sr: 9602,
    city: "Las Vegas",
    city_code: "LSV",
    airport_name: "Nellis AFB",
    country: "United States",
  },
  {
    sr: 9604,
    city: "Las Vegas",
    city_code: "VGT",
    airport_name: "North Air Terminal",
    country: "United States",
  },
  {
    sr: 9606,
    city: "Lashio",
    city_code: "LSH",
    airport_name: "Lashio",
    country: "Myanmar",
  },
  {
    sr: 9608,
    city: "Lastourville",
    city_code: "LTL",
    airport_name: "Lastourville",
    country: "Gabon",
  },
  {
    sr: 9610,
    city: "Latacunga",
    city_code: "LTX",
    airport_name: "Cotopaxi International",
    country: "Ecuador",
  },
  {
    sr: 9612,
    city: "Latakia",
    city_code: "LTK",
    airport_name: "Latakia",
    country: "Syria",
  },
  {
    sr: 9614,
    city: "Lathrop",
    city_code: "LRO",
    airport_name: "Sharpe AAF",
    country: "United States",
  },
  {
    sr: 9616,
    city: "Lathrop Wells",
    city_code: "LTH",
    airport_name: "Lathrop Wells",
    country: "United States",
  },
  {
    sr: 9618,
    city: "Latrobe",
    city_code: "LBE",
    airport_name: "Westmoreland County",
    country: "United States",
  },
  {
    sr: 9622,
    city: "Latrobe",
    city_code: "LTB",
    airport_name: "Latrobe",
    country: "Australia",
  },
  {
    sr: 9624,
    city: "Laucala Is",
    city_code: "LUC",
    airport_name: "Laucala Is",
    country: "Fiji",
  },
  {
    sr: 9626,
    city: "Launceston",
    city_code: "LST",
    airport_name: "Launceston",
    country: "TASMANIA",
  },
  {
    sr: 9628,
    city: "Laura",
    city_code: "LUU",
    airport_name: "Laura",
    country: "Australia",
  },
  {
    sr: 9630,
    city: "Laura Station",
    city_code: "LUT",
    airport_name: "Laura Station",
    country: "Australia",
  },
  {
    sr: 9632,
    city: "Laurel",
    city_code: "PIB",
    airport_name: "Hattiesburg Laurel Reg",
    country: "United States",
  },
  {
    sr: 9634,
    city: "Laurel",
    city_code: "LUL",
    airport_name: "Hesler Noble Field",
    country: "United States",
  },
  {
    sr: 9636,
    city: "Laurie River",
    city_code: "LRQ",
    airport_name: "Laurie River",
    country: "Canada",
  },
  {
    sr: 9638,
    city: "Lauterach",
    city_code: "QLX",
    airport_name: "Lauterach",
    country: "Austria",
  },
  {
    sr: 9640,
    city: "Laval",
    city_code: "LVA",
    airport_name: "Entrammes",
    country: "France",
  },
  {
    sr: 9642,
    city: "Lavan",
    city_code: "LVP",
    airport_name: "Lavan",
    country: "Iran",
  },
  {
    sr: 9644,
    city: "Laverton",
    city_code: "LVO",
    airport_name: "Laverton",
    country: "Australia",
  },
  {
    sr: 9646,
    city: "Lawas",
    city_code: "LWY",
    airport_name: "Lawas",
    country: "Malaysia",
  },
  {
    sr: 9648,
    city: "Lawn Hill",
    city_code: "LWH",
    airport_name: "Lawn Hill",
    country: "Australia",
  },
  {
    sr: 9650,
    city: "Lawrence",
    city_code: "LWC",
    airport_name: "Lawrence",
    country: "United States",
  },
  {
    sr: 9652,
    city: "Lawrence",
    city_code: "LWM",
    airport_name: "Lawrence",
    country: "United States",
  },
  {
    sr: 9656,
    city: "Lawrenceville",
    city_code: "LWV",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 9658,
    city: "Lawrenceville",
    city_code: "LVL",
    airport_name: "Lawrenceville",
    country: "United States",
  },
  {
    sr: 9660,
    city: "Lawton",
    city_code: "LAW",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 9662,
    city: "Lazaro Cardenas",
    city_code: "LZC",
    airport_name: "Lazaro Cardenas",
    country: "Mexico",
  },
  {
    sr: 9664,
    city: "Le Castellet",
    city_code: "CTT",
    airport_name: "Le Castellet",
    country: "France",
  },
  {
    sr: 9666,
    city: "Le Havre",
    city_code: "LEH",
    airport_name: "Octeville",
    country: "France",
  },
  {
    sr: 9668,
    city: "Le Mans",
    city_code: "LME",
    airport_name: "Arnage",
    country: "France",
  },
  {
    sr: 9670,
    city: "Le Puy",
    city_code: "LPY",
    airport_name: "Loudes",
    country: "France",
  },
  {
    sr: 9672,
    city: "Le Touquet",
    city_code: "LTQ",
    airport_name: "Le Touquet",
    country: "France",
  },
  {
    sr: 9674,
    city: "Leadville",
    city_code: "LXV",
    airport_name: "Leadville",
    country: "United States",
  },
  {
    sr: 9676,
    city: "Leaf Bay",
    city_code: "XLF",
    airport_name: "Leaf Bay",
    country: "Canada",
  },
  {
    sr: 9678,
    city: "Leaf Rapids",
    city_code: "YLR",
    airport_name: "Leaf Rapids",
    country: "Canada",
  },
  {
    sr: 9680,
    city: "Learmonth",
    city_code: "LEA",
    airport_name: "Learmonth",
    country: "Australia",
  },
  {
    sr: 9682,
    city: "Lebakeng",
    city_code: "LEF",
    airport_name: "Lebakeng",
    country: "Lesotho",
  },
  {
    sr: 9684,
    city: "Lebel Sur Quevillon",
    city_code: "YLS",
    airport_name: "Lebel Sur Quevillon",
    country: "Canada",
  },
  {
    sr: 9686,
    city: "Lecce",
    city_code: "LCC",
    airport_name: "Galatina",
    country: "Italy",
  },
  {
    sr: 9690,
    city: "Leconi",
    city_code: "LEO",
    airport_name: "Leconi",
    country: "Gabon",
  },
  {
    sr: 9692,
    city: "Leeds",
    city_code: "LBA",
    airport_name: "Leeds Bradford",
    country: "United Kingdom",
  },
  {
    sr: 9694,
    city: "Leesburg",
    city_code: "LEE",
    airport_name: "Leesburg",
    country: "United States",
  },
  {
    sr: 9696,
    city: "Leeuwarden",
    city_code: "LWR",
    airport_name: "Leeuwarden",
    country: "Netherlands",
  },
  {
    sr: 9698,
    city: "Legazpi",
    city_code: "LGP",
    airport_name: "Legazpi",
    country: "Philippines",
  },
  {
    sr: 9700,
    city: "Leguizamo",
    city_code: "LGZ",
    airport_name: "Leguizamo",
    country: "Colombia",
  },
  {
    sr: 9702,
    city: "Leh",
    city_code: "IXL",
    airport_name: "Bakula Rimpoche",
    country: "India",
  },
  {
    sr: 9704,
    city: "Lehu",
    city_code: "LHP",
    airport_name: "Lehu",
    country: "Papua New Guinea",
  },
  {
    sr: 9706,
    city: "Leiden",
    city_code: "LID",
    airport_name: "Valkenburg",
    country: "Netherlands",
  },
  {
    sr: 9708,
    city: "Leigh Creek",
    city_code: "LGH",
    airport_name: "Leigh Creek",
    country: "Australia",
  },
  {
    sr: 9710,
    city: "Leinster",
    city_code: "LER",
    airport_name: "Leinster",
    country: "Australia",
  },
  {
    sr: 9712,
    city: "Leipzig Halle",
    city_code: "LEJ",
    airport_name: "Leipzig Halle",
    country: "Germany",
  },
  {
    sr: 9714,
    city: "Leitre",
    city_code: "LTF",
    airport_name: "Leitre",
    country: "Papua New Guinea",
  },
  {
    sr: 9716,
    city: "Lekana",
    city_code: "LKC",
    airport_name: "Lekana",
    country: "Congo",
  },
  {
    sr: 9718,
    city: "Leknes",
    city_code: "LKN",
    airport_name: "Leknes",
    country: "Norway",
  },
  {
    sr: 9720,
    city: "Lelystad",
    city_code: "LEY",
    airport_name: "Lelystad",
    country: "Netherlands",
  },
  {
    sr: 9724,
    city: "Lemars",
    city_code: "LRJ",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 9726,
    city: "Lemmon",
    city_code: "LEM",
    airport_name: "Lemmon",
    country: "United States",
  },
  {
    sr: 9728,
    city: "Lemoore",
    city_code: "NLC",
    airport_name: "NAS Reeves Field",
    country: "United States",
  },
  {
    sr: 9730,
    city: "Lemwerder",
    city_code: "XLW",
    airport_name: "Lemwerder",
    country: "Germany",
  },
  {
    sr: 9732,
    city: "Lencois",
    city_code: "LEC",
    airport_name: "Chapada Diamantina",
    country: "Brazil",
  },
  {
    sr: 9734,
    city: "Lengbati",
    city_code: "LNC",
    airport_name: "Lengbati",
    country: "Papua New Guinea",
  },
  {
    sr: 9736,
    city: "Leo",
    city_code: "XLU",
    airport_name: "Leo",
    country: "Burkina Faso",
  },
  {
    sr: 9738,
    city: "Leon",
    city_code: "LEN",
    airport_name: "Aeropuero de Bajio",
    country: "Spain",
  },
  {
    sr: 9740,
    city: "Leon Guanajuato",
    city_code: "BJX",
    airport_name: "Del Bajio",
    country: "Mexico",
  },
  {
    sr: 9742,
    city: "Leonardtown",
    city_code: "LTW",
    airport_name: "St Marys County",
    country: "United States",
  },
  {
    sr: 9744,
    city: "Leonora",
    city_code: "LNO",
    airport_name: "Leonora",
    country: "Australia",
  },
  {
    sr: 9746,
    city: "Leopoldina",
    city_code: "LEP",
    airport_name: "Leopoldina",
    country: "Brazil",
  },
  {
    sr: 9748,
    city: "Lereh",
    city_code: "LHI",
    airport_name: "Lereh",
    country: "Indonesia",
  },
  {
    sr: 9750,
    city: "Leribe",
    city_code: "LRB",
    airport_name: "Leribe",
    country: "Lesotho",
  },
  {
    sr: 9752,
    city: "Leron Plains",
    city_code: "LPN",
    airport_name: "Leron Plains",
    country: "Papua New Guinea",
  },
  {
    sr: 9754,
    city: "Leros",
    city_code: "LRS",
    airport_name: "Leros",
    country: "Greece",
  },
  {
    sr: 9758,
    city: "Les Cayes",
    city_code: "CYA",
    airport_name: "Les Cayes",
    country: "Haiti",
  },
  {
    sr: 9760,
    city: "Les Sables",
    city_code: "LSO",
    airport_name: "Talmont",
    country: "France",
  },
  {
    sr: 9762,
    city: "Lese",
    city_code: "LNG",
    airport_name: "Lese",
    country: "Papua New Guinea",
  },
  {
    sr: 9764,
    city: "Leshukonskoye",
    city_code: "LDG",
    airport_name: "Leshukonskoye",
    country: "Russia",
  },
  {
    sr: 9766,
    city: "Lesobeng",
    city_code: "LES",
    airport_name: "Lesobeng",
    country: "Lesotho",
  },
  {
    sr: 9768,
    city: "Lethbridge",
    city_code: "YQL",
    airport_name: "Lethbridge",
    country: "Canada",
  },
  {
    sr: 9770,
    city: "Lethem",
    city_code: "LTM",
    airport_name: "Lethem",
    country: "Guyana",
  },
  {
    sr: 9772,
    city: "Leticia",
    city_code: "LET",
    airport_name: "Gen A V Cobo",
    country: "Colombia",
  },
  {
    sr: 9774,
    city: "Letterkenny",
    city_code: "LTR",
    airport_name: "Letterkenny",
    country: "Ireland",
  },
  {
    sr: 9776,
    city: "Levanger",
    city_code: "XUH",
    airport_name: "Levanger Railway",
    country: "Norway",
  },
  {
    sr: 9778,
    city: "Levelock",
    city_code: "KLL",
    airport_name: "Levelock",
    country: "United States",
  },
  {
    sr: 9780,
    city: "Lewisburg",
    city_code: "LWB",
    airport_name: "Greenbrier Valley",
    country: "United States",
  },
  {
    sr: 9782,
    city: "Lewiston",
    city_code: "LWS",
    airport_name: "Nez Perce County Rgnl",
    country: "United States",
  },
  {
    sr: 9784,
    city: "Lewiston",
    city_code: "LEW",
    airport_name: "Auburn",
    country: "United States",
  },
  {
    sr: 9786,
    city: "Lewistown",
    city_code: "LWT",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 9788,
    city: "Lewoleba",
    city_code: "LWE",
    airport_name: "Lewoleba",
    country: "Indonesia",
  },
  {
    sr: 9792,
    city: "Lexington",
    city_code: "LEX",
    airport_name: "Blue Grass",
    country: "United States",
  },
  {
    sr: 9794,
    city: "Lexington",
    city_code: "LSD",
    airport_name: "Creech AAF",
    country: "United States",
  },
  {
    sr: 9796,
    city: "Lexington",
    city_code: "LXN",
    airport_name: "Lexington",
    country: "United States",
  },
  {
    sr: 9798,
    city: "Lhasa",
    city_code: "LXA",
    airport_name: "Lhasa",
    country: "China",
  },
  {
    sr: 9800,
    city: "Lhok Sukon",
    city_code: "LSX",
    airport_name: "Lhok Sukon",
    country: "Indonesia",
  },
  {
    sr: 9802,
    city: "Lhoksumawe",
    city_code: "LSW",
    airport_name: "Lhoksumawe",
    country: "Indonesia",
  },
  {
    sr: 9804,
    city: "Liangping",
    city_code: "LIA",
    airport_name: "Liangping",
    country: "China",
  },
  {
    sr: 9806,
    city: "Lianhuashan",
    city_code: "ZIY",
    airport_name: "Lianhuashan Port",
    country: "China",
  },
  {
    sr: 9808,
    city: "Lianyungang",
    city_code: "LYG",
    airport_name: "Lianyungang",
    country: "China",
  },
  {
    sr: 9810,
    city: "Libenge",
    city_code: "LIE",
    airport_name: "Libenge",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 9812,
    city: "Liberal",
    city_code: "LBL",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 9814,
    city: "Liberia",
    city_code: "LIR",
    airport_name: "Liberia",
    country: "Costa Rica",
  },
  {
    sr: 9816,
    city: "Liboi",
    city_code: "LBK",
    airport_name: "Liboi",
    country: "Kenya",
  },
  {
    sr: 9818,
    city: "Libreville",
    city_code: "LBV",
    airport_name: "Libreville",
    country: "Gabon",
  },
  {
    sr: 9820,
    city: "Lichinga",
    city_code: "VXC",
    airport_name: "Lichinga",
    country: "Mozambique",
  },
  {
    sr: 9822,
    city: "Lidkoping",
    city_code: "LDK",
    airport_name: "Hovby",
    country: "Sweden",
  },
  {
    sr: 9826,
    city: "Liege",
    city_code: "LGG",
    airport_name: "Bierset",
    country: "Belgium",
  },
  {
    sr: 9828,
    city: "Liege",
    city_code: "XHN",
    airport_name: "Guillemins  Raiway Stn",
    country: "Belgium",
  },
  {
    sr: 9830,
    city: "Liepaya",
    city_code: "LPX",
    airport_name: "Liepaya",
    country: "Latvia",
  },
  {
    sr: 9832,
    city: "Lifou",
    city_code: "LIF",
    airport_name: "Lifou",
    country: "New Caledonia",
  },
  {
    sr: 9834,
    city: "Lightning Ridge",
    city_code: "LHG",
    airport_name: "Lightning Ridge",
    country: "Australia",
  },
  {
    sr: 9836,
    city: "Lihir Island",
    city_code: "LNV",
    airport_name: "Lihir Island",
    country: "Papua New Guinea",
  },
  {
    sr: 9838,
    city: "Lijiang City",
    city_code: "LJG",
    airport_name: "Lijiang",
    country: "China",
  },
  {
    sr: 9840,
    city: "Likiep Island",
    city_code: "LIK",
    airport_name: "Likiep Island",
    country: "Marshall Islands",
  },
  {
    sr: 9842,
    city: "Likoma Island",
    city_code: "LIX",
    airport_name: "Likoma",
    country: "Malawi",
  },
  {
    sr: 9844,
    city: "Lilabari",
    city_code: "IXI",
    airport_name: "Lilabari",
    country: "India",
  },
  {
    sr: 9846,
    city: "Lille",
    city_code: "LIL",
    airport_name: "Lesquin",
    country: "France",
  },
  {
    sr: 9848,
    city: "Lille",
    city_code: "XDB",
    airport_name: "Lille Europe Rail Svc",
    country: "France",
  },
  {
    sr: 9850,
    city: "Lille",
    city_code: "XFA",
    airport_name: "Lille Flanders Rail Sv",
    country: "France",
  },
  {
    sr: 9852,
    city: "Lillehammer",
    city_code: "XXL",
    airport_name: "Lillehammer Rail",
    country: "Norway",
  },
  {
    sr: 9854,
    city: "Lillestrom",
    city_code: "XKI",
    airport_name: "Lillestrom Rail Stn",
    country: "Norway",
  },
  {
    sr: 9856,
    city: "Lilongwe",
    city_code: "LLW",
    airport_name: "Lilongwe International",
    country: "Malawi",
  },
  {
    sr: 9860,
    city: "Lima",
    city_code: "AOH",
    airport_name: "Allen County",
    country: "United States",
  },
  {
    sr: 9862,
    city: "Lima",
    city_code: "LIM",
    airport_name: "J Chavez Intl",
    country: "Peru",
  },
  {
    sr: 9864,
    city: "Limbang",
    city_code: "LMN",
    airport_name: "Limbang",
    country: "Malaysia",
  },
  {
    sr: 9866,
    city: "Limbe",
    city_code: "VCC",
    airport_name: "Limbe",
    country: "Cameroon",
  },
  {
    sr: 9868,
    city: "Limbunya",
    city_code: "LIB",
    airport_name: "Limbunya",
    country: "Australia",
  },
  {
    sr: 9870,
    city: "Lime Acres",
    city_code: "LMR",
    airport_name: "Lime Acres",
    country: "South Africa",
  },
  {
    sr: 9872,
    city: "Lime Village",
    city_code: "LVD",
    airport_name: "Lime Village",
    country: "United States",
  },
  {
    sr: 9874,
    city: "Limestone",
    city_code: "LIZ",
    airport_name: "Loring AFB",
    country: "United States",
  },
  {
    sr: 9876,
    city: "Limnos",
    city_code: "LXS",
    airport_name: "Limnos",
    country: "Greece",
  },
  {
    sr: 9878,
    city: "Limoges",
    city_code: "LIG",
    airport_name: "Bellegarde",
    country: "France",
  },
  {
    sr: 9880,
    city: "Limon",
    city_code: "LIC",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 9882,
    city: "Limon",
    city_code: "LIO",
    airport_name: "Limon",
    country: "Costa Rica",
  },
  {
    sr: 9884,
    city: "Limon",
    city_code: "LMH",
    airport_name: "Limon",
    country: "Honduras",
  },
  {
    sr: 9886,
    city: "Lincang",
    city_code: "LNJ",
    airport_name: "Lincang",
    country: "China",
  },
  {
    sr: 9888,
    city: "Lincoln",
    city_code: "LNK",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 9890,
    city: "Lincoln Rock",
    city_code: "LRK",
    airport_name: "Coast Guard",
    country: "United States",
  },
  {
    sr: 9894,
    city: "Linda Downs",
    city_code: "LLP",
    airport_name: "Linda Downs",
    country: "Australia",
  },
  {
    sr: 9896,
    city: "Lindeman Island",
    city_code: "LDC",
    airport_name: "Lindeman Island",
    country: "Australia",
  },
  {
    sr: 9898,
    city: "Linden",
    city_code: "LDJ",
    airport_name: "Linden",
    country: "United States",
  },
  {
    sr: 9900,
    city: "Lindi",
    city_code: "LDI",
    airport_name: "Kikwetu",
    country: "Tanzania",
  },
  {
    sr: 9902,
    city: "Linga Linga",
    city_code: "LGN",
    airport_name: "Linga Linga",
    country: "Papua New Guinea",
  },
  {
    sr: 9904,
    city: "LingLing",
    city_code: "LLF",
    airport_name: "LingLing",
    country: "China",
  },
  {
    sr: 9906,
    city: "Linkoping",
    city_code: "LPI",
    airport_name: "Linkoping",
    country: "Sweden",
  },
  {
    sr: 9908,
    city: "Linosa",
    city_code: "LIU",
    airport_name: "Linosa Heliport",
    country: "Italy",
  },
  {
    sr: 9910,
    city: "Lins",
    city_code: "LIP",
    airport_name: "Lins",
    country: "Brazil",
  },
  {
    sr: 9912,
    city: "Linxi",
    city_code: "LXI",
    airport_name: "Linxi",
    country: "China",
  },
  {
    sr: 9914,
    city: "Linyi",
    city_code: "LYI",
    airport_name: "Linyi",
    country: "China",
  },
  {
    sr: 9916,
    city: "Linz",
    city_code: "LNZ",
    airport_name: "Blue Danube",
    country: "Austria",
  },
  {
    sr: 9918,
    city: "Lipari",
    city_code: "ZIP",
    airport_name: "Harbour  Eolie Island",
    country: "Italy",
  },
  {
    sr: 9920,
    city: "Lipetsk",
    city_code: "LPK",
    airport_name: "Lipetsk",
    country: "Russia",
  },
  {
    sr: 9922,
    city: "Liping City",
    city_code: "HZH",
    airport_name: "Liping",
    country: "China",
  },
  {
    sr: 9924,
    city: "Lisala",
    city_code: "LIQ",
    airport_name: "Lisala",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 9928,
    city: "Lisbon",
    city_code: "LIS",
    airport_name: "Lisboa   Portela",
    country: "Portugal",
  },
  {
    sr: 9930,
    city: "Lisbon",
    city_code: "ZYD",
    airport_name: "Lisbon TP",
    country: "Portugal",
  },
  {
    sr: 9932,
    city: "Lishan",
    city_code: "LHN",
    airport_name: "Lishan",
    country: "Taiwan",
  },
  {
    sr: 9934,
    city: "Lismore",
    city_code: "LSY",
    airport_name: "Lismore",
    country: "Australia",
  },
  {
    sr: 9936,
    city: "Lissadell",
    city_code: "LLL",
    airport_name: "Lissadell",
    country: "Australia",
  },
  {
    sr: 9938,
    city: "Little Cayman",
    city_code: "LYB",
    airport_name: "Little Cayman",
    country: "Cayman Islands",
  },
  {
    sr: 9940,
    city: "Little Grand Rapids",
    city_code: "ZGR",
    airport_name: "Little Grand Rapids",
    country: "Canada",
  },
  {
    sr: 9942,
    city: "Little Naukati",
    city_code: "WLN",
    airport_name: "Little Naukati",
    country: "United States",
  },
  {
    sr: 9944,
    city: "Little Port Walter",
    city_code: "LPW",
    airport_name: "Little Port Walter",
    country: "United States",
  },
  {
    sr: 9946,
    city: "Little Rock",
    city_code: "LIT",
    airport_name: "Regional Airport",
    country: "United States",
  },
  {
    sr: 9948,
    city: "Liuzhou",
    city_code: "LZH",
    airport_name: "Liuzhou",
    country: "China",
  },
  {
    sr: 9950,
    city: "Livengood",
    city_code: "LIV",
    airport_name: "Livengood",
    country: "United States",
  },
  {
    sr: 9952,
    city: "Livermore",
    city_code: "LVK",
    airport_name: "Livermore",
    country: "United States",
  },
  {
    sr: 9954,
    city: "Liverpool",
    city_code: "LPL",
    airport_name: "Liverpool John Lennon  Speke",
    country: "United Kingdom",
  },
  {
    sr: 9956,
    city: "Livingston",
    city_code: "LVM",
    airport_name: "Mission Field",
    country: "United States",
  },
  {
    sr: 9958,
    city: "Livingstone",
    city_code: "LVI",
    airport_name: "Livingstone",
    country: "Zambia",
  },
  {
    sr: 9962,
    city: "Livramento",
    city_code: "LVB",
    airport_name: "Dos Galpoes",
    country: "Brazil",
  },
  {
    sr: 9964,
    city: "Lizard Island",
    city_code: "LZR",
    airport_name: "Lizard Island",
    country: "Australia",
  },
  {
    sr: 9966,
    city: "Ljubljana",
    city_code: "LJU",
    airport_name: "Brnik",
    country: "Slovenia",
  },
  {
    sr: 9968,
    city: "Lloydminster",
    city_code: "YLL",
    airport_name: "Lloydminster",
    country: "Canada",
  },
  {
    sr: 9970,
    city: "Loani",
    city_code: "LNQ",
    airport_name: "Loani",
    country: "Papua New Guinea",
  },
  {
    sr: 9972,
    city: "Lobatse",
    city_code: "LOQ",
    airport_name: "Lobatse",
    country: "Botswana",
  },
  {
    sr: 9974,
    city: "Lochgilphead",
    city_code: "LPH",
    airport_name: "Heliport",
    country: "United Kingdom",
  },
  {
    sr: 9976,
    city: "Lock",
    city_code: "LOC",
    airport_name: "Lock",
    country: "Australia",
  },
  {
    sr: 9978,
    city: "Lock Haven",
    city_code: "LHV",
    airport_name: "W T Piper Memorial",
    country: "United States",
  },
  {
    sr: 9980,
    city: "Lockhart River",
    city_code: "IRG",
    airport_name: "Lockhart River",
    country: "Australia",
  },
  {
    sr: 9982,
    city: "Lockport",
    city_code: "LOT",
    airport_name: "Lewis Lockport",
    country: "United States",
  },
  {
    sr: 9984,
    city: "Lodar",
    city_code: "LDR",
    airport_name: "Lodar",
    country: "Yemen",
  },
  {
    sr: 9986,
    city: "Lodja",
    city_code: "LJA",
    airport_name: "Lodja",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 9988,
    city: "Lodwar",
    city_code: "LOK",
    airport_name: "Lodwar",
    country: "Kenya",
  },
  {
    sr: 9990,
    city: "Lodz",
    city_code: "LCJ",
    airport_name: "Lodz Lublinek",
    country: "Poland",
  },
  {
    sr: 9992,
    city: "Loei",
    city_code: "LOE",
    airport_name: "Loei",
    country: "Thailand",
  },
  {
    sr: 9996,
    city: "Loen",
    city_code: "LOF",
    airport_name: "Loen",
    country: "Marshall Islands",
  },
  {
    sr: 9998,
    city: "Logan",
    city_code: "LGU",
    airport_name: "Cache",
    country: "United States",
  },
  {
    sr: 10000,
    city: "Logrono",
    city_code: "RJL",
    airport_name: "Agoncillo",
    country: "Spain",
  },
  {
    sr: 10002,
    city: "Loikaw",
    city_code: "LIW",
    airport_name: "Loikaw",
    country: "Myanmar",
  },
  {
    sr: 10004,
    city: "Loja",
    city_code: "LOH",
    airport_name: "Loja",
    country: "Ecuador",
  },
  {
    sr: 10006,
    city: "Lokichoggio",
    city_code: "LKG",
    airport_name: "Lokichoggio",
    country: "Kenya",
  },
  {
    sr: 10008,
    city: "Lome",
    city_code: "LFW",
    airport_name: "Lome",
    country: "Togo",
  },
  {
    sr: 10010,
    city: "Lompoc",
    city_code: "LPC",
    airport_name: "Lompoc Airport",
    country: "United States",
  },
  {
    sr: 10012,
    city: "Lompoc",
    city_code: "VBG",
    airport_name: "Vandenberg AFB",
    country: "United States",
  },
  {
    sr: 10014,
    city: "Loncopue",
    city_code: "LCP",
    airport_name: "Loncopue",
    country: "Argentina",
  },
  {
    sr: 10016,
    city: "Londolozi",
    city_code: "LDZ",
    airport_name: "Londolozi",
    country: "South Africa",
  },
  {
    sr: 10018,
    city: "Biggin Hill",
    city_code: "BQH",
    airport_name: "Biggin Hill",
    country: "United Kingdom",
  },
  {
    sr: 10020,
    city: "London",
    city_code: "ZLX",
    airport_name: "British Rail Terminal",
    country: "United Kingdom",
  },
  {
    sr: 10022,
    city: "London",
    city_code: "LGW",
    airport_name: "Gatwick",
    country: "United Kingdom",
  },
  {
    sr: 10024,
    city: "London",
    city_code: "LHR",
    airport_name: "Heathrow",
    country: "United Kingdom",
  },
  {
    sr: 10026,
    city: "London",
    city_code: "LCY",
    airport_name: "London City Airport",
    country: "United Kingdom",
  },
  {
    sr: 10030,
    city: "London",
    city_code: "QQW",
    airport_name: "London Waterloo",
    country: "United Kingdom",
  },
  {
    sr: 10032,
    city: "London",
    city_code: "LTN",
    airport_name: "Luton Airport",
    country: "United Kingdom",
  },
  {
    sr: 10034,
    city: "London",
    city_code: "STN",
    airport_name: "Stansted",
    country: "United Kingdom",
  },
  {
    sr: 10036,
    city: "London",
    city_code: "ZEP",
    airport_name: "Victoria Railway Stn",
    country: "United Kingdom",
  },
  {
    sr: 10038,
    city: "London",
    city_code: "LOZ",
    airport_name: "Corbin London",
    country: "United States",
  },
  {
    sr: 10040,
    city: "Londonderry",
    city_code: "LDY",
    airport_name: "Eglinton  City Of Londonderry",
    country: "United Kingdom",
  },
  {
    sr: 10042,
    city: "Londrina",
    city_code: "LDB",
    airport_name: "Londrina",
    country: "Brazil",
  },
  {
    sr: 10044,
    city: "Lone Rock",
    city_code: "LNR",
    airport_name: "Tri County",
    country: "United States",
  },
  {
    sr: 10046,
    city: "Lonely",
    city_code: "LNI",
    airport_name: "Dew Station",
    country: "United States",
  },
  {
    sr: 10048,
    city: "Long Akah",
    city_code: "LKH",
    airport_name: "Long Akah",
    country: "Malaysia",
  },
  {
    sr: 10050,
    city: "Long Apung",
    city_code: "LPU",
    airport_name: "Long Apung",
    country: "Indonesia",
  },
  {
    sr: 10052,
    city: "Long Banga",
    city_code: "LBP",
    airport_name: "Long Banga Airfield",
    country: "Malaysia",
  },
  {
    sr: 10054,
    city: "Long Bawan",
    city_code: "LBW",
    airport_name: "Long Bawan",
    country: "Indonesia",
  },
  {
    sr: 10056,
    city: "Long Beach",
    city_code: "JLB",
    airport_name: "Heliport",
    country: "United States",
  },
  {
    sr: 10058,
    city: "Long Beach",
    city_code: "LGB",
    airport_name: "Long Beach Municipal",
    country: "United States",
  },
  {
    sr: 10060,
    city: "Long Island",
    city_code: "LIJ",
    airport_name: "Long Island",
    country: "United States",
  },
  {
    sr: 10064,
    city: "Long Island",
    city_code: "LGI",
    airport_name: "Deadmans Cay",
    country: "Bahamas",
  },
  {
    sr: 10066,
    city: "Long Island",
    city_code: "LSJ",
    airport_name: "Long Island",
    country: "Papua New Guinea",
  },
  {
    sr: 10068,
    city: "Long Island",
    city_code: "HAP",
    airport_name: "Long Island",
    country: "Australia",
  },
  {
    sr: 10070,
    city: "Long Lama",
    city_code: "LLM",
    airport_name: "Long Lama",
    country: "Malaysia",
  },
  {
    sr: 10072,
    city: "Long Lellang",
    city_code: "LGL",
    airport_name: "Long Lellang",
    country: "Malaysia",
  },
  {
    sr: 10074,
    city: "Long Pasia",
    city_code: "GSA",
    airport_name: "Long Pasia",
    country: "Malaysia",
  },
  {
    sr: 10076,
    city: "Long Point",
    city_code: "YLX",
    airport_name: "Long Point",
    country: "Canada",
  },
  {
    sr: 10078,
    city: "Long Semado",
    city_code: "LSM",
    airport_name: "Lawas",
    country: "Malaysia",
  },
  {
    sr: 10080,
    city: "Long Seridan",
    city_code: "ODN",
    airport_name: "Long Seridan",
    country: "Malaysia",
  },
  {
    sr: 10082,
    city: "Long Sukang",
    city_code: "LSU",
    airport_name: "Long Sukang",
    country: "Malaysia",
  },
  {
    sr: 10084,
    city: "Long Xuyen",
    city_code: "XLO",
    airport_name: "Long Xuyen",
    country: "Vietnam",
  },
  {
    sr: 10086,
    city: "Longana",
    city_code: "LOD",
    airport_name: "Longana",
    country: "Vanuatu",
  },
  {
    sr: 10088,
    city: "Longreach",
    city_code: "LRE",
    airport_name: "Longreach",
    country: "Australia",
  },
  {
    sr: 10090,
    city: "Longview",
    city_code: "LOG",
    airport_name: "Longview",
    country: "United States",
  },
  {
    sr: 10092,
    city: "Longyan",
    city_code: "LCX",
    airport_name: "Liancheng",
    country: "China",
  },
  {
    sr: 10094,
    city: "Longyearbyen",
    city_code: "LYR",
    airport_name: "Svalbard",
    country: "Norway",
  },
  {
    sr: 10098,
    city: "Lonorore",
    city_code: "LNE",
    airport_name: "Lonorore",
    country: "Vanuatu",
  },
  {
    sr: 10100,
    city: "Lontras",
    city_code: "LOI",
    airport_name: "Helmuth Baungartem",
    country: "Brazil",
  },
  {
    sr: 10102,
    city: "Lop Buri",
    city_code: "KKM",
    airport_name: "Lop Buri",
    country: "Thailand",
  },
  {
    sr: 10104,
    city: "Lopez De Micay",
    city_code: "LMX",
    airport_name: "Lopez De Micay",
    country: "Colombia",
  },
  {
    sr: 10106,
    city: "Lopez Island",
    city_code: "LPS",
    airport_name: "Lopez Island",
    country: "United States",
  },
  {
    sr: 10108,
    city: "Lora Lai",
    city_code: "LRG",
    airport_name: "Lora Lai",
    country: "Pakistan",
  },
  {
    sr: 10110,
    city: "Lord Howe Island",
    city_code: "LDH",
    airport_name: "Lord Howe Island",
    country: "Australia",
  },
  {
    sr: 10112,
    city: "Lordsburg",
    city_code: "LSB",
    airport_name: "Lordsburg",
    country: "United States",
  },
  {
    sr: 10114,
    city: "Loreto",
    city_code: "LTO",
    airport_name: "Loreto",
    country: "Mexico",
  },
  {
    sr: 10116,
    city: "Lorica",
    city_code: "LRI",
    airport_name: "Lorica",
    country: "Colombia",
  },
  {
    sr: 10118,
    city: "Lorient",
    city_code: "LRT",
    airport_name: "Lann Bihoue",
    country: "France",
  },
  {
    sr: 10120,
    city: "Loring",
    city_code: "WLR",
    airport_name: "Loring",
    country: "United States",
  },
  {
    sr: 10122,
    city: "Lorraine",
    city_code: "LOA",
    airport_name: "Lorraine",
    country: "Australia",
  },
  {
    sr: 10124,
    city: "Los Alamos",
    city_code: "LAM",
    airport_name: "Los Alamos",
    country: "United States",
  },
  {
    sr: 10126,
    city: "Los Andes",
    city_code: "LOB",
    airport_name: "Los Andes",
    country: "Chile",
  },
  {
    sr: 10128,
    city: "Los Angeles",
    city_code: "CCD",
    airport_name: "Century City",
    country: "United States",
  },
  {
    sr: 10132,
    city: "Los Angeles",
    city_code: "JCX",
    airport_name: "Citicorp Plaza H P",
    country: "United States",
  },
  {
    sr: 10134,
    city: "Los Angeles",
    city_code: "JID",
    airport_name: "City Of Industry H P",
    country: "United States",
  },
  {
    sr: 10136,
    city: "Los Angeles",
    city_code: "JBP",
    airport_name: "Commerce Bus Plaza",
    country: "United States",
  },
  {
    sr: 10138,
    city: "Los Angeles",
    city_code: "LAX",
    airport_name: "Los Angeles International",
    country: "United States",
  },
  {
    sr: 10140,
    city: "Los Angeles",
    city_code: "JSN",
    airport_name: "Sherman Oaks H P",
    country: "United States",
  },
  {
    sr: 10142,
    city: "Los Angeles",
    city_code: "JLX",
    airport_name: "Union Station H P",
    country: "United States",
  },
  {
    sr: 10144,
    city: "Los Angeles",
    city_code: "JUC",
    airport_name: "Universal City H P",
    country: "United States",
  },
  {
    sr: 10146,
    city: "Los Angeles",
    city_code: "VNY",
    airport_name: "Van Nuys",
    country: "United States",
  },
  {
    sr: 10148,
    city: "Los Angeles",
    city_code: "JWC",
    airport_name: "Warner Cntr Bus Plaza",
    country: "United States",
  },
  {
    sr: 10150,
    city: "Los Angeles",
    city_code: "WHP",
    airport_name: "Whiteman",
    country: "United States",
  },
  {
    sr: 10152,
    city: "Los Angeles",
    city_code: "LSQ",
    airport_name: "Los Angeles",
    country: "Chile",
  },
  {
    sr: 10154,
    city: "Los Banos",
    city_code: "LSN",
    airport_name: "Los Banos",
    country: "United States",
  },
  {
    sr: 10156,
    city: "Los Chiles",
    city_code: "LSL",
    airport_name: "Los Chiles",
    country: "Costa Rica",
  },
  {
    sr: 10158,
    city: "Los Menucos",
    city_code: "LMD",
    airport_name: "Los Menucos",
    country: "Argentina",
  },
  {
    sr: 10160,
    city: "Los Mochis",
    city_code: "LMM",
    airport_name: "Federal",
    country: "Mexico",
  },
  {
    sr: 10162,
    city: "Los Roques",
    city_code: "LRV",
    airport_name: "Los Roques",
    country: "Venezuela",
  },
  {
    sr: 10166,
    city: "Los Tablones",
    city_code: "LOX",
    airport_name: "Los Tablones Airport",
    country: "Guatemala",
  },
  {
    sr: 10168,
    city: "Lossiemouth",
    city_code: "LMO",
    airport_name: "RAF Station",
    country: "United Kingdom",
  },
  {
    sr: 10170,
    city: "Lost Harbor",
    city_code: "LHB",
    airport_name: "Lost Harbor Sea Port",
    country: "United States",
  },
  {
    sr: 10172,
    city: "Lost River",
    city_code: "LSR",
    airport_name: "Lost River",
    country: "United States",
  },
  {
    sr: 10174,
    city: "Losuia",
    city_code: "LSA",
    airport_name: "Losuia",
    country: "Papua New Guinea",
  },
  {
    sr: 10176,
    city: "Lotusvale",
    city_code: "LTV",
    airport_name: "Lotusvale",
    country: "Australia",
  },
  {
    sr: 10178,
    city: "Loubomo",
    city_code: "DIS",
    airport_name: "Loubomo",
    country: "Congo",
  },
  {
    sr: 10180,
    city: "Louis Trichardt",
    city_code: "LCD",
    airport_name: "Louis Trichardt",
    country: "South Africa",
  },
  {
    sr: 10182,
    city: "Louisa",
    city_code: "LOW",
    airport_name: "Louisa",
    country: "United States",
  },
  {
    sr: 10184,
    city: "Louisburg",
    city_code: "LFN",
    airport_name: "Franklin",
    country: "United States",
  },
  {
    sr: 10186,
    city: "Louisville",
    city_code: "LOU",
    airport_name: "Bowman Field",
    country: "United States",
  },
  {
    sr: 10188,
    city: "Louisville",
    city_code: "LJC",
    airport_name: "Intercontinental",
    country: "United States",
  },
  {
    sr: 10190,
    city: "Louisville",
    city_code: "SDF",
    airport_name: "Standiford Field",
    country: "United States",
  },
  {
    sr: 10192,
    city: "Louisville",
    city_code: "LMS",
    airport_name: "Winston County",
    country: "United States",
  },
  {
    sr: 10194,
    city: "Lourdes Tarbes",
    city_code: "LDE",
    airport_name: "Tarbes Ossun Lourdes",
    country: "France",
  },
  {
    sr: 10196,
    city: "Lovelock",
    city_code: "LOL",
    airport_name: "Derby Field",
    country: "United States",
  },
  {
    sr: 10200,
    city: "Lowai",
    city_code: "LWI",
    airport_name: "Lowai",
    country: "Papua New Guinea",
  },
  {
    sr: 10202,
    city: "Loyangalani",
    city_code: "LOY",
    airport_name: "Loyangalani",
    country: "Kenya",
  },
  {
    sr: 10204,
    city: "Luabo",
    city_code: "LBM",
    airport_name: "Luabo",
    country: "Mozambique",
  },
  {
    sr: 10206,
    city: "Luanda",
    city_code: "LAD",
    airport_name: "Luanda",
    country: "Angola",
  },
  {
    sr: 10208,
    city: "Luang Namtha",
    city_code: "LXG",
    airport_name: "Luang Namtha",
    country: "Laos",
  },
  {
    sr: 10210,
    city: "Luang Prabang",
    city_code: "LPQ",
    airport_name: "Luang Prabang",
    country: "Laos",
  },
  {
    sr: 10212,
    city: "Luau",
    city_code: "UAL",
    airport_name: "Luau",
    country: "Angola",
  },
  {
    sr: 10214,
    city: "Lubang",
    city_code: "LBX",
    airport_name: "Lubang",
    country: "Philippines",
  },
  {
    sr: 10216,
    city: "Lubango",
    city_code: "SDD",
    airport_name: "Lubango",
    country: "Angola",
  },
  {
    sr: 10218,
    city: "Lubbock",
    city_code: "LBB",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 10220,
    city: "Lubbock",
    city_code: "REE",
    airport_name: "Reese AFB",
    country: "United States",
  },
  {
    sr: 10222,
    city: "Lubumbashi",
    city_code: "FBM",
    airport_name: "Luano",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 10224,
    city: "Lucca",
    city_code: "LCV",
    airport_name: "Lucca",
    country: "Italy",
  },
  {
    sr: 10226,
    city: "Lucenec",
    city_code: "LUE",
    airport_name: "Lucenec",
    country: "Slovak Republic",
  },
  {
    sr: 10228,
    city: "Lucknow",
    city_code: "LKO",
    airport_name: "Amausi",
    country: "India",
  },
  {
    sr: 10230,
    city: "Luderitz",
    city_code: "LUD",
    airport_name: "Luderitz",
    country: "Namibia",
  },
  {
    sr: 10234,
    city: "Ludhiana",
    city_code: "LUH",
    airport_name: "Ludhiana",
    country: "India",
  },
  {
    sr: 10236,
    city: "Ludington",
    city_code: "LDM",
    airport_name: "Mason County",
    country: "United States",
  },
  {
    sr: 10238,
    city: "Luena",
    city_code: "LUO",
    airport_name: "Luena",
    country: "Angola",
  },
  {
    sr: 10240,
    city: "Lugano",
    city_code: "LUG",
    airport_name: "Lugano",
    country: "Switzerland",
  },
  {
    sr: 10242,
    city: "Lugansk",
    city_code: "VSG",
    airport_name: "Lugansk",
    country: "Ukraine",
  },
  {
    sr: 10244,
    city: "Lugh Ganane",
    city_code: "LGX",
    airport_name: "Lugh Ganane",
    country: "Somalia",
  },
  {
    sr: 10246,
    city: "Luiza",
    city_code: "LZA",
    airport_name: "Luiza",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 10248,
    city: "Lukapa",
    city_code: "LBZ",
    airport_name: "Lukapa",
    country: "Angola",
  },
  {
    sr: 10250,
    city: "Lukla",
    city_code: "LUA",
    airport_name: "Lukla",
    country: "Nepal",
  },
  {
    sr: 10252,
    city: "Lukulu",
    city_code: "LXU",
    airport_name: "Lukulu",
    country: "Zambia",
  },
  {
    sr: 10254,
    city: "Lulea",
    city_code: "LLA",
    airport_name: "Kallax",
    country: "Sweden",
  },
  {
    sr: 10256,
    city: "Lumbala",
    city_code: "GGC",
    airport_name: "Lumbala",
    country: "Angola",
  },
  {
    sr: 10258,
    city: "Lumberton",
    city_code: "LBT",
    airport_name: "Lumberton",
    country: "United States",
  },
  {
    sr: 10260,
    city: "Lumbo",
    city_code: "LFB",
    airport_name: "Lumbo",
    country: "Mozambique",
  },
  {
    sr: 10262,
    city: "Lumi",
    city_code: "LMI",
    airport_name: "Lumi",
    country: "Papua New Guinea",
  },
  {
    sr: 10264,
    city: "Lumid Pau",
    city_code: "LUB",
    airport_name: "Lumid Pau",
    country: "Guyana",
  },
  {
    sr: 10268,
    city: "Lunyuk",
    city_code: "LYK",
    airport_name: "Lunyuk",
    country: "Indonesia",
  },
  {
    sr: 10270,
    city: "Luoyang",
    city_code: "LYA",
    airport_name: "Luoyang",
    country: "China",
  },
  {
    sr: 10272,
    city: "Luozi",
    city_code: "LZI",
    airport_name: "Luozi",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 10274,
    city: "Lupin",
    city_code: "YWO",
    airport_name: "Lupin",
    country: "Canada",
  },
  {
    sr: 10276,
    city: "Lusaka",
    city_code: "LUN",
    airport_name: "Lusaka",
    country: "Zambia",
  },
  {
    sr: 10278,
    city: "Lusambo",
    city_code: "LBO",
    airport_name: "Lusambo",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 10280,
    city: "Lusanga",
    city_code: "LUS",
    airport_name: "Lusanga",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 10282,
    city: "Lushan",
    city_code: "LUZ",
    airport_name: "Lushan",
    country: "China",
  },
  {
    sr: 10284,
    city: "Lushoto",
    city_code: "LUY",
    airport_name: "Lushoto",
    country: "Tanzania",
  },
  {
    sr: 10286,
    city: "Lusikisiki",
    city_code: "LUJ",
    airport_name: "Lusikisiki",
    country: "South Africa",
  },
  {
    sr: 10288,
    city: "Lusk",
    city_code: "LSK",
    airport_name: "Lusk",
    country: "United States",
  },
  {
    sr: 10290,
    city: "Lutselke Snowdrift",
    city_code: "YSG",
    airport_name: "Lutselke",
    country: "Canada",
  },
  {
    sr: 10292,
    city: "Lutsk",
    city_code: "UCK",
    airport_name: "Lutsk",
    country: "Ukraine",
  },
  {
    sr: 10294,
    city: "Luwuk",
    city_code: "LUW",
    airport_name: "Luwuk",
    country: "Indonesia",
  },
  {
    sr: 10296,
    city: "Luxembourg",
    city_code: "LUX",
    airport_name: "Findel",
    country: "Luxembourg",
  },
  {
    sr: 10298,
    city: "Luxi",
    city_code: "LUM",
    airport_name: "Mangshi",
    country: "China",
  },
  {
    sr: 10302,
    city: "Luxor",
    city_code: "LXR",
    airport_name: "Luxor",
    country: "Egypt",
  },
  {
    sr: 10304,
    city: "Luzamba",
    city_code: "LZM",
    airport_name: "Luzamba",
    country: "Angola",
  },
  {
    sr: 10306,
    city: "Luzhou",
    city_code: "LZO",
    airport_name: "Luzhou",
    country: "China",
  },
  {
    sr: 10308,
    city: "Angeles City",
    city_code: "CRK",
    airport_name: "Diosdado Macapagal International  Clark Int",
    country: "Philippines",
  },
  {
    sr: 10310,
    city: "Luzon Is",
    city_code: "NCP",
    airport_name: "Cubi Pt NAS",
    country: "Philippines",
  },
  {
    sr: 10312,
    city: "Lviv",
    city_code: "LWO",
    airport_name: "Snilow",
    country: "Ukraine",
  },
  {
    sr: 10314,
    city: "Lwbak",
    city_code: "LWA",
    airport_name: "Lwbak",
    country: "Philippines",
  },
  {
    sr: 10316,
    city: "Lyall Harbour",
    city_code: "YAJ",
    airport_name: "Lyall Harbour",
    country: "Canada",
  },
  {
    sr: 10318,
    city: "Lycksele",
    city_code: "LYC",
    airport_name: "Lycksele",
    country: "Sweden",
  },
  {
    sr: 10320,
    city: "Lynchburg",
    city_code: "LYH",
    airport_name: "Preston Glenn Field",
    country: "United States",
  },
  {
    sr: 10322,
    city: "Lyndhurst",
    city_code: "LTP",
    airport_name: "Lyndhurst",
    country: "Australia",
  },
  {
    sr: 10324,
    city: "Lyndonville",
    city_code: "LLX",
    airport_name: "Lyndonville",
    country: "United States",
  },
  {
    sr: 10326,
    city: "Lyneham",
    city_code: "LYE",
    airport_name: "RAF Station",
    country: "United Kingdom",
  },
  {
    sr: 10328,
    city: "Lynn Lake",
    city_code: "YYL",
    airport_name: "Lynn Lake",
    country: "Canada",
  },
  {
    sr: 10330,
    city: "Lyon",
    city_code: "LYN",
    airport_name: "Bron",
    country: "France",
  },
  {
    sr: 10332,
    city: "Lyon",
    city_code: "XYD",
    airport_name: "Lyon Part Dieu Rail Sv",
    country: "France",
  },
  {
    sr: 10336,
    city: "Lyon",
    city_code: "XYL",
    airport_name: "Lyon Perrache Rail Svc",
    country: "France",
  },
  {
    sr: 10338,
    city: "Lyon",
    city_code: "XYV",
    airport_name: "Lyon Satolas Rail Svc",
    country: "France",
  },
  {
    sr: 10340,
    city: "Lyon",
    city_code: "GNB",
    airport_name: "Saint Geoirs",
    country: "France",
  },
  {
    sr: 10342,
    city: "Lyon",
    city_code: "LYS",
    airport_name: "Satolas",
    country: "France",
  },
  {
    sr: 10344,
    city: "Lyons",
    city_code: "LYO",
    airport_name: "Rice County Municipal",
    country: "United States",
  },
  {
    sr: 10346,
    city: "Lysaker",
    city_code: "XUI",
    airport_name: "Lysaker Railway",
    country: "Norway",
  },
  {
    sr: 10348,
    city: "Maan",
    city_code: "MPQ",
    airport_name: "Maan",
    country: "Jordan",
  },
  {
    sr: 10350,
    city: "Maastricht",
    city_code: "MST",
    airport_name: "Maastricht aachen",
    country: "Netherlands",
  },
  {
    sr: 10352,
    city: "Mabaruma",
    city_code: "USI",
    airport_name: "Mabaruma",
    country: "Guyana",
  },
  {
    sr: 10354,
    city: "Mabuiag Island",
    city_code: "UBB",
    airport_name: "Mabuiag Island",
    country: "Australia",
  },
  {
    sr: 10356,
    city: "Macae",
    city_code: "MEA",
    airport_name: "Macae",
    country: "Brazil",
  },
  {
    sr: 10358,
    city: "Macanal",
    city_code: "NAD",
    airport_name: "Macanal",
    country: "Colombia",
  },
  {
    sr: 10360,
    city: "Macapa",
    city_code: "MCP",
    airport_name: "Internacional",
    country: "Brazil",
  },
  {
    sr: 10362,
    city: "Macara",
    city_code: "MRR",
    airport_name: "Macara",
    country: "Ecuador",
  },
  {
    sr: 10364,
    city: "Macas",
    city_code: "XMS",
    airport_name: "Macas",
    country: "Ecuador",
  },
  {
    sr: 10366,
    city: "Macau",
    city_code: "MFM",
    airport_name: "Macau",
    country: "Macau",
  },
  {
    sr: 10370,
    city: "Macau",
    city_code: "XZM",
    airport_name: "Macau Ferry",
    country: "Macau",
  },
  {
    sr: 10372,
    city: "Macdonald Downs",
    city_code: "MNW",
    airport_name: "Macdonald Downs",
    country: "Australia",
  },
  {
    sr: 10374,
    city: "Maceio",
    city_code: "MCZ",
    airport_name: "Palmares",
    country: "Brazil",
  },
  {
    sr: 10376,
    city: "Macenta",
    city_code: "MCA",
    airport_name: "Macenta",
    country: "Guinea",
  },
  {
    sr: 10378,
    city: "Machala",
    city_code: "MCH",
    airport_name: "Machala",
    country: "Ecuador",
  },
  {
    sr: 10380,
    city: "Machrihanish",
    city_code: "GQJ",
    airport_name: "RAF Station",
    country: "United Kingdom",
  },
  {
    sr: 10382,
    city: "Machu Picchu",
    city_code: "MFT",
    airport_name: "Machu Picchu",
    country: "Peru",
  },
  {
    sr: 10384,
    city: "Mackay",
    city_code: "MKY",
    airport_name: "Mackay",
    country: "Australia",
  },
  {
    sr: 10386,
    city: "Mackinac Island",
    city_code: "MCD",
    airport_name: "Mackinac Island",
    country: "United States",
  },
  {
    sr: 10388,
    city: "Macksville",
    city_code: "MVH",
    airport_name: "Macksville",
    country: "Australia",
  },
  {
    sr: 10390,
    city: "Macmahon Camp 4",
    city_code: "MHC",
    airport_name: "Macmahon Camp 4",
    country: "Australia",
  },
  {
    sr: 10392,
    city: "Macmillan Pass",
    city_code: "XMP",
    airport_name: "Macmillan Pass",
    country: "Canada",
  },
  {
    sr: 10394,
    city: "Macomb",
    city_code: "MQB",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 10396,
    city: "Macon",
    city_code: "MCN",
    airport_name: "Lewis B Wilson",
    country: "United States",
  },
  {
    sr: 10398,
    city: "Macon",
    city_code: "WRB",
    airport_name: "Robins AFB",
    country: "United States",
  },
  {
    sr: 10400,
    city: "Macon",
    city_code: "MAC",
    airport_name: "Smart",
    country: "United States",
  },
  {
    sr: 10404,
    city: "Madang",
    city_code: "MAG",
    airport_name: "Madang",
    country: "Papua New Guinea",
  },
  {
    sr: 10406,
    city: "Madeira",
    city_code: "FNC",
    airport_name: "Madeira",
    country: "Portugal",
  },
  {
    sr: 10408,
    city: "Madera",
    city_code: "MAE",
    airport_name: "Madera",
    country: "United States",
  },
  {
    sr: 10410,
    city: "Madinah",
    city_code: "MED",
    airport_name: "Mohammad Bin Abdulaziz",
    country: "Saudi Arabia",
  },
  {
    sr: 10412,
    city: "Madirovalo",
    city_code: "WMV",
    airport_name: "Madirovalo",
    country: "Madagascar",
  },
  {
    sr: 10414,
    city: "Madison",
    city_code: "MPE",
    airport_name: "Griswold",
    country: "United States",
  },
  {
    sr: 10416,
    city: "Madison",
    city_code: "MDN",
    airport_name: "Jefferson Proving Grd",
    country: "United States",
  },
  {
    sr: 10418,
    city: "Madison",
    city_code: "XMD",
    airport_name: "Madison",
    country: "United States",
  },
  {
    sr: 10420,
    city: "Madison",
    city_code: "MSN",
    airport_name: "Dane County Regional",
    country: "United States",
  },
  {
    sr: 10422,
    city: "Madras",
    city_code: "MDJ",
    airport_name: "City county",
    country: "United States",
  },
  {
    sr: 10424,
    city: "Madrid",
    city_code: "MAD",
    airport_name: "Barajas",
    country: "Spain",
  },
  {
    sr: 10426,
    city: "Madrid",
    city_code: "TOJ",
    airport_name: "Torrejon AFB",
    country: "Spain",
  },
  {
    sr: 10428,
    city: "Madurai",
    city_code: "IXM",
    airport_name: "Madurai",
    country: "India",
  },
  {
    sr: 10430,
    city: "Mae Hong Son",
    city_code: "HGN",
    airport_name: "Mae Hong Son",
    country: "Thailand",
  },
  {
    sr: 10432,
    city: "Mae Sot",
    city_code: "MAQ",
    airport_name: "Mae Sot",
    country: "Thailand",
  },
  {
    sr: 10434,
    city: "Maewo",
    city_code: "MWF",
    airport_name: "Maewo",
    country: "Vanuatu",
  },
  {
    sr: 10438,
    city: "Mafeteng",
    city_code: "MFC",
    airport_name: "Mafeteng",
    country: "Lesotho",
  },
  {
    sr: 10440,
    city: "Mafia",
    city_code: "MFA",
    airport_name: "Mafia",
    country: "Tanzania",
  },
  {
    sr: 10442,
    city: "Mafraq",
    city_code: "OMF",
    airport_name: "King Hussein",
    country: "Jordan",
  },
  {
    sr: 10444,
    city: "Magadan",
    city_code: "GDX",
    airport_name: "Magadan",
    country: "Russia",
  },
  {
    sr: 10446,
    city: "Magangue",
    city_code: "MGN",
    airport_name: "Baracoa",
    country: "Colombia",
  },
  {
    sr: 10448,
    city: "Maganja Da Costa",
    city_code: "MJS",
    airport_name: "Maganja Da Costa",
    country: "Mozambique",
  },
  {
    sr: 10450,
    city: "Magaruque",
    city_code: "MFW",
    airport_name: "Magaruque",
    country: "Mozambique",
  },
  {
    sr: 10452,
    city: "Magdagachi",
    city_code: "GDG",
    airport_name: "Magdagachi",
    country: "Russia",
  },
  {
    sr: 10454,
    city: "Magdalena",
    city_code: "MGD",
    airport_name: "Magdalena",
    country: "Bolivia",
  },
  {
    sr: 10456,
    city: "Chelyabinsk",
    city_code: "MQF",
    airport_name: "Magnitogorsk",
    country: "Russia",
  },
  {
    sr: 10458,
    city: "Magnolia",
    city_code: "AGO",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 10460,
    city: "Magwe",
    city_code: "MWQ",
    airport_name: "Magwe",
    country: "Myanmar",
  },
  {
    sr: 10462,
    city: "Mahanoro",
    city_code: "VVB",
    airport_name: "Mahanoro",
    country: "Madagascar",
  },
  {
    sr: 10464,
    city: "Mahaweli",
    city_code: "KDY",
    airport_name: "Mahaweli",
    country: "Sri Lanka",
  },
  {
    sr: 10466,
    city: "Mahdia",
    city_code: "MHA",
    airport_name: "Mahdia",
    country: "Guyana",
  },
  {
    sr: 10468,
    city: "Mahe Island",
    city_code: "SEZ",
    airport_name: "Seychelles International Airport",
    country: "Seychelles",
  },
  {
    sr: 10472,
    city: "Mahendranagar",
    city_code: "XMG",
    airport_name: "Mahendranagar",
    country: "Nepal",
  },
  {
    sr: 10474,
    city: "Mahenye",
    city_code: "MJW",
    airport_name: "Mahenye",
    country: "Zimbabwe",
  },
  {
    sr: 10476,
    city: "Maiana",
    city_code: "MNK",
    airport_name: "Maiana",
    country: "Kiribati",
  },
  {
    sr: 10478,
    city: "Maicao",
    city_code: "MCJ",
    airport_name: "Maicao",
    country: "Colombia",
  },
  {
    sr: 10480,
    city: "Maiduguri",
    city_code: "MIU",
    airport_name: "Maiduguri",
    country: "Nigeria",
  },
  {
    sr: 10482,
    city: "Maikwak",
    city_code: "VEG",
    airport_name: "Maikwak",
    country: "Guyana",
  },
  {
    sr: 10484,
    city: "Maimana",
    city_code: "MMZ",
    airport_name: "Maimana",
    country: "Afghanistan",
  },
  {
    sr: 10486,
    city: "Main Duck Island",
    city_code: "YDK",
    airport_name: "Main Duck Island",
    country: "Canada",
  },
  {
    sr: 10488,
    city: "Mainoru",
    city_code: "MIZ",
    airport_name: "Mainoru",
    country: "Australia",
  },
  {
    sr: 10490,
    city: "Maintirano",
    city_code: "MXT",
    airport_name: "Maintirano",
    country: "Madagascar",
  },
  {
    sr: 10492,
    city: "Maio",
    city_code: "MMO",
    airport_name: "Vila Do Maio",
    country: "Cape Verde",
  },
  {
    sr: 10494,
    city: "Maitland",
    city_code: "MTL",
    airport_name: "Maitland",
    country: "Australia",
  },
  {
    sr: 10496,
    city: "Majkin",
    city_code: "MJE",
    airport_name: "Majkin",
    country: "Marshall Islands",
  },
  {
    sr: 10498,
    city: "Majma",
    city_code: "MJH",
    airport_name: "Majma",
    country: "Saudi Arabia",
  },
  {
    sr: 10500,
    city: "Majunga",
    city_code: "MJN",
    airport_name: "Amborovy",
    country: "Madagascar",
  },
  {
    sr: 10502,
    city: "Majuro",
    city_code: "MAJ",
    airport_name: "Amata Kabua Intl",
    country: "Marshall Islands",
  },
  {
    sr: 10506,
    city: "Makabana",
    city_code: "KMK",
    airport_name: "Makabana",
    country: "Congo",
  },
  {
    sr: 10508,
    city: "Makale",
    city_code: "MQX",
    airport_name: "Makale",
    country: "Ethiopia",
  },
  {
    sr: 10510,
    city: "Makemo",
    city_code: "MKP",
    airport_name: "Makemo",
    country: "French Polynesia",
  },
  {
    sr: 10512,
    city: "Makhachkala",
    city_code: "MCX",
    airport_name: "Makhachkala",
    country: "Russia",
  },
  {
    sr: 10514,
    city: "Makin Island",
    city_code: "MTK",
    airport_name: "Makin Island",
    country: "Kiribati",
  },
  {
    sr: 10516,
    city: "Makini",
    city_code: "MPG",
    airport_name: "Makini",
    country: "Papua New Guinea",
  },
  {
    sr: 10518,
    city: "Makkovik",
    city_code: "YMN",
    airport_name: "Makkovik",
    country: "Canada",
  },
  {
    sr: 10520,
    city: "Makokou",
    city_code: "MKU",
    airport_name: "Makokou",
    country: "Gabon",
  },
  {
    sr: 10522,
    city: "Makoua",
    city_code: "MKJ",
    airport_name: "Makoua",
    country: "Congo",
  },
  {
    sr: 10524,
    city: "Makung",
    city_code: "MZG",
    airport_name: "Makung",
    country: "Taiwan",
  },
  {
    sr: 10526,
    city: "Makurdi",
    city_code: "MDI",
    airport_name: "Makurdi",
    country: "Nigeria",
  },
  {
    sr: 10528,
    city: "Mal",
    city_code: "MMV",
    airport_name: "Mal",
    country: "Papua New Guinea",
  },
  {
    sr: 10530,
    city: "Mala Mala",
    city_code: "AAM",
    airport_name: "Mala Mala",
    country: "South Africa",
  },
  {
    sr: 10532,
    city: "Malabang",
    city_code: "MLP",
    airport_name: "Malabang",
    country: "Philippines",
  },
  {
    sr: 10534,
    city: "Malabo",
    city_code: "SSG",
    airport_name: "Santa Isabel",
    country: "Equatorial Guinea",
  },
  {
    sr: 10536,
    city: "Malacca",
    city_code: "MKZ",
    airport_name: "Batu Berendum",
    country: "Malaysia",
  },
  {
    sr: 10540,
    city: "Malad City",
    city_code: "MLD",
    airport_name: "Malad City",
    country: "United States",
  },
  {
    sr: 10542,
    city: "Malaga",
    city_code: "AGP",
    airport_name: "Malaga",
    country: "Spain",
  },
  {
    sr: 10544,
    city: "Malaimbandy",
    city_code: "WML",
    airport_name: "Malaimbandy",
    country: "Madagascar",
  },
  {
    sr: 10546,
    city: "Malakal",
    city_code: "MAK",
    airport_name: "Malakal",
    country: "Sudan",
  },
  {
    sr: 10548,
    city: "Malalaua",
    city_code: "MLQ",
    airport_name: "Malalaua",
    country: "Papua New Guinea",
  },
  {
    sr: 10550,
    city: "Malam",
    city_code: "MQO",
    airport_name: "Malam",
    country: "Papua New Guinea",
  },
  {
    sr: 10552,
    city: "Malang",
    city_code: "MLG",
    airport_name: "Abdul Rahman Saleh",
    country: "Indonesia",
  },
  {
    sr: 10554,
    city: "Malange",
    city_code: "MEG",
    airport_name: "Malange",
    country: "Angola",
  },
  {
    sr: 10556,
    city: "Malargue",
    city_code: "LGS",
    airport_name: "Malargue",
    country: "Argentina",
  },
  {
    sr: 10558,
    city: "Malatya",
    city_code: "MLX",
    airport_name: "Malatya",
    country: "Turkey",
  },
  {
    sr: 10560,
    city: "Malda",
    city_code: "LDA",
    airport_name: "Malda",
    country: "India",
  },
  {
    sr: 10562,
    city: "Malden",
    city_code: "MAW",
    airport_name: "Malden",
    country: "United States",
  },
  {
    sr: 10564,
    city: "Male",
    city_code: "MLE",
    airport_name: "International",
    country: "Maldives",
  },
  {
    sr: 10566,
    city: "Malekolon",
    city_code: "MKN",
    airport_name: "Malekolon",
    country: "Papua New Guinea",
  },
  {
    sr: 10568,
    city: "Malelane",
    city_code: "LLE",
    airport_name: "Malelane",
    country: "South Africa",
  },
  {
    sr: 10570,
    city: "Mali Losinj",
    city_code: "LSZ",
    airport_name: "Losinj Arpt",
    country: "Croatia  Hrvatska",
  },
  {
    sr: 10574,
    city: "Maliana",
    city_code: "MPT",
    airport_name: "Maliana",
    country: "Indonesia",
  },
  {
    sr: 10576,
    city: "Malindi",
    city_code: "MYD",
    airport_name: "Malindi",
    country: "Kenya",
  },
  {
    sr: 10578,
    city: "Mallacoota",
    city_code: "XMC",
    airport_name: "Mallacoota",
    country: "Australia",
  },
  {
    sr: 10580,
    city: "Malmo",
    city_code: "JMM",
    airport_name: "Malmo Harbour Heliport",
    country: "Sweden",
  },
  {
    sr: 10582,
    city: "Malmo",
    city_code: "XFR",
    airport_name: "Malmo South Railway",
    country: "Sweden",
  },
  {
    sr: 10584,
    city: "Malmo",
    city_code: "MMX",
    airport_name: "Sturup",
    country: "Sweden",
  },
  {
    sr: 10586,
    city: "Maloelap Island",
    city_code: "MAV",
    airport_name: "Maloelap Island",
    country: "Marshall Islands",
  },
  {
    sr: 10588,
    city: "Malololailai",
    city_code: "PTF",
    airport_name: "Malololailai",
    country: "Fiji",
  },
  {
    sr: 10590,
    city: "Maloy",
    city_code: "QFQ",
    airport_name: "Harbour",
    country: "Norway",
  },
  {
    sr: 10592,
    city: "Gudja",
    city_code: "MLA",
    airport_name: "Malta International",
    country: "Malta",
  },
  {
    sr: 10594,
    city: "Malta",
    city_code: "MLK",
    airport_name: "Malta",
    country: "United States",
  },
  {
    sr: 10596,
    city: "Mamai",
    city_code: "MAP",
    airport_name: "Mamai",
    country: "Papua New Guinea",
  },
  {
    sr: 10598,
    city: "Mamburao",
    city_code: "MBO",
    airport_name: "Mamburao",
    country: "Philippines",
  },
  {
    sr: 10600,
    city: "Mamfe",
    city_code: "MMF",
    airport_name: "Mamfe",
    country: "Cameroon",
  },
  {
    sr: 10602,
    city: "Mamitupo",
    city_code: "MPI",
    airport_name: "Mamitupo",
    country: "Panama",
  },
  {
    sr: 10604,
    city: "Mammoth Lakes",
    city_code: "MMH",
    airport_name: "Mammoth Lakes",
    country: "United States",
  },
  {
    sr: 10608,
    city: "Mampikony",
    city_code: "WMP",
    airport_name: "Mampikony",
    country: "Madagascar",
  },
  {
    sr: 10610,
    city: "Mamuju",
    city_code: "MJU",
    airport_name: "Mamuju",
    country: "Indonesia",
  },
  {
    sr: 10612,
    city: "Man",
    city_code: "MJC",
    airport_name: "Man",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 10614,
    city: "Mana Island",
    city_code: "MNF",
    airport_name: "Mana Island Airstrip",
    country: "Fiji",
  },
  {
    sr: 10616,
    city: "Manado",
    city_code: "MDC",
    airport_name: "Samratulangi",
    country: "Indonesia",
  },
  {
    sr: 10618,
    city: "Managua",
    city_code: "MGA",
    airport_name: "Augusto C Sandino",
    country: "Nicaragua",
  },
  {
    sr: 10620,
    city: "Manakara",
    city_code: "WVK",
    airport_name: "Manakara",
    country: "Madagascar",
  },
  {
    sr: 10622,
    city: "Manama",
    city_code: "WGF",
    airport_name: "Off Line Point",
    country: "Bahrain",
  },
  {
    sr: 10624,
    city: "Mananara",
    city_code: "WMR",
    airport_name: "Mananara",
    country: "Madagascar",
  },
  {
    sr: 10626,
    city: "Manang",
    city_code: "NGX",
    airport_name: "Manang",
    country: "Nepal",
  },
  {
    sr: 10628,
    city: "Mananjary",
    city_code: "MNJ",
    airport_name: "Mananjary",
    country: "Madagascar",
  },
  {
    sr: 10630,
    city: "Manare",
    city_code: "MRM",
    airport_name: "Manare",
    country: "Papua New Guinea",
  },
  {
    sr: 10632,
    city: "Manassas",
    city_code: "MNZ",
    airport_name: "Manassas",
    country: "United States",
  },
  {
    sr: 10634,
    city: "Manatee",
    city_code: "MZE",
    airport_name: "Manatee",
    country: "Belize",
  },
  {
    sr: 10636,
    city: "Manaung",
    city_code: "MGU",
    airport_name: "Manaung",
    country: "Myanmar",
  },
  {
    sr: 10638,
    city: "Manaus",
    city_code: "MAO",
    airport_name: "Eduardo Gomes Intl",
    country: "Brazil",
  },
  {
    sr: 10642,
    city: "Manchester",
    city_code: "MAN",
    airport_name: "Manchester",
    country: "United Kingdom",
  },
  {
    sr: 10644,
    city: "Manchester",
    city_code: "ZMP",
    airport_name: "Manchester Bus Stn",
    country: "United Kingdom",
  },
  {
    sr: 10646,
    city: "Manchester",
    city_code: "MHT",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 10648,
    city: "Mandabe",
    city_code: "WMD",
    airport_name: "Mandabe",
    country: "Madagascar",
  },
  {
    sr: 10650,
    city: "Mandalay",
    city_code: "MDL",
    airport_name: "Mandalay International",
    country: "Myanmar",
  },
  {
    sr: 10652,
    city: "Mandalgobi",
    city_code: "MXW",
    airport_name: "Mandalgobi",
    country: "Mongolia",
  },
  {
    sr: 10654,
    city: "Mandera",
    city_code: "NDE",
    airport_name: "Mandera",
    country: "Kenya",
  },
  {
    sr: 10656,
    city: "Mandeville",
    city_code: "MVJ",
    airport_name: "Marlboro",
    country: "Jamaica",
  },
  {
    sr: 10658,
    city: "Mandji",
    city_code: "KMD",
    airport_name: "Mandji",
    country: "Gabon",
  },
  {
    sr: 10660,
    city: "Mandora",
    city_code: "MQA",
    airport_name: "Mandora",
    country: "Australia",
  },
  {
    sr: 10662,
    city: "Mandritsara",
    city_code: "WMA",
    airport_name: "Mandritsara",
    country: "Madagascar",
  },
  {
    sr: 10664,
    city: "Manega",
    city_code: "MGO",
    airport_name: "Manega",
    country: "Gabon",
  },
  {
    sr: 10666,
    city: "Manetai",
    city_code: "MVI",
    airport_name: "Manetai",
    country: "Papua New Guinea",
  },
  {
    sr: 10668,
    city: "Manga",
    city_code: "MGP",
    airport_name: "Manga",
    country: "Papua New Guinea",
  },
  {
    sr: 10670,
    city: "Mangaia Island",
    city_code: "MGS",
    airport_name: "Mangaia Island",
    country: "Cook Islands",
  },
  {
    sr: 10672,
    city: "Mangalore",
    city_code: "IXE",
    airport_name: "Bajpe",
    country: "India",
  },
  {
    sr: 10676,
    city: "Mangla",
    city_code: "XJM",
    airport_name: "Mangla",
    country: "Pakistan",
  },
  {
    sr: 10678,
    city: "Mangochi",
    city_code: "MAI",
    airport_name: "Mangochi",
    country: "Malawi",
  },
  {
    sr: 10680,
    city: "Mangole",
    city_code: "MAL",
    airport_name: "Mangole",
    country: "Indonesia",
  },
  {
    sr: 10682,
    city: "Mangrove Cay",
    city_code: "MAY",
    airport_name: "Mangrove Cay",
    country: "Bahamas",
  },
  {
    sr: 10684,
    city: "Manguna",
    city_code: "MFO",
    airport_name: "Manguna",
    country: "Egypt",
  },
  {
    sr: 10686,
    city: "Mangunjaya",
    city_code: "MJY",
    airport_name: "Mangunjaya",
    country: "Indonesia",
  },
  {
    sr: 10688,
    city: "Manhattan",
    city_code: "MHK",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 10690,
    city: "Manicore",
    city_code: "MNX",
    airport_name: "Manicore",
    country: "Brazil",
  },
  {
    sr: 10692,
    city: "Manihi",
    city_code: "XMH",
    airport_name: "Manihi",
    country: "French Polynesia",
  },
  {
    sr: 10694,
    city: "Manihiki Island",
    city_code: "MHX",
    airport_name: "Manihiki Island",
    country: "Cook Islands",
  },
  {
    sr: 10696,
    city: "Maniitsoq",
    city_code: "JSU",
    airport_name: "Heliport",
    country: "Greenland",
  },
  {
    sr: 10698,
    city: "Manila",
    city_code: "MXA",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 10700,
    city: "Manila",
    city_code: "MNL",
    airport_name: "Ninoy Aquino Intl",
    country: "Philippines",
  },
  {
    sr: 10702,
    city: "Cavite City",
    city_code: "SGL",
    airport_name: "Sangley Point NAS",
    country: "Philippines",
  },
  {
    sr: 10704,
    city: "Maningrida",
    city_code: "MNG",
    airport_name: "Maningrida",
    country: "Australia",
  },
  {
    sr: 10706,
    city: "Manistee",
    city_code: "MBL",
    airport_name: "Blacker",
    country: "United States",
  },
  {
    sr: 10710,
    city: "Manistique",
    city_code: "ISQ",
    airport_name: "Schoolcraft County",
    country: "United States",
  },
  {
    sr: 10712,
    city: "Manitouwadge",
    city_code: "YMG",
    airport_name: "Manitouwadge",
    country: "Canada",
  },
  {
    sr: 10714,
    city: "Manitowaning",
    city_code: "YEM",
    airport_name: "East Manitoulin",
    country: "Canada",
  },
  {
    sr: 10716,
    city: "Manitowoc",
    city_code: "MTW",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 10718,
    city: "Maniwaki",
    city_code: "YMW",
    airport_name: "Maniwaki",
    country: "Canada",
  },
  {
    sr: 10720,
    city: "Manizales",
    city_code: "MZL",
    airport_name: "Santaguida",
    country: "Colombia",
  },
  {
    sr: 10722,
    city: "Manja",
    city_code: "MJA",
    airport_name: "Manja",
    country: "Madagascar",
  },
  {
    sr: 10724,
    city: "Manjimup",
    city_code: "MJP",
    airport_name: "Manjimup",
    country: "Australia",
  },
  {
    sr: 10726,
    city: "Mankato",
    city_code: "MKT",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 10728,
    city: "Mankato",
    city_code: "JMN",
    airport_name: "Municipal Heliport",
    country: "United States",
  },
  {
    sr: 10730,
    city: "Mankono",
    city_code: "MOK",
    airport_name: "Mankono",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 10732,
    city: "Manley Hot Springs",
    city_code: "MLY",
    airport_name: "Manley Hot Springs",
    country: "United States",
  },
  {
    sr: 10734,
    city: "Manly",
    city_code: "WGZ",
    airport_name: "Off Line Point",
    country: "Australia",
  },
  {
    sr: 10736,
    city: "Manners Creek",
    city_code: "MFP",
    airport_name: "Manners Creek",
    country: "Australia",
  },
  {
    sr: 10738,
    city: "Mannheim",
    city_code: "MHG",
    airport_name: "Mannheim Arpt",
    country: "Germany",
  },
  {
    sr: 10740,
    city: "Manokotak",
    city_code: "KMO",
    airport_name: "Manokotak SPB",
    country: "United States",
  },
  {
    sr: 10744,
    city: "Manokwari",
    city_code: "MKW",
    airport_name: "Rendani",
    country: "Indonesia",
  },
  {
    sr: 10746,
    city: "Manono",
    city_code: "MNO",
    airport_name: "Manono",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 10748,
    city: "Mansa",
    city_code: "MNS",
    airport_name: "Mansa",
    country: "Zambia",
  },
  {
    sr: 10750,
    city: "Mansehra",
    city_code: "HRA",
    airport_name: "Mansehra",
    country: "Pakistan",
  },
  {
    sr: 10752,
    city: "Mansfield",
    city_code: "MFD",
    airport_name: "Lahm Municipal",
    country: "United States",
  },
  {
    sr: 10754,
    city: "Mansion House",
    city_code: "KWU",
    airport_name: "Mansion House",
    country: "New Zealand",
  },
  {
    sr: 10756,
    city: "Manston",
    city_code: "MSE",
    airport_name: "Kent International",
    country: "United Kingdom",
  },
  {
    sr: 10758,
    city: "Manta",
    city_code: "MEC",
    airport_name: "Manta",
    country: "Ecuador",
  },
  {
    sr: 10760,
    city: "Manteo",
    city_code: "MEO",
    airport_name: "Dare County Regional",
    country: "United States",
  },
  {
    sr: 10762,
    city: "Manti",
    city_code: "NTJ",
    airport_name: "Manti Ephraim",
    country: "United States",
  },
  {
    sr: 10764,
    city: "Manumu",
    city_code: "UUU",
    airport_name: "Manumu",
    country: "Papua New Guinea",
  },
  {
    sr: 10766,
    city: "Manus Island",
    city_code: "MAS",
    airport_name: "Momote",
    country: "Papua New Guinea",
  },
  {
    sr: 10768,
    city: "Manville",
    city_code: "JVI",
    airport_name: "Kupper",
    country: "United States",
  },
  {
    sr: 10770,
    city: "Manzanillo",
    city_code: "MZO",
    airport_name: "Sierra Maestra",
    country: "Cuba",
  },
  {
    sr: 10772,
    city: "Manzanillo",
    city_code: "ZLO",
    airport_name: "Manzanillo",
    country: "Mexico",
  },
  {
    sr: 10774,
    city: "Manzhouli",
    city_code: "NZH",
    airport_name: "Manzhouli",
    country: "China",
  },
  {
    sr: 10778,
    city: "Manzini",
    city_code: "MTS",
    airport_name: "Matsapha Intl",
    country: "Swaziland",
  },
  {
    sr: 10780,
    city: "Mao",
    city_code: "AMO",
    airport_name: "Mao",
    country: "Chad",
  },
  {
    sr: 10782,
    city: "Maota Savaii Is",
    city_code: "MXS",
    airport_name: "Maota Savaii Is",
    country: "Samoa",
  },
  {
    sr: 10784,
    city: "Maple Bay",
    city_code: "YAQ",
    airport_name: "Maple Bay",
    country: "Canada",
  },
  {
    sr: 10786,
    city: "Mapoda",
    city_code: "MPF",
    airport_name: "Mapoda",
    country: "Papua New Guinea",
  },
  {
    sr: 10788,
    city: "Mapua",
    city_code: "MPU",
    airport_name: "Mapua",
    country: "Papua New Guinea",
  },
  {
    sr: 10790,
    city: "Maputo",
    city_code: "MPM",
    airport_name: "Maputo International",
    country: "Mozambique",
  },
  {
    sr: 10792,
    city: "Maquinchao",
    city_code: "MQD",
    airport_name: "Maquinchao",
    country: "Argentina",
  },
  {
    sr: 10794,
    city: "Mar Del Plata",
    city_code: "MDQ",
    airport_name: "Mar Del Plata",
    country: "Argentina",
  },
  {
    sr: 10796,
    city: "Mara Lodges",
    city_code: "MRE",
    airport_name: "Mara Lodges",
    country: "Kenya",
  },
  {
    sr: 10798,
    city: "Maraba",
    city_code: "MAB",
    airport_name: "Maraba",
    country: "Brazil",
  },
  {
    sr: 10800,
    city: "Maracaibo",
    city_code: "MAR",
    airport_name: "La Chinita",
    country: "Venezuela",
  },
  {
    sr: 10802,
    city: "Maracay",
    city_code: "MYC",
    airport_name: "Maracay",
    country: "Venezuela",
  },
  {
    sr: 10804,
    city: "Maradi",
    city_code: "MFQ",
    airport_name: "Maradi",
    country: "Niger",
  },
  {
    sr: 10806,
    city: "Marakei",
    city_code: "MZK",
    airport_name: "Marakei",
    country: "Kiribati",
  },
  {
    sr: 10808,
    city: "Maramag",
    city_code: "XMA",
    airport_name: "Maramag",
    country: "Philippines",
  },
  {
    sr: 10812,
    city: "Maramuni",
    city_code: "MWI",
    airport_name: "Maramuni",
    country: "Papua New Guinea",
  },
  {
    sr: 10814,
    city: "Marana",
    city_code: "MZJ",
    airport_name: "Marana",
    country: "United States",
  },
  {
    sr: 10816,
    city: "Marathon",
    city_code: "MTH",
    airport_name: "Marathon Flight Strip",
    country: "United States",
  },
  {
    sr: 10818,
    city: "Marathon",
    city_code: "YSP",
    airport_name: "Marathon",
    country: "Canada",
  },
  {
    sr: 10820,
    city: "Marau Sound",
    city_code: "RUS",
    airport_name: "Marau Sound",
    country: "Solomon Islands",
  },
  {
    sr: 10822,
    city: "Marawaka",
    city_code: "MWG",
    airport_name: "Marawaka",
    country: "Papua New Guinea",
  },
  {
    sr: 10824,
    city: "Marble Bar",
    city_code: "MBB",
    airport_name: "Marble Bar",
    country: "Australia",
  },
  {
    sr: 10826,
    city: "Marble Canyon",
    city_code: "MYH",
    airport_name: "Marble Canyon",
    country: "United States",
  },
  {
    sr: 10828,
    city: "Marcala",
    city_code: "MRJ",
    airport_name: "Marcala",
    country: "Honduras",
  },
  {
    sr: 10830,
    city: "Marco Island",
    city_code: "MRK",
    airport_name: "Marco Island",
    country: "United States",
  },
  {
    sr: 10832,
    city: "Marcus Island",
    city_code: "MUS",
    airport_name: "Marcus Island",
    country: "Japan",
  },
  {
    sr: 10834,
    city: "Mardin",
    city_code: "MQM",
    airport_name: "Mardin",
    country: "Turkey",
  },
  {
    sr: 10836,
    city: "Mare",
    city_code: "MEE",
    airport_name: "Mare",
    country: "New Caledonia",
  },
  {
    sr: 10838,
    city: "Mareb",
    city_code: "MYN",
    airport_name: "Mareb",
    country: "Yemen",
  },
  {
    sr: 10840,
    city: "Mareeba",
    city_code: "MRG",
    airport_name: "Mareeba",
    country: "Australia",
  },
  {
    sr: 10842,
    city: "Marfa",
    city_code: "MRF",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 10846,
    city: "Margaret River",
    city_code: "MQZ",
    airport_name: "Margaret River",
    country: "Australia",
  },
  {
    sr: 10848,
    city: "Margaret River Station",
    city_code: "MGV",
    airport_name: "Margaret River",
    country: "Australia",
  },
  {
    sr: 10850,
    city: "Margarima",
    city_code: "MGG",
    airport_name: "Margarima",
    country: "Papua New Guinea",
  },
  {
    sr: 10852,
    city: "Margate",
    city_code: "MGH",
    airport_name: "Margate",
    country: "South Africa",
  },
  {
    sr: 10854,
    city: "Marguerite Bay",
    city_code: "RTE",
    airport_name: "Marguerite Bay",
    country: "United States",
  },
  {
    sr: 10856,
    city: "Marianske Lazne",
    city_code: "MKA",
    airport_name: "Marianske Lazne",
    country: "Czechoslovakia  former",
  },
  {
    sr: 10858,
    city: "Maribo",
    city_code: "MRW",
    airport_name: "Maribo",
    country: "Denmark",
  },
  {
    sr: 10860,
    city: "Maribor",
    city_code: "MBX",
    airport_name: "Maribor",
    country: "Slovenia",
  },
  {
    sr: 10862,
    city: "Maricourt Airstrip",
    city_code: "YMC",
    airport_name: "Maricourt Airstrip",
    country: "Canada",
  },
  {
    sr: 10864,
    city: "Marie Galante",
    city_code: "GBJ",
    airport_name: "Les Bases",
    country: "Guadeloupe",
  },
  {
    sr: 10866,
    city: "Mariehamn",
    city_code: "MHQ",
    airport_name: "Mariehamn",
    country: "Finland",
  },
  {
    sr: 10868,
    city: "Marietta",
    city_code: "NCQ",
    airport_name: "Atlanta NAS",
    country: "United States",
  },
  {
    sr: 10870,
    city: "Marietta",
    city_code: "MGE",
    airport_name: "Dobbins AFB",
    country: "United States",
  },
  {
    sr: 10872,
    city: "Marilia",
    city_code: "MII",
    airport_name: "Dr Gastao Vidigal",
    country: "Brazil",
  },
  {
    sr: 10874,
    city: "Marina Di Massa",
    city_code: "QMM",
    airport_name: "Marina Di Massa",
    country: "Italy",
  },
  {
    sr: 10876,
    city: "Marinduque",
    city_code: "MRQ",
    airport_name: "Marinduque",
    country: "Philippines",
  },
  {
    sr: 10880,
    city: "Maringa",
    city_code: "MGF",
    airport_name: "Regional De Maringa SBMG",
    country: "Brazil",
  },
  {
    sr: 10882,
    city: "Marion",
    city_code: "MWA",
    airport_name: "Williamson County",
    country: "United States",
  },
  {
    sr: 10884,
    city: "Marion",
    city_code: "MZZ",
    airport_name: "Marion",
    country: "United States",
  },
  {
    sr: 10886,
    city: "Marion",
    city_code: "MNN",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 10888,
    city: "Marion Downs",
    city_code: "MXD",
    airport_name: "Marion Downs",
    country: "Australia",
  },
  {
    sr: 10890,
    city: "Maripasoula",
    city_code: "MPY",
    airport_name: "Maripasoula",
    country: "French Guiana",
  },
  {
    sr: 10892,
    city: "Mariquita",
    city_code: "MQU",
    airport_name: "Mariquita",
    country: "Colombia",
  },
  {
    sr: 10894,
    city: "Mariscal Estigarribia",
    city_code: "ESG",
    airport_name: "Mariscal Estigarribia",
    country: "Paraguay",
  },
  {
    sr: 10896,
    city: "Mariupol",
    city_code: "MPW",
    airport_name: "Mariupol",
    country: "Ukraine",
  },
  {
    sr: 10898,
    city: "Marks",
    city_code: "MMS",
    airport_name: "Selfs",
    country: "United States",
  },
  {
    sr: 10900,
    city: "Marla",
    city_code: "MRP",
    airport_name: "Marla",
    country: "Australia",
  },
  {
    sr: 10902,
    city: "Marlborough",
    city_code: "MXG",
    airport_name: "Marlborough",
    country: "United States",
  },
  {
    sr: 10904,
    city: "Marmul",
    city_code: "OMM",
    airport_name: "Marmul",
    country: "Oman",
  },
  {
    sr: 10906,
    city: "Maroantsetra",
    city_code: "WMN",
    airport_name: "Maroantsetra",
    country: "Madagascar",
  },
  {
    sr: 10908,
    city: "Maron",
    city_code: "MNP",
    airport_name: "Maron",
    country: "Papua New Guinea",
  },
  {
    sr: 10910,
    city: "Maroua",
    city_code: "MVR",
    airport_name: "Salam",
    country: "Cameroon",
  },
  {
    sr: 10914,
    city: "Marqua",
    city_code: "MQE",
    airport_name: "Marqua",
    country: "Australia",
  },
  {
    sr: 10916,
    city: "Marquette",
    city_code: "MQT",
    airport_name: "Sawyer International",
    country: "United States",
  },
  {
    sr: 10918,
    city: "Marrakech",
    city_code: "RAK",
    airport_name: "Menara",
    country: "Morocco",
  },
  {
    sr: 10920,
    city: "Marree",
    city_code: "RRE",
    airport_name: "Marree",
    country: "Australia",
  },
  {
    sr: 10922,
    city: "Marromeu",
    city_code: "RRM",
    airport_name: "Marromeu",
    country: "Mozambique",
  },
  {
    sr: 10924,
    city: "Marsa Alam",
    city_code: "RMF",
    airport_name: "Marsa Alam",
    country: "Egypt",
  },
  {
    sr: 10926,
    city: "Marsa Brega",
    city_code: "LMQ",
    airport_name: "Marsa Brega",
    country: "Libya",
  },
  {
    sr: 10928,
    city: "Marsabit",
    city_code: "RBT",
    airport_name: "Marsabit",
    country: "Kenya",
  },
  {
    sr: 10930,
    city: "Marseille",
    city_code: "MRS",
    airport_name: "Marseille Provence Airport",
    country: "France",
  },
  {
    sr: 10932,
    city: "Marseille",
    city_code: "XRF",
    airport_name: "Marseille Railway",
    country: "France",
  },
  {
    sr: 10934,
    city: "Marsh Harbour",
    city_code: "MHH",
    airport_name: "International",
    country: "Bahamas",
  },
  {
    sr: 10936,
    city: "Marshall",
    city_code: "MLL",
    airport_name: "Marshall",
    country: "United States",
  },
  {
    sr: 10938,
    city: "Marshall",
    city_code: "MML",
    airport_name: "Municipal Ryan Field",
    country: "United States",
  },
  {
    sr: 10940,
    city: "Marshall",
    city_code: "MHL",
    airport_name: "Memorial Municipal",
    country: "United States",
  },
  {
    sr: 10942,
    city: "Marshall",
    city_code: "ASL",
    airport_name: "Harrison County",
    country: "United States",
  },
  {
    sr: 10944,
    city: "Marshalltown",
    city_code: "MIW",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 10948,
    city: "Marshfield",
    city_code: "MFI",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 10950,
    city: "Martinsburg",
    city_code: "AOO",
    airport_name: "Martinsburg",
    country: "United States",
  },
  {
    sr: 10952,
    city: "Martinsburg",
    city_code: "MRB",
    airport_name: "Eastern Wv Regional Airport Shepherd Field",
    country: "United States",
  },
  {
    sr: 10954,
    city: "Marudi",
    city_code: "MUR",
    airport_name: "Marudi",
    country: "Malaysia",
  },
  {
    sr: 10956,
    city: "Mary",
    city_code: "MYP",
    airport_name: "Mary",
    country: "Turkmenistan",
  },
  {
    sr: 10958,
    city: "Maryborough",
    city_code: "MBH",
    airport_name: "Maryborough",
    country: "Australia",
  },
  {
    sr: 10960,
    city: "Marysville",
    city_code: "BAB",
    airport_name: "Beale AFB",
    country: "United States",
  },
  {
    sr: 10962,
    city: "Marysville",
    city_code: "MYV",
    airport_name: "Yuba County",
    country: "United States",
  },
  {
    sr: 10964,
    city: "Masa",
    city_code: "MBV",
    airport_name: "Masa",
    country: "Papua New Guinea",
  },
  {
    sr: 10966,
    city: "Masada",
    city_code: "MTZ",
    airport_name: "Masada",
    country: "Israel",
  },
  {
    sr: 10968,
    city: "Masalembo",
    city_code: "MSI",
    airport_name: "Masalembo",
    country: "Indonesia",
  },
  {
    sr: 10970,
    city: "Masamba",
    city_code: "MXB",
    airport_name: "Masamba",
    country: "Indonesia",
  },
  {
    sr: 10972,
    city: "Masasi",
    city_code: "XMI",
    airport_name: "Masasi",
    country: "Tanzania",
  },
  {
    sr: 10974,
    city: "Masbate",
    city_code: "MBT",
    airport_name: "Masbate",
    country: "Philippines",
  },
  {
    sr: 10976,
    city: "Mascara",
    city_code: "MUW",
    airport_name: "Mascara",
    country: "Algeria",
  },
  {
    sr: 10978,
    city: "Maseru",
    city_code: "MSU",
    airport_name: "Moshoeshoe Intl",
    country: "Lesotho",
  },
  {
    sr: 10982,
    city: "Mashad",
    city_code: "MHD",
    airport_name: "Mashad",
    country: "Iran",
  },
  {
    sr: 10984,
    city: "Masi Manimba",
    city_code: "MSM",
    airport_name: "Masi Manimba",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 10986,
    city: "Masindi",
    city_code: "KCU",
    airport_name: "Masindi",
    country: "Uganda",
  },
  {
    sr: 10988,
    city: "Masirah",
    city_code: "MSH",
    airport_name: "Masirah",
    country: "Oman",
  },
  {
    sr: 10990,
    city: "Mason City",
    city_code: "MCW",
    airport_name: "Mason City",
    country: "United States",
  },
  {
    sr: 10992,
    city: "Massawa",
    city_code: "MSW",
    airport_name: "Massawa",
    country: "Ethiopia",
  },
  {
    sr: 10994,
    city: "Massena",
    city_code: "MSS",
    airport_name: "Richards Field",
    country: "United States",
  },
  {
    sr: 10996,
    city: "Masset",
    city_code: "ZMT",
    airport_name: "Masset",
    country: "Canada",
  },
  {
    sr: 10998,
    city: "Masterton",
    city_code: "MRO",
    airport_name: "Masterton",
    country: "New Zealand",
  },
  {
    sr: 11000,
    city: "Mastic Point",
    city_code: "MSK",
    airport_name: "Mastic Point",
    country: "Bahamas",
  },
  {
    sr: 11002,
    city: "Masvingo",
    city_code: "MVZ",
    airport_name: "Masvingo",
    country: "Zimbabwe",
  },
  {
    sr: 11004,
    city: "Matadi",
    city_code: "MAT",
    airport_name: "Matadi",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 11006,
    city: "Matagami",
    city_code: "YNM",
    airport_name: "Matagami",
    country: "Canada",
  },
  {
    sr: 11008,
    city: "Matagorda Island",
    city_code: "MGI",
    airport_name: "Matagorda AFB",
    country: "United States",
  },
  {
    sr: 11010,
    city: "Mataiva",
    city_code: "MVT",
    airport_name: "Mataiva",
    country: "French Polynesia",
  },
  {
    sr: 11012,
    city: "Matak",
    city_code: "MWK",
    airport_name: "Matak",
    country: "Indonesia",
  },
  {
    sr: 11016,
    city: "Matam",
    city_code: "MAX",
    airport_name: "Matam",
    country: "Senegal",
  },
  {
    sr: 11018,
    city: "Matamata",
    city_code: "MTA",
    airport_name: "Matamata",
    country: "New Zealand",
  },
  {
    sr: 11020,
    city: "Matamoros",
    city_code: "MAM",
    airport_name: "Matamoros",
    country: "Mexico",
  },
  {
    sr: 11022,
    city: "Matane",
    city_code: "YME",
    airport_name: "Matane",
    country: "Canada",
  },
  {
    sr: 11024,
    city: "Matanzas",
    city_code: "VRO",
    airport_name: "Kawama",
    country: "Cuba",
  },
  {
    sr: 11026,
    city: "Mataram",
    city_code: "AMI",
    airport_name: "Selaparang",
    country: "Indonesia",
  },
  {
    sr: 11028,
    city: "Mati",
    city_code: "MXI",
    airport_name: "Mati",
    country: "Philippines",
  },
  {
    sr: 11030,
    city: "Mato Grosso",
    city_code: "MTG",
    airport_name: "Mato Grosso",
    country: "Brazil",
  },
  {
    sr: 11032,
    city: "Matsaile",
    city_code: "MSG",
    airport_name: "Matsaile",
    country: "Lesotho",
  },
  {
    sr: 11034,
    city: "Matsu",
    city_code: "MFK",
    airport_name: "Matsu",
    country: "Taiwan",
  },
  {
    sr: 11036,
    city: "Matsumoto",
    city_code: "MMJ",
    airport_name: "Matsumoto",
    country: "Japan",
  },
  {
    sr: 11038,
    city: "Matsuyama",
    city_code: "MYJ",
    airport_name: "Matsuyama",
    country: "Japan",
  },
  {
    sr: 11040,
    city: "Matthews Ridge",
    city_code: "MWJ",
    airport_name: "Matthews Ridge",
    country: "Guyana",
  },
  {
    sr: 11042,
    city: "Mattoon",
    city_code: "MTO",
    airport_name: "Coles County Memorial",
    country: "United States",
  },
  {
    sr: 11044,
    city: "Matupa",
    city_code: "MBK",
    airport_name: "Matupa",
    country: "Brazil",
  },
  {
    sr: 11046,
    city: "Maturin",
    city_code: "MUN",
    airport_name: "Quiriquire",
    country: "Venezuela",
  },
  {
    sr: 11050,
    city: "Maues",
    city_code: "MBZ",
    airport_name: "Maues",
    country: "Brazil",
  },
  {
    sr: 11052,
    city: "Mauke Island",
    city_code: "MUK",
    airport_name: "Mauke Island",
    country: "Cook Islands",
  },
  {
    sr: 11054,
    city: "Maulmyine",
    city_code: "MNU",
    airport_name: "Maulmyine",
    country: "Myanmar",
  },
  {
    sr: 11056,
    city: "Maumere",
    city_code: "MOF",
    airport_name: "Waioti",
    country: "Indonesia",
  },
  {
    sr: 11058,
    city: "Maun",
    city_code: "MUB",
    airport_name: "Maun",
    country: "Botswana",
  },
  {
    sr: 11060,
    city: "Maupiti",
    city_code: "MAU",
    airport_name: "Maupiti",
    country: "French Polynesia",
  },
  {
    sr: 11062,
    city: "Mauritius",
    city_code: "MRU",
    airport_name: "S Seewoosagur Ram Int",
    country: "Mauritius",
  },
  {
    sr: 11064,
    city: "Maxton",
    city_code: "MXE",
    airport_name: "Maxton",
    country: "United States",
  },
  {
    sr: 11066,
    city: "May Creek",
    city_code: "MYK",
    airport_name: "May Creek",
    country: "United States",
  },
  {
    sr: 11068,
    city: "May River",
    city_code: "MRH",
    airport_name: "May River",
    country: "Papua New Guinea",
  },
  {
    sr: 11070,
    city: "Mayaguana",
    city_code: "MYG",
    airport_name: "Mayaguana",
    country: "Bahamas",
  },
  {
    sr: 11072,
    city: "Mayaguez",
    city_code: "MAZ",
    airport_name: "Eugenio M De Hostos",
    country: "Puerto Rico",
  },
  {
    sr: 11074,
    city: "Mayajigua",
    city_code: "MJG",
    airport_name: "Mayajigua",
    country: "Cuba",
  },
  {
    sr: 11076,
    city: "Mayo",
    city_code: "YMA",
    airport_name: "Mayo",
    country: "Canada",
  },
  {
    sr: 11078,
    city: "Mayoumba",
    city_code: "MYB",
    airport_name: "Mayoumba",
    country: "Gabon",
  },
  {
    sr: 11080,
    city: "Mayport",
    city_code: "NRB",
    airport_name: "Ns",
    country: "United States",
  },
  {
    sr: 11084,
    city: "Mazar I Sharif",
    city_code: "MZR",
    airport_name: "Mazar I Sharif",
    country: "Afghanistan",
  },
  {
    sr: 11086,
    city: "Mazatlan",
    city_code: "MZT",
    airport_name: "Gen Rafael Buelna",
    country: "Mexico",
  },
  {
    sr: 11088,
    city: "Mbala",
    city_code: "MMQ",
    airport_name: "Mbala",
    country: "Zambia",
  },
  {
    sr: 11090,
    city: "Mbambanakira",
    city_code: "MBU",
    airport_name: "Mbambanakira",
    country: "Solomon Islands",
  },
  {
    sr: 11092,
    city: "Mbandaka",
    city_code: "MDK",
    airport_name: "Mbandaka",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 11094,
    city: "Mbarara",
    city_code: "MBQ",
    airport_name: "Mbarara",
    country: "Uganda",
  },
  {
    sr: 11096,
    city: "Mbeya",
    city_code: "MBI",
    airport_name: "Mbeya",
    country: "Tanzania",
  },
  {
    sr: 11098,
    city: "Mbigou",
    city_code: "MBC",
    airport_name: "Mbigou",
    country: "Gabon",
  },
  {
    sr: 11100,
    city: "Mbout",
    city_code: "MBR",
    airport_name: "Mbout",
    country: "Mauritania",
  },
  {
    sr: 11102,
    city: "Mbuji Mayi",
    city_code: "MJM",
    airport_name: "Mbuji Mayi",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 11104,
    city: "Mc Alester",
    city_code: "MLC",
    airport_name: "Mc Alester",
    country: "United States",
  },
  {
    sr: 11106,
    city: "Mc Rae",
    city_code: "MQW",
    airport_name: "Telfair Wheeler",
    country: "United States",
  },
  {
    sr: 11108,
    city: "Mcarthur River",
    city_code: "MCV",
    airport_name: "Mcarthur River",
    country: "Australia",
  },
  {
    sr: 11110,
    city: "Mccall",
    city_code: "MYL",
    airport_name: "Mccall",
    country: "United States",
  },
  {
    sr: 11112,
    city: "Mccarthy",
    city_code: "MXY",
    airport_name: "Mccarthy",
    country: "United States",
  },
  {
    sr: 11114,
    city: "Mccomb",
    city_code: "MCB",
    airport_name: "Pike County",
    country: "United States",
  },
  {
    sr: 11118,
    city: "Mccook",
    city_code: "MCK",
    airport_name: "Mccook",
    country: "United States",
  },
  {
    sr: 11120,
    city: "Mcgrath",
    city_code: "MCG",
    airport_name: "Mcgrath",
    country: "United States",
  },
  {
    sr: 11122,
    city: "Mcminnville",
    city_code: "RNC",
    airport_name: "Warren County",
    country: "United States",
  },
  {
    sr: 11124,
    city: "Mcpherson",
    city_code: "MPR",
    airport_name: "Mcpherson",
    country: "United States",
  },
  {
    sr: 11126,
    city: "Meadow Lake",
    city_code: "YLJ",
    airport_name: "Meadow Lake",
    country: "Canada",
  },
  {
    sr: 11128,
    city: "Meadville",
    city_code: "MEJ",
    airport_name: "Meadville",
    country: "United States",
  },
  {
    sr: 11130,
    city: "Mechria",
    city_code: "MZW",
    airport_name: "Mechria",
    country: "Algeria",
  },
  {
    sr: 11132,
    city: "Medan",
    city_code: "MES",
    airport_name: "Polania",
    country: "Indonesia",
  },
  {
    sr: 11134,
    city: "Medellin",
    city_code: "EOH",
    airport_name: "Enrique Olaya Herrera International Airport",
    country: "Colombia",
  },
  {
    sr: 11136,
    city: "Medellin",
    city_code: "MDE",
    airport_name: "Jose Marie Cordova",
    country: "Colombia",
  },
  {
    sr: 11138,
    city: "Medford",
    city_code: "MFR",
    airport_name: "Jackson County",
    country: "United States",
  },
  {
    sr: 11140,
    city: "Medford",
    city_code: "MDF",
    airport_name: "Medford",
    country: "United States",
  },
  {
    sr: 11142,
    city: "Medfra",
    city_code: "MDR",
    airport_name: "Medfra",
    country: "United States",
  },
  {
    sr: 11144,
    city: "Medicine Hat",
    city_code: "YXH",
    airport_name: "Medicine Hat",
    country: "Canada",
  },
  {
    sr: 11146,
    city: "Medina",
    city_code: "MND",
    airport_name: "Medina",
    country: "Colombia",
  },
  {
    sr: 11148,
    city: "Medouneu",
    city_code: "MDV",
    airport_name: "Medouneu",
    country: "Gabon",
  },
  {
    sr: 11152,
    city: "Meekatharra",
    city_code: "MKR",
    airport_name: "Meekatharra",
    country: "Australia",
  },
  {
    sr: 11154,
    city: "Megeve",
    city_code: "MVV",
    airport_name: "Megeve",
    country: "France",
  },
  {
    sr: 11156,
    city: "Meghauli",
    city_code: "MEY",
    airport_name: "Meghauli",
    country: "Nepal",
  },
  {
    sr: 11158,
    city: "Mehamn",
    city_code: "MEH",
    airport_name: "Mehamn",
    country: "Norway",
  },
  {
    sr: 11160,
    city: "Meixian",
    city_code: "MXZ",
    airport_name: "Meixian",
    country: "China",
  },
  {
    sr: 11162,
    city: "Mejit Island",
    city_code: "MJB",
    airport_name: "Mejit Island",
    country: "Marshall Islands",
  },
  {
    sr: 11164,
    city: "Mekambo",
    city_code: "MKB",
    airport_name: "Mekambo",
    country: "Gabon",
  },
  {
    sr: 11166,
    city: "Mekane Selam",
    city_code: "MKS",
    airport_name: "Mekane Selam",
    country: "Ethiopia",
  },
  {
    sr: 11168,
    city: "Meknes",
    city_code: "MEK",
    airport_name: "Meknes",
    country: "Morocco",
  },
  {
    sr: 11170,
    city: "Mekoryuk",
    city_code: "MYU",
    airport_name: "Ellis Field",
    country: "United States",
  },
  {
    sr: 11172,
    city: "Melangguane",
    city_code: "MNA",
    airport_name: "Melangguane",
    country: "Indonesia",
  },
  {
    sr: 11174,
    city: "Melbourne",
    city_code: "MLB",
    airport_name: "Melbourne Intl",
    country: "United States",
  },
  {
    sr: 11176,
    city: "Geelong",
    city_code: "AVV",
    airport_name: "Avalon",
    country: "Australia",
  },
  {
    sr: 11178,
    city: "Melbourne",
    city_code: "KAH",
    airport_name: "City Heliport",
    country: "Australia",
  },
  {
    sr: 11180,
    city: "Melbourne",
    city_code: "MEB",
    airport_name: "Essendon",
    country: "Australia",
  },
  {
    sr: 11182,
    city: "Melbourne",
    city_code: "MEL",
    airport_name: "Tullamarine",
    country: "Australia",
  },
  {
    sr: 11186,
    city: "Melchor De Menco",
    city_code: "MCR",
    airport_name: "Melchor De Menco",
    country: "Guatemala",
  },
  {
    sr: 11188,
    city: "Melfa",
    city_code: "MFV",
    airport_name: "Accomack County",
    country: "United States",
  },
  {
    sr: 11190,
    city: "Melfi",
    city_code: "MEF",
    airport_name: "Melfi",
    country: "Chad",
  },
  {
    sr: 11192,
    city: "Melilla",
    city_code: "MLN",
    airport_name: "Melilla",
    country: "Spain",
  },
  {
    sr: 11194,
    city: "Melinda",
    city_code: "MDB",
    airport_name: "Melinda",
    country: "Belize",
  },
  {
    sr: 11196,
    city: "Melo",
    city_code: "MLZ",
    airport_name: "Melo",
    country: "Uruguay",
  },
  {
    sr: 11198,
    city: "Memanbetsu",
    city_code: "MMB",
    airport_name: "Memanbetsu",
    country: "Japan",
  },
  {
    sr: 11200,
    city: "Memphis",
    city_code: "MEM",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 11202,
    city: "Memphis",
    city_code: "NQA",
    airport_name: "NAS",
    country: "United States",
  },
  {
    sr: 11204,
    city: "Mena",
    city_code: "MZX",
    airport_name: "Mena",
    country: "Ethiopia",
  },
  {
    sr: 11206,
    city: "Mende",
    city_code: "MEN",
    airport_name: "Brenoux",
    country: "France",
  },
  {
    sr: 11208,
    city: "Mendez",
    city_code: "MZD",
    airport_name: "Mendez",
    country: "Ecuador",
  },
  {
    sr: 11210,
    city: "Mendi",
    city_code: "NDM",
    airport_name: "Mendi",
    country: "Ethiopia",
  },
  {
    sr: 11212,
    city: "Mendi",
    city_code: "MDU",
    airport_name: "Mendi",
    country: "Papua New Guinea",
  },
  {
    sr: 11214,
    city: "Mendoza",
    city_code: "MDZ",
    airport_name: "El Plumerillo",
    country: "Argentina",
  },
  {
    sr: 11216,
    city: "Menominee",
    city_code: "MNM",
    airport_name: "Menominee",
    country: "United States",
  },
  {
    sr: 11220,
    city: "Menongue",
    city_code: "SPP",
    airport_name: "Menongue",
    country: "Angola",
  },
  {
    sr: 11222,
    city: "Menorca",
    city_code: "MAH",
    airport_name: "Menorca",
    country: "Spain",
  },
  {
    sr: 11224,
    city: "Menyamya",
    city_code: "MYX",
    airport_name: "Menyamya",
    country: "Papua New Guinea",
  },
  {
    sr: 11226,
    city: "Meran",
    city_code: "ZMR",
    airport_name: "Bus Station",
    country: "Italy",
  },
  {
    sr: 11228,
    city: "Merauke",
    city_code: "MKQ",
    airport_name: "Mopah",
    country: "Indonesia",
  },
  {
    sr: 11230,
    city: "Merced",
    city_code: "MER",
    airport_name: "Castle AFB",
    country: "United States",
  },
  {
    sr: 11232,
    city: "Merced",
    city_code: "MCE",
    airport_name: "Merced Municipal Arpt",
    country: "United States",
  },
  {
    sr: 11234,
    city: "Mercedes",
    city_code: "MDX",
    airport_name: "Mercedes",
    country: "Argentina",
  },
  {
    sr: 11236,
    city: "Mercury",
    city_code: "DRA",
    airport_name: "Desert Rock",
    country: "United States",
  },
  {
    sr: 11238,
    city: "Merdey",
    city_code: "RDE",
    airport_name: "Merdey",
    country: "Indonesia",
  },
  {
    sr: 11240,
    city: "Meribel",
    city_code: "MFX",
    airport_name: "Meribel",
    country: "France",
  },
  {
    sr: 11242,
    city: "Merida",
    city_code: "MID",
    airport_name: "Rejon",
    country: "Mexico",
  },
  {
    sr: 11244,
    city: "Merida",
    city_code: "MRD",
    airport_name: "A Carnevalli",
    country: "Venezuela",
  },
  {
    sr: 11246,
    city: "Meridian",
    city_code: "MEI",
    airport_name: "Key Field",
    country: "United States",
  },
  {
    sr: 11248,
    city: "Merimbula",
    city_code: "MIM",
    airport_name: "Merimbula",
    country: "Australia",
  },
  {
    sr: 11250,
    city: "Merlo",
    city_code: "RLO",
    airport_name: "Valle Del Conlara",
    country: "Argentina",
  },
  {
    sr: 11254,
    city: "Merluna",
    city_code: "MLV",
    airport_name: "Merluna",
    country: "Australia",
  },
  {
    sr: 11256,
    city: "Merowe",
    city_code: "MWE",
    airport_name: "Merowe",
    country: "Sudan",
  },
  {
    sr: 11258,
    city: "Merrill",
    city_code: "RRL",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 11260,
    city: "Merritt",
    city_code: "YMB",
    airport_name: "Merritt",
    country: "Canada",
  },
  {
    sr: 11262,
    city: "Merry Island",
    city_code: "YMR",
    airport_name: "Merry Island",
    country: "Canada",
  },
  {
    sr: 11264,
    city: "Mersa Matruh",
    city_code: "MUH",
    airport_name: "Mersa Matruh",
    country: "Egypt",
  },
  {
    sr: 11266,
    city: "Mersing",
    city_code: "MEP",
    airport_name: "Mersing",
    country: "Malaysia",
  },
  {
    sr: 11268,
    city: "Merty",
    city_code: "RTY",
    airport_name: "Merty",
    country: "Australia",
  },
  {
    sr: 11270,
    city: "Merzifon",
    city_code: "MZH",
    airport_name: "Merzifon",
    country: "Turkey",
  },
  {
    sr: 11272,
    city: "Mesa",
    city_code: "MSC",
    airport_name: "Falcon Field",
    country: "United States",
  },
  {
    sr: 11274,
    city: "Mesalia",
    city_code: "MFZ",
    airport_name: "Mesalia",
    country: "Papua New Guinea",
  },
  {
    sr: 11276,
    city: "Mesquite",
    city_code: "MFH",
    airport_name: "Mesquite",
    country: "United States",
  },
  {
    sr: 11278,
    city: "Messina",
    city_code: "MEZ",
    airport_name: "Messina",
    country: "South Africa",
  },
  {
    sr: 11280,
    city: "Metlakatla",
    city_code: "MTM",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 11282,
    city: "Metz",
    city_code: "MZM",
    airport_name: "Frescaty",
    country: "France",
  },
  {
    sr: 11284,
    city: "Metz Nancy",
    city_code: "ETZ",
    airport_name: "Metz Nancy Lorraine",
    country: "France",
  },
  {
    sr: 11288,
    city: "Meulaboh",
    city_code: "MEQ",
    airport_name: "Seunagan",
    country: "Indonesia",
  },
  {
    sr: 11290,
    city: "Mevang",
    city_code: "MVG",
    airport_name: "Mevang",
    country: "Gabon",
  },
  {
    sr: 11292,
    city: "Mexicali",
    city_code: "MXL",
    airport_name: "Mexicali",
    country: "Mexico",
  },
  {
    sr: 11294,
    city: "Mexico City",
    city_code: "AZP",
    airport_name: "Atizapan",
    country: "Mexico",
  },
  {
    sr: 11296,
    city: "Mexico City",
    city_code: "MEX",
    airport_name: "Juarez International",
    country: "Mexico",
  },
  {
    sr: 11298,
    city: "Mexico City",
    city_code: "NLU",
    airport_name: "Santa Lucia",
    country: "Mexico",
  },
  {
    sr: 11300,
    city: "Meyers Chuck",
    city_code: "WMK",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 11302,
    city: "Mfuwe",
    city_code: "MFU",
    airport_name: "Mfuwe",
    country: "Zambia",
  },
  {
    sr: 11304,
    city: "Miami",
    city_code: "TNT",
    airport_name: "Dade Collier",
    country: "United States",
  },
  {
    sr: 11306,
    city: "Miami",
    city_code: "JDM",
    airport_name: "Downtown Heliport",
    country: "United States",
  },
  {
    sr: 11308,
    city: "Miami",
    city_code: "MIA",
    airport_name: "Miami International Airport",
    country: "United States",
  },
  {
    sr: 11310,
    city: "Miami",
    city_code: "OPF",
    airport_name: "Opa Locka",
    country: "United States",
  },
  {
    sr: 11312,
    city: "Miami",
    city_code: "MPB",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 11314,
    city: "Miami",
    city_code: "TMB",
    airport_name: "Tamiami",
    country: "United States",
  },
  {
    sr: 11316,
    city: "Miami",
    city_code: "MIO",
    airport_name: "Miami",
    country: "United States",
  },
  {
    sr: 11318,
    city: "Mian Yang",
    city_code: "MIG",
    airport_name: "Mian Yang",
    country: "Germany",
  },
  {
    sr: 11322,
    city: "Miandrivazo",
    city_code: "ZVA",
    airport_name: "Miandrivazo",
    country: "Madagascar",
  },
  {
    sr: 11324,
    city: "Mianwali",
    city_code: "MWD",
    airport_name: "Mianwali",
    country: "Pakistan",
  },
  {
    sr: 11326,
    city: "Michigan City",
    city_code: "MGC",
    airport_name: "Michigan City Arpt",
    country: "United States",
  },
  {
    sr: 11328,
    city: "Middle Caicos",
    city_code: "MDS",
    airport_name: "Middle Caicos",
    country: "Turks and Caicos Islands",
  },
  {
    sr: 11330,
    city: "Middlemount",
    city_code: "MMM",
    airport_name: "Middlemount",
    country: "Australia",
  },
  {
    sr: 11332,
    city: "Middleton Island",
    city_code: "MDO",
    airport_name: "Intermediate",
    country: "United States",
  },
  {
    sr: 11334,
    city: "Middletown  Ohio",
    city_code: "MWO",
    airport_name: "Hook Field",
    country: "United States",
  },
  {
    sr: 11336,
    city: "Midgard",
    city_code: "MQG",
    airport_name: "Midgard",
    country: "Namibia",
  },
  {
    sr: 11338,
    city: "Midland",
    city_code: "MDD",
    airport_name: "Airpark",
    country: "United States",
  },
  {
    sr: 11340,
    city: "Midland",
    city_code: "MAF",
    airport_name: "Odessa Regional",
    country: "United States",
  },
  {
    sr: 11342,
    city: "Midway Island",
    city_code: "MDY",
    airport_name: "Sand Island Field",
    country: "US Minor Outlying Islands",
  },
  {
    sr: 11344,
    city: "Miele Mimbale",
    city_code: "GIM",
    airport_name: "Miele Mimbale",
    country: "Gabon",
  },
  {
    sr: 11346,
    city: "Mikkeli",
    city_code: "MIK",
    airport_name: "Mikkeli",
    country: "Finland",
  },
  {
    sr: 11348,
    city: "Mikonos",
    city_code: "JMK",
    airport_name: "Mikonos",
    country: "Greece",
  },
  {
    sr: 11350,
    city: "Milan",
    city_code: "XIK",
    airport_name: "Central Station",
    country: "Italy",
  },
  {
    sr: 11352,
    city: "Milan",
    city_code: "LIN",
    airport_name: "Linate",
    country: "Italy",
  },
  {
    sr: 11356,
    city: "Milan",
    city_code: "MXP",
    airport_name: "Malpensa",
    country: "Italy",
  },
  {
    sr: 11358,
    city: "Milan bergamo",
    city_code: "BGY",
    airport_name: "Orio Al Serio",
    country: "Italy",
  },
  {
    sr: 11360,
    city: "Parma",
    city_code: "PMF",
    airport_name: "Giuseppe Verdi",
    country: "Italy",
  },
  {
    sr: 11362,
    city: "Milan",
    city_code: "SWK",
    airport_name: "Segrate",
    country: "Italy",
  },
  {
    sr: 11364,
    city: "Mildenhall",
    city_code: "MHZ",
    airport_name: "Mildenhall Arpt",
    country: "United Kingdom",
  },
  {
    sr: 11366,
    city: "Mildenhall",
    city_code: "GXH",
    airport_name: "NAF",
    country: "United Kingdom",
  },
  {
    sr: 11368,
    city: "Mildura",
    city_code: "MQL",
    airport_name: "Mildura",
    country: "Australia",
  },
  {
    sr: 11370,
    city: "Miles City",
    city_code: "MLS",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 11372,
    city: "Milford",
    city_code: "MLF",
    airport_name: "Milford",
    country: "United States",
  },
  {
    sr: 11374,
    city: "Milford Sound",
    city_code: "MFN",
    airport_name: "Milford Sound",
    country: "New Zealand",
  },
  {
    sr: 11376,
    city: "Mili Atoll",
    city_code: "EJT",
    airport_name: "Enijet",
    country: "Marshall Islands",
  },
  {
    sr: 11378,
    city: "Mili Island",
    city_code: "MIJ",
    airport_name: "Mili Island",
    country: "Marshall Islands",
  },
  {
    sr: 11380,
    city: "Milingimbi",
    city_code: "MGT",
    airport_name: "Milingimbi",
    country: "Australia",
  },
  {
    sr: 11382,
    city: "Milledgeville",
    city_code: "MLJ",
    airport_name: "Baldwin County",
    country: "United States",
  },
  {
    sr: 11384,
    city: "Millicent",
    city_code: "MLR",
    airport_name: "Millicent",
    country: "Australia",
  },
  {
    sr: 11386,
    city: "Millinocket",
    city_code: "MLT",
    airport_name: "Millinocket",
    country: "United States",
  },
  {
    sr: 11390,
    city: "Millville",
    city_code: "MIV",
    airport_name: "Millville",
    country: "United States",
  },
  {
    sr: 11392,
    city: "Milos",
    city_code: "MLO",
    airport_name: "Milos",
    country: "Greece",
  },
  {
    sr: 11394,
    city: "Milton",
    city_code: "NSE",
    airport_name: "Whiting Field NAS",
    country: "United States",
  },
  {
    sr: 11396,
    city: "Milton Keynes",
    city_code: "KYN",
    airport_name: "Milton Keynes",
    country: "United Kingdom",
  },
  {
    sr: 11398,
    city: "Milwaukee",
    city_code: "ZML",
    airport_name: "Milwaukee",
    country: "United States",
  },
  {
    sr: 11400,
    city: "Milwaukee",
    city_code: "MKE",
    airport_name: "General Mitchell",
    country: "United States",
  },
  {
    sr: 11402,
    city: "Milwaukee",
    city_code: "MWC",
    airport_name: "Lawrence J Timmerman",
    country: "United States",
  },
  {
    sr: 11404,
    city: "Minacu",
    city_code: "MQH",
    airport_name: "Municipal",
    country: "Brazil",
  },
  {
    sr: 11406,
    city: "Minaki",
    city_code: "YMI",
    airport_name: "Minaki",
    country: "Canada",
  },
  {
    sr: 11408,
    city: "Minami Daito",
    city_code: "MMD",
    airport_name: "Maridor",
    country: "Japan",
  },
  {
    sr: 11410,
    city: "Minatitlan",
    city_code: "MTT",
    airport_name: "Minatitlan",
    country: "Mexico",
  },
  {
    sr: 11412,
    city: "Minchumina",
    city_code: "MHM",
    airport_name: "Intermediate",
    country: "United States",
  },
  {
    sr: 11414,
    city: "Minden",
    city_code: "MEV",
    airport_name: "Douglas County",
    country: "United States",
  },
  {
    sr: 11416,
    city: "Mindik",
    city_code: "MXK",
    airport_name: "Mindik",
    country: "Papua New Guinea",
  },
  {
    sr: 11418,
    city: "Mindiptana",
    city_code: "MDP",
    airport_name: "Mindiptana",
    country: "Indonesia",
  },
  {
    sr: 11420,
    city: "Mineral Wells",
    city_code: "CWO",
    airport_name: "Ft Wolter AAF",
    country: "United States",
  },
  {
    sr: 11424,
    city: "Mineral Wells",
    city_code: "MWL",
    airport_name: "Mineral Wells Arpt",
    country: "United States",
  },
  {
    sr: 11426,
    city: "Mineralnye Vody",
    city_code: "MRV",
    airport_name: "Mineralnye Vody",
    country: "Russia",
  },
  {
    sr: 11428,
    city: "Miners Bay",
    city_code: "YAV",
    airport_name: "Miners Bay",
    country: "Canada",
  },
  {
    sr: 11430,
    city: "Miners Lake",
    city_code: "MRL",
    airport_name: "Miners Lake",
    country: "Australia",
  },
  {
    sr: 11432,
    city: "Mingan",
    city_code: "YLP",
    airport_name: "Mingan",
    country: "Canada",
  },
  {
    sr: 11434,
    city: "Minhad Ab",
    city_code: "NHD",
    airport_name: "Military Airport",
    country: "United Arab Emirates",
  },
  {
    sr: 11436,
    city: "Minj",
    city_code: "MZN",
    airport_name: "Minj",
    country: "Papua New Guinea",
  },
  {
    sr: 11438,
    city: "Minlaton",
    city_code: "XML",
    airport_name: "Minlaton",
    country: "Australia",
  },
  {
    sr: 11440,
    city: "Minna",
    city_code: "MXJ",
    airport_name: "Minna",
    country: "Nigeria",
  },
  {
    sr: 11442,
    city: "Minneapolis",
    city_code: "MIC",
    airport_name: "Crystal",
    country: "United States",
  },
  {
    sr: 11444,
    city: "Minneapolis",
    city_code: "JDT",
    airport_name: "Downtown Heliport",
    country: "United States",
  },
  {
    sr: 11446,
    city: "Minneapolis",
    city_code: "FCM",
    airport_name: "Flying Cloud",
    country: "United States",
  },
  {
    sr: 11448,
    city: "Minneapolis",
    city_code: "MSP",
    airport_name: "St Paul Intl",
    country: "United States",
  },
  {
    sr: 11450,
    city: "Minneriya",
    city_code: "MNH",
    airport_name: "Minneriya",
    country: "Sri Lanka",
  },
  {
    sr: 11452,
    city: "Minnipa",
    city_code: "MIN",
    airport_name: "Minnipa",
    country: "Australia",
  },
  {
    sr: 11454,
    city: "Minocqua",
    city_code: "ARV",
    airport_name: "Noble F Lee",
    country: "United States",
  },
  {
    sr: 11458,
    city: "Minot",
    city_code: "MOT",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 11460,
    city: "Minot",
    city_code: "MIB",
    airport_name: "Minot AFB",
    country: "United States",
  },
  {
    sr: 11462,
    city: "Minsk",
    city_code: "MHP",
    airport_name: "Minsk International 1",
    country: "Belarus",
  },
  {
    sr: 11464,
    city: "Minsk",
    city_code: "MSQ",
    airport_name: "Minsk International Two",
    country: "Belarus",
  },
  {
    sr: 11466,
    city: "Minto",
    city_code: "MNT",
    airport_name: "Minto",
    country: "United States",
  },
  {
    sr: 11468,
    city: "Minvoul",
    city_code: "MVX",
    airport_name: "Minvoul",
    country: "Gabon",
  },
  {
    sr: 11470,
    city: "Miquelon",
    city_code: "MQC",
    airport_name: "Miquelon Aprt",
    country: "St  Pierre and Miquelon",
  },
  {
    sr: 11472,
    city: "Miracema Do Norte",
    city_code: "NTM",
    airport_name: "Miracema Do Norte",
    country: "Brazil",
  },
  {
    sr: 11474,
    city: "Miraflores",
    city_code: "MFS",
    airport_name: "Miraflores",
    country: "Colombia",
  },
  {
    sr: 11476,
    city: "Miramar",
    city_code: "MJR",
    airport_name: "Miramar",
    country: "Argentina",
  },
  {
    sr: 11478,
    city: "Miramichi",
    city_code: "YCH",
    airport_name: "Miramichi",
    country: "Canada",
  },
  {
    sr: 11480,
    city: "Miranda Downs",
    city_code: "MWY",
    airport_name: "Miranda Downs",
    country: "Australia",
  },
  {
    sr: 11482,
    city: "Mirgorod",
    city_code: "MXR",
    airport_name: "Mirgorod",
    country: "Ukraine",
  },
  {
    sr: 11484,
    city: "Miri",
    city_code: "MYY",
    airport_name: "Miri",
    country: "Malaysia",
  },
  {
    sr: 11486,
    city: "Miriti",
    city_code: "MIX",
    airport_name: "Miriti",
    country: "Colombia",
  },
  {
    sr: 11488,
    city: "Mirnyj",
    city_code: "MJZ",
    airport_name: "Mirnyj",
    country: "Russia",
  },
  {
    sr: 11492,
    city: "Mirpur Khas",
    city_code: "MPD",
    airport_name: "Mirpur Khas",
    country: "Pakistan",
  },
  {
    sr: 11494,
    city: "Misawa",
    city_code: "MSJ",
    airport_name: "Misawa",
    country: "Japan",
  },
  {
    sr: 11496,
    city: "Misima Island",
    city_code: "MIS",
    airport_name: "Misima Island",
    country: "Papua New Guinea",
  },
  {
    sr: 11498,
    city: "Miskolc",
    city_code: "MCQ",
    airport_name: "Miskolc",
    country: "Hungary",
  },
  {
    sr: 11500,
    city: "Misrak Gashamo",
    city_code: "MHJ",
    airport_name: "Misrak Gashamo",
    country: "Ethiopia",
  },
  {
    sr: 11502,
    city: "Mission",
    city_code: "MFE",
    airport_name: "Mission",
    country: "United States",
  },
  {
    sr: 11504,
    city: "Missoula",
    city_code: "MSO",
    airport_name: "Johnson Bell Field",
    country: "United States",
  },
  {
    sr: 11506,
    city: "Misurata",
    city_code: "MRA",
    airport_name: "Misurata",
    country: "Libya",
  },
  {
    sr: 11508,
    city: "Mitchell",
    city_code: "MTQ",
    airport_name: "Mitchell",
    country: "Australia",
  },
  {
    sr: 11510,
    city: "Mitchell",
    city_code: "MHE",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 11512,
    city: "Mitchell Plateau",
    city_code: "MIH",
    airport_name: "Mitchell Plateau",
    country: "Australia",
  },
  {
    sr: 11514,
    city: "Mitchell River",
    city_code: "MXQ",
    airport_name: "Mitchell River",
    country: "Australia",
  },
  {
    sr: 11516,
    city: "Mitiaro Island",
    city_code: "MOI",
    airport_name: "Mitiaro Island",
    country: "Cook Islands",
  },
  {
    sr: 11518,
    city: "Mitiga",
    city_code: "MJI",
    airport_name: "Mitiga",
    country: "Libya",
  },
  {
    sr: 11520,
    city: "Mitspeh Ramon",
    city_code: "MIP",
    airport_name: "Mitspeh Ramon",
    country: "Israel",
  },
  {
    sr: 11522,
    city: "Mittiebah",
    city_code: "MIY",
    airport_name: "Mittiebah",
    country: "Australia",
  },
  {
    sr: 11526,
    city: "Mitu",
    city_code: "MVP",
    airport_name: "Mitu",
    country: "Colombia",
  },
  {
    sr: 11528,
    city: "Mitzic",
    city_code: "MZC",
    airport_name: "Mitzic",
    country: "Gabon",
  },
  {
    sr: 11530,
    city: "Miyake Jima",
    city_code: "MYE",
    airport_name: "Miyake Jima",
    country: "Japan",
  },
  {
    sr: 11532,
    city: "Miyako Jima",
    city_code: "MMY",
    airport_name: "Hirara",
    country: "Japan",
  },
  {
    sr: 11534,
    city: "Miyanmin",
    city_code: "MPX",
    airport_name: "Miyanmin",
    country: "Papua New Guinea",
  },
  {
    sr: 11536,
    city: "Miyazaki",
    city_code: "KMI",
    airport_name: "Miyazaki",
    country: "Japan",
  },
  {
    sr: 11538,
    city: "Mizan Teferi",
    city_code: "MTF",
    airport_name: "Mizan Teferi",
    country: "Ethiopia",
  },
  {
    sr: 11540,
    city: "Mkambati",
    city_code: "MBM",
    airport_name: "Mkambati",
    country: "South Africa",
  },
  {
    sr: 11542,
    city: "Mkuze",
    city_code: "MZQ",
    airport_name: "Mkuze",
    country: "South Africa",
  },
  {
    sr: 11544,
    city: "Mmabatho",
    city_code: "MBD",
    airport_name: "International",
    country: "South Africa",
  },
  {
    sr: 11546,
    city: "Mo I Rana",
    city_code: "MQN",
    airport_name: "Mo I Rana",
    country: "Norway",
  },
  {
    sr: 11548,
    city: "Moa",
    city_code: "MOA",
    airport_name: "Orestes Acosta",
    country: "Cuba",
  },
  {
    sr: 11550,
    city: "Moab",
    city_code: "CNY",
    airport_name: "Canyonlands Field",
    country: "United States",
  },
  {
    sr: 11552,
    city: "Moabi",
    city_code: "MGX",
    airport_name: "Moabi",
    country: "Gabon",
  },
  {
    sr: 11554,
    city: "Moala",
    city_code: "MFJ",
    airport_name: "Moala",
    country: "Fiji",
  },
  {
    sr: 11556,
    city: "Moanamani",
    city_code: "ONI",
    airport_name: "Moanamani",
    country: "Indonesia",
  },
  {
    sr: 11560,
    city: "Moanda",
    city_code: "MNB",
    airport_name: "Moanda",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 11562,
    city: "Moanda",
    city_code: "MFF",
    airport_name: "Moanda",
    country: "Gabon",
  },
  {
    sr: 11564,
    city: "Moba",
    city_code: "BDV",
    airport_name: "Moba",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 11566,
    city: "Moberly",
    city_code: "MBY",
    airport_name: "Moberly",
    country: "United States",
  },
  {
    sr: 11568,
    city: "Mobile",
    city_code: "BFM",
    airport_name: "Mob Aerospace",
    country: "United States",
  },
  {
    sr: 11570,
    city: "Mobile",
    city_code: "MOB",
    airport_name: "Mobile Regional Arpt",
    country: "United States",
  },
  {
    sr: 11572,
    city: "Mobridge",
    city_code: "MBG",
    airport_name: "Mobridge",
    country: "United States",
  },
  {
    sr: 11574,
    city: "Mocimboa Praia",
    city_code: "MZB",
    airport_name: "Mocimboa Praia",
    country: "Mozambique",
  },
  {
    sr: 11576,
    city: "Modena",
    city_code: "ZMO",
    airport_name: "Bus Station",
    country: "Italy",
  },
  {
    sr: 11578,
    city: "Modesto",
    city_code: "MOD",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 11580,
    city: "Moelv",
    city_code: "XUJ",
    airport_name: "Moelv Railway",
    country: "Norway",
  },
  {
    sr: 11582,
    city: "Moengo",
    city_code: "MOJ",
    airport_name: "Moengo",
    country: "Suriname",
  },
  {
    sr: 11584,
    city: "Mogadishu",
    city_code: "MGQ",
    airport_name: "International",
    country: "Somalia",
  },
  {
    sr: 11586,
    city: "Mogilev",
    city_code: "MVQ",
    airport_name: "Mogilev",
    country: "Belarus",
  },
  {
    sr: 11588,
    city: "Mohanbari",
    city_code: "MOH",
    airport_name: "Mohanbari",
    country: "India",
  },
  {
    sr: 11590,
    city: "Moheli",
    city_code: "NWA",
    airport_name: "Moheli",
    country: "Comoros",
  },
  {
    sr: 11594,
    city: "Mohenjodaro",
    city_code: "MJD",
    airport_name: "Mohenjodaro",
    country: "Pakistan",
  },
  {
    sr: 11596,
    city: "Mojave",
    city_code: "MHV",
    airport_name: "Kern County",
    country: "United States",
  },
  {
    sr: 11598,
    city: "Mokhotlong",
    city_code: "MKH",
    airport_name: "Mokhotlong",
    country: "Lesotho",
  },
  {
    sr: 11600,
    city: "Moki",
    city_code: "MJJ",
    airport_name: "Moki",
    country: "Papua New Guinea",
  },
  {
    sr: 11602,
    city: "Mokpo",
    city_code: "MPK",
    airport_name: "Mokpo",
    country: "South Korea",
  },
  {
    sr: 11604,
    city: "Mokuti Lodge",
    city_code: "OKU",
    airport_name: "Mokuti Lodge",
    country: "Namibia",
  },
  {
    sr: 11606,
    city: "Molde",
    city_code: "MOL",
    airport_name: "Aro",
    country: "Norway",
  },
  {
    sr: 11608,
    city: "Moline",
    city_code: "MLI",
    airport_name: "Quad City",
    country: "United States",
  },
  {
    sr: 11610,
    city: "Moma",
    city_code: "MMW",
    airport_name: "Moma",
    country: "Mozambique",
  },
  {
    sr: 11612,
    city: "Mombasa",
    city_code: "MBA",
    airport_name: "Moi International",
    country: "Kenya",
  },
  {
    sr: 11614,
    city: "Momeik",
    city_code: "MOE",
    airport_name: "Momeik",
    country: "Myanmar",
  },
  {
    sr: 11616,
    city: "Mompos",
    city_code: "MMP",
    airport_name: "Mompos",
    country: "Colombia",
  },
  {
    sr: 11618,
    city: "Monahans",
    city_code: "MIF",
    airport_name: "Roy Hurd Memorial",
    country: "United States",
  },
  {
    sr: 11620,
    city: "Monbetsu",
    city_code: "MBE",
    airport_name: "Monbetsu",
    country: "Japan",
  },
  {
    sr: 11622,
    city: "Monclova",
    city_code: "LOV",
    airport_name: "Monclova",
    country: "Mexico",
  },
  {
    sr: 11624,
    city: "Monfort",
    city_code: "MFB",
    airport_name: "Monfort",
    country: "Colombia",
  },
  {
    sr: 11628,
    city: "Mong Hsat",
    city_code: "MOG",
    airport_name: "Mong Hsat",
    country: "Myanmar",
  },
  {
    sr: 11630,
    city: "Mong Ton",
    city_code: "MGK",
    airport_name: "Mong Ton",
    country: "Myanmar",
  },
  {
    sr: 11632,
    city: "Mongo",
    city_code: "MVO",
    airport_name: "Mongo",
    country: "Chad",
  },
  {
    sr: 11634,
    city: "Mongu",
    city_code: "MNR",
    airport_name: "Mongu",
    country: "Zambia",
  },
  {
    sr: 11636,
    city: "Monkey Bay",
    city_code: "MYZ",
    airport_name: "Monkey Bay",
    country: "Malawi",
  },
  {
    sr: 11638,
    city: "Monkey Mia",
    city_code: "MJK",
    airport_name: "Shark Bay",
    country: "Australia",
  },
  {
    sr: 11640,
    city: "Monkey Mountain",
    city_code: "MYM",
    airport_name: "Monkey Mountain",
    country: "Guyana",
  },
  {
    sr: 11642,
    city: "Monkira",
    city_code: "ONR",
    airport_name: "Monkira",
    country: "Australia",
  },
  {
    sr: 11644,
    city: "Mono",
    city_code: "MNY",
    airport_name: "Mono",
    country: "Solomon Islands",
  },
  {
    sr: 11646,
    city: "Monroe",
    city_code: "MLU",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 11648,
    city: "Monroe",
    city_code: "MVC",
    airport_name: "Monroe   Walton County",
    country: "United States",
  },
  {
    sr: 11650,
    city: "Monrovia",
    city_code: "ROB",
    airport_name: "Roberts Intl",
    country: "Liberia",
  },
  {
    sr: 11652,
    city: "Monrovia",
    city_code: "MLW",
    airport_name: "Sprigg Payne",
    country: "Liberia",
  },
  {
    sr: 11654,
    city: "Mont Joli",
    city_code: "YYY",
    airport_name: "Mont Joli",
    country: "Canada",
  },
  {
    sr: 11656,
    city: "Mont Tremblant",
    city_code: "YTM",
    airport_name: "La Macaza",
    country: "Canada",
  },
  {
    sr: 11658,
    city: "Montagne Harbor",
    city_code: "YMF",
    airport_name: "Montagne Harbor",
    country: "Canada",
  },
  {
    sr: 11662,
    city: "Montague",
    city_code: "SIY",
    airport_name: "Siskiyou County",
    country: "United States",
  },
  {
    sr: 11664,
    city: "Montauk",
    city_code: "MTP",
    airport_name: "Sky Portal",
    country: "United States",
  },
  {
    sr: 11666,
    city: "Monte Alegre",
    city_code: "MTE",
    airport_name: "Monte Alegre",
    country: "Brazil",
  },
  {
    sr: 11668,
    city: "Monte Carlo",
    city_code: "MCM",
    airport_name: "Heliport",
    country: "Monaco",
  },
  {
    sr: 11670,
    city: "Monte Caseros",
    city_code: "MCS",
    airport_name: "Monte Caseros",
    country: "Argentina",
  },
  {
    sr: 11672,
    city: "Monte Dourado",
    city_code: "MEU",
    airport_name: "Monte Dourado",
    country: "Brazil",
  },
  {
    sr: 11674,
    city: "Monte Libano",
    city_code: "MTB",
    airport_name: "Monte Libano",
    country: "Colombia",
  },
  {
    sr: 11676,
    city: "Monteagudo",
    city_code: "MHW",
    airport_name: "Monteagudo",
    country: "Bolivia",
  },
  {
    sr: 11678,
    city: "Montego Bay",
    city_code: "MBJ",
    airport_name: "Sangster International",
    country: "Jamaica",
  },
  {
    sr: 11680,
    city: "Montepuez",
    city_code: "MTU",
    airport_name: "Montepuez",
    country: "Mozambique",
  },
  {
    sr: 11682,
    city: "Chicago",
    city_code: "OAR",
    airport_name: "Ohara International",
    country: "United States",
  },
  {
    sr: 11684,
    city: "Monterey",
    city_code: "MRY",
    airport_name: "Monterey Peninsula",
    country: "United States",
  },
  {
    sr: 11686,
    city: "Monteria",
    city_code: "MTR",
    airport_name: "S Jeronimo",
    country: "Colombia",
  },
  {
    sr: 11688,
    city: "Monterrey",
    city_code: "MOY",
    airport_name: "Monterrey",
    country: "Colombia",
  },
  {
    sr: 11690,
    city: "Monterrey",
    city_code: "NTR",
    airport_name: "Aeropuerto Del Norte",
    country: "Mexico",
  },
  {
    sr: 11692,
    city: "Monterrey",
    city_code: "MTY",
    airport_name: "Gen Mariano Escobedo",
    country: "Mexico",
  },
  {
    sr: 11696,
    city: "Montes Claros",
    city_code: "MOC",
    airport_name: "Montes Claros",
    country: "Brazil",
  },
  {
    sr: 11698,
    city: "Montevideo",
    city_code: "MVE",
    airport_name: "Municipal",
    country: "MINNESOTA",
  },
  {
    sr: 11700,
    city: "Montevideo",
    city_code: "MVD",
    airport_name: "Carrasco International Airport",
    country: "Uruguay",
  },
  {
    sr: 11702,
    city: "Montgomery",
    city_code: "MGM",
    airport_name: "Dannelly Fld",
    country: "United States",
  },
  {
    sr: 11704,
    city: "Montgomery",
    city_code: "GUN",
    airport_name: "Gunter AFB",
    country: "United States",
  },
  {
    sr: 11706,
    city: "Montgomery",
    city_code: "MXF",
    airport_name: "Maxwell AFB",
    country: "United States",
  },
  {
    sr: 11708,
    city: "Montgomery",
    city_code: "MGJ",
    airport_name: "Orange County",
    country: "United States",
  },
  {
    sr: 11710,
    city: "Monticello",
    city_code: "MXO",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 11712,
    city: "Monticello",
    city_code: "MSV",
    airport_name: "Sullivan County Intl",
    country: "United States",
  },
  {
    sr: 11714,
    city: "Monticello",
    city_code: "MXC",
    airport_name: "San Juan County",
    country: "United States",
  },
  {
    sr: 11716,
    city: "Montilla",
    city_code: "OZU",
    airport_name: "Montilla",
    country: "Spain",
  },
  {
    sr: 11718,
    city: "Montlucon",
    city_code: "MCU",
    airport_name: "Gueret  Lepaud",
    country: "France",
  },
  {
    sr: 11720,
    city: "Monto",
    city_code: "MNQ",
    airport_name: "Monto",
    country: "Australia",
  },
  {
    sr: 11722,
    city: "Montpelier",
    city_code: "MPV",
    airport_name: "Edward F Knapp State",
    country: "United States",
  },
  {
    sr: 11724,
    city: "Montpellier",
    city_code: "MPL",
    airport_name: "Montpellier Mediterranee",
    country: "France",
  },
  {
    sr: 11726,
    city: "Montpellier",
    city_code: "XPJ",
    airport_name: "Montpellier Railway",
    country: "France",
  },
  {
    sr: 11730,
    city: "Montreal",
    city_code: "YMX",
    airport_name: "Mirabel",
    country: "Canada",
  },
  {
    sr: 11732,
    city: "Montreal",
    city_code: "YUL",
    airport_name: "Montreal International Airport",
    country: "Canada",
  },
  {
    sr: 11734,
    city: "Montreal",
    city_code: "YHU",
    airport_name: "St Hubert",
    country: "Canada",
  },
  {
    sr: 11736,
    city: "Montreal",
    city_code: "XLM",
    airport_name: "St Lambert Rail Svce",
    country: "Canada",
  },
  {
    sr: 11738,
    city: "Montrose",
    city_code: "MTJ",
    airport_name: "Montrose County",
    country: "United States",
  },
  {
    sr: 11740,
    city: "Montserrat",
    city_code: "MNI",
    airport_name: "Bramble",
    country: "Montserrat",
  },
  {
    sr: 11742,
    city: "Montvale",
    city_code: "QMV",
    airport_name: "Montvale",
    country: "United States",
  },
  {
    sr: 11744,
    city: "Monument Valley Gldngs",
    city_code: "GMV",
    airport_name: "Monument Valley",
    country: "United States",
  },
  {
    sr: 11746,
    city: "Moolawatana",
    city_code: "MWT",
    airport_name: "Moolawatana",
    country: "Australia",
  },
  {
    sr: 11748,
    city: "Moomba",
    city_code: "MOO",
    airport_name: "Moomba",
    country: "Australia",
  },
  {
    sr: 11750,
    city: "Moorabbin",
    city_code: "MBW",
    airport_name: "Moorabbin",
    country: "Australia",
  },
  {
    sr: 11752,
    city: "Mooraberree",
    city_code: "OOR",
    airport_name: "Mooraberree",
    country: "Australia",
  },
  {
    sr: 11754,
    city: "Moorea",
    city_code: "MOZ",
    airport_name: "Temae",
    country: "French Polynesia",
  },
  {
    sr: 11756,
    city: "Moose Jaw",
    city_code: "YMJ",
    airport_name: "Moose Jaw  Air Vice Marshal C M Mcewen Airport",
    country: "Canada",
  },
  {
    sr: 11758,
    city: "Moose Lake",
    city_code: "YAD",
    airport_name: "Moose Lake",
    country: "Canada",
  },
  {
    sr: 11760,
    city: "Moosonee",
    city_code: "YMO",
    airport_name: "Moosonee",
    country: "Canada",
  },
  {
    sr: 11764,
    city: "Mopti",
    city_code: "MZI",
    airport_name: "Mopti",
    country: "Mali",
  },
  {
    sr: 11766,
    city: "Mora",
    city_code: "MXX",
    airport_name: "Mora",
    country: "Sweden",
  },
  {
    sr: 11768,
    city: "Morafenobe",
    city_code: "TVA",
    airport_name: "Morafenobe",
    country: "Madagascar",
  },
  {
    sr: 11770,
    city: "Moranbah",
    city_code: "MOV",
    airport_name: "Moranbah",
    country: "Australia",
  },
  {
    sr: 11772,
    city: "Morawa",
    city_code: "MWB",
    airport_name: "Morawa",
    country: "Australia",
  },
  {
    sr: 11774,
    city: "Moree",
    city_code: "MRZ",
    airport_name: "Moree",
    country: "Australia",
  },
  {
    sr: 11776,
    city: "Morehead",
    city_code: "MHY",
    airport_name: "Morehead",
    country: "Papua New Guinea",
  },
  {
    sr: 11778,
    city: "Morelia",
    city_code: "MLM",
    airport_name: "Morelia",
    country: "Mexico",
  },
  {
    sr: 11780,
    city: "Moreton",
    city_code: "MET",
    airport_name: "Moreton",
    country: "Australia",
  },
  {
    sr: 11782,
    city: "Morganton",
    city_code: "MRN",
    airport_name: "Lenoir",
    country: "United States",
  },
  {
    sr: 11784,
    city: "Morgantown",
    city_code: "MGW",
    airport_name: "Morgantown",
    country: "United States",
  },
  {
    sr: 11786,
    city: "Morichal",
    city_code: "MHF",
    airport_name: "Morichal",
    country: "Colombia",
  },
  {
    sr: 11788,
    city: "Morlaix",
    city_code: "MXN",
    airport_name: "Morlaix",
    country: "France",
  },
  {
    sr: 11790,
    city: "Morney",
    city_code: "OXY",
    airport_name: "Morney",
    country: "Australia",
  },
  {
    sr: 11792,
    city: "Mornington",
    city_code: "ONG",
    airport_name: "Mornington",
    country: "Australia",
  },
  {
    sr: 11794,
    city: "Moro",
    city_code: "MXH",
    airport_name: "Moro",
    country: "Papua New Guinea",
  },
  {
    sr: 11798,
    city: "Moroak",
    city_code: "MRT",
    airport_name: "Moroak",
    country: "Australia",
  },
  {
    sr: 11800,
    city: "Morobe",
    city_code: "OBM",
    airport_name: "Morobe",
    country: "Papua New Guinea",
  },
  {
    sr: 11802,
    city: "Morombe",
    city_code: "MXM",
    airport_name: "Morombe",
    country: "Madagascar",
  },
  {
    sr: 11804,
    city: "Moron",
    city_code: "OZP",
    airport_name: "Moron",
    country: "Spain",
  },
  {
    sr: 11806,
    city: "Moron",
    city_code: "MXV",
    airport_name: "Moron",
    country: "Mongolia",
  },
  {
    sr: 11808,
    city: "Morondava",
    city_code: "MOQ",
    airport_name: "Morondava",
    country: "Madagascar",
  },
  {
    sr: 11810,
    city: "Moroni",
    city_code: "YVA",
    airport_name: "Iconi",
    country: "Comoros",
  },
  {
    sr: 11812,
    city: "Moroni",
    city_code: "HAH",
    airport_name: "Prince Said Ibrahim In",
    country: "Comoros",
  },
  {
    sr: 11814,
    city: "Pitu  Morotai Island",
    city_code: "OTI",
    airport_name: "Morotai Island",
    country: "Indonesia",
  },
  {
    sr: 11816,
    city: "Morrilton",
    city_code: "MPJ",
    airport_name: "Petit Jean Park",
    country: "United States",
  },
  {
    sr: 11818,
    city: "Morris",
    city_code: "MOX",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 11820,
    city: "Morristown",
    city_code: "MMU",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 11822,
    city: "Morristown",
    city_code: "MOR",
    airport_name: "Moore Murrell",
    country: "United States",
  },
  {
    sr: 11824,
    city: "Moruya",
    city_code: "MYA",
    airport_name: "Moruya",
    country: "Australia",
  },
  {
    sr: 11826,
    city: "Moscow",
    city_code: "BKA",
    airport_name: "Bykovo",
    country: "Russia",
  },
  {
    sr: 11828,
    city: "Moscow",
    city_code: "DME",
    airport_name: "Domodedovo",
    country: "Russia",
  },
  {
    sr: 11832,
    city: "Moscow",
    city_code: "ZKD",
    airport_name: "Leningradskiy Rail Stn",
    country: "Russia",
  },
  {
    sr: 11834,
    city: "Moscow",
    city_code: "XRK",
    airport_name: "Paveletskiy Rail Stn",
    country: "Russia",
  },
  {
    sr: 11836,
    city: "Moscow",
    city_code: "SVO",
    airport_name: "Sheremetyevo",
    country: "Russia",
  },
  {
    sr: 11838,
    city: "Moscow",
    city_code: "VKO",
    airport_name: "Vnukovo",
    country: "Russia",
  },
  {
    sr: 11840,
    city: "Moser Bay",
    city_code: "KMY",
    airport_name: "Moser Bay",
    country: "United States",
  },
  {
    sr: 11842,
    city: "Moses Lake",
    city_code: "MWH",
    airport_name: "Grant County",
    country: "United States",
  },
  {
    sr: 11844,
    city: "Moses Lake",
    city_code: "LRN",
    airport_name: "Larson AFB",
    country: "United States",
  },
  {
    sr: 11846,
    city: "Moses Point",
    city_code: "MOS",
    airport_name: "Intermediate",
    country: "United States",
  },
  {
    sr: 11848,
    city: "Mosjoen",
    city_code: "MJF",
    airport_name: "Kjaerstad",
    country: "Norway",
  },
  {
    sr: 11850,
    city: "Mosquera",
    city_code: "MQR",
    airport_name: "Mosquera",
    country: "Colombia",
  },
  {
    sr: 11852,
    city: "Mossel Bay",
    city_code: "MZY",
    airport_name: "Mossel Bay",
    country: "South Africa",
  },
  {
    sr: 11854,
    city: "Mossendjo",
    city_code: "MSX",
    airport_name: "Mossendjo",
    country: "Congo",
  },
  {
    sr: 11856,
    city: "Mossoro",
    city_code: "MVF",
    airport_name: "Dixsept Rosado",
    country: "Brazil",
  },
  {
    sr: 11858,
    city: "Mostaganem",
    city_code: "MQV",
    airport_name: "Mostaganem",
    country: "Algeria",
  },
  {
    sr: 11860,
    city: "Mostar",
    city_code: "OMO",
    airport_name: "Mostar",
    country: "Bosnia and Herzegovina",
  },
  {
    sr: 11862,
    city: "Mosteiros",
    city_code: "MTI",
    airport_name: "Mosteiros",
    country: "Cape Verde",
  },
  {
    sr: 11866,
    city: "Mostyn",
    city_code: "MZS",
    airport_name: "Mostyn",
    country: "Malaysia",
  },
  {
    sr: 11868,
    city: "Mosul",
    city_code: "OSM",
    airport_name: "Mosul",
    country: "Iraq",
  },
  {
    sr: 11870,
    city: "Mota",
    city_code: "OTA",
    airport_name: "Mota",
    country: "Ethiopia",
  },
  {
    sr: 11872,
    city: "Mota Lava",
    city_code: "MTV",
    airport_name: "Mota Lava",
    country: "Vanuatu",
  },
  {
    sr: 11874,
    city: "Motswari",
    city_code: "MWR",
    airport_name: "Motswari Airfield",
    country: "South Africa",
  },
  {
    sr: 11876,
    city: "Motueka",
    city_code: "MZP",
    airport_name: "Motueka",
    country: "New Zealand",
  },
  {
    sr: 11878,
    city: "Moudjeria",
    city_code: "MOM",
    airport_name: "Moudjeria",
    country: "Mauritania",
  },
  {
    sr: 11880,
    city: "Mougulu",
    city_code: "GUV",
    airport_name: "Mougulu",
    country: "Papua New Guinea",
  },
  {
    sr: 11882,
    city: "Mouila",
    city_code: "MJL",
    airport_name: "Mouila",
    country: "Gabon",
  },
  {
    sr: 11884,
    city: "Mould Bay",
    city_code: "YMD",
    airport_name: "Mould Bay",
    country: "Canada",
  },
  {
    sr: 11886,
    city: "Moultrie",
    city_code: "MUL",
    airport_name: "Spence",
    country: "United States",
  },
  {
    sr: 11888,
    city: "Moultrie",
    city_code: "MGR",
    airport_name: "Thomasville",
    country: "United States",
  },
  {
    sr: 11890,
    city: "Moundou",
    city_code: "MQQ",
    airport_name: "Moundou",
    country: "Chad",
  },
  {
    sr: 11892,
    city: "Mount Aue",
    city_code: "UAE",
    airport_name: "Mount Aue",
    country: "Papua New Guinea",
  },
  {
    sr: 11894,
    city: "Mount Buffalo",
    city_code: "MBF",
    airport_name: "Mount Buffalo",
    country: "Australia",
  },
  {
    sr: 11896,
    city: "Mount Cook",
    city_code: "GTN",
    airport_name: "Glentanner",
    country: "New Zealand",
  },
  {
    sr: 11900,
    city: "Mount Cook",
    city_code: "MON",
    airport_name: "Mount Cook Airport",
    country: "New Zealand",
  },
  {
    sr: 11902,
    city: "Mount Cook",
    city_code: "TWZ",
    airport_name: "Pukaki Twizel",
    country: "New Zealand",
  },
  {
    sr: 11904,
    city: "Mount Etjo Lodge",
    city_code: "MJO",
    airport_name: "Mount Etjo Lodge",
    country: "Namibia",
  },
  {
    sr: 11906,
    city: "Mount Full Stop",
    city_code: "MFL",
    airport_name: "Mount Full Stop",
    country: "Australia",
  },
  {
    sr: 11908,
    city: "Mount Gambier",
    city_code: "MGB",
    airport_name: "Mount Gambier",
    country: "Australia",
  },
  {
    sr: 11910,
    city: "Mount Gunson",
    city_code: "GSN",
    airport_name: "Mount Gunson",
    country: "Australia",
  },
  {
    sr: 11912,
    city: "Mount Hagen",
    city_code: "HGU",
    airport_name: "Kagamuga",
    country: "Papua New Guinea",
  },
  {
    sr: 11914,
    city: "Mount Holly",
    city_code: "LLY",
    airport_name: "Burlington County",
    country: "United States",
  },
  {
    sr: 11916,
    city: "Mount Hotham",
    city_code: "MHU",
    airport_name: "Mount Hotham",
    country: "Australia",
  },
  {
    sr: 11918,
    city: "Mount House",
    city_code: "MHO",
    airport_name: "Mount House",
    country: "Australia",
  },
  {
    sr: 11920,
    city: "Mount Isa",
    city_code: "ISA",
    airport_name: "Mount Isa",
    country: "Australia",
  },
  {
    sr: 11922,
    city: "Mount Keith",
    city_code: "WME",
    airport_name: "Mount Keith",
    country: "Australia",
  },
  {
    sr: 11924,
    city: "Mount Magnet",
    city_code: "MMG",
    airport_name: "Mount Magnet",
    country: "Australia",
  },
  {
    sr: 11926,
    city: "Mount Pleasant",
    city_code: "MPN",
    airport_name: "Mount Pleasant",
    country: "Falkland Islands",
  },
  {
    sr: 11928,
    city: "Mount Pleasant",
    city_code: "MOP",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 11930,
    city: "Mount Pleasant",
    city_code: "MPS",
    airport_name: "Mount Pleasant",
    country: "United States",
  },
  {
    sr: 11934,
    city: "Mount Shasta",
    city_code: "MHS",
    airport_name: "Rep",
    country: "United States",
  },
  {
    sr: 11936,
    city: "Mount Swan",
    city_code: "MSF",
    airport_name: "Mount Swan",
    country: "Australia",
  },
  {
    sr: 11938,
    city: "Mount Union",
    city_code: "MUU",
    airport_name: "Mount Union",
    country: "United States",
  },
  {
    sr: 11940,
    city: "Mount Vernon",
    city_code: "MVW",
    airport_name: "Skagit Regional",
    country: "United States",
  },
  {
    sr: 11942,
    city: "Mount Wilson",
    city_code: "MWS",
    airport_name: "Mount Wilson",
    country: "United States",
  },
  {
    sr: 11944,
    city: "Mountain",
    city_code: "MWP",
    airport_name: "Mountain",
    country: "Nepal",
  },
  {
    sr: 11946,
    city: "Mountain Home",
    city_code: "WMH",
    airport_name: "Mountain Home",
    country: "United States",
  },
  {
    sr: 11948,
    city: "Mountain Home",
    city_code: "MUO",
    airport_name: "AFB",
    country: "United States",
  },
  {
    sr: 11950,
    city: "Mountain Valley",
    city_code: "MNV",
    airport_name: "Mountain Valley",
    country: "Australia",
  },
  {
    sr: 11952,
    city: "Mountain View",
    city_code: "NUQ",
    airport_name: "Moffett Field",
    country: "United States",
  },
  {
    sr: 11954,
    city: "Mountain Village",
    city_code: "MOU",
    airport_name: "Mountain Village",
    country: "United States",
  },
  {
    sr: 11956,
    city: "Mouscron",
    city_code: "MWW",
    airport_name: "Mouscron",
    country: "Belgium",
  },
  {
    sr: 11958,
    city: "Mouyondzi",
    city_code: "MUY",
    airport_name: "Mouyondzi",
    country: "Congo",
  },
  {
    sr: 11960,
    city: "Moyale",
    city_code: "MYS",
    airport_name: "Moyale",
    country: "Ethiopia",
  },
  {
    sr: 11962,
    city: "Moyale",
    city_code: "OYL",
    airport_name: "Moyale",
    country: "Kenya",
  },
  {
    sr: 11964,
    city: "Moyo",
    city_code: "OYG",
    airport_name: "Moyo",
    country: "Uganda",
  },
  {
    sr: 11968,
    city: "Moyobamba",
    city_code: "MBP",
    airport_name: "Moyobamba",
    country: "Peru",
  },
  {
    sr: 11970,
    city: "Mpacha",
    city_code: "MPA",
    airport_name: "Mpacha",
    country: "Namibia",
  },
  {
    sr: 11972,
    city: "Mt Barnett",
    city_code: "MBN",
    airport_name: "Mt Barnett",
    country: "Australia",
  },
  {
    sr: 11974,
    city: "Mt Cavenagh",
    city_code: "MKV",
    airport_name: "Mt Cavenagh",
    country: "Australia",
  },
  {
    sr: 11976,
    city: "Mt Clemens",
    city_code: "NFB",
    airport_name: "Detroit NAF",
    country: "United States",
  },
  {
    sr: 11978,
    city: "Mt Clemens",
    city_code: "MTC",
    airport_name: "Selfridge ANGB",
    country: "United States",
  },
  {
    sr: 11980,
    city: "Mt Mckinley",
    city_code: "MCL",
    airport_name: "Mt Mckinley",
    country: "United States",
  },
  {
    sr: 11982,
    city: "Mt Pleasant",
    city_code: "MPZ",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 11984,
    city: "Mt Pleasant",
    city_code: "MSD",
    airport_name: "Mt Pleasant",
    country: "United States",
  },
  {
    sr: 11986,
    city: "Mt Pocono",
    city_code: "MPO",
    airport_name: "Mt Pocono",
    country: "United States",
  },
  {
    sr: 11988,
    city: "Mt Sandford",
    city_code: "MTD",
    airport_name: "Mt Sandford",
    country: "Australia",
  },
  {
    sr: 11990,
    city: "Mt Vernon",
    city_code: "MVN",
    airport_name: "Mt Vernon Outland",
    country: "United States",
  },
  {
    sr: 11992,
    city: "Mtwara",
    city_code: "MYW",
    airport_name: "Mtwara",
    country: "Tanzania",
  },
  {
    sr: 11994,
    city: "Muccan",
    city_code: "MUQ",
    airport_name: "Muccan",
    country: "Australia",
  },
  {
    sr: 11996,
    city: "Mucuri",
    city_code: "MVS",
    airport_name: "Aeroporto Max Feffer",
    country: "Brazil",
  },
  {
    sr: 11998,
    city: "Mudanjiang",
    city_code: "MDG",
    airport_name: "Mudanjiang",
    country: "China",
  },
  {
    sr: 12002,
    city: "Mudgee",
    city_code: "DGE",
    airport_name: "Mudgee",
    country: "Australia",
  },
  {
    sr: 12004,
    city: "Mueda",
    city_code: "MUD",
    airport_name: "Mueda",
    country: "Mozambique",
  },
  {
    sr: 12006,
    city: "Muenster",
    city_code: "FMO",
    airport_name: "Muenster",
    country: "Germany",
  },
  {
    sr: 12008,
    city: "Mueo",
    city_code: "PDC",
    airport_name: "Mueo",
    country: "New Caledonia",
  },
  {
    sr: 12010,
    city: "Muharraq",
    city_code: "GBQ",
    airport_name: "Muharraq",
    country: "Bahrain",
  },
  {
    sr: 12012,
    city: "Mui",
    city_code: "MUJ",
    airport_name: "Mui",
    country: "Ethiopia",
  },
  {
    sr: 12014,
    city: "Mukah",
    city_code: "MKM",
    airport_name: "Mukah",
    country: "Malaysia",
  },
  {
    sr: 12016,
    city: "Mukalla",
    city_code: "MKX",
    airport_name: "Mukalla",
    country: "Yemen",
  },
  {
    sr: 12018,
    city: "Mukeiras",
    city_code: "UKR",
    airport_name: "Mukeiras",
    country: "Yemen",
  },
  {
    sr: 12020,
    city: "Muko Muko",
    city_code: "MPC",
    airport_name: "Muko Muko",
    country: "Indonesia",
  },
  {
    sr: 12022,
    city: "Mulatos",
    city_code: "ULS",
    airport_name: "Mulatos",
    country: "Colombia",
  },
  {
    sr: 12024,
    city: "Mulatupo",
    city_code: "MPP",
    airport_name: "Mulatupo",
    country: "Panama",
  },
  {
    sr: 12026,
    city: "Mulege",
    city_code: "MUG",
    airport_name: "Mulege",
    country: "Mexico",
  },
  {
    sr: 12028,
    city: "Mulga Park",
    city_code: "MUP",
    airport_name: "Mulga Park",
    country: "Australia",
  },
  {
    sr: 12030,
    city: "Basel Mulhouse Freiburg",
    city_code: "MLH",
    airport_name: "Euroairport",
    country: "France",
  },
  {
    sr: 12032,
    city: "Mulhouse Basel",
    city_code: "BSL",
    airport_name: "EuroAirport Swiss CH",
    country: "Switzerland",
  },
  {
    sr: 12036,
    city: "Mulia",
    city_code: "LII",
    airport_name: "Mulia",
    country: "Indonesia",
  },
  {
    sr: 12038,
    city: "Mulka",
    city_code: "MVK",
    airport_name: "Mulka",
    country: "Australia",
  },
  {
    sr: 12040,
    city: "Mull",
    city_code: "ULL",
    airport_name: "Mull",
    country: "United Kingdom",
  },
  {
    sr: 12042,
    city: "Mullen",
    city_code: "MHN",
    airport_name: "Mullen",
    country: "United States",
  },
  {
    sr: 12044,
    city: "Mullewa",
    city_code: "MXU",
    airport_name: "Mullewa",
    country: "Australia",
  },
  {
    sr: 12046,
    city: "Multan",
    city_code: "MUX",
    airport_name: "Multan",
    country: "Pakistan",
  },
  {
    sr: 12048,
    city: "Mulu",
    city_code: "MZV",
    airport_name: "Mulu",
    country: "Malaysia",
  },
  {
    sr: 12050,
    city: "Mumbai",
    city_code: "BOM",
    airport_name: "Chhatrapati Shivaji",
    country: "India",
  },
  {
    sr: 12052,
    city: "Mumias",
    city_code: "MUM",
    airport_name: "Mumias",
    country: "Kenya",
  },
  {
    sr: 12054,
    city: "Munbil",
    city_code: "LNF",
    airport_name: "Munbil",
    country: "Papua New Guinea",
  },
  {
    sr: 12056,
    city: "Muncie",
    city_code: "MIE",
    airport_name: "Delaware County",
    country: "United States",
  },
  {
    sr: 12058,
    city: "Munda",
    city_code: "MUA",
    airport_name: "Munda",
    country: "Solomon Islands",
  },
  {
    sr: 12060,
    city: "Munduku",
    city_code: "MDM",
    airport_name: "Munduku",
    country: "Papua New Guinea",
  },
  {
    sr: 12062,
    city: "Mundulkiri",
    city_code: "MWV",
    airport_name: "Mundulkiri",
    country: "Cambodia",
  },
  {
    sr: 12064,
    city: "Mungeranie",
    city_code: "MNE",
    airport_name: "Mungeranie",
    country: "Australia",
  },
  {
    sr: 12066,
    city: "Augsburg",
    city_code: "AGB",
    airport_name: "Augsburg   Muehlhausen",
    country: "Germany",
  },
  {
    sr: 12070,
    city: "Munich",
    city_code: "MUC",
    airport_name: "Franz Josef Strauss",
    country: "Germany",
  },
  {
    sr: 12072,
    city: "Muong Sai",
    city_code: "UON",
    airport_name: "Muong Sai",
    country: "Laos",
  },
  {
    sr: 12074,
    city: "Murcia",
    city_code: "MJV",
    airport_name: "San Javier",
    country: "Spain",
  },
  {
    sr: 12076,
    city: "Murmansk",
    city_code: "MMK",
    airport_name: "Murmansk",
    country: "Russia",
  },
  {
    sr: 12078,
    city: "Murray",
    city_code: "CEY",
    airport_name: "Calloway County",
    country: "United States",
  },
  {
    sr: 12080,
    city: "Murray Bay",
    city_code: "YML",
    airport_name: "Charlevoix",
    country: "Canada",
  },
  {
    sr: 12082,
    city: "Murray Island",
    city_code: "MYI",
    airport_name: "Murray Island",
    country: "Australia",
  },
  {
    sr: 12084,
    city: "Murzuq",
    city_code: "QMQ",
    airport_name: "Murzuq",
    country: "Libya",
  },
  {
    sr: 12086,
    city: "Mus",
    city_code: "MSR",
    airport_name: "Mus",
    country: "Turkey",
  },
  {
    sr: 12088,
    city: "Muscat",
    city_code: "MCT",
    airport_name: "Muscat International Airport",
    country: "Oman",
  },
  {
    sr: 12090,
    city: "Muscatine",
    city_code: "MUT",
    airport_name: "Muscatine",
    country: "United States",
  },
  {
    sr: 12092,
    city: "Musgrave",
    city_code: "MVU",
    airport_name: "Musgrave",
    country: "Australia",
  },
  {
    sr: 12094,
    city: "Musha",
    city_code: "MHI",
    airport_name: "Musha",
    country: "Djibouti",
  },
  {
    sr: 12096,
    city: "Muskegon",
    city_code: "MKG",
    airport_name: "Muskegon",
    country: "United States",
  },
  {
    sr: 12098,
    city: "Muskogee",
    city_code: "MKO",
    airport_name: "Davis Field",
    country: "United States",
  },
  {
    sr: 12100,
    city: "Muskogee",
    city_code: "HAX",
    airport_name: "Hatbox Field",
    country: "United States",
  },
  {
    sr: 12104,
    city: "Muskoka",
    city_code: "YQA",
    airport_name: "Muskoka",
    country: "Canada",
  },
  {
    sr: 12106,
    city: "Muskrat Dam",
    city_code: "MSA",
    airport_name: "Muskrat Dam",
    country: "Canada",
  },
  {
    sr: 12108,
    city: "Musoma",
    city_code: "MUZ",
    airport_name: "Musoma",
    country: "Tanzania",
  },
  {
    sr: 12110,
    city: "Mussau",
    city_code: "MWU",
    airport_name: "Mussau",
    country: "Papua New Guinea",
  },
  {
    sr: 12112,
    city: "Mustique Island",
    city_code: "MQS",
    airport_name: "Mustique",
    country: "Saint Vincent and the Grenadines",
  },
  {
    sr: 12114,
    city: "Mutare",
    city_code: "UTA",
    airport_name: "Mutare",
    country: "Zimbabwe",
  },
  {
    sr: 12116,
    city: "Muting",
    city_code: "MUF",
    airport_name: "Muting",
    country: "Indonesia",
  },
  {
    sr: 12118,
    city: "Muttaburra",
    city_code: "UTB",
    airport_name: "Muttaburra",
    country: "Australia",
  },
  {
    sr: 12120,
    city: "Muzaffarabad",
    city_code: "MFG",
    airport_name: "Muzaffarabad",
    country: "Pakistan",
  },
  {
    sr: 12122,
    city: "Muzaffarnagar",
    city_code: "MZA",
    airport_name: "Muzaffarnagar",
    country: "India",
  },
  {
    sr: 12124,
    city: "Muzaffarpur",
    city_code: "MZU",
    airport_name: "Muzaffarpur",
    country: "India",
  },
  {
    sr: 12126,
    city: "Mvuu Camp",
    city_code: "VUU",
    airport_name: "Mvuu Camp",
    country: "Malawi",
  },
  {
    sr: 12128,
    city: "Mwadui",
    city_code: "MWN",
    airport_name: "Mwadui",
    country: "Tanzania",
  },
  {
    sr: 12130,
    city: "Mwanza",
    city_code: "MWZ",
    airport_name: "Mwanza",
    country: "Tanzania",
  },
  {
    sr: 12132,
    city: "Mweka",
    city_code: "MEW",
    airport_name: "Mweka",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 12134,
    city: "Myeik",
    city_code: "MGZ",
    airport_name: "Myeik",
    country: "Myanmar",
  },
  {
    sr: 12138,
    city: "Myitkyina",
    city_code: "MYT",
    airport_name: "Myitkyina",
    country: "Myanmar",
  },
  {
    sr: 12140,
    city: "Myroodah",
    city_code: "MYO",
    airport_name: "Myroodah",
    country: "Australia",
  },
  {
    sr: 12142,
    city: "Myrtle Beach",
    city_code: "CRE",
    airport_name: "Grand Strand Airport",
    country: "United States",
  },
  {
    sr: 12144,
    city: "Myrtle Beach",
    city_code: "MYR",
    airport_name: "Myrtle Beach AFB",
    country: "United States",
  },
  {
    sr: 12146,
    city: "Mysore",
    city_code: "MYQ",
    airport_name: "Mysore",
    country: "India",
  },
  {
    sr: 12148,
    city: "Mytilene",
    city_code: "MJT",
    airport_name: "Mytilene",
    country: "Greece",
  },
  {
    sr: 12150,
    city: "Myvatn",
    city_code: "MVA",
    airport_name: "Reykiahlid",
    country: "Iceland",
  },
  {
    sr: 12152,
    city: "Mzamba Wild Coast Sun",
    city_code: "MZF",
    airport_name: "Mzamba Wild Coast Sun",
    country: "South Africa",
  },
  {
    sr: 12154,
    city: "Mzuzu",
    city_code: "ZZU",
    airport_name: "Mzuzu",
    country: "Malawi",
  },
  {
    sr: 12156,
    city: "N Sound Virgin Gorda",
    city_code: "NSX",
    airport_name: "Hovercraft Launch Pt",
    country: "Virgin Islands  British",
  },
  {
    sr: 12158,
    city: "Naberevnye Chelny",
    city_code: "NBC",
    airport_name: "Naberevnye Chelny",
    country: "Russia",
  },
  {
    sr: 12160,
    city: "Nabire",
    city_code: "NBX",
    airport_name: "Nabire",
    country: "Indonesia",
  },
  {
    sr: 12162,
    city: "Nacala",
    city_code: "MNC",
    airport_name: "Nacala",
    country: "Mozambique",
  },
  {
    sr: 12164,
    city: "Nachingwea",
    city_code: "NCH",
    airport_name: "Nachingwea",
    country: "Tanzania",
  },
  {
    sr: 12166,
    city: "Nacogdoches",
    city_code: "OCH",
    airport_name: "Nacogdoches",
    country: "United States",
  },
  {
    sr: 12168,
    city: "Nacogdoches",
    city_code: "LFK",
    airport_name: "Lufkin Angelina County",
    country: "United States",
  },
  {
    sr: 12172,
    city: "Nadi",
    city_code: "NAN",
    airport_name: "International",
    country: "Fiji",
  },
  {
    sr: 12174,
    city: "Nador",
    city_code: "NDR",
    airport_name: "Nador",
    country: "Morocco",
  },
  {
    sr: 12176,
    city: "Nadunumu",
    city_code: "NDN",
    airport_name: "Nadunumu",
    country: "Papua New Guinea",
  },
  {
    sr: 12178,
    city: "Nadym",
    city_code: "NYM",
    airport_name: "Nadym",
    country: "Russia",
  },
  {
    sr: 12180,
    city: "Nafoora",
    city_code: "NFR",
    airport_name: "Nafoora",
    country: "Libya",
  },
  {
    sr: 12182,
    city: "Naga",
    city_code: "WNP",
    airport_name: "Naga",
    country: "Philippines",
  },
  {
    sr: 12184,
    city: "Nagasaki",
    city_code: "NGS",
    airport_name: "Nagasaki",
    country: "Japan",
  },
  {
    sr: 12186,
    city: "Nagoya",
    city_code: "NKM",
    airport_name: "Komaki AFB",
    country: "Japan",
  },
  {
    sr: 12188,
    city: "Nagpur",
    city_code: "NAG",
    airport_name: "Sonegaon",
    country: "India",
  },
  {
    sr: 12190,
    city: "Naha",
    city_code: "NAH",
    airport_name: "Naha",
    country: "Indonesia",
  },
  {
    sr: 12192,
    city: "Nain",
    city_code: "YDP",
    airport_name: "Nain",
    country: "Canada",
  },
  {
    sr: 12194,
    city: "Nairobi",
    city_code: "NBO",
    airport_name: "Jomo Kenyatta International",
    country: "Kenya",
  },
  {
    sr: 12196,
    city: "Nairobi",
    city_code: "WIL",
    airport_name: "Wilson",
    country: "Kenya",
  },
  {
    sr: 12198,
    city: "Nakashibetsu",
    city_code: "SHB",
    airport_name: "Nakashibetsu",
    country: "Japan",
  },
  {
    sr: 12200,
    city: "Nakhichevan",
    city_code: "NAJ",
    airport_name: "Nakhichevan",
    country: "Azerbaijan",
  },
  {
    sr: 12202,
    city: "Nakhon Phanom",
    city_code: "KOP",
    airport_name: "Nakhon Phanom",
    country: "Thailand",
  },
  {
    sr: 12206,
    city: "Nakhon Ratchasima",
    city_code: "NAK",
    airport_name: "Nakhon Ratchasima",
    country: "Thailand",
  },
  {
    sr: 12208,
    city: "Nakhon Si Thammarat",
    city_code: "NST",
    airport_name: "Nakhon Si Thammarat",
    country: "Thailand",
  },
  {
    sr: 12210,
    city: "Nakina",
    city_code: "YQN",
    airport_name: "Nakina",
    country: "Canada",
  },
  {
    sr: 12212,
    city: "Naknek",
    city_code: "NNK",
    airport_name: "Naknek",
    country: "United States",
  },
  {
    sr: 12214,
    city: "Nakolik River",
    city_code: "NOL",
    airport_name: "Nakolik River",
    country: "United States",
  },
  {
    sr: 12216,
    city: "Nakuru",
    city_code: "NUU",
    airport_name: "Nakuru",
    country: "Kenya",
  },
  {
    sr: 12218,
    city: "Nalchik",
    city_code: "NAL",
    airport_name: "Nalchik",
    country: "Russia",
  },
  {
    sr: 12220,
    city: "Namangan",
    city_code: "NMA",
    airport_name: "Namangan",
    country: "Uzbekistan",
  },
  {
    sr: 12222,
    city: "Namatanai",
    city_code: "ATN",
    airport_name: "Namatanai",
    country: "Papua New Guinea",
  },
  {
    sr: 12224,
    city: "Nambaiyufa",
    city_code: "NBA",
    airport_name: "Nambaiyufa",
    country: "Papua New Guinea",
  },
  {
    sr: 12226,
    city: "Nambour",
    city_code: "NBR",
    airport_name: "Nambour",
    country: "Australia",
  },
  {
    sr: 12228,
    city: "Nambucca Heads",
    city_code: "NBH",
    airport_name: "Nambucca Heads",
    country: "Australia",
  },
  {
    sr: 12230,
    city: "Namdrik Island",
    city_code: "NDK",
    airport_name: "Namdrik Island",
    country: "Marshall Islands",
  },
  {
    sr: 12232,
    city: "Namibe",
    city_code: "MSZ",
    airport_name: "Namibe",
    country: "Angola",
  },
  {
    sr: 12234,
    city: "Namlea",
    city_code: "NAM",
    airport_name: "Namlea",
    country: "Indonesia",
  },
  {
    sr: 12236,
    city: "Nampula",
    city_code: "APL",
    airport_name: "Nampula",
    country: "Mozambique",
  },
  {
    sr: 12240,
    city: "Namrole",
    city_code: "NRE",
    airport_name: "Namrole",
    country: "Indonesia",
  },
  {
    sr: 12242,
    city: "Namsang",
    city_code: "NMS",
    airport_name: "Namsang",
    country: "Myanmar",
  },
  {
    sr: 12244,
    city: "Namsos",
    city_code: "OSY",
    airport_name: "Namsos",
    country: "Norway",
  },
  {
    sr: 12246,
    city: "Namtu",
    city_code: "NMT",
    airport_name: "Namtu",
    country: "Myanmar",
  },
  {
    sr: 12248,
    city: "Namu",
    city_code: "ZNU",
    airport_name: "Namu",
    country: "Canada",
  },
  {
    sr: 12250,
    city: "Namu",
    city_code: "NMU",
    airport_name: "Namu",
    country: "Marshall Islands",
  },
  {
    sr: 12252,
    city: "Namudi",
    city_code: "NDI",
    airport_name: "Namudi",
    country: "Papua New Guinea",
  },
  {
    sr: 12254,
    city: "Namutoni",
    city_code: "NNI",
    airport_name: "Namutoni",
    country: "Namibia",
  },
  {
    sr: 12256,
    city: "Nan",
    city_code: "NNT",
    airport_name: "Nan",
    country: "Thailand",
  },
  {
    sr: 12258,
    city: "Nanaimo",
    city_code: "ZNA",
    airport_name: "Harbour",
    country: "Canada",
  },
  {
    sr: 12260,
    city: "Nanaimo",
    city_code: "YCD",
    airport_name: "Nanaimo Arpt",
    country: "Canada",
  },
  {
    sr: 12262,
    city: "Nanchang",
    city_code: "KHN",
    airport_name: "Nanchang",
    country: "China",
  },
  {
    sr: 12264,
    city: "Nanchong",
    city_code: "NAO",
    airport_name: "Nanchong",
    country: "China",
  },
  {
    sr: 12266,
    city: "Nancy",
    city_code: "ENC",
    airport_name: "Essey",
    country: "France",
  },
  {
    sr: 12268,
    city: "Nanded",
    city_code: "NDC",
    airport_name: "Nanded",
    country: "India",
  },
  {
    sr: 12270,
    city: "Nangade",
    city_code: "NND",
    airport_name: "Nangade",
    country: "Mozambique",
  },
  {
    sr: 12274,
    city: "Nangan",
    city_code: "LZN",
    airport_name: "Nangan",
    country: "Taiwan",
  },
  {
    sr: 12276,
    city: "Nangapinoh",
    city_code: "NPO",
    airport_name: "Nangapinoh",
    country: "Indonesia",
  },
  {
    sr: 12278,
    city: "Nanhai",
    city_code: "ZEF",
    airport_name: "Nanhai Ferry",
    country: "China",
  },
  {
    sr: 12280,
    city: "Nanisivik",
    city_code: "YSR",
    airport_name: "Nanisivik",
    country: "Canada",
  },
  {
    sr: 12282,
    city: "Nankina",
    city_code: "NKN",
    airport_name: "Nankina",
    country: "Papua New Guinea",
  },
  {
    sr: 12284,
    city: "Nanking nanjing",
    city_code: "NKG",
    airport_name: "Nanking nanjing",
    country: "China",
  },
  {
    sr: 12286,
    city: "Nanning",
    city_code: "NNG",
    airport_name: "Nanning",
    country: "China",
  },
  {
    sr: 12288,
    city: "Nanortalik",
    city_code: "JNN",
    airport_name: "Nanortalik",
    country: "Greenland",
  },
  {
    sr: 12290,
    city: "Nantes",
    city_code: "NTE",
    airport_name: "Nantes Atlantique",
    country: "France",
  },
  {
    sr: 12292,
    city: "Nantong",
    city_code: "NTG",
    airport_name: "Nantong",
    country: "China",
  },
  {
    sr: 12294,
    city: "Nantucket",
    city_code: "ACK",
    airport_name: "Nantucket Memorial",
    country: "United States",
  },
  {
    sr: 12296,
    city: "Nanuque",
    city_code: "NNU",
    airport_name: "Nanuque",
    country: "Brazil",
  },
  {
    sr: 12298,
    city: "Nanwalek",
    city_code: "KEB",
    airport_name: "Nanwalek",
    country: "United States",
  },
  {
    sr: 12300,
    city: "Nanyang",
    city_code: "NNY",
    airport_name: "Nanyang",
    country: "China",
  },
  {
    sr: 12302,
    city: "Nanyuki",
    city_code: "NYK",
    airport_name: "Nanyuki",
    country: "Kenya",
  },
  {
    sr: 12304,
    city: "Naoro",
    city_code: "NOO",
    airport_name: "Naoro",
    country: "Papua New Guinea",
  },
  {
    sr: 12308,
    city: "Napa",
    city_code: "APC",
    airport_name: "Napa County",
    country: "United States",
  },
  {
    sr: 12310,
    city: "Napakiak",
    city_code: "WNA",
    airport_name: "Napakiak SPB",
    country: "United States",
  },
  {
    sr: 12312,
    city: "Napaskiak",
    city_code: "PKA",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 12314,
    city: "Napier Hastings",
    city_code: "NPE",
    airport_name: "Hawkes Bay",
    country: "New Zealand",
  },
  {
    sr: 12316,
    city: "Naples",
    city_code: "APF",
    airport_name: "Naples",
    country: "United States",
  },
  {
    sr: 12318,
    city: "Naples",
    city_code: "NAP",
    airport_name: "Capodichino",
    country: "Italy",
  },
  {
    sr: 12320,
    city: "Naples",
    city_code: "ZMF",
    airport_name: "Beverello Harbor",
    country: "Italy",
  },
  {
    sr: 12322,
    city: "Naples",
    city_code: "ZMJ",
    airport_name: "Mergellina Harbour",
    country: "Italy",
  },
  {
    sr: 12324,
    city: "Naples",
    city_code: "ZMI",
    airport_name: "Mergellina Railway Svc",
    country: "Italy",
  },
  {
    sr: 12326,
    city: "Nappa Merry",
    city_code: "NMR",
    airport_name: "Nappa Merry",
    country: "Australia",
  },
  {
    sr: 12328,
    city: "Napperby",
    city_code: "NPP",
    airport_name: "Napperby",
    country: "Australia",
  },
  {
    sr: 12330,
    city: "Napuka Island",
    city_code: "NAU",
    airport_name: "Napuka Island",
    country: "French Polynesia",
  },
  {
    sr: 12332,
    city: "Nara",
    city_code: "NRM",
    airport_name: "Nara",
    country: "Mali",
  },
  {
    sr: 12334,
    city: "Naracoorte",
    city_code: "NAC",
    airport_name: "Naracoorte",
    country: "Australia",
  },
  {
    sr: 12336,
    city: "Narathiwat",
    city_code: "NAW",
    airport_name: "Narathiwat",
    country: "Thailand",
  },
  {
    sr: 12338,
    city: "Nare",
    city_code: "NAR",
    airport_name: "Nare",
    country: "Colombia",
  },
  {
    sr: 12342,
    city: "Nargana",
    city_code: "NGN",
    airport_name: "Nargana",
    country: "Panama",
  },
  {
    sr: 12344,
    city: "Narrabri",
    city_code: "NAA",
    airport_name: "Narrabri",
    country: "Australia",
  },
  {
    sr: 12346,
    city: "Narrandera",
    city_code: "NRA",
    airport_name: "Narrandera",
    country: "Australia",
  },
  {
    sr: 12348,
    city: "Narrogin",
    city_code: "NRG",
    airport_name: "Narrogin",
    country: "Australia",
  },
  {
    sr: 12350,
    city: "Narsaq",
    city_code: "JNS",
    airport_name: "Heliport",
    country: "Greenland",
  },
  {
    sr: 12352,
    city: "Narsarsuaq",
    city_code: "UAK",
    airport_name: "Narsarsuaq",
    country: "Greenland",
  },
  {
    sr: 12354,
    city: "Narvik",
    city_code: "NVK",
    airport_name: "Framnes",
    country: "Norway",
  },
  {
    sr: 12356,
    city: "Naryan Mar",
    city_code: "NNM",
    airport_name: "Naryan Mar",
    country: "Russia",
  },
  {
    sr: 12358,
    city: "Nashua",
    city_code: "ASH",
    airport_name: "Boire Field",
    country: "United States",
  },
  {
    sr: 12360,
    city: "Nashville",
    city_code: "BNA",
    airport_name: "Metropolitan",
    country: "United States",
  },
  {
    sr: 12362,
    city: "Nasik",
    city_code: "ISK",
    airport_name: "Gandhinagar Arpt",
    country: "India",
  },
  {
    sr: 12364,
    city: "Nasiriyah",
    city_code: "XNH",
    airport_name: "Nasiriyah",
    country: "Iraq",
  },
  {
    sr: 12366,
    city: "Nassau",
    city_code: "NAS",
    airport_name: "Intl",
    country: "Bahamas",
  },
  {
    sr: 12368,
    city: "Nassau",
    city_code: "PID",
    airport_name: "Paradise Island",
    country: "Bahamas",
  },
  {
    sr: 12370,
    city: "Nassau",
    city_code: "WZY",
    airport_name: "Seaplane Base",
    country: "Bahamas",
  },
  {
    sr: 12372,
    city: "Natadola",
    city_code: "NTA",
    airport_name: "Natadola",
    country: "Fiji",
  },
  {
    sr: 12376,
    city: "Natal",
    city_code: "NAT",
    airport_name: "Augusto Severo",
    country: "Brazil",
  },
  {
    sr: 12378,
    city: "Natashquan",
    city_code: "YNA",
    airport_name: "Natashquan",
    country: "Canada",
  },
  {
    sr: 12380,
    city: "Natchez",
    city_code: "HEZ",
    airport_name: "Hardy Anders",
    country: "United States",
  },
  {
    sr: 12382,
    city: "Nationaltheatret",
    city_code: "ZZN",
    airport_name: "Nationaltheatret",
    country: "Norway",
  },
  {
    sr: 12384,
    city: "Natitingou",
    city_code: "NAE",
    airport_name: "Natitingou",
    country: "Benin",
  },
  {
    sr: 12386,
    city: "Natuashish",
    city_code: "YNP",
    airport_name: "Natuashish",
    country: "Canada",
  },
  {
    sr: 12388,
    city: "Natuna Ranai",
    city_code: "NTX",
    airport_name: "Natuna Ranai",
    country: "Indonesia",
  },
  {
    sr: 12390,
    city: "Naukiti",
    city_code: "NKI",
    airport_name: "Naukiti",
    country: "United States",
  },
  {
    sr: 12392,
    city: "Nauru Island",
    city_code: "INU",
    airport_name: "International",
    country: "Nauru",
  },
  {
    sr: 12394,
    city: "Navegantes",
    city_code: "NVT",
    airport_name: "Navegantes",
    country: "Brazil",
  },
  {
    sr: 12396,
    city: "Navoi",
    city_code: "NVI",
    airport_name: "Navoi",
    country: "Uzbekistan",
  },
  {
    sr: 12398,
    city: "Nawabshah",
    city_code: "WNS",
    airport_name: "Nawabshah",
    country: "Pakistan",
  },
  {
    sr: 12400,
    city: "Naxos",
    city_code: "JNX",
    airport_name: "Naxos Airport",
    country: "Greece",
  },
  {
    sr: 12402,
    city: "Ndalatandos",
    city_code: "NDF",
    airport_name: "Ndalatandos",
    country: "Angola",
  },
  {
    sr: 12404,
    city: "Ndele",
    city_code: "NDL",
    airport_name: "Ndele",
    country: "Central African Republic",
  },
  {
    sr: 12406,
    city: "Ndende",
    city_code: "KDN",
    airport_name: "Ndende",
    country: "Gabon",
  },
  {
    sr: 12410,
    city: "Ndjamena",
    city_code: "NDJ",
    airport_name: "Ndjamena",
    country: "Chad",
  },
  {
    sr: 12412,
    city: "Ndola",
    city_code: "NLA",
    airport_name: "Ndola",
    country: "Zambia",
  },
  {
    sr: 12414,
    city: "Necochea",
    city_code: "NEC",
    airport_name: "Necochea",
    country: "Argentina",
  },
  {
    sr: 12416,
    city: "Necocli",
    city_code: "NCI",
    airport_name: "Necocli",
    country: "Colombia",
  },
  {
    sr: 12418,
    city: "Needles",
    city_code: "EED",
    airport_name: "Needles",
    country: "United States",
  },
  {
    sr: 12420,
    city: "Neerlerit Inaat",
    city_code: "CNP",
    airport_name: "Neerlerit Inaat",
    country: "Greenland",
  },
  {
    sr: 12422,
    city: "Neftekamsk",
    city_code: "NEF",
    airport_name: "Neftekamsk",
    country: "Russia",
  },
  {
    sr: 12424,
    city: "Nefteyugansk",
    city_code: "NFG",
    airport_name: "Nefteyugansk",
    country: "Russia",
  },
  {
    sr: 12426,
    city: "Negage",
    city_code: "GXG",
    airport_name: "Negage",
    country: "Angola",
  },
  {
    sr: 12428,
    city: "Negarbo",
    city_code: "GBF",
    airport_name: "Negarbo",
    country: "Papua New Guinea",
  },
  {
    sr: 12430,
    city: "Negginan",
    city_code: "ZNG",
    airport_name: "Negginan",
    country: "Canada",
  },
  {
    sr: 12432,
    city: "Neghelli",
    city_code: "EGL",
    airport_name: "Neghelli",
    country: "Ethiopia",
  },
  {
    sr: 12434,
    city: "Negril",
    city_code: "NEG",
    airport_name: "Negril",
    country: "Jamaica",
  },
  {
    sr: 12436,
    city: "Neiva",
    city_code: "NVA",
    airport_name: "La Marguita",
    country: "Colombia",
  },
  {
    sr: 12438,
    city: "Nejjo",
    city_code: "NEJ",
    airport_name: "Nejjo",
    country: "Ethiopia",
  },
  {
    sr: 12440,
    city: "Nejran",
    city_code: "EAM",
    airport_name: "Nejran",
    country: "Saudi Arabia",
  },
  {
    sr: 12444,
    city: "Nekemt",
    city_code: "NEK",
    airport_name: "Nekemt",
    country: "Ethiopia",
  },
  {
    sr: 12446,
    city: "Nelson",
    city_code: "NSN",
    airport_name: "Nelson",
    country: "New Zealand",
  },
  {
    sr: 12448,
    city: "Nelson Lagoon",
    city_code: "NLG",
    airport_name: "Nelson Lagoon",
    country: "United States",
  },
  {
    sr: 12450,
    city: "Nelspruit",
    city_code: "NLP",
    airport_name: "Nelspruit International",
    country: "South Africa",
  },
  {
    sr: 12452,
    city: "Nelspruit",
    city_code: "MQP",
    airport_name: "Kruger Mpumalanga",
    country: "South Africa",
  },
  {
    sr: 12454,
    city: "Nema",
    city_code: "EMN",
    airport_name: "Nema",
    country: "Mauritania",
  },
  {
    sr: 12456,
    city: "Nemiscau",
    city_code: "YNS",
    airport_name: "Nemiscau",
    country: "Canada",
  },
  {
    sr: 12458,
    city: "Nenana",
    city_code: "ENN",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 12460,
    city: "Neosho",
    city_code: "EOS",
    airport_name: "Neosho",
    country: "United States",
  },
  {
    sr: 12462,
    city: "Nepalganj",
    city_code: "KEP",
    airport_name: "Nepalganj",
    country: "Nepal",
  },
  {
    sr: 12464,
    city: "Nephi",
    city_code: "NPH",
    airport_name: "Nephi",
    country: "United States",
  },
  {
    sr: 12466,
    city: "Neryungri",
    city_code: "NER",
    airport_name: "Neryungri",
    country: "Russia",
  },
  {
    sr: 12468,
    city: "Nesbyen",
    city_code: "XUL",
    airport_name: "Nesbyen Railway",
    country: "Norway",
  },
  {
    sr: 12470,
    city: "Neslandsvatn",
    city_code: "XUM",
    airport_name: "Neslandsvatn Railway",
    country: "Norway",
  },
  {
    sr: 12472,
    city: "Neubrandenburg",
    city_code: "FNB",
    airport_name: "Neubrandenburg",
    country: "Germany",
  },
  {
    sr: 12474,
    city: "Neumuenster",
    city_code: "EUM",
    airport_name: "Neumuenster",
    country: "Germany",
  },
  {
    sr: 12478,
    city: "Neuquen",
    city_code: "NQN",
    airport_name: "Neuquen",
    country: "Argentina",
  },
  {
    sr: 12480,
    city: "Nevada",
    city_code: "NVD",
    airport_name: "Nevada",
    country: "United States",
  },
  {
    sr: 12482,
    city: "Nevers",
    city_code: "NVS",
    airport_name: "Nevers",
    country: "France",
  },
  {
    sr: 12484,
    city: "Nevis",
    city_code: "NEV",
    airport_name: "Newcastle",
    country: "Saint Kitts and Nevis",
  },
  {
    sr: 12486,
    city: "Nevsehir",
    city_code: "NAV",
    airport_name: "Nevsehir",
    country: "Turkey",
  },
  {
    sr: 12488,
    city: "New Bedford",
    city_code: "EWB",
    airport_name: "New Bedford",
    country: "United States",
  },
  {
    sr: 12490,
    city: "New Bern",
    city_code: "EWN",
    airport_name: "Simmons Nott",
    country: "United States",
  },
  {
    sr: 12492,
    city: "New Bight",
    city_code: "NET",
    airport_name: "New Bight",
    country: "Bahamas",
  },
  {
    sr: 12494,
    city: "New Chenega",
    city_code: "NCN",
    airport_name: "New Chenega",
    country: "United States",
  },
  {
    sr: 12496,
    city: "New Halfa",
    city_code: "NHF",
    airport_name: "New Halfa",
    country: "Sudan",
  },
  {
    sr: 12498,
    city: "New Haven",
    city_code: "HVN",
    airport_name: "New Haven",
    country: "United States",
  },
  {
    sr: 12500,
    city: "New Haven",
    city_code: "ZVE",
    airport_name: "New Haven Rail",
    country: "United States",
  },
  {
    sr: 12502,
    city: "New Iberia",
    city_code: "ARA",
    airport_name: "Acadiana Regional",
    country: "United States",
  },
  {
    sr: 12504,
    city: "New Iberia",
    city_code: "LFT",
    airport_name: "Lafayette Regional",
    country: "United States",
  },
  {
    sr: 12506,
    city: "New Koliganek",
    city_code: "KGK",
    airport_name: "New Koliganek",
    country: "United States",
  },
  {
    sr: 12508,
    city: "New London",
    city_code: "GON",
    airport_name: "New London",
    country: "United States",
  },
  {
    sr: 12512,
    city: "New Moon",
    city_code: "NMP",
    airport_name: "New Moon",
    country: "Australia",
  },
  {
    sr: 12514,
    city: "New Orleans",
    city_code: "NEW",
    airport_name: "Lakefront",
    country: "United States",
  },
  {
    sr: 12516,
    city: "New Orleans",
    city_code: "NBG",
    airport_name: "NAS Alvin Callendar",
    country: "United States",
  },
  {
    sr: 12518,
    city: "New Philadelphia",
    city_code: "PHD",
    airport_name: "Harry Clever",
    country: "United States",
  },
  {
    sr: 12520,
    city: "New Plymouth",
    city_code: "NPL",
    airport_name: "New Plymouth",
    country: "New Zealand",
  },
  {
    sr: 12522,
    city: "New Richmond",
    city_code: "RNH",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 12524,
    city: "New Stuyahok",
    city_code: "KNW",
    airport_name: "New Stuyahok",
    country: "United States",
  },
  {
    sr: 12526,
    city: "New Ulm",
    city_code: "ULM",
    airport_name: "New Ulm",
    country: "United States",
  },
  {
    sr: 12528,
    city: "New Westminster",
    city_code: "YBD",
    airport_name: "New Westminster",
    country: "Canada",
  },
  {
    sr: 12530,
    city: "New York",
    city_code: "NBP",
    airport_name: "Battery Pk City N Cov",
    country: "United States",
  },
  {
    sr: 12532,
    city: "New York",
    city_code: "JRB",
    airport_name: "Downtown Manhattan H P",
    country: "United States",
  },
  {
    sr: 12534,
    city: "New York",
    city_code: "TSS",
    airport_name: "East 34th St Heliport",
    country: "United States",
  },
  {
    sr: 12536,
    city: "New York",
    city_code: "NES",
    airport_name: "East 34th St Landing",
    country: "United States",
  },
  {
    sr: 12538,
    city: "New York",
    city_code: "JRE",
    airport_name: "East 60th Street H P",
    country: "United States",
  },
  {
    sr: 12540,
    city: "New York",
    city_code: "FLU",
    airport_name: "Flushing",
    country: "United States",
  },
  {
    sr: 12542,
    city: "New York",
    city_code: "JFK",
    airport_name: "John F Kennedy Intl",
    country: "United States",
  },
  {
    sr: 12546,
    city: "New York",
    city_code: "LGA",
    airport_name: "La Guardia",
    country: "United States",
  },
  {
    sr: 12548,
    city: "New York",
    city_code: "QNY",
    airport_name: "Marine Air Terminal",
    country: "United States",
  },
  {
    sr: 12550,
    city: "New York",
    city_code: "ZYP",
    airport_name: "New York Penn STN",
    country: "United States",
  },
  {
    sr: 12552,
    city: "New York",
    city_code: "NWS",
    airport_name: "Pier 11 Wall St SPB",
    country: "United States",
  },
  {
    sr: 12554,
    city: "New York",
    city_code: "JRA",
    airport_name: "West 30th St Heliport",
    country: "United States",
  },
  {
    sr: 12556,
    city: "New York",
    city_code: "WTC",
    airport_name: "World Trade Center",
    country: "United States",
  },
  {
    sr: 12558,
    city: "Newark",
    city_code: "ZME",
    airport_name: "Metropark Rail",
    country: "United States",
  },
  {
    sr: 12560,
    city: "Newark",
    city_code: "ZRP",
    airport_name: "Newark NJ Rail",
    country: "United States",
  },
  {
    sr: 12562,
    city: "Newburgh",
    city_code: "SWF",
    airport_name: "Stewart",
    country: "United States",
  },
  {
    sr: 12564,
    city: "Newbury",
    city_code: "EWY",
    airport_name: "Greenham RAF",
    country: "United Kingdom",
  },
  {
    sr: 12566,
    city: "Newcastle",
    city_code: "NCL",
    airport_name: "Newcastle Airport",
    country: "United Kingdom",
  },
  {
    sr: 12568,
    city: "Newcastle",
    city_code: "BEO",
    airport_name: "Belmont",
    country: "Australia",
  },
  {
    sr: 12570,
    city: "Newcastle",
    city_code: "NTL",
    airport_name: "Williamtown",
    country: "Australia",
  },
  {
    sr: 12572,
    city: "Newcastle",
    city_code: "ECS",
    airport_name: "Mondell",
    country: "United States",
  },
  {
    sr: 12574,
    city: "Newcastle",
    city_code: "NCS",
    airport_name: "Newcastle",
    country: "South Africa",
  },
  {
    sr: 12576,
    city: "Newman",
    city_code: "ZNE",
    airport_name: "Newman",
    country: "Australia",
  },
  {
    sr: 12580,
    city: "Newport",
    city_code: "NWH",
    airport_name: "Parlin Field",
    country: "United States",
  },
  {
    sr: 12582,
    city: "Newport",
    city_code: "ONP",
    airport_name: "Newport",
    country: "United States",
  },
  {
    sr: 12584,
    city: "Newport",
    city_code: "NPT",
    airport_name: "State",
    country: "United States",
  },
  {
    sr: 12586,
    city: "Newport",
    city_code: "EFK",
    airport_name: "Newport",
    country: "United States",
  },
  {
    sr: 12588,
    city: "Newport Beach",
    city_code: "JNP",
    airport_name: "Heliport",
    country: "United States",
  },
  {
    sr: 12590,
    city: "Newquay",
    city_code: "NQY",
    airport_name: "St Mawgan",
    country: "United Kingdom",
  },
  {
    sr: 12592,
    city: "Newry",
    city_code: "NRY",
    airport_name: "Newry",
    country: "Australia",
  },
  {
    sr: 12594,
    city: "Newtok",
    city_code: "WWT",
    airport_name: "Newtok",
    country: "United States",
  },
  {
    sr: 12596,
    city: "Newton",
    city_code: "TNU",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 12598,
    city: "Newton",
    city_code: "EWK",
    airport_name: "City County",
    country: "United States",
  },
  {
    sr: 12600,
    city: "Neyveli",
    city_code: "NVY",
    airport_name: "Neyveli",
    country: "India",
  },
  {
    sr: 12602,
    city: "Ngala",
    city_code: "NGL",
    airport_name: "Ngala Airfield",
    country: "South Africa",
  },
  {
    sr: 12604,
    city: "Ngaoundere",
    city_code: "NGE",
    airport_name: "Ngaoundere",
    country: "Cameroon",
  },
  {
    sr: 12606,
    city: "Ngau Island",
    city_code: "NGI",
    airport_name: "Ngau Island",
    country: "Fiji",
  },
  {
    sr: 12608,
    city: "Ngiva",
    city_code: "NGV",
    airport_name: "Ngiva",
    country: "Angola",
  },
  {
    sr: 12610,
    city: "Ngoma",
    city_code: "ZGM",
    airport_name: "Ngoma",
    country: "Zambia",
  },
  {
    sr: 12614,
    city: "Ngukurr",
    city_code: "RPM",
    airport_name: "Ngukurr",
    country: "Australia",
  },
  {
    sr: 12616,
    city: "Nha Trang",
    city_code: "NHA",
    airport_name: "Nha Trang",
    country: "Vietnam",
  },
  {
    sr: 12618,
    city: "Nha Trang",
    city_code: "CXR",
    airport_name: "Cam Ranh",
    country: "Vietnam",
  },
  {
    sr: 12620,
    city: "Niagara Falls",
    city_code: "IAG",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 12622,
    city: "Niamey",
    city_code: "NIM",
    airport_name: "Niamey",
    country: "Niger",
  },
  {
    sr: 12624,
    city: "Niamtougou",
    city_code: "LRL",
    airport_name: "Niamtougou",
    country: "Togo",
  },
  {
    sr: 12626,
    city: "Niaqornaarsuk",
    city_code: "QMK",
    airport_name: "Heliport",
    country: "Greenland",
  },
  {
    sr: 12628,
    city: "Niblack",
    city_code: "NIE",
    airport_name: "Niblack",
    country: "United States",
  },
  {
    sr: 12630,
    city: "Nicaro",
    city_code: "ICR",
    airport_name: "Nicaro",
    country: "Cuba",
  },
  {
    sr: 12632,
    city: "Nichen Cove",
    city_code: "NKV",
    airport_name: "Nichen Cove",
    country: "United States",
  },
  {
    sr: 12634,
    city: "Nicholson",
    city_code: "NLS",
    airport_name: "Nicholson",
    country: "Australia",
  },
  {
    sr: 12636,
    city: "Nicosia",
    city_code: "NIC",
    airport_name: "Nicosia",
    country: "Cyprus",
  },
  {
    sr: 12638,
    city: "Nicoya",
    city_code: "NCT",
    airport_name: "Guanacaste",
    country: "Costa Rica",
  },
  {
    sr: 12640,
    city: "Nieuw Nickerie",
    city_code: "ICK",
    airport_name: "Nieuw Nickerie",
    country: "Suriname",
  },
  {
    sr: 12642,
    city: "Nifty",
    city_code: "NIF",
    airport_name: "Nifty",
    country: "Australia",
  },
  {
    sr: 12644,
    city: "Nightmute",
    city_code: "NME",
    airport_name: "Nightmute",
    country: "United States",
  },
  {
    sr: 12648,
    city: "Niigata",
    city_code: "KIJ",
    airport_name: "Niigata",
    country: "Japan",
  },
  {
    sr: 12650,
    city: "Niihama",
    city_code: "IHA",
    airport_name: "Niihama",
    country: "Japan",
  },
  {
    sr: 12652,
    city: "Nikolaev",
    city_code: "NLV",
    airport_name: "Nikolaev",
    country: "Ukraine",
  },
  {
    sr: 12654,
    city: "Nikolai",
    city_code: "NIB",
    airport_name: "Nikolai",
    country: "United States",
  },
  {
    sr: 12656,
    city: "Nikolski",
    city_code: "IKO",
    airport_name: "Nikolski AFS",
    country: "United States",
  },
  {
    sr: 12658,
    city: "Nikunau",
    city_code: "NIG",
    airport_name: "Nikunau",
    country: "Kiribati",
  },
  {
    sr: 12660,
    city: "Niles",
    city_code: "NLE",
    airport_name: "Jerry Tyler Memorial",
    country: "United States",
  },
  {
    sr: 12662,
    city: "Nimba",
    city_code: "NIA",
    airport_name: "Nimba",
    country: "Liberia",
  },
  {
    sr: 12664,
    city: "Nimes",
    city_code: "FNI",
    airport_name: "Garons",
    country: "France",
  },
  {
    sr: 12666,
    city: "Nimes",
    city_code: "ZYN",
    airport_name: "Railway",
    country: "France",
  },
  {
    sr: 12668,
    city: "Nimroz",
    city_code: "IMZ",
    airport_name: "Nimroz",
    country: "Afghanistan",
  },
  {
    sr: 12670,
    city: "Ningbo",
    city_code: "NGB",
    airport_name: "Ningbo",
    country: "China",
  },
  {
    sr: 12672,
    city: "Ningerum",
    city_code: "NGR",
    airport_name: "Ningerum",
    country: "Papua New Guinea",
  },
  {
    sr: 12674,
    city: "Ninilchik",
    city_code: "NIN",
    airport_name: "Ninilchik",
    country: "United States",
  },
  {
    sr: 12676,
    city: "Nioki",
    city_code: "NIO",
    airport_name: "Nioki",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 12678,
    city: "Niokolo Koba",
    city_code: "NIK",
    airport_name: "Niokolo Koba",
    country: "Senegal",
  },
  {
    sr: 12682,
    city: "Nioro",
    city_code: "NIX",
    airport_name: "Nioro",
    country: "Mali",
  },
  {
    sr: 12684,
    city: "Niort",
    city_code: "NIT",
    airport_name: "Niort",
    country: "France",
  },
  {
    sr: 12686,
    city: "Nipa",
    city_code: "NPG",
    airport_name: "Nipa",
    country: "Papua New Guinea",
  },
  {
    sr: 12688,
    city: "Niquelandia",
    city_code: "NQL",
    airport_name: "Niquelandia",
    country: "Brazil",
  },
  {
    sr: 12690,
    city: "Nis",
    city_code: "INI",
    airport_name: "Nis",
    country: "Serbia",
  },
  {
    sr: 12692,
    city: "Nishinoomote",
    city_code: "IIN",
    airport_name: "Nishinoomote",
    country: "Japan",
  },
  {
    sr: 12694,
    city: "Nissan Island",
    city_code: "IIS",
    airport_name: "Nissan Island",
    country: "Papua New Guinea",
  },
  {
    sr: 12696,
    city: "Nitchequon",
    city_code: "YNI",
    airport_name: "Nitchequon",
    country: "Canada",
  },
  {
    sr: 12698,
    city: "Niuatoputapu",
    city_code: "NTT",
    airport_name: "Kuini Lavenia",
    country: "Tonga",
  },
  {
    sr: 12700,
    city: "Niue Island",
    city_code: "IUE",
    airport_name: "Hanan",
    country: "Niue",
  },
  {
    sr: 12702,
    city: "Nizhnevartovsk",
    city_code: "NJC",
    airport_name: "Nizhnevartovsk",
    country: "Russia",
  },
  {
    sr: 12704,
    city: "Nizhniy Novgorod",
    city_code: "GOJ",
    airport_name: "Nizhniy Novgorod",
    country: "Russia",
  },
  {
    sr: 12706,
    city: "Njombe",
    city_code: "JOM",
    airport_name: "Njombe",
    country: "Tanzania",
  },
  {
    sr: 12708,
    city: "Nkan",
    city_code: "NKA",
    airport_name: "Nkan",
    country: "Gabon",
  },
  {
    sr: 12710,
    city: "Nkaus",
    city_code: "NKU",
    airport_name: "Nkaus",
    country: "Lesotho",
  },
  {
    sr: 12712,
    city: "Nkayi",
    city_code: "NKY",
    airport_name: "Nkayi",
    country: "Congo",
  },
  {
    sr: 12716,
    city: "Nkolo",
    city_code: "NKL",
    airport_name: "Nkolo",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 12718,
    city: "Nkongsamba",
    city_code: "NKS",
    airport_name: "Nkongsamba",
    country: "Cameroon",
  },
  {
    sr: 12720,
    city: "Noatak",
    city_code: "WTK",
    airport_name: "Noatak",
    country: "United States",
  },
  {
    sr: 12722,
    city: "Nogales",
    city_code: "OLS",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 12724,
    city: "Nogales",
    city_code: "NOG",
    airport_name: "Nogales",
    country: "Mexico",
  },
  {
    sr: 12726,
    city: "Nojabrxsk",
    city_code: "NOJ",
    airport_name: "Nojabrxsk",
    country: "Russia",
  },
  {
    sr: 12728,
    city: "Nojeh",
    city_code: "NUJ",
    airport_name: "Nojeh",
    country: "Iran",
  },
  {
    sr: 12730,
    city: "Nomad River",
    city_code: "NOM",
    airport_name: "Nomad River",
    country: "Papua New Guinea",
  },
  {
    sr: 12732,
    city: "Nomane",
    city_code: "NMN",
    airport_name: "Nomane",
    country: "Papua New Guinea",
  },
  {
    sr: 12734,
    city: "Nome",
    city_code: "OME",
    airport_name: "Nome",
    country: "United States",
  },
  {
    sr: 12736,
    city: "Nondalton",
    city_code: "NNL",
    airport_name: "Nondalton",
    country: "United States",
  },
  {
    sr: 12738,
    city: "Nonouti",
    city_code: "NON",
    airport_name: "Nonouti",
    country: "Kiribati",
  },
  {
    sr: 12740,
    city: "Noonkanbah",
    city_code: "NKB",
    airport_name: "Noonkanbah",
    country: "Australia",
  },
  {
    sr: 12742,
    city: "Noorvik",
    city_code: "ORV",
    airport_name: "Curtis Memorial",
    country: "United States",
  },
  {
    sr: 12744,
    city: "Noosa",
    city_code: "NSA",
    airport_name: "Noosa",
    country: "Australia",
  },
  {
    sr: 12746,
    city: "Noosaville",
    city_code: "NSV",
    airport_name: "Noosaville",
    country: "Australia",
  },
  {
    sr: 12750,
    city: "Nootka Sound",
    city_code: "YNK",
    airport_name: "Nootka Sound",
    country: "Canada",
  },
  {
    sr: 12752,
    city: "Nordagutu",
    city_code: "XUO",
    airport_name: "Nordagutu Railway",
    country: "Norway",
  },
  {
    sr: 12754,
    city: "Norddeich",
    city_code: "NOE",
    airport_name: "Norddeich",
    country: "Germany",
  },
  {
    sr: 12756,
    city: "Norden",
    city_code: "NOD",
    airport_name: "Norden",
    country: "Germany",
  },
  {
    sr: 12758,
    city: "Norderney",
    city_code: "NRD",
    airport_name: "Norderney",
    country: "Germany",
  },
  {
    sr: 12760,
    city: "Nordfjordur",
    city_code: "NOR",
    airport_name: "Nordfjordur",
    country: "Iceland",
  },
  {
    sr: 12762,
    city: "Nordholz Spieka",
    city_code: "NDZ",
    airport_name: "Cuxhaven",
    country: "Germany",
  },
  {
    sr: 12764,
    city: "Norfolk",
    city_code: "OFK",
    airport_name: "Stefan Field",
    country: "United States",
  },
  {
    sr: 12766,
    city: "Norfolk",
    city_code: "ORF",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 12768,
    city: "Norfolk",
    city_code: "NGU",
    airport_name: "Nas Chambers",
    country: "United States",
  },
  {
    sr: 12770,
    city: "Norfolk Island",
    city_code: "NLK",
    airport_name: "Norfolk Island",
    country: "Norfolk Island",
  },
  {
    sr: 12772,
    city: "Norman",
    city_code: "OUN",
    airport_name: "Max Westheimer",
    country: "United States",
  },
  {
    sr: 12774,
    city: "Norman Wells",
    city_code: "YVQ",
    airport_name: "Norman Wells",
    country: "Canada",
  },
  {
    sr: 12776,
    city: "Normanton",
    city_code: "NTN",
    airport_name: "Normanton",
    country: "Australia",
  },
  {
    sr: 12778,
    city: "Norridgewock",
    city_code: "OWK",
    airport_name: "Central Maine",
    country: "United States",
  },
  {
    sr: 12780,
    city: "Norrkoping",
    city_code: "NRK",
    airport_name: "Kungsangen",
    country: "Sweden",
  },
  {
    sr: 12784,
    city: "Norseman",
    city_code: "NSM",
    airport_name: "Norseman",
    country: "Australia",
  },
  {
    sr: 12786,
    city: "Norsup",
    city_code: "NUS",
    airport_name: "Norsup",
    country: "Vanuatu",
  },
  {
    sr: 12788,
    city: "North Battleford",
    city_code: "YQW",
    airport_name: "North Battleford",
    country: "Canada",
  },
  {
    sr: 12790,
    city: "North Bay",
    city_code: "YYB",
    airport_name: "North Bay",
    country: "Canada",
  },
  {
    sr: 12792,
    city: "North Bend",
    city_code: "OTH",
    airport_name: "North Bend",
    country: "United States",
  },
  {
    sr: 12794,
    city: "North Caicos",
    city_code: "NCA",
    airport_name: "North Caicos",
    country: "Turks and Caicos Islands",
  },
  {
    sr: 12796,
    city: "North Eleuthera",
    city_code: "ELH",
    airport_name: "International",
    country: "Bahamas",
  },
  {
    sr: 12798,
    city: "North Platte",
    city_code: "LBF",
    airport_name: "Lee Bird Field",
    country: "United States",
  },
  {
    sr: 12800,
    city: "North Ronaldsay",
    city_code: "NRL",
    airport_name: "North Ronaldsay",
    country: "United Kingdom",
  },
  {
    sr: 12802,
    city: "North Spirit Lake",
    city_code: "YNO",
    airport_name: "North Spirit Lake",
    country: "Canada",
  },
  {
    sr: 12804,
    city: "Northampton",
    city_code: "ORM",
    airport_name: "Northampton",
    country: "United Kingdom",
  },
  {
    sr: 12806,
    city: "Northbrook",
    city_code: "OBK",
    airport_name: "Sky Harbor",
    country: "United States",
  },
  {
    sr: 12808,
    city: "Northeast Cape",
    city_code: "OHC",
    airport_name: "AFS",
    country: "United States",
  },
  {
    sr: 12810,
    city: "Northolt",
    city_code: "NHT",
    airport_name: "Northolt",
    country: "United Kingdom",
  },
  {
    sr: 12812,
    city: "Northway",
    city_code: "ORT",
    airport_name: "Northway",
    country: "United States",
  },
  {
    sr: 12814,
    city: "Norwalk",
    city_code: "ORQ",
    airport_name: "Heliport",
    country: "United States",
  },
  {
    sr: 12818,
    city: "Norway House",
    city_code: "YNE",
    airport_name: "Norway House",
    country: "Canada",
  },
  {
    sr: 12820,
    city: "Norwich",
    city_code: "NWI",
    airport_name: "Norwich Arpt",
    country: "United Kingdom",
  },
  {
    sr: 12822,
    city: "Norwich",
    city_code: "OIC",
    airport_name: "Eaton",
    country: "United States",
  },
  {
    sr: 12824,
    city: "Norwood",
    city_code: "OWD",
    airport_name: "Memorial",
    country: "United States",
  },
  {
    sr: 12826,
    city: "Nosara Beach",
    city_code: "NOB",
    airport_name: "Nosara Beach",
    country: "Costa Rica",
  },
  {
    sr: 12828,
    city: "Nossi be",
    city_code: "NOS",
    airport_name: "Fascene",
    country: "Madagascar",
  },
  {
    sr: 12830,
    city: "Notodden",
    city_code: "NTB",
    airport_name: "Notodden",
    country: "Norway",
  },
  {
    sr: 12832,
    city: "Nottingham",
    city_code: "NQT",
    airport_name: "Nottingham Airport",
    country: "United Kingdom",
  },
  {
    sr: 12834,
    city: "Nottingham",
    city_code: "EMA",
    airport_name: "Nottingham E Midlands",
    country: "United Kingdom",
  },
  {
    sr: 12836,
    city: "Nottingham",
    city_code: "XQI",
    airport_name: "Railway Station",
    country: "United Kingdom",
  },
  {
    sr: 12838,
    city: "Nottingham",
    city_code: "XQH",
    airport_name: "Railway Station",
    country: "United Kingdom",
  },
  {
    sr: 12840,
    city: "Nouadhibou",
    city_code: "NDB",
    airport_name: "Nouadhibou",
    country: "Mauritania",
  },
  {
    sr: 12842,
    city: "Nouakchott",
    city_code: "NKC",
    airport_name: "Nouakchott",
    country: "Mauritania",
  },
  {
    sr: 12844,
    city: "Noumea",
    city_code: "GEA",
    airport_name: "Magenta",
    country: "New Caledonia",
  },
  {
    sr: 12846,
    city: "Noumea",
    city_code: "NOU",
    airport_name: "Tontouta",
    country: "New Caledonia",
  },
  {
    sr: 12848,
    city: "Nouna",
    city_code: "XNU",
    airport_name: "Nouna",
    country: "Burkina Faso",
  },
  {
    sr: 12852,
    city: "Nova Xavantina",
    city_code: "NOK",
    airport_name: "Nova Xavantina",
    country: "Brazil",
  },
  {
    sr: 12854,
    city: "Novato",
    city_code: "NOT",
    airport_name: "Novato",
    country: "United States",
  },
  {
    sr: 12856,
    city: "Novgorod",
    city_code: "NVR",
    airport_name: "Novgorod",
    country: "Russia",
  },
  {
    sr: 12858,
    city: "Aripuana",
    city_code: "NVP",
    airport_name: "Novo Aripuana",
    country: "Brazil",
  },
  {
    sr: 12860,
    city: "Novokuznetsk",
    city_code: "NOZ",
    airport_name: "Novokuznetsk",
    country: "Russia",
  },
  {
    sr: 12862,
    city: "Novorossijsk",
    city_code: "NOI",
    airport_name: "Novorossijsk",
    country: "Russia",
  },
  {
    sr: 12864,
    city: "Novosibirsk",
    city_code: "OVB",
    airport_name: "Tolmachevo",
    country: "Russia",
  },
  {
    sr: 12866,
    city: "Novy Urengoy",
    city_code: "NUX",
    airport_name: "Novy Urengoy",
    country: "Russia",
  },
  {
    sr: 12868,
    city: "Now Shahr",
    city_code: "NSH",
    airport_name: "Now Shahr",
    country: "Iran",
  },
  {
    sr: 12870,
    city: "Nowata",
    city_code: "NWT",
    airport_name: "Nowata",
    country: "Papua New Guinea",
  },
  {
    sr: 12872,
    city: "Nowra",
    city_code: "NOA",
    airport_name: "Nowra",
    country: "Australia",
  },
  {
    sr: 12874,
    city: "Nueva Casas Grandes",
    city_code: "NCG",
    airport_name: "Nueva Casas Grandes",
    country: "Mexico",
  },
  {
    sr: 12876,
    city: "Nueva Gerona",
    city_code: "GER",
    airport_name: "Rafael Cabrera",
    country: "Cuba",
  },
  {
    sr: 12878,
    city: "Nueva Guinea",
    city_code: "NVG",
    airport_name: "Nueva Guinea",
    country: "Nicaragua",
  },
  {
    sr: 12880,
    city: "Nuevo Laredo",
    city_code: "NLD",
    airport_name: "Intl Quetzalcoatl",
    country: "Mexico",
  },
  {
    sr: 12882,
    city: "Nuguria",
    city_code: "NUG",
    airport_name: "Nuguria",
    country: "Papua New Guinea",
  },
  {
    sr: 12886,
    city: "Nuiqsut",
    city_code: "NUI",
    airport_name: "Nuiqsut",
    country: "United States",
  },
  {
    sr: 12888,
    city: "Nuku",
    city_code: "UKU",
    airport_name: "Nuku",
    country: "Papua New Guinea",
  },
  {
    sr: 12890,
    city: "Nuku Hiva",
    city_code: "NHV",
    airport_name: "Nuku Hiva",
    country: "French Polynesia",
  },
  {
    sr: 12892,
    city: "Nukus",
    city_code: "NCU",
    airport_name: "Nukus",
    country: "Uzbekistan",
  },
  {
    sr: 12894,
    city: "Nukutavake",
    city_code: "NUK",
    airport_name: "Nukutavake",
    country: "French Polynesia",
  },
  {
    sr: 12896,
    city: "Nulato",
    city_code: "NUL",
    airport_name: "Nulato",
    country: "United States",
  },
  {
    sr: 12898,
    city: "Nullagine",
    city_code: "NLL",
    airport_name: "Nullagine",
    country: "Australia",
  },
  {
    sr: 12900,
    city: "Nullarbor",
    city_code: "NUR",
    airport_name: "Nullarbor",
    country: "Australia",
  },
  {
    sr: 12902,
    city: "Numbulwar",
    city_code: "NUB",
    airport_name: "Numbulwar",
    country: "Australia",
  },
  {
    sr: 12904,
    city: "Numfoor",
    city_code: "FOO",
    airport_name: "Numfoor",
    country: "Indonesia",
  },
  {
    sr: 12906,
    city: "Nunapitchuk",
    city_code: "NUP",
    airport_name: "Nunapitchuk",
    country: "United States",
  },
  {
    sr: 12908,
    city: "Nunchia",
    city_code: "NUH",
    airport_name: "Nunchia",
    country: "Colombia",
  },
  {
    sr: 12910,
    city: "Nunukan",
    city_code: "NNX",
    airport_name: "Nunukan",
    country: "Indonesia",
  },
  {
    sr: 12912,
    city: "Nuqui",
    city_code: "NQU",
    airport_name: "Nuqui",
    country: "Colombia",
  },
  {
    sr: 12914,
    city: "Nushki",
    city_code: "NHS",
    airport_name: "Nushki",
    country: "Pakistan",
  },
  {
    sr: 12916,
    city: "Nutuve",
    city_code: "NUT",
    airport_name: "Nutuve",
    country: "Papua New Guinea",
  },
  {
    sr: 12920,
    city: "Nutwood Downs",
    city_code: "UTD",
    airport_name: "Nutwood Downs",
    country: "Australia",
  },
  {
    sr: 12922,
    city: "Nuuk",
    city_code: "GOH",
    airport_name: "Nuuk",
    country: "Greenland",
  },
  {
    sr: 12924,
    city: "Nyac",
    city_code: "ZNC",
    airport_name: "Nyac",
    country: "United States",
  },
  {
    sr: 12926,
    city: "Nyala",
    city_code: "UYL",
    airport_name: "Nyala",
    country: "Sudan",
  },
  {
    sr: 12928,
    city: "Nyaung u",
    city_code: "NYU",
    airport_name: "Nyaung u",
    country: "Myanmar",
  },
  {
    sr: 12930,
    city: "Nyeri",
    city_code: "NYE",
    airport_name: "Nyeri",
    country: "Kenya",
  },
  {
    sr: 12932,
    city: "Nykoping",
    city_code: "XWZ",
    airport_name: "Nykoping",
    country: "Sweden",
  },
  {
    sr: 12934,
    city: "Nyngan",
    city_code: "NYN",
    airport_name: "Nyngan",
    country: "Australia",
  },
  {
    sr: 12936,
    city: "Nzagi",
    city_code: "NZA",
    airport_name: "Nzagi",
    country: "Angola",
  },
  {
    sr: 12938,
    city: "Nzerekore",
    city_code: "NZE",
    airport_name: "Nzerekore",
    country: "Guinea",
  },
  {
    sr: 12940,
    city: "Nzoia",
    city_code: "NZO",
    airport_name: "Nzoia",
    country: "Kenya",
  },
  {
    sr: 12942,
    city: "Oahu",
    city_code: "HDH",
    airport_name: "Dillingham Airfield",
    country: "United States",
  },
  {
    sr: 12944,
    city: "Oak Harbor",
    city_code: "ODW",
    airport_name: "Oak Harbor",
    country: "United States",
  },
  {
    sr: 12946,
    city: "Oakey",
    city_code: "OKY",
    airport_name: "Oakey",
    country: "Australia",
  },
  {
    sr: 12948,
    city: "Oakham",
    city_code: "OKH",
    airport_name: "Cottesmor RAF",
    country: "United Kingdom",
  },
  {
    sr: 12950,
    city: "Oakland",
    city_code: "JCE",
    airport_name: "Convention Center H P",
    country: "United States",
  },
  {
    sr: 12954,
    city: "Oakland",
    city_code: "OAK",
    airport_name: "Metropolitan Oak Intl",
    country: "United States",
  },
  {
    sr: 12956,
    city: "Oakland",
    city_code: "OBT",
    airport_name: "Oakland Coliseum Stat",
    country: "United States",
  },
  {
    sr: 12958,
    city: "Oaktown",
    city_code: "OTN",
    airport_name: "Green",
    country: "United States",
  },
  {
    sr: 12960,
    city: "Oamaru",
    city_code: "OAM",
    airport_name: "Oamaru",
    country: "New Zealand",
  },
  {
    sr: 12962,
    city: "Oaxaca",
    city_code: "OAX",
    airport_name: "Xoxocotlan",
    country: "Mexico",
  },
  {
    sr: 12964,
    city: "Oban",
    city_code: "OBN",
    airport_name: "Oban Airport",
    country: "United Kingdom",
  },
  {
    sr: 12966,
    city: "Oban",
    city_code: "OHP",
    airport_name: "Heliport",
    country: "United Kingdom",
  },
  {
    sr: 12968,
    city: "Oban",
    city_code: "OBA",
    airport_name: "Oban",
    country: "Australia",
  },
  {
    sr: 12970,
    city: "Obano",
    city_code: "OBD",
    airport_name: "Obano",
    country: "Indonesia",
  },
  {
    sr: 12972,
    city: "Obbia",
    city_code: "CMO",
    airport_name: "Obbia",
    country: "Somalia",
  },
  {
    sr: 12974,
    city: "Oberpfaffenhofen",
    city_code: "OBF",
    airport_name: "Oberpfaffenhofen",
    country: "Germany",
  },
  {
    sr: 12976,
    city: "Obidos",
    city_code: "OBI",
    airport_name: "Obidos",
    country: "Brazil",
  },
  {
    sr: 12978,
    city: "Obihiro",
    city_code: "OBO",
    airport_name: "Obihiro",
    country: "Japan",
  },
  {
    sr: 12980,
    city: "Obo",
    city_code: "OBX",
    airport_name: "Obo",
    country: "Papua New Guinea",
  },
  {
    sr: 12982,
    city: "Obock",
    city_code: "OBC",
    airport_name: "Obock",
    country: "Djibouti",
  },
  {
    sr: 12984,
    city: "Obre Lake",
    city_code: "YDW",
    airport_name: "Obre Lake",
    country: "Canada",
  },
  {
    sr: 12988,
    city: "Ocala",
    city_code: "OCF",
    airport_name: "Taylor Field",
    country: "United States",
  },
  {
    sr: 12990,
    city: "Ocana",
    city_code: "OCV",
    airport_name: "Aguasclaras",
    country: "Colombia",
  },
  {
    sr: 12992,
    city: "Ocean City",
    city_code: "OCE",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 12994,
    city: "Ocean Falls",
    city_code: "ZOF",
    airport_name: "Ocean Falls",
    country: "Canada",
  },
  {
    sr: 12996,
    city: "Ocean Reef",
    city_code: "OCA",
    airport_name: "Ocean Reef",
    country: "United States",
  },
  {
    sr: 12998,
    city: "Oceana",
    city_code: "NTU",
    airport_name: "NAS",
    country: "United States",
  },
  {
    sr: 13000,
    city: "Oceanic",
    city_code: "OCI",
    airport_name: "Oceanic",
    country: "United States",
  },
  {
    sr: 13002,
    city: "Oceanside",
    city_code: "OCN",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 13004,
    city: "Ocho Rios",
    city_code: "OCJ",
    airport_name: "Boscobel",
    country: "Jamaica",
  },
  {
    sr: 13006,
    city: "Ocussi",
    city_code: "OEC",
    airport_name: "Ocussi",
    country: "Indonesia",
  },
  {
    sr: 13008,
    city: "Odate Noshiro",
    city_code: "ONJ",
    airport_name: "Odate Noshiro",
    country: "Japan",
  },
  {
    sr: 13010,
    city: "Oddor Meanche",
    city_code: "OMY",
    airport_name: "Oddor Meanche",
    country: "Cambodia",
  },
  {
    sr: 13012,
    city: "Odense",
    city_code: "ODE",
    airport_name: "Beldringe",
    country: "Denmark",
  },
  {
    sr: 13014,
    city: "Odessa",
    city_code: "ODS",
    airport_name: "Central",
    country: "Ukraine",
  },
  {
    sr: 13016,
    city: "Odienne",
    city_code: "KEO",
    airport_name: "Odienne",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 13018,
    city: "Odiham",
    city_code: "ODH",
    airport_name: "RAF Station",
    country: "United Kingdom",
  },
  {
    sr: 13022,
    city: "Oenpelli",
    city_code: "OPI",
    airport_name: "Oenpelli",
    country: "Australia",
  },
  {
    sr: 13024,
    city: "Ofu",
    city_code: "OFU",
    airport_name: "Ofu",
    country: "American Samoa",
  },
  {
    sr: 13026,
    city: "Ogallala",
    city_code: "OGA",
    airport_name: "Searle Field",
    country: "United States",
  },
  {
    sr: 13028,
    city: "Ogden",
    city_code: "HIF",
    airport_name: "Hill AFB",
    country: "United States",
  },
  {
    sr: 13030,
    city: "Ogden",
    city_code: "OGD",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 13032,
    city: "Ogdensburg",
    city_code: "OGS",
    airport_name: "Ogdensburg",
    country: "United States",
  },
  {
    sr: 13034,
    city: "Ogeranang",
    city_code: "OGE",
    airport_name: "Ogeranang",
    country: "Papua New Guinea",
  },
  {
    sr: 13036,
    city: "Ogle",
    city_code: "OGL",
    airport_name: "Ogle",
    country: "Guyana",
  },
  {
    sr: 13038,
    city: "Ogoki",
    city_code: "YOG",
    airport_name: "Ogoki",
    country: "Canada",
  },
  {
    sr: 13040,
    city: "Ohakea",
    city_code: "OHA",
    airport_name: "Royal Air Force Base",
    country: "New Zealand",
  },
  {
    sr: 13042,
    city: "Ohrid",
    city_code: "OHD",
    airport_name: "Ohrid",
    country: "Yugoslavia",
  },
  {
    sr: 13044,
    city: "Oiapoque",
    city_code: "OYK",
    airport_name: "Oiapoque",
    country: "Brazil",
  },
  {
    sr: 13046,
    city: "Oil City",
    city_code: "OIL",
    airport_name: "Splane Memorial",
    country: "United States",
  },
  {
    sr: 13048,
    city: "Oita",
    city_code: "OIT",
    airport_name: "Oita",
    country: "Japan",
  },
  {
    sr: 13050,
    city: "Okaba",
    city_code: "OKQ",
    airport_name: "Okaba",
    country: "Indonesia",
  },
  {
    sr: 13052,
    city: "Okao",
    city_code: "OKV",
    airport_name: "Okao",
    country: "Papua New Guinea",
  },
  {
    sr: 13056,
    city: "Okaukuejo",
    city_code: "OKF",
    airport_name: "Okaukuejo",
    country: "Namibia",
  },
  {
    sr: 13058,
    city: "Okayama",
    city_code: "OKJ",
    airport_name: "Okayama",
    country: "Japan",
  },
  {
    sr: 13060,
    city: "Okeechobee",
    city_code: "OBE",
    airport_name: "County",
    country: "United States",
  },
  {
    sr: 13062,
    city: "Okhotsk",
    city_code: "OHO",
    airport_name: "Okhotsk",
    country: "Russia",
  },
  {
    sr: 13064,
    city: "Oki Island",
    city_code: "OKI",
    airport_name: "Oki Island",
    country: "Japan",
  },
  {
    sr: 13066,
    city: "Okinawa",
    city_code: "DNA",
    airport_name: "Kadena AFB",
    country: "Japan",
  },
  {
    sr: 13068,
    city: "Okinawa",
    city_code: "OKA",
    airport_name: "Naha",
    country: "Japan",
  },
  {
    sr: 13070,
    city: "Okinawa",
    city_code: "AHA",
    airport_name: "Naha AFB",
    country: "Japan",
  },
  {
    sr: 13072,
    city: "Okino Erabu",
    city_code: "OKE",
    airport_name: "Okino Erabu",
    country: "Japan",
  },
  {
    sr: 13074,
    city: "Oklahoma City",
    city_code: "DWN",
    airport_name: "Downtown Airpark",
    country: "United States",
  },
  {
    sr: 13076,
    city: "Oklahoma City",
    city_code: "TIK",
    airport_name: "Tinker AFB",
    country: "United States",
  },
  {
    sr: 13078,
    city: "Oklahoma City",
    city_code: "PWA",
    airport_name: "Wiley Post",
    country: "United States",
  },
  {
    sr: 13080,
    city: "Oklahoma City",
    city_code: "OKC",
    airport_name: "Will Rogers World",
    country: "United States",
  },
  {
    sr: 13082,
    city: "Okmulgee",
    city_code: "OKM",
    airport_name: "Okmulgee",
    country: "United States",
  },
  {
    sr: 13084,
    city: "Okondja",
    city_code: "OKN",
    airport_name: "Okondja",
    country: "Gabon",
  },
  {
    sr: 13086,
    city: "Okoyo",
    city_code: "OKG",
    airport_name: "Okoyo",
    country: "Congo",
  },
  {
    sr: 13090,
    city: "Oksapmin",
    city_code: "OKP",
    airport_name: "Oksapmin",
    country: "Papua New Guinea",
  },
  {
    sr: 13092,
    city: "Oksibil",
    city_code: "OKL",
    airport_name: "Oksibil",
    country: "Indonesia",
  },
  {
    sr: 13094,
    city: "Oktiabrskij",
    city_code: "OKT",
    airport_name: "Oktiabrskij",
    country: "Russia",
  },
  {
    sr: 13096,
    city: "Okushiri",
    city_code: "OIR",
    airport_name: "Okushiri",
    country: "Japan",
  },
  {
    sr: 13098,
    city: "Olafsfjordur",
    city_code: "OFJ",
    airport_name: "Olafsfjordur",
    country: "Iceland",
  },
  {
    sr: 13100,
    city: "Olafsvik",
    city_code: "OLI",
    airport_name: "Rif",
    country: "Iceland",
  },
  {
    sr: 13102,
    city: "Olanchito",
    city_code: "OAN",
    airport_name: "Olanchito",
    country: "Honduras",
  },
  {
    sr: 13104,
    city: "Olavarria",
    city_code: "OVR",
    airport_name: "Olavarria",
    country: "Argentina",
  },
  {
    sr: 13106,
    city: "Olbia",
    city_code: "OLB",
    airport_name: "Costa Smeralda",
    country: "Italy",
  },
  {
    sr: 13108,
    city: "Old Crow",
    city_code: "YOC",
    airport_name: "Old Crow",
    country: "Canada",
  },
  {
    sr: 13110,
    city: "Old Fort Bay",
    city_code: "ZFB",
    airport_name: "Old Fort Bay",
    country: "Canada",
  },
  {
    sr: 13112,
    city: "Old Harbor",
    city_code: "OLH",
    airport_name: "Old Harbor SPB",
    country: "United States",
  },
  {
    sr: 13114,
    city: "Old Town",
    city_code: "OLD",
    airport_name: "Old Town",
    country: "United States",
  },
  {
    sr: 13116,
    city: "Olean",
    city_code: "OLE",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 13118,
    city: "Olga Bay",
    city_code: "KOY",
    airport_name: "Olga Bay SPB",
    country: "United States",
  },
  {
    sr: 13120,
    city: "Olive Branch",
    city_code: "OLV",
    airport_name: "Olive Branch Arpt",
    country: "United States",
  },
  {
    sr: 13124,
    city: "Olney",
    city_code: "OLY",
    airport_name: "Olney Noble",
    country: "United States",
  },
  {
    sr: 13126,
    city: "Olney",
    city_code: "ONY",
    airport_name: "Olney",
    country: "United States",
  },
  {
    sr: 13128,
    city: "Olomouc",
    city_code: "OLO",
    airport_name: "Olomouc",
    country: "Czechoslovakia  former",
  },
  {
    sr: 13130,
    city: "Olpoi",
    city_code: "OLJ",
    airport_name: "Olpoi",
    country: "Vanuatu",
  },
  {
    sr: 13132,
    city: "Olsobip",
    city_code: "OLQ",
    airport_name: "Olsobip",
    country: "Papua New Guinea",
  },
  {
    sr: 13134,
    city: "Olympia",
    city_code: "OLM",
    airport_name: "Olympia",
    country: "United States",
  },
  {
    sr: 13136,
    city: "Olympic Dam",
    city_code: "OLP",
    airport_name: "Olympic Dam",
    country: "Australia",
  },
  {
    sr: 13138,
    city: "Omaha",
    city_code: "OMA",
    airport_name: "Eppley Airfield",
    country: "United States",
  },
  {
    sr: 13140,
    city: "Omaha",
    city_code: "MIQ",
    airport_name: "Maiquetia",
    country: "Venezuela",
  },
  {
    sr: 13142,
    city: "Omak",
    city_code: "OMK",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 13144,
    city: "Omboue",
    city_code: "OMB",
    airport_name: "Omboue",
    country: "Gabon",
  },
  {
    sr: 13146,
    city: "Omega",
    city_code: "OMG",
    airport_name: "Omega",
    country: "Namibia",
  },
  {
    sr: 13148,
    city: "Omidieh",
    city_code: "OMI",
    airport_name: "Omidieh",
    country: "Iran",
  },
  {
    sr: 13150,
    city: "Omkalai",
    city_code: "OML",
    airport_name: "Omkalai",
    country: "Papua New Guinea",
  },
  {
    sr: 13152,
    city: "Omora",
    city_code: "OSE",
    airport_name: "Omora",
    country: "Papua New Guinea",
  },
  {
    sr: 13154,
    city: "Omsk",
    city_code: "OMS",
    airport_name: "Omsk",
    country: "Russia",
  },
  {
    sr: 13158,
    city: "Omura",
    city_code: "OMJ",
    airport_name: "Omura",
    country: "Japan",
  },
  {
    sr: 13160,
    city: "Ondangwa",
    city_code: "OND",
    airport_name: "Ondangwa",
    country: "Namibia",
  },
  {
    sr: 13162,
    city: "Oneill",
    city_code: "ONL",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 13164,
    city: "Oneonta",
    city_code: "ONH",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 13166,
    city: "Onepusu",
    city_code: "ONE",
    airport_name: "Onepusu",
    country: "Solomon Islands",
  },
  {
    sr: 13168,
    city: "Ongava Game Reserve",
    city_code: "OGV",
    airport_name: "Ongava Game Reserve",
    country: "Namibia",
  },
  {
    sr: 13170,
    city: "Ongiva",
    city_code: "VPE",
    airport_name: "Ongiva",
    country: "Angola",
  },
  {
    sr: 13172,
    city: "Onion Bay",
    city_code: "ONN",
    airport_name: "Onion Bay",
    country: "United States",
  },
  {
    sr: 13174,
    city: "Ono I Lau",
    city_code: "ONU",
    airport_name: "Ono I Lau",
    country: "Fiji",
  },
  {
    sr: 13176,
    city: "Ononge",
    city_code: "ONB",
    airport_name: "Ononge",
    country: "Papua New Guinea",
  },
  {
    sr: 13178,
    city: "Onotoa",
    city_code: "OOT",
    airport_name: "Onotoa",
    country: "Kiribati",
  },
  {
    sr: 13180,
    city: "Onslow",
    city_code: "ONS",
    airport_name: "Onslow",
    country: "Australia",
  },
  {
    sr: 13182,
    city: "Ontario",
    city_code: "ONT",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 13184,
    city: "Ontario",
    city_code: "JIO",
    airport_name: "Intl Heliport",
    country: "United States",
  },
  {
    sr: 13186,
    city: "Ontario",
    city_code: "ONO",
    airport_name: "Ontario",
    country: "United States",
  },
  {
    sr: 13188,
    city: "Ontong Java",
    city_code: "OTV",
    airport_name: "Ontong Java",
    country: "Solomon Islands",
  },
  {
    sr: 13192,
    city: "Oodnadatta",
    city_code: "ODD",
    airport_name: "Oodnadatta",
    country: "Australia",
  },
  {
    sr: 13194,
    city: "Opapamiska Lake",
    city_code: "YBS",
    airport_name: "Musselwhite",
    country: "Canada",
  },
  {
    sr: 13196,
    city: "Opelousas",
    city_code: "OPL",
    airport_name: "St Landry Parish",
    country: "United States",
  },
  {
    sr: 13198,
    city: "Open Bay",
    city_code: "OPB",
    airport_name: "Open Bay",
    country: "Papua New Guinea",
  },
  {
    sr: 13200,
    city: "Opuwa",
    city_code: "OPW",
    airport_name: "Opuwa",
    country: "Namibia",
  },
  {
    sr: 13202,
    city: "Oqatsut",
    city_code: "XEO",
    airport_name: "Harbour",
    country: "Greenland",
  },
  {
    sr: 13204,
    city: "Oradea",
    city_code: "OMR",
    airport_name: "Oradea",
    country: "Romania",
  },
  {
    sr: 13206,
    city: "Oram",
    city_code: "RAX",
    airport_name: "Oram",
    country: "Papua New Guinea",
  },
  {
    sr: 13208,
    city: "Oran",
    city_code: "ORN",
    airport_name: "Es Senia",
    country: "Algeria",
  },
  {
    sr: 13210,
    city: "Oran",
    city_code: "TAF",
    airport_name: "Tafaraoui",
    country: "Algeria",
  },
  {
    sr: 13212,
    city: "Oran",
    city_code: "ORA",
    airport_name: "Oran",
    country: "Argentina",
  },
  {
    sr: 13214,
    city: "Orange",
    city_code: "JOR",
    airport_name: "The City Heliport",
    country: "United States",
  },
  {
    sr: 13216,
    city: "Orange",
    city_code: "OAG",
    airport_name: "Springhill",
    country: "Australia",
  },
  {
    sr: 13218,
    city: "Orange Walk",
    city_code: "ORZ",
    airport_name: "Orange Walk",
    country: "Belize",
  },
  {
    sr: 13220,
    city: "Orangeburg",
    city_code: "OGB",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 13222,
    city: "Oranjemund",
    city_code: "OMD",
    airport_name: "Oranjemund",
    country: "Namibia",
  },
  {
    sr: 13226,
    city: "Orapa",
    city_code: "ORP",
    airport_name: "Orapa",
    country: "Botswana",
  },
  {
    sr: 13228,
    city: "Orbost",
    city_code: "RBS",
    airport_name: "Orbost",
    country: "Australia",
  },
  {
    sr: 13230,
    city: "Orchid Beach",
    city_code: "OKB",
    airport_name: "Fraser Island",
    country: "Australia",
  },
  {
    sr: 13232,
    city: "Orchid Island",
    city_code: "KYD",
    airport_name: "Orchid Island",
    country: "Taiwan",
  },
  {
    sr: 13234,
    city: "Ord River",
    city_code: "ODR",
    airport_name: "Ord River",
    country: "Australia",
  },
  {
    sr: 13236,
    city: "Orebro",
    city_code: "ORB",
    airport_name: "Orebro bofors",
    country: "Sweden",
  },
  {
    sr: 13238,
    city: "Orel",
    city_code: "OEL",
    airport_name: "Orel",
    country: "Russia",
  },
  {
    sr: 13240,
    city: "Orenburg",
    city_code: "REN",
    airport_name: "Orenburg",
    country: "Russia",
  },
  {
    sr: 13242,
    city: "Oria",
    city_code: "OTY",
    airport_name: "Oria",
    country: "Papua New Guinea",
  },
  {
    sr: 13244,
    city: "Orientos",
    city_code: "OXO",
    airport_name: "Orientos",
    country: "Australia",
  },
  {
    sr: 13246,
    city: "Orinduik",
    city_code: "ORJ",
    airport_name: "Orinduik",
    country: "Guyana",
  },
  {
    sr: 13248,
    city: "Oriximina",
    city_code: "ORX",
    airport_name: "Oriximina",
    country: "Brazil",
  },
  {
    sr: 13250,
    city: "Orkney Island",
    city_code: "KOI",
    airport_name: "Orkney Island",
    country: "United Kingdom",
  },
  {
    sr: 13252,
    city: "Orland",
    city_code: "OLA",
    airport_name: "Orland",
    country: "Norway",
  },
  {
    sr: 13254,
    city: "Orlando",
    city_code: "ORL",
    airport_name: "Orlando Executive",
    country: "United States",
  },
  {
    sr: 13256,
    city: "Orlando",
    city_code: "MCO",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 13260,
    city: "Orlando",
    city_code: "SFB",
    airport_name: "Orlando Sanford Intl",
    country: "United States",
  },
  {
    sr: 13262,
    city: "Orlando",
    city_code: "DWS",
    airport_name: "Walt Disney World",
    country: "United States",
  },
  {
    sr: 13264,
    city: "Orleans",
    city_code: "ORE",
    airport_name: "Orleans",
    country: "France",
  },
  {
    sr: 13266,
    city: "Ormara",
    city_code: "ORW",
    airport_name: "Ormara",
    country: "Pakistan",
  },
  {
    sr: 13268,
    city: "Ormoc",
    city_code: "OMC",
    airport_name: "Ormoc",
    country: "Philippines",
  },
  {
    sr: 13270,
    city: "Ornskoldsvik",
    city_code: "OER",
    airport_name: "Ornskoldsvik",
    country: "Sweden",
  },
  {
    sr: 13272,
    city: "Orocue",
    city_code: "ORC",
    airport_name: "Orocue",
    country: "Colombia",
  },
  {
    sr: 13274,
    city: "Oroville",
    city_code: "OVE",
    airport_name: "Oroville",
    country: "United States",
  },
  {
    sr: 13276,
    city: "Orpheus Island Resort",
    city_code: "ORS",
    airport_name: "Waterport",
    country: "Australia",
  },
  {
    sr: 13278,
    city: "Orsk",
    city_code: "OSW",
    airport_name: "Orsk",
    country: "Russia",
  },
  {
    sr: 13280,
    city: "Orsta Volda",
    city_code: "HOV",
    airport_name: "Hovden",
    country: "Norway",
  },
  {
    sr: 13282,
    city: "Oruro",
    city_code: "ORU",
    airport_name: "Oruro",
    country: "Bolivia",
  },
  {
    sr: 13284,
    city: "Osage Beach",
    city_code: "OSB",
    airport_name: "Osage Beach",
    country: "United States",
  },
  {
    sr: 13286,
    city: "Osaka",
    city_code: "ITM",
    airport_name: "Itami",
    country: "Japan",
  },
  {
    sr: 13288,
    city: "Osaka",
    city_code: "KIX",
    airport_name: "Kansai International",
    country: "Japan",
  },
  {
    sr: 13290,
    city: "Osan",
    city_code: "OSN",
    airport_name: "Ab",
    country: "South Korea",
  },
  {
    sr: 13294,
    city: "Osceola",
    city_code: "OEO",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 13296,
    city: "Oscoda",
    city_code: "OSC",
    airport_name: "Wurtsmith AFB",
    country: "United States",
  },
  {
    sr: 13298,
    city: "Osh",
    city_code: "OSS",
    airport_name: "Osh",
    country: "Kyrgyzstan",
  },
  {
    sr: 13300,
    city: "Oshakati",
    city_code: "OHI",
    airport_name: "Oshakati",
    country: "Namibia",
  },
  {
    sr: 13302,
    city: "Oshawa",
    city_code: "YOO",
    airport_name: "Oshawa",
    country: "Canada",
  },
  {
    sr: 13304,
    city: "Oshima",
    city_code: "OIM",
    airport_name: "Oshima",
    country: "Japan",
  },
  {
    sr: 13306,
    city: "Oshkosh",
    city_code: "OKS",
    airport_name: "Oshkosh",
    country: "United States",
  },
  {
    sr: 13308,
    city: "Oshkosh",
    city_code: "OSH",
    airport_name: "Wittman Field",
    country: "United States",
  },
  {
    sr: 13310,
    city: "Osijek",
    city_code: "OSI",
    airport_name: "Osijek",
    country: "Croatia  Hrvatska",
  },
  {
    sr: 13312,
    city: "Oskaloosa",
    city_code: "OOA",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 13314,
    city: "Oskarshamn",
    city_code: "OSK",
    airport_name: "Oskarshamn",
    country: "Sweden",
  },
  {
    sr: 13316,
    city: "Oslo",
    city_code: "OSL",
    airport_name: "Oslo Airport  Gardermoen",
    country: "Norway",
  },
  {
    sr: 13318,
    city: "Oslo",
    city_code: "XZO",
    airport_name: "Oslo Central Station",
    country: "Norway",
  },
  {
    sr: 13320,
    city: "Oslo",
    city_code: "TRF",
    airport_name: "Sandefjord",
    country: "Norway",
  },
  {
    sr: 13322,
    city: "Osmanabad",
    city_code: "OMN",
    airport_name: "Osmanabad",
    country: "India",
  },
  {
    sr: 13324,
    city: "Osorno",
    city_code: "ZOS",
    airport_name: "Canal Balo",
    country: "Chile",
  },
  {
    sr: 13328,
    city: "Ossima",
    city_code: "OSG",
    airport_name: "Ossima",
    country: "Papua New Guinea",
  },
  {
    sr: 13330,
    city: "Ostend",
    city_code: "OST",
    airport_name: "Railway",
    country: "Belgium",
  },
  {
    sr: 13332,
    city: "Ostersund",
    city_code: "OSD",
    airport_name: "Froesoe",
    country: "Sweden",
  },
  {
    sr: 13334,
    city: "Ostrava",
    city_code: "OSR",
    airport_name: "Mosnov",
    country: "Czech Republic  former Czechoslovakia",
  },
  {
    sr: 13336,
    city: "Otjiwarongo",
    city_code: "OTJ",
    airport_name: "Otjiwarongo",
    country: "Namibia",
  },
  {
    sr: 13338,
    city: "Ottawa",
    city_code: "ZIF",
    airport_name: "Fallowfield Railway",
    country: "Canada",
  },
  {
    sr: 13340,
    city: "Ottawa",
    city_code: "YND",
    airport_name: "Executive Gatineau Ottawa",
    country: "Canada",
  },
  {
    sr: 13342,
    city: "Ottawa",
    city_code: "YOW",
    airport_name: "Ottawa International",
    country: "Canada",
  },
  {
    sr: 13344,
    city: "Ottawa",
    city_code: "YRO",
    airport_name: "Rockcliffe St",
    country: "Canada",
  },
  {
    sr: 13346,
    city: "Otto",
    city_code: "OTO",
    airport_name: "Vor",
    country: "United States",
  },
  {
    sr: 13348,
    city: "Ottumwa",
    city_code: "OTM",
    airport_name: "Industrial",
    country: "United States",
  },
  {
    sr: 13350,
    city: "Otu",
    city_code: "OTU",
    airport_name: "Otu",
    country: "Colombia",
  },
  {
    sr: 13352,
    city: "Ouadda",
    city_code: "ODA",
    airport_name: "Ouadda",
    country: "Central African Republic",
  },
  {
    sr: 13354,
    city: "Ouagadougou",
    city_code: "OUA",
    airport_name: "Ouagadougou",
    country: "Burkina Faso",
  },
  {
    sr: 13356,
    city: "Ouahigouya",
    city_code: "OUG",
    airport_name: "Ouahigouya",
    country: "Burkina Faso",
  },
  {
    sr: 13358,
    city: "Ouanda Djalle",
    city_code: "ODJ",
    airport_name: "Ouanda Djalle",
    country: "Central African Republic",
  },
  {
    sr: 13362,
    city: "Ouanga",
    city_code: "OUU",
    airport_name: "Ouanga",
    country: "Gabon",
  },
  {
    sr: 13364,
    city: "Ouango Fitini",
    city_code: "OFI",
    airport_name: "Ouango Fitini",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 13366,
    city: "Ouargla",
    city_code: "OGX",
    airport_name: "Ain Beida",
    country: "Algeria",
  },
  {
    sr: 13368,
    city: "Ouarzazate",
    city_code: "OZZ",
    airport_name: "Ouarzazate",
    country: "Morocco",
  },
  {
    sr: 13370,
    city: "Oudomxay",
    city_code: "ODY",
    airport_name: "Oudomxay",
    country: "Laos",
  },
  {
    sr: 13372,
    city: "Oudtshoorn",
    city_code: "OUH",
    airport_name: "Oudtshoorn",
    country: "South Africa",
  },
  {
    sr: 13374,
    city: "Ouesso",
    city_code: "OUE",
    airport_name: "Ouesso",
    country: "Congo",
  },
  {
    sr: 13376,
    city: "Oujda",
    city_code: "OUD",
    airport_name: "Les Angades",
    country: "Morocco",
  },
  {
    sr: 13378,
    city: "Oulu",
    city_code: "OUL",
    airport_name: "Oulu",
    country: "Finland",
  },
  {
    sr: 13380,
    city: "Oum Hadjer",
    city_code: "OUM",
    airport_name: "Oum Hadjer",
    country: "Chad",
  },
  {
    sr: 13382,
    city: "Ourilandia",
    city_code: "OIA",
    airport_name: "Ourilandia",
    country: "Brazil",
  },
  {
    sr: 13384,
    city: "Ourinhos",
    city_code: "OUS",
    airport_name: "Ourinhos",
    country: "Brazil",
  },
  {
    sr: 13386,
    city: "Outer Skerries",
    city_code: "OUK",
    airport_name: "Outer Skerries",
    country: "United Kingdom",
  },
  {
    sr: 13388,
    city: "Ouvea",
    city_code: "UVE",
    airport_name: "Ouvea",
    country: "New Caledonia",
  },
  {
    sr: 13390,
    city: "Ouyen",
    city_code: "OYN",
    airport_name: "Ouyen",
    country: "Australia",
  },
  {
    sr: 13392,
    city: "Ouzinkie",
    city_code: "KOZ",
    airport_name: "Ouzinkie SPB",
    country: "United States",
  },
  {
    sr: 13396,
    city: "Ovalle",
    city_code: "OVL",
    airport_name: "Ovalle",
    country: "Chile",
  },
  {
    sr: 13398,
    city: "Ovda",
    city_code: "VDA",
    airport_name: "Ovda",
    country: "Israel",
  },
  {
    sr: 13400,
    city: "Overberg",
    city_code: "OVG",
    airport_name: "Overberg  FAOB",
    country: "South Africa",
  },
  {
    sr: 13402,
    city: "Owando",
    city_code: "FTX",
    airport_name: "Owando",
    country: "Congo",
  },
  {
    sr: 13404,
    city: "Owatonna",
    city_code: "OWA",
    airport_name: "Owatonna",
    country: "United States",
  },
  {
    sr: 13406,
    city: "Owen Sound",
    city_code: "YOS",
    airport_name: "Billy Bishop Regional",
    country: "Canada",
  },
  {
    sr: 13408,
    city: "Owendo",
    city_code: "OWE",
    airport_name: "Owendo",
    country: "Gabon",
  },
  {
    sr: 13410,
    city: "Owensboro",
    city_code: "OWB",
    airport_name: "Daviess County",
    country: "United States",
  },
  {
    sr: 13412,
    city: "Oxford",
    city_code: "OXC",
    airport_name: "Waterbury Oxford",
    country: "United States",
  },
  {
    sr: 13414,
    city: "Oxford",
    city_code: "OXF",
    airport_name: "Kidlington",
    country: "United Kingdom",
  },
  {
    sr: 13416,
    city: "Oxford",
    city_code: "UOX",
    airport_name: "University Oxford",
    country: "United States",
  },
  {
    sr: 13418,
    city: "Oxford",
    city_code: "OXD",
    airport_name: "Miami University",
    country: "United States",
  },
  {
    sr: 13420,
    city: "Oxford House",
    city_code: "YOH",
    airport_name: "Oxford House",
    country: "Canada",
  },
  {
    sr: 13422,
    city: "Oyem",
    city_code: "OYE",
    airport_name: "Oyem",
    country: "Gabon",
  },
  {
    sr: 13424,
    city: "Ozamis City",
    city_code: "OZC",
    airport_name: "Labo",
    country: "Philippines",
  },
  {
    sr: 13426,
    city: "Ozark",
    city_code: "OZR",
    airport_name: "Cairns AAF",
    country: "United States",
  },
  {
    sr: 13430,
    city: "Ozark",
    city_code: "HEY",
    airport_name: "Hanchey Army Heliport",
    country: "United States",
  },
  {
    sr: 13432,
    city: "Ozark",
    city_code: "LOR",
    airport_name: "Lowe Army Heliport",
    country: "United States",
  },
  {
    sr: 13434,
    city: "Ozona",
    city_code: "OZA",
    airport_name: "Ozona Municipal",
    country: "United States",
  },
  {
    sr: 13436,
    city: "Paama",
    city_code: "PBJ",
    airport_name: "Paama",
    country: "Vanuatu",
  },
  {
    sr: 13438,
    city: "Paamiut",
    city_code: "JFR",
    airport_name: "Paamiut",
    country: "Greenland",
  },
  {
    sr: 13440,
    city: "Pa an",
    city_code: "PAA",
    airport_name: "Pa an",
    country: "Myanmar",
  },
  {
    sr: 13442,
    city: "Pacific City",
    city_code: "PFC",
    airport_name: "State",
    country: "United States",
  },
  {
    sr: 13444,
    city: "Pacific Harbor",
    city_code: "PHR",
    airport_name: "Pacific Harbor",
    country: "Fiji",
  },
  {
    sr: 13446,
    city: "Pack Creek",
    city_code: "PBK",
    airport_name: "Pack Creek",
    country: "United States",
  },
  {
    sr: 13448,
    city: "Padang",
    city_code: "PDG",
    airport_name: "Minangkabau International Airport",
    country: "Indonesia",
  },
  {
    sr: 13450,
    city: "Paderborn",
    city_code: "PAD",
    airport_name: "Paderborn",
    country: "Germany",
  },
  {
    sr: 13452,
    city: "Paducah",
    city_code: "PAH",
    airport_name: "Barkley Regional",
    country: "United States",
  },
  {
    sr: 13454,
    city: "Paf Warren",
    city_code: "PFA",
    airport_name: "Paf Warren",
    country: "United States",
  },
  {
    sr: 13456,
    city: "Pagadian",
    city_code: "PAG",
    airport_name: "Pagadian",
    country: "Philippines",
  },
  {
    sr: 13458,
    city: "Page",
    city_code: "PGA",
    airport_name: "Page",
    country: "United States",
  },
  {
    sr: 13460,
    city: "Pago Pago",
    city_code: "PPG",
    airport_name: "International",
    country: "American Samoa",
  },
  {
    sr: 13464,
    city: "Pagosa Springs",
    city_code: "PGO",
    airport_name: "Stevens Field",
    country: "United States",
  },
  {
    sr: 13466,
    city: "Pahokee",
    city_code: "PHK",
    airport_name: "Palm Beach Co Glades",
    country: "United States",
  },
  {
    sr: 13468,
    city: "Paiela",
    city_code: "PLE",
    airport_name: "Paiela",
    country: "Papua New Guinea",
  },
  {
    sr: 13470,
    city: "Pailin",
    city_code: "PAI",
    airport_name: "Pailin",
    country: "Cambodia",
  },
  {
    sr: 13472,
    city: "Paimiut",
    city_code: "PMU",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 13474,
    city: "Painesville",
    city_code: "PVZ",
    airport_name: "Casement",
    country: "United States",
  },
  {
    sr: 13476,
    city: "Painter Creek",
    city_code: "PCE",
    airport_name: "Painter Creek",
    country: "United States",
  },
  {
    sr: 13478,
    city: "Pajala",
    city_code: "PJA",
    airport_name: "Pajala",
    country: "Sweden",
  },
  {
    sr: 13480,
    city: "Pakatoa Island",
    city_code: "PKL",
    airport_name: "Pakatoa Island",
    country: "New Zealand",
  },
  {
    sr: 13482,
    city: "Pakokku",
    city_code: "PKK",
    airport_name: "Pakokku",
    country: "Myanmar",
  },
  {
    sr: 13484,
    city: "Paksane",
    city_code: "PKS",
    airport_name: "Paksane",
    country: "Laos",
  },
  {
    sr: 13486,
    city: "Pakse",
    city_code: "PKZ",
    airport_name: "Pakse",
    country: "Laos",
  },
  {
    sr: 13488,
    city: "Pakuashipi",
    city_code: "YIF",
    airport_name: "Pakuashipi",
    country: "Canada",
  },
  {
    sr: 13490,
    city: "Pakuba",
    city_code: "PAF",
    airport_name: "Pakuba",
    country: "Uganda",
  },
  {
    sr: 13492,
    city: "Pala",
    city_code: "PLF",
    airport_name: "Pala",
    country: "Chad",
  },
  {
    sr: 13494,
    city: "Palacios",
    city_code: "PCH",
    airport_name: "Palacios",
    country: "Honduras",
  },
  {
    sr: 13498,
    city: "Palacios",
    city_code: "PSX",
    airport_name: "Palacios",
    country: "United States",
  },
  {
    sr: 13500,
    city: "Palangkaraya",
    city_code: "PKY",
    airport_name: "Palangkaraya",
    country: "Indonesia",
  },
  {
    sr: 13502,
    city: "Palanquero",
    city_code: "PAL",
    airport_name: "Palanquero",
    country: "Colombia",
  },
  {
    sr: 13504,
    city: "Palembang",
    city_code: "PLM",
    airport_name: "Mahmud Badaruddin Ii",
    country: "Indonesia",
  },
  {
    sr: 13506,
    city: "Palenque",
    city_code: "PQM",
    airport_name: "Palenque",
    country: "Mexico",
  },
  {
    sr: 13508,
    city: "Palermo",
    city_code: "PMO",
    airport_name: "Punta Raisi",
    country: "Italy",
  },
  {
    sr: 13510,
    city: "Palestine",
    city_code: "PSN",
    airport_name: "Palestine",
    country: "United States",
  },
  {
    sr: 13512,
    city: "Palibelo",
    city_code: "PBW",
    airport_name: "Palibelo",
    country: "Indonesia",
  },
  {
    sr: 13514,
    city: "Palm Island",
    city_code: "PMK",
    airport_name: "Palm Island",
    country: "Australia",
  },
  {
    sr: 13516,
    city: "Palm Island",
    city_code: "PLI",
    airport_name: "Palm Island",
    country: "Saint Vincent and the Grenadines",
  },
  {
    sr: 13518,
    city: "Palm Springs",
    city_code: "UDD",
    airport_name: "Bermuda Dunes",
    country: "United States",
  },
  {
    sr: 13520,
    city: "Palm Springs",
    city_code: "PSP",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 13522,
    city: "Palma",
    city_code: "LMZ",
    airport_name: "Palma",
    country: "Mozambique",
  },
  {
    sr: 13524,
    city: "Palma Mallorca",
    city_code: "PMI",
    airport_name: "Palma Mallorca",
    country: "Spain",
  },
  {
    sr: 13526,
    city: "Palmar",
    city_code: "PMZ",
    airport_name: "Palmar Sur",
    country: "Costa Rica",
  },
  {
    sr: 13528,
    city: "Palmarito",
    city_code: "PTM",
    airport_name: "Palmarito",
    country: "Venezuela",
  },
  {
    sr: 13532,
    city: "Palmas",
    city_code: "PMW",
    airport_name: "Palmas",
    country: "Brazil",
  },
  {
    sr: 13534,
    city: "Palmdale",
    city_code: "PMD",
    airport_name: "Air Force 42",
    country: "United States",
  },
  {
    sr: 13536,
    city: "Palmer",
    city_code: "PAQ",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 13538,
    city: "Palmer",
    city_code: "PMX",
    airport_name: "Metropolitan",
    country: "United States",
  },
  {
    sr: 13540,
    city: "Palmerston North",
    city_code: "PMR",
    airport_name: "Palmerston North",
    country: "New Zealand",
  },
  {
    sr: 13542,
    city: "Palmyra",
    city_code: "PMS",
    airport_name: "Palmyra",
    country: "Syria",
  },
  {
    sr: 13544,
    city: "Palo Alto",
    city_code: "PAO",
    airport_name: "Palo Alto",
    country: "United States",
  },
  {
    sr: 13546,
    city: "Paloemeu",
    city_code: "OEM",
    airport_name: "Vincent Fayks",
    country: "Suriname",
  },
  {
    sr: 13548,
    city: "Palu",
    city_code: "PLW",
    airport_name: "Mutiara",
    country: "Indonesia",
  },
  {
    sr: 13550,
    city: "Pama",
    city_code: "XPA",
    airport_name: "Pama",
    country: "Burkina Faso",
  },
  {
    sr: 13552,
    city: "Pambwa",
    city_code: "PAW",
    airport_name: "Pambwa",
    country: "Papua New Guinea",
  },
  {
    sr: 13554,
    city: "Pamol",
    city_code: "PAY",
    airport_name: "Pamol",
    country: "Malaysia",
  },
  {
    sr: 13556,
    city: "Pampa",
    city_code: "PPA",
    airport_name: "Perry Lefors Field",
    country: "United States",
  },
  {
    sr: 13558,
    city: "Pamplona",
    city_code: "PNA",
    airport_name: "Pamplona",
    country: "Spain",
  },
  {
    sr: 13560,
    city: "Pan Yu",
    city_code: "ZAX",
    airport_name: "Pan Yu Ferry",
    country: "China",
  },
  {
    sr: 13562,
    city: "Pan Zhi Hua",
    city_code: "PZI",
    airport_name: "Pan Zhi Hua Bao AnYing",
    country: "China",
  },
  {
    sr: 13566,
    city: "Panama City",
    city_code: "PFN",
    airport_name: "Bay County",
    country: "United States",
  },
  {
    sr: 13568,
    city: "Panama City",
    city_code: "PAM",
    airport_name: "Tyndall AFB",
    country: "United States",
  },
  {
    sr: 13570,
    city: "Panama City",
    city_code: "PAC",
    airport_name: "Paitilla",
    country: "Panama",
  },
  {
    sr: 13572,
    city: "Panama City",
    city_code: "PTY",
    airport_name: "Tocumen International",
    country: "Panama",
  },
  {
    sr: 13574,
    city: "Panarea",
    city_code: "ZJE",
    airport_name: "Harbour  Eolie Island",
    country: "Italy",
  },
  {
    sr: 13576,
    city: "Pandie Pandie",
    city_code: "PDE",
    airport_name: "Pandie Pandie",
    country: "Australia",
  },
  {
    sr: 13578,
    city: "Panevezys",
    city_code: "PNV",
    airport_name: "Panevezys",
    country: "Lithuania",
  },
  {
    sr: 13580,
    city: "Pangia",
    city_code: "PGN",
    airport_name: "Pangia",
    country: "Papua New Guinea",
  },
  {
    sr: 13582,
    city: "Pangkalanbuun",
    city_code: "PKN",
    airport_name: "Pangkalanbuun",
    country: "Indonesia",
  },
  {
    sr: 13584,
    city: "Pangkalpinang",
    city_code: "PGK",
    airport_name: "Pangkalpinang",
    country: "Indonesia",
  },
  {
    sr: 13586,
    city: "Pangkor",
    city_code: "PKG",
    airport_name: "Pangkor Airport",
    country: "Malaysia",
  },
  {
    sr: 13588,
    city: "Pangnirtung",
    city_code: "YXP",
    airport_name: "Pangnirtung",
    country: "Canada",
  },
  {
    sr: 13590,
    city: "Pangoa",
    city_code: "PGB",
    airport_name: "Pangoa",
    country: "Papua New Guinea",
  },
  {
    sr: 13592,
    city: "Panguitch",
    city_code: "PNU",
    airport_name: "Panguitch",
    country: "United States",
  },
  {
    sr: 13594,
    city: "Panjgur",
    city_code: "PJG",
    airport_name: "Panjgur",
    country: "Pakistan",
  },
  {
    sr: 13596,
    city: "Pantelleria",
    city_code: "PNL",
    airport_name: "Pantelleria",
    country: "Italy",
  },
  {
    sr: 13600,
    city: "Pantnagar",
    city_code: "PGH",
    airport_name: "Pantnagar",
    country: "India",
  },
  {
    sr: 13602,
    city: "Paonia",
    city_code: "WPO",
    airport_name: "North Fork Valley",
    country: "United States",
  },
  {
    sr: 13604,
    city: "Papa Stour",
    city_code: "PSV",
    airport_name: "Papa Stour",
    country: "United Kingdom",
  },
  {
    sr: 13606,
    city: "Papa Westray",
    city_code: "PPW",
    airport_name: "Papa Westray",
    country: "United Kingdom",
  },
  {
    sr: 13608,
    city: "Papeete",
    city_code: "PPT",
    airport_name: "Faaa",
    country: "French Polynesia",
  },
  {
    sr: 13610,
    city: "Paphos",
    city_code: "PFO",
    airport_name: "International",
    country: "Cyprus",
  },
  {
    sr: 13612,
    city: "Papun",
    city_code: "PPU",
    airport_name: "Papun",
    country: "Myanmar",
  },
  {
    sr: 13614,
    city: "Para Chinar",
    city_code: "PAJ",
    airport_name: "Para Chinar",
    country: "Pakistan",
  },
  {
    sr: 13616,
    city: "Paraburdoo",
    city_code: "PBO",
    airport_name: "Paraburdoo",
    country: "Australia",
  },
  {
    sr: 13618,
    city: "Paradise River",
    city_code: "YDE",
    airport_name: "Paradise River",
    country: "Canada",
  },
  {
    sr: 13620,
    city: "Paragould",
    city_code: "PGR",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 13622,
    city: "Parakou",
    city_code: "PKO",
    airport_name: "Parakou",
    country: "Benin",
  },
  {
    sr: 13624,
    city: "Param",
    city_code: "PPX",
    airport_name: "Param",
    country: "Papua New Guinea",
  },
  {
    sr: 13626,
    city: "Paramakotoi",
    city_code: "PMT",
    airport_name: "Paramakotoi",
    country: "Guyana",
  },
  {
    sr: 13628,
    city: "Paramaribo",
    city_code: "PBM",
    airport_name: "Zanderij Intl",
    country: "Suriname",
  },
  {
    sr: 13630,
    city: "Paramaribo",
    city_code: "ORG",
    airport_name: "Zorg En Hoop",
    country: "Suriname",
  },
  {
    sr: 13634,
    city: "Parana",
    city_code: "PRA",
    airport_name: "Parana",
    country: "Argentina",
  },
  {
    sr: 13636,
    city: "Paranagua",
    city_code: "PNG",
    airport_name: "Municipal",
    country: "Brazil",
  },
  {
    sr: 13638,
    city: "Paranaiba",
    city_code: "PBB",
    airport_name: "Paranaiba",
    country: "Brazil",
  },
  {
    sr: 13640,
    city: "Paranavai",
    city_code: "PVI",
    airport_name: "Paranavai",
    country: "Brazil",
  },
  {
    sr: 13642,
    city: "Paraparaumu",
    city_code: "PPQ",
    airport_name: "Paraparaumu",
    country: "New Zealand",
  },
  {
    sr: 13644,
    city: "Parasi",
    city_code: "PRS",
    airport_name: "Parasi",
    country: "Solomon Islands",
  },
  {
    sr: 13646,
    city: "Paratebueno",
    city_code: "EUO",
    airport_name: "Paratebueno",
    country: "Colombia",
  },
  {
    sr: 13648,
    city: "Pardoo",
    city_code: "PRD",
    airport_name: "Pardoo",
    country: "Australia",
  },
  {
    sr: 13650,
    city: "Pardubice",
    city_code: "PED",
    airport_name: "Pardubice",
    country: "Czech Republic",
  },
  {
    sr: 13652,
    city: "Parintins",
    city_code: "PIN",
    airport_name: "Parintins",
    country: "Brazil",
  },
  {
    sr: 13654,
    city: "Paris",
    city_code: "XEX",
    airport_name: "Aerogare des Invalides",
    country: "France",
  },
  {
    sr: 13656,
    city: "Paris",
    city_code: "BVA",
    airport_name: "Beauvais Tille",
    country: "France",
  },
  {
    sr: 13658,
    city: "Paris",
    city_code: "XDT",
    airport_name: "C de G TGV Rail Svc",
    country: "France",
  },
  {
    sr: 13660,
    city: "Paris",
    city_code: "CDG",
    airport_name: "Charles De Gaulle",
    country: "France",
  },
  {
    sr: 13662,
    city: "Paris",
    city_code: "XTT",
    airport_name: "Etoile",
    country: "France",
  },
  {
    sr: 13664,
    city: "Paris",
    city_code: "XPG",
    airport_name: "Gare du Nord Rail Stn",
    country: "France",
  },
  {
    sr: 13668,
    city: "Paris",
    city_code: "XGB",
    airport_name: "Gare Montparnasse",
    country: "France",
  },
  {
    sr: 13670,
    city: "Paris",
    city_code: "JDP",
    airport_name: "Heliport De Paris",
    country: "France",
  },
  {
    sr: 13672,
    city: "Paris",
    city_code: "JPU",
    airport_name: "La Defense Heliport",
    country: "France",
  },
  {
    sr: 13674,
    city: "Paris",
    city_code: "LBG",
    airport_name: "Le Bourget",
    country: "France",
  },
  {
    sr: 13676,
    city: "Paris",
    city_code: "ORY",
    airport_name: "Orly",
    country: "France",
  },
  {
    sr: 13678,
    city: "Paris",
    city_code: "POX",
    airport_name: "Paris Cergy Pontoise",
    country: "France",
  },
  {
    sr: 13680,
    city: "Paris",
    city_code: "VIY",
    airport_name: "Villa Coublay",
    country: "France",
  },
  {
    sr: 13682,
    city: "Paris",
    city_code: "PHT",
    airport_name: "Henry County",
    country: "United States",
  },
  {
    sr: 13684,
    city: "Paris",
    city_code: "PRX",
    airport_name: "Cox Field",
    country: "United States",
  },
  {
    sr: 13686,
    city: "Park Falls",
    city_code: "PKF",
    airport_name: "Park Falls",
    country: "United States",
  },
  {
    sr: 13688,
    city: "Park Rapids",
    city_code: "PKD",
    airport_name: "Park Rapids",
    country: "United States",
  },
  {
    sr: 13690,
    city: "Parkersburg",
    city_code: "PKB",
    airport_name: "Wood County",
    country: "United States",
  },
  {
    sr: 13692,
    city: "Parkes",
    city_code: "PKE",
    airport_name: "Parkes",
    country: "Australia",
  },
  {
    sr: 13694,
    city: "Parks",
    city_code: "KPK",
    airport_name: "Parks SPB",
    country: "United States",
  },
  {
    sr: 13696,
    city: "Parnaiba",
    city_code: "PHB",
    airport_name: "Santos Dumont",
    country: "Brazil",
  },
  {
    sr: 13698,
    city: "Parndana",
    city_code: "PDN",
    airport_name: "Parndana",
    country: "Australia",
  },
  {
    sr: 13702,
    city: "Parnu",
    city_code: "EPU",
    airport_name: "Parnu",
    country: "Estonia",
  },
  {
    sr: 13704,
    city: "Paro",
    city_code: "PBH",
    airport_name: "Paro",
    country: "Bhutan",
  },
  {
    sr: 13706,
    city: "Paros",
    city_code: "PAS",
    airport_name: "Paros",
    country: "Greece",
  },
  {
    sr: 13708,
    city: "Parry Sound",
    city_code: "YPD",
    airport_name: "Parry Sound",
    country: "Canada",
  },
  {
    sr: 13710,
    city: "Parsabad",
    city_code: "PFQ",
    airport_name: "Parsabad",
    country: "Iran",
  },
  {
    sr: 13712,
    city: "Parsons",
    city_code: "PPF",
    airport_name: "Tri City",
    country: "United States",
  },
  {
    sr: 13714,
    city: "Paruima",
    city_code: "PRR",
    airport_name: "Paruima",
    country: "Guyana",
  },
  {
    sr: 13716,
    city: "Pasadena",
    city_code: "JPD",
    airport_name: "Heliport",
    country: "United States",
  },
  {
    sr: 13718,
    city: "Pascagoula",
    city_code: "PGL",
    airport_name: "Jackson County",
    country: "United States",
  },
  {
    sr: 13720,
    city: "Pasco",
    city_code: "PSC",
    airport_name: "Tri Cities",
    country: "United States",
  },
  {
    sr: 13722,
    city: "Pasighat",
    city_code: "IXT",
    airport_name: "Pasighat",
    country: "India",
  },
  {
    sr: 13724,
    city: "Pasir Gudang",
    city_code: "ZLW",
    airport_name: "Pasir Gudang Port",
    country: "Malaysia",
  },
  {
    sr: 13726,
    city: "Pasir Pangarayan",
    city_code: "PPR",
    airport_name: "Pasir Pangarayan",
    country: "Indonesia",
  },
  {
    sr: 13728,
    city: "Pasni",
    city_code: "PSI",
    airport_name: "Pasni",
    country: "Pakistan",
  },
  {
    sr: 13730,
    city: "Paso Caballos",
    city_code: "PCG",
    airport_name: "Paso Caballos",
    country: "Guatemala",
  },
  {
    sr: 13732,
    city: "Paso De Los Libres",
    city_code: "AOL",
    airport_name: "Paso De Los Libres",
    country: "Argentina",
  },
  {
    sr: 13736,
    city: "Paso Robles",
    city_code: "PRB",
    airport_name: "Paso Robles",
    country: "United States",
  },
  {
    sr: 13738,
    city: "Passo Fundo",
    city_code: "PFB",
    airport_name: "Passo Fundo",
    country: "Brazil",
  },
  {
    sr: 13740,
    city: "Passos",
    city_code: "PSW",
    airport_name: "Passos",
    country: "Brazil",
  },
  {
    sr: 13742,
    city: "Pastaza",
    city_code: "PTZ",
    airport_name: "Pastaza",
    country: "Ecuador",
  },
  {
    sr: 13744,
    city: "Pasto",
    city_code: "PSO",
    airport_name: "Cano",
    country: "Colombia",
  },
  {
    sr: 13746,
    city: "Pathankot",
    city_code: "IXP",
    airport_name: "Pathankot",
    country: "India",
  },
  {
    sr: 13748,
    city: "Patna",
    city_code: "PAT",
    airport_name: "Patna",
    country: "India",
  },
  {
    sr: 13750,
    city: "Pato Branco",
    city_code: "PTO",
    airport_name: "Municipal",
    country: "Brazil",
  },
  {
    sr: 13752,
    city: "Patong Beach",
    city_code: "PBS",
    airport_name: "Patong Beach",
    country: "Thailand",
  },
  {
    sr: 13754,
    city: "Patos De Minas",
    city_code: "POJ",
    airport_name: "Patos De Minas",
    country: "Brazil",
  },
  {
    sr: 13756,
    city: "Patras",
    city_code: "GPA",
    airport_name: "Araxos Airport",
    country: "Greece",
  },
  {
    sr: 13758,
    city: "Patreksfjordur",
    city_code: "PFJ",
    airport_name: "Patreksfjordur",
    country: "Iceland",
  },
  {
    sr: 13760,
    city: "Pattani",
    city_code: "PAN",
    airport_name: "Pattani",
    country: "Thailand",
  },
  {
    sr: 13762,
    city: "Pattaya",
    city_code: "PYX",
    airport_name: "Pattaya",
    country: "Thailand",
  },
  {
    sr: 13764,
    city: "Patterson",
    city_code: "PTN",
    airport_name: "Williams Memorial",
    country: "United States",
  },
  {
    sr: 13766,
    city: "Patuxent River",
    city_code: "NHK",
    airport_name: "NAS",
    country: "United States",
  },
  {
    sr: 13770,
    city: "Pau",
    city_code: "PUF",
    airport_name: "Uzein",
    country: "France",
  },
  {
    sr: 13772,
    city: "Pauk",
    city_code: "PAU",
    airport_name: "Pauk",
    country: "Myanmar",
  },
  {
    sr: 13774,
    city: "Paulatuk",
    city_code: "YPC",
    airport_name: "Paulatuk",
    country: "Canada",
  },
  {
    sr: 13776,
    city: "Paulo Afonso",
    city_code: "PAV",
    airport_name: "Paulo Afonso",
    country: "Brazil",
  },
  {
    sr: 13778,
    city: "Pauloff Harbor",
    city_code: "KPH",
    airport_name: "Pauloff Harbor SPB",
    country: "United States",
  },
  {
    sr: 13780,
    city: "Pavlodar",
    city_code: "PWQ",
    airport_name: "Pavlodar",
    country: "Kazakhstan",
  },
  {
    sr: 13782,
    city: "Pawi",
    city_code: "PWI",
    airport_name: "Beles",
    country: "Ethiopia",
  },
  {
    sr: 13784,
    city: "Payan",
    city_code: "PYN",
    airport_name: "Payan",
    country: "Colombia",
  },
  {
    sr: 13786,
    city: "Paysandu",
    city_code: "PDU",
    airport_name: "Paysandu",
    country: "Uruguay",
  },
  {
    sr: 13788,
    city: "Payson",
    city_code: "PJB",
    airport_name: "Payson",
    country: "United States",
  },
  {
    sr: 13790,
    city: "Paz De Ariporo",
    city_code: "PZA",
    airport_name: "Casanare",
    country: "Colombia",
  },
  {
    sr: 13792,
    city: "Peace River",
    city_code: "YPE",
    airport_name: "Peace River",
    country: "Canada",
  },
  {
    sr: 13794,
    city: "Peach Springs",
    city_code: "PGS",
    airport_name: "Peach Springs",
    country: "United States",
  },
  {
    sr: 13796,
    city: "Peawanuck",
    city_code: "YPO",
    airport_name: "Peawanuck",
    country: "Canada",
  },
  {
    sr: 13798,
    city: "Pebane",
    city_code: "PEB",
    airport_name: "Pebane",
    country: "Mozambique",
  },
  {
    sr: 13800,
    city: "Pechora",
    city_code: "PEX",
    airport_name: "Pechora",
    country: "Russia",
  },
  {
    sr: 13804,
    city: "Pecos City",
    city_code: "PEQ",
    airport_name: "Pecos City",
    country: "United States",
  },
  {
    sr: 13806,
    city: "Pedernales",
    city_code: "PDZ",
    airport_name: "Pedernales",
    country: "Venezuela",
  },
  {
    sr: 13808,
    city: "Pedro Bay",
    city_code: "PDB",
    airport_name: "Pedro Bay",
    country: "United States",
  },
  {
    sr: 13810,
    city: "Pedro Juan Caballero",
    city_code: "PJC",
    airport_name: "Pedro Juan Caballero",
    country: "Paraguay",
  },
  {
    sr: 13812,
    city: "Peenemuende",
    city_code: "PEF",
    airport_name: "Peenemuende",
    country: "Germany",
  },
  {
    sr: 13814,
    city: "Pehuajo",
    city_code: "PEH",
    airport_name: "Pehuajo",
    country: "Argentina",
  },
  {
    sr: 13816,
    city: "Pekanbaru",
    city_code: "PKU",
    airport_name: "Simpang Tiga",
    country: "Indonesia",
  },
  {
    sr: 13818,
    city: "Pelaneng",
    city_code: "PEL",
    airport_name: "Pelaneng",
    country: "Lesotho",
  },
  {
    sr: 13820,
    city: "Pelican",
    city_code: "PEC",
    airport_name: "Pelican SPB",
    country: "United States",
  },
  {
    sr: 13822,
    city: "Pell City",
    city_code: "PLR",
    airport_name: "St Clair County",
    country: "United States",
  },
  {
    sr: 13824,
    city: "Pellston",
    city_code: "PLN",
    airport_name: "Emmet County",
    country: "United States",
  },
  {
    sr: 13826,
    city: "Pelotas",
    city_code: "PET",
    airport_name: "Federal",
    country: "Brazil",
  },
  {
    sr: 13828,
    city: "Pemba",
    city_code: "POL",
    airport_name: "Pemba",
    country: "Mozambique",
  },
  {
    sr: 13830,
    city: "Pemba",
    city_code: "PMA",
    airport_name: "Wawi",
    country: "Tanzania",
  },
  {
    sr: 13832,
    city: "Pembina",
    city_code: "PMB",
    airport_name: "Intermediate",
    country: "United States",
  },
  {
    sr: 13834,
    city: "Pembroke",
    city_code: "YTA",
    airport_name: "Pembroke And Area Apt",
    country: "Canada",
  },
  {
    sr: 13838,
    city: "Penang",
    city_code: "PEN",
    airport_name: "Penang International",
    country: "Malaysia",
  },
  {
    sr: 13840,
    city: "Penang",
    city_code: "ZJR",
    airport_name: "Penang Port",
    country: "Malaysia",
  },
  {
    sr: 13842,
    city: "Pender Harbor",
    city_code: "YPT",
    airport_name: "Pender Harbor",
    country: "Canada",
  },
  {
    sr: 13844,
    city: "Pendleton",
    city_code: "PDT",
    airport_name: "Pendleton",
    country: "United States",
  },
  {
    sr: 13846,
    city: "Pendopo",
    city_code: "PDO",
    airport_name: "Pendopo",
    country: "Indonesia",
  },
  {
    sr: 13848,
    city: "Peng Lai",
    city_code: "PNJ",
    airport_name: "Peng Lai Sha He Kou",
    country: "China",
  },
  {
    sr: 13850,
    city: "Penneshaw",
    city_code: "PEA",
    airport_name: "Penneshaw",
    country: "Australia",
  },
  {
    sr: 13852,
    city: "Penong",
    city_code: "PEY",
    airport_name: "Penong",
    country: "Australia",
  },
  {
    sr: 13854,
    city: "Penrhyn Island",
    city_code: "PYE",
    airport_name: "Penrhyn Island",
    country: "Cook Islands",
  },
  {
    sr: 13856,
    city: "Pensacola",
    city_code: "NDP",
    airport_name: "Ellyson NAS",
    country: "United States",
  },
  {
    sr: 13858,
    city: "Pensacola",
    city_code: "NPA",
    airport_name: "Pensacola NAS",
    country: "United States",
  },
  {
    sr: 13860,
    city: "Pensacola",
    city_code: "PNS",
    airport_name: "Regional",
    country: "United States",
  },
  {
    sr: 13862,
    city: "Pensacola",
    city_code: "NUN",
    airport_name: "Saufley NAS",
    country: "United States",
  },
  {
    sr: 13864,
    city: "Penticton",
    city_code: "YYF",
    airport_name: "Penticton",
    country: "Canada",
  },
  {
    sr: 13866,
    city: "Penza",
    city_code: "PEZ",
    airport_name: "Penza",
    country: "Russia",
  },
  {
    sr: 13868,
    city: "Penzance",
    city_code: "PZE",
    airport_name: "Penzance",
    country: "United Kingdom",
  },
  {
    sr: 13872,
    city: "Peoria",
    city_code: "PIA",
    airport_name: "Greater Peoria",
    country: "United States",
  },
  {
    sr: 13874,
    city: "Peppers Palm Bay",
    city_code: "PBY",
    airport_name: "Hamilton Proserpine",
    country: "Australia",
  },
  {
    sr: 13876,
    city: "Peppimenarti",
    city_code: "PEP",
    airport_name: "Peppimenarti",
    country: "Australia",
  },
  {
    sr: 13878,
    city: "Peraitepuy",
    city_code: "PPH",
    airport_name: "Peraitepuy",
    country: "Venezuela",
  },
  {
    sr: 13880,
    city: "Pereira",
    city_code: "PEI",
    airport_name: "Matecana",
    country: "Colombia",
  },
  {
    sr: 13882,
    city: "Perigueux",
    city_code: "PGX",
    airport_name: "Perigueux",
    country: "France",
  },
  {
    sr: 13884,
    city: "Perito Moreno",
    city_code: "PMQ",
    airport_name: "Perito Moreno",
    country: "Argentina",
  },
  {
    sr: 13886,
    city: "Perm",
    city_code: "PEE",
    airport_name: "Perm",
    country: "Russia",
  },
  {
    sr: 13888,
    city: "Perpignan",
    city_code: "PGF",
    airport_name: "Llabanere",
    country: "France",
  },
  {
    sr: 13890,
    city: "Perry",
    city_code: "FPY",
    airport_name: "Perry Foley",
    country: "United States",
  },
  {
    sr: 13892,
    city: "Perry",
    city_code: "PRO",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 13894,
    city: "Perry Island",
    city_code: "PYL",
    airport_name: "Perry SPB",
    country: "United States",
  },
  {
    sr: 13896,
    city: "Perryville",
    city_code: "KPV",
    airport_name: "Perryville SPB",
    country: "United States",
  },
  {
    sr: 13898,
    city: "Perth",
    city_code: "PSL",
    airport_name: "Perth",
    country: "United Kingdom",
  },
  {
    sr: 13900,
    city: "Perth",
    city_code: "ZXP",
    airport_name: "ScotRail",
    country: "United Kingdom",
  },
  {
    sr: 13902,
    city: "Perth",
    city_code: "PER",
    airport_name: "Perth Airport",
    country: "Australia",
  },
  {
    sr: 13906,
    city: "Peru",
    city_code: "VYS",
    airport_name: "Illinois Valley Regnl",
    country: "United States",
  },
  {
    sr: 13908,
    city: "Peru",
    city_code: "GUS",
    airport_name: "Grissom AFB",
    country: "United States",
  },
  {
    sr: 13910,
    city: "Perugia",
    city_code: "PEG",
    airport_name: "Sant Egidio",
    country: "Italy",
  },
  {
    sr: 13912,
    city: "Pescara",
    city_code: "PSR",
    airport_name: "Liberi",
    country: "Italy",
  },
  {
    sr: 13914,
    city: "Peschiei",
    city_code: "PEJ",
    airport_name: "Peschiei",
    country: "Italy",
  },
  {
    sr: 13916,
    city: "Peshawar",
    city_code: "PEW",
    airport_name: "Peshawar",
    country: "Pakistan",
  },
  {
    sr: 13918,
    city: "Petawawa",
    city_code: "YWA",
    airport_name: "Petawawa",
    country: "Canada",
  },
  {
    sr: 13920,
    city: "Peterborough",
    city_code: "YPQ",
    airport_name: "Peterborough",
    country: "Canada",
  },
  {
    sr: 13922,
    city: "Petersburg",
    city_code: "PSG",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 13924,
    city: "Petersburg",
    city_code: "FLE",
    airport_name: "Fort Lee AAF",
    country: "United States",
  },
  {
    sr: 13926,
    city: "Petersburg",
    city_code: "PTB",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 13928,
    city: "Petersburg",
    city_code: "PGC",
    airport_name: "Grant County",
    country: "United States",
  },
  {
    sr: 13930,
    city: "Petrolina",
    city_code: "PNZ",
    airport_name: "Internacional",
    country: "Brazil",
  },
  {
    sr: 13932,
    city: "Petropavlovsk",
    city_code: "PPK",
    airport_name: "Petropavlovsk",
    country: "Kazakhstan",
  },
  {
    sr: 13934,
    city: "Petropavlovsk Kamchats",
    city_code: "PKC",
    airport_name: "Petropavlovsk Kamchats",
    country: "Russia",
  },
  {
    sr: 13936,
    city: "Petrozavodsk",
    city_code: "PES",
    airport_name: "Petrozavodsk",
    country: "Russia",
  },
  {
    sr: 13940,
    city: "Pevek",
    city_code: "PWE",
    airport_name: "Pevek",
    country: "Russia",
  },
  {
    sr: 13942,
    city: "Pforheim",
    city_code: "UPF",
    airport_name: "Pforheim",
    country: "Germany",
  },
  {
    sr: 13944,
    city: "Phalaborwa",
    city_code: "PHW",
    airport_name: "Phalaborwa",
    country: "South Africa",
  },
  {
    sr: 13946,
    city: "Phan Rang",
    city_code: "PHA",
    airport_name: "Phan Rang",
    country: "Vietnam",
  },
  {
    sr: 13948,
    city: "Phan Thiet",
    city_code: "PHH",
    airport_name: "Phan Thiet",
    country: "Vietnam",
  },
  {
    sr: 13950,
    city: "Phanom Sarakham",
    city_code: "PMM",
    airport_name: "Phanom Sarakham",
    country: "Thailand",
  },
  {
    sr: 13952,
    city: "Phaplu",
    city_code: "PPL",
    airport_name: "Phaplu",
    country: "Nepal",
  },
  {
    sr: 13954,
    city: "Phetchabun",
    city_code: "PHY",
    airport_name: "Phetchabun",
    country: "Thailand",
  },
  {
    sr: 13956,
    city: "Phi Phi Island",
    city_code: "PHZ",
    airport_name: "Phi Phi Island",
    country: "Thailand",
  },
  {
    sr: 13958,
    city: "Philadelphia",
    city_code: "PHL",
    airport_name: "Philadelphia International",
    country: "United States",
  },
  {
    sr: 13960,
    city: "Philadelphia",
    city_code: "MUV",
    airport_name: "Mustin Alf",
    country: "United States",
  },
  {
    sr: 13962,
    city: "Philadelphia",
    city_code: "ZHC",
    airport_name: "N Philadelphia Rail St",
    country: "United States",
  },
  {
    sr: 13964,
    city: "Philadelphia",
    city_code: "PNE",
    airport_name: "North Philadelphia",
    country: "United States",
  },
  {
    sr: 13966,
    city: "Philadelphia",
    city_code: "ZFV",
    airport_name: "Philadelphia Rail",
    country: "United States",
  },
  {
    sr: 13968,
    city: "Philadelphia",
    city_code: "PSQ",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 13970,
    city: "Philadelphia",
    city_code: "ZTN",
    airport_name: "Trenton Rail STN",
    country: "United States",
  },
  {
    sr: 13974,
    city: "Philadelphia",
    city_code: "TTN",
    airport_name: "Trenton Mercer",
    country: "United States",
  },
  {
    sr: 13976,
    city: "Philip",
    city_code: "PHP",
    airport_name: "Philip",
    country: "United States",
  },
  {
    sr: 13978,
    city: "Phinda",
    city_code: "PZL",
    airport_name: "Zulu Inyala",
    country: "South Africa",
  },
  {
    sr: 13980,
    city: "Phitsanulok",
    city_code: "PHS",
    airport_name: "Phitsanulok",
    country: "Thailand",
  },
  {
    sr: 13982,
    city: "Phnom Penh",
    city_code: "PNH",
    airport_name: "Phnom Penh International",
    country: "Cambodia",
  },
  {
    sr: 13984,
    city: "Phoenix",
    city_code: "LUF",
    airport_name: "Luke Afb",
    country: "United States",
  },
  {
    sr: 13986,
    city: "Phoenix",
    city_code: "DVT",
    airport_name: "Phoenix Deer Valley",
    country: "United States",
  },
  {
    sr: 13988,
    city: "Phoenix",
    city_code: "SCF",
    airport_name: "Scottsdale Municipal",
    country: "United States",
  },
  {
    sr: 13990,
    city: "Phoenix",
    city_code: "PHX",
    airport_name: "Sky Harbor Intl",
    country: "United States",
  },
  {
    sr: 13992,
    city: "Phongsaly",
    city_code: "PCQ",
    airport_name: "Bounneua",
    country: "Laos",
  },
  {
    sr: 13994,
    city: "Phrae",
    city_code: "PRH",
    airport_name: "Phrae",
    country: "Thailand",
  },
  {
    sr: 13996,
    city: "Phu Quoc",
    city_code: "PQC",
    airport_name: "Duong Dang",
    country: "Vietnam",
  },
  {
    sr: 13998,
    city: "Phu Vinh",
    city_code: "PHU",
    airport_name: "Phu Vinh",
    country: "Vietnam",
  },
  {
    sr: 14000,
    city: "Phu bon",
    city_code: "HBN",
    airport_name: "Flamingo",
    country: "Vietnam",
  },
  {
    sr: 14002,
    city: "Phuket",
    city_code: "HKT",
    airport_name: "Phuket International",
    country: "Thailand",
  },
  {
    sr: 14004,
    city: "Phuoclong",
    city_code: "VSO",
    airport_name: "Phuoclong",
    country: "Vietnam",
  },
  {
    sr: 14008,
    city: "Picayune",
    city_code: "PCU",
    airport_name: "Pearl River County",
    country: "United States",
  },
  {
    sr: 14010,
    city: "Pickens",
    city_code: "LQK",
    airport_name: "Pickens",
    country: "United States",
  },
  {
    sr: 14012,
    city: "Pickle Lake",
    city_code: "YPL",
    airport_name: "Pickle Lake",
    country: "Canada",
  },
  {
    sr: 14014,
    city: "Pico Island",
    city_code: "PIX",
    airport_name: "Pico Island",
    country: "Portugal",
  },
  {
    sr: 14016,
    city: "Picos",
    city_code: "PCS",
    airport_name: "Picos",
    country: "Brazil",
  },
  {
    sr: 14018,
    city: "Picton",
    city_code: "PCN",
    airport_name: "Koromiko",
    country: "New Zealand",
  },
  {
    sr: 14020,
    city: "Piedras Negras",
    city_code: "PDS",
    airport_name: "Piedras Negras",
    country: "Mexico",
  },
  {
    sr: 14022,
    city: "Pierre",
    city_code: "PIR",
    airport_name: "Pierre",
    country: "United States",
  },
  {
    sr: 14024,
    city: "Piestany",
    city_code: "PZY",
    airport_name: "Piestany",
    country: "Slovak Republic",
  },
  {
    sr: 14026,
    city: "Pietermaritzburg",
    city_code: "PZB",
    airport_name: "Pietermaritzburg Oribi",
    country: "South Africa",
  },
  {
    sr: 14028,
    city: "Pijiguaos",
    city_code: "LPJ",
    airport_name: "Pijiguaos",
    country: "Venezuela",
  },
  {
    sr: 14030,
    city: "Pikangikum",
    city_code: "YPM",
    airport_name: "Pikangikum",
    country: "Canada",
  },
  {
    sr: 14032,
    city: "Pikwitonei",
    city_code: "PIW",
    airport_name: "Pikwitonei",
    country: "Canada",
  },
  {
    sr: 14034,
    city: "Pilar",
    city_code: "PIL",
    airport_name: "Pilar",
    country: "Paraguay",
  },
  {
    sr: 14036,
    city: "Pilot Point",
    city_code: "PIP",
    airport_name: "Pilot Point Arpt",
    country: "United States",
  },
  {
    sr: 14038,
    city: "Pilot Point",
    city_code: "UGB",
    airport_name: "Ugashik Bay",
    country: "United States",
  },
  {
    sr: 14042,
    city: "Pilot Station",
    city_code: "PQS",
    airport_name: "Pilot Station",
    country: "United States",
  },
  {
    sr: 14044,
    city: "Pimaga",
    city_code: "PMP",
    airport_name: "Pimaga",
    country: "Papua New Guinea",
  },
  {
    sr: 14046,
    city: "Pimenta Bueno",
    city_code: "PBQ",
    airport_name: "Pimenta Bueno",
    country: "Brazil",
  },
  {
    sr: 14048,
    city: "Pincher Creek",
    city_code: "WPC",
    airport_name: "Pincher Creek",
    country: "Canada",
  },
  {
    sr: 14050,
    city: "Pindiu",
    city_code: "PDI",
    airport_name: "Pindiu",
    country: "Papua New Guinea",
  },
  {
    sr: 14052,
    city: "Pine Bluff",
    city_code: "PBF",
    airport_name: "Grider Field",
    country: "United States",
  },
  {
    sr: 14054,
    city: "Pine Cay",
    city_code: "PIC",
    airport_name: "Pine Cay",
    country: "Turks and Caicos Islands",
  },
  {
    sr: 14056,
    city: "Pine House",
    city_code: "ZPO",
    airport_name: "Pine House",
    country: "Canada",
  },
  {
    sr: 14058,
    city: "Pine Mountain",
    city_code: "PIM",
    airport_name: "Garden Harris County",
    country: "United States",
  },
  {
    sr: 14060,
    city: "Pine Point",
    city_code: "YPP",
    airport_name: "Pine Point",
    country: "Canada",
  },
  {
    sr: 14062,
    city: "Pine Ridge",
    city_code: "XPR",
    airport_name: "Pine Ridge",
    country: "United States",
  },
  {
    sr: 14064,
    city: "Pingtung",
    city_code: "PIF",
    airport_name: "Pingtung",
    country: "Taiwan",
  },
  {
    sr: 14066,
    city: "Pinheiro",
    city_code: "PHI",
    airport_name: "Pinheiro",
    country: "Brazil",
  },
  {
    sr: 14068,
    city: "Pinotepa Nacional",
    city_code: "PNO",
    airport_name: "Pinotepa Nacional",
    country: "Mexico",
  },
  {
    sr: 14070,
    city: "Pipillipai",
    city_code: "PIQ",
    airport_name: "Pipillipai",
    country: "Guyana",
  },
  {
    sr: 14072,
    city: "Pirapora",
    city_code: "PIV",
    airport_name: "Pirapora",
    country: "Brazil",
  },
  {
    sr: 14076,
    city: "Pisco",
    city_code: "PIO",
    airport_name: "Pisco",
    country: "Peru",
  },
  {
    sr: 14078,
    city: "Pitalito",
    city_code: "PTX",
    airport_name: "Pitalito",
    country: "Colombia",
  },
  {
    sr: 14080,
    city: "Pitinga",
    city_code: "PIG",
    airport_name: "Pitinga",
    country: "Brazil",
  },
  {
    sr: 14082,
    city: "Pitts Town",
    city_code: "PWN",
    airport_name: "Pitts Town",
    country: "Bahamas",
  },
  {
    sr: 14084,
    city: "Pittsburg",
    city_code: "PTS",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 14086,
    city: "Pittsburgh",
    city_code: "AGC",
    airport_name: "Allegheny County",
    country: "United States",
  },
  {
    sr: 14088,
    city: "Pittsburgh",
    city_code: "CVA",
    airport_name: "Civic Ar Heli",
    country: "United States",
  },
  {
    sr: 14090,
    city: "Pittsfield",
    city_code: "PSF",
    airport_name: "Pittsfield",
    country: "United States",
  },
  {
    sr: 14092,
    city: "Pituffik",
    city_code: "THU",
    airport_name: "Pituffik",
    country: "Greenland",
  },
  {
    sr: 14094,
    city: "Piura",
    city_code: "PIU",
    airport_name: "Piura",
    country: "Peru",
  },
  {
    sr: 14096,
    city: "Placencia",
    city_code: "PLJ",
    airport_name: "Placencia",
    country: "Belize",
  },
  {
    sr: 14098,
    city: "Placerville",
    city_code: "PVF",
    airport_name: "Placerville",
    country: "United States",
  },
  {
    sr: 14100,
    city: "Plainview",
    city_code: "PVW",
    airport_name: "Hale County",
    country: "United States",
  },
  {
    sr: 14102,
    city: "Planadas",
    city_code: "PLA",
    airport_name: "Planadas",
    country: "Colombia",
  },
  {
    sr: 14104,
    city: "Planeta Rica",
    city_code: "PLC",
    airport_name: "Planeta Rica",
    country: "Colombia",
  },
  {
    sr: 14106,
    city: "Platinum",
    city_code: "PTU",
    airport_name: "Platinum",
    country: "United States",
  },
  {
    sr: 14110,
    city: "Plato",
    city_code: "PLT",
    airport_name: "Plato",
    country: "Colombia",
  },
  {
    sr: 14112,
    city: "Plattsburgh",
    city_code: "PBG",
    airport_name: "AFB",
    country: "United States",
  },
  {
    sr: 14114,
    city: "Plattsburgh",
    city_code: "PLB",
    airport_name: "Clinton County",
    country: "United States",
  },
  {
    sr: 14116,
    city: "Playa Del Carmen",
    city_code: "PCM",
    airport_name: "Playa Del Carmen",
    country: "Mexico",
  },
  {
    sr: 14118,
    city: "Playa Grande",
    city_code: "PKJ",
    airport_name: "Playa Grande",
    country: "Guatemala",
  },
  {
    sr: 14120,
    city: "Playa Samara",
    city_code: "PLD",
    airport_name: "Playa Samara",
    country: "Costa Rica",
  },
  {
    sr: 14122,
    city: "Playon Chico",
    city_code: "PYC",
    airport_name: "Playon Chico",
    country: "Panama",
  },
  {
    sr: 14124,
    city: "Pleasant Harbour",
    city_code: "PTR",
    airport_name: "Pleasant Harbour",
    country: "United States",
  },
  {
    sr: 14126,
    city: "Pleasanton",
    city_code: "JBS",
    airport_name: "Hacienda Bus Park H P",
    country: "United States",
  },
  {
    sr: 14128,
    city: "Pleiku",
    city_code: "PXU",
    airport_name: "Pleiku",
    country: "Vietnam",
  },
  {
    sr: 14130,
    city: "Plentywood",
    city_code: "PWD",
    airport_name: "Sherwood",
    country: "United States",
  },
  {
    sr: 14132,
    city: "Plettenberg Bay",
    city_code: "PBZ",
    airport_name: "Plettenberg Bay",
    country: "South Africa",
  },
  {
    sr: 14134,
    city: "Pleven",
    city_code: "PVN",
    airport_name: "Pleven",
    country: "Bulgaria",
  },
  {
    sr: 14136,
    city: "Plovdiv",
    city_code: "PDV",
    airport_name: "Plovdiv",
    country: "Bulgaria",
  },
  {
    sr: 14138,
    city: "Plymouth",
    city_code: "PLH",
    airport_name: "Plymouth",
    country: "United Kingdom",
  },
  {
    sr: 14140,
    city: "Plymouth",
    city_code: "PLY",
    airport_name: "Plymouth",
    country: "United States",
  },
  {
    sr: 14144,
    city: "Plymouth",
    city_code: "PYM",
    airport_name: "Plymouth",
    country: "United States",
  },
  {
    sr: 14146,
    city: "Po",
    city_code: "PUP",
    airport_name: "Po",
    country: "Burkina Faso",
  },
  {
    sr: 14148,
    city: "Pocahontas",
    city_code: "POH",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 14150,
    city: "Pocatello",
    city_code: "PIH",
    airport_name: "Pocatello",
    country: "United States",
  },
  {
    sr: 14152,
    city: "Pochutla",
    city_code: "PUH",
    airport_name: "Pochutla",
    country: "Mexico",
  },
  {
    sr: 14154,
    city: "Pocos De Caldas",
    city_code: "POO",
    airport_name: "Pocos De Caldas",
    country: "Brazil",
  },
  {
    sr: 14156,
    city: "Podgorica",
    city_code: "TGD",
    airport_name: "Podgorica",
    country: "Montenegro",
  },
  {
    sr: 14158,
    city: "Podor",
    city_code: "POD",
    airport_name: "Podor",
    country: "Senegal",
  },
  {
    sr: 14160,
    city: "Pohakuloa",
    city_code: "BSF",
    airport_name: "Bradshaw AAF",
    country: "United States",
  },
  {
    sr: 14162,
    city: "Pohang",
    city_code: "KPO",
    airport_name: "Pohang",
    country: "South Korea",
  },
  {
    sr: 14164,
    city: "Pohnpei",
    city_code: "PNI",
    airport_name: "Pohnpei",
    country: "Micronesia",
  },
  {
    sr: 14166,
    city: "Point Baker",
    city_code: "KPB",
    airport_name: "Point Baker SPB",
    country: "United States",
  },
  {
    sr: 14168,
    city: "Point Hope",
    city_code: "PHO",
    airport_name: "Point Hope",
    country: "United States",
  },
  {
    sr: 14170,
    city: "Point Lay",
    city_code: "PIZ",
    airport_name: "Dew Station",
    country: "United States",
  },
  {
    sr: 14172,
    city: "Point Retreat",
    city_code: "PRT",
    airport_name: "Coast Guard Heliport",
    country: "United States",
  },
  {
    sr: 14174,
    city: "Pointe Noire",
    city_code: "PNR",
    airport_name: "Pointe Noire",
    country: "Congo",
  },
  {
    sr: 14178,
    city: "Pointe a Pitre",
    city_code: "PTP",
    airport_name: "Le Raizet",
    country: "Guadeloupe",
  },
  {
    sr: 14180,
    city: "Points North Landing",
    city_code: "YNL",
    airport_name: "Points North Landing",
    country: "Canada",
  },
  {
    sr: 14182,
    city: "Poipet",
    city_code: "HPP",
    airport_name: "Poipet",
    country: "Cambodia",
  },
  {
    sr: 14184,
    city: "Poitiers",
    city_code: "PIS",
    airport_name: "Biard",
    country: "France",
  },
  {
    sr: 14186,
    city: "Pokhara",
    city_code: "PKR",
    airport_name: "Pokhara",
    country: "Nepal",
  },
  {
    sr: 14188,
    city: "Polacca",
    city_code: "PXL",
    airport_name: "Polacca",
    country: "United States",
  },
  {
    sr: 14190,
    city: "Polk Inlet",
    city_code: "POQ",
    airport_name: "Polk Inlet",
    country: "United States",
  },
  {
    sr: 14192,
    city: "Polokwane",
    city_code: "PTG",
    airport_name: "Polokwane",
    country: "South Africa",
  },
  {
    sr: 14194,
    city: "Poltava",
    city_code: "PLV",
    airport_name: "Poltava",
    country: "Ukraine",
  },
  {
    sr: 14196,
    city: "Polyarnyj",
    city_code: "PYJ",
    airport_name: "Polyarnyj",
    country: "Russia",
  },
  {
    sr: 14198,
    city: "Pomala",
    city_code: "PUM",
    airport_name: "Pomala",
    country: "Indonesia",
  },
  {
    sr: 14200,
    city: "Pompano Beach",
    city_code: "PPM",
    airport_name: "Pompano Beach",
    country: "United States",
  },
  {
    sr: 14202,
    city: "Ponca City",
    city_code: "PNC",
    airport_name: "Ponca City",
    country: "United States",
  },
  {
    sr: 14204,
    city: "Ponce",
    city_code: "PSE",
    airport_name: "Mercedita",
    country: "Puerto Rico",
  },
  {
    sr: 14206,
    city: "Pond Inlet",
    city_code: "YIO",
    airport_name: "Pond Inlet",
    country: "Canada",
  },
  {
    sr: 14208,
    city: "Pondicherry",
    city_code: "PNY",
    airport_name: "Pondicherry",
    country: "India",
  },
  {
    sr: 14212,
    city: "Pondok Cabe",
    city_code: "PCB",
    airport_name: "Pondok Cabe",
    country: "Indonesia",
  },
  {
    sr: 14214,
    city: "Ponta de Ouro",
    city_code: "PDD",
    airport_name: "Ponta de Ouro",
    country: "Mozambique",
  },
  {
    sr: 14216,
    city: "Ponta Delgada",
    city_code: "PDL",
    airport_name: "Nordela",
    country: "Portugal",
  },
  {
    sr: 14218,
    city: "Ponta Pelada",
    city_code: "PLL",
    airport_name: "Ponta Pelada",
    country: "Brazil",
  },
  {
    sr: 14220,
    city: "Ponta Pora",
    city_code: "PMG",
    airport_name: "International",
    country: "Brazil",
  },
  {
    sr: 14222,
    city: "Pontes e Lacerda",
    city_code: "LCB",
    airport_name: "Pontes e Lacerda",
    country: "Brazil",
  },
  {
    sr: 14224,
    city: "Pontiac",
    city_code: "PTK",
    airport_name: "Pontiac",
    country: "United States",
  },
  {
    sr: 14226,
    city: "Pontianak",
    city_code: "PNK",
    airport_name: "Supadio",
    country: "Indonesia",
  },
  {
    sr: 14228,
    city: "Ponza",
    city_code: "ZJY",
    airport_name: "Harbour",
    country: "Italy",
  },
  {
    sr: 14230,
    city: "Popayan",
    city_code: "PPN",
    airport_name: "Machangara",
    country: "Colombia",
  },
  {
    sr: 14232,
    city: "Pope Vanoy",
    city_code: "PVY",
    airport_name: "Pope Vanoy",
    country: "United States",
  },
  {
    sr: 14234,
    city: "Poplar Bluff",
    city_code: "POF",
    airport_name: "Earl Fields Memorial",
    country: "United States",
  },
  {
    sr: 14236,
    city: "Poplar Hill",
    city_code: "YHP",
    airport_name: "Poplar Hill",
    country: "Canada",
  },
  {
    sr: 14238,
    city: "Poplar River",
    city_code: "XPP",
    airport_name: "Poplar River",
    country: "Canada",
  },
  {
    sr: 14240,
    city: "Popondetta",
    city_code: "PNP",
    airport_name: "Girua",
    country: "Papua New Guinea",
  },
  {
    sr: 14242,
    city: "Poprad Tatry",
    city_code: "TAT",
    airport_name: "Poprad Tatry",
    country: "Slovak Republic",
  },
  {
    sr: 14246,
    city: "Poptun",
    city_code: "PON",
    airport_name: "Poptun",
    country: "Guatemala",
  },
  {
    sr: 14248,
    city: "Porbandar",
    city_code: "PBD",
    airport_name: "Porbandar",
    country: "India",
  },
  {
    sr: 14250,
    city: "Porcupine Creek",
    city_code: "PCK",
    airport_name: "Porcupine Creek",
    country: "United States",
  },
  {
    sr: 14252,
    city: "Pore",
    city_code: "PRE",
    airport_name: "Pore",
    country: "Colombia",
  },
  {
    sr: 14254,
    city: "Porgera",
    city_code: "RGE",
    airport_name: "Porgera",
    country: "Papua New Guinea",
  },
  {
    sr: 14256,
    city: "Pori",
    city_code: "POR",
    airport_name: "Pori",
    country: "Finland",
  },
  {
    sr: 14258,
    city: "Porlamar",
    city_code: "PMV",
    airport_name: "DelCaribe Gen S Marino",
    country: "Venezuela",
  },
  {
    sr: 14260,
    city: "Porsgrunn",
    city_code: "XKP",
    airport_name: "Porsgrunn Rail St",
    country: "Norway",
  },
  {
    sr: 14262,
    city: "Port Alberni",
    city_code: "YPB",
    airport_name: "Port Alberni",
    country: "Canada",
  },
  {
    sr: 14264,
    city: "Port Alexander",
    city_code: "PTD",
    airport_name: "Port Alexander",
    country: "United States",
  },
  {
    sr: 14266,
    city: "Port Alfred",
    city_code: "AFD",
    airport_name: "Port Alfred",
    country: "South Africa",
  },
  {
    sr: 14268,
    city: "Port Alice",
    city_code: "PTC",
    airport_name: "Port Alice",
    country: "United States",
  },
  {
    sr: 14270,
    city: "Port Alsworth",
    city_code: "PTA",
    airport_name: "Port Alsworth",
    country: "United States",
  },
  {
    sr: 14272,
    city: "Port Angeles",
    city_code: "CLM",
    airport_name: "Fairchild Intl",
    country: "United States",
  },
  {
    sr: 14274,
    city: "Port Antonio",
    city_code: "POT",
    airport_name: "Ken Jones",
    country: "Jamaica",
  },
  {
    sr: 14276,
    city: "Port Armstrong",
    city_code: "PTL",
    airport_name: "Port Armstrong",
    country: "United States",
  },
  {
    sr: 14280,
    city: "Port Au Prince",
    city_code: "PAP",
    airport_name: "Mais Gate",
    country: "Haiti",
  },
  {
    sr: 14282,
    city: "Port Augusta",
    city_code: "PUG",
    airport_name: "Port Augusta",
    country: "Australia",
  },
  {
    sr: 14284,
    city: "Port Bailey",
    city_code: "KPY",
    airport_name: "Port Bailey SPB",
    country: "United States",
  },
  {
    sr: 14286,
    city: "Port Berge",
    city_code: "WPB",
    airport_name: "Port Berge",
    country: "Madagascar",
  },
  {
    sr: 14288,
    city: "Port Blair",
    city_code: "IXZ",
    airport_name: "Port Blair",
    country: "India",
  },
  {
    sr: 14290,
    city: "Port Clarence",
    city_code: "KPC",
    airport_name: "Port Clarence",
    country: "United States",
  },
  {
    sr: 14292,
    city: "Port De Paix",
    city_code: "PAX",
    airport_name: "Port De Paix",
    country: "Haiti",
  },
  {
    sr: 14294,
    city: "Port Douglas",
    city_code: "PTI",
    airport_name: "Port Douglas",
    country: "Australia",
  },
  {
    sr: 14296,
    city: "Port Elizabeth",
    city_code: "BQU",
    airport_name: "Bequia Airport",
    country: "Saint Vincent and the Grenadines",
  },
  {
    sr: 14298,
    city: "Port Elizabeth",
    city_code: "PLZ",
    airport_name: "Port Elizabeth",
    country: "South Africa",
  },
  {
    sr: 14300,
    city: "Port Fitzroy",
    city_code: "GBS",
    airport_name: "Port Fitzroy",
    country: "New Zealand",
  },
  {
    sr: 14302,
    city: "Port Frederick",
    city_code: "PFD",
    airport_name: "Port Frederick",
    country: "United States",
  },
  {
    sr: 14304,
    city: "Port Gentil",
    city_code: "POG",
    airport_name: "Port Gentil",
    country: "Gabon",
  },
  {
    sr: 14306,
    city: "Port Graham",
    city_code: "PGM",
    airport_name: "Port Graham",
    country: "United States",
  },
  {
    sr: 14308,
    city: "Port Harcourt",
    city_code: "PHC",
    airport_name: "Port Harcourt",
    country: "Nigeria",
  },
  {
    sr: 14310,
    city: "Port Harcourt",
    city_code: "PHG",
    airport_name: "Port Harcourt City",
    country: "Nigeria",
  },
  {
    sr: 14314,
    city: "Port Hardy",
    city_code: "YZT",
    airport_name: "Port Hardy",
    country: "Canada",
  },
  {
    sr: 14316,
    city: "Port Hawkesbury",
    city_code: "YPS",
    airport_name: "Port Hawkesbury",
    country: "Canada",
  },
  {
    sr: 14318,
    city: "Port Hedland",
    city_code: "PHE",
    airport_name: "Port Hedland",
    country: "Australia",
  },
  {
    sr: 14320,
    city: "Port Heiden",
    city_code: "PTH",
    airport_name: "Port Heiden",
    country: "United States",
  },
  {
    sr: 14322,
    city: "Port Hope Simpson",
    city_code: "YHA",
    airport_name: "Port Hope Simpson",
    country: "Canada",
  },
  {
    sr: 14324,
    city: "Port Hueneme",
    city_code: "NTD",
    airport_name: "Point Mugu NAS",
    country: "United States",
  },
  {
    sr: 14326,
    city: "Port Hunter",
    city_code: "PHJ",
    airport_name: "Port Hunter",
    country: "Australia",
  },
  {
    sr: 14328,
    city: "Port Huron",
    city_code: "PHN",
    airport_name: "St Clair County Intl",
    country: "United States",
  },
  {
    sr: 14330,
    city: "Port Johnson",
    city_code: "PRF",
    airport_name: "Port Johnson",
    country: "United States",
  },
  {
    sr: 14332,
    city: "Port Kaituma",
    city_code: "PKM",
    airport_name: "Port Kaituma",
    country: "Guyana",
  },
  {
    sr: 14334,
    city: "Port Keats",
    city_code: "PKT",
    airport_name: "Port Keats",
    country: "Australia",
  },
  {
    sr: 14336,
    city: "Port Klang",
    city_code: "XPQ",
    airport_name: "Ferry",
    country: "Malaysia",
  },
  {
    sr: 14338,
    city: "Port Lincoln",
    city_code: "PLO",
    airport_name: "Port Lincoln",
    country: "Australia",
  },
  {
    sr: 14340,
    city: "Port Lions",
    city_code: "ORI",
    airport_name: "Port Lions SPB",
    country: "United States",
  },
  {
    sr: 14342,
    city: "Port Macquarie",
    city_code: "PQQ",
    airport_name: "Port Macquarie",
    country: "Australia",
  },
  {
    sr: 14344,
    city: "Port McNeil",
    city_code: "YMP",
    airport_name: "Port McNeil",
    country: "Canada",
  },
  {
    sr: 14348,
    city: "Port Menier",
    city_code: "YPN",
    airport_name: "Port Menier",
    country: "Canada",
  },
  {
    sr: 14350,
    city: "Port Moller",
    city_code: "PML",
    airport_name: "AFS",
    country: "United States",
  },
  {
    sr: 14352,
    city: "Port Moresby",
    city_code: "POM",
    airport_name: "Jackson Fld",
    country: "Papua New Guinea",
  },
  {
    sr: 14354,
    city: "Port Oceanic",
    city_code: "PRL",
    airport_name: "Port Oceanic",
    country: "United States",
  },
  {
    sr: 14356,
    city: "Port Pirie",
    city_code: "PPI",
    airport_name: "Port Pirie",
    country: "Australia",
  },
  {
    sr: 14358,
    city: "Port Protection",
    city_code: "PPV",
    airport_name: "Port Protection",
    country: "United States",
  },
  {
    sr: 14360,
    city: "Port Said",
    city_code: "PSD",
    airport_name: "Port Said",
    country: "Egypt",
  },
  {
    sr: 14362,
    city: "Port Saint Johns",
    city_code: "JOH",
    airport_name: "Port Saint Johns",
    country: "South Africa",
  },
  {
    sr: 14364,
    city: "Port San Juan",
    city_code: "PJS",
    airport_name: "Port San Juan",
    country: "United States",
  },
  {
    sr: 14366,
    city: "Port Simpson",
    city_code: "YPI",
    airport_name: "Port Simpson",
    country: "Canada",
  },
  {
    sr: 14368,
    city: "Port Stanley",
    city_code: "PSY",
    airport_name: "Port Stanley",
    country: "Falkland Islands  Malvinas",
  },
  {
    sr: 14370,
    city: "Port Stephens",
    city_code: "PTE",
    airport_name: "Port Stephens",
    country: "Australia",
  },
  {
    sr: 14372,
    city: "Port Sudan",
    city_code: "PZU",
    airport_name: "Port Sudan",
    country: "Sudan",
  },
  {
    sr: 14374,
    city: "Port Townsend",
    city_code: "TWD",
    airport_name: "Port Townsend",
    country: "United States",
  },
  {
    sr: 14376,
    city: "Port Vila",
    city_code: "VLI",
    airport_name: "Bauerfield",
    country: "Vanuatu",
  },
  {
    sr: 14378,
    city: "Port Walter",
    city_code: "PWR",
    airport_name: "Port Walter",
    country: "United States",
  },
  {
    sr: 14382,
    city: "Port Williams",
    city_code: "KPR",
    airport_name: "Port Williams SPB",
    country: "United States",
  },
  {
    sr: 14384,
    city: "Portage Creek",
    city_code: "PCA",
    airport_name: "Portage Creek",
    country: "United States",
  },
  {
    sr: 14386,
    city: "Portage La Prairie",
    city_code: "YPG",
    airport_name: "Portage La Prairie",
    country: "Canada",
  },
  {
    sr: 14388,
    city: "Porterville",
    city_code: "PTV",
    airport_name: "Porterville",
    country: "United States",
  },
  {
    sr: 14390,
    city: "Portimao",
    city_code: "PRM",
    airport_name: "Portimao",
    country: "Portugal",
  },
  {
    sr: 14392,
    city: "Portland",
    city_code: "PWM",
    airport_name: "Intl Jetport",
    country: "United States",
  },
  {
    sr: 14394,
    city: "Portland",
    city_code: "PDX",
    airport_name: "Portland International",
    country: "United States",
  },
  {
    sr: 14396,
    city: "Portland",
    city_code: "PTJ",
    airport_name: "Portland",
    country: "Australia",
  },
  {
    sr: 14398,
    city: "Porto",
    city_code: "OPO",
    airport_name: "Porto",
    country: "Portugal",
  },
  {
    sr: 14400,
    city: "Porto",
    city_code: "ZYJ",
    airport_name: "Porto TP",
    country: "Portugal",
  },
  {
    sr: 14402,
    city: "Porto Alegre",
    city_code: "POA",
    airport_name: "Salgado Filho",
    country: "Brazil",
  },
  {
    sr: 14404,
    city: "Porto Alegre",
    city_code: "PGP",
    airport_name: "Porto Alegre",
    country: "Sao Tome and Principe",
  },
  {
    sr: 14406,
    city: "Porto Alegre Do Norte",
    city_code: "PBX",
    airport_name: "Porto Alegre Do Norte",
    country: "Brazil",
  },
  {
    sr: 14408,
    city: "Porto Amboim",
    city_code: "PBN",
    airport_name: "Porto Amboim",
    country: "Angola",
  },
  {
    sr: 14410,
    city: "Porto de Moz",
    city_code: "PTQ",
    airport_name: "Porto de Moz",
    country: "Brazil",
  },
  {
    sr: 14412,
    city: "Porto Dos Gauchos",
    city_code: "PBV",
    airport_name: "Porto Dos Gauchos",
    country: "Brazil",
  },
  {
    sr: 14416,
    city: "Porto Kheli",
    city_code: "PKH",
    airport_name: "Alexion",
    country: "Greece",
  },
  {
    sr: 14418,
    city: "Porto Nacional",
    city_code: "PNB",
    airport_name: "Porto Nacional",
    country: "Brazil",
  },
  {
    sr: 14420,
    city: "Porto Santo",
    city_code: "PXO",
    airport_name: "Porto Santo",
    country: "Portugal",
  },
  {
    sr: 14422,
    city: "Porto Seguro",
    city_code: "BPS",
    airport_name: "Porto Seguro",
    country: "Brazil",
  },
  {
    sr: 14424,
    city: "Porto Velho",
    city_code: "PVH",
    airport_name: "Belmonte",
    country: "Brazil",
  },
  {
    sr: 14426,
    city: "Portoroz",
    city_code: "POW",
    airport_name: "Portoroz",
    country: "Slovenia",
  },
  {
    sr: 14428,
    city: "Portoviejo",
    city_code: "PVO",
    airport_name: "Portoviejo",
    country: "Ecuador",
  },
  {
    sr: 14430,
    city: "Portsmouth",
    city_code: "PME",
    airport_name: "Portsmouth",
    country: "United Kingdom",
  },
  {
    sr: 14432,
    city: "Portsmouth",
    city_code: "PMH",
    airport_name: "Regional",
    country: "United States",
  },
  {
    sr: 14434,
    city: "Porvenir",
    city_code: "WPR",
    airport_name: "Porvenir",
    country: "Chile",
  },
  {
    sr: 14436,
    city: "Posadas",
    city_code: "PSS",
    airport_name: "Posadas",
    country: "Argentina",
  },
  {
    sr: 14438,
    city: "Poso",
    city_code: "PSJ",
    airport_name: "Poso",
    country: "Indonesia",
  },
  {
    sr: 14440,
    city: "Postville",
    city_code: "YSO",
    airport_name: "Postville",
    country: "Canada",
  },
  {
    sr: 14442,
    city: "Poteau",
    city_code: "RKR",
    airport_name: "Robert S Kerr",
    country: "United States",
  },
  {
    sr: 14444,
    city: "Potosi",
    city_code: "POI",
    airport_name: "Potosi",
    country: "Bolivia",
  },
  {
    sr: 14446,
    city: "Pottstown",
    city_code: "PTW",
    airport_name: "Pottstown Limerick",
    country: "United States",
  },
  {
    sr: 14450,
    city: "Poughkeepsie",
    city_code: "POU",
    airport_name: "Dutchess County",
    country: "United States",
  },
  {
    sr: 14452,
    city: "Poulsbo",
    city_code: "PUL",
    airport_name: "Poulsbo",
    country: "United States",
  },
  {
    sr: 14454,
    city: "Poum",
    city_code: "PUV",
    airport_name: "Poum",
    country: "New Caledonia",
  },
  {
    sr: 14456,
    city: "Pouso Alegre",
    city_code: "PPY",
    airport_name: "Pouso Alegre",
    country: "Brazil",
  },
  {
    sr: 14458,
    city: "Povungnituk",
    city_code: "YPX",
    airport_name: "Puvirnituq",
    country: "Canada",
  },
  {
    sr: 14460,
    city: "Powell",
    city_code: "POY",
    airport_name: "Powell",
    country: "United States",
  },
  {
    sr: 14462,
    city: "Powell Lake",
    city_code: "WPL",
    airport_name: "Powell Lake",
    country: "Canada",
  },
  {
    sr: 14464,
    city: "Powell Point",
    city_code: "PPO",
    airport_name: "Powell Point",
    country: "Bahamas",
  },
  {
    sr: 14466,
    city: "Powell River",
    city_code: "YPW",
    airport_name: "Powell River",
    country: "Canada",
  },
  {
    sr: 14468,
    city: "Poza Rica",
    city_code: "PAZ",
    airport_name: "Tajin",
    country: "Mexico",
  },
  {
    sr: 14470,
    city: "Poznan",
    city_code: "POZ",
    airport_name: "Lawica",
    country: "Poland",
  },
  {
    sr: 14472,
    city: "Prado",
    city_code: "PDF",
    airport_name: "Prado",
    country: "Brazil",
  },
  {
    sr: 14474,
    city: "Prague",
    city_code: "XYG",
    airport_name: "Hlavni N Railway",
    country: "Czech Republic",
  },
  {
    sr: 14476,
    city: "Prague",
    city_code: "XYJ",
    airport_name: "Holosovice Railway",
    country: "Czech Republic",
  },
  {
    sr: 14478,
    city: "Prague",
    city_code: "PRG",
    airport_name: "Ruzyne",
    country: "Czechoslovakia  former",
  },
  {
    sr: 14480,
    city: "Praia",
    city_code: "RAI",
    airport_name: "Francisco Mendes",
    country: "Cape Verde",
  },
  {
    sr: 14484,
    city: "Prairie Du Chien",
    city_code: "PCD",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 14486,
    city: "Praslin Island",
    city_code: "PRI",
    airport_name: "Praslin Island",
    country: "Seychelles",
  },
  {
    sr: 14488,
    city: "Pratt",
    city_code: "PTT",
    airport_name: "Pratt",
    country: "United States",
  },
  {
    sr: 14490,
    city: "Prentice",
    city_code: "PRW",
    airport_name: "Prentice",
    country: "United States",
  },
  {
    sr: 14492,
    city: "Prerov",
    city_code: "PRV",
    airport_name: "Prerov",
    country: "Czechoslovakia  former",
  },
  {
    sr: 14494,
    city: "Pres Roque Saenz Pena",
    city_code: "PRQ",
    airport_name: "Pres Roque Saenz Pena",
    country: "Argentina",
  },
  {
    sr: 14496,
    city: "Prescott",
    city_code: "PRC",
    airport_name: "Prescott",
    country: "United States",
  },
  {
    sr: 14498,
    city: "Presidente Dutra",
    city_code: "PDR",
    airport_name: "Municipal",
    country: "Brazil",
  },
  {
    sr: 14500,
    city: "Presidente Prudente",
    city_code: "PPB",
    airport_name: "A De Barros",
    country: "Brazil",
  },
  {
    sr: 14502,
    city: "Presov",
    city_code: "POV",
    airport_name: "Zamosc",
    country: "Czechoslovakia  former",
  },
  {
    sr: 14504,
    city: "Presque Isle",
    city_code: "PQI",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 14506,
    city: "Preston",
    city_code: "PST",
    airport_name: "Preston",
    country: "Cuba",
  },
  {
    sr: 14508,
    city: "Pretoria",
    city_code: "HPR",
    airport_name: "Central Hpr",
    country: "South Africa",
  },
  {
    sr: 14510,
    city: "Pretoria",
    city_code: "HIC",
    airport_name: "Iscor Heliport",
    country: "South Africa",
  },
  {
    sr: 14512,
    city: "Pretoria",
    city_code: "PRY",
    airport_name: "Wonderboom Apt",
    country: "South Africa",
  },
  {
    sr: 14514,
    city: "Preveza Lefkas",
    city_code: "PVK",
    airport_name: "Aktion",
    country: "Greece",
  },
  {
    sr: 14518,
    city: "Price",
    city_code: "PUC",
    airport_name: "Carbon County",
    country: "United States",
  },
  {
    sr: 14520,
    city: "Prieska",
    city_code: "PRK",
    airport_name: "Prieska",
    country: "South Africa",
  },
  {
    sr: 14522,
    city: "Prince Albert",
    city_code: "YPA",
    airport_name: "Prince Albert",
    country: "Canada",
  },
  {
    sr: 14524,
    city: "Prince George",
    city_code: "YXS",
    airport_name: "Prince George",
    country: "Canada",
  },
  {
    sr: 14526,
    city: "Prince George",
    city_code: "XDV",
    airport_name: "Pr George Rail Stn",
    country: "Canada",
  },
  {
    sr: 14528,
    city: "Prince Rupert",
    city_code: "YPR",
    airport_name: "Digby Island",
    country: "Canada",
  },
  {
    sr: 14530,
    city: "Prince Rupert",
    city_code: "XDW",
    airport_name: "Pr Rupert Rail Stn",
    country: "Canada",
  },
  {
    sr: 14532,
    city: "Prince Rupert",
    city_code: "ZSW",
    airport_name: "Seal Cove",
    country: "Canada",
  },
  {
    sr: 14534,
    city: "Princeton",
    city_code: "PNN",
    airport_name: "Princeton",
    country: "United States",
  },
  {
    sr: 14536,
    city: "Princeton",
    city_code: "PCT",
    airport_name: "Princeton",
    country: "United States",
  },
  {
    sr: 14538,
    city: "Princeton",
    city_code: "ZTJ",
    airport_name: "Princeton JT Rail",
    country: "United States",
  },
  {
    sr: 14540,
    city: "Princeton",
    city_code: "BLF",
    airport_name: "Princeton",
    country: "United States",
  },
  {
    sr: 14542,
    city: "Principe",
    city_code: "PCP",
    airport_name: "Principe",
    country: "Sao Tome and Principe",
  },
  {
    sr: 14544,
    city: "Prineville",
    city_code: "PRZ",
    airport_name: "Prineville",
    country: "United States",
  },
  {
    sr: 14546,
    city: "Prishtina",
    city_code: "PRN",
    airport_name: "Prishtina International Airport",
    country: "Kosovo",
  },
  {
    sr: 14548,
    city: "Procida",
    city_code: "ZJJ",
    airport_name: "Harbour",
    country: "Italy",
  },
  {
    sr: 14552,
    city: "Sao Paulo",
    city_code: "PGG",
    airport_name: "Progresso",
    country: "Brazil",
  },
  {
    sr: 14554,
    city: "Prome",
    city_code: "PRU",
    airport_name: "Prome",
    country: "Myanmar",
  },
  {
    sr: 14556,
    city: "Propriano",
    city_code: "PRP",
    airport_name: "Propriano",
    country: "France",
  },
  {
    sr: 14558,
    city: "Proserpine",
    city_code: "PPP",
    airport_name: "Whitsunday Coast",
    country: "Australia",
  },
  {
    sr: 14560,
    city: "Prospect Creek",
    city_code: "PPC",
    airport_name: "Prospect Creek",
    country: "United States",
  },
  {
    sr: 14562,
    city: "Provedenia",
    city_code: "PVX",
    airport_name: "Provedenia",
    country: "Russia",
  },
  {
    sr: 14564,
    city: "Providence",
    city_code: "ZRV",
    airport_name: "Providence Rail",
    country: "United States",
  },
  {
    sr: 14566,
    city: "Providence",
    city_code: "PVD",
    airport_name: "T  F Green Airport",
    country: "United States",
  },
  {
    sr: 14568,
    city: "Providencia",
    city_code: "PVA",
    airport_name: "Providencia",
    country: "Colombia",
  },
  {
    sr: 14570,
    city: "Providenciales",
    city_code: "PLS",
    airport_name: "International",
    country: "Turks and Caicos Islands",
  },
  {
    sr: 14572,
    city: "Provideniya",
    city_code: "PVS",
    airport_name: "Provideniya",
    country: "Russia",
  },
  {
    sr: 14574,
    city: "Provincetown",
    city_code: "PVC",
    airport_name: "Provincetown",
    country: "United States",
  },
  {
    sr: 14576,
    city: "Provo",
    city_code: "PVU",
    airport_name: "Provo",
    country: "United States",
  },
  {
    sr: 14578,
    city: "Prudhoe Bay",
    city_code: "PUO",
    airport_name: "Prudhoe Bay",
    country: "United States",
  },
  {
    sr: 14580,
    city: "Prudhoe Bay Deadhorse",
    city_code: "SCC",
    airport_name: "Prudhoe Bay Deadhorse",
    country: "United States",
  },
  {
    sr: 14582,
    city: "Pskov",
    city_code: "PKV",
    airport_name: "Pskov",
    country: "Russia",
  },
  {
    sr: 14586,
    city: "Puas",
    city_code: "PUA",
    airport_name: "Puas",
    country: "Papua New Guinea",
  },
  {
    sr: 14588,
    city: "Pucallpa",
    city_code: "PCL",
    airport_name: "Capitan Rolden",
    country: "Peru",
  },
  {
    sr: 14590,
    city: "Pucon",
    city_code: "ZPC",
    airport_name: "Pucon",
    country: "Chile",
  },
  {
    sr: 14592,
    city: "Puebla",
    city_code: "PBC",
    airport_name: "Huejotsingo",
    country: "Mexico",
  },
  {
    sr: 14594,
    city: "Pueblo",
    city_code: "PUB",
    airport_name: "Memorial",
    country: "United States",
  },
  {
    sr: 14596,
    city: "Puente Genil",
    city_code: "GEN",
    airport_name: "Puente Genil",
    country: "Norway",
  },
  {
    sr: 14598,
    city: "Puerto Aisen",
    city_code: "WPA",
    airport_name: "Puerto Aisen",
    country: "Chile",
  },
  {
    sr: 14600,
    city: "Puerto Armuellas",
    city_code: "AML",
    airport_name: "Puerto Armuellas",
    country: "Panama",
  },
  {
    sr: 14602,
    city: "Puerto Asis",
    city_code: "PUU",
    airport_name: "Puerto Asis",
    country: "Colombia",
  },
  {
    sr: 14604,
    city: "Puerto Ayacucho",
    city_code: "PYH",
    airport_name: "Puerto Ayacucho",
    country: "Venezuela",
  },
  {
    sr: 14606,
    city: "Puerto Barrios",
    city_code: "PBR",
    airport_name: "Puerto Barrios",
    country: "Guatemala",
  },
  {
    sr: 14608,
    city: "Puerto Berrio",
    city_code: "PBE",
    airport_name: "Puerto Berrio",
    country: "Colombia",
  },
  {
    sr: 14610,
    city: "Puerto Boyaca",
    city_code: "PYA",
    airport_name: "Puerto Boyaca",
    country: "Colombia",
  },
  {
    sr: 14612,
    city: "Puerto Cabello",
    city_code: "PBL",
    airport_name: "Puerto Cabello",
    country: "Venezuela",
  },
  {
    sr: 14614,
    city: "Puerto Cabezas",
    city_code: "PUZ",
    airport_name: "Puerto Cabezas",
    country: "Nicaragua",
  },
  {
    sr: 14616,
    city: "Puerto Carreno",
    city_code: "PCR",
    airport_name: "Puerto Carreno",
    country: "Colombia",
  },
  {
    sr: 14620,
    city: "Puerto De Santa Maria",
    city_code: "PXS",
    airport_name: "Puerto De Santa Maria",
    country: "Spain",
  },
  {
    sr: 14622,
    city: "Puerto del Rosario",
    city_code: "FUE",
    airport_name: "Fuerteventura",
    country: "Spain",
  },
  {
    sr: 14624,
    city: "Puerto Deseado",
    city_code: "PUD",
    airport_name: "Puerto Deseado",
    country: "Argentina",
  },
  {
    sr: 14626,
    city: "Puerto Escondido",
    city_code: "PXM",
    airport_name: "Puerto Escondido",
    country: "Mexico",
  },
  {
    sr: 14628,
    city: "Puerto Inirida",
    city_code: "PDA",
    airport_name: "Puerto Inirida",
    country: "Colombia",
  },
  {
    sr: 14630,
    city: "Puerto Jimenez",
    city_code: "PJM",
    airport_name: "Puerto Jimenez",
    country: "Costa Rica",
  },
  {
    sr: 14632,
    city: "Puerto Juarez",
    city_code: "PJZ",
    airport_name: "Puerto Juarez",
    country: "Mexico",
  },
  {
    sr: 14634,
    city: "Puerto La Cruz",
    city_code: "UPC",
    airport_name: "Puerto La Cruz",
    country: "Spain",
  },
  {
    sr: 14636,
    city: "Puerto La Victoria",
    city_code: "PCJ",
    airport_name: "Puerto La Victoria",
    country: "Paraguay",
  },
  {
    sr: 14638,
    city: "Puerto Leda",
    city_code: "PBT",
    airport_name: "Puerto Leda",
    country: "Paraguay",
  },
  {
    sr: 14640,
    city: "Puerto Leguizamo",
    city_code: "LQM",
    airport_name: "Puerto Leguizamo",
    country: "Colombia",
  },
  {
    sr: 14642,
    city: "Puerto Lempira",
    city_code: "PEU",
    airport_name: "Puerto Lempira",
    country: "Honduras",
  },
  {
    sr: 14644,
    city: "Puerto Madryn",
    city_code: "PMY",
    airport_name: "El Tehuelche",
    country: "Argentina",
  },
  {
    sr: 14646,
    city: "Puerto Maldonado",
    city_code: "PEM",
    airport_name: "Puerto Maldonado",
    country: "Peru",
  },
  {
    sr: 14648,
    city: "Puerto Montt",
    city_code: "PMC",
    airport_name: "Tepual",
    country: "Chile",
  },
  {
    sr: 14650,
    city: "Puerto Natales",
    city_code: "PNT",
    airport_name: "Teniente J Gallardo",
    country: "Chile",
  },
  {
    sr: 14654,
    city: "Puerto Obaldia",
    city_code: "PUE",
    airport_name: "Puerto Obaldia",
    country: "Panama",
  },
  {
    sr: 14656,
    city: "Puerto Ordaz",
    city_code: "PZO",
    airport_name: "Puerto Ordaz",
    country: "Venezuela",
  },
  {
    sr: 14658,
    city: "Puerto Paez",
    city_code: "PPZ",
    airport_name: "Puerto Paez",
    country: "Venezuela",
  },
  {
    sr: 14660,
    city: "Puerto Penasco",
    city_code: "PPE",
    airport_name: "Puerto Penasco",
    country: "Mexico",
  },
  {
    sr: 14662,
    city: "Puerto Plata",
    city_code: "POP",
    airport_name: "La Union",
    country: "Dominican Republic",
  },
  {
    sr: 14664,
    city: "Puerto Princesa",
    city_code: "PPS",
    airport_name: "Puerto Princesa",
    country: "Philippines",
  },
  {
    sr: 14666,
    city: "Puerto Rico",
    city_code: "PUR",
    airport_name: "Puerto Rico",
    country: "Bolivia",
  },
  {
    sr: 14668,
    city: "Puerto Rico",
    city_code: "PCC",
    airport_name: "Puerto Rico",
    country: "Colombia",
  },
  {
    sr: 14670,
    city: "Puerto Suarez",
    city_code: "PSZ",
    airport_name: "Puerto Suarez",
    country: "Bolivia",
  },
  {
    sr: 14672,
    city: "Puerto Vallarta",
    city_code: "PVR",
    airport_name: "Ordaz",
    country: "Mexico",
  },
  {
    sr: 14674,
    city: "Puerto Varas",
    city_code: "PUX",
    airport_name: "Puerto Varas",
    country: "Chile",
  },
  {
    sr: 14676,
    city: "Puerto Williams",
    city_code: "WPU",
    airport_name: "Puerto Williams",
    country: "Chile",
  },
  {
    sr: 14678,
    city: "Puertollano",
    city_code: "UER",
    airport_name: "Puertollano",
    country: "Spain",
  },
  {
    sr: 14680,
    city: "Puka Puka",
    city_code: "PKP",
    airport_name: "Puka Puka",
    country: "French Polynesia",
  },
  {
    sr: 14682,
    city: "Puka Puka Is Attol",
    city_code: "PZK",
    airport_name: "Puka Puka Is Attol",
    country: "Cook Islands",
  },
  {
    sr: 14684,
    city: "Pukarua",
    city_code: "PUK",
    airport_name: "Pukarua",
    country: "French Polynesia",
  },
  {
    sr: 14688,
    city: "Pukatawagan",
    city_code: "XPK",
    airport_name: "Pukatawagan",
    country: "Canada",
  },
  {
    sr: 14690,
    city: "Pula",
    city_code: "PUY",
    airport_name: "Pula",
    country: "Croatia  hrvatska",
  },
  {
    sr: 14692,
    city: "Pulau Layang Layang Is",
    city_code: "LAC",
    airport_name: "Layang Layang Airstrip",
    country: "Malaysia",
  },
  {
    sr: 14694,
    city: "Pulau Panjang",
    city_code: "PPJ",
    airport_name: "Pulau Panjang",
    country: "Indonesia",
  },
  {
    sr: 14696,
    city: "Pullman",
    city_code: "PUW",
    airport_name: "Pullman Moscow Regional Airport",
    country: "United States",
  },
  {
    sr: 14698,
    city: "Pumani",
    city_code: "PMN",
    airport_name: "Pumani",
    country: "Papua New Guinea",
  },
  {
    sr: 14700,
    city: "Pune",
    city_code: "PNQ",
    airport_name: "Lohegaon",
    country: "India",
  },
  {
    sr: 14702,
    city: "Punia",
    city_code: "PUN",
    airport_name: "Punia",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 14704,
    city: "Punta Alegre",
    city_code: "UPA",
    airport_name: "Punta Alegre",
    country: "Cuba",
  },
  {
    sr: 14706,
    city: "Punta Arenas",
    city_code: "PUQ",
    airport_name: "Pres Ibanez",
    country: "Chile",
  },
  {
    sr: 14708,
    city: "Punta Cana",
    city_code: "PUJ",
    airport_name: "Punta Cana",
    country: "Dominican Republic",
  },
  {
    sr: 14710,
    city: "Punta Chivato",
    city_code: "PCV",
    airport_name: "Punta Chivato",
    country: "Mexico",
  },
  {
    sr: 14712,
    city: "Punta Colorada",
    city_code: "PCO",
    airport_name: "Punta Colorada",
    country: "Mexico",
  },
  {
    sr: 14714,
    city: "Punta De Maisi",
    city_code: "UMA",
    airport_name: "Punta De Maisi",
    country: "Cuba",
  },
  {
    sr: 14716,
    city: "Maldonado",
    city_code: "PDP",
    airport_name: "Capitan Corbeta CA Curbelo International Airport",
    country: "Uruguay",
  },
  {
    sr: 14718,
    city: "Punta Gorda",
    city_code: "PND",
    airport_name: "Punta Gorda",
    country: "Belize",
  },
  {
    sr: 14722,
    city: "Punta Gorda",
    city_code: "PGD",
    airport_name: "Charlotte County",
    country: "United States",
  },
  {
    sr: 14724,
    city: "Punta Islita",
    city_code: "PBP",
    airport_name: "Punta Islita",
    country: "Costa Rica",
  },
  {
    sr: 14726,
    city: "Punta Renes",
    city_code: "JAP",
    airport_name: "Punta Renes",
    country: "Costa Rica",
  },
  {
    sr: 14728,
    city: "Pureni",
    city_code: "PUI",
    airport_name: "Pureni",
    country: "Papua New Guinea",
  },
  {
    sr: 14730,
    city: "Purwokerto",
    city_code: "PWL",
    airport_name: "Purwokerto",
    country: "Indonesia",
  },
  {
    sr: 14732,
    city: "Putao",
    city_code: "PBU",
    airport_name: "Putao",
    country: "Myanmar",
  },
  {
    sr: 14734,
    city: "Puttaparthi",
    city_code: "PUT",
    airport_name: "Puttaprathe",
    country: "India",
  },
  {
    sr: 14736,
    city: "Putumayo",
    city_code: "PYO",
    airport_name: "Putumayo",
    country: "Ecuador",
  },
  {
    sr: 14738,
    city: "Putussibau",
    city_code: "PSU",
    airport_name: "Putussibau",
    country: "Indonesia",
  },
  {
    sr: 14740,
    city: "Pweto",
    city_code: "PWO",
    airport_name: "Pweto",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 14742,
    city: "Pyongyang",
    city_code: "FNJ",
    airport_name: "Sunan",
    country: "North Korea",
  },
  {
    sr: 14744,
    city: "Pyrgos",
    city_code: "PYR",
    airport_name: "Andravida",
    country: "Greece",
  },
  {
    sr: 14746,
    city: "Qaanaaq",
    city_code: "NAQ",
    airport_name: "Qaanaaq",
    country: "Greenland",
  },
  {
    sr: 14748,
    city: "Qaarsut",
    city_code: "JQA",
    airport_name: "Qaarsut",
    country: "Greenland",
  },
  {
    sr: 14750,
    city: "Qachas Nek",
    city_code: "UNE",
    airport_name: "Qachas Nek",
    country: "Lesotho",
  },
  {
    sr: 14752,
    city: "Qaisumah",
    city_code: "AQI",
    airport_name: "Qaisumah",
    country: "Saudi Arabia",
  },
  {
    sr: 14756,
    city: "Qala Nau",
    city_code: "LQN",
    airport_name: "Qala Nau",
    country: "Afghanistan",
  },
  {
    sr: 14758,
    city: "Qaqortoq",
    city_code: "JJU",
    airport_name: "Heliport",
    country: "Greenland",
  },
  {
    sr: 14760,
    city: "Qasigiannguit",
    city_code: "JCH",
    airport_name: "Qasigiannguit",
    country: "Greenland",
  },
  {
    sr: 14762,
    city: "Qeqertaq",
    city_code: "PQT",
    airport_name: "Heliport",
    country: "Denmark",
  },
  {
    sr: 14764,
    city: "Qeqertarsuaq",
    city_code: "JGO",
    airport_name: "Qeqertarsuaq",
    country: "Greenland",
  },
  {
    sr: 14766,
    city: "Qiemo",
    city_code: "IQM",
    airport_name: "Qiemo",
    country: "China",
  },
  {
    sr: 14768,
    city: "Qikiqtarjuaq",
    city_code: "YVM",
    airport_name: "Qikiqtarjuaq",
    country: "Canada",
  },
  {
    sr: 14770,
    city: "Qingdao",
    city_code: "TAO",
    airport_name: "Qingdao",
    country: "China",
  },
  {
    sr: 14772,
    city: "Qingyang",
    city_code: "IQN",
    airport_name: "Qingyang",
    country: "China",
  },
  {
    sr: 14774,
    city: "Qinhuangdao",
    city_code: "SHP",
    airport_name: "Qinhuangdao",
    country: "China",
  },
  {
    sr: 14776,
    city: "Qiqihar",
    city_code: "NDG",
    airport_name: "Qiqihar",
    country: "China",
  },
  {
    sr: 14778,
    city: "Qishn",
    city_code: "IHN",
    airport_name: "Qishn",
    country: "Yemen",
  },
  {
    sr: 14780,
    city: "Quakertown",
    city_code: "UKT",
    airport_name: "Upper Bucks",
    country: "United States",
  },
  {
    sr: 14782,
    city: "Qualicum",
    city_code: "XQU",
    airport_name: "Qualicum",
    country: "Canada",
  },
  {
    sr: 14784,
    city: "Quanduc",
    city_code: "HOO",
    airport_name: "Nhon Co",
    country: "Vietnam",
  },
  {
    sr: 14786,
    city: "Quang Ngai",
    city_code: "XNG",
    airport_name: "Quang Ngai",
    country: "Vietnam",
  },
  {
    sr: 14790,
    city: "Quantico",
    city_code: "NYG",
    airport_name: "Quantanico Nas",
    country: "United States",
  },
  {
    sr: 14792,
    city: "Quaqtaq",
    city_code: "YQC",
    airport_name: "Quaqtaq",
    country: "Canada",
  },
  {
    sr: 14794,
    city: "Quebec City",
    city_code: "YQB",
    airport_name: "Quebec",
    country: "Canada",
  },
  {
    sr: 14796,
    city: "Quebec",
    city_code: "XFZ",
    airport_name: "Charny",
    country: "Canada",
  },
  {
    sr: 14798,
    city: "Quebec",
    city_code: "XLJ",
    airport_name: "Quebec Stn Rail Svce",
    country: "Canada",
  },
  {
    sr: 14800,
    city: "Quebec",
    city_code: "XFY",
    airport_name: "Sainte Foy Rail Svce",
    country: "Canada",
  },
  {
    sr: 14802,
    city: "Queen",
    city_code: "UQE",
    airport_name: "Queen",
    country: "United States",
  },
  {
    sr: 14804,
    city: "Queen Charlotte Is",
    city_code: "ZQS",
    airport_name: "Queen Charlotte Is",
    country: "Canada",
  },
  {
    sr: 14806,
    city: "Queenstown",
    city_code: "ZQN",
    airport_name: "Frankton",
    country: "New Zealand",
  },
  {
    sr: 14808,
    city: "Queenstown",
    city_code: "UEE",
    airport_name: "Queenstown",
    country: "Australia",
  },
  {
    sr: 14810,
    city: "Queenstown",
    city_code: "UTW",
    airport_name: "Queenstown",
    country: "South Africa",
  },
  {
    sr: 14812,
    city: "Quelimane",
    city_code: "UEL",
    airport_name: "Quelimane",
    country: "Mozambique",
  },
  {
    sr: 14814,
    city: "Pavones",
    city_code: "XQP",
    airport_name: "Quepos",
    country: "Costa Rica",
  },
  {
    sr: 14816,
    city: "Queretaro",
    city_code: "QRO",
    airport_name: "Queretaro",
    country: "Mexico",
  },
  {
    sr: 14818,
    city: "Quesnel",
    city_code: "YQZ",
    airport_name: "Quesnel",
    country: "Canada",
  },
  {
    sr: 14820,
    city: "Quetta",
    city_code: "UET",
    airport_name: "Quetta",
    country: "Pakistan",
  },
  {
    sr: 14824,
    city: "Quetzaltenango",
    city_code: "AAZ",
    airport_name: "Quetzaltenango Airport",
    country: "Guatemala",
  },
  {
    sr: 14826,
    city: "Qui Nhon",
    city_code: "UIH",
    airport_name: "Qui Nhon",
    country: "Vietnam",
  },
  {
    sr: 14828,
    city: "Quibdo",
    city_code: "UIB",
    airport_name: "Quibdo",
    country: "Colombia",
  },
  {
    sr: 14830,
    city: "Quiche",
    city_code: "AQB",
    airport_name: "Quiche Airport",
    country: "Guatemala",
  },
  {
    sr: 14832,
    city: "Quillayute",
    city_code: "UIL",
    airport_name: "Quillayute State",
    country: "United States",
  },
  {
    sr: 14834,
    city: "Quilpie",
    city_code: "ULP",
    airport_name: "Quilpie",
    country: "Australia",
  },
  {
    sr: 14836,
    city: "Quimper",
    city_code: "UIP",
    airport_name: "Pluguffan",
    country: "France",
  },
  {
    sr: 14838,
    city: "Quincemil",
    city_code: "UMI",
    airport_name: "Quincemil",
    country: "Peru",
  },
  {
    sr: 14840,
    city: "Quincy",
    city_code: "UIN",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 14842,
    city: "Quincy",
    city_code: "MQI",
    airport_name: "Quincy",
    country: "United States",
  },
  {
    sr: 14844,
    city: "Quine Hill",
    city_code: "UIQ",
    airport_name: "Quine Hill",
    country: "Vanuatu",
  },
  {
    sr: 14846,
    city: "Quinhagak",
    city_code: "KWN",
    airport_name: "Kwinhagak",
    country: "United States",
  },
  {
    sr: 14848,
    city: "Quirindi",
    city_code: "UIR",
    airport_name: "Quirindi",
    country: "Australia",
  },
  {
    sr: 14850,
    city: "Quito",
    city_code: "UIO",
    airport_name: "Mariscal Sucr",
    country: "Ecuador",
  },
  {
    sr: 14852,
    city: "Quonset Point",
    city_code: "NCO",
    airport_name: "NAS",
    country: "United States",
  },
  {
    sr: 14854,
    city: "Quthing",
    city_code: "UTG",
    airport_name: "Quthing",
    country: "Lesotho",
  },
  {
    sr: 14858,
    city: "Rabaraba",
    city_code: "RBP",
    airport_name: "Rabaraba",
    country: "Papua New Guinea",
  },
  {
    sr: 14860,
    city: "Rabat",
    city_code: "RBA",
    airport_name: "Sale",
    country: "Morocco",
  },
  {
    sr: 14862,
    city: "Rabaul",
    city_code: "RAB",
    airport_name: "Tokua",
    country: "Papua New Guinea",
  },
  {
    sr: 14864,
    city: "Rabi",
    city_code: "RBI",
    airport_name: "Rabi",
    country: "Fiji",
  },
  {
    sr: 14866,
    city: "Rach Gia",
    city_code: "VKG",
    airport_name: "Rach Gia",
    country: "Vietnam",
  },
  {
    sr: 14868,
    city: "Racine",
    city_code: "RAC",
    airport_name: "Horlick",
    country: "United States",
  },
  {
    sr: 14870,
    city: "Raduzhnyi",
    city_code: "RAT",
    airport_name: "Raduzhnyi",
    country: "Russia",
  },
  {
    sr: 14872,
    city: "Rae Lakes",
    city_code: "YRA",
    airport_name: "Rae Lakes",
    country: "Canada",
  },
  {
    sr: 14874,
    city: "Rafai",
    city_code: "RFA",
    airport_name: "Rafai",
    country: "Central African Republic",
  },
  {
    sr: 14876,
    city: "Rafha",
    city_code: "RAH",
    airport_name: "Rafha",
    country: "Saudi Arabia",
  },
  {
    sr: 14878,
    city: "Rafsanjan",
    city_code: "RJN",
    airport_name: "Rafsanjan",
    country: "Iran",
  },
  {
    sr: 14880,
    city: "Raglan",
    city_code: "RAG",
    airport_name: "Raglan",
    country: "New Zealand",
  },
  {
    sr: 14882,
    city: "Raha",
    city_code: "RAQ",
    airport_name: "Sugimanuru",
    country: "Indonesia",
  },
  {
    sr: 14884,
    city: "Rahim Yar Khan",
    city_code: "RYK",
    airport_name: "Rahim Yar Khan",
    country: "Pakistan",
  },
  {
    sr: 14886,
    city: "Raiatea",
    city_code: "RFP",
    airport_name: "Raiatea",
    country: "French Polynesia",
  },
  {
    sr: 14888,
    city: "Rail  Generic",
    city_code: "XZE",
    airport_name: "Off line Point",
    country: "Germany",
  },
  {
    sr: 14892,
    city: "Rail  Generic",
    city_code: "XZJ",
    airport_name: "Off line Point",
    country: "Japan",
  },
  {
    sr: 14894,
    city: "Rail  Generic",
    city_code: "XZU",
    airport_name: "Off line Point",
    country: "United States",
  },
  {
    sr: 14896,
    city: "Rail  Generic",
    city_code: "XZQ",
    airport_name: "Pass Rail",
    country: "Sweden",
  },
  {
    sr: 14898,
    city: "Rail  Generic",
    city_code: "XZX",
    airport_name: "Off line Point",
    country: "Australia",
  },
  {
    sr: 14900,
    city: "Rainbow Lake",
    city_code: "YOP",
    airport_name: "Rainbow Lake",
    country: "Canada",
  },
  {
    sr: 14902,
    city: "Raipur",
    city_code: "RPR",
    airport_name: "Raipur",
    country: "India",
  },
  {
    sr: 14904,
    city: "Rairua",
    city_code: "RVV",
    airport_name: "Raivavae",
    country: "French Polynesia",
  },
  {
    sr: 14906,
    city: "Rajahmundry",
    city_code: "RJA",
    airport_name: "Rajahmundry",
    country: "India",
  },
  {
    sr: 14908,
    city: "Rajbiraj",
    city_code: "RJB",
    airport_name: "Rajbiraj",
    country: "Nepal",
  },
  {
    sr: 14910,
    city: "Rajkot",
    city_code: "RAJ",
    airport_name: "Civil",
    country: "India",
  },
  {
    sr: 14912,
    city: "Rajouri",
    city_code: "RJI",
    airport_name: "Rajouri",
    country: "India",
  },
  {
    sr: 14914,
    city: "Rajshahi",
    city_code: "RJH",
    airport_name: "Rajshahi",
    country: "Bangladesh",
  },
  {
    sr: 14916,
    city: "Rakanda",
    city_code: "RAA",
    airport_name: "Rakanda",
    country: "Papua New Guinea",
  },
  {
    sr: 14918,
    city: "Raleigh Durham",
    city_code: "RDU",
    airport_name: "Raleigh Durham International Airport",
    country: "United States",
  },
  {
    sr: 14920,
    city: "Ramadan",
    city_code: "TFR",
    airport_name: "Ramadan",
    country: "Egypt",
  },
  {
    sr: 14922,
    city: "Ramadi",
    city_code: "XTM",
    airport_name: "Ramidi",
    country: "Iraq",
  },
  {
    sr: 14926,
    city: "Ramagundam",
    city_code: "RMD",
    airport_name: "Ramagundam",
    country: "India",
  },
  {
    sr: 14928,
    city: "Ramata",
    city_code: "RBV",
    airport_name: "Ramata",
    country: "Solomon Islands",
  },
  {
    sr: 14930,
    city: "Ramechhap",
    city_code: "RHP",
    airport_name: "Ramechhap",
    country: "Nepal",
  },
  {
    sr: 14932,
    city: "Ramingining",
    city_code: "RAM",
    airport_name: "Ramingining",
    country: "Australia",
  },
  {
    sr: 14934,
    city: "Rampart",
    city_code: "RMP",
    airport_name: "Rampart",
    country: "United States",
  },
  {
    sr: 14936,
    city: "Ramsar",
    city_code: "RZR",
    airport_name: "Ramsar",
    country: "Iran",
  },
  {
    sr: 14938,
    city: "Ramstein",
    city_code: "RMS",
    airport_name: "Ramstein",
    country: "Germany",
  },
  {
    sr: 14940,
    city: "Ranau",
    city_code: "RNU",
    airport_name: "Ranau",
    country: "Malaysia",
  },
  {
    sr: 14942,
    city: "Ranchi",
    city_code: "IXR",
    airport_name: "Ranchi",
    country: "India",
  },
  {
    sr: 14944,
    city: "Rancho",
    city_code: "RBK",
    airport_name: "French Valley",
    country: "United States",
  },
  {
    sr: 14946,
    city: "Rangely",
    city_code: "RNG",
    airport_name: "Rangely",
    country: "United States",
  },
  {
    sr: 14948,
    city: "Ranger",
    city_code: "RGR",
    airport_name: "Ranger Municipal",
    country: "United States",
  },
  {
    sr: 14950,
    city: "Rangiroa",
    city_code: "RGI",
    airport_name: "Rangiroa",
    country: "French Polynesia",
  },
  {
    sr: 14952,
    city: "Rangpur",
    city_code: "RAU",
    airport_name: "Rangpur",
    country: "Bangladesh",
  },
  {
    sr: 14954,
    city: "Rankin Inlet",
    city_code: "YRT",
    airport_name: "Rankin Inlet",
    country: "Canada",
  },
  {
    sr: 14956,
    city: "Ranong",
    city_code: "UNN",
    airport_name: "Ranong",
    country: "Thailand",
  },
  {
    sr: 14960,
    city: "Ransiki",
    city_code: "RSK",
    airport_name: "Ransiki",
    country: "Indonesia",
  },
  {
    sr: 14962,
    city: "Rapid City",
    city_code: "RCA",
    airport_name: "Ellsworth AFB",
    country: "United States",
  },
  {
    sr: 14964,
    city: "Rapid City",
    city_code: "RAP",
    airport_name: "Regional",
    country: "United States",
  },
  {
    sr: 14966,
    city: "Rarotonga",
    city_code: "RAR",
    airport_name: "Rarotonga",
    country: "Cook Islands",
  },
  {
    sr: 14968,
    city: "Ras Al Khaimah",
    city_code: "RKT",
    airport_name: "Ras Al Khaimah",
    country: "United Arab Emirates",
  },
  {
    sr: 14970,
    city: "Rasht",
    city_code: "RAS",
    airport_name: "Rasht",
    country: "Iran",
  },
  {
    sr: 14972,
    city: "Raspberry Strait",
    city_code: "RSP",
    airport_name: "Raspberry Strait",
    country: "United States",
  },
  {
    sr: 14974,
    city: "Ratanakiri",
    city_code: "RBE",
    airport_name: "Ratanakiri",
    country: "Cambodia",
  },
  {
    sr: 14976,
    city: "Ratnagiri",
    city_code: "RTC",
    airport_name: "Ratnagiri",
    country: "India",
  },
  {
    sr: 14978,
    city: "Raton",
    city_code: "RTN",
    airport_name: "Crews Field",
    country: "United States",
  },
  {
    sr: 14980,
    city: "Raudha",
    city_code: "RXA",
    airport_name: "Raudha",
    country: "Yemen",
  },
  {
    sr: 14982,
    city: "Raufarhofn",
    city_code: "RFN",
    airport_name: "Raufarhofn",
    country: "Iceland",
  },
  {
    sr: 14984,
    city: "Raufoss",
    city_code: "ZMQ",
    airport_name: "Railway",
    country: "Norway",
  },
  {
    sr: 14986,
    city: "Ravenna",
    city_code: "RAN",
    airport_name: "La Spreta",
    country: "Italy",
  },
  {
    sr: 14988,
    city: "Ravensthorpe",
    city_code: "RVT",
    airport_name: "Ravensthorpe",
    country: "Australia",
  },
  {
    sr: 14990,
    city: "Rawala Kot",
    city_code: "RAZ",
    airport_name: "Rawala Kot",
    country: "Pakistan",
  },
  {
    sr: 14994,
    city: "Rawlins",
    city_code: "RWL",
    airport_name: "Rawlins",
    country: "United States",
  },
  {
    sr: 14996,
    city: "Reading",
    city_code: "RDG",
    airport_name: "Municipal Spaatz Fld",
    country: "United States",
  },
  {
    sr: 14998,
    city: "Reao",
    city_code: "REA",
    airport_name: "Reao",
    country: "French Polynesia",
  },
  {
    sr: 15000,
    city: "Rebun",
    city_code: "RBJ",
    airport_name: "Rebun",
    country: "Japan",
  },
  {
    sr: 15002,
    city: "Rechlin",
    city_code: "REB",
    airport_name: "Rechlin",
    country: "Germany",
  },
  {
    sr: 15004,
    city: "Recife",
    city_code: "REC",
    airport_name: "Guararapes Intl",
    country: "Brazil",
  },
  {
    sr: 15006,
    city: "Reconquista",
    city_code: "RCQ",
    airport_name: "Reconquista",
    country: "Argentina",
  },
  {
    sr: 15008,
    city: "Red Bluff",
    city_code: "RBL",
    airport_name: "Red Bluff Fss",
    country: "United States",
  },
  {
    sr: 15010,
    city: "Red Deer",
    city_code: "YQF",
    airport_name: "Red Deer",
    country: "Canada",
  },
  {
    sr: 15012,
    city: "Red Devil",
    city_code: "RDV",
    airport_name: "Red Devil",
    country: "United States",
  },
  {
    sr: 15014,
    city: "Red Dog",
    city_code: "RDB",
    airport_name: "Red Dog",
    country: "United States",
  },
  {
    sr: 15016,
    city: "Red Lake",
    city_code: "YRL",
    airport_name: "Red Lake",
    country: "Canada",
  },
  {
    sr: 15018,
    city: "Red River",
    city_code: "RDR",
    airport_name: "Grand Forks AFB",
    country: "United States",
  },
  {
    sr: 15020,
    city: "Red Sucker Lake",
    city_code: "YRS",
    airport_name: "Red Sucker Lake",
    country: "Canada",
  },
  {
    sr: 15022,
    city: "Redang",
    city_code: "RDN",
    airport_name: "LTS Pulau Redang",
    country: "Malaysia",
  },
  {
    sr: 15024,
    city: "Redcliffe",
    city_code: "RCL",
    airport_name: "Redcliffe",
    country: "Vanuatu",
  },
  {
    sr: 15028,
    city: "Redding",
    city_code: "RDD",
    airport_name: "Redding",
    country: "United States",
  },
  {
    sr: 15030,
    city: "Redencao",
    city_code: "RDC",
    airport_name: "Redencao",
    country: "Brazil",
  },
  {
    sr: 15032,
    city: "Redmond",
    city_code: "RDM",
    airport_name: "Roberts Field",
    country: "United States",
  },
  {
    sr: 15034,
    city: "Redwood Falls",
    city_code: "RWF",
    airport_name: "Redwood Falls Muni",
    country: "United States",
  },
  {
    sr: 15036,
    city: "Reed City",
    city_code: "RCT",
    airport_name: "Miller Field",
    country: "United States",
  },
  {
    sr: 15038,
    city: "Reedsville",
    city_code: "RED",
    airport_name: "Mifflin County",
    country: "United States",
  },
  {
    sr: 15040,
    city: "Refugio",
    city_code: "RFG",
    airport_name: "Rooke Field",
    country: "United States",
  },
  {
    sr: 15042,
    city: "Reggio Calabria",
    city_code: "REG",
    airport_name: "Tito Menniti",
    country: "Italy",
  },
  {
    sr: 15044,
    city: "Regina",
    city_code: "REI",
    airport_name: "Regina",
    country: "French Guiana",
  },
  {
    sr: 15046,
    city: "Regina  Saskatchewan",
    city_code: "YQR",
    airport_name: "Regina",
    country: "Canada",
  },
  {
    sr: 15048,
    city: "Rehoboth Beach",
    city_code: "REH",
    airport_name: "Rehoboth Beach",
    country: "United States",
  },
  {
    sr: 15050,
    city: "Reims",
    city_code: "RHE",
    airport_name: "Reims",
    country: "France",
  },
  {
    sr: 15052,
    city: "Reivilo",
    city_code: "RVO",
    airport_name: "Reivilo",
    country: "South Africa",
  },
  {
    sr: 15054,
    city: "Rengat",
    city_code: "RGT",
    airport_name: "Japura",
    country: "Indonesia",
  },
  {
    sr: 15056,
    city: "Renmark",
    city_code: "RMK",
    airport_name: "Renmark",
    country: "Australia",
  },
  {
    sr: 15058,
    city: "Rennell",
    city_code: "RNL",
    airport_name: "Rennell",
    country: "Solomon Islands",
  },
  {
    sr: 15062,
    city: "Rennes",
    city_code: "ZFJ",
    airport_name: "Gare de Rennes",
    country: "France",
  },
  {
    sr: 15064,
    city: "Rennes",
    city_code: "RNS",
    airport_name: "St Jacques",
    country: "France",
  },
  {
    sr: 15066,
    city: "Rensselaer",
    city_code: "RNZ",
    airport_name: "Rensselaer",
    country: "United States",
  },
  {
    sr: 15068,
    city: "Renton",
    city_code: "RNT",
    airport_name: "Renton",
    country: "United States",
  },
  {
    sr: 15070,
    city: "Repulse Bay",
    city_code: "YUT",
    airport_name: "Repulse Bay",
    country: "Canada",
  },
  {
    sr: 15072,
    city: "Resende",
    city_code: "REZ",
    airport_name: "Resende",
    country: "Brazil",
  },
  {
    sr: 15076,
    city: "Resistencia",
    city_code: "RES",
    airport_name: "Resistencia",
    country: "Argentina",
  },
  {
    sr: 15078,
    city: "Resolute",
    city_code: "YRB",
    airport_name: "Resolute",
    country: "Canada",
  },
  {
    sr: 15080,
    city: "Resolution Island",
    city_code: "YRE",
    airport_name: "Resolution Island",
    country: "Canada",
  },
  {
    sr: 15082,
    city: "Retalhuleu",
    city_code: "RER",
    airport_name: "Base Aerea Del Sur",
    country: "Guatemala",
  },
  {
    sr: 15084,
    city: "Reus",
    city_code: "REU",
    airport_name: "Reus",
    country: "Spain",
  },
  {
    sr: 15086,
    city: "Revelstoke",
    city_code: "YRV",
    airport_name: "Revelstoke",
    country: "Canada",
  },
  {
    sr: 15088,
    city: "Rewa",
    city_code: "REW",
    airport_name: "Rewa",
    country: "India",
  },
  {
    sr: 15090,
    city: "Reyes",
    city_code: "REY",
    airport_name: "Reyes",
    country: "Bolivia",
  },
  {
    sr: 15092,
    city: "Reykholar",
    city_code: "RHA",
    airport_name: "Reykholar",
    country: "Iceland",
  },
  {
    sr: 15096,
    city: "Reykjavik",
    city_code: "KEF",
    airport_name: "Keflavik International",
    country: "Iceland",
  },
  {
    sr: 15098,
    city: "Reykjavik",
    city_code: "RKV",
    airport_name: "Reykjavik Domestic",
    country: "Iceland",
  },
  {
    sr: 15100,
    city: "Reynosa",
    city_code: "REX",
    airport_name: "Gen Lucio Blanco",
    country: "Mexico",
  },
  {
    sr: 15102,
    city: "Rheindahlen",
    city_code: "GMY",
    airport_name: "Rheindahlen",
    country: "Germany",
  },
  {
    sr: 15104,
    city: "Rhinelander",
    city_code: "RHI",
    airport_name: "Oneida County",
    country: "United States",
  },
  {
    sr: 15106,
    city: "Rhodes",
    city_code: "RHO",
    airport_name: "Diagoras Airport",
    country: "Greece",
  },
  {
    sr: 15108,
    city: "Ribeirao Preto",
    city_code: "RAO",
    airport_name: "Leite Lopes",
    country: "Brazil",
  },
  {
    sr: 15110,
    city: "Riberalta",
    city_code: "RIB",
    airport_name: "Gen Buech",
    country: "Bolivia",
  },
  {
    sr: 15112,
    city: "Rice Lake",
    city_code: "RIE",
    airport_name: "Rice Lake",
    country: "United States",
  },
  {
    sr: 15114,
    city: "Richard Toll",
    city_code: "RDT",
    airport_name: "Richard Toll",
    country: "Senegal",
  },
  {
    sr: 15116,
    city: "Richards Bay",
    city_code: "RCB",
    airport_name: "Richards Bay",
    country: "South Africa",
  },
  {
    sr: 15118,
    city: "Richfield",
    city_code: "RIF",
    airport_name: "Reynolds",
    country: "United States",
  },
  {
    sr: 15120,
    city: "Richland",
    city_code: "RLD",
    airport_name: "Richland",
    country: "United States",
  },
  {
    sr: 15122,
    city: "Richmond",
    city_code: "RID",
    airport_name: "Richmond",
    country: "United States",
  },
  {
    sr: 15124,
    city: "Richmond",
    city_code: "XRH",
    airport_name: "Richmond AFB",
    country: "Australia",
  },
  {
    sr: 15126,
    city: "Richmond",
    city_code: "RCM",
    airport_name: "Richmond",
    country: "Australia",
  },
  {
    sr: 15130,
    city: "Richmond",
    city_code: "RIC",
    airport_name: "Intl  Byrd Field",
    country: "United States",
  },
  {
    sr: 15132,
    city: "Richmond",
    city_code: "ZRD",
    airport_name: "Richmond VA Rail",
    country: "United States",
  },
  {
    sr: 15134,
    city: "Riesa",
    city_code: "IES",
    airport_name: "Riesa",
    country: "Germany",
  },
  {
    sr: 15136,
    city: "Rifle",
    city_code: "RIL",
    airport_name: "Garfield County",
    country: "United States",
  },
  {
    sr: 15138,
    city: "Riga",
    city_code: "RIX",
    airport_name: "Riga International Airport",
    country: "Latvia",
  },
  {
    sr: 15140,
    city: "Rigolet",
    city_code: "YRG",
    airport_name: "Rigolet",
    country: "Canada",
  },
  {
    sr: 15142,
    city: "Rijeka",
    city_code: "RJK",
    airport_name: "Rijeka",
    country: "Croatia  hrvatska",
  },
  {
    sr: 15144,
    city: "Rimini",
    city_code: "RMI",
    airport_name: "Miramare",
    country: "Italy",
  },
  {
    sr: 15146,
    city: "Rimouski",
    city_code: "YXK",
    airport_name: "Rimouski",
    country: "Canada",
  },
  {
    sr: 15148,
    city: "Rincon de los Sauces",
    city_code: "RDS",
    airport_name: "Rincon de los Sauces",
    country: "Argentina",
  },
  {
    sr: 15150,
    city: "Ringebu",
    city_code: "XUQ",
    airport_name: "Ringebu Railway",
    country: "Norway",
  },
  {
    sr: 15152,
    city: "Ringi Cove",
    city_code: "RIN",
    airport_name: "Ringi Cove",
    country: "Solomon Islands",
  },
  {
    sr: 15154,
    city: "Rio Alzucar",
    city_code: "RIZ",
    airport_name: "Rio Alzucar",
    country: "Panama",
  },
  {
    sr: 15156,
    city: "Rio Branco",
    city_code: "RBR",
    airport_name: "Pres Medici",
    country: "Brazil",
  },
  {
    sr: 15158,
    city: "Rio Cuarto",
    city_code: "RCU",
    airport_name: "Rio Cuarto",
    country: "Argentina",
  },
  {
    sr: 15160,
    city: "Rio De Janeiro",
    city_code: "GIG",
    airport_name: "Galeao Antonio Carlos Jobim International Airport",
    country: "Brazil",
  },
  {
    sr: 15164,
    city: "Rio De Janeiro",
    city_code: "SDU",
    airport_name: "Santos Dumont",
    country: "Brazil",
  },
  {
    sr: 15166,
    city: "Rio Dulce",
    city_code: "LCF",
    airport_name: "Las Vegas",
    country: "Guatemala",
  },
  {
    sr: 15168,
    city: "Rio Frio",
    city_code: "RFR",
    airport_name: "Rio Frio",
    country: "Costa Rica",
  },
  {
    sr: 15170,
    city: "Rio Gallegos",
    city_code: "RGL",
    airport_name: "Internacional",
    country: "Argentina",
  },
  {
    sr: 15172,
    city: "Rio Grande",
    city_code: "RIG",
    airport_name: "Rio Grande",
    country: "Brazil",
  },
  {
    sr: 15174,
    city: "Rio Grande",
    city_code: "RGA",
    airport_name: "Rio Grande",
    country: "Argentina",
  },
  {
    sr: 15176,
    city: "Rio Hondo",
    city_code: "RHD",
    airport_name: "Rio Hondo",
    country: "Argentina",
  },
  {
    sr: 15178,
    city: "Rio Mayo",
    city_code: "ROY",
    airport_name: "Rio Mayo",
    country: "Argentina",
  },
  {
    sr: 15180,
    city: "Rio Sidra",
    city_code: "RSI",
    airport_name: "Rio Sidra",
    country: "Panama",
  },
  {
    sr: 15182,
    city: "Rio Tigre",
    city_code: "RIT",
    airport_name: "Rio Tigre",
    country: "Panama",
  },
  {
    sr: 15184,
    city: "Rio Turbio",
    city_code: "RYO",
    airport_name: "Rio Turbio",
    country: "Argentina",
  },
  {
    sr: 15186,
    city: "Rio Verde",
    city_code: "RVD",
    airport_name: "Rio Verde",
    country: "Brazil",
  },
  {
    sr: 15188,
    city: "Riohacha",
    city_code: "RCH",
    airport_name: "Riohacha",
    country: "Colombia",
  },
  {
    sr: 15190,
    city: "Rioja",
    city_code: "RIJ",
    airport_name: "Rioja",
    country: "Peru",
  },
  {
    sr: 15192,
    city: "Rishiri",
    city_code: "RIS",
    airport_name: "Rishiri",
    country: "Japan",
  },
  {
    sr: 15194,
    city: "Rivera",
    city_code: "RVY",
    airport_name: "Rivera",
    country: "Uruguay",
  },
  {
    sr: 15198,
    city: "Rivercess",
    city_code: "RVC",
    airport_name: "Rivercess",
    country: "Liberia",
  },
  {
    sr: 15200,
    city: "Rivers",
    city_code: "YYI",
    airport_name: "Rivers",
    country: "Canada",
  },
  {
    sr: 15202,
    city: "Owekeno Village",
    city_code: "YRN",
    airport_name: "Rivers Inlet",
    country: "Canada",
  },
  {
    sr: 15204,
    city: "Riverside",
    city_code: "JRD",
    airport_name: "Heliport",
    country: "United States",
  },
  {
    sr: 15206,
    city: "Riverside",
    city_code: "RIV",
    airport_name: "March AFB",
    country: "United States",
  },
  {
    sr: 15208,
    city: "Riverside",
    city_code: "RIR",
    airport_name: "Riverside Fla Bob",
    country: "United States",
  },
  {
    sr: 15210,
    city: "Riverside",
    city_code: "RAL",
    airport_name: "Riverside Municipal",
    country: "United States",
  },
  {
    sr: 15212,
    city: "Riverton",
    city_code: "RIW",
    airport_name: "Riverton",
    country: "United States",
  },
  {
    sr: 15214,
    city: "Riviere Au Tonnerre",
    city_code: "YTN",
    airport_name: "Riviere Au Tonnerre",
    country: "Canada",
  },
  {
    sr: 15216,
    city: "Riviere Du Loup",
    city_code: "YRI",
    airport_name: "Riviere Du Loup",
    country: "Canada",
  },
  {
    sr: 15218,
    city: "Riyadh",
    city_code: "RUH",
    airport_name: "King Khaled Intl",
    country: "Saudi Arabia",
  },
  {
    sr: 15220,
    city: "Riyan Mukalla",
    city_code: "RIY",
    airport_name: "Riyan Mukalla",
    country: "Yemen",
  },
  {
    sr: 15222,
    city: "Roanne",
    city_code: "RNE",
    airport_name: "Renaison",
    country: "France",
  },
  {
    sr: 15224,
    city: "Roanoke",
    city_code: "ROA",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 15226,
    city: "Roanoke Rapids",
    city_code: "RZZ",
    airport_name: "Halifax County",
    country: "United States",
  },
  {
    sr: 15228,
    city: "Roatan",
    city_code: "RTB",
    airport_name: "Roatan",
    country: "Honduras",
  },
  {
    sr: 15232,
    city: "Robertson",
    city_code: "ROD",
    airport_name: "Robertson",
    country: "South Africa",
  },
  {
    sr: 15234,
    city: "Roberval",
    city_code: "YRJ",
    airport_name: "Roberval",
    country: "Canada",
  },
  {
    sr: 15236,
    city: "Robinhood",
    city_code: "ROH",
    airport_name: "Robinhood",
    country: "Australia",
  },
  {
    sr: 15238,
    city: "Robinson River",
    city_code: "RRV",
    airport_name: "Robinson River",
    country: "Australia",
  },
  {
    sr: 15240,
    city: "Robinson River",
    city_code: "RNR",
    airport_name: "Robinson River",
    country: "Papua New Guinea",
  },
  {
    sr: 15242,
    city: "Robinvale",
    city_code: "RBC",
    airport_name: "Robinvale",
    country: "Australia",
  },
  {
    sr: 15244,
    city: "Robore",
    city_code: "RBO",
    airport_name: "Robore",
    country: "Bolivia",
  },
  {
    sr: 15246,
    city: "Roche Harbor",
    city_code: "RCE",
    airport_name: "Roche Harbor",
    country: "United States",
  },
  {
    sr: 15248,
    city: "Rochefort",
    city_code: "RCO",
    airport_name: "Saint Agnant",
    country: "France",
  },
  {
    sr: 15250,
    city: "Rochester",
    city_code: "RCS",
    airport_name: "Rochester",
    country: "United Kingdom",
  },
  {
    sr: 15252,
    city: "Rochester",
    city_code: "RCR",
    airport_name: "Fulton County",
    country: "United States",
  },
  {
    sr: 15254,
    city: "Rochester",
    city_code: "RST",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 15256,
    city: "Rochester",
    city_code: "JRC",
    airport_name: "Municipal Heliport",
    country: "United States",
  },
  {
    sr: 15258,
    city: "Rochester",
    city_code: "ROC",
    airport_name: "Monroe County",
    country: "United States",
  },
  {
    sr: 15260,
    city: "Rochester",
    city_code: "ZTE",
    airport_name: "Rochester NY Rail",
    country: "United States",
  },
  {
    sr: 15262,
    city: "Rock Hill",
    city_code: "RKH",
    airport_name: "Rock Hill",
    country: "United States",
  },
  {
    sr: 15266,
    city: "Rock Sound",
    city_code: "RSD",
    airport_name: "S Eleuthera",
    country: "Bahamas",
  },
  {
    sr: 15268,
    city: "Rock Springs",
    city_code: "RKS",
    airport_name: "Sweetwater County",
    country: "United States",
  },
  {
    sr: 15270,
    city: "Rockdale",
    city_code: "RCK",
    airport_name: "Coffield",
    country: "United States",
  },
  {
    sr: 15272,
    city: "Rockford",
    city_code: "RMC",
    airport_name: "Machesney",
    country: "United States",
  },
  {
    sr: 15274,
    city: "Rockford",
    city_code: "ZRF",
    airport_name: "Park & Ride Bus Svc",
    country: "United States",
  },
  {
    sr: 15276,
    city: "Rockford",
    city_code: "ZRK",
    airport_name: "VanGalder Bus Termina",
    country: "United States",
  },
  {
    sr: 15278,
    city: "Rockhampton",
    city_code: "ROK",
    airport_name: "Rockhampton",
    country: "Australia",
  },
  {
    sr: 15280,
    city: "Rockhampton Downs",
    city_code: "RDA",
    airport_name: "Rockhampton Downs",
    country: "Australia",
  },
  {
    sr: 15282,
    city: "Rockland",
    city_code: "RKD",
    airport_name: "Knox County Regional",
    country: "United States",
  },
  {
    sr: 15284,
    city: "Rockport",
    city_code: "RKP",
    airport_name: "Aransas County",
    country: "United States",
  },
  {
    sr: 15286,
    city: "Rockwood",
    city_code: "RKW",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 15288,
    city: "Rocky Mount",
    city_code: "RWI",
    airport_name: "Rocky Mount Wilson",
    country: "United States",
  },
  {
    sr: 15290,
    city: "Rocky Mountain House",
    city_code: "YRM",
    airport_name: "Rocky Mountain House",
    country: "Canada",
  },
  {
    sr: 15292,
    city: "Rodez",
    city_code: "RDZ",
    airport_name: "Marcillac",
    country: "France",
  },
  {
    sr: 15294,
    city: "Rodrigues Is",
    city_code: "RRG",
    airport_name: "Rodrigues Is",
    country: "Mauritius",
  },
  {
    sr: 15296,
    city: "Rodriguez De Men",
    city_code: "RIM",
    airport_name: "Rodriguez De Men",
    country: "Peru",
  },
  {
    sr: 15300,
    city: "Roebourne",
    city_code: "RBU",
    airport_name: "Roebourne",
    country: "Australia",
  },
  {
    sr: 15302,
    city: "Roervik",
    city_code: "RVK",
    airport_name: "Ryumsjoen Aprt",
    country: "Norway",
  },
  {
    sr: 15304,
    city: "Rogers",
    city_code: "ROG",
    airport_name: "Rogers",
    country: "United States",
  },
  {
    sr: 15306,
    city: "Roi Et",
    city_code: "ROI",
    airport_name: "Roi Et Airport",
    country: "Thailand",
  },
  {
    sr: 15308,
    city: "Rokeby",
    city_code: "RKY",
    airport_name: "Rokeby",
    country: "Australia",
  },
  {
    sr: 15310,
    city: "Rokot",
    city_code: "RKI",
    airport_name: "Rokot",
    country: "Indonesia",
  },
  {
    sr: 15312,
    city: "Rolla",
    city_code: "RLA",
    airport_name: "National",
    country: "United States",
  },
  {
    sr: 15314,
    city: "Rolpa",
    city_code: "RPA",
    airport_name: "Rolpa",
    country: "Nepal",
  },
  {
    sr: 15316,
    city: "Roma",
    city_code: "RMA",
    airport_name: "Roma",
    country: "Australia",
  },
  {
    sr: 15318,
    city: "Roma",
    city_code: "FAL",
    airport_name: "Falcon State",
    country: "United States",
  },
  {
    sr: 15320,
    city: "Rome",
    city_code: "RMG",
    airport_name: "Richard B Russell",
    country: "United States",
  },
  {
    sr: 15322,
    city: "Rome",
    city_code: "CIA",
    airport_name: "Ciampino",
    country: "Italy",
  },
  {
    sr: 15324,
    city: "Rome",
    city_code: "FCO",
    airport_name: "Leonardo Da Vinci  Fiumicino",
    country: "Italy",
  },
  {
    sr: 15326,
    city: "Rome",
    city_code: "XVY",
    airport_name: "Mestre Rail Stn",
    country: "Italy",
  },
  {
    sr: 15328,
    city: "Rome",
    city_code: "XRJ",
    airport_name: "Termini Rail Stn",
    country: "Italy",
  },
  {
    sr: 15330,
    city: "Rome",
    city_code: "RME",
    airport_name: "Griffiss AFB",
    country: "United States",
  },
  {
    sr: 15334,
    city: "Rome",
    city_code: "REO",
    airport_name: "State",
    country: "United States",
  },
  {
    sr: 15336,
    city: "Ronda",
    city_code: "RRA",
    airport_name: "Ronda",
    country: "Spain",
  },
  {
    sr: 15338,
    city: "Rondon",
    city_code: "RON",
    airport_name: "Rondon",
    country: "Colombia",
  },
  {
    sr: 15340,
    city: "Rondonopolis",
    city_code: "ROO",
    airport_name: "Rondonopolis",
    country: "Brazil",
  },
  {
    sr: 15342,
    city: "Rongelap Island",
    city_code: "RNP",
    airport_name: "Rongelap Island",
    country: "Marshall Islands",
  },
  {
    sr: 15344,
    city: "Ronneby",
    city_code: "RNB",
    airport_name: "Kallinge",
    country: "Sweden",
  },
  {
    sr: 15346,
    city: "Ronneby",
    city_code: "XWK",
    airport_name: "Karlskrona Rail Svc",
    country: "Sweden",
  },
  {
    sr: 15348,
    city: "Roosevelt",
    city_code: "ROL",
    airport_name: "Roosevelt",
    country: "United States",
  },
  {
    sr: 15350,
    city: "Roosevelt Roads",
    city_code: "NRR",
    airport_name: "Roosevelt NAS",
    country: "Puerto Rico",
  },
  {
    sr: 15352,
    city: "Roper Bar",
    city_code: "RPB",
    airport_name: "Roper Bar",
    country: "Australia",
  },
  {
    sr: 15354,
    city: "Roper Valley",
    city_code: "RPV",
    airport_name: "Roper Valley",
    country: "Australia",
  },
  {
    sr: 15356,
    city: "Roros",
    city_code: "RRS",
    airport_name: "Roros",
    country: "Norway",
  },
  {
    sr: 15358,
    city: "Rosario",
    city_code: "ROS",
    airport_name: "Fisherton",
    country: "Argentina",
  },
  {
    sr: 15360,
    city: "Rosario",
    city_code: "RSJ",
    airport_name: "Rosario SPB",
    country: "United States",
  },
  {
    sr: 15362,
    city: "Roseau",
    city_code: "ROX",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 15364,
    city: "Roseberth",
    city_code: "RSB",
    airport_name: "Roseberth",
    country: "Australia",
  },
  {
    sr: 15368,
    city: "Roseburg",
    city_code: "RBG",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 15370,
    city: "Roseires",
    city_code: "RSS",
    airport_name: "Roseires",
    country: "Sudan",
  },
  {
    sr: 15372,
    city: "Rosella Plains",
    city_code: "RLP",
    airport_name: "Rosella Plains",
    country: "Australia",
  },
  {
    sr: 15374,
    city: "Rosh Pina",
    city_code: "RPN",
    airport_name: "Rosh Pina",
    country: "Israel",
  },
  {
    sr: 15376,
    city: "Rosh Pina",
    city_code: "RHN",
    airport_name: "Rosh Pina",
    country: "Namibia",
  },
  {
    sr: 15378,
    city: "Rosita",
    city_code: "RFS",
    airport_name: "Rosita",
    country: "Nicaragua",
  },
  {
    sr: 15380,
    city: "Ross River",
    city_code: "XRR",
    airport_name: "Ross River",
    country: "Canada",
  },
  {
    sr: 15382,
    city: "Rost",
    city_code: "RET",
    airport_name: "Stolport",
    country: "Norway",
  },
  {
    sr: 15384,
    city: "Rostock laage",
    city_code: "RLG",
    airport_name: "Laage",
    country: "Germany",
  },
  {
    sr: 15386,
    city: "Rostov",
    city_code: "ROV",
    airport_name: "Rostov",
    country: "Russia",
  },
  {
    sr: 15388,
    city: "Roswell",
    city_code: "ROW",
    airport_name: "Industrial",
    country: "United States",
  },
  {
    sr: 15390,
    city: "Rota",
    city_code: "ROP",
    airport_name: "Rota",
    country: "Northern Mariana Islands",
  },
  {
    sr: 15392,
    city: "Rothesay",
    city_code: "RAY",
    airport_name: "Heliport",
    country: "United Kingdom",
  },
  {
    sr: 15394,
    city: "Roti",
    city_code: "RTI",
    airport_name: "Roti",
    country: "Indonesia",
  },
  {
    sr: 15396,
    city: "Rotorua",
    city_code: "ROT",
    airport_name: "Rotorua",
    country: "New Zealand",
  },
  {
    sr: 15398,
    city: "Rotterdam",
    city_code: "RTM",
    airport_name: "Rotterdam",
    country: "Netherlands",
  },
  {
    sr: 15402,
    city: "Rotterdam",
    city_code: "QRH",
    airport_name: "Central Station",
    country: "Netherlands",
  },
  {
    sr: 15404,
    city: "Rottnest Island",
    city_code: "RTS",
    airport_name: "Rottnest Island",
    country: "Australia",
  },
  {
    sr: 15406,
    city: "Rotuma Island",
    city_code: "RTA",
    airport_name: "Rotuma Island",
    country: "Fiji",
  },
  {
    sr: 15408,
    city: "Rotunda",
    city_code: "RTD",
    airport_name: "Rotunda",
    country: "United States",
  },
  {
    sr: 15410,
    city: "Rouen",
    city_code: "URO",
    airport_name: "Boos",
    country: "France",
  },
  {
    sr: 15412,
    city: "Round Lake",
    city_code: "ZRJ",
    airport_name: "Round Lake",
    country: "Canada",
  },
  {
    sr: 15414,
    city: "Roundup",
    city_code: "RPX",
    airport_name: "Roundup",
    country: "United States",
  },
  {
    sr: 15416,
    city: "Rourkela",
    city_code: "RRK",
    airport_name: "Rourkela",
    country: "India",
  },
  {
    sr: 15418,
    city: "Rouses Point",
    city_code: "RSX",
    airport_name: "Rouses Point",
    country: "United States",
  },
  {
    sr: 15420,
    city: "Rousse",
    city_code: "ROU",
    airport_name: "Rousse",
    country: "Bulgaria",
  },
  {
    sr: 15422,
    city: "Rouyn",
    city_code: "YUY",
    airport_name: "Rouyn",
    country: "Canada",
  },
  {
    sr: 15424,
    city: "Rovaniemi",
    city_code: "RVN",
    airport_name: "Rovaniemi",
    country: "Finland",
  },
  {
    sr: 15426,
    city: "Rovno",
    city_code: "RWN",
    airport_name: "Rovno",
    country: "Ukraine",
  },
  {
    sr: 15428,
    city: "Rowan Bay",
    city_code: "RWB",
    airport_name: "Rowan Bay",
    country: "United States",
  },
  {
    sr: 15430,
    city: "Roxas City",
    city_code: "RXS",
    airport_name: "Roxas City",
    country: "Philippines",
  },
  {
    sr: 15432,
    city: "Roy Hill",
    city_code: "RHL",
    airport_name: "Roy Hill",
    country: "Australia",
  },
  {
    sr: 15436,
    city: "Royan",
    city_code: "RYN",
    airport_name: "Medis",
    country: "France",
  },
  {
    sr: 15438,
    city: "Rubelsanto",
    city_code: "RUV",
    airport_name: "Rubelsanto",
    country: "Guatemala",
  },
  {
    sr: 15440,
    city: "Ruby",
    city_code: "RBY",
    airport_name: "Ruby",
    country: "United States",
  },
  {
    sr: 15442,
    city: "Rugao",
    city_code: "RUG",
    airport_name: "Rugao",
    country: "China",
  },
  {
    sr: 15444,
    city: "Ruhengeri",
    city_code: "RHG",
    airport_name: "Ruhengeri",
    country: "Rwanda",
  },
  {
    sr: 15446,
    city: "Ruidoso",
    city_code: "RUI",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 15448,
    city: "Rukumkot",
    city_code: "RUK",
    airport_name: "Rukumkot",
    country: "Nepal",
  },
  {
    sr: 15450,
    city: "Rum Cay",
    city_code: "RCY",
    airport_name: "Rum Cay",
    country: "Bahamas",
  },
  {
    sr: 15452,
    city: "Rumginae",
    city_code: "RMN",
    airport_name: "Rumginae",
    country: "Papua New Guinea",
  },
  {
    sr: 15454,
    city: "Rumjatar",
    city_code: "RUM",
    airport_name: "Rumjatar",
    country: "Nepal",
  },
  {
    sr: 15456,
    city: "Rundu",
    city_code: "NDU",
    airport_name: "Rundu",
    country: "Namibia",
  },
  {
    sr: 15458,
    city: "Rupert",
    city_code: "BYI",
    airport_name: "Rupert",
    country: "United States",
  },
  {
    sr: 15460,
    city: "Rupsi",
    city_code: "RUP",
    airport_name: "Rupsi",
    country: "India",
  },
  {
    sr: 15462,
    city: "Rurrenabaque",
    city_code: "RBQ",
    airport_name: "Rurrenabaque",
    country: "Bolivia",
  },
  {
    sr: 15464,
    city: "Rurutu",
    city_code: "RUR",
    airport_name: "Rurutu",
    country: "French Polynesia",
  },
  {
    sr: 15466,
    city: "Russell",
    city_code: "RSL",
    airport_name: "Russell",
    country: "United States",
  },
  {
    sr: 15470,
    city: "Russian Mission",
    city_code: "RSH",
    airport_name: "Russian SPB",
    country: "United States",
  },
  {
    sr: 15472,
    city: "Ruston",
    city_code: "RSN",
    airport_name: "Ruston",
    country: "United States",
  },
  {
    sr: 15474,
    city: "Ruteng",
    city_code: "RTG",
    airport_name: "Ruteng",
    country: "Indonesia",
  },
  {
    sr: 15476,
    city: "Ruti",
    city_code: "RUU",
    airport_name: "Ruti",
    country: "Papua New Guinea",
  },
  {
    sr: 15478,
    city: "Rutland",
    city_code: "RUT",
    airport_name: "Rutland",
    country: "United States",
  },
  {
    sr: 15480,
    city: "Rutland Plains",
    city_code: "RTP",
    airport_name: "Rutland Plains",
    country: "Australia",
  },
  {
    sr: 15482,
    city: "Ryazan",
    city_code: "RZN",
    airport_name: "Ryazan",
    country: "Russia",
  },
  {
    sr: 15484,
    city: "Rybinsk",
    city_code: "RYB",
    airport_name: "Rybinsk",
    country: "Russia",
  },
  {
    sr: 15486,
    city: "Ryotsu Sado Is",
    city_code: "SDO",
    airport_name: "Ryotsu Sado Is",
    country: "Japan",
  },
  {
    sr: 15488,
    city: "Rzeszow",
    city_code: "RZE",
    airport_name: "Jasionka",
    country: "Poland",
  },
  {
    sr: 15490,
    city: "S Cristobal deL Casas",
    city_code: "SZT",
    airport_name: "San Cristobal Airport",
    country: "Mexico",
  },
  {
    sr: 15492,
    city: "Saarbruecken",
    city_code: "SCN",
    airport_name: "Ensheim",
    country: "Germany",
  },
  {
    sr: 15494,
    city: "Saba Island",
    city_code: "SAB",
    airport_name: "J Yrausquin",
    country: "Netherlands Antilles",
  },
  {
    sr: 15496,
    city: "Sabah",
    city_code: "SBV",
    airport_name: "Sabah",
    country: "Papua New Guinea",
  },
  {
    sr: 15498,
    city: "Sabana De Mar",
    city_code: "SNX",
    airport_name: "Sabana De Mar",
    country: "Dominican Republic",
  },
  {
    sr: 15500,
    city: "Sabana De Torres",
    city_code: "SNT",
    airport_name: "Sabana De Torres",
    country: "Colombia",
  },
  {
    sr: 15504,
    city: "Sabang",
    city_code: "SBG",
    airport_name: "Narita",
    country: "Indonesia",
  },
  {
    sr: 15506,
    city: "Sabi Sabi",
    city_code: "GSS",
    airport_name: "Sabi Sabi",
    country: "South Africa",
  },
  {
    sr: 15508,
    city: "Sable Island",
    city_code: "YSA",
    airport_name: "Sable Island",
    country: "Canada",
  },
  {
    sr: 15510,
    city: "Sabzevar",
    city_code: "AFZ",
    airport_name: "Sabzevar",
    country: "Iran",
  },
  {
    sr: 15512,
    city: "Sachigo Lake",
    city_code: "ZPB",
    airport_name: "Sachigo Lake",
    country: "Canada",
  },
  {
    sr: 15514,
    city: "Sachs Harbour",
    city_code: "YSY",
    airport_name: "Sachs Harbour",
    country: "Canada",
  },
  {
    sr: 15516,
    city: "Sacramento",
    city_code: "SAC",
    airport_name: "Executive",
    country: "United States",
  },
  {
    sr: 15518,
    city: "Sacramento",
    city_code: "MHR",
    airport_name: "Mather AFB",
    country: "United States",
  },
  {
    sr: 15520,
    city: "Sacramento",
    city_code: "MCC",
    airport_name: "Mcclellan AFB",
    country: "United States",
  },
  {
    sr: 15522,
    city: "Sacramento",
    city_code: "SMF",
    airport_name: "Sacramento International",
    country: "United States",
  },
  {
    sr: 15524,
    city: "Sacramento",
    city_code: "SCK",
    airport_name: "Stockton",
    country: "United States",
  },
  {
    sr: 15526,
    city: "Sadah",
    city_code: "SYE",
    airport_name: "Sadah",
    country: "Yemen",
  },
  {
    sr: 15528,
    city: "Sado Shima",
    city_code: "SDS",
    airport_name: "Sado Shima",
    country: "Japan",
  },
  {
    sr: 15530,
    city: "Saenz Pena",
    city_code: "SZQ",
    airport_name: "Saenz Pena",
    country: "Argentina",
  },
  {
    sr: 15532,
    city: "Safford",
    city_code: "SAD",
    airport_name: "Safford",
    country: "United States",
  },
  {
    sr: 15534,
    city: "Safi",
    city_code: "SFI",
    airport_name: "Safi",
    country: "Morocco",
  },
  {
    sr: 15538,
    city: "Safia",
    city_code: "SFU",
    airport_name: "Safia",
    country: "Papua New Guinea",
  },
  {
    sr: 15540,
    city: "Saga",
    city_code: "HSG",
    airport_name: "Saga",
    country: "Japan",
  },
  {
    sr: 15542,
    city: "Sagarai",
    city_code: "SGJ",
    airport_name: "Sagarai",
    country: "Papua New Guinea",
  },
  {
    sr: 15544,
    city: "Saginaw",
    city_code: "MBS",
    airport_name: "Tri City",
    country: "United States",
  },
  {
    sr: 15546,
    city: "Saginaw Bay",
    city_code: "SGW",
    airport_name: "Saginaw Bay",
    country: "United States",
  },
  {
    sr: 15548,
    city: "Saglek",
    city_code: "YSV",
    airport_name: "Saglek",
    country: "Canada",
  },
  {
    sr: 15550,
    city: "Sagwon",
    city_code: "SAG",
    airport_name: "Sagwon",
    country: "United States",
  },
  {
    sr: 15552,
    city: "Sahabat 16",
    city_code: "SXS",
    airport_name: "Sahabat 16",
    country: "Malaysia",
  },
  {
    sr: 15554,
    city: "Sahand",
    city_code: "ACP",
    airport_name: "Sahand",
    country: "Iran",
  },
  {
    sr: 15556,
    city: "Sahiwal",
    city_code: "SWN",
    airport_name: "Sahiwal",
    country: "Pakistan",
  },
  {
    sr: 15558,
    city: "Saibai Island",
    city_code: "SBR",
    airport_name: "Saibai Island",
    country: "Australia",
  },
  {
    sr: 15560,
    city: "Saidor",
    city_code: "SDI",
    airport_name: "Saidor",
    country: "Papua New Guinea",
  },
  {
    sr: 15562,
    city: "Saidpur",
    city_code: "SPD",
    airport_name: "Saidpur",
    country: "Bangladesh",
  },
  {
    sr: 15564,
    city: "Saidu Sharif",
    city_code: "SDT",
    airport_name: "Saidu Sharif",
    country: "Pakistan",
  },
  {
    sr: 15566,
    city: "Saint Cloud",
    city_code: "STC",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 15568,
    city: "Saint Cloud",
    city_code: "JSK",
    airport_name: "Municipal Heliport",
    country: "United States",
  },
  {
    sr: 15572,
    city: "Saint George",
    city_code: "SGU",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 15574,
    city: "Saint John",
    city_code: "YSJ",
    airport_name: "Saint John",
    country: "Canada",
  },
  {
    sr: 15576,
    city: "Saint Marys",
    city_code: "KSM",
    airport_name: "Saint Marys",
    country: "United States",
  },
  {
    sr: 15578,
    city: "Saint Paul Island",
    city_code: "SNP",
    airport_name: "Saint Paul Island",
    country: "United States",
  },
  {
    sr: 15580,
    city: "Saint Tropez",
    city_code: "XPZ",
    airport_name: "Harbour",
    country: "France",
  },
  {
    sr: 15582,
    city: "Saint Tropez",
    city_code: "LTT",
    airport_name: "La Mole",
    country: "France",
  },
  {
    sr: 15584,
    city: "Saint Yan",
    city_code: "SYT",
    airport_name: "Charolais Bourgogne S",
    country: "France",
  },
  {
    sr: 15586,
    city: "Sainte Marie",
    city_code: "SMS",
    airport_name: "Sainte Marie",
    country: "Madagascar",
  },
  {
    sr: 15588,
    city: "Saipan",
    city_code: "SPN",
    airport_name: "International",
    country: "Northern Mariana Islands",
  },
  {
    sr: 15590,
    city: "Sakon Nakhon",
    city_code: "SNO",
    airport_name: "Sakon Nakhon",
    country: "Thailand",
  },
  {
    sr: 15592,
    city: "Sal",
    city_code: "SID",
    airport_name: "Amilcar Cabral Intl",
    country: "Cape Verde",
  },
  {
    sr: 15594,
    city: "Sala",
    city_code: "XYX",
    airport_name: "Sala",
    country: "Sweden",
  },
  {
    sr: 15596,
    city: "Salalah",
    city_code: "SLL",
    airport_name: "Salalah",
    country: "Oman",
  },
  {
    sr: 15598,
    city: "Salamanca",
    city_code: "SLM",
    airport_name: "Matacan",
    country: "Spain",
  },
  {
    sr: 15600,
    city: "Salamo",
    city_code: "SAM",
    airport_name: "Salamo",
    country: "Papua New Guinea",
  },
  {
    sr: 15602,
    city: "Saldanha Bay",
    city_code: "SDB",
    airport_name: "Langebaanweg",
    country: "South Africa",
  },
  {
    sr: 15606,
    city: "Sale",
    city_code: "SXE",
    airport_name: "Sale",
    country: "Australia",
  },
  {
    sr: 15608,
    city: "Salekhard",
    city_code: "SLY",
    airport_name: "Salekhard",
    country: "Russia",
  },
  {
    sr: 15610,
    city: "Salem",
    city_code: "SLO",
    airport_name: "Leckrone",
    country: "United States",
  },
  {
    sr: 15612,
    city: "Salem",
    city_code: "SXV",
    airport_name: "Salem",
    country: "India",
  },
  {
    sr: 15614,
    city: "Salem",
    city_code: "SLE",
    airport_name: "Mcnary Field",
    country: "United States",
  },
  {
    sr: 15616,
    city: "Salida",
    city_code: "SLT",
    airport_name: "Salida",
    country: "United States",
  },
  {
    sr: 15618,
    city: "Salima",
    city_code: "LMB",
    airport_name: "Salima",
    country: "Malawi",
  },
  {
    sr: 15620,
    city: "Salina",
    city_code: "ZIQ",
    airport_name: "Harbour  Eolie Island",
    country: "Italy",
  },
  {
    sr: 15622,
    city: "Salina",
    city_code: "SLN",
    airport_name: "Salina",
    country: "United States",
  },
  {
    sr: 15624,
    city: "Salina",
    city_code: "SBO",
    airport_name: "Salina",
    country: "United States",
  },
  {
    sr: 15626,
    city: "Salina Cruz",
    city_code: "SCX",
    airport_name: "Salina Cruz",
    country: "Mexico",
  },
  {
    sr: 15628,
    city: "Salinas",
    city_code: "SNS",
    airport_name: "Salinas",
    country: "United States",
  },
  {
    sr: 15630,
    city: "Salinas",
    city_code: "SNC",
    airport_name: "Salinas",
    country: "Ecuador",
  },
  {
    sr: 15632,
    city: "Salisbury",
    city_code: "SRW",
    airport_name: "Rowan County",
    country: "United States",
  },
  {
    sr: 15634,
    city: "Salisbury Ocean City",
    city_code: "SBY",
    airport_name: "Wicomico Regional",
    country: "United States",
  },
  {
    sr: 15636,
    city: "Salluit",
    city_code: "YZG",
    airport_name: "Salluit",
    country: "Canada",
  },
  {
    sr: 15640,
    city: "Salmon",
    city_code: "SMN",
    airport_name: "Salmon",
    country: "United States",
  },
  {
    sr: 15642,
    city: "Salmon Arm",
    city_code: "YSN",
    airport_name: "Salmon Arm",
    country: "Canada",
  },
  {
    sr: 15644,
    city: "Salt Cay",
    city_code: "SLX",
    airport_name: "Salt Cay",
    country: "Turks and Caicos Islands",
  },
  {
    sr: 15646,
    city: "Salt Lake City",
    city_code: "SLC",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 15648,
    city: "Salta",
    city_code: "SLA",
    airport_name: "Gen Belgrano",
    country: "Argentina",
  },
  {
    sr: 15650,
    city: "Saltillo",
    city_code: "SLW",
    airport_name: "Saltillo",
    country: "Mexico",
  },
  {
    sr: 15652,
    city: "Salto",
    city_code: "STY",
    airport_name: "Salto",
    country: "Uruguay",
  },
  {
    sr: 15654,
    city: "Salton City",
    city_code: "SAS",
    airport_name: "Salton City",
    country: "United States",
  },
  {
    sr: 15656,
    city: "Salvador",
    city_code: "SSA",
    airport_name: "Arpt Luis R Magalhaes",
    country: "Brazil",
  },
  {
    sr: 15658,
    city: "Salzburg",
    city_code: "SZG",
    airport_name: "W A Mozart",
    country: "Austria",
  },
  {
    sr: 15660,
    city: "Sam Neua",
    city_code: "NEU",
    airport_name: "Sam Neua",
    country: "Laos",
  },
  {
    sr: 15662,
    city: "Samara",
    city_code: "KUF",
    airport_name: "Samara",
    country: "Russia",
  },
  {
    sr: 15664,
    city: "Samarai Island",
    city_code: "SQT",
    airport_name: "China Straits Airstrip",
    country: "Papua New Guinea",
  },
  {
    sr: 15666,
    city: "Samarinda",
    city_code: "SRI",
    airport_name: "Samarinda",
    country: "Indonesia",
  },
  {
    sr: 15668,
    city: "Samarkand",
    city_code: "SKD",
    airport_name: "Samarkand",
    country: "Uzbekistan",
  },
  {
    sr: 15670,
    city: "Sambava",
    city_code: "SVB",
    airport_name: "Sambava",
    country: "Madagascar",
  },
  {
    sr: 15674,
    city: "Sambu",
    city_code: "SAX",
    airport_name: "Sambu",
    country: "Panama",
  },
  {
    sr: 15676,
    city: "Samburu",
    city_code: "UAS",
    airport_name: "Samburu",
    country: "Kenya",
  },
  {
    sr: 15678,
    city: "Samcheok",
    city_code: "SUK",
    airport_name: "Samcheok",
    country: "South Korea",
  },
  {
    sr: 15680,
    city: "Samos",
    city_code: "SMI",
    airport_name: "Samos",
    country: "Greece",
  },
  {
    sr: 15682,
    city: "Sampit",
    city_code: "SMQ",
    airport_name: "Sampit",
    country: "Indonesia",
  },
  {
    sr: 15684,
    city: "Samsun",
    city_code: "SZF",
    airport_name: "Carsamba",
    country: "Turkey",
  },
  {
    sr: 15686,
    city: "Samsun carsamba",
    city_code: "SSX",
    airport_name: "Samsun",
    country: "Turkey",
  },
  {
    sr: 15688,
    city: "San Andres Island",
    city_code: "ADZ",
    airport_name: "San Andres Island",
    country: "Colombia",
  },
  {
    sr: 15690,
    city: "San Andros",
    city_code: "SAQ",
    airport_name: "San Andros",
    country: "Bahamas",
  },
  {
    sr: 15692,
    city: "San Angelo",
    city_code: "GOF",
    airport_name: "Goodfellow AFB",
    country: "United States",
  },
  {
    sr: 15694,
    city: "San Angelo",
    city_code: "SJT",
    airport_name: "Mathis Fld",
    country: "United States",
  },
  {
    sr: 15696,
    city: "San Antonio",
    city_code: "SAT",
    airport_name: "San Antonio International",
    country: "United States",
  },
  {
    sr: 15698,
    city: "San Antonio",
    city_code: "SKF",
    airport_name: "Kelly AFB",
    country: "United States",
  },
  {
    sr: 15700,
    city: "San Antonio",
    city_code: "MDA",
    airport_name: "Martindale AAF",
    country: "United States",
  },
  {
    sr: 15702,
    city: "San Antonio",
    city_code: "RND",
    airport_name: "Randolph AFB",
    country: "United States",
  },
  {
    sr: 15704,
    city: "San Antonio",
    city_code: "SSF",
    airport_name: "Stinson Municipal",
    country: "United States",
  },
  {
    sr: 15708,
    city: "San Antonio",
    city_code: "SVZ",
    airport_name: "San Antonio",
    country: "Venezuela",
  },
  {
    sr: 15710,
    city: "San Antonio Oeste",
    city_code: "OES",
    airport_name: "San Antonio Oeste",
    country: "Argentina",
  },
  {
    sr: 15712,
    city: "San Bernardino",
    city_code: "SBD",
    airport_name: "Norton Afb",
    country: "United States",
  },
  {
    sr: 15714,
    city: "San Bernardino",
    city_code: "SBT",
    airport_name: "Tri City",
    country: "United States",
  },
  {
    sr: 15716,
    city: "San Blas",
    city_code: "NBL",
    airport_name: "San Blas",
    country: "Panama",
  },
  {
    sr: 15718,
    city: "San Borja",
    city_code: "SRJ",
    airport_name: "Capitan G Q Guardia",
    country: "Bolivia",
  },
  {
    sr: 15720,
    city: "San Carlos",
    city_code: "SQL",
    airport_name: "San Carlos",
    country: "United States",
  },
  {
    sr: 15722,
    city: "San Carlos",
    city_code: "NCR",
    airport_name: "San Carlos",
    country: "Nicaragua",
  },
  {
    sr: 15724,
    city: "San Carlos DeBariloche",
    city_code: "BRC",
    airport_name: "International",
    country: "Argentina",
  },
  {
    sr: 15726,
    city: "San Cristobal",
    city_code: "SCY",
    airport_name: "San Cristobal Airport",
    country: "Ecuador",
  },
  {
    sr: 15728,
    city: "San Cristobal",
    city_code: "SCI",
    airport_name: "San Cristobal",
    country: "Venezuela",
  },
  {
    sr: 15730,
    city: "San Diego",
    city_code: "SDM",
    airport_name: "Brown Field Municipal",
    country: "United States",
  },
  {
    sr: 15732,
    city: "San Diego",
    city_code: "CLD",
    airport_name: "Carlsbad",
    country: "United States",
  },
  {
    sr: 15734,
    city: "San Diego",
    city_code: "SEE",
    airport_name: "Gillespie Field",
    country: "United States",
  },
  {
    sr: 15736,
    city: "San Diego",
    city_code: "SAN",
    airport_name: "Lindberg Fld S Diego",
    country: "United States",
  },
  {
    sr: 15738,
    city: "San Diego",
    city_code: "NKX",
    airport_name: "Miramar MCAS",
    country: "United States",
  },
  {
    sr: 15742,
    city: "San Diego",
    city_code: "MYF",
    airport_name: "Montgomery Field",
    country: "United States",
  },
  {
    sr: 15744,
    city: "San Diego",
    city_code: "NZY",
    airport_name: "North Island Nas",
    country: "United States",
  },
  {
    sr: 15746,
    city: "San Domino Island",
    city_code: "TQR",
    airport_name: "San Domino Island",
    country: "Italy",
  },
  {
    sr: 15748,
    city: "San Esteban",
    city_code: "SET",
    airport_name: "San Esteban",
    country: "Honduras",
  },
  {
    sr: 15750,
    city: "San Felipe",
    city_code: "SSD",
    airport_name: "San Felipe",
    country: "Colombia",
  },
  {
    sr: 15752,
    city: "San Felipe",
    city_code: "SFH",
    airport_name: "San Felipe",
    country: "Mexico",
  },
  {
    sr: 15754,
    city: "San Felipe",
    city_code: "SNF",
    airport_name: "San Felipe",
    country: "Venezuela",
  },
  {
    sr: 15756,
    city: "San Felix",
    city_code: "SFX",
    airport_name: "San Felix",
    country: "Venezuela",
  },
  {
    sr: 15758,
    city: "San Fernando",
    city_code: "SFR",
    airport_name: "San Fernando",
    country: "Columbia",
  },
  {
    sr: 15760,
    city: "San Fernando",
    city_code: "FES",
    airport_name: "San Fernando",
    country: "Spain",
  },
  {
    sr: 15762,
    city: "San Fernando",
    city_code: "SFE",
    airport_name: "San Fernando",
    country: "Philippines",
  },
  {
    sr: 15764,
    city: "San Fernando De Apure",
    city_code: "SFD",
    airport_name: "Las Flecheras",
    country: "Venezuela",
  },
  {
    sr: 15766,
    city: "San Francisco",
    city_code: "JCC",
    airport_name: "China Basin Heliport",
    country: "United States",
  },
  {
    sr: 15768,
    city: "San Francisco",
    city_code: "EMB",
    airport_name: "Embarkadero",
    country: "United States",
  },
  {
    sr: 15770,
    city: "San Francisco",
    city_code: "SFO",
    airport_name: "San Francisco International",
    country: "United States",
  },
  {
    sr: 15772,
    city: "San Giovanni Rotondo",
    city_code: "GBN",
    airport_name: "San Giovanni Rotondo",
    country: "Italy",
  },
  {
    sr: 15776,
    city: "San Ignacio",
    city_code: "SQS",
    airport_name: "Matthew Spain",
    country: "Belize",
  },
  {
    sr: 15778,
    city: "San Ignacio",
    city_code: "SGM",
    airport_name: "San Ignacio",
    country: "Mexico",
  },
  {
    sr: 15780,
    city: "San Ignacio De M",
    city_code: "SNM",
    airport_name: "San Ignacio De M",
    country: "Bolivia",
  },
  {
    sr: 15782,
    city: "San Ignacio De Velasco",
    city_code: "SNG",
    airport_name: "San Ignacio De Velasco",
    country: "Bolivia",
  },
  {
    sr: 15784,
    city: "San Javier",
    city_code: "SJV",
    airport_name: "San Javier",
    country: "Bolivia",
  },
  {
    sr: 15786,
    city: "San Joaquin",
    city_code: "SJB",
    airport_name: "San Joaquin",
    country: "Bolivia",
  },
  {
    sr: 15788,
    city: "San Jose",
    city_code: "SJS",
    airport_name: "San Jose",
    country: "Bolivia",
  },
  {
    sr: 15790,
    city: "San Jose",
    city_code: "SJC",
    airport_name: "Mineta San Jose International Airport",
    country: "United States",
  },
  {
    sr: 15792,
    city: "San Jose",
    city_code: "RHV",
    airport_name: "Reid Hillview",
    country: "United States",
  },
  {
    sr: 15794,
    city: "San Jose",
    city_code: "SJO",
    airport_name: "Juan Santamaria Intl",
    country: "Costa Rica",
  },
  {
    sr: 15796,
    city: "San Jose",
    city_code: "SJI",
    airport_name: "Mcguire Fld",
    country: "Philippines",
  },
  {
    sr: 15798,
    city: "San Jose Cabo",
    city_code: "SJD",
    airport_name: "Los Cabos",
    country: "Mexico",
  },
  {
    sr: 15800,
    city: "San Jose Del Gua",
    city_code: "SJE",
    airport_name: "San Jose Del Gua",
    country: "Colombia",
  },
  {
    sr: 15802,
    city: "San Juan",
    city_code: "WSJ",
    airport_name: "San Juan SPB",
    country: "United States",
  },
  {
    sr: 15804,
    city: "San Juan",
    city_code: "SJM",
    airport_name: "San Juan",
    country: "Dominican Republic",
  },
  {
    sr: 15806,
    city: "San Juan",
    city_code: "SJA",
    airport_name: "San Juan",
    country: "Peru",
  },
  {
    sr: 15810,
    city: "San Juan",
    city_code: "SIG",
    airport_name: "Isla Grande",
    country: "Puerto Rico",
  },
  {
    sr: 15812,
    city: "San Juan",
    city_code: "SJU",
    airport_name: "Luis Munoz Marin Intl",
    country: "Puerto Rico",
  },
  {
    sr: 15814,
    city: "San Juan",
    city_code: "UAQ",
    airport_name: "San Juan",
    country: "Argentina",
  },
  {
    sr: 15816,
    city: "San Juan Aposento",
    city_code: "APE",
    airport_name: "San Juan Aposento",
    country: "Peru",
  },
  {
    sr: 15818,
    city: "San Juan D Ur",
    city_code: "SJR",
    airport_name: "San Juan D Ur",
    country: "Colombia",
  },
  {
    sr: 15820,
    city: "San Juan Del Cesar",
    city_code: "SJH",
    airport_name: "San Juan Del Cesar",
    country: "Colombia",
  },
  {
    sr: 15822,
    city: "San Julian",
    city_code: "SNJ",
    airport_name: "San Julian",
    country: "Cuba",
  },
  {
    sr: 15824,
    city: "San Julian",
    city_code: "ULA",
    airport_name: "San Julian",
    country: "Argentina",
  },
  {
    sr: 15826,
    city: "San Luis",
    city_code: "LUQ",
    airport_name: "San Luis",
    country: "Argentina",
  },
  {
    sr: 15828,
    city: "San Luis De Pale",
    city_code: "SQE",
    airport_name: "San Luis De Pale",
    country: "Colombia",
  },
  {
    sr: 15830,
    city: "San Luis Obispo",
    city_code: "SBP",
    airport_name: "County Airport",
    country: "United States",
  },
  {
    sr: 15832,
    city: "San Luis Potosi",
    city_code: "SLP",
    airport_name: "San Luis Potosi",
    country: "Mexico",
  },
  {
    sr: 15834,
    city: "San Luis Rio Colorado",
    city_code: "UAC",
    airport_name: "San Luis Rio Colorado",
    country: "Mexico",
  },
  {
    sr: 15836,
    city: "San Marcos",
    city_code: "SRS",
    airport_name: "Santa Fe De Bogota",
    country: "Colombia",
  },
  {
    sr: 15838,
    city: "San Marino",
    city_code: "SAI",
    airport_name: "San Marino",
    country: "San Marino",
  },
  {
    sr: 15840,
    city: "San Martin DeLos Andes",
    city_code: "CPC",
    airport_name: "Chapelco",
    country: "Argentina",
  },
  {
    sr: 15844,
    city: "San Matias",
    city_code: "MQK",
    airport_name: "San Matias",
    country: "Bolivia",
  },
  {
    sr: 15846,
    city: "San Miguel",
    city_code: "SYL",
    airport_name: "Roberts AAF",
    country: "United States",
  },
  {
    sr: 15848,
    city: "San Miguel",
    city_code: "NMG",
    airport_name: "San Miguel",
    country: "Panama",
  },
  {
    sr: 15850,
    city: "Sevilla",
    city_code: "SPO",
    airport_name: "San Pablo",
    country: "Spain",
  },
  {
    sr: 15852,
    city: "San Pedro",
    city_code: "SPR",
    airport_name: "San Pedro",
    country: "Belize",
  },
  {
    sr: 15854,
    city: "San Pedro",
    city_code: "SPQ",
    airport_name: "Catalina SPB",
    country: "United States",
  },
  {
    sr: 15856,
    city: "San Pedro",
    city_code: "SPY",
    airport_name: "San Pedro",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 15858,
    city: "San Pedro de Alcantara",
    city_code: "ZRC",
    airport_name: "Bus Station",
    country: "Spain",
  },
  {
    sr: 15860,
    city: "San Pedro Jagua",
    city_code: "SJG",
    airport_name: "San Pedro Jagua",
    country: "Colombia",
  },
  {
    sr: 15862,
    city: "San Pedro Sula",
    city_code: "SAP",
    airport_name: "Ramon Villeda Morales",
    country: "Honduras",
  },
  {
    sr: 15864,
    city: "San Pedro Uraba",
    city_code: "NPU",
    airport_name: "San Pedro Uraba",
    country: "Colombia",
  },
  {
    sr: 15866,
    city: "San Quintin",
    city_code: "SNQ",
    airport_name: "San Quintin",
    country: "Mexico",
  },
  {
    sr: 15868,
    city: "San Rafael",
    city_code: "SRF",
    airport_name: "Hamilton Field",
    country: "United States",
  },
  {
    sr: 15870,
    city: "San Rafael",
    city_code: "JSG",
    airport_name: "San Rafael H P",
    country: "United States",
  },
  {
    sr: 15872,
    city: "San Rafael",
    city_code: "AFA",
    airport_name: "San Rafael",
    country: "Argentina",
  },
  {
    sr: 15874,
    city: "San Ramon",
    city_code: "SRD",
    airport_name: "San Ramon",
    country: "Bolivia",
  },
  {
    sr: 15878,
    city: "San Salvador",
    city_code: "ZSA",
    airport_name: "San Salvador",
    country: "Brazil",
  },
  {
    sr: 15880,
    city: "San Salvador",
    city_code: "SAL",
    airport_name: "Comalapa International",
    country: "El Salvador",
  },
  {
    sr: 15882,
    city: "San Salvador De",
    city_code: "SVV",
    airport_name: "San Salvador De",
    country: "Venezuela",
  },
  {
    sr: 15884,
    city: "San Sebas De La Gomera",
    city_code: "GMZ",
    airport_name: "La Gomera",
    country: "Spain",
  },
  {
    sr: 15886,
    city: "San Sebastian",
    city_code: "EAS",
    airport_name: "San Sebastian",
    country: "Spain",
  },
  {
    sr: 15888,
    city: "San Tome",
    city_code: "SOM",
    airport_name: "El Tigre",
    country: "Venezuela",
  },
  {
    sr: 15890,
    city: "San Vicente",
    city_code: "SVI",
    airport_name: "San Vicente",
    country: "Colombia",
  },
  {
    sr: 15892,
    city: "San Vito",
    city_code: "TOO",
    airport_name: "San Vito",
    country: "Costa Rica",
  },
  {
    sr: 15894,
    city: "Sanana",
    city_code: "SQN",
    airport_name: "Sanana",
    country: "Indonesia",
  },
  {
    sr: 15896,
    city: "Sanandaj",
    city_code: "SDG",
    airport_name: "Sanandaj",
    country: "Iran",
  },
  {
    sr: 15898,
    city: "Sancti Spiritus",
    city_code: "USS",
    airport_name: "Sancti Spiritus",
    country: "Cuba",
  },
  {
    sr: 15900,
    city: "Sand Point",
    city_code: "SDP",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 15902,
    city: "Sandakan",
    city_code: "SDK",
    airport_name: "Sandakan",
    country: "Malaysia",
  },
  {
    sr: 15904,
    city: "Sandane",
    city_code: "SDN",
    airport_name: "Sandane",
    country: "Norway",
  },
  {
    sr: 15906,
    city: "Sanday",
    city_code: "NDY",
    airport_name: "Sanday",
    country: "United Kingdom",
  },
  {
    sr: 15908,
    city: "Sandcreek",
    city_code: "SDC",
    airport_name: "Sandcreek",
    country: "Guyana",
  },
  {
    sr: 15912,
    city: "Sandefjord",
    city_code: "ZYS",
    airport_name: "Sandefjord Railway Svc",
    country: "Norway",
  },
  {
    sr: 15914,
    city: "Sandnes",
    city_code: "XKC",
    airport_name: "Sandnes Rail St",
    country: "Norway",
  },
  {
    sr: 15916,
    city: "Sandnessjoen",
    city_code: "SSJ",
    airport_name: "Stokka",
    country: "Norway",
  },
  {
    sr: 15918,
    city: "Sandringham",
    city_code: "SRM",
    airport_name: "Sandringham",
    country: "Australia",
  },
  {
    sr: 15920,
    city: "Sandspit",
    city_code: "YZP",
    airport_name: "Sandspit",
    country: "Canada",
  },
  {
    sr: 15922,
    city: "Sandstone",
    city_code: "NDS",
    airport_name: "Sandstone",
    country: "Australia",
  },
  {
    sr: 15924,
    city: "Sandusky",
    city_code: "SKY",
    airport_name: "Griffing Sandusky",
    country: "United States",
  },
  {
    sr: 15926,
    city: "Sandwip",
    city_code: "SDW",
    airport_name: "Sandwip",
    country: "Bangladesh",
  },
  {
    sr: 15928,
    city: "Sandy Lake",
    city_code: "ZSJ",
    airport_name: "Sandy Lake",
    country: "Canada",
  },
  {
    sr: 15930,
    city: "Sandy River",
    city_code: "KSR",
    airport_name: "Federal No 1",
    country: "United States",
  },
  {
    sr: 15932,
    city: "Sanfebagar",
    city_code: "FEB",
    airport_name: "Sanfebagar",
    country: "Nepal",
  },
  {
    sr: 15934,
    city: "Sanford",
    city_code: "SFM",
    airport_name: "Sanford",
    country: "United States",
  },
  {
    sr: 15936,
    city: "Sanga Sanga",
    city_code: "SGS",
    airport_name: "Sanga Sanga",
    country: "Philippines",
  },
  {
    sr: 15938,
    city: "Sangapi",
    city_code: "SGK",
    airport_name: "Sangapi",
    country: "Papua New Guinea",
  },
  {
    sr: 15940,
    city: "Sanggata",
    city_code: "SGQ",
    airport_name: "Sanggata",
    country: "Indonesia",
  },
  {
    sr: 15942,
    city: "Sangir",
    city_code: "SAE",
    airport_name: "Sangir",
    country: "Indonesia",
  },
  {
    sr: 15946,
    city: "Sangley Point",
    city_code: "NSP",
    airport_name: "NAF",
    country: "Philippines",
  },
  {
    sr: 15948,
    city: "Sanikiluaq",
    city_code: "YSK",
    airport_name: "Sanikiluaq",
    country: "Canada",
  },
  {
    sr: 15950,
    city: "Sanliurfa",
    city_code: "SFQ",
    airport_name: "Sanliurfa",
    country: "Turkey",
  },
  {
    sr: 15952,
    city: "Sans Souci",
    city_code: "YSI",
    airport_name: "Sans Souci",
    country: "Canada",
  },
  {
    sr: 15954,
    city: "Santa Ana",
    city_code: "SBL",
    airport_name: "Yacuma",
    country: "Bolivia",
  },
  {
    sr: 15956,
    city: "Santa Ana",
    city_code: "JOC",
    airport_name: "Centerport Heliport",
    country: "United States",
  },
  {
    sr: 15958,
    city: "Santa Ana",
    city_code: "DNT",
    airport_name: "Downtown Heliport",
    country: "United States",
  },
  {
    sr: 15960,
    city: "Santa Ana",
    city_code: "SNA",
    airport_name: "John Wayne",
    country: "United States",
  },
  {
    sr: 15962,
    city: "Santa Ana",
    city_code: "SQB",
    airport_name: "Santa Ana",
    country: "Colombia",
  },
  {
    sr: 15964,
    city: "Santa Ana",
    city_code: "NNB",
    airport_name: "Santa Ana",
    country: "Solomon Islands",
  },
  {
    sr: 15966,
    city: "Santa Barbara",
    city_code: "SBA",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 15968,
    city: "Santa Barbara",
    city_code: "SZN",
    airport_name: "Santa Cruz Island",
    country: "United States",
  },
  {
    sr: 15970,
    city: "Santa Barbara Ba",
    city_code: "SBB",
    airport_name: "Santa Barbara Ba",
    country: "Venezuela",
  },
  {
    sr: 15972,
    city: "Santa Barbara Ed",
    city_code: "STB",
    airport_name: "L Delicias",
    country: "Venezuela",
  },
  {
    sr: 15974,
    city: "Santa Carolina",
    city_code: "NTC",
    airport_name: "Santa Carolina",
    country: "Mozambique",
  },
  {
    sr: 15976,
    city: "Santa Catalina",
    city_code: "SCA",
    airport_name: "Santa Catalina",
    country: "Colombia",
  },
  {
    sr: 15980,
    city: "Santa Clara",
    city_code: "SNU",
    airport_name: "Santa Clara",
    country: "Cuba",
  },
  {
    sr: 15982,
    city: "Santa Cruz",
    city_code: "SRZ",
    airport_name: "El Trompillo",
    country: "Bolivia",
  },
  {
    sr: 15984,
    city: "Assuncion",
    city_code: "VVI",
    airport_name: "Viru Viru Intl",
    country: "Bolivia",
  },
  {
    sr: 15986,
    city: "Santa Cruz",
    city_code: "STU",
    airport_name: "Santa Cruz",
    country: "Belize",
  },
  {
    sr: 15988,
    city: "Santa Cruz",
    city_code: "SRU",
    airport_name: "Skypark",
    country: "United States",
  },
  {
    sr: 15990,
    city: "Santa Cruz",
    city_code: "SZC",
    airport_name: "Guanacaste",
    country: "Costa Rica",
  },
  {
    sr: 15992,
    city: "Santa Cruz",
    city_code: "SNZ",
    airport_name: "Santa Cruz",
    country: "Brazil",
  },
  {
    sr: 15994,
    city: "Santa Cruz",
    city_code: "RZA",
    airport_name: "Santa Cruz",
    country: "Argentina",
  },
  {
    sr: 15996,
    city: "Santa Cruz De La Palma",
    city_code: "SPC",
    airport_name: "La Palma",
    country: "Spain",
  },
  {
    sr: 15998,
    city: "Santa Cruz Do Sul",
    city_code: "CSU",
    airport_name: "Santa Cruz Do Sul",
    country: "Brazil",
  },
  {
    sr: 16000,
    city: "Santa Cruz Is",
    city_code: "SCZ",
    airport_name: "Santa Cruz Is",
    country: "Solomon Islands",
  },
  {
    sr: 16002,
    city: "Santa Elena",
    city_code: "SNV",
    airport_name: "Santa Elena",
    country: "Venezuela",
  },
  {
    sr: 16004,
    city: "Santa Fe",
    city_code: "SAF",
    airport_name: "Santa Fe",
    country: "United States",
  },
  {
    sr: 16006,
    city: "Santa Fe",
    city_code: "ZSH",
    airport_name: "Bus Station",
    country: "United States",
  },
  {
    sr: 16008,
    city: "Santa Fe",
    city_code: "SFW",
    airport_name: "Santa Fe",
    country: "Panama",
  },
  {
    sr: 16010,
    city: "Santa Fe",
    city_code: "SFN",
    airport_name: "Santa Fe",
    country: "Argentina",
  },
  {
    sr: 16014,
    city: "Santa Fe Do Sul",
    city_code: "SFV",
    airport_name: "Santa Fe Do Sul",
    country: "Brazil",
  },
  {
    sr: 16016,
    city: "Santa Isabel do Morro",
    city_code: "IDO",
    airport_name: "Rio",
    country: "Brazil",
  },
  {
    sr: 16018,
    city: "Santa Katarina",
    city_code: "SKV",
    airport_name: "Mount Sinai",
    country: "Egypt",
  },
  {
    sr: 16020,
    city: "Santa Maria",
    city_code: "SMX",
    airport_name: "Public",
    country: "United States",
  },
  {
    sr: 16022,
    city: "Santa Maria",
    city_code: "SMC",
    airport_name: "Santa Maria",
    country: "Colombia",
  },
  {
    sr: 16024,
    city: "Santa Maria",
    city_code: "SMG",
    airport_name: "Santa Maria",
    country: "Peru",
  },
  {
    sr: 16026,
    city: "Santa Maria",
    city_code: "SMA",
    airport_name: "Vila Do Porto",
    country: "Portugal",
  },
  {
    sr: 16028,
    city: "Santa Maria",
    city_code: "RIA",
    airport_name: "Base Aerea",
    country: "Brazil",
  },
  {
    sr: 16030,
    city: "Santa Marta",
    city_code: "SMR",
    airport_name: "Simon Bolivar",
    country: "Colombia",
  },
  {
    sr: 16032,
    city: "Santa Monica",
    city_code: "SMO",
    airport_name: "Santa Monica",
    country: "United States",
  },
  {
    sr: 16034,
    city: "Santa Paula",
    city_code: "SZP",
    airport_name: "Santa Paula",
    country: "United States",
  },
  {
    sr: 16036,
    city: "Santa Rosa",
    city_code: "SRB",
    airport_name: "Santa Rosa",
    country: "Bolivia",
  },
  {
    sr: 16038,
    city: "Santa Rosa",
    city_code: "STS",
    airport_name: "Sonoma County",
    country: "United States",
  },
  {
    sr: 16040,
    city: "Santa Rosa",
    city_code: "RSA",
    airport_name: "Santa Rosa",
    country: "Argentina",
  },
  {
    sr: 16042,
    city: "Santa Rosa",
    city_code: "SRA",
    airport_name: "Santa Rosa",
    country: "Brazil",
  },
  {
    sr: 16044,
    city: "Santa Rosa Copan",
    city_code: "SDH",
    airport_name: "Santa Rosa Copan",
    country: "Honduras",
  },
  {
    sr: 16048,
    city: "Santa Rosalia",
    city_code: "SSL",
    airport_name: "Santa Rosalia",
    country: "Colombia",
  },
  {
    sr: 16050,
    city: "Santa Rosalia",
    city_code: "SRL",
    airport_name: "Santa Rosalia",
    country: "Mexico",
  },
  {
    sr: 16052,
    city: "Santa Teresita",
    city_code: "SST",
    airport_name: "Santa Teresita",
    country: "Argentina",
  },
  {
    sr: 16054,
    city: "Santa Terezinha",
    city_code: "STZ",
    airport_name: "Confresa",
    country: "Brazil",
  },
  {
    sr: 16056,
    city: "Oakland",
    city_code: "CTQ",
    airport_name: "Oakland",
    country: "United States",
  },
  {
    sr: 16058,
    city: "Santa Ynez",
    city_code: "SQA",
    airport_name: "Santa Ynez",
    country: "United States",
  },
  {
    sr: 16060,
    city: "Santana Do Araguaia",
    city_code: "CMP",
    airport_name: "Campo Alegre",
    country: "Brazil",
  },
  {
    sr: 16062,
    city: "Santana Ramos",
    city_code: "SRO",
    airport_name: "Santana Ramos",
    country: "Colombia",
  },
  {
    sr: 16064,
    city: "Santander",
    city_code: "SDR",
    airport_name: "Santander",
    country: "Spain",
  },
  {
    sr: 16066,
    city: "Santarem",
    city_code: "STM",
    airport_name: "Eduardo Gomes",
    country: "Brazil",
  },
  {
    sr: 16068,
    city: "Santiago",
    city_code: "SCL",
    airport_name: "Arturo Merino Benitez",
    country: "Chile",
  },
  {
    sr: 16070,
    city: "Santiago",
    city_code: "ULC",
    airport_name: "Los Cerrillos",
    country: "Chile",
  },
  {
    sr: 16072,
    city: "Santiago",
    city_code: "SCU",
    airport_name: "Antonio Maceo",
    country: "Cuba",
  },
  {
    sr: 16074,
    city: "Santiago",
    city_code: "STI",
    airport_name: "Municipal",
    country: "Dominican Republic",
  },
  {
    sr: 16076,
    city: "Santiago",
    city_code: "SYP",
    airport_name: "Santiago",
    country: "Panama",
  },
  {
    sr: 16078,
    city: "Santiago De Compostela",
    city_code: "SCQ",
    airport_name: "Santiago De Compostela",
    country: "Spain",
  },
  {
    sr: 16082,
    city: "Santiago Del Estero",
    city_code: "SDE",
    airport_name: "Santiago Del Estero",
    country: "Argentina",
  },
  {
    sr: 16084,
    city: "Santo Angelo",
    city_code: "GEL",
    airport_name: "Sepe Tiaraju",
    country: "Brazil",
  },
  {
    sr: 16086,
    city: "Santo Antao",
    city_code: "NTO",
    airport_name: "Santo Antao",
    country: "Cape Verde",
  },
  {
    sr: 16088,
    city: "Santo Domingo",
    city_code: "HEX",
    airport_name: "Herrera",
    country: "Dominican Republic",
  },
  {
    sr: 16090,
    city: "Santo Domingo",
    city_code: "SDQ",
    airport_name: "Las Americas",
    country: "Dominican Republic",
  },
  {
    sr: 16092,
    city: "Santo Domingo",
    city_code: "STD",
    airport_name: "Mayo Guerrero",
    country: "Venezuela",
  },
  {
    sr: 16094,
    city: "Santos",
    city_code: "SSZ",
    airport_name: "Santos",
    country: "Brazil",
  },
  {
    sr: 16096,
    city: "Sanya",
    city_code: "SYX",
    airport_name: "Sanya",
    country: "China",
  },
  {
    sr: 16098,
    city: "Sao Felix Do Araguaia",
    city_code: "SXO",
    airport_name: "Sao Felix Do Araguaia",
    country: "Brazil",
  },
  {
    sr: 16100,
    city: "Sao Felix Do Xingu",
    city_code: "SXX",
    airport_name: "Sao Felix Do Xingu",
    country: "Brazil",
  },
  {
    sr: 16102,
    city: "Sao Filipe",
    city_code: "SFL",
    airport_name: "Sao Filipe",
    country: "Cape Verde",
  },
  {
    sr: 16104,
    city: "Sao Gabriel",
    city_code: "SJL",
    airport_name: "Da Cachoeira",
    country: "Brazil",
  },
  {
    sr: 16106,
    city: "Sao Jorge Island",
    city_code: "SJZ",
    airport_name: "Sao Jorge Island",
    country: "Portugal",
  },
  {
    sr: 16108,
    city: "Sao Jose Do Rio Preto",
    city_code: "SJP",
    airport_name: "Sao Jose Do Rio Preto",
    country: "Brazil",
  },
  {
    sr: 16110,
    city: "Sao Jose Dos Campos",
    city_code: "SJK",
    airport_name: "Sao Jose Dos Campos",
    country: "Brazil",
  },
  {
    sr: 16112,
    city: "Sao Lourenco",
    city_code: "SSO",
    airport_name: "Sao Lourenco",
    country: "Brazil",
  },
  {
    sr: 16116,
    city: "Sao Lourenco Do Sul",
    city_code: "SQY",
    airport_name: "Sao Lourenco Do Sul",
    country: "Brazil",
  },
  {
    sr: 16118,
    city: "Salvador",
    city_code: "SLZ",
    airport_name: "Mal Cunha Machado",
    country: "Brazil",
  },
  {
    sr: 16120,
    city: "Sao Mateus",
    city_code: "SBJ",
    airport_name: "Sao Mateus",
    country: "Brazil",
  },
  {
    sr: 16122,
    city: "Sao Miguel Araguaia",
    city_code: "SQM",
    airport_name: "Sao Miguel Araguaia",
    country: "Brazil",
  },
  {
    sr: 16124,
    city: "Sao Miguel Do Oeste",
    city_code: "SQX",
    airport_name: "Sao Miguel Do Oeste",
    country: "Brazil",
  },
  {
    sr: 16126,
    city: "Sao Nicolau",
    city_code: "SNE",
    airport_name: "Preguica",
    country: "Cape Verde",
  },
  {
    sr: 16128,
    city: "Sao Paulo",
    city_code: "CGH",
    airport_name: "Congonhas",
    country: "Brazil",
  },
  {
    sr: 16130,
    city: "Sao Paulo",
    city_code: "GRU",
    airport_name: "Guarulhos Intl",
    country: "Brazil",
  },
  {
    sr: 16132,
    city: "Sao Paulo",
    city_code: "VCP",
    airport_name: "Viracopos",
    country: "Brazil",
  },
  {
    sr: 16134,
    city: "Sao Tome Is",
    city_code: "TMS",
    airport_name: "Sao Tome Is",
    country: "Sao Tome and Principe",
  },
  {
    sr: 16136,
    city: "Sao Vicente",
    city_code: "VXE",
    airport_name: "San Pedro",
    country: "Cape Verde",
  },
  {
    sr: 16138,
    city: "Sapmanga",
    city_code: "SMH",
    airport_name: "Sapmanga",
    country: "Papua New Guinea",
  },
  {
    sr: 16140,
    city: "Saposoa",
    city_code: "SQU",
    airport_name: "Saposoa",
    country: "Peru",
  },
  {
    sr: 16142,
    city: "Sapporo",
    city_code: "CTS",
    airport_name: "Chitose",
    country: "Japan",
  },
  {
    sr: 16144,
    city: "Sapporo",
    city_code: "OKD",
    airport_name: "Okadama",
    country: "Japan",
  },
  {
    sr: 16146,
    city: "Saqani",
    city_code: "AQS",
    airport_name: "Saqani",
    country: "Fiji",
  },
  {
    sr: 16150,
    city: "Saqqaq",
    city_code: "QUP",
    airport_name: "Heliport",
    country: "Greenland",
  },
  {
    sr: 16152,
    city: "Sara",
    city_code: "SSR",
    airport_name: "Sara",
    country: "Vanuatu",
  },
  {
    sr: 16154,
    city: "Sarajevo",
    city_code: "SJJ",
    airport_name: "Butmir",
    country: "Bosnia and Herzegovina",
  },
  {
    sr: 16156,
    city: "Sarakhs",
    city_code: "CKT",
    airport_name: "Sarakhs",
    country: "Iran",
  },
  {
    sr: 16158,
    city: "Saranac Lake",
    city_code: "SLK",
    airport_name: "Adirondack",
    country: "United States",
  },
  {
    sr: 16160,
    city: "Saransk",
    city_code: "SKX",
    airport_name: "Saransk",
    country: "Russia",
  },
  {
    sr: 16162,
    city: "Sarasota",
    city_code: "SRQ",
    airport_name: "Sarasota Bradenton International Airport",
    country: "United States",
  },
  {
    sr: 16164,
    city: "Saratoga",
    city_code: "SAA",
    airport_name: "Shively Field",
    country: "United States",
  },
  {
    sr: 16166,
    city: "Saratov",
    city_code: "RTW",
    airport_name: "Saratov",
    country: "Russia",
  },
  {
    sr: 16168,
    city: "Saravane",
    city_code: "VNA",
    airport_name: "Saravane",
    country: "Laos",
  },
  {
    sr: 16170,
    city: "Saravena",
    city_code: "RVE",
    airport_name: "Los Colonizadores",
    country: "Colombia",
  },
  {
    sr: 16172,
    city: "Sardeh Band",
    city_code: "SBF",
    airport_name: "Sardeh Band",
    country: "Afghanistan",
  },
  {
    sr: 16174,
    city: "Sargodha",
    city_code: "BHW",
    airport_name: "Bhagatanwala Apt",
    country: "Pakistan",
  },
  {
    sr: 16176,
    city: "Sargodha",
    city_code: "SGI",
    airport_name: "Sargodha Apt",
    country: "Pakistan",
  },
  {
    sr: 16178,
    city: "Sarh",
    city_code: "SRH",
    airport_name: "Sarh",
    country: "Chad",
  },
  {
    sr: 16180,
    city: "Sarichef",
    city_code: "WSF",
    airport_name: "Sarichef",
    country: "United States",
  },
  {
    sr: 16184,
    city: "Sarmi",
    city_code: "ZRM",
    airport_name: "Sarmi",
    country: "Indonesia",
  },
  {
    sr: 16186,
    city: "Sarnia",
    city_code: "YZR",
    airport_name: "Sarnia",
    country: "Canada",
  },
  {
    sr: 16188,
    city: "Sarpsborg",
    city_code: "XKQ",
    airport_name: "Sarpsborg Rail St",
    country: "Norway",
  },
  {
    sr: 16190,
    city: "Sartaneja",
    city_code: "SJX",
    airport_name: "Sartaneja",
    country: "Belize",
  },
  {
    sr: 16192,
    city: "Sary",
    city_code: "SRY",
    airport_name: "Dashte Naz",
    country: "Iran",
  },
  {
    sr: 16194,
    city: "Sasereme",
    city_code: "TDS",
    airport_name: "Sasereme",
    country: "Papua New Guinea",
  },
  {
    sr: 16196,
    city: "Saskatoon",
    city_code: "YXE",
    airport_name: "J G Diefenbaker International Airport",
    country: "Canada",
  },
  {
    sr: 16198,
    city: "Sassandra",
    city_code: "ZSS",
    airport_name: "Sassandra",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 16200,
    city: "Sasstown",
    city_code: "SAZ",
    airport_name: "Sasstown",
    country: "Liberia",
  },
  {
    sr: 16202,
    city: "Satna",
    city_code: "TNI",
    airport_name: "Satna",
    country: "India",
  },
  {
    sr: 16204,
    city: "Satu Mare",
    city_code: "SUJ",
    airport_name: "Satu Mare",
    country: "Romania",
  },
  {
    sr: 16206,
    city: "Satwag",
    city_code: "SWG",
    airport_name: "Satwag",
    country: "Papua New Guinea",
  },
  {
    sr: 16208,
    city: "Saudarkrokur",
    city_code: "SAK",
    airport_name: "Saudarkrokur",
    country: "Iceland",
  },
  {
    sr: 16210,
    city: "Saul",
    city_code: "XAU",
    airport_name: "Saul",
    country: "French Guiana",
  },
  {
    sr: 16212,
    city: "Sault Ste Marie",
    city_code: "CIU",
    airport_name: "Chippewa County",
    country: "United States",
  },
  {
    sr: 16214,
    city: "Sault Ste Marie",
    city_code: "INR",
    airport_name: "Kincheloe AFB",
    country: "United States",
  },
  {
    sr: 16218,
    city: "Sault Ste Marie",
    city_code: "YAM",
    airport_name: "Sault Ste Marie",
    country: "Canada",
  },
  {
    sr: 16220,
    city: "Saumlaki",
    city_code: "SXK",
    airport_name: "Saumlaki",
    country: "Indonesia",
  },
  {
    sr: 16222,
    city: "Sauren",
    city_code: "SXW",
    airport_name: "Sauren",
    country: "Papua New Guinea",
  },
  {
    sr: 16224,
    city: "Saurimo",
    city_code: "VHC",
    airport_name: "Saurimo",
    country: "Angola",
  },
  {
    sr: 16226,
    city: "Sausalito",
    city_code: "JMC",
    airport_name: "Marin County",
    country: "United States",
  },
  {
    sr: 16228,
    city: "Savannah",
    city_code: "SVN",
    airport_name: "Hunter AAF",
    country: "United States",
  },
  {
    sr: 16230,
    city: "Savannah",
    city_code: "SAV",
    airport_name: "Savannah Hilton Head",
    country: "United States",
  },
  {
    sr: 16232,
    city: "Savannakhet",
    city_code: "ZVK",
    airport_name: "Savannakhet",
    country: "Laos",
  },
  {
    sr: 16234,
    city: "Save",
    city_code: "SVF",
    airport_name: "Save",
    country: "Benin",
  },
  {
    sr: 16236,
    city: "Savo",
    city_code: "SVY",
    airport_name: "Savo",
    country: "Solomon Islands",
  },
  {
    sr: 16238,
    city: "Savonlinna",
    city_code: "SVL",
    airport_name: "Savonlinna",
    country: "Finland",
  },
  {
    sr: 16240,
    city: "Savoonga",
    city_code: "SVA",
    airport_name: "Savoonga",
    country: "United States",
  },
  {
    sr: 16242,
    city: "Savusavu",
    city_code: "SVU",
    airport_name: "Savusavu",
    country: "Fiji",
  },
  {
    sr: 16244,
    city: "Savuti",
    city_code: "SVT",
    airport_name: "Savuti",
    country: "Botswana",
  },
  {
    sr: 16246,
    city: "Sawan",
    city_code: "RZS",
    airport_name: "Sawan",
    country: "Pakistan",
  },
  {
    sr: 16248,
    city: "Sawu",
    city_code: "SAU",
    airport_name: "Sawu",
    country: "Indonesia",
  },
  {
    sr: 16252,
    city: "Sayaboury",
    city_code: "ZBY",
    airport_name: "Sayaboury",
    country: "Laos",
  },
  {
    sr: 16254,
    city: "Scammon Bay",
    city_code: "SCM",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 16256,
    city: "Scampton",
    city_code: "SQZ",
    airport_name: "RAF Station",
    country: "United Kingdom",
  },
  {
    sr: 16258,
    city: "Schaumburg",
    city_code: "JMH",
    airport_name: "Marriott Heliport",
    country: "United States",
  },
  {
    sr: 16260,
    city: "Schefferville",
    city_code: "YKL",
    airport_name: "Schefferville",
    country: "Canada",
  },
  {
    sr: 16262,
    city: "Schenectady",
    city_code: "SCH",
    airport_name: "County",
    country: "United States",
  },
  {
    sr: 16264,
    city: "Schenectady",
    city_code: "ZTD",
    airport_name: "Schenectady Rail",
    country: "United States",
  },
  {
    sr: 16266,
    city: "Schleswig Jagel",
    city_code: "WBG",
    airport_name: "Schleswig Jagel",
    country: "Germany",
  },
  {
    sr: 16268,
    city: "Schwerin",
    city_code: "SZW",
    airport_name: "Parchim Airport",
    country: "Germany",
  },
  {
    sr: 16270,
    city: "Scone",
    city_code: "NSO",
    airport_name: "Scone",
    country: "Australia",
  },
  {
    sr: 16272,
    city: "Scottsbluff",
    city_code: "BFF",
    airport_name: "Scotts Bluff County",
    country: "United States",
  },
  {
    sr: 16274,
    city: "Scranton",
    city_code: "SCR",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 16276,
    city: "Scribner",
    city_code: "SCB",
    airport_name: "State",
    country: "United States",
  },
  {
    sr: 16278,
    city: "Scusciuban",
    city_code: "CMS",
    airport_name: "Scusciuban",
    country: "Somalia",
  },
  {
    sr: 16280,
    city: "Seal Bay",
    city_code: "SYB",
    airport_name: "Seal Bay",
    country: "United States",
  },
  {
    sr: 16282,
    city: "Searcy",
    city_code: "SRC",
    airport_name: "Searcy",
    country: "United States",
  },
  {
    sr: 16286,
    city: "Seattle",
    city_code: "BFI",
    airport_name: "Boeing Fld Intl",
    country: "United States",
  },
  {
    sr: 16288,
    city: "Seattle",
    city_code: "LKE",
    airport_name: "Lake Union SPB",
    country: "United States",
  },
  {
    sr: 16290,
    city: "Seattle",
    city_code: "SEA",
    airport_name: "Seattle tacoma International",
    country: "United States",
  },
  {
    sr: 16292,
    city: "Sebba",
    city_code: "XSE",
    airport_name: "Sebba",
    country: "Burkina Faso",
  },
  {
    sr: 16294,
    city: "Sebha",
    city_code: "SEB",
    airport_name: "Sebha",
    country: "Libya",
  },
  {
    sr: 16296,
    city: "Sebring",
    city_code: "SEF",
    airport_name: "Air Terminal",
    country: "United States",
  },
  {
    sr: 16298,
    city: "Sechelt",
    city_code: "YHS",
    airport_name: "Sechelt",
    country: "Canada",
  },
  {
    sr: 16300,
    city: "Secunda",
    city_code: "ZEC",
    airport_name: "Secunda",
    country: "South Africa",
  },
  {
    sr: 16302,
    city: "Sedalia",
    city_code: "DMO",
    airport_name: "Sedalia",
    country: "United States",
  },
  {
    sr: 16304,
    city: "Sedona",
    city_code: "SDX",
    airport_name: "Sedona",
    country: "United States",
  },
  {
    sr: 16306,
    city: "Sege",
    city_code: "EGM",
    airport_name: "Sege",
    country: "Solomon Islands",
  },
  {
    sr: 16308,
    city: "Segou",
    city_code: "SZU",
    airport_name: "Segou",
    country: "Mali",
  },
  {
    sr: 16310,
    city: "Seguela",
    city_code: "SEO",
    airport_name: "Seguela",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 16312,
    city: "Sehonghong",
    city_code: "SHK",
    airport_name: "Sehonghong",
    country: "Lesotho",
  },
  {
    sr: 16314,
    city: "Sehulea",
    city_code: "SXH",
    airport_name: "Sehulea",
    country: "Papua New Guinea",
  },
  {
    sr: 16316,
    city: "Sehwen Sharif",
    city_code: "SYW",
    airport_name: "Sehwen Sharif",
    country: "Pakistan",
  },
  {
    sr: 16320,
    city: "Seinajoki",
    city_code: "SJY",
    airport_name: "Ilmajoki",
    country: "Finland",
  },
  {
    sr: 16322,
    city: "Seiyun",
    city_code: "GXF",
    airport_name: "Seiyun",
    country: "Yemen",
  },
  {
    sr: 16324,
    city: "Sekakes",
    city_code: "SKQ",
    airport_name: "Sekakes",
    country: "Lesotho",
  },
  {
    sr: 16326,
    city: "Selawik",
    city_code: "WLK",
    airport_name: "Selawik",
    country: "United States",
  },
  {
    sr: 16328,
    city: "Selbang",
    city_code: "SBC",
    airport_name: "Selbang",
    country: "Papua New Guinea",
  },
  {
    sr: 16330,
    city: "Seldovia",
    city_code: "SOV",
    airport_name: "Seldovia",
    country: "United States",
  },
  {
    sr: 16332,
    city: "Selebi Phikwe",
    city_code: "PKW",
    airport_name: "Selebi Phikwe",
    country: "Botswana",
  },
  {
    sr: 16334,
    city: "Selibaby",
    city_code: "SEY",
    airport_name: "Selibaby",
    country: "Mauritania",
  },
  {
    sr: 16336,
    city: "Selinsgrove",
    city_code: "SEG",
    airport_name: "Penn Valley",
    country: "United States",
  },
  {
    sr: 16338,
    city: "Selje",
    city_code: "QFK",
    airport_name: "Harbour",
    country: "Norway",
  },
  {
    sr: 16340,
    city: "Selma",
    city_code: "SEM",
    airport_name: "Craig AFB",
    country: "United States",
  },
  {
    sr: 16342,
    city: "Selma",
    city_code: "SES",
    airport_name: "Selfield",
    country: "United States",
  },
  {
    sr: 16344,
    city: "Semarang",
    city_code: "SRG",
    airport_name: "Achmad Yani",
    country: "Indonesia",
  },
  {
    sr: 16346,
    city: "Sematan",
    city_code: "BSE",
    airport_name: "Sematan",
    country: "Malaysia",
  },
  {
    sr: 16348,
    city: "Sembach",
    city_code: "SEX",
    airport_name: "Sembach",
    country: "Germany",
  },
  {
    sr: 16350,
    city: "Semera",
    city_code: "SZE",
    airport_name: "Semera Airport",
    country: "Ethiopia",
  },
  {
    sr: 16354,
    city: "Semipalatinsk",
    city_code: "PLX",
    airport_name: "Semipalatinsk",
    country: "Kazakhstan",
  },
  {
    sr: 16356,
    city: "Semongkong",
    city_code: "SOK",
    airport_name: "Semongkong",
    country: "Lesotho",
  },
  {
    sr: 16358,
    city: "Semporna",
    city_code: "SMM",
    airport_name: "Semporna",
    country: "Malaysia",
  },
  {
    sr: 16360,
    city: "Sena Madureira",
    city_code: "ZMD",
    airport_name: "Sena Madureira",
    country: "Brazil",
  },
  {
    sr: 16362,
    city: "Senanga",
    city_code: "SXG",
    airport_name: "Senanga",
    country: "Zambia",
  },
  {
    sr: 16364,
    city: "Sendai",
    city_code: "SDJ",
    airport_name: "Sendai",
    country: "Japan",
  },
  {
    sr: 16366,
    city: "Senggeh",
    city_code: "SEH",
    airport_name: "Senggeh",
    country: "Indonesia",
  },
  {
    sr: 16368,
    city: "Senggo",
    city_code: "ZEG",
    airport_name: "Senggo",
    country: "Indonesia",
  },
  {
    sr: 16370,
    city: "Senhor Do Bonfim",
    city_code: "SEI",
    airport_name: "Senhor Do Bonfim",
    country: "Brazil",
  },
  {
    sr: 16372,
    city: "Senipah",
    city_code: "SZH",
    airport_name: "Senipah",
    country: "Indonesia",
  },
  {
    sr: 16374,
    city: "Seno",
    city_code: "SND",
    airport_name: "Seno",
    country: "Laos",
  },
  {
    sr: 16376,
    city: "Seu De Urgel",
    city_code: "LEU",
    airport_name: "Aeroport De La Seu",
    country: "Spain",
  },
  {
    sr: 16378,
    city: "Seoul",
    city_code: "ICN",
    airport_name: "Seoul  Incheon",
    country: "South Korea",
  },
  {
    sr: 16380,
    city: "Sepik Plains",
    city_code: "SPV",
    airport_name: "Sepik Plains",
    country: "Papua New Guinea",
  },
  {
    sr: 16382,
    city: "Sept Iles",
    city_code: "YZV",
    airport_name: "Sept Iles",
    country: "Canada",
  },
  {
    sr: 16384,
    city: "Sepulot",
    city_code: "SPE",
    airport_name: "Sepulot",
    country: "Malaysia",
  },
  {
    sr: 16388,
    city: "Sequim",
    city_code: "SQV",
    airport_name: "Sequim Valley Arpt",
    country: "United States",
  },
  {
    sr: 16390,
    city: "Seronera",
    city_code: "SEU",
    airport_name: "Seronera",
    country: "Tanzania",
  },
  {
    sr: 16392,
    city: "Serra Norte",
    city_code: "RRN",
    airport_name: "Serra Norte",
    country: "Brazil",
  },
  {
    sr: 16394,
    city: "Serra Pelada",
    city_code: "RSG",
    airport_name: "Serra Pelada",
    country: "Brazil",
  },
  {
    sr: 16396,
    city: "Serre Chevalier",
    city_code: "SEC",
    airport_name: "Serre Chevalier",
    country: "France",
  },
  {
    sr: 16398,
    city: "Sert",
    city_code: "SRX",
    airport_name: "Sert",
    country: "Libya",
  },
  {
    sr: 16400,
    city: "Serui",
    city_code: "ZRI",
    airport_name: "Serui",
    country: "Indonesia",
  },
  {
    sr: 16402,
    city: "Sesheke",
    city_code: "SJQ",
    airport_name: "Sesheke",
    country: "Zambia",
  },
  {
    sr: 16404,
    city: "Seshutes",
    city_code: "SHZ",
    airport_name: "Seshutes",
    country: "Lesotho",
  },
  {
    sr: 16406,
    city: "Sesriem",
    city_code: "SZM",
    airport_name: "Sesriem",
    country: "Namibia",
  },
  {
    sr: 16408,
    city: "Setif",
    city_code: "QSF",
    airport_name: "Setif",
    country: "Algeria",
  },
  {
    sr: 16410,
    city: "Sette Cama",
    city_code: "ZKM",
    airport_name: "Sette Cama",
    country: "Gabon",
  },
  {
    sr: 16412,
    city: "Sevastopol",
    city_code: "UKS",
    airport_name: "Belbek",
    country: "Ukraine",
  },
  {
    sr: 16414,
    city: "Severodoneck",
    city_code: "SEV",
    airport_name: "Severodoneck",
    country: "Ukraine",
  },
  {
    sr: 16416,
    city: "Sevilla",
    city_code: "SVQ",
    airport_name: "San Pablo",
    country: "Spain",
  },
  {
    sr: 16418,
    city: "Sewanee",
    city_code: "UOS",
    airport_name: "Franklin County",
    country: "United States",
  },
  {
    sr: 16422,
    city: "Seward",
    city_code: "SWD",
    airport_name: "Seward",
    country: "United States",
  },
  {
    sr: 16424,
    city: "Seydisfjordur",
    city_code: "SEJ",
    airport_name: "Seydisfjordur",
    country: "Iceland",
  },
  {
    sr: 16426,
    city: "Seymour",
    city_code: "SER",
    airport_name: "Freeman Municipal",
    country: "United States",
  },
  {
    sr: 16428,
    city: "Sfax",
    city_code: "SFA",
    airport_name: "Sfax El Maou",
    country: "Tunisia",
  },
  {
    sr: 16430,
    city: "Shafter",
    city_code: "MIT",
    airport_name: "Kern County",
    country: "United States",
  },
  {
    sr: 16432,
    city: "Shageluk",
    city_code: "SHX",
    airport_name: "Shageluk",
    country: "United States",
  },
  {
    sr: 16434,
    city: "Shahre Kord",
    city_code: "CQD",
    airport_name: "Shahre Kord",
    country: "Iran",
  },
  {
    sr: 16436,
    city: "Shakawe",
    city_code: "SWX",
    airport_name: "Shakawe",
    country: "Botswana",
  },
  {
    sr: 16438,
    city: "Shakiso",
    city_code: "SKR",
    airport_name: "Shakiso",
    country: "Ethiopia",
  },
  {
    sr: 16440,
    city: "Shaktoolik",
    city_code: "SKK",
    airport_name: "Shaktoolik",
    country: "United States",
  },
  {
    sr: 16442,
    city: "Shamattawa",
    city_code: "ZTM",
    airport_name: "Shamattawa",
    country: "Canada",
  },
  {
    sr: 16444,
    city: "Shamshernagar",
    city_code: "ZHM",
    airport_name: "Shamshernagar",
    country: "Bangladesh",
  },
  {
    sr: 16446,
    city: "Shanghai",
    city_code: "SHA",
    airport_name: "Hongqiao",
    country: "China",
  },
  {
    sr: 16448,
    city: "Shanghai",
    city_code: "PVG",
    airport_name: "Pu Dong",
    country: "China",
  },
  {
    sr: 16450,
    city: "Shangri la",
    city_code: "NRI",
    airport_name: "Shangri la",
    country: "United States",
  },
  {
    sr: 16452,
    city: "Shanhaiguan",
    city_code: "SHF",
    airport_name: "Shanhaiguan",
    country: "China",
  },
  {
    sr: 16456,
    city: "Shannon",
    city_code: "SNN",
    airport_name: "Shannon",
    country: "Ireland",
  },
  {
    sr: 16458,
    city: "Shanshan",
    city_code: "SXJ",
    airport_name: "Shanshan",
    country: "China",
  },
  {
    sr: 16460,
    city: "Shantou",
    city_code: "SWA",
    airport_name: "Shantou",
    country: "China",
  },
  {
    sr: 16462,
    city: "Shanzhou",
    city_code: "SZO",
    airport_name: "Shanzhou",
    country: "China",
  },
  {
    sr: 16464,
    city: "Shaoguan",
    city_code: "HSC",
    airport_name: "Shaoguan",
    country: "China",
  },
  {
    sr: 16466,
    city: "Sharjah",
    city_code: "SHJ",
    airport_name: "Sharjah",
    country: "United Arab Emirates",
  },
  {
    sr: 16468,
    city: "Shark Elowainat",
    city_code: "GSQ",
    airport_name: "Shark Elowainat",
    country: "Egypt",
  },
  {
    sr: 16470,
    city: "Sharm El Sheikh",
    city_code: "SSH",
    airport_name: "Ophira",
    country: "Egypt",
  },
  {
    sr: 16472,
    city: "Sharurah",
    city_code: "SHW",
    airport_name: "Sharurah",
    country: "Saudi Arabia",
  },
  {
    sr: 16474,
    city: "Shashi",
    city_code: "SHS",
    airport_name: "Shashi",
    country: "China",
  },
  {
    sr: 16476,
    city: "Shaw River",
    city_code: "SWB",
    airport_name: "Shaw River",
    country: "Australia",
  },
  {
    sr: 16478,
    city: "Shawnee",
    city_code: "SNL",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 16480,
    city: "Shay Gap",
    city_code: "SGP",
    airport_name: "Shay Gap",
    country: "Australia",
  },
  {
    sr: 16482,
    city: "She Kou",
    city_code: "ZCU",
    airport_name: "She Kou Ferry",
    country: "China",
  },
  {
    sr: 16484,
    city: "Shearwater",
    city_code: "YSX",
    airport_name: "Shearwater",
    country: "Canada",
  },
  {
    sr: 16486,
    city: "Sheboygan",
    city_code: "SBM",
    airport_name: "Memorial",
    country: "United States",
  },
  {
    sr: 16490,
    city: "Sheep Mountain",
    city_code: "SMU",
    airport_name: "Sheep Mountain",
    country: "United States",
  },
  {
    sr: 16492,
    city: "Sheffield",
    city_code: "MSL",
    airport_name: "Sheffield",
    country: "United States",
  },
  {
    sr: 16494,
    city: "Sheffield",
    city_code: "SZD",
    airport_name: "Sheffield City Airport",
    country: "United Kingdom",
  },
  {
    sr: 16496,
    city: "Sheghnan",
    city_code: "SGA",
    airport_name: "Sheghnan",
    country: "Afghanistan",
  },
  {
    sr: 16498,
    city: "Shehdi",
    city_code: "SQJ",
    airport_name: "Shehdi",
    country: "Ethiopia",
  },
  {
    sr: 16500,
    city: "Shek Mum",
    city_code: "QDM",
    airport_name: "Shek Mum",
    country: "China",
  },
  {
    sr: 16502,
    city: "Shekou",
    city_code: "ZYK",
    airport_name: "Shekou Port",
    country: "China",
  },
  {
    sr: 16504,
    city: "Shelby",
    city_code: "SBX",
    airport_name: "Shelby",
    country: "United States",
  },
  {
    sr: 16506,
    city: "Shelbyville",
    city_code: "SYI",
    airport_name: "Bomar Field",
    country: "United States",
  },
  {
    sr: 16508,
    city: "Sheldon Point",
    city_code: "SXP",
    airport_name: "Sheldon SPB",
    country: "United States",
  },
  {
    sr: 16510,
    city: "Shelton",
    city_code: "SHN",
    airport_name: "Sanderson Field",
    country: "United States",
  },
  {
    sr: 16512,
    city: "Shemya",
    city_code: "SYA",
    airport_name: "Shemya AFB",
    country: "United States",
  },
  {
    sr: 16514,
    city: "Shenyang",
    city_code: "SHE",
    airport_name: "Shenyang",
    country: "China",
  },
  {
    sr: 16516,
    city: "Shenzhen",
    city_code: "SZX",
    airport_name: "Shenzhen",
    country: "China",
  },
  {
    sr: 16518,
    city: "Shepparton",
    city_code: "SHT",
    airport_name: "Shepparton",
    country: "Australia",
  },
  {
    sr: 16520,
    city: "Sherbrooke",
    city_code: "YSC",
    airport_name: "Sherbrooke",
    country: "Canada",
  },
  {
    sr: 16524,
    city: "Sheridan",
    city_code: "SHR",
    airport_name: "Sheridan",
    country: "United States",
  },
  {
    sr: 16526,
    city: "Sherman Denison",
    city_code: "PNX",
    airport_name: "Grayson County",
    country: "United States",
  },
  {
    sr: 16528,
    city: "Shetland Islands",
    city_code: "LWK",
    airport_name: "Lerwick Tingwall",
    country: "United Kingdom",
  },
  {
    sr: 16530,
    city: "Shetland Islands",
    city_code: "SCS",
    airport_name: "Scatsta",
    country: "United Kingdom",
  },
  {
    sr: 16532,
    city: "Shetland Islands",
    city_code: "LSI",
    airport_name: "Sumburgh",
    country: "United Kingdom",
  },
  {
    sr: 16534,
    city: "Shijiazhuang",
    city_code: "SJW",
    airport_name: "Daguocun",
    country: "China",
  },
  {
    sr: 16536,
    city: "Shikarpur",
    city_code: "SWV",
    airport_name: "Shikarpur",
    country: "Pakistan",
  },
  {
    sr: 16538,
    city: "Shillavo",
    city_code: "HIL",
    airport_name: "Shillavo",
    country: "Ethiopia",
  },
  {
    sr: 16540,
    city: "Shillong",
    city_code: "SHL",
    airport_name: "Shillong",
    country: "India",
  },
  {
    sr: 16542,
    city: "Shilo",
    city_code: "YLO",
    airport_name: "Shilo",
    country: "Canada",
  },
  {
    sr: 16544,
    city: "Shimkent",
    city_code: "CIT",
    airport_name: "Shimkent",
    country: "Kazakhstan",
  },
  {
    sr: 16546,
    city: "Shimojishima",
    city_code: "SHI",
    airport_name: "Shimojishima",
    country: "Japan",
  },
  {
    sr: 16548,
    city: "Shinyanga",
    city_code: "SHY",
    airport_name: "Shinyanga",
    country: "Tanzania",
  },
  {
    sr: 16550,
    city: "Shirahama",
    city_code: "SHM",
    airport_name: "Shirahama",
    country: "Japan",
  },
  {
    sr: 16552,
    city: "Shiraz",
    city_code: "SYZ",
    airport_name: "Shiraz",
    country: "Iran",
  },
  {
    sr: 16554,
    city: "Shiringayoc",
    city_code: "SYC",
    airport_name: "Shiringayoc",
    country: "Peru",
  },
  {
    sr: 16558,
    city: "Shirley",
    city_code: "WSH",
    airport_name: "Brookhaven",
    country: "United States",
  },
  {
    sr: 16560,
    city: "Shishmaref",
    city_code: "SHH",
    airport_name: "Shishmaref",
    country: "United States",
  },
  {
    sr: 16562,
    city: "Shoal Cove",
    city_code: "HCB",
    airport_name: "Shoal Cove",
    country: "United States",
  },
  {
    sr: 16564,
    city: "Sholapur",
    city_code: "SSE",
    airport_name: "Sholapur",
    country: "India",
  },
  {
    sr: 16566,
    city: "Shonai",
    city_code: "SYO",
    airport_name: "Shonai",
    country: "Japan",
  },
  {
    sr: 16568,
    city: "Shoreham By Sea",
    city_code: "ESH",
    airport_name: "Shoreham",
    country: "United Kingdom",
  },
  {
    sr: 16570,
    city: "Los Angless",
    city_code: "SOW",
    airport_name: "Show Low",
    country: "United States",
  },
  {
    sr: 16572,
    city: "Shreveport",
    city_code: "BAD",
    airport_name: "Barksdale AFB",
    country: "United States",
  },
  {
    sr: 16574,
    city: "Shreveport",
    city_code: "DTN",
    airport_name: "Downtown",
    country: "United States",
  },
  {
    sr: 16576,
    city: "Shreveport",
    city_code: "SHV",
    airport_name: "Regional",
    country: "United States",
  },
  {
    sr: 16578,
    city: "Shunde",
    city_code: "ZAS",
    airport_name: "Shunde Ferry",
    country: "China",
  },
  {
    sr: 16580,
    city: "Shungnak",
    city_code: "SHG",
    airport_name: "Shungnak",
    country: "United States",
  },
  {
    sr: 16582,
    city: "Shute Harbour",
    city_code: "JHQ",
    airport_name: "Shute Harbour H P",
    country: "Australia",
  },
  {
    sr: 16584,
    city: "Sialkot",
    city_code: "SKT",
    airport_name: "Sialkot International Airport",
    country: "Pakistan",
  },
  {
    sr: 16586,
    city: "Sialum",
    city_code: "SXA",
    airport_name: "Sialum",
    country: "Papua New Guinea",
  },
  {
    sr: 16588,
    city: "Siasi",
    city_code: "SSV",
    airport_name: "Siasi",
    country: "Philippines",
  },
  {
    sr: 16592,
    city: "Siassi",
    city_code: "SSS",
    airport_name: "Siassi",
    country: "Papua New Guinea",
  },
  {
    sr: 16594,
    city: "Siauliai",
    city_code: "SQQ",
    airport_name: "Siauliai",
    country: "Lithuania",
  },
  {
    sr: 16596,
    city: "Sibi",
    city_code: "SBQ",
    airport_name: "Sibi",
    country: "Pakistan",
  },
  {
    sr: 16598,
    city: "Sibisa",
    city_code: "SIW",
    airport_name: "Sibisa",
    country: "Indonesia",
  },
  {
    sr: 16600,
    city: "Sibiti",
    city_code: "SIB",
    airport_name: "Sibiti",
    country: "Congo",
  },
  {
    sr: 16602,
    city: "Sibiu",
    city_code: "SBZ",
    airport_name: "Sibiu",
    country: "Romania",
  },
  {
    sr: 16604,
    city: "Sibu",
    city_code: "SBW",
    airport_name: "Sibu",
    country: "Malaysia",
  },
  {
    sr: 16606,
    city: "Sicogon Island",
    city_code: "ICO",
    airport_name: "Sicogon Island",
    country: "Philippines",
  },
  {
    sr: 16608,
    city: "Sidi Barani",
    city_code: "SQK",
    airport_name: "Sidi Barani",
    country: "Egypt",
  },
  {
    sr: 16610,
    city: "Sidi Belabbes",
    city_code: "BFW",
    airport_name: "Sidi Belabbes",
    country: "Algeria",
  },
  {
    sr: 16612,
    city: "Sidi Ifni",
    city_code: "SII",
    airport_name: "Sidi Ifni",
    country: "Morocco",
  },
  {
    sr: 16614,
    city: "Sidney",
    city_code: "SDY",
    airport_name: "Richland Municipal",
    country: "United States",
  },
  {
    sr: 16616,
    city: "Sidney",
    city_code: "SNY",
    airport_name: "Sidney",
    country: "United States",
  },
  {
    sr: 16618,
    city: "Sidney",
    city_code: "SXY",
    airport_name: "Sidney",
    country: "United States",
  },
  {
    sr: 16620,
    city: "Siegen",
    city_code: "SGE",
    airport_name: "Siegerland Airport",
    country: "Germany",
  },
  {
    sr: 16622,
    city: "Siem Reap",
    city_code: "REP",
    airport_name: "Siem Reap",
    country: "Cambodia",
  },
  {
    sr: 16626,
    city: "Siena",
    city_code: "SAY",
    airport_name: "Siena",
    country: "Italy",
  },
  {
    sr: 16628,
    city: "Sierra Grande",
    city_code: "SGV",
    airport_name: "Sierra Grande",
    country: "Argentina",
  },
  {
    sr: 16630,
    city: "Sierra Leone",
    city_code: "SRK",
    airport_name: "Sierra Leone",
    country: "Sierra Leone",
  },
  {
    sr: 16632,
    city: "Sierra Vista",
    city_code: "FHU",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 16634,
    city: "Sigiriya",
    city_code: "GIU",
    airport_name: "Sigiriya Slaf Base",
    country: "Sri Lanka",
  },
  {
    sr: 16636,
    city: "Siglufjordur",
    city_code: "SIJ",
    airport_name: "Siglufjordur",
    country: "Iceland",
  },
  {
    sr: 16638,
    city: "Tokat",
    city_code: "NSY",
    airport_name: "Sigonella NAF",
    country: "Italy",
  },
  {
    sr: 16640,
    city: "Siguanea",
    city_code: "SZJ",
    airport_name: "Siguanea",
    country: "Cuba",
  },
  {
    sr: 16642,
    city: "Siguiri",
    city_code: "GII",
    airport_name: "Siguiri",
    country: "Guinea",
  },
  {
    sr: 16644,
    city: "Sihanoukville",
    city_code: "KOS",
    airport_name: "Sihanoukville",
    country: "Cambodia",
  },
  {
    sr: 16646,
    city: "Siirt",
    city_code: "SXZ",
    airport_name: "Siirt",
    country: "Turkey",
  },
  {
    sr: 16648,
    city: "Sikasso",
    city_code: "KSS",
    airport_name: "Sikasso",
    country: "Mali",
  },
  {
    sr: 16650,
    city: "Sikeston",
    city_code: "SIK",
    airport_name: "Memorial",
    country: "United States",
  },
  {
    sr: 16652,
    city: "Sila",
    city_code: "SIL",
    airport_name: "Sila",
    country: "Papua New Guinea",
  },
  {
    sr: 16654,
    city: "Silchar",
    city_code: "IXS",
    airport_name: "Kumbhirgram",
    country: "India",
  },
  {
    sr: 16656,
    city: "Silgadi Doti",
    city_code: "SIH",
    airport_name: "Silgadi Doti",
    country: "Nepal",
  },
  {
    sr: 16660,
    city: "Silistra",
    city_code: "SLS",
    airport_name: "Silistra",
    country: "Bulgaria",
  },
  {
    sr: 16662,
    city: "Siloam Springs",
    city_code: "SLG",
    airport_name: "Smith Field",
    country: "United States",
  },
  {
    sr: 16664,
    city: "Silur",
    city_code: "SWR",
    airport_name: "Silur",
    country: "Papua New Guinea",
  },
  {
    sr: 16666,
    city: "Silva Bay",
    city_code: "SYF",
    airport_name: "Silva Bay",
    country: "Canada",
  },
  {
    sr: 16668,
    city: "Silver City",
    city_code: "SVC",
    airport_name: "Grant County",
    country: "United States",
  },
  {
    sr: 16670,
    city: "Silver Creek",
    city_code: "SVK",
    airport_name: "Silver Creek",
    country: "Belize",
  },
  {
    sr: 16672,
    city: "Silver Plains",
    city_code: "SSP",
    airport_name: "Silver Plains",
    country: "Australia",
  },
  {
    sr: 16674,
    city: "Sim",
    city_code: "SMJ",
    airport_name: "Sim",
    country: "Papua New Guinea",
  },
  {
    sr: 16676,
    city: "Simanggang",
    city_code: "SGG",
    airport_name: "Simanggang",
    country: "Malaysia",
  },
  {
    sr: 16678,
    city: "Simao",
    city_code: "SYM",
    airport_name: "Simao",
    country: "China",
  },
  {
    sr: 16680,
    city: "Simara",
    city_code: "SIF",
    airport_name: "Simara",
    country: "Nepal",
  },
  {
    sr: 16682,
    city: "Simbai",
    city_code: "SIM",
    airport_name: "Simbai",
    country: "Papua New Guinea",
  },
  {
    sr: 16684,
    city: "Simberi Island",
    city_code: "NIS",
    airport_name: "Simberi",
    country: "Papua New Guinea",
  },
  {
    sr: 16686,
    city: "Simenti",
    city_code: "SMY",
    airport_name: "Simenti",
    country: "Senegal",
  },
  {
    sr: 16688,
    city: "Trapani",
    city_code: "SIP",
    airport_name: "Simferopol",
    country: "Ukraine",
  },
  {
    sr: 16690,
    city: "Simikot",
    city_code: "IMK",
    airport_name: "Simikot",
    country: "Nepal",
  },
  {
    sr: 16694,
    city: "Simla",
    city_code: "SLV",
    airport_name: "Simla",
    country: "India",
  },
  {
    sr: 16696,
    city: "Sinak",
    city_code: "NKD",
    airport_name: "Sinak",
    country: "Indonesia",
  },
  {
    sr: 16698,
    city: "Sindal",
    city_code: "CNL",
    airport_name: "Sindal",
    country: "Denmark",
  },
  {
    sr: 16700,
    city: "Sines",
    city_code: "SIE",
    airport_name: "Sines",
    country: "Portugal",
  },
  {
    sr: 16702,
    city: "Singapore",
    city_code: "SIN",
    airport_name: "Changi",
    country: "Singapore",
  },
  {
    sr: 16704,
    city: "Singapore",
    city_code: "QPG",
    airport_name: "Paya Lebar",
    country: "Singapore",
  },
  {
    sr: 16706,
    city: "Singapore",
    city_code: "XSP",
    airport_name: "Seletar",
    country: "Singapore",
  },
  {
    sr: 16708,
    city: "Singaua",
    city_code: "SGB",
    airport_name: "Singaua",
    country: "Papua New Guinea",
  },
  {
    sr: 16710,
    city: "Singkep",
    city_code: "SIQ",
    airport_name: "Dabo",
    country: "Indonesia",
  },
  {
    sr: 16712,
    city: "Singleton",
    city_code: "SIX",
    airport_name: "Singleton",
    country: "Australia",
  },
  {
    sr: 16714,
    city: "Sinoe",
    city_code: "XSA",
    airport_name: "AFC",
    country: "Liberia",
  },
  {
    sr: 16716,
    city: "Sinoe",
    city_code: "SNI",
    airport_name: "R E Murray",
    country: "Liberia",
  },
  {
    sr: 16718,
    city: "Sinop",
    city_code: "OPS",
    airport_name: "Sinop",
    country: "Brazil",
  },
  {
    sr: 16720,
    city: "Sinop",
    city_code: "SQD",
    airport_name: "Sinop",
    country: "Turkey",
  },
  {
    sr: 16722,
    city: "Sinop",
    city_code: "SIC",
    airport_name: "Sinop Airport",
    country: "Turkey",
  },
  {
    sr: 16724,
    city: "Sintang",
    city_code: "SQG",
    airport_name: "Sintang",
    country: "Indonesia",
  },
  {
    sr: 16728,
    city: "Siocon",
    city_code: "XSO",
    airport_name: "Siocon",
    country: "Philippines",
  },
  {
    sr: 16730,
    city: "Sion",
    city_code: "SIR",
    airport_name: "Sion",
    country: "Switzerland",
  },
  {
    sr: 16732,
    city: "Sioux City",
    city_code: "SUX",
    airport_name: "Sioux Gateway",
    country: "United States",
  },
  {
    sr: 16734,
    city: "Sioux Falls",
    city_code: "FSD",
    airport_name: "Regional Jo Foss Fld",
    country: "United States",
  },
  {
    sr: 16736,
    city: "Sioux Lookout",
    city_code: "YXL",
    airport_name: "Sioux Lookout",
    country: "Canada",
  },
  {
    sr: 16738,
    city: "Sipitang",
    city_code: "SPT",
    airport_name: "Sipitang",
    country: "Malaysia",
  },
  {
    sr: 16740,
    city: "Sipora",
    city_code: "RKO",
    airport_name: "Sipora",
    country: "Indonesia",
  },
  {
    sr: 16742,
    city: "Sirajganj",
    city_code: "SAJ",
    airport_name: "Sirajganj",
    country: "Bangladesh",
  },
  {
    sr: 16744,
    city: "Sirjan",
    city_code: "SYJ",
    airport_name: "Sirjan",
    country: "Iran",
  },
  {
    sr: 16746,
    city: "Sirri Island",
    city_code: "SXI",
    airport_name: "Sirri Island",
    country: "Iran",
  },
  {
    sr: 16748,
    city: "Sishen",
    city_code: "SIS",
    airport_name: "Sishen",
    country: "South Africa",
  },
  {
    sr: 16750,
    city: "Sisimiut",
    city_code: "JHS",
    airport_name: "Sisimiut",
    country: "Greenland",
  },
  {
    sr: 16752,
    city: "Sissano",
    city_code: "SIZ",
    airport_name: "Sissano",
    country: "Papua New Guinea",
  },
  {
    sr: 16754,
    city: "Sitia",
    city_code: "JSH",
    airport_name: "Sitia",
    country: "Greece",
  },
  {
    sr: 16756,
    city: "Sitiawan",
    city_code: "SWY",
    airport_name: "Sitiawan",
    country: "Malaysia",
  },
  {
    sr: 16758,
    city: "Sitka",
    city_code: "SIT",
    airport_name: "Sitka",
    country: "United States",
  },
  {
    sr: 16762,
    city: "Sitkinak Island",
    city_code: "SKJ",
    airport_name: "Sitkinak Cgs",
    country: "United States",
  },
  {
    sr: 16764,
    city: "Sittwe",
    city_code: "AKY",
    airport_name: "Civil",
    country: "Myanmar",
  },
  {
    sr: 16766,
    city: "Siuna",
    city_code: "SIU",
    airport_name: "Siuna",
    country: "Nicaragua",
  },
  {
    sr: 16768,
    city: "Sivas",
    city_code: "VAS",
    airport_name: "Sivas",
    country: "Turkey",
  },
  {
    sr: 16770,
    city: "Siwa",
    city_code: "SEW",
    airport_name: "Siwa",
    country: "Egypt",
  },
  {
    sr: 16772,
    city: "Siwea",
    city_code: "SWE",
    airport_name: "Siwea",
    country: "Papua New Guinea",
  },
  {
    sr: 16774,
    city: "Skagen Z7",
    city_code: "QJV",
    airport_name: "Limousine Service",
    country: "Denmark",
  },
  {
    sr: 16776,
    city: "Skagway",
    city_code: "SGY",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 16778,
    city: "Skardu",
    city_code: "KDU",
    airport_name: "Skardu",
    country: "Pakistan",
  },
  {
    sr: 16780,
    city: "Skeldon",
    city_code: "SKM",
    airport_name: "Skeldon",
    country: "Guyana",
  },
  {
    sr: 16782,
    city: "Skelleftea",
    city_code: "SFT",
    airport_name: "Skelleftea",
    country: "Sweden",
  },
  {
    sr: 16784,
    city: "Ski",
    city_code: "YVS",
    airport_name: "Railway",
    country: "Norway",
  },
  {
    sr: 16786,
    city: "Skiathos",
    city_code: "JSI",
    airport_name: "Skiathos",
    country: "Greece",
  },
  {
    sr: 16788,
    city: "Skien",
    city_code: "SKE",
    airport_name: "Skien",
    country: "Norway",
  },
  {
    sr: 16790,
    city: "Skikda",
    city_code: "SKI",
    airport_name: "Skikda",
    country: "Algeria",
  },
  {
    sr: 16792,
    city: "Skiros",
    city_code: "SKU",
    airport_name: "Skiros",
    country: "Greece",
  },
  {
    sr: 16796,
    city: "Skive",
    city_code: "SQW",
    airport_name: "Skive Airport",
    country: "Denmark",
  },
  {
    sr: 16798,
    city: "Skopje",
    city_code: "SKP",
    airport_name: "Petrovec",
    country: "Macedonia",
  },
  {
    sr: 16800,
    city: "Skoppum",
    city_code: "XUR",
    airport_name: "Skoppum Railway",
    country: "Norway",
  },
  {
    sr: 16802,
    city: "Skovde",
    city_code: "KVB",
    airport_name: "Skovde",
    country: "Sweden",
  },
  {
    sr: 16804,
    city: "Skukuza",
    city_code: "SZK",
    airport_name: "Skukuza",
    country: "South Africa",
  },
  {
    sr: 16806,
    city: "Skwentna",
    city_code: "SKW",
    airport_name: "Intermediate",
    country: "United States",
  },
  {
    sr: 16808,
    city: "Slate Island",
    city_code: "YSS",
    airport_name: "Slate Island",
    country: "Canada",
  },
  {
    sr: 16810,
    city: "Slave Lake",
    city_code: "YZH",
    airport_name: "Slave Lake",
    country: "Canada",
  },
  {
    sr: 16812,
    city: "Sleetmute",
    city_code: "SLQ",
    airport_name: "Sleetmute",
    country: "United States",
  },
  {
    sr: 16814,
    city: "Sliac",
    city_code: "SLD",
    airport_name: "Sliac",
    country: "Slovak Republic",
  },
  {
    sr: 16816,
    city: "Sligo",
    city_code: "ZSL",
    airport_name: "Bus Station",
    country: "Ireland",
  },
  {
    sr: 16818,
    city: "Sligo",
    city_code: "SXL",
    airport_name: "Collooney",
    country: "Ireland",
  },
  {
    sr: 16820,
    city: "Slupsk",
    city_code: "OSP",
    airport_name: "Redzikowo",
    country: "Poland",
  },
  {
    sr: 16822,
    city: "Smara",
    city_code: "SMW",
    airport_name: "Smara",
    country: "Morocco",
  },
  {
    sr: 16824,
    city: "Smith Cove",
    city_code: "SCJ",
    airport_name: "Smith Cove",
    country: "United States",
  },
  {
    sr: 16826,
    city: "Smith Falls",
    city_code: "YSH",
    airport_name: "Smith Falls",
    country: "Canada",
  },
  {
    sr: 16830,
    city: "Smith Point",
    city_code: "SHU",
    airport_name: "Smith Point",
    country: "Australia",
  },
  {
    sr: 16832,
    city: "Smithers",
    city_code: "YYD",
    airport_name: "Smithers",
    country: "Canada",
  },
  {
    sr: 16834,
    city: "Smithfield",
    city_code: "SFZ",
    airport_name: "North Central",
    country: "United States",
  },
  {
    sr: 16836,
    city: "Smithton",
    city_code: "SIO",
    airport_name: "Smithton",
    country: "Australia",
  },
  {
    sr: 16838,
    city: "Smolensk",
    city_code: "LNX",
    airport_name: "Smolensk",
    country: "Russia",
  },
  {
    sr: 16840,
    city: "Smyrna",
    city_code: "MQY",
    airport_name: "Smyrna",
    country: "United States",
  },
  {
    sr: 16842,
    city: "Snake Bay",
    city_code: "SNB",
    airport_name: "Snake Bay",
    country: "Australia",
  },
  {
    sr: 16844,
    city: "Snake River",
    city_code: "YXF",
    airport_name: "Snake River",
    country: "Canada",
  },
  {
    sr: 16846,
    city: "Snare Lake",
    city_code: "YFJ",
    airport_name: "Snare Lake",
    country: "Canada",
  },
  {
    sr: 16848,
    city: "Snartemo",
    city_code: "XUS",
    airport_name: "Snartemo Railway",
    country: "Norway",
  },
  {
    sr: 16850,
    city: "SNCB Rail Network",
    city_code: "ZYB",
    airport_name: "SNCB Rail Network",
    country: "Belgium",
  },
  {
    sr: 16852,
    city: "Snyder",
    city_code: "SNK",
    airport_name: "Winston Field",
    country: "United States",
  },
  {
    sr: 16854,
    city: "Soalala",
    city_code: "DWB",
    airport_name: "Soalala",
    country: "Madagascar",
  },
  {
    sr: 16856,
    city: "Soc Trang",
    city_code: "SOA",
    airport_name: "Soc Trang",
    country: "Vietnam",
  },
  {
    sr: 16858,
    city: "Socorro",
    city_code: "ONM",
    airport_name: "Socorro",
    country: "United States",
  },
  {
    sr: 16860,
    city: "Socotra",
    city_code: "SCT",
    airport_name: "Socotra",
    country: "Yemen",
  },
  {
    sr: 16864,
    city: "Sodankyla",
    city_code: "SOT",
    airport_name: "Sodankyla",
    country: "Finland",
  },
  {
    sr: 16866,
    city: "Soddu",
    city_code: "SXU",
    airport_name: "Soddu",
    country: "Ethiopia",
  },
  {
    sr: 16868,
    city: "Soderhamn",
    city_code: "SOO",
    airport_name: "Soderhamn",
    country: "Sweden",
  },
  {
    sr: 16870,
    city: "Sodertalje",
    city_code: "JSO",
    airport_name: "Sodertalje Heliport",
    country: "Sweden",
  },
  {
    sr: 16872,
    city: "Sodertalje",
    city_code: "XEZ",
    airport_name: "Sodertalje S Railway",
    country: "Sweden",
  },
  {
    sr: 16874,
    city: "Sofia",
    city_code: "SOF",
    airport_name: "Sofia",
    country: "Bulgaria",
  },
  {
    sr: 16876,
    city: "Sogamoso",
    city_code: "SOX",
    airport_name: "Alberto Lleras Camargo",
    country: "Colombia",
  },
  {
    sr: 16878,
    city: "Sogndal",
    city_code: "SOG",
    airport_name: "Haukasen",
    country: "Norway",
  },
  {
    sr: 16880,
    city: "	Lubombo",
    city_code: "SHO",
    airport_name: "King Mswati III International Airport",
    country: "Swaziland",
  },
  {
    sr: 16882,
    city: "Sokoto",
    city_code: "SKO",
    airport_name: "Sokoto",
    country: "Nigeria",
  },
  {
    sr: 16884,
    city: "Sola",
    city_code: "SLH",
    airport_name: "Sola",
    country: "Vanuatu",
  },
  {
    sr: 16886,
    city: "Solano",
    city_code: "SQF",
    airport_name: "Solano",
    country: "Colombia",
  },
  {
    sr: 16888,
    city: "Soldotna",
    city_code: "SXQ",
    airport_name: "Soldotna",
    country: "United States",
  },
  {
    sr: 16890,
    city: "Solenzara",
    city_code: "SOZ",
    airport_name: "Solenzara",
    country: "France",
  },
  {
    sr: 16892,
    city: "Solita",
    city_code: "SOH",
    airport_name: "Solita",
    country: "Colombia",
  },
  {
    sr: 16894,
    city: "Solo City",
    city_code: "SOC",
    airport_name: "Adi Sumarmo",
    country: "Indonesia",
  },
  {
    sr: 16898,
    city: "Solomon",
    city_code: "SOL",
    airport_name: "Solomon",
    country: "United States",
  },
  {
    sr: 16900,
    city: "Solovetsky",
    city_code: "CSH",
    airport_name: "Solovky",
    country: "Russia",
  },
  {
    sr: 16902,
    city: "Solvesborg",
    city_code: "XYU",
    airport_name: "Solvesborg",
    country: "Sweden",
  },
  {
    sr: 16904,
    city: "Solwezi",
    city_code: "SLI",
    airport_name: "Solwezi",
    country: "Zambia",
  },
  {
    sr: 16906,
    city: "Somerset",
    city_code: "SME",
    airport_name: "Pulaski County",
    country: "United States",
  },
  {
    sr: 16908,
    city: "Sonderborg",
    city_code: "SGD",
    airport_name: "Sonderborg",
    country: "Denmark",
  },
  {
    sr: 16910,
    city: "Sonderborg",
    city_code: "QSG",
    airport_name: "Sonderborg",
    country: "Denmark",
  },
  {
    sr: 16912,
    city: "Song Pan",
    city_code: "JZH",
    airport_name: "Jiu Zhai Huang Long",
    country: "China",
  },
  {
    sr: 16914,
    city: "Songea",
    city_code: "SGX",
    airport_name: "Songea",
    country: "Tanzania",
  },
  {
    sr: 16916,
    city: "Songkhla",
    city_code: "SGZ",
    airport_name: "Songkhla",
    country: "Thailand",
  },
  {
    sr: 16918,
    city: "Son La",
    city_code: "SQH",
    airport_name: "Na San",
    country: "Vietnam",
  },
  {
    sr: 16920,
    city: "Sophia Antipolis",
    city_code: "SXD",
    airport_name: "Sophia Antipolis",
    country: "France",
  },
  {
    sr: 16922,
    city: "Sopu",
    city_code: "SPH",
    airport_name: "Sopu",
    country: "Papua New Guinea",
  },
  {
    sr: 16924,
    city: "Sorkjosen",
    city_code: "SOJ",
    airport_name: "Sorkjosen",
    country: "Norway",
  },
  {
    sr: 16926,
    city: "Soroako",
    city_code: "SQR",
    airport_name: "Soroako",
    country: "Indonesia",
  },
  {
    sr: 16928,
    city: "Sorocaba",
    city_code: "SOD",
    airport_name: "Sorocaba",
    country: "Brazil",
  },
  {
    sr: 16932,
    city: "Sorong",
    city_code: "SOQ",
    airport_name: "Jefman",
    country: "Indonesia",
  },
  {
    sr: 16934,
    city: "Soroti",
    city_code: "SRT",
    airport_name: "Soroti",
    country: "Uganda",
  },
  {
    sr: 16936,
    city: "Naples",
    city_code: "RRO",
    airport_name: "Sorrento",
    country: "Italy",
  },
  {
    sr: 16938,
    city: "Souanke",
    city_code: "SOE",
    airport_name: "Souanke",
    country: "Congo",
  },
  {
    sr: 16940,
    city: "Soure",
    city_code: "SFK",
    airport_name: "Soure",
    country: "Brazil",
  },
  {
    sr: 16942,
    city: "South Andros",
    city_code: "TZN",
    airport_name: "South Andros",
    country: "Bahamas",
  },
  {
    sr: 16944,
    city: "South Bend",
    city_code: "SBN",
    airport_name: "South Bend Regional",
    country: "United States",
  },
  {
    sr: 16946,
    city: "South Caicos",
    city_code: "XSC",
    airport_name: "International",
    country: "Turks and Caicos Islands",
  },
  {
    sr: 16948,
    city: "South Galway",
    city_code: "ZGL",
    airport_name: "South Galway",
    country: "Australia",
  },
  {
    sr: 16950,
    city: "South Indian Lake",
    city_code: "XSI",
    airport_name: "South Indian Lake",
    country: "Canada",
  },
  {
    sr: 16952,
    city: "South Lake Tahoe",
    city_code: "TVL",
    airport_name: "South Lake Tahoe",
    country: "United States",
  },
  {
    sr: 16954,
    city: "South Molle Island",
    city_code: "SOI",
    airport_name: "South Molle Island",
    country: "Australia",
  },
  {
    sr: 16956,
    city: "South Naknek",
    city_code: "WSN",
    airport_name: "South Naknek",
    country: "United States",
  },
  {
    sr: 16958,
    city: "South Trout Lake",
    city_code: "ZFL",
    airport_name: "South Trout Lake",
    country: "Canada",
  },
  {
    sr: 16960,
    city: "South West Bay",
    city_code: "SWJ",
    airport_name: "South West Bay",
    country: "Vanuatu",
  },
  {
    sr: 16962,
    city: "South Weymouth",
    city_code: "NZW",
    airport_name: "South Weymouth",
    country: "United States",
  },
  {
    sr: 16966,
    city: "Southampton",
    city_code: "SOU",
    airport_name: "Eastleigh",
    country: "United Kingdom",
  },
  {
    sr: 16968,
    city: "Southend",
    city_code: "SEN",
    airport_name: "London Southend",
    country: "United Kingdom",
  },
  {
    sr: 16970,
    city: "Southern Cross",
    city_code: "SQC",
    airport_name: "Southern Cross",
    country: "Australia",
  },
  {
    sr: 16972,
    city: "Southern Pines",
    city_code: "SOP",
    airport_name: "Pinehurst S Pines",
    country: "United States",
  },
  {
    sr: 16974,
    city: "Southport",
    city_code: "SHQ",
    airport_name: "Southport",
    country: "Australia",
  },
  {
    sr: 16976,
    city: "Soyo",
    city_code: "SZA",
    airport_name: "Soyo",
    country: "Angola",
  },
  {
    sr: 16978,
    city: "Spangdahlem",
    city_code: "SPM",
    airport_name: "Spangdahlem",
    country: "Germany",
  },
  {
    sr: 16980,
    city: "Spanish Wells",
    city_code: "SWL",
    airport_name: "Spanish Wells",
    country: "Bahamas",
  },
  {
    sr: 16982,
    city: "Sparrevohn",
    city_code: "SVW",
    airport_name: "Sparrevohn AFS",
    country: "United States",
  },
  {
    sr: 16984,
    city: "Athens",
    city_code: "SPJ",
    airport_name: "Eleftherios Venizelos",
    country: "Greece",
  },
  {
    sr: 16986,
    city: "Sparta",
    city_code: "SAR",
    airport_name: "Sparta Community",
    country: "United States",
  },
  {
    sr: 16988,
    city: "Sparta",
    city_code: "CMY",
    airport_name: "Camp Mccoy AAF",
    country: "United States",
  },
  {
    sr: 16990,
    city: "Spartanburg",
    city_code: "SPA",
    airport_name: "Downtown Memorial",
    country: "United States",
  },
  {
    sr: 16992,
    city: "Spearfish",
    city_code: "SPF",
    airport_name: "Black Hills",
    country: "United States",
  },
  {
    sr: 16994,
    city: "Spencer",
    city_code: "SPW",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 16996,
    city: "Spetsai Island",
    city_code: "JSS",
    airport_name: "Spetsai Island",
    country: "Greece",
  },
  {
    sr: 17000,
    city: "Spiddal",
    city_code: "NNR",
    airport_name: "Connemara",
    country: "Ireland",
  },
  {
    sr: 17002,
    city: "Spirit Lake",
    city_code: "RTL",
    airport_name: "Spirit Lake",
    country: "United States",
  },
  {
    sr: 17004,
    city: "Split",
    city_code: "SPU",
    airport_name: "Split",
    country: "Croatia  Hrvatska",
  },
  {
    sr: 17006,
    city: "Spokane",
    city_code: "SKA",
    airport_name: "Fairchild AFB",
    country: "United States",
  },
  {
    sr: 17008,
    city: "Spokane",
    city_code: "SFF",
    airport_name: "Felts Field",
    country: "United States",
  },
  {
    sr: 17010,
    city: "Spokane",
    city_code: "GEG",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 17012,
    city: "Spring Creek",
    city_code: "SCG",
    airport_name: "Spring Creek",
    country: "Australia",
  },
  {
    sr: 17014,
    city: "Spring Island",
    city_code: "YSQ",
    airport_name: "Spring Island",
    country: "Canada",
  },
  {
    sr: 17016,
    city: "Spring Point",
    city_code: "AXP",
    airport_name: "Springpoint Arpt",
    country: "Bahamas",
  },
  {
    sr: 17018,
    city: "Springbok",
    city_code: "SBU",
    airport_name: "Springbok",
    country: "South Africa",
  },
  {
    sr: 17020,
    city: "Springdale",
    city_code: "SPZ",
    airport_name: "Springdale Muni",
    country: "United States",
  },
  {
    sr: 17022,
    city: "Springfield",
    city_code: "SPI",
    airport_name: "Capital",
    country: "United States",
  },
  {
    sr: 17024,
    city: "Springfield",
    city_code: "ZSF",
    airport_name: "Springfield MA RR",
    country: "United States",
  },
  {
    sr: 17026,
    city: "Springfield",
    city_code: "CEF",
    airport_name: "Westover Metro Arpt",
    country: "United States",
  },
  {
    sr: 17028,
    city: "Springfield",
    city_code: "SGF",
    airport_name: "Springfield Branson Rg",
    country: "United States",
  },
  {
    sr: 17030,
    city: "Springfield",
    city_code: "SGH",
    airport_name: "Springfield",
    country: "United States",
  },
  {
    sr: 17034,
    city: "Springfield",
    city_code: "VSF",
    airport_name: "State",
    country: "United States",
  },
  {
    sr: 17036,
    city: "Springvale",
    city_code: "KSV",
    airport_name: "Springvale",
    country: "Australia",
  },
  {
    sr: 17038,
    city: "Springvale",
    city_code: "ZVG",
    airport_name: "Springvale",
    country: "Australia",
  },
  {
    sr: 17040,
    city: "Squamish",
    city_code: "YSE",
    airport_name: "Squamish",
    country: "Canada",
  },
  {
    sr: 17042,
    city: "Squirrel Cove",
    city_code: "YSZ",
    airport_name: "Squirrel Cove",
    country: "Canada",
  },
  {
    sr: 17044,
    city: "Srinagar",
    city_code: "SXR",
    airport_name: "Srinagar",
    country: "India",
  },
  {
    sr: 17046,
    city: "St Andrews",
    city_code: "ADX",
    airport_name: "Leuchars",
    country: "United Kingdom",
  },
  {
    sr: 17048,
    city: "St Anthony",
    city_code: "YAY",
    airport_name: "St Anthony",
    country: "Canada",
  },
  {
    sr: 17050,
    city: "St Anton",
    city_code: "ANT",
    airport_name: "St Anton",
    country: "Austria",
  },
  {
    sr: 17052,
    city: "St Augustine",
    city_code: "UST",
    airport_name: "St Augustine",
    country: "United States",
  },
  {
    sr: 17054,
    city: "St Barthelemy",
    city_code: "SBH",
    airport_name: "St Barthelemy",
    country: "Guadeloupe",
  },
  {
    sr: 17056,
    city: "St Brieuc",
    city_code: "SBK",
    airport_name: "Tremuson",
    country: "France",
  },
  {
    sr: 17058,
    city: "St Catharines",
    city_code: "YCM",
    airport_name: "St Catharines",
    country: "Canada",
  },
  {
    sr: 17060,
    city: "St Crepin",
    city_code: "SCP",
    airport_name: "St Crepin",
    country: "France",
  },
  {
    sr: 17062,
    city: "St Croix Island",
    city_code: "STX",
    airport_name: "Alex Hamilton",
    country: "Virgin Islands U S",
  },
  {
    sr: 17064,
    city: "St Croix Island",
    city_code: "JCD",
    airport_name: "Downtown Heliport",
    country: "Virgin Islands U S",
  },
  {
    sr: 17068,
    city: "St Croix Island",
    city_code: "SSB",
    airport_name: "SPB",
    country: "Virgin Islands U S",
  },
  {
    sr: 17070,
    city: "St Denis de la Reunion",
    city_code: "RUN",
    airport_name: "Gillot",
    country: "Reunion",
  },
  {
    sr: 17072,
    city: "St Etienne",
    city_code: "EBU",
    airport_name: "Boutheon",
    country: "France",
  },
  {
    sr: 17074,
    city: "St Eustatius",
    city_code: "EUX",
    airport_name: "F D Roosevelt",
    country: "Netherlands Antilles",
  },
  {
    sr: 17076,
    city: "St Francois",
    city_code: "SFC",
    airport_name: "St Francois",
    country: "Guadeloupe",
  },
  {
    sr: 17078,
    city: "St George",
    city_code: "SGO",
    airport_name: "St George",
    country: "Australia",
  },
  {
    sr: 17080,
    city: "St George Island",
    city_code: "STG",
    airport_name: "St George Island",
    country: "United States",
  },
  {
    sr: 17082,
    city: "St Georges de lOyapock",
    city_code: "OYP",
    airport_name: "St Georges de lOyapock",
    country: "French Guiana",
  },
  {
    sr: 17084,
    city: "St Helens",
    city_code: "HLS",
    airport_name: "St Helens",
    country: "Australia",
  },
  {
    sr: 17086,
    city: "St Jean",
    city_code: "YJN",
    airport_name: "St Jean",
    country: "Canada",
  },
  {
    sr: 17088,
    city: "St John Island",
    city_code: "SJF",
    airport_name: "St John Island",
    country: "Virgin Islands U S",
  },
  {
    sr: 17090,
    city: "St Johns",
    city_code: "SJN",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 17092,
    city: "St Joseph",
    city_code: "STJ",
    airport_name: "Rosecrans Memorial",
    country: "United States",
  },
  {
    sr: 17094,
    city: "St Kitts",
    city_code: "SKB",
    airport_name: "Golden Rock",
    country: "Saint Kitts and Nevis",
  },
  {
    sr: 17096,
    city: "St Laurent du Maroni",
    city_code: "LDX",
    airport_name: "St Laurent du Maroni",
    country: "French Guiana",
  },
  {
    sr: 17098,
    city: "St Leonard",
    city_code: "YSL",
    airport_name: "Edmunston",
    country: "Canada",
  },
  {
    sr: 17102,
    city: "St Louis",
    city_code: "CPS",
    airport_name: "Bi State Parks",
    country: "United States",
  },
  {
    sr: 17104,
    city: "St Louis",
    city_code: "STL",
    airport_name: "Lambert St Louis Intl",
    country: "United States",
  },
  {
    sr: 17106,
    city: "St Louis",
    city_code: "SUS",
    airport_name: "Spirit Of St Louis",
    country: "United States",
  },
  {
    sr: 17108,
    city: "St Louis",
    city_code: "XLS",
    airport_name: "St Louis",
    country: "Senegal",
  },
  {
    sr: 17110,
    city: "St Lucia",
    city_code: "UVF",
    airport_name: "Hewanorra",
    country: "Saint Lucia",
  },
  {
    sr: 17112,
    city: "St Lucia",
    city_code: "SLU",
    airport_name: "Vigie",
    country: "Saint Lucia",
  },
  {
    sr: 17114,
    city: "St Maarten",
    city_code: "SXM",
    airport_name: "Princess Juliana",
    country: "St Marteen",
  },
  {
    sr: 17116,
    city: "St Martin",
    city_code: "SFG",
    airport_name: "Esperance",
    country: "St Martin",
  },
  {
    sr: 17118,
    city: "St Martin",
    city_code: "CCE",
    airport_name: "Grand Case",
    country: "Guadeloupe",
  },
  {
    sr: 17120,
    city: "St Martin",
    city_code: "MSB",
    airport_name: "Marigot SPB",
    country: "Guadeloupe",
  },
  {
    sr: 17122,
    city: "St Marys",
    city_code: "XSM",
    airport_name: "St Marys",
    country: "United States",
  },
  {
    sr: 17124,
    city: "St Marys",
    city_code: "STQ",
    airport_name: "St Marys",
    country: "United States",
  },
  {
    sr: 17126,
    city: "St Michael",
    city_code: "SMK",
    airport_name: "St Michael",
    country: "United States",
  },
  {
    sr: 17128,
    city: "St Moritz",
    city_code: "SMV",
    airport_name: "Samedan",
    country: "Switzerland",
  },
  {
    sr: 17130,
    city: "St Nazaire",
    city_code: "SNR",
    airport_name: "Montoir",
    country: "France",
  },
  {
    sr: 17132,
    city: "St Paul",
    city_code: "STP",
    airport_name: "Downtown",
    country: "United States",
  },
  {
    sr: 17136,
    city: "St Paul",
    city_code: "ZSP",
    airport_name: "St Paul",
    country: "Canada",
  },
  {
    sr: 17138,
    city: "St Peter",
    city_code: "PSH",
    airport_name: "St Peter",
    country: "Germany",
  },
  {
    sr: 17140,
    city: "St Petersburg",
    city_code: "PIE",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 17142,
    city: "St Petersburg",
    city_code: "SPG",
    airport_name: "Whitted",
    country: "United States",
  },
  {
    sr: 17144,
    city: "St Petersburg",
    city_code: "ZLK",
    airport_name: "Moscovskiy Railway Stn",
    country: "Russia",
  },
  {
    sr: 17146,
    city: "St Petersburg",
    city_code: "LED",
    airport_name: "Pulkovo",
    country: "Russia",
  },
  {
    sr: 17148,
    city: "St Petersburg",
    city_code: "RVH",
    airport_name: "Rzhevka",
    country: "Russia",
  },
  {
    sr: 17150,
    city: "St Pierre",
    city_code: "FSP",
    airport_name: "Saint Pierre Pointe Blanche",
    country: "St  Pierre And Miquelon",
  },
  {
    sr: 17152,
    city: "St Pierre dela Reunion",
    city_code: "ZSE",
    airport_name: "St Pierre dela Reunion",
    country: "Reunion",
  },
  {
    sr: 17154,
    city: "St Thomas",
    city_code: "YQS",
    airport_name: "Pembroke Area Mncpl",
    country: "Canada",
  },
  {
    sr: 17156,
    city: "St Thomas Island",
    city_code: "STT",
    airport_name: "H S Truman",
    country: "Virgin Islands U S",
  },
  {
    sr: 17158,
    city: "St Thomas Island",
    city_code: "SPB",
    airport_name: "SPB",
    country: "Virgin Islands U S",
  },
  {
    sr: 17160,
    city: "St Vincent",
    city_code: "SVD",
    airport_name: "E T Joshua",
    country: "Saint Vincent and the Grenadines",
  },
  {
    sr: 17162,
    city: "Staniel Cay",
    city_code: "TYM",
    airport_name: "Staniel Cay",
    country: "Bahamas",
  },
  {
    sr: 17164,
    city: "Stanthorpe",
    city_code: "SNH",
    airport_name: "Stanthorpe",
    country: "Australia",
  },
  {
    sr: 17166,
    city: "Stanton",
    city_code: "SYN",
    airport_name: "Carleton",
    country: "United States",
  },
  {
    sr: 17170,
    city: "Star Habour",
    city_code: "NAZ",
    airport_name: "Nana",
    country: "Solomon Islands",
  },
  {
    sr: 17172,
    city: "Stara Zagora",
    city_code: "SZR",
    airport_name: "Stara Zagora",
    country: "Bulgaria",
  },
  {
    sr: 17174,
    city: "Starcke",
    city_code: "SQP",
    airport_name: "Starcke",
    country: "Australia",
  },
  {
    sr: 17176,
    city: "State College",
    city_code: "SCE",
    airport_name: "University Park",
    country: "United States",
  },
  {
    sr: 17178,
    city: "Statesboro",
    city_code: "TBR",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 17180,
    city: "Statesville",
    city_code: "SVH",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 17182,
    city: "Skjern",
    city_code: "STA",
    airport_name: "Stauning Vestjyllands Airport",
    country: "Denmark",
  },
  {
    sr: 17184,
    city: "Staunton",
    city_code: "SHD",
    airport_name: "Shenandoah Valley",
    country: "United States",
  },
  {
    sr: 17186,
    city: "Stavanger",
    city_code: "SVG",
    airport_name: "Sola",
    country: "Norway",
  },
  {
    sr: 17188,
    city: "Stavropol",
    city_code: "STW",
    airport_name: "Stavropol",
    country: "Russia",
  },
  {
    sr: 17190,
    city: "Stawell",
    city_code: "SWC",
    airport_name: "Stawell",
    country: "Australia",
  },
  {
    sr: 17192,
    city: "Ste Therese Point",
    city_code: "YST",
    airport_name: "Ste Therese Point",
    country: "Canada",
  },
  {
    sr: 17194,
    city: "Steamboat Bay",
    city_code: "WSB",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 17196,
    city: "Steamboat Springs",
    city_code: "SBS",
    airport_name: "Steamboat Springs",
    country: "United States",
  },
  {
    sr: 17198,
    city: "Stebbins",
    city_code: "WBB",
    airport_name: "Stebbins",
    country: "United States",
  },
  {
    sr: 17200,
    city: "Steenkool",
    city_code: "ZKL",
    airport_name: "Steenkool",
    country: "Indonesia",
  },
  {
    sr: 17204,
    city: "Stella Maris",
    city_code: "SML",
    airport_name: "Estate Airstrip",
    country: "Bahamas",
  },
  {
    sr: 17206,
    city: "Stephen Island",
    city_code: "STF",
    airport_name: "Stephen Island",
    country: "Australia",
  },
  {
    sr: 17208,
    city: "Stephenville",
    city_code: "YJT",
    airport_name: "Stephenville",
    country: "Canada",
  },
  {
    sr: 17210,
    city: "Stephenville",
    city_code: "SEP",
    airport_name: "Clark Field",
    country: "United States",
  },
  {
    sr: 17212,
    city: "Sterling",
    city_code: "STK",
    airport_name: "Crosson Field",
    country: "United States",
  },
  {
    sr: 17214,
    city: "Sterling Rockfalls",
    city_code: "SQI",
    airport_name: "Whiteside County",
    country: "United States",
  },
  {
    sr: 17216,
    city: "Stevens Point",
    city_code: "STE",
    airport_name: "Stevens Point",
    country: "United States",
  },
  {
    sr: 17218,
    city: "Stevens Village",
    city_code: "SVS",
    airport_name: "Stevens Village",
    country: "United States",
  },
  {
    sr: 17220,
    city: "Stewart",
    city_code: "ZST",
    airport_name: "Stewart",
    country: "Canada",
  },
  {
    sr: 17222,
    city: "Stewart Island",
    city_code: "SZS",
    airport_name: "Stewart Island",
    country: "New Zealand",
  },
  {
    sr: 17224,
    city: "Stillwater",
    city_code: "SWO",
    airport_name: "Searcy Fld",
    country: "United States",
  },
  {
    sr: 17226,
    city: "Stjordal",
    city_code: "XUU",
    airport_name: "Stjordal Railway",
    country: "Norway",
  },
  {
    sr: 17228,
    city: "Stockholm",
    city_code: "SMP",
    airport_name: "Stockholm",
    country: "Papua New Guinea",
  },
  {
    sr: 17230,
    city: "Stockholm",
    city_code: "ARN",
    airport_name: "Arlanda",
    country: "Sweden",
  },
  {
    sr: 17232,
    city: "Stockholm",
    city_code: "BMA",
    airport_name: "Bromma",
    country: "Sweden",
  },
  {
    sr: 17234,
    city: "Stockholm",
    city_code: "NYO",
    airport_name: "Skavsta",
    country: "Sweden",
  },
  {
    sr: 17238,
    city: "Stockholm",
    city_code: "VST",
    airport_name: "Vasteras Hasslo",
    country: "Sweden",
  },
  {
    sr: 17240,
    city: "Stoelmans Eiland",
    city_code: "SMZ",
    airport_name: "Stoelmans Eiland",
    country: "Suriname",
  },
  {
    sr: 17242,
    city: "Stokmarknes",
    city_code: "SKN",
    airport_name: "Skagen",
    country: "Norway",
  },
  {
    sr: 17244,
    city: "Stony Rapids",
    city_code: "YSF",
    airport_name: "Stony Rapids",
    country: "Canada",
  },
  {
    sr: 17246,
    city: "Stony River",
    city_code: "SRV",
    airport_name: "Stony River",
    country: "United States",
  },
  {
    sr: 17248,
    city: "Stord",
    city_code: "SRP",
    airport_name: "Stord Airport",
    country: "Norway",
  },
  {
    sr: 17250,
    city: "Storekvina",
    city_code: "XUV",
    airport_name: "Storekvina Railway",
    country: "Norway",
  },
  {
    sr: 17252,
    city: "Storen",
    city_code: "XUW",
    airport_name: "Storen Railway",
    country: "Norway",
  },
  {
    sr: 17254,
    city: "Storm Lake",
    city_code: "SLB",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 17256,
    city: "Stornoway",
    city_code: "SYY",
    airport_name: "Stornoway",
    country: "United Kingdom",
  },
  {
    sr: 17258,
    city: "Storuman",
    city_code: "SQO",
    airport_name: "Gunnarn",
    country: "Sweden",
  },
  {
    sr: 17260,
    city: "Stow",
    city_code: "MMN",
    airport_name: "Minute Man Airfield",
    country: "United States",
  },
  {
    sr: 17262,
    city: "Stowe",
    city_code: "MVL",
    airport_name: "Morrisville Stowe",
    country: "United States",
  },
  {
    sr: 17264,
    city: "Stradbroke Island",
    city_code: "SRR",
    airport_name: "Dunwich",
    country: "Australia",
  },
  {
    sr: 17266,
    city: "Strahan",
    city_code: "SRN",
    airport_name: "Strahan",
    country: "Australia",
  },
  {
    sr: 17268,
    city: "Strasbourg",
    city_code: "SXB",
    airport_name: "Entzheim",
    country: "France",
  },
  {
    sr: 17272,
    city: "Stratford",
    city_code: "JSD",
    airport_name: "Sikorsky Heliport",
    country: "United States",
  },
  {
    sr: 17274,
    city: "Strathmore",
    city_code: "STH",
    airport_name: "Strathmore",
    country: "Australia",
  },
  {
    sr: 17276,
    city: "Straubing",
    city_code: "RBM",
    airport_name: "Wallmuhle",
    country: "Germany",
  },
  {
    sr: 17278,
    city: "Streaky Bay",
    city_code: "KBY",
    airport_name: "Streaky Bay",
    country: "Australia",
  },
  {
    sr: 17280,
    city: "Stromboli",
    city_code: "ZJX",
    airport_name: "Harbour  Eolie Island",
    country: "Italy",
  },
  {
    sr: 17282,
    city: "Stronsay",
    city_code: "SOY",
    airport_name: "Stronsay",
    country: "United Kingdom",
  },
  {
    sr: 17284,
    city: "Stroud",
    city_code: "SUD",
    airport_name: "Stroud",
    country: "United States",
  },
  {
    sr: 17286,
    city: "Strzhewoi",
    city_code: "SWT",
    airport_name: "Strzhewoi",
    country: "Russia",
  },
  {
    sr: 17288,
    city: "Stuart",
    city_code: "SUA",
    airport_name: "Witham Field",
    country: "United States",
  },
  {
    sr: 17290,
    city: "Stuart Island",
    city_code: "YRR",
    airport_name: "Stuart Island",
    country: "Canada",
  },
  {
    sr: 17292,
    city: "Stuart Island",
    city_code: "SSW",
    airport_name: "Stuart Island",
    country: "United States",
  },
  {
    sr: 17294,
    city: "Stung Treng",
    city_code: "TNX",
    airport_name: "Stung Treng",
    country: "Cambodia",
  },
  {
    sr: 17296,
    city: "Sturdee",
    city_code: "YTC",
    airport_name: "Sturdee",
    country: "Canada",
  },
  {
    sr: 17298,
    city: "Sturgeon Bay",
    city_code: "SUE",
    airport_name: "Door County",
    country: "United States",
  },
  {
    sr: 17300,
    city: "Sturgis",
    city_code: "IRS",
    airport_name: "Kirsch Municipal",
    country: "United States",
  },
  {
    sr: 17302,
    city: "Sturt Creek",
    city_code: "SSK",
    airport_name: "Sturt Creek",
    country: "Australia",
  },
  {
    sr: 17306,
    city: "Stuttgart",
    city_code: "SGT",
    airport_name: "Stuttgart",
    country: "United States",
  },
  {
    sr: 17308,
    city: "Stuttgart",
    city_code: "STR",
    airport_name: "Echterdingen",
    country: "Germany",
  },
  {
    sr: 17310,
    city: "Stykkisholmur",
    city_code: "SYK",
    airport_name: "Stykkisholmur",
    country: "Iceland",
  },
  {
    sr: 17312,
    city: "Suabi",
    city_code: "SBE",
    airport_name: "Suabi",
    country: "Papua New Guinea",
  },
  {
    sr: 17314,
    city: "Suai",
    city_code: "UAI",
    airport_name: "Suai",
    country: "Indonesia",
  },
  {
    sr: 17316,
    city: "Suapan",
    city_code: "SXN",
    airport_name: "Suapan",
    country: "Botswana",
  },
  {
    sr: 17318,
    city: "Suavanao",
    city_code: "VAO",
    airport_name: "Suavanao Airstrip",
    country: "Solomon Islands",
  },
  {
    sr: 17320,
    city: "Suceava",
    city_code: "SCV",
    airport_name: "Salcea",
    country: "Romania",
  },
  {
    sr: 17322,
    city: "Sucre",
    city_code: "SRE",
    airport_name: "Sucre",
    country: "Bolivia",
  },
  {
    sr: 17324,
    city: "Sucua",
    city_code: "SUQ",
    airport_name: "Sucua",
    country: "Ecuador",
  },
  {
    sr: 17326,
    city: "Sudbury",
    city_code: "YSB",
    airport_name: "Sudbury",
    country: "Canada",
  },
  {
    sr: 17328,
    city: "Sudbury",
    city_code: "XDY",
    airport_name: "Sudbury Jct Rail Stn",
    country: "Canada",
  },
  {
    sr: 17330,
    city: "Sudureyri",
    city_code: "SUY",
    airport_name: "Sudureyri",
    country: "Iceland",
  },
  {
    sr: 17332,
    city: "Sue Island",
    city_code: "SYU",
    airport_name: "Warraber Island",
    country: "Australia",
  },
  {
    sr: 17334,
    city: "Suffield",
    city_code: "YSD",
    airport_name: "Suffield",
    country: "Canada",
  },
  {
    sr: 17336,
    city: "Sugar Land",
    city_code: "SGR",
    airport_name: "Sugar Land Municipal",
    country: "United States",
  },
  {
    sr: 17340,
    city: "Sui",
    city_code: "SUL",
    airport_name: "Sui",
    country: "Pakistan",
  },
  {
    sr: 17342,
    city: "Sui Fen He",
    city_code: "FUD",
    airport_name: "Sui Fen He",
    country: "China",
  },
  {
    sr: 17344,
    city: "Suia Missu",
    city_code: "SWM",
    airport_name: "Suia Missu",
    country: "Brazil",
  },
  {
    sr: 17346,
    city: "Sukhothai",
    city_code: "THS",
    airport_name: "Sukhothai",
    country: "Thailand",
  },
  {
    sr: 17348,
    city: "Sukhumi",
    city_code: "SUI",
    airport_name: "Babusheri",
    country: "Georgia",
  },
  {
    sr: 17350,
    city: "Suki",
    city_code: "SKC",
    airport_name: "Suki",
    country: "Papua New Guinea",
  },
  {
    sr: 17352,
    city: "Sukkur",
    city_code: "SKZ",
    airport_name: "Sukkur",
    country: "Pakistan",
  },
  {
    sr: 17354,
    city: "Sulaco",
    city_code: "SCD",
    airport_name: "Sulaco",
    country: "Honduras",
  },
  {
    sr: 17356,
    city: "Sulayel",
    city_code: "SLF",
    airport_name: "Sulayel",
    country: "Saudi Arabia",
  },
  {
    sr: 17358,
    city: "Sule",
    city_code: "ULE",
    airport_name: "Sule",
    country: "Papua New Guinea",
  },
  {
    sr: 17360,
    city: "Sullivan",
    city_code: "SIV",
    airport_name: "County",
    country: "United States",
  },
  {
    sr: 17362,
    city: "Sullivan Bay",
    city_code: "YTG",
    airport_name: "Sullivan Bay",
    country: "Canada",
  },
  {
    sr: 17364,
    city: "Sulphur Springs",
    city_code: "SLR",
    airport_name: "Sulphur Springs",
    country: "United States",
  },
  {
    sr: 17366,
    city: "Sumare",
    city_code: "RWS",
    airport_name: "Sumare",
    country: "Brazil",
  },
  {
    sr: 17368,
    city: "Sumbawa",
    city_code: "SWQ",
    airport_name: "Brang Bidji",
    country: "Indonesia",
  },
  {
    sr: 17370,
    city: "Sumbawanga",
    city_code: "SUT",
    airport_name: "Sumbawanga",
    country: "Tanzania",
  },
  {
    sr: 17374,
    city: "Sumbe",
    city_code: "NDD",
    airport_name: "Sumbe",
    country: "Angola",
  },
  {
    sr: 17376,
    city: "Sumenep",
    city_code: "SUP",
    airport_name: "Trunojoyo",
    country: "Indonesia",
  },
  {
    sr: 17378,
    city: "Summer Beaver",
    city_code: "SUR",
    airport_name: "Summer Beaver",
    country: "Canada",
  },
  {
    sr: 17380,
    city: "Summerside",
    city_code: "YSU",
    airport_name: "Summerside",
    country: "Canada",
  },
  {
    sr: 17382,
    city: "Summit",
    city_code: "UMM",
    airport_name: "Summit",
    country: "United States",
  },
  {
    sr: 17384,
    city: "Summit Lake",
    city_code: "IUM",
    airport_name: "Summit Lake",
    country: "Canada",
  },
  {
    sr: 17386,
    city: "Sumter",
    city_code: "SUM",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 17388,
    city: "Sumter",
    city_code: "SSC",
    airport_name: "Shaw AFB",
    country: "United States",
  },
  {
    sr: 17390,
    city: "Sumy",
    city_code: "UMY",
    airport_name: "Sumy",
    country: "Ukraine",
  },
  {
    sr: 17392,
    city: "Sun City",
    city_code: "NTY",
    airport_name: "Pilanesberg",
    country: "South Africa",
  },
  {
    sr: 17394,
    city: "Sun Moon Lake",
    city_code: "SMT",
    airport_name: "Sun Moon Lake",
    country: "Taiwan",
  },
  {
    sr: 17396,
    city: "Sun River",
    city_code: "SUO",
    airport_name: "Sun River",
    country: "United States",
  },
  {
    sr: 17398,
    city: "Sun Valley",
    city_code: "SUN",
    airport_name: "Sun Valley",
    country: "United States",
  },
  {
    sr: 17400,
    city: "Suncheon",
    city_code: "SYS",
    airport_name: "Yeosu",
    country: "South Korea",
  },
  {
    sr: 17402,
    city: "Sundance",
    city_code: "SUC",
    airport_name: "Schloredt",
    country: "United States",
  },
  {
    sr: 17404,
    city: "Sundsvall",
    city_code: "SDL",
    airport_name: "Sundsvall Harnosand",
    country: "Sweden",
  },
  {
    sr: 17408,
    city: "Sungai Pakning",
    city_code: "SEQ",
    airport_name: "Sungai Pakning",
    country: "Indonesia",
  },
  {
    sr: 17410,
    city: "Sungei Tekai",
    city_code: "GTK",
    airport_name: "Sungei Tekai",
    country: "Malaysia",
  },
  {
    sr: 17412,
    city: "Sunshine Coast",
    city_code: "MCY",
    airport_name: "Maroochydore",
    country: "Australia",
  },
  {
    sr: 17414,
    city: "Sunyani",
    city_code: "NYI",
    airport_name: "Sunyani",
    country: "Ghana",
  },
  {
    sr: 17416,
    city: "Superior",
    city_code: "SUW",
    airport_name: "Richard I Bong Arpt",
    country: "United States",
  },
  {
    sr: 17418,
    city: "Sur",
    city_code: "SUH",
    airport_name: "Sur",
    country: "Oman",
  },
  {
    sr: 17420,
    city: "Surabaya",
    city_code: "SUB",
    airport_name: "Juanda",
    country: "Indonesia",
  },
  {
    sr: 17422,
    city: "Surat",
    city_code: "STV",
    airport_name: "Surat",
    country: "India",
  },
  {
    sr: 17424,
    city: "Surat Thani",
    city_code: "URT",
    airport_name: "Surat Thani",
    country: "Thailand",
  },
  {
    sr: 17426,
    city: "Surfdale",
    city_code: "WIK",
    airport_name: "Surfdale",
    country: "New Zealand",
  },
  {
    sr: 17428,
    city: "Surfers Paradise",
    city_code: "SFP",
    airport_name: "Surfers Paradise",
    country: "Australia",
  },
  {
    sr: 17430,
    city: "Surgut",
    city_code: "SGC",
    airport_name: "Surgut",
    country: "Russia",
  },
  {
    sr: 17432,
    city: "Suria",
    city_code: "SUZ",
    airport_name: "Suria",
    country: "Papua New Guinea",
  },
  {
    sr: 17434,
    city: "Surigao",
    city_code: "SUG",
    airport_name: "Surigao",
    country: "Philippines",
  },
  {
    sr: 17436,
    city: "Surin",
    city_code: "PXR",
    airport_name: "Surin",
    country: "Thailand",
  },
  {
    sr: 17438,
    city: "Surkhet",
    city_code: "SKH",
    airport_name: "Kadmandu",
    country: "Nepal",
  },
  {
    sr: 17442,
    city: "Susanville",
    city_code: "SVE",
    airport_name: "Susanville",
    country: "United States",
  },
  {
    sr: 17444,
    city: "Suttonheath",
    city_code: "WOB",
    airport_name: "Woodbridge RAF",
    country: "United Kingdom",
  },
  {
    sr: 17446,
    city: "Suva",
    city_code: "SUV",
    airport_name: "Nausori",
    country: "Fiji",
  },
  {
    sr: 17448,
    city: "Suwon",
    city_code: "SWU",
    airport_name: "Suwon",
    country: "South Korea",
  },
  {
    sr: 17450,
    city: "Suzhou",
    city_code: "SZV",
    airport_name: "Suzhou",
    country: "China",
  },
  {
    sr: 17452,
    city: "Svalbard",
    city_code: "SYG",
    airport_name: "Spitsberg",
    country: "Norway",
  },
  {
    sr: 17454,
    city: "Svay Rieng",
    city_code: "SVR",
    airport_name: "Svay Rieng",
    country: "Cambodia",
  },
  {
    sr: 17456,
    city: "Sveg",
    city_code: "EVG",
    airport_name: "Sveg",
    country: "Sweden",
  },
  {
    sr: 17458,
    city: "Svolvaer",
    city_code: "SVJ",
    airport_name: "Helle",
    country: "Norway",
  },
  {
    sr: 17460,
    city: "Swakopmund",
    city_code: "SWP",
    airport_name: "Swakopmund",
    country: "Namibia",
  },
  {
    sr: 17462,
    city: "Swakopmund",
    city_code: "ZSZ",
    airport_name: "Railway Station",
    country: "Namibia",
  },
  {
    sr: 17464,
    city: "Swan Hill",
    city_code: "SWH",
    airport_name: "Swan Hill",
    country: "Australia",
  },
  {
    sr: 17466,
    city: "Swan River",
    city_code: "ZJN",
    airport_name: "Swan River",
    country: "Canada",
  },
  {
    sr: 17468,
    city: "Swansea",
    city_code: "SWS",
    airport_name: "Fairwood Comm",
    country: "United Kingdom",
  },
  {
    sr: 17470,
    city: "Sweetwater",
    city_code: "SWW",
    airport_name: "Sweetwater",
    country: "United States",
  },
  {
    sr: 17472,
    city: "Swift Current",
    city_code: "YYN",
    airport_name: "Swift Current",
    country: "Canada",
  },
  {
    sr: 17476,
    city: "Swindon",
    city_code: "SWI",
    airport_name: "Swindon",
    country: "United Kingdom",
  },
  {
    sr: 17478,
    city: "Swindon",
    city_code: "XWS",
    airport_name: "Railway Station",
    country: "United Kingdom",
  },
  {
    sr: 17480,
    city: "Syangboche",
    city_code: "SYH",
    airport_name: "Syangboche",
    country: "Nepal",
  },
  {
    sr: 17482,
    city: "Sydney",
    city_code: "RSE",
    airport_name: "Sydney Water Airport  Rose Bay",
    country: "Australia",
  },
  {
    sr: 17484,
    city: "Sydney",
    city_code: "SYD",
    airport_name: "Kingsford Smith",
    country: "Australia",
  },
  {
    sr: 17486,
    city: "Sydney",
    city_code: "LBH",
    airport_name: "Palm Beach SPB",
    country: "Australia",
  },
  {
    sr: 17488,
    city: "Sydney",
    city_code: "SWZ",
    airport_name: "Sydney West",
    country: "Australia",
  },
  {
    sr: 17490,
    city: "Sydney",
    city_code: "YQY",
    airport_name: "Sydney",
    country: "Canada",
  },
  {
    sr: 17492,
    city: "Syktyvkar",
    city_code: "SCW",
    airport_name: "Syktyvkar",
    country: "Russia",
  },
  {
    sr: 17494,
    city: "Sylhet",
    city_code: "ZYL",
    airport_name: "Osmani International",
    country: "Bangladesh",
  },
  {
    sr: 17496,
    city: "Sylvester",
    city_code: "SYV",
    airport_name: "Sylvester",
    country: "United States",
  },
  {
    sr: 17498,
    city: "Syracuse",
    city_code: "SYR",
    airport_name: "Syracuse Hancock International Airport",
    country: "United States",
  },
  {
    sr: 17500,
    city: "Syracuse",
    city_code: "ZYQ",
    airport_name: "Syracuse NY Rail",
    country: "United States",
  },
  {
    sr: 17502,
    city: "Syros Island",
    city_code: "JSY",
    airport_name: "Syros Island",
    country: "Greece",
  },
  {
    sr: 17504,
    city: "Szczecin",
    city_code: "SZZ",
    airport_name: "Goleniow",
    country: "Poland",
  },
  {
    sr: 17506,
    city: "Szymany",
    city_code: "SZY",
    airport_name: "Mazury",
    country: "Poland",
  },
  {
    sr: 17510,
    city: "Taabo",
    city_code: "TBX",
    airport_name: "Taabo",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 17512,
    city: "Taba",
    city_code: "TCP",
    airport_name: "Taba International",
    country: "Egypt",
  },
  {
    sr: 17514,
    city: "Tabal",
    city_code: "TBV",
    airport_name: "Tabal",
    country: "Marshall Islands",
  },
  {
    sr: 17516,
    city: "Tabarka",
    city_code: "TBJ",
    airport_name: "Tabarka",
    country: "Tunisia",
  },
  {
    sr: 17518,
    city: "Tabas",
    city_code: "TCX",
    airport_name: "Tabas",
    country: "Iran",
  },
  {
    sr: 17520,
    city: "Tabatinga",
    city_code: "TBT",
    airport_name: "Internacional",
    country: "Brazil",
  },
  {
    sr: 17522,
    city: "Tabibuga",
    city_code: "TBA",
    airport_name: "Tabibuga",
    country: "Papua New Guinea",
  },
  {
    sr: 17524,
    city: "Tabiteuea North",
    city_code: "TBF",
    airport_name: "Tabiteuea North",
    country: "Kiribati",
  },
  {
    sr: 17526,
    city: "Tabiteuea South",
    city_code: "TSU",
    airport_name: "Tabiteuea South",
    country: "Kiribati",
  },
  {
    sr: 17528,
    city: "Tablas",
    city_code: "TBH",
    airport_name: "Tablas",
    country: "Philippines",
  },
  {
    sr: 17530,
    city: "Tableland",
    city_code: "TBL",
    airport_name: "Tableland",
    country: "Australia",
  },
  {
    sr: 17532,
    city: "Tablon De Tamara",
    city_code: "TTM",
    airport_name: "Tablon De Tamara",
    country: "Colombia",
  },
  {
    sr: 17534,
    city: "Tabora",
    city_code: "TBO",
    airport_name: "Tabora",
    country: "Tanzania",
  },
  {
    sr: 17536,
    city: "Tabou",
    city_code: "TXU",
    airport_name: "Tabou",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 17538,
    city: "Tabriz",
    city_code: "TBZ",
    airport_name: "Tabriz",
    country: "Iran",
  },
  {
    sr: 17540,
    city: "Tabuaeran",
    city_code: "TNV",
    airport_name: "Tabuaeran",
    country: "Kiribati",
  },
  {
    sr: 17544,
    city: "Tabubil",
    city_code: "TBG",
    airport_name: "Tabubil",
    country: "Papua New Guinea",
  },
  {
    sr: 17546,
    city: "Tabuk",
    city_code: "TUU",
    airport_name: "Tabuk",
    country: "Saudi Arabia",
  },
  {
    sr: 17548,
    city: "Tacheng",
    city_code: "TCG",
    airport_name: "Tacheng",
    country: "China",
  },
  {
    sr: 17550,
    city: "Tachilek",
    city_code: "THL",
    airport_name: "Tachilek",
    country: "Myanmar",
  },
  {
    sr: 17552,
    city: "Tacloban",
    city_code: "TAC",
    airport_name: "D Z Romualdez",
    country: "Philippines",
  },
  {
    sr: 17554,
    city: "Tacna",
    city_code: "TCQ",
    airport_name: "Tacna",
    country: "Peru",
  },
  {
    sr: 17556,
    city: "Tacoma",
    city_code: "GRF",
    airport_name: "Gray AAF",
    country: "United States",
  },
  {
    sr: 17558,
    city: "Tacoma",
    city_code: "TIW",
    airport_name: "Industrial",
    country: "United States",
  },
  {
    sr: 17560,
    city: "Tacoma",
    city_code: "TCM",
    airport_name: "Mc Chord AFB",
    country: "United States",
  },
  {
    sr: 17562,
    city: "Tacuarembo",
    city_code: "TAW",
    airport_name: "Tacuarembo",
    country: "Uruguay",
  },
  {
    sr: 17564,
    city: "Tadjoura",
    city_code: "TDJ",
    airport_name: "Tadjoura",
    country: "Djibouti",
  },
  {
    sr: 17566,
    city: "Tadoule Lake",
    city_code: "XTL",
    airport_name: "Tadoule Lake",
    country: "Canada",
  },
  {
    sr: 17568,
    city: "Taftan",
    city_code: "TFT",
    airport_name: "Taftan",
    country: "Pakistan",
  },
  {
    sr: 17570,
    city: "Tagbilaran",
    city_code: "TAG",
    airport_name: "Tagbilaran",
    country: "Philippines",
  },
  {
    sr: 17572,
    city: "Tagbita",
    city_code: "TGB",
    airport_name: "Tagbita",
    country: "Philippines",
  },
  {
    sr: 17574,
    city: "Tagula",
    city_code: "TGL",
    airport_name: "Tagula",
    country: "Papua New Guinea",
  },
  {
    sr: 17578,
    city: "Taharoa",
    city_code: "THH",
    airport_name: "Taharoa",
    country: "New Zealand",
  },
  {
    sr: 17580,
    city: "Tahneta Pass Lodge",
    city_code: "HNE",
    airport_name: "Tahneta Pass",
    country: "United States",
  },
  {
    sr: 17582,
    city: "Tahoua",
    city_code: "THZ",
    airport_name: "Tahoua",
    country: "Niger",
  },
  {
    sr: 17584,
    city: "Tahsis",
    city_code: "ZTS",
    airport_name: "Tahsis",
    country: "Canada",
  },
  {
    sr: 17586,
    city: "Tai Shan",
    city_code: "ZCA",
    airport_name: "Tai Shan",
    country: "China",
  },
  {
    sr: 17588,
    city: "Taichung",
    city_code: "TXG",
    airport_name: "Taichung",
    country: "Taiwan",
  },
  {
    sr: 17590,
    city: "Taichung",
    city_code: "RMQ",
    airport_name: "ChingChuanKang",
    country: "Taiwan",
  },
  {
    sr: 17592,
    city: "Taif",
    city_code: "TIF",
    airport_name: "Taif",
    country: "Saudi Arabia",
  },
  {
    sr: 17594,
    city: "Tainan",
    city_code: "TNN",
    airport_name: "Tainan",
    country: "Taiwan",
  },
  {
    sr: 17596,
    city: "Taipei",
    city_code: "TPE",
    airport_name: "Chiang Kai Shek",
    country: "Taiwan",
  },
  {
    sr: 17598,
    city: "Taipei",
    city_code: "TSA",
    airport_name: "Sung Shan",
    country: "Taiwan",
  },
  {
    sr: 17600,
    city: "Taiping",
    city_code: "TPG",
    airport_name: "Taiping",
    country: "Malaysia",
  },
  {
    sr: 17602,
    city: "Taisha",
    city_code: "TSC",
    airport_name: "Taisha",
    country: "Ecuador",
  },
  {
    sr: 17604,
    city: "Taitung",
    city_code: "TTT",
    airport_name: "Taitung",
    country: "Taiwan",
  },
  {
    sr: 17606,
    city: "Taiyuan",
    city_code: "TYN",
    airport_name: "Taiyuan",
    country: "China",
  },
  {
    sr: 17608,
    city: "Taiz",
    city_code: "TAI",
    airport_name: "Al Janad",
    country: "Yemen",
  },
  {
    sr: 17612,
    city: "Tak",
    city_code: "TKT",
    airport_name: "Tak",
    country: "Thailand",
  },
  {
    sr: 17614,
    city: "Takaka",
    city_code: "KTF",
    airport_name: "Takaka",
    country: "New Zealand",
  },
  {
    sr: 17616,
    city: "Takamatsu",
    city_code: "TAK",
    airport_name: "Takamatsu",
    country: "Japan",
  },
  {
    sr: 17618,
    city: "Takapoto",
    city_code: "TKP",
    airport_name: "Takapoto",
    country: "French Polynesia",
  },
  {
    sr: 17620,
    city: "Takaroa",
    city_code: "TKX",
    airport_name: "Takaroa",
    country: "French Polynesia",
  },
  {
    sr: 17622,
    city: "Takhli",
    city_code: "TKH",
    airport_name: "Takhli",
    country: "Thailand",
  },
  {
    sr: 17624,
    city: "Takoradi",
    city_code: "TKD",
    airport_name: "Takoradi",
    country: "Ghana",
  },
  {
    sr: 17626,
    city: "Takotna",
    city_code: "TCT",
    airport_name: "Takotna",
    country: "United States",
  },
  {
    sr: 17628,
    city: "Taku Lodge",
    city_code: "TKL",
    airport_name: "Taku SPB",
    country: "United States",
  },
  {
    sr: 17630,
    city: "Takume",
    city_code: "TJN",
    airport_name: "Takume",
    country: "French Polynesia",
  },
  {
    sr: 17632,
    city: "Talara",
    city_code: "TYL",
    airport_name: "Talara",
    country: "Peru",
  },
  {
    sr: 17634,
    city: "Talasea",
    city_code: "TLW",
    airport_name: "Talasea",
    country: "Papua New Guinea",
  },
  {
    sr: 17636,
    city: "Talca",
    city_code: "TLX",
    airport_name: "Talca",
    country: "Chile",
  },
  {
    sr: 17638,
    city: "Taldy Kurgan",
    city_code: "TDK",
    airport_name: "Taldy Kurgan",
    country: "Kazakhstan",
  },
  {
    sr: 17640,
    city: "Taliabu",
    city_code: "TAX",
    airport_name: "Taliabu",
    country: "Indonesia",
  },
  {
    sr: 17642,
    city: "Talkeetna",
    city_code: "TKA",
    airport_name: "Talkeetna",
    country: "United States",
  },
  {
    sr: 17646,
    city: "Talknafjordur",
    city_code: "TLK",
    airport_name: "Talknafjordur",
    country: "Iceland",
  },
  {
    sr: 17648,
    city: "Talladega",
    city_code: "ASN",
    airport_name: "Talladega",
    country: "United States",
  },
  {
    sr: 17650,
    city: "Tallahassee",
    city_code: "TLH",
    airport_name: "Tallahassee Regional Airport",
    country: "United States",
  },
  {
    sr: 17652,
    city: "Tallinn",
    city_code: "QUF",
    airport_name: "Pirita Harbour",
    country: "Estonia",
  },
  {
    sr: 17654,
    city: "Tallinn",
    city_code: "TLL",
    airport_name: "Ulemiste",
    country: "Estonia",
  },
  {
    sr: 17656,
    city: "Taloyoak",
    city_code: "YYH",
    airport_name: "Taloyoak",
    country: "Canada",
  },
  {
    sr: 17658,
    city: "Taltal",
    city_code: "TTC",
    airport_name: "Taltal",
    country: "Chile",
  },
  {
    sr: 17660,
    city: "Taltheilei Narrows",
    city_code: "GSL",
    airport_name: "Taltheilei Narrows",
    country: "Canada",
  },
  {
    sr: 17662,
    city: "Taluqan",
    city_code: "TQN",
    airport_name: "Taluqan",
    country: "Afghanistan",
  },
  {
    sr: 17664,
    city: "Tamale",
    city_code: "TML",
    airport_name: "Tamale",
    country: "Ghana",
  },
  {
    sr: 17666,
    city: "Taman Negara",
    city_code: "SXT",
    airport_name: "Taman Negara",
    country: "Malaysia",
  },
  {
    sr: 17668,
    city: "Tamana Island",
    city_code: "TMN",
    airport_name: "Tamana Island",
    country: "Kiribati",
  },
  {
    sr: 17670,
    city: "Tamanrasset",
    city_code: "TMR",
    airport_name: "Aguemar",
    country: "Algeria",
  },
  {
    sr: 17672,
    city: "Tamarindo",
    city_code: "TNO",
    airport_name: "Tamarindo",
    country: "Costa Rica",
  },
  {
    sr: 17674,
    city: "Tamatave",
    city_code: "TMM",
    airport_name: "Tamatave",
    country: "Madagascar",
  },
  {
    sr: 17676,
    city: "Tambacounda",
    city_code: "TUD",
    airport_name: "Tambacounda",
    country: "Senegal",
  },
  {
    sr: 17680,
    city: "Tambao",
    city_code: "TMQ",
    airport_name: "Tambao",
    country: "Burkina Faso",
  },
  {
    sr: 17682,
    city: "Tambohorano",
    city_code: "WTA",
    airport_name: "Tambohorano",
    country: "Madagascar",
  },
  {
    sr: 17684,
    city: "Tambolaka",
    city_code: "TMC",
    airport_name: "Tjilik Riwut",
    country: "Indonesia",
  },
  {
    sr: 17686,
    city: "Tambor",
    city_code: "TMU",
    airport_name: "Tambor",
    country: "Costa Rica",
  },
  {
    sr: 17688,
    city: "Tambov",
    city_code: "TBW",
    airport_name: "Tambov",
    country: "Russia",
  },
  {
    sr: 17690,
    city: "Tamchakett",
    city_code: "THT",
    airport_name: "Tamchakett",
    country: "Mauritania",
  },
  {
    sr: 17692,
    city: "Tame",
    city_code: "TME",
    airport_name: "Tame",
    country: "Colombia",
  },
  {
    sr: 17694,
    city: "Tamky",
    city_code: "TMK",
    airport_name: "Tamky",
    country: "Vietnam",
  },
  {
    sr: 17696,
    city: "Tamky",
    city_code: "VCL",
    airport_name: "Chulai",
    country: "Vietnam",
  },
  {
    sr: 17698,
    city: "Tampa",
    city_code: "TPA",
    airport_name: "Tampa International",
    country: "United States",
  },
  {
    sr: 17700,
    city: "Tampa",
    city_code: "MCF",
    airport_name: "Mac Dill AFB",
    country: "United States",
  },
  {
    sr: 17702,
    city: "Tampa",
    city_code: "KYO",
    airport_name: "Topp Of Tampa",
    country: "United States",
  },
  {
    sr: 17704,
    city: "Tampere",
    city_code: "TMP",
    airport_name: "Tampere Pirkkala",
    country: "Finland",
  },
  {
    sr: 17706,
    city: "Tampico",
    city_code: "TAM",
    airport_name: "Gen F Javier Mina",
    country: "Mexico",
  },
  {
    sr: 17708,
    city: "Tamuin",
    city_code: "TSL",
    airport_name: "Tamuin",
    country: "Mexico",
  },
  {
    sr: 17710,
    city: "Tamworth",
    city_code: "TMW",
    airport_name: "Tamworth",
    country: "Australia",
  },
  {
    sr: 17714,
    city: "Tan Tan",
    city_code: "TTA",
    airport_name: "Tan Tan",
    country: "Morocco",
  },
  {
    sr: 17716,
    city: "Tana Toraja",
    city_code: "TTR",
    airport_name: "Tana Toraja",
    country: "Indonesia",
  },
  {
    sr: 17718,
    city: "Tanacross",
    city_code: "TSG",
    airport_name: "Intermediate",
    country: "United States",
  },
  {
    sr: 17720,
    city: "Tanah Grogot",
    city_code: "TNB",
    airport_name: "Tanah Grogot",
    country: "Indonesia",
  },
  {
    sr: 17722,
    city: "Tanahmerah",
    city_code: "TMH",
    airport_name: "Tanahmerah",
    country: "Indonesia",
  },
  {
    sr: 17724,
    city: "Tanalian Point",
    city_code: "TPO",
    airport_name: "Tanalian Point",
    country: "United States",
  },
  {
    sr: 17726,
    city: "Tanana",
    city_code: "TAL",
    airport_name: "Ralph Calhoun",
    country: "United States",
  },
  {
    sr: 17728,
    city: "Tanandava",
    city_code: "TDV",
    airport_name: "Tanandava",
    country: "Madagascar",
  },
  {
    sr: 17730,
    city: "Tanbar",
    city_code: "TXR",
    airport_name: "Tanbar",
    country: "Australia",
  },
  {
    sr: 17732,
    city: "Tanda Tula",
    city_code: "TDT",
    airport_name: "Tanda Tula",
    country: "South Africa",
  },
  {
    sr: 17734,
    city: "Tandag",
    city_code: "TDG",
    airport_name: "Tandag",
    country: "Philippines",
  },
  {
    sr: 17736,
    city: "Tandil",
    city_code: "TDL",
    airport_name: "Tandil",
    country: "Argentina",
  },
  {
    sr: 17738,
    city: "Tanegashima",
    city_code: "TNE",
    airport_name: "Tanegashima",
    country: "Japan",
  },
  {
    sr: 17740,
    city: "Tanga",
    city_code: "TGT",
    airport_name: "Tanga",
    country: "Tanzania",
  },
  {
    sr: 17742,
    city: "Tangalooma",
    city_code: "TAN",
    airport_name: "Tangalooma",
    country: "Australia",
  },
  {
    sr: 17744,
    city: "Tangara da Serra",
    city_code: "TGQ",
    airport_name: "Tangara da Serra",
    country: "Brazil",
  },
  {
    sr: 17748,
    city: "Tangier",
    city_code: "TNG",
    airport_name: "Boukhalef",
    country: "Morocco",
  },
  {
    sr: 17750,
    city: "Tanjung Balai",
    city_code: "TJB",
    airport_name: "Tanjung Balai",
    country: "Indonesia",
  },
  {
    sr: 17752,
    city: "Tanjung Pandan",
    city_code: "TJQ",
    airport_name: "Bulutumbang",
    country: "Indonesia",
  },
  {
    sr: 17754,
    city: "Tanjung Pelepas",
    city_code: "ZJT",
    airport_name: "Tanjung Pelepas Port",
    country: "Malaysia",
  },
  {
    sr: 17756,
    city: "Tanjung Pinang",
    city_code: "TNJ",
    airport_name: "Kidjang",
    country: "Indonesia",
  },
  {
    sr: 17758,
    city: "Tanjung Santan",
    city_code: "TSX",
    airport_name: "Tanjung Santan",
    country: "Indonesia",
  },
  {
    sr: 17760,
    city: "Tanjung Selor",
    city_code: "TJS",
    airport_name: "Tanjung Selor",
    country: "Indonesia",
  },
  {
    sr: 17762,
    city: "Tanjung Warukin",
    city_code: "TJG",
    airport_name: "Tanjung Warukin",
    country: "Indonesia",
  },
  {
    sr: 17764,
    city: "Tanna",
    city_code: "TAH",
    airport_name: "Tanna",
    country: "Vanuatu",
  },
  {
    sr: 17766,
    city: "Taormina",
    city_code: "TFC",
    airport_name: "Taormina Harbour",
    country: "Italy",
  },
  {
    sr: 17768,
    city: "Taos",
    city_code: "TSM",
    airport_name: "Taos",
    country: "United States",
  },
  {
    sr: 17770,
    city: "Tapachula",
    city_code: "TAP",
    airport_name: "Tapachula International",
    country: "Mexico",
  },
  {
    sr: 17772,
    city: "Tapaktuan",
    city_code: "TPK",
    airport_name: "Tapaktuan",
    country: "Indonesia",
  },
  {
    sr: 17774,
    city: "Tapeta",
    city_code: "TPT",
    airport_name: "Tapeta",
    country: "Liberia",
  },
  {
    sr: 17776,
    city: "Tapini",
    city_code: "TPI",
    airport_name: "Tapini",
    country: "Papua New Guinea",
  },
  {
    sr: 17778,
    city: "Taplejung",
    city_code: "TPJ",
    airport_name: "Taplejung Suketar",
    country: "Nepal",
  },
  {
    sr: 17782,
    city: "Tara",
    city_code: "XTR",
    airport_name: "Tara",
    country: "Australia",
  },
  {
    sr: 17784,
    city: "Tarabo",
    city_code: "TBQ",
    airport_name: "Tarabo",
    country: "Papua New Guinea",
  },
  {
    sr: 17786,
    city: "Tarakan",
    city_code: "TRK",
    airport_name: "Tarakan",
    country: "Indonesia",
  },
  {
    sr: 17788,
    city: "Tarakbits",
    city_code: "TRJ",
    airport_name: "Tarakbits",
    country: "Papua New Guinea",
  },
  {
    sr: 17790,
    city: "Taramajima",
    city_code: "TRA",
    airport_name: "Tarama",
    country: "Japan",
  },
  {
    sr: 17792,
    city: "Taranto",
    city_code: "TAR",
    airport_name: "M A Grottag",
    country: "Italy",
  },
  {
    sr: 17794,
    city: "Tarapaca",
    city_code: "TCD",
    airport_name: "Tarapaca",
    country: "Colombia",
  },
  {
    sr: 17796,
    city: "Tarapaina",
    city_code: "TAA",
    airport_name: "Tarapaina",
    country: "Solomon Islands",
  },
  {
    sr: 17798,
    city: "Tarapoa",
    city_code: "TPC",
    airport_name: "Tarapoa",
    country: "Ecuador",
  },
  {
    sr: 17800,
    city: "Tarapoto",
    city_code: "TPP",
    airport_name: "Tarapoto",
    country: "Peru",
  },
  {
    sr: 17802,
    city: "Tarauaca",
    city_code: "TRQ",
    airport_name: "Tarauaca",
    country: "Brazil",
  },
  {
    sr: 17804,
    city: "Tarawa",
    city_code: "TRW",
    airport_name: "Bonriki",
    country: "Kiribati",
  },
  {
    sr: 17806,
    city: "Tarbela",
    city_code: "TLB",
    airport_name: "Tarbela",
    country: "Pakistan",
  },
  {
    sr: 17808,
    city: "Tarcoola",
    city_code: "TAQ",
    airport_name: "Tarcoola",
    country: "Australia",
  },
  {
    sr: 17810,
    city: "Taree",
    city_code: "TRO",
    airport_name: "Taree",
    country: "Australia",
  },
  {
    sr: 17812,
    city: "Tarfaya",
    city_code: "TFY",
    airport_name: "Tarfaya",
    country: "Morocco",
  },
  {
    sr: 17816,
    city: "Targovishte",
    city_code: "TGV",
    airport_name: "Targovishte",
    country: "Bulgaria",
  },
  {
    sr: 17818,
    city: "Tari",
    city_code: "TIZ",
    airport_name: "Tari",
    country: "Papua New Guinea",
  },
  {
    sr: 17820,
    city: "Tarija",
    city_code: "TJA",
    airport_name: "Tarija",
    country: "Bolivia",
  },
  {
    sr: 17822,
    city: "Taroom",
    city_code: "XTO",
    airport_name: "Taroom",
    country: "Australia",
  },
  {
    sr: 17824,
    city: "Tartagal",
    city_code: "TTG",
    airport_name: "Tartagal",
    country: "Argentina",
  },
  {
    sr: 17826,
    city: "Tartu",
    city_code: "TAY",
    airport_name: "Tartu",
    country: "Estonia",
  },
  {
    sr: 17828,
    city: "Tashkent",
    city_code: "TAS",
    airport_name: "Vostochny",
    country: "Uzbekistan",
  },
  {
    sr: 17830,
    city: "Tasiilaq",
    city_code: "AGM",
    airport_name: "Tasiilaq",
    country: "Greenland",
  },
  {
    sr: 17832,
    city: "Tasikmalaya",
    city_code: "TSY",
    airport_name: "Tasikmalaya",
    country: "Indonesia",
  },
  {
    sr: 17834,
    city: "Tasiuasaq",
    city_code: "XEQ",
    airport_name: "Harbour",
    country: "Greenland",
  },
  {
    sr: 17836,
    city: "Tasiujuaq",
    city_code: "YTQ",
    airport_name: "Tasiujuaq",
    country: "Canada",
  },
  {
    sr: 17838,
    city: "Taskul",
    city_code: "TSK",
    airport_name: "Taskul",
    country: "Papua New Guinea",
  },
  {
    sr: 17840,
    city: "Tasu",
    city_code: "YTU",
    airport_name: "Tasu",
    country: "Canada",
  },
  {
    sr: 17842,
    city: "Tatakoto",
    city_code: "TKV",
    airport_name: "Tatakoto",
    country: "French Polynesia",
  },
  {
    sr: 17844,
    city: "Tatalina",
    city_code: "TLJ",
    airport_name: "Tatalina AFS",
    country: "United States",
  },
  {
    sr: 17846,
    city: "Tatitlek",
    city_code: "TEK",
    airport_name: "Tatitlek",
    country: "United States",
  },
  {
    sr: 17850,
    city: "Tau",
    city_code: "TAV",
    airport_name: "Tau",
    country: "American Samoa",
  },
  {
    sr: 17852,
    city: "Taupo",
    city_code: "TUO",
    airport_name: "Taupo",
    country: "New Zealand",
  },
  {
    sr: 17854,
    city: "Tauramena",
    city_code: "TAU",
    airport_name: "Tauramena",
    country: "Colombia",
  },
  {
    sr: 17856,
    city: "Tauranga",
    city_code: "TRG",
    airport_name: "Tauranga City",
    country: "New Zealand",
  },
  {
    sr: 17858,
    city: "Tauta",
    city_code: "TUT",
    airport_name: "Tauta",
    country: "Papua New Guinea",
  },
  {
    sr: 17860,
    city: "Taveuni",
    city_code: "TVU",
    airport_name: "Matei",
    country: "Fiji",
  },
  {
    sr: 17862,
    city: "Tawa",
    city_code: "TWY",
    airport_name: "Tawa",
    country: "Papua New Guinea",
  },
  {
    sr: 17864,
    city: "Tawau",
    city_code: "TWU",
    airport_name: "Tawau",
    country: "Malaysia",
  },
  {
    sr: 17866,
    city: "Tawitawi",
    city_code: "TWT",
    airport_name: "Tawitawi",
    country: "Philippines",
  },
  {
    sr: 17868,
    city: "Taylor",
    city_code: "TWE",
    airport_name: "Taylor",
    country: "United States",
  },
  {
    sr: 17870,
    city: "Taylor",
    city_code: "TYZ",
    airport_name: "Taylor",
    country: "United States",
  },
  {
    sr: 17872,
    city: "Taytay Sandoval",
    city_code: "RZP",
    airport_name: "CLR Airport",
    country: "Philippines",
  },
  {
    sr: 17874,
    city: "Tbessa",
    city_code: "TEE",
    airport_name: "Tbessa",
    country: "Algeria",
  },
  {
    sr: 17876,
    city: "Tbilisi",
    city_code: "TBS",
    airport_name: "Novo Alexeyevka",
    country: "Georgia",
  },
  {
    sr: 17878,
    city: "Tchibanga",
    city_code: "TCH",
    airport_name: "Tchibanga",
    country: "Gabon",
  },
  {
    sr: 17880,
    city: "Tchien",
    city_code: "THC",
    airport_name: "Tchien",
    country: "Liberia",
  },
  {
    sr: 17884,
    city: "Te Anau",
    city_code: "TEU",
    airport_name: "Manapouri",
    country: "New Zealand",
  },
  {
    sr: 17886,
    city: "Tefe",
    city_code: "TFF",
    airport_name: "Tefe",
    country: "Brazil",
  },
  {
    sr: 17888,
    city: "Tegucigalpa",
    city_code: "TGU",
    airport_name: "Toncontin",
    country: "Honduras",
  },
  {
    sr: 17890,
    city: "Tehachapi",
    city_code: "TSP",
    airport_name: "Kern County",
    country: "United States",
  },
  {
    sr: 17892,
    city: "Tehran",
    city_code: "THR",
    airport_name: "Mehrabad",
    country: "Iran",
  },
  {
    sr: 17894,
    city: "Tehuacan",
    city_code: "TCN",
    airport_name: "Tehuacan",
    country: "Mexico",
  },
  {
    sr: 17896,
    city: "Teixeira de Freitas",
    city_code: "TXF",
    airport_name: "Teixeira de Freitas",
    country: "Brazil",
  },
  {
    sr: 17898,
    city: "Tekadu",
    city_code: "TKB",
    airport_name: "Tekadu",
    country: "Papua New Guinea",
  },
  {
    sr: 17900,
    city: "Tekin",
    city_code: "TKW",
    airport_name: "Tekin",
    country: "Papua New Guinea",
  },
  {
    sr: 17902,
    city: "Tekirdag",
    city_code: "TEQ",
    airport_name: "Corlu",
    country: "Turkey",
  },
  {
    sr: 17904,
    city: "Tel Aviv Yafo",
    city_code: "TLV",
    airport_name: "Ben Gurion Intl",
    country: "Israel",
  },
  {
    sr: 17906,
    city: "Tel Aviv Yafo",
    city_code: "SDV",
    airport_name: "Sde Dov",
    country: "Israel",
  },
  {
    sr: 17908,
    city: "Tela",
    city_code: "TEA",
    airport_name: "Tela",
    country: "Honduras",
  },
  {
    sr: 17910,
    city: "Telefomin",
    city_code: "TFM",
    airport_name: "Telefomin",
    country: "Papua New Guinea",
  },
  {
    sr: 17912,
    city: "Telegraph Creek",
    city_code: "YTX",
    airport_name: "Telegraph Creek",
    country: "Canada",
  },
  {
    sr: 17914,
    city: "Telegraph Harbour",
    city_code: "YBQ",
    airport_name: "Telegraph Harbour",
    country: "Canada",
  },
  {
    sr: 17918,
    city: "Telemaco Borba",
    city_code: "TEC",
    airport_name: "Telemaco Borba",
    country: "Brazil",
  },
  {
    sr: 17920,
    city: "Telfer",
    city_code: "TEF",
    airport_name: "Telfer",
    country: "Australia",
  },
  {
    sr: 17922,
    city: "Telida",
    city_code: "TLF",
    airport_name: "Telida",
    country: "United States",
  },
  {
    sr: 17924,
    city: "Teller",
    city_code: "TLA",
    airport_name: "Teller",
    country: "United States",
  },
  {
    sr: 17926,
    city: "Teller Mission",
    city_code: "KTS",
    airport_name: "Brevig Mission",
    country: "United States",
  },
  {
    sr: 17928,
    city: "Telluride",
    city_code: "TEX",
    airport_name: "Telluride",
    country: "United States",
  },
  {
    sr: 17930,
    city: "Telluride",
    city_code: "ZTL",
    airport_name: "Bus Station",
    country: "United States",
  },
  {
    sr: 17932,
    city: "Telupid",
    city_code: "TEL",
    airport_name: "Telupid",
    country: "Malaysia",
  },
  {
    sr: 17934,
    city: "Tembagapura",
    city_code: "TIM",
    airport_name: "Timika",
    country: "Indonesia",
  },
  {
    sr: 17936,
    city: "Teminabuan",
    city_code: "TXM",
    airport_name: "Teminabuan",
    country: "Indonesia",
  },
  {
    sr: 17938,
    city: "Temora",
    city_code: "TEM",
    airport_name: "Temora",
    country: "Australia",
  },
  {
    sr: 17940,
    city: "Temple",
    city_code: "TPL",
    airport_name: "Draughon Miller",
    country: "United States",
  },
  {
    sr: 17942,
    city: "Temuco",
    city_code: "ZCO",
    airport_name: "Maquehue",
    country: "Chile",
  },
  {
    sr: 17944,
    city: "Tenakee Springs",
    city_code: "TKE",
    airport_name: "Tenakee SPB",
    country: "United States",
  },
  {
    sr: 17946,
    city: "Tenerife",
    city_code: "TFN",
    airport_name: "Norte Los Rodeos",
    country: "Spain",
  },
  {
    sr: 17948,
    city: "Tenerife",
    city_code: "TFS",
    airport_name: "Sur Reina Sofia",
    country: "Spain",
  },
  {
    sr: 17954,
    city: "Tenkodogo",
    city_code: "TEG",
    airport_name: "Tenkodogo",
    country: "Burkina Faso",
  },
  {
    sr: 17956,
    city: "Tennant Creek",
    city_code: "TCA",
    airport_name: "Tennant Creek",
    country: "Australia",
  },
  {
    sr: 17958,
    city: "Teofilo Otoni",
    city_code: "TFL",
    airport_name: "Teofilo Otoni",
    country: "Brazil",
  },
  {
    sr: 17960,
    city: "Tepic",
    city_code: "TPQ",
    airport_name: "Tepic",
    country: "Mexico",
  },
  {
    sr: 17962,
    city: "Teptep",
    city_code: "TEP",
    airport_name: "Teptep",
    country: "Papua New Guinea",
  },
  {
    sr: 17964,
    city: "Teraina",
    city_code: "TNQ",
    airport_name: "Teraina",
    country: "Kiribati",
  },
  {
    sr: 17966,
    city: "Terapo",
    city_code: "TEO",
    airport_name: "Terapo",
    country: "Papua New Guinea",
  },
  {
    sr: 17968,
    city: "Terceira Island",
    city_code: "TER",
    airport_name: "Lajes",
    country: "Portugal",
  },
  {
    sr: 17970,
    city: "Teresina",
    city_code: "THE",
    airport_name: "Teresina",
    country: "Brazil",
  },
  {
    sr: 17972,
    city: "Termez",
    city_code: "TMJ",
    airport_name: "Termez",
    country: "Uzbekistan",
  },
  {
    sr: 17974,
    city: "Ternate",
    city_code: "TTE",
    airport_name: "Babullah",
    country: "Indonesia",
  },
  {
    sr: 17976,
    city: "Ternopol",
    city_code: "TNL",
    airport_name: "Ternopol",
    country: "Ukraine",
  },
  {
    sr: 17978,
    city: "Terrace",
    city_code: "YXT",
    airport_name: "Terrace",
    country: "Canada",
  },
  {
    sr: 17980,
    city: "Terrace Bay",
    city_code: "TCY",
    airport_name: "Terrace Bay",
    country: "Namibia",
  },
  {
    sr: 17982,
    city: "Terrace Bay",
    city_code: "YTJ",
    airport_name: "Terrace Bay",
    country: "Canada",
  },
  {
    sr: 17986,
    city: "Terre Haute",
    city_code: "HUF",
    airport_name: "Hulman Field",
    country: "United States",
  },
  {
    sr: 17988,
    city: "Terre de Bas",
    city_code: "HTB",
    airport_name: "Terre de Bas",
    country: "Guadeloupe",
  },
  {
    sr: 17990,
    city: "Terre de Haut",
    city_code: "LSS",
    airport_name: "Terre de Haut",
    country: "Guadeloupe",
  },
  {
    sr: 17992,
    city: "Terrell",
    city_code: "TRL",
    airport_name: "Terrell Field",
    country: "United States",
  },
  {
    sr: 17994,
    city: "Teslin",
    city_code: "YZW",
    airport_name: "Teslin",
    country: "Canada",
  },
  {
    sr: 17998,
    city: "Tetabedi",
    city_code: "TDB",
    airport_name: "Tetabedi",
    country: "Papua New Guinea",
  },
  {
    sr: 18000,
    city: "Tete",
    city_code: "TCV",
    airport_name: "Tete",
    country: "Mozambique",
  },
  {
    sr: 18002,
    city: "Tete",
    city_code: "TET",
    airport_name: "Matunda",
    country: "Mozambique",
  },
  {
    sr: 18004,
    city: "Tete a La Baleine",
    city_code: "ZTB",
    airport_name: "Tete a La Baleine",
    country: "Canada",
  },
  {
    sr: 18006,
    city: "Teterboro",
    city_code: "TEB",
    airport_name: "Teterboro",
    country: "United States",
  },
  {
    sr: 18008,
    city: "Tetiaroa Is",
    city_code: "TTI",
    airport_name: "Tetiaroa Is",
    country: "French Polynesia",
  },
  {
    sr: 18010,
    city: "Tetlin",
    city_code: "TEH",
    airport_name: "Tetlin",
    country: "United States",
  },
  {
    sr: 18012,
    city: "Tetuan",
    city_code: "TTU",
    airport_name: "Sania Ramel",
    country: "Morocco",
  },
  {
    sr: 18014,
    city: "Tewantin",
    city_code: "TWN",
    airport_name: "Tewantin",
    country: "Australia",
  },
  {
    sr: 18016,
    city: "Texarkana",
    city_code: "TXK",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 18020,
    city: "Tezpur",
    city_code: "TEZ",
    airport_name: "Salonibari",
    country: "India",
  },
  {
    sr: 18022,
    city: "Tezu",
    city_code: "TEI",
    airport_name: "Tezu",
    country: "India",
  },
  {
    sr: 18024,
    city: "Thaba Nchu",
    city_code: "TCU",
    airport_name: "Thaba Nchu",
    country: "South Africa",
  },
  {
    sr: 18026,
    city: "Thaba Tseka",
    city_code: "THB",
    airport_name: "Thaba Tseka",
    country: "Lesotho",
  },
  {
    sr: 18028,
    city: "Thakhek",
    city_code: "THK",
    airport_name: "Thakhek",
    country: "Laos",
  },
  {
    sr: 18030,
    city: "Thakurgaon",
    city_code: "TKR",
    airport_name: "Thakurgaon",
    country: "Bangladesh",
  },
  {
    sr: 18032,
    city: "Thames",
    city_code: "TMZ",
    airport_name: "Thames",
    country: "New Zealand",
  },
  {
    sr: 18034,
    city: "Thandwe",
    city_code: "SNW",
    airport_name: "Thandwe",
    country: "Myanmar",
  },
  {
    sr: 18036,
    city: "Thangool",
    city_code: "THG",
    airport_name: "Thangool",
    country: "Australia",
  },
  {
    sr: 18038,
    city: "Thanjavur",
    city_code: "TJV",
    airport_name: "Thanjavur",
    country: "India",
  },
  {
    sr: 18040,
    city: "Thargomindah",
    city_code: "XTG",
    airport_name: "Thargomindah",
    country: "Australia",
  },
  {
    sr: 18042,
    city: "The Bight",
    city_code: "TBI",
    airport_name: "New Bright",
    country: "Bahamas",
  },
  {
    sr: 18044,
    city: "The Dalles",
    city_code: "DLS",
    airport_name: "The Dalles",
    country: "United States",
  },
  {
    sr: 18046,
    city: "The Hague",
    city_code: "ZYH",
    airport_name: "Holland Spoor Rail Stn",
    country: "Netherlands",
  },
  {
    sr: 18048,
    city: "The Pas",
    city_code: "YQD",
    airport_name: "The Pas",
    country: "Canada",
  },
  {
    sr: 18050,
    city: "Theda",
    city_code: "TDN",
    airport_name: "Theda Station",
    country: "Australia",
  },
  {
    sr: 18054,
    city: "Theodore",
    city_code: "TDR",
    airport_name: "Theodore",
    country: "Australia",
  },
  {
    sr: 18056,
    city: "Thermal",
    city_code: "TRM",
    airport_name: "Thermal",
    country: "United States",
  },
  {
    sr: 18058,
    city: "Thermopolis",
    city_code: "THP",
    airport_name: "Hot Springs",
    country: "United States",
  },
  {
    sr: 18060,
    city: "Thessaloniki",
    city_code: "SKG",
    airport_name: "Thessaloniki International Airport Makedonia",
    country: "Greece",
  },
  {
    sr: 18062,
    city: "Thicket Portage",
    city_code: "YTD",
    airport_name: "Thicket Portage",
    country: "Canada",
  },
  {
    sr: 18064,
    city: "Thief River Falls",
    city_code: "TVF",
    airport_name: "Regional",
    country: "United States",
  },
  {
    sr: 18066,
    city: "Thingeyri",
    city_code: "TEY",
    airport_name: "Thingeyri",
    country: "Iceland",
  },
  {
    sr: 18068,
    city: "Thira",
    city_code: "JTR",
    airport_name: "Thira",
    country: "Greece",
  },
  {
    sr: 18070,
    city: "Thiruvananthapuram",
    city_code: "TRV",
    airport_name: "International",
    country: "India",
  },
  {
    sr: 18072,
    city: "Thisted",
    city_code: "TED",
    airport_name: "Thisted",
    country: "Denmark",
  },
  {
    sr: 18074,
    city: "Thohoyandou",
    city_code: "THY",
    airport_name: "Thohoyandou",
    country: "South Africa",
  },
  {
    sr: 18076,
    city: "Thomasville",
    city_code: "TVI",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 18078,
    city: "Thompson",
    city_code: "YTH",
    airport_name: "Thompson",
    country: "Canada",
  },
  {
    sr: 18080,
    city: "Thompsonfield",
    city_code: "THM",
    airport_name: "Thompsonfield",
    country: "United States",
  },
  {
    sr: 18082,
    city: "Thorne Bay",
    city_code: "KTB",
    airport_name: "Thorne Bay",
    country: "United States",
  },
  {
    sr: 18084,
    city: "Thorshofn",
    city_code: "THO",
    airport_name: "Thorshofn",
    country: "Iceland",
  },
  {
    sr: 18088,
    city: "Thousand Oaks",
    city_code: "JTO",
    airport_name: "Heliport",
    country: "United States",
  },
  {
    sr: 18090,
    city: "Three Rivers",
    city_code: "HAI",
    airport_name: "Dr Haines",
    country: "United States",
  },
  {
    sr: 18092,
    city: "Thumrait",
    city_code: "TTH",
    airport_name: "Thumrait",
    country: "Oman",
  },
  {
    sr: 18094,
    city: "Thunder Bay",
    city_code: "YQT",
    airport_name: "Thunder Bay",
    country: "Canada",
  },
  {
    sr: 18096,
    city: "Thursday Island",
    city_code: "TIS",
    airport_name: "Thursday Island",
    country: "Australia",
  },
  {
    sr: 18098,
    city: "Thylungra",
    city_code: "TYG",
    airport_name: "Thylungra",
    country: "Australia",
  },
  {
    sr: 18100,
    city: "Tianjin",
    city_code: "TSN",
    airport_name: "Tianjin",
    country: "China",
  },
  {
    sr: 18102,
    city: "Tianjin",
    city_code: "XTA",
    airport_name: "Econ Tech Dev Area",
    country: "China",
  },
  {
    sr: 18104,
    city: "Tianjin",
    city_code: "XTF",
    airport_name: "Port Free Trade Zone",
    country: "China",
  },
  {
    sr: 18106,
    city: "Tiaret",
    city_code: "TID",
    airport_name: "Bouchekif",
    country: "Algeria",
  },
  {
    sr: 18108,
    city: "Tibooburra",
    city_code: "TYB",
    airport_name: "Tibooburra",
    country: "Australia",
  },
  {
    sr: 18110,
    city: "Tibu",
    city_code: "TIB",
    airport_name: "Tibu",
    country: "Colombia",
  },
  {
    sr: 18112,
    city: "Ticantiki",
    city_code: "TJC",
    airport_name: "Ticantiki",
    country: "Panama",
  },
  {
    sr: 18114,
    city: "Tichitt",
    city_code: "THI",
    airport_name: "Tichitt",
    country: "Mauritania",
  },
  {
    sr: 18116,
    city: "Tidjikja",
    city_code: "TIY",
    airport_name: "Tidjikja",
    country: "Mauritania",
  },
  {
    sr: 18118,
    city: "Tifalmin",
    city_code: "TFB",
    airport_name: "Tifalmin",
    country: "Papua New Guinea",
  },
  {
    sr: 18122,
    city: "Tifton",
    city_code: "TMA",
    airport_name: "Henry Tift Myers",
    country: "United States",
  },
  {
    sr: 18124,
    city: "Tiga",
    city_code: "TGJ",
    airport_name: "Tiga",
    country: "New Caledonia",
  },
  {
    sr: 18126,
    city: "Tignes",
    city_code: "TGF",
    airport_name: "Tignes",
    country: "France",
  },
  {
    sr: 18128,
    city: "Tijuana",
    city_code: "TIJ",
    airport_name: "Rodriguez",
    country: "Mexico",
  },
  {
    sr: 18130,
    city: "Tikal",
    city_code: "TKM",
    airport_name: "El Peten",
    country: "Guatemala",
  },
  {
    sr: 18132,
    city: "Tikapur",
    city_code: "TPU",
    airport_name: "Tikapur",
    country: "Nepal",
  },
  {
    sr: 18134,
    city: "Tikchik",
    city_code: "KTH",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 18136,
    city: "Tikehau Atoll",
    city_code: "TIH",
    airport_name: "Tikehau Atoll",
    country: "French Polynesia",
  },
  {
    sr: 18138,
    city: "Tiko",
    city_code: "TKC",
    airport_name: "Tiko",
    country: "Cameroon",
  },
  {
    sr: 18140,
    city: "Tikrit",
    city_code: "XTV",
    airport_name: "Tikrit",
    country: "Iraq",
  },
  {
    sr: 18142,
    city: "Tiksi",
    city_code: "IKS",
    airport_name: "Tiksi",
    country: "Russia",
  },
  {
    sr: 18144,
    city: "Tilin",
    city_code: "TIO",
    airport_name: "Tilin",
    country: "Myanmar",
  },
  {
    sr: 18146,
    city: "Timaru",
    city_code: "TIU",
    airport_name: "Timaru",
    country: "New Zealand",
  },
  {
    sr: 18148,
    city: "Timbedra",
    city_code: "TMD",
    airport_name: "Timbedra",
    country: "Mauritania",
  },
  {
    sr: 18150,
    city: "Timber Creek",
    city_code: "TBK",
    airport_name: "Timber Creek",
    country: "Australia",
  },
  {
    sr: 18152,
    city: "Timbiqui",
    city_code: "TBD",
    airport_name: "Timbiqui",
    country: "Colombia",
  },
  {
    sr: 18156,
    city: "Timbunke",
    city_code: "TBE",
    airport_name: "Timbunke",
    country: "Papua New Guinea",
  },
  {
    sr: 18158,
    city: "Timimoun",
    city_code: "TMX",
    airport_name: "Timimoun",
    country: "Algeria",
  },
  {
    sr: 18160,
    city: "Timisoara",
    city_code: "TSR",
    airport_name: "Timisoara  Traian Vuia  International",
    country: "Romania",
  },
  {
    sr: 18162,
    city: "Timmins",
    city_code: "YTS",
    airport_name: "Timmins",
    country: "Canada",
  },
  {
    sr: 18164,
    city: "Tin City",
    city_code: "TNC",
    airport_name: "Tin City AFS",
    country: "United States",
  },
  {
    sr: 18166,
    city: "Tinak Island",
    city_code: "TIC",
    airport_name: "Tinak Island",
    country: "Marshall Islands",
  },
  {
    sr: 18168,
    city: "Tinboli",
    city_code: "TCK",
    airport_name: "Tinboli Airport",
    country: "Papua New Guinea",
  },
  {
    sr: 18170,
    city: "Tindouf",
    city_code: "TIN",
    airport_name: "Tindouf",
    country: "Algeria",
  },
  {
    sr: 18172,
    city: "Tingo Maria",
    city_code: "TGI",
    airport_name: "Tingo Maria",
    country: "Peru",
  },
  {
    sr: 18174,
    city: "Tingrela",
    city_code: "TGX",
    airport_name: "Tingrela",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 18176,
    city: "Tingwon",
    city_code: "TIG",
    airport_name: "Tingwon",
    country: "Papua New Guinea",
  },
  {
    sr: 18178,
    city: "Tinian",
    city_code: "TIQ",
    airport_name: "Tinian",
    country: "Northern Mariana Islands",
  },
  {
    sr: 18180,
    city: "Tioga",
    city_code: "VEX",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 18182,
    city: "Tiom",
    city_code: "TMY",
    airport_name: "Tiom",
    country: "Indonesia",
  },
  {
    sr: 18184,
    city: "Tioman",
    city_code: "TOD",
    airport_name: "Tioman",
    country: "Malaysia",
  },
  {
    sr: 18186,
    city: "Tippi",
    city_code: "TIE",
    airport_name: "Tippi",
    country: "Ethiopia",
  },
  {
    sr: 18190,
    city: "Tiputini",
    city_code: "TPN",
    airport_name: "Tiputini",
    country: "Ecuador",
  },
  {
    sr: 18192,
    city: "Tirana",
    city_code: "TIA",
    airport_name: "Rinas",
    country: "Albania",
  },
  {
    sr: 18194,
    city: "Tiree",
    city_code: "TRE",
    airport_name: "Tiree",
    country: "United Kingdom",
  },
  {
    sr: 18196,
    city: "Tirgu Mures",
    city_code: "TGM",
    airport_name: "Tirgu Mures",
    country: "Romania",
  },
  {
    sr: 18198,
    city: "Tirinkot",
    city_code: "TII",
    airport_name: "Tirinkot",
    country: "Afghanistan",
  },
  {
    sr: 18200,
    city: "Tiruchirapally",
    city_code: "TRZ",
    airport_name: "Civil",
    country: "India",
  },
  {
    sr: 18202,
    city: "Tirupati",
    city_code: "TIR",
    airport_name: "Tirupati",
    country: "India",
  },
  {
    sr: 18204,
    city: "Tisdale",
    city_code: "YTT",
    airport_name: "Tisdale",
    country: "Canada",
  },
  {
    sr: 18206,
    city: "Titusville",
    city_code: "TIX",
    airport_name: "Space Center Exect",
    country: "United States",
  },
  {
    sr: 18208,
    city: "Tivat",
    city_code: "TIV",
    airport_name: "Tivat",
    country: "Serbia",
  },
  {
    sr: 18210,
    city: "Tizimin",
    city_code: "TZM",
    airport_name: "Tizimin",
    country: "Mexico",
  },
  {
    sr: 18212,
    city: "Tlemcen",
    city_code: "TLM",
    airport_name: "Zenata",
    country: "Algeria",
  },
  {
    sr: 18214,
    city: "Tlokoeng",
    city_code: "TKO",
    airport_name: "Tlokoeng",
    country: "Lesotho",
  },
  {
    sr: 18216,
    city: "Tobago",
    city_code: "TAB",
    airport_name: "Crown Point Airport",
    country: "Trinidad and Tobago",
  },
  {
    sr: 18218,
    city: "Tobermorey",
    city_code: "TYP",
    airport_name: "Tobermorey",
    country: "Australia",
  },
  {
    sr: 18220,
    city: "Tobolsk",
    city_code: "TOX",
    airport_name: "Tobolsk",
    country: "Russia",
  },
  {
    sr: 18224,
    city: "Tobruk",
    city_code: "TOB",
    airport_name: "Tobruk",
    country: "Libya",
  },
  {
    sr: 18226,
    city: "Toccoa",
    city_code: "TOC",
    airport_name: "Toccoa",
    country: "United States",
  },
  {
    sr: 18228,
    city: "Tocoa",
    city_code: "TCF",
    airport_name: "Tocoa",
    country: "Honduras",
  },
  {
    sr: 18230,
    city: "Tocopilla",
    city_code: "TOQ",
    airport_name: "Barriles",
    country: "Chile",
  },
  {
    sr: 18232,
    city: "Tocumwal",
    city_code: "TCW",
    airport_name: "Tocumwal",
    country: "Australia",
  },
  {
    sr: 18234,
    city: "Tofino",
    city_code: "YTP",
    airport_name: "Seaplane Base",
    country: "Canada",
  },
  {
    sr: 18236,
    city: "Tofino",
    city_code: "YAZ",
    airport_name: "Tofino Airport",
    country: "Canada",
  },
  {
    sr: 18238,
    city: "Togiak Fish",
    city_code: "GFB",
    airport_name: "Togiak Fish",
    country: "United States",
  },
  {
    sr: 18240,
    city: "Togiak Village",
    city_code: "TOG",
    airport_name: "Togiak Village",
    country: "United States",
  },
  {
    sr: 18242,
    city: "Tohid",
    city_code: "TEW",
    airport_name: "Tohid",
    country: "Iran",
  },
  {
    sr: 18244,
    city: "Tok",
    city_code: "TKJ",
    airport_name: "Tok",
    country: "United States",
  },
  {
    sr: 18246,
    city: "Tokat",
    city_code: "TJK",
    airport_name: "Tokat",
    country: "Turkey",
  },
  {
    sr: 18248,
    city: "Tokeen",
    city_code: "TKI",
    airport_name: "Tokeen",
    country: "United States",
  },
  {
    sr: 18250,
    city: "Tokoroa",
    city_code: "TKZ",
    airport_name: "Tokoroa",
    country: "New Zealand",
  },
  {
    sr: 18252,
    city: "Toksook Bay",
    city_code: "OOK",
    airport_name: "Toksook Bay",
    country: "United States",
  },
  {
    sr: 18254,
    city: "Tokunoshima",
    city_code: "TKN",
    airport_name: "Tokunoshima",
    country: "Japan",
  },
  {
    sr: 18258,
    city: "Tokushima",
    city_code: "TKS",
    airport_name: "Tokushima",
    country: "Japan",
  },
  {
    sr: 18260,
    city: "Tokyo",
    city_code: "HND",
    airport_name: "Haneda",
    country: "Japan",
  },
  {
    sr: 18262,
    city: "Tokyo",
    city_code: "NRT",
    airport_name: "Narita",
    country: "Japan",
  },
  {
    sr: 18264,
    city: "Tokyo",
    city_code: "OKO",
    airport_name: "Yokota AFB",
    country: "Japan",
  },
  {
    sr: 18266,
    city: "Tol",
    city_code: "TLO",
    airport_name: "Tol",
    country: "Papua New Guinea",
  },
  {
    sr: 18268,
    city: "Toledo",
    city_code: "TDZ",
    airport_name: "Toledo",
    country: "United States",
  },
  {
    sr: 18270,
    city: "Toledo",
    city_code: "TOL",
    airport_name: "Express",
    country: "United States",
  },
  {
    sr: 18272,
    city: "Toledo",
    city_code: "TOW",
    airport_name: "Toledo",
    country: "Brazil",
  },
  {
    sr: 18274,
    city: "Toledo",
    city_code: "TDO",
    airport_name: "Winlock",
    country: "United States",
  },
  {
    sr: 18276,
    city: "Tolitoli",
    city_code: "TLI",
    airport_name: "Tolitoli",
    country: "Indonesia",
  },
  {
    sr: 18278,
    city: "Tolu",
    city_code: "TLU",
    airport_name: "Tolu",
    country: "Colombia",
  },
  {
    sr: 18280,
    city: "Toluca",
    city_code: "TLC",
    airport_name: "Toluca",
    country: "Mexico",
  },
  {
    sr: 18282,
    city: "Tom Price",
    city_code: "TPR",
    airport_name: "Tom Price",
    country: "Australia",
  },
  {
    sr: 18284,
    city: "Tomanggong",
    city_code: "TMG",
    airport_name: "Tomanggong",
    country: "Malaysia",
  },
  {
    sr: 18286,
    city: "Tombouctou",
    city_code: "TOM",
    airport_name: "Tombouctou",
    country: "Mali",
  },
  {
    sr: 18288,
    city: "Toms River",
    city_code: "MJX",
    airport_name: "Robert J  Miller",
    country: "United States",
  },
  {
    sr: 18292,
    city: "Tomsk",
    city_code: "TOF",
    airport_name: "Tomsk",
    country: "Russia",
  },
  {
    sr: 18294,
    city: "Tonghua",
    city_code: "TNH",
    airport_name: "Tonghua Liuhe",
    country: "China",
  },
  {
    sr: 18296,
    city: "Tongliao",
    city_code: "TGO",
    airport_name: "Tongliao",
    country: "China",
  },
  {
    sr: 18298,
    city: "Tongoa",
    city_code: "TGH",
    airport_name: "Tongoa",
    country: "Vanuatu",
  },
  {
    sr: 18300,
    city: "Tongren",
    city_code: "TEN",
    airport_name: "Tongren",
    country: "China",
  },
  {
    sr: 18302,
    city: "Tonopah",
    city_code: "XSD",
    airport_name: "Test Range",
    country: "United States",
  },
  {
    sr: 18304,
    city: "Tonopah",
    city_code: "TPH",
    airport_name: "Tonopah Arpt",
    country: "United States",
  },
  {
    sr: 18306,
    city: "Tonsberg",
    city_code: "XKW",
    airport_name: "Tonsberg Rail St",
    country: "Norway",
  },
  {
    sr: 18308,
    city: "Tonu",
    city_code: "TON",
    airport_name: "Tonu",
    country: "Papua New Guinea",
  },
  {
    sr: 18310,
    city: "Toowoomba",
    city_code: "TWB",
    airport_name: "Toowoomba",
    country: "Australia",
  },
  {
    sr: 18312,
    city: "Topeka",
    city_code: "FOE",
    airport_name: "Forbes AFB",
    country: "United States",
  },
  {
    sr: 18314,
    city: "Topeka",
    city_code: "TOP",
    airport_name: "Philip Billard",
    country: "United States",
  },
  {
    sr: 18316,
    city: "Torembi",
    city_code: "TCJ",
    airport_name: "Torembi Airport",
    country: "Papua New Guinea",
  },
  {
    sr: 18318,
    city: "Torokina",
    city_code: "TOK",
    airport_name: "Torokina",
    country: "Papua New Guinea",
  },
  {
    sr: 18320,
    city: "Toronto",
    city_code: "YKZ",
    airport_name: "Buttonville Municipal",
    country: "Canada",
  },
  {
    sr: 18322,
    city: "Toronto",
    city_code: "YYZ",
    airport_name: "Toronto Lester B Pearson International",
    country: "Canada",
  },
  {
    sr: 18326,
    city: "Toronto",
    city_code: "YTZ",
    airport_name: "Toronto Island",
    country: "Canada",
  },
  {
    sr: 18328,
    city: "Tororo",
    city_code: "TRY",
    airport_name: "Tororo",
    country: "Uganda",
  },
  {
    sr: 18330,
    city: "Torrance",
    city_code: "TOA",
    airport_name: "Torrance",
    country: "United States",
  },
  {
    sr: 18332,
    city: "Torremolinos",
    city_code: "UTL",
    airport_name: "Torremolinos",
    country: "Spain",
  },
  {
    sr: 18334,
    city: "Torreon",
    city_code: "TRC",
    airport_name: "Torreon",
    country: "Mexico",
  },
  {
    sr: 18336,
    city: "Torres",
    city_code: "TSQ",
    airport_name: "Torres",
    country: "Brazil",
  },
  {
    sr: 18338,
    city: "Torres",
    city_code: "TOH",
    airport_name: "Torres Airstrip",
    country: "Vanuatu",
  },
  {
    sr: 18340,
    city: "Torrington",
    city_code: "TOR",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 18342,
    city: "Torsby",
    city_code: "TYF",
    airport_name: "Torsby Airport",
    country: "Sweden",
  },
  {
    sr: 18344,
    city: "Tortola",
    city_code: "RAD",
    airport_name: "Road Town",
    country: "Virgin Islands  British",
  },
  {
    sr: 18346,
    city: "Tortola",
    city_code: "TOV",
    airport_name: "West End SPB",
    country: "Virgin Islands  British",
  },
  {
    sr: 18348,
    city: "Tortoli",
    city_code: "TTB",
    airport_name: "Arbatax",
    country: "Italy",
  },
  {
    sr: 18350,
    city: "Tortuquero",
    city_code: "TTQ",
    airport_name: "Tortuquero",
    country: "Costa Rica",
  },
  {
    sr: 18352,
    city: "Torwood",
    city_code: "TWP",
    airport_name: "Torwood",
    country: "Australia",
  },
  {
    sr: 18354,
    city: "Tosontsengel",
    city_code: "TNZ",
    airport_name: "Tosontsengel",
    country: "Mongolia",
  },
  {
    sr: 18356,
    city: "Totness",
    city_code: "TOT",
    airport_name: "Coronie",
    country: "Suriname",
  },
  {
    sr: 18360,
    city: "Tottori",
    city_code: "TTJ",
    airport_name: "Tottori",
    country: "Japan",
  },
  {
    sr: 18362,
    city: "Touba",
    city_code: "TOZ",
    airport_name: "Touba",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 18364,
    city: "Tougan",
    city_code: "TUQ",
    airport_name: "Tougan",
    country: "Burkina Faso",
  },
  {
    sr: 18366,
    city: "Touggourt",
    city_code: "TGR",
    airport_name: "Touggourt",
    country: "Algeria",
  },
  {
    sr: 18368,
    city: "Touho",
    city_code: "TOU",
    airport_name: "Touho",
    country: "New Caledonia",
  },
  {
    sr: 18370,
    city: "Toulon",
    city_code: "TLN",
    airport_name: "Hyeres",
    country: "France",
  },
  {
    sr: 18372,
    city: "Toulon",
    city_code: "XZV",
    airport_name: "TGV Station",
    country: "France",
  },
  {
    sr: 18374,
    city: "Toulouse",
    city_code: "TLS",
    airport_name: "Blagnac",
    country: "France",
  },
  {
    sr: 18376,
    city: "Toulouse",
    city_code: "XYT",
    airport_name: "Montaudran",
    country: "France",
  },
  {
    sr: 18378,
    city: "Tour Sinai City",
    city_code: "ELT",
    airport_name: "Tour Sinai City",
    country: "Egypt",
  },
  {
    sr: 18380,
    city: "Tours",
    city_code: "TUF",
    airport_name: "St Symphorien",
    country: "France",
  },
  {
    sr: 18382,
    city: "Toussus le noble",
    city_code: "TNF",
    airport_name: "Toussus le noble",
    country: "France",
  },
  {
    sr: 18384,
    city: "Townsville",
    city_code: "TSV",
    airport_name: "Townsville International",
    country: "Australia",
  },
  {
    sr: 18386,
    city: "Toyama",
    city_code: "TOY",
    airport_name: "Toyama",
    country: "Japan",
  },
  {
    sr: 18388,
    city: "Toyooka",
    city_code: "TJH",
    airport_name: "Tajima",
    country: "Japan",
  },
  {
    sr: 18390,
    city: "Tozeur",
    city_code: "TOE",
    airport_name: "Tozeur",
    country: "Tunisia",
  },
  {
    sr: 18394,
    city: "Trabzon",
    city_code: "TZX",
    airport_name: "Trabzon",
    country: "Turkey",
  },
  {
    sr: 18396,
    city: "Trang",
    city_code: "TST",
    airport_name: "Trang",
    country: "Thailand",
  },
  {
    sr: 18398,
    city: "Trapani",
    city_code: "TPS",
    airport_name: "Birgi",
    country: "Italy",
  },
  {
    sr: 18400,
    city: "Traralgon",
    city_code: "TGN",
    airport_name: "La Trobe Regional",
    country: "Australia",
  },
  {
    sr: 18402,
    city: "Trat",
    city_code: "TDX",
    airport_name: "Trat",
    country: "Thailand",
  },
  {
    sr: 18404,
    city: "Traverse City",
    city_code: "TVC",
    airport_name: "Traverse City",
    country: "United States",
  },
  {
    sr: 18406,
    city: "Treasure Cay",
    city_code: "TCB",
    airport_name: "Treasure Cay",
    country: "Bahamas",
  },
  {
    sr: 18408,
    city: "Tree Point",
    city_code: "TRP",
    airport_name: "Coast Guard Heliport",
    country: "United States",
  },
  {
    sr: 18410,
    city: "Treinta y Tres",
    city_code: "TYT",
    airport_name: "Treinta y Tres",
    country: "Uruguay",
  },
  {
    sr: 18412,
    city: "Trelew",
    city_code: "REL",
    airport_name: "Trelew",
    country: "Argentina",
  },
  {
    sr: 18414,
    city: "Tremonton",
    city_code: "TRT",
    airport_name: "Tremonton",
    country: "United States",
  },
  {
    sr: 18416,
    city: "Trenton",
    city_code: "TRX",
    airport_name: "Memorial",
    country: "United States",
  },
  {
    sr: 18418,
    city: "Trenton",
    city_code: "YTR",
    airport_name: "Trenton",
    country: "Canada",
  },
  {
    sr: 18420,
    city: "Tres Arroyos",
    city_code: "OYO",
    airport_name: "Tres Arroyos",
    country: "Argentina",
  },
  {
    sr: 18422,
    city: "Tres Esquinas",
    city_code: "TQS",
    airport_name: "Tres Esquinas",
    country: "Colombia",
  },
  {
    sr: 18424,
    city: "Trieste",
    city_code: "TRS",
    airport_name: "Ronchi Dei Legionari",
    country: "Italy",
  },
  {
    sr: 18428,
    city: "Trincomalee",
    city_code: "TRR",
    airport_name: "China Bay",
    country: "Sri Lanka",
  },
  {
    sr: 18430,
    city: "Trinidad",
    city_code: "TDD",
    airport_name: "Trinidad",
    country: "Bolivia",
  },
  {
    sr: 18432,
    city: "Trinidad",
    city_code: "TDA",
    airport_name: "Trinidad",
    country: "Colombia",
  },
  {
    sr: 18434,
    city: "Trinidad",
    city_code: "TAD",
    airport_name: "Las Animas",
    country: "United States",
  },
  {
    sr: 18436,
    city: "Trinidad",
    city_code: "TND",
    airport_name: "Trinidad",
    country: "Cuba",
  },
  {
    sr: 18438,
    city: "Trinidad",
    city_code: "POS",
    airport_name: "Trinidad",
    country: "Trinidad and Tobago",
  },
  {
    sr: 18440,
    city: "Triple Island",
    city_code: "YTI",
    airport_name: "Triple Island",
    country: "Canada",
  },
  {
    sr: 18442,
    city: "Tripoli",
    city_code: "KYE",
    airport_name: "Kleyate",
    country: "Lebanon",
  },
  {
    sr: 18444,
    city: "Tripoli",
    city_code: "TIP",
    airport_name: "International",
    country: "Libya",
  },
  {
    sr: 18446,
    city: "Trois Rivieres",
    city_code: "YRQ",
    airport_name: "Trois Rivieres",
    country: "Canada",
  },
  {
    sr: 18448,
    city: "Trollhattan",
    city_code: "THN",
    airport_name: "Trollhattan",
    country: "Sweden",
  },
  {
    sr: 18450,
    city: "Trombetas",
    city_code: "TMT",
    airport_name: "Trombetas",
    country: "Brazil",
  },
  {
    sr: 18452,
    city: "Tromso",
    city_code: "TOS",
    airport_name: "Tromso langnes",
    country: "Norway",
  },
  {
    sr: 18454,
    city: "Trona",
    city_code: "TRH",
    airport_name: "Trona",
    country: "United States",
  },
  {
    sr: 18456,
    city: "Trondheim",
    city_code: "TRD",
    airport_name: "Vaernes",
    country: "Norway",
  },
  {
    sr: 18458,
    city: "Troutdale",
    city_code: "TTD",
    airport_name: "Troutdale",
    country: "United States",
  },
  {
    sr: 18462,
    city: "Troy",
    city_code: "TOI",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 18464,
    city: "Truckee",
    city_code: "TKF",
    airport_name: "Truckee",
    country: "United States",
  },
  {
    sr: 18466,
    city: "Trujillo",
    city_code: "TJI",
    airport_name: "Capiro",
    country: "Honduras",
  },
  {
    sr: 18468,
    city: "Trujillo",
    city_code: "TRU",
    airport_name: "Trujillo",
    country: "Peru",
  },
  {
    sr: 18470,
    city: "Truk",
    city_code: "TKK",
    airport_name: "Truk",
    country: "Micronesia",
  },
  {
    sr: 18472,
    city: "Truth Or Consequences",
    city_code: "TCS",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 18474,
    city: "Tsabong",
    city_code: "TBY",
    airport_name: "Tsabong",
    country: "Botswana",
  },
  {
    sr: 18476,
    city: "Tsaratanana",
    city_code: "TTS",
    airport_name: "Tsaratanana",
    country: "Madagascar",
  },
  {
    sr: 18478,
    city: "Tsetserleg",
    city_code: "TSZ",
    airport_name: "Tsetserleg",
    country: "Mongolia",
  },
  {
    sr: 18480,
    city: "Tsewi",
    city_code: "TSW",
    airport_name: "Tsewi",
    country: "Papua New Guinea",
  },
  {
    sr: 18484,
    city: "Tshipise",
    city_code: "TSD",
    airport_name: "Tshipise",
    country: "South Africa",
  },
  {
    sr: 18486,
    city: "Tsili Tsili",
    city_code: "TSI",
    airport_name: "Tsili Tsili",
    country: "Papua New Guinea",
  },
  {
    sr: 18488,
    city: "Tsiroanomandidy",
    city_code: "WTS",
    airport_name: "Tsiroanomandidy",
    country: "Madagascar",
  },
  {
    sr: 18490,
    city: "Tsumeb",
    city_code: "TSB",
    airport_name: "Tsumeb",
    country: "Namibia",
  },
  {
    sr: 18492,
    city: "Tsushima",
    city_code: "TSJ",
    airport_name: "Tsushima",
    country: "Japan",
  },
  {
    sr: 18496,
    city: "Tuba City",
    city_code: "TBC",
    airport_name: "Tuba City",
    country: "United States",
  },
  {
    sr: 18498,
    city: "Tubala",
    city_code: "TUW",
    airport_name: "Tubala",
    country: "Panama",
  },
  {
    sr: 18500,
    city: "Tubuai",
    city_code: "TUB",
    airport_name: "Tubuai",
    country: "French Polynesia",
  },
  {
    sr: 18502,
    city: "Tucson",
    city_code: "AVW",
    airport_name: "Avra Valley",
    country: "United States",
  },
  {
    sr: 18504,
    city: "Tucson",
    city_code: "DMA",
    airport_name: "Davis Monthan AFB",
    country: "United States",
  },
  {
    sr: 18506,
    city: "Tucson",
    city_code: "TUS",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 18508,
    city: "Tucuma",
    city_code: "TUZ",
    airport_name: "Tucuma",
    country: "Brazil",
  },
  {
    sr: 18510,
    city: "Tucuman",
    city_code: "TUC",
    airport_name: "Benj Matienzo",
    country: "Argentina",
  },
  {
    sr: 18512,
    city: "Tucumcari",
    city_code: "TCC",
    airport_name: "Tucumcari",
    country: "United States",
  },
  {
    sr: 18514,
    city: "Tucupita",
    city_code: "TUV",
    airport_name: "Tucupita",
    country: "Venezuela",
  },
  {
    sr: 18516,
    city: "Tucurui",
    city_code: "TUR",
    airport_name: "Tucurui",
    country: "Brazil",
  },
  {
    sr: 18518,
    city: "Tufi",
    city_code: "TFI",
    airport_name: "Tufi",
    country: "Papua New Guinea",
  },
  {
    sr: 18520,
    city: "Tuguegarao",
    city_code: "TUG",
    airport_name: "Tuguegarao",
    country: "Philippines",
  },
  {
    sr: 18522,
    city: "Tuktoyaktuk",
    city_code: "YUB",
    airport_name: "Tuktoyaktuk",
    country: "Canada",
  },
  {
    sr: 18524,
    city: "Tula",
    city_code: "TYA",
    airport_name: "Tula",
    country: "Russia",
  },
  {
    sr: 18526,
    city: "Tulagi Island",
    city_code: "TLG",
    airport_name: "Tulagi Island",
    country: "Solomon Islands",
  },
  {
    sr: 18530,
    city: "Tulare",
    city_code: "TLR",
    airport_name: "Tulare",
    country: "United States",
  },
  {
    sr: 18532,
    city: "Tulcan",
    city_code: "TUA",
    airport_name: "Tulcan",
    country: "Ecuador",
  },
  {
    sr: 18534,
    city: "Tulcea",
    city_code: "TCE",
    airport_name: "Tulcea",
    country: "Romania",
  },
  {
    sr: 18536,
    city: "Tulear",
    city_code: "TLE",
    airport_name: "Tulear",
    country: "Madagascar",
  },
  {
    sr: 18538,
    city: "Tuli Block",
    city_code: "TLD",
    airport_name: "Limpopo Valley",
    country: "Botswana",
  },
  {
    sr: 18540,
    city: "Tulita Fort Norman",
    city_code: "ZFN",
    airport_name: "Tulita",
    country: "Canada",
  },
  {
    sr: 18542,
    city: "Tullahoma",
    city_code: "TUH",
    airport_name: "Arnold AFS",
    country: "United States",
  },
  {
    sr: 18544,
    city: "Tullahoma",
    city_code: "THA",
    airport_name: "Northern",
    country: "United States",
  },
  {
    sr: 18546,
    city: "Tulsa",
    city_code: "TUL",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 18548,
    city: "Tulsa",
    city_code: "RVS",
    airport_name: "R Lloyd Jones",
    country: "United States",
  },
  {
    sr: 18550,
    city: "Tulua",
    city_code: "ULQ",
    airport_name: "Farfan",
    country: "Colombia",
  },
  {
    sr: 18552,
    city: "Tulugak",
    city_code: "YTK",
    airport_name: "Tulugak",
    country: "Canada",
  },
  {
    sr: 18554,
    city: "Tuluksak",
    city_code: "TLT",
    airport_name: "Tuluksak",
    country: "United States",
  },
  {
    sr: 18556,
    city: "Tulum",
    city_code: "TUY",
    airport_name: "Tulum",
    country: "Mexico",
  },
  {
    sr: 18558,
    city: "Tum",
    city_code: "TUJ",
    airport_name: "Tum",
    country: "Ethiopia",
  },
  {
    sr: 18560,
    city: "Tumaco",
    city_code: "TCO",
    airport_name: "La Florida",
    country: "Colombia",
  },
  {
    sr: 18564,
    city: "Tumbang Samba",
    city_code: "TBM",
    airport_name: "Tumbang Samba",
    country: "Indonesia",
  },
  {
    sr: 18566,
    city: "Tumbes",
    city_code: "TBP",
    airport_name: "Tumbes",
    country: "Peru",
  },
  {
    sr: 18568,
    city: "Tumbler Ridge",
    city_code: "TUX",
    airport_name: "Tumbler Ridge",
    country: "Canada",
  },
  {
    sr: 18570,
    city: "Tumeremo",
    city_code: "TMO",
    airport_name: "Tumeremo",
    country: "Venezuela",
  },
  {
    sr: 18572,
    city: "Tumling Tar",
    city_code: "TMI",
    airport_name: "Tumling Tar",
    country: "Nepal",
  },
  {
    sr: 18574,
    city: "Tumolbil",
    city_code: "TLP",
    airport_name: "Tumolbil",
    country: "Papua New Guinea",
  },
  {
    sr: 18576,
    city: "Tumut",
    city_code: "TUM",
    airport_name: "Tumut",
    country: "Australia",
  },
  {
    sr: 18578,
    city: "Tungsten",
    city_code: "TNS",
    airport_name: "Tungsten",
    country: "Canada",
  },
  {
    sr: 18580,
    city: "Tunis",
    city_code: "TUN",
    airport_name: "Carthage",
    country: "Tunisia",
  },
  {
    sr: 18582,
    city: "Tuntutuliak",
    city_code: "WTL",
    airport_name: "Tuntutuliak",
    country: "United States",
  },
  {
    sr: 18584,
    city: "Tununak",
    city_code: "TNK",
    airport_name: "Tununak",
    country: "United States",
  },
  {
    sr: 18586,
    city: "Tunxi",
    city_code: "TXN",
    airport_name: "Tunxi",
    country: "China",
  },
  {
    sr: 18588,
    city: "Tupai",
    city_code: "TPX",
    airport_name: "Tupai",
    country: "French Polynesia",
  },
  {
    sr: 18590,
    city: "Tupelo",
    city_code: "TUP",
    airport_name: "Lemons Municipal",
    country: "United States",
  },
  {
    sr: 18592,
    city: "Tupile",
    city_code: "TUE",
    airport_name: "Tupile",
    country: "Panama",
  },
  {
    sr: 18594,
    city: "Turaif",
    city_code: "TUI",
    airport_name: "Turaif",
    country: "Saudi Arabia",
  },
  {
    sr: 18598,
    city: "Turbat",
    city_code: "TUK",
    airport_name: "Turbat",
    country: "Pakistan",
  },
  {
    sr: 18600,
    city: "Turbo",
    city_code: "TRB",
    airport_name: "Gonzalo",
    country: "Colombia",
  },
  {
    sr: 18602,
    city: "Tureira",
    city_code: "ZTA",
    airport_name: "Tureira",
    country: "French Polynesia",
  },
  {
    sr: 18604,
    city: "Turin",
    city_code: "TRN",
    airport_name: "Sandro Pertini  caselle",
    country: "Italy",
  },
  {
    sr: 18606,
    city: "Turkey Creek",
    city_code: "TKY",
    airport_name: "Turkey Creek",
    country: "Australia",
  },
  {
    sr: 18608,
    city: "Turkmenabad",
    city_code: "CRZ",
    airport_name: "Turkmenabad",
    country: "Turkmenistan",
  },
  {
    sr: 18610,
    city: "Turkmenbashi",
    city_code: "KRW",
    airport_name: "Turkmanbashi",
    country: "Turkmenistan",
  },
  {
    sr: 18612,
    city: "Turku",
    city_code: "TKU",
    airport_name: "Turku",
    country: "Finland",
  },
  {
    sr: 18614,
    city: "Turtle Island",
    city_code: "TTL",
    airport_name: "Turtle Island",
    country: "Fiji",
  },
  {
    sr: 18616,
    city: "Tuscaloosa",
    city_code: "TCL",
    airport_name: "Van De Graaf",
    country: "United States",
  },
  {
    sr: 18618,
    city: "Tuskegee",
    city_code: "TGE",
    airport_name: "Sharpe Field",
    country: "United States",
  },
  {
    sr: 18620,
    city: "Tuticorin",
    city_code: "TCR",
    airport_name: "Tuticorin",
    country: "India",
  },
  {
    sr: 18622,
    city: "Tuxekan Island",
    city_code: "WNC",
    airport_name: "Nichen Cove SPB",
    country: "United States",
  },
  {
    sr: 18624,
    city: "Tuxtla Gutierrez",
    city_code: "TGZ",
    airport_name: "Llano San Juan",
    country: "Mexico",
  },
  {
    sr: 18626,
    city: "Tuy Hoa",
    city_code: "TBB",
    airport_name: "Tuy Hoa",
    country: "Vietnam",
  },
  {
    sr: 18628,
    city: "Tuzla",
    city_code: "TZL",
    airport_name: "Tuzla International",
    country: "Bosnia and Herzegovina",
  },
  {
    sr: 18632,
    city: "Twentynine Palms",
    city_code: "TNP",
    airport_name: "Twentynine Palms",
    country: "United States",
  },
  {
    sr: 18634,
    city: "Twin Falls",
    city_code: "TWF",
    airport_name: "City County",
    country: "United States",
  },
  {
    sr: 18636,
    city: "Twin Hills",
    city_code: "TWA",
    airport_name: "Twin Hills",
    country: "United States",
  },
  {
    sr: 18638,
    city: "Tyler",
    city_code: "TYR",
    airport_name: "Pounds Field",
    country: "United States",
  },
  {
    sr: 18640,
    city: "Tynda",
    city_code: "TYD",
    airport_name: "Tynda",
    country: "Russia",
  },
  {
    sr: 18642,
    city: "Tynset",
    city_code: "ZMX",
    airport_name: "Railway",
    country: "Norway",
  },
  {
    sr: 18644,
    city: "Tyonek",
    city_code: "TYE",
    airport_name: "Tyonek",
    country: "United States",
  },
  {
    sr: 18646,
    city: "Tyumen",
    city_code: "TJM",
    airport_name: "Tyumen",
    country: "Russia",
  },
  {
    sr: 18648,
    city: "Tzaneen",
    city_code: "LTA",
    airport_name: "Letaba",
    country: "South Africa",
  },
  {
    sr: 18650,
    city: "Ua Huka",
    city_code: "UAH",
    airport_name: "Ua Huka",
    country: "French Polynesia",
  },
  {
    sr: 18652,
    city: "Ua Pou",
    city_code: "UAP",
    airport_name: "Ua Pou",
    country: "French Polynesia",
  },
  {
    sr: 18654,
    city: "Uaxactun",
    city_code: "UAX",
    airport_name: "Uaxactun",
    country: "Guatemala",
  },
  {
    sr: 18656,
    city: "Ubari",
    city_code: "QUB",
    airport_name: "Ubari",
    country: "Libya",
  },
  {
    sr: 18658,
    city: "Ubatuba",
    city_code: "UBT",
    airport_name: "Ubatuba",
    country: "Brazil",
  },
  {
    sr: 18660,
    city: "Ube",
    city_code: "UBJ",
    airport_name: "Ube",
    country: "Japan",
  },
  {
    sr: 18662,
    city: "Uberaba",
    city_code: "UBA",
    airport_name: "Uberaba",
    country: "Brazil",
  },
  {
    sr: 18666,
    city: "Uberlandia",
    city_code: "UDI",
    airport_name: "Eduardo Gomes",
    country: "Brazil",
  },
  {
    sr: 18668,
    city: "Ubon Ratchathani",
    city_code: "UBP",
    airport_name: "Muang Ubon",
    country: "Thailand",
  },
  {
    sr: 18670,
    city: "Ubrub",
    city_code: "UBR",
    airport_name: "Ubrub",
    country: "Indonesia",
  },
  {
    sr: 18672,
    city: "Udaipur",
    city_code: "UDR",
    airport_name: "Dabok",
    country: "India",
  },
  {
    sr: 18674,
    city: "Uden",
    city_code: "UDE",
    airport_name: "Volkel",
    country: "Netherlands",
  },
  {
    sr: 18676,
    city: "Udine",
    city_code: "UDN",
    airport_name: "Airfield",
    country: "Italy",
  },
  {
    sr: 18678,
    city: "Udomxay",
    city_code: "UDO",
    airport_name: "Udomxay",
    country: "Laos",
  },
  {
    sr: 18680,
    city: "Udon Thani",
    city_code: "UTH",
    airport_name: "Udon Thani",
    country: "Thailand",
  },
  {
    sr: 18682,
    city: "Ufa",
    city_code: "UFA",
    airport_name: "Ufa",
    country: "Russia",
  },
  {
    sr: 18684,
    city: "Uganik",
    city_code: "UGI",
    airport_name: "Uganik",
    country: "United States",
  },
  {
    sr: 18686,
    city: "Ugashik",
    city_code: "UGS",
    airport_name: "Ugashik",
    country: "United States",
  },
  {
    sr: 18688,
    city: "Uherske Hradiste",
    city_code: "UHE",
    airport_name: "Kunovice",
    country: "Czechoslovakia  former",
  },
  {
    sr: 18690,
    city: "Uige",
    city_code: "UGO",
    airport_name: "Uige",
    country: "Angola",
  },
  {
    sr: 18692,
    city: "Ujae Island",
    city_code: "UJE",
    airport_name: "Ujae Island",
    country: "Marshall Islands",
  },
  {
    sr: 18694,
    city: "Ujung Pandang",
    city_code: "UPG",
    airport_name: "Hasanudin",
    country: "Indonesia",
  },
  {
    sr: 18696,
    city: "Ukhta",
    city_code: "UCT",
    airport_name: "Ukhta",
    country: "Russia",
  },
  {
    sr: 18700,
    city: "Ukiah",
    city_code: "UKI",
    airport_name: "Ukiah",
    country: "United States",
  },
  {
    sr: 18702,
    city: "Ukunda",
    city_code: "UKA",
    airport_name: "Ukunda",
    country: "Kenya",
  },
  {
    sr: 18704,
    city: "Ulaanbaatar",
    city_code: "ULN",
    airport_name: "Buyant Uhaa",
    country: "Mongolia",
  },
  {
    sr: 18706,
    city: "Ulaangom",
    city_code: "ULO",
    airport_name: "Ulaangom",
    country: "Mongolia",
  },
  {
    sr: 18708,
    city: "Ulanhot",
    city_code: "HLH",
    airport_name: "Ulanhot",
    country: "China",
  },
  {
    sr: 18710,
    city: "Ulan ude",
    city_code: "UUD",
    airport_name: "Ulan ude  Mukhino",
    country: "Russia",
  },
  {
    sr: 18712,
    city: "Ulei",
    city_code: "ULB",
    airport_name: "Ulei",
    country: "Vanuatu",
  },
  {
    sr: 18714,
    city: "Ulgit",
    city_code: "ULG",
    airport_name: "Ulgit",
    country: "Mongolia",
  },
  {
    sr: 18716,
    city: "Uliastai",
    city_code: "ULZ",
    airport_name: "Uliastai",
    country: "Mongolia",
  },
  {
    sr: 18718,
    city: "Ulithi",
    city_code: "ULI",
    airport_name: "Ulithi",
    country: "Micronesia",
  },
  {
    sr: 18720,
    city: "Ulsan",
    city_code: "USN",
    airport_name: "Ulsan",
    country: "South Korea",
  },
  {
    sr: 18722,
    city: "Ulundi",
    city_code: "ULD",
    airport_name: "Ulundi",
    country: "South Africa",
  },
  {
    sr: 18724,
    city: "Ulusaba",
    city_code: "ULX",
    airport_name: "Ulusaba",
    country: "South Africa",
  },
  {
    sr: 18726,
    city: "Ulyanovsk",
    city_code: "ULY",
    airport_name: "Ulyanovsk",
    country: "Russia",
  },
  {
    sr: 18728,
    city: "Umba",
    city_code: "UMC",
    airport_name: "Umba",
    country: "Papua New Guinea",
  },
  {
    sr: 18730,
    city: "Umea",
    city_code: "UME",
    airport_name: "Umea",
    country: "Sweden",
  },
  {
    sr: 18734,
    city: "Umiat",
    city_code: "UMT",
    airport_name: "Umiat",
    country: "United States",
  },
  {
    sr: 18736,
    city: "Umiujaq",
    city_code: "YUD",
    airport_name: "Umiujaq",
    country: "Canada",
  },
  {
    sr: 18738,
    city: "Umnak Island",
    city_code: "UMB",
    airport_name: "North Shore",
    country: "United States",
  },
  {
    sr: 18740,
    city: "Umnak Island",
    city_code: "UNS",
    airport_name: "Umnak",
    country: "United States",
  },
  {
    sr: 18742,
    city: "Umnugobitour",
    city_code: "UGT",
    airport_name: "Umnugobitour",
    country: "Mongolia",
  },
  {
    sr: 18744,
    city: "Umtata",
    city_code: "UTT",
    airport_name: "Umtata",
    country: "South Africa",
  },
  {
    sr: 18746,
    city: "Umuarama",
    city_code: "UMU",
    airport_name: "Ernesto Geisel",
    country: "Brazil",
  },
  {
    sr: 18748,
    city: "Una",
    city_code: "UNA",
    airport_name: "Una",
    country: "Brazil",
  },
  {
    sr: 18750,
    city: "Unalakleet",
    city_code: "UNK",
    airport_name: "Unalakleet",
    country: "United States",
  },
  {
    sr: 18752,
    city: "Unayzah",
    city_code: "UZH",
    airport_name: "Unayzah",
    country: "Saudi Arabia",
  },
  {
    sr: 18754,
    city: "Undarra",
    city_code: "UDA",
    airport_name: "Undarra",
    country: "Australia",
  },
  {
    sr: 18756,
    city: "Underkhaan",
    city_code: "UNR",
    airport_name: "Underkhaan",
    country: "Mongolia",
  },
  {
    sr: 18758,
    city: "Unguia",
    city_code: "UNC",
    airport_name: "Unguia",
    country: "Colombia",
  },
  {
    sr: 18760,
    city: "Union City",
    city_code: "UCY",
    airport_name: "Everett Stewart",
    country: "United States",
  },
  {
    sr: 18762,
    city: "Union Island",
    city_code: "UNI",
    airport_name: "Union Island",
    country: "Saint Vincent and the Grenadines",
  },
  {
    sr: 18764,
    city: "Unst Shetland Is",
    city_code: "UNT",
    airport_name: "Baltasound",
    country: "United Kingdom",
  },
  {
    sr: 18768,
    city: "Upala",
    city_code: "UPL",
    airport_name: "Upala",
    country: "Costa Rica",
  },
  {
    sr: 18770,
    city: "Upavon",
    city_code: "UPV",
    airport_name: "Upavon",
    country: "United Kingdom",
  },
  {
    sr: 18772,
    city: "Upernavik",
    city_code: "JUV",
    airport_name: "Heliport",
    country: "Greenland",
  },
  {
    sr: 18774,
    city: "Upiara",
    city_code: "UPR",
    airport_name: "Upiara",
    country: "Papua New Guinea",
  },
  {
    sr: 18776,
    city: "Upington",
    city_code: "UTN",
    airport_name: "Upington",
    country: "South Africa",
  },
  {
    sr: 18778,
    city: "Upland",
    city_code: "CCB",
    airport_name: "Cable",
    country: "United States",
  },
  {
    sr: 18780,
    city: "Upland",
    city_code: "JUP",
    airport_name: "Cable Heliport",
    country: "United States",
  },
  {
    sr: 18782,
    city: "Upolu Point",
    city_code: "UPP",
    airport_name: "Upolu Point",
    country: "United States",
  },
  {
    sr: 18784,
    city: "Upper Heyford",
    city_code: "UHF",
    airport_name: "RAF Station",
    country: "United Kingdom",
  },
  {
    sr: 18786,
    city: "Uraj",
    city_code: "URJ",
    airport_name: "Uraj",
    country: "Russia",
  },
  {
    sr: 18788,
    city: "Uralsk",
    city_code: "URA",
    airport_name: "Uralsk",
    country: "Kazakhstan",
  },
  {
    sr: 18790,
    city: "Uranium City",
    city_code: "YBE",
    airport_name: "Uranium City",
    country: "Canada",
  },
  {
    sr: 18792,
    city: "Urgench",
    city_code: "UGC",
    airport_name: "Urgench",
    country: "Uzbekistan",
  },
  {
    sr: 18794,
    city: "Urgoon",
    city_code: "URN",
    airport_name: "Urgoon",
    country: "Afghanistan",
  },
  {
    sr: 18796,
    city: "Uribe",
    city_code: "URI",
    airport_name: "Uribe",
    country: "Colombia",
  },
  {
    sr: 18798,
    city: "Uriman",
    city_code: "URM",
    airport_name: "Uriman",
    country: "Venezuela",
  },
  {
    sr: 18802,
    city: "Urmieh",
    city_code: "OMH",
    airport_name: "Urmieh",
    country: "Iran",
  },
  {
    sr: 18804,
    city: "Uroubi",
    city_code: "URU",
    airport_name: "Uroubi",
    country: "Papua New Guinea",
  },
  {
    sr: 18806,
    city: "Urrao",
    city_code: "URR",
    airport_name: "Urrao",
    country: "Colombia",
  },
  {
    sr: 18808,
    city: "Uruapan",
    city_code: "UPN",
    airport_name: "Uruapan",
    country: "Mexico",
  },
  {
    sr: 18810,
    city: "Urubupunga",
    city_code: "URB",
    airport_name: "Ernesto Pochler",
    country: "Brazil",
  },
  {
    sr: 18812,
    city: "Uruguaiana",
    city_code: "URG",
    airport_name: "Ruben Berta",
    country: "Brazil",
  },
  {
    sr: 18814,
    city: "Urumqi",
    city_code: "URC",
    airport_name: "Urumqi",
    country: "China",
  },
  {
    sr: 18816,
    city: "Uruzgan",
    city_code: "URZ",
    airport_name: "Uruzgan",
    country: "Afghanistan",
  },
  {
    sr: 18818,
    city: "Usak",
    city_code: "USQ",
    airport_name: "Usak",
    country: "Turkey",
  },
  {
    sr: 18820,
    city: "Useless Loop",
    city_code: "USL",
    airport_name: "Useless Loop",
    country: "Australia",
  },
  {
    sr: 18822,
    city: "Ushuaia",
    city_code: "USH",
    airport_name: "Islas Malvinas",
    country: "Argentina",
  },
  {
    sr: 18824,
    city: "Usino",
    city_code: "USO",
    airport_name: "Usino",
    country: "Papua New Guinea",
  },
  {
    sr: 18826,
    city: "Usinsk",
    city_code: "USK",
    airport_name: "Usinsk",
    country: "Russia",
  },
  {
    sr: 18828,
    city: "Ustaoset",
    city_code: "XUX",
    airport_name: "Ustaoset Railway",
    country: "Norway",
  },
  {
    sr: 18830,
    city: "Ust Ilimsk",
    city_code: "UIK",
    airport_name: "Ust Ilimsk",
    country: "Russia",
  },
  {
    sr: 18832,
    city: "Ust kamenogorsk",
    city_code: "UKK",
    airport_name: "Ust kamenogorsk",
    country: "Kazakhstan",
  },
  {
    sr: 18836,
    city: "Ust Kut",
    city_code: "UKX",
    airport_name: "Ust Kut",
    country: "Russia",
  },
  {
    sr: 18838,
    city: "Ustupo",
    city_code: "UTU",
    airport_name: "Ustupo",
    country: "Panama",
  },
  {
    sr: 18840,
    city: "Utapao",
    city_code: "UTP",
    airport_name: "Utapao",
    country: "Thailand",
  },
  {
    sr: 18842,
    city: "Utica",
    city_code: "UIZ",
    airport_name: "Berz Macomb",
    country: "United States",
  },
  {
    sr: 18844,
    city: "Utica",
    city_code: "UCA",
    airport_name: "Oneida County",
    country: "United States",
  },
  {
    sr: 18846,
    city: "Utica",
    city_code: "ZUA",
    airport_name: "Utica NNY Rail",
    country: "United States",
  },
  {
    sr: 18848,
    city: "Utila",
    city_code: "UII",
    airport_name: "Utila",
    country: "Honduras",
  },
  {
    sr: 18850,
    city: "Utirik Island",
    city_code: "UTK",
    airport_name: "Utirik Island",
    country: "Marshall Islands",
  },
  {
    sr: 18852,
    city: "Utopia Creek",
    city_code: "UTO",
    airport_name: "Indian Mountain AFS",
    country: "United States",
  },
  {
    sr: 18854,
    city: "Utrecht",
    city_code: "UTC",
    airport_name: "Soesterberg",
    country: "Netherlands",
  },
  {
    sr: 18856,
    city: "Uttaradit",
    city_code: "UTR",
    airport_name: "Uttaradit",
    country: "Thailand",
  },
  {
    sr: 18858,
    city: "Uummannaq",
    city_code: "UMD",
    airport_name: "Uummannaq",
    country: "Greenland",
  },
  {
    sr: 18860,
    city: "Uvalde",
    city_code: "UVA",
    airport_name: "Garner Fld",
    country: "United States",
  },
  {
    sr: 18862,
    city: "Uvol",
    city_code: "UVO",
    airport_name: "Uvol",
    country: "Papua New Guinea",
  },
  {
    sr: 18864,
    city: "Uzhgorod",
    city_code: "UDJ",
    airport_name: "Uzhgorod",
    country: "Ukraine",
  },
  {
    sr: 18866,
    city: "Uzice",
    city_code: "UZC",
    airport_name: "Ponikve",
    country: "Serbia",
  },
  {
    sr: 18870,
    city: "Vaasa",
    city_code: "VAA",
    airport_name: "Vaasa",
    country: "Finland",
  },
  {
    sr: 18872,
    city: "Vadodara",
    city_code: "BDQ",
    airport_name: "Vadodara",
    country: "India",
  },
  {
    sr: 18874,
    city: "Vadso",
    city_code: "VDS",
    airport_name: "Vadso",
    country: "Norway",
  },
  {
    sr: 18876,
    city: "Vaeroy",
    city_code: "VRY",
    airport_name: "Stolport",
    country: "Norway",
  },
  {
    sr: 18878,
    city: "Vahitahi",
    city_code: "VHZ",
    airport_name: "Vahitahi",
    country: "French Polynesia",
  },
  {
    sr: 18880,
    city: "Vail Eagle",
    city_code: "ZBV",
    airport_name: "Beaver Creek Van Svce",
    country: "United States",
  },
  {
    sr: 18882,
    city: "Vail Eagle",
    city_code: "EGE",
    airport_name: "Eagle County",
    country: "United States",
  },
  {
    sr: 18884,
    city: "Vail Eagle",
    city_code: "QBF",
    airport_name: "Vail Van Service",
    country: "United States",
  },
  {
    sr: 18886,
    city: "Valcartier",
    city_code: "YOY",
    airport_name: "Valcartier",
    country: "Canada",
  },
  {
    sr: 18888,
    city: "Valcheta",
    city_code: "VCF",
    airport_name: "Valcheta",
    country: "Argentina",
  },
  {
    sr: 18890,
    city: "Valdez",
    city_code: "VDZ",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 18892,
    city: "Valdivia",
    city_code: "ZAL",
    airport_name: "Pichoy",
    country: "Chile",
  },
  {
    sr: 18894,
    city: "Valdosta",
    city_code: "VAD",
    airport_name: "Moody AFB",
    country: "United States",
  },
  {
    sr: 18896,
    city: "Valdosta",
    city_code: "VLD",
    airport_name: "Regional",
    country: "United States",
  },
  {
    sr: 18898,
    city: "Valenca",
    city_code: "VAL",
    airport_name: "Valenca",
    country: "Brazil",
  },
  {
    sr: 18900,
    city: "Valence",
    city_code: "VAF",
    airport_name: "Chabeuil",
    country: "France",
  },
  {
    sr: 18904,
    city: "Valencia",
    city_code: "VLC",
    airport_name: "Valencia",
    country: "Spain",
  },
  {
    sr: 18906,
    city: "Valencia",
    city_code: "VLN",
    airport_name: "Valencia",
    country: "Venezuela",
  },
  {
    sr: 18908,
    city: "Valentine",
    city_code: "VTN",
    airport_name: "Miller Field",
    country: "United States",
  },
  {
    sr: 18910,
    city: "Valera",
    city_code: "VLV",
    airport_name: "Carvajal",
    country: "Venezuela",
  },
  {
    sr: 18912,
    city: "Valesdir",
    city_code: "VLS",
    airport_name: "Valesdir",
    country: "Vanuatu",
  },
  {
    sr: 18914,
    city: "Valladolid",
    city_code: "VLL",
    airport_name: "Valladolid",
    country: "Spain",
  },
  {
    sr: 18916,
    city: "Valle",
    city_code: "VLE",
    airport_name: "J t Robidoux",
    country: "United States",
  },
  {
    sr: 18918,
    city: "Valle De Pascua",
    city_code: "VDP",
    airport_name: "Valle De Pascua",
    country: "Venezuela",
  },
  {
    sr: 18920,
    city: "Valledupar",
    city_code: "VUP",
    airport_name: "Valledupar",
    country: "Colombia",
  },
  {
    sr: 18922,
    city: "Vallegrande",
    city_code: "VAH",
    airport_name: "Vallegrande",
    country: "Bolivia",
  },
  {
    sr: 18924,
    city: "Vallejo",
    city_code: "VLO",
    airport_name: "Stolport",
    country: "United States",
  },
  {
    sr: 18926,
    city: "Vallemi",
    city_code: "VMI",
    airport_name: "INC",
    country: "Paraguay",
  },
  {
    sr: 18928,
    city: "Vallenar",
    city_code: "VLR",
    airport_name: "Vallenar",
    country: "Chile",
  },
  {
    sr: 18930,
    city: "Valparaiso",
    city_code: "VAP",
    airport_name: "Valparaiso",
    country: "Chile",
  },
  {
    sr: 18932,
    city: "Valparaiso",
    city_code: "EGI",
    airport_name: "Duke Field",
    country: "United States",
  },
  {
    sr: 18934,
    city: "Valparaiso",
    city_code: "VPS",
    airport_name: "Ft Walton Beach",
    country: "United States",
  },
  {
    sr: 18938,
    city: "Valparaiso",
    city_code: "VPZ",
    airport_name: "Porter County",
    country: "United States",
  },
  {
    sr: 18940,
    city: "Valverde",
    city_code: "VDE",
    airport_name: "Hierro",
    country: "Spain",
  },
  {
    sr: 18942,
    city: "Van",
    city_code: "VAN",
    airport_name: "Van",
    country: "Turkey",
  },
  {
    sr: 18944,
    city: "Van Horn",
    city_code: "VHN",
    airport_name: "Culberson County",
    country: "United States",
  },
  {
    sr: 18946,
    city: "Vancouver",
    city_code: "YDT",
    airport_name: "Boundary Bay",
    country: "Canada",
  },
  {
    sr: 18948,
    city: "Vancouver",
    city_code: "CXH",
    airport_name: "Coal Harbour",
    country: "Canada",
  },
  {
    sr: 18950,
    city: "Vancouver",
    city_code: "YVR",
    airport_name: "Vancouver Intl",
    country: "Canada",
  },
  {
    sr: 18952,
    city: "Vancouver",
    city_code: "XEA",
    airport_name: "Vancouver Rail Stn",
    country: "Canada",
  },
  {
    sr: 18954,
    city: "Vandalia",
    city_code: "VLA",
    airport_name: "Vandalia",
    country: "United States",
  },
  {
    sr: 18956,
    city: "Vangaindrano",
    city_code: "VND",
    airport_name: "Vangaindrano",
    country: "Madagascar",
  },
  {
    sr: 18958,
    city: "Vangrieng",
    city_code: "VGG",
    airport_name: "Vangrieng",
    country: "Laos",
  },
  {
    sr: 18960,
    city: "Vanimo",
    city_code: "VAI",
    airport_name: "Vanimo",
    country: "Papua New Guinea",
  },
  {
    sr: 18962,
    city: "Vannes",
    city_code: "VNE",
    airport_name: "Meucon",
    country: "France",
  },
  {
    sr: 18964,
    city: "Vanrook",
    city_code: "VNR",
    airport_name: "Vanrook",
    country: "Australia",
  },
  {
    sr: 18966,
    city: "Vanuabalavu",
    city_code: "VBV",
    airport_name: "Vanuabalavu",
    country: "Fiji",
  },
  {
    sr: 18968,
    city: "Varadero",
    city_code: "VRA",
    airport_name: "Juan Gualberto Gomez",
    country: "Cuba",
  },
  {
    sr: 18972,
    city: "Varanasi",
    city_code: "VNS",
    airport_name: "Varanasi",
    country: "India",
  },
  {
    sr: 18974,
    city: "Vardoe",
    city_code: "VAW",
    airport_name: "Vardoe",
    country: "Norway",
  },
  {
    sr: 18976,
    city: "Varginha",
    city_code: "VAG",
    airport_name: "Maj Brig Trompowsky",
    country: "Brazil",
  },
  {
    sr: 18978,
    city: "Varkaus",
    city_code: "VRK",
    airport_name: "Varkaus",
    country: "Finland",
  },
  {
    sr: 18980,
    city: "Varna",
    city_code: "VAR",
    airport_name: "Varna",
    country: "Bulgaria",
  },
  {
    sr: 18982,
    city: "Varrelbusch",
    city_code: "VAC",
    airport_name: "Varrelbusch",
    country: "Germany",
  },
  {
    sr: 18984,
    city: "Vastervik",
    city_code: "VVK",
    airport_name: "Vastervik",
    country: "Sweden",
  },
  {
    sr: 18986,
    city: "Vatomandry",
    city_code: "VAT",
    airport_name: "Vatomandry",
    country: "Madagascar",
  },
  {
    sr: 18988,
    city: "Vatukoula",
    city_code: "VAU",
    airport_name: "Vatukoula",
    country: "Fiji",
  },
  {
    sr: 18990,
    city: "Vatulele",
    city_code: "VTF",
    airport_name: "Vatulele",
    country: "Fiji",
  },
  {
    sr: 18992,
    city: "Vaxjo",
    city_code: "VXO",
    airport_name: "Vaxjo",
    country: "Sweden",
  },
  {
    sr: 18994,
    city: "Vejle",
    city_code: "VEJ",
    airport_name: "Vejle",
    country: "Denmark",
  },
  {
    sr: 18996,
    city: "Velikij Ustyug",
    city_code: "VUS",
    airport_name: "Velikij Ustyug",
    country: "Russia",
  },
  {
    sr: 18998,
    city: "Velikiye Luki",
    city_code: "VLU",
    airport_name: "Velikiye Luki",
    country: "Russia",
  },
  {
    sr: 19000,
    city: "Venetie",
    city_code: "VEE",
    airport_name: "Venetie",
    country: "United States",
  },
  {
    sr: 19002,
    city: "Venice",
    city_code: "VNC",
    airport_name: "Venice",
    country: "United States",
  },
  {
    sr: 19006,
    city: "Venice",
    city_code: "VCE",
    airport_name: "Marco Polo",
    country: "Italy",
  },
  {
    sr: 19008,
    city: "Venice",
    city_code: "XVQ",
    airport_name: "Santa Lucia Rail",
    country: "Italy",
  },
  {
    sr: 19010,
    city: "Treviso",
    city_code: "TSF",
    airport_name: "Treviso",
    country: "Italy",
  },
  {
    sr: 19012,
    city: "Vennesla",
    city_code: "XXE",
    airport_name: "Vennesla Railway",
    country: "Norway",
  },
  {
    sr: 19014,
    city: "Ventura",
    city_code: "OXR",
    airport_name: "Ventura",
    country: "United States",
  },
  {
    sr: 19016,
    city: "Veracruz",
    city_code: "VER",
    airport_name: "Las Bajadas",
    country: "Mexico",
  },
  {
    sr: 19018,
    city: "Verdal",
    city_code: "XXG",
    airport_name: "Verdal Railway",
    country: "Norway",
  },
  {
    sr: 19020,
    city: "Vermilion",
    city_code: "YVG",
    airport_name: "Vermilion",
    country: "Canada",
  },
  {
    sr: 19022,
    city: "Vernal",
    city_code: "VEL",
    airport_name: "Vernal",
    country: "United States",
  },
  {
    sr: 19024,
    city: "Vernon",
    city_code: "YVE",
    airport_name: "Vernon",
    country: "Canada",
  },
  {
    sr: 19026,
    city: "Vero Beach",
    city_code: "VRB",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 19028,
    city: "Verona",
    city_code: "VRN",
    airport_name: "Valerio Catullo",
    country: "Italy",
  },
  {
    sr: 19030,
    city: "Verona",
    city_code: "VBS",
    airport_name: "Brescia Montichiari",
    country: "Italy",
  },
  {
    sr: 19032,
    city: "Verona",
    city_code: "XIX",
    airport_name: "Porta Nuova Rail",
    country: "Italy",
  },
  {
    sr: 19034,
    city: "Versailles",
    city_code: "VRS",
    airport_name: "Versailles",
    country: "United States",
  },
  {
    sr: 19036,
    city: "Vestmannaeyjar",
    city_code: "VEY",
    airport_name: "Vestmannaeyjar",
    country: "Iceland",
  },
  {
    sr: 19040,
    city: "Vicenza",
    city_code: "VIC",
    airport_name: "Vicenza",
    country: "Italy",
  },
  {
    sr: 19042,
    city: "Vichadero",
    city_code: "VCH",
    airport_name: "Vichadero",
    country: "Uruguay",
  },
  {
    sr: 19044,
    city: "Vichy",
    city_code: "VHY",
    airport_name: "Charmeil",
    country: "France",
  },
  {
    sr: 19046,
    city: "Vichy",
    city_code: "VIH",
    airport_name: "Rolla National",
    country: "United States",
  },
  {
    sr: 19048,
    city: "Vicksburg",
    city_code: "VKS",
    airport_name: "Vicksburg",
    country: "United States",
  },
  {
    sr: 19050,
    city: "Victoria",
    city_code: "YYJ",
    airport_name: "Victoria International",
    country: "Canada",
  },
  {
    sr: 19052,
    city: "Victoria",
    city_code: "YWH",
    airport_name: "Victoria Inner Harbor",
    country: "Canada",
  },
  {
    sr: 19054,
    city: "Victoria",
    city_code: "ZIC",
    airport_name: "Victoria",
    country: "Chile",
  },
  {
    sr: 19056,
    city: "Victoria",
    city_code: "VTA",
    airport_name: "Victoria",
    country: "Honduras",
  },
  {
    sr: 19058,
    city: "Victoria",
    city_code: "VCT",
    airport_name: "County Foster",
    country: "United States",
  },
  {
    sr: 19060,
    city: "Victoria Falls",
    city_code: "VFA",
    airport_name: "Victoria Falls",
    country: "Zimbabwe",
  },
  {
    sr: 19062,
    city: "Victoria Island",
    city_code: "QVL",
    airport_name: "Victoria Island",
    country: "Nigeria",
  },
  {
    sr: 19064,
    city: "Victoria River Downs",
    city_code: "VCD",
    airport_name: "Victoria River Downs",
    country: "Australia",
  },
  {
    sr: 19066,
    city: "Victorville",
    city_code: "VCV",
    airport_name: "George AFB",
    country: "United States",
  },
  {
    sr: 19068,
    city: "Vidalia",
    city_code: "VDI",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 19070,
    city: "Videira",
    city_code: "VIA",
    airport_name: "Vienna",
    country: "Brazil",
  },
  {
    sr: 19074,
    city: "Vidin",
    city_code: "VID",
    airport_name: "Vidin",
    country: "Bulgaria",
  },
  {
    sr: 19076,
    city: "Viedma",
    city_code: "VDM",
    airport_name: "Viedma",
    country: "Argentina",
  },
  {
    sr: 19078,
    city: "Viengxay",
    city_code: "VNG",
    airport_name: "Viengxay",
    country: "Laos",
  },
  {
    sr: 19080,
    city: "Vienna",
    city_code: "XWC",
    airport_name: "Suedbahnhof Rail",
    country: "Austria",
  },
  {
    sr: 19082,
    city: "Vienna",
    city_code: "VDD",
    airport_name: "Vienna Danubepier Hov",
    country: "Austria",
  },
  {
    sr: 19084,
    city: "Vienna",
    city_code: "VIE",
    airport_name: "Schwechat International",
    country: "Austria",
  },
  {
    sr: 19086,
    city: "Vienna",
    city_code: "XWW",
    airport_name: "Westbahnhof Rail",
    country: "Austria",
  },
  {
    sr: 19088,
    city: "Vientiane",
    city_code: "VTE",
    airport_name: "Wattay",
    country: "Laos",
  },
  {
    sr: 19090,
    city: "Vieques",
    city_code: "VQS",
    airport_name: "Vieques",
    country: "Puerto Rico",
  },
  {
    sr: 19092,
    city: "Vieste",
    city_code: "VIF",
    airport_name: "Vieste",
    country: "Italy",
  },
  {
    sr: 19094,
    city: "View Cove",
    city_code: "VCB",
    airport_name: "View Cove",
    country: "United States",
  },
  {
    sr: 19096,
    city: "Vigo",
    city_code: "VGO",
    airport_name: "Vigo",
    country: "Spain",
  },
  {
    sr: 19098,
    city: "Vijayawada",
    city_code: "VGA",
    airport_name: "Vijayawada",
    country: "India",
  },
  {
    sr: 19100,
    city: "Vila Real",
    city_code: "VRL",
    airport_name: "Vila Real",
    country: "Portugal",
  },
  {
    sr: 19102,
    city: "Vila Rica",
    city_code: "VLP",
    airport_name: "Municipal",
    country: "Brazil",
  },
  {
    sr: 19104,
    city: "Vilanculos",
    city_code: "VNX",
    airport_name: "Vilanculos",
    country: "Mozambique",
  },
  {
    sr: 19108,
    city: "Vilhelmina",
    city_code: "VHM",
    airport_name: "Vilhelmina",
    country: "Sweden",
  },
  {
    sr: 19110,
    city: "Vilhena",
    city_code: "BVH",
    airport_name: "Vilhena",
    country: "Brazil",
  },
  {
    sr: 19112,
    city: "Villa Constitucion",
    city_code: "VIB",
    airport_name: "Villa Constitucion",
    country: "Mexico",
  },
  {
    sr: 19114,
    city: "Villa Dolores",
    city_code: "VDR",
    airport_name: "Villa Dolores",
    country: "Argentina",
  },
  {
    sr: 19116,
    city: "Villa Gesell",
    city_code: "VLG",
    airport_name: "Villa Gesell",
    country: "Argentina",
  },
  {
    sr: 19118,
    city: "Villa Mercedes",
    city_code: "VME",
    airport_name: "Villa Mercedes",
    country: "Argentina",
  },
  {
    sr: 19120,
    city: "Villagarzon",
    city_code: "VGZ",
    airport_name: "Villagarzon",
    country: "Colombia",
  },
  {
    sr: 19122,
    city: "Villahermosa",
    city_code: "VSA",
    airport_name: "Capitan Carlos Rovirosa",
    country: "Mexico",
  },
  {
    sr: 19124,
    city: "Villamontes",
    city_code: "VLM",
    airport_name: "Villamontes",
    country: "Bolivia",
  },
  {
    sr: 19126,
    city: "Villavicencio",
    city_code: "VVC",
    airport_name: "La Vanguardia",
    country: "Colombia",
  },
  {
    sr: 19128,
    city: "Vilnius",
    city_code: "VNO",
    airport_name: "Vilnius",
    country: "Lithuania",
  },
  {
    sr: 19130,
    city: "Vina del Mar",
    city_code: "KNA",
    airport_name: "Vina del Mar",
    country: "Chile",
  },
  {
    sr: 19132,
    city: "Vincennes",
    city_code: "OEA",
    airport_name: "Oneal",
    country: "United States",
  },
  {
    sr: 19134,
    city: "Vinh City",
    city_code: "VII",
    airport_name: "Vinh City",
    country: "Vietnam",
  },
  {
    sr: 19136,
    city: "Vinh Long",
    city_code: "XVL",
    airport_name: "Vinh Long",
    country: "Vietnam",
  },
  {
    sr: 19138,
    city: "Vinnica",
    city_code: "VIN",
    airport_name: "Vinnica",
    country: "Ukraine",
  },
  {
    sr: 19142,
    city: "Viqueque",
    city_code: "VIQ",
    airport_name: "Viqueque",
    country: "Indonesia",
  },
  {
    sr: 19144,
    city: "Virac",
    city_code: "VRC",
    airport_name: "Virac",
    country: "Philippines",
  },
  {
    sr: 19146,
    city: "Virgin Gorda",
    city_code: "VIJ",
    airport_name: "Virgin Gorda",
    country: "Virgin Islands  British",
  },
  {
    sr: 19148,
    city: "Viru",
    city_code: "VIU",
    airport_name: "Viru Harbour Airstrip",
    country: "Solomon Islands",
  },
  {
    sr: 19150,
    city: "Visalia",
    city_code: "VIS",
    airport_name: "Visalia",
    country: "United States",
  },
  {
    sr: 19152,
    city: "Visby",
    city_code: "VBY",
    airport_name: "Visby",
    country: "Sweden",
  },
  {
    sr: 19154,
    city: "Viseu",
    city_code: "VSE",
    airport_name: "Viseu",
    country: "Portugal",
  },
  {
    sr: 19156,
    city: "Vishakhapatnam",
    city_code: "VTZ",
    airport_name: "Vishakhapatnam",
    country: "India",
  },
  {
    sr: 19158,
    city: "Vitebsk",
    city_code: "VTB",
    airport_name: "Vitebsk",
    country: "Belarus",
  },
  {
    sr: 19160,
    city: "Vitoria",
    city_code: "VIX",
    airport_name: "Eurico Sales",
    country: "Brazil",
  },
  {
    sr: 19162,
    city: "Vitoria Da Conquista",
    city_code: "VDC",
    airport_name: "Vitoria Da Conquista",
    country: "Brazil",
  },
  {
    sr: 19164,
    city: "Vitoria",
    city_code: "VIT",
    airport_name: "Vitoria",
    country: "Spain",
  },
  {
    sr: 19166,
    city: "Vittel",
    city_code: "VTL",
    airport_name: "Vittel",
    country: "France",
  },
  {
    sr: 19168,
    city: "Vivigani",
    city_code: "VIV",
    airport_name: "Vivigani",
    country: "Papua New Guinea",
  },
  {
    sr: 19170,
    city: "Vladikavkaz",
    city_code: "OGZ",
    airport_name: "Vladikavkaz",
    country: "Russia",
  },
  {
    sr: 19172,
    city: "Vladivostok",
    city_code: "VVO",
    airport_name: "Vladivostok",
    country: "Russia",
  },
  {
    sr: 19176,
    city: "Vohemar",
    city_code: "VOH",
    airport_name: "Vohemar",
    country: "Madagascar",
  },
  {
    sr: 19178,
    city: "Voinjama",
    city_code: "VOI",
    airport_name: "Voinjama",
    country: "Liberia",
  },
  {
    sr: 19180,
    city: "Vojens",
    city_code: "SKS",
    airport_name: "Vojens",
    country: "Denmark",
  },
  {
    sr: 19182,
    city: "Vojens",
    city_code: "XJE",
    airport_name: "Rialway Service",
    country: "Denmark",
  },
  {
    sr: 19184,
    city: "Volgodonsk",
    city_code: "VLK",
    airport_name: "Volgodonsk",
    country: "Russia",
  },
  {
    sr: 19186,
    city: "Volgograd",
    city_code: "VOG",
    airport_name: "Volgograd",
    country: "Russia",
  },
  {
    sr: 19188,
    city: "Vologda",
    city_code: "VGD",
    airport_name: "Vologda",
    country: "Russia",
  },
  {
    sr: 19190,
    city: "Volos",
    city_code: "VOL",
    airport_name: "Nea Anchialos",
    country: "Greece",
  },
  {
    sr: 19192,
    city: "Vopnafjordur",
    city_code: "VPN",
    airport_name: "Vopnafjordur",
    country: "Iceland",
  },
  {
    sr: 19194,
    city: "Vorkuta",
    city_code: "VKT",
    airport_name: "Vorkuta",
    country: "Russia",
  },
  {
    sr: 19196,
    city: "Voronezh",
    city_code: "VOZ",
    airport_name: "Voronezh",
    country: "Russia",
  },
  {
    sr: 19198,
    city: "Votuporanga",
    city_code: "VOT",
    airport_name: "Votuporanga",
    country: "Brazil",
  },
  {
    sr: 19200,
    city: "Vredendal",
    city_code: "VRE",
    airport_name: "Vredendal",
    country: "South Africa",
  },
  {
    sr: 19202,
    city: "Vryburg",
    city_code: "VRU",
    airport_name: "Vryburg",
    country: "South Africa",
  },
  {
    sr: 19204,
    city: "Vryheid",
    city_code: "VYD",
    airport_name: "Vryheid",
    country: "South Africa",
  },
  {
    sr: 19206,
    city: "Vulcano",
    city_code: "ZIE",
    airport_name: "Harbour  Eolie Island",
    country: "Italy",
  },
  {
    sr: 19210,
    city: "Vung Tau",
    city_code: "VTG",
    airport_name: "Vung Tau",
    country: "Vietnam",
  },
  {
    sr: 19212,
    city: "Wabag",
    city_code: "WAB",
    airport_name: "Wabag",
    country: "Papua New Guinea",
  },
  {
    sr: 19214,
    city: "Wabo",
    city_code: "WAO",
    airport_name: "Wabo",
    country: "Papua New Guinea",
  },
  {
    sr: 19216,
    city: "Wabush",
    city_code: "YWK",
    airport_name: "Wabush",
    country: "Canada",
  },
  {
    sr: 19218,
    city: "Waca",
    city_code: "WAC",
    airport_name: "Waca",
    country: "Ethiopia",
  },
  {
    sr: 19220,
    city: "Waco",
    city_code: "CNW",
    airport_name: "James Connall",
    country: "United States",
  },
  {
    sr: 19222,
    city: "Waco",
    city_code: "ACT",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 19224,
    city: "Waco Kungo",
    city_code: "CEO",
    airport_name: "Waco Kungo",
    country: "Angola",
  },
  {
    sr: 19226,
    city: "Wad Medani",
    city_code: "DNI",
    airport_name: "Wad Medani",
    country: "Sudan",
  },
  {
    sr: 19228,
    city: "Waddington",
    city_code: "WTN",
    airport_name: "RAF Station",
    country: "United Kingdom",
  },
  {
    sr: 19230,
    city: "Wadi Ad Dawasir",
    city_code: "WAE",
    airport_name: "Wadi Ad Dawasir",
    country: "Saudi Arabia",
  },
  {
    sr: 19232,
    city: "Wadi Ain",
    city_code: "WDA",
    airport_name: "Wadi Ain",
    country: "Yemen",
  },
  {
    sr: 19234,
    city: "Wadi Halfa",
    city_code: "WHF",
    airport_name: "Wadi Halfa",
    country: "Sudan",
  },
  {
    sr: 19236,
    city: "Wagau",
    city_code: "WGU",
    airport_name: "Wagau",
    country: "Papua New Guinea",
  },
  {
    sr: 19238,
    city: "Wageningen",
    city_code: "AGI",
    airport_name: "Wageningen",
    country: "Suriname",
  },
  {
    sr: 19240,
    city: "Wagethe",
    city_code: "WET",
    airport_name: "Wagethe",
    country: "Indonesia",
  },
  {
    sr: 19244,
    city: "Wagga Wagga",
    city_code: "WGA",
    airport_name: "Forrest Hill",
    country: "Australia",
  },
  {
    sr: 19246,
    city: "Wagny",
    city_code: "WGY",
    airport_name: "Wagny",
    country: "Gabon",
  },
  {
    sr: 19248,
    city: "Wahai",
    city_code: "WBA",
    airport_name: "Wahai",
    country: "Australia",
  },
  {
    sr: 19250,
    city: "Wahiawa",
    city_code: "HHI",
    airport_name: "Wheeler AFB",
    country: "United States",
  },
  {
    sr: 19252,
    city: "Wahpeton",
    city_code: "WAH",
    airport_name: "Wahpeton",
    country: "United States",
  },
  {
    sr: 19254,
    city: "Waikoloa",
    city_code: "WKL",
    airport_name: "Waikoloa Airport",
    country: "United States",
  },
  {
    sr: 19256,
    city: "Waimanalo",
    city_code: "BLW",
    airport_name: "Bellows Field",
    country: "United States",
  },
  {
    sr: 19258,
    city: "Waingapu",
    city_code: "WGP",
    airport_name: "Waingapu",
    country: "Indonesia",
  },
  {
    sr: 19260,
    city: "Wainwright",
    city_code: "AIN",
    airport_name: "Wainwright",
    country: "United States",
  },
  {
    sr: 19262,
    city: "Wairoa",
    city_code: "WIR",
    airport_name: "Wairoa",
    country: "New Zealand",
  },
  {
    sr: 19264,
    city: "Waitangi",
    city_code: "WGN",
    airport_name: "Waitangi",
    country: "New Zealand",
  },
  {
    sr: 19266,
    city: "Wajima",
    city_code: "NTQ",
    airport_name: "Noto Airport",
    country: "Japan",
  },
  {
    sr: 19268,
    city: "Wajir",
    city_code: "WJR",
    airport_name: "Wajir",
    country: "Kenya",
  },
  {
    sr: 19270,
    city: "Wakaya Island",
    city_code: "KAY",
    airport_name: "Wakaya Island",
    country: "Fiji",
  },
  {
    sr: 19272,
    city: "Wake Island",
    city_code: "AWK",
    airport_name: "Wake Island",
    country: "US Minor Outlying Islands",
  },
  {
    sr: 19274,
    city: "Wakkanai",
    city_code: "WKJ",
    airport_name: "Hokkaido",
    country: "Japan",
  },
  {
    sr: 19278,
    city: "Wakunai",
    city_code: "WKN",
    airport_name: "Wakunai",
    country: "Papua New Guinea",
  },
  {
    sr: 19280,
    city: "Walaha",
    city_code: "WLH",
    airport_name: "Walaha",
    country: "Vanuatu",
  },
  {
    sr: 19282,
    city: "Walcha",
    city_code: "WLC",
    airport_name: "Walcha",
    country: "Australia",
  },
  {
    sr: 19284,
    city: "Waldron Island",
    city_code: "WDN",
    airport_name: "Waldron Island",
    country: "United States",
  },
  {
    sr: 19286,
    city: "Wales",
    city_code: "WAA",
    airport_name: "Wales",
    country: "United States",
  },
  {
    sr: 19288,
    city: "Walgett",
    city_code: "WGE",
    airport_name: "Walgett",
    country: "Australia",
  },
  {
    sr: 19290,
    city: "Walla Walla",
    city_code: "ALW",
    airport_name: "Walla Walla",
    country: "United States",
  },
  {
    sr: 19292,
    city: "Wallal",
    city_code: "WLA",
    airport_name: "Wallal",
    country: "Australia",
  },
  {
    sr: 19294,
    city: "Wallis Island",
    city_code: "WLS",
    airport_name: "Wallis Island",
    country: "Wallis and Futuna Islands",
  },
  {
    sr: 19296,
    city: "Walnut Ridge",
    city_code: "ARG",
    airport_name: "Walnut Ridge",
    country: "United States",
  },
  {
    sr: 19298,
    city: "Walterboro",
    city_code: "RBW",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 19300,
    city: "Waltham",
    city_code: "WLM",
    airport_name: "Waltham",
    country: "United States",
  },
  {
    sr: 19302,
    city: "Walvis Bay",
    city_code: "WVB",
    airport_name: "Rooikop",
    country: "Namibia",
  },
  {
    sr: 19304,
    city: "Wamena",
    city_code: "WMX",
    airport_name: "Wamena",
    country: "Indonesia",
  },
  {
    sr: 19306,
    city: "Wana",
    city_code: "WAF",
    airport_name: "Wana",
    country: "Pakistan",
  },
  {
    sr: 19308,
    city: "Wanaka",
    city_code: "WKA",
    airport_name: "Wanaka",
    country: "New Zealand",
  },
  {
    sr: 19312,
    city: "Wanganui",
    city_code: "WAG",
    airport_name: "Wanganui",
    country: "New Zealand",
  },
  {
    sr: 19314,
    city: "Wangaratta",
    city_code: "WGT",
    airport_name: "Wangaratta",
    country: "Australia",
  },
  {
    sr: 19316,
    city: "Wangerooge",
    city_code: "AGE",
    airport_name: "Flugplatz",
    country: "Germany",
  },
  {
    sr: 19318,
    city: "Wanigela",
    city_code: "AGL",
    airport_name: "Wanigela",
    country: "Papua New Guinea",
  },
  {
    sr: 19320,
    city: "Wantoat",
    city_code: "WTT",
    airport_name: "Wantoat",
    country: "Papua New Guinea",
  },
  {
    sr: 19322,
    city: "Wanuma",
    city_code: "WNU",
    airport_name: "Wanuma",
    country: "Papua New Guinea",
  },
  {
    sr: 19324,
    city: "Wanxian",
    city_code: "WXN",
    airport_name: "Wanxian",
    country: "China",
  },
  {
    sr: 19326,
    city: "Wapakoneta",
    city_code: "AXV",
    airport_name: "Neil Armstrong",
    country: "United States",
  },
  {
    sr: 19328,
    city: "Wapenamanda",
    city_code: "WBM",
    airport_name: "Wapenamanda",
    country: "Papua New Guinea",
  },
  {
    sr: 19330,
    city: "Wapolu",
    city_code: "WBC",
    airport_name: "Wapolu",
    country: "Papua New Guinea",
  },
  {
    sr: 19332,
    city: "Warangal",
    city_code: "WGC",
    airport_name: "Warangal",
    country: "India",
  },
  {
    sr: 19334,
    city: "Warder",
    city_code: "WRA",
    airport_name: "Warder",
    country: "Ethiopia",
  },
  {
    sr: 19336,
    city: "Ware",
    city_code: "UWA",
    airport_name: "Ware",
    country: "United States",
  },
  {
    sr: 19338,
    city: "Waris",
    city_code: "WAR",
    airport_name: "Waris",
    country: "Indonesia",
  },
  {
    sr: 19340,
    city: "Warracknabeal",
    city_code: "WKB",
    airport_name: "Warracknabeal",
    country: "Australia",
  },
  {
    sr: 19342,
    city: "Warrawagine",
    city_code: "WRW",
    airport_name: "Warrawagine",
    country: "Australia",
  },
  {
    sr: 19346,
    city: "Warrensburg",
    city_code: "SZL",
    airport_name: "Whiteman AFB",
    country: "United States",
  },
  {
    sr: 19348,
    city: "Warri",
    city_code: "QRW",
    airport_name: "Warri",
    country: "Nigeria",
  },
  {
    sr: 19350,
    city: "Warrnambool",
    city_code: "WMB",
    airport_name: "Warrnambool",
    country: "Australia",
  },
  {
    sr: 19352,
    city: "Warroad",
    city_code: "RRT",
    airport_name: "Warroad",
    country: "United States",
  },
  {
    sr: 19354,
    city: "Warsaw",
    city_code: "WAW",
    airport_name: "Frederic Chopin",
    country: "Poland",
  },
  {
    sr: 19356,
    city: "Warwick",
    city_code: "WAZ",
    airport_name: "Warwick",
    country: "Australia",
  },
  {
    sr: 19358,
    city: "Washabo",
    city_code: "WSO",
    airport_name: "Washabo",
    country: "Suriname",
  },
  {
    sr: 19360,
    city: "Baltimore",
    city_code: "BWI",
    airport_name: "Balt wash International",
    country: "United States",
  },
  {
    sr: 19362,
    city: "Washington",
    city_code: "BOF",
    airport_name: "Bolling AFB",
    country: "United States",
  },
  {
    sr: 19364,
    city: "Washington",
    city_code: "BZS",
    airport_name: "Buzzards Pt S",
    country: "United States",
  },
  {
    sr: 19366,
    city: "Dulles  DC",
    city_code: "IAD",
    airport_name: "Washington Dulles Intl",
    country: "United States",
  },
  {
    sr: 19368,
    city: "Washington",
    city_code: "ZRZ",
    airport_name: "New Carrolton RR",
    country: "United States",
  },
  {
    sr: 19370,
    city: "Washington",
    city_code: "JPN",
    airport_name: "Pentagon Army",
    country: "United States",
  },
  {
    sr: 19372,
    city: "Washington",
    city_code: "DCA",
    airport_name: "Ronald Reagan National",
    country: "United States",
  },
  {
    sr: 19374,
    city: "Leesburg",
    city_code: "ZWU",
    airport_name: "Reagan National",
    country: "United States",
  },
  {
    sr: 19376,
    city: "Washington",
    city_code: "OCW",
    airport_name: "Warren Field",
    country: "United States",
  },
  {
    sr: 19380,
    city: "Washington",
    city_code: "WSG",
    airport_name: "County",
    country: "United States",
  },
  {
    sr: 19382,
    city: "Wasilla",
    city_code: "WWA",
    airport_name: "Wasilla",
    country: "United States",
  },
  {
    sr: 19384,
    city: "Wasior",
    city_code: "WSR",
    airport_name: "Wasior",
    country: "Indonesia",
  },
  {
    sr: 19386,
    city: "Waskaganish",
    city_code: "YKQ",
    airport_name: "Waskaganish",
    country: "Canada",
  },
  {
    sr: 19388,
    city: "Waspam",
    city_code: "WSP",
    airport_name: "Waspam",
    country: "Nicaragua",
  },
  {
    sr: 19390,
    city: "Wasu",
    city_code: "WSU",
    airport_name: "Wasu",
    country: "Papua New Guinea",
  },
  {
    sr: 19392,
    city: "Wasua",
    city_code: "WSA",
    airport_name: "Wasua",
    country: "Papua New Guinea",
  },
  {
    sr: 19394,
    city: "Wasum",
    city_code: "WUM",
    airport_name: "Wasum",
    country: "Papua New Guinea",
  },
  {
    sr: 19396,
    city: "Waterfall",
    city_code: "KWF",
    airport_name: "Waterfall SPB",
    country: "United States",
  },
  {
    sr: 19398,
    city: "Waterford",
    city_code: "WAT",
    airport_name: "Waterford",
    country: "Ireland",
  },
  {
    sr: 19400,
    city: "Waterkloof",
    city_code: "WKF",
    airport_name: "Waterkloof  FAWK",
    country: "South Africa",
  },
  {
    sr: 19402,
    city: "Waterloo",
    city_code: "ALO",
    airport_name: "Waterloo",
    country: "United States",
  },
  {
    sr: 19404,
    city: "Waterloo",
    city_code: "WLO",
    airport_name: "Waterloo",
    country: "Australia",
  },
  {
    sr: 19406,
    city: "Watertown",
    city_code: "ART",
    airport_name: "Watertown",
    country: "United States",
  },
  {
    sr: 19408,
    city: "Watertown",
    city_code: "ATY",
    airport_name: "Watertown",
    country: "United States",
  },
  {
    sr: 19410,
    city: "Waterville",
    city_code: "WVL",
    airport_name: "Robert Lafleur",
    country: "United States",
  },
  {
    sr: 19414,
    city: "Watson Lake",
    city_code: "YQH",
    airport_name: "Watson Lake",
    country: "Canada",
  },
  {
    sr: 19416,
    city: "Watsonville",
    city_code: "WVI",
    airport_name: "Watsonville",
    country: "United States",
  },
  {
    sr: 19418,
    city: "Wau",
    city_code: "WUG",
    airport_name: "Wau",
    country: "Papua New Guinea",
  },
  {
    sr: 19420,
    city: "Wau",
    city_code: "WUU",
    airport_name: "Wau",
    country: "Sudan",
  },
  {
    sr: 19422,
    city: "Wauchope",
    city_code: "WAU",
    airport_name: "Wauchope",
    country: "Australia",
  },
  {
    sr: 19424,
    city: "Waukegan",
    city_code: "UGN",
    airport_name: "Memorial",
    country: "United States",
  },
  {
    sr: 19426,
    city: "Waukesha",
    city_code: "UES",
    airport_name: "Waukesha",
    country: "United States",
  },
  {
    sr: 19428,
    city: "Waukon",
    city_code: "UKN",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 19430,
    city: "Wausau",
    city_code: "CWA",
    airport_name: "Central Wisconsin",
    country: "United States",
  },
  {
    sr: 19432,
    city: "Wausau",
    city_code: "AUW",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 19434,
    city: "Wave Hill",
    city_code: "WAV",
    airport_name: "Kalkgurung",
    country: "Australia",
  },
  {
    sr: 19436,
    city: "Waverney",
    city_code: "WAN",
    airport_name: "Waverney",
    country: "Australia",
  },
  {
    sr: 19438,
    city: "Wawa",
    city_code: "YXZ",
    airport_name: "Wawa",
    country: "Canada",
  },
  {
    sr: 19440,
    city: "Wawoi Falls",
    city_code: "WAJ",
    airport_name: "Wawoi Falls",
    country: "Papua New Guinea",
  },
  {
    sr: 19442,
    city: "Waycross",
    city_code: "AYS",
    airport_name: "Ware County",
    country: "United States",
  },
  {
    sr: 19444,
    city: "Waynesburg",
    city_code: "WAY",
    airport_name: "Green County",
    country: "United States",
  },
  {
    sr: 19448,
    city: "Weam",
    city_code: "WEP",
    airport_name: "Weam",
    country: "Papua New Guinea",
  },
  {
    sr: 19450,
    city: "Weasua",
    city_code: "WES",
    airport_name: "Weasua",
    country: "Liberia",
  },
  {
    sr: 19452,
    city: "Weatherford",
    city_code: "WEA",
    airport_name: "Parker County",
    country: "United States",
  },
  {
    sr: 19454,
    city: "Webequie",
    city_code: "YWP",
    airport_name: "Webequie",
    country: "Canada",
  },
  {
    sr: 19456,
    city: "Webster City",
    city_code: "EBS",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 19458,
    city: "Wedau",
    city_code: "WED",
    airport_name: "Wedau",
    country: "Papua New Guinea",
  },
  {
    sr: 19460,
    city: "Wedjh",
    city_code: "EJH",
    airport_name: "Wedjh",
    country: "Saudi Arabia",
  },
  {
    sr: 19462,
    city: "Wee Waa",
    city_code: "WEW",
    airport_name: "Wee Waa",
    country: "Australia",
  },
  {
    sr: 19464,
    city: "Weeping Water",
    city_code: "EPG",
    airport_name: "Browns",
    country: "United States",
  },
  {
    sr: 19466,
    city: "Weerawila",
    city_code: "WRZ",
    airport_name: "Weerawila",
    country: "Sri Lanka",
  },
  {
    sr: 19468,
    city: "Weifang",
    city_code: "WEF",
    airport_name: "Weifang",
    country: "China",
  },
  {
    sr: 19470,
    city: "Weihai",
    city_code: "WEH",
    airport_name: "Weihai",
    country: "China",
  },
  {
    sr: 19472,
    city: "Weipa",
    city_code: "WEI",
    airport_name: "Weipa",
    country: "Australia",
  },
  {
    sr: 19474,
    city: "Welkom",
    city_code: "WEL",
    airport_name: "Welkom",
    country: "South Africa",
  },
  {
    sr: 19476,
    city: "Wellington",
    city_code: "WLG",
    airport_name: "Wellington International",
    country: "New Zealand",
  },
  {
    sr: 19478,
    city: "Wells",
    city_code: "LWL",
    airport_name: "Harriet Field",
    country: "United States",
  },
  {
    sr: 19482,
    city: "Wellsville",
    city_code: "ELZ",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 19484,
    city: "Welshpool",
    city_code: "WHL",
    airport_name: "Welshpool",
    country: "Australia",
  },
  {
    sr: 19486,
    city: "Wemindji",
    city_code: "YNC",
    airport_name: "Wemindji",
    country: "Canada",
  },
  {
    sr: 19488,
    city: "Wenatchee",
    city_code: "EAT",
    airport_name: "Pangborn Field",
    country: "United States",
  },
  {
    sr: 19490,
    city: "Wendover",
    city_code: "ENV",
    airport_name: "Wendover",
    country: "United States",
  },
  {
    sr: 19492,
    city: "Wenzhou",
    city_code: "WNZ",
    airport_name: "Wenzhou",
    country: "China",
  },
  {
    sr: 19494,
    city: "West Bend",
    city_code: "ETB",
    airport_name: "West Bend",
    country: "United States",
  },
  {
    sr: 19496,
    city: "West End",
    city_code: "WTD",
    airport_name: "West End",
    country: "Bahamas",
  },
  {
    sr: 19498,
    city: "West Kavik",
    city_code: "VKW",
    airport_name: "West Kavik",
    country: "United States",
  },
  {
    sr: 19500,
    city: "West Kuparuk",
    city_code: "XPU",
    airport_name: "West Kuparuk",
    country: "United States",
  },
  {
    sr: 19502,
    city: "West Malling",
    city_code: "WEM",
    airport_name: "West Malling",
    country: "United Kingdom",
  },
  {
    sr: 19504,
    city: "West Memphis",
    city_code: "AWM",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 19506,
    city: "West Palm Beach",
    city_code: "PBI",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 19508,
    city: "West Palm Beach",
    city_code: "LNA",
    airport_name: "Palm Beach County Park",
    country: "United States",
  },
  {
    sr: 19510,
    city: "West Point",
    city_code: "KWP",
    airport_name: "Village SPB",
    country: "United States",
  },
  {
    sr: 19512,
    city: "West Wyalong",
    city_code: "WWY",
    airport_name: "West Wyalong",
    country: "Australia",
  },
  {
    sr: 19516,
    city: "West Yellowstone",
    city_code: "WYS",
    airport_name: "Yellowstone",
    country: "United States",
  },
  {
    sr: 19518,
    city: "Westchester County",
    city_code: "ZTF",
    airport_name: "Stamford Rail STN",
    country: "United States",
  },
  {
    sr: 19520,
    city: "Westchester County",
    city_code: "HPN",
    airport_name: "Westchester County Apt",
    country: "United States",
  },
  {
    sr: 19522,
    city: "Westerland",
    city_code: "GWT",
    airport_name: "Westerland   Sylt",
    country: "Germany",
  },
  {
    sr: 19524,
    city: "Westerly",
    city_code: "WST",
    airport_name: "Westerly State",
    country: "United States",
  },
  {
    sr: 19526,
    city: "Westfield",
    city_code: "BAF",
    airport_name: "Barnes",
    country: "United States",
  },
  {
    sr: 19528,
    city: "Westhampton",
    city_code: "FOK",
    airport_name: "Suffolk County",
    country: "United States",
  },
  {
    sr: 19530,
    city: "Westport",
    city_code: "WSZ",
    airport_name: "Westport",
    country: "New Zealand",
  },
  {
    sr: 19532,
    city: "Westray",
    city_code: "WRY",
    airport_name: "Westray",
    country: "United Kingdom",
  },
  {
    sr: 19534,
    city: "Westsound",
    city_code: "WSX",
    airport_name: "Westsound",
    country: "United States",
  },
  {
    sr: 19536,
    city: "Wewak",
    city_code: "WWK",
    airport_name: "Boram",
    country: "Papua New Guinea",
  },
  {
    sr: 19538,
    city: "Wexford",
    city_code: "WEX",
    airport_name: "Castlebridge",
    country: "Ireland",
  },
  {
    sr: 19540,
    city: "Wha Ti Lac La Martre",
    city_code: "YLE",
    airport_name: "Wha Ti",
    country: "Canada",
  },
  {
    sr: 19542,
    city: "Whakatane",
    city_code: "WHK",
    airport_name: "Whakatane",
    country: "New Zealand",
  },
  {
    sr: 19544,
    city: "Whale Cove",
    city_code: "YXN",
    airport_name: "Whale Cove",
    country: "Canada",
  },
  {
    sr: 19546,
    city: "Whale Pass",
    city_code: "WWP",
    airport_name: "Whale Pass",
    country: "United States",
  },
  {
    sr: 19550,
    city: "Whalsay",
    city_code: "WHS",
    airport_name: "Whalsay",
    country: "United Kingdom",
  },
  {
    sr: 19552,
    city: "Whangarei",
    city_code: "WRE",
    airport_name: "Whangarei",
    country: "New Zealand",
  },
  {
    sr: 19554,
    city: "Wharton",
    city_code: "WHT",
    airport_name: "Wharton",
    country: "United States",
  },
  {
    sr: 19556,
    city: "Wheatland",
    city_code: "EAN",
    airport_name: "Phifer Field",
    country: "United States",
  },
  {
    sr: 19558,
    city: "Wheeling",
    city_code: "HLG",
    airport_name: "Ohio County",
    country: "United States",
  },
  {
    sr: 19560,
    city: "Whidbey Island",
    city_code: "NUW",
    airport_name: "Ault Field",
    country: "United States",
  },
  {
    sr: 19562,
    city: "Whistler",
    city_code: "YWS",
    airport_name: "Whistler",
    country: "Canada",
  },
  {
    sr: 19564,
    city: "White Mountain",
    city_code: "WMO",
    airport_name: "White Mountain",
    country: "United States",
  },
  {
    sr: 19566,
    city: "White River",
    city_code: "WTR",
    airport_name: "White River",
    country: "United States",
  },
  {
    sr: 19568,
    city: "White River",
    city_code: "YWR",
    airport_name: "White River",
    country: "Canada",
  },
  {
    sr: 19570,
    city: "White River",
    city_code: "LEB",
    airport_name: "White River",
    country: "United States",
  },
  {
    sr: 19572,
    city: "White Sands",
    city_code: "WSD",
    airport_name: "Condron AAF",
    country: "United States",
  },
  {
    sr: 19574,
    city: "White Sulphur Springs",
    city_code: "SSU",
    airport_name: "Greenbrier",
    country: "United States",
  },
  {
    sr: 19576,
    city: "Whitecourt",
    city_code: "YZU",
    airport_name: "Whitecourt",
    country: "Canada",
  },
  {
    sr: 19578,
    city: "Whitefield",
    city_code: "HIE",
    airport_name: "Regional",
    country: "United States",
  },
  {
    sr: 19580,
    city: "Whitehorse",
    city_code: "YXY",
    airport_name: "Whitehorse",
    country: "Canada",
  },
  {
    sr: 19584,
    city: "Whitehouse",
    city_code: "NEN",
    airport_name: "Olf Usn",
    country: "United States",
  },
  {
    sr: 19586,
    city: "Whitesburg",
    city_code: "BRG",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 19588,
    city: "Whitianga",
    city_code: "WTZ",
    airport_name: "Whitianga",
    country: "New Zealand",
  },
  {
    sr: 19590,
    city: "Whyalla",
    city_code: "WYA",
    airport_name: "Whyalla",
    country: "Australia",
  },
  {
    sr: 19592,
    city: "Wiarton",
    city_code: "YVV",
    airport_name: "Wiarton",
    country: "Canada",
  },
  {
    sr: 19594,
    city: "Wichita",
    city_code: "BEC",
    airport_name: "Beech",
    country: "United States",
  },
  {
    sr: 19596,
    city: "Wichita",
    city_code: "CEA",
    airport_name: "Cessna Aircraft Field",
    country: "United States",
  },
  {
    sr: 19598,
    city: "Wichita",
    city_code: "IAB",
    airport_name: "Mcconnell AFB",
    country: "United States",
  },
  {
    sr: 19600,
    city: "Wichita",
    city_code: "ICT",
    airport_name: "Mid Continent",
    country: "United States",
  },
  {
    sr: 19602,
    city: "Wichita Falls",
    city_code: "KIP",
    airport_name: "Kickapoo",
    country: "United States",
  },
  {
    sr: 19604,
    city: "Wichita Falls",
    city_code: "SPS",
    airport_name: "Sheppard AFB",
    country: "United States",
  },
  {
    sr: 19606,
    city: "Wick",
    city_code: "WIC",
    airport_name: "Wick",
    country: "United Kingdom",
  },
  {
    sr: 19608,
    city: "Wiesbaden",
    city_code: "WIE",
    airport_name: "Air Base",
    country: "Germany",
  },
  {
    sr: 19610,
    city: "Wilcannia",
    city_code: "WIO",
    airport_name: "Wilcannia",
    country: "Australia",
  },
  {
    sr: 19612,
    city: "Wildenrath",
    city_code: "WID",
    airport_name: "Wildenrath",
    country: "Germany",
  },
  {
    sr: 19614,
    city: "Wildman Lake",
    city_code: "EWD",
    airport_name: "Wildman Lake",
    country: "United States",
  },
  {
    sr: 19618,
    city: "Wildwood",
    city_code: "WWS",
    airport_name: "USAF Heliport",
    country: "United States",
  },
  {
    sr: 19620,
    city: "Wildwood",
    city_code: "WWD",
    airport_name: "Cape May County",
    country: "United States",
  },
  {
    sr: 19622,
    city: "Wilhelmshaven",
    city_code: "WVN",
    airport_name: "Wilhelmshaven",
    country: "Germany",
  },
  {
    sr: 19624,
    city: "Wilkes Barre",
    city_code: "AVP",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 19626,
    city: "Wilkes Barre",
    city_code: "WBW",
    airport_name: "Wyoming Valle",
    country: "United States",
  },
  {
    sr: 19628,
    city: "Wilkesboro",
    city_code: "IKB",
    airport_name: "Wilkes County",
    country: "United States",
  },
  {
    sr: 19630,
    city: "Williams Harbour",
    city_code: "YWM",
    airport_name: "Williams Harbour",
    country: "Canada",
  },
  {
    sr: 19632,
    city: "Williams Lake",
    city_code: "YWL",
    airport_name: "Williams Lake",
    country: "Canada",
  },
  {
    sr: 19634,
    city: "Williamsburg",
    city_code: "PHF",
    airport_name: "Newport News Williamsb",
    country: "United States",
  },
  {
    sr: 19636,
    city: "Williamsport",
    city_code: "IPT",
    airport_name: "Lycoming County",
    country: "United States",
  },
  {
    sr: 19638,
    city: "Williston",
    city_code: "ISN",
    airport_name: "Sloulin Field Intl",
    country: "United States",
  },
  {
    sr: 19640,
    city: "Willmar",
    city_code: "ILL",
    airport_name: "Willmar",
    country: "United States",
  },
  {
    sr: 19642,
    city: "Willoughby",
    city_code: "LNN",
    airport_name: "Lost Nation",
    country: "United States",
  },
  {
    sr: 19644,
    city: "Willow",
    city_code: "WOW",
    airport_name: "Willow",
    country: "United States",
  },
  {
    sr: 19646,
    city: "Willow Grove",
    city_code: "NXX",
    airport_name: "Willow Grove NAS",
    country: "United States",
  },
  {
    sr: 19648,
    city: "Willows",
    city_code: "WLW",
    airport_name: "Glenn County",
    country: "United States",
  },
  {
    sr: 19652,
    city: "Wilmington",
    city_code: "ILG",
    airport_name: "Greater Wilmington",
    country: "United States",
  },
  {
    sr: 19654,
    city: "Wilmington",
    city_code: "ZWI",
    airport_name: "Wilmington Rail",
    country: "United States",
  },
  {
    sr: 19656,
    city: "Wilmington",
    city_code: "ILM",
    airport_name: "New Hanover County",
    country: "United States",
  },
  {
    sr: 19658,
    city: "Wilmington",
    city_code: "ILN",
    airport_name: "Clinton Field",
    country: "United States",
  },
  {
    sr: 19660,
    city: "Wiluna",
    city_code: "WUN",
    airport_name: "Wiluna",
    country: "Australia",
  },
  {
    sr: 19662,
    city: "Winchester",
    city_code: "WGO",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 19664,
    city: "Windarra",
    city_code: "WND",
    airport_name: "Windarra",
    country: "Australia",
  },
  {
    sr: 19666,
    city: "Winder",
    city_code: "WDR",
    airport_name: "Winder",
    country: "United States",
  },
  {
    sr: 19668,
    city: "Windhoek",
    city_code: "ERS",
    airport_name: "Eros",
    country: "Namibia",
  },
  {
    sr: 19670,
    city: "Windhoek",
    city_code: "ZWH",
    airport_name: "Railway Station",
    country: "Namibia",
  },
  {
    sr: 19672,
    city: "Windom",
    city_code: "MWM",
    airport_name: "Municipal",
    country: "MINNESOTA",
  },
  {
    sr: 19674,
    city: "Windorah",
    city_code: "WNR",
    airport_name: "Windorah",
    country: "Australia",
  },
  {
    sr: 19676,
    city: "Windsor",
    city_code: "YQG",
    airport_name: "Windsor",
    country: "Canada",
  },
  {
    sr: 19678,
    city: "Windsor Locks",
    city_code: "BDL",
    airport_name: "Bradley International",
    country: "United States",
  },
  {
    sr: 19680,
    city: "Winfield",
    city_code: "WLD",
    airport_name: "Arkansas City",
    country: "United States",
  },
  {
    sr: 19682,
    city: "Winisk",
    city_code: "YWN",
    airport_name: "Winisk",
    country: "Canada",
  },
  {
    sr: 19686,
    city: "Wink",
    city_code: "INK",
    airport_name: "Wink",
    country: "United States",
  },
  {
    sr: 19688,
    city: "Winnemucca",
    city_code: "WMC",
    airport_name: "Winnemucca",
    country: "United States",
  },
  {
    sr: 19690,
    city: "Winnipeg",
    city_code: "YWG",
    airport_name: "Winnipeg",
    country: "Canada",
  },
  {
    sr: 19692,
    city: "Winona",
    city_code: "ONA",
    airport_name: "Municipal",
    country: "United States",
  },
  {
    sr: 19694,
    city: "Winslow",
    city_code: "INW",
    airport_name: "Winslow",
    country: "United States",
  },
  {
    sr: 19696,
    city: "Winston Salem",
    city_code: "INT",
    airport_name: "Smith Reynolds",
    country: "United States",
  },
  {
    sr: 19698,
    city: "Winter Haven",
    city_code: "GIF",
    airport_name: "Gilbert Field",
    country: "United States",
  },
  {
    sr: 19700,
    city: "Winter Park",
    city_code: "QWP",
    airport_name: "Van Service",
    country: "United States",
  },
  {
    sr: 19702,
    city: "Winton",
    city_code: "WIN",
    airport_name: "Winton",
    country: "Australia",
  },
  {
    sr: 19704,
    city: "Wipim",
    city_code: "WPM",
    airport_name: "Wipim",
    country: "Papua New Guinea",
  },
  {
    sr: 19706,
    city: "Wiscasset",
    city_code: "ISS",
    airport_name: "Wiscasset",
    country: "United States",
  },
  {
    sr: 19708,
    city: "Wisconsin Rapids",
    city_code: "ISW",
    airport_name: "Alexander Field",
    country: "United States",
  },
  {
    sr: 19710,
    city: "Wise",
    city_code: "LNP",
    airport_name: "Wise",
    country: "United States",
  },
  {
    sr: 19712,
    city: "Wiseman",
    city_code: "WSM",
    airport_name: "Wiseman",
    country: "United States",
  },
  {
    sr: 19714,
    city: "Wittenoom",
    city_code: "WIT",
    airport_name: "Wittenoom",
    country: "Australia",
  },
  {
    sr: 19716,
    city: "Witu",
    city_code: "WIU",
    airport_name: "Witu",
    country: "Papua New Guinea",
  },
  {
    sr: 19720,
    city: "Woburn",
    city_code: "WBN",
    airport_name: "Cummings Park",
    country: "United States",
  },
  {
    sr: 19722,
    city: "Woensdrecht AB",
    city_code: "WOE",
    airport_name: "Woensdrecht AB",
    country: "Netherlands",
  },
  {
    sr: 19724,
    city: "Woitape",
    city_code: "WTP",
    airport_name: "Woitape",
    country: "Papua New Guinea",
  },
  {
    sr: 19726,
    city: "Woja",
    city_code: "WJA",
    airport_name: "Woja",
    country: "Marshall Islands",
  },
  {
    sr: 19728,
    city: "Wolf Point",
    city_code: "OLF",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 19730,
    city: "Wollaston Lake",
    city_code: "ZWL",
    airport_name: "Wollaston Lake",
    country: "Canada",
  },
  {
    sr: 19732,
    city: "Wollogorang",
    city_code: "WLL",
    airport_name: "Wollogorang",
    country: "Australia",
  },
  {
    sr: 19734,
    city: "Wollongong",
    city_code: "WOL",
    airport_name: "Wollongong",
    country: "Australia",
  },
  {
    sr: 19736,
    city: "Wologissi",
    city_code: "WOI",
    airport_name: "Wologissi",
    country: "Liberia",
  },
  {
    sr: 19738,
    city: "Wonan",
    city_code: "WOT",
    airport_name: "Wonan",
    country: "Taiwan",
  },
  {
    sr: 19740,
    city: "Wondai",
    city_code: "WDI",
    airport_name: "Wondai",
    country: "Australia",
  },
  {
    sr: 19742,
    city: "Wondoola",
    city_code: "WON",
    airport_name: "Wondoola",
    country: "Australia",
  },
  {
    sr: 19744,
    city: "Wonenara",
    city_code: "WOA",
    airport_name: "Wonenara",
    country: "Papua New Guinea",
  },
  {
    sr: 19746,
    city: "WonJu",
    city_code: "WJU",
    airport_name: "WonJu",
    country: "South Korea",
  },
  {
    sr: 19748,
    city: "Wonken",
    city_code: "WOK",
    airport_name: "Wonken",
    country: "Venezuela",
  },
  {
    sr: 19750,
    city: "Wood River",
    city_code: "WOD",
    airport_name: "Wood River",
    country: "United States",
  },
  {
    sr: 19754,
    city: "Woodbridge",
    city_code: "BWY",
    airport_name: "Bentwaters St",
    country: "United Kingdom",
  },
  {
    sr: 19756,
    city: "Woodchopper",
    city_code: "WOO",
    airport_name: "Woodchopper",
    country: "United States",
  },
  {
    sr: 19758,
    city: "Woodford",
    city_code: "WFD",
    airport_name: "Woodford",
    country: "United Kingdom",
  },
  {
    sr: 19760,
    city: "Woodgreen",
    city_code: "WOG",
    airport_name: "Woodgreen",
    country: "Australia",
  },
  {
    sr: 19762,
    city: "Woodie Woodie",
    city_code: "WWI",
    airport_name: "Woodie Woodie",
    country: "Australia",
  },
  {
    sr: 19764,
    city: "Woodward",
    city_code: "WWR",
    airport_name: "West Woodward",
    country: "United States",
  },
  {
    sr: 19766,
    city: "Woomera",
    city_code: "UMR",
    airport_name: "Woomera",
    country: "Australia",
  },
  {
    sr: 19768,
    city: "Wooster",
    city_code: "BJJ",
    airport_name: "Wayne County",
    country: "United States",
  },
  {
    sr: 19770,
    city: "Wora Na Ye",
    city_code: "WNE",
    airport_name: "Wora Na Ye",
    country: "Gabon",
  },
  {
    sr: 19772,
    city: "Worcester",
    city_code: "ORH",
    airport_name: "Worcester",
    country: "United States",
  },
  {
    sr: 19774,
    city: "Worland",
    city_code: "WRL",
    airport_name: "Worland",
    country: "United States",
  },
  {
    sr: 19776,
    city: "Worthington",
    city_code: "OTG",
    airport_name: "Worthington",
    country: "United States",
  },
  {
    sr: 19778,
    city: "Wotho Island",
    city_code: "WTO",
    airport_name: "Wotho Island",
    country: "Marshall Islands",
  },
  {
    sr: 19780,
    city: "Wotje Island",
    city_code: "WTE",
    airport_name: "Wotje Island",
    country: "Marshall Islands",
  },
  {
    sr: 19782,
    city: "Wrangell",
    city_code: "WRG",
    airport_name: "Wrangell SPB",
    country: "United States",
  },
  {
    sr: 19784,
    city: "Wrench Creek",
    city_code: "WRH",
    airport_name: "Wrench Creek",
    country: "United States",
  },
  {
    sr: 19788,
    city: "Wrigley",
    city_code: "YWY",
    airport_name: "Wrigley",
    country: "Canada",
  },
  {
    sr: 19790,
    city: "Wroclaw",
    city_code: "WRO",
    airport_name: "Strachowice",
    country: "Poland",
  },
  {
    sr: 19792,
    city: "Wrotham Park",
    city_code: "WPK",
    airport_name: "Wrotham Park",
    country: "Australia",
  },
  {
    sr: 19794,
    city: "Wu Hai",
    city_code: "WUA",
    airport_name: "Wu Hai",
    country: "China",
  },
  {
    sr: 19796,
    city: "Wudinna",
    city_code: "WUD",
    airport_name: "Wudinna",
    country: "Australia",
  },
  {
    sr: 19798,
    city: "Wuhan",
    city_code: "WUH",
    airport_name: "Wuhan",
    country: "China",
  },
  {
    sr: 19800,
    city: "Wuhu",
    city_code: "WHU",
    airport_name: "Wuhu",
    country: "China",
  },
  {
    sr: 19802,
    city: "Wunnummin Lake",
    city_code: "WNN",
    airport_name: "Wunnummin Lake",
    country: "Canada",
  },
  {
    sr: 19804,
    city: "Wuvulu Is",
    city_code: "WUV",
    airport_name: "Wuvulu Is",
    country: "Papua New Guinea",
  },
  {
    sr: 19806,
    city: "Wuxi",
    city_code: "WUX",
    airport_name: "Wuxi",
    country: "China",
  },
  {
    sr: 19808,
    city: "Wuyishan",
    city_code: "WUS",
    airport_name: "Wuyishan",
    country: "China",
  },
  {
    sr: 19810,
    city: "Wuzhou",
    city_code: "WUZ",
    airport_name: "Changzhoudao",
    country: "China",
  },
  {
    sr: 19812,
    city: "Wyk Auf Foehr",
    city_code: "OHR",
    airport_name: "Wyk Auf Foehr",
    country: "Germany",
  },
  {
    sr: 19814,
    city: "Wyndham",
    city_code: "WYN",
    airport_name: "Wyndham",
    country: "Australia",
  },
  {
    sr: 19816,
    city: "Xai Xai",
    city_code: "VJB",
    airport_name: "Xai Xai",
    country: "Mozambique",
  },
  {
    sr: 19818,
    city: "Xangongo",
    city_code: "XGN",
    airport_name: "Xangongo",
    country: "Angola",
  },
  {
    sr: 19822,
    city: "Xanxere",
    city_code: "AXE",
    airport_name: "Xanxere",
    country: "Brazil",
  },
  {
    sr: 19824,
    city: "Xayabury",
    city_code: "XAY",
    airport_name: "Xayabury",
    country: "Laos",
  },
  {
    sr: 19826,
    city: "Xi An",
    city_code: "XIY",
    airport_name: "Xianyang",
    country: "China",
  },
  {
    sr: 19828,
    city: "Xi An",
    city_code: "SIA",
    airport_name: "Xiguan",
    country: "China",
  },
  {
    sr: 19830,
    city: "Xiamen",
    city_code: "XMN",
    airport_name: "Xiamen",
    country: "China",
  },
  {
    sr: 19832,
    city: "Xiangfan",
    city_code: "XFN",
    airport_name: "Xiangfan",
    country: "China",
  },
  {
    sr: 19834,
    city: "Xichang",
    city_code: "XIC",
    airport_name: "Xichang",
    country: "China",
  },
  {
    sr: 19836,
    city: "Xieng Khouang",
    city_code: "XKH",
    airport_name: "Xieng Khouang",
    country: "Laos",
  },
  {
    sr: 19838,
    city: "Xienglom",
    city_code: "XIE",
    airport_name: "Xienglom",
    country: "Laos",
  },
  {
    sr: 19840,
    city: "Xilinhot",
    city_code: "XIL",
    airport_name: "Xilinhot",
    country: "China",
  },
  {
    sr: 19842,
    city: "Xin Hui",
    city_code: "ZBZ",
    airport_name: "Xin Hui Ferry",
    country: "China",
  },
  {
    sr: 19844,
    city: "Xingcheng",
    city_code: "XEN",
    airport_name: "Xingcheng",
    country: "China",
  },
  {
    sr: 19846,
    city: "Xingning",
    city_code: "XIN",
    airport_name: "Xingning",
    country: "China",
  },
  {
    sr: 19848,
    city: "Xingtai",
    city_code: "XNT",
    airport_name: "Xingtai",
    country: "China",
  },
  {
    sr: 19850,
    city: "Xinguara",
    city_code: "XIG",
    airport_name: "Municipal",
    country: "Brazil",
  },
  {
    sr: 19852,
    city: "Xingyi",
    city_code: "ACX",
    airport_name: "Xingyi",
    country: "China",
  },
  {
    sr: 19856,
    city: "Xining",
    city_code: "XNN",
    airport_name: "Xining",
    country: "China",
  },
  {
    sr: 19858,
    city: "Xuzhou",
    city_code: "XUZ",
    airport_name: "Xuzhou",
    country: "China",
  },
  {
    sr: 19860,
    city: "Yacuiba",
    city_code: "BYC",
    airport_name: "Yacuiba",
    country: "Bolivia",
  },
  {
    sr: 19862,
    city: "Yagoua",
    city_code: "GXX",
    airport_name: "Yagoua",
    country: "Cameroon",
  },
  {
    sr: 19864,
    city: "Yaguara",
    city_code: "AYG",
    airport_name: "Yaguara",
    country: "Colombia",
  },
  {
    sr: 19866,
    city: "Yakataga",
    city_code: "CYT",
    airport_name: "Intermediate",
    country: "United States",
  },
  {
    sr: 19868,
    city: "Yakima",
    city_code: "FCT",
    airport_name: "Firing Center AAF",
    country: "United States",
  },
  {
    sr: 19870,
    city: "Yakima",
    city_code: "YKM",
    airport_name: "Yakima Air Terminal",
    country: "United States",
  },
  {
    sr: 19872,
    city: "Yakushima",
    city_code: "KUM",
    airport_name: "Yakushima",
    country: "Japan",
  },
  {
    sr: 19874,
    city: "Yakutat",
    city_code: "YAK",
    airport_name: "Yakutat",
    country: "United States",
  },
  {
    sr: 19876,
    city: "Yakutsk",
    city_code: "YKS",
    airport_name: "Yakutsk",
    country: "Russia",
  },
  {
    sr: 19878,
    city: "Yalata Mission",
    city_code: "KYI",
    airport_name: "Yalata Mission",
    country: "Australia",
  },
  {
    sr: 19880,
    city: "Yalgoo",
    city_code: "YLG",
    airport_name: "Yalgoo",
    country: "Australia",
  },
  {
    sr: 19882,
    city: "Yalinga",
    city_code: "AIG",
    airport_name: "Yalinga",
    country: "Central African Republic",
  },
  {
    sr: 19884,
    city: "Yalumet",
    city_code: "KYX",
    airport_name: "Yalumet",
    country: "Papua New Guinea",
  },
  {
    sr: 19886,
    city: "Yam Island",
    city_code: "XMY",
    airport_name: "Yam Island",
    country: "Australia",
  },
  {
    sr: 19890,
    city: "Yamagata",
    city_code: "GAJ",
    airport_name: "Junmachi",
    country: "Japan",
  },
  {
    sr: 19892,
    city: "Yamoussoukro",
    city_code: "ASK",
    airport_name: "Yamoussoukro",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 19894,
    city: "Yanbu",
    city_code: "YNB",
    airport_name: "Yanbu",
    country: "Saudi Arabia",
  },
  {
    sr: 19896,
    city: "Yancheng",
    city_code: "YNZ",
    airport_name: "Yancheng",
    country: "China",
  },
  {
    sr: 19898,
    city: "Yandicoogina",
    city_code: "YNN",
    airport_name: "Yandi",
    country: "Australia",
  },
  {
    sr: 19900,
    city: "Yandina",
    city_code: "XYA",
    airport_name: "Yandina",
    country: "Solomon Islands",
  },
  {
    sr: 19902,
    city: "Yangambi",
    city_code: "YAN",
    airport_name: "Yangambi",
    country: "Democratic Republic of the Congo",
  },
  {
    sr: 19904,
    city: "Yangon",
    city_code: "RGN",
    airport_name: "Mingaladon",
    country: "Myanmar",
  },
  {
    sr: 19906,
    city: "Yangoonabie",
    city_code: "KYB",
    airport_name: "Yangoonabie",
    country: "Australia",
  },
  {
    sr: 19908,
    city: "Yangyang",
    city_code: "YNY",
    airport_name: "Yangyang",
    country: "South Korea",
  },
  {
    sr: 19910,
    city: "Yanji",
    city_code: "YNJ",
    airport_name: "Yanji",
    country: "China",
  },
  {
    sr: 19912,
    city: "Yankton",
    city_code: "YKN",
    airport_name: "Chan Gurney",
    country: "United States",
  },
  {
    sr: 19914,
    city: "Yantai",
    city_code: "YNT",
    airport_name: "Laishan",
    country: "China",
  },
  {
    sr: 19916,
    city: "Yaounde",
    city_code: "NSI",
    airport_name: "Nsimalen",
    country: "Cameroon",
  },
  {
    sr: 19918,
    city: "Yaounde",
    city_code: "YAO",
    airport_name: "Yaounde Airport",
    country: "Cameroon",
  },
  {
    sr: 19920,
    city: "Yap",
    city_code: "YAP",
    airport_name: "Yap",
    country: "Micronesia",
  },
  {
    sr: 19924,
    city: "Yapsiei",
    city_code: "KPE",
    airport_name: "Yapsiei",
    country: "Papua New Guinea",
  },
  {
    sr: 19926,
    city: "Yari",
    city_code: "AYI",
    airport_name: "Yari",
    country: "Colombia",
  },
  {
    sr: 19928,
    city: "Yarmouth",
    city_code: "YQI",
    airport_name: "Yarmouth",
    country: "Canada",
  },
  {
    sr: 19930,
    city: "Yaroslavl",
    city_code: "IAR",
    airport_name: "Yaroslavl",
    country: "Russia",
  },
  {
    sr: 19932,
    city: "Yasawa Island",
    city_code: "YAS",
    airport_name: "Yasawa Island",
    country: "Fiji",
  },
  {
    sr: 19934,
    city: "Yasouj",
    city_code: "YES",
    airport_name: "Yasouj",
    country: "Iran",
  },
  {
    sr: 19936,
    city: "Yasuru",
    city_code: "KSX",
    airport_name: "Yasuru",
    country: "Papua New Guinea",
  },
  {
    sr: 19938,
    city: "Yavarate",
    city_code: "VAB",
    airport_name: "Yavarate",
    country: "Colombia",
  },
  {
    sr: 19940,
    city: "Yaviza",
    city_code: "PYV",
    airport_name: "Yaviza",
    country: "Panama",
  },
  {
    sr: 19942,
    city: "Yazd",
    city_code: "AZD",
    airport_name: "Yazd",
    country: "Iran",
  },
  {
    sr: 19944,
    city: "Ye",
    city_code: "XYE",
    airport_name: "Ye",
    country: "Myanmar",
  },
  {
    sr: 19946,
    city: "Yecheon",
    city_code: "YEC",
    airport_name: "Yecheon",
    country: "South Korea",
  },
  {
    sr: 19948,
    city: "Yeelirrie",
    city_code: "KYF",
    airport_name: "Yeelirrie",
    country: "Australia",
  },
  {
    sr: 19950,
    city: "Yegepa",
    city_code: "PGE",
    airport_name: "Yegepa",
    country: "Papua New Guinea",
  },
  {
    sr: 19952,
    city: "Yelimane",
    city_code: "EYL",
    airport_name: "Yelimane",
    country: "Mali",
  },
  {
    sr: 19954,
    city: "Yellow River",
    city_code: "XYR",
    airport_name: "Yellow River",
    country: "Papua New Guinea",
  },
  {
    sr: 19958,
    city: "Yellowknife",
    city_code: "YZF",
    airport_name: "Yellowknife",
    country: "Canada",
  },
  {
    sr: 19960,
    city: "Yengema",
    city_code: "WYE",
    airport_name: "Yengema",
    country: "Sierra Leone",
  },
  {
    sr: 19962,
    city: "Yenkis",
    city_code: "YEQ",
    airport_name: "Yenkis",
    country: "Papua New Guinea",
  },
  {
    sr: 19964,
    city: "Yeosu",
    city_code: "RSU",
    airport_name: "Yeosu",
    country: "South Korea",
  },
  {
    sr: 19966,
    city: "Yeovilton",
    city_code: "YEO",
    airport_name: "Yeovilton",
    country: "United Kingdom",
  },
  {
    sr: 19968,
    city: "Yerevan",
    city_code: "EVN",
    airport_name: "Yerevan",
    country: "Armenia",
  },
  {
    sr: 19970,
    city: "Yerington",
    city_code: "EYR",
    airport_name: "Yerington",
    country: "United States",
  },
  {
    sr: 19972,
    city: "Yes Bay",
    city_code: "WYB",
    airport_name: "SPB",
    country: "United States",
  },
  {
    sr: 19974,
    city: "Yeva",
    city_code: "YVD",
    airport_name: "Yeva",
    country: "Papua New Guinea",
  },
  {
    sr: 19976,
    city: "Yibin",
    city_code: "YBP",
    airport_name: "Yibin",
    country: "China",
  },
  {
    sr: 19978,
    city: "Yichang",
    city_code: "YIH",
    airport_name: "Yichang",
    country: "China",
  },
  {
    sr: 19980,
    city: "Yilan",
    city_code: "YLN",
    airport_name: "Yilan",
    country: "China",
  },
  {
    sr: 19982,
    city: "Yinchuan",
    city_code: "INC",
    airport_name: "Yinchuan",
    country: "China",
  },
  {
    sr: 19984,
    city: "Yining",
    city_code: "YIN",
    airport_name: "Yining",
    country: "China",
  },
  {
    sr: 19986,
    city: "Yiwu",
    city_code: "YIW",
    airport_name: "Yiwu",
    country: "China",
  },
  {
    sr: 19988,
    city: "Ylivieska",
    city_code: "YLI",
    airport_name: "Ylivieska",
    country: "Finland",
  },
  {
    sr: 19992,
    city: "Yogyakarta",
    city_code: "JOG",
    airport_name: "Adisutjipto",
    country: "Indonesia",
  },
  {
    sr: 19994,
    city: "Yokohama",
    city_code: "YOK",
    airport_name: "Yokohama",
    country: "Japan",
  },
  {
    sr: 19996,
    city: "Yola",
    city_code: "YOL",
    airport_name: "Yola",
    country: "Nigeria",
  },
  {
    sr: 19998,
    city: "Yonago",
    city_code: "YGJ",
    airport_name: "Miho",
    country: "Japan",
  },
  {
    sr: 20000,
    city: "Yonaguni Jima",
    city_code: "OGN",
    airport_name: "Yonaguni Jima",
    country: "Japan",
  },
  {
    sr: 20002,
    city: "Yongai",
    city_code: "KGH",
    airport_name: "Yongai",
    country: "Papua New Guinea",
  },
  {
    sr: 20004,
    city: "York",
    city_code: "THV",
    airport_name: "York",
    country: "United States",
  },
  {
    sr: 20006,
    city: "York Landing",
    city_code: "ZAC",
    airport_name: "York Landing",
    country: "Canada",
  },
  {
    sr: 20008,
    city: "Yorke Island",
    city_code: "OKR",
    airport_name: "Yorke Island",
    country: "Australia",
  },
  {
    sr: 20010,
    city: "Yorketown",
    city_code: "ORR",
    airport_name: "Yorketown",
    country: "Australia",
  },
  {
    sr: 20012,
    city: "Yorkton",
    city_code: "YQV",
    airport_name: "Yorkton",
    country: "Canada",
  },
  {
    sr: 20014,
    city: "Yoro",
    city_code: "ORO",
    airport_name: "Yoro",
    country: "Honduras",
  },
  {
    sr: 20016,
    city: "Yoronjima",
    city_code: "RNJ",
    airport_name: "Yoronjima",
    country: "Japan",
  },
  {
    sr: 20018,
    city: "Yosemite Ntl Park",
    city_code: "OYS",
    airport_name: "Yosemite Ntl Park",
    country: "United States",
  },
  {
    sr: 20020,
    city: "Yotvata",
    city_code: "YOT",
    airport_name: "Yotvata",
    country: "Israel",
  },
  {
    sr: 20022,
    city: "Young",
    city_code: "NGA",
    airport_name: "Young",
    country: "Australia",
  },
  {
    sr: 20026,
    city: "Youngstown",
    city_code: "YNG",
    airport_name: "Youngstown",
    country: "United States",
  },
  {
    sr: 20028,
    city: "Yreka",
    city_code: "RKC",
    airport_name: "Yreka",
    country: "United States",
  },
  {
    sr: 20030,
    city: "Yuanmou",
    city_code: "YUA",
    airport_name: "Yuanmou",
    country: "China",
  },
  {
    sr: 20032,
    city: "Yucca Flat",
    city_code: "UCC",
    airport_name: "Yucca Flat",
    country: "United States",
  },
  {
    sr: 20034,
    city: "Yuendumu",
    city_code: "YUE",
    airport_name: "Yuendumu",
    country: "Australia",
  },
  {
    sr: 20036,
    city: "Yule Island",
    city_code: "RKU",
    airport_name: "Kairuku",
    country: "Papua New Guinea",
  },
  {
    sr: 20038,
    city: "Yulin",
    city_code: "UYN",
    airport_name: "Yulin",
    country: "China",
  },
  {
    sr: 20040,
    city: "Yuma",
    city_code: "YUM",
    airport_name: "International",
    country: "United States",
  },
  {
    sr: 20042,
    city: "Yuma",
    city_code: "LGF",
    airport_name: "Laguna AAF",
    country: "United States",
  },
  {
    sr: 20044,
    city: "Yun Cheng",
    city_code: "YCU",
    airport_name: "Yun Cheng",
    country: "Canada",
  },
  {
    sr: 20046,
    city: "Yurimaguas",
    city_code: "YMS",
    airport_name: "Yurimaguas",
    country: "Peru",
  },
  {
    sr: 20048,
    city: "Yuruf",
    city_code: "RUF",
    airport_name: "Yuruf",
    country: "Indonesia",
  },
  {
    sr: 20050,
    city: "Yuzhno Sakhalinsk",
    city_code: "UUS",
    airport_name: "Yuzhno Sakhalinsk",
    country: "Russia",
  },
  {
    sr: 20052,
    city: "Zabljak",
    city_code: "ZBK",
    airport_name: "Zabljak",
    country: "Yugoslavia",
  },
  {
    sr: 20054,
    city: "Zabol",
    city_code: "ACZ",
    airport_name: "Zabol A P",
    country: "Iran",
  },
  {
    sr: 20056,
    city: "Zabre",
    city_code: "XZA",
    airport_name: "Zabre",
    country: "Burkina Faso",
  },
  {
    sr: 20060,
    city: "Zabreh",
    city_code: "ZBE",
    airport_name: "Dolni Benesov",
    country: "Czechoslovakia  former",
  },
  {
    sr: 20062,
    city: "Zacatecas",
    city_code: "ZCL",
    airport_name: "La Calera",
    country: "Mexico",
  },
  {
    sr: 20064,
    city: "Zachar Bay",
    city_code: "KZB",
    airport_name: "Zachar Bay SPB",
    country: "United States",
  },
  {
    sr: 20066,
    city: "Zadar",
    city_code: "ZAD",
    airport_name: "Zadar",
    country: "Croatia  hrvatska",
  },
  {
    sr: 20068,
    city: "Zagreb",
    city_code: "ZAG",
    airport_name: "Pleso",
    country: "Croatia  hrvatska",
  },
  {
    sr: 20070,
    city: "Zahedan",
    city_code: "ZAH",
    airport_name: "Zahedan",
    country: "Iran",
  },
  {
    sr: 20072,
    city: "Zaisan",
    city_code: "SZI",
    airport_name: "Zaisan",
    country: "Kazakhstan",
  },
  {
    sr: 20074,
    city: "Zakinthos Is",
    city_code: "ZTH",
    airport_name: "Zakinthos Is",
    country: "Greece",
  },
  {
    sr: 20076,
    city: "Zakouma",
    city_code: "AKM",
    airport_name: "Zakouma",
    country: "Chad",
  },
  {
    sr: 20078,
    city: "Zambezi",
    city_code: "BBZ",
    airport_name: "Zambezi",
    country: "Zambia",
  },
  {
    sr: 20080,
    city: "Zamboanga",
    city_code: "ZAM",
    airport_name: "Zamboanga",
    country: "Philippines",
  },
  {
    sr: 20082,
    city: "Zamora",
    city_code: "ZMM",
    airport_name: "Zamora",
    country: "Mexico",
  },
  {
    sr: 20084,
    city: "Zanaga",
    city_code: "ANJ",
    airport_name: "Zanaga",
    country: "Congo",
  },
  {
    sr: 20086,
    city: "Zanesville",
    city_code: "ZZV",
    airport_name: "Zanesville",
    country: "United States",
  },
  {
    sr: 20088,
    city: "Zanjan",
    city_code: "JWN",
    airport_name: "Zanjan",
    country: "Iran",
  },
  {
    sr: 20090,
    city: "Zanzibar",
    city_code: "ZNZ",
    airport_name: "Kisauni",
    country: "Tanzania",
  },
  {
    sr: 20094,
    city: "Zapala",
    city_code: "APZ",
    airport_name: "Zapala",
    country: "Argentina",
  },
  {
    sr: 20096,
    city: "Zapatoca",
    city_code: "AZT",
    airport_name: "Zapatoca",
    country: "Colombia",
  },
  {
    sr: 20098,
    city: "Zaporozhye",
    city_code: "OZH",
    airport_name: "Zaporozhye",
    country: "Ukraine",
  },
  {
    sr: 20100,
    city: "Zarafshan",
    city_code: "AFS",
    airport_name: "Zarafshan",
    country: "Uzbekistan",
  },
  {
    sr: 20102,
    city: "Zaragoza",
    city_code: "ZAZ",
    airport_name: "Zaragoza",
    country: "Spain",
  },
  {
    sr: 20104,
    city: "Zaranj",
    city_code: "ZAJ",
    airport_name: "Zaranj",
    country: "Afghanistan",
  },
  {
    sr: 20106,
    city: "Zaria",
    city_code: "ZAR",
    airport_name: "Zaria",
    country: "Nigeria",
  },
  {
    sr: 20108,
    city: "Zemio",
    city_code: "IMO",
    airport_name: "Zemio",
    country: "Central African Republic",
  },
  {
    sr: 20110,
    city: "Zenag",
    city_code: "ZEN",
    airport_name: "Zenag",
    country: "Papua New Guinea",
  },
  {
    sr: 20112,
    city: "Zephyrhills",
    city_code: "ZPH",
    airport_name: "Zephyrhills",
    country: "United States",
  },
  {
    sr: 20114,
    city: "Zero",
    city_code: "ZER",
    airport_name: "Zero",
    country: "India",
  },
  {
    sr: 20116,
    city: "Zhairem",
    city_code: "HRC",
    airport_name: "Zhairem",
    country: "Kazakhstan",
  },
  {
    sr: 20118,
    city: "Zhambyl",
    city_code: "DMB",
    airport_name: "Zhambyl",
    country: "Kazakhstan",
  },
  {
    sr: 20120,
    city: "Zhanjiang",
    city_code: "ZHA",
    airport_name: "Zhanjiang",
    country: "China",
  },
  {
    sr: 20122,
    city: "Zhaotong",
    city_code: "ZAT",
    airport_name: "Zhaotong",
    country: "China",
  },
  {
    sr: 20124,
    city: "Zhengzhou",
    city_code: "CGO",
    airport_name: "Zhengzhou",
    country: "China",
  },
  {
    sr: 20128,
    city: "Zhezkazgan",
    city_code: "DZN",
    airport_name: "Zhezhazgan",
    country: "Kazakhstan",
  },
  {
    sr: 20130,
    city: "Zhi Jiang",
    city_code: "HJJ",
    airport_name: "Zhi Jiang",
    country: "China",
  },
  {
    sr: 20132,
    city: "Zhitomir",
    city_code: "ZTR",
    airport_name: "Zhitomir",
    country: "Ukraine",
  },
  {
    sr: 20134,
    city: "Zhob",
    city_code: "PZH",
    airport_name: "Zhob",
    country: "Pakistan",
  },
  {
    sr: 20136,
    city: "Zhongshan",
    city_code: "ZGN",
    airport_name: "Zhongshan Ferry Port",
    country: "China",
  },
  {
    sr: 20138,
    city: "Zhoushan",
    city_code: "HSN",
    airport_name: "Zhoushan",
    country: "China",
  },
  {
    sr: 20140,
    city: "Zhuhai",
    city_code: "ZUH",
    airport_name: "Zhuhai Airport",
    country: "China",
  },
  {
    sr: 20142,
    city: "Zielona Gora",
    city_code: "IEG",
    airport_name: "Babimost",
    country: "Poland",
  },
  {
    sr: 20144,
    city: "Ziguinchor",
    city_code: "ZIG",
    airport_name: "Ziguinchor",
    country: "Senegal",
  },
  {
    sr: 20146,
    city: "Zilfi",
    city_code: "ZUL",
    airport_name: "Zilfi",
    country: "Saudi Arabia",
  },
  {
    sr: 20148,
    city: "Zilina",
    city_code: "ILZ",
    airport_name: "Zilina",
    country: "Slovak Republic",
  },
  {
    sr: 20150,
    city: "Zinder",
    city_code: "ZND",
    airport_name: "Zinder",
    country: "Niger",
  },
  {
    sr: 20152,
    city: "Zlin",
    city_code: "GTW",
    airport_name: "Holesov",
    country: "Czechoslovakia  former",
  },
  {
    sr: 20154,
    city: "Zoersel",
    city_code: "OBL",
    airport_name: "Zoersel",
    country: "Belgium",
  },
  {
    sr: 20156,
    city: "Zonguldak",
    city_code: "ONQ",
    airport_name: "Zonguldak",
    country: "Turkey",
  },
  {
    sr: 20158,
    city: "Zouerate",
    city_code: "OUZ",
    airport_name: "Zouerate",
    country: "Mauritania",
  },
  {
    sr: 20162,
    city: "Zuenoula",
    city_code: "ZUE",
    airport_name: "Zuenoula",
    country: "Cote D Ivoire  Ivory Coast",
  },
  {
    sr: 20164,
    city: "Zugapa",
    city_code: "UGU",
    airport_name: "Zugapa",
    country: "Indonesia",
  },
  {
    sr: 20166,
    city: "Zunyi",
    city_code: "ZYI",
    airport_name: "Zunyi",
    country: "China",
  },
  {
    sr: 20168,
    city: "Zurich",
    city_code: "ZRH",
    airport_name: "Zurich",
    country: "Switzerland",
  },
  {
    sr: 20170,
    city: "Zurs Lech",
    city_code: "ZRS",
    airport_name: "Flexenpass Heliport",
    country: "Austria",
  },
  {
    sr: 20172,
    city: "Zwara",
    city_code: "WAX",
    airport_name: "Zwara",
    country: "United States",
  },
  {
    sr: 20174,
    city: "Chicago",
    city_code: "ORD",
    airport_name: "Ohare International",
    country: "United States",
  },
  {
    sr: 20176,
    city: "Hong Kong",
    city_code: "HKG",
    airport_name: "Hong Kong International",
    country: "China",
  },
  {
    sr: 20178,
    city: "Newark",
    city_code: "EWR",
    airport_name: "Newark International Airport",
    country: "United States",
  },
  {
    sr: 20180,
    city: "Johannesburg",
    city_code: "JNB",
    airport_name: "Johannesburg International",
    country: "South Africa",
  },
  {
    sr: 20182,
    city: "Allentown",
    city_code: "ABE",
    airport_name: "Lehigh Valley International",
    country: "United States",
  },
  {
    sr: 20184,
    city: "Acapulco",
    city_code: "ACA",
    airport_name: "General Juan N Alvarez International",
    country: "Mexico",
  },
  {
    sr: 20186,
    city: "Baku",
    city_code: "BAK",
    airport_name: "Heydar Aliyev International",
    country: "Azerbaijan",
  },
  {
    sr: 20188,
    city: "Buffalo",
    city_code: "BUF",
    airport_name: "Buffalo Niagara International",
    country: "United States",
  },
  {
    sr: 20190,
    city: "Nuremberg",
    city_code: "NUE",
    airport_name: "Nuremberg",
    country: "Germany",
  },
  {
    sr: 20192,
    city: "Nagoya",
    city_code: "NGO",
    airport_name: "Central Japan International",
    country: "Japan",
  },
  {
    sr: 20196,
    city: "Ramallah",
    city_code: "ZDM",
    airport_name: "Ramallah Heliport",
    country: "Palestine",
  },
  {
    sr: 20198,
    city: "London",
    city_code: "YXU",
    airport_name: "London International Airport",
    country: "Canada",
  },
  {
    sr: 20200,
    city: "Toronto",
    city_code: "YZD",
    airport_name: "Downsview Airport",
    country: "Canada",
  },
  {
    sr: 20202,
    city: "Hendersonville",
    city_code: "0A7",
    airport_name: "Hendersonville Airport",
    country: "United States",
  },
  {
    sr: 20204,
    city: "Nice",
    city_code: "NCE",
    airport_name: "Cote d Azur International Airport",
    country: "France",
  },
  {
    sr: 20206,
    city: "Sanaa",
    city_code: "SAH",
    airport_name: "El Rahaba Airport  Sanaa Intenational",
    country: "Yemen",
  },
  {
    sr: 20208,
    city: "Monastir",
    city_code: "MIR",
    airport_name: "Habib Bourguiba",
    country: "Tunisia",
  },
  {
    sr: 20210,
    city: "Windhoek",
    city_code: "WDH",
    airport_name: "Hosea Kutako International Airport",
    country: "Namibia",
  },
  {
    sr: 20212,
    city: "St Johns",
    city_code: "YYT",
    airport_name: "St Johns International Airport",
    country: "Canada",
  },
  {
    sr: 20214,
    city: "New Orleans",
    city_code: "MSY",
    airport_name: "Louis Armstrong New Orleans International Airport",
    country: "United States",
  },
  {
    sr: 20216,
    city: "Dammam",
    city_code: "DMM",
    airport_name: "King Fahd International Airport",
    country: "Saudi Arabia",
  },
  {
    sr: 20218,
    city: "Pittsburgh",
    city_code: "PIT",
    airport_name: "Pittsburgh International Airport",
    country: "United States",
  },
  {
    sr: 20220,
    city: "Kinshasa",
    city_code: "FIH",
    airport_name: "NDjili Airport",
    country: "Congo",
  },
  {
    sr: 20222,
    city: "Rafah",
    city_code: "GZA",
    airport_name: "Gaza   Yaser Arafat International Airport",
    country: "Palestine",
  },
  {
    sr: 20223,
    city: "Washington DC All airports",
    city_code: "WAS",
    airport_name: "Washington DC",
    country: "United States",
  },
  {
    sr: 20224,
    city: "New York  NY All airports",
    city_code: "NYC",
    airport_name: "New York",
    country: "United States",
  },
  {
    sr: 20225,
    city: "Chicago  IL All airports",
    city_code: "CHI",
    airport_name: "Chicago",
    country: "United States",
  },
  {
    sr: 20226,
    city: "Detroit  MI All airports",
    city_code: "DTT",
    airport_name: "Detroit",
    country: "United States",
  },
  {
    sr: 20228,
    city: "Montreal  QC all airports",
    city_code: "YMQ",
    airport_name: "Montreal",
    country: "Canada",
  },
  {
    sr: 20229,
    city: "London UK All Airports",
    city_code: "LON",
    airport_name: "London",
    country: "United Kingdom",
  },
  {
    sr: 20231,
    city: "Paris",
    city_code: "PAR",
    airport_name: "Paris",
    country: "France",
  },
  {
    sr: 20232,
    city: "Aarhus",
    city_code: "ZID",
    airport_name: "Aarhus",
    country: "Denmark",
  },
  {
    sr: 20233,
    city: "Al Ain",
    city_code: "ZVH",
    airport_name: "Al Ain",
    country: "United Arab Emirates",
  },
  {
    sr: 20234,
    city: "Al Maktoum",
    city_code: "DWC",
    airport_name: "Al Maktoum",
    country: "United Arab Emirates",
  },
  {
    sr: 20235,
    city: "Albany  OR",
    city_code: "QWY",
    airport_name: "Albany  OR",
    country: "United States",
  },
  {
    sr: 20236,
    city: "Aldershot  ON",
    city_code: "XLY",
    airport_name: "Aldershot  ON",
    country: "Canada",
  },
  {
    sr: 20237,
    city: "Alexandria ON",
    city_code: "XFS",
    airport_name: "Alexandria ON",
    country: "Canada",
  },
  {
    sr: 20238,
    city: "Angers",
    city_code: "QXG",
    airport_name: "Angers",
    country: "France",
  },
  {
    sr: 20239,
    city: "Arthurs Town",
    city_code: "ATC",
    airport_name: "Arthurs Town",
    country: "Bahamas",
  },
  {
    sr: 20240,
    city: "Auki",
    city_code: "AKS",
    airport_name: "Auki",
    country: "Solomon Islands",
  },
  {
    sr: 20241,
    city: "Austin  TX",
    city_code: "AUS",
    airport_name: "Austin  TX",
    country: "United States",
  },
  {
    sr: 20242,
    city: "Baku",
    city_code: "GYD",
    airport_name: "Baku",
    country: "Azerbaijan",
  },
  {
    sr: 20243,
    city: "Bangkok",
    city_code: "DMK",
    airport_name: "Don Muang Airport",
    country: "Thailand",
  },
  {
    sr: 20244,
    city: "Basel",
    city_code: "ZDH",
    airport_name: "Mulhouse Railway Station",
    country: "Switzerland",
  },
  {
    sr: 20245,
    city: "Belleville  ON",
    city_code: "XVV",
    airport_name: "Belleville  ON",
    country: "Canada",
  },
  {
    sr: 20246,
    city: "Belo Horizonte",
    city_code: "CNF",
    airport_name: "Belo Horizonte",
    country: "Brazil",
  },
  {
    sr: 20247,
    city: "Berlin",
    city_code: "BER",
    airport_name: "Berlin",
    country: "Germany",
  },
  {
    sr: 20248,
    city: "Berne",
    city_code: "ZDJ",
    airport_name: "Berne",
    country: "Switzerland",
  },
  {
    sr: 20249,
    city: "Blo Horizonte",
    city_code: "CNF",
    airport_name: "Blo Horizonte",
    country: "Brazil",
  },
  {
    sr: 20250,
    city: "Bloemfontein",
    city_code: "BFN",
    airport_name: "Bloemfontein",
    country: "South Africa",
  },
  {
    sr: 20251,
    city: "Bonn",
    city_code: "BNJ",
    airport_name: "Bonn",
    country: "Germany",
  },
  {
    sr: 20252,
    city: "Boulder  CO",
    city_code: "XHH",
    airport_name: "Boulder  CO",
    country: "United States",
  },
  {
    sr: 20253,
    city: "Brampton  ON",
    city_code: "XPN",
    airport_name: "Brampton  ON",
    country: "Canada",
  },
  {
    sr: 20254,
    city: "Brantford  ON",
    city_code: "XFV",
    airport_name: "Brantford  ON",
    country: "Canada",
  },
  {
    sr: 20255,
    city: "Brno",
    city_code: "ZDN",
    airport_name: "Brno",
    country: "Czech Republic",
  },
  {
    sr: 20256,
    city: "Campbellton  NB",
    city_code: "XAZ",
    airport_name: "Campbellton  NB",
    country: "Canada",
  },
  {
    sr: 20257,
    city: "Capreol  ON",
    city_code: "XAW",
    airport_name: "Capreol  ON",
    country: "Canada",
  },
  {
    sr: 20258,
    city: "Casselman  ON",
    city_code: "XZB",
    airport_name: "Casselman  ON",
    country: "Canada",
  },
  {
    sr: 20259,
    city: "Chambord  QC",
    city_code: "XCI",
    airport_name: "Chambord  QC",
    country: "Canada",
  },
  {
    sr: 20260,
    city: "Chandler  QC",
    city_code: "XDL",
    airport_name: "Chandler  QC",
    country: "Canada",
  },
  {
    sr: 20261,
    city: "Chemainus  BC",
    city_code: "XHS",
    airport_name: "Chemainus  BC",
    country: "Canada",
  },
  {
    sr: 20262,
    city: "Churchill  MB",
    city_code: "XAD",
    airport_name: "Churchill  MB",
    country: "Canada",
  },
  {
    sr: 20263,
    city: "Churchill  MB",
    city_code: "YYQ",
    airport_name: "Churchill  MB",
    country: "Canada",
  },
  {
    sr: 20264,
    city: "Cobourg  ON",
    city_code: "XGJ",
    airport_name: "Cobourg  ON",
    country: "Canada",
  },
  {
    sr: 20265,
    city: "Cologne",
    city_code: "QKL",
    airport_name: "Cologne",
    country: "Germany",
  },
  {
    sr: 20266,
    city: "Columbus  GA",
    city_code: "CSG",
    airport_name: "Columbus  GA",
    country: "United States",
  },
  {
    sr: 20267,
    city: "Coteau  QC",
    city_code: "XGK",
    airport_name: "Coteau  QC",
    country: "Canada",
  },
  {
    sr: 20268,
    city: "Coxs Bazar",
    city_code: "CXB",
    airport_name: "Coxs Bazar",
    country: "Bangladesh",
  },
  {
    sr: 20269,
    city: "Dammam",
    city_code: "DMS",
    airport_name: "Sabtco Bus Station",
    country: "Saudi Arabia",
  },
  {
    sr: 20270,
    city: "Denver  CO Longmont",
    city_code: "QWM",
    airport_name: "Denver  CO Longmont",
    country: "United States",
  },
  {
    sr: 20271,
    city: "Drummondville  QC",
    city_code: "XDM",
    airport_name: "Drummondville  QC",
    country: "Canada",
  },
  {
    sr: 20272,
    city: "Dubai Bus Station",
    city_code: "XNB",
    airport_name: "Dubai Bus Station",
    country: "United Arab Emirates",
  },
  {
    sr: 20273,
    city: "Edmonton  AB",
    city_code: "XZL",
    airport_name: "Edmonton  AB",
    country: "Canada",
  },
  {
    sr: 20274,
    city: "Esbjerg",
    city_code: "ZBB",
    airport_name: "Esbjerg",
    country: "Denmark",
  },
  {
    sr: 20275,
    city: "Fredericia",
    city_code: "ZBJ",
    airport_name: "Fredericia",
    country: "Denmark",
  },
  {
    sr: 20276,
    city: "Fredericton Junction  NB",
    city_code: "XFC",
    airport_name: "Fredericton Junction  NB",
    country: "Canada",
  },
  {
    sr: 20277,
    city: "Freeport",
    city_code: "FPO",
    airport_name: "Freeport",
    country: "Bahamas",
  },
  {
    sr: 20278,
    city: "Gaspe  QC",
    city_code: "XDD",
    airport_name: "Gaspe  QC",
    country: "Canada",
  },
  {
    sr: 20279,
    city: "Gaspe  QC",
    city_code: "YGP",
    airport_name: "Gaspe  QC",
    country: "Canada",
  },
  {
    sr: 20280,
    city: "Georgetown",
    city_code: "GEO",
    airport_name: "Georgetown",
    country: "Guyana",
  },
  {
    sr: 20281,
    city: "Georgetown  ON",
    city_code: "XHM",
    airport_name: "Georgetown  ON",
    country: "Canada",
  },
  {
    sr: 20282,
    city: "Glencoe  ON",
    city_code: "XZC",
    airport_name: "Glencoe  ON",
    country: "Canada",
  },
  {
    sr: 20283,
    city: "Grimsby  ON",
    city_code: "XGY",
    airport_name: "Grimsby  ON",
    country: "Canada",
  },
  {
    sr: 20284,
    city: "Guam",
    city_code: "GUM",
    airport_name: "Guam",
    country: "Guam",
  },
  {
    sr: 20285,
    city: "Guelph  ON",
    city_code: "XIA",
    airport_name: "Guelph  ON",
    country: "Canada",
  },
  {
    sr: 20286,
    city: "HaApa",
    city_code: "HPA",
    airport_name: "HaApa",
    country: "Tonga",
  },
  {
    sr: 20287,
    city: "Halberstadt",
    city_code: "ZHQ",
    airport_name: "Halberstadt",
    country: "Germany",
  },
  {
    sr: 20288,
    city: "Halifax  NS",
    city_code: "XDG",
    airport_name: "Halifax  NS",
    country: "Canada",
  },
  {
    sr: 20289,
    city: "Herning",
    city_code: "XAK",
    airport_name: "Herning",
    country: "Denmark",
  },
  {
    sr: 20290,
    city: "Hervey  QC",
    city_code: "XDU",
    airport_name: "Hervey  QC",
    country: "Canada",
  },
  {
    sr: 20291,
    city: "Ingersoll  ON",
    city_code: "XIB",
    airport_name: "Ingersoll  ON",
    country: "Canada",
  },
  {
    sr: 20292,
    city: "Jakarta",
    city_code: "CGK",
    airport_name: "Jakarta",
    country: "Indonesia",
  },
  {
    sr: 20293,
    city: "Jasper  AB",
    city_code: "XDH",
    airport_name: "Jasper  AB",
    country: "Canada",
  },
  {
    sr: 20294,
    city: "Joliette  QC",
    city_code: "XJL",
    airport_name: "Joliette  QC",
    country: "Canada",
  },
  {
    sr: 20295,
    city: "Jonquiere  QC",
    city_code: "XJQ",
    airport_name: "Jonquiere  QC",
    country: "Canada",
  },
  {
    sr: 20296,
    city: "Kingston  ON",
    city_code: "XEG",
    airport_name: "Kingston  ON",
    country: "Canada",
  },
  {
    sr: 20297,
    city: "Kolobrzeg",
    city_code: "QJY",
    airport_name: "Kolobrzeg",
    country: "Poland",
  },
  {
    sr: 20298,
    city: "Lac Edouard  QC",
    city_code: "XEE",
    airport_name: "Lac Edouard  QC",
    country: "Canada",
  },
  {
    sr: 20299,
    city: "Ladysmith  BC",
    city_code: "XEH",
    airport_name: "Ladysmith  BC",
    country: "Canada",
  },
  {
    sr: 20300,
    city: "Langford  BC",
    city_code: "XEJ",
    airport_name: "Langford  BC",
    country: "Canada",
  },
  {
    sr: 20301,
    city: "Le Mans",
    city_code: "ZLN",
    airport_name: "Le Mans",
    country: "France",
  },
  {
    sr: 20302,
    city: "London Paddington",
    city_code: "QQP",
    airport_name: "London Paddington",
    country: "United Kingdom",
  },
  {
    sr: 20303,
    city: "London  ON",
    city_code: "XDQ",
    airport_name: "London  ON",
    country: "Canada",
  },
  {
    sr: 20304,
    city: "Marthas Vineyard  MA",
    city_code: "MVY",
    airport_name: "Marthas Vineyard MA",
    country: "United States",
  },
  {
    sr: 20305,
    city: "Marys Harbour  NL",
    city_code: "YMH",
    airport_name: "Marys Harbour  NL",
    country: "Canada",
  },
  {
    sr: 20306,
    city: "Maxville  ON",
    city_code: "XID",
    airport_name: "Maxville  ON",
    country: "Canada",
  },
  {
    sr: 20307,
    city: "Melville  SK",
    city_code: "XEK",
    airport_name: "Melville  SK",
    country: "Canada",
  },
  {
    sr: 20308,
    city: "Miramichi  NB",
    city_code: "XEY",
    airport_name: "Miramichi  NB",
    country: "Canada",
  },
  {
    sr: 20309,
    city: "Moncton  NB",
    city_code: "XDP",
    airport_name: "Moncton  NB",
    country: "Canada",
  },
  {
    sr: 20310,
    city: "Moncton  NB Airport",
    city_code: "YQM",
    airport_name: "Moncton  NB Airport",
    country: "Canada",
  },
  {
    sr: 20311,
    city: "Montreal  QC Dorval",
    city_code: "XAX",
    airport_name: "Montreal  QC Dorval",
    country: "Canada",
  },
  {
    sr: 20312,
    city: "Montreal  QC Downtown",
    city_code: "YMY",
    airport_name: "Montreal  QC Downtown",
    country: "Canada",
  },
  {
    sr: 20313,
    city: "Moscow",
    city_code: "MOW",
    airport_name: "Moscow",
    country: "Russia",
  },
  {
    sr: 20314,
    city: "Nantes",
    city_code: "QJZ",
    airport_name: "Nantes",
    country: "France",
  },
  {
    sr: 20315,
    city: "Napanee  ON",
    city_code: "XIF",
    airport_name: "Napanee  ON",
    country: "Canada",
  },
  {
    sr: 20316,
    city: "New Carlisle  QC",
    city_code: "XEL",
    airport_name: "New Carlisle  QC",
    country: "Canada",
  },
  {
    sr: 20317,
    city: "New Richmond  QC",
    city_code: "XEM",
    airport_name: "New Richmond  QC",
    country: "Canada",
  },
  {
    sr: 20318,
    city: "Niagara Falls  ON",
    city_code: "XLV",
    airport_name: "Niagara Falls  ON",
    country: "Canada",
  },
  {
    sr: 20319,
    city: "Niuafoou",
    city_code: "NFO",
    airport_name: "Niuafoou",
    country: "Tonga",
  },
  {
    sr: 20320,
    city: "Norilsk",
    city_code: "NSK",
    airport_name: "Norilsk",
    country: "Russia",
  },
  {
    sr: 20321,
    city: "Nuku Alofa",
    city_code: "TBU",
    airport_name: "Nuku Alofa",
    country: "Tonga",
  },
  {
    sr: 20322,
    city: "Nuremberg",
    city_code: "ZAQ",
    airport_name: "Nuremberg",
    country: "Germany",
  },
  {
    sr: 20323,
    city: "Odense",
    city_code: "ZBQ",
    airport_name: "Odense",
    country: "Denmark",
  },
  {
    sr: 20324,
    city: "Osaka",
    city_code: "OSA",
    airport_name: "Osaka",
    country: "Japan",
  },
  {
    sr: 20325,
    city: "Ottawa  ON",
    city_code: "XDS",
    airport_name: "Ottawa  ON",
    country: "Canada",
  },
  {
    sr: 20326,
    city: "Parent  QC",
    city_code: "XFE",
    airport_name: "Parent  QC",
    country: "Canada",
  },
  {
    sr: 20327,
    city: "Parksville  BC",
    city_code: "XPB",
    airport_name: "Parksville  BC",
    country: "Canada",
  },
  {
    sr: 20328,
    city: "Perce  QC",
    city_code: "XFG",
    airport_name: "Perce  QC",
    country: "Canada",
  },
  {
    sr: 20329,
    city: "Pointe aux Trembles  QC",
    city_code: "XPX",
    airport_name: "Pointe aux Trembles  QC",
    country: "Canada",
  },
  {
    sr: 20330,
    city: "Poitiers",
    city_code: "XOP",
    airport_name: "Poitiers",
    country: "France",
  },
  {
    sr: 20331,
    city: "Portsmouth  NH",
    city_code: "PSM",
    airport_name: "Portsmouth  NH",
    country: "United States",
  },
  {
    sr: 20332,
    city: "Prescott  ON",
    city_code: "XII",
    airport_name: "Prescott  ON",
    country: "Canada",
  },
  {
    sr: 20333,
    city: "Quebec  QC Charny",
    city_code: "YFZ",
    airport_name: "Quebec  QC Charny",
    country: "Canada",
  },
  {
    sr: 20334,
    city: "Quebec  QC Levis",
    city_code: "XLK",
    airport_name: "Quebec  QC Levis",
    country: "Canada",
  },
  {
    sr: 20335,
    city: "Reno  NV",
    city_code: "RNO",
    airport_name: "Reno  NV",
    country: "United States",
  },
  {
    sr: 20336,
    city: "Riviere a Pierre  QC",
    city_code: "XRP",
    airport_name: "Riviere a Pierre  QC",
    country: "Canada",
  },
  {
    sr: 20337,
    city: "Rognan",
    city_code: "ZXM",
    airport_name: "Rognan",
    country: "Norway",
  },
  {
    sr: 20338,
    city: "Rome",
    city_code: "ROM",
    airport_name: "Rome",
    country: "Italy",
  },
  {
    sr: 20339,
    city: "Saarbruecken",
    city_code: "QFZ",
    airport_name: "Saarbruecken",
    country: "Germany",
  },
  {
    sr: 20340,
    city: "Sackville  NB",
    city_code: "XKV",
    airport_name: "Sackville  NB",
    country: "Canada",
  },
  {
    sr: 20341,
    city: "Saint Hyacinthe  QC",
    city_code: "XIM",
    airport_name: "Saint Hyacinthe  QC",
    country: "Canada",
  },
  {
    sr: 20342,
    city: "San Jose",
    city_code: "SYQ",
    airport_name: "San Jose",
    country: "Costa Rica",
  },
  {
    sr: 20343,
    city: "Sarnia  ON",
    city_code: "XDX",
    airport_name: "Sarnia  ON",
    country: "Canada",
  },
  {
    sr: 20344,
    city: "Senneterre  QC",
    city_code: "XFK",
    airport_name: "Senneterre  QC",
    country: "Canada",
  },
  {
    sr: 20345,
    city: "Seoul",
    city_code: "SEL",
    airport_name: "Seoul",
    country: "South Korea",
  },
  {
    sr: 20346,
    city: "Seoul",
    city_code: "GMP",
    airport_name: "Seoul",
    country: "South Korea",
  },
  {
    sr: 20347,
    city: "Shawinigan  QC",
    city_code: "XFL",
    airport_name: "Shawinigan  QC",
    country: "Canada",
  },
  {
    sr: 20348,
    city: "Shawnigan  BC",
    city_code: "XFM",
    airport_name: "Shawnigan  BC",
    country: "Canada",
  },
  {
    sr: 20349,
    city: "St Marys  ON",
    city_code: "XIO",
    airport_name: "St Marys  ON",
    country: "Canada",
  },
  {
    sr: 20350,
    city: "Stockholm",
    city_code: "STO",
    airport_name: "Stockholm",
    country: "Sweden",
  },
  {
    sr: 20351,
    city: "Strasbourg",
    city_code: "XER",
    airport_name: "Strasbourg",
    country: "France",
  },
  {
    sr: 20352,
    city: "Strathroy  ON",
    city_code: "XTY",
    airport_name: "Strathroy  ON",
    country: "Canada",
  },
  {
    sr: 20353,
    city: "Stuttgart",
    city_code: "ZWS",
    airport_name: "Stuttgart",
    country: "Germany",
  },
  {
    sr: 20354,
    city: "The Pas  MB",
    city_code: "XDZ",
    airport_name: "The Pas  MB",
    country: "Canada",
  },
  {
    sr: 20355,
    city: "Tokyo",
    city_code: "TYO",
    airport_name: "Tokyo",
    country: "Japan",
  },
  {
    sr: 20356,
    city: "Toronto  ON Downtown",
    city_code: "YBZ",
    airport_name: "Toronto  ON Downtown",
    country: "Canada",
  },
  {
    sr: 20357,
    city: "Toronto  ON Guildwood",
    city_code: "XLQ",
    airport_name: "Toronto  ON Guildwood",
    country: "Canada",
  },
  {
    sr: 20358,
    city: "Tours",
    city_code: "XSH",
    airport_name: "Tours",
    country: "France",
  },
  {
    sr: 20359,
    city: "Truro  NS",
    city_code: "XLZ",
    airport_name: "Truro  NS",
    country: "Canada",
  },
  {
    sr: 20360,
    city: "Val dOr  QC",
    city_code: "YVO",
    airport_name: "Val dOr  QC",
    country: "Canada",
  },
  {
    sr: 20361,
    city: "Valenciennes",
    city_code: "XVS",
    airport_name: "Valenciennes",
    country: "France",
  },
  {
    sr: 20362,
    city: "Vavau",
    city_code: "VAV",
    airport_name: "Vavau",
    country: "Tonga",
  },
  {
    sr: 20363,
    city: "Warsaw",
    city_code: "WMI",
    airport_name: "Warsaw",
    country: "Poland",
  },
  {
    sr: 20364,
    city: "Watford  ON",
    city_code: "XWA",
    airport_name: "Watford  ON",
    country: "Canada",
  },
  {
    sr: 20365,
    city: "Weymont  QC",
    city_code: "XFQ",
    airport_name: "Weymont  QC",
    country: "Canada",
  },
  {
    sr: 20366,
    city: "Windsor  ON",
    city_code: "XEC",
    airport_name: "Windsor  ON",
    country: "Canada",
  },
  {
    sr: 20367,
    city: "Winnipeg  MB",
    city_code: "XEF",
    airport_name: "Winnipeg  MB",
    country: "Canada",
  },
  {
    sr: 20368,
    city: "Woodstock  ON",
    city_code: "XIP",
    airport_name: "Woodstock  ON",
    country: "Canada",
  },
  {
    sr: 20369,
    city: "Wyoming  ON",
    city_code: "XWY",
    airport_name: "Wyoming  ON",
    country: "Canada",
  },
  {
    sr: 20370,
    city: "Yanan",
    city_code: "ENY",
    airport_name: "Yanan",
    country: "China",
  },
  {
    sr: 20371,
    city: "Ebbsfleet Valley  Kent",
    city_code: "XQE",
    airport_name: "Ebbsfleet International Railway Station",
    country: "United Kingdom",
  },
  {
    sr: 20372,
    city: "Abu Dhabi",
    city_code: "ZVJ",
    airport_name: "Abu Dhabi Bus Station",
    country: "United Arab Emirates",
  },
  {
    sr: 20373,
    city: "Bayannur",
    city_code: "RLK",
    airport_name: "Bayannur Tianjitai Airport",
    country: "China",
  },
  {
    sr: 20374,
    city: "Tehran",
    city_code: "IKA",
    airport_name: "Tehran",
    country: "Iran",
  },
  {
    sr: 20375,
    city: "Oxford Railway Station",
    city_code: "OXQ",
    airport_name: "Oxford Railway Station",
    country: "United Kingdom",
  },
  {
    sr: 20378,
    city: "Juba",
    city_code: "JUB",
    airport_name: "Juba International Airport",
    country: "South Sudan",
  },
  {
    sr: 20379,
    city: "Sulaimaniyah",
    city_code: "ISU",
    airport_name: "Sulaimaniyah International Airport",
    country: "Iraq",
  },
  {
    sr: 20380,
    city: "Erbil",
    city_code: "EBL",
    airport_name: "Erbil International Airport",
    country: "Iraq",
  },
  {
    sr: 20381,
    city: "Diass Senegal",
    city_code: "DSS",
    airport_name: "Blaise Diagne International Airport",
    country: "Senegal",
  },
  {
    sr: 20382,
    city: "Muan",
    city_code: "MWX",
    airport_name: "Muan International Airport",
    country: "South Korea",
  },
  {
    sr: 20383,
    city: "Sohar",
    city_code: "OHS",
    airport_name: "Sohar Airport",
    country: "Oman",
  },
  {
    sr: 20384,
    city: "Beijing",
    city_code: "BJS",
    airport_name: "All Airports",
    country: "China",
  },
  {
    sr: 20385,
    city: "Kannur",
    city_code: "CNN",
    airport_name: "Kannur International Airport",
    country: "India",
  },
  {
    sr: 20386,
    city: "Duqm",
    city_code: "DQM",
    airport_name: "Duqm International Airport",
    country: "Oman",
  },
  {
    sr: 20387,
    city: "Saint Barthelemy",
    city_code: "SBH",
    airport_name: "Gustaf III Airport",
    country: "Saint Barthelemy",
  },
  {
    sr: 20388,
    city: "Willemstad",
    city_code: "CUR",
    airport_name: "Curacao International Airport",
    country: "Curacao",
  },
  {
    sr: 20389,
    city: "Buenos Aires",
    city_code: "BUE",
    airport_name: "Buenos Aires",
    country: "Argentina",
  },
  {
    sr: 20390,
    city: "Beijing Daxing",
    city_code: "PKX",
    airport_name: "Beijing Daxing International Airport",
    country: "China",
  },
  {
    sr: 20391,
    city: "Milan",
    city_code: "MIL",
    airport_name: "Milan",
    country: "Italy",
  },
  {
    sr: 20392,
    city: "Jalgaon",
    city_code: "JLG",
    airport_name: "Jalgaon Airport",
    country: "India",
  },
  {
    sr: 20393,
    city: "Santo Domingo",
    city_code: "JBQ",
    airport_name: "La Isabela",
    country: "Dominican Republic",
  },
  {
    sr: 20394,
    city: "Anglesey Airport",
    city_code: "VLY",
    airport_name: "Anglesey Airport",
    country: "United Kingdom",
  },
  {
    sr: 20395,
    city: "Hatay",
    city_code: "HTY",
    airport_name: "Hatay Havalimanı",
    country: "Turkey",
  },
  {
    sr: 20396,
    city: "Jharsuguda",
    city_code: "JRG",
    airport_name: "Jharsuguda",
    country: "India",
  },
  {
    sr: 20397,
    city: "Humberto Ghizzo",
    city_code: "JJG",
    airport_name: "Humberto Ghizzo Bortoluzzi Regional Airport",
    country: "Brazil",
  },
  {
    sr: 20398,
    city: "Darbhanga Airport",
    city_code: "DBR",
    airport_name: "Darbhanga Airport",
    country: "India",
  },
  {
    sr: 20399,
    city: "Yogyakarta",
    city_code: "YIA",
    airport_name: "Yogyakarta",
    country: "Indonesia",
  },
  {
    sr: 20400,
    city: "Moscow",
    city_code: "ZIA",
    airport_name: "Zhukovsky International Airport",
    country: "Russia",
  },
  {
    sr: 20401,
    city: "Gazipasa",
    city_code: "GZP",
    airport_name: "Gazipasa Alanya Airport",
    country: "Turkey",
  },
  {
    sr: 20402,
    city: "Berlin",
    city_code: "QPP",
    airport_name: "Berlin Hauptbahnhof",
    country: "Germany",
  },
  {
    sr: 20403,
    city: "Lombok",
    city_code: "LOP",
    airport_name: "Lombok International Airport",
    country: "Indonesia",
  },
  {
    sr: 20404,
    city: "Chengdu",
    city_code: "TFU",
    airport_name: "Tianfu International Airport",
    country: "China",
  },
  {
    sr: 20405,
    city: "Goa",
    city_code: "GOX",
    airport_name: "North Goa Airport",
    country: "India",
  },
  {
    sr: 20406,
    city: "GIZA",
    city_code: "SPX",
    airport_name: "SPHINX INTERNATIONAL",
    country: "Egypt",
  },
  {
    sr: 20407,
    city: "Astana",
    city_code: "NQZ",
    airport_name: "Nursultan Nazarbayev International Airport",
    country: "Kazakhstan",
  },
  {
    sr: 20408,
    city: "Al-Ula",
    city_code: "ULH",
    airport_name: "AlUla International Airport",
    country: "Saudi Arabia",
  },
  {
    sr: 20409,
    city: "Banyuwangi",
    city_code: "BWX",
    airport_name: "Banyuwangi International Airport",
    country: "Indonesia",
  },
  {
    sr: 20410,
    city: "Rio de Janerio",
    city_code: "RRJ",
    airport_name: "Roberto Marinho Airport",
    country: "Brazil",
  },
  {
    sr: 20411,
    city: "Kolaka",
    city_code: "KXB",
    airport_name: "Sangia Nibandera Airport",
    country: "Indonesia",
  },
  {
    sr: 20412,
    city: "Patna",
    city_code: "AYJ",
    airport_name: "Maharishi Valmiki International Airport",
    country: "India",
  },
  {
    sr: 20413,
    city: "Uyuni",
    city_code: "UYU",
    airport_name: "Joya Andina Airport",
    country: "Bolivia",
  },
  {
    sr: 20414,
    city: "Neom Bay",
    city_code: "NUM",
    airport_name: "Neom Bay Airport",
    country: "Saudi Arabia",
  },
  {
    sr: 20415,
    city: "Sohag",
    city_code: "HMB",
    airport_name: "Sohag International Airport",
    country: "Egypt",
  },
  {
    sr: 20416,
    city: "Riyadh",
    city_code: "RUJ",
    airport_name: "Saptco Bus Station Airport",
    country: "Saudi Arabia",
  },
  {
    sr: 20417,
    city: "Doha",
    city_code: "DIA",
    airport_name: "Doha International Airport",
    country: "Qatar",
  },
  {
    sr: 20418,
    city: "Del Carmen",
    city_code: "IAO",
    airport_name: "Sayak Airport",
    country: "Philippines",
  },
  {
    sr: 20419,
    city: "Najaf",
    city_code: "NJF",
    airport_name: "Al Najaf International Airport",
    country: "Iraq",
  },
  {
    sr: 20420,
    city: "Durgapur",
    city_code: "RDP",
    airport_name: "Kazi Nazrul Islam Airport",
    country: "India",
  },
  {
    sr: 20421,
    city: "Toowoomba",
    city_code: "WTB",
    airport_name: "Toowoomba Wellcamp Airport",
    country: "Australia",
  },
  {
    sr: 20422,
    city: "Shimoga",
    city_code: "RQY",
    airport_name: "Rashtrakavi Kuvempu Airport",
    country: "India",
  },
  {
    sr: 20423,
    city: "Rajkot",
    city_code: "HSR",
    airport_name: "Rajkot International Airport",
    country: "India",
  },
  {
    sr: 20424,
    city: "Bokhtar",
    city_code: "KQT",
    airport_name: "Bokhtar International Airport",
    country: "Tajikistan",
  },
  {
    sr: 20425,
    city: "Baishan",
    city_code: "NBS",
    airport_name: "Baishan Changbaishan Airport",
    country: "China",
  },
  {
    sr: 20426,
    city: "Guangzhou",
    city_code: "PFT",
    airport_name: "Pazhou Ferry Terminal",
    country: "China",
  },
  {
    sr: 20427,
    city: "Thanh Hóa",
    city_code: "THD",
    airport_name: "Thọ Xuân Airport",
    country: "Vietnam",
  },
  {
    sr: 20428,
    city: "Tulum",
    city_code: "TQO",
    airport_name: "Tulum International Airport",
    country: "Mexico",
  },
  {
    sr: 20429,
    city: "Concord",
    city_code: "USA",
    airport_name: "Concord-Padgett Regional Airport",
    country: "United States",
  },
  {
    sr: 20430,
    city: "Nay Pyi Taw",
    city_code: "NYT",
    airport_name: "Naypyidaw International Airport",
    country: "Myanmar",
  },
  {
    sr: 20431,
    city: "Türkistan",
    city_code: "HSA",
    airport_name: "Hazrat Sultan International Airport",
    country: "Kazakhstan",
  },
  {
    sr: 20432,
    city: "Panama City",
    city_code: "ECP",
    airport_name: "Northwest Florida Beaches International Airport",
    country: "United States",
  },
  {
    sr: 20433,
    city: "Maamigili",
    city_code: "VAM",
    airport_name: "Villa International Airport Maamigili",
    country: "Maldives",
  },
  {
    sr: 20434,
    city: "Dong Hoi",
    city_code: "VDH",
    airport_name: "Dong Hoi Airport",
    country: "Vietnam",
  },
  {
    sr: 20435,
    city: "Gondia",
    city_code: "GDB",
    airport_name: "Gondia Airport",
    country: "India",
  },
  {
    sr: 20436,
    city: "Bologna",
    city_code: "BLQ",
    airport_name: "Bologna Central Rail Station Airport",
    country: "Italy",
  },
  {
    sr: 20437,
    city: "Hamburg",
    city_code: "ZMB",
    airport_name: "Hamburg Hbf Airport",
    country: "Germany",
  }
];

export default airportDetails;
