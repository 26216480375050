import { Table } from "@mui/joy";
import BootstrapTooltip1 from "../BootstrapToolTip/BootstrapToolTip1";
import { useTranslation } from "react-i18next";
export default function ComissionParting({ flight }) {
    const { t } = useTranslation();
    return (
        <div className="flight-itenary">
            <div className="flight-itenary-header">
                <div className="flight-itenary-title-container">
                    <div>
                        {t("Commission & Parting")}
                    </div>
                </div>
            </div>
            <Table>
                <thead className="mui-table-header">
                    <BootstrapTooltip1 title={t("IATA")}>
                        <th style={{ textAlign: 'center' }}>{t("IATA")}</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title={t("PLB")}>
                        <th style={{ textAlign: 'center' }}>{t("PLB")}</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title={t("Dropnet")}>
                        <th style={{ textAlign: 'center' }}>{t("Dropnet")}</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title={t("Booking Fee")}>
                        <th style={{ textAlign: 'center' }}>{t("Booking Fee")}</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title={t("Client Tax IATA")}>
                        <th style={{ textAlign: 'center' }}>{t("Client Tax IATA")}</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title={t("Client Tax PLB")}>
                        <th style={{ textAlign: 'center' }}>{t("Client Tax PLB")}</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title={t("Client Tax Type")}>
                        <th style={{ textAlign: 'center' }}>{t("Client Tax Type")}</th>
                    </BootstrapTooltip1>
                </thead>
                <tbody>
                    <tr>
                        <td align="center">{flight?.commissionParting?.iata}</td>
                        <td align="center">{flight?.commissionParting?.plb}</td>
                        <td align="center">{flight?.commissionParting?.dropnet}</td>
                        <td align="center">{flight?.commissionParting?.bookingFee}</td>
                        <td align="center">{flight?.commissionParting?.taxIATA}</td>
                        <td align="center">{flight?.commissionParting?.taxPLB}</td>
                        <td align="center">{flight?.commissionParting?.taxType}</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
}