import React, { useContext, useEffect, useState } from "react";
import "./SearchResultsPage.css";
import SearchContext from "../../contexts/Searchbar/SearchContext";
// import LoadingGif from "../../assets/loading.gif";
import Grid from "@material-ui/core/Grid";
import ModifySearchBar from "../ModifySearchBar/ModifySearchBar";
// import LogoText from "../../assets/Logo-Text.png";
import ModifySearchDialog from "./ModifySearchDialog/ModifySearchDialog";
import Filters from "./Filters/Filters";
import SortComponent from "./SortComponents/SortComponent";
import Results from "./Results/Results";
import getSearchResults from "../../functions/getSearchResults";
import getNumberOfStopsFilter from "../../functions/getNumberOfStopsFilter";
import getFareTypeFilter from "../../functions/getFareTypeFilter";
import getAirlinesFilter from "../../functions/getAirlinesFilter";
import getPricesFilter from "../../functions/getPricesFilter";
import getConnectingInFilter from "../../functions/getConnectingInFilter";
import SearchIcon from "@material-ui/icons/Search";
import getArrivalDepartFilters from "../../functions/getArrivalDepartFilters";
import checkStopsFilter from "../../functions/checkStopsFilter";
import checkFareTypeFilter from "../../functions/checkFareTypeFilter";
import checkAirlinesFilter from "../../functions/checkAirlinesFilter";
import checkPricesFilter from "../../functions/checkPricesFilter";
import checkConnectingInFilter from "../../functions/checkConnectingInFilter";
import checkArrivalDepartFilter from "../../functions/checkArrivalDepartFilter";
import Drawer from "@material-ui/core/Drawer";
import TicketDrawer from "./TicketDrawer/TicketDrawer";
import LinearProgress from "@material-ui/core/LinearProgress";
// import TransFlightGIF from "../../assets/trans-flight.gif";
// import Skeleton from "@material-ui/lab/Skeleton";
import SearchLoadingSkeleton from "./SearchLoadingSkeleton";
import moment from "moment";
function SearchResultsPage() {
  const [search, setSearch] = useContext(SearchContext);
  const [activeSearch, setActiveSearch] = useState();
  const [modifySearchOpen, setModifySearchOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [traceId, setTraceId] = useState("");
  const [clientId, setClientId] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [searchError, setSearchError] = useState(false);
  const [filteredResults, setFilteredResults] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState();
  const [drawer, setDrawer] = useState(false);
  const [currencyCode, setCurrencyCode] = useState("INR");
  const [errorText, setErrorText] = useState(null);

  const handleClickOpen = () => {
    setModifySearchOpen(true);
  };

  const handleModifySearch = () => {
    setActiveSearch(search);
    setLoading(true);
    fetchSearchResults(search);
  };

  const handleClose = (value) => {
    if (value === undefined) {
      const lastSearch = JSON.parse(localStorage.getItem("lastSearch"));
      if (lastSearch) {

        lastSearch.depart = moment(lastSearch.depart);
        lastSearch.return = moment(lastSearch.return);
        if (lastSearch.destinations && lastSearch.destinations.length > 0) {
          for (let destination of lastSearch.destinations) {
            destination.depart = moment(lastSearch.depart);
          }
        }
      } else {
        setSearch(search);
      }
      setSearch(lastSearch);
    }
    // localStorage.removeItem("lastSearch");
    setModifySearchOpen(false);
  };

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const getMultiCityViaDestinations = () => {
    let viaDestinationArray = [];
    viaDestinationArray.push(search?.to?.city);
    for (let [index, destination] of search.destinations.entries()) {
      if (index === search.destinations.length - 1) {
        viaDestinationArray.push(destination.from.city);
      } else {
        viaDestinationArray.push(destination.from.city);
        viaDestinationArray.push(destination.to.city);
      }
    }
    return ` via ${viaDestinationArray
      .filter(onlyUnique)
      .join()
      .replace(/,/g, ", ")}`;
  };

  useEffect(() => {

    let temp = JSON.parse(localStorage.getItem("lastSearch"));
    if (temp) {
      // temp.arrival = moment(temp?.arrival)
      // temp.depart = moment(temp?.depart)
      setActiveSearch(temp);
      setLoading(true);
      fetchSearchResults(temp);
    } else {
      setSearch(search);
      setActiveSearch(search);
    }

  }, []);

  useEffect(() => {
    let result = searchResults.filter((item) =>
      searchInFilters(item, activeSearch)
    );
    setFilteredResults(result);
  }, [activeSearch]);

  const searchInFilters = (item, search) => {
    return (
      checkStopsFilter(item, search) &&
      checkFareTypeFilter(item, search) &&
      checkAirlinesFilter(item, search) &&
      checkPricesFilter(item, search) &&
      checkConnectingInFilter(item, search) &&
      checkArrivalDepartFilter(item, search)
    );
  };

  const fetchSearchResults = (searchContent) => {
    setSearchError(false);
    getSearchResults(searchContent)
      .then((response) => {
        if (response === undefined || response === "Error Doing Search Contact Admin" || response.flights.length === 0) {
          setErrorText(response.errors[0].errorDetail)
          console.log(errorText);

          setSearchError(true);
          setLoading(false);
        } else {
          setTraceId(response.traceId);
          setClientId(searchContent.clientId);
          setSearchResults(response.flights);
          setLoading(false);
          setAvailableFilters(response.flights, searchContent);
          setCurrencyCode(response.flights[0]?.currency);
        }
        localStorage.setItem("lastSearch", JSON.stringify(searchContent));
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const setAvailableFilters = (flights, searchContent) => {
    let existingSearch = { ...searchContent };
    existingSearch.filters.stops = getNumberOfStopsFilter(flights);
    existingSearch.filters.fareType = getFareTypeFilter(flights);
    existingSearch.filters.airlines = getAirlinesFilter(flights);
    existingSearch.filters.price = getPricesFilter(flights);
    existingSearch.filters.connectingIn = getConnectingInFilter(flights);
    existingSearch.filters.arrivalDepart = getArrivalDepartFilters(
      existingSearch
    );
    setActiveSearch(existingSearch);
  };

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(value);
    setSelectedTicket();
  };

  useEffect(() => {
    if (drawer === false) {
      setSelectedTicket();
    }
  }, [drawer]);

  //  console.log(123456, searchResults);
  //   console.log(456789, activeSearch);
  //   console.log(789123, filteredResults); 

  return (
    <div className="search-results-page-container">
      <div className="search-results-container">
        <div className="search-results-header">
          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <div className="search-results-header-container">
                <div className="modify-search-container">
                  <ModifySearchBar
                    search={search}
                    setSearch={setSearch}
                    modifySearch={handleClickOpen}
                    handleModifySearch={handleModifySearch}
                    loading={loading}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </div>

        {loading ? <>
          <LinearProgress color="secondary" />
          <SearchLoadingSkeleton />
        </>
          :
          <>
            {searchError ? <>
              <div className="no-results-container">
                <div className="no-results-icon">
                  <SearchIcon fontSize="large"></SearchIcon>
                </div>
                <div className="no-results-text">
                  {
                    errorText ? <p>{errorText}</p> : <div>No Results Found for your Search</div>
                  }
                </div>
              </div>
            </>
              :
              <>
                <div className="full-width negative-margin-top-120">
                  <Grid container>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          {searchResults.length > 0 && (
                            <Filters
                              search={activeSearch}
                              setSearch={setActiveSearch}
                              results={searchResults}
                              toggleDrawer={setDrawer}
                              currencyCode={currencyCode}
                            />
                          )}
                        </Grid>
                        <Grid item xs={9}>
                          <div className="ml-16">
                            {searchResults.length > 0 &&
                              <div className="search-results-title">
                                {activeSearch && activeSearch.type === "OneWay"
                                  ? `Flights from ${activeSearch.from.city} to ${activeSearch?.to?.city}`
                                  : ""}
                                {activeSearch && activeSearch.type === "RoundTrip"
                                  ? `Flights from ${activeSearch.from.city} to ${activeSearch?.to?.city}, and back`
                                  : ""}
                                {activeSearch &&
                                  activeSearch.type === "Multidestination"
                                  ? `Flights from ${activeSearch.from.city} to ${activeSearch.destinations[
                                    activeSearch.destinations.length - 1
                                  ].to.city
                                  }`
                                  : ""}
                                {activeSearch &&
                                  activeSearch.type === "Multidestination" && (
                                    <span className="opacity-5 small-font">
                                      {getMultiCityViaDestinations()}
                                    </span>
                                  )}
                              </div>
                            }
                            <div>
                              {searchResults.length > 0 &&
                                filteredResults.length > 0 && (
                                  <SortComponent
                                    results={filteredResults}
                                    order={order}
                                    setOrder={setOrder}
                                    orderBy={orderBy}
                                    setOrderBy={setOrderBy}
                                    currencyCode={currencyCode}
                                  />
                                )}
                            </div>
                            {searchResults.length > 0 ? <div>
                              <Results
                                selectedTicket={selectedTicket}
                                setSelectedTicket={setSelectedTicket}
                                traceId={traceId}
                                clientId={clientId}
                                order={order}
                                setOrder={setOrder}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                results={searchResults}
                                search={activeSearch}
                                setSearch={setActiveSearch}
                                setDrawer={setDrawer}
                              />
                            </div>
                              :
                              <div className="no-results-text">
                                {
                                  errorText ? <p>{errorText}</p> : <div>No Results Found for your Search</div>
                                }
                              </div>
                            }
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </div>
              </>}
          </>
        }

        {/* {loading && (
          <div className="custom-loader">
            <div>
              <img height={100} src={TransFlightGIF}></img>
            </div>
            <div className="loading-text">
              Searching flights for lowest fares
            </div>
          </div>
        )} */}


      </div>
      <Drawer
        anchor="right"
        open={drawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <TicketDrawer
          activeSearch={activeSearch}
          selectedTicket={selectedTicket}
          toggleDrawer={toggleDrawer}
          traceId={traceId}
          clientId={clientId}
          currencyCode={currencyCode}
        ></TicketDrawer>
      </Drawer>

      <ModifySearchDialog
        open={modifySearchOpen}
        onClose={handleClose}
        search={search}
        setSearch={setSearch}
        handleModifySearch={handleModifySearch}
      ></ModifySearchDialog>
    </div>
  );
}

export default SearchResultsPage;
