import { Grid, Paper } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState, useRef } from "react";
import airlineDetails from "../../data/AirlineDetails";
import airportDetails from "../../data/AirportDetails";
import cabinClass from "../../data/CabinClass";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Notify from "../Notifications/Notifications";
import { TimePicker } from "@material-ui/pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Row } from "react-bootstrap";
import DialogActions from "@mui/material/DialogActions";

// dayjs.extend(utc);
// dayjs.extend(timezone);
const today = dayjs();
export default function AddSegmentForm({
  returnSegments,
  onwardSegments,
  index,
  mode,
  type,
  setOnwardSegments,
  setReturnSegments,
  toggleDrawer,
  selectedItem,
  action,
  handleSnackbarToggle,
  selectedTripType,
  setSelectedTripType,
  value
}) {
  const addSegmentForm = useRef();
  const initialSegment = {
    airline: "",
    flightNo: "",
    originAirport: "",
    originTerminal: "",
    destinationAirport: "",
    destinationTerminal: "",
    depDate: null,
    depTime: null,
    arrDate: null,
    arrTime: null,
    cabinClass: "",
    bookingClass: "",
    gdsPNR: "",
    airlinePNR: "",
  };
  let selectedIndex = 0;
  const [segmentData, setSegmentData] = useState(initialSegment);
  const [formError, setformError] = useState(false);
  const [minArrTime, setMinArrTime] = useState(dayjs());
  const [duplicatePopup, setDuplicatePopup] = useState(false);
  const [isDuplicateRes, setIsDuplicateRes] = useState("");
  let cabinDetails = cabinClass;
  useEffect(() => {
    console.log("segmentData", segmentData);
    if (segmentData.depTime) {
      let temp = dayjs(segmentData.depTime).get("hour");
      let time = dayjs(segmentData.depTime).set("hour", temp).startOf("hour");
      setMinArrTime((minArrTime) => time);
      // console.log("minArrTime", minArrTime);
    }
  }, [segmentData]);
  useEffect(() => {
    console.log("segmentData Add Segment Form");
    console.log("onwardSegments", onwardSegments);
    console.log("returnSegments", returnSegments);
  }, [onwardSegments, returnSegments]);
  useEffect(() => {
    if (action === "edit" || action === "view") {
      if (selectedItem) {
        let item = initialSegment;
        item.airline = selectedItem.airline;
        item.flightNo = selectedItem.flightNo;
        item.originAirport = selectedItem.originAirport;
        item.originTerminal = selectedItem.originTerminal;
        item.destinationAirport = selectedItem.destinationAirport;
        item.destinationTerminal = selectedItem.destinationTerminal;
        item.depDate = setDayJSDate(selectedItem.depDate.$d);
        item.depTime = setDayJSDateTime(
          selectedItem.depDate.$d,
          selectedItem.depTime.$d
        );
        item.arrDate = setDayJSDate(selectedItem.arrDate.$d);
        item.arrTime = setDayJSDateTime(
          selectedItem.arrDate.$d,
          selectedItem.arrTime.$d
        );
        item.cabinClass = selectedItem.cabinClass;
        item.bookingClass = selectedItem.bookingClass;
        item.gdsPNR = selectedItem.gdsPNR;
        item.airlinePNR = selectedItem.airlinePNR;
        setSegmentData(item);
      }
    } else {
      resetForm();
    }
  }, [toggleDrawer, action]);
  async function handleUpdate() {
    if (
      segmentData.depDate === null ||
      segmentData.depTime === null ||
      segmentData.arrDate === null ||
      segmentData.arrTime === null ||
      (segmentData.depDate === segmentData.arrDate &&
        segmentData.depTime > segmentData.arrTime)
    ) {
      setformError(true);
    } else {
      if (selectedTripType === "onward") {
        let temp = structuredClone(onwardSegments);
        action === "edit"
          ? (temp[index] = segmentData)
          : temp.push(segmentData);
        setOnwardSegments((onwardSegments) => structuredClone(temp));
      } else {
        if (type === "onward") {
          let temp = structuredClone(onwardSegments);
          action === "edit"
            ? (temp[index] = segmentData)
            : temp.push(segmentData);
          setOnwardSegments((onwardSegments) => structuredClone(temp));
        } else {
          let temp = structuredClone(returnSegments);
          action === "edit"
            ? (temp[index] = segmentData)
            : temp.push(segmentData);
          setReturnSegments((returnSegments) => structuredClone(temp));
        }
      }

      setSelectedTripType((selectedTripType) => selectedTripType);
      await resetForm();
      await handleSnackbarToggle();
    }
  }

  async function resetForm() {
    setSegmentData(initialSegment);
  }
  const handleInputChange = (fieldName, value) => {
    let tempForm = { ...segmentData };
    if (fieldName === "depDate" || fieldName === "arrDate") {
      tempForm[fieldName] = setDayJSDate(value);
    } else if (fieldName === "depTime" || fieldName === "arrTime") {
      tempForm[fieldName] =
        fieldName === "depTime"
          ? setDayJSDateTime(segmentData.depDate.$d, value)
          : setDayJSDateTime(segmentData.arrDate.$d, value);
    } else {
      tempForm[fieldName] = value;
    }
    setSegmentData(tempForm);
  };
  const onSubmitClick = () => {
    addSegmentForm.current && addSegmentForm.current.submit();
  };
  function setDayJSDateTime(calDate, time) {
    let date = dayjs(calDate);
    let hour = dayjs(time).get("hour");
    let minute = dayjs(time).get("minute");
    let second = dayjs(time).get("second");
    let temp = date
      .set("hour", hour)
      .set("minute", minute)
      .set("second", second);
    return temp;
  }
  function setDayJSDate(calDate) {
    let day = dayjs(calDate);
    let date = day.get("date");
    let month = day.get("month");
    let year = day.get("year");
    let temp = day.set("date", date).set("month", month).set("year", year);
    return dayjs(temp);
  }
  const airlineFilterOptions = (options, { inputValue }) => {
    if (inputValue.length <= 2) {
      return options.filter(
        (option) => option.Code.toLowerCase() === inputValue.toLowerCase()
      );
    } else {
      return options.filter((option) =>
        option.Name.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
  };
  const airportFilterOptions = (options, { inputValue }) => {
    if (inputValue.length <= 3) {
      return options.filter(
        (option) => option.city_code.toLowerCase() === inputValue.toLowerCase()
      );
    } else {
      return options.filter((option) =>
        option.airport_name.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
  };
  async function loadPNRDetails() {
    if(value != "Fresh"){
      onSubmitClick(); 
    }
    else{
    const headers = {
      "Content-Type": "application/json",
    };
    let obj = {
    };
    let url = '';
      obj = {
        PNR: segmentData.gdsPNR
      }
      url = "Flights/Booking/GetBooking/v1/IsPNRExist"

    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        url,

        JSON.stringify(obj),
        { headers }
      )
      .then((res) => {
        onSubmitClick(); 
      })
      .catch((err) => {
        if (err.response.data.toLowerCase().includes("order already exists")) {
          setIsDuplicateRes(err.response.data);
          setDuplicatePopup(true);
        }
        else {
          let errorMessage =
            err.response &&
              err.response.data &&
              err.response.data.error_description
              ? err.response.data.error_description
              : err.response && err.response.data
                ? err.response.data
                : err.response
                  ? err.response
                  : err;
          console.log(err);
        }
      });
    }
  }  

  const handleDuplicateProceed = () => {
    setDuplicatePopup(false);
    onSubmitClick();
  }

  const handleDuplicateCancel = () => {
    setDuplicatePopup(false);
  }
  return (
    <>
      {" "}
      <Paper
        elevation={3}
        style={{ padding: "16px", marginBottom: "16px", maxWidth: "50vw" }}
      >
        <ValidatorForm
          onSubmit={handleUpdate}
          ref={addSegmentForm}
          onError={() =>
            Notify("error", "Form contains error", "Ticketing Configuration")
          }
        >
          {/* */}
          <div className="segment-drawer-container">
            <div className="vendor-heading-container vendor-title-flex-container">
              <div className="">
                <CloseIcon
                  className="icon-fill pointer"
                  fontSize="medium"
                  onClick={toggleDrawer(false)}
                ></CloseIcon>{" "}
                {mode === "add" ? "Add" : "Edit"} Segment Info
              </div>
            </div>
            <div className="ticket-drawer-form">
              <Grid container spacing={5}>
                <Grid item xs={6} md={6}>
                  <Autocomplete
                    id="PCC-select"
                    value={
                      segmentData.airline !== ""
                        ? airlineDetails.find(
                          (v) => v.Code === segmentData.airline
                        )
                        : null
                    }
                    onChange={(event, newValue) => {
                      handleInputChange(
                        "airline",
                        newValue ? newValue.Code : ""
                      );
                    }}
                    filterSelectedOptions
                    filterOptions={airlineFilterOptions}
                    options={airlineDetails.filter((x) => x.Code !== "All")}
                    disabled={action === "view"}
                    getOptionLabel={(option) =>
                      option.Name + " (" + option.Code + ")"
                    }
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        className={
                          action === "view"
                            ? "ticket-config-inputs-disabled"
                            : "ticket-config-inputs"
                        }
                        required
                        label="Airline"
                        value={
                          segmentData.airline !== ""
                            ? airlineDetails.find(
                              (v) => v.Code === segmentData.airline
                            )
                            : null
                        }
                        color="secondary"
                        size="small"
                        validators={["required"]}
                        errorMessages={["required"]}
                        disabled={action === "view"}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextValidator
                    className="ticket-config-inputs"
                    label="Flight No"
                    size="small"
                    color="secondary"
                    value={segmentData.flightNo}
                    onChange={(event) =>
                      handleInputChange("flightNo", event.target.value)
                    }
                    required
                    validators={[
                      "required",
                      "matchRegexp:^[^\\s]+(\\s+[^\\s]+)*$",
                    ]}
                    errorMessages={[
                      "required",
                      "This field cannot be blank or contain only spaces",
                    ]}
                    disabled={action === "view"}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <Autocomplete
                    options={airportDetails.filter(
                      (option) => option.city_code !== "All"
                    )} // Replace with your airline options
                    value={
                      segmentData.originAirport !== ""
                        ? airportDetails.find(
                          (v) => v.city_code === segmentData.originAirport
                        )
                        : null
                    }
                    getOptionLabel={(option) =>
                      option.airport_name + " (" + option.city_code + ")"
                    }
                    onChange={(event, value) =>
                      handleInputChange(
                        "originAirport",
                        value ? value.city_code : ""
                      )
                    }
                    filterSelectedOptions
                    filterOptions={airportFilterOptions}
                    required
                    renderInput={(params) => (
                      <TextValidator
                        label="Origin Airport"
                        validators={["required"]}
                        errorMessages={["required"]}
                        required
                        value={
                          segmentData.originAirport !== ""
                            ? airportDetails.find(
                              (v) => v.city_code === segmentData.originAirport
                            )
                            : null
                        }
                        disabled={action === "view"}
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextValidator
                    // validators={["required"]}
                    // errorMessages={["required"]}
                    disabled={action === "view"}
                    label="Origin Terminal"
                    value={segmentData.originTerminal}
                    onChange={(e) =>
                      handleInputChange("originTerminal", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <Autocomplete
                    options={airportDetails.filter(
                      (option) => option.city_code !== "All"
                    )} // Replace with your airline options
                    value={
                      segmentData.destinationAirport !== ""
                        ? airportDetails.find(
                          (v) =>
                            v.city_code === segmentData.destinationAirport
                        )
                        : null
                    }
                    filterSelectedOptions
                    filterOptions={airportFilterOptions}
                    getOptionLabel={(option) =>
                      option.airport_name + " (" + option.city_code + ")"
                    }
                    onChange={(event, value) =>
                      handleInputChange(
                        "destinationAirport",
                        value ? value.city_code : ""
                      )
                    }
                    required
                    renderInput={(params) => (
                      <TextValidator
                        label="Destination Airport"
                        validators={["required"]}
                        required
                        value={
                          segmentData.destinationAirport !== ""
                            ? airportDetails.find(
                              (v) =>
                                v.city_code === segmentData.destinationAirport
                            )
                            : null
                        }
                        errorMessages={["required"]}
                        disabled={action === "view"}
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextValidator
                    // validators={["required"]}
                    // errorMessages={["required"]}
                    disabled={action === "view"}
                    label="Destination Terminal"
                    value={segmentData.destinationTerminal}
                    onChange={(e) =>
                      handleInputChange("destinationTerminal", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled={action === "view"}
                      onChange={(newValue) => {
                        handleInputChange(
                          "depDate",
                          newValue.$d ? newValue.$d : ""
                        );
                      }}
                      format="DD/MM/YYYY"
                      // timezone="Europe/London"
                      defaultValue={today}
                      disablePast
                      required
                      value={segmentData.depDate}
                      label="Departure Date *"
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6} md={6}>
                  <TimePicker
                    disabled={action === "view" || segmentData.depDate === null}
                    clearable
                    disablePast
                    ampm={false}
                    label="Departure Time"
                    // timezone="Europe/London"
                    value={segmentData.depTime}
                    onChange={(newValue) => {
                      handleInputChange("depTime", newValue ? newValue : "");
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled={
                        action === "view" || segmentData.depDate === null
                      }
                      onChange={(newValue) => {
                        handleInputChange(
                          "arrDate",
                          newValue.$d ? newValue.$d : ""
                        );
                      }}
                      format="DD/MM/YYYY"
                      // timezone="Europe/London"
                      defaultValue={
                        segmentData.depDate
                          ? setDayJSDate(segmentData.depDate)
                          : today
                      }
                      disablePast
                      minDate={
                        segmentData.depDate
                          ? setDayJSDate(segmentData.depDate)
                          : today
                      }
                      required
                      value={segmentData.arrDate}
                      label="Arrival Date*"
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6} md={6}>
                  <TimePicker
                    disabled={
                      action === "view" ||
                      segmentData.arrDate === null ||
                      segmentData.depDate === null ||
                      segmentData.depTime === null
                    }
                    clearable
                    ampm={false}
                    // timezone="Europe/London"
                    label="Arrival Time"
                    disablePast
                    minTime={minArrTime}
                    defaultValue={minArrTime}
                    value={segmentData.arrTime}
                    onChange={(newValue) => {
                      handleInputChange("arrTime", newValue ? newValue : "");
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <Autocomplete
                    options={cabinDetails.filter((x) => x.label !== "All")} // Replace with your airport options
                    value={
                      segmentData.cabinClass !== ""
                        ? cabinDetails.find(
                          (v) => v.value === segmentData.cabinClass
                        )
                        : null
                    }
                    getOptionLabel={(option) => option?.label}
                    onChange={(event, value) => {
                      handleInputChange("cabinClass", value ? value.value : "");
                    }}
                    disabled={action === "view"}
                    required
                    renderInput={(params) => (
                      <TextValidator
                        validators={["required"]}
                        errorMessages={["required"]}
                        disabled={action === "view"}
                        required
                        value={
                          segmentData.cabinClass !== ""
                            ? cabinDetails.find(
                              (v) => v.value === segmentData.cabinClass
                            )
                            : null
                        }
                        label="Cabin Class"
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextValidator
                    validators={[
                      "required",
                      "matchRegexp:^[^\\s]+(\\s+[^\\s]+)*$",
                    ]}
                    errorMessages={[
                      "required",
                      "This field cannot be blank or contain only spaces",
                    ]}
                    disabled={action === "view"}
                    label="Booking Class"
                    required
                    value={segmentData.bookingClass}
                    onChange={(e) =>
                      handleInputChange("bookingClass", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextValidator
                    validators={[
                      "required",
                      "matchRegexp:^[^\\s]+(\\s+[^\\s]+)*$",
                    ]}
                    errorMessages={[
                      "required",
                      "This field cannot be blank or contain only spaces",
                    ]}
                    disabled={action === "view"}
                    label="GDS PNR"
                    required
                    value={segmentData.gdsPNR}
                    onChange={(e) =>
                      handleInputChange("gdsPNR", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextValidator
                    validators={[
                      "required",
                      "matchRegexp:^[^\\s]+(\\s+[^\\s]+)*$",
                    ]}
                    errorMessages={[
                      "required",
                      "This field cannot be blank or contain only spaces",
                    ]}
                    disabled={action === "view"}
                    label="Airline PNR"
                    required
                    value={segmentData.airlinePNR}
                    onChange={(e) =>
                      handleInputChange("airlinePNR", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className="modal_submit_btn"
                    variant="filled"
                    color="primary"
                    size="large"
                    style={{
                      textTransform: "none",
                      width: "100%",
                    }}
                    onClick={loadPNRDetails}
                  >
                    {action == "edit" ? "Update" : "Add"}
                  </Button>
                </Grid>
              </Grid>{" "}
            </div>
            <Dialog
        open={duplicatePopup}
        onClose={() => setDuplicatePopup(false)}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
        className="error-dialog-box"
      >
        <DialogTitle id="error-dialog-title" className="main-heading-cls">
          Duplicate Order
        </DialogTitle>
        <DialogContent className="mt-3 py-2">
          <DialogContentText id="error-dialog-description">
            <Row>
              <div className="col-12 sub-heading-cls mb-2">
                Dear Travel Partner
              </div>
              <div className="normal-txt-cls">
                <p>
                  {isDuplicateRes}
                </p>
              </div>
            </Row>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="error-dialog-action text-center my-2">
          <Button variant="outlined" onClick={handleDuplicateCancel}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={handleDuplicateProceed}>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
          </div>
        </ValidatorForm>
        {formError ? (
          <div style={{ width: "100%", textAlign: "center" }}>
            <span
              style={{ fontSize: "16px", color: "red", paddingLeft: "10px" }}
            >
              *Please fill all fields
            </span>
          </div>
        ) : (
          ""
        )}
      </Paper>
    </>
  );
}
