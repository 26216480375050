//import * as React from "react";
import React, { useState, useEffect } from "react";
import "./ViewReissue.css";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { checkIfUserStaff } from "../../services/AuthService";
import SearchIcon from "@mui/icons-material/Search";
import RiseLoading from "../Loader/RiseLoader";
import Input from "@mui/joy/Input";
import dayjs from "dayjs";
import Button from "@mui/joy/Button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useHistory } from "react-router-dom";
import { formatDate } from "../../services/dateFormatter";
import TablePagination from "@material-ui/core/TablePagination";
import { IconButton, TextField } from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Autocomplete from "@mui/material/Autocomplete";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useTranslation } from "react-i18next";

function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </>
    );
}

export default function ViewReissue() {
    const today = dayjs();
    const [selectedRef, setSelectedRef] = useState("reissue");
    const [selecteddate, setselecteddate] = useState("");
    const [cancelViewDetails, setCancelViewDetails] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [gfsinputValue, setgfsInputValue] = useState("");
    const [gxinputValue, setgxInputValue] = useState("");
    const [FromDate, setFromDateValue] = useState(today);
    const [ToDate, setToDateValue] = useState(today);
    const [selectedservice, setSelectedService] = useState("");
    const [clientIdvalue, setClientIdValue] = useState("");
    const [selectedviewStatus, setViewSelectedStatus] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [tableData, setTableData] = useState([]);
    const [clientProfiles, setClientProfiles] = useState(null);
    const { t } = useTranslation();

    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1);
    };


    useEffect(() => {

        if (cancelViewDetails) {
            const startIndex = (page - 1) * rowsPerPage;
            const endIndex = page * rowsPerPage;
            setTableData(cancelViewDetails.slice(startIndex, endIndex));
        }
        else {
            setTableData([]);
        }
    }, [cancelViewDetails, page])


    useEffect(() => {
        if (checkIfUserStaff()) {
            getClients();
        }
    }, []);

    useEffect(() => {
        if (clientProfiles) {
            loadViewCancellationDetails();
        }
    }, [clientProfiles])


    const handleViewStatusChange = (event, newValue) => {
        setViewSelectedStatus(newValue);
    };
    const handleServicesChange = (event, newValue) => {
        setSelectedService(newValue);
    };
    const handlerefNoChange = (event, newValue) => {

        setSelectedRef(newValue);
    };

    useEffect(() => {
        selectedRef === "gfs" ? setselecteddate("Booking_Date") : setselecteddate("Reissue_Date");
        setgxInputValue("");
        setgfsInputValue("");
    }, [selectedRef])


    const viewcancel = (event) => {
        if (FromDate > ToDate) {
            alert(`${t("From date shouldn't be greater than to date")}`);
        } else {
            event.preventDefault();
            loadViewCancellationDetails();
        }

    };
    const handlegxInputChange = (e) => {
        setgxInputValue(e.target.value);
    };
    const handlegfsInputChange = (e) => {
        setgfsInputValue(e.target.value);
    };
    const handleDateChange = (event, newValue) => {
        setselecteddate(newValue);
    };

    function handleStatusClick(orderRefId, reissueRefId) {
        setShowLoader(true);
        sessionStorage.setItem('orderRefId', orderRefId);
        sessionStorage.setItem('reissueRefId', reissueRefId || 0);
        window.open("/ViewReissueDetails", '_blank');
        setShowLoader(false);
    }


    async function loadViewCancellationDetails() {

        setPage(1)
        setShowLoader(true);
        const headers = {
            "Content-Type": "application/json",
        };
        await axios
            .post(
                process.env.REACT_APP_CORE_URL +
                "Flights/Booking/Reissue/v1/GetViewReissue",
                JSON.stringify({
                    GFSId: selectedRef === "gfs" ? gfsinputValue : null,
                    GFRId: selectedRef === "reissue" ? gxinputValue : null,
                    clientId: (gfsinputValue || gxinputValue) ? 0 : clientIdvalue || 0,
                    fromDate: (gfsinputValue || gxinputValue) ? null : dayjs(FromDate).format("YYYY-MM-DD") + "T00:00:00.000Z" || null,
                    toDate: (gfsinputValue || gxinputValue) ? null : dayjs(ToDate).format("YYYY-MM-DD") + "T23:59:00.000Z" || null,
                    DateChoosen: selecteddate || null,
                    status: (gfsinputValue || gxinputValue) ? null : selectedviewStatus || null,
                    ServiceSelected: (gfsinputValue || gxinputValue) ? null : selectedservice || null,
                }),
                {
                    headers,
                }
            )
            .then((response) => {
                if (response?.data[0]?.reissueRefNo) {
                    let viewcancel = response.data;

                    const sortedDetails = viewcancel.sort((a, b) => {
                        const refA = parseInt(a.reissueRefNo.replace(/\D/g, ""), 10);
                        const refB = parseInt(b.reissueRefNo.replace(/\D/g, ""), 10);
                        return refB - refA;
                    });
                    setCancelViewDetails(sortedDetails);
                    setShowLoader(false);
                }
                else {
                    setCancelViewDetails([]);
                    setShowLoader(false);
                }

            })
            .catch((err) => {
                setErrMsg(err);
                setShowLoader(false);
                console.log(err);
            });
    }


    async function getClients() {
        setShowLoader(true);
        await axios
            // .get(process.env.REACT_APP_CORE_URL + "api/Configuration/ClientProfile")
            .get(
                process.env.REACT_APP_CORE_URL +
                "api/Configuration/ClientProfile/GetClientList?sorts=created&filters=isactive==true"
            )
            .then((response) => {
                // console.log("clientProfiles", response);
                let tempArray = response.data.sort((a, b) =>
                    a.clientName > b.clientName ? 1 : b.clientName > a.clientName ? -1 : 0
                );
                setClientProfiles(() => structuredClone(tempArray));
            }).catch(() => {
                setShowLoader(false);
            });
    }

    return (
        <div>
            <div className="">
                <h5 style={{ textAlign: "left", padding: "30px 10px 10px 30px", color: "#a0155a" }}>
                    {t("View Reissue")}
                </h5>
                <div className="reissue-container">
                    <div className="view-cancel">
                        <div className="Reissuefilter1stRow">
                            <div colSpan="4" className="padLR2vw padTB1vw">
                                <Select
                                    defaultValue={"reissue"}
                                    variant="outlined"
                                    onChange={handlerefNoChange}
                                >
                                    <Option value="gfs">{t("GFS RefNo")}</Option>
                                    <Option value="reissue">{t("Reissue RefNo")}</Option>
                                </Select>
                            </div>
                            <div colSpan="4" className="padLR2vw padTB1vw">
                                <Select
                                    value={selecteddate}
                                    // defaultValue={"Cancel_Date"}
                                    variant="outlined"
                                    onChange={handleDateChange}
                                    placeholder="Date Type "
                                >
                                    <Option value="Booking_Date">{t("Booking Date")}</Option>
                                    <Option value="Reissue_Date">{t("Reissue Date")}</Option>
                                </Select>
                            </div>
                            {checkIfUserStaff() ? (
                                <div colSpan="4" className="padLR2vw padTB1vw ">
                                    <Autocomplete

                                        style={{ width: "200px", backgroundColor: "#d3d3d317" }}
                                        id="Client select"
                                        value={
                                            clientProfiles !== null
                                                ? clientProfiles.find((v) => v.id === clientIdvalue)
                                                : null
                                        }
                                        onChange={(event, newValue) => {
                                            setClientIdValue(newValue ? newValue.id : "");
                                        }}
                                        options={clientProfiles || []}
                                        getOptionLabel={(option) => `${option.clientName} (${option.id})`}
                                        filterOptions={(options, { inputValue }) =>
                                            options.filter((option) =>
                                                option.clientName.toLowerCase().includes(inputValue.toLowerCase().trim()) ||
                                                option.id.toString().includes(inputValue.trim())
                                            )
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className="rounded"
                                                label={t("Select Client")}
                                                variant="outlined"
                                                color="secondary"
                                                size="small"
                                                validators={["required"]}
                                                errorMessages={[t("Client is required")]}
                                            />
                                        )}
                                    />
                                </div>
                            ) : null}
                            <div colSpan="4" className="padLR2vw padTB1vw">
                                <Select defaultValue="None" onChange={handleViewStatusChange}>
                                    <Option value="None">{t("Status")}</Option>
                                    <Option value="Cancel_Requested">{t("Cancel Requested")}</Option>
                                    <Option value="Cancelled">{t("Cancelled")}</Option>
                                    {checkIfUserStaff() && <Option value="Refund_Initiated">{t("Refund Initiated")}</Option>}
                                    {checkIfUserStaff() && <Option value="Voided">{t("Voided")}</Option>}
                                    <Option value="Refunded">{t("Refunded")}</Option>
                                    <Option value="Cancel_Rejected">{t("Cancel_Rejected")}</Option>
                                </Select>
                            </div>
                            <div colSpan="4" className="padLR2vw padTB1vw">
                                <Select
                                    defaultValue="All Services"
                                    onChange={handleServicesChange}
                                >
                                    <Option value="All Services">{t("All Services")}</Option>
                                    <Option value="Flight">{t("Flight")}</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="Reissuefilter1stRow">
                            <div colSpan="4" className="padLR2vw padTB1vw">
                                {selectedRef === "gfs" ? (
                                    <Input
                                        placeholder={t("Search By Ref No")}
                                        size="md"
                                        style={{
                                            "&:focus, &:hover": {},
                                        }}
                                        value={gfsinputValue}
                                        onChange={handlegfsInputChange}
                                    />
                                ) : (
                                    <Input
                                        placeholder={t("Free Text Search")}
                                        size="md"
                                        style={{
                                            "&:focus, &:hover": {
                                                borderColor: "#a0155a",
                                            },
                                        }}
                                        value={gxinputValue}
                                        onChange={handlegxInputChange}
                                    />
                                )}
                            </div>
                            <div colSpan="4" className="padLR2vw padTB1vw">
                                {selecteddate === "Booking_Date" ? (
                                    <div className="reissue-date-filter-container">
                                        <div className="">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    onChange={(newValue) => {
                                                        setFromDateValue(newValue.$d ? newValue.$d : null);
                                                    }}
                                                    defaultValue={today}
                                                    disableFuture
                                                    format="DD/MM/YYYY"
                                                    maxDate={today}
                                                    value={FromDate ? dayjs(FromDate) : dayjs()}
                                                    label={t("From Date")}
                                                    className="rounded"
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <div className="add-search-if-item flex-1-input margin-right-16 ms-2">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    onChange={(newValue) => {
                                                        setToDateValue(newValue.$d ? newValue.$d : null);
                                                    }}
                                                    defaultValue={today}
                                                    disableFuture
                                                    format="DD/MM/YYYY"
                                                    minDate={dayjs(FromDate)}
                                                    maxDate={today}
                                                    value={ToDate ? dayjs(ToDate) : dayjs()}
                                                    label={t("To Date")}
                                                    className="rounded"
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="reissue-date-filter-container">
                                        <div className="add-search-if-item flex-1-input margin-right-16">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    onChange={(newValue) => {
                                                        setFromDateValue(newValue.$d ? newValue.$d : null);
                                                    }}
                                                    defaultValue={today}
                                                    disableFuture
                                                    format="DD/MM/YYYY"
                                                    maxDate={today}
                                                    value={FromDate ? dayjs(FromDate) : dayjs()}
                                                    label={t("From Date")}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <div className="add-search-if-item flex-1-input margin-right-16 ms-2">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    onChange={(newValue) => {
                                                        setToDateValue(newValue.$d ? newValue.$d : null);
                                                    }}
                                                    defaultValue={today}
                                                    disableFuture
                                                    format="DD/MM/YYYY"
                                                    minDate={dayjs(FromDate)}
                                                    maxDate={today}
                                                    value={ToDate ? dayjs(ToDate) : dayjs()}
                                                    label={t("To Date")}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* <div colSpan="4" className="padLR2vw padTB1vw">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker defaultValue={today} />
              </LocalizationProvider>
              </div> */}


                            <div colSpan="4" className="padLR2vw padTB1vw">
                                <Button
                                    variant="contained"
                                    style={{
                                        background: "#a0155a",
                                        color: "white",
                                        fontSize: "16px",
                                    }}
                                    onClick={viewcancel}
                                >
                                    {t("Search")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                {showLoader ? (
                    <RiseLoading isviewBooking={showLoader} />
                ) : (
                    <div className="cancellationViewContainer">
                        {tableData && tableData[0] ? (
                            <>
                                <h5 style={{ color: "#a0155a", margin: "10px" }}>
                                    {t("Reissue Details List")}
                                </h5>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {checkIfUserStaff() &&
                                                    <TableCell>
                                                        <Typography
                                                            variant="h5"
                                                            style={{ fontSize: "18px", color: "green" }}
                                                        >
                                                            {t("Client ID")}
                                                        </Typography>
                                                    </TableCell>
                                                }
                                                <TableCell>
                                                    <Typography
                                                        style={{ fontSize: "16px", color: "black" }}
                                                    >
                                                        {t("Reissue Request Date")}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        style={{ fontSize: "16px", color: "black" }}
                                                    >
                                                        {t("Service")}
                                                    </Typography>{" "}
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        style={{ fontSize: "16px", color: "black" }}
                                                    >
                                                        {t("Reissue RefNo")}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        style={{ fontSize: "16px", color: "black" }}
                                                    >
                                                        {t("Booking RefNo")}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        style={{ fontSize: "16px", color: "black" }}
                                                    >
                                                        {t("Airline")}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        style={{ fontSize: "16px", color: "black" }}
                                                    >
                                                        {t("Passenger Name")}
                                                    </Typography>
                                                </TableCell>
                                                {checkIfUserStaff() && <TableCell>
                                                    <Typography
                                                        style={{ fontSize: "16px", color: "black" }}
                                                    >
                                                        {t("PCC")}
                                                    </Typography>
                                                </TableCell>}
                                                <TableCell>
                                                    <Typography
                                                        style={{ fontSize: "16px", color: "black" }}
                                                    >
                                                        {t("User")}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        style={{ fontSize: "16px", color: "black" }}
                                                    >
                                                        {t("Booking Status")}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Array.isArray(tableData) &&
                                                tableData.map((canceldetail, index) => (
                                                    <TableRow key={index}>
                                                        {checkIfUserStaff() && <TableCell>{canceldetail.clientId}</TableCell>}
                                                        <TableCell>
                                                            {formatDate(canceldetail.reissueRequestDate)}
                                                        </TableCell>
                                                        <TableCell>{canceldetail.service}</TableCell>
                                                        <TableCell>{canceldetail.reissueRefNo}</TableCell>
                                                        <TableCell>{canceldetail.bookingRefno}</TableCell>
                                                        <TableCell>{canceldetail.airline}</TableCell>
                                                        <TableCell>{canceldetail.passengers[0]}</TableCell>
                                                        {checkIfUserStaff() && <TableCell>{canceldetail.pcc}</TableCell>}
                                                        <TableCell>{canceldetail.user}</TableCell>
                                                        <TableCell
                                                            onClick={() =>
                                                                handleStatusClick(canceldetail.orderrefid, canceldetail.reissueRefNo)
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                                color: "#a0155a",
                                                                transition: "color 0.3s ease",
                                                            }}
                                                            className="change-hover-style"
                                                        >
                                                            {canceldetail.bookingStatus}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[25]}
                                    component="div"

                                    count={cancelViewDetails?.length}
                                    rowsPerPage="25"
                                    page={page - 1}
                                    onChangePage={(event, newPage) => handleChangePage(event, newPage, page, rowsPerPage)}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </>
                        ) : (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "50px",
                                        color: "grey",
                                    }}
                                >
                                    <SearchIcon fontSize="large"></SearchIcon>
                                </div>
                                <p className="text-center">{t("No result found for your search")}</p>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
