import React, { useEffect, useState } from "react";
import { Button } from "./styles";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { useTranslation } from "react-i18next";
const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    setVisible(scrolled > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);

    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);

  return (
    <>
      {visible ? (
        <Button onClick={scrollToTop}>
          {t("Back to Top")}
          <KeyboardDoubleArrowUpIcon />
        </Button>
      ) : (
        ""
      )}
    </>
  );
};

export default ScrollToTopButton;
