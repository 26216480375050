import "rheostat/initialize";
import React, { useEffect, useState } from "react";
import "./FilterComponents.css";
import "./Rheostat.css";
import Rheostat from "rheostat";
import CurrencyCodeComponent from "../../../providers/CurrencyCode";

// function FlightPrice({ search, setSearch, toggleDrawer, currencyCode }) {
//   const updatePriceFilterInSearch = (value) => {
//     const existingSearch = { ...search };
//     existingSearch.filters.price[3] = value.values[0];
//     existingSearch.filters.price[2] = value.values[1];
//     toggleDrawer(false);
//     setSearch(existingSearch);
//   };

//   let priceArr = search.filters.price;

//   if(priceArr.length === 3){
//     priceArr[3] = priceArr[0];
//   }

//   return (
//     <div className="flight-duration-container mt-12 mb-16">
//       <Rheostat
//         min={priceArr[0]}
//         max={priceArr[1]}
//         values={[priceArr[3],priceArr[2]]}
//         onValuesUpdated={updatePriceFilterInSearch}
//       />
//       <div className="prices-filter-value">
//         <div className="prices-filter-start-value">
//         {currencyCode}{" "} {priceArr[3].toFixed(2)}
//         </div>
//         <div className="prices-filter-end-value">
//         {currencyCode}{" "} {priceArr[2].toFixed(2)}
//         </div>
//       </div>
//     </div>
//   );
// }

function FlightPrice({ search, setSearch, toggleDrawer, currencyCode }) {
  const [priceFilter, setPriceFilter] = useState(null);

  const updatePriceFilterInSearch = (value) => {
    setPriceFilter(value); 
  };

  useEffect(() => {
    if (priceFilter) {
      const timeout = setTimeout(() => {
        setSearch((prevSearch) => {
          const updatedSearch = { ...prevSearch };
          updatedSearch.filters.price[3] = priceFilter.values[0];
          updatedSearch.filters.price[2] = priceFilter.values[1];
          return updatedSearch;
        });
      }, 300);

      return () => clearTimeout(timeout);
    }
  }, [priceFilter]);

  let priceArr = search.filters.price;

  if (priceArr.length === 3) {
    priceArr[3] = priceArr[0];
  }

  return (
    <div className='flight-duration-container mt-12 mb-16'>
      <Rheostat
        min={priceArr[0]}
        max={priceArr[1]}
        values={[priceArr[3], priceArr[2]]}
        onValuesUpdated={updatePriceFilterInSearch}
      />
      <div className='prices-filter-value'>
        <div className='prices-filter-start-value'>
          <CurrencyCodeComponent currencyCode={currencyCode} />
          {search.filters.price[3].toFixed(2)}
        </div>
        <div className='prices-filter-end-value'>
          <CurrencyCodeComponent currencyCode={currencyCode} />
          {search.filters.price[2].toFixed(2)}
        </div>
      </div>
    </div>
  );
}

export default FlightPrice;
