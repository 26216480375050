import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
// import Backend from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import jp from './locales/jp.json';
import it from './locales/it.json';
import de from './locales/de.json';
import en from './locales/en.json';

const lang = localStorage.getItem("i18nextLng")

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        lng: lang, // Default language
        debug: true,
        resources: {
            en: {
                translation: en
            },
            jp: {
                translation: jp
            },
            it: {
                translation: it
            },
            de: {
                translation: de
            }
        },
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
