import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import useConfigurationTableCell from "../../../../hooks/useConfigurationTableCell";
import useConfigurationTableRow from "../../../../hooks/useConfigurationTableRow";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Paper from "@material-ui/core/Paper";
import { Chip } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import Alert from "@material-ui/lab/Alert";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { OutlinedInput, InputAdornment } from "@material-ui/core";

function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </>
    );
}

export default function ATCConfigTable({
    handleAtcRowClick,
    openDrawer,
    setAction,
    categories,
}) {
    const StyledTableRow = useConfigurationTableRow;
    const StyledTableCell = useConfigurationTableCell;
    const [searchedVal, setSearchedVal] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = React.useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className="search-category-listing">
            <TableContainer component={Paper} className="table-paper-styles">
                <Table className="table-background">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={6} className="py-2">
                                <OutlinedInput
                                    id="keyword-search"
                                    onChange={(e) => { setSearchedVal(e.target.value); setPage(0) }}
                                    placeholder="Keyword Search by Airline"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchOutlinedIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Sl No</TableCell>
                            <TableCell>Airlines - Code</TableCell>
                            <TableCell>Auto Ticket Changer Status</TableCell>
                            <TableCell>Last Update</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categories && categories.length > 0 &&
                            (categories.filter((group) => {
                                const searchValLower = searchedVal.toString().toLowerCase();
                                const { airline } = group;
                                return searchValLower === '' || airline.toLowerCase().includes(searchValLower);
                            })).length > 0
                            ? ((
                                categories.filter((group) => {
                                    const searchValLower = searchedVal.toString().toLowerCase();
                                    const { airline } = group;
                                    return searchValLower === '' || airline.toLowerCase().includes(searchValLower);
                                }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((group) => (
                                        <StyledTableRow>
                                            <StyledTableCell>{group.id}</StyledTableCell>
                                            <StyledTableCell>
                                                <div className="table-cell-title pointer" onClick={() => handleAtcRowClick(group.id)}>
                                                    {group.airline}
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Chip
                                                    label={group.isActive ? "Enabled" : "Disabled"}
                                                    color="primary"
                                                    size="small"
                                                    style={{ backgroundColor: group.isActive ? "green" : "red" }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <div className="table-cell-description">Created by: <span style={{ color: "black" }}>{group.createdBy}</span></div>
                                                <div className="table-cell-description">Created on: {group.created.replace('T', " , ")}</div>
                                                <div className="table-cell-description">Last Modified by: <span style={{ color: "black" }}>{group.modifiedBy}</span></div>
                                                <div className="table-cell-description">Last Modified on: {group.modified.replace('T', " , ")}</div>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                            )
                            ) : (
                                <StyledTableRow>
                                    <StyledTableCell colSpan={4} align="center">
                                        <Alert severity="error">No record found.</Alert>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={categories.filter((group) => {
                    const searchValLower = searchedVal.toString().toLowerCase();
                    const { airline } = group;
                    return searchValLower === '' || airline.toLowerCase().includes(searchValLower);
                }).length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => handleChangePage(event, newPage)}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
                ActionsComponent={TablePaginationActions}
            />

        </div>
    );
}