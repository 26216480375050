import moment from "moment";
export default function sanitizeOriginDestinations(search) {
  // console.log("Default Date:"+search.depart)
  // console.log("New Convert as per Format Date:"+(moment(new Date(search.depart)).format()).split("T")[0])
  // console.log("Old Code toISOString: "+search.depart.toISOString())
  if (search.type === "OneWay") {
    return [
      {
        departureDateTime: (moment(new Date(search.originDestinations[0].departureDateTime)).format()).split("T")[0],
        origin: search.originDestinations[0].origin.city_code,
        destination: search.originDestinations[0].destination.city_code,
      },
    ];
  } else if (search.type === "RoundTrip") {
    return [
      {
        //departureDateTime: search.depart.toISOString(),
        departureDateTime: (moment(new Date(search.originDestinations[0].departureDateTime)).format()).split("T")[0],
        origin: search.originDestinations[0].origin.city_code,
        destination: search.originDestinations[0].destination.city_code,
      },
      {
        //departureDateTime: search.return.toISOString(),      
        departureDateTime: (moment(new Date(search.originDestinations[0].returnDateTime)).format()).split("T")[0],
        origin: search.originDestinations[0].destination.city_code,
        destination: search.originDestinations[0].origin.city_code,
      },                                                                
    ];
  } else if (search.type === "Multidestination") {
    const originDestinations = [];
    originDestinations.push({
      //departureDateTime: search.depart.toISOString(),
      departureDateTime: (moment(new Date(search.depart)).format()).split("T")[0],
      origin: search.from.city_code,
      destination: search.to.city_code,
    });
    for (const destination of search.destinations) {
      originDestinations.push({
        //departureDateTime: destination.depart.toISOString(),
        departureDateTime: (moment(new Date(destination.depart)).format()).split("T")[0],
        origin: destination.from.city_code,
        destination: destination.to.city_code,
      });
    }
    return originDestinations;

  } else if (search.type === "MultiCity") {
    const originDestinations = search.originDestinations.map((item) => {
      return {
        departureDateTime: (moment(new Date(item.departureDateTime)).format()).split("T")[0],
        origin: item.origin.city_code,
        destination: item.destination.city_code,
      }
    });

    return originDestinations;
  }
}
