import React, { } from "react";
import "./Results.css";
import findAirportbyCode from "../../functions/findAirportByCode";
import findAirlinebyCode from "../../functions/findAirlineByCode";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import { Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";
import moment from "moment";
import getLayoverTime from "../../functions/getLayoverTime";
import findAirportNameByCode from "../../functions/findAirportNameByCode";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "../ToolTip/ToolTip";
import { Table, TableBody, TableCell } from "@mui/material";
import { OnlyAirlineLogo } from "../../functions/airlineLogo";
import { useTranslation } from "react-i18next";
export default function Tickets({ result, segGroups, baggages, source }) {

  const { t } = useTranslation();
  const getDeparture = (departure) => {
    let temp = new Date(
      departure.includes("+00:00") ? departure.replace("+00:00", "") : departure
    );
    return moment(temp).format("HH:mm");
  };

  const getDuration = (duration) => {
    return `${Math.floor(duration / 60)}hrs ${duration % 60}min`;
  };

  const images = require.context("../../airline_logo", true);

  const getDifferenceInDays = (dateA, dateB) => {
    const date1 = new Date(
      dateA.includes("+00:00") ? dateA.replace("+00:00", "") : dateA
    );
    const date2 = new Date(
      dateB.includes("+00:00") ? dateB.replace("+00:00", "") : dateB
    );
    const diffInMs = Math.abs(date1 - date2);
    const getDiffInDay = Math.round(diffInMs / (1000 * 60 * 60 * 24));
    let getDiffInDay_msg = "";
    if (getDiffInDay !== "" || getDiffInDay !== 0) {
      if (getDiffInDay === 1) {
        getDiffInDay_msg = "Arrives next day";
      } else if (getDiffInDay > 1) {
        getDiffInDay_msg = "Arrives " + getDiffInDay + " days later";
      }
    }
    return getDiffInDay_msg;
  };

  return (
    <div style={{ marginTop: "5px" }}>
      {result.segGroups.length > 0 &&
        result.segGroups.map((segGroup, segGroupIndex) => (
          <section className="col-12 " key={`${segGroupIndex + segGroup.origin}`}>
            <Row className="mx-0 pb-2 flt-bkg-heading-bg px-2">
              <div className="col-12 flt-bkg-main-heading px-2">
                {findAirportbyCode(segGroup.origin)} {" → "}
                {findAirportbyCode(segGroup.destination)}{" "}
                <small className="px-1">|</small>
                <small className="px-1">
                  {moment(
                    new Date(
                      segGroup.departureOn.includes("+00:00")
                        ? segGroup.departureOn.replace("+00:00", "")
                        : segGroup.departureOn
                    )
                  ).format("ddd,Do MMM  YYYY")}
                </small>
              </div>
            </Row>
            {segGroup.segments.map((item, index) => (
              <>
                <div>
                  <Table>
                    <TableBody>
                      <TableCell className="pnrTableCell">
                        <span className="pnrCell">{t("Airline PNR")}: {item.pnr}</span>
                        <span className="pnrCell">{t("GDS PNR")}: {result.pnr}</span>
                      </TableCell>
                    </TableBody>
                  </Table>
                </div>
                <Row className="eticket-flt-bkg-information-panel mx-0 pt-3 pb-2">
                  <div className="col-2">
                    <Row>
                      <div className="col-4 flt-ic px-2">
                        {item.mrkAirline !== undefined ? (
                          <OnlyAirlineLogo val={item.mrkAirline} />
                        ) : (
                          item.mrkAirline
                        )}
                      </div>
                      <div className="col-8 flt-info px-3">
                        <div className="flt-name">
                          {findAirlinebyCode(item.mrkAirline)}
                        </div>
                        <div className="flt-number">
                          {item.mrkAirline + "-" + item.flightNum}
                        </div>
                        <div className="flt-route-cls">{item.cabinClass}</div>
                        {item.mrkAirline !== item.opAirline &&
                          item.opAirline !== "" ? (
                          <div className="flt-operated-by">
                            {t("Operated by")} <br />
                            {findAirlinebyCode(item.opAirline)}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Row>
                  </div>
                  <div className="col-10">
                    <Row>
                      {baggages && baggages.length > 0 &&
                        baggages.filter(
                          (i) =>
                            i.paxType === "ADT" &&
                            i.cityPair === item.origin + item.destination &&
                            i.amount === 0
                        ).length ? (
                        baggages
                          .filter(
                            (i) =>
                              i.paxType === "ADT" &&
                              i.cityPair === item.origin + item.destination &&
                              i.amount === 0
                          )
                          .map((bag) =>
                            (bag.checkInBag &&
                              bag.checkInBag !== "Nil" &&
                              bag.checkInBag !== "0PC" &&
                              bag.checkInBag !== "0KG") ||
                              (bag.cabinBag &&
                                bag.cabinBag !== "Nil" &&
                                bag.cabinBag !== "0PC" &&
                                bag.cabinBag !== "0KG") ? (
                              <>
                                {" "}
                                <div className="col-4 flt-departure-info px-1">
                                  <div className="flt-airport-code pb-1">
                                    <span className="flt-origin">
                                      {item.origin}
                                    </span>
                                    <span className="flt-time">
                                      {getDeparture(item.departureOn)}
                                    </span>
                                  </div>
                                  <div className="flt-date">
                                    {moment(
                                      new Date(
                                        item.departureOn.includes("+00:00")
                                          ? item.departureOn.replace(
                                            "+00:00",
                                            ""
                                          )
                                          : item.departureOn
                                      )
                                    ).format("ddd,Do MMM  YYYY")}
                                  </div>
                                  <div className="flt-airport-nm">
                                    {findAirportNameByCode(item.origin)},{" "}
                                    {findAirportbyCode(item.origin)}
                                    {item.depTerminal ? (
                                      <>
                                        <br />
                                        {t("Terminal")} {item.depTerminal}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="col-2 flt-duration-info text-center px-1">
                                  {source !== "OfflineBooking" && (
                                    <>
                                      <AccessTimeIcon />
                                      <div className="flt-duration py-1">
                                        {getDuration(item.duration)}
                                      </div>
                                    </>
                                  )}
                                  {item.eqpType ? (
                                    <div className="flt-number">
                                      {t("EQP") + "-" + item.eqpType}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-4 flt-arrival-info px-1">
                                  <div className="flt-airport-code pb-1">
                                    <span className="flt-time">
                                      {getDeparture(item.arrivalOn)}
                                    </span>

                                    <span className="flt-destination">
                                      {item.destination}
                                    </span>
                                  </div>
                                  <div className="flt-date">
                                    <span className="badge flt-bkg-highlight-badge">

                                      {getDifferenceInDays(
                                        item.departureOn.includes("+00:00")
                                          ? item.departureOn.replace(
                                            "+00:00",
                                            ""
                                          )
                                          : item.departureOn,
                                        segGroup.segments[
                                          index
                                        ].arrivalOn.includes("+00:00")
                                          ? segGroup.segments[
                                            index
                                          ].arrivalOn.replace("+00:00", "")
                                          : segGroup.segments[index].arrivalOn
                                      )}
                                    </span>
                                  </div>
                                  <div className="flt-date">
                                    {moment(
                                      new Date(
                                        item.arrivalOn.includes("+00:00")
                                          ? item.arrivalOn.replace("+00:00", "")
                                          : item.arrivalOn
                                      )
                                    ).format("ddd,Do MMM  YYYY")}
                                  </div>
                                  <div className="flt-airport-nm">
                                    {findAirportNameByCode(item.destination)},{" "}
                                    {findAirportbyCode(item.destination)}
                                    {item.arrTerminal ? (
                                      <>
                                        <br />
                                        {t("Terminal")} {item.arrTerminal}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="col-2 ">
                                  <div className="flight-confirmation-details-item">
                                    {bag.checkInBag &&
                                      bag.checkInBag !== "Nil" &&
                                      bag.checkInBag !== "0PC" &&
                                      bag.checkInBag !== "0KG" && (
                                        <>
                                          <div className="flight-confirmation-details-item-text">
                                            {t("Check In")}{" "}
                                            <Tooltip
                                              tooltipText={bag.checkInBag}
                                            >
                                              <InfoOutlinedIcon
                                                style={{
                                                  fontSize: "12px",
                                                  color: "#db2a5f",
                                                }}
                                              />
                                            </Tooltip>{" "}
                                          </div>
                                          <div className="flight-confirmation-details-item-subtext ">
                                            <p
                                              style={{ display: "block" }}
                                              className="textElipsis"
                                            >
                                              {bag.checkInBag}
                                            </p>
                                          </div>
                                        </>
                                      )}
                                    {bag.cabinBag &&
                                      bag.cabinBag !== "Nil" &&
                                      bag.cabinBag !== "0PC" &&
                                      bag.cabinBag !== "0KG" && (
                                        <>
                                          <div className="flight-confirmation-details-item-text">
                                            {t("Cabin")}{" "}
                                            <Tooltip tooltipText={bag.cabinBag}>
                                              <InfoOutlinedIcon
                                                style={{
                                                  fontSize: "12px",
                                                  color: "#db2a5f",
                                                }}
                                              />
                                            </Tooltip>{" "}
                                          </div>
                                          <div className="flight-confirmation-details-item-subtext">
                                            <p
                                              style={{ display: "block" }}
                                              className="textElipsis"
                                            >
                                              {bag.cabinBag}
                                            </p>
                                          </div>
                                        </>
                                      )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="col-5 flt-departure-info px-1">
                                  <div className="flt-airport-code pb-1">
                                    <span className="flt-origin">
                                      {item.origin}
                                    </span>
                                    <span className="flt-time">
                                      {getDeparture(item.departureOn)}
                                    </span>
                                  </div>
                                  <div className="flt-date">
                                    {moment(
                                      new Date(
                                        item.departureOn.includes("+00:00")
                                          ? item.departureOn.replace(
                                            "+00:00",
                                            ""
                                          )
                                          : item.departureOn
                                      )
                                    ).format("ddd,Do MMM  YYYY")}
                                  </div>
                                  <div className="flt-airport-nm">
                                    {findAirportNameByCode(item.origin)},{" "}
                                    {findAirportbyCode(item.origin)}
                                    {item.depTerminal ? (
                                      <>
                                        <br />
                                        {t("Terminal")} {item.depTerminal}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="col-2 flt-duration-info text-center px-1">
                                  {source !== "OfflineBooking" && (
                                    <>
                                      <AccessTimeIcon />
                                      <div className="flt-duration py-1">
                                        {getDuration(item.duration)}
                                      </div>
                                    </>
                                  )}
                                  {item.eqpType ? (
                                    <div className="flt-number">
                                      {"EQP" + "-" + item.eqpType}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-5 flt-arrival-info px-1">
                                  <div className="flt-airport-code pb-1">
                                    <span className="flt-time">
                                      {getDeparture(item.arrivalOn)}
                                    </span>

                                    <span className="flt-destination">
                                      {item.destination}
                                    </span>
                                  </div>
                                  <div className="flt-date">
                                    <span className="badge flt-bkg-highlight-badge">

                                      {getDifferenceInDays(
                                        item.departureOn.includes("+00:00")
                                          ? item.departureOn.replace(
                                            "+00:00",
                                            ""
                                          )
                                          : item.departureOn,
                                        segGroup.segments[
                                          index
                                        ].arrivalOn.includes("+00:00")
                                          ? segGroup.segments[
                                            index
                                          ].arrivalOn.replace("+00:00", "")
                                          : segGroup.segments[index].arrivalOn
                                      )}
                                    </span>
                                  </div>
                                  <div className="flt-date">
                                    {moment(
                                      new Date(
                                        item.arrivalOn.includes("+00:00")
                                          ? item.arrivalOn.replace("+00:00", "")
                                          : item.arrivalOn
                                      )
                                    ).format("ddd,Do MMM  YYYY")}
                                  </div>
                                  <div className="flt-airport-nm">
                                    {findAirportNameByCode(item.destination)},{" "}
                                    {findAirportbyCode(item.destination)}
                                    {item.arrTerminal ? (
                                      <>
                                        <br />
                                        {t("Terminal")} {item.arrTerminal}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </>
                            )
                          )
                      ) : (
                        <>
                          <div className="col-5 flt-departure-info px-1">
                            <div className="flt-airport-code pb-1">
                              <span className="flt-origin">{item.origin}</span>
                              <span className="flt-time">
                                {getDeparture(item.departureOn)}
                              </span>
                            </div>
                            <div className="flt-date">
                              {moment(
                                new Date(
                                  item.departureOn.includes("+00:00")
                                    ? item.departureOn.replace("+00:00", "")
                                    : item.departureOn
                                )
                              ).format("ddd,Do MMM  YYYY")}
                            </div>
                            <div className="flt-airport-nm">
                              {findAirportNameByCode(item.origin)},{" "}
                              {findAirportbyCode(item.origin)}
                              {item.depTerminal ? (
                                <>
                                  <br />
                                  {t("Terminal")} {item.depTerminal}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-2 flt-duration-info text-center px-1">
                            {source !== "OfflineBooking" && (
                              <>
                                <AccessTimeIcon />
                                <div className="flt-duration py-1">
                                  {getDuration(item.duration)}
                                </div>
                              </>
                            )}
                            {item.eqpType ? (
                              <div className="flt-number">
                                {"EQP" + "-" + item.eqpType}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-5 flt-arrival-info px-1">
                            <div className="flt-airport-code pb-1">
                              <span className="flt-time">
                                {getDeparture(item.arrivalOn)}
                              </span>

                              <span className="flt-destination">
                                {item.destination}
                              </span>
                            </div>
                            <div className="flt-date">
                              <span className="badge flt-bkg-highlight-badge">

                                {getDifferenceInDays(
                                  item.departureOn.includes("+00:00")
                                    ? item.departureOn.replace("+00:00", "")
                                    : item.departureOn,
                                  segGroup.segments[index].arrivalOn.includes(
                                    "+00:00"
                                  )
                                    ? segGroup.segments[
                                      index
                                    ].arrivalOn.replace("+00:00", "")
                                    : segGroup.segments[index].arrivalOn
                                )}
                              </span>
                            </div>
                            <div className="flt-date">
                              {moment(
                                new Date(
                                  item.arrivalOn.includes("+00:00")
                                    ? item.arrivalOn.replace("+00:00", "")
                                    : item.arrivalOn
                                )
                              ).format("ddd,Do MMM  YYYY")}
                            </div>
                            <div className="flt-airport-nm">
                              {findAirportNameByCode(item.destination)},{" "}
                              {findAirportbyCode(item.destination)}
                              {item.arrTerminal ? (
                                <>
                                  <br />
                                  {t("Terminal")} {item.arrTerminal}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </Row>
                  </div>

                  {index !== segGroupIndex && (
                    <div className="col-12">
                      <Row className="justify-content-md-end flt-bkg-layover-info mx-0 py-2">
                        <div className="col-9 text-center layover-info">
                          <span className="layover-time py-1 px-2">
                            {t("Layover")}:{" "}
                            {getLayoverTime(
                              segGroup.segments[index],
                              segGroup.segments[index]
                            )}
                          </span>
                        </div>
                      </Row>
                    </div>
                  )}
                </Row>
              </>
            ))}
          </section>
        ))}
    </div>
  );
}
