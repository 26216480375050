import React, { useState } from "react";
import "./SearchUser.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Travel from "../../../assets/travel.svg";
import { useTranslation } from "react-i18next";
export default function AddClientCard() {
  const [configurationTitle, setConfigurationTitle] = useState("");
  const [searchSupplier, setSearchSupplier] = useState("");
  const [searchPcc, setSearchPcc] = useState("");
  const [cabinClass, setCabinClass] = useState("");
  const [airlines, setAirlines] = useState("");
  const [bookingSupplier, setBookingSupplier] = useState("");
  const [bookingPcc, setBookingPcc] = useState("");
  const { t } = useTranslation();
  return (
    <div className="add-configuration-card-container">
      <div className="add-configuration-card-title">Search Configuration</div>
      <div className="create-configuration-contents-container">
        <div className="create-configuration-contents">
          <div className="create-configuration-name-container">
            <ValidatorForm onError={(errors) => console.log(errors)}>
              <TextValidator
                variant="outlined"
                label={t("Configuration Title")}
                size="small"
                color="secondary"
                value={configurationTitle}
                onChange={(event) => setConfigurationTitle(event.target.value)}
              />
            </ValidatorForm>
          </div>
          <div className="add-configuration-if-items-container">
            {/* <div className="add-configuration-if-item margin-right-16">
              <div className="add-configuration-if-container">IF</div>
            </div> */}
            <div className="add-configuration-if-item flex-1-input  margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label={t("Search Supplier")}
                  size="small"
                  color="secondary"
                  value={searchSupplier}
                  onChange={(event) => setSearchSupplier(event.target.value)}
                />
              </ValidatorForm>
            </div>
            <div className="add-configuration-if-item flex-1-input margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label={t("Search PCC")}
                  size="small"
                  color="secondary"
                  value={searchPcc}
                  onChange={(event) => setSearchPcc(event.target.value)}
                />
              </ValidatorForm>
            </div>
            <div className="add-configuration-if-item flex-1-input margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label={t("Cabin Class")}
                  size="small"
                  color="secondary"
                  value={cabinClass}
                  onChange={(event) => setCabinClass(event.target.value)}
                />
              </ValidatorForm>
            </div>
            <div className="add-configuration-if-item flex-1-input ">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label={t("Airlines")}
                  size="small"
                  color="secondary"
                  value={airlines}
                  onChange={(event) => setAirlines(event.target.value)}
                />
              </ValidatorForm>
            </div>
          </div>

          <div className="add-configuration-if-items-container">
            {/* <div className="add-configuration-if-item margin-right-16">
              <div className="add-configuration-if-container">THEN</div>
            </div> */}
            <div className="add-configuration-if-item flex-1-input margin-right-16">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label={t("Booking Supplier")}
                  size="small"
                  color="secondary"
                  value={bookingSupplier}
                  onChange={(event) => setBookingSupplier(event.target.value)}
                />
              </ValidatorForm>
            </div>
            <div className="add-configuration-if-item flex-1-input">
              <ValidatorForm onError={(errors) => console.log(errors)}>
                <TextValidator
                  variant="outlined"
                  label={t("Booking PCC")}
                  size="small"
                  color="secondary"
                  value={bookingPcc}
                  onChange={(event) => setBookingPcc(event.target.value)}
                />
              </ValidatorForm>
            </div>
          </div>
          <div className="add-configuration-button-container">
            <div className="add-configuration-button">{t("Search Configuration")}</div>
          </div>
        </div>
        <div className="create-configuration-image">
          <img src={Travel} height="100%"></img>
        </div>
      </div>
    </div>
  );
}
