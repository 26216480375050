import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import months from "../../data/Months";
import Days from "../../data/Days";

const ModifySearchItems = ({ search, itemIndex }) => {
    const { t } = useTranslation();

    const getMultidestinationDestinations = () => {
        let destinationString = "";
        destinationString = destinationString.concat(`${search?.from?.city} to `);
        const lastDestination =
            search?.destinations[search?.destinations?.length - 1].to?.city;
        destinationString = destinationString.concat(`${lastDestination}`);
        return destinationString + " ";
    };

    const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    };

    const getMultidestinationViaDestinations = () => {
        let viaDestinationArray = [];
        viaDestinationArray.push(search?.to?.city);
        for (let [index, destination] of search?.destinations?.entries()) {
            if (index === search?.destinations?.length - 1) {
                viaDestinationArray.push(destination?.from?.city);
            } else {
                viaDestinationArray.push(destination?.from?.city);
                viaDestinationArray.push(destination?.to?.city);
            }
        }
        return (
            <span className='opacity-5'>
                via {viaDestinationArray.filter(onlyUnique).join().replace(/,/g, ", ")}
            </span>
        );
    };
    return (
        <Grid container className='height-100 mb-2'>

            <Grid item xs={2}>
                {itemIndex === 0 &&
                    <div className='search-info-container'>
                        <div className='search-info-title'>{t("Trip Type")}</div>
                        <div className='search-info-description trip-type-modify-text'>
                            {t(search?.type)}
                        </div>
                    </div>
                }
            </Grid>

            <Grid item xs={search?.type === "MultiCity" ? 3: 2}>
                <div className='search-info-container'>
                    <div className='search-info-title'>{t('From')}</div>
                    <div className='search-info-description'>
                        {search?.originDestinations[itemIndex]?.origin.city}, {search?.originDestinations[itemIndex]?.origin.country}
                    </div>
                </div>
            </Grid>


            <Grid item xs={search?.type === "MultiCity" ? 3: 2}>
                <div className='search-info-container'>
                    <div className='search-info-title'>{t('To')}</div>
                    <div className='search-info-description'>
                        {search?.originDestinations[itemIndex]?.destination.city}, {search?.originDestinations[itemIndex]?.destination.country}
                    </div>
                </div>
            </Grid>


            {search?.type !== "Multidestination" && (
                <Grid item xs={2}>
                    <div className='search-info-container'>
                        <div className='search-info-title'>{t("Depart")}</div>
                        {
                            new Date(search?.originDestinations[itemIndex]?.departureDateTime) && <div className='search-info-description'>
                                {Days[new Date(search?.originDestinations[itemIndex]?.departureDateTime)?.getDay()]},{" "}
                                {months[new Date(search?.originDestinations[itemIndex]?.departureDateTime)?.getMonth()]} {new Date(search?.originDestinations[itemIndex]?.departureDateTime)?.getDate()}
                                , {new Date(search?.originDestinations[itemIndex]?.departureDateTime)?.getFullYear().toString().substring(2, 4)}
                            </div>
                        }

                    </div>  
                </Grid>
            )}
            {search?.type !== "MultiCity" && (
                <Grid item xs={2}>
                    <div className='search-info-container'>
                        <div className='search-info-title'>{t("Return")}</div>
                        {search?.type === "RoundTrip" && new Date(search?.originDestinations[itemIndex]?.returnDateTime) && (
                            <div className='search-info-description'>
                                {Days[new Date(search?.originDestinations[itemIndex]?.returnDateTime)?.getDay()]},{" "}
                                {months[new Date(search?.originDestinations[itemIndex]?.returnDateTime)?.getMonth()]}{" "}
                                {new Date(search?.originDestinations[itemIndex]?.returnDateTime)?.getDate()},{" "}
                                {new Date(search?.originDestinations[itemIndex]?.returnDateTime)?.getFullYear().toString().substring(2, 4)}
                            </div>
                        )}
                        {search?.type === "OneWay" && (
                            <div className='search-info-description opacity-5'>
                                {t("Book Return Ticket")}
                            </div>
                        )}
                    </div>
                </Grid>
            )}
            {search?.type === "Multidestination" && (
                <Grid item xs={8}>
                    <div className='search-info-container'>
                        <div className='search-info-title'>From</div>
                        <div className='search-info-description'>
                            {getMultidestinationDestinations()}
                            {getMultidestinationViaDestinations()}
                        </div>
                    </div>
                </Grid>
            )}
            <Grid item xs={2}>
                <div className='search-info-container'>
                    <div className='search-info-title'>{t("Pax")} & {t("Class")}</div>
                    {search?.adult + search?.infant + search?.children > 1 ? (
                        <div className='search-info-description'>
                            {search?.adult + search?.infant + search?.children} {t("Travellers")},{" "}
                            {t(search?.class)}
                        </div>
                    ) : (
                        <div className='search-info-description'>
                            {search?.adult + search?.infant + search?.children} {t("Traveller")},{" "}
                            {t(search?.class)}
                        </div>
                    )}
                </div>
            </Grid>
        </Grid>
    )
}

export default ModifySearchItems;