import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import { IconButton } from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { TextField, InputLabel } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import TablePagination from "@material-ui/core/TablePagination";
import { CircularProgress } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import RiseLoading from "../Loader/RiseLoader";
import { CSVLink } from "react-csv";
import { encryptData } from "../../providers/dataEncryptDecryptor";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";

function Alerts(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </>
    );
}
export default function OrderTraceReport() {
    const today = dayjs();
    const csvLink = useRef();


    const [fromDate, setFromDate] = useState(today.$d);
    const [toDate, setTodate] = useState(today.$d);
    const [traceReportData, setTraceReportData] = useState([]);
    const [ifNoDataExport, setIfNoDataExport] = useState(false);
    const [transactionData, setTransactionData] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(1);
    const [queueTableData, setQueueTableData] = useState("");
    const location = useLocation();
    const history = useHistory();


    const validateDateFilter = () => {
        if (toDate < fromDate) {
            alert("To Date should be greater than or equal to From Date");
        } else {
            getOrderTraceReport();
        }
    };
    const handleChangePage = (event, newPage, page, rowsPerPage) => {
        setPage(newPage + 1);
    };

    useEffect(() => {
        const data = localStorage.getItem('orderTraceReportData');
        if (data) {
            const stateData = JSON.parse(data);
            setQueueTableData(stateData.queueTable);
            getOrderTraceReport();
        }
    }, []);

    useEffect(() => {
        if (transactionData) {
            const startIndex = (page - 1) * rowsPerPage;
            const endIndex = page * rowsPerPage;
            setTableData(transactionData.slice(startIndex, endIndex));
        }
    }, [transactionData, page]);

    const getOrderTraceReport = async () => {
        setShowLoader(true);

        const headers = {
            "Content-Type": "application/json",
        };

        const orderTraceReportData = JSON.parse(localStorage.getItem('orderTraceReportData'));
        const dateFrom = orderTraceReportData?.dateFrom;
        const dateTo = orderTraceReportData?.dateTo;

        const formatDateString = (date) => {
            return date ? moment(date).format("YYYY-MM-DD") + "T00:00:00.000Z" : "";
        };

        const body = {
            filters: [
                {
                    field: "DateFrom",
                    value: formatDateString(dateFrom),
                    displayvalue: dateFrom,
                },
                {
                    field: "DateTo",
                    value: formatDateString(dateTo),
                    displayvalue: dateTo,
                },
            ],
        };

        const obj = {
            fromDate: fromDate,
            toDate: toDate,
        };

        const url = orderTraceReportData?.queueTable === "queueTableData"
            ? "Flights/Booking/GetBooking/v1/GetAzureDiscrepency"
            : "Flights/Booking/GetBooking/v1/GetOrderTraceReport";

        const requestBody = orderTraceReportData?.queueTable === "queueTableData" ? body : obj;

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_CORE_URL}${url}`,
                requestBody,
                { headers }
            );

            if (response?.data?.orderTraceInfos?.length > 0) {
                setIfNoDataExport(false);
                const temp = response.data.orderTraceInfos;
                const array = temp.map(item => ({
                    "Order Ref No": item.gfsRefNo,
                    "TraceId": item.traceId,
                }));

                setTableData(temp);
                setTransactionData(temp);
                setTraceReportData(array);

                if (array.length > 0) {
                    setTimeout(() => {
                        csvLink.current.link.click();
                        setShowLoader(false);
                    }, 3000);
                } else {
                    setShowLoader(false);
                }
            } else {
                setShowLoader(false);
                setIfNoDataExport(true);
            }
        } catch (err) {
            setShowLoader(false);
            setIfNoDataExport(true);
            console.log(err);
        }
    };



    const handleGfsidClick = (gfsID) => {
        sessionStorage.setItem("orderId", encryptData(gfsID));
        let link = "/flight/view-booked-tickets";
        window.open(link, "_blank");
    }

    return (

        <div>
            {
                showLoader ?
                    <div className="col-md-12">
                        <RiseLoading isQueueTable={true} />
                    </div> : ''
            }
            {
                ifNoDataExport ?
                    <Snackbar
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={ifNoDataExport}
                        autoHideDuration={4000}
                        onClose={() => { setIfNoDataExport(false) }}
                    >
                        <Alerts onClose={() => { setIfNoDataExport(false) }} severity="error">
                            {`No Data To Export`}
                        </Alerts>
                    </Snackbar> : ''
            }
            <Grid className="p-4 main-grid" container>
                <Grid xs={12}>
                    <h2 className="fw-bold">
                        {((JSON.parse(localStorage.getItem('orderTraceReportData'))?.queueTable) === "queueTableData") ? "Discrepency Report" : "Order Trace Report"}
                    </h2>
                </Grid>
                {!((JSON.parse(localStorage.getItem('orderTraceReportData'))?.queueTable) === "queueTableData") && <Grid sx={{ flexGrow: 1, display: "flex", justifyContent: 'center', alignItems: 'center', background: '#fff', padding: '2vh 0' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            className="bg-light me-1 rounded orderTracePicker"
                            style={{ maxwidth: '10vw' }}
                            defaultValue={today}
                            disableFuture
                            value={dayjs(fromDate)}
                            onChange={(newValue) => {
                                setFromDate(newValue.$d ? newValue.$d : null);
                            }}
                            format="DD/MM/YYYY"
                            label="From Date"
                        />
                        <DatePicker
                            className="bg-light me-1 rounded orderTracePicker"
                            defaultValue={today}
                            minDate={dayjs(fromDate)}
                            disableFuture
                            value={dayjs(toDate)}
                            onChange={(newValue) => {
                                setTodate(newValue.$d ? newValue.$d : null);
                            }}
                            format="DD/MM/YYYY"
                            label="To Date"
                        />
                    </LocalizationProvider>

                    <CSVLink
                        className="hidden"
                        ref={csvLink}
                        target="_blank"
                        data={traceReportData}
                        filename="Order Trace Report"
                    />

                    <Button
                        className="rounded"
                        style={{ backgroundColor: "#9f155a" }}
                        onClick={validateDateFilter}
                        variant="contained"
                    >
                        Download
                    </Button>
                </Grid>}
                {((JSON.parse(localStorage.getItem('orderTraceReportData'))?.queueTable) === "queueTableData") && <CSVLink
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                    data={traceReportData}
                    filename="Order Trace Report"
                />}
                {
                    tableData && tableData.length > 0 ?

                        <Grid className="m-3 w-100">
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead sx={{ backgroundColor: "#9f155a" }}>
                                        <TableRow>
                                            <TableCell colSpan={2} className="text-white opacity-100" align="center">
                                                Order Reference Id
                                            </TableCell>
                                            <TableCell colSpan={5} className="text-white opacity-100" align="center">
                                                Trace Id
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData.map((row) => (
                                            <TableRow
                                                key={row.gfsRefNo}
                                            >
                                                <TableCell colSpan={2} align="center" style={{ cursor: "pointer" }} onClick={() => handleGfsidClick(row.gfsRefNo)}>{row.gfsRefNo}</TableCell>
                                                <TableCell colSpan={5} align="center">{row.traceId}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[100]}
                                component="div"
                                count={transactionData?.length}
                                rowsPerPage="100"
                                page={page - 1}
                                onChangePage={(event, newPage) =>
                                    handleChangePage(event, newPage, page, rowsPerPage)
                                }
                                ActionsComponent={TablePaginationActions}
                            />

                            {!showLoader && transactionData.length === 0 && (
                                <>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "50px",
                                            color: "grey",
                                        }}
                                    >
                                        <SearchIcon fontSize="large"></SearchIcon>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "10px",
                                            color: "grey",
                                        }}
                                    >
                                        <span>No data found</span>
                                    </div>
                                </>
                            )}
                            {showLoader && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "50px",
                                        color: "grey",
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            )}
                        </Grid>
                        : ''}
            </Grid>
        </div>

    )

}