import React, { useState, Fragment } from "react";
import "./TicketDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import months from "../../../data/Months";
import Days from "../../../data/Days";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import IconButton from "@material-ui/core/IconButton";
import findAirlinebyCode from "../../../functions/findAirlineByCode";
import FlightDetails from "./FlightDetails/FlightDetails";
import { useHistory } from "react-router";
import getTotalFare from "../../../functions/getTotalFare";
import useHTMLTooltips from "../../../hooks/useHTMLTooltips";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import { AirlineLogo } from "../../../functions/airlineLogo";
export default function TicketDrawer({
  activeSearch,
  selectedTicket,
  toggleDrawer,
  traceId,
  clientId,
  currencyCode,
}) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const HtmlTooltip = useHTMLTooltips;
  //TODO for NDC Fare
  const getAdultPrice = () => {
    let adultTotalPrice = 0;
    const ticketDetails = selectedTicket;
    if (ticketDetails && ticketDetails.fareGroups[0] !== undefined) {
      ticketDetails.fareGroups[0].fares.forEach((fare) => {
        if (fare.paxType === "ADT") {
          adultTotalPrice = adultTotalPrice + fare.base;
        }
      });
    }
    return adultTotalPrice;
  };

  const getChildrenPrice = () => {
    let childrenTotalPrice = 0;
    const ticketDetails = selectedTicket;
    if (ticketDetails && ticketDetails.fareGroups[0] !== undefined) {
      ticketDetails.fareGroups[0].fares.forEach((fare) => {
        if (fare.paxType === "CHD") {
          childrenTotalPrice = childrenTotalPrice + fare.base;
        }
      });
    }
    return childrenTotalPrice;
  };

  const getInfantPrice = () => {
    let infantTotalPrice = 0;
    const ticketDetails = selectedTicket;
    if (ticketDetails && ticketDetails.fareGroups[0] !== undefined) {
      ticketDetails.fareGroups[0].fares.forEach((fare) => {
        if (fare.paxType === "INF") {
          infantTotalPrice = infantTotalPrice + fare.base;
        }
      });
    }
    return infantTotalPrice;
  };

  const getAdultTaxPrice = () => {
    let adultTotalPrice = 0;
    const ticketDetails = selectedTicket;
    if (ticketDetails && ticketDetails.fareGroups[0] !== undefined) {
      ticketDetails.fareGroups[0].fares.forEach((fare) => {
        if (fare.paxType === "ADT") {
          fare.taxes.forEach((item) => {
            adultTotalPrice = adultTotalPrice + item.amt;
          });
        }
      });
    }
    return adultTotalPrice;
  };

  const getChildrenTaxPrice = () => {
    let childrenTotalPrice = 0;
    const ticketDetails = selectedTicket;
    if (ticketDetails && ticketDetails.fareGroups[0] !== undefined) {
      ticketDetails.fareGroups[0].fares.forEach((fare) => {
        if (fare.paxType === "CHD") {
          fare.taxes.forEach((item) => {
            childrenTotalPrice = childrenTotalPrice + item.amt;
          });
        }
      });
    }
    return childrenTotalPrice;
  };

  const getInfantTaxPrice = () => {
    let infantTotalPrice = 0;
    const ticketDetails = selectedTicket;
    if (ticketDetails && ticketDetails.fareGroups[0] !== undefined) {
      ticketDetails.fareGroups[0].fares.forEach((fare) => {
        if (fare.paxType === "INF") {
          fare.taxes.forEach((item) => {
            infantTotalPrice = infantTotalPrice + item.amt;
          });
        }
      });
    }
    return infantTotalPrice;
  };

  const getMultiCityViaDestinations = () => {
    let viaDestinationArray = [];
    viaDestinationArray.push(activeSearch?.to?.city);
    for (let [index, destination] of activeSearch?.destinations?.entries()) {
      if (index === activeSearch?.destinations?.length - 1) {
        viaDestinationArray.push(destination.from.city);
      } else {
        viaDestinationArray.push(destination?.from?.city);
        viaDestinationArray.push(destination?.to?.city);
      }
    }
    return `via ${viaDestinationArray
      .filter(onlyUnique)
      .join()
      .replace(/,/g, ", ")}`;
  };

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const getTotalTax = (taxes) => {
    let total = 0;
    taxes.forEach((item) => {
      total = total + item.amt;
    });
    return total;
  };

  const getAdultBaggage = (baggages) => {
    const values = baggages.filter((item) => {
      return item.paxType === "ADT";
    });
    return values.length > 0 ? values[0].checkInBag : "";
  };

  const getChildBaggage = (baggages) => {
    const values = baggages.filter((item) => {
      return item.paxType === "CHD";
    });
    return values.length > 0 ? values[0].checkInBag : "";
  };

  const getInfantGroups = (baggages) => {
    const values = baggages.filter((item) => {
      return item.paxType === "INF";
    });
    return values.length > 0 ? values[0].checkInBag : "";
  };

  // console.log(114477," selectedTicket => ",selectedTicket);

  const images = require.context("../../../airline_logo", true);

  const miniRulesAvailability =
    selectedTicket?.fareGroups[0]?.miniRules != undefined
      ? selectedTicket.fareGroups[0].miniRules.length > 0
        ? true
        : false
      : false;
  const beforeCancel = miniRulesAvailability
    ? selectedTicket.fareGroups[0].miniRules.filter(
      (entry) => entry?.apply === "Before" && entry?.cancelAllowed
    )
    : [];
  const afterCancel = miniRulesAvailability
    ? selectedTicket.fareGroups[0].miniRules.filter(
      (entry) => entry?.apply === "After" && entry?.cancelAllowed
    )
    : [];

  const beforeChange = miniRulesAvailability
    ? selectedTicket.fareGroups[0].miniRules.filter(
      (entry) => entry?.apply === "Before" && entry?.changeAllowed
    )
    : [];
  const afterChange = miniRulesAvailability
    ? selectedTicket.fareGroups[0].miniRules.filter(
      (entry) => entry?.apply === "After" && entry?.changeAllowed
    )
    : [];

  return (
    <div className="ticket-drawer-container search-result-drawer-container">
      <div className="drawer-total-container">
        <div className="drawer-total-container-price">
          {selectedTicket && (
            <div className="drawer-total-price">
              {currencyCode}{" "}
              {getTotalFare(
                selectedTicket.fareGroups[0],
                activeSearch?.adult,
                activeSearch?.children,
                activeSearch?.infant
              ).total.toFixed(2)}
            </div>
          )}
          {selectedTicket && (
            <div className="drawer-total-price-per-description">
              for{" "}
              {Number(activeSearch?.adult) +
                Number(activeSearch?.infant) +
                Number(activeSearch?.children)}{" "}
              travellers
            </div>
          )}
          {/* {selectedTicket && (
            <div className="drawer-total-price-all-description">
              {currencyCode}{" "}
              {(Number(activeSearch?.adult) +
                Number(activeSearch?.infant) +
                Number(activeSearch?.children)) *
                (selectedTicket.fareGroups[0].fares[0].base +
                  getTotalTax(
                    selectedTicket.fareGroups[0].fares[0].taxes
                  ))}{" "}
              for{" "}
              {Number(activeSearch?.adult) +
                Number(activeSearch?.infant) +
                Number(activeSearch?.children)}{" "}
              travellers
            </div>
          )} */}
        </div>
        {selectedTicket && (
          <div className="drawer-select-button">
            <div
              className="drawer-modify-search-block"
              onClick={() => {
                history.push({
                  pathname: "/flight/review-booking",
                  state: {
                    activeSearch: activeSearch,
                    traceId: traceId,
                    purchaseId: selectedTicket.fareGroups[0].purchaseId,
                    clientId: clientId,
                  },
                });
              }}
            >
              Continue
            </div>
          </div>
        )}
      </div>
      {selectedTicket && (
        <div className="close-button-container">
          <CloseIcon
            className="icon-fill pointer"
            fontSize="medium"
            onClick={toggleDrawer(false)}
          ></CloseIcon>
        </div>
      )}
      <div className="drawer-ticket-details-container">
        {selectedTicket && (
          <div className="drawer-from-to-header">
            {activeSearch && activeSearch?.type === "OneWay"
              ? `${activeSearch?.from?.city} to ${activeSearch?.to?.city}`
              : ""}
            {activeSearch && activeSearch?.type === "RoundTrip"
              ? `${activeSearch?.from?.city} to ${activeSearch?.to?.city}, and back`
              : ""}
            {activeSearch && activeSearch?.type === "Multidestination"
              ? `${activeSearch?.from?.city} to ${activeSearch?.destinations[
                activeSearch?.destinations.length - 1
              ].to.city
              }`
              : ""}
            {activeSearch && activeSearch?.type === "Multidestination" && (
              <div className="drawer-via-header">
                {getMultiCityViaDestinations()}
              </div>
            )}
          </div>
        )}
        {selectedTicket && (
          <div className="drawer-search-flight">
            <div className="drawer-search-airline br-result-item">
              <div className="airline-container pr-8">
                <div className="airline-icon">
                  {selectedTicket.airline !== undefined ? (
                    <AirlineLogo val={selectedTicket.airline} h={24} w={24} />
                  ) : (
                    <FlightTakeoffIcon
                      className="primary-fill"
                      fontSize="small"
                    />
                  )}
                </div>
                <div className="airline-title ">
                  {" "}
                  {findAirlinebyCode(selectedTicket.airline)}
                  {""}
                  <span className="ml-12">{selectedTicket.airline}</span>
                </div>
              </div>
            </div>
            <div className="drawer-search-date">
              {Days[activeSearch?.depart?.getDay()]},{" "}
              {months[activeSearch?.depart?.getMonth()]}{" "}
              {activeSearch?.depart?.getDate()},{" "}
              {activeSearch?.depart?.getFullYear().toString().substring(2, 4)}
            </div>
          </div>
        )}
        {selectedTicket && (
          <div className="show-flight-details">
            <div
              className="view-flight-details-container pointer"
              onClick={() => setOpen(!open)}
            >
              <IconButton
                aria-label="expand row"
                size="small"
                className="airline-detail-expand-button"
              >
                {open ? (
                  <KeyboardArrowUpRoundedIcon
                    fontSize="small"
                    className="airline-detail-expand-icon-fill"
                  />
                ) : (
                  <KeyboardArrowDownRoundedIcon
                    fontSize="small"
                    className="airline-detail-expand-icon-fill"
                  />
                )}
              </IconButton>
              <div className="show-flight-details-text">Flight Details</div>
            </div>
          </div>
        )}
        {selectedTicket && (
          <div>
            <FlightDetails
              open={open}
              result={selectedTicket}
              activeSearch={activeSearch}
            ></FlightDetails>
          </div>
        )}
        {selectedTicket && <div className="bb-1 h1"></div>}
        {selectedTicket && (
          <div className="drawer-fare-header mt-24">Fare Details</div>
        )}
        {selectedTicket && (
          <div className="drawer-fare-container drawer-fare-total">
            <div className="text-bold">
              {currencyCode}{" "}
              {getTotalFare(
                selectedTicket.fareGroups[0],
                activeSearch?.adult,
                activeSearch?.children,
                activeSearch?.infant
              ).total.toFixed(2)}
            </div>
            <div className="refundable-ticket">
              {selectedTicket.fareGroups[0].refundable
                ? "Refundable"
                : "Non-Refundable"}
            </div>
            <div className="bb-1 h1 mt-16"></div>
            <div className="mt-16 drawer-fare-breakup-container">
              <div className="drawer-base-fare">
                <div className="drawer-fare-description">Base Fare</div>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <div>
                        <table className="table">
                          <tr>
                            <th className="th ta-left">Travellers</th>
                            <th className="th ta-right">Price</th>
                            <th className="th ta-right">Subtotal</th>
                          </tr>
                          {activeSearch?.adult > 0 && (
                            <tr>
                              <td className="td ta-left">
                                {activeSearch?.adult} x Adult(s)
                              </td>
                              <td className="td ta-right">
                                {currencyCode} {getAdultPrice().toFixed(2)}
                              </td>
                              <td className="td ta-right">
                                {currencyCode}{" "}
                                {(activeSearch?.adult * getAdultPrice()).toFixed(
                                  2
                                )}
                              </td>
                            </tr>
                          )}
                          {activeSearch?.children > 0 && (
                            <tr>
                              <td className="td ta-left">
                                {activeSearch?.children} x Children
                              </td>
                              <td className="td ta-right">
                                {currencyCode} {getChildrenPrice().toFixed(2)}
                              </td>
                              <td className="td ta-right">
                                {currencyCode}{" "}
                                {(
                                  activeSearch?.children * getChildrenPrice()
                                ).toFixed(2)}
                              </td>
                            </tr>
                          )}
                          {activeSearch?.infant > 0 && (
                            <tr>
                              <td className="td ta-left">
                                {activeSearch?.infant} x Infant
                              </td>
                              <td className="td ta-right">
                                {currencyCode} {getInfantPrice().toFixed(2)}
                              </td>
                              <td className="td ta-right">
                                {currencyCode}{" "}
                                {(
                                  activeSearch?.infant * getInfantPrice()
                                ).toFixed(2)}
                              </td>
                            </tr>
                          )}
                        </table>
                      </div>
                    </React.Fragment>
                  }
                  placement="top-start"
                >
                  <div className="drawer-fare-description">
                    {currencyCode}{" "}
                    {getTotalFare(
                      selectedTicket.fareGroups[0],
                      activeSearch?.adult,
                      activeSearch?.children,
                      activeSearch?.infant
                    ).totalBaseFare.toFixed(2)}
                  </div>
                </HtmlTooltip>
              </div>
              <div className="drawer-tax-fare mt-8 mb-8">
                <div className="drawer-fare-description">Tax</div>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <div>
                        <table className="table">
                          <tr>
                            <th className="th ta-left">Travellers</th>
                            <th className="th ta-right">Price</th>
                            <th className="th ta-right">Subtotal</th>
                          </tr>
                          {activeSearch?.adult > 0 && (
                            <tr>
                              <td className="td ta-left">
                                {activeSearch?.adult} x Adult(s)
                              </td>
                              <td className="td ta-right">
                                {currencyCode} {getAdultTaxPrice().toFixed(2)}
                              </td>
                              <td className="td ta-right">
                                {currencyCode}{" "}
                                {(
                                  activeSearch?.adult * getAdultTaxPrice()
                                ).toFixed(2)}
                              </td>
                            </tr>
                          )}
                          {activeSearch?.children > 0 && (
                            <tr>
                              <td className="td ta-left">
                                {activeSearch?.children} x Children
                              </td>
                              <td className="td ta-right">
                                {currencyCode}{" "}
                                {getChildrenTaxPrice().toFixed(2)}
                              </td>
                              <td className="td ta-right">
                                {currencyCode}{" "}
                                {(
                                  activeSearch?.children * getChildrenTaxPrice()
                                ).toFixed(2)}
                              </td>
                            </tr>
                          )}
                          {activeSearch?.infant > 0 && (
                            <tr>
                              <td className="td ta-left">
                                {activeSearch?.infant} x Infant
                              </td>
                              <td className="td ta-right">
                                {currencyCode} {getInfantTaxPrice().toFixed(2)}
                              </td>
                              <td className="td ta-right">
                                {currencyCode}{" "}
                                {(
                                  activeSearch?.infant * getInfantTaxPrice()
                                ).toFixed(2)}
                              </td>
                            </tr>
                          )}
                        </table>
                      </div>
                    </React.Fragment>
                  }
                  placement="top-start"
                >
                  <div className="drawer-fare-description">
                    {currencyCode}{" "}
                    {getTotalFare(
                      selectedTicket.fareGroups[0],
                      activeSearch?.adult,
                      activeSearch?.children,
                      activeSearch?.infant
                    ).totalTaxFare.toFixed(2)}
                  </div>
                </HtmlTooltip>
              </div>
            </div>
            {/* <div className="bb-1 h1 mt-16"></div>
            <div className="drawer-base-fare mt-16">
              <div className="drawer-fare-description opacity-9">
                Check in Baggage:
              </div>
            </div>
            <div className="mt-16 drawer-fare-breakup-container">
              {getAdultBaggage(selectedTicket.fareGroups[0].baggages).length >
                0 && (
                <div className="drawer-base-fare">
                  <div className="drawer-fare-description">Adult</div>
                  <div className="drawer-fare-description">
                    {getAdultBaggage(selectedTicket.fareGroups[0].baggages)}
                  </div>
                </div>
              )}
              {getChildBaggage(selectedTicket.fareGroups[0].baggages).length >
                0 && (
                <div className="drawer-tax-fare mt-8 mb-8">
                  <div className="drawer-fare-description">Child</div>
                  <div className="drawer-fare-description">
                    {getChildBaggage(selectedTicket.fareGroups[0].baggages)}
                  </div>
                </div>
              )}
              {getInfantGroups(selectedTicket.fareGroups[0].baggages).length >
                0 && (
                <div className="drawer-tax-fare mt-8 mb-8">
                  <div className="drawer-fare-description">Infant</div>
                  <div className="drawer-fare-description">
                    {getInfantGroups(selectedTicket.fareGroups[0].baggages)}
                  </div>
                </div>
              )}
            </div> */}
          </div>
        )}
        {selectedTicket && <div className="bb-1 h1"></div>}
        {selectedTicket && (
          <div className="drawer-fare-header mt-24">Cancellation</div>
        )}
        {selectedTicket && (
          <div className="drawer-cancellation pl-24 pr-24">
            <div className="mt-16 drawer-fare-breakup-container">
              <div className="drawer-base-fare">
                <table className="drawer-fare-description mini-fares-rules-table">
                  <tr>
                    <th></th>
                    {selectedTicket.adtNum >= 1 ? (
                      <th className="right">ADT</th>
                    ) : (
                      ""
                    )}
                    {selectedTicket.chdNum >= 1 ? (
                      <th className="right">CHD</th>
                    ) : (
                      ""
                    )}
                    {selectedTicket.infNum >= 1 ? (
                      <th className="right">INF</th>
                    ) : (
                      ""
                    )}
                  </tr>
                  <tr>
                    <th className="left">Before Trip</th>
                    {beforeCancel.length > 0 ? (
                      beforeCancel.map((item) => (
                        <>
                          <th className="right">
                            {item?.canAmt != undefined
                              ? currencyCode + "  " + item?.canAmt
                              : "-"}
                          </th>
                        </>
                      ))
                    ) : (
                      <>
                        {selectedTicket.adtNum >= 1 ? (
                          <th className="right">Non-refundable</th>
                        ) : (
                          ""
                        )}
                        {selectedTicket.chdNum >= 1 ? (
                          <th className="right">Non-refundable</th>
                        ) : (
                          ""
                        )}
                        {selectedTicket.infNum >= 1 ? (
                          <th className="right">Non-refundable</th>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </tr>
                  <tr>
                    <th className="left">After Trip</th>
                    {afterCancel.length > 0 ? (
                      afterCancel.map((item) => (
                        <>
                          <th className="right">
                            {item?.canAmt != undefined
                              ? currencyCode + "  " + item?.canAmt
                              : "-"}
                          </th>
                        </>
                      ))
                    ) : (
                      <>
                        {selectedTicket.adtNum >= 1 ? (
                          <th className="right">Non-refundable</th>
                        ) : (
                          ""
                        )}
                        {selectedTicket.chdNum >= 1 ? (
                          <th className="right">Non-refundable</th>
                        ) : (
                          ""
                        )}
                        {selectedTicket.infNum >= 1 ? (
                          <th className="right">Non-refundable</th>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </tr>
                </table>
              </div>
            </div>
          </div>
        )}
        {selectedTicket && <div className="bb-1 h1"></div>}
        {selectedTicket && (
          <div className="drawer-fare-header mt-24">Date Change</div>
        )}

        {selectedTicket && (
          <div className="drawer-cancellation pl-24 pr-24">
            <div className="mt-16 drawer-fare-breakup-container">
              <div className="drawer-base-fare">
                <table className="drawer-fare-description mini-fares-rules-table">
                  <tr>
                    <th></th>
                    {selectedTicket.adtNum >= 1 ? (
                      <th className="right">ADT</th>
                    ) : (
                      ""
                    )}
                    {selectedTicket.chdNum >= 1 ? (
                      <th className="right">CHD</th>
                    ) : (
                      ""
                    )}
                    {selectedTicket.infNum >= 1 ? (
                      <th className="right">INF</th>
                    ) : (
                      ""
                    )}
                  </tr>
                  <tr>
                    <th className="left">Before Trip</th>
                    {beforeChange.length > 0 ? (
                      beforeChange.map((item) => (
                        <>
                          <th className="right">
                            {item?.exgAmt != undefined
                              ? currencyCode + "  " + item?.exgAmt
                              : "-"}
                          </th>
                        </>
                      ))
                    ) : (
                      <>
                        {selectedTicket.adtNum >= 1 ? (
                          <th className="right">Change Not Permitted</th>
                        ) : (
                          ""
                        )}
                        {selectedTicket.chdNum >= 1 ? (
                          <th className="right">Change Not Permitted</th>
                        ) : (
                          ""
                        )}
                        {selectedTicket.infNum >= 1 ? (
                          <th className="right">Change Not Permitted</th>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </tr>
                  <tr>
                    <th className="left">After Trip</th>
                    {afterChange.length > 0 ? (
                      afterChange.map((item) => (
                        <>
                          <th className="right">
                            {item?.exgAmt != undefined
                              ? currencyCode + "  " + item?.exgAmt
                              : "-"}
                          </th>
                        </>
                      ))
                    ) : (
                      <>
                        {selectedTicket.adtNum >= 1 ? (
                          <th className="right">Change Not Permitted</th>
                        ) : (
                          ""
                        )}
                        {selectedTicket.chdNum >= 1 ? (
                          <th className="right">Change Not Permitted</th>
                        ) : (
                          ""
                        )}
                        {selectedTicket.infNum >= 1 ? (
                          <th className="right">Change Not Permitted</th>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </tr>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
