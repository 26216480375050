import React, { useState, useEffect } from "react";
import "./SearchCategoryConfigurationTable.css";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import useConfigurationTableCell from "../../../../hooks/useConfigurationTableCell";
import useConfigurationTableRow from "../../../../hooks/useConfigurationTableRow";
import Paper from "@material-ui/core/Paper";
import cabinClassList from "../../../../data/CabinClass";
import TripTypeList from "../../../../data/TripType";
import Skeleton from "@material-ui/lab/Skeleton";
import { Chip } from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import { IconButton, OutlinedInput, InputAdornment } from "@material-ui/core";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Alert from "@material-ui/lab/Alert";


function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'>
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'>
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'>
        <LastPageIcon />
      </IconButton>
    </>
  );
}

export default function SearchCategoryConfigurationTable({
  openDrawer,
  setAction,
  pccList,
  name,
  filteredSearchCategory,
  page,
  setPage,
}) {
  const StyledTableRow = useConfigurationTableRow; 
  const StyledTableCell = useConfigurationTableCell;
  const [rowsPerPage, setRowsPerPage] = useState(10);
 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [searchedVal, setSearchedVal] = useState("");
  return (
    <div className='search-category-listing'>
      <TableContainer component={Paper} className='table-paper-styles'>
        <Table className='table-background'>
          <TableHead>
            {/* <TableRow>
              <TableCell colSpan={9} className='py-2'>
                <OutlinedInput
                  id='keyword-search'
                  onChange={(e) => {
                    setSearchedVal(e.target.value);
                    setPage(0);
                  }}
                  placeholder='Keyword Search by ID or Pseudo Code or Status'
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton>
                        <SearchOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </TableCell>
            </TableRow> */}
            <TableRow>
              {/* <TableCell>Search Category</TableCell> */}
              <TableCell>Ref ID</TableCell>
              <TableCell>Origin</TableCell>
              <TableCell>Destination</TableCell>
              <TableCell>Origin Airport</TableCell>
              <TableCell>Destination Airport</TableCell>
              <TableCell>SupplierName</TableCell>
              <TableCell width={170}>Pseudo Code</TableCell>
              {/* <TableCell>Travel Type</TableCell>
                            <TableCell>Cabin Class</TableCell> */}
              <TableCell>Status</TableCell>
              <TableCell resize={{ width: 100 }}>Modified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSearchCategory !== undefined &&
              filteredSearchCategory.length > 0 ? (
                filteredSearchCategory.filter((config) => {
                if (
                  searchedVal.toString().toLowerCase() == "active" ||
                  searchedVal.toString().toLowerCase() == "inactive"
                ) {
                  return (
                    config.searchCategoryConfigurations.status.toLowerCase() ==
                    searchedVal.toString().toLowerCase()
                  );
                } else {
                  return (
                    config.searchCategoryConfigurations.pcc
                      ?.toLowerCase()
                      .includes(searchedVal.toString().toLowerCase()) ||
                    config.searchCategoryConfigurations.id == searchedVal
                  );
                }
              }).length === 0 ? (
                <>
                  <StyledTableRow>
                    <StyledTableCell
                      component='th'
                      scope='row'
                      colSpan={9}
                      align='center'>
                      <Alert severity='error'>No record found.</Alert>
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              ) : (
                filteredSearchCategory
                  .filter((config) => {
                    if (
                      searchedVal.toString().toLowerCase() == "active" ||
                      searchedVal.toString().toLowerCase() == "inactive"
                    ) {
                      return (
                        config.searchCategoryConfigurations.status.toLowerCase() ==
                        searchedVal.toString().toLowerCase()
                      );
                    } else {
                      return (
                        config.searchCategoryConfigurations.pcc
                          ?.toLowerCase()
                          .includes(searchedVal.toString().toLowerCase()) ||
                        config.searchCategoryConfigurations.id == searchedVal
                      );
                    }
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((config) => (
                    <StyledTableRow
                      key={JSON.stringify(config.searchCategoryConfigurations)}
                      onClick={() => {
                        setAction("view");
                        openDrawer(config.searchCategoryConfigurations);
                      }}>
                      <StyledTableCell component='th' scope='row'>
                        <div className='table-cell-title'>
                          {config.searchCategoryConfigurations.id}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell component='th' scope='row'>
                        <div className='table-cell-title'>
                          {config.searchCategoryConfigurations.originCountry}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell component='th' scope='row'>
                        <div className='table-cell-title'>
                          {
                            config.searchCategoryConfigurations
                              .destinationCountry
                          }
                        </div>
                      </StyledTableCell>
                      <StyledTableCell component='th' scope='row'>
                        <div className='table-cell-title'>
                          {config.searchCategoryConfigurations.originAirport}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell component='th' scope='row'>
                        <div className='table-cell-title'>
                          {
                            config.searchCategoryConfigurations
                              .destinationAirport
                          }
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className='table-cell-title'>
                          {config.searchCategoryConfigurations.supplierNameType}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className='table-cell-title'>
                          {config.searchCategoryConfigurations.pcc}
                        </div>
                        <div className='table-cell-description'>
                          {pccList
                            .filter((id) =>
                              id.value.startsWith(
                                config.searchCategoryConfigurations.pcc + ","
                              )
                            )
                            .map(({ label }) => {
                              return label?.match(/\(([^)]+)\)/)?.length > 1
                                ? label?.match(/\(([^)]+)\)/)[1]
                                : label?.match(/\(([^)]+)\)/)?.length === 1
                                  ? label?.match(/\(([^)]+)\)/)[0]
                                  : "";
                            })}
                        </div>
                      </StyledTableCell>
                      {/* <StyledTableCell>
                                        <div className="table-cell-title">{
                                            TripTypeList && config.searchCategoryConfigurations.travelType !== undefined && config.searchCategoryConfigurations.travelType !== "All" &&
                                            config.searchCategoryConfigurations.travelType.split(",").map((item, i) => (
                                                TripTypeList.filter(id => id === item).map((row) => <p>{row}</p>)))
                                        }
                                            {
                                                config.searchCategoryConfigurations.travelType !== undefined && config.searchCategoryConfigurations.travelType === "All" &&
                                                <p>{config.searchCategoryConfigurations.travelType}</p>
                                            }
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <div className="table-cell-title">{
                                            cabinClassList && config.searchCategoryConfigurations.cabinClassType !== undefined &&
                                            config.searchCategoryConfigurations.cabinClassType.split(",").map((item, i) => (
                                                cabinClassList.filter(id => id.value === item).map((row) => <p>{row.label}</p>)))
                                        }</div>
                                    </StyledTableCell> */}
                      <StyledTableCell>
                        <div className='table-cell-title'>
                          {config.searchCategoryConfigurations.isActive ? (
                            <Chip
                              label='Active'
                              color='primary'
                              size='small'
                              style={{ backgroundColor: "green" }}
                            />
                          ) : (
                            <Chip
                              label='In Active'
                              color='primary'
                              style={{ backgroundColor: "red" }}
                            />
                          )}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className='table-cell-description'>
                          Created by:{" "}
                          <span style={{ color: "black" }}>
                            {config.createdBy}
                          </span>
                        </div>
                        <div className='table-cell-description'>
                          Created on:{" "}
                          {config.searchCategoryConfigurations.created.replace(
                            "T",
                            " , "
                          )}
                        </div>
                        <div className='table-cell-description'>
                          Last Modified by:{" "}
                          <span style={{ color: "black" }}>
                            {config.modefiedBy}
                          </span>
                        </div>
                        <div className='table-cell-description'>
                          Last Modified on:{" "}
                          {config.searchCategoryConfigurations.modified.replace(
                            "T",
                            " , "
                          )}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
              )
            ) : (
              <StyledTableRow>
                 <TableCell colSpan={8} align="center">
                No Results
              </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={
          filteredSearchCategory.length
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(event, newPage) => handleChangePage(event, newPage)}
        onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event)}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
}
