import { Table } from "@mui/joy";
import BootstrapTooltip1 from "../BootstrapToolTip/BootstrapToolTip1";
import React from "react";
import { useTranslation } from "react-i18next";
export default function SupplierFare({ flight, fopText, fop, flag }) {
    const { t } = useTranslation();
    let passthroughCardDetails = {
        cardNumber: '',
        expiryDate: '',
        Cvv: '',
    };
    if (fop === 'PassThrough' || fop.includes('PassThrough')) {
        let temp = fop.replace('PassThrough', '');
        passthroughCardDetails.cardNumber = temp.split('|')[0] + temp.split('|')[fop?.split('|')?.length - 1];
        passthroughCardDetails.expiryDate = temp.split('|')[1];
        passthroughCardDetails.Cvv = temp.split('|')[2];
    }
    const passthroughCardDetailsText = `{t("Card Number")}: ${passthroughCardDetails.cardNumber} \n ${t("Expiry Date")}: ${passthroughCardDetails.expiryDate} \n ${t("Cvv")}: ${passthroughCardDetails.Cvv}`

    return (
        <div className='flight-itenary'>
            <div className='flight-itenary-header'>
                <div
                    className='flight-itenary-title-container'
                    style={{ width: "100%" }}>
                    <p style={{ marginBottom: "0px", width: "100%" }}>
                        <span style={{ float: "left" }}>{t("Supplier Fare")} {flag === "reissue" ? <span className="ms-5 p-1 rounded" style={{ backgroundColor: "#671c41" }}> Existing Itinerary Fares </span> : ""}</span>
                        <BootstrapTooltip1 title={<pre>{passthroughCardDetailsText}</pre>}>
                            <span style={{ float: "right" }}>
                                {/* Fop - {flight?.supplierFare[0]?.fop} */}
                                {t("FOP")} - {flight?.supplierFare[0]?.fop && flight?.supplierFare[0]?.fop != null && flight.cardInfoId === 0 && flight?.supplierFare[0]?.fop !== "Cash" && !(flight?.supplierFare[0]?.fop?.includes("PassThrough")) ?
                                    ("PC" + (flight?.supplierFare[0]?.fop.length > 4 ? flight?.supplierFare[0]?.fop.substr(flight?.supplierFare[0]?.fop.length - 4) : flight?.supplierFare[0]?.fop))
                                    : flight?.supplierFare[0]?.fop?.includes("PassThrough") ?
                                        t("PassThrough")
                                        : fopText && fopText !== undefined ?
                                            fopText
                                            : t("Cash")}
                            </span>
                        </BootstrapTooltip1>

                        <span style={{ float: "right", marginRight: "10px" }}>
                            {t("Currency")} - {flight?.supplierFare[0]?.supplierCurrency}
                        </span>
                    </p>
                </div>
            </div>
            {flight?.supplierFare && flight?.supplierFare?.length > 0 ? (
                <Table>
                    <thead className='mui-table-header'>
                        <BootstrapTooltip1 title={t("PaxType")}>
                            <th style={{ textAlign: "center" }}>{t("PaxType")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("Base Fare")}>
                            <th style={{ textAlign: "center" }}>{t("Base Fare")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("Tax")}>
                            <th style={{ textAlign: "center" }}>{t("Tax")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("Markup")}>
                            <th style={{ textAlign: "center" }}>{t("Markup")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("IATA")}>
                            <th style={{ textAlign: "center" }}>{t("IATA")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("PLB")}>
                            <th style={{ textAlign: "center" }}>{t("PLB")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("DropNet")}>
                            <th style={{ textAlign: "center" }}>{t("DropNet")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("Vendor Fee")}>
                            <th style={{ textAlign: "center" }}>{t("Vendor Fee")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("Vendor Tax")}>
                            <th style={{ textAlign: "center" }}>{t("Vendor Tax")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("Baggage")}>
                            <th style={{ textAlign: "center" }}>{t("Baggage")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("Seat")}>
                            <th style={{ textAlign: "center" }}>{t("Seat")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("Meal")}>
                            <th style={{ textAlign: "center" }}>{t("Meal")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("GST")}>
                            <th style={{ textAlign: "center" }}>{t("GST")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("Fare Type")}>
                            <th style={{ textAlign: "center" }}>{t("Fare Type")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("Supplier Total (Per Pax)")}>
                            <th style={{ textAlign: "center" }}>{t("Supplier Total (Per Pax)")}</th>
                        </BootstrapTooltip1>
                        <BootstrapTooltip1 title={t("Supplier Total")}>
                            <th style={{ textAlign: "center" }}>{t("Supplier Total")}</th>
                        </BootstrapTooltip1>
                        {/* <BootstrapTooltip1 title="IBoss total">
                                    <th style={{ textAlign: 'center' }}>IBoss total</th>
                                </BootstrapTooltip1> */}
                    </thead>
                    <tbody>
                        {flight?.supplierFare
                            ?.sort(function (a, b) {
                                if (a.paxType < b.paxType) {
                                    return -1;
                                }
                                if (a.paxType > b.paxType) {
                                    return 1;
                                }
                                return 0;
                            })
                            .map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td align='center'>
                                            {item?.paxType} * {item?.paxCount}
                                        </td>
                                        <td align='center'>{(item?.baseFare).toFixed(2)}</td>
                                        <td align='center'>{(item?.tax).toFixed(2)}</td>
                                        <td align='center'>{(item?.markup).toFixed(2)}</td>
                                        <td align='center'>{(item?.iata).toFixed(2)}</td>
                                        <td align='center'>{(item?.plb).toFixed(2)}</td>
                                        <td align='center'>{(item?.dropNet).toFixed(2)}</td>
                                        <td align='center'>{(item?.vendorIssuanceFee).toFixed(2)}</td>
                                        <td align='center'>{item?.vendorTax ? item?.vendorTax.toFixed(2) : '0.00'}</td>
                                        <td align='center'>{(item?.baggagefare).toFixed(2)}</td>
                                        <td align='center'>{(item?.seatFare).toFixed(2)}</td>
                                        <td align='center'>{(item?.mealFare).toFixed(2)}</td>
                                        <td align='center'>{(item?.gst).toFixed(2)}</td>
                                        <td align='center'>{(item?.fareType)}</td>
                                        <td align='center'>{(item?.supplierTotal).toFixed(2)}</td>
                                        <td align='center'>{(item?.supplierTotal * item?.paxCount).toFixed(2)}</td>
                                        {/* <td align="center">{item.ibossTotal}</td> */}
                                    </tr>
                                );
                            })}
                        <tr>
                            <td align='center' className="fw600">
                                <b>Total</b>
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.baseFare || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.tax || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.markup || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.iata || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.plb || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.dropNet || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.vendorIssuanceFee || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.vendorTax || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.baggagefare || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.seatFare || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.mealFare || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + (item?.gst || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {/* fareType might need a different handling */}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + ((item?.supplierTotal) || 0), 0)).toFixed(2)}
                            </td>
                            <td align='center' className="fw600">
                                {(flight?.supplierFare?.reduce((total, item) => total + ((item?.supplierTotal * item?.paxCount) || 0), 0)).toFixed(2)}
                            </td>
                        </tr>
                    </tbody>
                    <p style={{ margin: "0px", padding: "0px 10px", width: '40vw', color: 'red' }}>* The fares shown above are for one pax </p>
                </Table>
            ) : (
                ""
            )}
        </div>
    );
}
