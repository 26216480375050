import AddPassengerDrawer from "./addPassengerDrawer";
import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Button from "@material-ui/core/Button";
import axios from "axios";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import supplierList from "../../data/Supplier";
import findAirlinebyCode from "../../functions/findAirlineByCode";
import findAirportbyCode from "../../functions/findAirportByCode";
import getAirportByCode from "../../functions/getAirportByCode";
import getTax from "../../functions/getTax";
import getPaxTotal from "../../functions/getPaxTotal";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Drawer from "@material-ui/core/Drawer";
import Skeleton from "@material-ui/lab/Skeleton";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import * as XLSX from "xlsx";
import { FormLabel, Input } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";

export default function PassengerForm({
  onwardSegments,
  returnSegments,
  setAction,
  openAddDrawer,
  passengers,
  openEditDrawer,
  deletePassenger,
  selectedItem,
  setSelectedItem,
  selectedPassengerIndex,
  setSelectedPassengerIndex,
  drawer,
  toggleDrawer,
  setPassengerTemplate,
  handleSnackbarToggle,
  handleSnackbarClose,
  snackbarOpen,
  action,
  selectDate,
  setDrawer,
  showLoader,
  setShowLoader,
  selectedTripType,
  setPassengers,
  excelData,
  setExcelData,
  disableImportBtn,
  value
}) {
  let pasIndex = 0;
  const [disableAddButton, setDisableAddButton] = useState(true);
  const [fileVal, setFileVal] = useState("");
  const fileInputRef = useRef();
  const [segmentError, setSegmentError] = useState('');
  const clearFileInput = (e) => {
    document.getElementById("fileInputRef").value = "";
  };

  useEffect(() => {
    console.log("segmentData Passenger Form");
    console.log("onwardSegments", onwardSegments);
    console.log("returnSegments", returnSegments);
    console.log("selectedTripType", selectedTripType);
    if (selectedTripType === "onward" && onwardSegments.length === 0) {
      setDisableAddButton((disableAddButton) => true);
    } else if (
      selectedTripType === "return" &&
      returnSegments.length === 0 &&
      onwardSegments.length === 0
    ) {
      setDisableAddButton((disableAddButton) => true);
    } else {
      const isOnwardValid =
        selectedTripType === 'onward' &&
        onwardSegments.length > 0 &&
        onwardSegments.every(
          (segment) =>
            segment.gdsPNR !== undefined &&
            segment.gdsPNR !== null &&
            segment.gdsPNR.trim() !== '' &&
            segment.airlinePNR !== undefined &&
            segment.airlinePNR !== null &&
            segment.airlinePNR.trim() !== ''
        );

      const isReturnValid =
        selectedTripType === 'return' &&
        returnSegments.length > 0 &&
        returnSegments.every(
          (segment) =>
            segment.gdsPNR !== undefined &&
            segment.gdsPNR !== null &&
            segment.gdsPNR.trim() !== '' &&
            segment.airlinePNR !== undefined &&
            segment.airlinePNR !== null &&
            segment.airlinePNR.trim() !== ''
        ) &&
        onwardSegments.length > 0 &&
        onwardSegments.every(
          (segment) =>
            segment.gdsPNR !== undefined &&
            segment.gdsPNR !== null &&
            segment.gdsPNR.trim() !== '' &&
            segment.airlinePNR !== undefined &&
            segment.airlinePNR !== null &&
            segment.airlinePNR.trim() !== ''
        );

      const isButtonDisabled = !isOnwardValid && !isReturnValid;
      setDisableAddButton((disableAddButton) => isButtonDisabled);
      setSegmentError((segmentError) => isButtonDisabled);
    }
  }, [onwardSegments, selectedTripType, returnSegments]);

  const isValidTicketNumber = (ticketNumber) => {
    const ticketNumberPattern = /^[0-9A-Za-z/]*$/;
    return ticketNumberPattern.test(ticketNumber);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setExcelData([]);

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const dataArray = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        console.log("dataArray", dataArray);
        const headers = dataArray[0];

        const passengersTemp = dataArray
          .slice(1)
          .map((row) => {
            if (row.length > 0) {
              const passengerDetails = {};
              const baggageDetails = [];
              for (let i = 0; i < 8; i++) {
                if (headers[i] === "dob") {
                  passengerDetails[headers[i]] = ExcelDateToJSDate(row[i]);
                } else {
                  passengerDetails[headers[i]] = row[i];
                }

                passengerDetails.id = 0;
                passengerDetails.seatPref = "";
                passengerDetails.flightTicketStatus = "";
                passengerDetails.inputType = "E";
              }

              // Validate ticket number
              if (!isValidTicketNumber(passengerDetails.ticketNumber)) {
                return null; // Skip invalid ticket number
              }

              for (let i = 8; i < headers.length; i += 4) {
                const baggageObj = {
                  checkInBag: row[i],
                  cabinBag: row[i + 1],
                  cityPair: row[i + 2]?.toUpperCase(),
                  amount: row[i + 3],
                };
                if (
                  baggageObj.checkInBag !== undefined &&
                  baggageObj.cabinBag !== undefined &&
                  baggageObj.cityPair !== undefined &&
                  baggageObj.amount !== undefined
                ) {
                  baggageDetails.push(baggageObj);
                }
              }

              passengerDetails.baggage = baggageDetails;
              return passengerDetails;
            } else {
              return null;
            }
          })
          .filter((passenger) => passenger !== null);

        console.log("condition 1", passengersTemp);
        console.log("condition 2", passengers);

        const filteredPassengers = passengers.filter(
          (passenger) => passenger.inputType !== "E"
        );
        setPassengers([...filteredPassengers]);
        setExcelData((prevExcelData) => [...prevExcelData, ...passengersTemp]);
      };

      reader.readAsArrayBuffer(file);
    }
    document.getElementById("fileInputRef").value = "";
  };

  const ExcelDateToJSDate = (date) => {

    let converted_date = new Date(Math.round((date - 25569) * 864e5));
    converted_date = moment(converted_date).format("DD-MMM-YYYY");
    return converted_date;
  };

  useEffect(() => {
    console.log("excelData", excelData);
  }, [excelData]);
  useEffect(() => {
    console.log("passengers", passengers);
  }, [passengers]);

  const handleDownload = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = "/Book1.xlsx";
    downloadLink.download = "Book1.xlsx";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <div className="offline-itenary-container">
        {
          segmentError ?
            <p style={{ fontSize: "12px", color: "red", textAlign: 'end' }}>Kindly Enter GDS & Airline PNR Values For All Segments</p>
            : ''
        }
        <div className="offline-itenary-container-import">
          <div>Passenger Details</div>
          <div className="d-flex">
            <Button
              variant="outlined"
              disabled={disableAddButton || disableImportBtn}
              className="importbtn"
            // endIcon={<CloseIcon onClick={clearFileInput} />}
            >
              Import Via Excel
              <Input
                style={{ maxWidth: "250px" }}
                id="fileInputRef"
                disabled={disableAddButton || disableImportBtn}
                type="file"
                accept=".xlsx, .xls"
                className="uploadbtn"
                onChange={handleFileChange}
              />
            </Button>

            <div>
              <Button
                variant="outlined"
                color="primary"
                className="addButton"
                startIcon={<AddIcon />}
                disabled={disableAddButton}
                onClick={async () => {
                  await setAction("add");
                  await openAddDrawer(0);
                }}
              >
                Add
              </Button>
            </div>
          </div>
        </div>
        <div className="offlineBooking passenger-itenary-details">
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="itenary-passenger-table"
          >
            <TableHead>
              <TableRow className="itenary-passenger-table-header">
                <TableCell
                  align="left"
                  className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight itenary-passenger-table-header-item-initial "
                >
                  Passenger(s)
                </TableCell>
                <TableCell
                  align="left"
                  className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                >
                  Pax
                </TableCell>
                <TableCell
                  align="left"
                  className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                >
                  Gender
                </TableCell>
                <TableCell
                  align="left"
                  className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                >
                  Date of Birth
                </TableCell>
                <TableCell
                  align="left"
                  className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                >
                  Ticket No
                </TableCell>
                <TableCell
                  align="left"
                  className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                >
                  Passport No
                </TableCell>
                {/* <TableCell
                            align="left"
                            className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight"
                          >
                            {" "}
                          </TableCell> */}
                <TableCell
                  align="left"
                  className="import-booking-itenary-passenger-table-header-pl-12 import-booking-header-item-font-weight itenary-passenger-table-header-item-final"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {passengers !== undefined && passengers.length > 0 ? (
                passengers
                  .sort(function (a, b) {
                    if (a.paxType < b.paxType) {
                      return -1;
                    }
                    if (a.paxType > b.paxType) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((passenger, passengerIndex) => (
                    <TableRow className="itenary-passenger-table-item" key={`${passengerIndex + 'op'}`}>
                      <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                        {passenger.title} {passenger.firstName}{" "}
                        {passenger.lastName}
                      </TableCell>
                      <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                        {passenger.paxType}
                      </TableCell>
                      <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                        {passenger.genderType}
                      </TableCell>
                      <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                        {passenger.dob ? passenger.dob : ""}
                      </TableCell>
                      <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                        {passenger.ticketNumber}
                      </TableCell>
                      <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                        {passenger.passportNumber}
                      </TableCell>
                      {/* <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                                  {" "}
                                </TableCell> */}
                      <TableCell className="itenary-passenger-table-h-64 itenary-passenger-table-header-pl-30">
                        <IconButton
                          color="primary"
                          className="editIcon"
                          onClick={async () => {
                            await setAction("edit");
                            await openEditDrawer(passengerIndex);
                          }}
                          aria-label="edit"
                          disabled={passenger.inputType === "E"}
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          color="error"
                          className="deleteIcon"
                          onClick={async () => {
                            await setAction("delete");
                            await deletePassenger(passengerIndex);
                          }}
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow></TableRow>
              )}
            </TableBody>
          </Table>
          {/* </TableContainer> */}
          <Drawer
            anchor="right"
            open={drawer}
            onClose={toggleDrawer(false)}
            BackdropProps={{ invisible: true }}
            variant="persistent"
            className="drawer-width"
            transitionDuration={{ enter: 500, exit: 500 }}
          >
            <div className="drawer-min-width-50">
              <AddPassengerDrawer
                selectedItem={selectedItem}
                toggleDrawer={toggleDrawer}
                index={selectedPassengerIndex}
                passengers={passengers}
                setPassengerTemplate={setPassengerTemplate}
                action={action}
                handleSnackbarToggle={handleSnackbarToggle}
                selectDate={selectDate}
                setDrawer={setDrawer}
                setShowLoader={setShowLoader}
                showLoader={showLoader}
                onwardSegments={onwardSegments}
                returnSegments={returnSegments}
                value={value}
              ></AddPassengerDrawer>
            </div>
          </Drawer>
        </div>{" "}
        <div style={{ display: disableAddButton ? "none" : "" }}>
          <p
            style={{
              fontSize: "12px",
              fontWeight: "600",
              opacity: "0.8",
              marginTop: "15px",
            }}
          >
            Please upload the excel in the following order:
            paxType,title,firstName,lastName,dob,genderType,ticketNumber,passportNumber,checkInBag,cabinBag,cityPair,amount{" "}
            <br></br>
            Click here to{" "}
            <span>
              <a href="#" onClick={handleDownload}>
                Download template
              </a>
            </span>
          </p>
        </div>
      </div>

      <div className="offline-itenary-container">
        <div className="offline-itenary-container-import">
          <div>Baggage</div>
        </div>
        {/* <TableContainer component={Paper}> */}
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Passenger Type</TableCell>
              <TableCell align="left">Passenger Name</TableCell>
              <TableCell align="left">CheckIn Bag</TableCell>
              <TableCell align="left">Cabin Bag</TableCell>
              <TableCell align="left">City Pair</TableCell>
              <TableCell align="left">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {passengers.map((passenger) =>
              passenger?.baggage.map((row) => (
                <TableRow
                  key={row.paxName}
                  sx={{
                    "&:last-child TableCell, &:last-child TableCell": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell align="left">{passenger.paxType}</TableCell>
                  <TableCell align="left">
                    {passenger.title} {passenger.firstName} {passenger.lastName}
                  </TableCell>
                  <TableCell align="left">
                    {row.checkInBag ? row.checkInBag : ""}
                  </TableCell>
                  <TableCell align="left">
                    {row.cabinBag ? row.cabinBag : ""}
                  </TableCell>
                  <TableCell align="left">
                    {row.cityPair ? row.cityPair : ""}
                  </TableCell>

                  <TableCell align="left">{row.amount}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {/* </TableContainer> */}
      </div>
    </>
  );
}
