import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import "./ClassSelectMenu.css";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import classDetails from "../../data/ClassDetails";

function ClassSelectMenu({ search, setSearch, t }) {
  const [value, setValue] = useState(search.class);

  const handleChange = (event) => {
    setClassType(event.target.value);
    setValue(event.target.value);
  };

  const setClassType = (value) => {
    const existingSearch = { ...search };
    existingSearch.class = value;
    setSearch(existingSearch);
  };
  useEffect(() => {
    setClassType(search.class);
    setValue(search.class);
  }, [search.class])
  return (
    <div className="class-type-select-width">
      <FormControl component="fieldset">
        <Select value={value} onChange={handleChange}
          autoWidth
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}>
          {classDetails.map((item) => (
            <MenuItem value={item}>
              {t(item.replace(/([A-Z])/g, " $1").trim())}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default ClassSelectMenu;
