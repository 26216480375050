import React, { useState, useEffect } from "react";
import "./BasicSearchBar.css";
import Grid from "@material-ui/core/Grid";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import Menu from "@material-ui/core/Menu";
import DestinationSearchMenu from "../DestinationSearchMenu/DestinationSearchMenu";
import Popover from "@material-ui/core/Popover";
import DateRangePicker from "../DatePicker/DatePicker";
import months from "../../data/Months";
import Days from "../../data/Days";
import ImportExportRoundedIcon from "@material-ui/icons/ImportExportRounded";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import { Alert, Button, Snackbar } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from '@mui/icons-material/Close';
import countryCodeMap from "../../data/countryCodeMap";

function BasicSearchBar({ search, setSearch, t, arrIndex }) {
  const currentDate = moment();
  const departDate = search.originDestinations[arrIndex].departureDateTime;
  const returnDate = search.originDestinations[arrIndex].returnDateTime ? (search.originDestinations[arrIndex].returnDateTime) : moment(search.originDestinations[arrIndex].departureDateTime).add(1, 'd').$d;
  const displayDate = departDate < (currentDate) ? currentDate : departDate;
  const displayReturnDate = returnDate < (currentDate) && returnDate < (departDate) ? null : returnDate

  const [peopleClassMenuAnchorEl, setPeopleClassMenuAnchorEl] = useState(null);
  const [fromSearchMenuAnchorEl, setFromSearchMenuAnchorEl] = useState(null);
  const [toSearchMenuAnchorEl, setToSearchMenuAnchorEl] = useState(null);
  const [datePickerMenuAnchorEl, setDatePickerMenuAnchorEl] = useState(null);
  const [flag, setFlag] = useState("");
  const [isErrorShow, serIsErrorShow] = useState(false);


  const fromSearchMenuHandleClick = (event) => {
    setFromSearchMenuAnchorEl(event.currentTarget);
  };

  const fromSearchMenuAnchorElHandleClose = (event) => {
    setFromSearchMenuAnchorEl(null);
  };

  const toSearchMenuHandleClick = (event) => {
    setToSearchMenuAnchorEl(event.currentTarget);
  };

  const toSearchMenuAnchorElHandleClose = (event) => {
    setToSearchMenuAnchorEl(null);
  };

  const datePickerMenuHandleClick = (event) => {
    setDatePickerMenuAnchorEl(event.currentTarget);
  };

  const datePickerMenuHandleClose = () => {
    setDatePickerMenuAnchorEl(null);
  };

  const peopleClassMenuHandleClick = (event) => {
    setPeopleClassMenuAnchorEl(event.currentTarget);
  };

  const peopleClassMenuHandleClose = () => {
    setPeopleClassMenuAnchorEl(null);
  };

  const changeToReturnTrip = () => {
    const existingSearch = { ...search };
    existingSearch.type = "RoundTrip";
    setDatePickerMenuAnchorEl(true);
    // existingSearch.depart = "";
    existingSearch.return = "";
    // existingSearch.return = getNextDay(existingSearch.depart);
    setSearch(existingSearch);
  };

  const getNextDay = (day) => {
    const nextDay = new Date(day);
    nextDay.setDate(day.getDate() + 2);
    return nextDay;
  };


  const interchangeLocations = () => {
    const existingSearch = { ...search };
    if (existingSearch.originDestinations[arrIndex].origin !== "From" && existingSearch.originDestinations[arrIndex].destination !== "To") {
      const from = existingSearch.originDestinations[arrIndex].origin;
      existingSearch.originDestinations[arrIndex].origin = existingSearch.originDestinations[arrIndex].destination;
      existingSearch.originDestinations[arrIndex].destination = from;
      setSearch(existingSearch);
    }
  };

  const addSearchHadler = () => {
    const existingSearch = { ...search };
    if (existingSearch.originDestinations[existingSearch.originDestinations.length - 1].origin === 'From' ||
      existingSearch.originDestinations[existingSearch.originDestinations.length - 1].destination === 'To' ||
      existingSearch.originDestinations[existingSearch.originDestinations.length - 1].origin === '' ||
      existingSearch.originDestinations[existingSearch.originDestinations.length - 1].destination === '' ||
      existingSearch.originDestinations[existingSearch.originDestinations.length - 1].origin === null ||
      existingSearch.originDestinations[existingSearch.originDestinations.length - 1].destination === null
    ) {
      serIsErrorShow(true);
      return;
    }

    existingSearch.originDestinations.push({
      departureDateTime: moment(existingSearch?.originDestinations[existingSearch.originDestinations.length - 1].departureDateTime)?.add(1, "d"),
      destination: 'To',
      origin: existingSearch.originDestinations[existingSearch.originDestinations.length - 1].destination,
      returnDateTime: '',
    })
    setSearch(existingSearch);
  }

  const handleSnackBarClose = () => {
    serIsErrorShow(false);
  }

  const removeSearchHandler = () => {
    const existingSearch = { ...search };
    if (existingSearch.originDestinations.length !== 1) {
      const updatedOriginDestinations = existingSearch.originDestinations.filter(
        (_, index) => index !== arrIndex
      );
      const updatedSearch = {
        ...existingSearch,
        originDestinations: updatedOriginDestinations,
      };

      setSearch(updatedSearch);
    }
  }
  const getCountryCode = (country) => countryCodeMap[country] || "unknown";

  return (
    <div className="mb-1">
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={isErrorShow}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose}
          severity="error"
          sx={{ backgroundColor: 'rgb(253, 237, 237) !important', marginTop: '3rem' }}
        >
          Origin and destination cannot be left blank. Please fill in both fields to proceed
        </Alert>
      </Snackbar>
      <div className="basic-search-bar">
        <Grid container className="full-height">
          <Grid
            item
            xs={(search?.type !== "MultiCity") ? 3 : 4}
            className="pl-16 br-1 hover-fill relative"
          >
            <Menu
              className="top-z-index"
              id="fromSearchMenu"
              anchorEl={fromSearchMenuAnchorEl}
              keepMounted
              open={Boolean(fromSearchMenuAnchorEl)}
              onClose={fromSearchMenuAnchorElHandleClose}
            >
              <DestinationSearchMenu
                search={search}
                setSearch={setSearch}
                type="from"
                closeMenu={fromSearchMenuAnchorElHandleClose}
                arrIndex={arrIndex}
              />
            </Menu>
            {search?.type !== "Multidestination" && (
              <div
                className="destination-icon pointer"
                onClick={() => interchangeLocations()}
              >
                <ImportExportRoundedIcon className="rotate-90" />
              </div>
            )}
            <div
              className="from-to-height-width"
              onClick={fromSearchMenuHandleClick}
            >
              <div
                className={`bold small-font medium-opacity search-title pointer heading-font ${typeof search?.originDestinations[arrIndex].origin !== "string" ? "" : "no-opacity"
                  }`}
              >
                {t('From')}
              </div>
              <Tooltip title="Leaving From">
                <div
                  className="station-text pointer"
                  onClick={fromSearchMenuHandleClick}
                >
                  {search?.originDestinations[arrIndex].origin?.city ? search?.originDestinations[arrIndex].origin?.city : t('From')}
                </div>
              </Tooltip>
              <div
                className="description pointer"
                onClick={fromSearchMenuHandleClick}
              >
                {search?.originDestinations[arrIndex].origin?.city_code ? `${search?.originDestinations[arrIndex].origin?.city_code},` : ""}{" "}
                {search?.originDestinations[arrIndex].origin?.airport_name ? search?.originDestinations[arrIndex].origin?.airport_name : ""}
                {
                  search?.originDestinations[arrIndex].origin?.country &&
                  <img
                    src={`https://flagcdn.com/16x12/${(getCountryCode(search?.originDestinations[arrIndex].origin?.country)).toLowerCase()}.png`}
                    alt={`${search?.originDestinations[arrIndex].origin?.country} flag`}
                    style={{ width: "20px", height: "20px", margin: "0 10px", opacity: 1 }}
                  />
                }
              </div>

            </div>
          </Grid>

          <Grid
            item
            xs={(search?.type !== "MultiCity") ? 3 : 4}
            className={
              "br-1 hover-fill " +
              (search?.type === "Multidestination" ? " pl-16" : " pl-24")
            }
          >
            <Menu
              id="toSearchMenu"
              anchorEl={toSearchMenuAnchorEl}
              keepMounted
              open={Boolean(toSearchMenuAnchorEl)}
              onClose={toSearchMenuAnchorElHandleClose}
            >
              <DestinationSearchMenu
                search={search}
                setSearch={setSearch}
                type="to"
                closeMenu={toSearchMenuAnchorElHandleClose}
                arrIndex={arrIndex}
              />
            </Menu>
            <div
              className="from-to-height-width"
              onClick={toSearchMenuHandleClick}
            >
              <div
                className={`bold small-font medium-opacity search-title pointer heading-font ${typeof search?.originDestinations[arrIndex].destination !== "string" ? "" : "no-opacity"
                  }`}
              >
                {t('To')}
              </div>
              <Tooltip title="Going To">
                <div
                  className="station-text pointer"
                  onClick={toSearchMenuHandleClick}
                >
                  {search?.originDestinations[arrIndex].destination?.city ? search?.originDestinations[arrIndex].destination?.city : t('To')}
                </div>
              </Tooltip>
              <div
                className="description pointer"
                onClick={toSearchMenuHandleClick}
              >
                {search?.originDestinations[arrIndex].destination?.city_code ? `${search?.originDestinations[arrIndex].destination?.city_code},` : ""}{" "}
                {search?.originDestinations[arrIndex].destination?.airport_name ? search?.originDestinations[arrIndex].destination?.airport_name : ""}
                {
                  search?.originDestinations[arrIndex].destination?.country &&
                  <img
                    src={`https://flagcdn.com/16x12/${(getCountryCode(search?.originDestinations[arrIndex].destination?.country)).toLowerCase()}.png`}
                    alt={`${search?.originDestinations[arrIndex].destination?.country} flag`}
                    style={{ width: "20px", height: "20px", margin: "0 10px", opacity: 1 }}
                  />
                }
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={(search?.type !== "MultiCity") ? 3 : 4}
            className="pl-16 br-1 hover-fill"
          >
            <Grid container>
              <Grid
                style={{
                  width: (search?.type === 'MultiCity') ? 'calc(100% -  40px)' : search?.originDestinations.length - 1 === arrIndex ? '100%' : '100%'
                }}
              >
                <Popover
                  id="departDatePicker"
                  anchorEl={datePickerMenuAnchorEl}
                  keepMounted
                  open={Boolean(datePickerMenuAnchorEl)}
                  onClose={datePickerMenuHandleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <DateRangePicker
                    search={search}
                    setSearch={setSearch}
                    closeMenu={datePickerMenuHandleClose}
                    flag={flag}
                    arrIndex={arrIndex}
                  />
                </Popover>
                <div className="bold small-font medium-opacity search-title pointer heading-font">
                  {t("Depart")}
                  <div className="expand-icon pointer">
                    <ExpandMoreRoundedIcon className="icon-blue" fontSize="small" />
                  </div>
                </div>
                <div
                  className="station-text pointer"
                  onClick={(event) => {
                    datePickerMenuHandleClick(event);
                    setFlag("Depart");
                  }}
                >
                  {search && search?.originDestinations[arrIndex].departureDateTime && new Date(search?.originDestinations[arrIndex].departureDateTime).getDate() ? (
                    <>
                      {" "}
                      {new Date(displayDate).getDate()}
                      <span className="station-sub-text">
                        {months[new Date(displayDate).getMonth()]},
                        {new Date(displayDate).getFullYear().toString().substring(2, 4)}
                      </span>

                    </>
                  ) : (
                    <span
                      className="station-sub-text"
                      onClick={(event) => {
                        datePickerMenuHandleClick(event);
                        setFlag("Depart");
                      }}
                    >
                      {""}
                    </span>
                  )}
                </div>
                <div
                  className="description pointer"
                  onClick={(event) => {
                    datePickerMenuHandleClick(event);
                    setFlag("Depart");
                  }}
                >
                  {search?.originDestinations[arrIndex].departureDateTime ? Days[(new Date(search?.originDestinations[arrIndex].departureDateTime)).getDay()] : ""}
                </div>
              </Grid>
              {(search?.type === 'MultiCity') && search?.originDestinations.length > 2 &&
                <Grid style={{ width: '40px' }} className="d-flex align-items-center justify-content-center">
                  <CloseIcon
                    onClick={() => {
                      removeSearchHandler()
                    }}
                    style={{ cursor: 'pointer', color: 'rgba(0,0,0,0.2)' }}
                  />
                </Grid>
              }
            </Grid>
          </Grid>

          {(search?.type !== 'MultiCity') && (
            <Grid item xs={3} className="pl-16 br-1 hover-fill">
              <div className="bold small-font medium-opacity search-title pointer heading-font">
                {t('Return')}
                <div className="expand-icon">
                  <ExpandMoreRoundedIcon
                    className="icon-blue"
                    fontSize="small"
                  />
                </div>
              </div>
              {search?.type === "OneWay" && (
                <div
                  className="reference-text pointer"
                  onClick={() => {
                    changeToReturnTrip();
                    setFlag("Return");
                  }}
                >
                  {t("Book return tickets for great discounts")}
                </div>
              )}
              {search?.type === "RoundTrip" && (
                <div
                  className="station-text pointer"
                  onClick={(event) => {
                    datePickerMenuHandleClick(event);
                    setFlag("Return");
                  }}
                >
                  {displayReturnDate ? new Date(displayReturnDate).getDate() : ""}
                  {displayReturnDate ? (
                    <span className="station-sub-text">
                      {months[new Date(displayReturnDate).getMonth()]},
                      {new Date(displayReturnDate).getFullYear().toString().substring(2, 4)}
                    </span>
                  ) : (
                    <span
                      className="station-sub-text"
                      onClick={(event) => {
                        datePickerMenuHandleClick(event);
                        setFlag("Return");
                      }}
                    >
                      {" "}
                    </span>
                  )}
                </div>
              )}
              {search?.type === "RoundTrip" && (
                <div
                  className="description pointer"
                  onClick={(event) => {
                    datePickerMenuHandleClick(event);
                    setFlag("Return");
                  }}
                >
                  {search?.originDestinations[arrIndex].returnDateTime ? Days[(new Date(search?.originDestinations[arrIndex].returnDateTime)).getDay()] : ""}
                </div>
              )}
            </Grid>
          )}
        </Grid>
      </div>
      {search?.type === "MultiCity" && search?.originDestinations.length - 1 === arrIndex && (search?.originDestinations.length <= 5) &&
        <Grid container>
          <Grid xs={12} className="pl-16 br-1 hover-fill d-flex align-items-center justify-content-end mt-2">
            <Button
              variant="outlined"
              className="addButton"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                addSearchHadler();
              }}
              sx={{
                borderRadius: '3px !important',
                padding: '0.4rem 1rem !important',
                fontWeight: '600 !important',
                fontSize: '13px !important',
              }}
            >
              ADD ANOTHER CITY
            </Button>
          </Grid>
        </Grid>
      }
    </div>
  );
}

export default BasicSearchBar;
