import { Table } from "@mui/joy";
import { useTranslation } from "react-i18next";
export default function BookingStatusFlow({ historyDetail }) {
    const { t } = useTranslation();
    return (
        <div className='flight-itenary'>
            <div className="flight-itenary-header">
                <div className="flight-itenary-title-container">
                    <div>
                        {t("Booking Status Flow")}
                    </div>
                </div>
            </div>
            <Table>
                <thead className="mui-table-header">
                    <th style={{ textAlign: 'center' }}>{t("Booking Status")}</th>
                    <th style={{ textAlign: 'center' }}>{t("Actioned On")}</th>
                    <th style={{ textAlign: 'center' }}>{t("Actioned By")}</th>
                    <th style={{ textAlign: 'center' }}>{t("Remarks")}</th>
                </thead>
                <tbody>
                    {historyDetail.map((item) => (
                        <tr className="itenary-passenger-table-item  price-row">
                            <td align="center" className="width10  itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                                {item.status}
                            </td>
                            <td align="center"
                                style={{ textAlign: "center !important" }}
                                className="width15 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                            >
                                {item.actionedOn}
                            </td>
                            <td align="center" className="width20 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                                {item.actionBy}
                            </td>
                            <td align="center" className="width20 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                                {item.remarks}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}