import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import "./autoImps.css";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
export default function AutoImps() {
  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();

  return (
    <div className="impsPaymentTitleContainer">
      <div className="impsPaymentTitle">
        <AccountBalanceIcon
          className="icon-fill pointer"
          fontSize="medium"
        ></AccountBalanceIcon>
        <span className="impsPaymentHeading">{t("AXIS BANK")}</span>
      </div>
      <div className="impsInfo">
        <p>
          {t("now get auto account upload for IMPS/NEFT/RTGS Recharge, no need to send mail or deposit Request")}
        </p>
      </div>
      <div className="impsSteps">
        <div className="impsStepsHead">
          <span>{t("Below are the steps to recharge via Online banking")}</span>
        </div>
        <ol style={{ listStyleType: "disc" }}>
          <li>
            {t("Register the given account number as beneficiary in your Net banking login.")}
          </li>
          <li>
            {t("Once this account gets activated you can do IMPS, NEFT or RTGS transaction to get auto upload Funds.")}
          </li>
          <li>
            {t("Account fund upload Email confirmation will be sent on registered email address.")}
          </li>
        </ol>
      </div>
      <div className="impsAccountInfotable">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={6}>
                <span style={{ paddingRight: "5px" }} className="fontMono">
                  {t("Bank Holder Name")}
                </span>
              </TableCell>
              <TableCell colSpan={6}>
                <span className="f600 pl-5 fs16 fontMono">
                  Akbar Offshore Pvt Ltd
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={6}>
                <span style={{ paddingRight: "5px" }} className="fontMono">
                  {t("Account Number")}
                </span>
              </TableCell>
              <TableCell colSpan={6}>
                <span className="f600 pl-5 fs16 fontMono">
                  AOPLGFC
                  {user?.clientref_id.length === 5
                    ? user?.clientref_id
                    : user?.clientref_id.length === 4
                      ? "0" + user?.clientref_id
                      : user?.clientref_id.length === 3
                        ? "00" + user?.clientref_id
                        : user?.clientref_id.length === 2
                          ? "000" + user?.clientref_id
                          : user?.clientref_id.length === 1 &&
                          "0000" + user?.clientref_id}
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={6}>
                <span style={{ paddingRight: "5px" }} className="fontMono">
                  {t("Bank Name")}
                </span>
              </TableCell>
              <TableCell colSpan={6}>
                <span className="f600 pl-5 fs16 fontMono">Axis Bank</span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={6}>
                <span style={{ paddingRight: "5px" }} className="fontMono">
                  {t("IFSC Code")}
                </span>
              </TableCell>
              <TableCell colSpan={6}>
                <span className="f600 pl-5 fs16 fontMono">UTIB0CCH274</span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={6}>
                <span style={{ paddingRight: "5px" }} className="fontMono">
                  {t("Branch Address")}{" "}
                  {/* <span className="f600 pl-5 fs16 fontMono">
                  Centralised Collection Hub,Mumbai,Maharashtra
                </span> */}
                </span>
              </TableCell>
              <TableCell colSpan={6}>
                <span className="f600 pl-5 fs16 fontMono">
                  Centralised Collection Hub,Mumbai,Maharashtra
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className="impsSteps">
        <div className="impsStepsHead">
          <span>{t("Note")}</span>
        </div>
        <ol style={{ listStyleType: "disc" }}>
          <li>
            {t("The Virtual account is for only online transfer to INR bank account")}
          </li>
          <li>
            {t("Overseas clients cannot do payment without swift code, above one is only for domestic transfers(Within India).")}
          </li>
        </ol>
      </div>
    </div>
  );
}
