import React, { useState, useEffect, useRef } from "react";
import "./ManageClients.css";
import SearchIcon from "@material-ui/icons/Search";
import PersonOutlineRoundedIcon from "@material-ui/icons/PersonOutlineRounded";
import ConfirmationNumberOutlinedIcon from "@material-ui/icons/ConfirmationNumberOutlined";
import { IconButton, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import ClientTable from "./ClientTable/ClientTable";
import FilterListIcon from "@material-ui/icons/FilterList";
import Drawer from "@material-ui/core/Drawer";
import ClientDrawer from "./ClientDrawer/ClientDrawer";
import AddClientCard from "./AddClientCard/AddClientCard";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import axios from "axios";
import RegisterClientUser from "./RegisterClientUser/RegisterClientUser";
import { SetMealOutlined } from "@mui/icons-material";
import { CSVLink } from "react-csv";
import moment from "moment";
import countries from "../../data/Country";
import Unauthorizedtemplate from "../../providers/UnauthorizedTemplate/Unauthorizedtemplate";
import { useHistory } from "react-router-dom";
import { isUserHasPermission } from "../../services/AuthService";
import FormGroup from "@mui/material/FormGroup";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Radio, RadioGroup } from "@mui/material";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ManageClients() {
  const history = useHistory();
  const [drawer, setDrawer] = useState(false);
  const [registerClientUserdrawer, setRegisterClientUserdrawer] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [action, setAction] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [subUserSnackbarOpen, setSubUserSnackbarOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [searchCategories, setSearchCategories] = useState(null);
  const [railSearchCategories, setRailSearchCategories] = useState(null);
  const [l1CacheGroups, setL1CacheGroups] = useState(null);
  const [searchComGroup, setSearchComGroup] = useState(null);
  const [supplierCommissionGroups, setSupplierCommissionGroups] = useState(null);
  const [markupGroup, setMarkupGroup] = useState(null);
  const [railMarkupGroup, setRailMarkupGroup] = useState(null);
  const [discountGroup, setDiscountGroup] = useState(null);
  const [serviceFeesGroup, setServiceFeesGroup] = useState(null);
  const [clientProfiles, setclientProfiles] = useState(null);
  const [exportClientProfiles, setexportclientProfiles] = useState([]);
  const [page, setPage] = React.useState(1);
  const [AlluserProfiles, setuserProfiles] = useState(null);
  const [pccList, setPccList] = useState([]);
  const [paymentgatewayList, setPaymentGatewayList] = useState([]);
  const [clientID, setClientID] = useState("");
  const [isActiveFilter, setisActiveFilter] = useState(false);
  const [isLiveFilter, setisLiveFilter] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [alertText, setalertText] = useState("");
  const [subUserAlert, setSubUserAlert] = useState("");
  const [mode, setMode] = useState("");
  const [clientClick, setClientClick] = useState("");
  const csvLink = useRef();
  const headers = [
    { label: "ID", key: "clientpforile.id" },
    { label: "ClientType", key: "clientpforile.clientType" },
    { label: "ClientCategory", key: "clientpforile.clientCategory" },
    { label: "ClientName", key: "clientpforile.clientName" },
    { label: "L1CacheGroup", key: "clientpforile.l1CacheGroupId" },
    { label: "PaymentType", key: "clientpforile.paymentType" },
    { label: "Email", key: "clientpforile.email" },
    { label: "PhoneNumber", key: "clientpforile.phoneNumber" },
    { label: "CurrencyCode", key: "clientpforile.currencyCode" },
    { label: "TimeZone", key: "clientpforile.timeZone" },
    { label: "Country", key: "clientpforile.countryCode" },
    { label: "IsActive", key: "clientpforile.isActive" },
    { label: "IsLive", key: "clientpforile.isLiveTicket" },
    { label: "Created By", key: "createdBy" },
    { label: "Created", key: "clientpforile.created" },
    { label: "Modified At", key: "clientpforile.modified" },
  ];
  const [showLoader, setShowLoader] = useState(false);
  const [cbFilter, setcbFilter] = useState({
    active: false,
    inactive: false,
    live: false,
    nonlive: false,
  });
  const [filteredData, setFilteredData] = useState([]);
  const [cbChecked, setCbChecked] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [value, setValue] = useState(0);
  const [addUserdrawer, setaddUserdrawer] = useState(false);

  let filter,
    clientIDFilter = "",
    clientNameFilter = "",
    clientEmailFilter = "",
    clientIsLiveFilter = "",
    clientIsActiveFilter = "",
    clientIsPendingFilter = "",
    clientIsNotActiveFilter = "";
  useEffect(() => {
    //Loading Necessory Data.
    loadNecessaryData();
  }, []);

  useEffect(() => { }, [alertText, mode]);

  const loadNecessaryData = async () => {

    // if (isUserHasPermission('ClientRead')) {
    setShowLoader(true);
    await getSearchCategories();
    await getL1CacheGroups();
    await getSearchComGroup();
    await getMarkupGroup();
    await getDiscountGroup();
    await getServiceFesGroup();
    // await getClients(false);
    await getUsers();
    await loadPCCDetails();
    await loadPaymentDetails();
    setShowLoader(false);
    // } else {
    //   history.push("/Unauthorized");
    // }
  };
  // useEffect(() => {
  //   getClients(true);
  // }, [isActiveFilter, isLiveFilter]);
  async function loadPaymentDetails() {
    setShowLoader(true);
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/PaymentGatewayList/GetClientPaymentGatewayList"
      )
      .then((response) => {
        let paymentdetails = response.data;
        setPaymentGatewayList(paymentdetails);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        console.log(err);
      });
  }
  async function loadPCCDetails() {
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/PccConfig")
      .then((response) => {

        let tmpArray = [];
        for (var i = 0; i < response.data.length; i++) {
          tmpArray.push({

            value: response.data[i]?.pccConfigs.pccCode,
            label: response.data[i]?.pccConfigs.pccCode,
          });
        }
        // console.log(tmpArray);
        setPccList(tmpArray);
      });
  }

  function getClientFilters(search) {
    if (clientID) {
      clientIDFilter = ",id==" + clientID;
    }
    if (clientName) {
      clientNameFilter = ",clientname@=" + clientName;
    }
    if (selectedFilter == "live") {
      clientIsLiveFilter = ",isLiveTicket==" + cbFilter.live;
    }
    if (selectedFilter == "notLive") {
      clientIsPendingFilter = ",isLiveTicket==" + !cbFilter.live;
    }
    if (selectedFilter == "active") {
      clientIsActiveFilter = ",isActive==" + cbFilter.active;
    }
    if (selectedFilter == "notActive") {
      clientIsNotActiveFilter = ",isActive==" + !cbFilter.active;
    }

    if (clientID !== "" || clientName !== "" || clientEmail !== "") {
      if (search) {
        setPage(1);
      }
      filter =
        clientIDFilter +
        clientNameFilter +
        clientIsActiveFilter +
        clientIsLiveFilter +
        clientIsPendingFilter +
        clientIsNotActiveFilter +
        "&email=" +
        clientEmail +
        "&page=" +
        page;
    } else {
      filter = "&page=" + page;
    }

    return filter;
  }

  const filteredClientProfiles = clientProfiles?.filter((profile) => {
    const isLive = profile.clientpforile.isLiveTicket;
    const isActive = profile.clientpforile.isActive;

    if (selectedFilter === "all") {
      return true;
    } else if (selectedFilter === "live") {
      return isLive;
    } else if (selectedFilter === "notLive") {
      return !isLive;
    } else if (selectedFilter === "active") {
      return isActive;
    } else if (selectedFilter === "notActive") {
      return !isActive;
    }

    return true;
  });

  async function getClients(search) {
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile?sorts=created&filters=" +
        getClientFilters(search)
      )
      .then((response) => {
        setclientProfiles(response.data);
      })
      .catch((err) => {
        // history.push("/Unauthorized");
      });
  }

  useEffect(() => {
    setPage(1);
    getClients(true);
  }, [selectedFilter]);

  async function getUsers() {
    let filter = "ClinetId==0";
    await axios
      .get(
        process.env.REACT_APP_IDENTITY_SERVER +
        "ids/User?page=" +
        page +
        "&pageSize=100&filters=" +
        filter
      )
      .then((response) => {
        // console.log(response.data.result);
        setuserProfiles(response.data);
      });
  }

  async function getSearchCategories() {
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/SearchCategory")
      .then((response) => {
        //console.log(response);
        setSearchCategories(response.data);
      });
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/RailSearchCategory")
      .then((response) => {
        //console.log(response);
        setRailSearchCategories(response.data);
      });
  }

  async function getL1CacheGroups() {
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/L1GroupCache")
      .then((response) => {
        //console.log(response);
        setL1CacheGroups(response.data);
      });
  }

  async function getSearchComGroup() {
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/SupplierCommissionGroup"
      )
      .then((response) => {
        //console.log(response);
        setSearchComGroup(response.data);
      });

    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/RailSupplierCommissionGroup"
      )
      .then((response) => {
        //console.log(response);
        setSupplierCommissionGroups(response.data);
      });
  }

  async function getMarkupGroup() {
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/MarkupGroup")
      .then((response) => {
        //console.log(response);
        setMarkupGroup(response.data);
      });
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/RailMarkupGroup")
      .then((response) => {
        //console.log(response);
        setRailMarkupGroup(response.data);
      });
  }

  async function getDiscountGroup() {
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/DiscountGroup")
      .then((response) => {
        //console.log(response);
        setDiscountGroup(response.data);
      });
  }

  async function getServiceFesGroup() {
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/ServiceFeeGroup")
      .then((response) => {
        //console.log(response);
        setServiceFeesGroup(response.data);
      });
  }

  const toggleOpenSearch = () => {
    setOpenSearch(!openSearch);
  };

  const handleSnackbarToggle = (text, mode) => {
    setalertText(text);
    setMode(mode);
    setDrawer(false);
    setSnackbarOpen(true);
    getClients(false);
  };
  const handleSubUserSnackbarToggle = (text, mode) => {
    setSubUserAlert(text);
    setMode(mode);
    setSubUserSnackbarOpen(true);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const toggleDrawer = (value) => async (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    await setDrawer(value);
    if (value === false) {
      setAction("")
    }
    //  await  setShowLoader(true);
    // await getClients(false);
    //  await setShowLoader(false);
  };

  const registerClientUsertoggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setRegisterClientUserdrawer(value);
  };

  async function myqueueExportExcel() {
    // setIsLoading(true);
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile?sorts=created&filters=" +
        getClientFilters(filter)
      )
      .then((response) => {
        let resultlist = [];
        let countrielist = countries;
        response.data.forEach((item) => {
          // Apply any necessary transformations
          item.clientpforile.isActive = item.clientpforile.isActive
            ? "Active"
            : "In active";
          item.clientpforile.isLiveTicket = item.clientpforile.isLiveTicket
            ? "Live"
            : "Pending";
          item.clientpforile.countryCode = (
            countrielist.find((v) => v.code === item.clientpforile.countryCode)
              ?.label +
            " (" +
            item.clientpforile.countryCode +
            ")"
          ).toString();
          item.clientpforile.created = moment(
            item.clientpforile.created
          ).format("DD/MM/YYYY");
          item.clientpforile.modified = moment(
            item.clientpforile.modified
          ).format("DD/MM/YYYY");

          const l1CacheGroup = l1CacheGroups.find((v) => v.id === item.clientpforile.l1CacheGroupId);
          item.clientpforile.l1CacheGroupId = l1CacheGroup ? l1CacheGroup.l1GroupName : 'None';

          resultlist.push(item);
          item.clientpforile.clientCategory = item.clientpforile.clientCategory;
        });

        // setexportclientProfiles((prevData) => [...prevData, ...resultlist]); commenting as we are getting duplicates
        setexportclientProfiles(resultlist);
        csvLink.current.link.click();
      });
  }

  const openDrawer = (selectedItem) => {
    setSelectedItem(selectedItem);
    setDrawer(true);
    setValue(0);
    setaddUserdrawer(false);
  };


  // const handleChange = (event) => {
  //   const { name, checked } = event.target;
  //   setcbFilter(prevState => ({ ...prevState, [name]: checked }));
  // };

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };


  return (
    <div className="client-configuration-container">
      <div className="configuration-content-container">
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <div className="client-search-configuration-container">
              <div className="configuration-table-container">
                <div className="configuration-title-add-button-container">
                  <div className="configuration-heading">
                    Manage Clients Profiles
                  </div>
                  <>
                    {isUserHasPermission("ClientWrite") ? (
                      <div className="search-icon-button-container">
                        <SearchIcon
                          fontSize="small"
                          className="search-icon-margin "
                          onClick={() => toggleOpenSearch()}
                        ></SearchIcon>
                        <div
                          className="add-configuration-button mt-0px pointer"
                          onClick={() => {
                            setAction("new");
                            setDrawer(true);
                          }}
                        >
                          Add New Client
                        </div>
                        <div
                          className="add-configuration-button mt-0px pointer"
                          style={{ marginLeft: "20px" }}
                          onClick={() => {
                            setRegisterClientUserdrawer(true);
                          }}
                        >
                          Register New Client
                        </div>
                      </div>
                    ) : (
                      <div className="search-icon-button-container">
                        <SearchIcon
                          fontSize="small"
                          className="search-icon-margin "
                          onClick={() => toggleOpenSearch()}
                        ></SearchIcon>
                      </div>
                    )}
                  </>
                </div>
                <Collapse in={openSearch} timeout="auto" unmountOnExit>
                  <div className="configuration-card-container">
                    <AddClientCard
                      setClientID={setClientID}
                      setClientName={setClientName}
                      setClientEmail={setClientEmail}
                      setisLiveFilter={setisLiveFilter}
                      setisActiveFilter={setisActiveFilter}
                      isLiveFilter={isLiveFilter}
                      isActiveFilter={isActiveFilter}
                      getClients={getClients}
                    ></AddClientCard>
                  </div>
                </Collapse>

                <div className="d-flex justify-content-between">
                  <div className="">
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="position" row>
                        <FormControlLabel
                          value="all"
                          control={
                            <Radio
                              checked={selectedFilter === "all"}
                              onChange={() => handleFilterChange("all")}
                            />
                          }
                          label="All"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="live"
                          control={
                            <Radio
                              checked={selectedFilter === "live"}
                              onChange={() => handleFilterChange("live")}
                            />
                          }
                          label="Live"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="notLive"
                          control={
                            <Radio
                              name="notActive"
                              checked={selectedFilter === "notLive"}
                              onChange={() => handleFilterChange("notLive")}
                            />
                          }
                          label="Pending"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="active"
                          control={
                            <Radio
                              name="isLive"
                              checked={selectedFilter === "active"}
                              onChange={() => handleFilterChange("active")}
                            />
                          }
                          label="Active"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="notActive"
                          control={
                            <Radio
                              name="notLive"
                              checked={selectedFilter === "notActive"}
                              onChange={() => handleFilterChange("notActive")}
                            />
                          }
                          label="In Active"
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginBottom: "5px" }}
                      onClick={() => myqueueExportExcel()}
                    >
                      Export Client
                    </Button>
                    <CSVLink
                      headers={headers}
                      className="hidden"
                      ref={csvLink}
                      target="_blank"
                      data={exportClientProfiles}
                      filename="ExportClientData"
                    />
                  </div>
                </div>

                <ClientTable
                  openDrawer={openDrawer}
                  setAction={setAction}
                  clientProfiles={filteredClientProfiles}
                  setPage={setPage}
                  page={page}
                  getClients={getClients}
                  showLoader={showLoader}
                  setClientClick={setClientClick}
                  l1CacheGroups={l1CacheGroups}
                  value={value}
                  setValue={setValue}
                ></ClientTable>
              </div>
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      <Drawer
        anchor="right"
        open={drawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <div className="drawer-min-width-40">
          <ClientDrawer
            selectedItem={selectedItem}
            toggleDrawer={toggleDrawer}
            action={action}
            setAction={setAction}
            handleSnackbarToggle={handleSnackbarToggle}
            handleSubUserSnackbarToggle={handleSubUserSnackbarToggle}
            getClients={getClients}
            searchCategories={searchCategories}
            l1CacheGroups={l1CacheGroups}
            searchComGroup={searchComGroup}
            markupGroup={markupGroup}
            AlluserProfiles={AlluserProfiles}
            pccList={pccList}
            paymentgatewayList={paymentgatewayList}
            clientClick={clientClick}
            value={value}
            setValue={setValue}
            addUserdrawer={addUserdrawer}
            setaddUserdrawer={setaddUserdrawer}
            railSearchCategories={railSearchCategories}
            railMarkupGroup={railMarkupGroup}
            discountGroup={discountGroup}
            serviceFeesGroup={serviceFeesGroup}
            supplierCommissionGroups={supplierCommissionGroups}
          ></ClientDrawer>
        </div>
      </Drawer>

      <Drawer
        anchor="right"
        open={registerClientUserdrawer}
        onClose={registerClientUsertoggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <div className="drawer-min-width-40">
          <RegisterClientUser
            registerClientUsertoggleDrawer={registerClientUsertoggleDrawer}
            handleSnackbarToggle={handleSnackbarToggle}
            setRegisterClientUserdrawer={setRegisterClientUserdrawer}
          ></RegisterClientUser>
        </div>
      </Drawer>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
      >
        {alertText && mode == "error" ? (
          <Alert onClose={handleSnackBarClose} severity="error">
            {alertText}
          </Alert>
        ) : (
          <Alert onClose={handleSnackBarClose} severity="success">
            {alertText}
          </Alert>
        )}
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={subUserSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSubUserSnackbarOpen(false)}
      >
        {subUserAlert && mode == "error" ? (
          <Alert onClose={() => setSubUserSnackbarOpen(false)} severity="error">
            {subUserAlert}
          </Alert>
        ) : (
          <Alert onClose={() => setSubUserSnackbarOpen(false)} severity="success">
            {subUserAlert}
          </Alert>
        )}
      </Snackbar>
    </div>
  );
}
