import React, { useState } from 'react';
import * as dateFns from 'date-fns';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import TrainIcon from '@mui/icons-material/Train';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import moment from 'moment';
const ScheduleCalendar = ({ scheduleState, setScheduleState, filterBookingsByTravelDate }) => {
    function getTotalBookingsCountByDate(targetDate) {
        let count =
            scheduleState?.bookings?.reduce((total, booking) => {
                if (moment.utc(booking?.TravelDate).format('YYYY-MM-DD') === moment(targetDate).format('YYYY-MM-DD')) {
                    return total + 1;
                }
                return total;
            }, 0);
        return count;
    }
    const renderHeader = () => {
        const dateFormat = "MMMM yyyy";
        return (
            <div className="header row flex-middle">
                <div className="col col-start">
                    <div className="icon" onClick={prevMonth}>
                        <ChevronLeftIcon />
                    </div>
                </div>
                <div className="col col-center">
                    <span>{dateFns.format(scheduleState?.currentMonth, dateFormat)}</span>
                </div>
                <div className="col col-end">
                    <div className="icon" onClick={nextMonth}>
                        <ChevronRightIcon />
                    </div>
                </div>
            </div>
        );
    };
    const renderCells = () => {
        const monthStart = dateFns.startOfMonth(scheduleState.currentMonth);
        const monthEnd = dateFns.endOfMonth(monthStart);
        const startDate = dateFns.startOfWeek(monthStart);
        const endDate = dateFns.endOfWeek(monthEnd);
        const dateFormat = "d";
        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = "";
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = dateFns.format(day, dateFormat);
                const cloneDay = day;
                const totalCount = getTotalBookingsCountByDate(day)
                days.push(
                    <div
                        className={`col cell ${!dateFns.isSameMonth(day, monthStart)
                            ? "disabled"
                            : dateFns.isSameDay(day, scheduleState.selectedDate) ? "selected" : ""
                            }`}
                        key={day}
                        onClick={() => onDateClick(cloneDay)}
                    >
                        {totalCount > 0 ?
                            <p className='countDisplay'>
                                {totalCount}
                            </p>
                            : ''
                        }
                        <p className="number">{formattedDate}</p>
                        <p className='iconDisplay'>
                            {
                                dateFns.isSameDay(day, scheduleState?.selectedDate) && scheduleState?.filteredBookings?.length > 0 ?
                                    <>
                                        {
                                            scheduleState.bookingTypes.length > 0 ?
                                                scheduleState.bookingTypes.map((bookingType, index) => (
                                                    (bookingType.type === 'flight' && bookingType.count > 0) ?
                                                        <FlightTakeoffIcon /> :
                                                        (bookingType.type === 'rail' && bookingType.count > 0) ?
                                                            <TrainIcon /> : ''
                                                ))
                                                : ''
                                        }

                                    </>
                                    : ''}
                        </p>
                        <span className="bg">{formattedDate}</span>
                    </div>
                );
                day = dateFns.addDays(day, 1);
            }
            rows.push(
                <div className="row" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="body">{rows}</div>;
    };

    const onDateClick = (day) => {
        filterBookingsByTravelDate(day);
    };

    const nextMonth = () => {
        setScheduleState({ ...scheduleState, currentMonth: dateFns.addMonths(scheduleState.currentMonth, 1) });
    };

    const prevMonth = () => {
        setScheduleState({ ...scheduleState, currentMonth: dateFns.subMonths(scheduleState.currentMonth, 1) });
    };

    return (
        <div className="TravelSchedule">
            {renderHeader()}
            {renderCells()}
        </div>
    );
};

export default ScheduleCalendar;
