import React, { useState, useEffect } from 'react'
import axios from 'axios';
import './PassengerProfile.css'
import TextField from "@mui/material/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from '@mui/material/Checkbox';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RiseLoading from '../Loader/RiseLoader';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import PassengerDrawer from './PassengerDrawer';
import { useTranslation } from 'react-i18next';


const PassengerProfile = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [clientID, setClientID] = useState(0);
    const [passengerData, setPassengerData] = useState([]);
    const [showDrawer, setShowDrawer] = React.useState(false);
    const [action, setAction] = useState("");
    const [checkSelectAll, setCheckSelectAll] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [passengerID, setPassengerID] = useState("");
    const [selectedPassenger, setSelectedPassenger] = useState("");
    const [selectedPassengerIDs, setSelectedPassengerIDs] = useState([]);
    const { t } = useTranslation();



    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        setClientID(user["clientref_id"]);
    }, []);

    useEffect(() => {
        if (clientID > 0) {
            getPassengerData();
        }
    }, [clientID]);

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setCheckSelectAll(event.target.checked)
            const allPassengerIDs = passengerData.map(passenger => passenger.passengerProfile.id);
            setSelectedPassengerIDs(allPassengerIDs);
        } else {
            setCheckSelectAll(event.target.checked)
            setSelectedPassengerIDs([]);
        }
    };

    const handleCheckboxChange = (event, passengerID) => {
        if (event.target.checked) {
            setSelectedPassengerIDs(prevSelected => [...prevSelected, passengerID]);
        } else {
            setSelectedPassengerIDs(prevSelected =>
                prevSelected.filter(id => id !== passengerID)
            );
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };


    const getPassengerData = async () => {
        setIsLoading(true);
        await axios
            .get(
                process.env.REACT_APP_CORE_URL +
                "api/Configuration/PassengerProfile?filters=clientId==" +
                clientID
            )
            .then((response) => {
                console.log("getPassengerData", response.data);
                setPassengerData(response.data)

                response.data.map((psg) => {
                    console.log('ds', psg.passengerProfile.firstName)
                })

                console.log(response.data[0].passengerProfile.firstName)


                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    };



    const handleDelete = async (PassengerID) => {
        setIsLoading(true);


        await axios
            .put(
                process.env.REACT_APP_CORE_URL +
                "api/Configuration/PassengerProfile/ids", PassengerID !== "" ? PassengerID : selectedPassengerIDs
            )
            .then((response) => {
                console.log("getPassengerData", response.data);
                getPassengerData();
                setIsLoading(false);
                setSelectedPassengerIDs([]);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });


    };

    const addNewProfile = () => {
        setAction("add");
        setShowDrawer(true);
    }

    const handleEdit = (PassengerID, actionName) => {

        setAction(actionName);
        setShowDrawer(true);
        console.log(passengerData[0].passengerProfile.id === PassengerID)
        setSelectedPassenger(passengerData.filter(passenger => (passenger.passengerProfile.id === PassengerID)));

    }

    useEffect(() => {
        console.log('setSelectedPassengerIDs', selectedPassengerIDs.join(','), selectedPassengerIDs.length)
    }, [selectedPassengerIDs]);

    useEffect(() => {
        console.log('state var', passengerData)
    }, [passengerData]);


    return (
        <div className='passenger-profile-main-div'>

            <div className='d-flex justify-content-between align-items-center div-bottom-border p-4'>
                <div className='fs-4'>{t("Traveller Profile")}</div>
                <div className='rounded p-1 srn-btn' style={{ cursor: 'pointer' }} onClick={addNewProfile}>{t("Add New Profile")}</div>
            </div>
            <div className='d-flex justify-content-between align-items-center div-bottom-border pt-4 pb-4 pe-4'>
                <div>
                    <TextField
                        className="bg-light rounded ms-5"
                        variant="outlined"
                        placeholder={t("Search")}
                        sx={{ minWidth: "400px" }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={searchQuery}
                        onChange={handleSearchChange}
                    /></div>

                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                        <Checkbox checked={selectedPassengerIDs.length === passengerData.length && checkSelectAll} onChange={handleSelectAll} />
                    </div>
                    <div>

                        {t("Select All")}
                    </div>

                    <div className='srn-btn rounded p-1 ms-2 text-center' style={{ cursor: "pointer" }} onClick={() => handleDelete("")}>{t("Delete")}</div>

                </div>
            </div>
            {
                passengerData && passengerData.length > 0 ? (
                    passengerData.filter(
                        (passenger) =>
                            passenger.passengerProfile.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            passenger.passengerProfile.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            passenger.passengerProfile.mobile.toLowerCase().includes(searchQuery.toLowerCase())
                    ).map((passenger) => (
                        <div key={passenger.passengerProfile.id} className='d-flex justify-content-between align-items-center p-3 div-bottom-border'>
                            <div className='d-flex align-items-center'>
                                <div><Checkbox checked={checkSelectAll || selectedPassengerIDs.includes(passenger.passengerProfile.id)}
                                    onChange={(event) => handleCheckboxChange(event, passenger.passengerProfile.id)}
                                /></div>
                                <div className='ms-2' ><AccountCircleRoundedIcon /></div>
                                <div className='ms-2' style={{ width: '12.5rem' }} >{passenger.passengerProfile.firstName + " " + passenger.passengerProfile.lastName}</div>
                            </div>
                            <div className='ms-3' style={{ width: '12.5rem' }}>
                                <div style={{ fontSize: '12px' }}>Mobile</div>
                                <div>{passenger.passengerProfile.mobile}</div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <div style={{ cursor: "pointer" }} onClick={() => handleEdit(passenger.passengerProfile.id, "view")}><EditOutlinedIcon /></div>
                                <div className='ms-3' style={{ cursor: "pointer" }} onClick={() => handleDelete([passenger.passengerProfile.id])}><DeleteOutlineOutlinedIcon /></div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="text-center mt-5">{t("No Results Found")}</div>
                )
            }


            <Drawer
                anchor={"right"}
                open={showDrawer}
                onClose={() => setShowDrawer(false)}

            >
                <Box
                    sx={{ width: 'auto' }}
                    role="presentation"
                >
                    <PassengerDrawer
                        setShowDrawer={setShowDrawer}
                        action={action}
                        setAction={setAction}
                        setIsLoading={setIsLoading}
                        clientID={clientID}
                        getPassengerData={getPassengerData}
                        passengerData={passengerData}
                        passengerID={passengerID}
                        selectedPassenger={selectedPassenger}
                    />
                </Box>
            </Drawer>

            {isLoading && <RiseLoading MarkupGroup={isLoading} />}

        </div>

    )
}

export default PassengerProfile