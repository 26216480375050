import { Table } from "@mui/joy";
import moment from "moment";
import BootstrapTooltip1 from "../BootstrapToolTip/BootstrapToolTip1";
import { useTranslation } from "react-i18next";
export default function PassengerInfo({ flight }) {
    const { t } = useTranslation();
    return (
        <div className='flight-itenary'>
            <div className='flight-itenary-header'>
                <div className='flight-itenary-title-container'>{t("Passenger Detail")}s</div>
            </div>
            <Table>
                <thead className='mui-table-header'>
                    <BootstrapTooltip1 title={t("PaxType/ Title/ First/ Last/ Gender")}>
                        <th className="flightItineraryColumnHeader" style={{ width: '15vw' }}>
                            {t("PaxType/ Title/ First/ Last/ Gender")}{" "}
                        </th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title={t("Date of Birth")} style={{ width: '8vw' }}>
                        <th className="flightItineraryColumnHeader">{t("Date of Birth")}</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title={t("Passport")}>
                        <th className="flightItineraryColumnHeader" style={{ width: '8vw' }}>{t("Passport")}</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title={t("Passenger Nationality")}>
                        <th className="flightItineraryColumnHeader" style={{ width: '8vw' }}>{t("Passenger Nationality")}</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title={t("Passport DOE")}>
                        <th className="flightItineraryColumnHeader" style={{ width: '8vw' }}>{t("Passport DOE")}</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title={t("Pan")}>
                        <th className="flightItineraryColumnHeader" style={{ width: '6vw' }}>{t("Pan")}</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title={t("Ticket No")}>
                        <th className="flightItineraryColumnHeader" style={{ width: '8vw' }}>{t("Ticket No")}</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title={t("Baggage Selected")}>
                        <th className="flightItineraryColumnHeader">{t("Baggage Selected")}</th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title={t("Special Request/ Meal Request")}>
                        <th className="flightItineraryColumnHeader">
                            {t("Special Request/ Meal Request")}
                        </th>
                    </BootstrapTooltip1>
                    <BootstrapTooltip1 title={t("Seat")}>
                        <th className="flightItineraryColumnHeader">{t("Seat")}</th>
                    </BootstrapTooltip1>
                </thead>
                <tbody>
                    {flight.passengers && flight.passengers.length > 0
                        ? flight.passengers
                            .sort(function (a, b) {
                                if (a.paxType < b.paxType) {
                                    return -1;
                                }
                                if (a.paxType > b.paxType) {
                                    return 1;
                                }
                                return 0;
                            })
                            .map((passenger, index) => (
                                <tr hover aria-checked role='checkbox' tabIndex={-1}>
                                    <td className="flightItineraryColumn">
                                        {passenger.paxType +
                                            " / " +
                                            passenger.title +
                                            " / " +
                                            passenger.firstName +
                                            " / " +
                                            passenger.lastName +
                                            " / " +
                                            passenger.genderType}
                                    </td>
                                    <td className="flightItineraryColumn">
                                        {passenger.dob
                                            ? moment(passenger.dob).format("Do MMM YYYY")
                                            : "-"}
                                    </td>
                                    <td className="flightItineraryColumn">
                                        {passenger.passportNumber
                                            ? passenger.passportNumber
                                            : `NA`}
                                    </td>
                                    <td className="flightItineraryColumn">
                                        {passenger.passengerNationality
                                            ? passenger.passengerNationality
                                            : `NA`}
                                    </td>
                                    <td className="flightItineraryColumn">
                                        {passenger.passportDOE
                                            ? moment(passenger.passportDOE).format("Do MMM YYYY")
                                            : `-`}
                                    </td>
                                    <td className="flightItineraryColumn">
                                        {passenger.panCard ? passenger.panCard : `NA`}
                                    </td>
                                    <td className="flightItineraryColumn">
                                        {passenger.ticketNumber ? passenger.ticketNumber : `NA`}
                                    </td>

                                    <td className="flightItineraryColumn">
                                        {flight.baggages?.filter(
                                            (item) =>
                                                item?.paxName ===
                                                passenger.firstName + " " + passenger.lastName
                                        ).length > 0
                                            ? flight.baggages
                                                ?.filter(
                                                    (item) =>
                                                        item?.paxName ===
                                                        passenger.firstName + " " + passenger.lastName
                                                )
                                                .map((bag, index) => (
                                                    <>
                                                        {bag.checkInBag} {flight.currency} {"  "}{" "}
                                                        {bag.amount}
                                                        {<br></br>}
                                                        {flight.baggages?.filter(
                                                            (item) =>
                                                                item.paxName ===
                                                                passenger.firstName +
                                                                " " +
                                                                passenger.lastName
                                                        ).length -
                                                            1 <
                                                            index
                                                            ? "\n"
                                                            : ""}{" "}
                                                    </>
                                                ))
                                            : "NA"}
                                    </td>

                                    {flight.meals.length > 0 ? (
                                        <>
                                            {flight.meals?.filter(
                                                (item) =>
                                                    item.paxName ===
                                                    passenger.firstName + " " + passenger.lastName
                                            ).length > 0 ? (
                                                <td className="flightItineraryColumn">
                                                    {flight.meals?.filter(
                                                        (item) =>
                                                            item?.paxName ===
                                                            passenger.firstName + " " + passenger.lastName
                                                    ).length > 0 &&
                                                        flight.meals
                                                            ?.filter(
                                                                (item) =>
                                                                    item?.paxName ===
                                                                    passenger.firstName +
                                                                    " " +
                                                                    passenger.lastName
                                                            )
                                                            .map((meal, index) => (
                                                                <>
                                                                    {meal.description} {flight.currency} {"  "}{" "}
                                                                    {meal.amount}
                                                                    {<br></br>}
                                                                    {flight.meals?.filter(
                                                                        (item) =>
                                                                            item.paxName ===
                                                                            passenger.firstName +
                                                                            " " +
                                                                            passenger.lastName
                                                                    ).length -
                                                                        1 <
                                                                        index
                                                                        ? "\n"
                                                                        : ""}{" "}
                                                                </>
                                                            ))}
                                                </td>
                                            ) : (
                                                <td className="flightItineraryColumn">NA</td>
                                            )}
                                        </>
                                    ) : (
                                        <td className="flightItineraryColumn">NA</td>
                                    )}
                                    <td className="flightItineraryColumn">
                                        {passenger.seatPref ? passenger.seatPref : `NA`}
                                    </td>
                                </tr>
                            ))
                        : null}
                </tbody>
            </Table>
        </div>
    );
}
