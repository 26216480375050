import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import './Reissue.css'
import { formatDate } from '../../../services/dateFormatter';
import SkeletonView from '../../SkeletonView/SkeletonView';
import Textarea from '@mui/joy/Textarea';
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ValidatorForm } from 'react-material-ui-form-validator';
const Reissue = ({ reissuePopUp, setReissuePopUp, results, pnr, reissueRequest, wholeItinerarySelected, setWholeItinerarySelected,
    setCancelDetails, cancelDetails, onwardDate, setOnwardDate, returnDate, setReturnDate
}) => {
    const { t } = useTranslation();
    const [selectedPax, setSelectedPax] = useState({});
    const [disabledPax, setDisabledPax] = useState({});
    const [isReissueDisabled, setIsReissueDisabled] = useState(false);
    const today = dayjs();
    const [itOnwardDate, setItOnwardDate] = useState(null);
    const [itReturnDate, setItReturnDate] = useState(null);
    const [dateConflict, setDateConflict] = useState(false);
    const [conflictIndex, setConflictIndex] = useState(false);
    const [dateErrors, setDateErrors] = useState({});

    const handleSelectPax = (itineraryId, paxId) => {
        const newSelectedPax = { ...selectedPax };
        const newDisabledPax = { ...disabledPax };
        const newCancelDetails = { ...cancelDetails };

        const isSelected = newSelectedPax[itineraryId]?.includes(paxId);
        const segGroupIndex = newCancelDetails.flight[0].segGroups.findIndex(seg => seg.id === itineraryId);
        if (segGroupIndex === -1) return;

        if (isSelected) {
            newSelectedPax[itineraryId] = newSelectedPax[itineraryId].filter(id => id !== paxId);

            newCancelDetails.flight[0].segGroups.map((segGroup, index) => {
                if (index === segGroupIndex) {
                    segGroup.passengers.map(pax => {
                        if (pax.id === paxId) {
                            pax.isChecked = false;
                        }
                    });
                }
            });

        } else {
            if (!newSelectedPax[itineraryId]) {
                newSelectedPax[itineraryId] = [];
            }
            newSelectedPax[itineraryId].push(paxId);
            newCancelDetails.flight[0].segGroups.map((segGroup, index) => {
                if (index === segGroupIndex) {
                    segGroup.passengers.map(pax => {
                        if (pax.id === paxId) {
                            pax.isChecked = true;
                        }
                    });
                }
            });
        }

        setSelectedPax(newSelectedPax);
        setDisabledPax(newDisabledPax);
        setCancelDetails(newCancelDetails);
    };


    const handleSelectAll = (itineraryId) => {
        let newSelectedPax = { ...selectedPax };
        let newDisabledPax = { ...disabledPax };
        const newCancelDetails = { ...cancelDetails };
        const segGroupIndex = newCancelDetails.flight[0].segGroups.findIndex(seg => seg.id === itineraryId);
        if (segGroupIndex === -1) return;

        const itinerary = results.find(it => it.id === itineraryId);

        if (newSelectedPax[itineraryId]?.length === itinerary.passengers.length) {
            newSelectedPax = {};
            newDisabledPax = {};

            newCancelDetails.flight[0].segGroups.forEach((segGroup, index) => {
                if (index === segGroupIndex) {
                    segGroup.isAllChecked = false;
                    segGroup.passengers.forEach(pax => {
                        pax.isChecked = false;
                        pax.isDisabled = false;
                    });
                }
            });
        } else {
            newSelectedPax[itineraryId] = itinerary.passengers.map(pax => pax.id);
            newDisabledPax[itineraryId] = itinerary.passengers.map(pax => pax.id);
            newCancelDetails.flight[0].segGroups.forEach((segGroup, index) => {
                if (index === segGroupIndex) {
                    segGroup.isAllChecked = true;
                    segGroup.passengers.forEach(pax => {
                        pax.isChecked = true;
                        pax.isDisabled = true;
                    });
                }
            });
        }

        setSelectedPax(newSelectedPax);
        setDisabledPax(newDisabledPax);
        setCancelDetails(newCancelDetails);
    };


    useEffect(() => {
        let wholeItineraryChecked = true;
        let reissueDisabled = false;

        results.forEach(itinerary => {
            const segGroupIndex = cancelDetails.flight[0].segGroups.findIndex(seg => seg.id === itinerary.id);

            const allPassengersChecked = cancelDetails.flight[0].segGroups[segGroupIndex].passengers.every(pax => pax.isChecked);
            cancelDetails.flight[0].segGroups[segGroupIndex].isAllChecked = allPassengersChecked;

            if (!allPassengersChecked) {
                wholeItineraryChecked = false;
            }

            const allNonreissueStatus = cancelDetails.flight[0].segGroups[segGroupIndex].passengers.every(
                pax => !["none", "ticketed"].includes(pax.flightTicketStatus.toLowerCase())
            );

            if (allNonreissueStatus) {
                reissueDisabled = true;
            }
        });

        setItOnwardDate(results[0]?.departureOn);
        setItReturnDate(results[1]?.departureOn);
        setWholeItinerarySelected(wholeItineraryChecked);
        setIsReissueDisabled(reissueDisabled);

    }, [results, selectedPax]);


    const handleWholeItinerarySelection = () => {
        const isSelected = wholeItinerarySelected;

        results.forEach(itinerary => {
            const segGroupIndex = cancelDetails.flight[0].segGroups.findIndex(seg => seg.id === itinerary.id);
            cancelDetails.flight[0].segGroups[segGroupIndex].isAllChecked = !isSelected;

            cancelDetails.flight[0].segGroups[segGroupIndex].passengers.forEach(pax => {
                pax.isChecked = !isSelected;
                pax.isDisabled = !isSelected;
            });
        });

        setWholeItinerarySelected(!isSelected);
    };

    const reissueNow = () => {
        reissueRequest();
    };

    useEffect(() => {
        console.log('onwardDate', onwardDate);
        console.log('returnDate', returnDate);
        console.log('dayjs(itReturnDate)', dayjs(itReturnDate));
        console.log('dayjs(itOnwardDate)', dayjs(itOnwardDate));

        const onward = dayjs(onwardDate);
        const returnDt = returnDate ? dayjs(returnDate) : null; // Handle null returnDate

        if (onwardDate || returnDate) {
            // Check if onward date is after both existing return date and new return date
            if (onward.isAfter(dayjs(itReturnDate)) && (!returnDt || onward.isAfter(returnDt))) {
                setDateConflict("*Selected date for onward trip is greater than the existing date for return trip");
                setConflictIndex(0);
            }
            // Check if existing onward date is after new return date
            else if (dayjs(itOnwardDate).isAfter(returnDt) && (!onward || onward.isAfter(returnDt))) {
                setDateConflict("*Selected date for return trip is less than the existing date for onward trip");
                setConflictIndex(1);
            }
            // No conflicts
            else {
                setDateConflict("");
                setConflictIndex(-1);
            }
        } else {
            setDateConflict("");
            setConflictIndex(-1);
        }
    }, [onwardDate, returnDate]);


    useEffect(() => {
        const newDateErrors = {};

        if (wholeItinerarySelected && results.length > 1) {
            if (!onwardDate) {
                newDateErrors["onwardDate"] = t("Onward date is required");
            }
            if (!returnDate) {
                newDateErrors["returnDate"] = t("Return date is required");
            }
        } else {
            results.forEach((itinerary, index) => {
                const segGroupId = itinerary.id;
                console.log('selectedPax', selectedPax)
                const isAnyPaxSelected = selectedPax[segGroupId]?.length > 0;

                if (isAnyPaxSelected) {
                    if (index === 0 && !onwardDate) {
                        newDateErrors["onwardDate"] = t("Onward date is required");
                    } else if (index === 1 && !returnDate) {
                        newDateErrors["returnDate"] = t("Return date is required");
                    }
                }
            });
        }

        setDateErrors(newDateErrors);
    }, [selectedPax, onwardDate, returnDate, wholeItinerarySelected]);

    return (
        <>
            <Dialog className="" style={{ inset: "40px" }} open={reissuePopUp} onClose={() => setReissuePopUp(false)} maxWidth="md" fullWidth >
                <DialogTitle>
                    <div className='d-flex justify-content-between'>
                        <div></div>
                        <div><Button onClick={() => {
                            setReissuePopUp(false);
                            setOnwardDate(null);
                            setReturnDate(null);
                            setConflictIndex(-1);
                            setDateConflict("");
                            setDateErrors({});
                        }} color="inherit">
                            <CloseIcon />
                        </Button>
                        </div>
                    </div>
                </DialogTitle>

                <DialogContent className=''>
                    <div className="reissue">
                        <ValidatorForm>
                            <div className='d-flex justify-content-between'>
                                <div className='text-bold'>Reissue</div>
                                <div>
                                    <Checkbox
                                        onChange={handleWholeItinerarySelection}
                                        checked={wholeItinerarySelected}
                                    /> {t("Select Whole Itinerary")}
                                </div>
                            </div>

                            {
                                results[0].origin ? (<>
                                    <div >
                                        {results?.map((itinerary, index) => (
                                            <div key={itinerary.id} className="itinerary mt-3 mb-3">
                                                {itinerary.segments.map((segment, segIndex) => (
                                                    <div className='d-flex justify-content-between border border-1 border-danger p-2 rounded mt-1'>
                                                        <div>{segment.origin} ---&gt; {segment.destination}</div>
                                                        <div>{formatDate(segment.departureOn)}</div>
                                                        <div> {segment.mrkAirline + "-" + segment.flightNum}</div>
                                                        <div>PNR- {pnr}</div>
                                                        <div>
                                                            {segIndex === 0 ? <>
                                                                <Checkbox
                                                                    onChange={() => handleSelectAll(itinerary.id)}
                                                                    checked={itinerary.isAllChecked}
                                                                    disabled={itinerary.isDisabled}
                                                                /> {t("Select All")}
                                                            </> : <div style={{ width: "90px" }}></div>
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                                {itinerary.passengers
                                                    .sort((a, b) => a.paxType.localeCompare(b.paxType))
                                                    .map(pax => (
                                                        <div key={pax.id} className='d-flex justify-content-between mt-3 mb-3'>
                                                            <div style={{ width: "50%" }}>
                                                                <Checkbox
                                                                    onChange={() => handleSelectPax(itinerary.id, pax.id, pax.ticketNumber)}
                                                                    checked={pax.isChecked || pax.isCancelled || false}
                                                                    disabled={pax.isDisabled || pax.isCancelled}
                                                                />
                                                                {pax?.firstName + " " + pax?.lastName} ({pax?.paxType})
                                                            </div>
                                                            <div className='d-flex justify-content-between' style={{ width: "50%" }}>
                                                                <div> {t("Ticket Number")}: {pax?.ticketNumber} </div>
                                                                <div className='text-success'>{pax?.flightTicketStatus}</div>
                                                            </div>

                                                        </div>
                                                    ))}
                                                <div style={{ width: "13rem" }} className='border border-2 rounded mt-4'>
                                                    {console.log('selectedPax[itinerary.id]?.length > 0', selectedPax[itinerary.id]?.length > 0)}
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            label={t("Select Date")}
                                                            disablePast
                                                            disabled={!(selectedPax[itinerary.id]?.length > 0)}
                                                            format='DD/MM/YYYY'
                                                            onChange={(newValue) => {
                                                                index === 0 ?
                                                                    setOnwardDate(newValue.$d ? newValue.$d : null) :
                                                                    setReturnDate(newValue.$d ? newValue.$d : null);
                                                            }}
                                                            value={
                                                                index === 0 ?
                                                                    (onwardDate ? dayjs(onwardDate) : null) :
                                                                    (returnDate ? dayjs(returnDate) : null)
                                                            }
                                                            // maxDate={index === 0 && returnDate ? dayjs(returnDate) : null}
                                                            minDate={index === 1 && onwardDate && dateConflict ? dayjs(onwardDate) : null}
                                                        />
                                                    </LocalizationProvider>
                                                    {dateErrors["onwardDate"] && index === 0 && (
                                                        <span className="text-danger" style={{ fontSize: "0.8rem" }}>
                                                            {dateErrors["onwardDate"]}
                                                        </span>
                                                    )}
                                                    {dateErrors["returnDate"] && index === 1 && (
                                                        <span className="text-danger" style={{ fontSize: "0.8rem" }}>
                                                            {dateErrors["returnDate"]}
                                                        </span>
                                                    )}
                                                </div>
                                                {index === conflictIndex && dateConflict && <span className='text-danger' style={{ fontSize: "0.8rem" }}>{dateConflict}</span>}
                                            </div>
                                        ))}
                                    </div>
                                    <Divider sx={{ opacity: 1, borderBottomWidth: "medium" }} className='mt-5' />

                                    <div className='mt-3'>
                                        <p>
                                            <span className='text-danger'>Note:</span><br></br>
                                            1. {t("If whole itinerary selected, then it will Auto exchange the Tickets")}<br></br>
                                            1. {t("Partial Exchange will create offline request")}<br></br>
                                        </p>
                                    </div>
                                    <div>
                                        <div className='text-bold'>{t("Remark")}</div>
                                        <Textarea sx={{ minHeight: "6rem" }} />
                                    </div>
                                </>)
                                    :
                                    (
                                        <SkeletonView />
                                    )}

                            <div className='d-flex justify-content-between mt-3'>
                                <div>{t("I accept the Terms and Conditions by clicking on Submit Now")}</div>
                                <Button disabled={isReissueDisabled || conflictIndex !== -1 || !(Object.keys(dateErrors).length === 0)} onClick={reissueNow} className='srn-btn'>{t("Submit Now")}</Button>
                            </div>
                        </ValidatorForm>
                    </div>
                </DialogContent >


            </Dialog >
        </>
    );
};

export default Reissue;