import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import "./Header.css";
import { isUserHasPermission } from "../../services/AuthService";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
export function Megamenu({ closeMenu }) {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const handleMenuClick = (event) => {
    if (event.target.className == "menu_item") {
      closeMenu();
    } else {
      return;
    }
  };

  const handleLinkClick = (event) => {

    if (location.pathname === '/OrderTraceReport') {
      localStorage.removeItem('orderTraceReportData');
      window.location.reload();
    } else {
      history.push('/OrderTraceReport');
    }
  };

  return (
    <Container onClick={handleMenuClick}>
      <Row className="menu_row">
        {isUserHasPermission("PendingApprovalQueueRead") ||
          isUserHasPermission("ClientFinanceApproval") ||
          isUserHasPermission("PendingApprovalQueueRead") ||
          isUserHasPermission("MyBookingQueueRead") ||
          isUserHasPermission("FSHRead") ||
          isUserHasPermission("VendorFinanceApproval") ||
          isUserHasPermission("ImportBookingRead") ||
          isUserHasPermission("ImportBookingWrite") ||
          isUserHasPermission("MyBookingQueueWrite") ||
          isUserHasPermission("PendingApprovalQueueWrite") ? (
          <Col>
            <h5 className="menu_sub_header">Queue &amp; Others</h5>
            {(isUserHasPermission("PendingApprovalQueueRead") ||
              isUserHasPermission("ClientFinanceApproval") ||
              isUserHasPermission("VendorFinanceApproval") ||
              isUserHasPermission("PendingApprovalQueueWrite")) && (
                <Link className="menu_item" to="/PendingApprovalQueue">
                  {t("Pending Approval Queue")}
                </Link>
              )}
            {(isUserHasPermission("MyBookingQueueRead") ||
              isUserHasPermission("MyBookingQueueWrite")) && (
                <Link className="menu_item" to="/QueueTable">
                  {t("My Booking Queue")}
                </Link>
              )}
            {isUserHasPermission("FSHRead") && (
              <Link className="menu_item" to="/FSH">
                {t("FSH")}
              </Link>
            )}
            {(isUserHasPermission("ImportBookingRead") ||
              isUserHasPermission("ImportBookingWrite")) && (
                <Link className="menu_item" to="/importbooking">
                  {t("Import Booking")}
                </Link>
              )}
            <Link className="menu_item" to="/OfflineBooking">
              {t("Offline Booking")}
            </Link>
            <Link className="menu_item" to="/flight/ViewCancellation">
              {t("View Cancellation")}
            </Link>
            <Link className="menu_item" to="/ImportPNR">
              {t("Import PNR Ticketing")}
            </Link>
            <Link className="menu_item" to="/ViewReissue">
              {t("View Reissue")}
            </Link>
          </Col>
        ) : (
          ""
        )}
        {isUserHasPermission("DebitTransactionRead") ||
          isUserHasPermission("FlightReconcilliationRead") ? (
          <Col>
            <h5 className="menu_sub_header">Finance</h5>
            {isUserHasPermission("DebitTransactionRead") && (
              <Link className="menu_item" to="/DebitTransactions/0">
                {t(" Debit Transactions")}
              </Link>
            )}
            {isUserHasPermission("FlightReconcilliationRead") && (
              <Link className="menu_item" to="/Reco">
                {t("Flight Reconcilliation")}
              </Link>
            )}
            <Link className="menu_item" to="/BankInfo">
              {t("Bank Info")}
            </Link>
            <Link className="menu_item" to="/OperatingOND">
              {t(" Operating OND")}
            </Link>
            <Link className="menu_item" to="/Recharge">
              {t("Recharge Transactions")}
            </Link>
            {/* <Link className="menu_item" to="/TravelSchedule">
                Travel Schedule
              </Link> */}
          </Col>
        ) : (
          ""
        )}
        {isUserHasPermission("PCCRead") ||
          isUserHasPermission("SupplierCommissionGroupRead") ||
          isUserHasPermission("SearchCategoryRead") ||
          isUserHasPermission("SearchCategoryGroupRead") ||
          isUserHasPermission("TicketingConfigurationRead") ||
          isUserHasPermission("MarkupGroupRead") ||
          isUserHasPermission("PCCWrite") ||
          isUserHasPermission("SupplierCommissionGroupWrite") ||
          isUserHasPermission("SearchCategoryWrite") ||
          isUserHasPermission("TicketingConfigurationWrite") ||
          isUserHasPermission("MarkupGroupWrite") ? (
          <Col>
            <h5 className="menu_sub_header">Configurations</h5>
            {(isUserHasPermission("PCCRead") ||
              isUserHasPermission("PCCWrite")) && (
                <Link className="menu_item" to="/Supplier">
                  {t("PCC")}
                </Link>
              )}
            {(isUserHasPermission("SupplierCommissionGroupRead") ||
              isUserHasPermission("SupplierCommissionGroupWrite")) && (
                <Link className="menu_item" to="/SupplierCommissionGroup">
                  {t("Supplier Commission")}
                </Link>
              )}
            {(isUserHasPermission("SearchCategoryRead") ||
              isUserHasPermission("SearchCategoryGroupRead") ||
              isUserHasPermission("SearchCategoryWrite")) && (
                <Link className="menu_item" to="/SearchCategory">
                  {t("Search Category")}
                </Link>
              )}
            {/* {(isUserHasPermission("L1CacheGroupRead") ||
                  isUserHasPermission("L1CacheGroupWrite")) && ( */}
            <Link className="menu_item" to="/L1CacheGroup">
              {t("L1 Cache Group")}
            </Link>
            {/* )} */}
            {(isUserHasPermission("TicketingConfigurationRead") ||
              isUserHasPermission("TicketingConfigurationWrite")) && (
                <Link className="menu_item" to="/TicketingConfiguration">
                  {t("Ticketing Configuration")}
                </Link>
              )}
            {(isUserHasPermission("MarkupGroupRead") ||
              isUserHasPermission("MarkupGroupWrite")) && (
                <Link className="menu_item" to="/MarkupGroup">
                  {t("Markup")}
                </Link>
              )}
            {/* {(isUserHasPermission("PassthroughConfigurationRead") ||
                isUserHasPermission("PassthroughConfigurationWrite")) && ( */}
            <Link className="menu_item" to="/PassthroughConfiguration">
              {t("Passthrough Configuration")}
            </Link>
            <Link className="menu_item" to="/BookingSwitches">
              {t("Booking Switches")}
            </Link>
            {/* )} */}
            <Link className="menu_item" to="/ATCConfiguration">
              {t("Auto Ticket Changer Configuration")}
            </Link>
          </Col>
        ) : (
          ""
        )}
        {isUserHasPermission("ClientRead") ||
          isUserHasPermission("ClientWrite") ||
          isUserHasPermission("StaffRead") ||
          isUserHasPermission("StaffWrite") ||
          isUserHasPermission("RoleRightsRead") ||
          isUserHasPermission("RoleRightsWrite") ||
          isUserHasPermission("VendorConfigurationRead") ||
          isUserHasPermission("VendorOnBoarding") ? (
          <Col>
            <h5 className="menu_sub_header">{t("Administration")}</h5>
            {(isUserHasPermission("ClientRead") ||
              isUserHasPermission("ClientWrite")) && (
                <Link className="menu_item" to="/ManageClients">
                  {t("Clients")}
                </Link>
              )}
            {(isUserHasPermission("StaffRead") ||
              isUserHasPermission("StaffWrite")) && (
                <Link className="menu_item" to="/ManageUser/0">
                  {t("Staffs")}
                </Link>
              )}
            {(isUserHasPermission("VendorConfigurationRead") ||
              isUserHasPermission("VendorOnBoarding")) && (
                <Link className="menu_item" to="/Vendor">
                  {t("Vendors")}
                </Link>
              )}
            {(isUserHasPermission("RoleRightsRead") ||
              isUserHasPermission("RoleRightsWrite")) && (
                <Link className="menu_item" to="/RoleRights">
                  {t("Roles & Rights")}
                </Link>
              )}
            {/* {(isUserHasPermission("RoleRightsRead") ||
                  isUserHasPermission("RoleRightsWrite")) && ( */}
            <Link className="menu_item" to="/ClientOnBoardingQueue">
              {t("Client Onboarding Queue")}
            </Link>
            {/* )} */}
          </Col>
        ) : (
          ""
        )}
        <Col>
          <h5 className="menu_sub_header">Void</h5>
          <Link className="menu_item" to="/VoidAdmin">
            {t("Void Management")}
          </Link>
          <Link className="menu_item" to="/VoidRulesConfiguration">
            {t("Add Void Configuration")}
          </Link>
          <Link className="menu_item" to="/VoidQuote">
            {t("Void Quote")}
          </Link>
          <Link className="menu_item" to="/Payment">
            {t("Payment")}
          </Link>

          {/* <Link className="menu_item" to="/PaymentType">Payment Type</Link>
        <Link className="menu_item" to="/PaymentHistory">Payment History</Link> */}
        </Col>
        <Col>
          <h5 className="menu_sub_header">Analytics</h5>
          <Link className="menu_item" to="/Dashboard">
            {t("My Dashboard")}
          </Link>
          <Link className="menu_item" to="/ClientBalanceReport">
            {t("Client Balance Report")}
          </Link>
          <Link className="menu_item" to="/OrderTraceReport" onClick={() => handleLinkClick()}>
            {t("Order Trace Report")}
          </Link>
          <Link className="menu_item" to="/OfflineImportReport">
            {t("Booking Report")}
          </Link>
        </Col>
      </Row>
    </Container>
  );
}
