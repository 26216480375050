import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import useCheckboxStyles from "../../../hooks/useCheckboxStyles";
import clsx from "clsx";
import "./FilterComponents.css";
import findAirlinebyCode from "../../../functions/findAirlineByCode";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CurrencyCodeComponent from "../../../providers/CurrencyCode";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";

function Airlines({ search, setSearch, toggleDrawer, currencyCode }) {
  const classes = useCheckboxStyles();
  const [showMore, setShowMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [dataFiltered, setDataFiltered] = useState(search.filters.airlines);

  const { t } = useTranslation();
  useEffect(() => {
    setDataFiltered(structuredClone(search.filters.airlines));
  }, [search]);
  useEffect(() => { }, [dataFiltered]);

  const handleChange = (event) => {
    const existingSearch = { ...search };
    existingSearch.filters.airlines.find(
      (item) => item.airline === event.target.name
    ).checked = event.target.checked;
    if (dataFiltered && dataFiltered.length) {
      dataFiltered.find((item) => item.airline === event.target.name).checked =
        event.target.checked;
    }
    setSearch(existingSearch);
  };
  const filterData = () => {
    let temp = searchQuery ? searchQuery.toLowerCase() : "";
    if (!temp) {
      setDataFiltered(structuredClone(search.filters.airlines));
    } else {
      let d = structuredClone(search.filters.airlines).filter((d) =>
        findAirlinebyCode(d.airline).toLowerCase().includes(temp)
      );
      return setDataFiltered(d);
    }
  };
  const setDefaults = () => {
    setSearchQuery("");
    setDataFiltered(structuredClone(search.filters.airlines));
  };
  return (
    <div className="number-of-stops-container mt-12 mb-16">
      <div className="searchBar">
        <TextField
          id="search-bar"
          onInput={(e) => {
            e.target.value ? setSearchQuery(e.target.value) : setDefaults();
          }}
          value={searchQuery}
          className="searchField"
          label={t("Search By Airline Name")}
          variant="outlined"
          size="small"
        />
        <SearchIcon className="searchIcon" onClick={filterData} />
      </div>
      <FormControl component="fieldset">
        <FormGroup>
          {dataFiltered.length === 0 ? (
            <div className="stops-item-container">
              <div className="stop-item-title">
                <span className="fs14">{t("No Results for this search")}</span>
              </div>
            </div>
          ) : showMore ? (
            dataFiltered &&
            dataFiltered.length > 0 &&
            dataFiltered.map(
              (airline, index) =>
                airline.airline !== undefined && (
                  <div className="stops-item-container" key={index}>
                    <div className="stop-item-title">
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.root}
                            color="default"
                            checkedIcon={
                              <span
                                className={clsx(
                                  classes.icon,
                                  classes.checkedIcon
                                )}
                              />
                            }
                            icon={<span className={classes.icon} />}
                            checked={airline.checked}
                            onChange={handleChange}
                            name={airline.airline}
                          />
                        }
                        label={
                          findAirlinebyCode(airline.airline) +
                          " - " +
                          airline.airline
                        }
                      />
                    </div>
                    <div className="stop-item-price">
                      <CurrencyCodeComponent currencyCode={currencyCode} />
                      {airline.startingFrom.toFixed(2)}
                    </div>
                  </div>
                )
            )
          ) : dataFiltered.length > 5 ? (
            dataFiltered &&
            dataFiltered.length > 0 &&
            dataFiltered.slice(0, 5).map(
              (airline, index) =>
                airline.airline !== undefined && (
                  <div className="stops-item-container" key={index}>
                    <div className="stop-item-title">
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.root}
                            color="default"
                            checkedIcon={
                              <span
                                className={clsx(
                                  classes.icon,
                                  classes.checkedIcon
                                )}
                              />
                            }
                            icon={<span className={classes.icon} />}
                            checked={airline.checked}
                            onChange={handleChange}
                            name={airline.airline}
                          />
                        }
                        label={
                          findAirlinebyCode(airline.airline) +
                          " - " +
                          airline.airline
                        }
                      />
                    </div>
                    <div className="stop-item-price">
                      <CurrencyCodeComponent currencyCode={currencyCode} />
                      {airline.startingFrom.toFixed(2)}
                    </div>
                  </div>
                )
            )
          ) : (
            dataFiltered &&
            dataFiltered.length > 0 &&
            dataFiltered.map(
              (airline, index) =>
                airline.airline !== undefined && (
                  <div className="stops-item-container" key={index}>
                    <div className="stop-item-title">
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.root}
                            color="default"
                            checkedIcon={
                              <span
                                className={clsx(
                                  classes.icon,
                                  classes.checkedIcon
                                )}
                              />
                            }
                            icon={<span className={classes.icon} />}
                            checked={airline.checked}
                            onChange={handleChange}
                            name={airline.airline}
                          />
                        }
                        label={
                          findAirlinebyCode(airline.airline) +
                          " - " +
                          airline.airline
                        }
                      />
                    </div>
                    <div className="stop-item-price">
                      <CurrencyCodeComponent currencyCode={currencyCode} />
                      {airline.startingFrom.toFixed(2)}
                    </div>
                  </div>
                )
            )
          )}
        </FormGroup>
      </FormControl>
      <div>
        {showMore && dataFiltered.length > 5 && (
          <Button
            variant="text"
            onClick={() => setShowMore(false)}
            className="textTransCap"
            startIcon={<RemoveIcon />}
          >
            {t("ShowLess")}
          </Button>
        )}
        {!showMore && dataFiltered.length > 5 && (
          <Button
            variant="text"
            onClick={() => setShowMore(true)}
            className="textTransCap"
            startIcon={<AddIcon />}
          >
            {t("ShowMore")}
          </Button>
        )}
      </div>
    </div>
  );
}

export default Airlines;
