import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import './Void.css'
import { formatDate } from '../../../services/dateFormatter';
import SkeletonView from '../../SkeletonView/SkeletonView';
import Textarea from '@mui/joy/Textarea';
import { useTranslation } from "react-i18next";
const Void = ({ voidPopUp, setVoidPopUp, results, pnr, updateCancelRequestDetails, wholeItinerarySelected, setWholeItinerarySelected,
    bookingAmount, voidServiceFee, voidCharge, voidVendorFee, clientMarkup, setCancelDetails, cancelDetails
}) => {
    const { t } = useTranslation();
    const [selectedPax, setSelectedPax] = useState({});
    const [disabledPax, setDisabledPax] = useState({});
    const [refundDetails, setRefundDetails] = useState({ bookingAmount: 0, cancellationFee: 0, serviceFee: 0, refund: 0 });
    const [isVoidDisabled, setIsVoidDisabled] = useState(false);

    const handleSelectPax = (itineraryId, paxId, ticketNumber) => {
        const newSelectedPax = { ...selectedPax };
        const newDisabledPax = { ...disabledPax };
        const newCancelDetails = { ...cancelDetails };

        const isSelected = newSelectedPax[itineraryId]?.includes(paxId);
        const segGroupIndex = newCancelDetails.flight[0].segGroups.findIndex(seg => seg.id === itineraryId);
        if (segGroupIndex === -1) return;


        if (isSelected) {
            newSelectedPax[itineraryId] = newSelectedPax[itineraryId].filter(id => id !== paxId);

            results.map(itinerary => {
                itinerary.passengers.map(pax => {
                    if (pax.ticketNumber === ticketNumber) {
                        newDisabledPax[itinerary.id] = newDisabledPax[itinerary.id]?.filter(id => id !== pax.id) || [];
                    }
                });
            });

            newCancelDetails.flight[0].segGroups.map((segGroup, index) => {
                if (index === segGroupIndex) {
                    segGroup.passengers.map(pax => {
                        if (pax.id === paxId) {
                            pax.isChecked = false;
                        }
                    });
                } else {
                    segGroup.passengers.map(otherPax => {
                        newCancelDetails.flight[0].segGroups[segGroupIndex].passengers.map(pax => {
                            if (pax.id === paxId) {
                                if (otherPax.ticketNumber === pax.ticketNumber) {
                                    otherPax.isChecked = false;
                                    otherPax.isDisabled = false;
                                }
                            }
                        });
                    });
                }
            });

        } else {
            if (!newSelectedPax[itineraryId]) {
                newSelectedPax[itineraryId] = [];
            }
            newSelectedPax[itineraryId].push(paxId);

            results.map(itinerary => {
                itinerary.passengers.map(pax => {
                    if (pax.ticketNumber === ticketNumber) {
                        if (!newDisabledPax[itinerary.id]) {
                            newDisabledPax[itinerary.id] = [];
                        }
                        if (!newDisabledPax[itinerary.id].includes(pax.id)) {
                            newDisabledPax[itinerary.id].push(pax.id);
                        }
                    }
                });
            });

            newCancelDetails.flight[0].segGroups.map((segGroup, index) => {
                if (index === segGroupIndex) {
                    segGroup.passengers.map(pax => {
                        if (pax.id === paxId) {
                            pax.isChecked = true;
                        }
                    });
                } else {
                    segGroup.passengers.map(otherPax => {
                        newCancelDetails.flight[0].segGroups[segGroupIndex].passengers.map(pax => {
                            if (pax.id === paxId) {
                                if (otherPax.ticketNumber === pax.ticketNumber) {
                                    otherPax.isChecked = true;
                                    otherPax.isDisabled = true;
                                }
                            }
                        });
                    });
                }
            });
        }


        const allAdtSelected = newCancelDetails.flight[0].segGroups.map((sg) =>
            sg.passengers
                .filter(pax => pax.paxType === 'ADT')
                .every(pax => pax.isChecked))


        newCancelDetails.flight[0].segGroups.map((sg) => sg.passengers.forEach(pax => {
            if (pax.paxType === 'INF' && !pax.isDisabled && !allAdtSelected.includes(false)) {
                pax.isChecked = true;
                // pax.isDisabled = true;
                if (!newSelectedPax[itineraryId]?.includes(pax.id)) {
                    newSelectedPax[itineraryId].push(pax.id);
                }
            }

        }));


        setSelectedPax(newSelectedPax);
        setDisabledPax(newDisabledPax);
        setCancelDetails(newCancelDetails);
    };


    const handleSelectAll = (itineraryId) => {
        let newSelectedPax = { ...selectedPax };
        let newDisabledPax = { ...disabledPax };
        const newCancelDetails = { ...cancelDetails };
        const segGroupIndex = newCancelDetails.flight[0].segGroups.findIndex(seg => seg.id === itineraryId);
        if (segGroupIndex === -1) return;

        const itinerary = results.find(it => it.id === itineraryId);

        if (newSelectedPax[itineraryId]?.length === itinerary.passengers.length) {
            newSelectedPax = {};
            newDisabledPax = {};

            newCancelDetails.flight[0].segGroups.forEach((segGroup, index) => {
                if (index === segGroupIndex) {
                    segGroup.isAllChecked = false;
                    segGroup.passengers.forEach(pax => {
                        pax.isChecked = false;
                        pax.isDisabled = false;
                    });
                } else {
                    segGroup.passengers.forEach(otherPax => {
                        let matched = false;
                        newCancelDetails.flight[0].segGroups[segGroupIndex].passengers.forEach(pax => {
                            if (otherPax.ticketNumber === pax.ticketNumber) {
                                otherPax.isChecked = false;
                                otherPax.isDisabled = false;
                                matched = true;
                            }
                        });
                        if (!matched) {
                            otherPax.isChecked = false;
                        }
                    });
                    segGroup.isAllChecked = false;
                }
            });
        } else {
            newSelectedPax[itineraryId] = itinerary.passengers.map(pax => pax.id);
            newDisabledPax[itineraryId] = itinerary.passengers.map(pax => pax.id);

            itinerary.passengers.forEach(pax => {
                results.forEach(otherItinerary => {
                    if (otherItinerary.id !== itineraryId) {
                        otherItinerary.passengers.forEach(otherPax => {
                            if (otherPax.ticketNumber === pax.ticketNumber) {
                                if (!newDisabledPax[otherItinerary.id]) {
                                    newDisabledPax[otherItinerary.id] = [];
                                    newSelectedPax[otherItinerary.id] = [];
                                }
                                if (!newDisabledPax[otherItinerary.id].includes(otherPax.id)) {
                                    newDisabledPax[otherItinerary.id].push(otherPax.id);
                                    newSelectedPax[otherItinerary.id].push(otherPax.id);
                                }
                            }
                        });
                    }
                });
            });

            newCancelDetails.flight[0].segGroups.forEach((segGroup, index) => {
                if (index === segGroupIndex) {
                    segGroup.isAllChecked = true;
                    segGroup.passengers.forEach(pax => {
                        pax.isChecked = true;
                        pax.isDisabled = true;
                    });
                } else {
                    let allPassengersChecked = true;

                    segGroup.passengers.forEach(otherPax => {
                        let matched = false;
                        newCancelDetails.flight[0].segGroups[segGroupIndex].passengers.forEach(pax => {
                            if (otherPax.ticketNumber === pax.ticketNumber) {
                                otherPax.isChecked = true;
                                otherPax.isDisabled = true;
                                matched = true;
                            }
                        });

                        if (!matched) {
                            allPassengersChecked = false;
                            otherPax.isChecked = false;
                        }
                    });

                    if (allPassengersChecked) {
                        segGroup.isAllChecked = true;
                    } else {
                        segGroup.isAllChecked = false;
                    }
                }
            });
        }

        setSelectedPax(newSelectedPax);
        setDisabledPax(newDisabledPax);
        setCancelDetails(newCancelDetails);
    };


    useEffect(() => {
        let wholeItineraryChecked = true;
        let voidDisabled = false;

        results.forEach(itinerary => {
            const segGroupIndex = cancelDetails.flight[0].segGroups.findIndex(seg => seg.id === itinerary.id);

            const allPassengersChecked = cancelDetails.flight[0].segGroups[segGroupIndex].passengers.every(pax => pax.isChecked);
            cancelDetails.flight[0].segGroups[segGroupIndex].isAllChecked = allPassengersChecked;

            if (!allPassengersChecked) {
                wholeItineraryChecked = false;
            }

            const allNonVoidStatus = cancelDetails.flight[0].segGroups[segGroupIndex].passengers.every(
                pax => !["none", "ticketed"].includes(pax.flightTicketStatus.toLowerCase())
            );

            if (allNonVoidStatus) {
                voidDisabled = true;
            }
        });

        setWholeItinerarySelected(wholeItineraryChecked);
        setIsVoidDisabled(voidDisabled);

    }, [results, selectedPax]);


    const handleWholeItinerarySelection = () => {
        const isSelected = wholeItinerarySelected;

        results.forEach(itinerary => {
            const segGroupIndex = cancelDetails.flight[0].segGroups.findIndex(seg => seg.id === itinerary.id);
            cancelDetails.flight[0].segGroups[segGroupIndex].isAllChecked = !isSelected;

            cancelDetails.flight[0].segGroups[segGroupIndex].passengers.forEach(pax => {
                pax.isChecked = !isSelected;
                pax.isDisabled = !isSelected;
            });
        });

        setWholeItinerarySelected(!isSelected);
    };

    useEffect(() => {
        if (wholeItinerarySelected || wholeItinerarySelected === true) {
            calculateRefund();
        }
    }, [wholeItinerarySelected])


    const calculateRefund = () => {

        const ticketNumbers = [];

        results.forEach((segGroup) => {
            segGroup.passengers.forEach((passenger) => {
                if (ticketNumbers.indexOf(passenger.ticketNumber) === -1) {
                    ticketNumbers.push(passenger.ticketNumber);
                }
            });
        });

        let voidFee = (voidVendorFee + voidCharge) * ticketNumbers.length;
        let serviceFees = voidServiceFee * ticketNumbers.length;
        let refund = bookingAmount - clientMarkup - voidFee - serviceFees;

        setRefundDetails({
            bookingAmount: (bookingAmount - clientMarkup).toFixed(2),
            cancellationFee: voidFee.toFixed(2),
            serviceFee: serviceFees.toFixed(2),
            refund: refund.toFixed(2)
        });
    };

    const voidNow = () => {
        updateCancelRequestDetails("Void");
    };

    return (
        <>
            <Dialog className="" style={{ inset: "40px" }} open={voidPopUp} onClose={() => setVoidPopUp(false)} maxWidth="md" fullWidth >
                <DialogTitle>
                    <div className='d-flex justify-content-between'>
                        <div></div>
                        <div><Button onClick={() => setVoidPopUp(false)} color="inherit">
                            <CloseIcon />
                        </Button>
                        </div>
                    </div>
                </DialogTitle>

                <DialogContent className=''>
                    <div className="void-booking">
                        <div className='d-flex justify-content-between'>
                            <div className='text-bold'>Void the Itinerary</div>
                            <div>
                                <Checkbox
                                    onChange={handleWholeItinerarySelection}
                                    checked={wholeItinerarySelected}
                                /> {t("Select Whole Itinerary")}
                            </div>
                        </div>

                        {
                            results[0].origin ? (<>
                                <div >
                                    {results?.map(itinerary => (
                                        <div key={itinerary.id} className="itinerary mt-3 mb-3">
                                            {itinerary.segments.map((segment, segIndex) => (
                                                <div className='d-flex justify-content-between border border-1 border-danger p-2 rounded mt-1'>
                                                    <div>{segment.origin} ---&gt; {segment.destination}</div>
                                                    <div>{formatDate(segment.departureOn)}</div>
                                                    <div> {segment.mrkAirline + "-" + segment.flightNum}</div>
                                                    <div>PNR- {pnr}</div>
                                                    <div>
                                                        {segIndex === 0 ? <>
                                                            <Checkbox
                                                                onChange={() => handleSelectAll(itinerary.id)}
                                                                checked={itinerary.isAllChecked}
                                                                disabled={itinerary.isDisabled}
                                                            /> {t("Select All")}
                                                        </> : <div style={{ width: "90px" }}></div>
                                                        }
                                                    </div>
                                                </div>
                                            ))}
                                            {itinerary.passengers
                                                .sort((a, b) => a.paxType.localeCompare(b.paxType))
                                                .map(pax => (
                                                    <div key={pax.id} className='d-flex justify-content-between mt-3 mb-3'>
                                                        <div style={{ width: "50%" }}>
                                                            <Checkbox
                                                                onChange={() => handleSelectPax(itinerary.id, pax.id, pax.ticketNumber)}
                                                                checked={pax.isChecked || pax.isCancelled || false}
                                                                disabled={pax.isDisabled || pax.isCancelled}
                                                            />
                                                            {pax?.firstName + " " + pax?.lastName} ({pax?.paxType})
                                                        </div>
                                                        <div className='d-flex justify-content-between' style={{ width: "50%" }}>
                                                            <div> {t("Ticket Number")}: {pax?.ticketNumber} </div>
                                                            <div className='text-success'>{pax?.flightTicketStatus}</div>
                                                        </div>

                                                    </div>
                                                ))}

                                        </div>
                                    ))}
                                </div>
                                <Divider sx={{ opacity: 1, borderBottomWidth: "medium" }} className='mt-5' />
                                {wholeItinerarySelected && <div className="refund mt-3">
                                    <div className='text-bold'>{t("Void Refund")}</div>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">{t("Booking Amoun")}t</TableCell>
                                                    <TableCell align="center">{t("Void/Cancellation Fee")}</TableCell>
                                                    <TableCell align="center">{t("Service Fee")}</TableCell>
                                                    <TableCell align="center">{t("Refund")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center">{refundDetails.bookingAmount}</TableCell>
                                                    <TableCell align="center">{refundDetails.cancellationFee}</TableCell>
                                                    <TableCell align="center">{refundDetails.serviceFee}</TableCell>
                                                    <TableCell align="center">{refundDetails.refund}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </div>}

                                <div className='mt-3'>
                                    <p>
                                        <span className='text-danger'>Note:</span><br></br>
                                        1. {t("If whole itinerary selected, then it will Auto Void the Tickets")},<br></br>
                                        2. {t("Partial Void will create offline request")}
                                    </p>
                                </div>
                                <div>
                                    <div className='text-bold'>{t("Remark")}</div>
                                    <Textarea sx={{ minHeight: "6rem" }} />
                                </div>
                            </>)
                                :
                                (
                                    <SkeletonView />
                                )}

                        <div className='d-flex justify-content-between mt-3'>
                            <div>{t("I accept the Terms and Conditions by clicking on Void Now")}</div>
                            <Button disabled={isVoidDisabled} onClick={voidNow} className='srn-btn'>{t("Void Now")}</Button>
                        </div>

                    </div>
                </DialogContent >


            </Dialog >
        </>
    );
};

export default Void;